import React from 'react';
import { useStyles } from './styles';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { SPECIAL_CHAR_NOT_ALLOWED } from "../../../helpers/constants";

export const SpecialCharAlert = (props) => {

  const { open, handleSpecialCharAlertClose, file} = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      width="600px"
      PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={handleSpecialCharAlertClose} open={open}
    >
      <Box className={classes.root}>
        <IconButton className={classes.iconStyle} onClick={handleSpecialCharAlertClose}>
          <CancelIcon />
        </IconButton>
        <Box className={classes.header}>
           <Typography className={classes.subTitle}>
             {SPECIAL_CHAR_NOT_ALLOWED}
           </Typography>
        </Box>
        
        <Button
          color='primary'
          variant='contained'
          className={classes.btn}
          onClick={handleSpecialCharAlertClose}
        >OK</Button> 
      </Box>
    </Dialog>
  )
}
