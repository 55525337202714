import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 720,
    borderRadius: 12,
    backgroundColor: '#EAECED',
    position: 'relative',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    overflowY: "auto"
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxWidth: '80%',
    maxHeight: "100%",
    backgroundColor: 'transparent'
  },

  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '18px',
    fontWeight: "400",
    textAlign: 'center',
    margin: 12
  },

  cardBody: {
    width: '100%',
    // display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 5.5em',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      padding: '1.6em',
    },
  },

  statusContainer: {
    marginBottom: 30,
  },

  statusTitle: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "500",
    marginBottom: 12,
    marginTop: 0,
  },

  statusBody: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "bold",
  },

  bodyText: {
    color: "#0C0B0B",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    marginBottom: 30,
    marginTop: 0,
  },

  linkStyle: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    fontWeight: "600",
    marginTop: 5,
  },

  btn: {
    width: 188,
    fontFamily: "Montserrat",
    fontSize: '1.5em',
    fontWeight: "600",
  },

  closeIcon: {
    position: 'absolute',
    // top: 10,
    right: 5,
  },

  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },

  statusSubTitle: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "500",
    marginBottom: 12,
    marginTop: 0,
    textAlign: 'center',
    marginTop: 12
  },

  statusSubTitles: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "500",
    marginBottom: 12,
    marginTop: 0,
    textAlign: 'center',
    wordWrap: 'break-word'
  },
  okBtn: {
    fontFamily: "Montserrat",
    marginBottom: 10,
    marginTop: 10,
    fontSize: 15,
    fontWeight: '600',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 100,
  },


}));

