import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  inputText: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "bold",
    width: '100%',
  },

  flexBox: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  inputField: {
    width: '43%',
    marginBottom: 40,
  },

  title: {
    width: '100%',
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: "600",
    marginBottom: 18,
  },
  subtitle: {
    width: '100%',
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: "600",
    marginBottom: 18,
  },

  inputBox: {
    width: '100%',
    marginBottom: 20,
  },

  formControl: {
    width: '100%',
    marginBottom: 20,
  },

  formLabel: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "20px",
    marginBottom: 10,
  },

  labelStyle: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "16px",
  },

  formStyle: {
    width: '100%',
  },
  questionText:{    
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "20px",
    letterSpacing: "0.44px",
    lineHeight: "24px",
    marginBottom:"10px"
  },
  inputFieldFullWidth: {
    width: '100%',
    //marginBottom: 40,
  },
  
infoIcon:{
  verticalAlign: "middle",
  fontSize: "10px"
},
tableInputBox:{
  //height:56,
  width: "100px", //"100%",
  borderRadius: "4px",
  border: "1px solid #535D6D",
  padding:"10px 10px",
  color:"#535D6D",
  fontSize: "16px",  
  fontWeight:600
},
otherInputBox:{
    float: "right",
    width: "50%",
    borderRadius: "4px",
  border: "1px solid #535D6D",
  padding:"5px 10px",
  color:"#535D6D",
  fontSize: "16px",  
  fontWeight:600
},
inputFieldTable: {
  width: '100px',
  //marginBottom: 20,
},
inputTextTable: {
  color: "#535D6D",
  fontFamily: "Montserrat",
  fontSize: "16px",
  fontWeight: "bold",
  width: '100%',
},


}));

