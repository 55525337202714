import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, CircularProgress, Button, Grid } from "@material-ui/core";
import { useStyles } from './styles';
import { getDocLanverDetails, getDashboardData  } from '../../actions';
import { scrollTop, getUserType, getAppSource } from '../../helpers';
import CustomBreadcrum from '../Breadcrum';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import goGreenDesktop from '../../assets/goGreenDesktop.svg';
import { EnrollPaperLess, SnackBar } from '../../components';
import { PAPERLESS_CONSENT, SNACKBAR_SUCCESS } from '../../helpers/constants';

export const Documentcenter = () => {
 
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();

  const selectedAccount = useSelector(state => state.selectedAccount);
  const selectedAccountData = useSelector(state => state.selectedAccountData);
  const accountInfoData = useSelector(state => state?.accountInfoData);

  const [content, setContent] = useState('');
  const [goGreenStatus, setGoGreenStatus] = useState(false);

  const privateLabelUrl = Cookies.get("privateLabelUrl")
  const isDocRequestAllowed = Cookies.get('isDocRequestAllowed');
  const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'));
  const ipadMediaQuery = useMediaQuery(theme.breakpoints.down('sm'));
  const mobileMediaQuery = useMediaQuery(theme.breakpoints.down('xs'));
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState(null);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const handleClickOpen = () => setOpen(true);
 
  const handleClose = (status) =>  {
    setOpen(false)
    if (status) {
      getData(selectedAccount);
      setOpenSnackBar(true)
    }
  };

  useEffect(() => {
    if (selectedAccount) {
      getData(selectedAccount)
    }
  },[selectedAccount])

  const toggleSnackBar = () => {
    setOpenSnackBar(!openSnackBar)
  }

  const getDocDetails = async (selectedAccount) => {
    const res = await getDocLanverDetails(selectedAccount);
    if (res) {
      setContent(res.url)
      scrollTop()
    }
  };

  useEffect(() => {
    if (selectedAccount) {
      getDocDetails(selectedAccount)
    }
    scrollTop()
  }, [selectedAccount]);

  const getData = async (selectedAccount) => {
    const data = {
      "accountNumber": selectedAccount,
      "propertyState": selectedAccountData && selectedAccountData.propertyState ? selectedAccountData.propertyState : '',
      "investorCode": selectedAccountData && selectedAccountData.bsi_InvestorCode ? selectedAccountData.bsi_InvestorCode : '', 
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };

    const res = await dispatch(getDashboardData(data));
    if (res) {
      setData(res)
      if ((res.bannerInfo?.length > 0) && (res.bannerInfo !== null)) {
        const displayStatus = res.bannerInfo.filter((banner) => ((banner.bannerName.toUpperCase() == 'GOGREEN') && (banner.isDisplay == true)))
        setGoGreenStatus(displayStatus?.length > 0 ? true : false)
      } else {
        setGoGreenStatus(false);
      }
    }
  }
 
  return (
    <>
    <Box className={classes.rootBg}>
      <Box className={classes.root}>
        <CustomBreadcrum backPage="Home" backPageLink="/Dashboard" activePage="Documents" />
        { ((privateLabelUrl.toLowerCase() !== "aafmaa") && (isDocRequestAllowed == 'true') && (privateLabelUrl.toLowerCase() !== "point")) &&
        <Button className={classes.btn} size="large" fullWidth
                  variant='outlined'
                  component={Link} to='/DocumentRequest?back'
                >
                  REQUEST DOCUMENT
          </Button>
        }

     { ((!mediaQuery || ipadMediaQuery) && !mobileMediaQuery) && goGreenStatus &&
      <Box className={classes.quoteBox}>
        <Grid container className={classes.goGreenContainer}>
          <Grid item md={2} xs={3} lg={2}>
            <Box style={{display:'content', justifyItems: 'center' }}>
              <img src={goGreenDesktop} className={classes.goGreenImg}/>
            </Box>
          </Grid>
          <Grid item md={6} xs={5} lg={6}>
            <Box className={classes.bannerParagraph}>{PAPERLESS_CONSENT}
            </Box>
          </Grid>
          <Grid item md={4} xs={2} lg={4}>
            <Box className={classes.quickQuoteBtn}>
              <a className={classes.paperlessBtn} 
                 onClick={handleClickOpen}
                 >Go paperless now!</a>
            </Box>
          </Grid>
        </Grid>
      </Box> 
      }

     { (ipadMediaQuery && mobileMediaQuery) && goGreenStatus &&
      <Box className={classes.quoteBox}>
        <Grid container className={classes.goGreenContainer}>
          <Grid item md={4} xs={4} lg={4}>
            <Box style={{display:'content', justifyItems: 'center' }}>
              <img src={goGreenDesktop} className={classes.goGreenMobImg}/>
            </Box>
          </Grid>
          <Grid item md={8} xs={8} lg={8}>
            <Box className={classes.bannerParagraphMob}>{PAPERLESS_CONSENT}
            </Box>
            <Box className={classes.quickQuoteBtn}>
              <Button className={classes.inquireNowMob} 
                 variant='contained'
                 color='primary'
                 onClick={handleClickOpen}
                 >Go paperless now!</Button>
            </Box>
          </Grid>
        </Grid>
      </Box> 
      }

        {content !== '' ?
          < Box className={classes.container} >
            <iframe className={classes.responsiveIframe} src={content}></iframe>
          </ Box>
          :
          <Box className={classes.loaderContainer}>
            <CircularProgress />
          </Box>
        }
      </Box>
    </Box>
    {open &&
      <EnrollPaperLess
          open={open}
          handleClose={handleClose}
          accountNumber={selectedAccount}
          isCommConcent={data.isCommConcent}
          isCommConcentMobile={data.isCommConcentMobile}
          IsSmsConsent={data.isSmsConsent}
        /> }
       {
        <SnackBar handleClose={toggleSnackBar}
         open={openSnackBar}
         severity={'success'}
         message={SNACKBAR_SUCCESS}
       />
      }
    </>
  )
}