import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, CssBaseline, Grid, Typography, Button, CircularProgress,
    InputAdornment, FormControl, TextField, Select,
    InputLabel, TextareaAutosize, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { useStyles } from './styles';
import { renderDefaultMsg, isAdmin, getUserType, getAppSource, scrollTop  } from '../../helpers';
import CustomBreadcrum from '../Breadcrum';
import { getMaturityDetails, submitMaturityDetails, getDashboardData,showToaster } from '../../actions';
import { useHistory } from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';
import MenuItem from '@material-ui/core/MenuItem';
import { decrypt } from '../../helpers/encrypt';
import Cookies from 'js-cookie';
import { SnackBar } from '../../components';
import { AccessAlert } from '../AccessAlert';
import moment from 'moment';
const businessMoment = require('moment-business-days');

export const MaturityExtension = (props) => {
 
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const accountInfoData = useSelector(state => state?.accountInfoData);
  const selectedAccountData = useSelector(state => state.selectedAccountData);
  const loanInfoData = useSelector(state => state?.loanInfoReducer);
 
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(true);
  const [months, setMonths] = useState([{ddL_Key: "3 Months"}, {ddL_Key: "6 Months"}, {ddL_Key: "9 Months"}, {ddL_Key: "12 Months"}]);
  const [plans, setPlans] = useState([{ddL_Key: "Sell"}, {ddL_Key: "Refinance"}, {ddL_Key: "Payoff"}]);
  const [month, setMonth] = useState();
  const [comments , setComments] = useState();
  const [submitDetails, setSubmitDetails] = useState({});
  const [errorMsg, setErrorMsg] = useState('')
  const [checkboxState, setCheckboxState] = useState(false);
  const [plan, setPlan] = useState();
  const [maturityDate, setMaturityDate] = useState();
  const [apiError, setApiError] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");

  const checkboxChangeHandler = (event) => {
    setCheckboxState(event.target.checked)
  }
  
  useEffect(() => {
    
    if (accountInfoData?.accountNumber) {
      
        setMaturityDate(accountInfoData.maturityExtensionsInfo.maturityDate)
        if (accountInfoData?.maturityExtensionsInfo?.isPopUp == false) {
          getMaturityExtSubmitDetails()
        }
    } else {
     
        getData(props?.accountNumber)
    }
  },[accountInfoData])
 
  useEffect(() => {
    getMaturityExtSubmitDetails()
  }, [props.reload])
 
  const accessDeniedPopup = () => {
    history.push('/Dashboard');
  }
  
  const getMaturityExtSubmitDetails = async () => {
    setErrorMsg('')
    setApiError('')
    setLoader(true)
    setComments('')
    const data = {
      "accountNumber": props?.accountNumber, 
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };

    const res = await getMaturityDetails(data);
    
    if (res?.responseData?.lstResponseStatus[0]?.statusCode == '0') {
        setLoader(false);
        setCheckboxState(false)
        setMonth()
        setPlan()
        setSubmitDetails(res?.responseData?.responseData);
        setSubmitStatus(!res?.responseData?.responseData?.communicationConsent);
        scrollTop()
      }
      else{
        setSubmitStatus(true);
        setLoader(false);
        setApiError(renderDefaultMsg('400'));
        scrollTop()
      }
  }

   const submitData = async () => {
    setLoading(true)
    setApiError('')
    const data = {
        "extensionInMonths": month,
        "reasonForExtension": comments,
        "constructionCompletePlan": plan,
        "communicationConsent": checkboxState,
        "maturityDate": maturityDate,
        "accountNumber": props?.accountNumber, 
        "privateLabelUrl": Cookies.get("privateLabelUrl"),
        "ipAddress": Cookies.get('publicIp'),
        "browser": Cookies.get("browser"),
        "appSource": getAppSource(),
        "userType": getUserType(),
        "userName": ""
    };

    const res = await submitMaturityDetails(data);
    if (res && res?.responseData?.lstResponseStatus && res?.responseData.lstResponseStatus[0]?.statusCode === '0') {
      setLoading(false);
      setComments()
      Cookies.set('maturityExtensionMenu',true);
      getMaturityExtSubmitDetails()
      snackBarHandler('Your maturity extension application submitted successfully.');
      setData(props?.accountNumber)
    } else {
      setLoading(false)
      setApiError(res?.responseData?.lstResponseStatus[0]?.statusDesc);
    }
  }
 
  const setData = async (accountNumber) => {
    const data = {
      "accountNumber": accountNumber,
      "propertyState": selectedAccountData && selectedAccountData.propertyState ? selectedAccountData.propertyState : '', 
      "investorCode": selectedAccountData && selectedAccountData.bsi_InvestorCode ? selectedAccountData.bsi_InvestorCode : '', 
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };

    const res = await dispatch(getDashboardData(data));
  }

  if (loader) {
    return (
      <Box className={classes.loader}>
        <CircularProgress />
      </Box>
    )
  }
 
  const checkValidations = () => {
    if (!checkboxState || isAdmin() || (plan == undefined) || (plan == "") || (month == undefined) || (month == "") || (comments?.trim().length === 0) || loanInfoData?.purgeLoanInfo?.liquidationStatus) {
        return true
    } else {
        return false
    }
  }

  const defaultMonth = (data) => {
    setMonth(data)
  }
 
  const setDefaultPlan = (data) => {
    setPlan(data)
  }

  const submitForm = () => {
    submitData()
  }

  let allDefaultReasons = '';
  if (months && months.length) {
    allDefaultReasons = months.map((item) => {
      return (
        <MenuItem value={item.ddL_Key} key={item.ddL_Key} onClick={() => defaultMonth(item.ddL_Key)}>{item.ddL_Key}</MenuItem>
      )
    })
  }

  let defaultPlans = '';
  if (plans && plans.length) {
    defaultPlans = plans.map((item) => {
      return (
        <MenuItem value={item.ddL_Key} key={item.ddL_Key} onClick={() => setDefaultPlan(item.ddL_Key)}>{item.ddL_Key}</MenuItem>
      )
    })
  }

  const routeToDashboard = () => {
    Cookies.set('maturityExtensionMenu',true);
    history.push('/Dashboard')
  }

  const handleAddCommentChange = (event) => {
    setComments(event.target.value)
  }
 
  const calculateCommentLenth = () => {
    const length = comments?.length 
    if (isNaN(length)) {
        return 0
    } else {
        return length
    }
  }

  const getData = async (accountNumber) => {
    const data = {
      "accountNumber": accountNumber,
      "propertyState": selectedAccountData && selectedAccountData.propertyState ? selectedAccountData.propertyState : '', 
      "investorCode": selectedAccountData && selectedAccountData.bsi_InvestorCode ? selectedAccountData.bsi_InvestorCode : '', 
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };

    const res = await dispatch(getDashboardData(data));
    if (res) {
        setLoader(false)
        setMaturityDate(res?.maturityExtensionsInfo?.maturityDate)
        if (accountInfoData?.maturityExtensionsInfo?.isPopUp == false) {
          getMaturityExtSubmitDetails()
        }
    }
  }

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false)
  }
  const snackBarHandler = (msg) => {
   // setHasError(false);
    setSnackBarMsg(msg);
    setOpenSnackBar(true);
  }
 
  
  return (
    <CssBaseline>
       <Box container="true" maxWidth='xl' className={classes.root}>      
        <Box className={classes.container}>
        <CustomBreadcrum backPage="Loan details" backPageLink="/LoanInfo" activePage="Maturity Extension Application" />
          <h1 className={classes.pageHeading} color="textPrimary" component='h4'>Maturity Extension Application</h1>

          <>
         { submitStatus ?
            <Box className={classes.infoStrip}>
                  <Grid container>
                    <Grid item md={12} xs={12} lg={12}>
                      <Box className={classes.infoSection}>
                        <Typography className={classes.heading}>Fill the form to apply for an extension</Typography>
                        <Typography className={classes.title}>This application is used to request an extension on your maturity. Extending your maturity will give you more time to pay off the loan/sell the property. By submitting this application, it gives no promise or guarantee that your maturity date will be extended. This is simply an application to see if the lender of your loan will allow the maturity date to be extended.</Typography>             
                      </Box>     
                    </Grid> 
            </Grid>
          
            <Box className={classes.formSection}>
              
               
              <Grid container>
                <Grid item md={12} xs={12} lg={12}>
                  <Grid container>
                  { allDefaultReasons?.length > 0 &&
                    <Grid item md={6} xs={12} lg={6}>
                        <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                            <InputLabel id="demo-simple-select-label1" className={classes.ques}>How long do you need an extension for in months? <span style={{ color: "red" }}>*</span></InputLabel>
                            <Select
                             labelId="demo-simple-select-label1"
                             id="demo-simple-select"
                             className={classes.inputStyle}
                             label={'How long do you need an extension for in m'}
                            variant="outlined" 
                            fullWidth
                            >
                            {allDefaultReasons}
                            </Select>
                        </FormControl>
                </Grid>
               }
                <Grid item md={6} xs={12} lg={6}>
                    <Box className={classes.textareaSection}>
                      <TextareaAutosize
                        style={{ height: '90px'}}
                        color="primary"
                        onChange={(event) => handleAddCommentChange(event)}
                        id={`outlined_impacted250`}
                        variant="outlined"
                        type="text"
                        name="comments"
                        maxLength="200"
                        value={comments}
                        placeholder="Why do you need an extension? *"
                        className={classes.textArea}
                        />
                        <Typography className={classes.textAreaValidation}>{calculateCommentLenth()}/200</Typography>
                     </Box>      
                </Grid>
                </Grid>
              </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12} xs={12} lg={12}>
                  <Grid container>
                  { defaultPlans?.length > 0 &&
                    <Grid item md={6} xs={12} lg={6}>
                        <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                            <InputLabel id="demo-simple-select-label" className={classes.ques}>What are your plans once construction is complete? <span style={{ color: "red" }}>*</span></InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                            className={classes.inputStyle}
                            label={'What are your plans once construction is co'}
                            fullWidth
                            >
                            {defaultPlans}
                            </Select>
                        </FormControl>
                </Grid>
               }
                <Grid item md={6} xs={12} lg={6}>
                  <Box style={{paddingTop: "10px", paddingLeft: "20px"}}>
                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox
                            checked={checkboxState}
                            onChange={(event) => checkboxChangeHandler(event)}
                            className={classes.checkboxContainer}
                            name="agree"
                        />}
                            label={<><Typography> I agree to receive digital updates regarding this application's progress.<span style={{color:"red"}}>*</span></Typography></>}
                            className={classes.inputStyle}
                            classes={{
                                label: classes.radioLabel
                            }}
                        />
                    </FormGroup>
                 </Box>     
                </Grid>
                </Grid>
              </Grid>
              </Grid>
        
              <p className={classes.errorMsg} dangerouslySetInnerHTML={{ __html: apiError !== '' ? apiError : '' }} />

              <Grid container className={classes.footerSection}>
                <Grid item md={12} xs={12} lg={12}>
                    <Grid container style={{ marginTop: '12px' }}>
                        <Grid item xs={5} md={6} className={classes.pr35}>
                            <Box className={classes.cancelBtnBox}>
                            <Button
                                className={classes.cancelBtn}
                                variant="outlined"
                                onClick={() => routeToDashboard()} 
                            >CANCEL</Button>
                            </Box>
                        </Grid>
                        <Grid item xs={7} md={6} className={classes.pl35}>
                            <Box className={classes.saveBtnBox}>
                            {loading ?
                              <Button disabled={true} type='submit' className={classes.saveBtn} variant="contained" color='primary'>
                                <CircularProgress color='primary' />
                              </Button>
                              :
                             <Button
                                className={classes.saveBtn}
                                disabled={checkValidations()}
                                variant="contained"
                                onClick={() => submitForm()} 
                                color="primary">
                               APPLY</Button> }
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
             </Grid>
            </Box>
          </Box>
          :
          <Box className={classes.infoStrip}>
            <Box className={classes.activePromise}>
                
              <Typography className={classes.heading}>Application Status {(businessMoment(moment(submitDetails?.createdDate)).businessAdd(2)._d > moment(new Date())) && <span className={classes.hours}>(Submitted on { moment(submitDetails?.createdDate).format('ll')})</span>}</Typography>
                  
              <Box className={classes.cardContent}>
                  <InfoIcon className={classes.cardIcon} /> 
                  <p className={classes.requestSubmit}> Your request has been submitted and is being reviewed. It may take up to 2 business days for status updates to be sent at your registered email address, {submitDetails?.emailAddress?decrypt(submitDetails?.emailAddress):''}.</p>
              </Box>
              <Box className={classes.formSection}>
                  <Typography className={classes.transaction}>If you have any questions, please contact us via email at: <a href={'mailto:'+'businesspurposeteam@bsifinancial.com'} className={classes.feeScedule}>businesspurposeteam@bsifinancial.com</a></Typography>
              </Box>
            </Box>

          <Box className={classes.listSection}>
              <Typography className={classes.heading}>Maturity Extension Application Details </Typography>

                  <Grid container>
                    <Grid item  md={6} xs={16} lg={6}>
                      <Typography className={classes.paymentHeading}>How long do you need an extension for in months?</Typography>
                      <Typography className={classes.paymentSubHeading}>{submitDetails?.extensionInMonths}</Typography>
                     
                    </Grid>
                    <Grid item  md={6} xs={12} lg={6}>
                      <Typography className={classes.paymentHeading}>Why do you need an extension? </  Typography>
                      <Typography className={classes.paymentSubHeading}>{submitDetails?.reasonForExtension}</Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item  md={6} xs={12} lg={6}>
                      <Typography className={classes.paymentHeading}>What are your plans once construction is complete?</Typography>
                      <Typography className={classes.paymentSubHeading}>{submitDetails?.constructionCompletePlan}</Typography>
                     
                    </Grid>
                    <Grid item  md={6} xs={12} lg={6}>
                      <Box style={{paddingTop: "10px"}}>
                        <FormGroup row>
                            <FormControlLabel
                                control={<Checkbox
                                checked={true}
                                className={classes.checkboxDisabled}
                                name="agree"
                            />}
                                label={<Typography style={{cursor: "default"}}> I agree to receive digital updates regarding the status of the application.</Typography>}
                                className={classes.inputStyle}
                                style={{pointerEvents:'none'}}
                                classes={{
                                    label: classes.radioLabel
                                }}
                            />
                        </FormGroup>
                      </Box>
                    </Grid>
                </Grid>      
              </Box>     
       </Box>
      }
      </>
     </Box>
    </Box>
     {snackBarMsg.length !== 0 && <SnackBar
        severity={"success"}
        message={snackBarMsg} open={openSnackBar}
        handleClose={handleCloseSnackBar}
      />}
       { loanInfoData && !loanInfoData?.maturityExtensionsInfo?.isMenu &&
        <AccessAlert status={true} accessDeniedPopup={accessDeniedPopup} type="maturity"/>
       }
   
    </CssBaseline>
  )
}
