
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 550,
    borderRadius: 12,
    backgroundColor: 'white',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)"
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxHeight: "100%",
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '20px',
        margin: '50px 15px'
      },
  },

  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "400",
    textAlign: 'center',
    margin: 14,
  },

  cardBody: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '12px 2em',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  cardBody2: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 2em',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  statusTitle: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: '18px',
    fontWeight: "700",
    padding: '3px 2px 2px 3px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px'
    }
  },

  statusBody: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "bold",
  },

  btn: {
    width: 180,
    height: '32px',
    maxHeight: '40px',
    fontFamily: "Montserrat",
    fontSize: '14px',
    fontWeight: "600",
    margin: "10px",
    [theme.breakpoints.down('sm')]: {
        width: 100,
        fontSize: '1em',
        height: '35px'
    }
  },

  labelStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "400",
  },

  cardFooter: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: "#fff",
    padding: '8px 76px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '8px 0px 0',
      justifyContent: 'space-evenly'
    },
  },

  footerItem: {
    marginBottom: 15,
    maxWidth: 175,
    marginRight: 25,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0
    },
  },

  paragraph: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: "500",
    marginBottom: 8,
    paddingTop: 4
 },

 infoIcon: {
  paddingRight: "10px",
  fontSize: '30px',
  color: "#F59720"
},

cardContainer: {
  width: '100%',
  marginTop: 25,
  textAlign: 'center'
},

totalCardContainer: {
  width: '100%',
  textAlign: 'center'
},

imageContainer: {
  border: '1px solid #D9D9D9',
  backgroundColor: "#E5ECF0",
  borderRadius: 4,
  padding: "6px"
},

container: {
  border: '1px solid #D9D9D9',
  backgroundColor: "#00000",
  borderRadius: 4,
  padding: "6px"
},

detail: {
  fontSize: '14px',
  color: "#121D22",
  fontWeight: '500'
},

paymentDetail: {
  fontSize: '14px',
  color: "#121D22",
  fontWeight: '700',
  paddingLeft: 22
},

paymentTotal: {
  fontSize: '16px',
  color: "#4D4D4D",
  fontWeight: '600'
},

paymentTotalDetails: {
  fontSize: '16px',
  color: "#121D22",
  fontWeight: '700',
  paddingLeft: 22
},

mainContainer: {
  display: "flex",
  justifyContent: "center",
},

cardContent: {
  display: 'flex'
},

cardTitle: {
  color: "#000000",
  fontFamily: "Montserrat",
  fontSize: "12px",
  fontWeight: "600",
  marginBottom: '20px',
  letterSpacing: '0.15px',
  linHheight: '24px',
},

details: {
  textDecoration: "underline", 
  cursor: 'pointer',
  color: "#007FFF !important", 
  fontWeight: 700
},
propertyAdd: {
  maxWidth: '265px !important',
  wordBreak: 'break-all',
  textAlign: 'justify'
}

}));

export const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: "#cccccc",
    color: '#202020',
    // borderRight: 'solid 1px #fff',
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: 600
  },
  body: {
    fontSize: 12,
    fontFamily: "Montserrat",
    color: '#202020',
    // fontWeight: 600,
   
  },
  root : {
    padding: 6
  }
}))(TableCell);



export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      // backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
