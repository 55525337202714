import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: 12,
    backgroundColor: "#fff",
    marginTop: 28,
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    padding: '3.6em 7.7em',
    [theme.breakpoints.down('xs')]: {
      padding: '1.6em',
    },
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "500",
    marginBottom: 35,
  },

  disabledRoot: {
    width: '100%',
    height: 400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  errorTitle: {
    color: "#EB4F4F",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "500",
  },

  flexBox: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItem: 'center',
  },

  totalText: {
    color: '#585757',
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "400",
  }

}));

