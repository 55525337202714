import { adminProtected } from '../config/adminConfig';

export const getAdminUserList = async (data) => {
  try {
    const res = await adminProtected.post('/Admin/GetAdminUserList', data);
    return res.data
  } catch (e) {
    return null
  }
}
export const insertAdminUser = async (data) => {
  try {
    const res = await adminProtected.post('/Admin/InsertAdminUser', data);
    return res.data
  } catch (e) {
    return null
  }
}

export const updateAdminUser = async (data) => {
  try {
    const res = await adminProtected.post('/Admin/UpdateAdminUser', data);
    return res.data
  } catch (e) {    
    return null
  }
}

export const resetAdminPassword = async (data) => {
  try {
    const res = await adminProtected.post('/Admin/ResetAdminPassword', data);
    return res.data
  } catch (e) {
    return null
  }
}

export const unlockAdminUser = async (data) => {
  try {
    const res = await adminProtected.post('/Admin/UnlockAdminuser', data);
    return res.data
  } catch (e) {
    return null
  }
}

export const checkUserName = async (data) => {
  try {
    const res = await adminProtected.post('/Admin/CheckUserName', data);
    return res.data
  } catch (e) {
    return null
  }
}

export const deleteAdminUser = async (data) => {
  try {
    const res = await adminProtected.post('/Admin/DeleteAdminUser', data);
    return res.data
  } catch (e) {
    return null
  }
}


/*Update Page Content API Start*/
export const getAllCompanyName = async (data) => {
  try {
    const res = await adminProtected.post('/Admin/GetPrivateLabelList', data);
    return res.data
  } catch (e) {
    return null
  }
}

export const getPageDetail = async (data) => {
  try {
    const res = await adminProtected.post('/Admin/GetPageDetail', data);
    return res.data
  } catch (e) {
    return null
  }
}

export const updatePageContent = async (data) => {
  try {
    const res = await adminProtected.post('/Admin/UpdatePageContent', data);
    return res.data
  } catch (e) {
    return null
  }
}

export const getAdminPageContent = async (data) => {
  try {
    const res = await adminProtected.post('/Admin/GetPageContent', data);
    return res.data
  } catch (e) {
    return null
  }
}