import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Box, Grid, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import RoomIcon from '@material-ui/icons/Room';
import MailIcon from '@material-ui/icons/Mail';
import PrintIcon from '@material-ui/icons/Print';
import PhoneIcon from '@material-ui/icons/Phone';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { getPointPrivateLabel } from '../../helpers';

export function ReactOutToUs(props) {
  const mytheme = useSelector(state => state.theme);
  const classes = useStyles();
  const { privateLabelCode, privateLabelName, address, customerCareNumber, lendingNumber, customerCareTime, lendingNumberTime } = mytheme;
 
  useEffect(() => {
    if (props?.contactUs) {
      setTimeout(() => {
        window.scrollTo({ top: getPointPrivateLabel() ? 250 : 450, left: 0, behavior: 'smooth' });
      }, 1000);
   }
  }, [props?.contactUs])

  return (
    <div className={classes.bg}>
      <Typography className={classes.mainHeading}
        variant="h4" component="h4" >
        Have questions? Let us help you</Typography>
      <Box className={classes.root} >
        <Paper className={classes.paper} my={5} style={{ width: '100%' }}>
          <Grid container display="flex" justify="space-around"  style={{ height: '100%' }}>
            <Box className={classes.flexItem}>
              <Box className={`${classes.helpAddress} ${classes.flex}`} >
                <RoomIcon className={classes.iconStyle} />
                <Box>

                  {privateLabelCode === 'BSI021' ? <Box dangerouslySetInnerHTML={{ __html: address }}></Box> :
                    <><p className={classes.bodyText}>{privateLabelName}</p>
                      <p>P.O. Box 517<br />Titusville, PA 16354-0517</p></>}
                </Box>
              </Box>
            </Box>
            <Box className={`${classes.flexItem} ${classes.noborder}`} >
              <Box className={`${classes.helpAddress} ${classes.flex}`} >
                <MailIcon className={classes.iconStyle} />
                <Box>
                  <Box component="span" >
                    <Link className={classes.linkStyle} to='/ContactUs'>Email us </Link></Box>
                </Box>
              </Box>
            </Box>
              
            <Box className={classes.flexItem} >
              { customerCareNumber &&
              <Box>
                <Box className={`${classes.helpAddress} ${classes.flex}`}>
                  <Box style={{display: 'flex', alignItems: 'center'}}>Customer Care: <PhoneIcon mr={2} className={classes.icon} />
                   <a href={"tel:" + customerCareNumber} style={{textDecoration: 'none', cursor: 'pointer', color: '#4D4D4D'}}>
                    <span className={`${classes.contactTime} ${classes.noUnderLine}`} >
                    {customerCareNumber}</span></a>
                  </Box>
                </Box> 
                <Typography className={classes.visitTimeFont} mt={2} dangerouslySetInnerHTML={{ __html: customerCareTime }}>
                  {/* Weekdays: <span className={classes.timing}>8:00 am to 11:00 pm (EST)</span><br /> */}
                  {/* Sat: <span className={classes.timing}>8:00 am to 11:00 pm (EST)</span> */}
                </Typography>
              </Box> }
              { lendingNumber &&
              <Box mt={1}  className={classes.separate}>
                <Box mt={1}>
                  <Box className={`${classes.helpAddress} ${classes.flex}`}>
                    <Box style={{display: 'flex', alignItems: 'center'}}>Buy / Refinance: <PhoneIcon mr={2} className={classes.icon} />
                       <a href={"tel:" + lendingNumber} style={{textDecoration: 'none', cursor: 'pointer', color: '#4D4D4D'}}>
                       <span className={`${classes.contactTime} ${classes.noUnderLine}`} >
                      {lendingNumber}</span></a>
                    </Box>
                  </Box> 
                  <Typography className={classes.visitTimeFont} mt={2} dangerouslySetInnerHTML={{ __html: lendingNumberTime }}>
                    {/* Weekdays: <span className={classes.timing}>9:30 am to 6:30 pm (EST)</span><br /> */}
                  </Typography>
                </Box>  
              </Box> }
           </Box>

          </Grid>
        </Paper>
      </Box>
    </div>
  );
}
