import React from 'react';
import { useStyles } from './styles';
import { Box, Button } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setPaymentOption } from '../../actions';

export const RightCard = (props) => {

  const { bannerBody, bannerImageUrl, bannerTitle,
    buttonColor, buttonText, buttonURL, titleBgColor } = props.banner;

  const classes = useStyles();
  const dispatch = useDispatch()
  const history = useHistory();
  
  const handleAutoPayNavigation = () => {
    if (props.banner.bannerName.toUpperCase() === 'AUTOPAY') {
      dispatch(setPaymentOption('MANAGEAUTOPAY'));
      history.push(buttonURL)
    } else {
      history.push(buttonURL)
    }
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.header} style={{ backgroundColor: titleBgColor }}>
        <h1 className={classes.title}>{bannerTitle}</h1>
      </Box>
      <Box className={classes.body} >
        <img className={classes.logo} src={bannerImageUrl} alt='banner logo' />
        <Box className={classes.bodyTextContainer}>
          <div className={classes.bodyText} dangerouslySetInnerHTML={{ __html: bannerBody }}></div>
        </Box>

      </Box>
      <Box className={classes.footer}>
        <Button onClick={() => handleAutoPayNavigation()} className={classes.btn} style={{ backgroundColor: buttonColor }}>{buttonText}</Button>
      </Box>
    </Box>
  )
}
