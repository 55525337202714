import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
  mainHeading: {
    padding: '0.2em 3.5em 0.3em',
    fontWeight: '700',
    fontSize: '2.5em',
    color: '#727272',
    [theme.breakpoints.down('sm')]: {
      padding: '1.5em 1.2em 0 2em',
      fontSize: '2em',
      fontFamily: 'Montserrat',
    },
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    fontFamily: 'Montserrat',
    '& > *': {
      margin: theme.spacing(1),
    },
    '&:last-child': {
      paddingBottom: '4em'
    },
    width: "100%",
    maxWidth: "123em",
    padding: "2.4em 1.5em 1em 1.5em",
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      padding: "2.4em 1.5em 1em 1.5em",
    },
  },

  Margin0: {
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  }
}));