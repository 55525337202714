import React, { useEffect, useState } from 'react';
import {
  Box, TextField, FormControl,
  FormLabel, RadioGroup, FormControlLabel, Radio, Typography
} from '@material-ui/core';
import { NextPrevButton } from '../NextPrevButton'
import { SaveAndExitPopup } from '../SaveAndExitPopup';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';
import { checkDigitOnly, checkDigitWithDecimal } from '../../../helpers';


export const SectionF = (props) => {
  const { parentStyle } =props
  const classes = useStyles();
  const history = useHistory();
  const [isUpdated, setIsUpdated] = useState(false)
  const [fFormData, setFFormData] = useState([
    {
      propertyAddress: "",
      loanNumber: "",
      servicerName1: "",
      mortgageBalance1: "",
      servicerName2: "",
      mortgageBalance2: "",
      propertyType: ""
    },
  ])

  const nullCheck=(str)=>str?str:''

  useEffect(()=>{
    if(props.data){
      const {property} = props.data
      if(Array.isArray(property)){
        setFFormData(property)
        setIsUpdated(true)
      } else {
        setFFormData([property])
        setIsUpdated(true)
      }      
    }
  },[props.data])

 
  useEffect(()=>{
    if(isUpdated){
      let formErrors = formValidationSectionF()
      setFormErrorMap(formErrors)
    }
  }, [isUpdated])



  const handleChange = (event) => {
    let item = event.target.name.split("_")
    let amountFieldList = ["mortgageBalance1","mortgageBalance2" ]
   
    let newFormData = [...fFormData]
    let oldObj ={...newFormData[item[1]]}

    if(item[0]==="loanNumber"){
      if(checkDigitOnly(event.target.value)===false){
        newFormData[item[1]] = oldObj   
        setFFormData(newFormData)
        return false
      }
    }

    if(amountFieldList.indexOf(item[0])>-1){
      if(checkDigitWithDecimal(event.target.value)===false){
        newFormData[item[1]] = oldObj   
        setFFormData(newFormData)
        return false
      }
    }

    
    newFormData[item[1]][item[0]] = event.target.value   
    setFFormData(newFormData)
  }

  const addMoreRow = () => {
    let addObj = {
      propertyAddress: "",
      loanNumber: "",
      servicerName1: "",
      mortgageBalance1: "",
      servicerName2: "",
      mortgageBalance2: "",
      propertyType: ""
    }
    setFFormData([...fFormData, addObj])
  }
  const removeRow=()=>{
    let newArray = [...fFormData]
    let newArrayLen = newArray.length
    if(newArrayLen>1){
      newArray.splice(newArrayLen-1, 1)
    }   
    setFFormData(newArray)
  }
  const handleSave = (e) => {

    let formErrors = formValidationSectionF()
    let formErrorLength = Object.keys(formErrors).length
    let sectionCompleteStatus = formErrorLength>0? "incomplete" : "complete"
    props.status({id:5, sectionName:"section_F", status:sectionCompleteStatus})

    const data = {
      "section_F": {
        "property":fFormData,
        "sectionStatus":{
          "status":sectionCompleteStatus,
          "sectionName":"Section F:Property"
          },
      }
    }
    console.log("===Section F data====", data)
    props.saveFromParent(data)
  }
  const saveAndExitAction = (action) => {
    if (action === "leave") {
      handleSave()
      history.push('/Dashboard')
    }
  }
  /*Error handler*/
const [formErrorMap, setFormErrorMap] = useState([])
const isEmpty=(str)=>str?false:true
  
const formValidationSectionF = ()=>{ 
  const formErrorMessage={}

  const {propertyAddress,loanNumber,servicerName1,mortgageBalance1,propertyType} = fFormData[0]

  if(isEmpty(propertyAddress)){
    formErrorMessage["propertyAddress"]="This is required field"
  }
  if(isEmpty(loanNumber)){
    formErrorMessage["loanNumber"]="This is required field"
  }
  if(isEmpty(servicerName1)){
    formErrorMessage["servicerName1"]="This is required field"
  }
  if(isEmpty(mortgageBalance1)){
    formErrorMessage["mortgageBalance1"]="This is required field"
  }
  if(isEmpty(propertyType)){
    formErrorMessage["propertyType"]="This is required field"
  }

  console.log("===form error F===",formErrorMessage)
  return formErrorMessage;
}

const errorHandler = (field) => {
  if (formErrorMap[field]) {
    let message = formErrorMap[field]==="invalid"?"Invalid Data, Please check the value" :"*"
    return message
  } else {
    return false
  }
}
/*Error handler end*/
  
  return (
    <Box className={classes.root}>
      <Box style={{ marginTop: "-85px" }}>
        <SaveAndExitPopup actionCallBack={saveAndExitAction}></SaveAndExitPopup>
      </Box>
      <Box height="90%" style={{ paddingTop: "25px" }}>

      {fFormData.map((formset, i) => (
        <Box key={i} className={classes.inputBox}>
          <h1 className={classes.title}>Property#{i + 1} </h1>
          <Box className={classes.flexBox}>
            <TextField
              size="small"
              error={i===0?errorHandler('propertyAddress')!==false:false}
              helperText={i===0?errorHandler('propertyAddress'):''}
              label="Property Address"
              color='secondary'
              variant="outlined"
              name={`propertyAddress_${i}`}
              value={nullCheck(formset.propertyAddress)}
              onChange={handleChange}
              className={parentStyle.inputField}
              InputProps={{
                classes: { root: parentStyle.inputText }
              }}
            />
            <TextField
            size="small"
              error={i===0?errorHandler('loanNumber')!==false:false}
              helperText={i===0?errorHandler('loanNumber'):''}
              name={`loanNumber_${i}`}
              value={nullCheck(formset.loanNumber)}
              onChange={handleChange}
              label="Loan Number"
              color='secondary'
              variant="outlined"
              className={parentStyle.inputField}
              InputProps={{
                classes: { root: parentStyle.inputText }
              }}
            />
          </Box>
          <Box className={classes.flexBox}>
            <TextField
            size="small"
              error={i===0?errorHandler('servicerName1')!==false:false}
              helperText={i===0?errorHandler('servicerName1'):''}
              name={`servicerName1_${i}`}
              value={nullCheck(formset.servicerName1)}
              onChange={handleChange}
              label="First Mortgage Servicer Name"
              color='secondary'
              variant="outlined"
              className={parentStyle.inputField}
              InputProps={{
                classes: { root: parentStyle.inputText }
              }}
            />
            <TextField
              size="small"
              error={i===0?errorHandler('mortgageBalance1')!==false:false}
              helperText={i===0?errorHandler('mortgageBalance1'):''}
              name={`mortgageBalance1_${i}`}
              value={nullCheck(formset.mortgageBalance1)}
              onChange={handleChange}
              label="First Mortgage Balance $"
              color='secondary'
              variant="outlined"
              className={parentStyle.inputField}
              InputProps={{
                classes: { root: parentStyle.inputText }
              }}
            />
          </Box>
          <Box className={classes.flexBox}>
            <TextField
              size="small"
              name={`servicerName2_${i}`}
              value={nullCheck(formset.servicerName2)}
              onChange={handleChange}
              label="Second Mortgage Servicer Name"
              color='secondary'
              variant="outlined"
              className={parentStyle.inputField}
              InputProps={{
                classes: { root: parentStyle.inputText }
              }}
            />
            <TextField
            size="small"
              name={`mortgageBalance2_${i}`}
              value={nullCheck(formset.mortgageBalance2)}
              onChange={handleChange}
              label="Second Mortgage Balance $"
              color='secondary'
              variant="outlined"
              className={parentStyle.inputField}
              InputProps={{
                classes: { root: parentStyle.inputText }
              }}
            />
          </Box>

          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend" className={parentStyle.formLabel}>Property is
            {i===0 && <span className={parentStyle.errorMessage}> {errorHandler('propertyType')}</span>}
            </FormLabel>
            <RadioGroup name={`propertyType_${i}`} value={nullCheck(formset.propertyType)} onChange={handleChange}>
              <FormControlLabel className={parentStyle.labelStyle} value="Vacant" control={<Radio />} label="Vacant" />
              <FormControlLabel className={parentStyle.labelStyle} value="2nd or Seasonal Home" control={<Radio />} label="2nd or Seasonal Home" />
              <FormControlLabel className={parentStyle.labelStyle} value="Rented" control={<Radio />} label="Rented" />
            </RadioGroup>
          </FormControl>
        </Box>))}

      {/* <Box py={2}>
        <Typography className={classes.addMore} onClick={() => addMoreRow()} >+Add New Property</Typography>
      </Box> */}
      <Box py={3}>
              <span className={classes.addMore} onClick={() => addMoreRow()} >+Add New Property</span>
              {fFormData.length>1 && <span className={classes.removeBtn} onClick={() => removeRow()} >- Remove Property</span>}
            </Box>


      </Box>
      <NextPrevButton
        handleNextCallback={handleSave}
        handlePrevCallBack={props.previous}
        activeStepIndex={props.activeSection}></NextPrevButton>
    </Box >
  )
}