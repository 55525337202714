import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 620,
    borderRadius: 12,
    backgroundColor: 'white',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    overflowY: 'auto'
  },

  dialogStyle: {
    maxWidth: '85%',
    borderRadius: '12px !important',
    maxHeight: "90%",
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '20px',
        margin: '50px 15px'
      },
  },

  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "400",
    textAlign: 'center',
    margin: 14,
  },

  cardBody: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '12px 2em',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  cardBody2: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '6px 28px',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  statusTitle: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '18px',
    fontWeight: "700",
    padding: '3px 2px 2px 3px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px'
    }
  },

  statusBody: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "bold",
  },

  linkStyle: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    fontWeight: "600",
    marginTop: 5,
  },

  btn: {
    width: 160,
    height: '32px',
    fontFamily: "Montserrat",
    fontSize: '1.5em',
    fontWeight: "600",
    margin: "5px",
    marginBottom: "10px",
    [theme.breakpoints.down('sm')]: {
        width: 80
    }
  },

  closeIcon: {
    position: 'absolute',
    right: -6,
    paddingTop: 4,
    [theme.breakpoints.down('sm')]: {
        right: 10
    }
  },

  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },
  
  inputStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600"
  },

  labelStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "400",
  },

  cardFooter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    justifyContent: 'center',
    backgroundColor: "#fff",
    padding: '8px 76px 0',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      padding: '8px 64px 0'
    },
  },

  footerItem: {
    marginBottom: 15,
    maxWidth: 175,
  },

  boxMargin: {
    marginLeft: 75
  },

  paragraph: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: "500",
    marginBottom: 12,
    textAlign: 'center',
    marginTop: 10,
    paddig: 4
 },

 requestId: {
  color: "#198100",
  fontFamily: "Montserrat",
  fontSize: 14,
  fontWeight: "600",
  marginBottom: 12,
  textAlign: 'center',
  marginTop: 14,
  paddig: 4
 },

 proceed: {
  color: "#202020",
  fontFamily: "Montserrat",
  fontSize: 15,
  fontWeight: "700",
  marginBottom: 8,
  marginTop: 0,
  textAlign: 'center',
},


cardContainer: {
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',
  marginTop: 30,
},

card1: {
  maxWidth: 200,
  padding: '10px',
  borderRadius: 12,
  backgroundColor: "#F4F4F4",
  border: '1px solid #EBEBEB',
  boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
  marginBottom: 20,
  // display: 'flex',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    width: '100%',
    padding: '15px',
  },
  [theme.breakpoints.down('sm')]: {
    // flexWrap: 'wrap',
  },
},

card2: {
  maxWidth: 200,
  // padding: '20px 20px',
  padding: '10px',
  borderRadius: 12,
  border: '1px solid #EDB36A',
  backgroundColor: "#FBF0E1",
  boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
  marginBottom: 20,
  // display: 'flex',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    width: '100%',
    padding: '15px',
  },
  [theme.breakpoints.down('sm')]: {
    // flexWrap: 'wrap',
  },
},

cardIcon: {
  width: 28,
  height: 22,
  [theme.breakpoints.down('sm')]: {
    marginBottom: 10,
  },
},

cardContent: {
  display: 'flex'
},

cardTitle: {
  color: "#000000",
  fontFamily: "Montserrat",
  fontSize: "12px",
  fontWeight: "600",
  marginBottom: '20px',
  letterSpacing: '0.15px',
  linHheight: '24px',
},

cardText: {
  color: "#2B2D30",
  fontFamily: "Montserrat",
  letterSpacing: '0.5px',
  lineHeight: '20px',
  fontSize: "12px",
  fontWeight: "500",
  [theme.breakpoints.down('sm')]: {
    marginBottom: 60,
  },
},

btnContainer: {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'absolute',
  bottom: '24px',
  right: '23px',
  textAlign: 'right',
  [theme.breakpoints.down('sm')]: {
    right: '12px',
    position: 'relative',
  },
},

cardBtn: {
  color: theme.palette.primary.contrastText,
  fontFamily: "Montserrat",
  fontSize: "16px",
  fontWeight: "600",
  width: '320px',
  letterSpacing: '1.43px',
  lineHeight: '16px',
  height: '40px',
},

linkBtn: {
  cursor: 'pointer',
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  textDecoration: 'none',
  textAlign: 'center',
  fontFamily: "Montserrat",
  fontSize: "16px",
  fontWeight: "600",
  width: '320px',
  letterSpacing: '1.43px',
  lineHeight: '40px',
  height: '40px',
  borderRadius: '4px',
  borderSizing: 'border-box',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  },
},

link: {
  color: "#25ABC7",
  fontFamily: "Montserrat",
  fontSize: "16px",
  fontWeight: "600",
  marginTop: 14,
},

disabledLink: {
  color: "#7B8390",
  fontFamily: "Montserrat",
  fontSize: "16px",
  fontWeight: "600",
  letterSpacing: '0.5px',
  textDecoration: 'none',
},

activeLink: {
  color: theme.palette.primary.main,
  fontFamily: "Montserrat",
  fontSize: "16px",
  fontWeight: "600",
  textDecoration: 'none',
},

accFinancialContainer: {
  // display: 'flex',
  // justifyContent: 'space-between',
  // flexWrap: 'wrap'
},

accFinancialImgContainer: {
  width: '12%',
  float: 'left',
  height: '200px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    float: 'none',
    height: 'auto',
    textAlign: 'center',
  },

}

}));

export const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "10px"
  },
  head: {
    backgroundColor: "#535D6D",
    color: "#ffffff",
    fontSize: "14px",
    fontWeight: "bold",
    letterSpacing: "0.36px",
    fontFamily: "Montserrat",
    borderRight: "1px solid #ffffff",
    verticalAlign: "top",
    padding: "10px"
  },
  body: {
    fontSize: "14px",
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Montserrat"
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);