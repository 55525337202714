import { makeStyles } from '@material-ui/core/styles';
import signinIcon from '../../assets/SignIn/SignIn.svg';

export const useStyles = makeStyles((theme) => ({

  mainHeading: {
    padding: '0.2em 3.5em 0.3em',
    fontWeight: '700',
    fontSize: '2.5em',
    color: '#727272',
    [theme.breakpoints.down('sm')]: {
      padding: '1.5em 1.2em 0 2em',
      fontSize: '2em',
      fontFamily: 'Montserrat',
    },
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '2.4em 1.6em 6.6em',
    fontFamily: 'Montserrat',
    width: '100%',
    maxWidth: '122em',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: '3em 1.6em',
    },
  },

  formControl: {
    minWidth: 140,
  },

  pageBg: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#ccc',
    backgroundImage: `url(${signinIcon})`,
    backgroundPosition: "bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'contain',
  },

  mainContainer: {
    background: 'rgb(255, 255, 255)',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 14px 7px, rgba(0, 0, 0, 0.2) 0px 1px 3px 0px',
    position: 'relative',
    borderRadius: '12px',
    marginBottom: '32px',
    width: '100%'
  },

  pageHeading: {
    color: '#1A4664',
    fontFamily: 'Montserrat',
    fontSize: '22px',
    fontWeight: '700',
    letterSpacing: '0.18px',
    lineHeight: '24px',
    marginBottom: 25,
    marginTop: 24
  },

  sectionTitle: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: '400',
    letterSpacing: '0.15px',
    lineHeight: '24px',
    marginBottom: 25,
    marginTop: 4,
  },

  listTitle: {
    color: '#0F355C',
  },

  subTitle: {
    color: '#363636',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: '600',
    letterSpacing: '0.47px',
    lineHeight: '22px',
    margin: '30px 0 23px 0'
  },

  topFormText: {
    margin: '20px 0'
  },

  bobyText: {
    color: '#535D6D',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: '0.36px',
    lineHeight: '19px',
  },

  rightMenuContainer: {
    display: 'flex',
    textAlign: 'right',
    position: 'absolute',
    top: '20px',
    right: '35px',
    alignItems: 'center'
  },

  formContainer: {
    padding: '20px 30px 32px 30px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0 20px 0',
    },
    '& .MuiFormLabel-root': {
      // color: '#535D6D',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      // fontWeight: '600',
      letterSpacing: '0.36px',
      lineHeight: '19px',
      paddingRight: '5px'
    },
    '& .MuiFormLabel-root.MuiInputLabel-outlined': {
      color: 'rgba(0,0,0,0.87)',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      // fontWeight: '600',
      letterSpacing: '0.15px',
      lineHeight: '20px',
      paddingRight: '5px',
      // background: '#eaeced',
    },
    '& .MuiFormLabel-root.MuiInputLabel-outlined.Mui-focused': {
      color: theme.palette.primary.main,
      // fontWeight: '500',
    },
    '& .MuiFormLabel-root.MuiInputLabel-outlined.MuiFormLabel-filled': {
      fontWeight: '500',
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      color: 'rgba(0,0,0,0.87)',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      letterSpacing: '0.15px',
      lineHeight: '20px',
    },
  },

  newListThirdPartyBg: {
    backgroundColor: '#fff'
  },

  textField: {
    // color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: '500'
  },

  input: {
    fontWeight: '600'
  },

  textFieldBold: {
    fontWeight: '600'
  },

  formText: {
    color: '#535D6D',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: '600',
    letterSpacing: '0.4px',
    lineHeight: '22px'
  },

  cancelAccButton: {
    fontFamily: 'Montserrat',
    letterSpacing: '0.36px',
    fontWeight: '600',
    marginRight: '25px',
    width: '180px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '28px',
      marginRight: 0,
    },
  },
  addAccButton: {
    color: theme.palette.primary.contrastText,
    width: '270px',
    height: '40px',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    textTransform: 'uppercase',
    fontSize: '18px'
  },
  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  btnFlex: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
  },

  flexBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },

  flex: {
    display: 'flex',
    alignItems: 'center',
  },


  errorMsg: {
    fontSize: '1.4em',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
    marginTop: 5,
    fontFamily: 'Montserrat',
    color: "red",
  },

  successMsg: {
    fontSize: '1.4em',
    fontWeight: 'bold',
    color: 'green',
    textAlign: 'center',
    marginBottom: 10,
    marginTop: 5,
    fontFamily: 'Montserrat',
  },

  validationErr: {
    fontSize: '12px',
    fontWeight: '500',
    top: 3,
    left: 0,
    fontFamily: 'Montserrat',
    color: "#B00020",
  },

  loaderRoot: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  rowMargin: {
    marginBottom: 40,
  },

  boxPadding: {
    padding: '32px 72px 32px 72px',
    [theme.breakpoints.down('sm')]: {
      padding: '32px 16px 32px 16px',
    },
  },

  lightBox: {
    borderRadius: '9px',
    backgroundColor: '#EAECED',
    padding: '30px 35px',
    margin: '30px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '30px 12px',
    },
  },

  upperText: {
    color: 'rgba(0,0,0,0.74)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: '0.5px',
    lineHeight: '20px',
  },

  newRequestText: {
    color: 'rgba(0,0,0,0.74)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: '0.5px',
    lineHeight: '20px',
  },

  expireDateText: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: '500',
    letterSpacing: '0.15px',
    lineHeight: '24px',
    paddingBottom: '12px'
  },

  newRequestBoldText: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: 'bold',
    letterSpacing: '0.15px',
    lineHeight: '24px',
    marginTop: '12px'
  },

  belowText: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: 'bold',
    letterSpacing: '0.15px',
    lineHeight: '24px',
    marginTop: '12px'
  },

  helperText: {
    color: 'rgba(0,0,0,0.6)',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: '500',
    letterSpacing: '0.4px',
    lineHeight: '15px',
    marginTop: '5px',
    marginBottom: '2px',
    paddingLeft: '12px'
  },

  expiryDateBox: {
    marginTop: '40px',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  },

  dateWidth: {
    width: '45%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  listMargin: {
    margin: '20px 0'
  },

  partyDetails: {
    marginTop: '30px',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  },

  delteBtnContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      '&>div': {
        display: 'flex',
        justifyContent: 'flex-end',
      }
    },
  },

  borderBtn: {
    border: '2px solid' + theme.palette.primary.main,
    boxShadow: "0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)",
    color: '#535D6D !important',
    background: '#fff !important'
  },

  moreDetailsMargin: {
    marginTop: '27px',
  },

  buttonText: {
    color: theme.palette.primary.contrastText,
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: '1.43px',
    lineHeight: '19px',
    textAlign: 'center',
    height: '40px',
    width: '178px',
    marginLeft: '25px',
    [theme.breakpoints.down('md')]: {
      width: 'auto !important',
      marginLeft: '15px',
    },
  },

  btnContainer: {
    [theme.breakpoints.down('md')]: {
      marginTop: '20px',
      display: 'flex',
    },
  },

  section: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  viewMoreLink: {
    color: '#535D6D',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: '600',
    letterSpacing: '0.53px',
    lineHeight: '18px',
    textAlign: 'right',
    textDecoration: 'underline',
    cursor: 'pointer',
    backgroundColor: 'inherit',
    border: 'none',
    paddingLeft: '20px',
    textTransform: 'capitalize',
    paddingTop: '0'
  },

  borderRight: {
    borderRight: '3px solid #585757',
    paddingRight: '20px'
  },

  optionalText: {
    display: 'inline-block',
    color: '#0F355C',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: '500',
    letterSpacing: 0,
    lineHeight: '15px',
    marginLeft: '5px'
  },

  infoIcon: {
    paddingRight: "10px",
    fontSize: '35px',
    color: theme.palette.secondary.contrastText,
  },
  infoText: {
    color: theme.palette.secondary.contrastText,
    fontFamily: "Montserrat",
    fontSize: "16px",
    letterSpacing: "0.36px",
    lineHeight: "24px",
  },

  loanInfoIcon: {
    color: theme.palette.secondary.contrastText,
    fontFamily: 'Montserrat',
    fontSize: '28px',
    marginRight: '30px'
  },

  loanInfoTitleIcon: {
    fontFamily: 'Montserrat',
    fontSize: '50px',
    marginRight: '14px'
  },

  infoStrip: {
    backgroundColor: "#1A4664",
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 14px 7px, rgba(0, 0, 0, 0.2) 0px 1px 3px 0px',
    width: '100%',
    padding: '3px 6em 3px 1em',
    borderRadius: '12px',
    margin: 0,
    marginBottom: '22px',
    [theme.breakpoints.down('sm')]: {
      padding: '3px 2em 3px 2em',
    },
  },

  blockMobile: {
    display: 'block'
  },

  title: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '400',
    letterSpacing: '0.15px',
    lineHeight: '24px',
    marginBottom: 25,
    marginTop: 4,
  },

}));