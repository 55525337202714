import React, { useEffect, useState, useRef } from 'react';
import {
  Box, Button, CircularProgress, Grid,
  TextField, Radio,
  RadioGroup, FormControlLabel, FormGroup, Checkbox, TextareaAutosize
} from "@material-ui/core";

import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { setEditProfileComponent,getBorrowerDetails, getDisasterAssistanceStatus, saveDisasterAssistance, getContent } from '../../actions';
import { SnackBar, DisasterConfirmAlert } from '../../components';
import CustomBreadcrum from '../Breadcrum';
import { DisasterAssistanceList } from './DisasterAssistanceList';
import { Link, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { decrypt, encrypt } from '../../helpers/encrypt';
import { checkDigitOnly, scrollTop, validateEmail, renderDefaultMsg, getAppSource, getUserType, isAdmin } from '../../helpers'
import ReactToPrint from 'react-to-print';
import PrintIcon from '@material-ui/icons/Print';

import disaster from '../../assets/FinancialHardship/natural-disaster.png';

export const DisasterAssistance = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const componentRef = useRef();
  const selectedAccount = useSelector(state => state.selectedAccount);
  const mytheme = useSelector(state => state.theme);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [covidDataLoading, setCovidDataLoading] = useState(false);
  const [covidError, setCovidError] = React.useState('');
  const [covidDataLoadError, setCovidDataLoadError] = useState('')
  const [covidConfirmAlert, setCovidConfirmAlert] = useState(false);
  const [idAddDeferementSubmit, setIdAddDeferementSubmit] = useState(false);
  const [successSnackBar, setSuccessSnackBar] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [isQAgree, setIsQAgree] = useState(false);
  const [isQAgreeError, setIsQAgreeError] = useState('');

  // validation 
  const [errors, setErrors] = React.useState({});
  const [touched, setTouched] = React.useState({});
  const [mobileError, setMobileError] = useState('');

  const [isSubmit, setIsSubmit] = useState(false);
  const [covidConfirmAlertProgress, setCovidConfirmAlertProgress] = useState(false);
  const [displayStatus, setDisplayForm] = useState(false);
  const [displaySubmitStatus, setDisplaySubmitStatus] = useState(false);

  const [covidPostData, setCovidPostData] = useState({
    mailingAddress: '',
    primaryAddress2: '',
    propertyAddress: '',
    primaryName: '',
    primaryAddress1: '',
    emailID: '',
    mobileNumber: '',
    renovationDate: new Date(),
    monthlyMortgagePaymentDate: new Date(),
    owner: '',
    rental: '',
    vacant: ''
  });

  const [citStatus, setCitStatus] = useState({
    status: '',
    forbearanceStatus: ''
  });

  const [borrowerInfo, setBorowerInfo] = useState({
    borowerName: '',
    borowerEmail: '',
    primPhone: '',
    otherPhone: '',
    primPhoneType: 'C',
    otherPhoneType: '',
  });

  const resetCovidForm = () => {
    setSuccessMsg('');
    setSuccessSnackBar(false);
    setIsQAgree(false);
    setIsQAgreeError('');
    setCovidError('');
    setErrors({});
    setTouched({});
    setMobileError('');
    scrollTop();
    setBorowerInfo({
      borowerName: '',
      borowerEmail: '',
      primPhone: '',
      otherPhone: '',
      primPhoneType: 'C',
      otherPhoneType: '',
    });
    setCovidPostData({
      mailingAddress: '',
      primaryAddress2: '',
      propertyAddress: '',
      primaryName: '',
      primaryAddress1: '',
      emailID: '',
      mobileNumber: '',
      owner: '',
      rental: '',
      vacant: ''
    });
  }
  const [content, setContent] = useState([])

  useEffect(() => {
    if (Cookies.get("displayNaturalDisasterLink") != 'true') {
       history.push({pathname: '/Dashboard'});
    }
  }, [])

  useEffect(() => {
    if (selectedAccount) {
      resetCovidForm();
      getRequestStatus();
    }
    scrollTop();
  }, [selectedAccount])

  const getRequestStatus = async () => {
    setCovidDataLoading(true);
    setCovidDataLoadError('');
    const response = await getDisasterAssistanceStatus(selectedAccount);
    if (response && response.responseData.lstResponseStatus && response.responseData.lstResponseStatus.length && response.responseData.lstResponseStatus[0].statusCode === '0') {
      setCovidDataLoading(false);
      setCovidDataLoadError('');
      setCitStatus({ status: response.responseData.responseData.citStatus, forbearanceStatus :  response.responseData.responseData.forbearanceStatus})
     if ((response.responseData.responseData.citStatus !== 'A') && (response.responseData.responseData.isRequestExist == false) && (response.responseData.responseData.forbearanceStatus == null || response.responseData.responseData.forbearanceStatus == '')) {
    
      getCovidHardshipData();
      setDisplayForm(true)
      setDisplaySubmitStatus(false)
     } else {
      setDisplayForm(false)
      setDisplaySubmitStatus(true)
     }
    } else if (response && response.responseData.lstResponseStatus && response.responseData.lstResponseStatus.length && response.responseData.lstResponseStatus[0].statusCode !== '0') {
      setCovidDataLoadError(response.responseData.lstResponseStatus[0].statusDesc);
      setCovidDataLoading(false);
    } else {
      setCovidDataLoadError(renderDefaultMsg('400'));
      setCovidDataLoading(false);
    }
    setCovidDataLoading(false);
    scrollTop();
  }

  const getCovidHardshipData = async () => {
    setCovidDataLoadError('');
    const response = await getBorrowerDetails(selectedAccount);
    if (response && response.responseData.lstResponseStatus && response.responseData.lstResponseStatus.length && response.responseData.lstResponseStatus[0].statusCode === '0') {
      setCovidDataLoading(false);

      const data = response.responseData.responseData.borrowerInfo;

      setCovidPostData({
       
        propertyAddress: response.responseData.responseData.propertyAddress,
        primaryName: data.primaryName !== null ? data.primaryName : '',
        mailingAddress: data.primaryAddress1 !== null ? data.primaryAddress1 : '' +
        data.primaryAddress2 !== null ? data.primaryAddress2 : '',
        primaryAddress1: data.primaryAddress1 !== null ? data.primaryAddress1 : '',
        primaryAddress2: data.primaryAddress2 !== null ? data.primaryAddress2 : '',
        emailID: data.emailID !== null && decrypt(data.emailID),
        mobileNumber: data.mobileNumber !== null ? decrypt(data.mobileNumber) : ''
      })
      setCovidDataLoadError('');
     
    } else if (response && response.responseData.lstResponseStatus && response.responseData.lstResponseStatus.length && response.responseData.lstResponseStatus[0].statusCode !== '0') {
      setCovidDataLoadError(response.responseData.lstResponseStatus[0].statusDesc);
      setCovidDataLoading(false);
    } else {
      setCovidDataLoadError(renderDefaultMsg('400'));
      setCovidDataLoading(false);
    }
    setCovidDataLoading(false);
    scrollTop();
  };

  const isQRadioHandler = (event) => {
    if (event.target.checked === true) {
      setIsQAgree(event.target.checked);
      setIsQAgreeError('');
    } else {
      setIsQAgree(event.target.checked);
    }
  };

  const handleSnackBarClose = () => {
    setSuccessSnackBar(false);
  };

  const toggleCovidAlertBox = () => {
    setCovidConfirmAlert(!covidConfirmAlert);
    setIsSubmit(false);
    setCovidConfirmAlertProgress(false);
  }

  const textChangeHandler = (event) => {

    if ((event.target.name === 'mobileNumber') || (event.target.name === 'alternatePhoneNumber')) {
      if (checkDigitOnly(event.target.value) && event.target.value.length <= 10) {
        setCovidPostData({ ...covidPostData, [event.target.name]: event.target.value })
      }
    } else if (event.target.name === 'emailID') {
      setCovidPostData({ ...covidPostData, [event.target.name]: event.target.value.slice(0, 110) })
      setTouched({
        ...touched,
        [event.target.name]: true
      });
    } else {
      setCovidPostData({ ...covidPostData, [event.target.name]: event.target.value })
    }
  }

  const handleBlur = event => {
    const { name, value } = event.target;
    setErrors({
      ...errors,
      ...(errors && { [name]: touched[name] && (name === 'zip' ? '' : (name === 'emailID' && validateEmail(value))) })
    });
  };

  const verifyPhone = (event) => {
    if (event.target.value.length !== 0) {
      if (event.target.value.length === 10) {
        setMobileError('');
      } else {
        setMobileError('Mobile number is invalid.');
      }
    } else {
      setMobileError('');
    }
  }

  const checkErrorObject = () => {
    const errArr = Object.values(errors).filter((item, i) => {
      return item !== null;
    })
    return errArr.length;
  }


  const borowerRadioChangeHandler = (event) => {
    setBorowerInfo({ ...borrowerInfo, [event.target.name]: event.target.value })
  };
  const textChangePostDataHandler = (name, val) => {
    setCovidPostData({
      ...covidPostData,
      [name]: val
    })
  }

  const checkboxChangePostDataHandler = (event) => {
    setCovidPostData({
      ...covidPostData,
      [event.target.name]: event.target.checked
    })
  }


  const covidHardshipDescHandler = (event) => {
    if (event.target.name === 'percentComplete') {
      if (checkDigitOnly(event.target.value) && event.target.value.length <= 2) {
        setCovidPostData({
          ...covidPostData,
          [event.target.name]: event.target.value
        })
        return;
      }
    } else if (event.target.name === 'impactedCov19') {
      if (event.target.value.length <= 60) {
        setCovidPostData({
          ...covidPostData,
          [event.target.name]: event.target.value
        })
        return;
      }
    } else {
      setCovidPostData({
        ...covidPostData,
        [event.target.name]: event.target.value
      })
    }

  };

  const submitQualifiedCovidData = () => {
    if (!isQAgree) {
      setIsQAgreeError('Please check the option I Agree to proceed.');
    } else {
      setIsQAgreeError('');
      setIsSubmit(true);
      setCovidConfirmAlert(true);
    }
  }

  const confirmCovidSubmit = async (type = null) => {
  
    setIsSubmit(true);
    setCovidError('');
    setSuccessMsg('');
    setCovidConfirmAlertProgress(true);
    const data = {
      "accountNumber": selectedAccount,
      "borrowerName": covidPostData.primaryName,
      "email": covidPostData.emailID ? encrypt(covidPostData.emailID) : '',
      "phoneNumber": covidPostData.mobileNumber ? encrypt(covidPostData.mobileNumber) : '',
      "phoneNumberType": borrowerInfo.primPhoneType ? borrowerInfo.primPhoneType : 'C',
      "alternatePhoneNumber": covidPostData.alternatePhoneNumber ? encrypt(covidPostData.alternatePhoneNumber) : '',
      "alternatePhoneNumberType": borrowerInfo.otherPhoneType ? borrowerInfo.otherPhoneType : 'C',
      "propertyAddress": covidPostData.propertyAddress,
      "mailingAddress": covidPostData.mailingAddress,
      "propertyIsCurrently": covidPostData.accountType ? covidPostData.accountType : '',
      "propertyIs": (covidPostData.owner ? 'O, ' : '') + (covidPostData.rental ? 'R, ' : '') + (covidPostData.vacant ? 'V' : ''),
      "impacted250": covidPostData.impacted250 ? covidPostData.impacted250 : '',
      "employmentImpacted": covidPostData.workShutdown ? covidPostData.workShutdown : '',
      "anticipateDuration": covidPostData.payingWhileShutdown ? covidPostData.payingWhileShutdown : '',
      "isPropertyDamaged": covidPostData.unemploymentMeantime ? covidPostData.unemploymentMeantime : '',
      "month3DisasterForbearance": covidPostData.setGoBack ? covidPostData.setGoBack : '',
      "briefDescription": covidPostData.assistanceRequested ? covidPostData.assistanceRequested : '',
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
     
  }
  console.log(data)

    const response = await saveDisasterAssistance(data);
    if (response && response.responseData.lstResponseStatus && response.responseData.lstResponseStatus[0].statusCode === '0') {
      setCitStatus({ status: 'A', forbearanceStatus : ''})
      setIsSubmit(false);
      setCovidError('');
      setCovidConfirmAlertProgress(false);
      setCovidConfirmAlert(false);
      // setSuccessMsg('Form successfully submitted!');
      setSuccessSnackBar(true);
      setDisplaySubmitStatus(true)
      scrollTop()
    } else if (response && response.responseData.lstResponseStatus && response.responseData.lstResponseStatus[0].statusCode !== '0') {
      setIsSubmit(false);
      if (type === 'deferementSubmit') {
        setIdAddDeferementSubmit(false);
      } else {
        setSubmitStatus(false);
      }
      setCovidConfirmAlertProgress(false);
      setCovidError(response.responseData.lstResponseStatus[0].statusDesc);
      setCovidConfirmAlert(false);
    } else {
      setIsSubmit(false);
      setCovidConfirmAlertProgress(true);
      setCovidConfirmAlert(false);
      setCovidError(renderDefaultMsg('400'));
    }
  }

  const routePage = async (link) => {
    await dispatch(setEditProfileComponent(true));
    history.push(link);
  }

  const cancelForm = () => {
    history.push('/FinancialHardship');
  }
 
  useEffect(() => {
    getContentData();
  }, []);

  const getContentData = async () => {
    const res = await dispatch(getContent('PC022'));
    if (res) {
      setContent(res);
      scrollTop();
    }
  }

  if (covidDataLoadError !== '') {
    return (
      <Box className={classes.loaderContainer}>
        <p className={classes.dataLoadError}>{covidDataLoadError}</p>
      </Box>
    );
  }

  if (covidDataLoading) {
    return (
      <Box className={classes.loaderContainer}>
        <CircularProgress />
      </Box>
    );
  }

  if (displaySubmitStatus) {
    return (
      <Box style={{ background: '#e8e8e8' }}>
        <Box className={classes.root}>
          <CustomBreadcrum backPage="Financial Hardship" backPageLink="/FinancialHardship" activePage="Disaster Assistance" />
          <h1 className={classes.title}>Hardship Assistance due to a Natural Disaster</h1>
          <Box className={classes.container}>

            <Box className={classes.covidInfoContainer}>
              <Box className={classes.covidStatus}>
                <p className={classes.text}>Your application has already been submitted and is currently being reviewed/processed.</p>
                <p className={classes.text}>No further action is required.</p><br />
                <p className={classes.text}>Your Forbearance status is available below and can be viewed at any time by going to the Loan Details &gt;
                 <Link to="/WorkoutStatus" className={classes.covidLinkStyle}> Workout Status page</Link></p>
                <p className={classes.text}>Forbearance status: {(citStatus.status == 'A' || citStatus.forbearanceStatus == null || citStatus.forbearanceStatus == '') ? 'In-Review' : citStatus.forbearanceStatus}</p>
                <p className={classes.helpText}> Note: It can take up to 24 hours for your request status to be updated.</p>
              </Box>
            </Box>
          </Box>
        </Box>
        {successMsg !== '' && <SnackBar open={successSnackBar} handleClose={handleSnackBarClose} severity="success" message={successMsg} />}
      </Box>
    )
  }

  return (
    <Box className={classes.pageBg}>
      <Box className={classes.root}>
        <CustomBreadcrum backPage="Financial Hardship" backPageLink="/FinancialHardship" activePage="Disaster Assistance" />
        <h1 className={classes.title}>Hardship Assistance due to a Natural Disaster</h1>
        {displayStatus &&
        <Box className={classes.container} ref={componentRef}>
         
          <Box className={classes.covidInfoContainer}>
            
              <h2 className={classes.subTitle}>Have you been impacted by the recent natural disaster?</h2> 

            
              <p className={classes.text}>If your property has been damaged or you have experienced a loss of income due to the impact of the natural disaster, the following option may be available to you.  Please note that this option is subject to lender approval you will be required to meet certain eligibility criteria in order to participate. A brief summary is provided below.  Please refer to our Disaster Relief <Link  to={{
                      pathname: '/HelpDescription/FAQs',
                      state: {
                        tilesName:'DisasterAssistance'
                      }
                    }}
>FAQs</Link> for detailed information on how Disaster Forbearance works before you request a Disaster Forbearance Plan.</p>

           
            { displayStatus && <>
              <DisasterAssistanceList forbearance={'true'} />
              <Box className={classes.forbearanceAppForm}>
                <Box className={`${classes.flexBox} ${classes.marTopBottom20}${classes.flexWrap} ${classes.xsMb10}`}>
                  <img className={classes.covidImg} src={disaster} alt='disaster icon' />
                  <h2 className={classes.forbearanceAppFormTitle}>Disaster Forbearance Application</h2>
                </Box>
                <p className={classes.text}>If you are having mortgage payment challenges related to the recent natural disaster, please compete and submit the application below.  We will contact you within five business days to advise if additional information is needed and to explain the specifics of the Disaster Forbearance Plan if approved.</p>
              </Box>
            </>}
          </Box>
          
        {displayStatus &&
          <Box>
            <Box className={classes.headingContainer}>
              <Box className={`${classes.flexBox} ${classes.justifyCenter} ${classes.flexWrap}`}>
                <h3 className={`${classes.formTitle} ${classes.xsMb10}`}>Borrower Information</h3>
                <Button
                  className={classes.btn}
                  type='submit' size="large" variant="contained" color="primary" onClick={() => routePage('/UserProfile/Profile')}>
                  EDIT PROFILE
                  </Button>
              </Box>
            </Box>
            <Box className={`${classes.formContainer} `}>
              <Box className={`${classes.form} ${classes.formBg}`}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      color="primary"
                      id={`outlined - `}
                      variant="outlined"
                      name="primaryName"
                      type="text"
                      label={<Box>Borrower's Name<span className={classes.required}>*</span></Box>}
                      value={covidPostData.primaryName}
                      onChange={textChangeHandler}
                      InputLabelProps={{
                        classes: { root: classes.textField }
                      }}
                      InputProps={{
                        maxLength: 100,
                        classes: { root: classes.textField, input: classes.textInput },
                      }} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      color='primary'
                      id={`outlined - `}
                      variant="outlined"
                      name="emailID"
                      type="text"
                      label={<Box>Borrower's Email<span className={classes.required}>*</span></Box>}
                      value={covidPostData.emailID}
                      onChange={textChangeHandler}
                      onBlur={handleBlur}
                      InputLabelProps={{
                        classes: { root: classes.textField }
                      }}
                      InputProps={{
                        maxLength: 100,
                        classes: { root: classes.textField, input: classes.textInput }
                      }} />
                    {touched.emailID && <p className={classes.validationErr}>{errors.emailID}</p>}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      color="primary"
                      id={`outlined - `}
                      variant="outlined"
                      type="text"
                      label={<Box>Primary Phone Number<span className={classes.required}>*</span></Box>}
                      name="mobileNumber"
                      value={covidPostData.mobileNumber ? covidPostData.mobileNumber : ''}
                      onChange={textChangeHandler}
                      onBlur={verifyPhone}
                      InputLabelProps={{
                        classes: { root: classes.textField }
                      }}
                      InputProps={{
                        classes: { root: classes.textField, input: classes.textInput },
                        maxLength: 12,
                      }} />
                    {mobileError !== '' && <p className={classes.validationErr}>{mobileError}</p>}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <RadioGroup row className={`${classes.justifyCenter} ${classes.mt10}`}
                      value={borrowerInfo.primPhoneType}
                      onChange={borowerRadioChangeHandler}>
                      <FormControlLabel
                        value="C"
                        name="primPhoneType"
                        checked={borrowerInfo.primPhoneType === 'C' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="Cell"
                        classes={{
                          label: borrowerInfo.primPhoneType === 'C' ? classes.activeRadioLabel : classes.radioLabel
                        }}
                      />
                      <FormControlLabel
                        value="H"
                        name="primPhoneType"
                        checked={borrowerInfo.primPhoneType === 'H' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="Home"
                        classes={{
                          label: borrowerInfo.primPhoneType === 'H' ? classes.activeRadioLabel : classes.radioLabel
                        }}
                      />
                      <FormControlLabel
                        value="W"
                        name="primPhoneType"
                        checked={borrowerInfo.primPhoneType === 'W' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="Work"
                        classes={{
                          label: borrowerInfo.primPhoneType === 'W' ? classes.activeRadioLabel : classes.radioLabel
                        }}
                      />
                      <FormControlLabel
                        value="O"
                        name="primPhoneType"
                        checked={borrowerInfo.primPhoneType === 'O' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="Other"
                        classes={{
                          label: borrowerInfo.primPhoneType === 'O' ? classes.activeRadioLabel : classes.radioLabel
                        }}
                      />
                    </RadioGroup >
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      color="primary"
                      id={`outlined - `}
                      variant="outlined"
                      type="text"
                      label="Alternate Phone Number"
                      name="alternatePhoneNumber"
                      value={covidPostData.alternatePhoneNumber ? covidPostData.alternatePhoneNumber : ''}
                      onChange={textChangeHandler}
                      InputLabelProps={{
                        classes: { root: classes.textField }
                      }}
                      InputProps={{
                        classes: { root: classes.textField, input: classes.textInput },
                        maxLength: 12,
                      }} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <RadioGroup row className={`${classes.justifyCenter} ${classes.mt10}`}
                      value={borrowerInfo.otherPhoneType}
                      onChange={borowerRadioChangeHandler}>
                      <FormControlLabel
                        value="C"
                        name="otherPhoneType"
                        checked={borrowerInfo.otherPhoneType === 'C' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="Cell"
                        classes={{
                          label: borrowerInfo.otherPhoneType === 'C' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                      <FormControlLabel
                        value="H"
                        name="otherPhoneType"
                        checked={borrowerInfo.otherPhoneType === 'H' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="Home"
                        classes={{
                          label: borrowerInfo.otherPhoneType === 'H' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                      <FormControlLabel
                        value="W"
                        name="otherPhoneType"
                        checked={borrowerInfo.otherPhoneType === 'W' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="Work"
                        classes={{
                          label: borrowerInfo.otherPhoneType === 'W' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                      <FormControlLabel
                        value="O"
                        name="otherPhoneType"
                        checked={borrowerInfo.otherPhoneType === 'O' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="Other"
                        classes={{
                          label: borrowerInfo.otherPhoneType === 'O' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                    </RadioGroup >
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box className={classes.headingContainer}>
              <Box className={`${classes.flexBox} ${classes.justifyCenter}`}>
                <h3 className={classes.formTitle}>Property Details</h3>
              </Box>
            </Box>
            <Box className={`${classes.formContainer} `}>
              <Box className={`${classes.form} ${classes.formBg}`}>
                <Grid container >
                  <Grid item xs={12} md={4}>
                    <p className={`${classes.formText} ${classes.xsMb10}`}>Property Address:<span className={classes.required}>*</span></p>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Box className={classes.flexBox}>
                      <p className={classes.formText}>{covidPostData.propertyAddress}</p>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container className={classes.marginFixed}>
                  <Grid item xs={12} md={4}>
                    <p className={`${classes.formText} ${classes.mt20} ${classes.xsMb10}`}>Mailing Address:</p>
                  </Grid>
                  <Grid item xs={9} md={5}>
                    <TextField
                      fullWidth
                      color="primary"
                      id={`outlined - `}
                      variant="outlined"
                      type="text"
                      name="mailingAddress"
                      label="Borrower's Address"
                      value={covidPostData.mailingAddress ? covidPostData.mailingAddress : ''}
                      onChange={(event) => textChangePostDataHandler(event.target.name, event.target.value)}
                      InputLabelProps={{
                        classes: { root: classes.textField }
                      }}
                      InputProps={{
                        classes: { root: classes.textField, input: classes.textInput }
                      }} />
                  </Grid>
                  <Grid item xs={3} >
                    <Button className={classes.editBtn} color="secondary" component={Link} to="/UserProfile/Profile">
                      Edit
                    </Button>
                  </Grid>
                </Grid>

                <Grid container className={classes.marginFixed}>
                  <Grid item xs={12} md={4}>
                    <p className={classes.formText}>The property is currently:<span className={classes.required}>*</span></p>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <RadioGroup className={classes.propertyRadio}
                      value={covidPostData.accountType ? covidPostData.accountType : ''}
                      onChange={(event) => textChangePostDataHandler(event.target.name, event.target.value)}
                    >
                      <FormControlLabel
                        value="P"
                        name="accountType"
                        checked={covidPostData.accountType && covidPostData.accountType === 'P' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="A primary Residence"
                        classes={{
                          label: covidPostData.accountType && covidPostData.accountType === 'P' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                      <FormControlLabel
                        value='S'
                        name="accountType"
                        checked={covidPostData.accountType && covidPostData.accountType === 'S' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="A second Home"
                        classes={{
                          label: covidPostData.accountType && covidPostData.accountType === 'S' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                      <FormControlLabel
                        value='I'
                        name="accountType"
                        checked={covidPostData.accountType && covidPostData.accountType === 'I' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="An investment Property"
                        classes={{
                          label: covidPostData.accountType && covidPostData.accountType === 'I' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                    </RadioGroup >
                  </Grid>
                </Grid>

                <Grid container className={classes.marginFixed}>
                  <Grid item xs={12} md={4}>
                    <p className={classes.formText}>The property is:<span className={classes.required}>*</span></p>
                    <p className={classes.formText}>(select all that apply)	 </p>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <FormGroup row className={classes.propertyRadio}>
                      <FormControlLabel
                        control={<Checkbox
                          checked={covidPostData.owner ? true : false}
                          onChange={(event) => checkboxChangePostDataHandler(event)}
                          name="owner"
                          value="O" />}
                        label="Owner Occupied"
                        classes={{
                          label: covidPostData.owner && covidPostData.owner === 'O' ? classes.activeRadioLabel : classes.radioLabel
                        }}
                      />
                      <FormControlLabel
                        control={<Checkbox
                          checked={covidPostData.rental ? true : false}
                          onChange={(event) => checkboxChangePostDataHandler(event)}
                          name="rental"
                          value="R" />}
                        label="Rental Occupied"
                        classes={{
                          label: covidPostData.rental && covidPostData.rental === 'R' ? classes.activeRadioLabel : classes.radioLabel
                        }}
                      />
                      <FormControlLabel
                        control={<Checkbox
                          checked={covidPostData.vacant ? true : false}
                          onChange={(event) => checkboxChangePostDataHandler(event)}
                          name="vacant"
                          value="V" />}
                        label="Vacant"
                        classes={{
                          label: covidPostData.vacant && covidPostData.vacant === 'V' ? classes.activeRadioLabel : classes.radioLabel
                        }}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box className={`${classes.impectedCovid} `}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Box className={classes.covidQnA}>
                    <h4 className={classes.subHeading}>How have you been impacted by the recent natural disaster?<span className={classes.required}>*</span></h4>

                    <TextareaAutosize
                      style={{ height: '117px', width:  '100%'}}
                      fullWidth
                      color="primary"
                      id={`outlined_impacted250`}
                      variant="outlined"
                      type="text"
                      name="impacted250"
                      maxLength="300"
                      placeholder="Please complete a brief description of the event and the impact to your financial ability to maintain your mortgage payments."
                      value={covidPostData.impacted250 ? covidPostData.impacted250 : ''}
                      onChange={covidHardshipDescHandler}
                      className={classes.textArea}
                      // InputLabelProps={{
                      //   classes: { root: classes.textField }
                      // }}
                      // InputProps={{
                       
                      //   classes: { root: classes.textField250, input: classes.textInput }
                      // }} 
                      />

                    <Box className={classes.covidImpectRadio}>
                      <Grid container className={classes.radioBtnMargin}>
                        <Grid item xs={12} sm={6}>
                          <p className={classes.subText}>Has your employment been impacted?<span className={classes.required}>*</span></p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <RadioGroup row className={`${classes.justifyCenter}`}
                            value={covidPostData.workShutdown ? covidPostData.workShutdown : ''}
                            onChange={covidHardshipDescHandler}>
                            <FormControlLabel
                              value="Y"
                              name="workShutdown"
                              checked={covidPostData.workShutdown === 'Y' ? true : false}
                              control={<Radio classes={{ root: classes.radioStyle }} />}
                              label="Yes"
                              classes={{
                                label: covidPostData.workShutdown === 'Y' ? classes.activeRadioLabel : classes.radioLabel
                              }} />
                            <FormControlLabel
                              value="N"
                              name="workShutdown"
                              checked={covidPostData.workShutdown === 'N' ? true : false}
                              control={<Radio classes={{ root: classes.radioStyle }} />}
                              label="No"
                              classes={{
                                label: covidPostData.workShutdown === 'N' ? classes.activeRadioLabel : classes.radioLabel
                              }} />
                          </RadioGroup >
                        </Grid>
                      </Grid>
                      <Grid container className={classes.radioBtnMargin}>
                        <Grid item xs={12} sm={6}>
                          <p className={classes.subText}>How long do you anticipate your employment to be impacted?<span className={classes.required}>*</span></p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <RadioGroup row className={`${classes.justifyCenter}`}
                            value={covidPostData.payingWhileShutdown ? covidPostData.payingWhileShutdown : ''}
                            onChange={covidHardshipDescHandler}>
                            <FormControlLabel
                              value="1Month"
                              name="payingWhileShutdown"
                              checked={covidPostData.payingWhileShutdown === '1Month' ? true : false}
                              control={<Radio classes={{ root: classes.radioStyle }} />}
                              label="Up to 1 month"
                              classes={{
                                label: covidPostData.payingWhileShutdown === '1Month' ? classes.activeRadioLabel : classes.radioLabel
                              }} />
                            <FormControlLabel
                              value="1-3Month"
                              name="payingWhileShutdown"
                              checked={covidPostData.payingWhileShutdown === '1-3Month' ? true : false}
                              control={<Radio classes={{ root: classes.radioStyle }} />}
                              label="1-3 months"
                              classes={{
                                label: covidPostData.payingWhileShutdown === '1-3Month' ? classes.activeRadioLabel : classes.radioLabel
                              }} />
                               <FormControlLabel
                              value="3-6Month"
                              name="payingWhileShutdown"
                              checked={covidPostData.payingWhileShutdown === '3-6Month' ? true : false}
                              control={<Radio classes={{ root: classes.radioStyle }} />}
                              label="3-6 months"
                              classes={{
                                label: covidPostData.payingWhileShutdown === '3-6Month' ? classes.activeRadioLabel : classes.radioLabel
                              }} />
                               <FormControlLabel
                              value="6Month"
                              name="payingWhileShutdown"
                              checked={covidPostData.payingWhileShutdown === '6Month' ? true : false}
                              control={<Radio classes={{ root: classes.radioStyle }} />}
                              label="6+ months"
                              classes={{
                                label: covidPostData.payingWhileShutdown === '6Month' ? classes.activeRadioLabel : classes.radioLabel
                              }} />
                               <FormControlLabel
                              value="Unsure"
                              name="payingWhileShutdown"
                              checked={covidPostData.payingWhileShutdown === 'Unsure' ? true : false}
                              control={<Radio classes={{ root: classes.radioStyle }} />}
                              label="Unsure"
                              classes={{
                                label: covidPostData.payingWhileShutdown === 'Unsure' ? classes.activeRadioLabel : classes.radioLabel
                              }} />
                          </RadioGroup >
                        </Grid>
                      </Grid>
                      <Grid container className={classes.radioBtnMargin}>
                        <Grid item xs={12} sm={6}>
                          <p className={classes.subText}>Has your property been damaged as a result of the recent natural disaster?<span className={classes.required}>*</span>	</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <RadioGroup row className={`${classes.justifyCenter}`}
                            value={covidPostData.unemploymentMeantime ? covidPostData.unemploymentMeantime : ''}
                            onChange={covidHardshipDescHandler}>
                            <FormControlLabel
                              value="Y"
                              name="unemploymentMeantime"
                              checked={covidPostData.unemploymentMeantime === 'Y' ? true : false}
                              control={<Radio classes={{ root: classes.radioStyle }} />}
                              label="Yes"
                              classes={{
                                label: covidPostData.unemploymentMeantime === 'Y' ? classes.activeRadioLabel : classes.radioLabel
                              }} />
                            <FormControlLabel
                              value="N"
                              name="unemploymentMeantime"
                              checked={covidPostData.unemploymentMeantime === 'N' ? true : false}
                              control={<Radio classes={{ root: classes.radioStyle }} />}
                              label="No"
                              classes={{
                                label: covidPostData.unemploymentMeantime === 'N' ? classes.activeRadioLabel : classes.radioLabel
                              }} />
                          </RadioGroup >
                        </Grid>
                      </Grid>
                      <Grid container className={classes.radioBtnMargin}>
                        <Grid item xs={12} sm={6}>
                          <p className={classes.subText}>Confirm you would like to enter into a 3-month initial Disaster Forbearance Plan (this plan potentially can be shortened or extended by contacting your Single Point of Contact).<span className={classes.required}>*</span></p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <RadioGroup row className={`${classes.justifyCenter}`}
                            value={covidPostData.setGoBack ? covidPostData.setGoBack : ''}
                            onChange={covidHardshipDescHandler}>
                            <FormControlLabel
                              value="Y"
                              name="setGoBack"
                              checked={covidPostData.setGoBack === 'Y' ? true : false}
                              control={<Radio classes={{ root: classes.radioStyle }} />}
                              label="Yes"
                              classes={{
                                label: covidPostData.setGoBack === 'Y' ? classes.activeRadioLabel : classes.radioLabel
                              }} />
                            <FormControlLabel
                              value="N"
                              name="setGoBack"
                              checked={covidPostData.setGoBack === 'N' ? true : false}
                              control={<Radio classes={{ root: classes.radioStyle }} />}
                              label="No"
                              classes={{
                                label: covidPostData.setGoBack === 'N' ? classes.activeRadioLabel : classes.radioLabel
                              }} />
                          </RadioGroup >

                        </Grid>
                       
                      </Grid>
                      { covidPostData.setGoBack === 'N' &&
                        <Grid  item xs={12} sm={12} md={12} container className={classes.radioBtnMargin}>
                        <h4 className={classes.que}>If you selected No, above please provide an explanation of the assistance requested.<span className={classes.required}>*</span></h4>

                          <TextareaAutosize
                            style={{ height: '117px', width: '100%' }}
                            fullWidth
                            maxLength="300"
                            color="primary"
                            id={`outlined_impacted250`}
                            variant="outlined"
                            type="text"
                            name="assistanceRequested"
                            placeholder="Please complete a brief description here."
                            value={covidPostData.assistanceRequested ? covidPostData.assistanceRequested : ''}
                            onChange={covidHardshipDescHandler}
                            className={classes.textArea}
                            // InputLabelProps={{
                            //   classes: { root: classes.textField }
                            // }}
                            // InputProps={{
                            //   classes: { root: classes.textField250, input: classes.textInput }
                            // }} 
                            />
                        </Grid>
                        }

                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box className={classes.agrementContainer}>
                    <Box className={classes.termAndCondition}>
                      <h3 className={classes.aggTitle}>Borrower Certification and Agreement</h3>
                      <Box display="block" displayPrint="none">
                        <Box className={classes.termText}>
                         {content.length !== 0 && <p className={classes.text} dangerouslySetInnerHTML={{ __html: content[0].contentValue }} ></p>}
                        </Box>
                      </Box>
                      <Box display="none" displayPrint="block">
                        <Box className={classes.termText}>
                        {content.length !== 0 && <p className={classes.text} dangerouslySetInnerHTML={{ __html: content[0].contentValue }} ></p>}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <div className={classes.agreeContainer}>
                    <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox
                          checked={isQAgree}
                          onChange={(event) => isQRadioHandler(event)}
                          name="agree"
                        />}
                        label="I agree to the Terms and Conditions"
                        className={classes.inputStyle}
                        classes={{
                          label: isQAgree ? classes.activeRadioLabel : classes.radioLabel
                        }}
                      />
                    </FormGroup>
                    {isQAgreeError !== '' && <p className={classes.isQAgreeError}>{isQAgreeError}</p>}
                  </div>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} sm={12} style={{ position: 'relative' }} className={classes.covidBtnContainer}>
                  <Box p={1} textAlign="right" className={classes.printBtn}>
                    <ReactToPrint
                      trigger={() => <Box className={classes.printBtnIcon} >
                        Print Form
                      <PrintIcon className={classes.printIconStyle} />
                      </Box>}
                      content={() => componentRef.current}
                    />
                  </Box>
                  {covidError !== '' && <p className={classes.ErrorMsg}>{covidError}</p>}
                  <Box className={`${classes.btnContainer}`}
                    display="flex" justifyContent="center" style={{ paddingBottom: '34px', paddingTop: '40px' }}>
                    <Button className={`${classes.btn} ${classes.resetButton}`} mt={2}
                      variant="outlined"
                      onClick={cancelForm}>
                      Cancel
                  </Button>
                    <Button className={`${classes.btn} ${classes.submitButton}`} mt={2}
                      disabled={isAdmin() || (isSubmit || mobileError !== '' || (checkErrorObject() !== 0) || !covidPostData.mobileNumber || !covidPostData.primaryName || !covidPostData.emailID
                        || !covidPostData.accountType || (!covidPostData.owner && !covidPostData.rental && !covidPostData.vacant)
                        || !covidPostData.impacted250 || !covidPostData.workShutdown 
                        ||  !covidPostData.payingWhileShutdown
                        || (covidPostData.setGoBack == 'N' && !covidPostData.assistanceRequested) || !covidPostData.setGoBack || !isQAgree
                        || !covidPostData.unemploymentMeantime) ? true : false}
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={submitQualifiedCovidData}>
                      {isSubmit ? <CircularProgress color='primary' style={{ width: 25, height: 25 }} /> : 'SUBMIT'}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
         }
         </Box>
        }
      </Box>

      {
        covidConfirmAlert && <DisasterConfirmAlert
          toggleOpen={toggleCovidAlertBox}
          open={covidConfirmAlert}
          confirmCovidSubmit={confirmCovidSubmit}
          covidConfirmAlertProgress={covidConfirmAlertProgress}
        />
      }


    </Box >
  );
}
