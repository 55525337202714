import Tooltip from '@material-ui/core/Tooltip';

import { makeStyles, withStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
  setting: {
    width: '100%',
    padding: '35px 60px',
    [theme.breakpoints.down('md')]: {
      padding: '35px 0px',
    }
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: " 2.4em",
    fontWeight: "400",
    marginBottom: 30,
  },

  content: {
    width: '100%',
    padding: '0 15px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },


  text: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 2.1em",
    fontWeight: "500",
    marginBottom: 9,
  },

  inline: {
    display: 'inline',
  },

  subText: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 2.4em",
    fontWeight: "bold",
  },

  linkText: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: " 1.4em",
    fontWeight: "bold",
    marginLeft: 40,
    textDecoration: 'underline',
    textTransform: "capitalize",
  },

  settingContent: {
    width: '100%',
    paddingLeft: 15,
    // padding: '0 15px 35px',
    // borderBottom: 'solid 1px #4571C4',
    // marginBottom: 40,
    [theme.breakpoints.down('md')]: {
      // padding: '0 0 35px',
    }
  },

  flexContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  settingSubTitle: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: " 2em",
    fontWeight: "400",
    marginBottom: 30,
    paddingBottom: 10,
    borderBottom: 'solid 1px rgba(0,0,0,0.12)'
  },

  item: {
    width: '50%',
    marginTop: 0,
  },

  rightText: {
    textAlign: "right",
  },

  subTitle: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Montserrat",
    fontSize: " 1.6em",
    fontWeight: "600",
    marginBottom: 13,
  },

  subText: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 1.4em",
    marginBottom: 30,
  },

  additionalPayHeader: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 30,
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)'
  },

  marginFix: {
    marginBottom: 0
  },

  marginLeft: {
    marginLeft: 40,
  },

  additionalPaySubTitle: {
    color: "rgba(0, 0, 0, 0.74)",
    fontFamily: "Montserrat",
    fontSize: "1.6em",
    fontWeight: "400",
    marginTop: 0,
    marginBottom: 10,
  },

  additionalPayAmount: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Montserrat",
    fontSize: "1.6em",
    fontWeight: "600",
    marginTop: 0,
    marginBottom: 30,
  },

  additionalPayItem: {
    maxWidth: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
    }
  },

  btn: {
    width: 212,
    fontFamily: "Montserrat",
    fontSize: " 1.4em",
    [theme.breakpoints.down('md')]: {
      display: 'block',
      margin: '0 auto',
    }
  },

  button: {
    width: 160,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  additionalPayTextFieldContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 14,
  },

  additionalPayTextField: {
    width: '45%',
  },


  textField: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: '600'
  },

  divider: {
    height: 100,
  },

  flexEnd: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 14,
  },

  draftDay: {
    color: "rgba(0, 0, 0, 0.74)",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: "400 !important"
  },

  errMsg: {
    color: "#EB4F4F",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: '500',
    marginBottom: 10,
  },

  errMsg1: {
    color: "#000",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    fontWeight: '500',
    marginBottom: 10,
  },

  errorMsg: {
    color: "#EB4F4F",
    fontFamily: "Montserrat"
  },

  paymentInfoItem: {
    maxWidth: '50%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      width: '100%',
    }
  },

  paymentInfoTitle: {
    color: "rgba(0, 0, 0, 0.74)",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: "400",
    marginBottom: 10,
    marginTop: 0,
  },

  paymentInfoSubTitle: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: "600",
    marginBottom: 30,
    marginTop: 0,
  },

  paymentContent: {
    width: '100%',
    padding: '0 15px',
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      padding: '0 0',
    }
  },

  dummyitem: {
    maxWidth: '50%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      width: '100%',
      marginBottom: 40,
    }
  },

  borderBottom: {
    paddingBottom: 30,
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)'
  },

  borderNone: {
    borderBottom: 'none',
  },
  errorMsg2: {
    marginTop: 12,
    color: 'black',
    // textAlign: 'center',
    fontSize: 12,
    fontFamily: 'Montserrat',
    fontWeight: '600',
    lineHeight: '19px',
    letterSpacing: '0.36px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      bottom: 0,
    },
  }
}));

export const DisableTooltip = withStyles((theme) => ({
  arrow: {
    color: "#7eb1cc",
  },
  tooltip: {
    backgroundColor: '#7eb1cc',
    color: '#fff',
    fontFamily: "Montserrat",
    boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
    padding: "10px",
    fontSize: 16,
    maxWidth: 300,
  },
}))(Tooltip);

