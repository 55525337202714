import React, { useEffect, useState } from 'react';
import { useStyles, StyledTableCell, StyledTableRow } from './styles';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { Link } from 'react-router-dom';


import { getPaymentHistory } from '../../actions';


export const RecentTransaction = (props) => {
  const { accountNumber } = props;
  const classes = useStyles();
  const [patmentHistory, setPaymentHistory] = useState([]);
  const [buyDownStatus, setBuyDownStatus] = useState(false);

  useEffect(() => {
    getData()
  }, [accountNumber]);

  const getData = async () => {
    const res = await getPaymentHistory(accountNumber);
    if (res) {
      const history = res.map(item => createData(item.transDate, item.transType, item.transDesc, item.transAmount, item.buydown))
      setPaymentHistory(history.slice(0, 5))
      const filterBuyDown = history.slice(0,5).filter((item) => ((item?.buydown !== null) && (item?.buydown !== '$0.00')))
      setBuyDownStatus(filterBuyDown.length > 0 ? true : false)
    }
  }

  function createData(date, paymentType, description, amaount, buydown) {
    return { date, paymentType, description, amaount, buydown };
  }

  const nullCheckBuydown = (str) => {
    if (str !== null) {
      return str
    } else {
      return '$0.00'
    }
  }

  if (patmentHistory && patmentHistory.length === 0) {
    return (
      <Box className={classes.loader}>
        <Box className={classes.loaderWrapper}>
          <h1 className={classes.title}>Recent Transaction Activity</h1>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Date</StyledTableCell>
                  <StyledTableCell align="center">Description</StyledTableCell>
                  <StyledTableCell align="center">Transaction Amount</StyledTableCell>
                </TableRow>
              </TableHead>

            </Table>
          </TableContainer>
          <Box className={classes.noDataBody}>
            <p className={classes.norecordText}>No record found</p>
          </Box>
        </Box>

      </Box>
    )
  } else if (patmentHistory && patmentHistory.length !== 0) {
    return (
      <Box className={classes.root}>
        <h1 className={classes.title}>Recent Transaction Activity</h1>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell align="left">Description</StyledTableCell>
                <StyledTableCell align="left">Transaction Amount</StyledTableCell>
                {buyDownStatus && <StyledTableCell align="left">Buydown</StyledTableCell>}

              </TableRow>
            </TableHead>
            <TableBody>
              {patmentHistory.map((row, index) => (
                <StyledTableRow key={`${row.date}${index}`}>
                  <StyledTableCell component="th" scope="row">
                    {moment(row.date).isValid() ? moment(row.date).format("MM/DD/YYYY") : null}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.description}</StyledTableCell>
                  <StyledTableCell align="left">{row.amaount}</StyledTableCell>
                  {buyDownStatus && <StyledTableCell align="left">{nullCheckBuydown(row?.buydown)}</StyledTableCell>}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          variant='contained'
          color='secondary'
          component={Link}
          to='/TransactionHistory'
          className={classes.btn}
        >See more activity</Button>
      </Box>
    )
  }
  else {
    return (
      <Box className={classes.loader}>
        <CircularProgress />
      </Box>
    )
  }

}
