import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from 'react-redux';
import {
  Box, Button, StepLabel, FormControlLabel,
  Radio, RadioGroup, TextField, Divider, Select,
  FormControl, InputLabel, MenuItem,
  InputAdornment,
  CircularProgress,
  Tooltip,
  Typography, ClickAwayListener
} from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import CancelIcon from '@material-ui/icons/Cancel';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import HelpIcon from '@material-ui/icons/Help';


import { useStyles } from './styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import { Link } from 'react-router-dom';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import clsx from "clsx";
import { AutoPaySuccessAlert } from '../../components';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PrintIcon from '@material-ui/icons/Print';
import {
  maskAccount, renderDraftingOption, numberWithCommas,
  checkDigitWithDecimal, checkDigitOnly, renderDefaultMsg, getUserType,
  getAppSource, maskAccountNo
} from '../../helpers';
import { decrypt } from '../../helpers/encrypt';
import { addRecurringACHPayment, getContent, getBankAccountDetail } from '../../actions';
import DateFnsUtils from '@date-io/date-fns';
import { DeleteConfirmation } from '../ManageBankAccount/DeleteConfirmationOnAccount/DeleteConfirmation'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import ErrorIcon from '@material-ui/icons/Error';
import Cookies from 'js-cookie';
import ReactToPrint from 'react-to-print'
import { AddEditAccount } from '../SharedComponent/AddBankAccAlert';
import TermAndConditions from '../TermAndConditions';
import { useSelector } from 'react-redux';
import { AmortizationCalculator } from '../AmortizationCalculator'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import amortizationIcon from '../../assets/AmortizationCalculator/amortizationCalculator.svg';
import { ManageAutoPayAlert } from "../ManageAutoPayAlert";
import { AdditionalPaymentAlert } from "../AdditionalPaymentAlert";
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Popup from "../PopUp";
import { LateFeeAckAlert } from "../SharedComponent/LateFeeAckAlert";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { BENEFIT_OF_PAYING_MORE } from '../../helpers/constants';

const LightToolTip = withStyles((theme) => ({
  arrow: {
    color: '#235A86',
    // backgroundColor: '#ffffff',
  },
  tooltip: {
    backgroundColor: '#235A86',
    color: '#fffff',
    fontFamily: "Montserrat",
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    padding: "16px",
    fontSize: 14,
    maxWidth: 350,
    fontWeight: 500,
    borderRadius: 8,
    // border: "1px solid #4D4D4D"
  },
}))(Tooltip);
const businessMoment = require('moment-business-days');

const SimpleTooltip = withStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white,
    "&::before": {
      backgroundColor: theme.palette.common.white,
      border: "1px solid #EDB36A"
    }
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#555D6C',
    fontFamily: "Montserrat",
    // boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
    padding: "1em",
    fontSize: 12,
    maxWidth: 300,
    border: '1px solid #EDB36A',
  }
}))(Tooltip);


export const ManageAutoPayEdit = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const componentRef = useRef();
  const tncRef = useRef(null);
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'));

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [selectedBank, setSelectedBank] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [tncSummary, setTncSummary] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [deletedConfirmationCode, setDeletedConfirmationCode] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isCalculator, setIsCalculator] = useState(false);
  const selectedAccountData = useSelector(state => state.selectedAccountData);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [openStatus, setManageAutoPayStatus] = useState(false);
  const [warningPopup, setWarningPopup] = useState({ show: false, message: '' })
  const { maxPaymentAllowed } = props.data;
  const [openHowItWorksPopup, setOpenHowItWorksPopup] = useState(false)
  const [ackPoup, setAckPopup] = useState(false)
  const [dueDateGreen, setDueDateGreen] = React.useState('');
  const [BiWeeklyLateFeeAck, setBiWeeklyLateFeeAck] = useState(false);
  const [isDateChange, setIsDateChange] = useState(false);
  let clientName = Cookies.get('clientName');
  clientName = clientName?.toLowerCase()?.trim();
  const isToorak = clientName === 'toorak';
  const [isToorakLoan,setToorakLoan] = useState(isToorak);

  const [state, setState] = React.useState({
    regularPayment: 0,
    paymentFrequncyType: '',
    additionalPrincipal: 0,
    additionalEscrow: 0,
    bankAccount: '',
    isEscrowAllowed: true,
    weeklyPayment: 0,
    paymentDueDate: '',
    latePaymentDate: '',
    accountNumber: '',
    maxAutoPaymentDate: '',
    isBiWeeklyAllowed: true,
    estDateTime: ''
  });

  const [newAddedAccount, setNewAddedAccount] = useState(null)
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [howItWorks, setHowItWorks] = useState()
  const [openMonthyPaymentTooltip, setOpenMonthyPaymentTooltip] = useState(false);
  const [openHalfMonthyPaymentTooltip, setOpenHalfMonthyPaymentTooltip] = useState(false);
  const [openSecondDraftDateTooltip, setOpenDraftDateTooltip] = useState(false);
  const [openPayingMoreTooltip, setOpenPayingMoreTooltip] = useState(false);

  const handleMonthyPaymentTooltipClose = () => {
    setOpenMonthyPaymentTooltip(false);
  };

  const handleMonthyPaymentTooltipOpen = () => {
    setOpenMonthyPaymentTooltip(true);
  };
 
  const handleHalfMonthyPaymentTooltipClose = () => {
    setOpenHalfMonthyPaymentTooltip(false);
  };

  const handleHalfMonthyPaymentTooltipOpen = () => {
    setOpenHalfMonthyPaymentTooltip(true);
  };

  const handleDraftDateTooltipClose = () => {
    setOpenDraftDateTooltip(false);
  };

  const handleDraftDateTooltipOpen = () => {
    setOpenDraftDateTooltip(true);
  };

  const handlePayingMoreTooltipClose = () => {
    setOpenPayingMoreTooltip(false);
  };

  const handlePayingMoreTooltipOpen = () => {
    setOpenPayingMoreTooltip(true);
  };

  useEffect(() => {
    if (props.data) {
      const { lstRecurACH, isEscrowAllowed, daysToCalculateSecondDraftDate, latePaymentDate, accountNumber, maxAutoPaymentDate, isBiWeeklyAllowed, estDateTime, paymentDueDate } = props.data;
      if (lstRecurACH && lstRecurACH.length !== 0) {
        const {
          draftAdditionalPrincipal, draftAdditionalEscrow,
          draftingOption, nextDraftDate, draftPayment,
          bankAccountNumber, bankAccountType, draftDate2 = "",
        } = lstRecurACH[0];
    
        if (businessMoment(nextDraftDate, 'MM-DD-YYYY').businessDiff(businessMoment(moment(), 'MM-DD-YYYY'), true) < 0) {
          setDueDateGreen(businessMoment(moment(props.data.estDateTime), 'MM-DD-YYYY').businessAdd(3)._d)
          setSelectedDate(businessMoment(moment(props.data.estDateTime), 'MM-DD-YYYY').businessAdd(3)._d);
          let date = businessMoment(moment(props.data.estDateTime)).businessAdd(3)._d;
          if ((draftingOption === 'M') && (businessMoment(date, 'MM-DD-YYYY').businessDiff(businessMoment(latePaymentDate, 'MM-DD-YYYY'), true) > 0)) {
          // if ((draftingOption === 'M') && ((businessMoment(moment(props.data.estDateTime), 'MM-DD-YYYY').businessAdd(3)._d).businessDiff(businessMoment(latePaymentDate, 'MM-DD-YYYY'), true) > 0)) {
            setWarningPopup({ show: false, message: '', showAck: true });
          } 
        } else {
          setDueDateGreen(nextDraftDate);
          setSelectedDate(nextDraftDate);
          if ((draftingOption === 'M') && (businessMoment(nextDraftDate, 'MM-DD-YYYY').businessDiff(businessMoment(latePaymentDate, 'MM-DD-YYYY'), true) > 0)) {
            setWarningPopup({ show: false, message: '', showAck: true });
          }
        }

        setState({
          regularPayment: draftPayment,
          daysToCalculateSecondDraftDate: daysToCalculateSecondDraftDate,
          draftDate2: draftDate2,
          weeklyPayment: (draftPayment / 2),
          paymentFrequncyType: draftingOption,
          additionalPrincipal: draftAdditionalPrincipal,
          additionalEscrow: draftAdditionalEscrow,
          bankAccount: `${bankAccountType} ${maskAccount(decrypt(bankAccountNumber))}`,
          paymentDueDate: paymentDueDate,
          isEscrowAllowed: isEscrowAllowed,
          latePaymentDate: latePaymentDate,
          accountNumber: accountNumber,
          maxAutoPaymentDate: maxAutoPaymentDate,
          isBiWeeklyAllowed: isBiWeeklyAllowed,
          estDateTime: estDateTime
        })
      } else {
        const { monthlyAmountDue, paymentDueDate, estDateTime } = props.data;
        let addDate = businessMoment(moment(estDateTime), 'MM-DD-YYYY').businessAdd(3)._d
        if (businessMoment(paymentDueDate, 'MM-DD-YYYY').businessDiff(businessMoment(moment(addDate), 'MM-DD-YYYY'), true) < 0) {
          setDueDateGreen(businessMoment(moment(estDateTime), 'MM-DD-YYYY').businessAdd(3)._d)
          setSelectedDate(businessMoment(moment(estDateTime), 'MM-DD-YYYY').businessAdd(3)._d);
        } else {
          setDueDateGreen(paymentDueDate);
          setSelectedDate(paymentDueDate);
        }

        let draftDate2Condtional;
        if (businessMoment(paymentDueDate, 'MM-DD-YYYY').businessDiff(businessMoment(moment(), 'MM-DD-YYYY'), true) < 0) {
          draftDate2Condtional = moment().add(3 + daysToCalculateSecondDraftDate, "days").format("MM/DD/YYYY");
        } else {
          draftDate2Condtional = moment(paymentDueDate).add(daysToCalculateSecondDraftDate, "days").format("MM/DD/YYYY");
        }

        setState({
          regularPayment: monthlyAmountDue,
          weeklyPayment: (monthlyAmountDue / 2),
          paymentFrequncyType: "M",
          additionalPrincipal: 0,
          additionalEscrow: 0,
          bankAccount: '',
          paymentDueDate: paymentDueDate,
          isEscrowAllowed: isEscrowAllowed,
          latePaymentDate: latePaymentDate,
          accountNumber: accountNumber,
          maxAutoPaymentDate: maxAutoPaymentDate,
          isBiWeeklyAllowed: isBiWeeklyAllowed,
          daysToCalculateSecondDraftDate: daysToCalculateSecondDraftDate,
          draftDate2: draftDate2Condtional,
          estDateTime: estDateTime

        })
      }
    }
  }, [props.data])

  useEffect(() => {
   
    getContentData();
    getBankAccounts();
  }, [])

  

  const getContentData = async () => {
    const res = await dispatch(getContent('PC017'));
    if (res && res.length !== 0) {
      const biWeeklyContent = res?.filter(key => key.contentKey == 'Bi-WeeklyHowItWorks')
      const termAndCondition = res?.filter(key => key.contentKey == 'T&C')
      if (termAndCondition?.length > 0) {
        setTncSummary(termAndCondition[0].contentValue)
      }
      if (biWeeklyContent?.length > 0) {
        setHowItWorks(biWeeklyContent[0].contentValue)
      }
    }
  }

  const handlePaymentType = (event) => {
    setState({ ...state, paymentFrequncyType: event.target.value })
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStep = (step) => {
    setActiveStep(step);
  };

  const handleComplete = (draftDate) => {
    /**Check warning when click on continue in payment information section  */
    if (state?.paymentFrequncyType === 'B' && draftDate && !isDateChange) {
      const { latePaymentDate } = props.data;
      const {
        paymentDueDate, draftDate2 = ""
      } = state;
      /*1. if draftdat1 and draftdate2 both are greater than grace period
       show warning and below message*/
      if (businessMoment(paymentDueDate, 'MM-DD-YYYY').businessDiff(businessMoment(latePaymentDate, 'MM-DD-YYYY'), true) > 0) {
        setWarningPopup({ show: true, message: 'msg1', showAck: true });
      }
      /*2. if  draftdate2 is greater than grace period
        show warning and below message*/
      else if (businessMoment(draftDate2, 'MM-DD-YYYY').businessDiff(businessMoment(latePaymentDate, 'MM-DD-YYYY'), true) > 0) {
        setWarningPopup({ show: true, message: 'msg2', showAck: true });
      }
    }
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
    if (completed[0] && completed[1] && completed[2]) {
      setErrMsg('');
    }
  };

  const handleEdit = (step) => {
    const newCompleted = completed;
    newCompleted[step] = false;
    setCompleted(newCompleted);
    setActiveStep(step);
  }
  //When click on continue on Acknowledgment popup
  const handleSubmit = () => {
    scheduleAutoPay();
    setAckPopup(false);
  }
  const scheduleAutoPay = async () => {
    setErrMsg('');
    if (completed[0] && completed[1] && completed[2]) {
      setLoading(true);
      const data = {
        "accountNumber": state.accountNumber,
        "paymentAmount": state.paymentFrequncyType === "M" ? state.regularPayment : state.weeklyPayment,
        "abaRoutingNumber": selectedBank.abaRoutingNumber,
        "bankAccountNumber": selectedBank.bankAccountNumber,
        "accountType": selectedBank.accountType,
        "bankCity": selectedBank.bankCity && selectedBank.bankCity.length ? selectedBank.bankCity : 'NA',
        "bankName": selectedBank.bankName && selectedBank.bankName.length ? decrypt(selectedBank.bankName) : 'NA',
        "bankState": selectedBank.bankState && selectedBank.bankState.length ? selectedBank.bankState : 'NA',
        "draftStartDate": moment(selectedDate).format('L'),
        "draftingOption": state.paymentFrequncyType,
        "nameOnAccount": selectedBank.borrowerName,
        "borrowerType": selectedBank.borrowerType,
        "additionalPrincipal": state.additionalPrincipal ? parseFloat(state.additionalPrincipal) : 0,
        "additionalEscrow": state.additionalEscrow ? parseFloat(state.additionalEscrow) : 0,
        "draftDate1": "",
        "draftDate2": state?.draftDate2 ? state.draftDate2 : "",
        "biWeeklyLateFeeAck": state?.draftDate2 ? BiWeeklyLateFeeAck : false,
        "action": "E",
        "graceDays": props.data.graceDays,
        "bankProfileID": selectedBank.bankProfileID,
        "propertyState": selectedAccountData.propertyState,
        "investorCode": selectedAccountData.bsi_InvestorCode,
        "privateLabelUrl": Cookies.get("privateLabelUrl"),
        "ipAddress": Cookies.get('publicIp'),
        "browser": Cookies.get("browser"),
        "appSource": getAppSource(),
        "userType": getUserType(),
        "userName": "",
        "delinquentDays": props.data.delinquentDays,
        "IsACHAdv": props.openStatus
      }
      const res = await addRecurringACHPayment(data);
      if (res && res.lstResponseStatus && res.lstResponseStatus[0].statusCode === '0') {
        setConfirmationCode(res.confirmationNumber);
        setDeletedConfirmationCode(res.deletedConfirmationNumber);
        setLoading(false);
        handleComplete(false);
        setOpen(true);

      } else if (res && res.lstResponseStatus && res.lstResponseStatus[0].statusCode !== '0') {
        setLoading(false);
        setErrMsg(res.lstResponseStatus[0].statusDesc);
      } else {
        setLoading(false);
        setErrMsg(renderDefaultMsg('400'));
      }
    } else {
      setErrMsg("Please complete all the steps first.");
    }
  }

  const handleClose = () => {
    if (props.openStatus) {
      setOpen(false)
      setManageAutoPayStatus(true)
    } else {
      setOpen(false)
    }
  }

  const handleChange = (event) => {
    if (checkDigitWithDecimal(event.target.value)) {
      setState({ ...state, [event.target.name]: event.target.value !== '' ? event.target.value : '' })
    }
  }

  const handleOk = () => {
    if (props.openStatus) {
      handleClose()
    } else {
      setOpen(false);
      props.handlePaymentOption("FAQ");
    }
  }

  const handleClosePopup = () => {
    setManageAutoPayStatus(false);
    props.handlePaymentOption("FAQ");
  }
  /**isFromWarning is a  flag for when user redirect from late payment warning popup  */
  const routeToManageAutoPay = (isFromWarning) => {
    setManageAutoPayStatus(false);
    props.handlePaymentOption("ONETIMEPAYMENT", isFromWarning);
  }

  const handleBankAccountChange = (event) => {
    setSelectedBank(event.target.value);
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setIsDateChange(true);

    if ((state?.paymentFrequncyType === 'M') && (businessMoment(date, 'MM-DD-YYYY').businessDiff(businessMoment(state.latePaymentDate, 'MM-DD-YYYY'), true) > 0)) {
      setWarningPopup({ show: false, message: '', showAck: true });
    } else {
      setWarningPopup({ show: false, message: '', showAck: false });
    }

    if (state?.paymentFrequncyType === 'B') {
      const draftDate2 = moment(date).add(state.daysToCalculateSecondDraftDate, "days").format("MM/DD/YYYY");
      setState({ ...state, draftDate2: draftDate2 });

      /*1. if draftdat1 and draftdate2 both are greater than grace period
        show warning and below message*/
      if (businessMoment(date, 'MM-DD-YYYY').businessDiff(businessMoment(state.latePaymentDate, 'MM-DD-YYYY'), true) > 0) {
        setWarningPopup({ show: true, message: 'msg1', showAck: true });
      }
      /*2. if  draftdate2 is greater than grace period
        show warning and below message*/
      else if (businessMoment(draftDate2, 'MM-DD-YYYY').businessDiff(businessMoment(state.latePaymentDate, 'MM-DD-YYYY'), true) > 0) {
        setWarningPopup({ show: true, message: 'msg2', showAck: true });
      }
      else {
        setWarningPopup({ show: false, message: '', showAck: false });

      }
    }
    setIsOpen(false);
  };

  function disableWeekends(date) {
    if (state.paymentFrequncyType !== 'M') {
      return (date.getDay() === 0 || date.getDay() === 6)
    }
    return false
  }

  const renderDayInPicker = (date, selectedDate, dayInCurrentMonth, dayComponent) => {

    if (businessMoment(dueDateGreen, 'MM-DD-YYYY').diff(businessMoment(date, 'MM-DD-YYYY'), true) === 0) {
      return (<div className={`${classes.paymentDayComponent}`}>
        {dayComponent}
      </div>)
    }

    if (businessMoment(state.latePaymentDate, 'MM-DD-YYYY').businessDiff(businessMoment(date, 'MM-DD-YYYY'), true) >= 0) {
      return (<div className={`${classes.greenZoneComponent}`}>
        {dayComponent}
      </div>)
    }

    if (businessMoment(state.latePaymentDate, 'MM-DD-YYYY').businessDiff(businessMoment(date, 'MM-DD-YYYY'), true) < 0) {
      return (<div className={`${classes.redZoneComponent}`}>
        {dayComponent}
      </div>)
    }

    return dayComponent
  }

  // Add bank account
  const ToggleAccountPopup = (saved = null) => {
    if (saved === 'Saved') {
      getBankAccounts();
    }
    setShowPopup(!showPopup);
  }

  const handleCalculator = (flag) => {
    setIsCalculator(flag)
  }

  useEffect(() => {
    if (bankAccounts.length !== 0) {
      const selectBankAccDetail = bankAccounts.find(bankAccount => bankAccount.bankAccountNumber === newAddedAccount);
      if (selectBankAccDetail) {
        setSelectedBank(selectBankAccDetail);
        setNewAddedAccount(null);
      } else {
        const alreadySelectedAccount = props.data && props.data.lstRecurACH && props.data.lstRecurACH.length ? props.data.lstRecurACH[0].bankAccountNumber : null;
        let selectedBankAcct = bankAccounts.find(bankAccount => bankAccount.bankAccountNumber === alreadySelectedAccount);
        if (!selectedBankAcct) {
          selectedBankAcct = bankAccounts.find(bankAccount => bankAccount.isPreferredAcc === true);
          if (!selectedBankAcct) {
            selectedBankAcct = bankAccounts[0];
          }
        }
        setSelectedBank(selectedBankAcct);
      }
    }
  }, [bankAccounts])

  const getBankAccounts = async () => {
    const res = await getBankAccountDetail();
    if (res && res.lstBEBankAccountDetails && res.lstBEBankAccountDetails.length !== 0) {
      setBankAccounts(res.lstBEBankAccountDetails)
    }
  }

  const showPopupAccount = () => {
    setShowPopup(true);
  }

  const newAddedBankAccount = (newAddedBank) => {
    setNewAddedAccount(newAddedBank);
  }
  // end Add Bank Account

  const calculateTotal = () => {
    if (state.paymentFrequncyType === "M") {
      return parseFloat(state.regularPayment + Number(state.additionalEscrow) + Number(state.additionalPrincipal)).toFixed(2);
    }
    return parseFloat(state.weeklyPayment + Number(state.additionalEscrow) + Number(state.additionalPrincipal)).toFixed(2)
  }

  const handleScheduleAutopay = () => {
    if (calculateTotal() < maxPaymentAllowed && state.additionalPrincipal < props.data.unpaidPrincipalBalance && state.additionalPrincipal > (props.data.unpaidPrincipalBalance - 5000)) {
      setAlertOpen(true)
    } else if (warningPopup?.showAck) {
      setAckPopup(true);
    } else {
      scheduleAutoPay()
    }
  }

  const routeToRequestPayOff = () => {
    history.push('/PayoffRequest');
  }

  const handleCloseAlert = () => {
    setAlertOpen(false);
    if (warningPopup?.showAck) {
      setAckPopup(true);
    } else {
      scheduleAutoPay()
    }
  }

  const handleClosePopUp = () => {
    setAlertOpen(false);
  }

  const howItWorksPopup = () => {
    setOpenHowItWorksPopup(true);
  }
  const closeHowItWorksPopup = () => {
    setOpenHowItWorksPopup(false);
  }


  const closePopupFunc = () => {
    const { showAck } = warningPopup;
    setWarningPopup({ show: false, message: '', showAck });
  }
  //Close Acknowledgement popup
  const handleAckPopupClose = () => {
    setAckPopup(false)
  }

  return (
    <Box className={classes.root} ref={componentRef} >
      <Stepper classes={{ root: classes.stepperStyle }} nonLinear activeStep={activeStep} orientation="vertical">
        <Step expanded={completed[0]}>
          <StepLabel
            StepIconComponent={!completed[0] ? () => <RadioButtonCheckedIcon classes={{ root: classes.inCompleteIcon }} /> : () => <RadioButtonCheckedIcon classes={{ root: classes.completeIcon }} />}
            onClick={() => handleStep(0)}
            completed={completed[0]}
            classes={{
              label: completed[0] || activeStep === 0 ? classes.aciveLabelStyle : classes.labelStyle,
              labelContainer: classes.labelContainer
            }}
          >
            Payment Frequency
          </StepLabel >
          <StepContent className={classes.stepContent}>
            {!completed[0] ?
              <Box className={classes.actionsContainer}>
                <RadioGroup className={classes.paymentFrequency} value={state.paymentFrequncyType} onChange={handlePaymentType}>
                  <Box className={classes.paymentFrequencyType}>
                    <Box className={classes.flexContainer}>
                      <Box className={classes.flex}>
                        <FormControlLabel
                          value="M"
                          control={<Radio classes={{ root: classes.radioStyle }} />} label="Regular Monthly Payment"
                          classes={{
                            label: classes.activeRadioLabel,
                          }}
                        />
                        
                        {!mediaQuery && <LightToolTip
                            arrow placement="bottom" title={"Regular monthly payment of principal, interest, and escrow"} >
                            <InfoIcon style={{ fill: '#535D6D' }} />
                          </LightToolTip> }
                          {mediaQuery && 
                          <ClickAwayListener onClickAway={handleMonthyPaymentTooltipClose}>
                          <LightToolTip
                              arrow placement="bottom" title={"Regular monthly payment of principal, interest, and escrow"}
                              open={ openMonthyPaymentTooltip }
                            >
                               <InfoIcon style={{ fill: '#535D6D' }} onClick={handleMonthyPaymentTooltipOpen}/>
                            </LightToolTip>
                          </ClickAwayListener>}
                      </Box>
                      <h3 className={classes.payFrequencyAmount}>${numberWithCommas(state.regularPayment.length !== 0 ? Number(state.regularPayment).toFixed(2) : 0)}</h3>
                    </Box>
                    <Box className={classes.flexContainer}>
                      <p className={classes.subText}>Regular monthly payment of principal, interest, and escrow</p>
                      {/* <h3 className={classes.subTextBold}>Charged once a Month</h3> */}
                    </Box>
                  </Box>
                  {(state.paymentFrequncyType == "B" || (state.paymentFrequncyType != "B" && state.isBiWeeklyAllowed)) && <Box>
                    <Box className={classes.flexContainer}>
                      <Box className={classes.flex}>
                        <FormControlLabel
                          value='B'
                          control={<Radio classes={{ root: classes.radioStyle }} />}
                          label="Bi-Weekly Payment"
                          classes={{
                            label: classes.radioLabel
                          }}
                        />
                       
                        {!mediaQuery && <LightToolTip
                            arrow placement="bottom" title={"Pay half of your monthly amount every two weeks."} >
                            <InfoIcon style={{ fill: '#535D6D' }} />
                          </LightToolTip> }
                          {mediaQuery && 
                          <ClickAwayListener onClickAway={handleHalfMonthyPaymentTooltipClose}>
                          <LightToolTip
                              arrow placement="bottom" title={"Pay half of your monthly amount every two weeks."}
                              open={ openHalfMonthyPaymentTooltip }
                            >
                               <InfoIcon style={{ fill: '#535D6D' }} onClick={handleHalfMonthyPaymentTooltipOpen}/>
                            </LightToolTip>
                          </ClickAwayListener>}

                        <a className={classes.howDWork} onClick={howItWorksPopup}>How does it work?</a>
                      </Box>
                      <h3 className={classes.payFrequencyAmount}>${numberWithCommas(parseFloat(state.weeklyPayment).toFixed(2))}</h3>
                    </Box>
                    <Box className={clsx(classes.flexContainer)}>
                      <p className={classes.subText}>Pay half of your monthly amount every two weeks</p>
                      {/* <h3 className={classes.subTextBold}>Every other week</h3> */}
                    </Box>
                  </Box>}
                  <Box className={classes.buttonContainer}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleComplete(false)}
                      className={`${classes.button} ${classes.marginTop30}`}
                    >
                      Continue
                    </Button>
                  </Box>
                </RadioGroup >
              </Box>
              :
              <Box className={classes.paymentFrequencyEdited} onClick={() => handleEdit(0)}>
                <Box className={clsx(classes.flexContainer)}>
                  <h3 className={classes.editedText}>{state.paymentFrequncyType === "M" ? "Regular Monthly Payment" : "Bi-Weekly Payment"}</h3>
                  <h3 className={classes.editedText}>{state.paymentFrequncyType === "M" ? `$${numberWithCommas(state.regularPayment.toFixed(2))}` : `$${numberWithCommas(state.weeklyPayment.toFixed(2))}`}</h3>
                </Box>
                <Box className={classes.flexContainer}> {state.paymentFrequncyType === 'M' ? <p className={classes.subTextEdited}>Regular monthly payment of principal, interest, and escrow</p> : ''}

                  <p className={classes.dueDate}>Charged {renderDraftingOption(state.paymentFrequncyType)}</p>
                </Box>
              </Box>
            }
          </StepContent>
        </Step>
        <Step expanded={completed[1]}>
          <StepLabel
            StepIconComponent={!completed[1] ? () => <RadioButtonCheckedIcon classes={{ root: classes.inCompleteIcon }} /> : () => <RadioButtonCheckedIcon classes={{ root: classes.completeIcon }} />}
            onClick={() => handleStep(1)}
            completed={completed[1]}
            classes={{
              label: completed[1] || activeStep === 1 ? classes.aciveLabelStyle : classes.labelStyle,
              labelContainer: classes.labelContainer
            }}
          >
            Additional Payment
          </StepLabel>
          <StepContent className={classes.stepContent}>
            {!completed[1] ?
              <Box className={classes.actionsContainer}>
                <Box className={classes.paymentFrequency}>
                  <Box className={classes.flexEndBox}>
                    <span className={classes.linkStyle}>Benefits of paying more</span>
                    
                    {!mediaQuery && <LightToolTip
                            arrow placement="bottom" title={BENEFIT_OF_PAYING_MORE} >
                            <InfoIcon style={{ fill: '#535D6D', marginLeft: 5, }}/>
                          </LightToolTip> }
                      {mediaQuery && 
                      <ClickAwayListener onClickAway={handlePayingMoreTooltipClose}>
                        <LightToolTip
                            arrow placement="bottom" title={BENEFIT_OF_PAYING_MORE}
                            open={ openPayingMoreTooltip }
                          >
                            <InfoIcon style={{ fill: '#535D6D', marginLeft: 5, }} onClick={handlePayingMoreTooltipOpen}/>
                          </LightToolTip>
                      </ClickAwayListener>}
                  </Box>
                  <Box className={classes.additionalPayTextFieldContainer}>
                    <TextField
                      color='primary'
                      label="Additional Principal"
                      variant="outlined"
                      type="text"
                      value={state.additionalPrincipal}
                      name='additionalPrincipal'
                      onChange={handleChange}
                      classes={{
                        root: classes.additionalPayTextField,
                      }}
                      InputProps={{
                        classes: { root: classes.textField },
                        startAdornment: (
                          <InputAdornment position="start">
                            <AttachMoneyIcon />
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        classes: { root: classes.textField }
                      }}
                    />

                    {state.isEscrowAllowed &&
                      <TextField
                        color='primary'
                        label="Additional Escrow"
                        variant="outlined"
                        type="text"
                        value={state.additionalEscrow}
                        name='additionalEscrow'
                        onChange={handleChange}
                        classes={{
                          root: classes.additionalPayTextField
                        }}
                        InputProps={{
                          classes: { root: classes.textField },
                          startAdornment: (
                            <InputAdornment position="start">
                              <AttachMoneyIcon />
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{
                          classes: { root: classes.textField }
                        }}
                      />}
                  </Box>
                  {/* <Button variant='outlined' color='primary'>
                  Amortization Calculator
                </Button> */}
                  <Box className={classes.flexContainer}>
                    {/* <Link to='/' className={classes.linkStyle}>Learn More About It</Link> */}
                    <Box
                      className={classes.calcBtn}
                      onClick={() => handleCalculator(true)}
                    >
                      <img className={classes.amortizationIcon} src={amortizationIcon} alt='icon' />
                    </Box>
                    {isCalculator &&
                      <AmortizationCalculator
                        addPayment={state.additionalPrincipal}
                        frequency={state.paymentFrequncyType}
                        accountNumber={state.accountNumber}
                        open={isCalculator} handleClose={() => handleCalculator(false)}
                        paymentFrequncyType={state.paymentFrequncyType}
                      />
                    }
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleComplete(false)}
                      className={classes.button}
                    >
                      Continue
                    </Button>
                  </Box>
                </Box>
              </Box>
              :
              <Box className={clsx(classes.additionalPaymentEdited, classes.flexContainer)} onClick={() => handleEdit(1)}>
                <Box>
                  <h3 className={classes.aditionalEditedText}>Additional Principal</h3>
                  <h3 className={classes.amaountSpan}>${state.additionalPrincipal ? numberWithCommas(state.additionalPrincipal) : 0}</h3>
                </Box>
                <Box>
                  {state.isEscrowAllowed && <h3 className={classes.aditionalEditedText}>Additional Escrow</h3>}
                  {state.isEscrowAllowed && <h3 className={classes.amaountSpan}>${state.additionalEscrow ? numberWithCommas(state.additionalEscrow) : 0}</h3>}
                </Box>
              </Box>
            }
          </StepContent>
        </Step>
        <Step expanded={completed[2]}>
          <StepLabel
            StepIconComponent={!completed[2] ? () => <RadioButtonCheckedIcon classes={{ root: classes.inCompleteIcon }} /> : () => <RadioButtonCheckedIcon classes={{ root: classes.completeIcon }} />}
            onClick={() => handleStep(2)}
            completed={completed[2]}
            classes={{
              label: completed[2] || activeStep === 2 ? classes.aciveLabelStyle : classes.labelStyle,
              labelContainer: classes.labelContainer
            }}
          >
            Payment Information
          </StepLabel>
          <StepContent className={classes.stepContent}>
            {!completed[2] ?
              <Box className={classes.actionsContainer}>
                <Box className={classes.paymentInfo}>
                  <Box className={classes.flexEndBox}>
                    <p className={classes.dueDate}>(Due Date {state.paymentDueDate})</p>
                  </Box>
                  <Box className={classes.additionalPayTextFieldContainer}>
                    <FormControl variant="outlined" className={state.paymentFrequncyType === 'B' ? classes.formControlBiw : classes.formControl}>
                      <InputLabel className={classes.textField} id="select-account">Bank Account</InputLabel>
                      <Select
                        labelId="select-account"
                        id="select-account-outlined"
                        label="Bank Account"
                        value={selectedBank}
                        onChange={handleBankAccountChange}
                      >
                        {
                          bankAccounts.map((bank) => {
                            return (
                              <MenuItem
                                key={bank.bankAccountNumber}
                                value={bank}
                                className={classes.textField}
                              >
                                {`${bank.nickName} ${maskAccountNo(decrypt(bank.bankAccountNumber))}`}
                              </MenuItem>
                            )
                          })
                        }
                      </Select>
                    </FormControl>
                    {state.paymentFrequncyType !== 'B' && <Divider className={classes.divider} orientation="vertical" flexItem />}
                    <Box className={state.paymentFrequncyType === 'B' ? classes.formControlBiw : classes.formControl}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          fullWidth
                          color='secondary'
                          variant="outlined"
                          variant="inline"
                          inputVariant="outlined"
                          shouldDisableDate={disableWeekends}
                          minDate={businessMoment(moment(state.estDateTime), 'DD-MM-YYYY').businessAdd(3)._d}
                          maxDate={state.maxAutoPaymentDate ? ((isToorakLoan && state.paymentFrequncyType === 'B')?undefined:state.maxAutoPaymentDate) : undefined}
                          renderDay={renderDayInPicker}
                          autoOk={true}
                          disableToolbar
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          label={(state.paymentFrequncyType === 'B') ? "First Draft Date (Eastern Time)" : "Draft Date (Eastern Time)"}
                          value={selectedDate}
                          onChange={handleDateChange}
                          //variant="inline"
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          InputProps={{
                            classes: { root: classes.textField },
                          }}
                          InputLabelProps={{
                            classes: { root: classes.textField },
                            shrink: true,
                          }}
                          KeyboardButtonProps={{
                            onFocus: e => {
                              setIsOpen(true);
                            }
                          }}
                          PopoverProps={{
                            disableRestoreFocus: true,
                            onClose: () => {
                              setIsOpen(false);
                            }
                          }}
                          InputProps={{
                            onFocus: () => {
                              setIsOpen(true);
                            }
                          }}
                          open={isOpen}
                        />
                      </MuiPickersUtilsProvider>
                      {/* new Date(selectedDate).getTime() > new Date(state.latePaymentDate).getTime() */}
                      {businessMoment(selectedDate, 'MM-DD-YYYY').businessDiff(businessMoment(state.latePaymentDate, 'MM-DD-YYYY'), true) > 0 && state.paymentFrequncyType !== 'B' ?
                        <Box>
                          <p className={clsx(classes.warningText, classes.warningRedText)}><ErrorIcon style={{ fill: '#EB4F4F' }} />Warning:</p>
                          <p className={classes.warningText}>Scheduling Payment for this date will result in a late fee on your payment every month.</p>
                        </Box>
                        :
                        ''
                      }
                    </Box>
                    {state.paymentFrequncyType === 'B' && <Box className={classes.formControlBiw}>
                      <Box style={{ display: 'grid' }}>
                        <Typography
                          sx={{ display: 'inline' }}
                          style={{ marginBottom: '10px', color: '#959698' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Second Draft Date

                          {!mediaQuery && <LightToolTip
                            arrow placement='top-start' title={"The date is calculated based on your selected first draft date i.e. first draft date+14 calendar days due to payment being Bi-weekly."} >
                            <InfoIcon style={{ fill: '#555D6C', marginBottom: '-8px', marginLeft: '5px' }} />
                          </LightToolTip> }
                          {mediaQuery && 
                          <ClickAwayListener onClickAway={handleDraftDateTooltipClose}>
                          <LightToolTip
                              arrow placement='top-start' title={"The date is calculated based on your selected first draft date i.e. first draft date+14 calendar days due to payment being Bi-weekly."}
                              open={ openSecondDraftDateTooltip }
                            >
                               <InfoIcon style={{ fill: '#555D6C', marginBottom: '-8px', marginLeft: '5px' }} onClick={handleDraftDateTooltipOpen}/>
                            </LightToolTip>
                          </ClickAwayListener>}

                        </Typography>
                        <Typography
                          sx={{ display: 'inline' }}
                          style={{ marginBottom: '10px', color: '#535D6D' }}
                          component="span"
                          variant="body2"
                          color="text.secondary"
                        >
                          {state.draftDate2}
                        </Typography>
                      </Box>
                    </Box>}
                  </Box>

                  <Box className={classes.flexContainer}>
                    <Button
                      className={classes.addAccountBtn}
                      startIcon={<AddCircleOutlineIcon />}
                      onClick={showPopupAccount}
                    >
                      Add Another Bank Account</Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleComplete(true)}
                      className={classes.button}
                      disabled={bankAccounts.length === 0}
                    >
                      Continue
                    </Button>
                  </Box>
                </Box>
              </Box>
              :
              <Box className={classes.paymentInfoEdited} onClick={() => handleEdit(2)}>
                <Box className={classes.flexContainer}>
                  <Box>
                    <h3 className={classes.aditionalEditedText}>Bank Account</h3>
                    {selectedBank !== '' && <h3 className={classes.amaountSpan}> {`${selectedBank.nickName} ${maskAccountNo(decrypt(selectedBank.bankAccountNumber))}`}</h3>}
                  </Box>
                  <Box>
                    <h3 className={classes.aditionalEditedText}>Payment Scheduled </h3>
                    <h3 className={classes.amaountSpanLight}><span className={classes.amaountSpan} >{moment(selectedDate).format('L')} </span> {(state.paymentFrequncyType === 'B') ? 'First Draft Date' : 'Draft Date'} </h3>
                    {(state.paymentFrequncyType === 'B') && <h3 className={classes.secondDraftDate}><span className={classes.amaountSpan} >{state.draftDate2} </span> Second Draft Date</h3>}
                  </Box>
                </Box>
              </Box>
            }
          </StepContent>
        </Step>
        <Step expanded={completed[3]}>
          <StepLabel
            StepIconComponent={!completed[3] ? () => <RadioButtonCheckedIcon classes={{ root: classes.inCompleteIcon }} /> : () => <RadioButtonCheckedIcon classes={{ root: classes.completeIcon }} />}
            onClick={() => handleStep(3)}
            completed={completed[3]}
            classes={{
              label: completed[3] || activeStep === 3 ? classes.aciveLabelStyle : classes.labelStyle,
              labelContainer: classes.labelContainer
            }}
          >
            Confirm Payment
          </StepLabel>
          <StepContent className={classes.stepContent}>
            <Box className={classes.actionsContainer}>
              <Box className={classes.confirmPayment}>
                <Box className={classes.confirmPaymentFlex}>
                  <Box className={classes.paymentDetailBoxItem}>
                    <h3 className={classes.paymentDetailTitle}>Payment Details</h3>
                    <Box className={clsx(classes.flexContainer, classes.payDetailItem)}>
                      <h3 className={classes.textField}>{state.paymentFrequncyType === "M" ? "Monthly Payment" : "Bi-Weekly Payment"}</h3>
                      <h3 className={classes.payAmount}>{state.paymentFrequncyType === "M" ? `$${numberWithCommas(state.regularPayment.toFixed(2))}` : `$${numberWithCommas(state.weeklyPayment.toFixed(2))}`}</h3>
                    </Box>
                    {state.additionalPrincipal > 0 &&
                      <Box className={clsx(classes.flexContainer, classes.payDetailItem)}>
                        <h3 className={classes.textField}>Additional Principal Amount</h3>
                        <h3 className={classes.payAmount}>${numberWithCommas(state.additionalPrincipal !== '' ? parseFloat(state.additionalPrincipal).toFixed(2) : 0)}</h3>
                      </Box>
                    }
                    {state.isEscrowAllowed && state.additionalEscrow > 0 &&
                      <Box className={clsx(classes.flexContainer, classes.payDetailItem)}>
                        <h3 className={classes.textField}>Additional Escrow Amount</h3>
                        <h3 className={classes.payAmount}>${numberWithCommas(state.additionalEscrow !== '' ? parseFloat(state.additionalEscrow).toFixed(2) : 0)}</h3>
                      </Box>
                    }
                    <Box className={classes.totalPayBox}>
                      <h3 className={classes.totalText}>Payment Total</h3>
                      <h3 className={classes.payTotalAmount}>${numberWithCommas(calculateTotal())}</h3>
                    </Box>
                    {calculateTotal() > maxPaymentAllowed ? <h5 className={classes.errorMsg}>Payment Amount Restricted: Total payment amount cannot be greater than ${numberWithCommas(maxPaymentAllowed)}. <p className={classes.errorMsg2} >To complete a payment greater than ${numberWithCommas(maxPaymentAllowed)}, please contact Customer Support.</p></h5> : null}


                    {calculateTotal() <= maxPaymentAllowed && state.additionalPrincipal >= props.data.unpaidPrincipalBalance &&
                      <>
                        <h5 className={classes.errorMsg}>
                          The amount you’ve designated is greater than your principal balance.
                        </h5>
                        <p className={classes.errorMsg1}>Please select <a style={{ color: 'blue' }} href={'./PayoffRequest'}>Request Payoff</a> if you intend to pay off your loan. The instructions regarding submission of funds to satisfy your loan in full will be outlined in the payoff statement. Please note that we cannot accept a payoff online. If you wish to make a payment towards your loan balance, please resubmit an amount that does not exceed your principal balance.</p>
                      </>
                    }

                  </Box>
                  <Box className={classes.termAndCondition}>
                    <h3 className={classes.termTitle}>Auto Debit Authorization</h3>

                    <Box display="block" displayPrint="none">
                      <Box className={classes.termAndConditionBox}>
                        <Box className={classes.tncTextContainer}>
                          <TermAndConditions data={tncSummary} />
                        </Box>
                      </Box>
                    </Box>

                    <Box display="none" displayPrint="block">
                      <Box className={classes.termAndConditionBoxPrint}>
                        <Box className={classes.tncTextContainer}>
                          <TermAndConditions data={tncSummary} />
                        </Box>
                      </Box>
                    </Box>


                    <Box className={classes.printButton} textAlign="right">
                      <ReactToPrint
                        trigger={() =>
                          <Box className={classes.printIconText}>
                            <h5 className={classes.printForm}>Print Form</h5>
                            <PrintIcon style={{ width: '24px', height: '24px' }} />
                          </Box>}
                        content={() => componentRef.current} />
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.flexContainer}>
                  {/* <Box className={classes.printButton} textAlign="right">
                    <ReactToPrint
                      trigger={() =>
                        <Box className={classes.printIconText}>
                          <h5 className={classes.printForm}>Print Form</h5>
                          <PrintIcon style={{ width: '24px', height: '24px' }} />
                        </Box>}
                      content={() => componentRef.current} />
                  </Box> */}
                  <p />
                  <Box>
                    <Button
                      disabled={Cookies.get('adminToken') || loading || calculateTotal() > maxPaymentAllowed || calculateTotal() <= maxPaymentAllowed && state.additionalPrincipal >= props.data.unpaidPrincipalBalance}
                      variant="contained"
                      color="primary"
                      onClick={handleScheduleAutopay}
                      className={classes.button}
                    >
                      {loading ? <CircularProgress color='inherit' size={20} /> : 'Schedule Autopay'}
                    </Button>
                    {errMsg.length !== 0 ? <p className={classes.errMsg}>{errMsg}</p> : null}
                  </Box>
                </Box>

              </Box>
            </Box>
          </StepContent>
        </Step>
      </Stepper>
      {
        selectedBank !== '' && confirmationCode !== '' &&
        <AutoPaySuccessAlert
          dueDate={selectedDate}
          bankAccount={`${selectedBank.nickName} ${maskAccountNo(decrypt(selectedBank.bankAccountNumber))}`}
          totalAmount={`${numberWithCommas(calculateTotal())}`}
          open={open}
          handleClose={handleClose}
          handleOk={handleOk}
          confirmationCode={confirmationCode}
          deletedConfirmationCode={deletedConfirmationCode}
        />
      }
      {
        <Modal
          aria-labelledby="transition-modal-title"
          open={openHowItWorksPopup}
          onClose={closeHowItWorksPopup}
          slots={{ backdrop: Backdrop }}
          fullWidth
          maxWidth="sm"
        >
          <Box>
            <Box className={classes.howItWorks}>
              <CancelIcon
                onClick={closeHowItWorksPopup}
                className={classes.closeIcon} />
              <Box className={classes.innerContent}>
                <div dangerouslySetInnerHTML={{ __html: howItWorks }} />
              </Box>
            </Box>
          </Box>
        </Modal>
      }
      {warningPopup?.show &&
        <Popup heading={'Warning'} open={warningPopup?.show} closePopup={closePopupFunc} isWarningPopup={true}>
          <div className={classes.popupBody}>

            {warningPopup?.message === 'msg1' && <p className={classes.loanDetail}>
              Scheduling Payment for this date may result in a late fee on your payment every month. To avoid late fees, you can either adjust your first draft date or ensure your full monthly payment is received by the end of the grace period date of {state?.latePaymentDate} by making a <a className={classes.linkStyle2} onClick={() => routeToManageAutoPay(true)}>one-time payment</a> in advance of the first scheduled biweekly draft.
            </p>}
            {warningPopup?.message === 'msg2' && <p className={classes.loanDetail}>
              Scheduling Payment for this date may result in a late fee on your payment every month due to second draft date falling after the grace period. To avoid late fees, you can either adjust your first draft date or ensure your full monthly payment is received by the end of the grace period date of  {state?.latePaymentDate} by making a <a className={classes.linkStyle2} onClick={() => routeToManageAutoPay(true)}>one-time payment</a> in advance of the first scheduled biweekly draft.
            </p>}
          </div>
        </Popup>
      }

      {showPopup === true ? <AddEditAccount
        newAddedBank={newAddedBankAccount}
        toggleOpen={ToggleAccountPopup}
        open={showPopup}
        bankAccounts={bankAccounts}
        renderPage={'autoPay'}
      /> : null}

      {openStatus ?
        <ManageAutoPayAlert routeToManageAutoPay={routeToManageAutoPay} handleClosePopup={handleClosePopup} /> : null
      }
      {
        isAlertOpen &&
        <AdditionalPaymentAlert open={true} handleCloseAlert={handleCloseAlert} routeToRequestPayOff={routeToRequestPayOff} handleClose={handleClosePopUp}
          single={true} />
      }
      {
        ackPoup &&
        <LateFeeAckAlert open={ackPoup} setBiWeeklyLateFeeAck={setBiWeeklyLateFeeAck} handleSubmit={handleSubmit} isForSingleLoan={true} handleClose={handleAckPopupClose} paymentFrequncyType={state?.paymentFrequncyType} />
      }
    </Box >
  )
}
