import React, {useState} from 'react';
import { useStyles } from './styles';
import { Box, Button, Grid, FormControlLabel, TextField, TextareaAutosize, Typography, Checkbox, FormGroup  } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { decrypt, encrypt } from '../../../helpers/encrypt';
import { checkValidEmail, isAdminCheck, getAppSource, getUserType, checkDigitOnly, renderDefaultMsg } from '../../../helpers';
import { submitRefinanceRequest } from '../../../actions';
import close from '../../../assets/close.png';
import Cookies from 'js-cookie';

export const CallMeAlert = (props) => {

  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [value, setValue] = useState('');
  const [documentDesc, setDocumentDesc] = useState("");
  const [checkboxState, setCheckboxState] = useState(false);
  const [addDescriptionStatus, setDescriptionStatus] = useState(false);
  const [mobileNo, setMobileNo] = useState(props?.userProfile?.mobileNo ? decrypt(props?.userProfile?.mobileNo) : '');
  const [mobileNoErr, setMoileNoError] = useState("");
  const [errorMessage, setErrorMessage] = useState("")

  const checkboxChangeHandler = (event) => {
    setCheckboxState(event.target.checked)
  }
  
  const handleMobileNo = (event) => {
    if (checkDigitOnly(event.target.value)) {
     setMobileNo(event.target.value)
    } 
  }

  const handleCommment = (event) => {
    setDocumentDesc(event.target.value)
  }

  const addDescription = () => {
    setDescriptionStatus(!addDescriptionStatus)
  }

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  const handleCancel= (status) => {
    props.closeCallMePopup(status)
  }
 
  const handleConfirm = async () => {
    const data = {
      "contactInfo": encrypt(mobileNo),
      "contactType": "Call",
      "isConsent": checkboxState,
      "description": documentDesc,
      "accountNumber": Cookies.get("selectedAccount"),
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    const response = await submitRefinanceRequest(data);
    if (response) {
      if (response?.responseData?.lstResponseStatus && (response?.responseData?.lstResponseStatus[0]?.statusCode == 0)) {
        setErrorMessage("")
        handleCancel(true)
      } else {
        setErrorMessage(renderDefaultMsg('400'))
      }
    } else {
      setErrorMessage(renderDefaultMsg('400'))
    }
  }
  
  const calculateCommentLenth = () => {
    const length = documentDesc?.length
    if (isNaN(length)) {
      return 0
    } else {
      return length
    }
  }

  const validateMobileNo = (event) => {
    event.preventDefault()
    if (checkDigitOnly(event.target.value)) {
      if(event.target.value.length === 10) {
        setMoileNoError('');
      } else {
        setMoileNoError('Please enter valid phone number.')
      }
   }
  }

  const checkValidations = () => {
    if (isAdminCheck() || (mobileNo == undefined) || (mobileNo == "") || (mobileNo?.trim().length === 0) || !checkboxState || (mobileNoErr !== '')) {
      return true
    } else {
      return false
    }
  }

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} open={() => handleCancel(false)}>
      <Box className={classes.root}>
        <IconButton  className={classes.closeIcon} onClick={() => handleCancel(false)}>
          <CancelIcon />
        </IconButton>
         <Box className={classes.cardTitleBody}>
           <Typography className={classes.statusTitle}>Confirm Your Phone Number</Typography>
         </Box> 
            <Box className={classes.cardBody}>
            {errorMessage !== '' && <Typography className={classes.validationError}>{errorMessage}</Typography>}
              <Box className={classes.textareaSection}>
                <TextField
                  autoFocus
                  color="primary"
                  variant="outlined"
                  type="text"
                  label="This is My Phone Number"
                  value={mobileNo}
                  onChange={(event) => {handleMobileNo(event); validateMobileNo(event)}}
                  className={classes.textBox}
                  inputProps={{
                    maxLength: 10
                  }}
                />
                {mobileNoErr !== '' && <p className={classes.validationError}>{mobileNoErr}</p>}
                {((decrypt(props?.userProfile?.mobileNo) == '') && (mobileNo?.trim().length === 0)) && <p className={classes.validationError}>User's phone number could not be found. Please enter your phone number.</p>}
              </Box>
                  
              <Box className={classes.textareaSection}>
                <TextareaAutosize
                  style={{ height: '70px' }}
                  color="primary"
                  variant="outlined"
                  type="text"
                  name="comments"
                  maxLength="100"
                  onChange={(event) => handleCommment(event)}
                  value={documentDesc}
                  placeholder="What's on Your Mind (Optional)"
                  className={`${classes.textArea}`}
                >
              </TextareaAutosize>
                <Typography className={classes.textAreaValidation}>{calculateCommentLenth()}/100</Typography>
              </Box>
          
            {/* <Box className={classes.statusContainer}>
              <Typography className={classes.paragraph}>Preferred Timezone </Typography>
            </Box> */}

          <Box>
            {/* <Box className={classes.radioBtn}>
              <RadioGroup row aria-label="digital_comm" name="digital_comm" value={value} onChange={handleRadioChange}
              >
                <FormControlLabel value="Pacific"
                  control={<Radio />} label="Pacific" checked={value === 'Pacific' ? true : false} classes={{ label:
                    classes.labelText
                  }} />
                <FormControlLabel value="Central" checked={value === 'Central' ? true : false}
                  control={<Radio />} label="Central" classes={{ label:
                    classes.labelText
                  }}/>
                <FormControlLabel value="Eastern" checked={value === 'Eastern' ? true : false}
                  control={<Radio />} label="Eastern" classes={{ label:
                    classes.labelText
                  }}/>
                <FormControlLabel value="Mountain" checked={value === 'Mountain' ? true : false}
                  control={<Radio />} label="Mountain" classes={{ label:
                    classes.labelText
                  }}/>    
              </RadioGroup>
            </Box> */}
            <Box style={{paddingTop: "25px"}}>
              <FormGroup row>
                  <FormControlLabel
                      control={<Checkbox
                      checked={checkboxState}
                      onChange={(event) => checkboxChangeHandler(event)}
                      className={classes.checkboxContainer}
                      name="agree"
                      style={{paddingBottom: "24px"}}
                  />}
                      label={<Typography className={classes.checkBoxLabel}> By checking this Box, you consent to being contacted by BSI for sales or marketing purposes.</Typography>}
                      classes={{
                          label: classes.radioLabel
                      }}
                  />
              </FormGroup>
            </Box>     
            { !addDescriptionStatus && <Typography className={classes.viewconsent} onClick={() => addDescription()}>View Consent</Typography>}
            { addDescriptionStatus &&
                <Box className={classes.infoTopBox}>
                 <Box style={{display: "flex"}}>
                    <Grid container >
                      <Grid item xs={11} md={11}>
                        <Typography className={classes.viewconsent} onClick={() => addDescription()}>Hide Consent </Typography>
                      </Grid>
                      <Grid item xs={1} md={1}>
                        <img src={close} className={classes.icon} onClick={() => addDescription()}/>
                      </Grid>
                    </Grid>
                  </Box>
                  <Typography className={classes.feelFree}>By providing my email address and mobile phone number, I am granting BSI, its subsidiaries, affiliates, partners, service providers, successors and assigns, express consent to contact me at that email address or phone number about my loan, including information regarding servicing and products or services offered by BSI or a joint-marketing partner in connection with my loan. My consent allows BSI to use text messaging, artificial or pre-recorded voice messages and automatic dialing technology, as well as emails, for informational and service calls or messages. Message and data rates may apply.</Typography>
                </Box>
              } 
          </Box>

          <Box className={classes.buttonContainer}>
             <Grid container>
                    <Grid container style={{ marginTop: '12px' }}>
                      <Grid item xs={6} md={6}>
                        <Button
                          className={classes.skipBtn}
                          variant="outlined"
                          onClick={() => handleCancel(false)}  
                          >CANCEL</Button>
                        </Grid>
                        <Grid item xs={6} md={6}>
                        <Button type='submit' className={classes.continueBtn} onClick={() => handleConfirm()} disabled={checkValidations()} variant="contained" color="primary">
                          CONFIRM
                        </Button>
                    </Grid>
               </Grid>
             </Grid>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}
