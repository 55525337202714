import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "95%",
    minHeight: '100vh',
    padding: "3em 0em",
    margin: '0 auto',
  },
  fullpart: {
    width: '100%',
    maxWidth: "100%",
  },
  midtxtarea:{
    // maxWidth:'64%',
    marginTop:'10px'
  },
  bgg: {
    background: 'url(/static/media/signinIcon.de91850d.svg) no-repeat center center',
    backgroundSize: 'cover',
    width: '100%',    
    backgroundColor: '#ccc',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainContainer: {
    background: 'rgb(255, 255, 255)',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 14px 7px, rgba(0, 0, 0, 0.2) 0px 1px 3px 0px',
    position: 'relative',
    borderRadius: '12px'
  },
  firsthead: {
    Color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    letterSpacing: '0.15px',
    lineHeight: '24px',
  },

  formContainer: {
    padding: '23px 30px 0 26px',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 10px 0 10px',
    },
    '& .MuiFormLabel-root': {
      color: '#535D6D',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      letterSpacing: '0.36px',
      // lineHeight: '19px',
      background: '#fff',
      paddingRight: '5px'
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: props => props.secondaryColorCode,
    }
  },
  secondhead: {
    color: 'rgba(0,0,0,0.74)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    letterSpacing: '0.5px',
    lineHeight: '20px',
    marginTop: '20px',
    marginBottom: '20px',
  },
  title: {
    width: '100%',
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: "600",
    marginBottom: 18,
  },

  textField: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: '600'
  },

  errorMsg: {
    fontSize: '1.4em',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
    marginTop: 5,
    fontFamily: 'Montserrat',
    color: "red",
  },

  loaderRoot: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  dataErrorMsg: {
    width: '100%',
    height: '50vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'red',
    fontSize: '22px',
  },
  btn:{
    width:'175px',
  },

  errorMsg: {
    fontSize: '14px',
    fontWeight: '500',
    fontFamily: 'Montserrat',
    color: "red",
    marginBottpm: '10px'
  },
  buttoncheckarea: {
    width: '97%',
    overflow: 'hidden',
    paddingTop:'7px'
  },
  buttoncheck_1: {
    width: '46%',
    float: 'left'
  },
  buttoncheck_2: {
    width: '50%',
    float: 'left'
  },
  insider_1: {
    width: '11%',
    float: 'left'
  },
  insider_2: {
    width: '22%',
    float: 'left'
  },
  text_part: {
    color: 'rgba(0,0,0,0.74)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    letterSpacing: '0.5px',
    lineHeight: '20px',
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: '14px',
    },
  },
  innerwid: {
    width: '100%',
    display: 'flex'
  },
  success: {
    color: 'green'
  },
  data_part: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.5px',
    lineHeight: '20px',
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: '14px',
    },
  },
  note: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    color: 'rgba(0, 0, 0, 0.74)',
    fontWeight: '600',
    marginBottom: 20
  }

}));