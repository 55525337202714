import React, { useState, useEffect } from "react";
import { Box, Button, CircularProgress } from "@material-ui/core";
import { useStyles } from './styles';
import { Link,useLocation,useHistory } from 'react-router-dom';
import clsx from "clsx";
import { ManageAutoPaySetting, ManageAutoPayEdit, ManageAutoPayMultiLoan } from '../../components';
import moment from 'moment';
import { getBankAccountDetail } from '../../actions';
import { numberWithCommas, scrollTop } from '../../helpers';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import calendar from '../../assets/PaymentOptions/calendar.png';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const ManageAutoPay = (props) => {
  let query = useQuery();
  const classes = useStyles();
  const history = useHistory()
  const [isPaymentScheduled, setIsPaymentScheduled] = useState(false);
  const [showCurrentSetting, setShowCurrentSetting] = useState(false);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [isMultiLoan, setIsMultiLoan] = useState(false);
  const selectedAccount = useSelector(state => state.selectedAccount);
  const [showMultiLoanBtn, setShowMultiLoanBtn] = useState(false);

  const getAccounts = async () => {
    const totalAccounts = await Cookies.get('totalAccounts');
    if (totalAccounts) {
      Number(totalAccounts) > 1 ? setShowMultiLoanBtn(true) : setShowMultiLoanBtn(false)
    }
  }

  const getBankAccounts = async () => {
    const res = await getBankAccountDetail();
    if (res && res.lstBEBankAccountDetails && res.lstBEBankAccountDetails.length !== 0) {
      setBankAccounts(res.lstBEBankAccountDetails)
    }
  }

  const toggleCurrentSetting = (flag) => {
    setShowCurrentSetting(flag);
    scrollTop()
  }

  const handleMultiLoan = (flag = false) => {
    setIsMultiLoan(flag)
  }
  useEffect(()=>{
    if(props.isFromMultiLoan){
      setIsMultiLoan(true);
    }
  },[props.isFromMultiLoan])
  useEffect(() => {
    if (props.data) {
      var { lstRecurACH } = props.data;
      const name = query.get("name");
      if (lstRecurACH && lstRecurACH.length !== 0) {
        setShowCurrentSetting(true);
        setIsPaymentScheduled(true)
      } else {
        setShowCurrentSetting(false);
        setIsPaymentScheduled(false)
      }
      if(name==='MANAGE_BANK_ACCOUNTS_MULTI'){
        setIsMultiLoan(true);
        query.delete('name'); 
        history.replace({
          search: query.toString(),
        })
      }
      else if(name==='MANAGE_BANK_ACCOUNTS_SINGLE'){
        //setShowCurrentSetting(false);
        query.delete('name'); 
        history.replace({
          search: query.toString(),
        })
      }
    }
  }, [props.data]);

  useEffect(() => {
    getAccounts();
  }, []);


  if (props.data && props.loading === false) {
    const { paymentDueDate, lstRecurACH, monthlyAmountDue } = props.data;
    if (lstRecurACH && lstRecurACH.length !== 0) {
      var { nextDraftDate, draftPayment, draftingOption, draftAmount1
      } = lstRecurACH[0];
    }
    if (isMultiLoan) {
      return (
        <Box className={classes.manageAutoPayMultiLoan}>
          <ManageAutoPayMultiLoan
            customerCareNo={props.customerCareNo}
            selectedAccount={selectedAccount}
            handleMultiLoan={handleMultiLoan}
            handlePaymentOption={props?.handlePaymentOption}
          />
        </Box>
      )
    } else {
      return (
        <Box className={classes.root}>
          {showMultiLoanBtn && <Box className={classes.infoBox}>
            <p className={classes.infoBoxText}>There are multiple loans linked to your account. You can make a payment on multiple loans at a time or choose to pay on the loan that you are currently signed in for.</p>
            <Box className={classes.flexEnd}>
              <Button
                className={classes.infoBoxBtn}
                onClick={() => handleMultiLoan(true)}
              >SWITCH TO MULTI LOAN PAYMENT</Button>
            </Box>
          </Box>}
          <Box className={classes.header}>
            <Box className={classes.headerTitleContainer}>
              <img src={calendar} alt='icon' className={classes.headerImg} />
              <h2 className={classes.title}>{isPaymentScheduled ? "Upcoming Scheduled Payment" : "Upcoming Payment"}</h2>
            </Box>
            <Box className={classes.content}>
              <Box className={classes.item}>
                <h3 className={classes.text}>{draftingOption === "B" ? 'Total Amount Due(Bi-Weekly)' : 'Total Amount Due'}</h3>
                <h3 className={clsx(classes.headerSubText, classes.inline)}>${isPaymentScheduled ? numberWithCommas(parseFloat(draftAmount1).toFixed(2)) : numberWithCommas(parseFloat(monthlyAmountDue).toFixed(2))}</h3>
                {/* <Link className={classes.linkText} to='/'>View details</Link> */}
              </Box>
              <Box className={classes.item}>
                <h3 className={classes.text}>{isPaymentScheduled ? "Next Draft Date" : 'Payment Due Date'}</h3>
                <h3 className={classes.headerSubText}>{isPaymentScheduled ? moment(nextDraftDate).format('ll') : moment(paymentDueDate).format('ll')}</h3>
              </Box>
            </Box>
          </Box>
          {showCurrentSetting ?
            <ManageAutoPaySetting
              data={props.data}
              toggleCurrentSetting={toggleCurrentSetting}
              handlePaymentOption={props.handlePaymentOption}
            />
            :
            <ManageAutoPayEdit
              data={props.data}
              toggleCurrentSetting={toggleCurrentSetting}
              handlePaymentOption={props.handlePaymentOption}
              openStatus={props.openStatus}
            />
          }
        </Box>
      )
    }
  }
  return (
    <Box className={classes.loaderContainer}>
      <CircularProgress />
    </Box>
  );
}