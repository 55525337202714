import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 460,
    borderRadius: 12,
    backgroundColor: '#EAECED',
    position: 'relative',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)"
  },

  dialogStyle: {
    borderRadius: '12px !important',
    // width: '35%',
    maxHeight: "100%",
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '20px',
        margin: '50px 15px'
      },
  },

  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "400",
    textAlign: 'center',
    margin: 14,
  },

  cardBody: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 2em',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  statusTitle: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '16px',
    fontWeight: "600",
    padding: '3px 2px 2px 3px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px'
    }
  },

  statusBody: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "bold",
  },

  linkStyle: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    fontWeight: "600",
    marginTop: 5,
  },

  btn: {
    width: 105,
    height: '32px',
    fontFamily: "Montserrat",
    fontSize: '1.5em',
    fontWeight: "600",
    marginTop: "10px",
    [theme.breakpoints.down('sm')]: {
        width: 80
    }
  },

  closeIcon: {
    position: 'absolute',
    right: 42,
    paddingTop: 18,
    [theme.breakpoints.down('sm')]: {
        right: 10
    }
  },

  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },
  
  inputStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600"
  },

  labelStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "400",
  },

  cardFooter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: "#fff",
    padding: '20px 70px 0',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      padding: '20px 64px 0'
    },
  },

  footerItem: {
    marginBottom: 33,
    maxWidth: 175,
  },

  boxMargin: {
    marginLeft: 75
  },

  logoutIcon: {
    padding: '10px'
  }

}));
