import React from 'react';
import { useStyles } from './styles';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const DisasterAlert = (props) => {

  const { open,  handleClose } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      width="1000px"
      PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={handleClose} open={open}
    >
      <Box className={classes.root}>
        <IconButton className={classes.iconStyle} onClick={handleClose}>
          <CancelIcon />
        </IconButton>
        <Box className={classes.header}>
        
          <Typography className={classes.paragraph}>Have you been impacted by the recent natural disaster? If your property has been damaged or you have experienced loss of income due to the impact of the natural disaster, please contact Loss Mitigation at 888-482-7271 to discuss your options for Disaster Assistance.</Typography>
        </Box>
      </Box>
    </Dialog>
  )
}
