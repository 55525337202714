import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "95%",
    padding: "3em 0",
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      padding: "2em 0",
    },
    '& .MuiAlert-message': {
      fontFamily: "Montserrat",
      fontSize: " 14px",
      fontWeight: '500',
    }
  },
  fullpart: {
    width: '100%',
    maxWidth: "100%",
  },
  bgg: {
    background: 'url(/static/media/signinIcon.de91850d.svg) no-repeat center center',
    backgroundSize: 'cover',
    width: '100%',    
    backgroundColor: '#ccc',
  },
  title: {
    width: '100%',
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: "600",
    marginBottom: 18,
  },
  navinnerArea: {
    maxWidth: '100%',
  },
  inputStyle: {
    padding: '10px',
    fontSize: '14px',
    fontFamily: "Montserrat",
  },
  inputStyleBox: {
    padding: '10px',
    fontSize: '14px',
    fontFamily: "Montserrat",
    width: '182px'
  },
  inputStyle_1: {
    padding: '11.5px',
    fontSize: '14px',
    fontFamily: "Montserrat",
  },
  inputStyle_2: {
    padding: '0px !important',
    fontSize: '12px !important',
    lineHeight: '1px !important'
  },
  inner_part_2: {
    width: '42%',
    float: 'left',
    paddingTop: '7px'
  },
  part_1: {
    width: '38%',
    float: 'left'
  },
  part_1_chn: {
    width: '95%',
    float: 'left'
  },
  part_2: {
    width: '58%',
    float: 'left'
  },

  textFieldStyle: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 14px",
    fontWeight: '500',
    padding: '11.5px',
  },

  selectBoxStyle: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 14px",
    fontWeight: '500',
    padding: '11.5px',
  },

  navbuttonarea: {
    float: 'left',
    width: '20%',
    fontSize: '16px',
    textAlign: 'center'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%',
  },
  mainContainer: {
    background: 'rgb(255, 255, 255)',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 14px 7px, rgba(0, 0, 0, 0.2) 0px 1px 3px 0px',
    position: 'relative',
    borderRadius: '12px'
  },
  firsthead: {
    Color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    letterSpacing: '0.15px',
    lineHeight: '24px',
  },
  todoheading: {
    Color: '#535D6D',
    fontFamily: 'Montserrat',
    fontSize: '24px',
    fontWeight: '600',
    letterSpacing: '0.18px',
    lineHeight: '24px',
  },



  loaderContainer: {
    width: '100%',
    height: '100%',
    minHeight: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  container: {
    maxWidth: '100%',
    overflow: 'hidden'
  },
  innerDivisionArea: {
    maxWidth: '100%'
  },
  innerDivisionArea_1: {
    width: '54%',
    float: 'left'
  },
  innerDivisionArea_2: {
    width: '40%',
    float: 'left',
    padding: '10px'
  },

  division: {
    width: '48%',
    float: 'left'
  },
  searchbtn: {
    marginBottom: 24,
    fontSize: 14,
    color: '#535D6D',
    fontWeight: '600',
    textAlign: 'center',
    paddingTop: '9px',
    ['@media (max-width:1100px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: 12,
    },

  },
  innercontainer: {
    maxWidth: '90%',
    overflow: 'hidden',
    margin: '0 auto',
    marginTop: '15px',
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      maxWidth: '99%',
    },
  },
  button_det: {
    width: '22%',
    float: 'left',
    ['@media (max-width:1200px)']: { // eslint-disable-line no-useless-computed-key
      width: '25%'
    },
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      width: '31%',
    },
    textAlign: 'center'
  },
  button_det_4: {
    width: '17%',
    float: 'left',
    ['@media (max-width:1200px)']: { // eslint-disable-line no-useless-computed-key
      width: '19%'
    },
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      width: '24%',
    },
    textAlign: 'center'
  },
  button_det_1: {
    width: '11%',
    float: 'left',
    ['@media (max-width:1200px)']: { // eslint-disable-line no-useless-computed-key
      width: '13%'
    },
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      width: '16%',
    },
    textAlign: 'center'
  },
  leftmargin: {
    marginLeft: '130px',
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      marginLeft: '10px',
    },
  },
  ineer: {
    width: '100%',
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      textAlign: 'center',
      marginBottom: '5px'
    },
  },
  button_det_2: {
    width: '7.5%',
    float: 'left',
    ['@media (max-width:1200px)']: { // eslint-disable-line no-useless-computed-key
      width: '9.5%'
    },
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      width: '10.5%',
    },
    textAlign: 'center'
  },
  link: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    marginTop: 14,
  },

  disabledLink: {
    color: "#B3B8C0",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "bold",
    textDecoration: 'none',
  },

  pageHeading: {
    Color: '#535D6D',
    fontFamily: 'Montserrat',
    fontSize: '24px',
    fontWeight: '600',
    letterSpacing: '0.18px',
    lineHeight: '24px',
    marginBottom: 38,
    marginTop: 26,
  },

  activeLink: {
    color: "#3064A6",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    textDecoration: 'none',
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center'
  },
  table: {
    minWidth: 350,
  },

  subTitle: {
    color: '#363636',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: '600',
    letterSpacing: '0.47px',
    lineHeight: '22px',
    margin: '30px 0 23px 0'
  },

  topFormText: {
    margin: '20px 0'
  },

  bobyText: {
    color: '#535D6D',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: '0.36px',
    lineHeight: '19px',
  },

  rightMenuContainer: {
    display: 'flex',
    textAlign: 'right',
    position: 'absolute',
    top: '20px',
    right: '35px',
    alignItems: 'center'
  },

  formContainer: {
    padding: '23px 30px 0 26px',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 10px 0 10px',
    },
    '& .MuiFormLabel-root': {
      color: '#535D6D',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      letterSpacing: '0.36px',
      lineHeight: '19px',
      background: '#fff',
      paddingRight: '5px'
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: props => props.secondaryColorCode,
    }
  },

  secondhead: {
    color: 'rgba(0,0,0,0.74)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    letterSpacing: '0.5px',
    lineHeight: '20px',
    marginTop: '20px',
    marginBottom: '20px',
  },

  divider: {
    opacity: '0.12',
    backgroundColor: '#000000',
    width: '100%',
    height: '1px',
  },

  pdf: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    letterSpacing: '0.5px',
    lineHeight: '20px',
    marginTop: '15px',
  },

  divider_1: {
    opacity: '0.12',
    backgroundColor: '#000000',
    width: '91%',
    height: '1px',
    marginLeft: '30px',
  },

  innerbox: {
    width: '335px',
    margin: '0px auto',
    marginTop: '25px',
    height: '120px',
    ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
      // transform: 'scale(0.7)'
      width: '310px'
    }
  },

  widd: {
    width: '15%',
    margin: '0 auto'
  },
  secondcss: {
    marginTop: '20px',
  },
  reviewloop: {
    width: '100%',
    padding: '50px',
    overflow: 'hidden',
    ['@media (max-width:650px)']: { // eslint-disable-line no-useless-computed-key
      // transform: 'scale(0.7)'
      width: '100%',
      padding: '20px',
    }
  },
  scale: {
    ['@media (max-width:750px)']: { // eslint-disable-line no-useless-computed-key
      // transform: 'scale(0.7)'
    }

  },
  refresh: {
    width: '5%',
    float: 'left',
    // ['@media (max-width:650px)']: { // eslint-disable-line no-useless-computed-key
    //   width: '100%'
    // }
  },
  butarea: {
    marginTop: '40px',
    ['@media (max-width:700px)']: { // eslint-disable-line no-useless-computed-key
      marginTop: '0px',
    }
  },
  refresh_size: {
    width: '50px', color: '#535D6D', height: '50px', textAlign: 'center',
    ['@media (max-width:650px)']: { // eslint-disable-line no-useless-computed-key
      width: '30px',
      height: '30px',
    }
  },
  refresh_1: {
    width: '15%',
    float: 'left',
    // ['@media (max-width:650px)']: { // eslint-disable-line no-useless-computed-key
    //   width: '100%'
    // }
  },
  refresh_img: {
    width: '85%',

    // ['@media (max-width:650px)']: { // eslint-disable-line no-useless-computed-key
    //   width: '100%'
    // }
  },
  downloadd: {

    width: '180px',
    height: '40px',
    color: "#FFF",
    fontFamily: "Montserrat",
    borderRadius: '4px',
    fontSize: '1.6em',
    fontWeight: "600",
    letterSpacing: "0.53px",
    lineHeight: "19px",
    textAlign: "center",
    ['@media (max-width:950px)']: { // eslint-disable-line no-useless-computed-key
      width: '150px',
      fontSize: '1.3em',
    },
    ['@media (max-width:750px)']: { // eslint-disable-line no-useless-computed-key
      width: '120px',
      fontSize: '1em',
    }

  },
  topbottom: {
    marginTop: '18px',
    ['@media (max-width:650px)']: { // eslint-disable-line no-useless-computed-key
      marginTop: '10px',
    }
  },
  pdffile: {
    color: 'rgba(18,18,18,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    letterSpacing: '0.4px',
    lineHeight: '16px'
  },
  adjust: {
    ['@media (max-width:650px)']: { // eslint-disable-line no-useless-computed-key
      width: '77% !important',
      marginLeft: '14px !important'
    },
  },
  dataa: {
    width: '85%',
    float: 'left',
    ['@media (max-width:650px)']: { // eslint-disable-line no-useless-computed-key
      width: '83%',
      marginLeft: '16px !important'
    },
    marginLeft: '25px'
  },
  fixx: {
    ['@media (max-width:700px)']: { // eslint-disable-line no-useless-computed-key

      marginLeft: '6px !important'
    },
    ['@media (max-width:400px)']: { // eslint-disable-line no-useless-computed-key

      marginLeft: '13px !important'
    },
  },
  secincomp: {

    fontFamily: 'Montserrat',
    fontSize: '16px',
    letterSpacing: '0.5px',
    ['@media (max-width:650px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: '13px',
    },
    lineHeight: '20px',
    color: 'rgba(0,0,0,0.38)',
  },
  bankruptcy: {
    fontFamily: 'Montserrat',
    fontSize: '20px',
    letterSpacing: '0.15px',
    lineHeight: '24px',
    color: 'rgba(0,0,0,0.87)',
    marginTop: '10px'
  },
  bankruptcy_1: {
    fontFamily: 'Montserrat',
    fontSize: '15px',
    letterSpacing: '0.15px',
    lineHeight: '24px',
    color: 'rgba(0,0,0,0.87)',
    marginTop: '10px',
    ['@media (max-width:500px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: '12px',
    },
    ['@media (max-width:330px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: '8px',
    },
  },
  firstHead: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '23px',
    letterSpacing: '0.18px',
    lineHeight: '24px',
    marginBottom: '10px',
    ['@media (max-width:650px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: '21px'
    },
  },


  submitby: {
    color: 'rgba(0,0,0,0.38)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    letterSpacing: '0.5px',
    lineHeight: '20px',
  },
  datee: {
    color: 'rgba(0,0,0,0.74)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    letterSpacing: '0.5px',
    lineHeight: '20px',
  },
  lossmitigation: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '24px',
    letterSpacing: '0.18px',
    lineHeight: '24px',
  },
  secondtHead: {
    color: 'rgba(0,0,0,0.74)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    letterSpacing: '0.5px',
    lineHeight: '20px',
  },
  pending: {
    width: '100%',
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '24px',
    letterSpacing: '0.18px',
    lineHeight: '24px',
    textAlign: 'center',
    ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: '21px'
    },
  },

  textField: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: '600'
  },

  formText: {
    color: '#535D6D',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: '600',
    letterSpacing: '0.4px',
    lineHeight: '22px'
  },

  cancelAccButton: {
    fontFamily: 'Montserrat',
    letterSpacing: '0.36px',
    fontWeight: '600',
    marginRight: '25px',
    width: '180px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '28px',
      marginRight: 0,
    },
  },
  addAccButton: {
    width: '180px',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    textTransform: 'uppercase'
  },
  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },


  errorMsg: {
    fontSize: '1.4em',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
    marginTop: 5,
    fontFamily: 'Montserrat',
    color: "red",
  },

  validationErr: {
    fontSize: '12px',
    fontWeight: '500',
    top: 3,
    left: 0,
    fontFamily: 'Montserrat',
    color: "red",
  },

  loaderRoot: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  rowMargin: {
    marginBottom: 40,
  },

  infoBox: {
    borderRadius: '6px',
    backgroundColor: '#3064A6',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
    padding: '10px 60px',
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px',
    },
  },

  loaderRoot: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  dataErrorMsg: {
    width: '100%',
    height: '50vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'red',
    fontSize: '22px',
  },
  actionBtn: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    letterSpacing: "0.36px",
    lineHeight: "19px",
    cursor: "pointer",
    paddingRight: "0px",
    paddingTop: "8px",
    verticalAlign: "top"
  },
  dataErrorMsg_1: {
    width: '100%',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'red',
    fontSize: '22px',
  },

  errorMsg: {
    fontSize: '14px',
    fontWeight: '500',
    fontFamily: 'Montserrat',
    color: "red",
    marginBottpm: '10px'
  },
  buttoncheckarea: {
    width: '100%',
    overflow: 'hidden'
  },
  firstareaa: {
    width: '100%',
    display: 'flex'
  },
  inerr: {
    flexGrow: '1'
  },
  new_inerr: {
    flexGrow: '0.24'
  },
  inerr_1: {
    flexGrow: '0.36'
  },
  inerr_1_p: {
    float: 'left',
    width: '28%'
  },
  inerr_1_p_1: {
    float: 'left',
    width: '62%'
  },
  firstareaa_1: {
    display: 'block',
    overflow: 'hidden'
  },
  fullarea: {
    width: '100%',
    display: 'block'
  },
  full_1: {
    width: '45%',
    float: 'left',
    marginRight: '35px'
  },
  full_2: {
    width: '47%',
    float: 'left',
    paddingTop: '7px'
  },
  // full_2:{
  //   flexGrow:'1',
  //   textAlign:'center',
  //   paddingTop:'7px'
  // },
  color_part: {
    fontSize: '12px',
    paddingTop: '0px',
    marginLeft: '-12px'
  },
  buttoncheck_1: {
    width: '46%',
    float: 'left'
  },
  buttoncheck_2: {
    width: '100%',
    float: 'left'
  },
  insider_1: {
    width: '40%',
    float: 'left'
  },
  insider_2: {
    width: '33%',
    float: 'left'
  },
  text_part: {
    color: 'rgba(0,0,0,0.74)',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    letterSpacing: '0.5px',
    lineHeight: '20px',
    paddingTop: '16px',
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: '14px',
    },
  },
  holder: {
    width: '100%',
    display: 'flex',
    paddingTop: '10px'
  },
  holder_1: {
    flexGrow: '0.1'
  },
  innerwid: {
    width: '100%',
    display: 'flex'
  },
  innermanageArea: {
    padding: '5px'
  },
  iconss: {
    color: '#535D6D',
    width: '30px',
    height: '30px'
  },
  innermanageArea_1: {
    padding: '10px'
  },
  success: {
    color: 'green'
  },
  data_part: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '600',
    letterSpacing: '0.5px',
    lineHeight: '20px',
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: '13px',
    },
  },
  namee: {
    fontSize: '14px',
    marginTop: '18px',
    textAlign: 'center',
    fontFamily: "Montserrat",
    fontWeight: '500',
  },
  inner_part_1: {
    width: '25%',
    float: 'left'
  },

  loaderBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  tableErrorMsg: {
    fontSize: '14px',
    fontWeight: '500',
    fontFamily: 'Montserrat',
    color: "red",
    marginTop: '20px',
    marginBottpm: '20px'
  },

  outLinedBtn: {
    color: '#535D6D'
  },

  errMessage: {
    fontSize: '14px',
    fontWeight: '500',
    fontFamily: 'Montserrat',
    color: "red",
    marginTop: '5px',
    marginBottpm: '5px',

  },

  successMsg: {
    fontSize: '14px',
    fontWeight: '500',
    fontFamily: 'Montserrat',
    color: "green",
    marginTop: '5px',
    marginBottpm: '5px',
  },

  disableBtnLoader: {
    width: '24px !important', height: '24px !important'
  },

  noDataFoundText: {
    textAlign: 'center',
    fontSize: '14px',
    padding: '10px 0',
  },

  dataLoaderCir: {
    marginTop: '15px',
    marginBottom: '15px',
    zIndex: '999999',
    margin: '10px auto',
    display: 'block',
  },

  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%;',
    '& button': {
      width: '48%'
    }
  },

  link: {
    color: "rgb(126, 177, 204)",
    fontFamily: "Montserrat",
    fontSize: "16px",
    textDecoration: 'underline',
    cursor: "pointer",
    fontWeight: "bold",
    padding: "0 8px",
    textTransform: 'capitalize'
  },

  deleteBtn: {
    color: "#B00020",
  },

}));