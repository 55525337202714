import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },

  fadePageName: {
    color: '#4D4D4D',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.5px',
    lineHeight: 'normal',
    textDecoration: 'none',
    cursor: 'pointer',
    fontStyle: 'normal'
  },
  activePageName: {
    color: '#121D22',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.5px',
    lineHeight: 'normal',
  },

  MuiBreadcrumbsSeparator: {
    color: '#2B2D30',
    fontFamily: 'Montserrat',
    marginLeft: 2,
    marginRight: 2
  },
  BreadCrum: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '8px'
    },
  },
}));

export default function CustomBreadcrum(props) {
  const mytheme = useSelector(state => state.theme);
  const { primaryColorCode } = mytheme;
  const classes = useStyles();
  const { backPage, backPageLink, activePage } = props;
  return (
    <div className={classes.root} style={{ width: '100%', marginBottom: '0', marginTop: '0' }}>
      <Box className={classes.BreadCrum} aria-label="breadcrumb" display="flex" alignItems="center">
        {/* <Hidden only={['sm', 'md', 'lg']}>
          <NavigateBeforeIcon className={classes.MuiBreadcrumbsSeparator} />
        </Hidden> */}
        <Typography className={classes.fadePageName} color="inherit" component={Link} to={backPageLink}>
          {backPage}
        </Typography>
        {/* <Hidden only={'xs'}>
          <NavigateNextIcon className={classes.MuiBreadcrumbsSeparator} />
        </Hidden> */}
        <Hidden>
          <NavigateNextIcon className={classes.MuiBreadcrumbsSeparator} />
        </Hidden>
        <Hidden >
          <Typography className={classes.activePageName}>{activePage}</Typography>
        </Hidden>
      </Box>
    </div>
  );
}
