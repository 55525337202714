import React from 'react';
import { useStyles } from './styles';
import { Box, Typography, Dialog, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { getMortgageLabel, getLoanLabel, getAccountLabel, isHEI } from '../../helpers';
import { getDocLanverDetails } from '../../actions';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PhoneIcon from '@material-ui/icons/Phone';
import InfoIcon from '@material-ui/icons/Info';
import tick from '../../assets/tick.png';
import Cookies from 'js-cookie';
import moment from 'moment';

export const TransferInfoAlert = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const browserName = Cookies.get('browser');
  const privateLabelUrl = Cookies.get("privateLabelUrl")

  const handleDocumentCenter = async () => {
    if (browserName.includes('Safari ')) {
    const res = await getDocLanverDetails(props.selectedAccount);
      if (res) {
      var newWin = window.open(res.url,"_blank");             
      if(!newWin || newWin.closed || typeof newWin.closed=='undefined') 
        { 
            alert('A popup blocker is preventing the application from opening the page. To allow pop-ups, go to Safari app and choose Safari>Preferences, then click websites and allow popup for your website.')
        }
      }
    } else {
      history.push('/DocumentCenter')
    }
  }

  return (
  
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={props.closePopup} open={props?.transferAlertStatus}>
      <IconButton onClick={props.closePopup} className={classes.closeIcon}>
        <CancelIcon />
      </IconButton>
      <Box className={classes.root}>
        
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>{(props?.liquidationType == 'purgedDays') ? 'Account Access Info' : 'New Servicer Info'}</Typography>
        </Box>

        { (props?.liquidationType == 'purgedDays') &&  
          <Box className={classes.newServicer}>
            <Grid container direction="row" alignItems="center" style={{ paddingLeft: '5px' }}>
              <Grid item md={12} xs={12} lg={12}>
                <Box display='flex' alignItems="center">
                  <InfoIcon className={classes.paidInFullIcon} />
                  <Typography className={classes.information}>Your {getLoanLabel()?.toLowerCase()} has been transferred to new servicer.</Typography>
                </Box>
              </Grid>
            </Grid>
         </Box>}

        <Box className={classes.transferBox}>
          <Box alignItems="center" >
            <Typography className={classes.informationText}>New Servicer: <span className={classes.servicerText}> {props?.loanInfoData.newServicerName}</span></Typography>
            {((props?.loanInfoData.newServicerPhone !== null) && (props?.loanInfoData.newServicerPhone !== 'null')) &&
              <Box style={{display: 'flex', alignItems: 'center'}}>
               <Typography className={classes.callUs}>If you need help or have questions, please don't hesitate to call your new servicer at
               <PhoneIcon mr={2} className={classes.iconStyle} />
                <a href={"tel:" + props?.loanInfoData.newServicerPhone} style={{textDecoration: 'none', cursor: 'pointer'}}> <span className={`${classes.contactTime} ${classes.noUnderLine}`} >{props?.loanInfoData.newServicerPhone}</span></a></Typography>
                </Box>
              }
          </Box>
         </Box>

        {(props?.liquidationType == 'purgedDays') &&
        <Box className={classes.cardContainer}>
              <Typography className={classes.paragraph}>We would like to inform you that your {getMortgageLabel()?.toLowerCase()} {getLoanLabel()?.toLowerCase()} has been closed with us. As a result, you will no longer be able to access your {getAccountLabel()?.toLowerCase()} after {moment(props?.loanInfoData?.purgedDate).format('MM/DD/YYYY')}.</Typography>
              <Box style={{display: 'flex'}}><img src={tick} alt="check icon" className={classes.tick}/><Typography className={classes.paragraph}>For future reference, you can save/print any important information from the {getAccountLabel()?.toLowerCase()}.</Typography></Box>
              <Box style={{display: 'flex'}}><img src={tick} alt="check icon" className={classes.tick}/><Typography className={classes.paragraph}>You can view/download the available documents in  <a className={classes.link} onClick={() => handleDocumentCenter()}>document center</a> as usual before.</Typography></Box>
        </Box>}
       
        {(props?.liquidationType !== 'purgedDays') &&
        <Box className={classes.cardContainer}>
              <Box style={{display: 'flex'}}><img src={tick} alt="check icon" className={classes.tick}/><Typography className={classes.paragraph}>You will now only have view rights, which will allow you to access information about your {getLoanLabel()?.toLowerCase()} balance, payment history, and {getMortgageLabel()?.toLowerCase()} details. If you need to update any information, please contact the new servicer directly.</Typography></Box>
              <Box style={{display: 'flex'}}><img src={tick} alt="check icon" className={classes.tick}/><Typography className={classes.paragraph}>You can view/download the available documents in  <a className={classes.link} onClick={() => handleDocumentCenter()}>document center</a> as usual before.</Typography></Box>
              { ((privateLabelUrl.toLowerCase() !== "aafmaa") && !isHEI()) &&
              <Box style={{display: 'flex'}}><img src={tick} alt="check icon" className={classes.tick}/><Typography className={classes.paragraph}>You can also request any missing {getMortgageLabel()?.toLowerCase()} document from <a className={classes.link} onClick={() => history.push('/DocumentRequest')}>document request</a> feature.</Typography></Box> }
        </Box>}
      </Box> 
   </Dialog>
  )
}