import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 12,
    backgroundColor: "#FFFFFF",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    padding: '2em',
    height: '85vh',
    overflowY: 'auto',
    [theme.breakpoints.down('xs')]: {
      padding: '1.6em',
    },
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxWidth: '100em',
    minWidth: '600px',
    maxHeight: "100%",
    backgroundColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '95%',
      minWidth: 'auto',
      top: '10%',
    },
  },

  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },

  iconStyle: {
    padding: 0,
    color: '#535D6D',
    fontSize: '24px'
  },

  body: {
    width: '100%',
    padding: '10px 20px 30px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 5px',
    },
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '20px',
    fontWeight: "500",
    letterSpacing: '0.15px',
    lineHeight: '20px',
    paddingBottom: '17px',
    paddinTop: '7px',
  },

  subTitle: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '17px',
    fontWeight: "500",
    letterSpacing: '0.15px',
    lineHeight: '20px',
    paddingBottom: '17px',
  },

  textBoxTitle: {
    marginTop: '30px',
    marginBottom: '20px',
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '18px',
    fontWeight: "500",
    letterSpacing: '0.15px',
    lineHeight: '20px',
  },

  flexBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  bobyText: {
    color: "rgba(0,0,0,0.74)",
    fontFamily: "Montserrat",
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '20px',
    marginBottom: '10px',
    height: '40px'
  },

  bobyTextBold: {
    fontWeight: '600',
  },

  textField: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: '500'
  },

  inputDate: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: '600'
  },

  textInput: {
    fontWeight: '600'
  },

  resetButton: {
    fontFamily: 'Montserrat',
    letterSpacing: '0.36px',
    fontWeight: '600',
    marginRight: '30px',
    width: '250px',
    height: '40px',
    border: "2px solid",
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      border: "2px solid",
      borderColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '28px',
      marginRight: 0,
    },
  },

  submitBtn: {
    width: '250px',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    textTransform: 'uppercase',
    height: '40px',
  },

  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  radioBtn: {
    '& .Mui-checked': {
      color: '#000'
    },
  },

  helperText: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: "13px",
    fontWeight: '500',
    marginTop: '20px'
  },


  errorMsg: {
    fontSize: '1.4em',
    fontWeight: '600',
    textAlign: 'center',
    marginBottom: 10,
    marginTop: 5,
    fontFamily: 'Montserrat',
    color: "red",
  },

  validationErr: {
    fontSize: '12px',
    fontWeight: '500',
    top: 1,
    left: 0,
    fontFamily: 'Montserrat',
    color: "red",
    margin: '2px 0'
  },

  loaderRoot: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: "#fff",
  },

}));

