import React, { Component } from 'react';
import PropTypes from 'prop-types';

const defaultButtonStyles = {
  fontSize:'16px',
  background: "#34495e",
  color: "#fff",
  fontWeight: "600",
  zIndex: 99,
  padding:'16px 29px 4px 59px',
  position:'fixed',
  borderRadius:'5px 5px 0 0',
  border: 'none',
  bottom:0,
  backgroundColor:'#1A4664',
  // width: 'auto',
  cursor: 'pointer',
  minWidth: '222px !important',
  right: '1% !important',
  ['@media (max-width:720px)']: {
    padding:'16px 12px 4px 34px !important',
  }
}


class Button extends Component {

  render(){
    const { handleButtonClick, text, styles, position, handleCustomPosition, disableButton, styleBtn } = this.props;
    return(
      <button type='button' onClick={!disableButton && handleButtonClick} className={styleBtn}
      // style={handleCustomPosition(position, styles)}
      >
        {text}
      </button>
    )
  }
}

Button.propTypes = {
  handleCustomPosition: PropTypes.func,
  handleButtonClick: PropTypes.func,
  styles: PropTypes.object,
  text: PropTypes.string,
  position: PropTypes.string
}

Button.defaultProps = {
  position: PropTypes.string,
  // styles: defaultButtonStyles,
  text: 'Document Request Feedback',
  handleButtonClick: () => this.handleButtonClick
}


export default Button;
