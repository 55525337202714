import React from 'react';
import { useStyles } from './styles';
import { Box, Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

export const LogoutConfirmation = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={props.handleCancelIcon} open={props.open}>
     
      <Box className={classes.root}>
          <Box className={classes.cardBody}>
            <Box display='flex' alignItems="center" className={classes.logoutIcon}>
             <ExitToAppIcon />
             <Typography className={classes.statusTitle}> Are you sure you want to logout ?</Typography>
            </Box>
        </Box>
        <Box className={classes.cardFooter}>
          <Box className={classes.footerItem}>
            <Button
              variant='contained'
              className={classes.btn}
              onClick={props.handleSubmitClose}
            > Cancel</Button>
          </Box>
          <Box className={classes.footerItem}>
            <Button
              variant='contained'
              color='primary'
              className={classes.btn}
              onClick={props.handleSubmit}
            > Ok</Button>
          </Box>
        </Box>
      </Box> 
    </Dialog>
  )
}
