import Cookies from 'js-cookie';
import { axiosProtected } from '../config';
import { getAppSource, getUserType } from '../helpers';

export const getDisasterAssistanceStatus= async (accountNum) => {
  const data = {
    "accountNumber": accountNum,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": "",
  };
  try {
    const response = await axiosProtected.post('/DisasterRelief/GetRequestStatus', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const getBorrowerDetails = async (accountNum) => {
  const data = {
    "accountNumber": accountNum,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": "",
  };
  try {
    const response = await axiosProtected.post('/DisasterRelief/GetBorrowerDetail', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const saveDisasterAssistance = async (data) => {
  try {
    const response = await axiosProtected.post('/DisasterRelief/SubmitRequest', data);
    return response.data;
  } catch (e) {
    return null
  }
}
