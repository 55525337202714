import { makeStyles, withStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '850px',
    minWidth:'850px',
    borderRadius: 12,
    backgroundColor: 'white',
   // boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('md')]: {
      width: '100%',
      minWidth:'100%'
     
    },
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxHeight: "100%",
    minWidth:'850px',
    top: '50px',
    position: 'fixed',
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      minWidth:'100%',
      padding: '20px',
      margin: '50px 15px'
    },
  },


  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  heading: {
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    marginTop: 10
  },
  progress: {
    textAlign: 'end',
    marginTop: '20px'
  },
  progressText: {
    color: '#555D6C',
    textAlign: 'right',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    padding:'0px 10px'
  },
  colorPrimary: {
    backgroundColor: '#D9D9D9',
    height: '20px',
    borderRadius: '20px'
  },
  barColorPrimary: {
    backgroundColor: '#EDB36A',
  },

  question: {
    color: '#000',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '32px',
    marginLeft:'5px'
  },
  responses: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    padding: '0px 25px'
  },
  slider:{
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0px 25px'
  },
  radioInput:{
   display:'none'
  },
  textareaSection: {
    paddingTop: '16px',
    '& .MuiFormLabel-root': {
      asterisk: {
        color: 'red',
        '&$error': {
          color: 'red'
        },
      }
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '15px'
    },
  },
  textAreaValidation: {
    color: '#202020',
    textAlign: 'right',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '24px'
  },
  textArea: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 500,
    width: '100%',
    padding: "10px",
    height: '56px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: 'transparent',
    outline: 'none',
    border: '1px solid #9E9E9E',
    "&::placeholder": {
      fontSize: '14px',
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '1rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      lineHeight: 1
    }
  },
  ratingLabel: {
    color: '#121D22',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,

    lineHeight: 'normal'
  },


  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "400",
    textAlign: 'center',
    margin: 14,
  },

  cardBody: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 2em',
   // boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  cardBody2: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '8px 2em',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  statusTitle: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '18px',
    fontWeight: "700",
    padding: '3px 2px 2px 3px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px'
    }
  },

  statusBody: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "bold",
  },

  linkStyle: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    fontWeight: "600",
    marginTop: 5,
  },
  singleFooterItem: {
    textAlign: 'center',
    padding: '0px 20px 20px'
  },
  btn: {
    width: 188,
    height: '38px',
    fontFamily: "Montserrat",
    fontSize: '1.3em',
    fontWeight: "600",
    margin: "10px",
    '&.documentBtn': {
      width: 'max-content'
    },
    [theme.breakpoints.down('sm')]: {
      width: 100,
      fontSize: '1em',
      height: '35px'
    }
  },

  closeIcon: {
    position: 'absolute',
    right: 42,
    paddingTop: 18,
    [theme.breakpoints.down('sm')]: {
      right: 10
    }
  },
  closeIco: {
    position: 'absolute',
    right: -5,
    paddingTop: 7,
    [theme.breakpoints.down('sm')]: {
      right: 10
    }
  },

  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },

  inputStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600"
  },

  labelStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "400",
  },
  maxHeight:{
     maxHeight:'320px',
     overflow:'auto',
     padding: '0px 16px',
     marginTop:'25px'
  },
  starComponent:{
    display: 'flex',
    //marginBottom: 16,
    [theme.breakpoints.down('xs')]: {
      display:'block'
    },
  },
  thankyouText:{
    color: '#000',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '28px',
    marginTop:'10px'
  },
  thankyouHeading:{
    color: '#000',
    fontFamily: 'Montserrat',
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '28px',
  },
  cardContainerThnks:{
      padding:'20px',
      [theme.breakpoints.down('sm')]: {
       overflow: 'auto',
       maxHeight: '370px'
    }
  },  
  cardFooter: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent:'center',
    // backgroundColor: "#fff",
   // padding: '8px 95px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '8px 13px 0'
    },
  },

  footerItem: {
    marginBottom: 33,
    maxWidth: 175,
    marginRight: 25
  },

  boxMargin: {
    marginLeft: 75
  },
  
  errorMsg: {
    fontSize: '1.4em',
    fontWeight: '400',    
    marginBottom: 10,
    marginTop: 5,
    fontFamily: 'Montserrat',
    color: "red",
    marginLeft:'16px',

  },
  paragraph: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: 15,
    fontWeight: "500",
    marginBottom: 8,
    textAlign: 'center'
  },

  proceed: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: 15,
    fontWeight: "700",
    marginBottom: 0,
    marginTop: 22,
    textAlign: 'center',
  },


  cardContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'left',
    textAlign:'left'
    //marginTop: 15,
  },

  card1: {
    maxWidth: '100%',
    padding: '10px',
    borderRadius: 12,
    border: '1px solid #EDB36A',
    backgroundColor: "#FBF0E1",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    marginBottom: 20,
    // display: 'flex',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      width: '100%',
      padding: '15px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    }
  },

  cardIcon: {
    width: 28,
    height: 22,
    color: "#EDB36A",
    marginTop: 22,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
    },
  },

  cardContent: {
    display: 'flex'
  },
  radioLabel:{
    borderRadius: '16px',
    border: '2px solid #D9D9D9',
    background: '#FFF',
    padding: '14px 64px',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 14px',
    }
  },
  radioLabel1:{
    '&:hover':{
      background: '#FF5A5A'
    },
    '&.checked':{
      background: '#FF5A5A'
    }
  },
  radioLabel2:{
    '&:hover':{
      background: '#FCB243'
    },
    '&.checked':{
      background: '#FCB243'
    }
  },
  radioLabel3:{
    
    '&:hover':{
      background: '#FFDF70'
    },
    '&.checked':{
      background: '#FFDF70'
    }
  },
  radioLabel4:{
   
    '&:hover':{
      background: '#8AF171'
    },
    '&.checked':{
      background: '#8AF171'
    }
  },
  radioLabel5:{
   
    '&:hover':{
      background: '#67C650'
    },
    '&.checked':{
      background: '#67C650'
    }
  },
  sliderLabel:{
    display: 'flex',
    padding: '12px 56px',
    justifyContent: 'space-between',
    '& .text':{
      color: '#000',
      fontSize: '18px',
      fontWeight: 500
    },
    '& .first':{
      marginRight:'300px',
      [theme.breakpoints.down('xs')]: {
       marginRight:'100px'
      }
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10px 10px',
      //justifyContent: 'space-around'

    }
  },
  radioLabelText:{
    color: '#000',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
  },

  cardTitle: {
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "600",
    marginBottom: '20px',
    letterSpacing: '0.15px',
    linHheight: '24px',
  },

  disableBtn: {
    color: "#121D22 !important",
    backgroundColor: '#BBB !important',
    cursor: "pointer",
    width: 188,
    fontFamily: "Montserrat",
    margin: "10px",
    '&:hover': {
      backgroundColor: "#BBB",
    },
    [theme.breakpoints.down('xs')]: {
      width: '105px'
    }
  },
  paragraph: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: "500",
    marginBottom: 8,
    textAlign: 'center',
  },
  call: {
    color: '#1A4664',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .ico': {
      height: '20px',
      width: '20px',
      marginRight: '8px'
    }
  },
  Divider: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '15px 0px',
    [theme.breakpoints.down('xs')]: {
      margin:'5px 0px',
    }
  },
  DividerText: {
    color: '#121D22',
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    margin: '0px 5px'
  },
  innerContainer:{
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin:'20px 0px',
    [theme.breakpoints.down('xs')]: {
      margin:'8px 0px',
    }
  },
  btnText: {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: '18px',
    marginBottom:'10px'
  }

}));

