import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '53px 0',
  },

  title: {
    color: "#193559",
    fontFamily: "Montserrat",
    fontSize: " 2.4em",
    fontWeight: "600",
    marginBottom: 30,
  },

  button: {
    fontFamily: "Montserrat",
    fontSize: 16,
    letterSpacing: '1px',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      margin: '10px auto 0',
    }

  },

  actionsContainer: {
    // marginBottom: theme.spacing(2),
  },

  aciveLabelStyle: {
    color: "rgba(0,0,0,0.87) !important",
    fontFamily: "Montserrat",
    fontSize: " 20px",
    letterSpacing: "0.15px",
    fontWeight: "400",
    cursor: "pointer",
  },

  labelStyle: {
    color: "rgba(0,0,0,0.87) !important",
    fontFamily: "Montserrat",
    fontSize: " 20px",
    letterSpacing: "0.15px",
    fontWeight: "400",
    cursor: "pointer",
  },

  labelContainer: {
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
    paddingBottom: 10,
  },

  stepContent: {
    marginTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },

  paymentFrequency: {
    display: 'block',
    position: 'relative',
    width: '100%',
    padding: '30px 20px 20px',
    backgroundColor: "#fff"
  },

  stepperStyle: {
    padding: 0,
  },

  flexContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    // marginBottom: 14,
  },

  activeRadioLabel: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
  },

  marginLeftFix: {
    marginLeft: 30,
  },

  radioLabel: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: "600",
  },

  payAmount: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: "600",
  },

  payTotalAmount: {
    color: "rgb(0, 0, 0)",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: "600",
  },

  payDetailItem: {
    marginBottom: 25,
  },

  subText: {
    color: "rgba(0,0,0,0.87)",
    fontWeight: '500',
    letterSpacing: "0.25px",
    fontFamily: "Montserrat",
    fontSize: " 12px",
    marginLeft: 30,
    marginTop: 12,
    marginBottom: 30,
    [theme.breakpoints.down('md')]: {
      marginBottom: 20,
    }
  },

  subTextEdited: {
    color: "rgba(0,0,0,0.87)",
    fontWeight: '500',
    letterSpacing: "0.25px",
    fontFamily: "Montserrat",
    fontSize: " 12px",
    marginTop: 12,
  },

  subTextBold: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Montserrat",
    fontWeight: '600',
    letterSpacing: "0.25px",
    fontSize: " 12px",
    marginLeft: 30,
    [theme.breakpoints.down('md')]: {
      marginBottom: 20,
    }
  },

  paymentFrequencyType: {
    borderBottom: "solid 1px rgba(0, 0, 0, 0.12)",
    marginBottom: 14,
  },

  radioStyle: {
    padding: '0 9px',
    color: '#535D6D !important'
  },

  buttonContainer: {
    width: "100%",
    display: 'flex',
    justifyContent: 'flex-end'
  },

  additionalPayTextFieldContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: 14,
  },

  divider: {
    height: 100,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    }
  },

  additionalPayTextField: {
    width: '40%',
    marginBottom: 30,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: 40,
    }

  },

  textField: {
    color: "rgba(0,0,0,0.74)",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: '400'
  },

  flexEndBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  linkStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: " 1.4em",
    fontWeight: '600',
    // textDecoration: 'underline',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      margin: '0 auto 20px'
    }
  },

  paymentInfo: {
    display: 'block',
    position: 'relative',
    width: '100%',
    padding: '30px 20px 20px',
    backgroundColor: "#fff"
  },

  dueDate: {
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: '600',
    fontFamily: "Montserrat",
    fontSize: " 1.4em",
  },

  addAccountBtn: {
    color: "#535D6D",
    textTransform: "capitalize",
    fontFamily: "Montserrat",
    fontSize: " 1.4em",
    fontWeight: '600',
    textDecoration: 'underline',
    [theme.breakpoints.down('sm')]: {
      fontSize: " 1.2em",
    },
  },

  formControl: {
    width: '45%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 20,
    },
    '& .MuiFormControl-marginNormal': {
      marginTop: '0',
    }
  },
  loanDetail:{
    padding:'20px 40px 30px',
    fontSize:'15px',
    color:'#535D6D',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    textAlign:'justify'
  },
  linkStyle2:{
     color : '#007FFF',
     cursor:'pointer',
     textDecoration:'underline'
  },
  closeIcon:{
    // float:'right',
    cursor:'pointer',
    width:'18px',
    height:'18px',
    position:'absolute',
    marginTop: '3px',
    marginRight: '3px',
    right:'0px'
 },
 innerContent:{
  position: 'absolute',
  overflowY: 'auto',
  height: '94%',
  width:'100%',
  padding:'0px 0px 21px 20px',
  marginTop:'24px'
 },
 howItWorks:{
  position: 'absolute',
  top: '10%',
  left: '10%',
  width: '80%',
  background: '#ffffff',
  height: '65%',
  // overflow: 'auto',
  margin: '0px auto'
 },
 howDWork:{
  color:' #007FFF',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '17px',
  textDecoration: 'underline',
  cursor:'pointer',
  paddingLeft: '10px'
 },
  formControlBiw: {
    width: '30.33%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 20,
    },
    '& .MuiFormControl-marginNormal': {
      marginTop: '0',
    }
  },

  confirmPayment: {
    display: 'block',
    position: 'relative',
    width: '100%',
    padding: '30px 20px 20px',
    backgroundColor: "#fff"
  },

  printButton: {
    padding: 0,
    margin: '10px 0'
  },

  printForm: {
    fontSize: '14px',
    color: '#535D6D',
    fontFamily: "Montserrat",
    fontWeight: '600',
    textDecoration: 'underline',
  },

  confirmPaymentFlex: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: 14,
  },

  paymentDetailBox: {
    width: '100%',
  },

  paymentDetailBoxItem: {
    width: '48%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  paymentDetailTitle: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: "2em",
    fontWeight: "400",
    paddingBottom: 10,
    borderBottom: 'solid 1px rgba(0,0,0,0.12)',
    marginBottom: 20,
  },

  totalPayBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: "solid 1px rgba(0,0,0,0.12)",
    padding: '1em 0',
  },

  totalText: {
    color: "rgba(0,0,0,1)",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: "600",
  },

  termAndCondition: {
    width: '48%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },


  termAndConditionBox: {
    width: '100%',
    maxHeight: 116,
    padding: '10px 5px',
    overflowY: 'auto',
    border: "solid 1px rgba(0,0,0,0.12)",
  },

  // term n condition api data css
  printIcon: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '7px',
    paddingRight: 0,
  },
  printIconText: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer',
  },
  tncTextContainer: {
    color: "rgba(0, 0, 0, 0.14)",
    fontFamily: "Montserrat",
    fontSize: " 1.6em",
    fontWeight: '400',
    letterSpacing: "0.36px",
  },
  // end

  termTitle: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: "2em",
    fontWeight: "400",
    paddingBottom: 10,
    borderBottom: 'solid 1px rgba(0,0,0,0.12)',
    marginBottom: 20,
  },

  paymentFrequencyEdited: {
    width: '100%',
    padding: '30px 20px 0px',
    cursor: "pointer",
  },

  additionalPaymentEdited: {
    width: '100%',
    padding: '30px 20px 0px',
    cursor: "pointer",
  },

  amaountSpan: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: '600',
  },


  amaountSpanLight: {
    color: "rgba(0, 0, 0, 0.74)",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: '400',
  },

  secondDraftDate: {
    color: "rgba(0, 0, 0, 0.74)",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: '400',
    marginTop: 6
  },

  paymentInfoEdited: {
    width: '100%',
    padding: '30px 20px 0px',
    cursor: "pointer",
  },

  paymentInfoDueDate: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 14px",
    fontWeight: '400',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  },

  paymentInfoEditedItem: {
    marginBottom: 0,
  },

  editedText: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
  },


  aditionalEditedText: {
    color: "rgba(0,0,0,0.74)",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "400",
    marginBottom: 10,
  },

  paymentDayComponent: {
    border: '2px solid #25abc7',
    borderRadius: '30px',
    position: 'relative',
    width: '36px',
    height: '36px',
    lineHeight: '34px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiPickersDay-daySelected': {
      background: '#25abc7',
      color: '#fff',
    },
    '& .MuiPickersDay-hidden': {
      opacity: '0.3',
    }
  },

  greenZoneComponent: {
    // border: '2px solid #25ABC7',
    '& .MuiPickersDay-daySelected': {
      border: '2px solid #25ABC7',
      background: '#25ABC7',
      color: '#fff',
    },
    '& .MuiPickersDay-hidden': {
      opacity: '0.3',
    }
  },

  redZoneComponent: {
    '& .MuiPickersDay-daySelected': {
      border: '2px solid #EB4F4F',
      background: '#EB4F4F',
      color: '#fff',
    },
    '& .MuiPickersDay-hidden': {
      opacity: '0.3',
    }
  },

  warningText: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: "16px",
  },

  warningRedText: {
    color: '#EB4F4F',
    display: "flex",
    alignItems: "center",
  },

  errMsg: {
    color: "#EB4F4F",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: '500',
    marginBottom: 10,
  },

  payFrequencyAmount: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: "600",
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'right',
      marginTop: 10,
    }
  },

  confirmAmauntSpan: {
    marginRight: 40,
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    }
  },

  inCompleteIcon: {
    color: 'rgba(0, 0, 0, 0.87)',
  },

  completeIcon: {
    color: '#7EB1CC',
  },

  amortizationIcon: {
    width: 200,
    height: 44
  },

  calcBtn: {
    cursor: "pointer"
  },

  errorMsg: {
    color: 'red',
    fontSize: 16,
    fontFamily: 'Montserrat',
    fontWeight: '600'
  },

  errorMsg1: {
    color: 'black',
    fontSize: 14,
    fontFamily: 'Montserrat',
    fontWeight: '600'
  },

  flex: {
    display: 'flex',
    alignItems: 'center',
  },

  marginTop30: {
    marginTop: '30px'
  },
  errorMsg2: {
    marginTop: 12,
    color: 'black',
    // textAlign: 'center',
    fontSize: 12,
    fontFamily: 'Montserrat',
    fontWeight: '600',
    lineHeight: '19px',
    letterSpacing: '0.36px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      bottom: 0,
    },
  },


}));

