import React, { useState } from "react";
import { useStyles, StyledTableCell, StyledTableRow } from './styles';
import { Box, Button, Typography, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import moment from 'moment';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useEffect } from "react";
import { maskAccount } from '../../../helpers';
import { decrypt } from '../../../helpers/encrypt';

const businessMoment = require('moment-business-days');

export const LateFeeAckAlert = (props) => {
  
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [data, setData] = useState([]);
  const [checkboxState, setCheckboxState] = useState(false);
  const [selectedDate, setSelectedDate] = React.useState(businessMoment().businessAdd(3)._d);

  /***
   * When selected loan having draft date2 descrepancy
   */



  useEffect(() => {

    if (props?.selectedLoans && props?.selectedLoans.length) {
      let loan = [...props.selectedLoans];
      loan = loan.filter(item => item.isSelected === true);
      loan = loan.filter((loan) => {
        if (businessMoment(loan?.paymentDueDate, 'MM-DD-YYYY').businessDiff(businessMoment(loan?.latePaymentDate, 'MM-DD-YYYY'), true) > 0) {
          return true;
        }
        else if ((businessMoment(moment(loan?.paymentDueDate).add(loan?.daysToCalculateSecondDraftDate, 'days').format('L'), 'MM-DD-YYYY').businessDiff(businessMoment(loan?.latePaymentDate, 'MM-DD-YYYY'), true) > 0) && (props?.paymentFrequncyType == 'B')) {
          if (((loan?.lstRecurACH == null)) || (loan?.lstRecurACH && loan?.lstRecurACH[0]?.draftingOption == "B")) {
            return true;
          }
        }
      });
      loan = loan.map((data) => {
          data.draftDate2 = moment(data.paymentDueDate).add(data?.daysToCalculateSecondDraftDate, 'days').format('L');
          if (businessMoment(data.paymentDueDate, 'MM-DD-YYYY').businessDiff(businessMoment(selectedDate, 'MM-DD-YYYY'), true) > 0) {
            data.draftDate2 = moment(data.paymentDueDate).add(data?.daysToCalculateSecondDraftDate, 'days').format('L') 
          } else {
            data.draftDate2 = moment(selectedDate).add(data?.daysToCalculateSecondDraftDate, 'days').format('L') 
            data.paymentDueDate = moment(selectedDate).format('L')
          }
        return data;
      })
      setData(loan);
    }
  }, [props.selectedLoans]);

  const checkboxChangeHandler = (event) => {
    props.setBiWeeklyLateFeeAck(event.target.checked);
    setCheckboxState(event.target.checked)
  }

  const renderTableBody = () => {
    return data?.map((row, index) => {
      return (
        <StyledTableRow key={`${index}`}>
          <StyledTableCell align="center">{decrypt(row.accountNumber)}</StyledTableCell>
          <StyledTableCell align="center">{row.paymentDueDate}</StyledTableCell>
          { (props?.paymentFrequncyType == 'B') &&
            <StyledTableCell align="center">{row.draftDate2}</StyledTableCell>
          }
        </StyledTableRow>)
    })
  }

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={props?.handleCancelIcon} open={props.open}>
      <Box className={classes.root}>
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>Acknowledge Late Payment Term</Typography>
        </Box>
        <Box className={classes.cardBody2}>
          <Box className={classes.statusContainer}>
            <Box className={classes.cardContainer}>

              {!props.isForSingleLoan && <p className={classes.paragraph}>The {(props?.paymentFrequncyType == 'B') && 'first'} draft date will cause late payment fees on a monthly basis for below loan account(s). <a href="./FeesSchedule" target="_blank" className={classes.feeScedule}>Click here</a> to view the fee schedule.</p>}
              {props.isForSingleLoan && <p className={classes.paragraph}>The {(props?.paymentFrequncyType == 'B') && 'first'} draft date will cause late payment fees on a monthly basis. <a href="./FeesSchedule" target="_blank" className={classes.feeScedule}>Click here</a> to view the fee schedule.</p>}
              {!props.isForSingleLoan && <TableContainer>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Loan Number</StyledTableCell>
                      <StyledTableCell align="center">{(props?.paymentFrequncyType == 'B') ? 'First Draft Date' : 'Draft Date'}</StyledTableCell>
                      { (props?.paymentFrequncyType == 'B') &&
                        <StyledTableCell align="center">Second Draft Date</StyledTableCell>
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {renderTableBody()}
                  </TableBody>
                </Table>
              </TableContainer>}
              <Box style={{ paddingTop: '15px' }}>
                <FormGroup row>
                  <FormControlLabel
                    control={<Checkbox
                      checked={checkboxState}
                      onChange={(event) => checkboxChangeHandler(event)}
                      className={classes.checkboxContainer}
                      name="agree"
                    />}
                    label="I acknowledge the same."
                    className={classes.inputStyle1}
                    classes={{
                      label: classes.radioLabel
                    }}
                  />
                </FormGroup>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className={classes.cardFooter}>
          <Box className={classes.footerItem}>
            <Button
              variant='outlined'
              className={classes.btn}
              onClick={props.handleClose}
            > Cancel</Button>
          </Box>
          <Box className={classes.footerItem}>
            <Button
              variant='contained'
              color='primary'
              className={classes.btn}
              disabled={!checkboxState}
              onClick={props.handleSubmit}
            > Continue</Button>
          </Box>
        </Box>

      </Box>
    </Dialog>
  )
}
