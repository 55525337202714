import React from 'react';
import { useStyles, RedTooltip, useStylesBootstrap } from './styles';
import { Box, Button, Tooltip, ClickAwayListener } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import { updateCommunicationConsent } from '../../actions';
import Cookies from 'js-cookie';
import { maskEmail, maskMobile, getAppSource, getUserType, renderDefaultMsg, isAdmin } from '../../helpers';
import { decrypt } from '../../helpers/encrypt';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { SwitchButton } from '../SwitchButton'
import {EMAIL_TOOLTIP, PHONE_TOOLTIP, MESSAGE_TOOLTIP, PAPERLESS_TOOLTIP} from '../../helpers/constants';
import emailIcon from '../../assets/CommunicationPreferences/email.png';
import paperless from '../../assets/CommunicationPreferences/paperless.png';
import phoneIcon from '../../assets/CommunicationPreferences/phone.png';
import sms from '../../assets/CommunicationPreferences/sms.png';
import { insertActivityLog } from '../../../src/actions';
import { withStyles } from '@material-ui/core/styles';

const LightToolTip = withStyles((theme) => ({
  arrow: {
    color: '#235A86',
    // backgroundColor: '#ffffff',
  },
  tooltip: {
    backgroundColor: '#235A86',
    color: '#fffff',
    fontFamily: "Montserrat",
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    padding: "16px",
    fontSize: 14,
    maxWidth: 350,
    fontWeight: 500,
    borderRadius: 8,
    // border: "1px solid #4D4D4D"
  },
}))(Tooltip);

function BlueOnWhiteTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} placement="bottom-end" />;
}

export const CommunicationConsentAlert = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { open, handleClose, email, phone, accountNumber, isCommConcent,
    isGoGreen, isCommConcentMobile, IsSmsConsent, isVerified } = props;

  const classes = useStyles();
  const content = useSelector(state => state.content);
  const [loading, setLoading] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('')
  const [state, setState] = React.useState({
    email: isCommConcent,
    phone: isCommConcentMobile,
    sms: IsSmsConsent,
    lanveraEstatement: isGoGreen,
    lanveraEnotification: isGoGreen
  });
  const [openEmailToolTip, setOpenEmailToolTip] =  React.useState(false);
  const [openPhoneToolTip, setOpenPhoneToolTip] =  React.useState(false);
  const [openSmsToolTip, setOpenSmsToolTip] =  React.useState(false);
  const [openLanveraToolTip, setOpenLanveraToolTip] =  React.useState(false);

  const handleEmailTooltipClose = () => {
    setOpenEmailToolTip(false);
  };

  const handleEmailTooltipOpen = () => {
    setOpenEmailToolTip(true);
  };
 
  const handlePhoneTooltipClose = () => {
    setOpenPhoneToolTip(false);
  };

  const handlePhoneTooltipOpen = () => {
    setOpenPhoneToolTip(true);
  };

  const handleSmsTooltipClose = () => {
    setOpenSmsToolTip(false);
  };

  const handleSmsTooltipOpen = () => {
    setOpenSmsToolTip(true);
  };

  const handleLanveraTooltipClose = () => {
    setOpenLanveraToolTip(false);
  };

  const handleLanveraTooltipOpen = () => {
    setOpenLanveraToolTip(true);
  };

  const handleChange = (item) => {
    setState({ ...state, [item.fieldName]: item.fieldValue });
  }
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMsg('');
    const data = {
      "accountNumber": accountNumber,
      "isLanveraEStatements": state.lanveraEstatement,
      "isLanveraESmtNotification": state.email,
      "isCellConsent": state.phone,
      "isSmsConsent": state.sms,
      "isEmailConsent": state.email,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    }

     
    if(state.email !== isCommConcent || state.phone !== isCommConcentMobile || state.sms !== IsSmsConsent || state.lanveraEstatement !== isGoGreen) {
    const res = await updateCommunicationConsent(data);
    setLoading(false);
    if (res) {
      handleClose(true)
    } else {
      setErrorMsg(renderDefaultMsg('400'));
    }
  } else {
    const text = "No API Call- There is no changes in data on Communication preferences Dash Board";
    const data = {
      "accountNumber": props.accountNumber ? props.accountNumber : '',
      "activityCode": "UAL192",
      "activityStatus": "Success",
      "action": "GetAccountInfo",
      "controller": "Dashboard",
      "apiName": "GetAccountInfo",
      "activityRemark":  text,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };
    await insertActivityLog(data);
    setLoading(false);
    handleClose(false)
  }
}

  const renderContent = (content) => {
    if (content && content.lstPageContentDetails && content.lstPageContentDetails.length !== 0) {
      return content.lstPageContentDetails.filter(item => item.contentKey.toUpperCase() === "COMMUNICATIONCONSENT")
        .map(item => {
          return (
            <p key={item.contentID} className={classes.consentText} dangerouslySetInnerHTML={{ __html: item.contentValue }}></p>
          )
        })
    }
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={handleClose} open={open}
    >
      <Box className={classes.root}>
        <IconButton className={classes.iconStyle} onClick={handleClose}>
          <CancelIcon />
        </IconButton>
        <Box className={classes.header}>
          <h1 className={classes.title}>Communication Preferences</h1>
        </Box>
        {errorMsg.length !== 0 ? <p className={classes.errorMsg}>{errorMsg}</p> : ''}
        <Box className={classes.cardBody}>
          {renderContent(content)}
          <Box className={classes.inputBox}>
            
            <p className={classes.consentRule}>                        
            
            {!fullScreen && <LightToolTip 
            arrow placement="bottom" title={EMAIL_TOOLTIP} >
             <span style={{ position: 'relative', top: '7px', left: '-8px', cursor: 'pointer' }}><img src={emailIcon} alt="check icon" style={{ width: '20px', height: '23px', }}/></span>
          </LightToolTip> }
          {fullScreen && 
          <ClickAwayListener onClickAway={handleEmailTooltipClose}>
          <LightToolTip
              arrow placement="bottom" title={EMAIL_TOOLTIP}
              open={ openEmailToolTip }
            >
                <span style={{ position: 'relative', top: '7px', left: '-8px', cursor: 'pointer' }}  onClick={handleEmailTooltipOpen}><img src={emailIcon} alt="check icon" style={{ width: '20px', height: '23px', }}/></span>
            </LightToolTip>
          </ClickAwayListener>}  

            Receive emails on {maskEmail(decrypt(email))}</p>
            <SwitchButton
              status={state.email}
              handleSwitch={handleChange}
              switchName={"email"}></SwitchButton>
          </Box>
          {phone && <Box className={classes.inputBox}>
            <p className={classes.consentRule}>
            

            {!fullScreen && <LightToolTip
            arrow placement="bottom" title={PHONE_TOOLTIP} >
             <span style={{ position: 'relative', top: '7px', left: '-8px', cursor: 'pointer' }}><img src={phoneIcon} alt="check icon" style={{ width: '20px', height: '23px', }}/></span>
          </LightToolTip> }
          {fullScreen && 
          <ClickAwayListener onClickAway={handlePhoneTooltipClose}>
          <LightToolTip
              arrow placement="bottom" title={PHONE_TOOLTIP}
              open={ openPhoneToolTip }
            >
                <span style={{ position: 'relative', top: '7px', left: '-8px', cursor: 'pointer' }}  onClick={handlePhoneTooltipOpen}><img src={emailIcon} alt="check icon" style={{ width: '20px', height: '23px', }}/></span>
            </LightToolTip>
          </ClickAwayListener>} 

            Receive phone calls on {maskMobile(decrypt(phone))}</p>
            <SwitchButton
              status={state.phone}
              handleSwitch={handleChange}
              switchName={"phone"}></SwitchButton>
          </Box>}
          {phone && <Box className={classes.inputBox}>
            <p className={classes.consentRule}>
            
            {!fullScreen && <LightToolTip
            arrow placement="bottom" title={MESSAGE_TOOLTIP} >
             <span style={{ position: 'relative', top: '7px', left: '-8px', cursor: 'pointer' }}><img src={sms} alt="check icon" style={{ width: '20px', height: '23px', }}/></span>
          </LightToolTip> }
          {fullScreen && 
          <ClickAwayListener onClickAway={handleSmsTooltipClose}>
          <LightToolTip
              placement="bottom"
              arrow title={MESSAGE_TOOLTIP}
              open={ openSmsToolTip }
            >
                <span style={{ position: 'relative', top: '7px', left: '-8px', cursor: 'pointer' }}  onClick={handleSmsTooltipOpen}><img src={sms} alt="check icon" style={{ width: '20px', height: '23px', }}/></span>
            </LightToolTip>
          </ClickAwayListener>} 
            
            Receive text messages on {maskMobile(decrypt(phone))}</p>
            <SwitchButton
              status={state.sms}
              handleSwitch={handleChange}
              switchName={"sms"}></SwitchButton>
          </Box>}
          <Box className={classes.inputBox}>
            <p className={classes.consentRule}>  
              
              {!fullScreen && <LightToolTip
                placement="bottom"
                arrow title={PAPERLESS_TOOLTIP} >
                <span style={{ position: 'relative', top: '11px', left: '-8px' , paddingRight: '2px', cursor: 'pointer' }}><img src={paperless} alt="check icon" style={{ width: '20px', height: '23px', }}/></span>
              </LightToolTip> }
              {fullScreen && 
              <ClickAwayListener onClickAway={handleLanveraTooltipClose}>
              <LightToolTip
                  placement="bottom"
                  arrow title={PAPERLESS_TOOLTIP}
                  open={ openLanveraToolTip }
                >
                <span style={{ position: 'relative', top: '11px', left: '-8px' , paddingRight: '2px', cursor: 'pointer' }}  onClick={handleLanveraTooltipOpen}><img src={paperless} alt="check icon" style={{ width: '20px', height: '23px', }}/></span>
            </LightToolTip>
          </ClickAwayListener>} 

              Go paperless and receive statements electronically</p>
            <SwitchButton
              status={state.lanveraEstatement}
              handleSwitch={handleChange}
              switchName={"lanveraEstatement"}></SwitchButton>
          </Box>
        </Box>
       
        {isVerified ?
            <Button
            color='primary'
            variant='contained'
            onClick={handleSubmit}
            className={classes.btn}
            disabled={loading || isAdmin()}
          >
            Enroll Now 
          </Button> :
          <RedTooltip arrow title={`Please verify your ${!props.isEmailVerified == true ? 'email' : ''}${(!props.isEmailVerified && !props.isMobileVerified) ? ' and' : ''}${!props.isMobileVerified ? ' mobile number' : ''}.`}>
          <Button
            // color='primary'
            variant='contained'
            className={classes.btnDisable}
            disabled={loading || isAdmin()}
          >
            Enroll Now
          </Button>
        </RedTooltip>
        }
      </Box>
    </Dialog>
  )
}