import { makeStyles, withStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 12,
    backgroundColor: "#fff",
    position: 'relative',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    padding: '2em 4.5em',
    [theme.breakpoints.down('xs')]: {
      padding: '1.6em',
    },
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxWidth: 850,
    maxHeight: '95vh',
  },

  header: {

  },

  iconContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 23
  },

  title: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "600",
    marginLeft: 20,
    marginTop: 10,
  },

  cardBody: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  statusContainer: {
    marginBottom: 33,
  },

  statusTitle: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "500",
    marginBottom: 16,
  },

  statusBody: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '2.1em',
    fontWeight: "bold",
  },

  bodyText: {
    color: "#0C0B0B",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    marginBottom: 50,
  },

  cardFooter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  footerText: {
    color: "#0C0B0B",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    fontWeight: "400",
  },

  linkStyle: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    fontWeight: "600",
    marginTop: 5,
  },

  footerItem: {
    marginBottom: 33,
  },

  btn: {
    width: 193,
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "500",
  },

  btnDisable: {
    width: 193,
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "500",
    backgroundColor: 'lightgrey'
  },
  errMsg: {
    color: "red",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "bold",
    textAlign: 'center',
  },

  disableBtn: {
    backgroundColor: '#A0A0A0',
    width: 193,

    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "500",
    '&:hover': {
      backgroundColor: '#A0A0A0',
    }
  },

  iconBtn: {
    position: 'absolute',
    top: 10,
    right: 10,
  },

  gogreenLogo: {
    width: 62,
    height: 62,
  },

  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 22,
  },

  labelStyle: {
    color: "#0C0B0B",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    margin: 0,
  },

  inputStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600"
  },

  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },

  popupInfo: {
    color: "#0C0B0B",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    margin: 0,
    marginBottom: 30,
  },

  formStyle: {
    width: '100%',
  },

  editBtn: {
    cursor: 'pointer',
    color: '#535D6D',
    position: 'absolute',
    right: 10,
    top: 15,
  },

  displayFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },

  relative: {
    position: 'relative',
    width: '48%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }

}));

