import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: 12,
    marginBottom: 28,
    backgroundColor: "#FFFFFF",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
  },

  bodyTitle: {
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: '3em',
    fontWeight: "500",
    textAlign: 'center',
    marginTop: 31,
    maxWidth: 221,
  },

  title: {
    color: "#FFF",
    fontSize: '1.8em',
    fontWeight: "500",
    fontFamily: "Montserrat",
    textAlign: 'center',
    maxWidth: '290px',
    wordBreak: "break-word",
  },

  header: {
    padding: '1.6em 4.3em',
    backgroundColor: '#C9CAC8',
    borderRadius: "12px 12px 0 0",
  },

  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  bodyText: {
    marginLeft: 10,
    color: "rgba(0,0,0,0.74)",
    fontWeight: '400',
    fontFamily: "Montserrat",
    fontSize: '16px',
    maxWidth: '290px',
    wordBreak: "break-word",
    '& p': {
      color: "rgba(0,0,0,0.74)",
      fontWeight: '400',
      fontFamily: "Montserrat",
      fontSize: '16px',
      maxWidth: '290px',
      wordBreak: "break-word",
    }

  },

  bodyTextContainer: {
    display: 'flex',
    marginBottom: 20,
    width: 284
  },

  footer: {
    padding: '0.6em 4em 3.5em'
  },

  logo: {
    // height: '17.9em',
    // width: '14.5em',
    marginTop: 10,
    marginBottom: 20,
  },

  btn: {
    width: '100%',
    backgroundColor: "#19cf7c",
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "500",
    '&:hover': {
      backgroundColor: "#19cf7c",
    }
  },

  subTitle: {
    fontFamily: "Montserrat",
    fontSize: '2em',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: "500",
    textAlign: 'center',
    marginTop: 10,
  }


}));

