import React, { Fragment, useEffect, useState } from 'react';
import {
  Box, TextField, FormControl,
  FormLabel, RadioGroup, FormControlLabel, Radio, Button, Typography, TextareaAutosize
} from '@material-ui/core';
import { NextPrevButton } from '../NextPrevButton'
import { useStyles } from './styles';
import { SaveAndExitPopup } from '../SaveAndExitPopup';
import { useHistory } from 'react-router-dom';
import HelpIcon from '@material-ui/icons/Help';
import {
  checkCellPhoneLength, checkDigitOnly, checkDigitWithDecimal, checkPhoneLength,
  checkSSNLength, checkValidEmail, scrollTop
} from '../../../helpers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';


const coBorrowerFields = {
  coBorrowerName: '',
  coBorrowerDob: '',
  coBorrowerSsn: '',
  coBorrowerCellNumber: '',
  coBorrowerPhoneNumber: '',
  coBorrowerEmailId: '',
}

export const SectionA = (props) => {
  const { parentStyle } = props
  const classes = useStyles();
  const history = useHistory();

  const nullCheck = (str) => str ? str : ''
  const [coBorrowersMap, setCoBorrowersMap] = useState([coBorrowerFields])

  const [sectionA, setSectionA] = useState({
    isUpdated: false,
    borrowerName: '',
    borrowerDob: '',
    borrowerSsn: '',
    borrowerCellNumber: '',
    borrowerPhoneNumber: '',
    borrowerEmailId: '',
    // coBorrowerName: '',
    // coBorrowerDob: '',
    // coBorrowerSsn: '',
    // coBorrowerCellNumber: '',
    // coBorrowerPhoneNumber: '',
    // coBorrowerEmailId: '',
    isBorrowerServiceMan: '',
    hasPcsOrder: '',
    intendToOccupyPrimaryResidence: '',
    isBorrowerSurvivingSpouse: '',
    iWantTo: '',
    propertyIsMy: '',
    propertyIsMyOtherText: '',
    propertyIs: '',
    propertyIsOtherText: '',
    que8: '',
    que9: '',
    que10: '',
    que11: '',
    que12: '',
    que13: '',
    que14: '',
    que15: '', que16: '', que17: '', que18: '', que19: '', que20: '', que21: '', que22: '',
    que23: '', que24: '', que25: '',
    que26: '',
    que27: '',
    que28: '',
    que29: '',
    que30: '',
    que30OtherText: '',
    que31: '',
    que32: '',
    que33: '',
    que34: '',
    que35: '',
    que36: '',
  });

  useEffect(() => {
    if (props.data) {
      const { borrower, coBorrower, questions } = props.data;
      const { question1, question2, question3, question4, question5, question6, question7,
        question8, question9, question10, question11, question12, question13, question14,
        question15, question16, question17, question18, question19, question20, question21, question22,
        question23, question24, question25, question26, question27, question28, question29, question30,
        question31, question32, question33, question34, question35, question36 } = questions;

      if (Array.isArray(coBorrower)) {
        let getUpdatedCoB = coBorrower.map((coBorrower) => {
          let obj = {
            coBorrowerName: nullCheck(coBorrower.name),
            coBorrowerDob: nullCheck(coBorrower.dob),
            coBorrowerSsn: nullCheck(coBorrower.ssn),
            coBorrowerCellNumber: nullCheck(coBorrower.cellNumber),
            coBorrowerPhoneNumber: nullCheck(coBorrower.phoneNumber),
            coBorrowerEmailId: nullCheck(coBorrower.emailId),
          }
          return obj
        })
        setCoBorrowersMap(getUpdatedCoB)
      } else {
        let getUpdatedCoB = {
          coBorrowerName: nullCheck(coBorrower.name),
          coBorrowerDob: nullCheck(coBorrower.dob),
          coBorrowerSsn: nullCheck(coBorrower.ssn),
          coBorrowerCellNumber: nullCheck(coBorrower.cellNumber),
          coBorrowerPhoneNumber: nullCheck(coBorrower.phoneNumber),
          coBorrowerEmailId: nullCheck(coBorrower.emailId),
        }
        setCoBorrowersMap([getUpdatedCoB])
      }

      setSectionA({
        // --------borrower-------
        borrowerName: nullCheck(borrower.name),
        borrowerDob: nullCheck(borrower.dob),
        borrowerSsn: nullCheck(borrower.ssn),
        borrowerCellNumber: nullCheck(borrower.cellNumber),
        borrowerPhoneNumber: nullCheck(borrower.phoneNumber),
        borrowerEmailId: nullCheck(borrower.emailId),
        // --------coBorrower-------
        // coBorrowerName: coBorrower.name,
        // coBorrowerDob: coBorrower.dob,
        // coBorrowerSsn: coBorrower.ssn,
        // coBorrowerCellNumber: coBorrower.cellNumber,
        // coBorrowerPhoneNumber: coBorrower.phoneNumber,
        // coBorrowerEmailId: coBorrower.emailId,
        // --------questions-------
        isBorrowerServiceMan: nullCheck(question1.answer),
        hasPcsOrder: nullCheck(question2.answer),
        intendToOccupyPrimaryResidence: nullCheck(question3.answer),
        isBorrowerSurvivingSpouse: nullCheck(question4.answer),
        iWantTo: nullCheck(question5.answer),
        propertyIsMy: nullCheck(question6.answer),
        propertyIsMyOtherText: nullCheck(question6.otherText),
        propertyIs: nullCheck(question7.answer),
        propertyIsOtherText: nullCheck(question7.otherText),
        que8: nullCheck(question8.answer),
        que9: nullCheck(question9.answer),
        que10: nullCheck(question10.answer),
        que11: nullCheck(question11.answer),
        que12: nullCheck(question12.answer),
        que13: nullCheck(question13.answer),
        que14: nullCheck(question14.answer),
        que15: nullCheck(question15.answer),
        que16: nullCheck(question16.answer),
        que17: nullCheck(question17.answer),
        que18: nullCheck(question18.answer),
        que19: nullCheck(question19.answer),
        que20: nullCheck(question20.answer),
        que21: nullCheck(question21.answer),
        que22: nullCheck(question22.answer),
        que23: nullCheck(question23.answer),
        que24: nullCheck(question24.answer),
        que25: nullCheck(question25.answer),
        que26: nullCheck(question26.answer),
        que27: nullCheck(question27.answer),
        que28: nullCheck(question28.answer),
        que29: nullCheck(question29.answer),
        que30: nullCheck(question30.answer),
        que30OtherText: nullCheck(question30.otherText),
        que31: nullCheck(question31.answer),
        que32: nullCheck(question32.answer),
        que33: nullCheck(question33.answer),
        que34: nullCheck(question34.answer),
        que35: nullCheck(question35.answer),
        que36: nullCheck(question36.answer),
        isUpdated: true

      });
    }
  }, [props.data])

  const {
    // --------borrower-------
    borrowerName,
    borrowerDob,
    borrowerSsn,
    borrowerCellNumber,
    borrowerPhoneNumber,
    borrowerEmailId,
    // --------coBorrower-------
    // coBorrowerName,
    // coBorrowerDob,
    // coBorrowerSsn,
    // coBorrowerCellNumber,
    // coBorrowerPhoneNumber,
    // coBorrowerEmailId,
    // --------questions-------
    isBorrowerServiceMan,
    hasPcsOrder,
    intendToOccupyPrimaryResidence,
    isBorrowerSurvivingSpouse,
    iWantTo,
    propertyIsMy, propertyIsMyOtherText,
    propertyIs, propertyIsOtherText,
    que8,
    que9,
    que10,
    que11,
    que12,
    que13,
    que14,
    que15,
    que16,
    que17,
    que18,
    que19,
    que20,
    que21,
    que22, que23, que24, que25, que26, que27, que28, que29,
    que30, que30OtherText,
    que31, que32, que33, que34, que35, que36,

  } = sectionA;

  useEffect(() => {
    if (sectionA.isUpdated) {
      let formErrors = formValidation()
      coBorrowerValidation()
      setFormErrorMap(formErrors)
    }
  }, [sectionA.isUpdated])

  const handleChange = (event) => {

    let numberFieldList = ["borrowerSsn", "borrowerCellNumber", "borrowerPhoneNumber",
      "que12", "que22", "que26", "que36"]
    if (numberFieldList.indexOf(event.target.name) > -1) {
      let isDigits = checkDigitOnly(event.target.value)
      if (isDigits === false) {
        return false
      }
    }
    let amountFields = ["que19"]
    if (amountFields.indexOf(event.target.name) > -1) {
      let isAmount = checkDigitWithDecimal(event.target.value)
      if (isAmount === false) {
        return false
      }
    }


    setSectionA({ ...sectionA, [event.target.name]: event.target.value });
  };

  const handleChangeDate = (filed) => (date) => {    
    setSectionA({ ...sectionA, [filed]: date });
  }

  const handleSave = (e) => {
    //e.preventDefault();
    let coBorrowerForSave = coBorrowersMap.map(item => {
      let setObj = {
        "name": item.coBorrowerName,
        "dob": item.coBorrowerDob,
        "ssn": item.coBorrowerSsn,
        "cellNumber": item.coBorrowerCellNumber,
        "phoneNumber": item.coBorrowerPhoneNumber,
        "emailId": item.coBorrowerEmailId
      }
      return setObj
    })

    let formErrors = formValidation()
    let cobErrorCount = coBorrowerValidation()
    let formErrorLength = Object.keys(formErrors).length + cobErrorCount    
    let sectionCompleteStatus = formErrorLength > 0 ? "incomplete" : "complete"
    props.status({ id: 0, sectionName: "section_A", status: sectionCompleteStatus })


    const data = {
      "section_A": {
        "borrower": {
          "name": borrowerName,
          "dob": borrowerDob,
          "ssn": borrowerSsn,
          "cellNumber": borrowerCellNumber,
          "phoneNumber": borrowerPhoneNumber,
          "emailId": borrowerEmailId
        },
        "coBorrower": coBorrowerForSave, //coBorrowersMap,
        // "coBorrower": {
        //   "name": coBorrowerName,
        //   "dob": coBorrowerDob,
        //   "ssn": coBorrowerSsn,
        //   "cellNumber": coBorrowerCellNumber,
        //   "phoneNumber": coBorrowerPhoneNumber,
        //   "emailId": coBorrowerEmailId
        // },
        "questions": {
          "question1": {
            "questionText": "Is any borrower a service man?",
            "answer": isBorrowerServiceMan
          },
          "question2": {
            "questionText": "Have you recently been deployed away from your principal residence or recently received a Permanent Change of Station (PCS) order?",
            "answer": hasPcsOrder
          },
          "question3": {
            "questionText": "Do you intend to occupy this as your primary residence sometimes in the future?",
            "answer": intendToOccupyPrimaryResidence
          },
          "question4": {
            "questionText": "Is any borrower the surviving spouse of a deceased service member who was on active duty at the time of death?",
            "answer": isBorrowerSurvivingSpouse
          },
          "question5": {
            "questionText": "I want to:",
            "answer": iWantTo
          },
          "question6": {
            "questionText": "The property is my:",
            "answer": propertyIsMy,
            "otherText": propertyIsMy === "Other" ? propertyIsMyOtherText : ''
          },
          "question7": {
            "questionText": "The property is:",
            "answer": propertyIs,
            "otherText": propertyIs === "Other" ? propertyIsOtherText : ''
          },

          "question8": {
            "questionText": `Have you previously requested mortgage payment assistance through BSI ?`,
            "answer": que8
          },
          "question9": {
            "questionText": `How many single family properties other than your principal residence do you and/or any co-borrower(s) own individually, jointly, or with others ?`,
            "answer": que9, //"inputBox"
          },
          "question10": {
            "questionText": `Is the mortgage on your principal residencecurrent?`,
            "answer": que10,
          },
          "question11": {
            "questionText": `If "No", number of months your payment is pastdue(if known):`,
            "answer": que11, // input Box
          },
          "question12": {
            "questionText": `Number of People in Household`,
            "answer": que12, // input Box          
          },
          "question13": {
            "questionText": `Mailing Address`,
            "answer": que13, // input Box 
          },
          "question14": {
            "questionText": `Property Address(if same as mailing address, just write “same”):`,
            "answer": que14, // input Box          
          },
          "question15": {
            "questionText": `Is the property listed for sale?`,
            "answer": que15,
          },
          "question16": {
            "questionText": `If yes, what was the listing date?`,
            "answer": que16, // input Box          
          },
          "question17": {
            "questionText": `Have you received an offer on the property?`,
            "answer": que17,
          },
          "question18": {
            "questionText": `Date of Offer`,
            "answer": que18, // input Box          
          },
          "question19": {
            "questionText": `Amount of Offer`,
            "answer": que19, // input Box          
          },
          "question20": {
            "questionText": `Closing Date`,
            "answer": que20, // input Box          
          },
          "question21": {
            "questionText": `Agent’s Name`,
            "answer": que21, // input Box          
          },
          "question22": {
            "questionText": `Agent’s Phone Number`,
            "answer": que22, // input Box          
          },

          "question23": {
            "questionText": `Have you contacted a credit counseling agency for help?`,
            "answer": que23,
          },
          "question24": {
            "questionText": `Counselor's Name`,
            "answer": que24, // input Box          
          },
          "question25": {
            "questionText": `Agency Name`,
            "answer": que25, // input Box          
          },
          "question26": {
            "questionText": `Counselor's Phone Number`,
            "answer": que26, // input Box          
          },
          "question27": {
            "questionText": `Counselor's Email`,
            "answer": que27, // input Box          
          },
          "question28": {
            "questionText": `Who pays the real estate tax bill on your property?`,
            "answer": que28,
          },
          "question29": {
            "questionText": `Are the taxes current?`,
            "answer": que29,
          },
          "question30": {
            "questionText": `Condominium or HOA Fees?`,
            "answer": que30,
            "otherText": que30 === 'No' ? que30OtherText : "",
          },
          "question31": {
            "questionText": `Are the fees paid current?`,
            "answer": que31,
          },
          "question32": {
            "questionText": `Name and address that fees are paid to`,
            "answer": que32, // input Box          
          },
          "question33": {
            "questionText": `Who pays the insurance premiums for your property?`,
            "answer": que33,
          },
          "question34": {
            "questionText": `Is the policy current?`,
            "answer": que34,
          },
          "question35": {
            "questionText": `Name(s) of Insurance Company`,
            "answer": que35, // input Box          
          },
          "question36": {
            "questionText": `Insurance Company Phone Number(s)`,
            "answer": que36, // input Box          
          },


        },
        "sectionStatus": {
          "status": sectionCompleteStatus,
          "sectionName": "Section A:Borrower Information"
        },
      }
    }
   
    props.saveFromParent(data)
  }
  const saveAndExitAction = (action) => {
    if (action === "leave") {
      handleSave()
      history.push('/Dashboard')
    }
  }
  const addMoreRow = () => {
    let newObj = { ...coBorrowerFields }
    setCoBorrowersMap([...coBorrowersMap, newObj])
  }

  const removeRow = () => {
    let newArray = [...coBorrowersMap]
    let newArrayLen = newArray.length
    if (newArrayLen > 1) {
      newArray.splice(newArrayLen - 1, 1)
    }
    setCoBorrowersMap(newArray)
  }
  const handleCoBorrower = (index) => (event) => {
    let item = event.target.name.split("_")
    let numberFieldList = ["coBorrowerSsn", "coBorrowerCellNumber", "coBorrowerPhoneNumber"]

    if (numberFieldList.indexOf(item[0]) > -1) {
      let isDigits = checkDigitOnly(event.target.value)
      if (isDigits === false) {
        return false
      }
    }

    let newFormData = [...coBorrowersMap]
    newFormData[item[1]][item[0]] = event.target.value
    setCoBorrowersMap(newFormData)
  }
  const handleCoBorrowerDate = (inputName) => (date) => {
    let item = inputName.split("_")
    let newFormData = [...coBorrowersMap]
    newFormData[item[1]][item[0]] = date
    setCoBorrowersMap(newFormData)
  }


  /*======Next Prev Work section wise=====*/
  const subSteps = [
    "BorrowerDetail",
    "BorrowerInformation",
    "PropertyInformation",
    "PropertyListedForSale",
    "RealEstateTax"]
  const subStepsLength = subSteps.length - 1
  const [activeSubStep, setActiveSubStep] = useState(0);
  const nextSubStep = () => {
    if (activeSubStep < subStepsLength) {
      setActiveSubStep(activeSubStep + 1)
      scrollTop()
    } else {
      // go to next
      handleSave()
    }
  }
  const backSubStep = () => {
    if (activeSubStep !== 0 && activeSubStep <= subStepsLength) {
      setActiveSubStep(activeSubStep - 1)
      scrollTop()
    }
  }

  /*=======Form validation=====*/
  const [formErrorMap, setFormErrorMap] = useState([])
  const isEmpty = (str) => str ? false : true

  const formValidation = () => {
    const formErrorMessage = {}
    /*Borrower Detail*/
    if (isEmpty(borrowerName)) {
      formErrorMessage["borrowerName"] = "This is required field"
    }
    if (isEmpty(borrowerDob)) {
      formErrorMessage["borrowerDob"] = "This is required field"
    }
    if (isEmpty(borrowerSsn)) {
      formErrorMessage["borrowerSsn"] = "This is required field"
    } else if (!checkSSNLength(borrowerSsn)) {
      formErrorMessage["borrowerSsn"] = "invalid"
    }
    if (isEmpty(borrowerCellNumber)) {
      formErrorMessage["borrowerCellNumber"] = "This is required field"
    } else if (!checkCellPhoneLength(borrowerCellNumber)) {
      formErrorMessage["borrowerCellNumber"] = "invalid"
    }
    if (isEmpty(borrowerPhoneNumber)) {
      formErrorMessage["borrowerPhoneNumber"] = "This is required field"
    } else if (!checkPhoneLength(borrowerPhoneNumber)) {
      formErrorMessage["borrowerPhoneNumber"] = "invalid"
    }
    if (isEmpty(borrowerEmailId)) {
      formErrorMessage["borrowerEmailId"] = "This is required field"
    } else if (!checkValidEmail(borrowerEmailId)) {
      formErrorMessage["borrowerEmailId"] = "invalid"
    }

    /*Borrower Information*/
    if (isEmpty(isBorrowerServiceMan)) {
      formErrorMessage["isBorrowerServiceMan"] = "This is required field"
    } else if (isBorrowerServiceMan === 'Yes') {
      if (isEmpty(hasPcsOrder)) {
        formErrorMessage["hasPcsOrder"] = "This is required field"
      }
      if (isEmpty(intendToOccupyPrimaryResidence)) {
        formErrorMessage["intendToOccupyPrimaryResidence"] = "This is required field"
      }
    }

    if (isEmpty(isBorrowerSurvivingSpouse)) {
      formErrorMessage["isBorrowerSurvivingSpouse"] = "This is required field"
    }
    /*Property Information*/
    if (isEmpty(iWantTo)) {
      formErrorMessage["iWantTo"] = "This is required field"
    }
    if (isEmpty(propertyIsMy)) {
      formErrorMessage["propertyIsMy"] = "This is required field"
    } else if (propertyIsMy === "Other") {
      if (isEmpty(propertyIsMyOtherText)) {
        formErrorMessage["propertyIsMyOtherText"] = "This is required field"
      }
    }
    if (isEmpty(propertyIs)) {
      formErrorMessage["propertyIs"] = "This is required field"
    } else if (propertyIs === "Other") {
      if (isEmpty(propertyIsOtherText)) {
        formErrorMessage["propertyIsOtherText"] = "This is required field"
      }
    }
    if (isEmpty(que8)) {
      formErrorMessage["que8"] = "This is required field"
    }
    if (isEmpty(que9)) {
      formErrorMessage["que9"] = "This is required field"
    }
    if (isEmpty(que10)) {
      formErrorMessage["que10"] = "This is required field"
    } else if (que10 === 'No') {
      if (isEmpty(que11)) {
        formErrorMessage["que11"] = "This is required field"
      }
    }
    if (isEmpty(que12)) {
      formErrorMessage["que12"] = "This is required field"
    }
    // if (isEmpty(que13)) {
    //   formErrorMessage["que13"] = "This is required field"
    // } else if(!checkValidEmail(que13)){
    //   formErrorMessage["que13"] = "invalid"
    // }
    if (isEmpty(que14)) {
      formErrorMessage["que14"] = "This is required field"
    }
    /*Property Listed For Sale*/
    if (isEmpty(que15)) {
      formErrorMessage["que15"] = "This is required field"
    } else if (que15 === 'Yes') {
      if (isEmpty(que16)) {
        formErrorMessage["que16"] = "This is required field"
      }
      if (isEmpty(que17)) {
        formErrorMessage["que17"] = "This is required field"
      } else if (que17 === 'Yes') {
        if (isEmpty(que18)) {
          formErrorMessage["que18"] = "This is required field"
        }
        if (isEmpty(que19)) {
          formErrorMessage["que19"] = "This is required field"
        }
        if (isEmpty(que20)) {
          formErrorMessage["que20"] = "This is required field"
        }
        if (isEmpty(que21)) {
          formErrorMessage["que21"] = "This is required field"
        }
        if (isEmpty(que22)) {
          formErrorMessage["que22"] = "This is required field"
        } else if (!checkPhoneLength(que22)) {
          formErrorMessage["que22"] = "invalid"
        }
      }

    } // end que15
    /*Credit counseling agency*/
    if (isEmpty(que23)) {
      formErrorMessage["que23"] = "This is required field"
    } else if (que23 === 'Yes') {
      if (isEmpty(que24)) {
        formErrorMessage["que24"] = "This is required field"
      }
      if (isEmpty(que25)) {
        formErrorMessage["que25"] = "This is required field"
      }
      if (isEmpty(que26)) {
        formErrorMessage["que26"] = "This is required field"
      } else if (!checkPhoneLength(que26)) {
        formErrorMessage["que26"] = "invalid"
      }
      if (isEmpty(que27)) {
        formErrorMessage["que27"] = "This is required field"
      } else if (!checkValidEmail(que27)) {
        formErrorMessage["que27"] = "invalid"
      }
    }
    /*Real Estate Tax*/
    if (isEmpty(que28)) {
      formErrorMessage["que28"] = "This is required field"
    }
    if (isEmpty(que29)) {
      formErrorMessage["que29"] = "This is required field"
    }
    if (isEmpty(que30)) {
      formErrorMessage["que30"] = "This is required field"
    } else if (que30 === 'No') {
      if (isEmpty(que30OtherText)) {
        formErrorMessage["que30OtherText"] = "This is required field"
      }
    }
    if (isEmpty(que31)) {
      formErrorMessage["que31"] = "This is required field"
    }
    if (isEmpty(que32)) {
      formErrorMessage["que32"] = "This is required field"
    }
    /*Insurance premiums*/
    if (isEmpty(que33)) {
      formErrorMessage["que33"] = "This is required field"
    }
    if (isEmpty(que34)) {
      formErrorMessage["que34"] = "This is required field"
    }
    if (isEmpty(que35)) {
      formErrorMessage["que35"] = "This is required field"
    }
    if (isEmpty(que36)) {
      formErrorMessage["que36"] = "This is required field"
    } else if (!checkPhoneLength(que36)) {
      formErrorMessage["que36"] = "invalid"
    }
    console.log("====formErrorMessage 704====", formErrorMessage)
    return formErrorMessage
  }

  const [cobFormErrorMap, setCobFormErrorMap] = useState([])
  const coBorrowerValidation = () => {
    setCobFormErrorMap([])
    let errorCount = 0;
    if (coBorrowersMap.length > 0) {
      let cobErrors = coBorrowersMap.map(item => {
        const { coBorrowerSsn, coBorrowerCellNumber, coBorrowerPhoneNumber, coBorrowerEmailId } = item
        const cobErrorMessage = {}
        if (!isEmpty(coBorrowerSsn) && !checkSSNLength(coBorrowerSsn)) {
          cobErrorMessage["coBorrowerSsn"] = "invalid"
          errorCount++;
        }
        if (!isEmpty(coBorrowerCellNumber) && !checkCellPhoneLength(coBorrowerCellNumber)) {
          cobErrorMessage["coBorrowerCellNumber"] = "invalid"
          errorCount++;
        }
        if (!isEmpty(coBorrowerPhoneNumber) && !checkPhoneLength(coBorrowerPhoneNumber)) {
          cobErrorMessage["coBorrowerPhoneNumber"] = "invalid"
          errorCount++;
        }
        if (!isEmpty(coBorrowerEmailId) && !checkValidEmail(coBorrowerEmailId)) {
          cobErrorMessage["coBorrowerEmailId"] = "invalid"
          errorCount++;
        }
        return cobErrorMessage
      })
     
      setCobFormErrorMap(cobErrors)
      return errorCount;
    }
  }

  const cobErrorHandler = (field, index) => {
    if (cobFormErrorMap[index] && cobFormErrorMap[index][field]) {
      let message = cobFormErrorMap[index][field] === "invalid" ? "Invalid Data, Please check the value" : "*"
      return message
    } else {
      return false
    }
  }

  const errorHandler = (field) => {
    if (formErrorMap[field]) {
      let message = formErrorMap[field] === "invalid" ? "Invalid Data, Please check the value" : "*"
      return message
    } else {
      return false
    }
  }

  const radioErrorHandler = (field) => {
    if (errorHandler(field)) {
      return (<span className={parentStyle.errorMessage}>{errorHandler(field)}</span>)
    }
  }

  return (
    <Box className={classes.root}>
      <Box style={{ marginTop: "-85px" }}>
        <SaveAndExitPopup actionCallBack={saveAndExitAction}></SaveAndExitPopup>
      </Box>

      <form className={classes.formStyle} style={{ paddingTop: "25px" }}>
        {activeSubStep === 0 && (<Fragment>

          <Box>
            <Box>
              <Typography className={classes.title}>Borrower Information <span className={classes.infoIcon}><HelpIcon color="primary" /></span></Typography>

            </Box>
            <Box className={classes.flexBox}>
              <TextField
                size="small"
                error={errorHandler('borrowerName') !== false}
                helperText={errorHandler('borrowerName')}
                onChange={handleChange}
                value={borrowerName}
                name="borrowerName"
                label="Borrower Name"
                color='secondary'
                variant="outlined"
                className={parentStyle.inputField}
                InputProps={{
                  classes: { root: parentStyle.inputText }
                }}
              />

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  //disabled={isRestrict}
                  views={['year', 'month', 'date']}
                  error={errorHandler('borrowerDob') !== false}
                  helperText={errorHandler('borrowerDob')}
                  size={"small"}
                  color='secondary'
                  autoOk={true}
                  //disableToolbar
                  //minDate={new Date()}
                  variant="inline"
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  fullWidth
                  id="id-borrowerDob"
                  label="Date of Birth"
                  value={borrowerDob}
                  name="borrowerDob"
                  onChange={handleChangeDate("borrowerDob")}
                  KeyboardButtonProps={{ 'aria-label': 'change date', }}
                  placeholder={"MM/dd/yyyy"}
                  className={parentStyle.inputField}
                  InputProps={{ classes: { root: parentStyle.inputText } }}
                  InputLabelProps={{ shrink: true, }}
                />
              </MuiPickersUtilsProvider>

            </Box>
            <Box className={classes.flexBox}>
              <TextField
                size="small"
                error={errorHandler('borrowerSsn') !== false}
                helperText={errorHandler('borrowerSsn')}
                onChange={handleChange}
                value={borrowerSsn}
                name="borrowerSsn"
                label="SSN"
                color='secondary'
                variant="outlined"
                className={parentStyle.inputField}
                // InputProps={{
                //   classes: { root: parentStyle.inputText },                

                // }}
                inputProps={{
                  className: parentStyle.inputText,
                  maxLength: 9,
                  form: { autocomplete: 'off', },
                }}


              />
              <TextField
                size="small"
                error={errorHandler('borrowerCellNumber') !== false}
                helperText={errorHandler('borrowerCellNumber')}
                onChange={handleChange}
                value={borrowerCellNumber}
                name="borrowerCellNumber"
                label="Cell Number"
                color='secondary'
                variant="outlined"
                className={parentStyle.inputField}
                // InputProps={{
                //   classes: { root: parentStyle.inputText },
                //   maxLength: 10,
                // }}
                inputProps={{
                  className: parentStyle.inputText,
                  maxLength: 10,
                  form: { autocomplete: 'off', },
                }}
              />
            </Box>
            <Box className={classes.flexBox}>
              <TextField
                size="small"
                error={errorHandler('borrowerPhoneNumber') !== false}
                helperText={errorHandler('borrowerPhoneNumber')}
                onChange={handleChange}
                value={borrowerPhoneNumber}
                name="borrowerPhoneNumber"
                label="Phone Number"
                color='secondary'
                variant="outlined"
                className={parentStyle.inputField}
                // InputProps={{
                //   classes: { root: parentStyle.inputText },
                //   maxLength: 10,
                // }}
                inputProps={{
                  className: parentStyle.inputText,
                  maxLength: 10,
                  form: { autocomplete: 'off', },
                }}
              />
              <TextField
                size="small"
                error={errorHandler('borrowerEmailId') !== false}
                helperText={errorHandler('borrowerEmailId')}
                onChange={handleChange}
                value={borrowerEmailId}
                name="borrowerEmailId"
                label="Email Id"
                color='secondary'
                variant="outlined"
                className={parentStyle.inputField}
                InputProps={{
                  classes: { root: parentStyle.inputText }
                }}
              />
            </Box>
          </Box>


          {coBorrowersMap.map((cob, index) => (
            <Box key={index}>
              <h1 className={classes.title}>Co-Borrower Information #{index + 1}</h1>
              <Box className={classes.flexBox}>
                <TextField
                  size="small"
                  onChange={handleCoBorrower(index)}
                  value={cob.coBorrowerName}
                  name={`coBorrowerName_${index}`}
                  label="Co-Borrower Name"
                  color='secondary'
                  variant="outlined"
                  className={parentStyle.inputField}
                  InputProps={{
                    classes: { root: parentStyle.inputText }
                  }}
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    views={['year', 'month', 'date']}
                    size={"small"} color='secondary' autoOk={true}
                    variant="inline" inputVariant="outlined" format="MM/dd/yyyy"
                    fullWidth
                    id={`id-coBorrowerDob_${index}`}
                    label="Date of Birth"
                    value={cob.coBorrowerDob || null}
                    name={`coBorrowerDob_${index}`}
                    onChange={handleCoBorrowerDate(`coBorrowerDob_${index}`)}
                    KeyboardButtonProps={{ 'aria-label': 'change date', }}
                    placeholder={"MM/dd/yyyy"}
                    className={parentStyle.inputField}
                    InputProps={{ classes: { root: parentStyle.inputText } }}
                    InputLabelProps={{ shrink: true, }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <Box className={classes.flexBox}>
                <TextField
                  error={cobErrorHandler("coBorrowerSsn", index) !== false}
                  helperText={cobErrorHandler("coBorrowerSsn", index)}
                  size="small"
                  onChange={handleCoBorrower(index)}
                  value={cob.coBorrowerSsn}
                  name={`coBorrowerSsn_${index}`}
                  label="SSN"
                  color='secondary'
                  variant="outlined"
                  className={parentStyle.inputField}
                  inputProps={{
                    className: parentStyle.inputText,
                    maxLength: 9,
                    form: { autocomplete: 'off', },
                  }}
                />
                <TextField
                  error={cobErrorHandler("coBorrowerCellNumber", index) !== false}
                  helperText={cobErrorHandler("coBorrowerCellNumber", index)}
                  size="small"
                  onChange={handleCoBorrower(index)}
                  value={cob.coBorrowerCellNumber}
                  name={`coBorrowerCellNumber_${index}`}
                  label="Cell Number"
                  color='secondary'
                  variant="outlined"
                  className={parentStyle.inputField}
                  inputProps={{
                    className: parentStyle.inputText,
                    maxLength: 10,
                    form: { autocomplete: 'off', },
                  }}
                />
              </Box>
              <Box className={classes.flexBox}>
                <TextField
                  error={cobErrorHandler("coBorrowerPhoneNumber", index) !== false}
                  helperText={cobErrorHandler("coBorrowerPhoneNumber", index)}
                  size="small"
                  onChange={handleCoBorrower(index)}
                  value={cob.coBorrowerPhoneNumber}
                  name={`coBorrowerPhoneNumber_${index}`}
                  label="Phone Number"
                  color='secondary'
                  variant="outlined"
                  className={parentStyle.inputField}
                  inputProps={{
                    className: parentStyle.inputText,
                    maxLength: 10,
                    form: { autocomplete: 'off', },
                  }}
                />
                <TextField
                  error={cobErrorHandler("coBorrowerEmailId", index) !== false}
                  helperText={cobErrorHandler("coBorrowerEmailId", index)}
                  size="small"
                  onChange={handleCoBorrower(index)}
                  value={cob.coBorrowerEmailId}
                  name={`coBorrowerEmailId_${index}`}
                  label="Email Id"
                  color='secondary'
                  variant="outlined"
                  className={parentStyle.inputField}
                  InputProps={{
                    classes: { root: parentStyle.inputText }
                  }}
                />
              </Box>
            </Box>
          ))}

          <Box pb={5}>
            <span className={classes.addMore} onClick={() => addMoreRow()} >+ Add New Co Borrower</span>
            {coBorrowersMap.length > 1 && <span className={classes.removeBtn} onClick={() => removeRow()} >- Remove Co Borrower</span>}
          </Box>
        </Fragment>)}
        {/* Borrower Detail Ends */}

        {/* Borrower Information start  */}
        {activeSubStep === 1 && (<Fragment>
          <Box className={classes.inputBox}>
            <h1 className={parentStyle.title}>Borrower Information</h1>
            <FormControl component="fieldset" className={parentStyle.formControl}>
              <FormLabel component="legend" className={parentStyle.formLabel}>Is any borrower a service member?
              {radioErrorHandler("isBorrowerServiceMan")}
              </FormLabel>
              <RadioGroup name="isBorrowerServiceMan" value={isBorrowerServiceMan} onChange={handleChange}>
                <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            {isBorrowerServiceMan === "Yes" && <FormControl component="fieldset" className={parentStyle.formControl}>
              <FormLabel component="legend" className={parentStyle.formLabel}>Have you recently been deployed away from your
              principal residence or recently received a Permanent Change of Station (PCS) order
              {radioErrorHandler("hasPcsOrder")}
              </FormLabel>
              <RadioGroup name="hasPcsOrder" value={hasPcsOrder} onChange={handleChange}>
                <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>}
            {isBorrowerServiceMan === "Yes" && <FormControl component="fieldset" className={parentStyle.formControl}>
              <FormLabel component="legend" className={parentStyle.formLabel}>Do you intend to occupy this as your primary residence sometimes in the future?
              {radioErrorHandler("intendToOccupyPrimaryResidence")}
              </FormLabel>
              <RadioGroup name='intendToOccupyPrimaryResidence' value={intendToOccupyPrimaryResidence} onChange={handleChange}>
                <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>}
            <FormControl component="fieldset" className={parentStyle.formControl}>
              <FormLabel component="legend" className={parentStyle.formLabel}>Is any borrower the surviving spouse of a deceased
              service member who was on active duty at the time of death?
              {radioErrorHandler("isBorrowerSurvivingSpouse")}
              </FormLabel>
              <RadioGroup name='isBorrowerSurvivingSpouse' value={isBorrowerSurvivingSpouse} onChange={handleChange}>
                <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Box>

        </Fragment>)}
        {/* Borrower Information end  */}
        {activeSubStep === 2 && (<Fragment>
          {/* Property Information */}
          <Box className={classes.inputBox}>
            <h1 className={parentStyle.title}>Property Information</h1>
            <FormControl component="fieldset" className={parentStyle.formControl}>
              <FormLabel component="legend" className={parentStyle.formLabel}>I want to: {radioErrorHandler("iWantTo")}</FormLabel>
              <RadioGroup name='iWantTo' value={iWantTo} onChange={handleChange}>
                <FormControlLabel className={parentStyle.labelStyle} value="Be reviewed for all mortgage assistance options" control={<Radio />} label="Be reviewed for all mortgage assistance options" />
                <FormControlLabel className={parentStyle.labelStyle} value="Only be reviewed for selling the home for less than I owe or releasing my property" control={<Radio />} label="Only be reviewed for selling the home for less than I owe or releasing my property" />
              </RadioGroup>
            </FormControl>
            <FormControl component="fieldset" className={parentStyle.formControl}>
              <FormLabel component="legend" className={parentStyle.formLabel}>The property is my: {radioErrorHandler("propertyIsMy")}</FormLabel>
              <RadioGroup name='propertyIsMy' value={propertyIsMy} onChange={handleChange}>
                <FormControlLabel className={parentStyle.labelStyle} value="Primary Residence" control={<Radio />} label="Primary Residence" />
                <FormControlLabel className={parentStyle.labelStyle} value="Second Home" control={<Radio />} label="Second Home" />
                <FormControlLabel className={parentStyle.labelStyle} value="Investment" control={<Radio />} label="Investment" />
                <FormControlLabel className={parentStyle.labelStyle} value="Other" control={<Radio />} label="Other" />
              </RadioGroup>
            </FormControl>
            {propertyIsMy === "Other" && <TextField
              size="small"
              error={errorHandler('propertyIsMyOtherText') !== false}
              helperText={errorHandler('propertyIsMyOtherText')}
              onChange={handleChange}
              value={propertyIsMyOtherText}
              name="propertyIsMyOtherText"
              label="Other Text"
              color='secondary'
              variant="outlined"
              className={parentStyle.inputField}
              InputProps={{
                classes: { root: parentStyle.inputText }
              }}
            />}
            <FormControl component="fieldset" className={parentStyle.formControl} >
              <FormLabel component="legend" className={parentStyle.formLabel}>The property is:{radioErrorHandler("propertyIs")}</FormLabel>
              <RadioGroup name='propertyIs' value={propertyIs} onChange={handleChange}>
                <FormControlLabel className={parentStyle.labelStyle} value="Owner Occupied" control={<Radio />} label="Owner Occupied" />
                <FormControlLabel className={parentStyle.labelStyle} value="Renter Occupied" control={<Radio />} label="Renter Occupied" />
                <FormControlLabel className={parentStyle.labelStyle} value="Vacant" control={<Radio />} label="Vacant" />
                <FormControlLabel className={parentStyle.labelStyle} value="Other" control={<Radio />} label="Other" />
              </RadioGroup>
            </FormControl>
            {propertyIs === "Other" && <TextField
              size="small"
              error={errorHandler('propertyIsOtherText') !== false}
              helperText={errorHandler('propertyIsOtherText')}
              onChange={handleChange}
              value={propertyIsOtherText}
              name="propertyIsOtherText"
              label="Other Text"
              color='secondary'
              variant="outlined"
              className={parentStyle.inputField}
              InputProps={{
                classes: { root: parentStyle.inputText }
              }}
            />}
          </Box>

          <Box className={classes.inputBox}>
            <FormControl component="fieldset" className={parentStyle.formControl} >
              <FormLabel component="legend" className={parentStyle.formLabel}>Have you previously requested mortgage payment assistance
              through BSI ? {radioErrorHandler("que8")}</FormLabel>
              <RadioGroup name='que8' value={que8} onChange={handleChange}>
                <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>

            <Typography className={parentStyle.questionText}>How many single family properties other than your principal
              residence do you and/or any co-borrower(s) own individually, jointly, or with others ?</Typography>
            <TextField
              size="small"
              error={errorHandler('que9') !== false}
              helperText={errorHandler('que9')}
              onChange={handleChange}
              value={que9}
              name="que9"
              //label=""
              color='secondary'
              variant="outlined"
              className={parentStyle.inputFieldNumber}
              InputProps={{
                classes: { root: parentStyle.inputText }
              }}
              fullWidth
            />
          </Box>
          <Box className={classes.inputBox}>
            <FormControl component="fieldset" className={parentStyle.formControl} >
              <FormLabel component="legend" className={parentStyle.formLabel}>Is the mortgage on your principal
              residencecurrent? {radioErrorHandler("que10")}</FormLabel>
              <RadioGroup name='que10' value={que10} onChange={handleChange}>
                <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            {que10 === "No" && <Box>
              <Typography className={parentStyle.questionText}> If "No", number of months your payment is pastdue(if known)</Typography>
              <TextField
                size="small"
                error={errorHandler('que11') !== false}
                helperText={errorHandler('que11')}
                onChange={handleChange}
                value={que11}
                name="que11"
                //label=""
                color='secondary'
                variant="outlined"
                className={parentStyle.inputFieldNumber}
                InputProps={{
                  classes: { root: parentStyle.inputText }
                }}
              />
            </Box>}
          </Box>
          <Box className={classes.inputBox}>
            <Typography className={parentStyle.questionText}> Number of People in Household</Typography>
            <TextField
              size="small"
              error={errorHandler('que12') !== false}
              helperText={errorHandler('que12')}
              onChange={handleChange}
              value={que12}
              name="que12"
              //label=""
              color='secondary'
              variant="outlined"
              className={parentStyle.inputFieldNumber}
              InputProps={{
                classes: { root: parentStyle.inputText }
              }}
            />
          </Box>
          <Box className={parentStyle.inputBox}>
            <Typography className={parentStyle.questionText}> Mailing Address</Typography>
            <TextField
              size="small"
              error={errorHandler('que13') !== false}
              helperText={errorHandler('que13')}
              onChange={handleChange}
              value={que13}
              name="que13"
              //label=""
              color='secondary'
              variant="outlined"
              className={parentStyle.inputField}
              InputProps={{
                classes: { root: parentStyle.inputText }
              }}
            />
          </Box>
          <Box className={classes.inputBox}>
            <Typography className={parentStyle.questionText}> Property Address(if same as mailing address, just write "same"):{radioErrorHandler("que14")}</Typography>
            <TextareaAutosize
              rowsMin={3}
              // size="small"
              onChange={handleChange}
              value={que14}
              name="que14"
              //label=""
              color='secondary'
              variant="outlined"
              className={parentStyle.inputTextTextarea}
            // InputProps={{
            //   classes: { root: parentStyle.inputText }
            // }}
            />
          </Box>
        </Fragment>)}
        {/* Property Information end */}

        {/* 15-22 */}
        {/* Property Listed For Sale start  */}
        {activeSubStep === 3 && (<Fragment>
          <Box>
            <h1 className={parentStyle.title}>Property Listed For Sale</h1>
            <FormControl component="fieldset" className={parentStyle.formControl} >
              <FormLabel component="legend" className={parentStyle.formLabel}>Is the property listed for sale?{radioErrorHandler("que15")}</FormLabel>
              <RadioGroup name='que15' value={que15} onChange={handleChange}>
                <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>

            {que15 === "Yes" ? (<Box>
              <Box className={classes.inputBox}>
                <Typography className={parentStyle.questionText}>If yes, what was the listing date?</Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    id={'date-id-que16'}
                    views={['year', 'month', 'date']}
                    size={"small"} color='secondary' autoOk={true}
                    variant="inline" inputVariant="outlined" format="MM/dd/yyyy"
                    fullWidth
                    value={que16 || null}
                    name={"que16"}
                    onChange={handleChangeDate("que16")}
                    KeyboardButtonProps={{ 'aria-label': 'change date', }}
                    placeholder={"MM/dd/yyyy"}
                    className={parentStyle.inputFieldDate}
                    InputProps={{ classes: { root: parentStyle.inputText } }}
                    InputLabelProps={{ shrink: true, }}
                  />
                </MuiPickersUtilsProvider>
              </Box>

              <FormControl component="fieldset" className={parentStyle.formControl} >
                <FormLabel component="legend" className={parentStyle.formLabel}>Have you received an offer on the
                property?{radioErrorHandler("que17")} </FormLabel>
                <RadioGroup name='que17' value={que17} onChange={handleChange}>
                  <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
              {que17 === 'Yes' && <Fragment>
                <Box className={classes.flexBox}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      id={`date-id-que18`}
                      views={['year', 'month', 'date']}
                      error={errorHandler('que18') !== false}
                      helperText={errorHandler('que18')}
                      size={"small"} color='secondary' autoOk={true}
                      variant="inline" inputVariant="outlined" format="MM/dd/yyyy"
                      fullWidth
                      label="Date of Offer"
                      value={que18 || null}
                      name={"que18"}
                      onChange={handleChangeDate("que18")}
                      KeyboardButtonProps={{ 'aria-label': 'change date', }}
                      placeholder={"MM/dd/yyyy"}
                      className={parentStyle.inputField}
                      InputProps={{ classes: { root: parentStyle.inputText } }}
                      InputLabelProps={{ shrink: true, }}
                    />
                  </MuiPickersUtilsProvider>
                  <TextField
                    size="small"
                    error={errorHandler('que19') !== false}
                    helperText={errorHandler('que19')}
                    onChange={handleChange}
                    value={que19}
                    name="que19"
                    label="Amount of Offer"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText },
                      maxLength: 20,
                    }}
                  />
                </Box>
                <Box className={classes.flexBox}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      id={`date-id-que20`}
                      views={['year', 'month', 'date']}
                      error={errorHandler('que20') !== false}
                      helperText={errorHandler('que20')}
                      size={"small"} color='secondary' autoOk={true}
                      variant="inline" inputVariant="outlined" format="MM/dd/yyyy"
                      fullWidth
                      label="Closing Date"
                      value={que20 || null}
                      name={"que20"}
                      onChange={handleChangeDate("que20")}
                      KeyboardButtonProps={{ 'aria-label': 'change date', }}
                      placeholder={"MM/dd/yyyy"}
                      className={parentStyle.inputField}
                      InputProps={{ classes: { root: parentStyle.inputText } }}
                      InputLabelProps={{ shrink: true, }}
                    />
                  </MuiPickersUtilsProvider>
                </Box>

                <Box className={classes.flexBox}>
                  <TextField
                    size="small"
                    error={errorHandler('que21') !== false}
                    helperText={errorHandler('que21')}
                    onChange={handleChange}
                    value={que21}
                    name="que21"
                    label="Agent's Name"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />
                  <TextField
                    size="small"
                    error={errorHandler('que22') !== false}
                    helperText={errorHandler('que22')}
                    onChange={handleChange}
                    value={que22}
                    name="que22"
                    label="Agent’s Phone Number"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    inputProps={{
                      className: parentStyle.inputText,
                      maxLength: 10,
                      form: { autocomplete: 'off', },
                    }}
                  />
                </Box>

              </Fragment>}


            </Box>) : null}

          </Box>
          {/* 15-22 end */}
          {/* 23-27 start */}

          <Box className={classes.inputBox}>
            <h1 className={parentStyle.title}>Credit counseling agency</h1>
            <FormControl component="fieldset" className={parentStyle.formControl} >
              <FormLabel component="legend" className={parentStyle.formLabel}>Have you contacted a credit counseling agency for help?{radioErrorHandler("que23")} </FormLabel>
              <RadioGroup name='que23' value={que23} onChange={handleChange}>
                <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>

            {que23 === "Yes" ? (<Fragment>
              <Box className={classes.flexBox}>
                <TextField
                  size="small"
                  error={errorHandler('que24') !== false}
                  helperText={errorHandler('que24')}
                  onChange={handleChange}
                  value={que24}
                  name="que24"
                  label="Counselor's Name"
                  color='secondary'
                  variant="outlined"
                  className={parentStyle.inputField}
                  InputProps={{
                    classes: { root: parentStyle.inputText }
                  }}
                />
                <TextField
                  size="small"
                  error={errorHandler('que25') !== false}
                  helperText={errorHandler('que25')}
                  onChange={handleChange}
                  value={que25}
                  name="que25"
                  label="Agency Name"
                  color='secondary'
                  variant="outlined"
                  className={parentStyle.inputField}
                  InputProps={{
                    classes: { root: parentStyle.inputText }
                  }}
                />
              </Box>
              <Box className={classes.flexBox}>
                <TextField
                  size="small"
                  error={errorHandler('que26') !== false}
                  helperText={errorHandler('que26')}
                  onChange={handleChange}
                  value={que26}
                  name="que26"
                  label="Counselor's Phone Number"
                  color='secondary'
                  variant="outlined"
                  className={parentStyle.inputField}
                  inputProps={{
                    className: parentStyle.inputText,
                    maxLength: 10,
                    form: { autocomplete: 'off', },
                  }}
                />
                <TextField
                  size="small"
                  error={errorHandler('que27') !== false}
                  helperText={errorHandler('que27')}
                  onChange={handleChange}
                  value={que27}
                  name="que27"
                  label="Counselor's Email"
                  color='secondary'
                  variant="outlined"
                  className={parentStyle.inputField}
                  InputProps={{
                    classes: { root: parentStyle.inputText }
                  }}
                />
              </Box>
            </Fragment>) : null}
          </Box>
        </Fragment>)}
        {/* Property Listed For Sale End  */}


        {/* Real Estate Tax Start  */}
        {activeSubStep === 4 && (<Fragment>
          <Box className={classes.inputBox}>
            <h1 className={parentStyle.title}>Real Estate Tax</h1>
            <FormControl component="fieldset" className={parentStyle.formControl} >
              <FormLabel component="legend" className={parentStyle.formLabel}>Who pays the real estate tax bill on your property?{radioErrorHandler("que28")} </FormLabel>
              <RadioGroup name='que28' value={que28} onChange={handleChange}>
                <FormControlLabel className={parentStyle.labelStyle} value="I do" control={<Radio />} label="I do" />
                <FormControlLabel className={parentStyle.labelStyle} value="Lender does" control={<Radio />} label="Lender does" />
                <FormControlLabel className={parentStyle.labelStyle} value="Paid bycondoor HOA" control={<Radio />} label="Paid bycondoor HOA" />
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset" className={parentStyle.formControl} >
              <FormLabel component="legend" className={parentStyle.formLabel}>Are the taxes current?{radioErrorHandler("que29")}</FormLabel>
              <RadioGroup name='que29' value={que29} onChange={handleChange}>
                <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset" className={parentStyle.formControl} >
              <FormLabel component="legend" className={parentStyle.formLabel}>Condominium or HOA Fees?{radioErrorHandler("que30")}</FormLabel>
              <RadioGroup name='que30' value={que30} onChange={handleChange}>
                <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
                {/* <input type="text" /> */}
              </RadioGroup>
            </FormControl>
            {que30 === "No" && <TextField
              size="small"
              error={errorHandler('que30OtherText') !== false}
              helperText={errorHandler('que30OtherText')}
              onChange={handleChange}
              value={que30OtherText}
              name="que30OtherText"
              label="Per Month"
              color='secondary'
              variant="outlined"
              className={parentStyle.inputFieldNumber}
              InputProps={{
                classes: { root: parentStyle.inputText }
              }}
            />}

            <FormControl component="fieldset" className={parentStyle.formControl} >
              <FormLabel component="legend" className={parentStyle.formLabel}>Are the fees paid current?{radioErrorHandler("que31")} </FormLabel>
              <RadioGroup name='que31' value={que31} onChange={handleChange}>
                <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            <Typography className={parentStyle.questionText}>Name and address that fees are paid to</Typography>
            <TextField
              size="small"
              error={errorHandler('que32') !== false}
              helperText={errorHandler('que32')}
              onChange={handleChange}
              value={que32}
              name="que32"
              //label="Counselor's Email"
              color='secondary'
              variant="outlined"
              className={parentStyle.inputField}
              InputProps={{
                classes: { root: parentStyle.inputText }
              }}
            />
          </Box>
          <Box>
            <h1 className={parentStyle.title}>Insurance premiums </h1>
            <FormControl component="fieldset" className={parentStyle.formControl} >
              <FormLabel component="legend" className={parentStyle.formLabel}>Who pays the insurance premiums for your property?{radioErrorHandler("que33")}</FormLabel>
              <RadioGroup name='que33' value={que33} onChange={handleChange}>
                <FormControlLabel className={parentStyle.labelStyle} value="I do" control={<Radio />} label="I do" />
                <FormControlLabel className={parentStyle.labelStyle} value="Lender does" control={<Radio />} label="Lender does" />
                <FormControlLabel className={parentStyle.labelStyle} value="Paid bycondoor HOA" control={<Radio />} label="Paid bycondoor HOA" />
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset" className={parentStyle.formControl} >
              <FormLabel component="legend" className={parentStyle.formLabel}>Is the policy current?{radioErrorHandler("que34")}</FormLabel>
              <RadioGroup name='que34' value={que34} onChange={handleChange}>
                <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            <Box className={classes.flexBox}>
              <TextField
                size="small"
                error={errorHandler('que35') !== false}
                helperText={errorHandler('que35')}
                onChange={handleChange}
                value={que35}
                name="que35"
                label="Name(s) of Insurance Company"
                color='secondary'
                variant="outlined"
                className={parentStyle.inputField}
                InputProps={{
                  classes: { root: parentStyle.inputText }
                }}
              />
              <TextField
                size="small"
                error={errorHandler('que36') !== false}
                helperText={errorHandler('que36')}
                onChange={handleChange}
                value={que36}
                name="que36"
                label="Insurance Company Phone Number(s)"
                color='secondary'
                variant="outlined"
                className={parentStyle.inputField}
                inputProps={{
                  className: parentStyle.inputText,
                  maxLength: 10,
                  form: { autocomplete: 'off', },
                }}
              />
            </Box>
          </Box>
        </Fragment>)}
        {/* Real Estate Tax End  */}
      </form>

      <Box>        
        <NextPrevButton
          handleNextCallback={nextSubStep}
          handlePrevCallBack={backSubStep}
          activeStepIndex={props.activeSection}
          subStep={activeSubStep}></NextPrevButton>
      </Box>
    </Box>
  )
}