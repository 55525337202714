import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from "@material-ui/core";
import { useStyles } from './styles';
import { useDispatch } from 'react-redux';
import { getContent } from '../../actions';
import { scrollTop } from '../../helpers';
import './style.css'
import CustomBreadcrum from '../Breadcrum';

export const Aboutus = () => {
  const classes = useStyles();
  const [content, setContent] = useState([])
  const dispatch = useDispatch()
  const getContentData = async () => {
    const res = await dispatch(getContent('PC023'));
    if (res) {
      setContent(res);
      scrollTop()
    }

  };
  useEffect(() => {
    getContentData();
    scrollTop()
  }, []);

  return (
    <Box className={classes.bg}>
      <Box className={classes.root}>
        <CustomBreadcrum backPage="Home" backPageLink="/" activePage="About Us" />
        {content.length !== 0 ?
          < Box className={classes.container} dangerouslySetInnerHTML={{ __html: content[0].contentValue }} />
          :
          <Box className={classes.loaderContainer}>
            <CircularProgress />
          </Box>
        }
      </Box>
    </Box>
  )
}