import React from 'react';
import { useSelector } from 'react-redux';
import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import pdf_icon from '../../assets/pdf_icon.svg';
import Cookies from 'js-cookie';

export const ThirdPartyPdfFile = React.memo(() => {
  const mytheme = useSelector(state => state.theme);
  const { primaryColorCode, secondaryColorCode } = mytheme;
  const props = { primaryColorCode: primaryColorCode, secondaryColorCode: secondaryColorCode };
  const classes = useStyles(props);
  const requestType = Cookies.get('requestType')

  return (
    <Box className={classes.root} >
      <h3 className={classes.subTitle}>Alternate Option For Making Third Party Request</h3>
      {requestType === 'subordination request' ?
      <div>
        <p className={`${classes.bobyText} ${classes.topPDFText}`}>
        We are unable to process your request
        The borrower information you have provided does not have an online account. </p>
        <p className={`${classes.bobyText} ${classes.topPDFText}`}>Please submit your request to <a href={'mailto:subordinations@bsifinancial.com'}>subordinations@bsifinancial.com </a>
      </p> 
      </div>
      :
      <div>
      <p className={`${classes.bobyText} ${classes.topPDFText}`}>
        Please enter all of the information below. If the information is successfully matched,
        we will provide you with status information through email.
      </p>
      <Box className={classes.pdfBox}>
        <Box className={classes.pdfContainer}>
          <Box className={classes.flexBox}>
            <Box>
              <h4 className={`${classes.bobyText} ${classes.pdfHeading}`}>Third Party Request Form</h4>
              <Box className={classes.flex}>
                <img src={pdf_icon} alt="pdf icon" />
                <Box className={classes.pdfFile}>
                  <p className={classes.smallText}>File format :PDF <br /><br />File Size: 1 mb</p>
                </Box>
              </Box>
            </Box>
            <a type="button" href="https://d3idzssovnbdsz.cloudfront.net/images/Assets/PDFs/3rdPartyRequest.pdf"
              target="_blank" className={`${classes.button}`}
            >Download Form
              <GetAppIcon className={classes.downLoadIcon} />
            </a>
          </Box>
        </Box>
      </Box>
     </div>
    }
    </Box>
  )
})