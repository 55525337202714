import React, { Fragment, useState } from 'react';
import { useStyles } from './styles';
import { Box, Button, Typography } from '@material-ui/core';
import equalHousing  from '../../assets/equal-housing.png';
import { insertActivityLog } from '../../actions'
import Cookies from 'js-cookie';
import { getAppSource, getUserType } from '../../helpers'

export const GoMortgageCard = (props) => {
  const { bannerBody, bannerImageUrl, bannerTitle,
    buttonColor, buttonText, buttonURL, titleBgColor, bannerSubTitle } = props.banner;

  const classes = useStyles();
  
  const sendActivityData = async () => {
    const remark = "GoMortgage - User clicked on Refinance Card button";
    const data = {
      "accountNumber": props.accountNumber,
      "activityCode": "UAL192",
      "activityStatus": "Success",
      "action": "GetAccountInfo",
      "controller": "Dashboard",
      "apiName": "GetAccountInfo",
      "activityRemark":  remark,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertActivityLog(data);
  }

  const route = () => {
    sendActivityData()
    window.open(buttonURL, '_blank')
  }

  return (<Fragment>
    <Box className={classes.root}>
      <Box className={classes.body} >
        <img className={classes.logo} src={bannerImageUrl} alt='logo' />
      
        <Box className={classes.bodyTextContainer}>
          <div  dangerouslySetInnerHTML={{ __html: bannerBody }}></div> 
        </Box>
       
      </Box>
      <Box className={classes.footer}>
      <Button onClick={() => route()} className={classes.btn} style={{ backgroundColor: buttonColor }}>{buttonText}</Button>
        <div style={{marginTop: '8px'}}>
          <img src={equalHousing} alt="" style={{ marginRight: '68px', width: '30px', float: 'right'}} />
          <Typography style={{ fontSize: '12px', marginRight: '20px', float: 'right'}}>{"Go Mortgage, LLC"}</Typography>
          <Typography style={{ marginRight: '30px',  fontSize: '12px', float: 'right'}}>{"NMLS ID# 1018"}</Typography>
         </div>
      </Box>
    </Box>
   
  </Fragment>
  )
}
