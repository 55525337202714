import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import line from '../../assets/line.png';

export const useStyles = makeStyles((theme) => (
  {
    root: {
      width: '100%',
      minHeight: '100vh',
      backgroundColor: '#ccc',
      display: 'flex',
      justifyContent: 'center',
      backgroundPosition: "bottom left",
      backgroundRepeat: "no-repeat",
      backgroundSize: 'contain',
    },
    infoStrip: {
      backgroundColor: '#FAFAFA',
      margin: '8px',
      width: '100%',
      margin: '19px 0px 19px 0px',
      border:'1px solid #B4B4B4',
      borderRadius : '10px',
      [theme.breakpoints.up('sm')]: {
        // height: '229px',
      },
      [theme.breakpoints.down('sm')]: {
        // height: '800px',
      },
    },
    infoBox: {
      backgroundColor: '#1A4664',
      margin: '8px',
      width: '100%',
      margin: '19px 0px 19px 0px',
      borderRadius : '10px'
    },
    information: {
      color: '#ffffff',
      fontFamily: "Inter",
      fontSize: "16px",
      letterSpacing: "0.36px",
      lineHeight: "19px",
      padding: '10px 0px 5px 20px'
    },
    image: {
      marginLeft: '300px',
      marginTop: '-230px',
      marginBottom: '20px',
      height: '65px',
      [theme.breakpoints.down('sm')]: {
        height: '0px'
      },
    },
    errorMsg: {
      fontSize: '1.4em',
      fontWeight: 'bold',
      textAlign: 'justify',
      marginBottom: 10,
      fontFamily: 'Inter',
      color: "red",
      marginTop: 30
    },
    nextEscrow: {
     padding: '0px 40px',
     [theme.breakpoints.down('sm')]: {
      padding: '0px 15px'
    },
    },
    escrowSection: {
     padding: '35px 40px 10px 42px',
     [theme.breakpoints.down('sm')]: {
      padding: '30px 40px 10px 15px',
    },
    },
    escrowHeading: {
      color: '#EDB36A',
      fontFamily: "Inter",
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: '600'
    },
    loader: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }, 
    escrowDesc: {
      color: '#EDB36A',
      fontFamily: "Inter",
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: '600',
      paddingBottom: '38px',
      paddingTop: '38px'
    },
    escrowParagraph: {
      color: '#979797',
      fontFamily: "Montserrat",
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: '400'
    },
    boxText : {
      padding: '10px 0px 2px 2px',
      fontSize: '16px',
      fontWeight: '500',
      color: '#979797',
      lineHeight: '32px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
      }
    },
    boxTextValue : {
      padding: '14px 10px 2px 0px',
      fontSize: '16px',
      fontWeight: '600',
      color: '#636363',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      [theme.breakpoints.down('sm')]: {
        fontSize: '26px',
        padding: '5px 10px 2px 2px',
      }
    },
    date: {
      color: '#4F4F4F',
      fontFamily: "Inter",
      fontSize: "14px",
      lineHeight: "24px",
      fontWeight: '600'
    },
    nextEscrowDate: {
      padding: '7px 0px 14px 0px',
      fontFamily: "Inter",
      fontSize: "14px",
      lineHeight: "24px",
      fontWeight: '400',
      [theme.breakpoints.down('sm')]: {
        fontSize: '15px'
      }
    },
    escrowAccount: {
      padding: '7px 0px 14px 48px',
      fontFamily: "Inter",
      fontSize: "14px",
      lineHeight: "24px",
      fontWeight: '600',
      color: '#4F4F4F',
      [theme.breakpoints.down('sm')]: {
        padding: '4px 0px 14px 0px',
      }
    },
    manageEscrow: {
      color: '#0079D0',
      cursor: "pointer",
      fontFamily: "Inter",
      fontSize: "14px",
      lineHeight: "24px",
      fontWeight: '400',
    },
    escrowRec : {
      color: '#979797',
      fontFamily: "Inter",
      fontSize: "17px",
      lineHeight: "22px",
      fontWeight: '500',  
      paddingBottom: '10px',
      paddingTop: '13px'
    },
    escrowRecAns : {
      color: '#636363',
      fontFamily: "Inter",
      fontSize: "28px",
      lineHeight: "22px",
      fontWeight: '400',
      padding: '2px 0px 20px 0px',
    },
    line: {
      border: '1px solid #E3E3E3'
    },
    infoText: {
      color: '#474747',
      fontFamily: "Montserrat",
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: '400',
      padding: '5px 20px 20px 16px'
    },
    
    infoHeading: {
      color: '#626262',
      fontFamily: "Montserrat",
      fontSize: "18px",
      lineHeight: "22px",
      fontWeight: '700',
      padding: '26px 20px 5px 16px'
    },
    container: {
      width: '120em',
      minWidth: "33.3em",
      marginTop: '3.6em',
      marginBottom: '6.6em',
      [theme.breakpoints.down('sm')]: {
        margin: '42px 16px',
      },
    },
    pageHeading: {
      color: '#1A4664',
      fontFamily: 'Montserrat',
      fontSize: '22px',
      fontWeight: '700',
      letterSpacing: '0.18px',
      lineHeight: '24px',
      marginBottom: 25,
      marginTop: 24
    },
    formContainer: {
      minHeight: "300px",
      backgroundColor: '#fff',
      borderRadius: 12,
      boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    appBar: {
      width: "100%",
      backgroundColor: theme.palette.secondary.main,
      color: theme.header.fontColor,
      padding: '0px 16px',
      borderRadius: '4px'
    },

    tabStyle: {
      display: 'flex',
      flexWrap: 'wrap',
      boxSizing: 'border-box',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',        
      }
    },

    containerPadding: {
      padding: "1px 0px"
    },

    tabContainer: {
      borderRadius: "12px",
      // backgroundColor: "#F5F5F5",
      [theme.breakpoints.down('sm')]: {
        overflowX: 'scroll',
      }
    },
    tabShedow: {     
      //
    },
    tabs: {
      color: "#888b90",
      // fontFamily: "Noto Serif Thai",
      fontSize: "18px",
      fontWeight: "600",
      letterSpacing: "0.63px",
      lineHeight: "29px",
      textAlign: "center",
      cursor: "pointer",
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
      borderBottom: '1px solid #d2cfcf',
      cursor: 'pointer',
      border: '1px solid #B4B4B4',
      // boxShadow: '0px -2px 3px #6c6363',
      // width: '33.33%',      
      padding: '22px 10px 16px 10px',
      [theme.breakpoints.down('md')]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        padding: '16px 10px',
      },
    },
    tabsDisable: {
      backgroundColor: 'lightgrey',
      color: "#888b90",
      fontFamily: "Montserrat",
      fontSize: "23px",
      fontWeight: "600",
      letterSpacing: "0.63px",
      lineHeight: "29px",
      textAlign: "center",
      cursor: "default",
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
      borderBottom: '1px solid #d2cfcf',
      boxShadow: '0px -2px 3px #6c6363',
      width: '33.33%',      
      padding: '17px 10px 20px 10px',
      [theme.breakpoints.down('md')]: {
        fontSize: "20px",
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: "20px",
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        padding: '16px 10px',
      },
    },
    linkStyle: {
      color: "inherit",
      textDecoration: "none"
    },
    activeTab: {
      borderTopLeftRadius: "12px",
      borderTopRightRadius: "12px",
      backgroundColor: "#ffffff",
      color: "#fafafa",
      // fontWeight: "600",
      borderBottom: 'none',
      '& .linkStyle': {
        color: "#727272",

      },
    },
    labelText: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "16px",
      letterSpacing: "0.36px",
      lineHeight: "19px",
    },
    labelTextBold: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "600",
      letterSpacing: "0.36px",
      lineHeight: "19px",
      marginTop: '7px',
      marginBottom: '7px',
      paddingLeft: '56px'
    },
    labelTextBoldMobile: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "600",
      letterSpacing: "0.36px",
      lineHeight: "19px",
      marginTop: '7px',
      marginBottom: '7px',
      paddingLeft: '49px'
    },
    icon: {
      float: "left",
      marginRight: "30px"
    },
    profileBtn: {
      width: "192px",
      fontSize: "16px",
      fontFamily: "Montserrat",
      fontWeight: "600",
      letterSpacing: "0.53px",
      lineHeight: "19px",
      textAlign: "center",
      height: '40px'
    },
    closeBtn: {
      height: "20px",
      width: "20px",
      backgroundColor: "#ffffff",
      color: "#3064A6",
      borderRadius: "50%",
      display: "inline-block",
      textAlign: "center",
      fontSize: "14px",
      fontWeight: 600,
      cursor: "pointer"
    },

    pl60: {
      paddingLeft: "60px"
    },
    infoIcon: {
      padding: "6px 1px",
      fontSize: '35px',
      color: theme.palette.secondary.contrastText,
    },
    infolink: {
      fontWeight: 600,
      cursor: "pointer",
      textDecoration: "underline"
    },
    mobileInfoTooltip: {
      boxSizing: "border-box",      
      border: "1px solid #E8E8E8",
      borderRadius: "12px",
      backgroundColor: "#FFFFFF",
      boxShadow: "0 3px 6px 0 rgba(0,35,64,0.2)",
      position: "absolute",
      zIndex: "9",
      width: "360px",
      marginLeft: "136px",
      marginTop: "-5px",
      padding: "4px"
    },
    emailInfoTooltip: {
      boxSizing: "border-box",
      border: "1px solid #E8E8E8",
      borderRadius: "12px",
      backgroundColor: "#FFFFFF",
      boxShadow: "0 3px 6px 0 rgba(0,35,64,0.2)",
      position: "absolute",
      zIndex: "9",
      width: "360px",
      marginLeft: "112px",
      marginTop: "-29px",
      padding: "4px"
    },

    profileMargin: {
      marginBottom: '26px',
      paddingRight: '20px'
    },

    labelTextMobile: {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '60px'
      },
    },

    pl14: {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '14px'
      },
    },

    pt0: {
      [theme.breakpoints.down('xs')]: {
        paddingTop: '0'
      },
    },

    noteText: {
      fontFamily: "Montserrat",
      fontSize: "14px",
      color: 'rgba(0, 0, 0, 0.74)',
      fontWeight: '500',
    },

    wordBreak: {
      wordBreak: 'break-all'
    },

    errorIcon: {
      // fontSize: "25px",
      float: "left",
      width: '18px',
      height: '18px',
      marginRight: "3px",
      // paddingLeft: '56px'
    },
    verifyBtn: {
      float: 'right',
      fontSize: "16px",
      fontFamily: "Montserrat",
      fontWeight: "600",
      letterSpacing: "0.53px",
      lineHeight: "19px",
      textAlign: "center",
      height: '40px',
      padding: '6px !important'
    },
    
    labelTextname: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "600",
      letterSpacing: "0.36px",
      lineHeight: "19px",
      marginTop: '7px',
      marginBottom: '7px',
      // paddingLeft: '56px'
    },
    emailContainer: {
      paddingLeft: 30
    },
    verifyText: {
      backgroundColor: '#7EB1CC',
      color: 'white',
      fontFamily: "Montserrat",
      fontSize: "13px",
      fontWeight: "600",
      letterSpacing: "0.36px",
      // lineHeight: "13px",
      border: 'none',
      cursor: "pointer",

      paddingLeft: '13px',
      paddingRight: '13px',
      borderRadius: '4px',
      paddingTop: '5px',
      paddingBottom: '5px',
      // padding: '0.5px 1px !important'
      // textDecoration: 'underline'
    },
   
    transferBox: {
      width: '100%',
      alignItems: 'center',
      backgroundColor: '#1A4664',
      borderRadius: 4,
      padding: '1em',
      marginBottom: '0.5em',
      borderRadius: '8px',
    },
  
    clickLink: {
      color: '#007FFF',
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontStyle: 'normal',
      cursor:'pointer',
      fontWeight: '600',
      lineHeight: '16px',
      textDecorationLine: 'underline'
    },
    clickLinkDisable: {
      color: '#555D6C',
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '16px',
      textDecorationLine: 'underline'
    },
    completeUrSurvey: {
      color: "#FFFFFF",
      fontFamily: "Montserrat",
      fontSize: '16px',
      fontWeight: "400",
      paddingLeft: 5
    },
    infoIcon: {
      color: "#fff",
      width: 25,
      height: 25,
      marginRight: 10,
    },
    click: {
      textDecoration: 'underline', 
      cursor: 'pointer', 
      fontWeight: '700'
    }
  }
));

export const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: '#25ABC7',
    fontSize: '40px'
  },
  tooltip: {
    color: "#fff",
    backgroundColor: '#25ABC7',
    padding: '20px 15px',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    letterSpacing: '0.55px',
    lineHeight: '21px',
    width: '500px',
    maxWidth: '500px',
    borderRadius: '10px'
  },
}));


export const RedTooltip = withStyles((theme) => ({
  arrow: {
    color: "#B91717",
  },
  tooltip: {
    backgroundColor: '#B91717',
    color: '#fff',
    fontFamily: "Montserrat",
    boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
    padding: "0.5em 1.6em",
    fontSize: 16,
    maxWidth: 600,
  },
}))(Tooltip);
