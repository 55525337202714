import React from 'react';
import { useStyles } from './styles';
import { Box, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';





export const InsuranceBanner = (props) => {

  const { bannerBody, bannerImageUrl, bannerTitle,
    buttonColor, buttonText, buttonURL, titleBgColor } = props.banner;

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.header} style={{ backgroundColor: titleBgColor }}>
        <h1 className={classes.title}>{bannerTitle}</h1>
      </Box>
      <Box className={classes.body} >
        <img className={classes.logo} src={bannerImageUrl} alt='logo' />
        <Box className={classes.bodyTextContainer}>
          <div className={classes.bodyText} dangerouslySetInnerHTML={{ __html: bannerBody }}></div>
        </Box>

      </Box>

      <Box className={classes.footer}>
        {buttonURL && buttonURL.length ? <a href={buttonURL} className={classes.btn} style={{ backgroundColor: buttonColor }} target="_blank">{buttonText}</a> : null}
      </Box>
    </Box>
  )
}
