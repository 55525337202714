import { Box, Button, Grid, Typography, CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import email from '../../assets/CommunicationPreferences/email.png';
import phone from '../../assets/CommunicationPreferences/phone.png';
import paperless from '../../assets/CommunicationPreferences/paperless.png';
import sms from '../../assets/CommunicationPreferences/sms.png';
import { getAppSource, getUserType, maskEmail, maskMobile, renderDefaultMsg } from '../../helpers';
import { EMAIL_TOOLTIP, MESSAGE_TOOLTIP, PHONE_TOOLTIP,PAPERLESS_TOOLTIP } from '../../helpers/constants';
import { SwitchButton } from '../SwitchButton';
import { useStyles, useStylesBootstrap } from './styles';

import { insertActivityLog } from '../../../src/actions';
import { getCommPreference, updateCommPreference } from '../../actions';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';


const LightToolTip = withStyles((theme) => ({
  arrow: {
    color: '#235A86',
    // backgroundColor: '#ffffff',
  },
  tooltip: {
    backgroundColor: '#235A86',
    color: '#fffff',
    fontFamily: "Montserrat",
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    padding: "16px",
    fontSize: 14,
    maxWidth: 350,
    fontWeight: 500,
    borderRadius: 8,
    // border: "1px solid #4D4D4D"
  },
}))(Tooltip);


function BlueOnWhiteTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} placement="bottom-end" />;
}

export const CommunicationPreference = (props) => {
  const { details } = props;
  let editProfileReducerDataState = useSelector(state => state.setEditProfileDataReducer);
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [radioValue, setRadioValue] = React.useState(true);
  const [cPData, setCPData] = React.useState({})

  const theme = useTheme();
  const [isEmailConsent, setIsEmailConsent] = useState(true)
  const [isCellConsent, setIsCellConsent] = useState(true)
  const [IsSmsConsent, setIsSmsConsent] = useState(true);
  const [statementRecieve, setStatementRecieve] = useState(true);

  const [isEmail, setIsEmail] = useState(true);
  const [isCell, setIsCell] = useState(true);
  const [isSms, setIsSms] = useState(true);
  const [isStatementRcv, setIsStatementRcv] = useState(true);
  const [isCPSubmit, setIsCPSubmit] = useState(false)
  const [errorMessage, setErrorMessage] = useState('');
  const [isMailVerified, setMailVerify] = useState(false);
  const [isMobileVerified, setMobileVerify] = useState(false);
  const [msg, setMsg] = useState("");
  const [Result, setResult] = useState({});
  const [cpLoading, setIsCPLoading] = useState(false);


  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    // if (props.accountNumber) {
    getCP()
    // }
  }, []);

  const handleSwitchCallback = (item) => {
    if (item.fieldName === 'isEmailConsent') {
      setIsEmailConsent(item.fieldValue)
    } else if (item.fieldName === 'isCellConsent') {
      setIsCellConsent(item.fieldValue)
    } else if (item.fieldName === 'IsSmsConsent') {
      setIsSmsConsent(item.fieldValue)
    } else if (item.fieldName === 'statementRecieve') {
      setStatementRecieve(item.fieldValue)
    } 
  }
  const insertActivityData = async (buttonName) => {
    let text = `${'Communication preference Popup'} - ` + `User clicked on ${buttonName} button.`;
    if(editProfileReducerDataState?.isFromProfilePopup){
       text = `${'Communication preference Popup'} - ` + `User clicked on ${buttonName} button and routed from profile update popup.`;
    }
    const data = {
      "accountNumber": props.accountNumber ? props.accountNumber : '',
      "activityCode": "UAL204",
      "activityStatus": "Success",
      "action": `${buttonName}`,
      "controller": "Contact Validation Popup",
      "apiName": "contactValidation",
      "activityRemark": text,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };
    await insertActivityLog(data);
}
const handleSubmit = async () => {
  setMsg('');
  setErrorMessage('')
  const requestData = {
    "accountNumber": props.accountNumber,
    "isLanveraEStatements": statementRecieve,
    "isLanveraESmtNotification": isEmailConsent,
    "isCellConsent": isCellConsent,
    "isSmsConsent": IsSmsConsent,
    "isEmailConsent": isEmailConsent,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }
    setIsCPSubmit(true)
    setErrorMessage('')
    setIsCPLoading(true)
    const result = await updateCommPreference(requestData);
    if (result) {
      const { lstResponseStatus } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        props.updateStore();
        props.handleClosePopup()
        setIsCPLoading(false)
        insertActivityData('Continue');
      } else {
        setIsCPSubmit(false)
        setIsCPLoading(false)
        insertActivityData('Continue');
        setErrorMessage(lstResponseStatus[0].statusDesc)
      }
    } else {
      setIsCPSubmit(false)
      setIsCPLoading(false)
      insertActivityData('Continue');
      setErrorMessage(renderDefaultMsg('400'))
    }
  }
  const handleClosePopup=()=>{
    props.handleClosePopup();
    insertActivityData('Skip & Continue');
  }
  const getCP = async () => {
    const requestData = {
      "accountNumber": props.accountNumber,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }

    setErrorMessage('')
    let result = await getCommPreference(requestData)
    setResult(result)

    if (result) {
      const { lstResponseStatus } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        setCPData(result)
        setIsEmailConsent(result.isEmailConsent)
        setIsCellConsent(result.isCellConsent)
        setIsSmsConsent(result.isSmsConsent)
        setStatementRecieve(result.isLanveraEStatements)

        if (result.isEmailConsent && result.isCellConsent && result.isSmsConsent && result.isLanveraEStatements) {
            props.updateDone();
        }
        else {
          setOpen(true);
        }

        setIsEmail(result.isEmailConsent)
        setIsCell(result.isCellConsent)
        setIsSms(result.isSmsConsent)
        setIsStatementRcv(result.isLanveraEStatements)
        setMailVerify(result.isEmailVerified)
        setMobileVerify(result.isMobileVerified)
      } else {
        setIsCPLoading(false)
        setCPData({})
        setErrorMessage(lstResponseStatus[0].statusDesc)
      }
    } else {
      setCPData({})
      setErrorMessage(renderDefaultMsg('400'))
    }
  }
  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} open={open}>
      <Box className={classes.root}>
        <Box className={classes.header}>
          <Typography className={classes.title}>Please update your communication preferences</Typography>
        </Box>
        <Box className={classes.cardBody}>
          <Box style={{ width: '100%' }}>
            {!isEmail && <Box mb={3} style={{ width: '100%',marginBottom:1 }}>
              <Grid container direction="row" justify="space-between" alignItems="center"   >
                <Grid item xs={9} sm={9} md={11}>
                  <Typography className={classes.onOffText}>
                    <BlueOnWhiteTooltip arrow placement="left-start" title={EMAIL_TOOLTIP}>
                      <span style={{ position: 'relative', top: '7px', left: '-8px', cursor: 'pointer' }}><img src={email} alt="check icon" style={{ width: '20px', height: '23px', }} /></span>

                    </BlueOnWhiteTooltip>
                    Receive emails on {props.emailID ? maskEmail(props.emailID) : ''}</Typography>
                </Grid>
                <Grid item xs={3} sm={3} md={1} className={classes.textRight}>
                  <SwitchButton
                    status={isEmailConsent}
                    handleSwitch={handleSwitchCallback}
                    switchName={"isEmailConsent"}></SwitchButton>
                </Grid>
              </Grid>
            </Box>}
            {!isCell ?
              (<Box mb={3} style={{marginBottom:1}}>
                <Grid container direction="row" justify="space-between" alignItems="center"  >
                  <Grid item xs={9} sm={9} md={11}> <Typography className={classes.onOffText}>
                    <BlueOnWhiteTooltip arrow title={PHONE_TOOLTIP}><span style={{ position: 'relative', top: '7px', left: '-8px', cursor: 'pointer' }}><img src={phone} alt="check icon" style={{ width: '20px', height: '23px', }} /></span></BlueOnWhiteTooltip>Receive phone calls on {props.mobileNo ? maskMobile(props.mobileNo) : ''}</Typography></Grid>
                  <Grid item xs={3} sm={3} md={1} className={classes.textRight}>
                    <SwitchButton
                      status={isCellConsent}
                      handleSwitch={handleSwitchCallback}
                      switchName={"isCellConsent"}></SwitchButton>
                  </Grid>
                </Grid>
              </Box>) : null}
            {!isSms ?
              (<Box mb={3} style={{marginBottom:1}}>
                <Grid container direction="row" justify="space-between" alignItems="center" >
                  <Grid item xs={9} sm={9} md={11}> <Typography className={classes.onOffText}>
                    <BlueOnWhiteTooltip arrow title={MESSAGE_TOOLTIP}><span style={{ position: 'relative', top: '7px', left: '-8px', cursor: 'pointer' }}><img src={sms} alt="check icon" style={{ width: '20px', height: '23px', }} /></span></BlueOnWhiteTooltip>
                    Receive text messages on  {props.mobileNo ? maskMobile(props.mobileNo) : ''}</Typography></Grid>
                  <Grid item xs={3} sm={3} md={1} className={classes.textRight}>
                    <SwitchButton
                      status={IsSmsConsent}
                      handleSwitch={handleSwitchCallback}
                      switchName={"IsSmsConsent"}></SwitchButton>
                  </Grid>
                </Grid>
              </Box>) : null}
              {!isStatementRcv && <Box mb={3} style={{marginBottom:1}}>
                    <Grid container direction="row" justify="space-between" alignItems="center"  >
                      <Grid item xs={9} sm={9} md={11}> <Typography className={classes.onOffText}>
                      <LightToolTip arrow title={PAPERLESS_TOOLTIP}><span style={{ position: 'relative', top: '11px', left: '-8px' , paddingRight: '2px', cursor: 'pointer'}}><img src={paperless} alt="check icon" style={{ width: '20px', height: '23px', }}/></span></LightToolTip>Go paperless and receive statements electronically</Typography></Grid>
                      <Grid item xs={3} sm={3} md={1} className={classes.textRight}>
                        <SwitchButton
                          status={statementRecieve}
                          handleSwitch={handleSwitchCallback}
                          switchName={"statementRecieve"}></SwitchButton>
                      </Grid>
                    </Grid>
                  </Box>}

          </Box>
        </Box>
        {errorMessage !== '' ? (<Typography className={classes.errorMsg}>{errorMessage}</Typography>) : null}

        <Box className={classes.cardFooter}>

          <Box className={`${classes.footerItem} ${classes.skip}`}>
            <Button
              variant='outlined'
              className={classes.btn}
              onClick={() => handleClosePopup()}
            >SKIP & CONTINUE</Button>
          </Box>
          <Box className={classes.footerItem}>
            {cpLoading ? (<Button className={classes.saveBtn} disabled={true} variant="contained"><CircularProgress /></Button>) : (
              <Button
                variant='contained'
                color='primary'
                className={classes.btn}
                disabled={Result.isEmailConsent === isEmailConsent && Result.isCellConsent === isCellConsent && Result.isSmsConsent === IsSmsConsent && Result.isLanveraEStatements === statementRecieve}
                onClick={() => handleSubmit()}
              >UPDATE</Button>)}
          </Box>
        </Box>
      </Box>
    </Dialog >
  )
}
