import React from "react";
import { Box, Button, Dialog, CircularProgress } from "@material-ui/core";
import { useStyles } from './styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const DisasterConfirmAlert = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      PaperProps={{ classes: { root: classes.dialogStyle } }}
      onClose={props.toggleOpen}
      open={props.open}
    >
      <Box className={classes.root}>
        <Box className={classes.body}>
          <h3 className={classes.text}>Please confirm that you understand… <br /><br />
          Forbearance does not mean your payments are forgiven.
          You are still required to eventually fully repay your forbearance, but you won't have to repay it all at once - unless you are able to do so.<br /><br />
          After this initial payment break, we'll reevaluate your situation and if you are still financially impacted by disaster we can (may) extend your forbearance period. <br /><br />
          This application is not submitted until you click “OK”. Alternatively you may click “Cancel”  to go back to the application.
          </h3>

          <Box className={classes.flexBox}>
            <Button className={classes.btns}
              onClick={props.toggleOpen}
              type='submit' size="small" variant="contained" color="primary" >
              Cancel
          </Button>
            {!props.covidConfirmAlertProgress ? <Button className={classes.btns}
              onClick={props.confirmCovidSubmit}
              type='submit' size="small" variant="contained" color="primary" >
              Ok
          </Button> : <Button
                disabled={true}
                className={classes.btns}
                type='submit' size="small" variant="contained" color="primary" >
                <CircularProgress color='primary' style={{ width: 29, height: 29 }} />
              </Button>}
          </Box>

        </Box>
      </Box>
    </Dialog >
  )
}