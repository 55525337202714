import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Box, Button, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed', top: 0, left: 0, right: 0, backgroundColor: '#E10C32',
    width: '100%', zIndex: 2, width: '100%', margin: 0, padding: '13px 16px',
    textAlign: 'center', maxHeight: 56,
    [theme.breakpoints.down('xs')]: {
      padding: '0rem 2.9rem'
    }
  },
  iconStyle: {
    cursor: 'pointer',
    position: 'absolute',
    top: 5,
    right: 10,
    [theme.breakpoints.down('xs')]: {
     right: 20,
     top: 6
    }
  },
  banner: {
    margin: 0,
    fontWeight: 600, 
    padding:4,
    fontSize: 18,
    fontFamily: 'Montserrat',
    fontWeight: 600,
    lineHeight: '22px',
    letterSpacing: '0.18px',
    color: 'rgba(255,255,255,0.87)',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '0.14px',
      padding: 2
    },
  },
  p: {
    margin:0
  }
}));

export const AlertBannerText = (props) => {
  const classes = useStyles();
  const handleClose = () => {
   props.closeAlertBanner()
  }

  return (
    <div className={classes.root}>
      <IconButton className={classes.iconStyle} onClick={handleClose}>
          <CloseIcon style={{color: 'white'}}/>
        </IconButton>
      <p className={classes.banner} dangerouslySetInnerHTML={{ __html: props.text }}></p>
    </div>
  );
}
