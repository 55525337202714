import { makeStyles } from '@material-ui/core/styles';
import signinIcon from '../../assets/signinIcon.svg';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "120em",
    padding: "2.4em 1.5em 9em",

    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      padding: "2.4em 1.5em 5em",
    },
  },
  bg: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#ccc',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${signinIcon})`,
    backgroundPosition: "bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'contain',
  },
  // bg:{
  //   width: '100%',
  //   minHeight: '100vh',
  //   backgroundColor: '#ccc',
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',


  // },
  mainContainer: {
    background: 'rgb(255, 255, 255)',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 14px 7px, rgba(0, 0, 0, 0.2) 0px 1px 3px 0px',
    position: 'relative',
    borderRadius: '12px'
  },
  firsthead: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    letterSpacing: '0.15px',
    lineHeight: '24px',
  },
  todoheading: {
    Color: '#535D6D',
    fontFamily: 'Montserrat',
    fontSize: '24px',
    fontWeight: '600',
    letterSpacing: '0.18px',
    lineHeight: '24px',
  },



  loaderContainer: {
    width: '100%',
    height: '100%',
    minHeight: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  container: {
    width: '100%',
    marginTop: 40,
  },

  link: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    marginTop: 14,
  },

  disabledLink: {
    color: '#4D4D4D',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.5px',
    lineHeight: 'normal',
    textDecoration: 'none',
    cursor: 'pointer',
    fontStyle: 'normal',
    textDecoration: 'none'
  },

  activeLink: {
    color: '#121D22',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.5px',
    lineHeight: 'normal',
    textDecoration: 'none'
  },

  pageHeading: {
    color: '#1A4664',
    fontFamily: 'Montserrat',
    fontSize: '22px',
    fontWeight: '700',
    letterSpacing: '0.18px',
    lineHeight: '24px',
    marginBottom: 25,
    marginTop: 24,
  },

  flexBox: {
    display: 'flex',
    alignItems: 'center'
  },

  subTitle: {
    color: '#363636',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: '600',
    letterSpacing: '0.47px',
    lineHeight: '22px',
    margin: '30px 0 23px 0'
  },

  topFormText: {
    margin: '20px 0'
  },

  bobyText: {
    color: '#535D6D',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: '0.36px',
    lineHeight: '19px',
  },

  rightMenuContainer: {
    display: 'flex',
    textAlign: 'right',
    position: 'absolute',
    top: '20px',
    right: '35px',
    alignItems: 'center'
  },

  formContainer: {
    padding: '23px 30px 0 26px',
    [theme.breakpoints.down('sm')]: {
      padding: '17px 10px 0 10px',
      margin: 0,
    },
    '& .MuiFormLabel-root': {
      color: '#535D6D',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      letterSpacing: '0.36px',
      lineHeight: '19px',
      background: '#fff',
      paddingRight: '5px'
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: props => props.secondaryColorCode,
    }
  },

  secondhead: {
    color: 'rgba(0,0,0,0.74)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    letterSpacing: '0.5px',
    lineHeight: '20px',
    marginTop: '20px',
    marginBottom: '20px',
  },

  // coverarea:{
  //     width:'850px'
  // },

  // border_box:{
  //   width:'100%',
  //   border:' 2px solid #F7B15C',
  //   borderRadius:'12px',
  //   boxSizing:'border-box'
  // },

  divider: {
    opacity: '0.12',
    backgroundColor: '#000000',
    width: '100%',
    height: '1px',
  },

  pdf: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    letterSpacing: '0.5px',
    lineHeight: '20px',
    marginTop: '15px',
  },

  divider_1: {
    opacity: '0.12',
    backgroundColor: '#000000',
    width: '91%',
    height: '1px',
    marginLeft: '30px',
  },

  innerbox: {
    width: '335px',
    margin: '0px auto',
    marginTop: '25px',
    height: '120px',
    ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
      // transform: 'scale(0.7)'
      width: '310px'
    }
  },

  widd: {
    width: '15%',
    margin: '0 auto'
  },
  secondcss: {
    marginTop: '20px',
  },
  reviewloop: {
    width: '80%',
    padding: '50px',
    overflow: 'hidden',
    ['@media (max-width:650px)']: { // eslint-disable-line no-useless-computed-key
      // transform: 'scale(0.7)'
      width: '100%',
      padding: '20px',
    }
  },
  scale: {
    ['@media (max-width:750px)']: { // eslint-disable-line no-useless-computed-key
      // transform: 'scale(0.7)'
    }

  },
  refresh: {
    width: '5%',
    float: 'left',
    // ['@media (max-width:650px)']: { // eslint-disable-line no-useless-computed-key
    //   width: '100%'
    // }
  },
  butarea: {
    marginTop: '40px',
    ['@media (max-width:700px)']: { // eslint-disable-line no-useless-computed-key
      marginTop: '0px',
    }
  },
  refresh_size: {
    width: '50px', color: '#535D6D', height: '50px', textAlign: 'center',
    ['@media (max-width:650px)']: { // eslint-disable-line no-useless-computed-key
      width: '30px',
      height: '30px',
    }
  },
  refresh_1: {
    width: '15%',
    float: 'left',
    // ['@media (max-width:650px)']: { // eslint-disable-line no-useless-computed-key
    //   width: '100%'
    // }
  },
  refresh_img: {
    width: '85%',

    // ['@media (max-width:650px)']: { // eslint-disable-line no-useless-computed-key
    //   width: '100%'
    // }
  },
  downloadd: {

    width: '180px',
    height: '40px',
    color: "#FFF",
    fontFamily: "Montserrat",
    borderRadius: '4px',
    fontSize: '1.6em',
    fontWeight: "600",
    letterSpacing: "0.53px",
    lineHeight: "19px",
    textAlign: "center",
    ['@media (max-width:950px)']: { // eslint-disable-line no-useless-computed-key
      width: '150px',
      fontSize: '1.3em',
    },
    ['@media (max-width:750px)']: { // eslint-disable-line no-useless-computed-key
      width: '120px',
      fontSize: '1em',
    }

  },
  topbottom: {
    marginTop: '18px',
    ['@media (max-width:650px)']: { // eslint-disable-line no-useless-computed-key
      marginTop: '10px',
    }
  },
  pdffile: {
    color: 'rgba(18,18,18,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    letterSpacing: '0.4px',
    lineHeight: '16px'
  },
  adjust: {
    ['@media (max-width:650px)']: { // eslint-disable-line no-useless-computed-key
      width: '77% !important',
      marginLeft: '14px !important'
    },
  },
  dataa: {
    width: '85%',
    float: 'left',
    ['@media (max-width:650px)']: { // eslint-disable-line no-useless-computed-key
      width: '83%',
      marginLeft: '14px !important'
    },
    marginLeft: '25px'
  },
  secincomp: {

    fontFamily: 'Montserrat',
    fontSize: '16px',
    letterSpacing: '0.5px',
    ['@media (max-width:650px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: '13px',
    },
    lineHeight: '20px',
    color: 'rgba(0,0,0,0.38)',
  },
  bankruptcy: {
    fontFamily: 'Montserrat',
    fontSize: '20px',
    letterSpacing: '0.15px',
    lineHeight: '24px',
    color: 'rgba(0,0,0,0.87)',
    marginTop: '10px'
  },
  firstHead: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '24px',
    letterSpacing: '0.18px',
    lineHeight: '24px',
    marginBottom: '10px',
    ['@media (max-width:650px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: '21px'
    },
  },


  submitby: {
    color: 'rgba(0,0,0,0.38)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    letterSpacing: '0.5px',
    lineHeight: '20px',
  },
  datee: {
    color: 'rgba(0,0,0,0.74)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    letterSpacing: '0.5px',
    lineHeight: '20px',
  },
  lossmitigation: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '24px',
    letterSpacing: '0.18px',
    lineHeight: '24px',
  },
  secondtHead: {
    color: 'rgba(0,0,0,0.74)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    letterSpacing: '0.5px',
    lineHeight: '20px',
  },
  pending: {
    width: '100%',
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '24px',
    letterSpacing: '0.18px',
    lineHeight: '24px',
    textAlign: 'center',
    ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: '21px'
    },
  },

  textField: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: '600'
  },

  formText: {
    color: '#535D6D',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: '600',
    letterSpacing: '0.4px',
    lineHeight: '22px'
  },

  cancelAccButton: {
    fontFamily: 'Montserrat',
    letterSpacing: '0.36px',
    fontWeight: '600',
    marginRight: '25px',
    width: '180px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '28px',
      marginRight: 0,
    },
  },
  addAccButton: {
    width: '180px',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    textTransform: 'uppercase'
  },
  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },


  errorMsg: {
    fontSize: '1.4em',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
    marginTop: 5,
    fontFamily: 'Montserrat',
    color: "red",
  },

  validationErr: {
    fontSize: '12px',
    fontWeight: '500',
    top: 3,
    left: 0,
    fontFamily: 'Montserrat',
    color: "red",
  },

  loaderRoot: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  rowMargin: {
    marginBottom: 40,
  },

  infoBox: {
    borderRadius: '6px',
    backgroundColor: '#3064A6',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
    padding: '10px 60px',
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px',
    },
  },


}));