import React from 'react';
import { useStyles } from './styles';
import { Box, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { updateSSN } from '../../actions';
import { decrypt, encrypt } from '../../helpers/encrypt';
import { 
  getPublicIp, getAppSource, getUserType, renderDefaultMsg } from '../../helpers';
import Cookies from 'js-cookie';

export const ConfirmEinSsnPopup = (props) => {

  const { details, handleClose } = props;
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [inProgress, setStatus] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOk = () => {
     history.replace({ pathname: "/Dashboard" });
     window.location.reload(false);
  }   
 
  const getPBorrowerSSN = () => {
      if (details[0]?.borrowerTypeName == 'Primary' && details[0]?.radioButtonStatus == 'true' && details[0]?.ssn != '') {
        return encrypt(details[0].ssn)
      } else if (details[0]?.borrowerTypeName == 'Primary' &&
      details[0]?.radioButtonStatus == '' && details[0]?.ein != '') {
        return encrypt(details[0].ein)
      } else if (details[1]?.borrowerTypeName == 'Primary' && details[1]?.radioButtonStatus == 'true' && details[1]?.ssn != '') {
        return encrypt(details[1].ssn)
      } else if (details[1]?.borrowerTypeName == 'Primary' &&
      details[1]?.radioButtonStatus == '' && details[1]?.ein != '') {
        return encrypt(details[1].ein)
      } else {
        return " "
      }
  }

  const getSBorrowerSSN = () => {
    if (details[0]?.borrowerTypeName == 'Secondary' && details[0]?.radioButtonStatus == 'true' && details[0]?.ssn != '') {
      return encrypt(details[0].ssn)
    } else if (details[0]?.borrowerTypeName == 'Secondary' &&
    details[0]?.radioButtonStatus == '' && details[0]?.ein != '') {
      return encrypt(details[0].ein)
    } else if (details[1]?.borrowerTypeName == 'Secondary' && details[1]?.radioButtonStatus == 'true' && details[1]?.ssn != '') {
      return encrypt(details[1].ssn)
    } else if (details[1]?.borrowerTypeName == 'Secondary' &&
    details[1]?.radioButtonStatus == '' && details[1]?.ein != '') {
      return encrypt(details[1].ein)
    } else {
      return " "
    }
  }
  
  const getPUpdateType = () => {
    if (details[0]?.borrowerTypeName == 'Primary' && details[0]?.radioButtonStatus == 'true' && details[0]?.ssn != '') {
      return 'SSN'
    } else if (details[0]?.borrowerTypeName == 'Primary' &&
    details[0]?.radioButtonStatus == '' && details[0]?.ein != '') {
      return 'EIN'
    } else if (details[1]?.borrowerTypeName == 'Primary' && details[1]?.radioButtonStatus == 'true' && details[1]?.ssn != '') {
      return 'SSN'
    } else if (details[1]?.borrowerTypeName == 'Primary' &&
    details[1]?.radioButtonStatus == '' && details[1]?.ein != '') {
      return 'EIN'
    } else {
      return ""
    }
  }

    
  const getSUpdateType = () => {
    if (details[0]?.borrowerTypeName == 'Secondary' && details[0]?.radioButtonStatus == 'true' && details[0]?.ssn != '') {
      return 'SSN'
    } else if (details[0]?.borrowerTypeName == 'Secondary' &&
    details[0]?.radioButtonStatus == '' && details[0]?.ein != '') {
      return 'EIN'
    } else if (details[1]?.borrowerTypeName == 'Secondary' && details[1]?.radioButtonStatus == 'true' && details[1]?.ssn != '') {
      return 'SSN'
    } else if (details[1]?.borrowerTypeName == 'Secondary' &&
    details[1]?.radioButtonStatus == '' && details[1]?.ein != '') {
      return 'EIN'
    } else {
      return ""
    }
  }

  const handleSubmit =  async () => {
    var modifyData = {
        "accountNumber": details[0].accountNumber,
        "pBorrowerSSN": getPBorrowerSSN(),
        "secBorrowerSSN": getSBorrowerSSN(),
        "pUpdateType":  getPUpdateType(),
        "sUpdateType": getSUpdateType()
      }
   
    const updateData = {
      "accountNumber": modifyData.accountNumber,
      "pBorrowerSSN": modifyData.pBorrowerSSN,
      "secBorrowerSSN": modifyData.secBorrowerSSN,
      "pUpdateType": modifyData.pUpdateType,
      "sUpdateType": modifyData.sUpdateType,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": await getPublicIp(),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }
    
    const res = await updateSSN(updateData);
    if (res) {
      const { lstResponseStatus } = res;
      if (res && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        updateStatus();
      } else {
        setErrorMsg(lstResponseStatus[0].statusDesc)
      }
    }  else {
      setErrorMsg(renderDefaultMsg('400'))
    }
 };

 const updateStatus = () => {
  setStatus(true)
 }

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={handleClose} open={open}> 
      { !inProgress ?
        <Box className={classes.root}>
        <IconButton onClick={handleClose} className={classes.closeIcon}>
          <CancelIcon />
        </IconButton>
     
        <Box className={classes.cardBody}>
      
         <Box className={classes.statusContainer}>
            { details && details.map((data, i) => {
              return (
                <div key={i}>
                   { data.ein != '' || data.ssn != '' ?
                   <>
                  <p className={classes.statusUpdateTinTitle}>
                  You have entered <b>{data.radioButtonStatus ? data.ssn : data.ein}</b>, please confirm that this is the correct {data.radioButtonStatus ? 'SSN' : 'EIN'} for <b>{data.pBorrowerName}</b>.</p>
                  <hr style={{ marginTop: '14px' }}/>
                  </>
                  : null }
                </div>
                )}) 
            }
            <p className={classes.statusUpdateTinSubTitle}> 
              If this is not correct please select edit to re-enter. 
            </p>
          </Box>
          <p className={classes.errorMsg} dangerouslySetInnerHTML={{ __html: errorMsg !== '' ? 
          errorMsg : '' }} />
        </Box>
      
        <Box className={classes.cardFooter}>
         <Box className={classes.footerItem}>
            <Button
              variant='contained'
              color='primary'
              className={classes.btn}
              onClick={handleClose}
            >Edit</Button>
          </Box>
          <Box className={classes.footerItem}>
            <Button
              variant='contained'
              color='primary'
              className={classes.btn}
              onClick={handleSubmit}
            >Update</Button>
          </Box>
        </Box> 
      </Box> :

    <Box className={classes.root}>
    <IconButton onClick={handleOk} className={classes.closeIcon}>
      <CancelIcon />
    </IconButton>

    <Box className={classes.cardBody}>
      <Box className={classes.statusContainer}>
         
          <p className={classes.statusUpdateTinSubTitle}> 
          Thank you for updating your EIN/ SSN, please allow 24-48 hours for the system to update with this information.
          </p>
          
     </Box>
    </Box>
    <Box className={classes.cardFooter}>
        <Box className={classes.footerItem}></Box>
        <Box className={classes.footerItem}>
            <Button
              variant='contained'
              color='primary'
              className={classes.btn}
              onClick={handleOk}
            >Ok</Button>
        </Box>
      </Box> 
    </Box>
    }  
    </Dialog>
  )
}
