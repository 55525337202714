import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => (
  {
    root: {
      width: '100%',
      minHeight: '100vh',
      backgroundColor: '#ccc',
      display: 'flex',
      justifyContent: 'center',
      backgroundPosition: "bottom left",
      backgroundRepeat: "no-repeat",
      backgroundSize: 'contain'
    },
    labelAsterisk: {
        color: "red"
    },
    infoStrip: {
      backgroundColor: '#fff',
      margin: '8px',
      width: '100%',
      margin: '19px 0px 19px 0px',
      border:'1px solid #B4B4B4',
      borderRadius : '10px'
    },
    infoBox: {
      backgroundColor: '#7EB1CC',
      margin: '8px',
      width: '100%',
      margin: '19px 0px 19px 0px',
      border:'1px solid #B4B4B4',
      borderRadius : '10px'
    },
    setUpPayment: {
        fontFamily: "Montserrat",
        fontSize: 16,
        fontWeight: '600',
        width: 250,
        marginTop: 25,
        [theme.breakpoints.down('sm')]: {
          fontSize: 14,
          width: "100%",
          height: "46px"
        },
    },
    successMsg: {
        color: "green",
        fontFamily: "Montserrat",
        fontSize: "18px",
        fontWeight: "600",
        letterSpacing: "0.53px",
        lineHeight: "18px",
        textAlign: 'center',
        paddingTop: 15
      },
    textField: {
        color: "#585757",
        fontFamily: "Montserrat",
        fontSize: " 16px",
        fontWeight: '600'
    },
    finding: {
        fontSize: '16px',
        fontFamily: 'MONTSERRAT',
        fontWeight: '500',
        paddingTop: '25px',
        paddingBottom: '25px',
        color: '#202020'
    },
    transaction: {
        fontSize: '16px',
        fontFamily: 'MONTSERRAT',
        fontWeight: '500',
        paddingBottom: '20px',
        color: '#202020'
    },
    feeScedule: {
        color: '#007FFF',
        cursor: "pointer"
    },
    cancelBtn: {
        width: 192,
        fontFamily: "Montserrat",
        fontSize: '1.6em',
        fontWeight: "600",
        letterSpacing: "0.53px",
        lineHeight: "19px",
        textAlign: "center",
        float: "right",
        border: "2px solid",
        height: '40px',
        boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
        borderColor: theme.palette.primary.main,
        [theme.breakpoints.down('xs')]: {
          width: 'auto',
        },
      },
      saveBtnBox: {
        float: "left",
        [theme.breakpoints.down('sm')]: {
          float: "right",
          paddingRight: 15
        }
      },
      textAreaValidation : {
        color: '#BBBBBB'
      },
      saveBtn: {
        width: 192,
        fontFamily: "Montserrat",
        fontSize: '1.6em',
        marginLeft: 20,
        fontWeight: "600",
        letterSpacing: "0.53px",
        lineHeight: "19px",
        textAlign: "center",
        height: '40px',
        [theme.breakpoints.down('xs')]: {
          width: 'auto',
        },
      },
    inputStyle: {
        color: "#535D6D",
        fontFamily: "Montserrat",
        fontSize: 16,
        fontWeight: "600",
        '& .MuiFormLabel-root': {
            asterisk: {
                color: 'red',
                '&$error': {
                  color: '#db3131'
                },
              }
          },
      },
    
      labelStyle: {
        color: "#535D6D",
        fontFamily: "Montserrat",
        fontSize: 16,
        fontWeight: "400",
      },
    information: {
      color: '#ffffff',
      fontFamily: "Inter",
      fontSize: "16px",
      letterSpacing: "0.36px",
      lineHeight: "19px",
      padding: '10px 0px 5px 20px'
    },
    textArea: {
        color: "#535D6D",
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontWeight: "bold",
        width: '100%',
        padding: "10px",
        height: '56px'
      },
    errorMsg: {
      fontSize: '1.4em',
      fontWeight: 'bold',
      textAlign: 'justify',
      marginBottom: 10,
      fontFamily: 'Inter',
      color: "red",
      marginTop: 30
    },
    formSection: {
     padding: '0px 100px 40px 100px',
     [theme.breakpoints.down('sm')]: {
      padding: '15px 15px'
    },
    },
    footerSection: {
       paddingTop: 35
    },
    activePromise: {
        padding: '60px 40px 0px 100px',
        '& .MuiFormControl-marginNormal': {
           marginTop: '0'
         },
        [theme.breakpoints.down('sm')]: {
         padding: '30px 40px 10px 15px',
        },
    },
    listSection: {
        padding: '0px 100px 30px 100px',
        '& .MuiFormControl-marginNormal': {
           marginTop: '0'
         },
        [theme.breakpoints.down('sm')]: {
         padding: '0px 0px 0px 15px',
        },
    },
    infoSection: {
        padding: '60px 100px 30px 100px',
        '& .MuiFormControl-marginNormal': {
           marginTop: '0'
         },
        [theme.breakpoints.down('sm')]: {
         padding: '30px 40px 10px 15px',
        },
    },
    detailsSection: {
        padding: '20px 0px 20px 0px',
        '& .MuiFormControl-marginNormal': {
            marginTop: '0',
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
          padding: '20px 0px 20px 0px',
        },
    },
    datePicker: {
      paddingLeft: '20px',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '20px',
        paddingLeft: '0px'
      },
    },
    textareaSection: {
        paddingLeft: '20px',
        [theme.breakpoints.down('sm')]: {
          paddingTop: '19px',
          paddingLeft: '3px'
        },
    },
    heading: {
      color: '#555D6C',
      fontFamily: "Montserrat",
      fontSize: "20px",
      lineHeight: "30px",
      fontWeight: '600',
      paddingBottom: 22
    },
    editPromiseHeading: {
        color: '#555D6C',
        fontFamily: "Montserrat",
        fontSize: "20px",
        lineHeight: "30px",
        fontWeight: '600',
    },
    editIcon: {
      paddingTop: 10
    },
    errorMsg: {
      fontSize: '1.4em',
      fontWeight: 'bold',
      textAlign: 'justify',
      marginTop: 10,
      fontFamily: 'Montserrat',
      color: "red",
    },
    modify: {
        color: '#007FFF',
        fontFamily: "Montserrat",
        fontSize: "18px",
        float: "right",
        lineHeight: "30px",
        fontWeight: '600',
        paddingBottom: 22,
        cursor: "pointer",
        [theme.breakpoints.down('sm')]: {
          float: "none",
          fontSize: "19px"
        }
    },
    subHeading: {
       color: '#555D6C',
        fontFamily: "Montserrat",
        fontSize: "20px",
        lineHeight: "30px",
        fontWeight: '600',
        paddingBottom: 20,
        paddingTop: 20
    },
    loader: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }, 
    boxText : {
      padding: '10px 0px 2px 2px',
      fontSize: '16px',
      fontWeight: '500',
      color: '#979797',
      lineHeight: '32px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
      }
    },
    date: {
      color: '#4F4F4F',
      fontFamily: "Inter",
      fontSize: "14px",
      lineHeight: "24px",
      fontWeight: '600'
    },
    paymentHeading : {
      color: '#2B2D30',
      fontFamily: "Montserrat",
      fontSize: "18px",
      lineHeight: "22px",
      fontWeight: '400',  
      paddingBottom: '10px',
      paddingTop: '13px',
      // paddingLeft: '5px'
    },
    paymentSubHeading : {
      color: '#2B2D30',
      fontFamily: "Montserrat",
      fontSize: "20px",
      lineHeight: "22px",
      fontWeight: '600',
      padding: '2px 0px 20px 0px',
    },
    line: {
      border: '1px solid #E3E3E3'
    },
    infoText: {
      color: '#474747',
      fontFamily: "Montserrat",
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: '400',
      padding: '5px 20px 20px 16px'
    },
    
    infoHeading: {
      color: '#626262',
      fontFamily: "Montserrat",
      fontSize: "18px",
      lineHeight: "22px",
      fontWeight: '700',
      padding: '26px 20px 5px 16px'
    },
    container: {
      width: '120em',
      minWidth: "33.3em",
      marginTop: '3.6em',
      marginBottom: '6.6em',
      [theme.breakpoints.down('sm')]: {
        margin: '42px 16px',
      },
    },
    pageHeading: {
      color: '#1A4664',
      fontFamily: 'Montserrat',
      fontSize: '22px',
      fontWeight: '700',
      letterSpacing: '0.18px',
      lineHeight: '24px',
      marginBottom: 25,
      marginTop: 24
    },
    formContainer: {
      minHeight: "300px",
      backgroundColor: '#fff',
      borderRadius: 12,
      boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    linkStyle: {
      color: "inherit",
      textDecoration: "none"
    },
  
    labelText: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "16px",
      letterSpacing: "0.36px",
      lineHeight: "19px",
    },
  
    icon: {
      float: "left",
      marginRight: "30px"
    },
    profileBtn: {
      width: "192px",
      fontSize: "16px",
      fontFamily: "Montserrat",
      fontWeight: "600",
      letterSpacing: "0.53px",
      lineHeight: "19px",
      textAlign: "center",
      height: '40px'
    },
    closeBtn: {
      height: "20px",
      width: "20px",
      backgroundColor: "#ffffff",
      color: "#3064A6",
      borderRadius: "50%",
      display: "inline-block",
      textAlign: "center",
      fontSize: "14px",
      fontWeight: 600,
      cursor: "pointer"
    },

    pl60: {
      paddingLeft: "60px"
    },
    infoIcon: {
      padding: "6px 6px",
      fontSize: '35px',
      color: theme.palette.secondary.contrastText,
    },
  
    pl14: {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '14px'
      },
    },

    pt0: {
      [theme.breakpoints.down('xs')]: {
        paddingTop: '0'
      },
    },

    wordBreak: {
      wordBreak: 'break-all'
    },

    checkboxContainer: {
      marginBottom: "20px",
      [theme.breakpoints.down('xs')]: {
        marginBottom: "135px",
      },
    }

  }
));

export const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: '#25ABC7',
    fontSize: '40px'
  },
  tooltip: {
    color: "#fff",
    backgroundColor: '#25ABC7',
    padding: '20px 15px',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    letterSpacing: '0.55px',
    lineHeight: '21px',
    width: '500px',
    maxWidth: '500px',
    borderRadius: '10px'
  },
}));


export const RedTooltip = withStyles((theme) => ({
  arrow: {
    color: "#B91717",
  },
  tooltip: {
    backgroundColor: '#B91717',
    color: '#fff',
    fontFamily: "Montserrat",
    boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
    padding: "0.5em 1.6em",
    fontSize: 16,
    maxWidth: 600,
  },
}))(Tooltip);
