import React, {Component, useState} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { checkDigitOnly, checkDigitWithDecimal } from '../../../helpers';

const NO_RECORD = "NO Record Found"

const StyledTableCell = withStyles((theme) => ({
  root:{
    padding:"5px 10px"
  },
  head: {
    backgroundColor: "#535D6D",//theme.palette.common.black,
    color: "#ffffff",//theme.palette.common.white,
    fontSize:"16px",
    fontWeight:"bold",
    letterSpacing: "0.36px",
    lineHeight: "19px",
    fontFamily: "Montserrat",
    borderRight: "1px solid #ffffff",
    verticalAlign:"top",
    padding:"10px"
  },
  body: {
    fontSize: 16,
    color:"#202020",    
    letterSpacing: "0.36px",
    lineHeight: "19px",
    fontFamily: "Montserrat"
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    //minWidth: 700,
  },
  inputBox:{
    //height:56,
    width:"100%",
    borderRadius: "4px",
    border: "1px solid #535D6D",
    padding:"10px",
    color:"#535D6D",
    fontSize: "16px",  
    fontWeight:600
  }
});

export const CustomizedTablesLM = (props) =>{
  
  const classes = useStyles();
  const rows = props.data;
  const nullCheck=(str)=>str?str:'' 

  const handleChange=(index)=>(event)=>{
    let newRowsObj = [...rows] 
    let oldObj= {...newRowsObj[index]}
    
    if(event.target.name==="phone"){
      if(checkDigitOnly(event.target.value)===false){         
        newRowsObj[index] = oldObj
        props.onRowChange(newRowsObj)
        return false
      }
    } 
    if(event.target.name==="balance"){
      if(checkDigitWithDecimal(event.target.value)===false){         
        newRowsObj[index] = oldObj
        props.onRowChange(newRowsObj)
        return false
      }
    }
    
    newRowsObj[index][event.target.name] = event.target.value
    props.onRowChange(newRowsObj)
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Lien Holder's Name / Servicer</StyledTableCell>
            <StyledTableCell align="center">Balance</StyledTableCell>
            <StyledTableCell align="center">Phone Number</StyledTableCell>
            <StyledTableCell align="center">Reference Number / Loan Number</StyledTableCell>           
          </TableRow>          
        </TableHead>
        <TableBody>
          
          {rows.length>0?(rows.map((row,i) => (
            <StyledTableRow key={i}>
              <StyledTableCell align="center">
                <input name={"holderName"} 
                value={nullCheck(row.holderName)} 
                className={classes.inputBox} 
                onChange={handleChange(i)} 
                type="text" 
                placeholder="Enter Name" /></StyledTableCell>              
              <StyledTableCell align="center">
                <input name={"balance"} 
                value={nullCheck(row.balance)} 
                className={classes.inputBox} 
                onChange={handleChange(i)}
                type="text" 
                placeholder="Enter Balance" />
                </StyledTableCell>
              <StyledTableCell align="center">
                <input maxLength="10" 
                  name={"phone"}
                  value={nullCheck(row.phone)}
                  className={classes.inputBox} 
                  onChange={handleChange(i)}
                  type="text" 
                  placeholder="Enter Number" /></StyledTableCell>
              <StyledTableCell align="center">
                <input maxLength="16" name={"loanNumber"} 
                value={nullCheck(row.loanNumber)} 
                className={classes.inputBox} 
                onChange={handleChange(i)}
                type="text" 
                placeholder="Enter Number" /></StyledTableCell>                                       
            </StyledTableRow>
          ))):(<StyledTableRow><StyledTableCell colSpan={4} align="center">{NO_RECORD}</StyledTableCell></StyledTableRow>)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}