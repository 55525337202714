import { makeStyles, withStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 12,
    position: 'relative',
    backgroundColor: "#F5F6F7",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    padding: '2.2em 0em',
    [theme.breakpoints.down('xs')]: {
      padding: '1.6em',
    },
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxWidth: '45%',
    maxHeight: "100%",
    backgroundColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%'
    }
  },

  header: {
    width: '100%',
  },

  iconStyle: {
    position: 'absolute',
    top: 10,
    right: 10,
  },

  title: {
    color: "#444444",
    fontFamily: "Inter",
    fontSize: '16px',
    fontWeight: "500",
    marginBottom: 15,
    marginRight: 45,
    padding: '0px 16px'
  },

  subTitle: {
    color: "#fd8e07",
    fontFamily: "Inter",
    fontSize: '20',
    fontWeight: "600",
    marginBottom: 18,
    padding: '0px 25px',
    width: '100%',
    marginTop: 20
  },

  paragraph: {
    width: '100%',
    color: "#403d3d",
    fontFamily: "Inter",
    fontSize: '16px',
    fontWeight: "600",
    marginBottom: 22,
    padding: '0px 50px'
  },

  cardBody: {
    width: '100%',
  },

}));

