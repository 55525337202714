import React from 'react';
import { useStyles } from './styles';
import { Box, Button, Grid, Tooltip } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import Cookies from 'js-cookie';
import { withStyles } from '@material-ui/core/styles';

const LightTooltip = withStyles((theme) => ({
  arrow: {
    color: '#235A86',
    // backgroundColor: '#ffffff',
  },
  tooltip: {
    backgroundColor: '#235A86',
    color: '#fffff',
    fontFamily: "Montserrat",
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    padding: "16px",
    fontSize: 14,
    maxWidth: 350,
    fontWeight: 500,
    borderRadius: 8,
    // border: "1px solid #4D4D4D"
  },
}))(Tooltip);

export const PromiseToPayAlert = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();

  const routeToPromiseToPay = () => {
    Cookies.set("showPromisePopup", false)
    history.push('/PromiseToPay')
  }
 
  const routeToPayment = () => {
    Cookies.set("showPromisePopup", false)
    history.push('/PaymentOption')
  }
  
  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }}  open={props?.promiseToPayAlertStatus}>
      <Box className={classes.root}>
        <Box className={classes.cardBody}>
          <Box className={classes.statusContainer}>
            <p className={classes.statusTitle}>Payment Past Due Alert!</p>
            <p className={classes.paragraph}> Your mortgage payment is now past due. </p>
            <p className={classes.paragraph}> Please select one of the options below: </p>
          </Box>
          <Box className={classes.buttonContainer}>
             <Grid container>
                <Grid item md={12} xs={12} lg={12}>
                    <Grid container style={{ marginTop: '12px' }}>
                      <Grid item xs={6} md={6}>
                      <LightTooltip
                        arrow  placement="top"
                        title={"If you need more time, select this button to set up a promise plan with us."} 
                        >
                        <Button
                              className={classes.payLaterBtn}
                              variant="outlined"
                              onClick={() => routeToPromiseToPay()} 
                          >Pay Later</Button>
                          </LightTooltip>
                        </Grid>
                        <Grid item xs={6} md={6}>
                        <Button type='submit' className={classes.payNowBtn} size="medium" onClick={() => routeToPayment()} variant="contained" color="primary">
                          Pay Now
                        </Button>
                      </Grid>
                    </Grid>
               </Grid>
             </Grid>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}
