import { Box, Button, Typography,Divider  } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CancelIcon from '@material-ui/icons/Cancel';
import tick from '../../../assets/PayoffRequest/tick.svg';
import callIcon from '../../../assets/callIcon.svg';
import applyOnline from '../../../assets/applyOnline.svg';
import InfoIcon from '@material-ui/icons/Info';

import React, { useState } from 'react';
import { useStyles } from './styles';

export const PayoffRequestSurvey = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { popupTexts, displayRefinance } = props;
  const [btnStatus, setStatus] = useState();

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={props.handleCancelIcon} open={props.open}>
      <Box className={classes.root}>
        <IconButton onClick={()=>props.handleSubmitClose('Close')} className={classes.closeIco}>
          <CancelIcon />
        </IconButton>
        <Box className={classes.cardBody2}>
          <Box className={classes.statusContainer}>
            <Box className={classes.cardContainer}>
              {popupTexts.show && <Typography className={classes.heading}><img src={tick} /><span>Your payoff request submitted successfully!</span></Typography>}
              <Typography className={classes.heading2}>{popupTexts.heading}</Typography>
              <Typography className={classes.body}>{popupTexts.title}</Typography>
            </Box>
          </Box>
        </Box>

        <Box className={classes.cardFooter}>
          <Box className={classes.footerItem}>
            <Button
              variant='outlined'
              className={classes.btn}
              onClick={()=>props.handleSubmitClose('Skip')}
            > {'Skip'}</Button>
          </Box>

          <Box className={classes.footerItem}>
            {
              <Button
                variant='contained'
                color='primary'
                className={classes.btn}
                onClick={props.handleSubmit}
              > {'Proceed'}</Button>
            }
          </Box>
        </Box>
        { displayRefinance &&
          <Box className={classes.innerContainer}>
              <p className={classes.paragraph}>If you are looking for a new loan/refinance call us</p>
              <a href={"tel:" + "8773359048"} style={{textDecoration: 'none'}}>
              <p className={classes.call}>
                <img src={callIcon} className={'ico'} />
                877-335-9048 </p> </a>
              <Box className={classes.Divider}>
                <Box style={{ width: '20%' }}><Divider /></Box>
                <Typography className={classes.DividerText}>OR</Typography>
                <Box style={{ width: '20%' }}><Divider /></Box>
              </Box>

              {/* <p className={classes.paragraph}>Click here to</p> */}
              <Box><a href="https://bsifinancialrefinanceonline.mymortgage-online.com/" target="_blank" style={{color:'#ffffff',textDecoration:'none'}}><Button
                variant='contained'
                color='secondary'
                className={classes.btn}
                // onClick={props.handleSubmit}
              > Apply Online
               <img src={applyOnline} style={{marginLeft:'5px'}}/>
              </Button></a></Box>
              <Typography className={classes.btnText}>
                <InfoIcon style={{fill:"#007FFF",height:'16px',width:'16px'}}/>
                This will redirect you to our application portal.</Typography>
          </Box>
        }
      </Box>
    </Dialog>
  )
}
