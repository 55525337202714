import React, { useEffect, useState, useRef} from "react";
import { useDispatch } from 'react-redux';
import { Box, Button, CircularProgress } from "@material-ui/core";
import { useStyles } from './styles';
import clsx from "clsx";
import autoPayIcon from '../../assets/PaymentOptions/repeat.png';
import oneTimePayIcon from '../../assets/PaymentOptions/calendar.png';
import securityIcon from '../../assets/PaymentOptions/shield.png';
import moment from 'moment';
import { renderDraftingOption, maskAccount, numberWithCommas, renderDefaultMsg, maskAccountNo, achSuspensionMessage, isAdmin } from '../../helpers'
import { decrypt } from "../../helpers/encrypt";
import { CancelAutoPayAlert, SnackBar, Accordion } from '../../components';
import { getContent, getPaymentFAQContent } from '../../actions';
import UpcomingPaymentTrns from '../UpcomingPaymentTrns';
import ErrorIcon from '@material-ui/icons/Error';
import { PaymentTransactionHistory } from '../PaymentTransactionHistory';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@material-ui/icons/Print';
const businessMoment = require('moment-business-days');


export const PaymentFaqs = (props) => {

  const printRef = useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [content, setContent] = useState([]);
  const [faqContent, setFaqContent] = useState([]);
  const [faqContentError, setFaqContentError] = useState('');
  const [contentLoading, setContentLoading] = useState(false);

  const toggleOpen = () => {
    setOpen(!open)
  }

  const toggleSnackBar = () => {
    setOpenSnackBar(!openSnackBar)
  }

  const handleCancel = () => {
    const { lstRecurACH } = props.data;
    const { nextDraftDate } = lstRecurACH[0];
    if (businessMoment(nextDraftDate, 'MM-DD-YYYY').businessDiff(businessMoment(moment(), 'MM-DD-YYYY'), true) >= 3) {
      toggleOpen();
    } else {
      setErrMsg("You can not cancel your payment, next scheduled payment is within 3 days.");
      setOpenSnackBar(true)
    }
  }

  useEffect(() => {
    getContentData();
    getFaqContentData();
  }, [])

  const getContentData = async () => {
    const res = await dispatch(getContent('PC015'));
    if (res && res.length !== 0) {
      setContent(res[0])
      props.contentData(res[0])
    }
  }

  const getFaqContentData = async () => {
    setContentLoading(true);
    const res = await getPaymentFAQContent();
    if (res && res.lstResponseStatus[0].statusCode === '0') {
      setContentLoading(false);
      setFaqContent(res.questions);
      setFaqContentError('');
    } else if (res && res.lstResponseStatus[0].statusCode !== '0') {
      setContentLoading(false);
      setFaqContentError(res.lstResponseStatus[0].statusDesc);
    } else {
      setContentLoading(false);
      setFaqContentError(renderDefaultMsg('400'));
    }
  }


  if (props.data && props.loading === false) {
    const { lstRecurACH, isPaymentAllowed, accountNumber, isRecurringAllowed } = props.data;
    if (lstRecurACH && lstRecurACH.length !== 0) {
      var { nextDraftDate, totalAmountDrafted, draftingOption,
        bankAccountType, bankAccountNumber, draftPayment, draftAmount1
      } = lstRecurACH[0];
    }

    return (
      <Box className={classes.root}>
        <Box className={classes.cardContainer}>
          <Box className={clsx(classes.card)}>
            <Box className={classes.cardHeader}>
              <img className={classes.cardImg} src={autoPayIcon} alt='icon' />
              <h3 className={classes.cardTitle}>One-Time Payment</h3>
            </Box>
            <Box className={classes.cardBody}>
              <p className={classes.bodyText}>Make a one-time payment. It's quick and easy.</p>
            </Box>
            <Box className={classes.cardFooter}>
              <Button color='primary' variant='contained' disabled={!isPaymentAllowed || isAdmin()} onClick={() => props.handlePaymentOption('ONETIMEPAYMENT')} className={classes.btn}>Make a payment</Button>
            </Box>
          </Box>
          <Box className={clsx(classes.card)}>
            <Box className={classes.cardHeader}>
              <img className={classes.cardImg} src={oneTimePayIcon} alt='icon' />
              <h3 className={classes.cardTitle}>Manage Autopay</h3>
            </Box>
            {lstRecurACH && lstRecurACH.length !== 0 ?
              <Box className={classes.manageAutoPayCard}>
                <p className={classes.bodyTitleActive}>Upcoming Scheduled Payment</p>
                <Box>
                  <h3 className={classes.bodySubTitleActive}>Total Amount Due ({renderDraftingOption(draftingOption)})</h3>
                  <h3 className={classes.boldText}>$ {numberWithCommas(parseFloat(draftAmount1).toFixed(2))}</h3>
                </Box>
                <Box>
                    <h3 className={classes.bodySubTitleActive}>Bank Account Number </h3>
                    <h3 className={classes.boldText}>{maskAccountNo(decrypt(bankAccountNumber))}</h3>
                  </Box>
                <Box className={classes.flexBox}>
                  <Box>
                    <h3 className={classes.bodySubTitleActive}>Next Draft Date </h3>
                    <h3 className={classes.boldText}>{moment(nextDraftDate).format("ll")}</h3>
                  </Box>
                  {/* { moment(nextDraftDate) > moment().add(3, 'day')} */}
                  {isPaymentAllowed && isRecurringAllowed ?
                    <Button color='primary' variant='contained'
                      onClick={() => props.handlePaymentOption('MANAGEAUTOPAY')}
                      className={classes.btnActive}
                    >Manage Autopay</Button>
                    :
                    <Button color='primary' variant='contained'
                      onClick={() => handleCancel()}
                      className={classes.btnActive}
                    >Cancel AutoPay</Button>
                  }
                </Box>
              </Box>
              :
              <React.Fragment>
                <Box className={classes.cardBody}>
                  <p className={classes.bodyTitle}>Your account is not set up for autopay</p>
                  <p className={clsx(classes.bodyText)}>Autopayment is a great way to ensure that your payments are always made on time. You can also set up to make any additional payments on a regular basis.</p>
                </Box>
                <Box className={classes.cardFooter}>
                  <Button color='primary' variant='contained' disabled={!isPaymentAllowed || isRecurringAllowed === false} onClick={() => props.handlePaymentOption('MANAGEAUTOPAY')} className={classes.btn}>Set up Autopayment</Button>
                </Box>
              </React.Fragment>
            }
          </Box>
        </Box>
        <Box className={classes.schedulePaymentBox} >
          <Box className={classes.upcomingPayflexBox}>
            <h4 className={clsx(classes.headingText, classes.marginFix)}>Scheduled Payments</h4>
            <Box className={classes.printButton} textAlign="right">
              <ReactToPrint
                trigger={() =>
                  <Box className={classes.printIconText}>
                    <h5 className={classes.printForm}>Print</h5>
                    <PrintIcon style={{ width: '24px', height: '24px' }} />
                  </Box>}
                content={() => printRef.current} />
            </Box>
          </Box>
          {props?.data && props?.loading === false && props?.data.isACHSuspended  ?
            <Box><p className={classes.achSuspension}>{achSuspensionMessage(props?.customerCareNo)}</p>
          </Box>
          :
          null
        } 
         <UpcomingPaymentTrns
            selectedAccount={props?.selectedAccount}
            ref={printRef}
            rows={props?.transections}
            deleteUpcomingSchedulePayment={props.deleteUpcomingSchedulePayment}
            redirectToManageAutopay={props.redirectToManageAutopay}
            dataError={props?.dataError}
            loading={props?.loading}
            data={props?.data}
          /> 
        </Box>  
        <Box>
          <PaymentTransactionHistory
            accountNumber={props?.selectedAccount}
          />
        </Box>
        <Box className={classes.securityBox}>
          <Box className={classes.securityBoxHeader}>
            <img className={classes.securityIcon} src={securityIcon} alt='icon' />
          </Box>
          <Box className={classes.securityContent}>
            <h3 className={classes.securityTitle} color="secondary">{content.contentHeader}</h3>
            <p className={classes.text} dangerouslySetInnerHTML={{ __html: content.contentValue }}></p>
          </Box>
        </Box>
        <Box className={classes.askedQuestion}>
          <h1 className={classes.askedQuestionTitle}>Frequently Asked Payment Questions</h1>
          <Box className={classes.askedQuestionContent}>

            {contentLoading === true && <Box className={classes.loaderContainer}>
              <CircularProgress />
            </Box>}

            {faqContent.length && faqContent.map((item) => {
              return (
                <Accordion
                  key={`${item.id}_${item.dispayOrder}`}
                  title={item.question}
                  content={item.answer}
                />
              )
            })}
            {faqContentError !== '' && <p className={classes.errorMsg}>{faqContentError}</p>}

            {/* <Box className={classes.questionBox}>
              <Link className={classes.questionLink} to='/'>Where can i mail my payment ?</Link>
            </Box> */}
          </Box>
        </Box>
        {lstRecurACH && lstRecurACH.length !== 0 &&
          <CancelAutoPayAlert
            toggleOpen={toggleOpen}
            open={open}
            amount={draftAmount1}
            lastPaymentDate={nextDraftDate}
            bankAccount={`${bankAccountType} ${maskAccount(decrypt(bankAccountNumber))}`}
            accountNumber={accountNumber}
            handlePaymentOption={props.updateFaq}
            toggleCurrentSetting={props.toggleCurrentSetting}
          />
        }
        <SnackBar handleClose={toggleSnackBar} open={openSnackBar} severity='error' message={errMsg} />
      </Box>
    )
  }
  return (
    <Box className={classes.loaderContainer}>
      <CircularProgress />
    </Box>
  )
}