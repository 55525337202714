import React from 'react';
import { useStyles } from './styles';
import { Box, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { getAppSource, getUserType } from '../../helpers'
import { insertActivityLog } from '../../actions'

export const PLBanner = (props) => {

  const { bannerBody, bannerTitle,
    buttonColor, buttonText, buttonURL, titleBgColor, bannerImageUrl } = props.banner;

  const { accountNumber } = props

  const handleClick = async () => {
    const data = {
      "accountNumber": accountNumber,
      "activityCode": "UAL040",
      "activityStatus": "Success",
      "action": "GetAccountInfo",
      "controller": "Dashboard",
      "apiName": "GetAccountInfo",
      "activityRemark": "User clicked on Allied Mortgage Group insurance click here button.",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertActivityLog(data);
  }

  const handleImgClick = () => {
    window.open(buttonURL, '_blank')
  }

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {/* <Box className={classes.header} style={{ backgroundColor: titleBgColor }}>
        <h1 className={classes.title}>{bannerTitle}</h1>
      </Box> */}
      <Box className={classes.body} >
        {/* <Box className={classes.bodyTextContainer}> */}
          {/* <div className={classes.bodyText} dangerouslySetInnerHTML={{ __html: bannerBody }}></div> */}
          <img src={bannerImageUrl} className={classes.bodyText} onClick={handleImgClick}></img>
        {/* </Box> */}

      </Box>
      {/* <Box className={classes.footer}>
        {buttonURL && buttonURL.length ? <a href={buttonURL} onClick={handleClick} className={classes.btn} style={{ backgroundColor: buttonColor }} target="_blank">{buttonText}</a> : null}
      </Box> */}
    </Box>
  )
}
