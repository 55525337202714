import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '2.55em',
    [theme.breakpoints.down('md')]: {
      padding: '1.6em',
    },
  },

  loaderContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  cardContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  card: {
    width: '48%',
    height: 'auto',
    border: "2px solid rgba(0,0,0,0.12)",
    borderRadius: 12,
    margin: '0 5px',
    backgroundColor: "#EAECED",
    boxShadow: " 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: '0 0 25px',
    },
  },

  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '17px 53px',
    borderBottom: '1px solid #ddd',
    [theme.breakpoints.down('md')]: {
      padding: '16px',
    },
  },

  cardImg: {
    height: 45,
    width: 45,
    marginRight: 12
  },

  cardTitle: {
    color: "rgba(0,0,0,0.87)",
    letterSpacing: "0.18px",
    fontFamily: "Montserrat",
    fontSize: 24,
    fontWeight: "400",
  },

  cardBody: {
    width: '100%',
    minHeight: 212,
    padding: '20px 53px 5px',
    [theme.breakpoints.down('md')]: {
      padding: '16px',
    },
  },

  bodyText: {
    color: "rgba(0,0,0,0.74)",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "500",
    lineHeight: 1.2,
    letterSpacing: "0.5px",
  },

  bodyTitle: {
    margin: 0,
    marginBottom: 12,
    color: theme.palette.primary.main,
    fontFamily: "Montserrat",
    fontSize: '1.8em',
    fontWeight: 'bold'
  },

  bodyTextMarginFix: {
    marginTop: '13px'
  },

  cardFooter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 53px 30px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 45px 30px',
    },
  },

  btn: {
    fontFamily: "Montserrat",
    fontSize: '1.4em',
  },

  securityBox: {
    width: '100%',
    border: "2px solid rgba(0,0,0,0.12)",
    padding: '25px 53px',
    marginTop: 25,
    borderRadius: 12,
    display: 'flex',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      padding: '25px 16px',
    },
  },
  securityBoxHeader: {
    marginRight: 25,
  },

  securityContent: {
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },

  securityIcon: {
    width: 73,
    height: 89,
    marginRight: 10,
  },

  securityTitle: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2.4em',
    fontWeight: '400',
    marginBottom: '25px',
    paddingBottom: 25,
    borderBottom: "solid 1px rgba(0,0,0,0.12)",
  },

  listContainer: {
    padding: 0
  },

  listStyle: {
    displat: 'block',
    color: "#193559",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    marginBottom: '20px'
  },

  text: {
    color: '#535D6D',
    fontFamily: 'Montserrat',
    fontSize: '1.6em',
    marginBottom: '13px',
    '& p': {
      color: "rgba(0,0,0,0.74)",
      fontFamily: "Montserrat",
      fontSize: '16px',
      letterSpacing: '0.5px',
      maxWidth: '760px',
      wordBreak: "break-word",
    }
  },

  errorMsg: {
    color: 'red',
    fontFamily: 'Montserrat',
    fontSize: '1.6em',
    marginBottom: '13px',
  },

  askedQuestion: {
    marginTop: 25,
    width: '100%',
  },

  askedQuestionTitle: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2.4em',
    fontWeight: '400',
    marginBottom: '25px'
  },

  askedQuestionContent: {
    width: '100%',
    border: '2px solid',
    borderRadius: 12,
    borderColor: theme.palette.primary.main,
    padding: 4,
  },

  questionBox: {
    width: '100%',
    padding: '15px 40px',
    backgroundColor: '#f5f6f7',
    marginBottom: 10,
  },

  questionLink: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    textDecoration: 'none'
  },

  bodyTitleActive: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '2.1em',
    marginTop: '12px',
    marginBottom: '14px',
    fontWeight: "600",
  },

  bodySubTitleActive: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '1.8em',
    marginBottom: '2px',
    fontWeight: "500",
  },

  flexBox: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '12px',
  },

  btnActive: {
    fontFamily: "Montserrat",
    fontSize: '1.4em',
  },

  boldText: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '2.1em',
    marginBottom: '12px',
    fontWeight: "bold",
  },

  manageAutoPayCard: {
    width: '100%',
    minHeight: 212,
    padding: '2px 53px 2px',
    [theme.breakpoints.down('md')]: {
      padding: '16px',
    },
  },

   // schedule payment box
   schedulePaymentBox: {
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 14px 7px, rgba(0, 0, 0, 0.2) 0px 1px 3px 0px',
    width: '100%',
    padding: '3em 2.2em',
    borderRadius: '12px',
    margin: '37px 0',
    backgroundColor: '#fff',
  },
  headingText: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: '400',
    letterSpacing: '0.63px',
    lineHeight: '29px',
    marginBottom: 40,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px'
    }
  },

  disabledLink: {
    color: "#7B8390",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "bold",
    textDecoration: 'none',
  },

  activeLink: {
    color: theme.palette.primary.main,
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    textDecoration: 'none',
  },

  errText: {
    color: "red",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    textAlign: 'center',
    padding: '2em',
  },

  printIconText: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer',
  },

  printForm: {
    fontSize: '14px',
    color: '#535D6D',
    fontFamily: "Montserrat",
    fontWeight: '600',
    textDecoration: 'underline',
  },

  upcomingPayflexBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },

  marginFix: {
    marginBottom: 0
  },

  achSuspension: {
    color: 'red',
    marginBottom: 20
  }



}));

