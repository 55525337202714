import { adminProtected } from '../config/adminConfig';

export const getAdminNotification = async (data) => {
  // {
  //   "privateLabelUrl": "BSI",
  //     "ipAddress": "10.12.0.12",
  //     "browser": "Chrome",
  //     "appSource": "API",
  //     "userType": "React",
  //     "userName": "",
  //    "notificationTitle": ""
  //   }

  try {
    const res = await adminProtected.post('/Admin/GetNotification', data);
    return res.data
  } catch (e) {
    return null
  }
}

export const insertAdminNotification = async (data) => {
  // {
  //   "notificationTitle": "string",
  //   "shortNotificationBody": "string",
  //   "notificationBody": "string",
  //   "isActive": true,
  //   "privateLabelUrl": "string",
  //   "ipAddress": "string",
  //   "browser": "string",
  //   "appSource": "string",
  //   "userType": "string",
  //   "userName": "string"
  // }

  try {
    const res = await adminProtected.post('/Admin/InsertNotification', data);
    return res.data
  } catch (e) {
    return null
  }
}

export const updateAdminNotification = async (data) => {
  // {
  //   "notificationId": 0,
  //   "notificationTitle": "string",
  //   "shortNotificationBody": "string",
  //   "notificationBody": "string",
  //   "isActive": true,
  //   "privateLabelUrl": "string",
  //   "ipAddress": "string",
  //   "browser": "string",
  //   "appSource": "string",
  //   "userType": "string",
  //   "userName": "string"
  // }

  try {
    const res = await adminProtected.post('/Admin/UpdateNotification', data);
    return res.data
  } catch (e) {
    return null
  }
}