import React, { useState } from 'react';
import { Box, Grid, CircularProgress } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import { useStyles } from './styles';
import Cookies from 'js-cookie';
import { getAppSource, getUserType, renderDefaultMsg } from '../../../../helpers';
import { encrypt } from './../../../../helpers/encrypt';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DeleteInvestorCode } from './../../../../actions';

export const DeleteInvestorComponent = (props) => {
  const classes = useStyles();
  const [dataError, setDataError] = useState('');
  const [dataLoader, setDataLoader] = useState(false);

  const handleClose = () => {
    props.closeDialog()
  };

  const deleteInvestorCodeHandler = async () => {
    setDataLoader(true);
    setDataError('');
    const dataa = {
      "investorCode": encrypt(props.investorCode),
      "privateLabelUrl": "BSI",
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
    }
    const response = await DeleteInvestorCode(dataa);
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
      setDataLoader(false);
      handleClose();
      props.handleSnackBarHandler(response.lstResponseStatus[0].statusDesc);
    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
      setDataLoader(false);
      setDataError(response.lstResponseStatus[0].statusDesc);
    } else {
      setDataLoader(false);
      setDataError(renderDefaultMsg('400'));
    }
  }

  return (
    <div>
      <Grid container>
        <Dialog
          open={props.delteinvestorcode}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure want to delete this investor code?
              {dataError !== '' && <Box className={classes.errMessage}>
                {dataError}
              </Box>}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              variant="outlined"
              className={classes.outLinedBtn}>
              Cancel
          </Button>
            <Button
              disabled={dataLoader}
              onClick={deleteInvestorCodeHandler}
              color="primary"
              variant="contained">
              {dataLoader ? <CircularProgress className={classes.disableBtnLoader} /> : 'Delete'}
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </div>
  )
}