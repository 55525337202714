import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  rootBg: {
    width: '100%',
    minHeight: '100vh',
    backgroundPosition: "bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'contain',
  },
  mainHeading: {
    padding: '0.2em 3.5em 0.3em',
    fontWeight: '700',
    fontSize: '2.5em',
    color: '#727272',
    [theme.breakpoints.down('sm')]: {
      padding: '1.5em 1.2em 0 2em',
      fontSize: '2em',
      fontFamily: 'Montserrat',
    },
  },
  root: {
    // display: 'flex',
    // flexWrap: 'wrap',
    fontFamily: 'Montserrat',
    '& > *': {
      margin: theme.spacing(1),
    },
    width: '100%',
    padding: '3em 7em 1em 7em',
    '&:last-child': {
      paddingBottom: '4em'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '1em 2em',
    },
  },
  btn: { 
    padding: "0px 18px",
    cursor: "pointer",
    width: "150px",
    display: "block",
    textAlign: "center",
    fontFamily: 'Montserrat',
    fontWeight: 600,
    marginBottom: 10,
    height: "36px",
    lineHeight: "34px",
    textDecoration: "none",
    color: "#121D22",
    border: "2px solid #EDB36A",
    textTransform: "uppercase",
    fontSize: 16,
    borderRadius: "4px",
    [theme.breakpoints.down('sm')]: {
      fontSize: 8,
      width: "130px"
    },
  },
  applyNow: { 
    padding: "0px 18px",
    cursor: "pointer",
    width: "300px",
    display: "block",
    marginTop: 20,
    textAlign: "center",
    fontFamily: 'Montserrat',
    fontWeight: 600,
    marginBottom: 10,
    background: "#F7B15C",
    height: "36px",
    lineHeight: "35px",
    textDecoration: "none",
    color: "#121D22",
    borderRadius: "4px",
    textTransform: "uppercase",
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      width: "270px"
    },
  },
  info: {
    color: "#4D4D4D",
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: "500",
    fontStyle: 'italic',
    paddingLeft: 5
  },
  infoIcon: {
    width: 24,
    height: 24,
    color: "#F59720",
    [theme.breakpoints.down('sm')]: {
      width: 22,
      height: 22
    },
  },
  cardIcon: {
    width: 18,
    height: 18,
    color: "#007FFF"
  },
  cardContent: {
    display: 'flex',
    marginBottom: 20,
    marginTop: 10
  },
  title: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: 22,
    fontWeight: "700",
    paddingBottom: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      fontWeight: "600"
    },
  },
  icon: {
    margin: '6px 5px -5px 0px',
    fontSize: '30px',
    color: '#121D22',
    height: '20px',
    width: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      height: '14px',
      width: '14px'
    }
  },
  textMe: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600",
    marginLeft: 2,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      fontWeight: "600",
      marginLeft: 2,
      lineHeight: "14px"
    }
  },
  textMeCallMe: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600", 
    marginLeft: 22,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      fontWeight: "600",
      marginLeft: 4,
      lineHeight: "14px"
    }
  },
  logo: {
    height: '62px',
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      height: '60px',
      marginTop: 5
    }
  },
  btnMrg: {
    marginLeft: "24px",
    [theme.breakpoints.down('sm')]: {
      marginLeft: "6px"
    }
  }
}));