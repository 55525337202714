import 'date-fns';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Box, Typography} from '@material-ui/core';
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider, KeyboardDatePicker
} from '@material-ui/pickers';
import Cookies from 'js-cookie';
import { getUpdatedProcessFee } from '../../../actions';
import { getUserType, getAppSource } from '../../../helpers';
import moment from 'moment';
import InfoIcon from '@material-ui/icons/Info';


const useStyles = makeStyles((theme) => ({
  addedAccount: {
    '& .MuiFormLabel-root.Mui-focused': {
      color: "#25abc7",
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: "#25abc7",
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: "#25abc7",
      },
    },
  },
  paymentDayComponent: {
    border: '2px solid #25abc7',
    borderRadius: '30px',
    position: 'relative',
    width: '36px',
    height: '36px',
    lineHeight: '34px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiPickersDay-daySelected': {
      background: '#25abc7',
      color: '#fff',
    },
  },

  greenZoneComponent: {
    '& .MuiPickersDay-daySelected': {
      border: '2px solid #25ABC7',
      background: '#25ABC7',
      color: '#fff',
    },
    '& .MuiPickersDay-hidden': {
      opacity: '0.3',
    }
  },

  redZoneComponent: {
    '& .MuiPickersDay-daySelected': {
      border: '2px solid #EB4F4F',
      background: '#EB4F4F',
      color: '#fff',
    },
    '& .MuiPickersDay-hidden': {
      opacity: '0.3',
    }
  },
  dayWithDot: {
    position: 'absolute',
    height: 0,
    width: 0,
    border: '2px solid',
    borderRadius: 4,
    borderColor: theme.palette.primary.main,
    right: '50%',
    transform: 'translateX(1px)',
    top: '80%'
  },
  imageContainer: {
    display: "flex"
  },
  infoIcon: {
    paddingRight: "10px",
    fontSize: '30px',
    color: "#E02020"
  },
  paragraph: {
    color: "#E02020",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: "500",
    paddingTop: 5
 },
 icon: {
    paddingRight: 8,
    paddingBottom: 2,
    fontSize: 26,
    color: "#007FFF"
  },
  cancellation: {
    color: "#4D4D4D",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: "500",
    paddingTop: 5
  },
  note: {
    color: "#4D4D4D",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: "700",
    paddingTop: 5
  },
}))

export function Calender(props) {
  const classes = useStyles()
  const [processFee, setProcessFee] = useState(props.processingFee);
  const [dueDateGreen, setDueDateGreen] = React.useState(props.dueDate);
  const [selectedDate, setSelectedDate] = React.useState(moment(props?.estDateTime));
  const [processNote, setProcessNote] = useState(props.processNote);
  const [isOpen, setIsOpen] = useState(false);
  const [showErr, setShowErr] = useState((props.error !== '') ? true : false);
 
  useEffect(() => {
    // if (props.processingFee === 0) {
    updateFee(props.selectedAccount);
    // }
    // props.updateProcessFee(processFee, selectedDate, processNote);
  }, [props.selectedAccount]);

  useEffect(() => {
    if ((props.error !== '') && props.paymentDate) {
      setShowErr(true)
      setSelectedDate(props.paymentDate)
      setTimeout(()=>{
        updateProcessFee(props.paymentDate)
      }, 100)
    }
  }, [props.error, props.paymentDate]);
 
  const updateProcessFee = async (date) => {
    const feeVar = await updateFee(props.selectedAccount, date);
    if (feeVar === 'Done') {
      setIsOpen(false)
    }
  };

  useEffect(() => {
    // moment.tz.setDefault("America/New_York");
    if (moment(dueDateGreen).diff(moment()) < 0) {
      setDueDateGreen(moment().add(1, 'day').format('L'))
    }
    // return () => moment.tz.setDefault();
  }, []);

  const updateFee = async (acc, date = selectedDate) => {
  
    const data = {
      "accountNumber": acc,
      "paymentDate": moment(date).format('L'),
      "forbearanceStatus": props.forbearanceStatus,
      "propertyState": props.propertyState,
      "transferStatus": props.loanStatus,
      "investorCode": props.investorCode,
      "interestRate": props.interestRate,
      "isBPL": props.isBPL,
      "delinquentDays": props.delinquentDays,
      "paymentDueDate": props.dueDate,
      "graceDays": props.graceDays,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };
    const response = await getUpdatedProcessFee(data);
    if (response && response.processingFee) {
      const processingFeesWaiveOff = props.lstRecurACH !== null ? true : false;
      props.updateProcessFee(processingFeesWaiveOff ? 0 : response.processingFee.processingFee, moment(date).format('L'),
       processingFeesWaiveOff && response.processingFee.processingFee > 0 ? 'Waiveoff ' + response.processingFee?.processingFeeNote : 
       response.processingFee?.processingFeeNote,
        response.processingFee.delinquentDays, 
      processingFeesWaiveOff ? false : response.processingFee.isDisplay);
      // props.updateProcessFee(response);
      return 'Done'
    }
  }

  const handleDateChange = async (date) => {
    setSelectedDate(date);
    setShowErr(false)
    const feeVar = await updateFee(props.selectedAccount, date);
    if (feeVar === 'Done') {
      setIsOpen(false)
    }
  };

  const renderDayInPicker = (date, selectedDate, dayInCurrentMonth, dayComponent) => {
    if (moment(dueDateGreen).diff(moment(moment(date).format('L'))) === 0) {
      return (<div className={`${classes.paymentDayComponent}`}>
        {dayComponent}
      </div>)
    }
    if (moment(props.latePaymentDate).diff(moment(date)) > 0) {
      return (<div className={`${classes.greenZoneComponent}`}>
        {dayComponent}
      </div>)
    }
    if (moment(props.latePaymentDate).diff(moment(date)) < 0) {
      return (<div className={`${classes.redZoneComponent}`}>
        {dayComponent}
      </div>)
    }
    return dayComponent
  }
  
  return (
    <>
    <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
      <Grid container justifyContent="space-around">
        <KeyboardDatePicker
          disableToolbar
          error={showErr}
          minDate={moment(props?.estDateTime)}
          maxDate={moment(props?.estDateTime).add(10, 'day')}
          variant="inline"
          inputVariant="outlined"
          format="MM/DD/YYYY"
          margin="normal"
          id="date-picker-inline"
          label="Payment Date (Eastern Time)"
          value={selectedDate}
          onChange={handleDateChange}
          renderDay={renderDayInPicker}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}

          KeyboardButtonProps={{
            onFocus: e => {
              setIsOpen(true);
            }
          }}
          PopoverProps={{
            disableRestoreFocus: true,
            onClose: () => {
              setIsOpen(false);
            }
          }}
          InputProps={{
            onFocus: () => {
              setIsOpen(true);
            }
          }}
          InputLabelProps={{
            shrink: true,
          }}
          open={isOpen}

        />

      </Grid>
    </MuiPickersUtilsProvider>
    { showErr &&
      <Box className={classes.imageContainer}>
        <InfoIcon className={classes.infoIcon} />
        <Typography className={classes.paragraph}>Duplicate draft found for the selected date. Try selecting a new date to continue.</Typography>
      </Box>
    }
     {  (moment(props?.estDateTime).format('YYYY-MM-DD') == moment(selectedDate).format('YYYY-MM-DD')) &&
      <Box className={classes.imageContainer}>
        <InfoIcon className={classes.icon} />
        <Typography><span className={classes.note}>Note:</span> <span className={classes.cancellation}>Cancellations may not be possible for payments drafted on the same day.</span></Typography>
      </Box>
    }
    </>
  );
}

