import React, { Fragment, useEffect, useState } from 'react';
import { Box, TextField, Typography, Checkbox, FormControlLabel, Button } from '@material-ui/core';
import { NextPrevButton } from '../NextPrevButton'
import { useStyles } from './styles';
import { SaveAndExitPopup } from '../SaveAndExitPopup';
import { useHistory } from 'react-router-dom';
import { ConfirmPopup } from '../ConfirmPopup';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';


export const SectionI = (props) => {
const {parentStyle} = props
  const classes = useStyles();
  const history = useHistory();

  const nullCheck = (str) => str ? str : ''
  const [isConfirmPopup, setIsConfirmPopup] = useState(false)

  const [sectionI, setSectionI] = useState({
    borrowerName: "",
    bDate: "",
    isAgree: ""
  })

  const { borrowerName, bDate, isAgree } = sectionI

  useEffect(() => {
    if (props.data) {
      const { borrowerName, bDate, isAgree } = props.data
      setSectionI({
        borrowerName: nullCheck(borrowerName),
        bDate: nullCheck(bDate),
        isAgree: checkBoxStatus(isAgree)
      })
    }
  }, [props.data])

  const handleChange = (event) => {
    if (event.target.name === "isAgree") {
      setSectionI({ ...sectionI, [event.target.name]: event.target.checked });
    } else {
      setSectionI({ ...sectionI, [event.target.name]: event.target.value });
    }

  };
  const handleDateChange = (inputName) => (date) => {
    setSectionI({ ...sectionI, [inputName]: date });
  }

  const handleSave = (e) => {
    let formErrors = formValidationSectionI()
    let formErrorLength = Object.keys(formErrors).length
    let sectionCompleteStatus = formErrorLength > 0 ? "incomplete" : "complete"
    props.status({ id: 8, sectionName: "section_I", status: sectionCompleteStatus })

    const data = {
      "section_I": {
        borrowerName,
        bDate,
        isAgree,
        "sectionStatus": {
          "status": sectionCompleteStatus,
          "sectionName": "Section I:Acknowledgment And Agreement"
        },
      }
    }
    console.log("=====sectionI====", data)
    props.saveFromParent(data)
  }

  const saveAndExitAction = (action) => {
    if (action === "leave") {
      handleSave()
      history.push('/Dashboard')
    }
  }
  const checkBoxStatus = (status) => {
    if (status === "true" || status === true) {
      return true
    } else {
      return false
    }
  }
  /*Error handler*/
  const [formErrorMap, setFormErrorMap] = useState([])
  const isEmpty = (str) => str ? false : true

  const formValidationSectionI = () => {
    const formErrorMessage = {}

    if (isEmpty(isAgree)) {
      formErrorMessage["isAgree"] = "This is required field"
    } else if (checkBoxStatus(isAgree) === true) {
      if (isEmpty(borrowerName)) {
        formErrorMessage["borrowerName"] = "This is required field"
      }
      if (isEmpty(bDate)) {
        formErrorMessage["bDate"] = "This is required field"
      }
    }

    console.log("===form error I===", formErrorMessage)
    return formErrorMessage;
  }

  const errorHandler = (field) => {
    if (formErrorMap[field]) {
      let message = formErrorMap[field]==="invalid"?"Invalid Data, Please check the value" :"*"
      return message
    } else {
      return false
    }
  }
  /*Error handler end*/
  //const [isSubmit, setIsSubmit] = useState(false)
  const [inCompleteSections, setInCompleteSections]=useState([])
  // const inComplectSections = () => {
  //   let allSS = props.allSectionStatus.slice(0, 7)
  //   let incomplectArray = allSS.filter(item => item.status !== 'complete')
  //   console.log("in====", incomplectArray)
  //   // if (incomplectArray.length === 0 && isAgree === true && borrowerName !== '' && bDate !== '') {
  //   //   setIsSubmit(true)
  //   // }
  //   return incomplectArray
  // }
  useEffect(()=>{    
    let allSS = props.allSectionStatus.slice(0, 7)
    let incomplectArray = allSS.filter(item => item.status !== 'complete')
    setInCompleteSections(incomplectArray)
  },[props.allSectionStatus])

  const isSubmit = inCompleteSections.length===0 && isAgree === true && borrowerName !== '' && bDate !== ''

  return (
    <Box className={classes.root}>
      <Box style={{ marginTop: "-85px" }}>
        <SaveAndExitPopup actionCallBack={saveAndExitAction}></SaveAndExitPopup>
      </Box>
      <Box height={"90%"} style={{ paddingTop: "25px" }}>
        <Typography className={classes.title}>Acknowledgment And Agreement</Typography>
        <Box py={1}>
          <Typography className={`${classes.acknowledgementText} ${classes.bold}`} align="center">In making this request for consideration, I certify under penalty of perjury:</Typography>
        </Box>

        <Box py={1}>
          <Typography className={classes.acknowledgementText}>I understand the Servicer may pull a current credit report on all borrowers obligated on the Note.</Typography>
        </Box>
        <Box py={1}>
          <Typography className={classes.acknowledgementText}>I am willing to commit to credit counselling if it is determined that my financial hardship is related to excessive debt.</Typography>
        </Box>

        <Box py={1}>
          <Typography className={classes.acknowledgementText}>If I was discharged in a Chapter 7 bankruptcy proceeding subsequent to the execution of the Loan Documents, or am currently entitled to the
          protections of any automatic stay in bankruptcy, I acknowledge that the Servicer is providing the information about the mortgage relief program
at my request and for informational purposes, and not as an attempt to impose personal liability for the debt evidenced by the Note.</Typography>
        </Box>
        <Box py={1}>
          <Typography className={classes.acknowledgementText}>I understand that if the Servicer offers me a Trial Period Plan and I fail to accept or complete the trial plan for any reason, including, for example, declining
          the trial plan offer, failing to accept the trial plan offer, failing to make trial plan payments in a timely manner or failing to accept a final modification at
the end of the trial period, I may permanently lose eligibility for any modification program offered by the Servicer.</Typography>
        </Box>
        <Box py={1}>
          <Typography className={classes.acknowledgementText}>If I am eligible for a Trial Period Plan, Repayment Plan or Forbearance Plan, and I accept and agree to all terms of such plan, I also agree that the terms
          of this Acknowledgment and Agreement are incorporated into such plan by reference as if set forth in such plan in full. My first timely payment following
          my Servicer’s determination and notification of my eligibility or prequalification for a Trial Period Plan, Repayment Plan or Forbearance Plan (when
          applicable) will serve as acceptance of the terms set forth in the notice sent to me that sets forth the terms and conditions of the Trial Period Plan,
Repayment Plan or Forbearance Plan.</Typography>
        </Box>
        <Box py={1}>
          <Typography className={classes.acknowledgementText}>I agree that when the Servicer accepts and posts a payment during the term of any Repayment Plan, Trial Period Plan or Forbearance Plan it will be
          without prejudice to, and will not be deemed a waiver of, the acceleration of my loan or foreclosure action and related activities and shall not
constitute a cure of my default under my loan unless such payments are sufficient to completely cure my entire default under my loan.</Typography>
        </Box>
        <Box py={1}>
          <Typography className={classes.acknowledgementText}>I agree that any prior waiver as to my payment of escrow items to the Servicer in connection with my loan has been revoked.</Typography>
        </Box>
        <Box py={1}>
          <Typography className={classes.acknowledgementText}>If I qualify for and enter into a Repayment Plan, Forbearance Plan or Trial Period Plan, I agree to the establishment of an escrow account and the payment
of escrow items if an escrow account never existed on my loan.</Typography>
        </Box>
        <Box py={1}>
          <Typography className={classes.acknowledgementText}>I consent to being contacted concerning this request for mortgage assistance at any email address or cellular or mobile telephone number I have provided
to the Lender. This includes text messages and telephone calls to my cellular or mobile telephone.</Typography>
        </Box>
        <Box py={1}>
          <Typography className={classes.acknowledgementText}>That all of the information in this document is truthful and the hardship(s) identified on page 2 is/are the reason that I need to request a modification of the
terms of my mortgage loan, a short sale or a deed-in-lieu of foreclosure.</Typography>
        </Box>
        <Box py={1}>
          <Typography className={classes.acknowledgementText}>I understand that the Servicer, the U.S. Department of the Treasury, owner or guarantor of my mortgage or their agents may investigate the accuracy of
          my statements and may require me to provide additional supporting documentation. I also understand that knowingly submitting false information may
violate federal and other applicable laws.</Typography>
        </Box>
        <Box py={1}>
          <Typography className={classes.acknowledgementText}>I understand that if I have intentionally defaulted on my existing mortgage, engaged in fraud or if it is determined that any of my statements or any
          information contained in the documentation that I provide are materially false and that I was ineligible for assistance , and also mayseek other remedies
available at law and in equity, such as recouping any benefits or incentives previously received.</Typography>
        </Box>
        <Box py={1}>
          <Typography className={classes.acknowledgementText}>The property that I am requesting mortgage assistance for is able to be lived in, and it has not been or is not at risk of being condemned. There has been
no change in the ownership of the property since I signed the documents for the mortgage that I want to modify.</Typography>
        </Box>
        <Box py={1}>
          <Typography className={classes.acknowledgementText}>I am willing to provide all requested documents and to respond to all Servicer questions in a timely manner. I understand that time is of the essence.</Typography>
        </Box>
        <Box py={1}>
          <Typography className={classes.acknowledgementText}>I understand that the Servicer will use the information in this document to evaluate my eligibility for a loan modification or short sale or deed-in-lieu of
          foreclosure, but the Servicer is not obligated to offer me assistance based solely on the statements in this document or other documentation submitted
in connection with this request.</Typography>
        </Box>
        <Box py={1}>
          <Typography className={classes.acknowledgementText}>I understand that the Servicer will collect and record personal information, including, but not limited to, my name, address, telephone number, Social
          Security number, credit score, income, payment history, government monitoring information and information about account balances and activity. I
          understand and consent to the disclosure of my personal information and the terms of any mortgage relief or foreclosure alternative that I receive by
          the Servicerto (a) the U.S. Department of the Treasury; (b) Fannie Maeand Freddie Mac in connection with their responsibilities under the Homeowner
          Affordability and Stability Plan; (c) any investor, insurer, guarantor or Servicer that owns, insures, guarantees or services my first lien or subordinate
          lien (if applicable) mortgage loan(s); (d) companies that perform support services in conjunction with any other mortgage relief program; and (e) any
HUD-certified housing counselor.</Typography>
        </Box>
        <Box py={1}>
          <Typography className={classes.acknowledgementText}>I understand that as part of a review for mortgage assistance, the Servicer may order an appraisal or valuation to determine my property’s value and charge
          me for this appraisal or valuation. I understand that the Servicer must provide me with a copy. I understand that I can pay for an additional appraisal for
my own use at my own cost</Typography>
        </Box>
        <Box py={1}>
          <Typography className={classes.acknowledgementText}>If I or someone on my behalf has submitted a Fair Debt Collection Practices Act Cease and Desist notice to my Servicer, I hereby withdraw such notice
and understand that the Servicer must contact me through the loan modification process or to find other alternatives to foreclosure.</Typography>
        </Box>
        <Box className={classes.inputBox}>
          <FormControlLabel
            control={<Checkbox checked={checkBoxStatus(isAgree)} onChange={handleChange} name="isAgree" color="primary" />}
            label="I agree term and conditions"
            className={classes.labelStyle} />
        </Box>
        {checkBoxStatus(isAgree) && <Box className={classes.flexBox}>
          <TextField
            size="small"
            error={errorHandler('borrowerName') !== false}
            helperText={errorHandler('borrowerName')}
            onChange={handleChange}
            value={borrowerName}
            name="borrowerName"
            label="Borrower Name"
            color='secondary'
            variant="outlined"
            className={classes.inputField}
            InputProps={{
              classes: { root: classes.inputText }
            }}
          />
          {/* <TextField
              size="small"
              onChange={handleChange}
              value={bDate}
              name="bDate"
              label="Date"
              color='secondary'
              variant="outlined"
              className={classes.inputField}
              InputProps={{
                classes: { root: classes.inputText }
              }}
            /> */}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker            
              views={['year', 'month', 'date']}
              //disabled={isRestrict}
              size="small"
              error={errorHandler('bDate') !== false}
              helperText={errorHandler('bDate')}
              color='secondary' autoOk={true}
              //disableToolbar
              //minDate={new Date()}
              variant="inline" inputVariant="outlined" format="MM/dd/yyyy" fullWidth

              id="id-bDate"
              label="Date"
              value={bDate}
              name="bDate"
              onChange={handleDateChange("bDate")}

              KeyboardButtonProps={{ 'aria-label': 'change date', }}
              placeholder={"MM/dd/yyyy"}
              className={parentStyle.inputField}
              InputProps={{ classes: { root: parentStyle.inputText } }}
              InputLabelProps={{ shrink: true, }}
            />
          </MuiPickersUtilsProvider>
        </Box>}
      </Box>
      <Box>
        <NextPrevButton
          handleNextCallback={handleSave}
          handlePrevCallBack={props.previous}
          activeStepIndex={props.activeSection}></NextPrevButton>
      </Box>
      <Box p={5} textAlign="center">
        {/* //allSectionStatus */}
        <Box mb={1}>
        {inCompleteSections.length>0 && isSubmit === false && (<Box className="errorMessage">
          <Typography>Please Complect All Scetion to Submit Loss Mitigation Application</Typography>
          ( { inCompleteSections.map(itemName => (<span>{itemName.label}, </span>))})
        </Box>)}
        </Box>

        {isSubmit ?
          (<Button
            color="primary"
            //onClick={() => handleSave()}
            onClick={() => setIsConfirmPopup(true)}
            className={classes.cancelBtn}
            variant="contained">Submit Application</Button>) :
          (<Button disabled={true} variant="contained">Submit Application</Button>)
        }
      </Box>

      <ConfirmPopup
        open={isConfirmPopup}
        handleClose={() => setIsConfirmPopup(false)}
        actionCallBack={() => handleSave()}></ConfirmPopup>
    </Box>
  )
}