import React, { useEffect, useState } from 'react';
import { Box, Breadcrumbs, CircularProgress } from "@material-ui/core";
import { useStyles } from './styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getContent } from '../../actions';
import { scrollTop } from '../../helpers';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './style.css'

export const FinancialAdditionalform = () => {

  const classes = useStyles();
  const [content, setContent] = useState([])
  const history = useHistory();
  const dispatch = useDispatch()
  const selectedAccount = useSelector(state => state.selectedAccount);
  const getContentData = async () => {
    const res = await dispatch(getContent('PC027'));
    if (res) {
      setContent(res)
    }
  };

  const handleChange = async (e) => {
    let { from } = { from: { pathname: "/FinancialHardship" } };
    history.replace(from);
  }

  useEffect(() => {
    if (selectedAccount) {
      getContentData();
      scrollTop()
    }
  }, [selectedAccount]);

  return (
    <Box className={classes.bg}>
      <Box className={classes.root}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" style={{color: '#2B2D30'}}/>} aria-label="breadcrumb">
          <Link color="inherit" to='/PaymentOption' className={classes.disabledLink}>
            Payments
        </Link>
          <Link color="inherit" to='/FinancialHardship' className={classes.activeLink}>
            Financial Hardship
        </Link>
          <Link color="inherit" to='/additionalform' className={classes.activeLink}>
            Additional Applications
        </Link>
        </Breadcrumbs>

        <Box className={classes.topContainer} mx={0}>
          <Typography className={classes.pageHeading} color="textPrimary" component='h4'>Additional Applications</Typography>
        </Box>
        <Box className={classes.mainContainer}>
          <Box my={1} mx={2} className={classes.formContainer}>
            {content.length !== 0 ?
              < Box dangerouslySetInnerHTML={{ __html: content[0].contentValue }} />
              :
              <Box className={classes.loaderContainer}>
                <CircularProgress />
              </Box>
            }
          </Box>
        </Box>
      </Box>
    </Box>
  )
}