import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => (
  {
    root: {
      width: '100%',
      minHeight: '100vh',
      backgroundColor: '#ccc',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',     
      backgroundPosition: "bottom left",
      backgroundRepeat: "no-repeat",
      backgroundSize: 'contain',
    },

    container: {
      width: '90%',
      minWidth: "33.3em",
      marginBottom: '9em',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '5em',
      },
    },

    formContainer: {
      minHeight: "300px",
      backgroundColor: '#fff',
      borderRadius: 12,
      boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    instructionsContainer: {
      padding: "2.3em 7.9em 0 7.9em",
      [theme.breakpoints.down('sm')]: {
        padding: "1.6em 1.6em 0 1.6em"
      },
    },
    instText: {
      paddingLeft: 0,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: "0"
      },
    },


    headingText: {
      color: "#535D6D",
      fontFamily: "Montserrat",
      fontSize: "24px",
      fontWeight: "600",
      letterSpacing: "0.18px",
      lineHeight: "24px",
      marginTop: '3px'
    },


    text: {
      color: "rgba(0,0,0,0.74)",
      fontFamily: "Montserrat",
      fontSize: "16px",
      letterSpacing: "0.5px",
      lineHeight: "20px",
    },

    formInputContainer: {
    },
    inputContainer: {
      marginBottom: '3.9em',
      width: "100%",
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        width: "100%"
      },
    },

    btn: {
      color: theme.palette.primary.contrastText,
      width: "200px",
      height: "40px",
      fontSize: "16px",
      fontFamily: "Montserrat",
      fontWeight: "600",
      letterSpacing: "0.53px",
      lineHeight: "19px",
      textAlign: "center",
    },
    smallText: {
      color: "rgba(0,0,0,0.87)",
      fontFamily: "Montserrat",
      fontSize: "14px",
      letterSpacing: "0.25px",
      lineHeight: "16px",
      paddingTop: '6px',
      paddingLeft: 0
    },

    primaryColor: {
      backgroundColor: `${theme.primary}!important`,
    },

    textField: {
      color: 'rgba(0,0,0,0.87)',
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "600",
      letterSpacing: '0.15px',
      lineHeight: '20px',
    },

    textAreaField: {
      color: 'rgba(0,0,0,0.87)',
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: "600",
      letterSpacing: '0.15px',
      lineHeight: '20px',
    },

    activeRadioLabel: {
      fontWeight: "500",
    },

    radioLabel: {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: "500",
      cursor: 'pointer',
      color: 'rgba(0,0,0,0.87)',
      letterSpacing: '0.25px',
      lineHeight: '16px',
      '& .MuiRadio-colorSecondary.Mui-checked': {
        color: "#535D6D",
        fontWeight: "500",
      }
    },

    inputStyle: {
      padding: '12.5px 13px' 
    },

    text_part: {
      color: 'rgba(0,0,0,0.74)',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      letterSpacing: '0.5px',
      lineHeight: '20px',
     
    },


    marBtmFixed: {
      marginBottom: 0,
      '& .MuiFormHelperText-root.Mui-error': {
        display: 'none',
      },
      '& .MuiFormLabel-root.Mui-error': {
        color: 'inherit'
      },
      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: 'inherit',
      }
    },
 
    marFixed: {
      marginBottom: 0,
      marginLeft: 40,
      '& .MuiFormHelperText-root.Mui-error': {
        display: 'none',
      },
      '& .MuiFormLabel-root.Mui-error': {
        color: 'inherit'
      },
      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: 'inherit',
      }
    },

    inputTimeStyle: {
      padding: '12px',
      color: "rgba(0, 0, 0, 0.74)",
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: "500",
    },

    privateLabel: {
      marginLeft: 10,
      width: '26%'
    },

    time: {
      color: "rgba(0, 0, 0, 0.87)",
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: "600",
    },

    timeFormControl: {
      marginLeft: 10,
      width: '44% !important',
     '& .MuiFormLabel-root': {
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      }
    },

  }
));