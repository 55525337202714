var CryptoJS = require("crypto-js");

export const encrypt = (msg) => {

  const key = CryptoJS.enc.Utf8.parse('8082345678808080');
  const iv = CryptoJS.enc.Utf8.parse('8080808080808080');

  const encrypted = CryptoJS.AES.encrypt(msg, key,
    {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
  return encrypted.toString()
}



export const decrypt = (msg) => {
  const key = CryptoJS.enc.Utf8.parse('8082345678808080');
  const iv = CryptoJS.enc.Utf8.parse('8080808080808080');
  const decrypted = CryptoJS.AES.decrypt(msg.toString(), key, {
    keySize: 128 / 8,
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC
  })
  const decryptedFinal = decrypted.toString(CryptoJS.enc.Utf8);
  return decryptedFinal
}