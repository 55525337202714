import React, { useEffect, useState } from 'react';
import { Box, Breadcrumbs, CircularProgress } from "@material-ui/core";
import { useStyles } from './styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';
import { decrypt } from '../../helpers/encrypt'
import { getContent, getContact, insertActivityLog } from '../../actions';
import Cookies from 'js-cookie';
import { scrollTop, getUserType, getAppSource, getPointPrivateLabel } from '../../helpers';
import './style.css'
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import  CustomBreadcrum from '../Breadcrum';

export const Contactus = () => {

  const history = useHistory();
  const location = useLocation();
  const privateToken = Cookies.get("privateToken");
  const classes = useStyles();
  const [content, setContent] = useState([])
  const [profile, setProfile] = useState(null)
  const dispatch = useDispatch()
  const selectedAccount = useSelector(state => state.selectedAccount);

  const getContentData = async () => {
    setContent([]);
    const res = await dispatch(getContent('PC024'));
    if (res) {
      setContent(res);
      scrollTop();
    }
  };

  const getProfile = async () => {
    const data = {
      "accountNumber": selectedAccount,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };
    const ress = await getContact(data);
    setProfile(ress)
  };
 
  useEffect(() => {
    if ((location.search.split('?escrowactivity=')[1] !== undefined) && selectedAccount) {
      const queryParams = new URLSearchParams(location.search)
      sendActivityData()
      if (queryParams.has('escrowactivity')) {
        queryParams.delete('escrowactivity')
        history.replace({
          search: queryParams.toString(),
        })
      }
    }
  }, [(location.search.split('?escrowactivity=')[1] !== undefined) && selectedAccount])

  const sendActivityData = async () => {
    const data = {
      "accountNumber": selectedAccount,
      "activityCode": "UAL193",
      "activityStatus": "Success",
      "action": 'ContactUsLink',
      "controller": 'EscrowNotification',
      "apiName": "EscrowNotification/AdhocEscrowTelemetry",
      "activityRemark": 'Borrower clicked on contact us link',
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertActivityLog(data);
  }

  useEffect(() => {
    getContentData();
    { privateToken != undefined && getProfile() }
    scrollTop()
  }, []);

  useEffect(() => {
    if (selectedAccount) {
      getContentData();
      getProfile();
      scrollTop()
    }
  }, [selectedAccount]);

  var Name = '';
  var Email = '';
  var Loan = '';

  if (profile !== null) {
    Name = profile.firstName;
    Email = decrypt(profile.emailID);
    Loan = decrypt(selectedAccount);
  }

  const getWidgetProfile = () => {
    const privatelabel = window.location.pathname.split('/')[1];
    const clientName = Cookies.get('clientName');
    const isAgencyLoan = Cookies.get('isAgency');
    
    if (Cookies.get('privateToken')) {
      if (privatelabel !== undefined && clientName !== undefined && clientName !== null) {
        if (privatelabel.toLowerCase() == 'aafmaa' || clientName.toLowerCase() == 'vrm') {
          return 'Email%20-%20Customer%20Care%20Contact'
        } else if (isAgencyLoan == 'true') {
        return 'Email%20-%20Customer%20Care%20Contact%20-ESPL%20NON-AGENCY'
        } else {
          return 'Email%20-%20Customer%20Service%20Contact'
        } 
      } else {
          return 'Email%20-%20Customer%20Service%20Contact'
      }
    } else {
      if (privatelabel !== undefined) {
        if (privatelabel.toLowerCase() == 'bsi' || privatelabel.toLowerCase() == 'aafmaa') {
          return 'Email%20-%20Customer%20Care%20Contact'
        } else {
          return 'Email%20-%20Customer%20Service%20Contact'
        }
      } else {
          return 'Email%20-%20Customer%20Service%20Contact'
      } 
    }
  }

  var iframeurl = getPointPrivateLabel() ? 'https://app.five9.com/consoles/EmailConsole/index.html?title=Email&tenant=ServisOne&profiles=' + getWidgetProfile() + '&showProfiles=false&theme=default-theme.css&fields=%7B%22name%22%3A%7B%22value%22%3A%22' + Name + '%22%2C%22show%22%3Atrue%2C%22label%22%3A%22Name%22%7D%2C%22email%22%3A%7B%22value%22%3A%22' + Email + '%22%2C%22show%22%3Atrue%2C%22label%22%3A%22Email%22%7D%2C%22subject%22%3A%7B%22value%22%3A%22%22%2C%22show%22%3Atrue%2C%22label%22%3A%22%22%7D%2C%22Loan%20Number%22%3A%7B%22value%22%3A%22' + Loan + '%22%2C%22show%22%3Atrue%2C%22label%22%3A%22Contract%20Number%22%7D%7D' : 'https://app.five9.com/consoles/EmailConsole/index.html?title=Email&tenant=ServisOne&profiles=' + getWidgetProfile() + '&showProfiles=false&theme=default-theme.css&fields=%7B%22name%22%3A%7B%22value%22%3A%22' + Name + '%22%2C%22show%22%3Atrue%2C%22label%22%3A%22Name%22%7D%2C%22email%22%3A%7B%22value%22%3A%22' + Email + '%22%2C%22show%22%3Atrue%2C%22label%22%3A%22Email%22%7D%2C%22subject%22%3A%7B%22value%22%3A%22%22%2C%22show%22%3Atrue%2C%22label%22%3A%22%22%7D%2C%22Loan%20Number%22%3A%7B%22value%22%3A%22' + Loan + '%22%2C%22show%22%3Atrue%2C%22label%22%3A%22Loan%20Number%22%7D%7D';
  iframeurl = iframeurl.replace(/\s/g, "%20");
 
  return (
    <Box className={classes.mainRoot}>
      <Box className={classes.root}>
       
        <CustomBreadcrum backPage="Home" backPageLink="/Dashboard" activePage="Contact Us" />
        <h1 className={classes.pageHeading} color="textPrimary" component='h4'>Contact Us</h1>

        {content.length !== 0 ?
          <>
            <Box className={classes.container} dangerouslySetInnerHTML={{ __html: content[0].contentValue }} />
            {true && <React.Fragment>
              <Box className={classes.header}>
                <h1 className={classes.title}>Email Now</h1>
              </Box>
              <div className="contactContainer">
                <iframe className="responsive-iframe" src={iframeurl} frameBorder="0">
                </iframe>
              </div>
            </React.Fragment>}
          </>
          :
          <Box className={classes.loaderContainer}>
            <CircularProgress />
          </Box>
        }
      </Box>
    </Box>
  )
}