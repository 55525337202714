import { makeStyles } from '@material-ui/core/styles';
import signinIcon from '../../assets/SignIn/SignIn.svg';
import goGreenBgDesktop from '../../assets/goGreenBg.svg';

export const useStyles = makeStyles((theme) => ({
  rootBg: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#ccc',
    display: 'flex',
    justifyContent: 'center',
    backgroundImage: `url(${signinIcon})`,
    backgroundPosition: "bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'contain',
  },
  root: {
    width: "100%",
    maxWidth: "120em",
    padding: "2.3em 1.5em 9em",
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      padding: "2.3em 1.5em 5em",
    },
  },

  loaderContainer: {
    width: '100%',
    height: '100%',
    minHeight: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  container: {
    width: '100%',
    marginTop: 25,
  },

  link: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    marginTop: 14,
  },

  btn:{
       broder:'1px solid #EDB36A',
       background: 'white',
       width: '230px',
       float: 'right',
       marginTop: '-30px',
       cursor:'pointer',
       '&:hover':{
        background:'white'
       },
       [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
        width:'160px'
      }
  },

  disabledLink: {
    color: "#7B8390",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "bold",
    textDecoration: 'none',
  },

  activeLink: {
    color: theme.palette.primary.main,
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    textDecoration: 'none',
  },

  responsiveIframe: {
    width: '100%',
    height: '800px',
    border: 'none'
  },

  quoteBox: {
    width: '100%',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    marginBottom: '2em',
    marginTop: '3em',
    backgroundImage: `url(${goGreenBgDesktop})`
  },

  paperlessBtn: {
    width: '234px',
    alignItems: 'center',
    backgroundColor: '#457440 !important',
    borderColor: '#457440',
    color: '#FFFFFF',
    fontFamily: "Montserrat",
    fontSize: '16px',
    fontWeight: "600",
    textAlign: "center",
    textDecoration: "none",
    textTransform: "uppercase",
    borderRadius: "4px",
    padding: "12px",
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '210px',
      fontSize: 14
    }
  },

  inquireNow: {
    width: '234px',
    alignItems: 'center',
    backgroundColor: '#457440 !important',
    borderColor: '#457440',
    color: '#FFFFFF',
    borderRadius: 4,
    border: '1px solid',
    fontSize: 16,
    fontFamily: "Montserrat",
    fontWeight: "600",
    [theme.breakpoints.down('sm')]: {
      width: '210px',
      fontSize: 14
    }
  },

  bannerParagraph: {
    color: "#457440",
    fontFamily: "Montserrat",
    fontSize: 22,
    fontWeight: "700",
    maxWidth: '490px',
    marginLeft: '75px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight:'22px',
      marginLeft: '40px'
    }
  },

  quickQuoteBtn: {
    paddingLeft: 20
  },

  goGreenImg: {
    height: '94px',
    width: '160px', 
    marginLeft: '40px',
    [theme.breakpoints.down('sm')]: {
      width: '134px',
      height:'80px',
      marginLeft: '10px'
    }
  },

  goGreenMobImg: {
    height: '62px',
    width: '134px', 
    marginLeft: '14px'
  },

  bannerParagraphMob: {
    color: "#457440",
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: "700",
    maxWidth: '490px',
    marginLeft: '22px'
  },

  inquireNowMob: {
    width: '180px',
    height: '24px',
    alignItems: 'center',
    backgroundColor: '#457440 !important',
    borderColor: '#457440',
    color: '#FFFFFF',
    borderRadius: 4,
    border: '1px solid',
    fontSize: 12,
    fontFamily: "Montserrat",
    fontWeight: "600",
    marginTop: 2
  },

  goGreenContainer: {
    height: '118px', 
    textAlign: 'center', 
    alignItems: 'center' 
  }

}));