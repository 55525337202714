import React from "react";
import { Box, Button, Dialog, IconButton } from "@material-ui/core";
import { useStyles } from './styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import { decrypt } from '../../helpers/encrypt';




export const UpdatePaymentAlert = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { confirmationNumber, open, closeUpdateAlert } = props;



  return (
    <Dialog
      fullScreen={fullScreen}
      PaperProps={{ classes: { root: classes.dialogStyle } }}
      onClose={closeUpdateAlert}
      open={open}
    >
      <Box className={classes.root}>
        <Box className={classes.header}>
          <IconButton className={classes.iconStyle} onClick={closeUpdateAlert}>
            <CancelIcon />
          </IconButton>
        </Box>
        <h1 className={classes.successMsg}>Payment Updated Successfully.</h1>
        <p className={classes.successSubTitle}>Request ID: {decrypt(confirmationNumber)}</p>
        <Box className={classes.buttonBoxOk}>
          <Button onClick={closeUpdateAlert} className={classes.btn} variant="outlined" color='primary'>Ok</Button>
        </Box>
      </Box>
    </Dialog >
  )
}