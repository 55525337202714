import React, { useEffect, useState, useRef } from 'react';
import {
    Box, CssBaseline, CircularProgress, Grid, Typography, Button,
    FormControlLabel, FormGroup, Tooltip, Radio,
    RadioGroup
  } from '@material-ui/core';
import { useStyles, useStylesBootstrap } from './styles';
import {  renderDefaultMsg, scrollTop, isAdmin } from '../../helpers';
import CustomBreadcrum from '../Breadcrum';
import { useSelector } from 'react-redux';
import { submitIdentityTheftDocuments,
  downloadIdentityTheftDocument, deleteIdentityTheftDocuments, uploadIdentityTheftDocuments,
  identityTheftDocumentMapping, cancelIdentityTheftDocument } from '../../actions';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from "axios";
import fileDownload from 'js-file-download';
import Cookies from 'js-cookie';
import { FILE_SIZE_LIMII, FILE_LENGTH } from '../../helpers/constants';
import { DeleteAlert } from './DeleteAlert';
import { FileSizeAlert } from './FileSizeAlert';
import { FileExtensionAlert } from './FileExtensionAlert';
import { SubmitAlert } from './SubmitAlert';
import { UploadFileLengthValidationAlert } from './UploadFileLengthValidationAlert';
import moment from 'moment';

export const IdentityThefts = (props) => {

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const mytheme = useSelector(state => state.theme);
  const selectedAccount = useSelector(state => state.selectedAccount);
 
  const { primaryColorCode, secondaryColorCode } = mytheme;

  const [escrowApiError, setEscrowApiError] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [loading,  setLoading] = useState(false);
  const [lineType, setLineType] = useState('');
  const [fileName, setfileName] = useState('');
  const [docName, setDocName] = useState('');
  const [documentIndex, setDocumentIndex] = useState(0);
  const [openToolTip, setOpenToolTip] = useState(false);
  const [deleteStatus, setDeleteStatus] =  useState(false);
  const [replaceStatus, setReplaceStatus] = useState(false);
  const [documentId, setDocumentId] = useState(0);
  const [documentCode, setDocumentCode] = useState(0);
  const [openSubmitPopup, setSubmitPopupStatus] = useState(false);
  const [loader,  setLoader] = useState(true);
  const [alertStatus, setAlertStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [allowedExtensionsStatus, setAllowedExtensionsStatus] = useState(false);
  const [file, setFilesName] = useState('');
  const [fileLengthStatus, setFileLengthStatus] = useState(false);
  const [showMessageStatus, setShowMessageStatus] = useState(false);
  const [submitMessages, setSubmitMessages] = useState([])
  const [specialChar, setFileContainSpecialChar] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const [ques1, setQues1RadioBtnValue] = useState('b');
  const [ques2, setQues2RadioBtnValue] = useState('b');
  const [docs, setDocs] = useState([]);
  const [reqDocs, setReqDocs] = useState([
    {documentName: '', isRequired: true, document: '', documentTypeId : ''}, {documentName: '', isRequired: true, document: '', documentTypeId : ''}])
  const [req1Docs, setReq1Docs] = useState([
      {documentName: '', isRequired: true, document: '', documentTypeId : ''}, {documentName: '', isRequired: true, document: '', documentTypeId : ''}])
 const [req2Docs, setReq2Docs] = useState([
        {documentName: '', isRequired: true, document: '', documentTypeId : ''}, {documentName: '', isRequired: true, document: '', documentTypeId : ''}])
  const [docTypeId, setDocTypeId] = useState();
  const [isRequestAllowed, setIsRequestAllowed] = useState(true);
  const [createdDate, setSubmittedDate] = useState();
  const [status, reqDocStatus] = useState(false);
  const [formSubmitLoader, setFormSubmitLoader] = useState(false);

  const handleClose = () => setOpen(false);
  const handleExtensionAlertClose = () => setAllowedExtensionsStatus(false)
  const handleFileLengthAlertClose = () => setFileLengthStatus(false)
 
  useEffect(() => {
    Cookies.remove('redirectUrl')
    if (selectedAccount !== null) {
      cancelDocuments()
      getIdentityTheftDocMapping()
    }
  }, [selectedAccount])
 
  const getIdentityTheftDocMapping = async () => { 
    setLoader(true)
     const response = await identityTheftDocumentMapping(selectedAccount)
    if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode === '0') {  
      const data = response?.responseData?.responseData?.documents;
      const isRequestAllowed = response?.responseData?.responseData?.isRequestAllowed;
      setSubmittedDate(response?.responseData?.responseData?.lastSubmitRequest?.createdDate)
      setIsRequestAllowed(isRequestAllowed);
      var rb1a = data?.filter(item => item.documentType == 'RB1A')
      var rb1b = data?.filter(item => item.documentType == 'RB1B')
      var rb2a = data?.filter(item => item.documentType == 'RB2A')
      var rb2b = data?.filter(item => item.documentType == 'RB2B')
      var rb3 = data?.filter(item => item.documentType == 'RB3')
      setLoader(false)
      scrollTop()
      setDocs(rb3)
    
      setReqDocs([
        {documentName: rb1b?.[0].documentName, isRequired: true, document: rb1b?.[0].documentCode, documentTypeId :  rb1b?.[0].documentTypeId}, {documentName: rb2b?.[0].documentName, isRequired: true, document: rb2b?.[0].documentCode, documentTypeId :  rb2b?.[0].documentTypeId}])
        
      setReq1Docs([
        {documentName: rb1a?.[0].documentName, isRequired: true, document: rb1a?.[0].documentCode, documentTypeId :  rb1a?.[0].documentTypeId}, {documentName: rb1b?.[0].documentName, isRequired: true, document: rb1b?.[0].documentCode, documentTypeId :  rb1b?.[0].documentTypeId}])

      setReq2Docs([
        {documentName: rb2a?.[0].documentName, isRequired: true, document: rb2a?.[0].documentCode, documentTypeId :  rb2a?.[0].documentTypeId}, {documentName: rb2b?.[0].documentName, isRequired: true, document: rb2b?.[0].documentCode, documentTypeId :  rb2b?.[0].documentTypeId}])  
          
    } else if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode !== '0') {
      setLoader(false)
      setEscrowApiError(response?.responseData?.lstResponseStatus[0]?.statusDesc)
      scrollTop()
    } else {
      setEscrowApiError(renderDefaultMsg('400'));
      scrollTop()
    }
  }
 
  const handleSubmitClick = () => {
    setFormSubmit(true)
    if ((reqDocs[0]?.fileName == null) || (reqDocs[1]?.fileName == null)) {
       window.scrollTo({ top: 360, left: 0, behavior: 'smooth' });
    } else {
      handleSubmit(true);
    }
  } 

  const handleDelete = () => {
    setOpen(false);
    deleteDocuments()
  }

  const handleSubmit = async () => {
    setFormSubmitLoader(true)
    let answer1 = `1-${ques1}`
    let answer2 = `2-${ques2}`
    let filterDocs = docs.filter(function (e) {
      return e?.documentId != undefined;
    });
    let answer3 = filterDocs.map((item) => item?.documentId)  
    const response = await submitIdentityTheftDocuments(selectedAccount, answer1, reqDocs[0]?.documentId, answer2,  reqDocs[1]?.documentId, answer3)

    if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode === '0') {  
      setFormSubmitLoader(false)
      setShowMessageStatus(false)
      setFormSubmit(false)
      setLoader(true)
      scrollTop()
      getIdentityTheftDocMapping()
    } else if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode !== '0') {
      setFormSubmitLoader(false)
      setFormSubmit(false)
      setEscrowApiError(response?.responseData?.lstResponseStatus[0]?.statusDesc)
      scrollTop()
    } else {
      setFormSubmitLoader(false)
      setFormSubmit(false)
      setEscrowApiError(renderDefaultMsg('400'));
      scrollTop()
    }
  }
 
  const handleSubmitClose = () => {
    setSubmitPopupStatus(false)
    history.push({pathname: '/IdentityTheft'});
  }

  const handleAlertClose = () => {
   setAlertStatus(false)
  }

  const deleteDocuments = async () => {
    setLoading(true)
    const response = await deleteIdentityTheftDocuments(selectedAccount, documentId)
    if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode === '0') {  
      setLoading(false)
      if (status == true) {
        let newObj = [...reqDocs]  
        newObj[documentIndex]['fileName'] = null
        newObj[documentIndex]['uploadStatus'] = false
        newObj[documentIndex]['deleteStatus'] = true
        newObj[documentIndex]['documentId'] = undefined
        setReqDocs(newObj)
      } else {
        let newObj = [...docs]  
        newObj[documentIndex]['fileName'] = null
        newObj[documentIndex]['uploadStatus'] = false
        newObj[documentIndex]['deleteStatus'] = true
        newObj[documentIndex]['documentId'] = undefined
        setDocs(newObj)
      }
    } else if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode !== "0") {
      setLoading(false)
      setErrorMsg(response.lstResponseStatus[0].statusDesc)
      scrollTop()
    } else {
      setLoading(false)
      setEscrowApiError(renderDefaultMsg('400'));
      scrollTop()
   }
  } 

  const downloadDocuments = async (documentId, downloadFileName) => {
   
    const response = await downloadIdentityTheftDocument(selectedAccount, documentId)
    if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode === '0') {  

      axios.get(response?.responseData?.responseData, {
        responseType: "blob"
      })
      .then(res => {
        fileDownload(res.data, downloadFileName)
      })
    }  else if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode !== "0") {
      setLoading(false)
      setErrorMsg(response.lstResponseStatus[0].statusDesc)
      scrollTop()
    } else {
      setLoading(false)
      setEscrowApiError(renderDefaultMsg('400'));
      scrollTop()
   }
  } 

   const uploadReqDocuments = (documentIndex, name, deleteStatus, replace, id, code, docTypeId) => {
    setDocumentIndex(documentIndex)
    setfileName(name)
    setDeleteStatus(deleteStatus)
    setReplaceStatus(replace)
    setDocumentId(id)
    setDocumentCode(code)
    setDocName(name)
    setDocTypeId(docTypeId)
  };

  const handleFile = (event, type, index) => {
    var allowedExtensions = /(\.jpeg|\.png|\.jpg|\.pdf)$/i;
    var specialCharacters =  /[\/:*?“<>|]/;
  
    if (event.target.files[0].size > FILE_SIZE_LIMII) {
      setFilesName(event.target.files[0].name)
      setAlertStatus(true)
    } else if (!allowedExtensions.exec(event.target.value)) { 
      setFilesName(event.target.files[0].name)
      setAllowedExtensionsStatus(true)
    } else if (event.target.files[0].name.length > FILE_LENGTH) {
      setFilesName(event.target.files[0].name)
      setFileLengthStatus(true)
    }  else if (specialCharacters.test(event.target.files[0].name)) {
      setFilesName(event.target.files[0].name)
      setFileContainSpecialChar(true) 
    } else {
      onFileUpload(event.target.files[0], type, index)
    }
  };
 
  const onFileUpload = async (file, type, index) => {
   
    setErrorMsg('');
    if (type == 'requiredDoc') { 
      // setLoader(true)
    } else {
      setLoading(true)
    }
    const res = await uploadIdentityTheftDocuments(selectedAccount, file, replaceStatus, docName, documentId, docTypeId);
   
    if (res && res?.responseData?.lstResponseStatus && res?.responseData.lstResponseStatus[0]?.statusCode === '0') { 
      setLoading(false)
      // setLoader(false)
      if (type == 'requiredDoc') {
        let newObj = [...reqDocs]  
        newObj[documentIndex]['fileName'] = file.name
        newObj[documentIndex]['uploadStatus'] = true
        newObj[documentIndex]['deleteStatus'] = false
        newObj[documentIndex]['documentId'] = res?.responseData?.responseData
        setReqDocs(newObj)
      } else {
        let newObj = [...docs]  
        newObj[documentIndex]['fileName'] = file.name
        newObj[documentIndex]['uploadStatus'] = true
        newObj[documentIndex]['deleteStatus'] = false
        newObj[documentIndex]['documentId'] = res?.responseData?.responseData
        setDocs(newObj)
      }
    } else if (res && res?.responseData?.lstResponseStatus && res?.responseData.lstResponseStatus[0]?.statusCode !== "0") {
      setLoading(false)
      setErrorMsg(res.lstResponseStatus[0].statusDesc)
      scrollTop()
    } else {
      setLoading(false)
      setEscrowApiError(renderDefaultMsg('400'));
      scrollTop()
   }
  };

  const uploadDocuments = ( documentIndex, name, deleteStatus, replace, id, code, docTypeId) => {
    setDocumentIndex(documentIndex)
    setfileName(name)
    setDeleteStatus(deleteStatus)
    setReplaceStatus(replace)
    setDocumentId(id)
    setDocumentCode(code)
    setDocName(name)
    setDocTypeId(docTypeId)
  };

  const handleInputFile = (e) => {
    e.target.value = null;
  }

  const borowerRadioChangeHandler = (event) => {
    setQues1RadioBtnValue(event.target.value)
    if (event.target.value == 'a') {
      let newObj = [...reqDocs]  
      newObj[0]['documentName'] = req1Docs[0]?.documentName
      newObj[0]['isRequired'] = true
      newObj[0]['document'] = req1Docs[0]?.document
      newObj[0]['documentTypeId'] =req1Docs[0]?.documentTypeId
      setReqDocs(newObj)
    } else {
      let newObj = [...reqDocs]  
      newObj[0]['documentName'] = req1Docs[1]?.documentName
      newObj[0]['isRequired'] = true
      newObj[0]['document'] = req1Docs[1]?.document
      newObj[0]['documentTypeId'] =req1Docs[1]?.documentTypeId
      setReqDocs(newObj)
    }

    if (event.target.value == 'a' && reqDocs[0]?.fileName != null) {
      setDocumentIndex(0)
      setfileName(reqDocs[0]?.fileName)
      setDocName(reqDocs[0]?.document)
      setDocumentId(reqDocs[0]?.documentId)
      deleteDoc(0, reqDocs[0]?.documentId)
    } else if (event.target.value == 'b' && reqDocs[0]?.fileName != null) {
      setDocumentIndex(0)
      setfileName(reqDocs[0]?.fileName)
      setDocName(reqDocs[0]?.document)
      setDocumentId(reqDocs[0]?.documentId)
      deleteDoc(0, reqDocs[0]?.documentId)
    }
  };
 
  const deleteDoc = async (index, documentId) => {
    const response = await deleteIdentityTheftDocuments(selectedAccount, documentId)
      if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode === '0') {
        setLoading(false)
        let newObj = [...reqDocs]  
        newObj[index]['fileName'] = null
        newObj[index]['uploadStatus'] = false
        newObj[index]['deleteStatus'] = true
        newObj[index]['documentId'] = undefined
        setReqDocs(newObj)
    } else if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode !== "0") {
      setLoading(false)
      setErrorMsg(response.lstResponseStatus[0].statusDesc)
      scrollTop()
    } else {
      setLoading(false)
      setEscrowApiError(renderDefaultMsg('400'));
      scrollTop()
   }
  }

  const handleDeleteDoc = (docName, fileName, documentIndex, id, status) => {
    setDocumentIndex(documentIndex)
    setfileName(fileName)
    setDocName(docName)
    setOpen(true)
    setDocumentId(id)
    reqDocStatus(status)
  };

  const borowerRadioChangeHandler2 = (event) => {
    setQues2RadioBtnValue(event.target.value)
    if (event.target.value == 'a') {
      let newObj = [...reqDocs]  
      newObj[1]['documentName'] = req2Docs[0]?.documentName
      newObj[1]['isRequired'] = true
      newObj[1]['document'] = req2Docs[0]?.document
      newObj[1]['documentTypeId'] =req2Docs[0]?.documentTypeId
      setReqDocs(newObj)
    } else {
      let newObj = [...reqDocs]  
      newObj[1]['documentName'] = req2Docs[1]?.documentName
      newObj[1]['isRequired'] = true
      newObj[1]['document'] = req2Docs[1]?.document
      newObj[1]['documentTypeId'] =req2Docs[1]?.documentTypeId
      setReqDocs(newObj)
    }

    if (event.target.value == 'a' && reqDocs[1]?.fileName != null) {
      setDocumentIndex(1)
      setfileName(reqDocs[1]?.fileName)
      setDocName(reqDocs[1]?.document)
      setDocumentId(reqDocs[1]?.documentId)
      deleteDoc(1, reqDocs[1]?.documentId)
    } else if (event.target.value == 'b' && reqDocs[1]?.fileName != null) {
      setDocumentIndex(1)
      setfileName(reqDocs[1]?.fileName)
      setDocName(reqDocs[1]?.document)
      setDocumentId(reqDocs[1]?.documentId)
      deleteDoc(1, reqDocs[1]?.documentId)
    }
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const cancelDocuments = async () => {
    const response = await cancelIdentityTheftDocument(selectedAccount)
  } 

  const resetDocuments = async () => {
    history.push({pathname: '/Dashboard'})
    cancelDocuments();
  }

  if (loader) {
    return (
      <Box className={classes.loader}>
        <CircularProgress />
      </Box>
    )
  }

  if (!isRequestAllowed && !loader) {
    return (
      <Box container="true" maxWidth='xl' className={classes.root}>      
        <Box className={classes.container}>
      
          <CustomBreadcrum backPage="Dashboard" backPageLink="/Dashboard" activePage="Identity Theft" />
          <h1 className={classes.pageHeading} color="textPrimary" component='h4'>Identity Theft</h1>
          {escrowApiError !== '' ? <Typography className={classes.apiErrorMsg}>{escrowApiError}</Typography> : null}
          <Box  className={classes.infoStrip}>
          <Box  className={classes.toggleBtnBox}>
          <Box className={classes.identitySubmitInfoContainer}>
            <Box>
              <p className={classes.text}><b>Submission Status: Submitted </b></p>
              <p className={classes.text}>Submitted Date: {moment(createdDate).format('MM/DD/YYYY, h:mm:ss A')} EST</p>
              <p className={classes.text}><b>Application complete, under review by Privacy & Fraud Team</b></p>
            </Box>
          </Box>
          </Box>
       </Box>
       </Box>
       </Box>
    )
  }
  
  return (
    <CssBaseline>
      {!loader && 
      <>
       <Box container="true" maxWidth='xl' className={classes.root}>      
        <Box className={classes.container}>
      
          <CustomBreadcrum backPage="Dashboard" backPageLink="/Dashboard" activePage="Identity Theft" />
          <h1 className={classes.pageHeading} color="textPrimary" component='h4'>Identity Theft</h1>
          {escrowApiError !== '' ? <Typography className={classes.apiErrorMsg}>{escrowApiError}</Typography> : null}
          {isRequestAllowed && !loader &&
         <Box  className={classes.infoStrip}>
         <Box  className={classes.toggleBtnBox}>
           <Typography  className={classes.heading}>In order for our office to properly investigate this matter, we will need the following:</Typography>
           <Typography className={classes.questions}>1. <span style={{color: 'red'}}>* </span>Report filed by you alleging that you are victim of an identity theft crime.</Typography>
           <Grid item  className={classes.mt15}>
                    <RadioGroup col className={`${classes.justifyCenter} ${classes.mt10}`}
                     value={ques1}
                     onChange={borowerRadioChangeHandler}
                     >
                      <FormControlLabel
                        value="a"
                        name="type1"
                        checked={ques1 === 'a' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label={
                        `A copy of a valid police report filed by you alleging that you are victim of an identity theft crime, including, but not limited to, a violation of section 190.78, 190.79, 190.80, 190.81, 190.82 or 190.83 of the penal law for the debt that ${mytheme.privateLabelName} is collecting; OR`}
                        classes={{
                          label: classes.radioLabel
                        }}
                      />
                      <FormControlLabel
                        style={{marginTop: '10px'}}
                        value="b"
                        name="type2"
                        checked={ques1 === 'b' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="A copy of a completed and signed federal trade commission identity theft victim’s report filed by you alleging that you are a victim of identity theft."
                        classes={{
                          label:  classes.radioLabel
                        }}
                      />
                    </RadioGroup >
                  </Grid>
                  <>
                   <input
                    accept=".pdf, image/png, image/jpg, image/jpeg"
                    type="file"
                    name='selectedFile'
                    style={{display: 'none'}}
                    ref={inputRef}
                    onClick={(e) => handleInputFile(e)}
                    onChange={(e) => handleFile(e, 'requiredDoc', 0)}
                    />
                   {reqDocs[0]?.fileName == null &&
                    <Button onClick={() => {handleClick(); uploadReqDocuments(0, reqDocs[0].document,  reqDocs[0]?.deleteStatus, false,   reqDocs[0]?.documentId,  reqDocs[0]?.documentCode, reqDocs[0]?.documentTypeId  )}}
                          className={classes.uploadBtn}  variant="contained" color="primary">
                          Upload Document
                    </Button>
                   }
                   </>
                   {reqDocs[0]?.fileName != null &&
                    <Box  className={classes.uploadDocSection}>
                                 
                     <Box className={classes.uploadSection} style={{"backgroundColor" : loading ? '#eeecec' : 'white'}}>
                     <Grid container>
                            <Grid item  md={6} xs={6} lg={6}>
                            <Typography className={classes.docName} onClick={() => downloadDocuments(reqDocs[0]?.documentId, reqDocs[0]?.fileName)}>{reqDocs[0]?.fileName ? reqDocs[0]?.fileName : ''}</Typography>
                            </Grid>
                            <Grid item md={6} xs={6} lg={6}>
                              <input
                                accept=".pdf, image/png, image/jpg, image/jpeg"
                                type="file"
                                name='selectedFile'
                                style={{display: 'none'}}
                                id="upload-button1"
                                onClick={(e) => handleInputFile(e)}
                                onChange={(e) => handleFile(e, 'requiredDoc', 0)}

                              />
                             
                              <Box className={classes.deleteReplaceSection}>
                               <Typography  className={classes.delete} onClick={() => handleDeleteDoc(reqDocs[0]?.document, reqDocs[0]?.fileName, 0, reqDocs[0]?.documentId, true)}>Delete</Typography>
                               <Typography  className={classes.delete}>|</Typography>
                               <label htmlFor="upload-button1">
                                <Typography  className={classes.delete} onClick={() =>  uploadReqDocuments(0, reqDocs[0].document,  reqDocs[0]?.deleteStatus, true,   reqDocs[0]?.documentId,  reqDocs[0]?.documentCode, reqDocs[0]?.documentTypeId )}>Replace</Typography>
                               </label>
                              </Box>
                              
                            </Grid>
                     </Grid>
                    </Box>
                    </Box>
                  }
                  { formSubmit && (reqDocs[0]?.fileName == null) &&
                  <Typography className={classes.errorMsg}>* Document required.</Typography>
                  }
                  <Typography className={classes.questions}>2. <span style={{color: 'red'}}>* </span>A written statement claiming that you are a victim of identity theft with respect to the debt that BSI Financial Services is collecting.</Typography>
                <Grid item  className={classes.mt15}>
                    <RadioGroup col className={`${classes.justifyCenter} ${classes.mt10}`}
                     value={ques2}
                     onChange={borowerRadioChangeHandler2}
                     >
                      <FormControlLabel
                        disabled
                        style={{display: 'none'}}
                        value="a"
                        name="type1"
                        checked={ques2 === 'a' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="A signed federal trade commission ID theft victim’s affidavit;
                        "
                        classes={{
                          label: classes.radioLabel
                        }}
                      />
                      <FormControlLabel
                        style={{marginTop: '10px'}}
                        value="b"
                        name="type2"
                        checked={ques2 === 'b' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="A written statement that certifies that the representations are true, correct, and contain no material omissions of fact to the best knowledge and belief of the person submitting the certification. A person submitting such certification who declares as true any material matter pursuant to this subdivision that he or she knows to be false is guilty of a misdemeanor."
                        classes={{
                          label:  classes.radioLabel
                        }}
                      />
                    </RadioGroup >
                  </Grid>
                  <>
                   <input
                    accept=".pdf, image/png, image/jpg, image/jpeg"
                    type="file"
                    name='selectedFile'
                    style={{display: 'none'}}
                    ref={inputRef}
                    onClick={(e) => handleInputFile(e)}
                    onChange={(e) => handleFile(e, 'requiredDoc', 1)}
                    />
                    {reqDocs[1]?.fileName == null &&
                    <Button onClick={() => {handleClick(); uploadReqDocuments(1, reqDocs[1].document,  reqDocs[1]?.deleteStatus, false,   reqDocs[1]?.documentId,  reqDocs[1]?.documentCode, reqDocs[1]?.documentTypeId )}}
                          className={classes.uploadBtn}  variant="contained" color="primary">
                          Upload Document
                    </Button>
                   }
                   </>
                   {reqDocs[1]?.fileName != null &&
                     <Box  className={classes.uploadDocSection}>             
                     <Box className={classes.uploadSection} style={{"backgroundColor" : loading ? '#eeecec' : 'white'}}>
                   <Grid container>
                            <Grid item  md={6} xs={6} lg={6}>
                            <Typography className={classes.docName} onClick={() => downloadDocuments(reqDocs[1]?.documentId, reqDocs[1]?.fileName)}>{reqDocs[1]?.fileName ? reqDocs[1]?.fileName : ''}</Typography>
                            </Grid>
                            <Grid item md={6} xs={6} lg={6}>
                              <input
                                accept=".pdf, image/png, image/jpg, image/jpeg"
                                type="file"
                                name='selectedFile'
                                style={{display: 'none'}}
                                id="upload-button2"
                                onClick={(e) => handleInputFile(e)}
                                onChange={(e) => handleFile(e, 'requiredDoc', 1)}
                              />
                             
                              <Box className={classes.deleteReplaceSection}>
                               <Typography  className={classes.delete} onClick={() => handleDeleteDoc(reqDocs[1]?.document, reqDocs[1]?.fileName, 1, reqDocs[1]?.documentId, true)}>Delete</Typography>
                               <Typography  className={classes.delete}>|</Typography>
                               <label htmlFor="upload-button2">
                                <Typography  className={classes.delete} onClick={() =>  uploadReqDocuments(1, reqDocs[1].document,  reqDocs[1]?.deleteStatus, true,   reqDocs[1]?.documentId,  reqDocs[1]?.documentCode, reqDocs[1]?.documentTypeId )}>Replace</Typography>
                               </label>
                              </Box>
                              
                            </Grid>
                    </Grid>
                    </Box>
                    </Box>
                  }
                  { formSubmit && (reqDocs[1]?.fileName == null) &&
                    <Typography className={classes.errorMsg}>* Document required.</Typography>
                  }
            <Typography className={classes.questions}>3. The above statement should be accompanied by, the following, to the extent that such items are relevant to the allegation of identity theft with respect to the debt in question:</Typography>
          </Box>
          <Box style={{marginTop : '40px'}}>
            <Box  className={classes.escrowList}>
                <>
                  { true ?
                  <>                  
                 <Box className={classes.uploadSection} style={{"backgroundColor" : loading ? '#eeecec' : 'white'}}>
                      
                <Typography className={classes.upload}>
                  {loading && 
                    <Box className={classes.loadingRoot}>
                      <CircularProgress />
                    </Box> 
                  } 
                </Typography>

                { docs?.length > 0 && docs.map((key, ind) => {  
                return(
                  <>
                    <Grid container key={ind}>
                            <Grid item  md={5} xs={5} lg={5}>
                            <Typography className={classes.documentName} style={{ display: 'flex' }}>
                              {key?.documentName}
                            </Typography>
                            </Grid>
                            <Grid item  md={5} xs={5} lg={5}>
                            <Typography className={classes.identityTheftDocName} onClick={() => downloadDocuments(key?.documentId, key?.fileName)}>{key?.fileName ? key?.fileName : ''}</Typography>
                            </Grid>
                            <Grid item md={2} xs={2} lg={2}>
                              <input
                                accept=".pdf, image/png, image/jpg, image/jpeg"
                                type="file"
                                name='selectedFile'
                                style={{display: 'none'}}
                                id="upload-button"
                                onClick={(e) => handleInputFile(e)}
                                onChange={(e) => handleFile(e, 'key',ind)}
                              />
                              {!key?.fileName ?
                              <label htmlFor="upload-button">
                                  <Typography  className={classes.addDocument} 
                                  onClick={() => uploadDocuments(ind, key?.documentCode, key?.deleteStatus, false,  key?.documentId, key?.documentCode,
                                  key?.documentTypeId )}>Upload File</Typography>
                              </label> : 
                              <Box className={classes.deleteReplaceSection}>
                               <Typography  className={classes.delete} onClick={() => handleDeleteDoc(key?.documentCode, key?.fileName, ind, key?.documentId, false)}>Delete</Typography>
                               <Typography  className={classes.delete}>|</Typography>
                               <label htmlFor="upload-button">
                                <Typography  className={classes.delete} onClick={() => uploadDocuments(ind,  key?.documentCode,
                                   key?.deleteStatus, true, key?.documentId,  key?.documentCode,
                                   key?.documentTypeId
                                  )}>Replace</Typography>
                               </label>
                              </Box>
                              }
                            </Grid>
                    </Grid>
                    { ind < (docs?.length - 1) &&
                      <hr className={classes.line} />
                    }
                 </>
                )})}
              
                </Box> 
                </> : null
                }
              </> 
          </Box>

            
              <Grid container>
                        <Grid item  md={9} xs={12} lg={9}>
                            <Button type='submit' 
                                    className={classes.cancelBtn}  
                                    onClick={resetDocuments} variant="outlined">
                                    Cancel
                            </Button>
                        </Grid>
                       
                        <Grid item  md={3} xs={12} lg={3}>
                            <Box display='flex' className={classes.commonBtn}>
                                   
                                  
                                    {formSubmitLoader ?
                                      <Button 
                                          type="submit"
                                          variant="contained"
                                          className={classes.submitLoader} 
                                          disabled={formSubmitLoader}>
                                          <CircularProgress style={{ height: '24px', width: '24px' }} 
                                           color='primary' /> 
                                        </Button>
                                        :
                                        <Button type='submit'
                                        onClick={handleSubmitClick}
                                        disabled={isAdmin()}
                                        className={classes.submitBtn}  variant="contained" color="primary">
                                        Submit
                                        </Button>
                                   } 
                            </Box>
                        </Grid>
                </Grid> 
            </Box> 
          </Box>
          
         }
        </Box>
      </Box>
      <DeleteAlert
        open={open}
        file={fileName}
        handleDelete={handleDelete}
        handleClose={handleClose}
      />
      <FileSizeAlert
        open={alertStatus}
        handleAlertClose={handleAlertClose}
        file={file}
      />
      <FileExtensionAlert
        open={allowedExtensionsStatus}
        handleExtensionAlertClose={handleExtensionAlertClose}
        file={file}
      />
       <UploadFileLengthValidationAlert
        open={fileLengthStatus}
        handleFileLengthAlertClose={handleFileLengthAlertClose}
        file={file}
       />
      <SubmitAlert
        open={openSubmitPopup}
        submitMessages={submitMessages}
        showMessageStatus={showMessageStatus}
        handleSubmit={handleSubmit}
        handleSubmitClose={handleSubmitClose}
      />
    </> 
    }
    </CssBaseline>
  )
}
