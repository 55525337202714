import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from "@material-ui/core";
import { useStyles } from './styles';
import { useDispatch } from 'react-redux';
import { getContent } from '../../actions';
import { scrollTop, getPointPrivateLabel, getLoanLabel } from '../../helpers';
import './style.css'
import CustomBreadcrum from '../Breadcrum';
import { useSelector } from 'react-redux';

export const NewlyBoarded = () => {
  const classes = useStyles();
  const theme = useSelector(state => state.theme);
  const [content, setContent] = useState([])
  const dispatch = useDispatch()
  const getContentData = async () => {
    const res = await dispatch(getContent('PC023'));
    if (res) {
      setContent(res);
      scrollTop()
    }

  };
  useEffect(() => {
    getContentData();
    scrollTop()
  }, []);

  return (
    <Box className={classes.bg}>
      <Box className={classes.root}>
        <CustomBreadcrum backPage="Home" backPageLink="/" activePage="Newly Boarded" />
        {/* {content.length !== 0 ?
          < Box className={classes.container} dangerouslySetInnerHTML={{ __html: content[0].contentValue }} />
          :
          <Box className={classes.loaderContainer}>
            <CircularProgress />
          </Box>
        } */}
        <Box className={classes.container}>
        <div class="newly-boarded-container">           
          <div class="newly-boarded-about-us"></div>  
             <div class="rectangle">            
                {/* <div class="pics">             
                  <img src="https://d3idzssovnbdsz.cloudfront.net/images/Assets/AboutUs/aboutUs1.png" />           
                </div>            */}
                <div class="incider_text_newlyboarded">
                  {/* <span>Welcome to {theme.privateLabelName}</span> */}
                <h3 class="title">Welcome to {theme.privateLabelName}</h3>
                <p>We are excited to be servicing your {getLoanLabel()?.toLowerCase()}. Once your {getLoanLabel()?.toLowerCase()} transfers to our company you will be able to set up an account online to use convenient features like viewing your {getLoanLabel()?.toLowerCase()} data,{!getPointPrivateLabel() && 'automatic drafts,'} paperless statements, requesting documents, and much more.</p>
                { !getPointPrivateLabel() &&
                <>
                <h3 class="subtitle1">When Will My {getLoanLabel()} be Transferred to  {theme.privateLabelName}?</h3>
                <p>Located on your goodbye letter from your previous servicer will be a date that the servicing of your {getLoanLabel()?.toLowerCase()} will be transferred.</p>
               
                  <h3 class="subtitle2">How can I make my monthly payment?</h3>
                  <p>Once your loan is boarded in our system you will be able to create an online account and make one time payments or set up recurring payments. </p>
                  <h3 class="subtitle3">Please note, if you were set up on automatic withdrawal from your checking or savings account, the previous servicer may discontinue the auto-withdrawal service.</h3>
                  <p class="subtitle4">You can also mail your payments to  {theme.privateLabelName} at this address:</p>
                <div class="coverarea" style={{'paddingLeft': '20px'}}><div class="division"><h3 style={{'text-decoration': 'underline'}}>Via First Class Mail</h3> 
                <p> {theme.privateLabelName}  <br></br> PO Box 679002 <br></br> Dallas, TX 75267-9002</p></div>
                <div class="division"><h3 style={{'text-decoration': 'underline'}}>Via Priority or Overnight Mail</h3><p> {theme.privateLabelName} <br></br>
                Lockbox Number 679002  <br></br>
                1200 E. Campbell Rd. Ste. 108  <br></br> Richardson, TX 75081</p></div>
                </div>
                <h3 class="subtitle3">Under Federal Law,  during the 60-day period beginning on the effective date of the transfer of the loan, a loan payment received by your old servicer on or before its due date (including any grace period allowed under the mortgage loan instruments) may not be treated by the new servicer as late, and a late fee may not be imposed on you.</h3>
               </>
               }
              </div>
            </div>       
          </div>
        </Box>
      </Box>
    </Box>
  )
}