import React from 'react';
import {
  Box, CssBaseline, Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import GoPaperLess from '../../assets/GoPaperLess.png';
import Checkmarkpaperless from '../../assets/Checkmarkpaperless.png';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

export const PaperLess = () => {

  const classes = useStyles();
  const history = useHistory();

  const navigateToCommPref = () => {
    history.push('/UserProfile/CommunicationPreferences');
  }
 
  const navigateToSignIn = () => {
    history.push('/SignIn');
  }

  return (
    <CssBaseline>
      <Box container="true" maxWidth='xl' className={classes.root} >
          <Box className={classes.container}>
            <Box mt={3} mb={3.4}>
              
            </Box>
            <Box className={classes.formContainer}>
              <Box className={classes.instructionsContainer}>
                  
                <Box className={classes.paperlessStatement}>
                  <Grid container direction="row">
                    <Grid item xs={12} sm={12} md={12}>
                      <Box mt={1} className={classes.mbPhone}>
                        <Box >
                          <img className={classes.checkIcon} src={Checkmarkpaperless} alt='img' />
                          <Typography className={classes.congrats} style={{paddingBottom: "10px"}}>Congratulations! </Typography>
                          <Typography className={classes.activeHrs} style={{paddingBottom: "18px"}}>Your paperless option will be active within next 24 hours.</Typography>
                        </Box> 
                      </Box> 
                    </Grid>
                  </Grid>
                </Box>
                
                <Grid container direction="row">
                  <Grid item xs={12} sm={12} md={12}>
                    <Box mt={1} className={classes.mbPhone}>
                      <Box >
                        <Typography className={classes.paperless} style={{paddingBottom: "10px"}}>Your paperless document are on their way! </Typography>
                        <img className={classes.goGreen} src={GoPaperLess} alt='img' />
                        <Typography className={classes.activeHrs} style={{paddingBottom: "20px", paddingTop: "15px"}}>Say goodbye to paper clutter! The paperless option allows you to view and manage your documents conveniently online.</Typography>
                      </Box> 
                    </Box> 
                  </Grid>
                </Grid>
                <hr className={classes.line} />

                <Box className={classes.instText}>
                  <Typography className={classes.text}>Managing Your Paperless Preferences</Typography>
                  <Typography className={classes.straightForward}>If, for any reason, you wish to cancel the paperless option, it's a straightforward process:</Typography>
                </Box>

                <Box pb={4} className={classes.instText}>
                  { (Cookies.get('privateToken')) ?
                  <>
                    <Typography className={classes.straightForward}>1. <span className={classes.linkStyle} onClick={() => navigateToCommPref()}> Navigate to Preferences: </span> Once logged in, go to your <span className={classes.footerText}>Communication Preferences</span> tab under <span className={classes.footerText}>Account Settings</span>.</Typography>
                  <Typography className={classes.straightForward}>2. <span className={classes.linkStyle} onClick={() => navigateToCommPref()}>Paperless Settings: </span> Look for the <span className={classes.footerText}>Paperless</span> option. Here, you'll be able to manage your paperless preferences.</Typography>
                  </>
                   :
                   <>
                  <Typography className={classes.straightForward}>1. <span className={classes.linkStyle} onClick={() => navigateToSignIn()}>Log In:</span> Please log in to your account on <span className={classes.footerText}>MyLoanWeb</span>.</Typography>
                  <Typography className={classes.straightForward}>2. <span className={classes.linkStyle} onClick={() => navigateToCommPref()}> Navigate to Preferences: </span> Once logged in, go to your <span className={classes.footerText}>Communication Preferences</span> tab under <span className={classes.footerText}>Account Settings</span>.</Typography>
                  <Typography className={classes.straightForward}>3. <span className={classes.linkStyle} onClick={() => navigateToCommPref()}>Paperless Settings: </span> Look for the <span className={classes.footerText}>Paperless</span> option. Here, you'll be able to manage your paperless preferences.</Typography>
                  </>
                }
                </Box>
               
              </Box>
            </Box>
          </Box>
      </Box>
     
    </CssBaseline>
  )
}
