import signinIcon from '../../assets/signinIcon.svg';
import {
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#ccc',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${signinIcon})`,
    backgroundPosition: "top left",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'cover'
  },

  errorMsg: {
    fontSize: '1.4em',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
    fontFamily: 'Montserrat',
    color: "red",
  },

  //signin with sso
  ssoFormContainer: {
    width: '60%',
    minWidth: "33.3em",
    maxWidth: '60em',
    padding: '9em 15em',
    backgroundColor: '#fff',
    borderRadius: 12,
    marginTop: '6.6em',
    marginBottom: '6.6em',
    textAlign: 'center',
    boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
      padding: '20px',
      margin: '50px 15px'
    },
  },
  
  signInSsoBtn: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: '600',
  },
 
  signInUsernameBtn: {
    fontFamily: 'Montserrat',
    fontSize: 10,
    width: '55%',
    height: '20%',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: '600',
  },

  orContainer: {
    width: '80%',
    margin: '0 auto',
    borderTop: 'solid 1px #979797',
    position: 'relative',
    marginBottom: 40,
    marginTop: 40
  },

  orWraper: {
    width: 30,
    height: 30,
    position: 'absolute',
    top: -16,
    left: '45%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  orText: {
    color: "#727272",
    fontFamily: "Montserrat",
    fontSize: "1.4em",
    fontWeight: "500",
    margin: 0,
  },
  errorMsg: {
    fontSize: '1.4em',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
    fontFamily: 'Montserrat',
    color: "red",
  },
}));
