import React, { useState } from 'react';
import { useStyles } from './styles';
import { Box, Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';


export const DeleteConfirmation = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {popupText=null, file} = props;
  const [btnStatus, setStatus] = useState();

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={props.handleCancelIcon} open={props.open}>
     
      <Box className={classes.root}>
          
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>Delete Confirmation</Typography>
        </Box>
        
        <Box className={classes.cardBody2}>
          <Box className={classes.statusContainer}>
           <Box className={classes.cardContainer}>
            <Box className={classes.imageContainer}>
             {/* <InfoIcon className={classes.infoIcon} /> */}
             <Typography className={classes.paragraph}>Are you sure you want to delete the {file}?</Typography>
             </Box>
            </Box> 
          </Box>
        </Box>

        <Box className={classes.cardFooter}>
         <Box className={classes.footerItem}>
            <Button
              variant='outlined'
              className={classes.btn}
              onClick={props.handleClose}
            > Cancel</Button>
          </Box>

          <Box className={classes.footerItem}>
              <Button
                variant='contained'
                color='primary'
                className={classes.btn}
                onClick={props.handleOk}
              >Delete</Button>
          </Box> 
        </Box>
       
      </Box> 
    </Dialog>
  )
}
