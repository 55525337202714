import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 12,
    backgroundColor: "#F5F6F7",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    padding: '2.2em 2.8em',
    [theme.breakpoints.down('xs')]: {
      padding: '1.6em',
    },
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxWidth: 570,
    maxHeight: "100%",
    backgroundColor: 'transparent'
  },

  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },

  iconStyle: {
    padding: 0,
    color: '#535D6D',
  },

  formControl: {
    width: '100%',
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },

  buttonBoxOk: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },

  btn: {
    width: 160,
    fontFamily: "Montserrat",
    fontSize: '1.6em',
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "500",
    letterSpacing: '0.15px',
    textAlign: "center",
    margin: '5px 20px 35px 20px',
    borderBottom: '1px solid #d7dadc',
    paddingBottom: '17px',
    [theme.breakpoints.down('xs')]: {
      margin: '5px 10px 35px 10px',
    },
  },

  subTitle: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    textAlign: "justify",
    marginBottom: 20,
  },

  menuItem: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    borderBottom: 'solid 1px #ddd',
  },

  body: {
    width: '100%',
  },

  errMsg: {
    color: "red",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    textAlign: "center",
  },

  successMsg: {
    color: "green",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    textAlign: "center",
  }


}));

