import { Box, Button } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Dialog from '@material-ui/core/Dialog';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Typography } from 'antd';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import {
  deleteAccessTokenID, userLogoutState,deleteAccessTokenIDAdmin,insertActivityLog
} from '../../actions';
import logout from '../../assets/SignIn/logout.png';
import { signOut } from '../../helpers';
import { getUserType, getAppSource} from '../../helpers';

import { signOutAdmin } from '../../helpers/adminHelpers';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';


export const LogoutPopup = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [popup, showPopup] = useState(false);

  const [seconds, setSeconds] = useState(0);
  const popupShowCounter = 120;// popup will display counter from 120 sec to 0
  let interval;
  useEffect(() => {
    interval = setInterval(() => {
      if (seconds === 1) {
        if (props.isAdmin) {
          deleteAccessTokenIDAdmin();
          signOutAdmin(() => history.push('/'))
        }
        else {
          handleLogOut(true);
        }
        clearInterval(interval);
        setSeconds(0);
      }
      else if (seconds === 0) {
        clearInterval(interval);
        // Counter has reached 0
      } else {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);


  const logOutCall = () =>{
    if(!props.isAdmin){
      sendActivityData('Logout');
      }
    logOut();
  }
  const logOut = () => {
    if (props.isAdmin) {
      deleteAccessTokenIDAdmin();
      signOutAdmin(() => history.push('/'))
    }
    else {
      handleLogOut();
    }
    clearInterval(interval);
    setSeconds(0)
  }

  const handleLogOut = async (fromIdleLogout) => {
    await deleteAccessTokenID();
    signOut(() => {
      Cookies.remove('clientName');
      history.push({ pathname: '/SignIn', state: { fromIdleLogout: fromIdleLogout } })
    })
    setTimeout(() => {
      dispatch(userLogoutState());
    }, 1000);
  }

  /**Chech user inactivity  */
  const handleOnActive = event => {
    console.log('user is active', event)
  }

  const onIdle = () => {
    showPopup(true);
    setSeconds(popupShowCounter);
  }

  const { getRemainingTime, getLastActiveTime, getTotalIdleTime } = useIdleTimer({
    timeout: 480000,//8 mins
    onIdle: onIdle,
    onActive: handleOnActive,
    debounce: 500,
  })
  const handleClose = (event, reason) => {
    showPopup(false);
    clearInterval(interval);
    setSeconds(0);
    if(!props.isAdmin){
    sendActivityData('Continue')
    }
  }
  const sendActivityData = async (buttonName) => {

    let remark = `${'Session Popup'} - ` + `User clicked on ${buttonName} button.`;
    if(props.isAdmin){
      remark = `${'Session Popup'} - ` + `Admin clicked on ${buttonName} button.`;
    }
    const data = {
      "accountNumber": null, //accounts.length == 1 ? props?.accountNumber : selectedLoan.accountNumber,
      "activityCode": "UAL203",
      "activityStatus": "Success",
      "action": buttonName,//(selectedItem?.documentCode == 'PF') ? 'Paid in full letter popup' : buttonName,
      "controller": `${'Session Popup'}`,
      "apiName": "inactiveSession/popup",
      "activityRemark":  remark,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertActivityLog(data);
  }

  return (<>
    <Dialog PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={handleClose} onBackdropClick={handleClose} open={popup}>
      <Box className={classes.root}>
        <Box className={classes.cardBody}>
          <img src={logout} />
          <Typography className={classes.idleText}>You Have Been Idle!</Typography>
          <Typography className={classes.idleTextInner}>Your session is about to expire in</Typography>
          <Typography className={classes.idleTextTimer}> {seconds} seconds</Typography>
          <Typography className={classes.idleTextInner}>Click "Continue" to keep working or "Logout" to end your current session.</Typography>
        </Box>
        <Box className={classes.cardFooter}>
          <Box className={classes.footerItem}>
            <Button
              variant='outlined'
              className={classes.btn}
              onClick={logOutCall}
            > {'Logout'}</Button>
          </Box>

          <Box className={classes.footerItem}>
            <Button
              variant='contained'
              color='primary'
              className={classes.btn}
              onClick={handleClose}
            > {'Continue'}</Button>
          </Box>
        </Box>
      </Box>
    </Dialog>

  </>
  )
}
