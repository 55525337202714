import Cookies from 'js-cookie';
import { getAppSource, getUserType } from '../helpers'
import { axiosProtected } from '../config';

export const getOneTimeACHPaymentDetails = async (accNo, PropertyState) => {
  const data = {
    "accountNumber": accNo,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "PropertyState": PropertyState,
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  };
  try {
    const response = await axiosProtected.post('/Payment/GetOneTimeACHPaymentDetails', data);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      return error.response.data;
    }
    return error
  }
}

export const getUpdatedProcessFee = async (data) => {
  try {
    const response = await axiosProtected.post('/Payment/GetCalculateProcessingFee', data);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      return error.response.data;
    }
    return error
  }
}

export const getDraftOneTimePayment = async (data) => {
  try {
    const response = await axiosProtected.post('/Payment/DraftOneTimePayment', data);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      return error.response.data;
    }
    return error
  }
}

// routing no
export const getBankDetails = async (data) => {
  try {
    const response = await axiosProtected.post('/Payment/GetBankDetails', data);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      return error.response.data;
    }
    return error
  }
}

export const getMLOneTimeACHPaymentDetails = async (accNo) => {
  const data = {
    "accountNumber": accNo,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  };
  try {
    const response = await axiosProtected.post('Payment/GetMLOneTimeACHPaymentDetails', data);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      return error.response.data;
    }
    return error
  }
}

export const postMLOneTimeACHPaymentDetails = async (list) => {
  const data = {
    "lstMLOneTimePaymentModel": list,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": "",
  };

  try {
    const response = await axiosProtected.post('Payment/DraftMLOneTimeACHPaymentDetails', data);
    return response.data;
  } catch (e) {
    return null
  }
}