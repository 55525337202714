import signinIcon from '../../assets/SignIn/SignIn.svg';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1000,
    borderRadius: 12,
    backgroundColor: '#EAECED',
    position: 'relative',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    overflowY: 'auto',
    padding: '2em 0.5em'
  },
 
  dialogStyle: {
    borderRadius: '12px !important',
    maxWidth: '100%',
    maxHeight: "100%",
    backgroundColor: 'transparent',
  },

  formContainer: {
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 5.5em',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        padding: '1.6em',
      },
  },

  title: {
    fontSize: '2.5em',
    fontWeight: '600',
    lineHeight: 1,
    textAlign: 'center',
    marginBottom: 15,
    marginTop: 0,
    fontFamily: 'Montserrat',
    color: "#535D6D",
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.4em',
    },
  },

  subTitle: {
    color: '#000000',
    fontSize: 15,
    fontWeight: '600',
    lineHeight: 1,
    textAlign: 'center',
    marginBottom: 15,
    marginTop: 0,
    fontFamily: 'Montserrat',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },

  inputContainer: {
    marginBottom: '1.9em',
  },

  inputStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600"
  },

  labelStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600",
  },

  signInBtn: {
    fontFamily: "Montserrat",
    marginBottom: 20,
    fontSize: 16,
    fontWeight: '600',
  },

  linkStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    fontWeight: "600",
    letterSpacing: "0.47px",
    display: 'block',
    textAlign: 'right',
    display: 'inline-block',
    paddingTop: 5,
  },

  orContainer: {
    width: '100%',
    borderTop: 'solid 1px #979797',
    position: 'relative',
    marginTop: 10,
  },

  orWraper: {
    width: 30,
    height: 30,
    position: 'absolute',
    top: -16,
    left: '45%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  orText: {
    color: "#727272",
    fontFamily: "Montserrat",
    fontSize: "1.4em",
    fontWeight: "500",
    margin: 0,
  },

  otherLinks: {
    marginTop: 20,
  },

  otherLinksText: {
    color: "#A0A0A0",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    fontWeight: "500",
    textAlign: 'center',
    marginTop: 0,
    marginBottom: 20,
  },

  otherLinkStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: '1em',
    fontWeight: "600",
    letterSpacing: "0.47px",
    textTransform: 'uppercase',
  },

  errorMsg: {
    fontSize: '1.4em',
    fontWeight: '500',
    textAlign: 'center',
    marginBottom: 10,
    margin: 'auto',
    fontFamily: 'Montserrat',
    color: "red",
    width: "520px",
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },

  successMsg: {
    color: "#198100",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "600",
    letterSpacing: "0.53px",
    lineHeight: "18px",
    marginBottom: 20
  },

  resendLinkLoader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '5px',
  },

  usernameBtn: {
    fontFamily: "Montserrat",
    marginBottom: 10,
    marginTop: 10,
    fontSize: 12,
    fontWeight: '600',
    color: '#535D6D !important',
    border: 'solid 2px',
    borderColor: theme.palette.primary.main,
    boxShadow: "0px 4px 8px 0 rgba(0,0,0,0.14)",
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },

  otpBtn: {
    fontFamily: "Montserrat",
    marginBottom: 10,
    marginTop: 10,
    fontSize: 12,
    fontWeight: '600',
  },

  note: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: "600",
    letterSpacing: "0.47px",
    marginBottom: 40
  },
  signinContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 20,
    width: '33%',
    [theme.breakpoints.down('sm')]: {
      width: '50%'
    },
  },

  radioStyle: {
    padding: '0 5px',
    color: "#7B8390",
    '&.Mui-checked': {
      color: "#555D6C !important",
    }
  },

  activeRadioLabel: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "bold",
  },

  radioLabel: {
    color: "rgba(0,0,0,0.74)",
    fontFamily: "Montserrat",
    fontSize: " 12px",
    fontWeight: "500",
  },
  details: {
    textDecoration: "underline", 
    cursor: 'pointer',
    color: "#007FFF !important", 
    fontWeight: 700
  },
  propertyAdd: {
    maxWidth: '265px !important',
    wordBreak: 'break-all',
    textAlign: 'justify'
  }
}));

export const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: "#cccccc",
    color: '#202020',
    // borderRight: 'solid 1px #fff',
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: 600
  },
  body: {
    fontSize: 12,
    fontFamily: "Montserrat",
    color: '#202020',
    // fontWeight: 600,
   
  },
  root : {
    padding: 6
  }
}))(TableCell);



export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      // backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);