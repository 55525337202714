import React, { useEffect, useState, useRef } from 'react';
import { useStyles, StyledTableCell, ReportTooltip } from './styles';
import {
  Paper, Box, FormControlLabel, Switch, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@material-ui/core';
import Chart from "chart.js";
import moment from 'moment'
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

export const SargentWebServiceCalls = ({ chartData, toggleFullScreen, chartId }) => {

  const classes = useStyles();
  const [serviceCalls, setServiceCalls] = useState(false);
  const [chartInstance, setChartInstance] = useState();
  const webServiceRef = useRef(null);

  const handleChange = (event) => {
    setServiceCalls(event.target.checked);
  };

  const makeConfig = ({ data, labels }) => {
    const config = {
      type: 'line',
      data: {
        labels: [...labels],
        datasets: data.map(item => ({ ...item, fill: false }))
      }
    }
    if (chartInstance) {
      chartInstance.config = config;
      chartInstance.update();
    }
  }

  useEffect(() => {
    if (webServiceRef) {
      const ctx = webServiceRef.current;
      const chartInstance = new Chart(ctx, null);
      setChartInstance(chartInstance)
    }
  }, []);

  useEffect(() => {
    if (chartInstance && chartData) {
      makeConfig(chartData)
    }
  }, [chartData]);



  return (
    <Paper className={classes.root}>
      <Box className={classes.flexBetween}>
        <p className={classes.title}>Sagent Web Service Calls</p>
        {chartData && chartData.labels && <Box className={classes.actionBtn}>
          <ReportTooltip title="Grid View">
            <FormControlLabel
              control={
                <Switch
                  checked={serviceCalls}
                  onChange={handleChange}
                  name="serviceCalls"
                  color="primary"
                />
              }
            // label="Data"
            />
          </ReportTooltip>
          {chartId === 'swsCallsFS' ? (<ReportTooltip title="Exit FullScreen">
            <span className={classes.link} onClick={toggleFullScreen}><FullscreenExitIcon /></span>
          </ReportTooltip>) : (<ReportTooltip title="FullScreen View">
            <span className={classes.link} onClick={toggleFullScreen}>
              <FullscreenIcon /></span></ReportTooltip>)}
        </Box>}
      </Box>
      <Box className={classes.canvasContainer}>
        <canvas id={chartId} ref={webServiceRef}></canvas>
      </Box>
      {serviceCalls && <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <StyledTableCell className={classes.borderCell}>Date</StyledTableCell>
              <StyledTableCell className={classes.borderCell}>Total Sagent Call</StyledTableCell>
              <StyledTableCell className={classes.borderCell} align="center">Total Success call</StyledTableCell>
              <StyledTableCell className={classes.nonBorderCell} align="center">Total Failed call</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chartData && chartData.gridData.map((row, i) => (
              <TableRow key={`${row.createdDate}${i}`}>
                <TableCell lassName={classes.nonBorderCell} >{row.createdDate}</TableCell>
                <TableCell lassName={classes.nonBorderCell} align="center">{row.totalSagentCall}</TableCell>
                <TableCell lassName={classes.nonBorderCell} align="center">{row.totalSuccessCall}</TableCell>
                <TableCell lassName={classes.nonBorderCell} align="center">{row.totalFailedCall}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>}
    </Paper>
  )
}