import React, { useEffect, useState } from 'react';
import {
  Box, TextField, FormControl,
  FormLabel, RadioGroup, FormControlLabel, Radio, Typography, TextareaAutosize,
} from '@material-ui/core';
import { useStyles } from './styles';
import { CustomCheckBox } from '../CustomCheckBox'
import { NextPrevButton } from '../NextPrevButton'
import { SaveAndExitPopup } from '../SaveAndExitPopup';
import { useHistory } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider,KeyboardTimePicker, KeyboardDatePicker} from '@material-ui/pickers';

export const SectionB = (props) => {
  const { parentStyle } = props
  const classes = useStyles();  
  const history = useHistory();
  const [formErrorMap, setFormErrorMap] = useState([])
  const nullCheck = (str) => str ? str : ''
  const [bFormData, setBFormData] = useState({
    isUpdated:false,
    describeYourHardship: "",
    situationBeganDate: "",
    mySituation: "",
    checkboxA: false,
    checkboxB: false,
    checkboxC: false,
    checkboxD: false,
    checkboxE: false,
    checkboxF: false,
    checkboxG: false,
    checkboxH: false,
    checkboxI: false,
    checkboxJ: false,
    checkboxK: false,
    checkboxOther:false,
    checkboxOtherText:"",
  })

  useEffect(() => {
    if (props.data) {
      const { questions } = props.data
      const { question1, question2, question3, question4 } = questions;

      setBFormData({
        describeYourHardship: nullCheck(question1.answer),
        situationBeganDate: nullCheck(question2.answer),
        mySituation: nullCheck(question3.answer),
        checkboxA: nullCheck(question4.answer1),
        checkboxB: nullCheck(question4.answer2),
        checkboxC: nullCheck(question4.answer3),
        checkboxD: nullCheck(question4.answer4),
        checkboxE: nullCheck(question4.answer5),
        checkboxF: nullCheck(question4.answer6),
        checkboxG: nullCheck(question4.answer7),
        checkboxH: nullCheck(question4.answer8),
        checkboxI: nullCheck(question4.answer9),
        checkboxJ: nullCheck(question4.answer10),
        checkboxK: nullCheck(question4.answer11),
        checkboxOther:nullCheck(question4.answer12),
        checkboxOtherText:nullCheck(question4.otherText),
        isUpdated:true
      })
    }
  }, [props.data])

  useEffect(()=>{
    if(bFormData.isUpdated){
      let formErrors = formValidationSectionB()
      setFormErrorMap(formErrors)
    }
  }, [bFormData.isUpdated])

  const handleChange = (item) => (checkedStatus) => {
    setBFormData({ ...bFormData, [item]: checkedStatus });
  }
  const handleChange2 = (event) => {
    setBFormData({ ...bFormData, [event.target.name]: event.target.value });
  };
  const handleDateChange = (inputName) => (date) => {
    setBFormData({ ...bFormData, [inputName]: date });
}
  const { describeYourHardship, situationBeganDate, mySituation,
    checkboxA, checkboxB, checkboxC, checkboxD, checkboxE, checkboxF, checkboxG, checkboxH,
    checkboxI, checkboxJ, checkboxK,checkboxOther, checkboxOtherText } = bFormData
  const handleSave = (e) => {
    // const { describeYourHardship, situationBeganDate, mySituation,
    //   checkboxA, checkboxB, checkboxC, checkboxD, checkboxE, checkboxF, checkboxG, checkboxH,
    //   checkboxI, checkboxJ, checkboxK,checkboxOther, checkboxOtherText } = bFormData

    let formErrors = formValidationSectionB()
    let formErrorLength = Object.keys(formErrors).length
    let sectionCompleteStatus = formErrorLength>0? "incomplete" : "complete"
    props.status({id:1,sectionName:"section_B", status:sectionCompleteStatus})

    const data = {
      "section_B": {
        "questions": {
          "question1": {
            "questionText": "Describe Your Hardship",
            "answer": describeYourHardship
          },
          "question2": {
            "questionText": "Date situation began is",
            "answer": situationBeganDate
          },
          "question3": {
            "questionText": "I believe my situation is",
            "answer": mySituation
          },
          "question4": {
            "questionText": "I am having difficulty making my monthly payment because of reasons set forth below",
            "answer1": checkboxA,
            "answer2": checkboxB,
            "answer3": checkboxC,
            "answer4": checkboxD,
            "answer5": checkboxE,
            "answer6": checkboxF,
            "answer7": checkboxG,
            "answer8": checkboxH,
            "answer9": checkboxI,
            "answer10": checkboxJ,
            "answer11": checkboxK,
            "answer12":checkboxOther,
            "otherText":checkboxOther ? checkboxOtherText :''
          },

        },
        "sectionStatus":{
          "status":sectionCompleteStatus,
          "sectionName":"Section B:Required Documentation/ Hardship Affidavit"
          },
      }
    }

  
    props.saveFromParent(data)
  }

  const saveAndExitAction = (action) => {
    if (action === "leave") {
      handleSave()
      history.push('/Dashboard')
    }
  }
  const isCheckBoxStatus = (status) => {
    if (status === "true" || status === true) {
      return true
    } else {
      return false
    }
  }
/*Error handler*/

const isEmpty=(str)=>str?false:true
  
const formValidationSectionB = ()=>{  
  const formErrorMessage={}

  if(isEmpty(describeYourHardship)){
    formErrorMessage["describeYourHardship"]="This is required field"
  }
  if(isEmpty(situationBeganDate)){
    formErrorMessage["situationBeganDate"]="This is required field"
  }
  if(isEmpty(mySituation)){
    formErrorMessage["mySituation"]="This is required field"
  }
  if(isCheckBoxStatus(checkboxOther)===true){
    if(isEmpty(checkboxOtherText)){
      formErrorMessage["checkboxOtherText"]="This is required field"
    }
  }
  
  return formErrorMessage;
}

const errorHandler = (field) => {
  if (formErrorMap[field]) {
    let message = formErrorMap[field]==="invalid"?"Invalid Data, Please check the value" :"*"
    return message
  } else {
    return false
  }
}
/*Error handler end*/
  return (
    <Box container="true" maxWidth='xl' className={classes.root}>
      <Box style={{ marginTop: "-85px" }}>
        <SaveAndExitPopup actionCallBack={saveAndExitAction}></SaveAndExitPopup>
      </Box>
      <Box height="90%" style={{ paddingTop: "25px" }}>
        <Box className={classes.inputBox}>
          <Typography className={parentStyle.title}>Required Documentation/ Hardship Affidavit </Typography>
          <Typography className={parentStyle.questionText}>Describe Your Hardship 
          <span className={parentStyle.errorMessage}> {errorHandler('describeYourHardship')}</span></Typography>
          <TextareaAutosize
            rowsMin={3}
            onChange={handleChange2}
            value={bFormData.describeYourHardship}
            name="describeYourHardship"
            //label=""
            color='secondary'
            variant="outlined"
            className={parentStyle.inputTextTextarea}
            // className={classes.inputFieldFullWidth}
            // InputProps={{
            //   classes: { root: classes.inputText }
            // }}
            //fullWidth
          />
        </Box>
        <Box>
          <Typography className={parentStyle.questionText}>Date situation began is </Typography>
          {/* <TextField
            size="small"
            error={errorHandler('situationBeganDate')!==false}
            helperText={errorHandler('situationBeganDate')}
            onChange={handleChange2}
            value={bFormData.situationBeganDate}
            name="situationBeganDate"
            //label="Date situation began is"
            color='secondary'
            variant="outlined"
            className={parentStyle.inputFieldDate}
            InputProps={{
              classes: { root: parentStyle.inputText }
            }}
            fullWidth
          /> */}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  views={['year', 'month', 'date']}
                  //disabled={isRestrict}
                  size="small"
                  error={errorHandler('situationBeganDate')!==false}
                  helperText={errorHandler('situationBeganDate')}
                  color='secondary' autoOk={true}
                  //disableToolbar
                  //minDate={new Date()}
                  variant="inline" inputVariant="outlined" format="MM/dd/yyyy" fullWidth

                  id="id-situationBeganDate"
                 // label="Date of Birth"
                  value={bFormData.situationBeganDate || null}
                  name="situationBeganDate"
                  onChange={handleDateChange("situationBeganDate")}

                  KeyboardButtonProps={{'aria-label': 'change date',}}
                  placeholder={"MM/dd/yyyy"}
                  className={parentStyle.inputFieldDate}
                  InputProps={{ classes: { root: parentStyle.inputText }}}
                  InputLabelProps={{shrink: true,}}
                />
              </MuiPickersUtilsProvider>
        </Box>

        <FormControl component="fieldset" className={parentStyle.formControl}>
          <FormLabel component="legend" className={parentStyle.formLabel}>I believe my situation is <span className={parentStyle.errorMessage}> {errorHandler('mySituation')}</span></FormLabel>
          <RadioGroup name="mySituation" value={bFormData.mySituation} onChange={handleChange2}>
            <FormControlLabel className={parentStyle.labelStyle} value="Short-term (under 6 months)" control={<Radio />} label="Short-term (under 6 months)" />
            <FormControlLabel className={parentStyle.labelStyle} value="Medium-term (6-12 months)" control={<Radio />} label="Medium-term (6-12 months)" />
            <FormControlLabel className={parentStyle.labelStyle} value="Long-term or permanent(greater than 12 months)" control={<Radio />} label="Long-term or permanent(greater than 12 months)" />
          </RadioGroup>
        </FormControl>

        <Typography className={parentStyle.questionText}>I am having difficulty making my monthly payment because of reasons set forth below</Typography>
        <Box pl={1}>
          <Box>
            <CustomCheckBox
              labelText={"Unemployed"}
              status={isCheckBoxStatus(bFormData.checkboxA)}
              checkboxName={"checkboxA"}
              handleCheckbox={handleChange("checkboxA")}></CustomCheckBox>

            {isCheckBoxStatus(bFormData.checkboxA) ? (<Box className={classes.detailContainer} p={2} ml={4}>
              <Typography className={classes.detailText}><b>Document Required:</b> A copy of your benefits
                    statement or letter detailing the amount, frequency and duration of your unemployment benefits.</Typography>
            </Box>) : null}
          </Box>

          <Box>
            <CustomCheckBox
              labelText={"Underemployment"}
              status={isCheckBoxStatus(bFormData.checkboxB)}
              checkboxName={"checkboxB"}
              handleCheckbox={handleChange("checkboxB")}></CustomCheckBox>

            {isCheckBoxStatus(bFormData.checkboxB) ? (<Box className={classes.detailContainer} p={2} ml={4}>
              <Typography className={classes.detailText}>No hardship documentation required, as long as you have submitted
the income documentation that supports the income</Typography>
            </Box>) : null}
          </Box>

          <Box>
            <CustomCheckBox
              labelText={`Incomereduction(e.g.,eliminationofovertime,reductioninregular
                  working hours,or areductionin base pay)`}
              status={isCheckBoxStatus(bFormData.checkboxC)}
              checkboxName={"checkboxC"}
              handleCheckbox={handleChange("checkboxC")}></CustomCheckBox>

            {isCheckBoxStatus(bFormData.checkboxC) ? (<Box className={classes.detailContainer} p={2} ml={4}>
              <Typography className={classes.detailText}>No hardship documentation required, as long as you have submitted
the income documentation that supports the income</Typography>
            </Box>) : null}
          </Box>

          <Box>
            <CustomCheckBox
              labelText={`Divorceorlegalseparation;separationofborrowers unrelatedby
                marriage, civil union or similar domestic partnership under applicable
                law`}
              status={isCheckBoxStatus(bFormData.checkboxD)}
              checkboxName={"checkboxD"}
              handleCheckbox={handleChange("checkboxD")}></CustomCheckBox>

            {isCheckBoxStatus(bFormData.checkboxD) ? (<Box className={classes.detailContainer} p={2} ml={4}>
              <Typography className={classes.detailText}>Divorce decree signed by thecourt OR</Typography>
              <Typography className={classes.detailText}>Separation agreement signed by the court OR</Typography>
              <Typography className={classes.detailText}>Current credit report evidencing divorce, separation, or non-occupying
Borrower hasadifferent address OR</Typography>
              <Typography className={classes.detailText}>Recorded quit claim deed evidencing that the non-occupying borrower
or co-borrower has relinquished all rights to the property</Typography>
            </Box>) : null}
          </Box>

          <Box>
            <CustomCheckBox
              labelText={`Death of a borrower or death of either the primaryorsecondary
                wage earner in the household or dependent family member`}
              status={isCheckBoxStatus(bFormData.checkboxE)}
              checkboxName={"checkboxE"}
              handleCheckbox={handleChange("checkboxE")}></CustomCheckBox>

            {isCheckBoxStatus(bFormData.checkboxE) ? (<Box className={classes.detailContainer} p={2} ml={4}>
              <Typography className={classes.detailText}>Copy of the Death certificate and at least one additional acceptable document</Typography>
              <Typography className={classes.detailText}>SEE page 2 of the application instructions for all acceptable documents</Typography>
            </Box>) : null}
          </Box>

          <Box>
            <CustomCheckBox
              labelText={`Long-term or permanent disability; serious illness of a borrower/ coborrower or 
                dependent family member`}
              status={isCheckBoxStatus(bFormData.checkboxF)}
              checkboxName={"checkboxF"}
              handleCheckbox={handleChange("checkboxF")}></CustomCheckBox>

            {isCheckBoxStatus(bFormData.checkboxF) ? (<Box className={classes.detailContainer} p={2} ml={4}>
              <Typography className={classes.subTitle2}>Do not providemedical records or any details of your illness or disability</Typography>
              <Typography className={classes.detailText}>Written statement from you or other documentation verifying
disability or illness OR</Typography>
              <Typography className={classes.detailText}>Proof of monthly insurance benefits or government
assistance (with expiration date, if applicable)</Typography>
            </Box>) : null}
          </Box>

          <Box>
            <CustomCheckBox
              labelText={`Disaster(naturalorman-made) adversely impacting the property or borrower’s place of employment`}
              status={isCheckBoxStatus(bFormData.checkboxG)}
              checkboxName={"checkboxG"}
              handleCheckbox={handleChange("checkboxG")}></CustomCheckBox>

            {isCheckBoxStatus(bFormData.checkboxG) ? (<Box className={classes.detailContainer} p={2} ml={4}>
              <Typography className={classes.detailText}>Insurance claimOR</Typography>
              <Typography className={classes.detailText}>Federal Emergency Management Agency grantor Small Business
Administration loan OR</Typography>
              <Typography className={classes.detailText}>Borrower or employer property located in a federally declared disaster area</Typography>
            </Box>) : null}
          </Box>

          <Box>
            <CustomCheckBox
              labelText={`Distant employment transfer`}
              status={isCheckBoxStatus(bFormData.checkboxH)}
              checkboxName={"checkboxH"}
              handleCheckbox={handleChange("checkboxH")}></CustomCheckBox>

            {isCheckBoxStatus(bFormData.checkboxH) ? (<Box className={classes.detailContainer} p={2} ml={4}>
              <Typography className={classes.detailText}>Proof of transferOR</Typography>
              <Typography className={classes.detailText}>Military Permanent Change of Station (PCS)</Typography>
            </Box>) : null}
          </Box>

          <Box>
            <CustomCheckBox
              labelText={` Excessive obligations`}
              status={isCheckBoxStatus(bFormData.checkboxI)}
              checkboxName={"checkboxI"}
              handleCheckbox={handleChange("checkboxI")}></CustomCheckBox>

            {isCheckBoxStatus(bFormData.checkboxI) ? (<Box className={classes.detailContainer} p={2} ml={4}>
              <Typography className={classes.detailText}>No hardship documentation required, as long as you have submitted
the income documentation that supports the income</Typography>
            </Box>) : null}
          </Box>

          <Box>
            <CustomCheckBox
              labelText={`Business failure`}
              status={isCheckBoxStatus(bFormData.checkboxJ)}
              checkboxName={"checkboxJ"}
              handleCheckbox={handleChange("checkboxJ")}></CustomCheckBox>

            {isCheckBoxStatus(bFormData.checkboxJ) ? (<Box className={classes.detailContainer} p={2} ml={4}>
              <Typography className={classes.detailText}>Taxreturnfromthe previousyear(includingallschedules)AND</Typography>
              <Typography className={classes.detailText}>Proof ofbusiness failure supported by oneofthefollowing</Typography>
              <Typography className={classes.detailText}>Bankruptcy filing for the business; or</Typography>
              <Typography className={classes.detailText}>Two months recent bank statements for the business
account evidencing cessation of business activity; or</Typography>
              <Typography className={classes.detailText}>Most recent signed and dated quarterly or year-to-date profit
and loss statement</Typography>

            </Box>) : null}
          </Box>

          <Box>
            <CustomCheckBox
              labelText={`Payment increase`}
              status={isCheckBoxStatus(bFormData.checkboxK)}
              checkboxName={"checkboxK"}
              handleCheckbox={handleChange("checkboxK")}></CustomCheckBox>

            {isCheckBoxStatus(bFormData.checkboxK) ? (<Box className={classes.detailContainer} p={2} ml={4}>
              <Typography className={classes.detailText}>No hardship documentation required, as long as you have submitted
the income documentation that supports the income</Typography>
            </Box>) : null}
          </Box>

          <Box>
            <CustomCheckBox
              labelText={`Other`}
              status={isCheckBoxStatus(bFormData.checkboxOther)}
              checkboxName={"checkboxOther"}
              handleCheckbox={handleChange("checkboxOther")}></CustomCheckBox>
               {isCheckBoxStatus(bFormData.checkboxOther) ? (<Box className={classes.detailContainer} p={2} ml={4}>
              <Typography className={classes.detailText}>If you have income from rental properties that are not your principal residence, you must provide a copyof the current lease agreement
with bank statements showing deposit of rent checks.</Typography>
            </Box>) : null}

            {isCheckBoxStatus(bFormData.checkboxOther) ? (<Box mt={2}  ml={4}>
              <TextField
              size="small"
              error={errorHandler('checkboxOtherText')!==false}
              helperText={errorHandler('checkboxOtherText')}
              onChange={handleChange2}
              value={bFormData.checkboxOtherText}
              name="checkboxOtherText"
              label="Other Text"
              color='secondary'
              variant="outlined"
              className={parentStyle.inputField}
              InputProps={{
                classes: { root: parentStyle.inputText }
              }}
            />
            </Box>) : null}

           

          </Box>


        </Box>
      </Box>

      <NextPrevButton
        handleNextCallback={handleSave}
        handlePrevCallBack={props.previous}
        activeStepIndex={props.activeSection}></NextPrevButton>
    </Box>

  )
}