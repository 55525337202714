import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',         
  },
  subTitle:{  
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: "600",
    letterSpacing: "0.63px",
    lineHeight: "29px",
  },
  

  tableTitle:{     
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "16px",
    letterSpacing: "0.44px",
    lineHeight: "24px",
  },
  addMore:{     
    color: "#15ABC9",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "bold",
    letterSpacing: "0.36px",
    lineHeight: "19px",
    cursor: "pointer", 
    border:"1px solid",
    padding:"5px",
    margin:"10px",
    borderRadius: "4px"
  },
  removeBtn:{
    color:"red",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "bold",
    letterSpacing: "0.36px",
    lineHeight: "19px",
    cursor: "pointer", 
    border:"1px solid",
    padding:"5px",
    margin:"10px",
    borderRadius: "4px"    
  }
}));

