import React, { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { useStyles } from './styles';
import { getHelpContent } from '../../actions';
import { scrollTop } from '../../helpers';
import './style.css';

export const RefinanceMortgageFaq = React.memo(() => {
  const classes = useStyles();
  const history = useHistory();
  const mytheme = useSelector(state => state.theme);
  const [ansData, setAnsData] = useState('');
  const { primaryColorCode, secondaryColorCode } = mytheme;
  const dispatch = useDispatch();

  useEffect(() => {
    getData();
    scrollTop();
  }, []);

  useEffect(() => {
    faqListAccordion();
  })

  const getData = async () => {
    const res = await dispatch(getHelpContent('PC014'));
    if (res && res.lstPageContentDetails && res.lstPageContentDetails.length) {
      const ans = res.lstPageContentDetails.filter((item) => {
        return item.contentKey.toLowerCase() === 'mortgagefaqs';
      });
      setAnsData(ans[0])
    } else {
      setAnsData('')
    }
  }

  const faqListAccordion = () => {
    let acc = document.getElementsByClassName('accordion-set');
    let accInnerContent = Array.from(document.querySelectorAll('.inner-content'));
    let allFaIcon = Array.from(document.querySelectorAll('.header-arrow'));
    let i;

    for (i = 0; i < acc.length; i++) {
      let anchor = acc[i].querySelector('a');
      let panel = acc[i].querySelector('.inner-content');
      let faIcon = acc[i].querySelector('.header-arrow');
      anchor.addEventListener("click", function (e) {
        e.preventDefault();
        if (panel.style.display === "block") {
          panel.style.display = "none";
          if (faIcon !== null) {
            faIcon.classList.add("faq-arrow-down");
            faIcon.classList.remove("faq-arrow-up");
          }

        } else {
          accInnerContent.forEach(node => {
            node.style.display = "none";
          });
          allFaIcon.forEach(node => {
            node.classList.add("faq-arrow-down");
            node.classList.remove("faq-arrow-up");
          });
          panel.style.display = "block";
          if (faIcon !== null) {
            faIcon.classList.remove("faq-arrow-down");
            faIcon.classList.add("faq-arrow-up");
          }
        }
      });
    }
  }


  if (ansData !== '') {
    return (
      <Box className={classes.rootBg}>
        <Box className={classes.root} style={{ width: '100%' }}>
          <Box className={classes.html} style={{ width: '100%' }}>
            <div dangerouslySetInnerHTML={{ __html: ansData.contentValue }} />
          </Box>
        </Box>
      </Box>
    )
  } else {
    return (
      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
        <Box my={1} mx={2}>
          <CircularProgress color='primary' />
        </Box>
      </Box>
    )
  }
});
