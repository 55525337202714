
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import signinIcon from '../../assets/SignIn/SignIn.svg';

export const useStyles = makeStyles((theme) => (
  {
    root: {
      width: '100%',
      // minHeight: '100vh',
      backgroundColor: '#ccc',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: `url(${signinIcon})`,
      backgroundPosition: "bottom left",
      backgroundRepeat: "no-repeat",
      backgroundSize: 'contain',
    },
    container: {
      width: '90%',
      minWidth: "33.3em",
      marginBottom: '9em',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '5em',
      },
    },

    formContainer: {
      minHeight: "300px",
      backgroundColor: '#fff',
      borderRadius: 12,
      boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    instructionsContainer: {
      padding: "2.3em 7.9em 0 7.9em",
      [theme.breakpoints.down('sm')]: {
        padding: "1.6em 1.6em 0 1.6em"
      },
    },
    instText: {
      paddingLeft: 0,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: "0"
      },
    },

    breadcrumb: {
      color: "#7B8390",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "bold",
      letterSpacing: "0.36px",
      lineHeight: "19px",
    },

    activeBreadcrumb: {
      color: theme.palette.primary.main,
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "bold",
      letterSpacing: "0.36px",
      lineHeight: "19px",
    },
    headingText: {
      color: "#1A4664",
      fontFamily: "Montserrat",
      fontSize: "22px",
      fontWeight: "700",
      letterSpacing: "0.18px",
      lineHeight: "24px",
      marginTop: '24px',
      marginBottom: '24px'
    },

    subHeading: {
      color: "rgba(0,0,0,0.87)",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: "500",
      letterSpacing: "0.15px",
      lineHeight: "24px",
    },
    text: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "16px",
      letterSpacing: "0.36px",
      lineHeight: "19px",
    },
    accountContainer: {
      padding: "0 4em 0 7.9em",
      borderRight: "1px solid #dcdddf",
      borderTop: "1px solid #dcdddf",
      [theme.breakpoints.down('sm')]: {
        padding: "0 1.6em",
        borderRight: "none",
        width: "100%"
      },
    },
    accountEmail: {
      padding: "4em 2.3em 4em 0",
      [theme.breakpoints.down('sm')]: {
        padding: "2em 2.3em 2em 0",
      },

    },
    bodyText: {
      color: '#202020',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      letterSpacing: '0.36px',
      lineHeight: '19px',
    },
    userMail: {
      marginTop: '11px',
    },
    formInputContainer: {
      //    
    },
    inputContainer: {
      marginBottom: '3.9em',
      width: "75%",
      [theme.breakpoints.down('sm')]: {
        width: "100%"
      },
    },
    tncHeading: {
      color: "rgba(0,0,0,0.87)",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: "500",
      letterSpacing: "0.15px",
      lineHeight: "24px",
      borderBottom: '1px solid #dcdddf',
      paddingBottom: '10px',
      paddingTop: '0'
    },
    tncContainer: {
      borderRadius: "0 0 12px 0",
      backgroundColor: "#eaeced",
      padding: "2.5em",
      '& .MuiAlert-filledInfo': {
        backgroundColor: theme.palette.secondary.main,
        fontFamily: "Montserrat",
      },
      '& .MuiAlert-filledSuccess': {
        backgroundColor: theme.palette.secondary.main,
        fontFamily: "Montserrat",
      }
    },
    tncTextContainer: {
      boxSizing: "border-box",
      height: "162px",
      border: "1px solid rgba(156,156,156,0.5)",
      backgroundColor: "#FFFFFF",
      marginTop: "1em",
      paddingLeft: "1.6em",
      paddingRight: ".8em",
      paddingTop: "1.3em"
    },
    tncSummary: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "16px",
      letterSpacing: "0.36px",
      lineHeight: "19px",
      height: "115px",
      overflow: "auto",
      paddingRight: "1em",
    },
    smallText: {
      color: "rgba(0,0,0,0.87)",
      fontFamily: "Montserrat",
      fontSize: "14px",
      letterSpacing: "0.25px",
      lineHeight: "16px",
      paddingTop: '6px',
      paddingLeft: 0
    },
    btn: {
      color: theme.palette.primary.contrastText,
      width: "200px",
      height: "40px",
      fontSize: "16px",
      fontFamily: "Montserrat",
      fontWeight: "600",
      letterSpacing: "0.53px",
      lineHeight: "19px",
      textAlign: "center",
    },
    primaryColor: {
      backgroundColor: `${theme.primary}!important`,
    },
    mobileViewOnly: {
      display: "none",
      [theme.breakpoints.down('sm')]: {
        display: "block",
      },
    },
    desktopViewOnly: {
      display: "block",
      [theme.breakpoints.down('sm')]: {
        display: "none",
      },
    },

    linkStyles: {
      textDecoration: 'none',
      color: '#535D6D',
      fontWeight: '600',
      fontSize: "16px",
      fontFamily: "Montserrat",
      letterSpacing: '0.6px',
      lineHeight: '19px',
    },
    mt10: {
      marginTop: '13px'
    },
    printBtn: {
      padding: 0,
    },
    printBtnIcon: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: "600",
      cursor: 'pointer',
      color: '#535D6D'
    },
    textField: {
      color: 'rgba(0,0,0,0.87)',
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "600",
      letterSpacing: '0.15px',
      lineHeight: '20px',
    },
    textAreaField: {
      color: 'rgba(0,0,0,0.87)',
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: "600",
      letterSpacing: '0.15px',
      lineHeight: '20px',
    },
    activeRadioLabel: {
      fontWeight: "500",
    },
    radioLabel: {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: "500",
      cursor: 'pointer',
      color: 'rgba(0,0,0,0.87)',
      letterSpacing: '0.25px',
      lineHeight: '16px',
      '& .MuiRadio-colorSecondary.Mui-checked': {
        color: "#535D6D",
        fontWeight: "500",
      }
    },
    inputStyle: {
      color: "#535D6D",
      fontWeight: "500",
    },
    checkboxContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: '12px',
      '& .MuiCheckbox-colorSecondary.Mui-checked': {
        color: '#535D6D'
      }
    },
    infoIcon: {
      color: "#E02020",
      width: 20,
      height: 20,
      marginRight: 10,
    },

    infoBox: {
      width: '100%',
      alignItems: 'center',
      backgroundColor: '#FFF0F0',
      borderRadius: 4,
      padding: '0.5em',
      borderRadius: '4px',
      border: "1px solid #F85757",
      // marginLeft: 8
    },

    infoText: {
      color: "#121D22",
      fontFamily: "Montserrat",
      fontSize: '14px',
      fontWeight: "400",
    },
  }
));

export const CutomTextFieldEmail = withStyles((theme) => ({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
      height: 112,
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
}))(TextField);

export const CutomTextField = withStyles((theme) => ({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
}))(TextField);