import { FETCH_CONTENT } from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_CONTENT:
      return action.payload
    default:
      return state;
  }
}

