import React from 'react';
import { useStyles } from './styles';
import { Box, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { decrypt } from '../../helpers/encrypt';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { getBorrowerLabel, getLoanLabel, getMortgageLabel } from '../../helpers';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#535D6D",
    color: "#ffffff",
    fontSize: "16px",
    fontWeight: "bold",
    letterSpacing: "0.36px",
    lineHeight: "19px",
    fontFamily: "Montserrat",
    borderRight: "1px solid #ffffff",
    verticalAlign: "top"
  },
  body: {
    fontSize: 16,
    color: "#202020",
    letterSpacing: "0.36px",
    lineHeight: "19px",
    fontFamily: "Montserrat"
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export const IncorrectTinSsnAfterLogin = (props) => {
  const { details } = props;
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(true);
  const handleClose = () => setOpen(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleSubmit = () => history.replace({ pathname: "/UpdateSsn" });

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={handleClose} open={open}>
      <Box className={classes.root}>
        <IconButton onClick={handleClose} className={classes.closeIcon}>
          <CancelIcon />
        </IconButton>
          <Box className={classes.cardBody}>
          <Box className={classes.statusContainer}>
            <p className={classes.statusTitle}>
            As the servicer of your {getMortgageLabel()?.toLowerCase()} {getLoanLabel()?.toLowerCase()}, {details.privateLabelName} is required by the Internal Revenue Service (IRS) to obtain your correct Taxpayer Identification Number (TIN) to report {getMortgageLabel()?.toLowerCase()} interest you paid every tax year.</p>
            {/* <p className={classes.statusSubTitle}>Our records indicate that we do not have a valid EIN/ SSN for below borrowers. Please select “Submit” to update your information</p> */}
            <p className={classes.statusSubTitle}>Our records indicate that we don’t have a valid TIN for the below {getBorrowerLabel()?.toLowerCase() + 's'}/entities. Please enter the correct SSN(s)/EIN(s) and hit “submit” to correct this information.</p>
          </Box>
          <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell rowSpan={2} align="left">{getLoanLabel()} Number </StyledTableCell>
                <StyledTableCell rowSpan={2} align="left">Phone Number</StyledTableCell>
                <StyledTableCell rowSpan={2} align="left">Property Zip Code</StyledTableCell>
                <StyledTableCell rowSpan={2} align="left">{getBorrowerLabel()} Type</StyledTableCell>
                <StyledTableCell rowSpan={2} align="left">{getBorrowerLabel()} FullName</StyledTableCell>
                <StyledTableCell rowSpan={2} align="left">SSN</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { details.map((data, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="left">{decrypt(data.accountNumber)}</StyledTableCell>
                    <StyledTableCell align="left">{decrypt(data.phoneNumber)  == 0 ? '-' : decrypt(data.phoneNumber)}</StyledTableCell>
                    <StyledTableCell align="left">{decrypt(data.propertyZipCode)}</StyledTableCell>
                    <StyledTableCell align="left">{data.borrowerTypeName}</StyledTableCell>
                    <StyledTableCell align="left">{data.pBorrowerName}</StyledTableCell>
                    <StyledTableCell align="left">{decrypt(data.borrowerSSN)}</StyledTableCell>
                  </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Box>
        <Box className={classes.cardFooter}>
          <Box className={classes.footerItem}>
            <Button
              variant='contained'
              color='primary'
              className={classes.btn}
              onClick={() => handleClose()}
            >Cancel</Button>
          </Box>
          <Box className={classes.footerItem}>
            <Button
              variant='contained'
              color='primary'
              className={classes.btn}
              onClick={() => handleSubmit()}
              disabled={Cookies.get('adminToken') ? true : false}
            >Submit</Button>
          </Box>
        </Box>
      </Box> 
    </Dialog>
  )
}
