import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 12,
    backgroundColor: "#fff",
    position: 'relative',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    padding: '3em 6.8em',
    [theme.breakpoints.down('xs')]: {
      padding: '1.6em',
    },
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxWidth: 522,
    maxHeight: "100%",
    backgroundColor: 'transparent'
  },

  iconStyle: {
    color: '#535D6D',
    position: 'absolute',
    top: 7,
    right: 8,
  },

  body: {
    width: '100%',
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    letterSpacing: '0.15px',
    fontWeight: "500",
    textAlign: "center",
    borderBottom: '1px solid #e6e7e8',
    paddingBottom: 17,
    marginBottom: 42,
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      marginTop: 40,
    },
  },

  subTitle: {
    color: "#0C0B0B",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    marginBottom: 22,
  },

  smallText: {
    color: "#0C0B0B",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    textAlign: "center",
    marginBottom: 10,
  },

  mainContainer: {
    backgroundColor: '#e5f5f9',
    padding: 16,
    marginBottom: 20,
  },

  mainBoxTitle: {
    color: "#3064A6",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    textAlign: "center",
    fontWeight: 'bold',
    marginBottom: 20,
  },

  flexBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  item: {
    margin: 5,
  },

  itemText: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: '500',
  },

  itemSubText: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: 'bold',
  },

  buttonBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 12,
  },

  btn: {
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: '600',
  },

  warningText: {
    color: "#B00020",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  linkStyle: {
    fontFamily: "Montserrat",
    fontSize: '1em',
    color: '#535D6D',
    fontWeight: '600',
    letterSpacing: '0.6px',
  }


}));

