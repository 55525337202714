import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Box, Button } from '@material-ui/core';

class ErrorBoundary extends React.Component {

  state = {
    hasError: false,
    error: null,
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error: error };
  }

  //Error reporting
  // componentDidCatch(error, errorInfo) {
  //   console.log(error, errorInfo)
  // }

  handleRefresh = () => {
    window.location.reload()
  }

  render() {

    const { classes } = this.props;
    if (this.state.hasError) {
      return (
        <Box className={classes.root}>
          <Box className={classes.container}>
            <ErrorOutlineIcon classes={{ root: classes.iconStyle }} />
            <h1>Something went wrong.</h1>
            <Button
              className={classes.button}
              color='default'
              variant='contained'
              onClick={this.handleRefresh}
            >
              Refresh the page
            </Button>
          </Box>
        </Box>
      )
    }

    return this.props.children;
  }
}

export default withStyles(styles, { withTheme: true })(ErrorBoundary);