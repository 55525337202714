import React, { useState } from 'react';

import {
  Box, Button, IconButton,
  InputAdornment, FormControl, CssBaseline, CircularProgress, 
  TextField, OutlinedInput, InputLabel, Typography
} from '@material-ui/core';

import { makeStyles, withStyles } from '@material-ui/core/styles';



const CutomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#25ABC7',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#25ABC7',
      },
    },
  },
})(TextField);

const CutomOutlinedInput = withStyles({
  root: {
    "&$focused $notchedOutline": {
      border: `2px solid #25abc7`
    }
  },
  focused: {},
  notchedOutline: {}
})(OutlinedInput);

const CustomInputLabel = withStyles({
  root: {
    "&$focused": {
      color: "#25abc7",
    }
  },
  focused: {},
})(InputLabel);

export const CustomizedTextInput = (props) => {
  //const classes = useStyles();
  //console.log(props)

  const { inputType, inputName, inputValue, inputLabel, onChnageValue,qType } = props

  const handleChange = (inputName, qt) => (event) => {
    console.log(inputName, "====inputName====", event.target.value)
    onChnageValue({qType:qt,qId:event.target.name,answer:event.target.value})    
  }

  return (<Box>
    <Typography>{inputLabel}</Typography>
    <CutomTextField
      id={`outlined-${inputName}`}
      name={inputName}
      label=""//{inputLabel?inputLabel:'Input Field'}
      variant="outlined"
      type={inputType ? inputType : "text"}
      value={inputValue}
      onChange={handleChange(inputName,qType)}
      fullWidth
      inputProps={{
        //className: classes.inputStyle,
        //maxLength: 10,
      }}

    /></Box>
  )
}