import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 700,
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    position: 'relative',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    '& .MuiIconButton-root': {
      padding: 4
    },
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxWidth: '75%',
    maxHeight: "100%",
    backgroundColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%'
    }
  },

  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "400",
    textAlign: 'center',
    margin: 16,
  },

  cardBody: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '30px 4em 20px 4em',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },

  statusContainer: {
    marginBottom: 10
  },

  statusBody: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "bold",
  },

  bodyText: {
    color: "#0C0B0B",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    marginBottom: 30,
    marginTop: 0,
  },

  btn: {
    width: 188,
    fontFamily: "Montserrat",
    fontSize: '1.5em',
    fontWeight: "600",
  },

  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },
 
  statusTitle: {
    color: "#555D6C",
    fontFamily: "Montserrat",
    fontSize: 22,
    fontWeight: "700",
    marginTop: 0,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      fontWeight: "600"
    },
  },

  paragraph: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: 18,
    fontWeight: "500",
    paddingLeft: 5,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15
    },  
  },
 
  statusSubTitle: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: 18,
    fontWeight: "500",
    lineHeight: "30px",
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    },
  },

  date: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: 18,
    fontWeight: "700",
    lineHeight: "30px",
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      fontWeight: "600"
    },
    '&.red':{
      color:'#E02020'
    }
  },

  hours: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: "400",
    lineHeight: "32px"
  },

  paymentMenu: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "500",
    marginBottom: 20,
    textAlign: 'center',
    marginTop: 22
  },

  continueBtn: {
    fontFamily: "Montserrat",
    marginBottom: 20,
    fontSize: '1.6em',
    fontWeight: '600',
    width: 280,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      width: 105,
      height: '30px'
    },
  },

  buttonContainer: {
    textAlign: 'center',
    margin: '0 10px'
  },
 
  skipBtn: {
    width: 280,
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "600",
    letterSpacing: "0.53px",
    lineHeight: "19px",
    textAlign: "center",
    border: "2px solid",
    height: '40px',
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
    borderColor: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      width: 105,
      fontSize: 14,
      height: '30px'
    },
  },
 
  titleBody: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '12px 2em',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  cardIcon: {
    width: 18,
    height: 18,
    color: "#007FFF",
    marginTop: 10
  },

  cardContent1: {
    display: 'flex',
    paddingLeft: 50,
    paddingRight: 50,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 5,
      paddingRight: 5
    }
  },
 
  cardContent2: {
    display: 'flex',
    marginBottom: 20,
    marginTop: 20
  },

  info: {
    color: "#4D4D4D",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: "500",
    fontStyle: 'italic',
    paddingLeft: 5
  },
  
  infoIcon: {
    width: 24,
    height: 24,
    color: "#F59720",
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      width: 22,
      height: 22
    },
  },

  radioBtn: {
    paddingLeft: 126,
    paddingRight: 120,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 15,
      paddingRight: 15
    },
  },

  cardTitleBody: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '12px 2em',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  labelText: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "700",
  }
}));

