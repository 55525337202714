import React, { useState, useEffect } from 'react';
import {
  Box, Button,CssBaseline} from '@material-ui/core';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from "@okta/okta-react";
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

export const Admin = () => {

  const classes = useStyles();
  const history = useHistory();
  const { authState, oktaAuth } = useOktaAuth();
  const [errorMsg, setErrorMsg] = useState(Cookies.get("errorStatus"))
  const [loading, setLoading] = useState(false)

  const location = useLocation();
  const issuer = location.search.includes('?iss=')

  useEffect(() => {
    if (issuer) {
      oktaAuth.signInWithRedirect({ originalUri: "/signin" });
    } else {
      setLoading(true)
    }
  },[issuer])

  const handleNavigation = () => {
    Cookies.set("errorStatus","")
    history.replace({ pathname: "/AdminSignin" })
  }

  const loginWithRedirect = () => { oktaAuth.signInWithRedirect({ originalUri: "/signin" }); }

  return (
  
    <CssBaseline>
        { loading &&
      <Box container="true" maxWidth='xl' className={classes.root}>
       <Box className={classes.ssoFormContainer}>
          <Button type='submit' className={classes.signInSsoBtn} size="large" fullWidth variant="contained" color="primary" onClick={loginWithRedirect}>
            Login With SSO
          </Button>
          <p className={classes.errorMsg}>{errorMsg !== '' ? errorMsg : ''}</p>

         <Box className={classes.orContainer}>
            <Box className={classes.orWraper}><p className={classes.orText}>or</p></Box>
          </Box>

          <Button type='submit' className={classes.signInUsernameBtn} size="small" fullWidth variant="contained" color="primary" onClick={handleNavigation}>
            Login With Username
          </Button>
       </Box>
      </Box>
}
   </CssBaseline>
    
  )
}
