import { makeStyles } from '@material-ui/core/styles';
import signinIcon from '../../assets/SignIn/SignIn.svg';

export const useStyles = makeStyles((theme) => ({
  mainHeading: {
    padding: '0.2em 3.5em 0.3em',
    fontWeight: '700',
    fontSize: '2.5em',
    color: '#727272',
    [theme.breakpoints.down('sm')]: {
      padding: '1.5em 1.2em 0 2em',
      fontSize: '2em',
      fontFamily: 'Montserrat',
    },
  },
  pageBg: {
    width: '100%',
    backgroundColor: '#ccc',
    backgroundImage: `url(${signinIcon})`,
    backgroundPosition: "bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'contain',
  },
  root: {
    flexWrap: 'wrap',
    fontFamily: 'Montserrat',
    '& > *': {
      margin: theme.spacing(1),
    },
    width: '100%',
    padding: '2.4em 2em 5em 2em',
    '&:last-child': {
      paddingBottom: '9em',
      [theme.breakpoints.down('sm')]: {
        paddingBottom: '5em',
      },
    },
  },
  mainContainer: {
    width: '100%',
    borderRadius: '12px',
    background: 'rgb(255, 255, 255)',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 14px 7px, rgba(0, 0, 0, 0.2) 0px 1px 3px 0px',
    position: 'relative',
    padding: '1.6em 6em 1.7em 7em',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      padding: '1em',
    },
  },
  maxWidth: {
    maxWidth: '120em',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto 0 auto',
    },
  },

  loanSection: {
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    }
  },

  pageHeading: {
    color: '#1A4664',
    fontFamily: 'Montserrat',
    fontSize: '22px',
    fontWeight: '700',
    letterSpacing: '0.18px',
    lineHeight: '24px',
    marginTop: '24px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 8,
    },
  },
  addAccButton: {
    width: '280px',
    height: '40px',
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: '600',
    textTransform: 'uppercase',
    lineHeight: '16px',
    letterSpacing: '1.43px',
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
    },
  },

  loanFlex: {
    display: 'flex',
    alignItems: 'center'
  },
  loanOwnerInfo: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'intial',
      paddingLeft: 0,
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
    },
  },

  marginFixedTop: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
      marginLeft: '63px'
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '53px'
    },
  },

  loanOwner: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '24px;',
    fontWeight: '500',
    letterSpacing: '0.63px',
    lineHeight: '29px',
    marginLeft: '15px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  homeIcons: {
    fontSize: 50,
    [theme.breakpoints.down('xs')]: {
      fontSize: 40,
    },
  },
  custAddress: {
    padding: '0 22%',
    [theme.breakpoints.down('xs')]: {
      padding: '0',
    }
  },
  bodyFont: {
    color: 'rgba(0,0,0,0.74)',
    fontFamily: 'Montserrat',
    marginTop: 7,
    fontSize: 16,
    letterSpacing: '0.44px',
    lineHeight: '20px',

  },
  boldBodyFont: {
    color: 'rgba(0,0,0,0.87)',
    fontWeight: 'bold',
    marginTop: 7,
    fontSize: 20,
    letterSpacing: '0.15px',
    lineHeight: '24px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20
    },
  },

  accTypeFont: {
    color: '#202020',
    fontWeight: '600',
    marginTop: 7,
    fontSize: 20,
    letterSpacing: '0.15px',
    lineHeight: '24px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20
    },
  },

  bankDetails: {
    justifyContent: "space-between",
    paddingLeft: '0',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'intial',
      paddingLeft: '53px',
      flexDirection: 'column',
      alignItems: 'flex-start',
    }
  },

  bank: {
    paddingLeft: '65px',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'intial',
      paddingLeft: 0,
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginLeft: '0'
    }
  },

  rightMenuContainer: {
    display: 'flex',
    textAlign: 'right',
    position: 'absolute',
    top: '12px',
    right: '20px',
    alignItems: 'center',
    '& button': {
      [theme.breakpoints.down('xs')]: {
        padding: 0
      }
    },
    [theme.breakpoints.down('xs')]: {
      top: '32px',
      right: '2px',
    }
  },
  selectedForAutoPay: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    lineHeight: '16px',
    border: '2px solid rgba(0,0,0,0.12)',
    borderRadius: '6px',
    backgroundColor: '#FFFFFF',
    letterSpacing: '0.25px',
    textTransform: 'initial',
    height: '33px',
    display: 'flex',
    textAlign: 'right',
    position: 'absolute',
    top: '20px',
    right: '70px',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      right: '25px',
      float: 'right',
      marginBottom: '18px'
    }
  },
  pointerEvents:{
       pointerEvents:'none'
  },
  cursorPointer:{
     cursor:'default'
  },
  snackBarError: {
    '& .MuiSnackbarContent-root': {
      background: 'red',
      fontFamily: "Montserrat",
    }
  },
  snackBarSuccess: {
    '& .MuiSnackbarContent-root': {
      backgroundColor: "#1A4664",
      fontFamily: "Montserrat",
    }
  },

  pageLoader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh'
  },

  menuListItem: {
    position: 'absolute',
    top: '3px',
    right: '20px',
    zIndex: 999,
    textAlign: 'left',
    width: '185px',
    background: '#fff',
    boxShadow: '0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2)',
    listStyle: 'none',
    padding: '9px 0 5px 0',
    borderRadius: '4px',
    '& li': {
      fontFamily: 'Montserrat',
      fontSize: '16px',
      padding: '5px 13px',
      cursor: 'pointer',
      color: 'rgba(0,0,0,0.85)',
      fontWeight: '600',
      letterSpacing: '0.5px',
      borderBottom: '1px solid #ebe9e9',
      '&:hover': {
        background: '#eaeced',
      },
      '&:last-child': {
        borderBottom: 'none',
      }
    }
  },

  paddingTop0: {
    paddingTop: '0 !important'
  },

  disabledLink: {
    color: '#cccccc !important',
    background: '#f6f6f7 ',
    pointerEvents: 'none',
  },

  enabedLink: {
    color: 'rgba(0,0,0,0.87)',
    background: '#f6f6f7 '
  },

  infoBox:{
    padding: "0.5em",
    alignItems: 'center',
    borderRadius: '4px',
    backgroundColor: '#1A4664'
  },
  infoIcon: {
    paddingRight: "10px",
    fontSize: '35px',
    color: theme.palette.secondary.contrastText,
  },
  infoText: {
    color: theme.palette.secondary.contrastText,
    fontFamily: "Montserrat",
    fontSize: "16px",
    letterSpacing: "0.36px",
    lineHeight: "19px",
  },
  infolink: {
    fontWeight: 600,
    cursor: "pointer",
    textDecoration: "underline"
  },
  linkStyle:{
  color:"white",
  cursor:"pointer",
  textDecoration:"underline"
}
}));