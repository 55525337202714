import { Box, Button, FormControlLabel, Grid, Radio, RadioGroup, Typography,CircularProgress} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { useTheme } from '@material-ui/core/styles';
import { getMyProfile, confirmContactDetails, insertActivityLog } from '../../actions';
import { setEditProfileComponentData } from '../../actions/helperAction'

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';
import { renderDefaultMsg, getAppSource, getUserType } from '../../helpers';
import { decrypt } from '../../helpers/encrypt';
import moment from 'moment';


export const ProfileUpdateAlert = (props) => {
  const { details } = props;
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [radioValue, setRadioValue] = React.useState(false);
  const [userDetail, setUserDetail] = React.useState({})
  const [loader, setLoader] = React.useState(false);
  const [errorMessage,setErrorMessage] = React.useState('');
  const [submitLoader,setSubmitLoader] = React.useState(false)


  const theme = useTheme();
  const dispatch = useDispatch();
  const handleClose = () => setOpen(false);

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const getProfileData = async () => {
    const requestData = {
      "accountNumber": props.accountNumber,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }
    setLoader(true)
    const result = await getMyProfile(requestData)
    if (result) {
      const { lstResponseStatus } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        setLoader(false)
        setUserDetail(result);
        setOpen(true)

        // setMobileVerify(result.isMobileVerified)
        // setEmailVerify(result.isEmailVerified)

        // checkEditProfileState();
      } else {
        setLoader(false)
        setUserDetail({})
        setErrorMessage(lstResponseStatus[0].statusDesc)
      }
    } else {
      setLoader(false)
      setUserDetail({})
      setErrorMessage(renderDefaultMsg('400'))
    }
  }
  useEffect(() => {
    getProfileData();
  }, [])

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  }

  const handleRedirect = async () => {
    await dispatch(setEditProfileComponentData({ edit: true, isFromProfilePopup: true }));
    Cookies.remove('isSemiAnnuallyPopUpAppeared');
    sentContactDetailsConfirm('Modify',1);
    history.push('/UserProfile/Profile');

  }
  const handleSubmit = (value) => {

    if (value === 'continue') {
      sentContactDetailsConfirm(value,0)
    }
    else {
      setSubmitLoader(false);
      setOpen(false);
      Cookies.remove('isSemiAnnuallyPopUpAppeared');
      sentContactDetailsConfirm(value,2)
     
    }
  }

  const sentContactDetailsConfirm = async (value,acknowledgementType) => {
    const currentDate = moment(new Date()); // Get the current date and time
    const data = {
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "acknowledgementType": acknowledgementType,
      "acknowledgementDate": currentDate,
      "userName": Cookies.get("userName"),
    };
    setSubmitLoader(true);
    const result = await confirmContactDetails(data);
    if (result) {
      const { responseData:{lstResponseStatus=[]}={} } = result
      if ((lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        setSubmitLoader(false);
        setOpen(false);
        Cookies.remove('isSemiAnnuallyPopUpAppeared');
        sendActivityData(value);
      } else {
        setErrorMessage(lstResponseStatus[0].statusDesc)
        setSubmitLoader(false);
      }
    } else {
      setSubmitLoader(false);
      setErrorMessage(renderDefaultMsg('400'))
    }

  }

  const sendActivityData = async (buttonName) => {
    const remark = `${'Profile Update Popup'} - ` + `User clicked on ${buttonName} button.`;
    const data = {
      "accountNumber": props?.accountNumber,
      "activityCode": "UAL204",
      "activityStatus": "Success",
      "action": buttonName,
      "controller": "Contact Validation Popup",
      "apiName": "contactValidation",
      "activityRemark": remark,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertActivityLog(data);
  }

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={handleClose} open={open}>
      <Box className={classes.root}>
      <Box className={classes.header}>
         <Typography className={classes.title}>Please confirm your contact details</Typography>
        </Box>
        <Box className={classes.header}>
        </Box>
        <Box className={classes.cardBody}>
          {/* <Box>
            <Typography>First Name : </Typography>
            <Typography>Gitaram</Typography>
          </Box> */}
          <Grid container spacing={3} justify="space-around" columns={{ xs: 12, sm: 6, md: 6 }} className={`${classes.mobileFullWidth}`}>
            <Grid item xs={12} sm={4} md={4} >
              <Typography className={classes.label}>First Name : </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} >
              <Typography className={`${classes.label} value`}>{userDetail.firstName}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4} >
              <Typography className={classes.label}>Last Name : </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} >
              <Typography className={`${classes.label} value`}>{userDetail.lastName}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4} >
              <Typography className={classes.label}>Email : </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} >
              <Typography className={`${classes.label} value`}>{userDetail.emailID ? decrypt(userDetail.emailID) : ''}</Typography>
            </Grid>
            {userDetail.mobileNo && <Grid item xs={12} sm={4} md={4} >
              <Typography className={classes.label}>Mobile : </Typography>
            </Grid>}
            {userDetail.mobileNo && <Grid item xs={12} sm={6} md={6} >
              <Typography className={`${classes.label} value`}>{userDetail.mobileNo ? decrypt(userDetail.mobileNo) : ''}</Typography>
            </Grid>}
            <Grid item xs={12} sm={4} md={4} >
              <Typography className={classes.label}>Mailing Address : </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} >
              <Typography className={`${classes.label} value`}> {userDetail.address1 ? userDetail.address1 : ''}  {userDetail.address2 ? userDetail.address2 : ''},</Typography>
            </Grid>
          </Grid>
          <Box className={classes.radiogroup}>
            <RadioGroup row aria-label="confirm_type" onChange={handleRadioChange} name="confirm_type" value={radioValue} style={{ justifyContent: "flex-start" }}>
              <Grid item xs={12} sm={6} md={6}>
                <FormControlLabel value="Yes" checked={radioValue === 'Yes'} className={classes.radioText}
                  control={<Radio />} label="Yes. My contact details are correct" />

              </Grid>
              <Grid item xs={12} sm={6} md={6} >
                <FormControlLabel value="No" checked={radioValue === 'No'} className={classes.radioText}
                  control={<Radio />} label="No. My contact details are incorrect" />
              </Grid>
            </RadioGroup>
          </Box>
        </Box>
        {errorMessage? (<Box><Typography className={classes.errorText}>{errorMessage}</Typography></Box>) : null}

        <Box className={classes.cardFooter}>
          <Box className={classes.footerItem}>
            <a className={classes.link} onClick={() => handleSubmit('Remind Next Time')}>REMIND NEXT TIME</a>
          </Box>
          <Box className={classes.footerItem}>
            <Button
              variant='contained'
              color='primary'
              className={classes.btn}
              onClick={() => handleRedirect()}
              disabled={radioValue === 'Yes' || !radioValue}
            >Modify</Button>
          </Box>
          <Box className={classes.footerItem}>
            <Button
              variant='contained'
              color='primary'
              className={classes.btn}
              onClick={() => handleSubmit('continue')}
              disabled={radioValue === 'No' || !radioValue || submitLoader}
            >
          {submitLoader ? <CircularProgress className={classes.disableBtnLoader} /> : 'Confirm'}

            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog >
  )
}
