import axios from 'axios';
import Cookies from 'js-cookie';
import { axiosProtected, baseUrl } from '../config';

export const getPaymentHistoryTH = async (data) => {
  try {
    const res = await axiosProtected.post('/Dashboard/GetPaymentHistory', data);    
    return res.data
  } catch (e) {    
    return null
  }
}

export const DownloadPaymentHistory = async (data) => {
  const token = await Cookies.get('privateToken')
  try {
    const res = await axiosProtected({
        url: `${baseUrl}/Dashboard/DownloadPaymentHistory`,
        method: 'POST',
        responseType: 'blob', // important
        data: data,
        // headers: {
        //   Authorization: `Bearer ${token}`
        // }
      })
      return res    
  } catch (e) {    
    return null
  }
}