import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box, CssBaseline, CircularProgress, Grid, Typography, Button,
  FormControlLabel, FormGroup, Checkbox, ClickAwayListener
} from '@material-ui/core';
import { CutomTextFieldEmail, useStyles } from './styles';
import { submitVOM, getContent, getPayoffVOMDetails } from '../../actions';
import Cookies from 'js-cookie';
import { decrypt, encrypt } from '../../helpers/encrypt';
import {
  renderDefaultMsg, scrollTop, checkValidEmail, maskLoan, getUserType, getAppSource, isAdmin
} from '../../helpers';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import ReactToPrint from 'react-to-print';
import TermAndConditions from '../TermAndConditions'
import { withStyles } from '@material-ui/core/styles';
import { MessageManager } from '../MassageManager';
import PrintIcon from '@material-ui/icons/Print';
import CustomBreadcrum from '../Breadcrum';
import { useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const LightTooltip = withStyles((theme) => ({
  arrow: {
    color: '#235A86',
    // backgroundColor: '#ffffff',
  },
  tooltip: {
    backgroundColor: '#235A86',
    color: '#fffff',
    fontFamily: "Montserrat",
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    padding: "16px",
    fontSize: 14,
    maxWidth: 350,
    fontWeight: 500,
    borderRadius: 8,
    // border: "1px solid #4D4D4D"
  },
}))(Tooltip);

export const RequestSubordination = (props) => {
  
  const themeData = useSelector(state => state.theme);
  const loanInfoData = useSelector(state => state?.loanInfoReducer);
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'))

  const classes = useStyles();
  const dispatch = useDispatch();
  const componentRef = useRef();
  const [isProcessSubmit, setIsProcessSubmit] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [inputEmail, setInputEmail] = useState('')
  const [instructions, setInstructions] = useState('')
  const [tncSummary, setTncSummary] = useState('')
  const [tooltipText, setTooltipText] = useState('')
  const [inputDate, setInputDate] = React.useState(new Date());
  const [isLoader, setIsLoader] = useState(false)
  const [userEmail, setUserEmail] = useState('')
  const [isRestrict, setIsRestrict] = useState(false)
  const [checkboxState, setCheckboxState] = useState(false);
  const [openToolTip, setOpenToolTip] = useState(false);

  useEffect(() => {
    scrollTop();
    getContentData();
  }, [])

  useEffect(() => {
    if (props.accountNumber) {
      getVOMDetail();
    }
  }, [props.accountNumber])

  const handleTooltipClose = () => {
    setOpenToolTip(false);
  };

  const handleTooltipOpen = () => {
    setOpenToolTip(true);
  };

  const getContentData = async () => {
    setIsLoader(true)
    const res = await dispatch(getContent('PC019'));
    setIsLoader(false)
    if (res && res.length !== 0) {
      setInstructions(res[0].contentHeader)
      setTooltipText(`A Subordination Request is a request submitted by a borrower and/or their authorized third party for review by the owner of the existing loan/lien to allow another loan/lien to take priority over the one being serviced by ${themeData.privateLabelName}. Once a request is received, ${themeData.privateLabelName} will provide a subordination checklist detailing required information and documentation needed in order for the review to be completed. ${themeData.privateLabelName} can provide this Subordination Checklist to the potential new lender or other entity at your request.`)
      setTncSummary(res[0].contentValue)
    }
  }

  const getVOMDetail = async () => {
    setCheckboxState(false);
    const requestData = {
      "accountNumber": props.accountNumber ? props.accountNumber : '',
      "RequestType": "subordination",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }

    setErrorMessage('')
    setSuccessMessage('')
    const result = await getPayoffVOMDetails(requestData);
    if (result) {
      const { lstResponseStatus, responseData } = result?.responseData;
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {

        let ccEmail = responseData.ccEmail ? decrypt(responseData.ccEmail) : '';
        let dt = responseData.goodThroughDate ? ymdDate(responseData.goodThroughDate) : ''
        let userEmail = responseData.userEmailId ? decrypt(responseData.userEmailId) : ''
        setUserEmail(userEmail)
        setInputEmail(ccEmail)
        setInputDate(dt)
        setIsRestrict(responseData.isRestrict24hr)
      } else {
        setErrorMessage(lstResponseStatus[0].statusDesc)
      }
    } else {
      setErrorMessage(renderDefaultMsg('400'))
    }
    scrollTop()
  }

  const handleChange = (filed) => (event) => {
    if (filed === "inputEmail") {
      setInputEmail(event.target.value)
    } else if (filed === "inputDate") {
      setInputDate(event.target.value)
    }
  }

  const [isValidEmail, setIsValidEmail] = useState(true)
  const validateEmail = () => {
    setIsValidEmail(true)
    let isValid = true
    if (inputEmail) {
      let emailArray = inputEmail.split(";")
      for (let value of emailArray) {
        if (!checkValidEmail(value.trim())) {
          isValid = false
          setIsValidEmail(false)
          break
        }
      }
    }
    return isValid;
  }

  const handleSubmit = async () => {
    if (!validateEmail()) {
      return false;
    }
    const requestData = {
      "accountNumber": props.accountNumber ? props.accountNumber : '',
      "ccEmailId": inputEmail ? encrypt(inputEmail) : '',
      "RequestType": "subordination",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }
    setErrorMessage('')
    setSuccessMessage('')
    setIsProcessSubmit(true)
    const result = await submitVOM(requestData);

    if (result) {
      const { lstResponseStatus } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        setSuccessMessage('Your request has been submitted, Please allow for 1-2 Business days for your request to be complete. You will receive your request to the email address associated with your account. Email:' + userEmail);
        setErrorMessage('');
      } else {
        setErrorMessage(lstResponseStatus[0].statusDesc);
      }
    } else {
      setErrorMessage(renderDefaultMsg('400'))
    }
    setIsProcessSubmit(false)
  }

  const ymdDate = () => {
    let curDate = new Date()
    return `${curDate.getFullYear()}-${curDate.getMonth() + 1}-${curDate.getDate()}`
  }

  const checkboxChangeHandler = (event) => {
    setCheckboxState(event.target.checked)
  }

  const disabledBtn = userEmail != '' && isRestrict === false

  return (
    <CssBaseline>
      <Box container="true" maxWidth='xl' className={classes.root}>
        {isLoader ? (<Box py={5}><CircularProgress /></Box>) : (
          <Box className={classes.container}>
            <Box mt={3} mb={3.4}>
              <CustomBreadcrum backPage="Loan Details" backPageLink="/LoanInfo" activePage="Subordination Request" />
              <Typography className={classes.headingText}>Subordination Request</Typography>
            </Box>
            <Box className={classes.formContainer} ref={componentRef}>
              <Box className={classes.instructionsContainer}>
                <Box pb={3}>
                  <Grid container direction="row" justify="space-between" alignItems="center">
                    <Typography className={classes.subHeading}>{'Instructions'}</Typography>
                    <Box display="flex">
                      <Typography className={`${classes.desktopViewOnly} ${classes.linkStyles}`} >What is a Subordination Request?</Typography>
                      <Typography>
                       
                        {!mediaQuery && <LightTooltip
                            title={tooltipText}
                            arrow placement="bottom" >
                            <InfoIcon style={{ fill: '#535D6D', marginLeft: "5px", fontSize: '20px' }} />
                          </LightTooltip> }
                          {mediaQuery && 
                          <ClickAwayListener onClickAway={handleTooltipClose}>
                          <LightTooltip
                            title={tooltipText}
                              arrow placement="bottom"
                              open={ openToolTip }
                            >
                              <InfoIcon style={{ fill: '#535D6D', marginLeft: "5px", fontSize: '20px' }} onClick={handleTooltipOpen}/>
                            </LightTooltip>
                          </ClickAwayListener>}
                      </Typography>
                    </Box>
                  </Grid>
                </Box>
                <Box pb={3} className={classes.instText}>
                  <Typography className={classes.text}>{instructions}</Typography>
                </Box>
                <Box pb={1}>
                  <Typography className={classes.subHeading}>{`Account ${maskLoan(decrypt(props.accountNumber))}`}</Typography>
                </Box>
              </Box>
              <Box height={"100%"}>
                <Grid container direction="row" justify="flex-start" alignItems="stretch">
                  <Grid item xs={12} sm={12} md={7}>
                    <Box height={"100%"} className={classes.accountContainer}>
                      <Box py={3} px={2} className={classes.accountEmail}>
                        <Typography className={classes.bodyText}>Email Address</Typography>
                        <Typography className={`${classes.bodyText} ${classes.userMail}`} style={{ fontWeight: '600' }}>{userEmail}</Typography>
                      </Box>
                      <Box className={classes.inputContainer}>
                        <CutomTextFieldEmail
                          error={isValidEmail === false}
                          id="outlined-ccemail"
                          label="Email Address CC"
                          variant="outlined"
                          type="text"
                          value={inputEmail}
                          onChange={handleChange('inputEmail')}
                          fullWidth
                          multiline
                          rows={4}
                          rowsMax={4}
                          disabled={isRestrict}
                          InputProps={{
                            classes: { root: classes.textAreaField, input: classes.input },
                          }}
                        />
                       <Typography className={classes.smallText}>Note: Email addresses should be semi-colon separated.</Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5}>
                    <Box height={"100%"} className={classes.tncContainer}>
                      <Box py={2} >
                        <Typography className={classes.tncHeading}>Terms and Conditions</Typography>
                      </Box>
                      <Box className={classes.tncTextContainer}>
                        <Box className={classes.tncSummary}>
                          <TermAndConditions data={tncSummary}></TermAndConditions>
                        </Box>
                      </Box>
                      <Box className={classes.checkboxContainer}>
                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox
                              checked={checkboxState}
                              onChange={(event) => checkboxChangeHandler(event)}
                              name="readTnC"
                            />}
                            label="I agree to the Terms and Conditions"
                            className={classes.inputStyle}
                            classes={{
                              label: checkboxState ? classes.activeRadioLabel : classes.radioLabel
                            }}
                          />
                        </FormGroup>

                        <Box p={1} textAlign="right" className={classes.printBtn}>
                          <ReactToPrint
                            trigger={() => <Box className={classes.printBtnIcon}>
                              Print Form
                            <PrintIcon style={{ fill: '#535D6D', fontSize: 25, marginLeft: "5px", }} />
                            </Box>}
                            content={() => componentRef.current}
                          />
                        </Box>
                      </Box>
                       
                      { (isRestrict || (loanInfoData && loanInfoData?.purgeLoanInfo?.liquidationStatus)) &&
                        <Box className={classes.infoBox}>
                          <Box display='flex' alignItems="center">
                            <InfoIcon className={classes.infoIcon} />
                            {(loanInfoData?.purgeLoanInfo?.liquidationType.toLowerCase() == 'payoff') &&
                              <Typography className={classes.infoText}>Your loan has been paid in full.</Typography>
                            }
                            { (loanInfoData?.purgeLoanInfo?.liquidationType.toLowerCase() == 'servicetransfer') &&
                              <Typography className={classes.infoText}>Your loan has been Transferred to New Servicer.</Typography>
                            }
                            { (loanInfoData?.purgeLoanInfo?.liquidationType.toLowerCase() == 'chargeoff') &&
                              <Typography className={classes.infoText}>Your loan has been charged off.</Typography>
                            }
                            { (loanInfoData?.purgeLoanInfo?.liquidationType.toLowerCase() == 'fclcompleted') &&
                              <Typography className={classes.infoText}>A foreclosure sale date has been held with respect to this property.</Typography>
                            }
                            { (isRestrict && !loanInfoData?.purgeLoanInfo?.liquidationStatus) &&
                              <Typography className={classes.infoText}>Only 1 application submission is allowed in 24 hour period. If your application requires further review please allow our team 1- 2 business days to review.</Typography>
                            }
                          </Box>
                        </Box>
                      }

                      {successMessage ? (<MessageManager message={successMessage} type={"success"}></MessageManager>) : null}
                      {errorMessage ? (<MessageManager message={errorMessage} type={"error"}></MessageManager>) : null}
                      
                      <Box textAlign="right" pt={3.5}>
                        {isProcessSubmit ? (<Button className={classes.btn} disabled={true} variant="contained">
                          <CircularProgress /></Button>) :
                          (<Button
                            onClick={() => handleSubmit()}
                            className={classes.btn}
                            variant="contained"
                            color="primary"
                            disabled={isAdmin() || !disabledBtn || !checkboxState || successMessage !== ''}>
                            SUBMIT</Button>)}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>)}
      </Box>
    </CssBaseline>
  )
}
