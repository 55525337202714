import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField, OutlinedInput, InputLabel } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "95%",
    minHeight: '100vh',
    padding: "3em 0em",
    margin: '0 auto',
  },
  fullpart: {
    width: '100%',
    maxWidth: "100%",
  },
  inputContainer: {
    marginBottom: '3.0em',
  },
  formContainer_updated: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  bgg: {
    background: 'url(/static/media/signinIcon.de91850d.svg) no-repeat center center',
    backgroundSize: 'cover',
    width: '100%',    
    backgroundColor: '#ccc',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },  
  validationBox: {
    boxSizing: "border-box",
    border: "1px solid #A0A0A0",
    borderRadius: "12px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.2)",
  },
  validationTitle: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "600",
    letterSpacing: "0.53px",
    lineHeight: "18px",
  },
  errorMessage: {
    color: 'red',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 600,
    letterSpacing: '0.36px',
    lineHeight: '19px',
    maxWidth: '80%',
    margin: '0 auto 20px auto'
  },
  succesMessage: {
    color: 'green',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 600,
    letterSpacing: '0.36px',
    lineHeight: '19px',
    maxWidth: '80%',
    margin: '0 auto 20px auto'
  },  
  labelStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "500",
    letterSpacing: "0.36px",
    lineHeight: "19px"
  },
  validationText: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontStyle: "italic",
    letterSpacing: "0.53px",
    lineHeight: "24px",
  },
  validationError: {
    color: "#E30909"
  },
  validationSuccess: {
    color: "#00CB82"
  },
  
  inputStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600",
    width: "390px",
    [theme.breakpoints.down('sm')]: {
      width: "auto",
    },
  },  
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%',
  },
  mainContainer: {
    background: 'rgb(255, 255, 255)',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 14px 7px, rgba(0, 0, 0, 0.2) 0px 1px 3px 0px',
    position: 'relative',
    borderRadius: '12px'
  },
  container: {
    maxWidth: '100%',
    overflow: 'hidden'
  },  
  link: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    marginTop: 14,
  },
  formContainer: {    
    padding: "50px",
    [theme.breakpoints.down('sm')]: {
      padding: '40px 10px 0 10px',
    },
    '& .MuiFormLabel-root': {
      color: '#535D6D',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      letterSpacing: '0.36px',
      lineHeight: '19px',
      background: '#fff',
      paddingRight: '5px'
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: props => props.secondaryColorCode,
    }
  },  
  
  title: {
    width: '100%',
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: "600",
    marginBottom: 20,
  },
  btnStyle: {    
    width: "200px"
  }

}));

export const CutomTextField = withStyles((theme) => ({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
}))(TextField);

export const CutomOutlinedInput = withStyles((theme) => ({
  root: {
    "&$focused $notchedOutline": {
      border: `2px solid ${theme.palette.primary.main}`,
    }
  },
  focused: {},
  notchedOutline: {}
}))(OutlinedInput);

export const CustomInputLabel = withStyles((theme) => ({
  root: {
    "&$focused": {
      color: theme.palette.primary.main,
    }
  },
  focused: {},
}))(InputLabel);