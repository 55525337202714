import axios from 'axios';
import Cookies from 'js-cookie';
import { baseUrl } from './index';
import { getPublicIp, getUserType, getAppSource } from '../helpers'



// ===============================Protected Route ===============================


export const adminProtected = axios.create({
  baseURL: baseUrl,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  })

  failedQueue = [];
}

// Add a request interceptor
adminProtected.interceptors.request.use(
  config => {
    const token = Cookies.get('adminToken');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => {
    Promise.reject(error)
  }
);


adminProtected.interceptors.response.use((response) => {
  return response
}, async (error) => {
  const originalRequest = error.config;
  if (error.response.status === 401 && originalRequest.url === '/Authentication/GetAdminToken') {
    Cookies.remove('adminToken');
    Cookies.remove("accessTokenIdAdmin");
    window.location.href = '/Admin';
    return Promise.reject(error);
  }

  if (error.response.status === 401 && !originalRequest._retry) {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject })
      })
        .then(token => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return adminProtected.request(originalRequest);
        })
        .catch(err => {
          return Promise.reject(err);
        })
    }

    originalRequest._retry = true;
    isRefreshing = true;

    const data = {
      "accessTokenID": Cookies.get("accessTokenIdAdmin"),
      "privateLabelURL": "BSI",
      "ipAddress": await getPublicIp(),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }

    return new Promise((resolve, reject) => {
      adminProtected.post('/Authentication/GetAdminToken', data)
        .then(res => {
          if (res.status === 200) {
            Cookies.set("adminToken", res.data.accessToken);
            Cookies.set("accessTokenIdAdmin", res.data.accessTokenID);
            adminProtected.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.accessToken;
            originalRequest.headers['Authorization'] = 'Bearer ' + res.data.accessToken;
            processQueue(null, data.token);
            resolve(adminProtected(originalRequest));
            return adminProtected(originalRequest);
          }
        })
        .catch((err) => {
          processQueue(err, null);
          reject(err);
        })
        .then(() => { isRefreshing = false })
    })
  }
  return Promise.reject(error);
});