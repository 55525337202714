import React from 'react';
import { useStyles } from './styles';
import { Box } from "@material-ui/core";

export const DisasterAssistanceList = (props) => {
  const classes = useStyles();

  return (
    <>
        <Box className={classes.covidList}>
          <h3 className={classes.listHeading}>Disaster Forbearance Plan</h3>
          <ul className={classes.covidOptionsList}>
            <li>
            Your payments will be temporarily suspended for a certain period of time.  This is called a forbearance.
            </li>
            <li>
            At the end of the forbearance period, you would need to reinstate your loan with all amounts that were due during this period.  If you are unable to reinstate your loan, let us know and we will work with you on other workout alternatives such as extended forbearance timeframes, repayment plan, partial claim or modification.  (These options are based on your loan type and investor approval).
            </li>
            <li>
            You will not be assessed late fees during the forbearance period, but your loan will continue to accrue interest.
            </li>
            <li>
            If you are set up for automated payments and you are approved for a forbearance, your forbearance acceptance will not cancel your scheduled payments.  You will need to contact us separately to suspend these payments.
            </li>
            <li>
            If you are in an active bankruptcy, we recommend that you consult your bankruptcy attorney for information regarding your options.
            </li>
          </ul>
        </Box> 
        <Box className={classes.assistStepsList}>
          <h3 className={classes.listHeading}>To apply for assitance, follow these three steps:</h3>
          <ol >
            <li> Complete the Disaster Forbearance application below.</li>
            <li> Confirm the email address on file is correct.<br />
              <b>PLEASE NOTE:</b> We may reach out to you for additional documentation in connection with your review.<br />
            If  approved,the Disaster Forbearance Plan agreement will be sent to your email address.
            </li>
            <li>Save the Disaster Forbearance Plan for your records.</li>
          </ol>
        </Box>
    </>
  )
}