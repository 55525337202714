import React from "react";
import { Box, Button, Dialog, IconButton } from "@material-ui/core";
import { useStyles } from './styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import { Link } from 'react-router-dom';
import ErrorIcon from '@material-ui/icons/Error';
import { useSelector } from 'react-redux';

export const FinancialHardshipAlert = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const mytheme = useSelector(state => state.theme);
  const { customerCareNumber } = mytheme;

  return (
    <Dialog
      fullScreen={fullScreen}
      PaperProps={{ classes: { root: classes.dialogStyle } }}
      onClose={props.handleClose}
      open={props.open}
    >
      <Box className={classes.root}>
        <IconButton className={classes.iconStyle} onClick={props.handleClose}>
          <CancelIcon />
        </IconButton>
        <Box className={classes.body}>
          <h1 className={classes.title}>Having financial difficulty?</h1>
          <p className={classes.subTitle}>Are you having any problem paying your mortgage? Learn more about how we can help you to .</p>
          <Box className={classes.buttonBox}>
            <Button component={Link} to='/FinancialHardship' className={classes.btn} variant="contained" color='primary' >financial hardship</Button>
          </Box>
          <p className={classes.warningText}><ErrorIcon style={{ fill: '#EB4F4F', marginRight: '7px' }} /> Payment Options are Disabled </p>
          <p className={classes.subTitle}>Your loan is now in default (i.e. 90 days past due). You cannot pay online until this is resolved. </p>
          <p className={classes.subTitle}><b>Please call us at {customerCareNumber}</b> to discuss available payment options and verify the amount due.  </p>
          <p className={classes.subTitle}>If you have any further payment related question please visit <Link className={classes.linkStyle} onClick={props.handleClose} to='/PaymentOption'>Payment FAQs </Link></p>
        </Box>
      </Box>
    </Dialog>
  )
}