import React from 'react';
import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import { EnrollPaperLess } from '../../components';

export const DashboardCard = (props) => {

  const { buttonColor, buttonText, bannerImageUrl } = props.banner;
  const { email, phone, accountNumber, isCommConcent,
    isMobileVerified, isGoGreen, isCommConcentMobile, IsSmsConsent, isEmailVerified
  } = props;

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => setOpen(true);
 
  const handleClose = (status) =>  {
    if (status) {
      props.handePopupClose(true);
    }
    setOpen(false)
  };
  
  return (
    <Box>
      <Box className={classes.root}>
        <Box className={classes.body}>
          <img className={classes.logo} src={bannerImageUrl} alt='banner logo' />
        </Box> 

        <Box className={classes.footer}>
          <a className={classes.btn} style={{ backgroundColor: buttonColor }} onClick={() => handleClickOpen()}>{buttonText}</a>
        </Box>
      </Box>

      <EnrollPaperLess
        open={open}
        handleClose={handleClose}
        email={email}
        phone={phone}
        accountNumber={accountNumber}
        isCommConcent={isCommConcent}
        isCommConcentMobile={isCommConcentMobile}
        isGoGreen={isGoGreen}
        IsSmsConsent={IsSmsConsent}
        isVerified={isMobileVerified && isEmailVerified}
        isMobileVerified={isMobileVerified}
        isEmailVerified={isEmailVerified}
      /> 
      
  </Box>)
}
