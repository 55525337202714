
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField, OutlinedInput, InputLabel } from '@material-ui/core';
import signinIcon from '../../assets/SignIn/SignIn.svg';

export const useStyles = makeStyles((theme) => (
  {
    root: {
      width: '100%',
      // minHeight: '100vh',
      backgroundColor: '#ccc',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: `url(${signinIcon})`,
      backgroundPosition: "bottom left",
      backgroundRepeat: "no-repeat",
      backgroundSize: 'contain',
    },
    container: {
      width: '90%',
      minWidth: "33.3em",
      marginBottom: '9em',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '5em',
      },
    },

    formContainer: {
      minHeight: "300px",
      backgroundColor: '#fff',
      borderRadius: 12,
      boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    instructionsContainer: {
      padding: "2.3em 7.9em 0 7.9em",
      [theme.breakpoints.down('sm')]: {
        padding: "1.6em 1.6em 0 1.6em"
      },
    },
    instText: {
      paddingLeft: 0,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: "0"
      },
    },

    breadcrumb: {
      color: "#7B8390",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "600",
      letterSpacing: "0.36px",
      lineHeight: "19px",
    },

    activeBreadcrumb: {
      color: theme.palette.primary.main,
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "bold",
      letterSpacing: "0.36px",
      lineHeight: "19px",
    },
    headingText: {
      color: "#1A4664",
      fontFamily: "Montserrat",
      fontSize: "22px",
      fontWeight: "700",
      letterSpacing: "0.18px",
      lineHeight: "24px",
      marginTop: '24px',
      marginBottom: '24px'
    },

    subHeading: {
      color: "rgba(0,0,0,0.87)",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: "500",
      letterSpacing: "0.15px",
      lineHeight: "24px",
    },
    text: {
      color: "rgba(0,0,0,0.74)",
      fontFamily: "Montserrat",
      fontSize: "16px",
      letterSpacing: "0.5px",
      lineHeight: "20px",
    },
    accountContainer: {
      padding: "0 4em 0 7.9em",
      borderRight: "1px solid #dcdddf",
      borderTop: "1px solid #dcdddf",
      marginTop: '4px',
      marginBottom: 40,
      [theme.breakpoints.down('sm')]: {
        padding: "0 1.6em",
        borderRight: "none",
        width: "100%"
      },
    },
    accountEmail: {
      padding: "4em 2.3em 4em 0",
      [theme.breakpoints.down('sm')]: {
        padding: "2em 2.3em 2em 0",
      },
      '& .MuiTypography-body1': {
        fontFamily: "Montserrat",
        color: '#202020',
        fontSize: "16px",
        letterSpacing: "0.36px",
        lineHeight: "19px",
      },
      '& .MuiTypography-body1:last-child': {
        marginTop: '12px'
      }
    },
    formInputContainer: {
    },
    inputContainer: {
      marginBottom: '3.9em',
      width: "75%",
      [theme.breakpoints.down('sm')]: {
        width: "100%"
      },
    },

    tncHeading: {
      color: "rgba(0,0,0,0.87)",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: "500",
      letterSpacing: "0.15px",
      lineHeight: "24px",
      borderBottom: '1px solid #dcdddf',
      paddingBottom: '10px',
      paddingTop: '0'
    },

    tncContainer: {
      borderRadius: "0 0 12px 0",
      backgroundColor: "#eaeced",
      padding: "2.5em",
      marginTop: '4px',
      '& .MuiAlert-filledInfo': {
        backgroundColor: theme.palette.secondary.main,
        fontFamily: "Montserrat",
      },
      '& .MuiAlert-filledSuccess': {
        backgroundColor: theme.palette.secondary.main,
        fontFamily: "Montserrat",
      }
    },
    tncTextContainer: {
      boxSizing: "border-box",
      height: "162px",
      border: "1px solid rgba(156,156,156,0.5)",
      backgroundColor: "#FFFFFF",
      marginTop: "1em",
      paddingLeft: "1.6em",
      paddingRight: ".8em",
      paddingTop: "1.3em"
    },
    tncSummary: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "16px",
      letterSpacing: "0.36px",
      lineHeight: "19px",
      height: "115px",
      overflow: "auto",
      paddingRight: "1em"
    },

    btn: {
      color: theme.palette.primary.contrastText,
      width: "200px",
      height: "40px",
      fontSize: "16px",
      fontFamily: "Montserrat",
      fontWeight: "600",
      letterSpacing: "0.53px",
      lineHeight: "19px",
      textAlign: "center",
    },
    smallText: {
      color: "rgba(0,0,0,0.87)",
      fontFamily: "Montserrat",
      fontSize: "14px",
      letterSpacing: "0.25px",
      lineHeight: "16px",
      paddingTop: '6px',
      paddingLeft: 0
    },
    actionText: {
      color: "#000000",
      fontFamily: "Montserrat",
      fontSize: "16px",
      letterSpacing: "0.31px",
      lineHeight: "18px",
      fontWeight: "600"
    },
    payoffQuote: {
      color: "#000000",
      fontFamily: "Montserrat",
      fontSize: "16px",
      letterSpacing: "0.31px",
      lineHeight: "18px",
      fontWeight: "600",
      paddingTop: '4px',
      marginLeft: '6px',
      [theme.breakpoints.down('sm')]: {
        lineHeight: "20px",
        fontWeight: "500"
      },
    },
    generatePayoff: {
      color: "#000000",
      fontFamily: "Montserrat",
      fontSize: "16px",
      letterSpacing: "0.31px",
      lineHeight: "18px",
      fontWeight: "600",
      [theme.breakpoints.down('sm')]: {
        lineHeight: "20px",
        fontWeight: "500",
        fontSize: "15px"
      }
    },
    feelFree: {
      color: "#000000",
      fontFamily: "Montserrat",
      fontSize: "15px",
      letterSpacing: "0.31px",
      lineHeight: "18px",
      fontWeight: "400"
    },
    resendEmail: {
      color: "#000000",
      fontFamily: "Montserrat",
      fontSize: "16px",
      letterSpacing: "0.31px",
      lineHeight: "18px",
      fontWeight: "500",
      alignItems: "center",
      [theme.breakpoints.down('sm')]: {
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: "500"
      },
    },
    generatedDate: {
      color: "#000000",
      fontFamily: "Montserrat",
      fontSize: "14px",
      letterSpacing: "0.31px",
      lineHeight: "18px",
      fontWeight: "400",
      paddingTop: "50px",
      paddingLeft: "70px",
      paddingBottom: "10px",
      [theme.breakpoints.down('sm')]: {
        lineHeight: "16px",
        paddingTop: "0px",
        paddingLeft: "0px"
      },
    },
    downloadPdfLink: {
      color: "#007FFF",
      fontFamily: "Montserrat",
      fontSize: "18px",
      letterSpacing: "0.31px",
      lineHeight: "15px",
      fontWeight: "600",
      cursor: "pointer",
      textDecoration: 'underline',
      [theme.breakpoints.down('sm')]: {
        fontSize: "16px"
      },
    },
    resendEmailLink: {
      color: "#007FFF",
      fontFamily: "Montserrat",
      fontSize: "16px",
      letterSpacing: "0.31px",
      lineHeight: "15px",
      fontWeight: "500",
      cursor: "pointer",
      textDecoration: 'underline'
    },
    disableEmailLink: {
      color: "#949494",
      fontFamily: "Montserrat",
      fontSize: "16px",
      letterSpacing: "0.31px",
      lineHeight: "15px",
      fontWeight: "500",
      cursor: "pointer",
      textDecoration: 'underline'
    },
    emailSendLink: {
      color: "#198100",
      fontFamily: "Montserrat",
      fontSize: "16px",
      letterSpacing: "0.31px",
      lineHeight: "15px",
      fontWeight: "600",
      paddingTop: 4
    },
    primaryColor: {
      backgroundColor: `${theme.primary}!important`,
    },

    mobileViewOnly: {
      display: "none",
      [theme.breakpoints.down('sm')]: {
        display: "block",
      },
    },

    desktopViewOnly: {
      display: "block",
      [theme.breakpoints.down('sm')]: {
        display: "none",
      },
    },

    layer: {
      position: "fixed",
      backgroundColor: "rgba(0, 0, 255, 0.3)",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 99999,
      //display: "none",
    },
    timer: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: "600",
      letterSpacing: "0.44px",
      lineHeight: "24px",
    },
    actionItem: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: 600,
      letterSpacing: "0.31px",
      lineHeight: "18px",
      float: "right"
    },
    divider: {
      padding: "0 15px"
    },

    underline: {
      textDecoration: 'none',
      color: '#535D6D',
      fontWeight: '600',
      fontSize: "16px",
      fontFamily: "Montserrat",
      letterSpacing: '0.6px',
      lineHeight: '19px',
    },

    mt10: {
      marginTop: '13px'
    },

    printBtn: {
      padding: 0,
    },

    printBtnIcon: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: "600",
      cursor: 'pointer',
      color: '#535D6D'
    },

    textField: {
      color: 'rgba(0,0,0,0.87)',
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "600",
      letterSpacing: '0.15px',
      lineHeight: '20px',
    },

    textAreaField: {
      color: 'rgba(0,0,0,0.87)',
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: "600",
      letterSpacing: '0.15px',
      lineHeight: '20px',
    },

    activeRadioLabel: {
      fontWeight: "500",
    },

    radioLabel: {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: "500",
      cursor: 'pointer',
      color: 'rgba(0,0,0,0.87)',
      letterSpacing: '0.25px',
      lineHeight: '16px',
      '& .MuiRadio-colorSecondary.Mui-checked': {
        color: "#535D6D",
        fontWeight: "500",
      }
    },

    inputStyle: {
      color: "#535D6D",
      fontWeight: "500",
    },

    checkboxContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: '12px',
      '& .MuiCheckbox-colorSecondary.Mui-checked': {
        color: '#535D6D'
      }
    },

    marBtmFixed: {
      marginBottom: 0,
      '& .MuiFormHelperText-root.Mui-error': {
        display: 'none',
      },
      '& .MuiFormLabel-root.Mui-error': {
        // color: 'inherit'
      },
      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        // borderColor: 'inherit',
      }

    },
  
    mbPhone: {
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '3.9em',
      },
    },

    paymentDayComponent: {
      border: '2px solid #25abc7',
      borderRadius: '30px',
      position: 'relative',
      width: '36px',
      height: '36px',
      lineHeight: '34px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiPickersDay-daySelected': {
        background: '#25abc7',
        color: '#fff',
      },
    },

    downloadContainer: {
      boxSizing: "border-box",
      // height: "160px",
      border: "1px solid #D9D9D9",
      backgroundColor: "#F4F4F4",
      marginTop: "1em",
      paddingLeft: "1.6em",
      paddingRight: ".8em",
      paddingTop: "1.3em",
      borderRadius: '8px',
      marginBottom: '25px',
      [theme.breakpoints.down('sm')]: {
        height: "200px",
        paddingTop: "0px"
      }
    },

    tncSummary: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "16px",
      letterSpacing: "0.36px",
      lineHeight: "19px",
      height: "115px",
      overflow: "auto",
      paddingRight: "1em"
    },

    infoText: {
      color: "#121D22",
      fontFamily: "Montserrat",
      fontSize: '14px',
      fontWeight: "400",
    },

    clockImg: {
      width: 56,
      height: 56
    },
    
    checkImg: {
      width: 22,
      height: 22
    },

    infoTooltipIcon: {
      fill: "#535D6D",
      marginLeft: "5px",
      width: "19px", 
      height: "19px",
      [theme.breakpoints.down('sm')]: {
        marginTop: "12px"
      }
    },

    info: {
      color: "#000000",
      fontFamily: "Montserrat",
      fontSize: '17px',
      fontWeight: "600",
      fontStyle: "normal",
      lineHeight: "30px",
      textAlign: "center",
      paddingTop: 5,
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        fontWeight: "500",
        lineHeight: "25px",
        textAlign: "start",
      }
    },

    infoTopIcon: {
      color: "#007FFF",
      width: 24,
      height: 24,
      marginRight: 10,
      marginLeft: 15
    },
 
    infoIcon: {
      color: "#E02020",
      width: 20,
      height: 20,
      marginRight: 10,
    },

    infoBox: {
      width: '100%',
      alignItems: 'center',
      backgroundColor: '#FFF0F0',
      borderRadius: 4,
      padding: '0.5em',
      borderRadius: '4px',
      border: "1px solid #F85757",
      // marginLeft: 8
    },
  
    infoTopBox: {
      width: '100%',
      alignItems: 'center',
      backgroundColor: '#F4F4F4',
      borderRadius: 8,
      padding: '0.5em',
      borderRadius: '4px',
      border: "1px solid #D9D9D9",
      marginBottom: 20,
      marginTop: 5
    },

    timerContainer: {
      background: '#cccaca',
      textAlign: 'center',
      maxWidth: '52%',
      padding: '20px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '90%',
      },
    },

    timer: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "600",
      letterSpacing: "0.44px",
      lineHeight: "24px",
      paddingBottom: "4px",
      [theme.breakpoints.down('sm')]: {
        fontSize: '15px',
        lineHeight: "20px"
      },
    },

    errorText: {
      color: "red",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "600",
      letterSpacing: "0.31px",
      lineHeight: "18px",
      marginBottom: '12px'     
    },

    contactUs: {
      color: "#007FFF",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "400"
    },
    surveyLink:{
      color: '#121D22',
      textAlign: 'center',
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '20px',
      '& .link':{
        color: '#007FFF',
        textDecorationLine: 'underline',
        marginLeft:'10px',
        cursor:'pointer'

      }
    },
    marTop: {
      marginTop: 25
    },

    fileUpload:{
      display:'flex',
      alignItems:'center',
      marginTop:'10px',
      marginBottom:'24px',
      [theme.breakpoints.down('sm')]: {
        flexDirection:'column',
        alignItems:'normal',
        clear:'both',
        width: '90%'
      },
    },
    fileButton:{
      borderRadius: '4px',
      border: '1px solid #EDB36A',
      background: '#FFF',
      marginRight:'15px',
      fontSize: 14,
      fontWeight: 600,
      minWidth: '140px',
      color: '#121D22',
      fontFamily: 'Montserrat'
    },
    fileBtnDisable:{
      borderRadius: '4px',
      background: '#949494',
      marginRight:'15px',
    },
    chooseaFile:{
      color: '#121D22',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal',
      marginRight:'15px',
      '&.info':{
        fontSize: '16px',
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: '5px'
      },
    },
    chooseFile:{
      color: '#121D22',
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 'normal',
      marginRight:'15px',
      '&.info':{
        fontSize: '14px',
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: '5px'
      },
    },
    uploadedImageContainer:{
      paddingRight:'40px',
      [theme.breakpoints.down('sm')]: {
        paddingRight:'0px',
      },
    },
    uploadedImage:{
      borderBottom: '3px solid #198100',
      color: '#202020',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '16px',
      paddingBottom: '10px',
      marginBottom: '20px',
      display: 'flex',
    },
    imageText: {
      width: '285px',
      color: '#007FFF',
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '16px',
      textDecoration: 'underline',
      paddingLeft: 10,
      paddingTop: 2,
      wordWrap: 'break-word',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        width:'235px'
      },
    },
    suportedDoc: {
      color: '#121D22',
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '16px',
      marginBottom:'24px'
    },
    suportedDocDesc: {
      color: '#121D22',
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '16px',
    },
    cardContainer: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
      marginTop: 15,
    },
  }
));

export const CutomTextFieldEmail = withStyles((theme) => ({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
      height: 112,
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
}))(TextField);

export const CutomTextField = withStyles((theme) => ({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
}))(TextField);


