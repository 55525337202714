import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box, CssBaseline, CircularProgress, Grid, Typography, Button, Tooltip, ClickAwayListener
} from '@material-ui/core';
import { useStyles, RedTooltip, useStylesBootstrap } from './styles';
import { getCommPreference, updateCommPreference, getContent } from '../../../actions';
import Cookies from 'js-cookie';
import { decrypt, encrypt } from '../../../helpers/encrypt';
import { maskEmail, maskMobile, scrollTop, renderDefaultMsg, getAppSource, getUserType, isAdmin } from '../../../helpers';
import { SwitchButton } from '../../SwitchButton'
import Beenhere from '@material-ui/icons/Beenhere';
import email from '../../../assets/CommunicationPreferences/email.png';
import paperless from '../../../assets/CommunicationPreferences/paperless.png';
import phone from '../../../assets/CommunicationPreferences/phone.png';
import sms from '../../../assets/CommunicationPreferences/sms.png';
import { insertActivityLog } from '../../../../src/actions';
import {EMAIL_TOOLTIP, PHONE_TOOLTIP, MESSAGE_TOOLTIP, PAPERLESS_TOOLTIP} from '../../../helpers/constants';
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const LightToolTip = withStyles((theme) => ({
  arrow: {
    color: '#235A86',
    // backgroundColor: '#ffffff',
  },
  tooltip: {
    backgroundColor: '#235A86',
    color: '#fffff',
    fontFamily: "Montserrat",
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    padding: "16px",
    fontSize: 14,
    maxWidth: 350,
    fontWeight: 500,
    borderRadius: 8,
    // border: "1px solid #4D4D4D"
  },
}))(Tooltip);

function BlueOnWhiteTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} placement="bottom-end" />;
}

export const CommunicationPreferences = (props) => {

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const dispatch = useDispatch();
  const [pageContent, setPageContent] = useState([])
  const [summary, setSummary] = useState('')
  const [isEmailConsent, setIsEmailConsent] = useState(true)
  const [isCellConsent, setIsCellConsent] = useState(true)
  const [IsSmsConsent, setIsSmsConsent] = useState(true);
  const [statementRecieve, setStatementRecieve] = useState(true)
  const [statementAvailable, setStatementAvailable] = useState(true)
  const [cPData, setCPData] = useState({})
  const [isCPLoading, setIsCPLoading] = useState(false)
  const [isCPSubmit, setIsCPSubmit] = useState(false)
  const [errorMessage, setErrorMessage] = useState('');
  const [msg, setMsg] = useState("");
  const [isMailVerified, setMailVerify] = useState(false);
  const [isMobileVerified, setMobileVerify] = useState(false);
  const [Result, setResult] = useState({});
  const [successMsg,setSuccessMsg] = useState(false)
  const [openEmailToolTip, setOpenEmailToolTip] =  React.useState(false);
  const [openPhoneToolTip, setOpenPhoneToolTip] =  React.useState(false);
  const [openSmsToolTip, setOpenSmsToolTip] =  React.useState(false);
  const [openLanveraToolTip, setOpenLanveraToolTip] =  React.useState(false);

  const handleEmailTooltipClose = () => {
    setOpenEmailToolTip(false);
  };

  const handleEmailTooltipOpen = () => {
    setOpenEmailToolTip(true);
  };
 
  const handlePhoneTooltipClose = () => {
    setOpenPhoneToolTip(false);
  };

  const handlePhoneTooltipOpen = () => {
    setOpenPhoneToolTip(true);
  };

  const handleSmsTooltipClose = () => {
    setOpenSmsToolTip(false);
  };

  const handleSmsTooltipOpen = () => {
    setOpenSmsToolTip(true);
  };

  const handleLanveraTooltipClose = () => {
    setOpenLanveraToolTip(false);
  };

  const handleLanveraTooltipOpen = () => {
    setOpenLanveraToolTip(true);
  };
  
  useEffect(() => {
    getContentData();
  }, [])

  useEffect(() => {
    // if (props.accountNumber) {
      Cookies.remove('redirectUrl')
      setIsCPLoading(true)
      getCP()
    // }
  }, [props.accountNumber]);

  const getContentData = async () => {
    const res = await dispatch(getContent('PC004'));
    if (res) {
      let cpSummary = res.filter(item => item.contentKey === 'CommunicationConsent')
      setPageContent(res)
      setSummary(cpSummary[0].contentValue)
    }
  }

  const getCP = async () => {
    const requestData = {
      "accountNumber": props.accountNumber,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }
    
    setErrorMessage('')
    let result = await getCommPreference(requestData)
    setResult(result)

    if (result) {
      const { lstResponseStatus } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        setIsCPLoading(false)
        setCPData(result)
        setIsEmailConsent(result.isEmailConsent)
        setIsCellConsent(result.isCellConsent)
        setIsSmsConsent(result.isSmsConsent)
        setStatementAvailable(result.isLanveraESmtNotification)
        setStatementRecieve(result.isLanveraEStatements)
        setMailVerify(result.isEmailVerified)
        setMobileVerify(result.isMobileVerified)
      } else {
        setIsCPLoading(false)
        setCPData({})
        setErrorMessage(lstResponseStatus[0].statusDesc)
      }
    } else {
      setIsCPLoading(false)
      setCPData({})
      setErrorMessage(renderDefaultMsg('400'))
    }
  }

  const handleSubmit = async () => {
    setMsg('');
    setErrorMessage('')
    const requestData = {
      "accountNumber": props.accountNumber,
      "isLanveraEStatements": statementRecieve,
      "isLanveraESmtNotification": isEmailConsent,
      "isCellConsent": isCellConsent,
      "isSmsConsent": IsSmsConsent,
      "isEmailConsent": isEmailConsent,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }

   
    if (Result.isEmailConsent !== isEmailConsent || Result.isCellConsent !== isCellConsent || Result.isSmsConsent !== IsSmsConsent || Result.isLanveraEStatements !== statementRecieve) {
      setIsCPSubmit(true)
      setErrorMessage('')
      setSuccessMsg(false);
    const result = await updateCommPreference(requestData);
    if (result) {
      const { lstResponseStatus } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        getCP()
        setSuccessMsg(true);
        setIsCPSubmit(false)
       // scrollTop();
      } else {
        setIsCPSubmit(false)
        setErrorMessage(lstResponseStatus[0].statusDesc)
      }
    } else {
      setIsCPSubmit(false)
      setErrorMessage(renderDefaultMsg('400'))
    }
  } else {
    setIsCPSubmit(true)
    const text = "No API Call- There is no changes in data on Communication preferences";
    const data = {
      "accountNumber": props.accountNumber ? props.accountNumber : '',
      "activityCode": "UAL057",
      "activityStatus": "Success",
      "action": "UpdateCommPreference",
      "controller": "UpdateCommPreference",
      "apiName": "MyProfile",
      "activityRemark":  text,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };
    await insertActivityLog(data);
    setTimeout(() => {
      setIsCPSubmit(false)
      setSuccessMsg(true);

    }, 1000)
  }
}
  const handleSwitchCallback = (item) => {
    setSuccessMsg(false);

    if (item.fieldName === 'isEmailConsent') {
      setIsEmailConsent(item.fieldValue)
    } else if (item.fieldName === 'isCellConsent') {
      setIsCellConsent(item.fieldValue)
    } else if (item.fieldName === 'IsSmsConsent') {
      setIsSmsConsent(item.fieldValue)
    } else if (item.fieldName === 'statementRecieve') {
      setStatementRecieve(item.fieldValue)
    } else if (item.fieldName === 'statementAvailable') {
      setStatementAvailable(item.fieldValue)
    }
  }
  return (
    <CssBaseline>
      {cPData && cPData.emailID ? (
        <Box>
          {isCPLoading ? (<Box align="center" py={10}><CircularProgress color="primary" /></Box>) : (<Fragment>
            <Grid container direction="row"  >
              <Grid item xs={12} md={5}>
                <Typography ><p className={classes.summaryText} dangerouslySetInnerHTML={{ __html: summary }}></p></Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <Box style={{ width: '100%' }}>
                  <Box mb={3} style={{ width: '100%' }}>
                    <Grid container direction="row" justify="space-between" alignItems="center" >
                      <Grid item xs={9} sm={9} md={11}> 
                      <Typography className={classes.onOffText}>
                       
                    {!fullScreen && <LightToolTip
                      arrow placement="bottom" title={EMAIL_TOOLTIP} >
                      <span style={{ position: 'relative', top: '7px', left: '-8px', cursor: 'pointer' }}><img src={email} alt="check icon" style={{ width: '20px', height: '23px', }}/></span>
                    </LightToolTip> }
                    {fullScreen && 
                    <ClickAwayListener onClickAway={handleEmailTooltipClose}>
                    <LightToolTip
                        arrow placement="bottom" title={EMAIL_TOOLTIP}
                        open={ openEmailToolTip }
                      >
                          <span style={{ position: 'relative', top: '7px', left: '-8px', cursor: 'pointer' }}  onClick={handleEmailTooltipOpen}><img src={email} alt="check icon" style={{ width: '20px', height: '23px', }}/></span>
                      </LightToolTip>
                    </ClickAwayListener>}
                      Receive emails on {cPData.emailID ? maskEmail(decrypt(cPData.emailID)) : ''}</Typography>
                      </Grid>
                      <Grid item xs={3} sm={3} md={1} className={classes.textRight}>
                        <SwitchButton

                          status={isEmailConsent}
                          handleSwitch={handleSwitchCallback}
                          switchName={"isEmailConsent"}></SwitchButton>
                      </Grid>
                    </Grid>
                  </Box>
                  {cPData.mobileNo ?
                    (<Box mb={3}>
                      <Grid container direction="row" justify="space-between" alignItems="center" >
                        <Grid item xs={9} sm={9} md={11}> <Typography className={classes.onOffText}>
                        
                        {!fullScreen && <LightToolTip
                          arrow placement="bottom" title={PHONE_TOOLTIP} >
                          <span style={{ position: 'relative', top: '7px', left: '-8px', cursor: 'pointer' }}><img src={phone} alt="check icon" style={{ width: '20px', height: '23px', }}/></span>
                        </LightToolTip> }
                        {fullScreen && 
                        <ClickAwayListener onClickAway={handlePhoneTooltipClose}>
                        <LightToolTip
                            arrow placement="bottom" title={PHONE_TOOLTIP}
                            open={ openPhoneToolTip }
                          >
                              <span style={{ position: 'relative', top: '7px', left: '-8px', cursor: 'pointer' }}  onClick={handlePhoneTooltipOpen}><img src={phone} alt="check icon" style={{ width: '20px', height: '23px', }}/></span>
                          </LightToolTip>
                        </ClickAwayListener>}
                        Receive phone calls on {cPData.mobileNo ? maskMobile(decrypt(cPData.mobileNo)) : ''}</Typography></Grid>
                        <Grid item xs={3} sm={3} md={1} className={classes.textRight}>
                          <SwitchButton
                            status={isCellConsent}
                            handleSwitch={handleSwitchCallback}
                            switchName={"isCellConsent"}></SwitchButton>
                        </Grid>
                      </Grid>
                    </Box>) : null}
                  {cPData.mobileNo ?
                    (<Box mb={3}>
                      <Grid container direction="row" justify="space-between" alignItems="center" >
                        <Grid item xs={9} sm={9} md={11}> <Typography className={classes.onOffText}>
                        
                        {!fullScreen && <LightToolTip placement="bottom"
                          arrow  title={MESSAGE_TOOLTIP} >
                          <span style={{ position: 'relative', top: '7px', left: '-8px', cursor: 'pointer' }}><img src={sms} alt="check icon" style={{ width: '20px', height: '23px', }}/></span>
                        </LightToolTip> }
                        {fullScreen && 
                        <ClickAwayListener onClickAway={handleSmsTooltipClose}>
                        <LightToolTip placement="bottom"
                            arrow title={MESSAGE_TOOLTIP}
                            open={ openSmsToolTip }
                          >
                              <span style={{ position: 'relative', top: '7px', left: '-8px', cursor: 'pointer' }}  onClick={handleSmsTooltipOpen}><img src={sms} alt="check icon" style={{ width: '20px', height: '23px', }}/></span>
                          </LightToolTip>
                        </ClickAwayListener>} 
                          Receive text messages on  {cPData.mobileNo ? maskMobile(decrypt(cPData.mobileNo)) : ''}</Typography></Grid>
                        <Grid item xs={3} sm={3} md={1} className={classes.textRight}>
                          <SwitchButton
                            status={IsSmsConsent}
                            handleSwitch={handleSwitchCallback}
                            switchName={"IsSmsConsent"}></SwitchButton>
                        </Grid>
                      </Grid>
                    </Box>) : null}
                  <Box mb={3}>
                    <Grid container direction="row" justify="space-between" alignItems="center" >
                      <Grid item xs={9} sm={9} md={11}> <Typography className={classes.onOffText}>
                     
                       
                        {!fullScreen && <LightToolTip placement="bottom"
                          arrow title={PAPERLESS_TOOLTIP} >
                          <span style={{ position: 'relative', top: '11px', left: '-8px' , paddingRight: '2px', cursor: 'pointer' }}><img src={paperless} alt="check icon" style={{ width: '20px', height: '23px', }}/></span>
                        </LightToolTip> }
                        {fullScreen && 
                        <ClickAwayListener onClickAway={handleLanveraTooltipClose}>
                        <LightToolTip placement="bottom"
                            arrow title={PAPERLESS_TOOLTIP}
                            open={ openLanveraToolTip }
                          >
                          <span style={{ position: 'relative', top: '11px', left: '-8px' , paddingRight: '2px', cursor: 'pointer' }}  onClick={handleLanveraTooltipOpen}><img src={paperless} alt="check icon" style={{ width: '20px', height: '23px', }}/></span>
                      </LightToolTip>
                    </ClickAwayListener>}
                      Go paperless and receive statements electronically</Typography></Grid>
                      <Grid item xs={3} sm={3} md={1} className={classes.textRight}>
                        <SwitchButton
                          status={statementRecieve}
                          handleSwitch={handleSwitchCallback}
                          switchName={"statementRecieve"}></SwitchButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Box textAlign="center" pt={3}>
              {errorMessage ? (<Box><Typography className={classes.errorMessage}>{errorMessage}</Typography></Box>) : null}
              {successMsg && <p className={classes.successMsg}>{'Changes saved successfully.'}</p>}

              {isCPSubmit ? (<Button
                className={classes.btn}
                variant="contained"
                disabled={true}><CircularProgress color="primary" /></Button>) :
                  (!(isMailVerified && isMobileVerified)) ?
                    <RedTooltip arrow title={`Please verify your ${!isMailVerified ? 'email' : ''}${(!isMailVerified && !isMobileVerified) ? ' and' : ''}${!isMobileVerified ? ' mobile number' : ''}.`}>
                      <Button
                        className={classes.btnDisable} mt={2}
                        variant="contained"
                        // color="primary"
                        // disabled={true}
                      >
                        SUBMIT</Button>
                        </RedTooltip> :
                    <Button
                      onClick={() => handleSubmit() }
                      className={classes.btn} mt={2}
                      variant="contained"
                      color="primary"
                      disabled={isAdmin()}
                    >
                      SUBMIT</Button>
                  
                  }
            </Box>
          </Fragment>)}
        </Box>) : null}
    </CssBaseline>
  )
}
