import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Typography, Button, CircularProgress, OutlinedInput,IconButton,
  InputAdornment } from '@material-ui/core';

import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import { BoxWithShadow } from "../BoxWithShadow";
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useStyles } from './styles';
import Cookies from 'js-cookie';
import { encrypt, decrypt } from '../../helpers/encrypt';
import { renderDefaultMsg, getAppSource, getUserType, scrollTop, isAdmin } from '../../helpers';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import checkImg from '../../assets/checkImg.svg';
import CustomBreadcrum from '../Breadcrum';
import { addEditBankAccount, getBorrowerCoborrowerName, storeClearAfterEdit, getBankDetails, getBankAccountDetail, showToaster } from '../../actions';
import { checkAllDigits, consecutiveDigits, reverseConsecutiveDigits } from '../../helpers';
import { UpdateBankAccRequestPopup } from '../ManageBankAccount/UpdateBankAccRequest/UpdateBankAccRequestPopup';
import { maskAccountNo } from '../../helpers';

export const AddBankAccount = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const accForEditDetail = useSelector(state => state.accForEditDetail)
  const classes = useStyles();

  const myRef = React.useRef({});

  const [formSubmit, setFormSubmit] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [thirdPartyName, setThirdPartyName] = useState(false);
  const [coBoName, setCoBoName] = useState([]);
  const [coBoError, setCoBoError] = useState('');
  const [editDataLoader, setEditDataLoader] = useState(false);
  const [value, setValue] = React.useState('');
  const [editBtn, setEditBtn] = useState(false);
  const [abAError, setAbAError] = useState('');
  const [validateRountingLoader, setValidateRountingLoader] = useState(false);
  const [ifBankNameEmpty, setIfBankNameEmpty] = useState(false);
  const [aBAValChanged, setABAValChanged] = useState(false);
  const [values, setValues] = React.useState({
    showAccNumber: false,
    showConfirmAccNumber: true,
    userName: ''
  });
  const [formItem, setFormItem] = React.useState({
    bankProfileID: 0,
    bankName: "",
    bankCity: "",
    abaRoutingNumber: "",
    nickName: "",
    bankAccountNumber: "",
    accountType: "C",
    isPreferredAcc: false,
    borrowerName: '',
    borrowerType: '',
    thirdBorrowerName: '',
    confirmBankAccNumber: ''
  });

  const [formInputError, setFormInputError] = useState({
    bankName: "",
    abaRoutingNumber: "",
    nickName: "",
    bankAccountNumber: "",
    borrowerType: '',
    thirdBorrowerName: '',
    confirmBankAccNumber: ''
  })
  const [invalidAbaRoutingNoStatus, setInvalidAbaRoutingNo] = useState(false)
  const [data, setData] = useState([]);
  const [updateStatusForSingleAch, setUpdateStatusForSingleAch] = useState(false)
  const [requestId, setRequestId] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0);
    getBorroCoborroName();
    getData();
  }, [])
 
  const getData = async () => {
    const data = {
      "accountNumber": "",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };
    const response = await getBankAccountDetail(data);
    if (response) {
      setData(response.lstBEBankAccountDetails);
    }
  }

  const handleChange = (event) => {
    setFormItem({ ...formItem, ['isPreferredAcc']: event.target.checked });
  };

  const handleRadioChange = (event) => {
    setValue(event.target.value);
    setFormItem({ ...formItem, ['accountType']: event.target.value });
  };
  
  const getBorroCoborroName = async () => {
    const selectedAccount = await Cookies.get('selectedAccount');
    const data = {
      "accountNumber": selectedAccount,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };
    setEditDataLoader(true);
    const response = await getBorrowerCoborrowerName(data);
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {

      setCoBoName(response.borrowerNames);
      if (accForEditDetail != null) {
        setFormItem({
          ...formItem,
          bankProfileID: accForEditDetail.bankProfileID,
          bankName: accForEditDetail.bankName ? decrypt(accForEditDetail.bankName) : '',
          bankCity: accForEditDetail.bankCity,
          abaRoutingNumber: accForEditDetail.abaRoutingNumber,
          nickName: accForEditDetail.nickName,
          bankAccountNumber: accForEditDetail.bankAccountNumber ? decrypt(accForEditDetail.bankAccountNumber) : '',
          accountType: accForEditDetail.accountType,
          isPreferredAcc: accForEditDetail.isPreferredAcc,
          borrowerName: accForEditDetail.borrowerName,
          borrowerType: accForEditDetail.borrowerType,
          thirdBorrowerName: accForEditDetail.borrowerName,
          confirmBankAccNumber: accForEditDetail.bankAccountNumber ? decrypt(accForEditDetail.bankAccountNumber) : ''
        })
        if (accForEditDetail.borrowerType === 'T') {
          setThirdPartyName(true);
        } else {
          setThirdPartyName(false);
        }
        setEditBtn(true);
      }
      setEditDataLoader(false);
      setCoBoError('');
    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
      setCoBoName(response);
      setCoBoError(response.lstResponseStatus[0].statusDesc);
      setThirdPartyName(false);
      setEditDataLoader(false);
    } else {
      setCoBoError(renderDefaultMsg('400'));
      setEditDataLoader(false);
    }
  }


  const borowerName = (data) => {
    if (data.borrowerType.length) {
      setFormInputError({
        ...formInputError,
        borrowerType: ''
      })
    }

    if (data.borrowerType === "T") {
      setThirdPartyName(true);
      setFormItem({ ...formItem, ['borrowerType']: data.borrowerType });
    } else {
      setThirdPartyName(false);
      setFormItem({
        ...formItem,
        ['borrowerType']: data.borrowerType,
        ['borrowerName']: data.borrowerName
      });
    }
  }

  const handleFormTextChange = (key, val) => {
    if (val.trim().length) {
      if (key === 'nickName') {
        const filterNickName = data?.filter(details => details?.nickName?.toLowerCase() == val?.toLowerCase())

        if ((filterNickName?.length > 0) && (accForEditDetail?.nickName?.toLowerCase() !== val?.toLowerCase())) {
          setFormInputError({
            ...formInputError,
            [key]: 'Account nickname already in use'
          })
        } else {
          setFormInputError({
            ...formInputError,
            [key]: ''
          })
        }
      } else {
        setFormInputError({
          ...formInputError,
          [key]: ''
        })
      }
    }
    if (key === 'bankName') {
      setFormItem({ ...formItem, [key]: val.replace(/[^\w\s]/gi, "") });
    } else if (key === 'abaRoutingNumber') {
      if ((/^[0-9]+$/).test(decrypt(val)) || decrypt(val) === '') {
      setFormItem({ ...formItem, [key]: val });
      setABAValChanged(true);
      }
    } else {
      setFormItem({ ...formItem, [key]: val });
    }
  }

  const handleFormAccChange = (key, val) => {
    if ((/^[0-9]+$/).test(val) || val === '') {
      if (val.trim().length) {
        const filterAccountNumber = data?.filter(acc => (decrypt(acc?.bankAccountNumber) == val))
        if ((filterAccountNumber?.length > 0) && ((accForEditDetail?.bankAccountNumber == null) ?  true :  (decrypt(accForEditDetail?.bankAccountNumber) !== val))) {
          setFormInputError({
            ...formInputError,
            [key]: 'Bank account number already in use'
          })
        } else {
          setFormInputError({
            ...formInputError,
            [key]: ''
          })
        }
      }
      setFormItem({ ...formItem, [key]: val });
    }
  }
 
  const handleConfirmAccNoChange = (key, val) => {
    if ((/^[0-9]+$/).test(val) || val === '') {
      if (val.trim().length) {
          setFormInputError({
            ...formInputError,
            [key]: ''
          })
        }
        setFormItem({ ...formItem, [key]: val });
      }
  }

  const pageRouting = (page) => {
    clearEditStore();
    history.push('/' + page);
  }

  console.log(formInputError)

  const validateForm = () => {
    const formErrors = {
      bankName: "",
      abaRoutingNumber: "",
      nickName: "",
      bankAccountNumber: "",
      borrowerType: '',
      thirdBorrowerName: '',
      confirmBankAccNumber: ''
    }
    let errorCount = 0

    setFormInputError({ ...formErrors, errorCount })

    if (formItem.nickName.trim() === '') {
      formErrors.nickName = "Nick name cannot be blank";
      errorCount++
    }
    if (formItem.borrowerType === '') {
      formErrors.borrowerType = "Name on bank account cannot be blank";
      errorCount++
    }
    if (formItem.borrowerType === 'T' && formItem.thirdBorrowerName.trim() === '') {
      formErrors.thirdBorrowerName = "Name on bank account(Other) cannot be blank";
      errorCount++
    }

    if (formItem.abaRoutingNumber.trim() === ('' || null || "")) {
      formErrors.abaRoutingNumber = "ABA Routing Number cannot be blank";
      errorCount++
    }
    if (formItem.bankAccountNumber.trim() === '') {
      formErrors.bankAccountNumber = "Account number cannot be blank";
      errorCount++
    }
    if (formItem.bankName.trim() === '') {
      formErrors.bankName = "Bank name cannot be blank";
      errorCount++
    }

    setFormInputError({
      ...formErrors,
      errorCount
    })

    return errorCount
  }
  const handleFormSubmit = async (e) => {

    let nickNameStatus = false;
    if (editBtn) {
      if ((decrypt(accForEditDetail?.bankName) != formItem?.bankName) || (accForEditDetail?.abaRoutingNumber != formItem?.abaRoutingNumber) || (accForEditDetail?.accountType != formItem?.accountType) || (decrypt(accForEditDetail?.bankAccountNumber) != formItem?.bankAccountNumber) || (accForEditDetail?.borrowerType != formItem?.borrowerType) || (accForEditDetail?.borrowerName != formItem?.borrowerName) || (accForEditDetail?.borrowerName != formItem?.thirdBorrowerName)) {
        nickNameStatus = false
      } else if ((accForEditDetail?.nickName != formItem?.nickName)) {
        nickNameStatus = true
      } else {
        nickNameStatus = true
      }
    } else {
      nickNameStatus = true;
    }
    
    e.preventDefault();
    if (validateForm() === 0 && abAError === '') {
      setFormSubmit(true);
      setAbAError('');
      const data = {
        "bankProfileID": formItem.bankProfileID,
        "bankCity": "",
        "bankState": "",
        "bankName": encrypt(formItem.bankName),
        "abaRoutingNumber": formItem.abaRoutingNumber,
        "borrowerType": formItem.borrowerType,
        "borrowerName": formItem.borrowerType === 'T' ? formItem.thirdBorrowerName : formItem.borrowerName,
        "nickName": formItem.nickName,
        "bankAccountNumber": encrypt(formItem.bankAccountNumber),
        "accountType": formItem.accountType,
        // "isPreferredAcc": formItem.isPreferredAcc,
        "privateLabelUrl": Cookies.get("privateLabelUrl"),
        "ipAddress": Cookies.get('publicIp'),
        "browser": Cookies.get("browser"),
        "appSource": getAppSource(),
        "userType": getUserType(),
        "userName": "",
        "oldABARoutingNumber": accForEditDetail != null ? accForEditDetail.abaRoutingNumber : null,
        "oldBankAccountNumber": accForEditDetail != null ? accForEditDetail.bankAccountNumber : null,
        "isNickNameChanged": nickNameStatus
      }

      const response = await addEditBankAccount(data);
      if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
        setFormSubmit(false);
        setErrorMsg("");
        setFormItem({
          bankProfileID: 0,
          bankName: "",
          abaRoutingNumber: "",
          nickName: "",
          bankAccountNumber: "",
          accountType: "",
          isPreferredAcc: false,
          borrowerType: '',
          thirdBorrowerName: '',
          confirmBankAccNumber: ''
        });
        await dispatch(storeClearAfterEdit(null));
         //Generic toaster implementation
        let bankAccountNumber = accForEditDetail?.bankAccountNumber?decrypt(accForEditDetail?.bankAccountNumber):'';
        let bankName = accForEditDetail?.bankName?decrypt(accForEditDetail?.bankName):'';
        let message = `Your bank account ending in ${maskAccountNo(bankAccountNumber)} ${bankName} has been updated successfully.`;
        if(!editBtn)
        message = `Your bank account ending in ${maskAccountNo(formItem.bankAccountNumber)} ${formItem.bankName} has been added successfully.`;
        if (response?.editACHResponseList?.length == 1) {
          setRequestId(response?.editACHResponseList)
          setUpdateStatusForSingleAch(true)
        } else if (response?.editACHResponseList?.length > 1) {
          setRequestId(response?.editACHResponseList)
          setUpdateStatusForSingleAch(true)
        } else {
          await dispatch(showToaster({status:true,message:message,successToast:true}));
          history.push('/ManageBankAccount');
        }
      } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
        setErrorMsg(response.lstResponseStatus[0].statusDesc);
        setFormSubmit(false);
      } else {
        setErrorMsg(renderDefaultMsg('400'));
        setFormSubmit(false);
      }
    } else {
      scrollTop();
      if (formItem.nickName.trim() === '') {
        myRef.current['nickNameFocus'].focus();
        return;
      }
      if (formItem.borrowerType === '') {
        myRef.current['borrowerTypeFocus'].focus();
        return;
      }
      if (formItem.borrowerType === 'T' && formItem.thirdBorrowerName.trim() === '') {
        myRef.current['thirdBorrowerNameFocus'].focus();
        return;
      }
      if (formItem.abaRoutingNumber.trim() === '' || abAError !== '') {
        myRef.current['abaRoutingNumberFocus'].focus();
        return;
      }
      if (formItem.bankAccountNumber.trim() === '') {
        myRef.current['bankAccountNumberFocus'].focus();
        return;
      }
      if (formItem.confirmBankAccNumber.trim() === '') {
        myRef.current['confirmBankAccNumberFocus'].focus();
        return;
      }
      if (formItem.bankName.trim() === '') {
        myRef.current['bankNameFocus'].focus();
        return;
      }
    }
  }

  const clearEditStore = async () => {
    await dispatch(storeClearAfterEdit(null));
  }

  const checkAbA = (val) => {
    if (val.length == 9 && !invalidAbaRoutingNoStatus) {
      aBAValChanged && validateRoutingNo(val)
    }
  }

  const validateAbaRoutingNo = (val) => {
    if ((/^[0-9]+$/).test(val)) {
      if (consecutiveDigits(val) && checkAllDigits(val) && reverseConsecutiveDigits(val)) {
        setAbAError('')
        setErrorMsg('')
        setInvalidAbaRoutingNo(false)
      } else {
        setAbAError('Invalid ABA Routing Number')
        setFormItem({ ...formItem, bankName: '', bankCity: '' })
        setInvalidAbaRoutingNo(true)
      }
    } else {
      setAbAError('')
      setInvalidAbaRoutingNo(false)
    }
  }

  const validateRoutingNo = async (routingNo) => {
    
    setErrorMsg('');
    setValidateRountingLoader(true)
    const data = {
      "routingNumber": routingNo,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };
    const response = await getBankDetails(data);
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
      setValidateRountingLoader(false);
      setABAValChanged(false)

      let { bankName, routingNumber, city, state } = response.bankDetail;
      bankName = decrypt(bankName);
      setFormItem({
        ...formItem,
        bankName: bankName && bankName.length !== 0 && bankName !== 'NA' ? bankName.replace(/[^\w\s]/gi, "") : formItem.bankName.length ? formItem.bankName : '',
        abaRoutingNumber: routingNumber,
        bankCity: city,
      })
      setAbAError('');
      (decrypt(response.bankDetail.bankName) === '' || decrypt(response.bankDetail.bankName) === 'NA' && formItem.bankName === '')
        ? setFormInputError({ ...formInputError, bankName: 'Bank name cannot be blank.' }) : setFormInputError({ ...formInputError, bankName: '' })

    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
      setValidateRountingLoader(false);
      setABAValChanged(false)
      setAbAError(response.lstResponseStatus[0].statusDesc);
      setFormItem({ ...formItem, bankName: '', bankCity: '' })
    } else {
      setValidateRountingLoader(false);
      setABAValChanged(false)
      setAbAError('Invalid ABA Routing Number.');
      setFormItem({ ...formItem, bankName: '', bankCity: '' })
    }
  };

  // const disableBtn = ((formItem.nickName ? formItem.nickName.trim().length === 0 ? true : false : true) ||
  //   (formItem.abaRoutingNumber ? formItem.abaRoutingNumber.trim().length === 0 ? true : false : true) ||
  //   formItem.bankAccountNumber === '' ||
  //   (formItem.bankAccountNumber ? formItem.bankAccountNumber.trim().length === 0 ? true : false : true) ||
  //   (formItem.bankName ? formItem.bankName.trim().length === 0 ? true : false : true) ||
  //   abAError !== '' || formItem.borrowerType === '' ||
  //   (formItem.borrowerType ? formItem.borrowerType === 'T' ? (formItem.thirdBorrowerName ? formItem.thirdBorrowerName.trim().length === 0 ? true : false : true) :
  //     (formItem.borrowerType.trim().length === 0 ? true : false) : true))

  const disableBtn = false;


  let allCoBorrwerNameData = '';
  if (coBoName && coBoName.length) {
    allCoBorrwerNameData = coBoName.map((item, index) => {
      return (
        <MenuItem value={item.borrowerType} key={item.borrowerName} onClick={() => borowerName(item)}>{item.borrowerName}</MenuItem>
      )
    })
  }

  const disableCopyPaste = (e) => {
    e.preventDefault();
  }

  if (editDataLoader) {
    return (
      <Box className='loaderRoot'>
        <CircularProgress />
      </Box>
    )
  }
  const handleClickShowPassword = (key) => {
    setValues({ ...values, [key]: !values[key] });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleUpdateSubmit = () => {
   setUpdateStatusForSingleAch(false)
   history.push('/ManageBankAccount');
  }
 
  const validateConfirmAccNo = () => {
    if (formItem?.bankAccountNumber?.length > 0 && formItem?.confirmBankAccNumber?.length > 0) {
      if (formItem?.bankAccountNumber !== formItem.confirmBankAccNumber) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  return (
    <Box className={classes.pageBg}>
      <Box className={classes.root} >
        <CustomBreadcrum backPage="Payments" backPageLink="/PaymentOption" activePage="Manage Bank Accounts" />
        <Box className={classes.topContainer} mx={0}
          display="flex"
          justifyContent="space-between"
          alignItems="center" flexWrap="wrap" width="100%">
          <Typography className={classes.pageHeading} color="textPrimary" component='h4'>{editBtn ? 'Edit' : 'Add'} Bank Account</Typography>
        </Box>
        <BoxWithShadow position="relative" bgColor='#fff'
          boxShadow='0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)'>
          <Box my={1} mx={2} className={classes.formContainer}>
          {coBoError !== '' ? <p className={classes.errorMsg}>{coBoError}</p> : null}

            <form className={classes.formRoot} noValidate autoComplete="off" onSubmit={handleFormSubmit}>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={12} className={classes.rowMargin}>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={6} md={4}>
                      <div>
                        <TextField
                          inputRef={el => myRef.current['nickNameFocus'] = el}
                          error={formInputError.nickName !== '' ? true : false}
                          helperText={formInputError.nickName !== '' ? formInputError.nickName : null}
                          label="Account Nickname"
                          type="text"
                          value={formItem.nickName}
                          variant="outlined"
                          className={classes.inputStyle}
                          fullWidth
                          onChange={event => handleFormTextChange('nickName', event.target.value)}
                          inputProps={{
                            maxLength: 15,
                            className: classes.inputStyle,
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <div>
                        <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}
                          error={formInputError.borrowerType !== '' ? true : false}>
                          <InputLabel id="borrower-type-select-outlined">Name on Bank Account</InputLabel>
                          <Select
                            labelId="borrower-type-outlined-label"
                            inputRef={el => myRef.current['borrowerTypeFocus'] = el}
                            value={formItem.borrowerType}
                            label="Borrower Name"
                            className={classes.inputStyle}
                            fullWidth
                          >
                            {allCoBorrwerNameData}
                          </Select>
                          <FormHelperText>{formInputError.borrowerType !== '' ? formInputError.borrowerType : null}</FormHelperText>
                        </FormControl>
                      </div>
                    </Grid>
                    {thirdPartyName ? <Grid item xs={12} sm={6} md={4}>
                      <div>
                        <TextField
                          inputRef={el => myRef.current['thirdBorrowerNameFocus'] = el}
                          error={formInputError.thirdBorrowerName !== '' ? true : false}
                          helperText={formInputError.thirdBorrowerName !== '' ? formInputError.thirdBorrowerName : null}
                          label="Name on Bank Account (Other)"
                          type="text"
                          value={formItem.thirdBorrowerName}
                          variant="outlined"
                          className={classes.inputStyle}
                          fullWidth
                          onChange={event => handleFormTextChange('thirdBorrowerName', event.target.value)}
                          inputProps={{
                            maxLength: 30,
                            className: classes.inputStyle,
                          }}
                        />
                      </div>
                    </Grid> : null}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} >
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={6} md={4}>
                      <div style={{ position: 'relative' }} >
                        <TextField
                          inputRef={el => myRef.current['abaRoutingNumberFocus'] = el}
                          error={(formInputError.abaRoutingNumber !== '' || abAError !== '') ? true : false}
                          helperText={(formInputError.abaRoutingNumber !== '' || abAError !== '') ? formInputError.abaRoutingNumber : null}
                          label="ABA Routing Number"
                          type="text"
                          value={formItem.abaRoutingNumber ? decrypt(formItem.abaRoutingNumber) : ''}
                          variant="outlined"
                          className={classes.inputStyle}
                          fullWidth
                          onBlur={(event) => checkAbA(event.target.value)}
                          onChange={event => handleFormTextChange('abaRoutingNumber', event.target.value == '' ? '' : encrypt(event.target.value))}
                          onKeyUp={(event) => validateAbaRoutingNo(event.target.value)}
                          inputProps={{
                            maxLength: 9,
                            className: classes.inputStyle,
                            form: {
                              autocomplete: 'off',
                            },
                          }}
                        />
                        {abAError !== '' && <h6 className={classes.abaError}>{abAError}</h6>}
                        {((formItem.bankName !== '' || formItem.bankCity !== '') && (formItem.bankCity !== 'NA')) ? <h6 className={classes.aBaBankDetails}>{formItem.bankName}, {formItem.bankCity}</h6> : null}

                        {validateRountingLoader && <CircularProgress
                          className={classes.validateRLoader}
                          color='primary' />}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <div>
                        <TextField
                          inputRef={el => myRef.current['bankNameFocus'] = el}
                          error={formInputError.bankName !== ''}
                          helperText={formInputError.bankName !== '' ? formInputError.bankName : null}
                          disabled={validateRountingLoader}
                          label="Bank Name"
                          type="text"
                          value={formItem.bankName ? formItem.bankName : ''}
                          variant="outlined"
                          className={classes.inputStyle}
                          fullWidth
                          onChange={event => handleFormTextChange('bankName', event.target.value)}
                          inputProps={{
                            maxLength: 50,
                            className: classes.inputStyle,
                            autocomplete: "new-password",
                            form: {
                              autocomplete: 'no',
                            },
                          }}
                        />
                      </div>
                    </Grid>
                    
                  </Grid>
                </Grid>


                <Grid item xs={12} sm={12} >
                  <Grid container spacing={5}>
                   

                    <Grid item xs={12} sm={6} md={4}>
                      <div>
                        <TextField
                          inputRef={el => myRef.current['bankAccountNumberFocus'] = el}
                          error={formInputError.bankAccountNumber !== '' ? true : false}
                          helperText={formInputError.bankAccountNumber !== '' ? formInputError.bankAccountNumber : null}
                          disabled={validateRountingLoader}
                          label="Account Number"
                          type="password"
                          value={formItem.bankAccountNumber ? formItem.bankAccountNumber : ''}
                          variant="outlined"
                          className={classes.inputStyle}
                          fullWidth
                          onChange={event => handleFormAccChange('bankAccountNumber', event.target.value)}
                          autoComplete= 'off'
                          inputProps={{
                            maxLength: 17,
                            className: classes.inputStyle,
                            form: {
                              autocomplete: 'off',
                            },
                          }}
                        /> 
                         {/* <FormControl variant="outlined" fullWidth >
                              <InputLabel className={classes.labelStyle} htmlFor="outlined-adornment-password">Account Number</InputLabel>
                              <OutlinedInput
                                color='primary'
                                id="outlined-adornment-password"
                                value={formItem.bankAccountNumber ? formItem.bankAccountNumber : ''}
                                onChange={event => handleFormAccChange('bankAccountNumber', event.target.value)}
                                inputRef={el => myRef.current['bankAccountNumberFocus'] = el}
                                error={formInputError.bankAccountNumber !== '' ? true : false}
                                helperText={formInputError.bankAccountNumber !== '' ? formInputError.bankAccountNumber : null}
                                disabled={validateRountingLoader}
                                autocomplete="off"
                                label="Account Number"
                                className={classes.inputStyle}
                                inputProps={{
                                  maxLength: 17,
                                  className: classes.inputStyle,
                                }}
                                endAdornment={
                                  <InputAdornment position="end" tabIndex="-1">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                        onClick={()=>{handleClickShowPassword('showAccNumber')}}
                                       onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                     
                                    >
                                      {values.showAccNumber ? <Visibility tabIndex="-1" /> : <VisibilityOff tabIndex="-1" />}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                            </FormControl> */}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <div>
                       <TextField
                          inputRef={el => myRef.current['confirmBankAccNumberFocus'] = el}
                          error={validateConfirmAccNo()}
                          helperText={validateConfirmAccNo() ? "Bank Account number doesn't match" : null}
                          label="Confirm Account Number"
                          type="text"
                          value={formItem.confirmBankAccNumber ? formItem.confirmBankAccNumber : ''}
                          variant="outlined"
                          className={classes.inputStyle}
                          fullWidth
                          onChange={event => handleConfirmAccNoChange('confirmBankAccNumber', event.target.value)}
                          inputProps={{
                            maxLength: 17,
                            className: classes.inputStyle,
                          }}
                          onCut={disableCopyPaste}
                          onCopy={disableCopyPaste}
                          onPaste={disableCopyPaste}
                        /> 
                          {/* <FormControl variant="outlined" fullWidth >
                              <InputLabel className={classes.labelStyle} htmlFor="outlined-adornment-password">Confirm Account Number</InputLabel>
                              <OutlinedInput
                                color='primary'
                                id="outlined-adornment-password"
                                autocomplete="new-password"
                                error={validateConfirmAccNo()}
                                helperText={((formItem?.bankAccountNumber?.length > 3) && (formItem?.bankAccountNumber !== formItem?.confirmBankAccNumber)) ? "Bank Account number doesn't match" : null}
                                value={formItem.confirmBankAccNumber}
                                labelWidth={100}
                                className={classes.inputStyle}
                                onCut={disableCopyPaste}
                                onCopy={disableCopyPaste}
                                onPaste={disableCopyPaste}
                                onChange={event => handleConfirmAccNoChange('confirmBankAccNumber', event.target.value)}
                                inputRef={el => myRef.current['confirmBankAccNumberFocus'] = el}

                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                       onClick={()=>{handleClickShowPassword('showConfirmAccNumber')}}

                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                      // tabIndex="-1"
                                    >
                                      {values.showConfirmAccNumber ? <Visibility tabIndex="-1" /> : <VisibilityOff tabIndex="-1" />}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                inputProps={{
                                  maxLength: 17,
                                  className: classes.inputStyle,
                                }}
                              />
                               <FormHelperText style={{ color: 'red' }}>{(((formItem?.bankAccountNumber?.length > 2) !== (formItem?.confirmBankAccNumber?.length > 2))) ? "Bank Account number doesn't match" : null}</FormHelperText>
                            </FormControl> */}
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} >
                      <Box className={classes.accRadioType}>Account Type</Box>
                      <div className={classes.radioBtn}>
                        <RadioGroup row aria-label="account_type" name="account_type" value={value}
                          onChange={handleRadioChange}>
                          <FormControlLabel value="C" checked={formItem.accountType === 'C' ? true : false}
                            control={<Radio />} label="Checking" />
                          <FormControlLabel value="S" checked={formItem.accountType === 'S' ? true : false}
                            control={<Radio />} label="Savings" />
                        </RadioGroup>
                      </div>
                    </Grid>

                    {/* <Grid item xs={12} sm={6} md={4}>
                      <div>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formItem.isPreferredAcc}
                                onChange={handleChange}
                                name="checkPreferredAcc"
                                color="primary"
                                className={classes.inputStyle}
                              >
                              </Checkbox>
                            }
                            label={<Box className={classes.checkboxItem}>
                              <BookmarkIcon /> Make preferred account</Box>}
                          />
                        </FormGroup>
                      </div>
                    </Grid> */}

                  </Grid>
                </Grid>


                <Grid item xs={12} sm={12}  >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <div>
                        <img src={checkImg} alt="check icon" className={classes.fullWidth} />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <p className={classes.errorMsg}>{errorMsg !== '' ? errorMsg : ''}</p>
                  <Box className={`${classes.buttonContainer}`} display="flex" justifyContent="center" style={{ paddingBottom: '25px' }}>
                    <Button className={`${classes.cancelAccButton}`} mt={2}
                      variant="outlined"
                      onClick={() => pageRouting('ManageBankAccount')}>
                      Cancel
                  </Button>
                    {formSubmit ?
                      <Button className={`${classes.addAccButton}`} mt={2}
                        type="submit"
                        variant="contained"
                        disabled={formSubmit}>
                        <CircularProgress style={{ height: '24px', width: '24px' }} color='primary' />
                      </Button>
                      :
                      <Button className={`${classes.addAccButton}`} mt={2}
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isAdmin() || disableBtn || formInputError?.bankAccountNumber !== '' || formInputError?.nickName || (formItem.bankAccountNumber !== formItem.confirmBankAccNumber)}>
                        {editBtn ? 'Update Account' : 'Add Account'}
                      </Button>
                    }
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </BoxWithShadow>
      </Box>
      { updateStatusForSingleAch &&
        <UpdateBankAccRequestPopup
         status={updateStatusForSingleAch}
         data={requestId}
         handleSubmit={handleUpdateSubmit}/>
      }
    </Box>
  )
}