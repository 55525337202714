import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "95%",
    minHeight: '100vh',
    padding: "3em 0em",
    margin: '0 auto',
  },

  bgg: {
    width: '100%',
    backgroundColor: '#ccc',
  },

  title: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: "600",
    marginBottom: 20,
  },

  formStyle: {
    width: '100%',
  },

  tableBox: {
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: 12,
    marginBottom: 20,
    paddingBottom: 20,
  },

  stepBox: {
    width: '100%',
    backgroundColor: 'rgb(126, 177, 204)',
    padding: '10px',
  },

  stepText: {
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "600",
  },

  noteBox: {
    width: '100%',
    padding: '10px',
    marginTop: 10,
    marginBottom: 20
  },

  noteText: {
    color: "rgba(0, 0, 0, 0.74)",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "400",
  },

  inputContainer: {
    width: '100%',
    padding: '0 16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  label: {

  },

  inputField: {

  },

  formControl: {
    minWidth: 120,
    width: '30%',
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

  errorBox: {
    width: '100%',
    backgroundColor: '#fff',
    marginBottom: 20,
    padding: 10,
  },

  errorText: {
    color: "red",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "400",
    textAlign: 'center',
    marginBottom: 20,
  },

  autocompleteContainer: {
    width: 400,
    display: 'flex',
    alignItems: 'center',
  },

  selectAll: {
    marginLeft: 20
  },

  errorDataBox: {
    padding: 10,
    marginBottom: 20,
  },


  errorDescription: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "400",
  },




}));

