import React, { useState, useEffect } from 'react';
import { Box, Button } from "@material-ui/core";
import { useStyles, StyledTableCell, StyledTableRow } from './styles';
import {
  TextField, Select, FormControl,
  MenuItem, InputLabel, FormControlLabel,
  Checkbox, Table, TableBody, TableContainer, TableFooter,
  TablePagination, TableRow, Paper, TableHead, CircularProgress
} from '@material-ui/core';
import { getPrivatelabel, getBorrowerNotification, activateDeactivateNotification } from '../../../../actions';
import { decrypt, encrypt } from './../../../../helpers/encrypt';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import adminIcon from '../../../../assets/Admin/adminIcon.svg'
import { checkDigitOnly, checkValidEmail, getAppSource, getUserType } from '../../../../helpers'

export const NotificationSetup = () => {

  const classes = useStyles();
  const [privateLabelName, setPrivateLabelName] = useState(' ');
  const [searchByKey, setSearchByKey] = useState('1');
  const [searchByValue, setSearchByValue] = useState('');
  const [privateLabels, setPrivateLabels] = useState([]);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [notifications, setNotifications] = useState([]);
  const [loader, setLoader] = useState(false);
  const [emailAddressError, setEmailAddressError] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePrivateLabelNameChange = (event) => {
    setPrivateLabelName(event.target.value);
  };

  const handleSearchKeyChange = (event) => {
    setEmailAddressError(false);
    setSearchByValue('')
    setSearchByKey(event.target.value);
  };

  const handleSearchValueChange = (event) => {
    if (searchByKey === '1') {
      if (checkDigitOnly(event.target.value)) {
        setSearchByValue(event.target.value.trim());
      }
      return;
    } else if (searchByKey === '2') {
      setEmailAddressError(false);
      setSearchByValue(event.target.value.trim());
      return;
    }
    setSearchByValue(event.target.value.trim());
  };


  const validateEmailAddress = (emailId) => {
    checkValidEmail(emailId) ? setEmailAddressError(false) : setEmailAddressError(true)
  }

  const getPrivatelabels = async () => {
    const res = await getPrivatelabel();
    if (res && res.lstResponseStatus[0].statusCode === '0') {
      setPrivateLabels(res.privateLabelList);
    }
  }

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(0)
    getNotifications();
  }

  const renderSerchByKey = () => {
    switch (searchByKey) {
      case "1":
        return 'Enter Loan Number';
      case "2":
        return 'Enter User Id';
      case "3":
        return 'Enter Email Id';
      case "4":
        return 'Enter Investor Code';
      case "5":
        return 'Enter Title';
      default:
        return 'Enter Loan Number';
    }
  }


  const getNotifications = async () => {
    setLoader(true);
    setNotifications([]);
    setTotalRecords(0)
    const data = {
      "pageIndex": page,
      "pageSize": rowsPerPage,
      "privateLabelName": privateLabelName,
      "searchById": searchByKey,
      "searchValue": searchByValue.length ? encrypt(searchByValue) : '',
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
    }
    const res = await getBorrowerNotification(data);
    if (res && res.lstResponseStatus[0].statusCode === '0') {
      setNotifications(res.lstBorrowerNotification);
      setTotalRecords(res.totalRecords)
    }
    setLoader(false);
  }

  const handleActivate = async (id, active, accountNumber) => {
    setLoader(true);
    const data = {
      "notificationId": id,
      "isActive": !active,
      "accountNumber": accountNumber,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
    }
    const res = await activateDeactivateNotification(data);
    if (res && res.lstResponseStatus[0].statusCode === '0') {
      await getNotifications()
    }
    setLoader(false);
  }

  useEffect(() => {
    getPrivatelabels();
  }, []);

  useEffect(() => {
    getNotifications();
  }, [page, rowsPerPage])

  return (
    <Box className={classes.bgg}>
      <Box className={classes.root}>
        <h1 className={classes.title}>Borrower Notification Setup</h1>
        <form className={classes.formStyle} onSubmit={handleSearch}>
          <Box className={classes.searchBox}>
            <Box className={classes.inputBox}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="company-name" className={classes.privateLabel}>Company Name</InputLabel>
                <Select
                  labelId="company-name"
                  id='company-name-select'
                  labelWidth={125}
                  displayEmpty
                  value={privateLabelName}
                  onChange={handlePrivateLabelNameChange}
                  inputProps={{
                    className: classes.inputStyle,
                  }}
                >
                  <MenuItem value=' '>All</MenuItem>
                  {privateLabels.length ?
                    privateLabels.map(label => (
                      <MenuItem
                        key={label.id}
                        value={label.privateLabelName}
                      >
                        {label.privateLabelName}
                      </MenuItem>
                    )) : null}
                </Select>
              </FormControl>
            </Box>

            <Box className={classes.searchByBox}>
              <Box className={classes.inputBox}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="company-name" className={classes.privateLabel}>Search By</InputLabel>
                  <Select
                    labelId="search-by"
                    id='search-by-select'
                    labelWidth={80}
                    displayEmpty
                    value={searchByKey}
                    onChange={handleSearchKeyChange}
                    inputProps={{
                      className: classes.inputStyle,
                    }}
                  >
                    <MenuItem value={'1'}>Loan Number</MenuItem>
                    <MenuItem value={'2'}>User Id </MenuItem>
                    <MenuItem value={'3'}>Email Id</MenuItem>
                    <MenuItem value={'4'}>Investor Code</MenuItem>
                    <MenuItem value={'5'}>Title </MenuItem>

                  </Select>
                </FormControl>
              </Box>

              <Box className={classes.inputBox}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    error={(emailAddressError && searchByValue !== '')}
                    id="search-by-value"
                    variant="outlined"
                    placeholder={renderSerchByKey()}
                    value={searchByValue}
                    onChange={handleSearchValueChange}
                    onBlur={searchByKey === '3' ? validateEmailAddress : null}
                    inputProps={{
                      className: classes.textInputStyle,
                      maxLength: searchByKey === '1' ? 10 : searchByKey === '2' ? 100 : 100,
                    }}
                  />
                </FormControl>
              </Box>
            </Box>

            <Box className={classes.searchBtnBox}>
              <Button
                className={classes.searchBtn}
                type='submit'
                color='primary' variant='contained'
              >
                Search
            </Button>
            </Box>
          </Box>
        </form>
        <Box className={classes.mapBox}>
          <Button
            component={Link} to='/mapNotification'
            className={classes.searchBtn}
            color='primary' variant='contained' >Map Borrower For Notification </Button>
        </Box>
        <Box className={classes.tableBox}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">User Name </StyledTableCell>
                  <StyledTableCell align="center">Full Name</StyledTableCell>
                  <StyledTableCell align="center">Loan Number</StyledTableCell>
                  <StyledTableCell align="center">Email</StyledTableCell>
                  <StyledTableCell align="center">Company Name</StyledTableCell>
                  {/* <StyledTableCell align="center">Notification Type</StyledTableCell> */}
                  <StyledTableCell align="center">Notification Title</StyledTableCell>
                  <StyledTableCell align="center">Delete</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loader && notifications.length ?
                  notifications.map(row => {
                    return (
                      <StyledTableRow key={row.id}>
                        <StyledTableCell align="center" >{decrypt(row.userName)} </StyledTableCell>
                        <StyledTableCell align="center" >{`${row.firstName} ${row.lastName}`} </StyledTableCell>
                        <StyledTableCell align="center" >{decrypt(row.accountNumber)} </StyledTableCell>
                        <StyledTableCell align="center">{decrypt(row.emailID)} </StyledTableCell>
                        <StyledTableCell align="center">{row.privateLabelName} </StyledTableCell>
                        {/* <StyledTableCell align="center">Type</StyledTableCell> */}
                        <StyledTableCell align="center">{row.notificationTitle}</StyledTableCell>
                        <StyledTableCell align="center">
                          <button onClick={() => handleActivate(row.notificationId, row.isActive, row.accountNumber)} className={classes.actionBtn}>{row.isActive ? 'Deactivate' : 'Activate'}</button>
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                  })
                  :
                  <StyledTableRow>
                    <StyledTableCell colSpan={8}>
                      <Box className={classes.loaderBox}>
                        {loader ? <CircularProgress /> : <p className={classes.noDataFoundText}>No data found</p>}
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    count={totalRecords}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  /></TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Box >
    </Box >
  )
}

