import React from 'react';
import { useStyles } from './styles';
import { Box, Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';


export const SubmitAlert = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={props.handleSubmitClose} open={props.open}>
     
      <Box className={classes.root}>
        <IconButton onClick={props.handleSubmitClose} className={classes.closeIcon}>
          <CancelIcon />
        </IconButton>
          <Box className={classes.cardBody}>
            <Box className={classes.statusContainer}>
            <Typography className={classes.msg}>
               This feature is only available for New York.</Typography> 
              <Typography className={classes.statusTitle}>Are you sure you want to submit?</Typography>
            </Box>
        </Box>
        <Box className={classes.cardFooter}>
          <Box className={classes.footerItem}>
            <Button
              variant='contained'
              color='primary'
              className={classes.btn}
              onClick={props.handleSubmitClose}
            > No</Button>
          </Box>
          <Box className={classes.footerItem}>
            <Button
              variant='contained'
              color='primary'
              className={classes.btn}
              onClick={props.handleSubmit}
            > Yes</Button>
          </Box>
        </Box>
      </Box> 
    </Dialog>
  )
}
