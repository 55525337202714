import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Grid, Typography, Button, CircularProgress, Tooltip } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Snackbar from '@material-ui/core/Snackbar';

import home from '../../assets/BankAccounts/home.svg';
import { Link } from 'react-router-dom';

import { renderDefaultMsg, maskAccountNo, scrollTop, getUserType, getAppSource, isAdmin } from '../../helpers'
import { decrypt } from '../../helpers/encrypt';
import { useStyles } from './styles';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { setPaymentOption } from '../../actions';

import { getBankAccountDetail, storeClearAfterEdit } from '../../actions';
import CustomBreadcrum from '../Breadcrum';
import InfoIcon from '@material-ui/icons/Info';
import CancelIcon from '@material-ui/icons/Cancel';

import { getPreferredBankAccount, getDeleteBankAccount, storeAccForEdit, getAchAddEditBankDetails } from '../../actions'
import { DeleteConfirmationPopup } from './DeleteConfirmationOnAchAccount/DeleteConfirmationPopup';
import { UpdateBankAccRequestPopup } from './UpdateBankAccRequest/UpdateBankAccRequestPopup';
import { DeleteConfirmation } from './DeleteConfirmationOnAccount/DeleteConfirmation';
import { UpdateConfirmationOnMultiPopup } from './UpdateConfirmationOnMulti/UpdateConfirmationOnMultiPopup';
import { ErrorHandlingPopup } from './ErrorHandling/ErrorHandlingPopup';
import { withStyles } from '@material-ui/core/styles';

const LightTooltip = withStyles((theme) => ({
  arrow: {
    color: '#235A86',
    // backgroundColor: '#ffffff',
  },
  tooltip: {
    backgroundColor: '#235A86',
    color: '#fffff',
    fontFamily: "Montserrat",
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    padding: "16px",
    fontSize: 14,
    maxWidth: 350,
    fontWeight: 500,
    borderRadius: 8,
    // border: "1px solid #4D4D4D"
  },
}))(Tooltip);

export const ManageBankAccount = React.memo((props) => {

  const history = useHistory();
  const dispatch = useDispatch()
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false)
  const classes = useStyles();
  const [isMultiLoan, setIsMultiLoan] = useState(false);
  const [bankID, setBankID] = React.useState('');
  const [isFref, setIsFref] = React.useState(false);
  const [error, setError] = React.useState('');
  const [success, setSuccess] = React.useState('');
  const [accountData, setAccountData] = React.useState({});
  const [openSnakbar, setOpenSnakbar] = React.useState(false);
  const [deleteStatus, setDeleteStatus] = React.useState(false);
  const [deleteIndex, setDeleteIndex] = React.useState(false);
  const [message, setMessage] = React.useState('');

  const wrapperRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [autoPayEditStatus, setAutoPayEditStatus] = React.useState(false);
  const [autoPayDeleteStatus, setAutoPayDeleteStatus] = React.useState(false);
  const [autoPayTextStatus, setAutoPayTextStatus] = React.useState(false);
  const [bankAccountNumber, setBankAccountNumber] = React.useState(false);
  const [abaRoutingNo, setAbaRoutingNumber] = React.useState(false);
  const [bankAccName, setBankName] = React.useState('');
  const [updateStatusOnMulti, setUpdateStatusOnMulti] = React.useState(false);
  const [deleteStatusOnMulti, setDeleteStatusOnMulti] = React.useState(false);
  const [loansData, setLoansData] = useState([]);
  const [emailid, setEmailId] = React.useState('');
  const [tooltipMessage, setTooltipMsg] = useState('');
  const [existingAccountNumbers,setExistingAccountNumbers] = useState({});
  const hideSetUpAutoPayText = () => {
    setAutoPayTextStatus(false)
  }

  const handleDeleteBankDetails = (type, ind, item) => {
    if (!isAdmin()) {
      setDeleteIndex(ind)
      getAchBankDetailsOnDelete()
    }
  }

  const handleSubmitDeleteClose = () => {
    setAutoPayDeleteStatus(false)
    setDeleteStatusOnMulti(false)
  }

  const handleSnakClose = () => {
    setOpenSnakbar(false);
  };
  
  const handleClick = (event, id, isF, accouData = null, index, accNumber, abaRoutingNum, bankName) => {
    setBankName(bankName)
    setBankID(id);
    setBankAccountNumber(accNumber)
    setAbaRoutingNumber(abaRoutingNum)
    setIsFref(isF);
    setAccountData(accouData);
    handleMenuItem(index)
  };

  const handleClose = (menu, index) => {
    const newData = [...data];
    newData[index].showMenu = false;
    setData(newData);

    if (menu === 'Preffered') {
      getPreferredAccDetails(bankID, isFref);
    } else if (menu === 'delete') {

      setDeleteStatus(false)
      setAutoPayDeleteStatus(false)
      setDeleteStatusOnMulti(false)
      const bankAccountNumber = newData[index].bankAccountNumber ? decrypt(newData[index].bankAccountNumber) : '';
      const bankName = newData[index].bankName ? decrypt(newData[index].bankName) : '';
      let message1 = `Your bank account ending in ${maskAccountNo(bankAccountNumber)} ${bankName} has been deleted successfully.`
      setMessage(message1)
    }
  };

  useEffect(() => {
    if (message != '')
      getDeleteAccDetails(bankID);
  }, [message])

  const handleEditBankDetails = (item) => {
    // if (!isAdmin()) {
      getAchBankDetailsOnEdit()
    // }
  }

  const getAchBankDetailsOnDelete = async () => {
    const accData = {
      bankProfileID: bankID,
      abaRoutingNumber: abaRoutingNo,
      bankAccountNumber: bankAccountNumber,
      bankName: bankAccName
    };
    const response = await getAchAddEditBankDetails(accData);
    if (response && response?.responseData?.lstResponseStatus && response?.responseData?.lstResponseStatus[0].statusCode === '0') {
      setError('');

      if ((response?.responseData?.responseData?.length == 1) && !isMultiLoan) {
        setLoansData(response.responseData?.responseData)
        setAutoPayDeleteStatus(true)
      } else if ((response?.responseData?.responseData?.length > 0) && isMultiLoan) {
        setLoansData(response.responseData?.responseData)
        setDeleteStatusOnMulti(true)
      } else {
        setDeleteStatus(true)
      }
    } else if (response && response?.responseData?.lstResponseStatus && response?.responseData?.lstResponseStatus[0].statusCode !== '0') {
      setSuccess('');
      setError(response.lstResponseStatus[0].statusDesc);
    } else {
      setSuccess('');
      setError(renderDefaultMsg('400'));
    }
  }

  const getAchBankDetailsOnEdit = async () => {
    const accData = {
      bankProfileID: bankID,
      abaRoutingNumber: abaRoutingNo,
      bankAccountNumber: bankAccountNumber
    };
    const response = await getAchAddEditBankDetails(accData);
    if (response && response?.responseData?.lstResponseStatus && response?.responseData?.lstResponseStatus[0].statusCode === '0') {
      setError('');

      if ((response?.responseData?.responseData?.length == 1) && !isMultiLoan) {
        setLoansData(response.responseData?.responseData)
        setAutoPayEditStatus(true)
      } else if ((response?.responseData?.responseData?.length > 0) && isMultiLoan) {
        setLoansData(response.responseData?.responseData)
        setUpdateStatusOnMulti(true)
      } else {
        pageSwitchAcc()
      }
    } else if (response && response?.responseData?.lstResponseStatus && response.responseData?.lstResponseStatus[0].statusCode !== '0') {
      setSuccess('');
      setError(response.lstResponseStatus[0].statusDesc);
    } else {
      setSuccess('');
      setError(renderDefaultMsg('400'));
    }
  }

  const handleSubmitUpdateClose = () => {
    setAutoPayEditStatus(false)
    setUpdateStatusOnMulti(false)
  }

  const handleUpdateSubmit = () => {
    pageSwitchAcc()
  }

  const pageSwitchAcc = async () => {
    await dispatch(storeAccForEdit(accountData));
    history.push(`/AddBankAccount`);
  }

  //When click on manage autopay in menu
  const manageAutoPay = () => {
    dispatch(setPaymentOption("MANAGEAUTOPAY"));
    if (isMultiLoan) {
      history.push(`/PaymentOption?name=MANAGE_BANK_ACCOUNTS_MULTI`);
    } else {
      history.push(`/PaymentOption?name=MANAGE_BANK_ACCOUNTS_SINGLE`);
    }
  }

  const getAccounts = async () => {
    const totalAccounts = await Cookies.get('totalAccounts');
    if (totalAccounts) {
      Number(totalAccounts) > 1 ? setIsMultiLoan(true) : setIsMultiLoan(false)
    }
  }

  useEffect(() => {
    getAccounts();
  }, []);

  const clearEditStore = async () => {
    await dispatch(storeClearAfterEdit(null));
    history.push(`/AddBankAccount`);
  }

  useEffect(() => {
    scrollTop()
    getData();
  }, []);

  const getPreferredAccDetails = async (bankProfileID, isPreferredAcc) => {
    const accData = {
      bankProfileID: bankProfileID,
      isPreferredAcc: isPreferredAcc,
    };
    const response = await getPreferredBankAccount(accData);
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
      getData();
      setError('');
      setSuccess(response.lstResponseStatus[0].statusDesc);
      setOpenSnakbar(true);
      window.scrollTo(0, 0);
    } else {
      setSuccess('');
      setError(response.lstResponseStatus[0].statusDesc);
    }
  }

  const getDeleteAccDetails = async (bankProfileID) => {
    const accData = {
      bankProfileID: bankProfileID,
      abaRoutingNumber: abaRoutingNo,
      bankAccountNumber: bankAccountNumber,
      bankName: bankAccName
    };
    const response = await getDeleteBankAccount(accData);
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
      getData();
      setError('');
      let message2 = <div><div>Your bank account ending in {maskAccountNo(decrypt(bankAccountNumber))} {decrypt(bankAccName)} has been deleted successfully.</div><br></br><div>Request details were sent to your registered email address, {response?.emailId}.</div></div>
      const msg = !response?.emailId ? message : message2;
      setSuccess(msg);
      setOpenSnakbar(true);
      window.scrollTo(0, 0);
      if (response?.emailId) {
        setAutoPayTextStatus(true)
      } else {
        setAutoPayTextStatus(false)
      }
    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
      setSuccess('');
      setOpenSnakbar(true);
      setError(response.lstResponseStatus[0].statusDesc);
    } else {
      setSuccess('');
      setError(renderDefaultMsg('400'));
    }
  }

  const getData = async () => {
    setIsActive(false);
    setLoading(true);
    const data = {
      "accountNumber": "",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };
    const response = await getBankAccountDetail(data);
    if (response) {
      setData(response.lstBEBankAccountDetails);
      setLoading(false)
    }
    scrollTop()
  }

  useEffect(() => {
    if (isActive) {
      document.addEventListener("click", handleClickOutside, false);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [isActive]);

  const handleClickOutside = event => {
    let selectedData = null;
    if (data.length > 0) {
      selectedData = data.findIndex(item => item.showMenu === true);
    }
    if ((selectedData !== null) && (selectedData !== -1)) {
      if (wrapperRef.current !== null && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        const newData = [...data];
        newData[selectedData].showMenu = false;
        setData(newData);
        setIsActive(false);
      }
    }
  };
  const handleMenuItem = (index) => {
    const newData = [...data];
    newData[index].showMenu ? newData[index].showMenu = false : newData[index].showMenu = true
    newData[index].showMenu = true;
    setData(newData);
    setIsActive(true);
  }

  const handleCancelIcon = () => {
    setDeleteStatus(false)
  }

  const handleSubmitClose = () => {
    setDeleteStatus(false)
  }

  const handleSubmit = () => {
    handleClose('delete', deleteIndex)
  }

  /*
     For api call handling to fetch loan numbers 
  */
   const getMessageFunction =(data)=>{
    if ((data.length > 1) && isMultiLoan) {
      const tooltipMessage = `This bank account is set up for Autopay for loan number(s): ${data.toString()}`;
      setTooltipMsg(tooltipMessage)
    }
    else if ((data.length == 1) && isMultiLoan) {
      const tooltipMessage = `This Bank account is set up for Autopay for loan number: ${data}`;
      setTooltipMsg(tooltipMessage);
    }
   }  
  /*
    For multiloan scenario when user hover selected for autopay then show tooltip with appropriate message
  */
  const getAchLoans = async (id, accNumber, abaRoutingNum) => {
    const accData = {
      bankProfileID: id,
      abaRoutingNumber: abaRoutingNum,
      bankAccountNumber: accNumber
    };
    //Set empty tooltip message
    const tooltipMessage = `This bank account is set up for Autopay for loan number(s):`;
    setTooltipMsg(tooltipMessage)
    const existingAccountNumber = { ...existingAccountNumbers };
    if (!existingAccountNumber[id]) {
      const response = await getAchAddEditBankDetails(accData);
      if (response && response?.responseData?.lstResponseStatus && response?.responseData?.lstResponseStatus[0].statusCode === '0' && response?.responseData?.responseData) {
        setError('');
        let loan = response?.responseData?.responseData.map((data) => {
          return ' ' + decrypt(data?.accountNumber);
        }) || []
        setExistingAccountNumbers({existingAccountNumber,[id]:loan});
        getMessageFunction(loan);
      } else if (response && response?.responseData?.lstResponseStatus && response.responseData?.lstResponseStatus[0].statusCode !== '0') {
        setSuccess('');
        setTooltipMsg('');
        setError(response.lstResponseStatus[0].statusDesc);
      } else {
        setSuccess('');
        setError(renderDefaultMsg('400'));
        setTooltipMsg('');

      }
    }
    else {
      //existingAccountNumbers
      getMessageFunction(existingAccountNumber[id]);
    }
  }

  let manageAccDetails = ''
  if (data && data.length !== 0) {
    manageAccDetails = data.map((item, index) => {
      return (
        <Box className={classes.mainContainer} key={item.bankAccountNumber}>

          {item?.isAchEnable && isMultiLoan &&
            <LightTooltip
              arrow placement="bottom"
              title={tooltipMessage}
            >
              <Button onMouseEnter={() => getAchLoans(item.bankProfileID, item.bankAccountNumber, item.abaRoutingNumber)} variant="outlined" className={`${classes.selectedForAutoPay} ${classes.cursorPointer}`}>
                <BookmarkIcon style={{ color: '#535D6D', marginRight: 5 }} />
                Enrolled for Autopay
              </Button>
            </LightTooltip>
          }
          {item?.isAchEnable && !isMultiLoan &&
            <Button variant="outlined" className={`${classes.selectedForAutoPay} ${classes.pointerEvents}`}>
              <BookmarkIcon style={{ color: '#535D6D', marginRight: 5 }} />
              Enrolled for Autopay
            </Button>
          }
          <Box textAlign="right" display="flex" justifyContent="space-around" className={classes.rightMenuContainer}>
            <div >
              <IconButton
                aria-label="more"
                aria-controls="more-menu"
                aria-haspopup="true"
                disabled={isActive}
                onClick={event => handleClick(event, item.bankProfileID, item.isPreferredAcc, item, index, item.bankAccountNumber, item.abaRoutingNumber, item.bankName)}>
                <MoreVertIcon />
              </IconButton>
              {item.showMenu ? <ul className={classes.menuListItem} ref={wrapperRef}>
                {item?.isAchEnable &&
                  <li onClick={() => manageAutoPay()}
                    className={classes.enabedLink}
                  >Manage AutoPay</li>}
                <li onClick={() => handleEditBankDetails(item)}
                  className={classes.enabedLink}
                // disabled={!item.isDeletedAllow ? true : false}
                >Edit</li>
                <li onClick={() => handleDeleteBankDetails('delete', index, item)}
                  className={classes.enabedLink}
                // disabled={!item.isDeletedAllow ? true : false}
                >Delete</li>
              </ul> : null}
            </div>
          </Box>

          <Box my={1} mx={1} >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Grid className={classes.loanOwnerInfo} container spacing={0} alignItems="center" justifycontent="space-between">
                  <div className={`${classes.loanFlex} ${classes.loanSection}`}>
                    <img src={home} alt="bank icon" />
                    <Typography variant='h5' className={classes.loanOwner}>
                      {item.nickName}
                    </Typography>
                  </div>
                  <Box className={`${classes.marginFixedTop} ${classes.loanSection}`}>
                    <Typography variant='body1' className={`${classes.bodyFont}`}>
                      Account Type
                    </Typography>
                    <Typography variant='body1' className={`${classes.bodyFont} ${classes.accTypeFont}`}>
                      {item.accountType === 'S' && 'Savings'}
                      {item.accountType === 'C' && 'Checking'}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} className={classes.paddingTop0}>
                <Grid className={classes.bankDetails} container spacing={0} alignItems="center" justifycontent="space-between">
                  <Box className={`${classes.bank} ${classes.loanSection}`}>
                    <Typography variant='body1' className={`${classes.bodyFont}`}>
                      Bank
                    </Typography>
                    <Typography variant='body1' className={`${classes.bodyFont} ${classes.boldBodyFont}`}>
                      {`${decrypt(item.bankName)} ${item.bankCity !== 'NA' ? ',' : ''} ${item.bankCity !== 'NA' ? item.bankCity : ''}`}
                    </Typography>
                  </Box>
                  <Box className={`${classes.loanSection}`}>
                    <Typography variant='body1' className={`${classes.bodyFont}`}>
                      Account Number
                    </Typography>
                    <Typography variant='body1' className={`${classes.bodyFont} ${classes.boldBodyFont}`}>
                      {maskAccountNo(decrypt(item.bankAccountNumber))}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )
    })
  }

  if (loading) {
    return (
      <Box className={classes.pageLoader} >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box className={classes.pageBg}>
      <Box className={classes.root} >
        <Box className={classes.maxWidth}>
          <CustomBreadcrum backPageLink="/PaymentOption" backPage="Payments" activePage="My Bank Accounts" />
          <Box className={classes.topContainer} mx={0}
            display="flex"
            justifyContent="space-between"
            alignItems="center" flexWrap="wrap" width="100%" style={{ marginBottom: '20px' }}>

            <Typography className={classes.pageHeading} color="textPrimary" component='h4'>
              My Bank Accounts
            </Typography>

            {autoPayTextStatus && <Box display='flex' alignItems="center" className={classes.infoBox} >
              <InfoIcon className={classes.infoIcon} />
              <Typography className={classes.infoText}>Do you want to setup a new autopay? &nbsp; <a className={classes.linkStyle} onClick={() => manageAutoPay()} >Enroll now</a></Typography>
              <CancelIcon style={{ marginLeft: '25px', cursor: 'pointer', color: 'white' }} onClick={() => hideSetUpAutoPayText()} />
            </Box>}

            <Button className={`${classes.addAccButton}`} mt={2}
              variant="contained"
              color="primary"
              onClick={() => clearEditStore()}>
              + Add Bank Account
            </Button>
          </Box>

          {manageAccDetails}

          {error !== '' ?
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              autoHideDuration={9000}
              open={openSnakbar}
              onClose={handleSnakClose}
              className={classes.snackBarError}
              message={<span id="message-id">{error}</span>}
            /> : null}

          {success !== '' ?
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={openSnakbar}
              autoHideDuration={9000}
              onClose={handleSnakClose}
              className={classes.snackBarSuccess}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id="message-id">{success}</span>}
            /> : null}

        </Box>
      </Box>
      {deleteStatus &&
        <DeleteConfirmation
          open={deleteStatus}
          handleCancelIcon={handleCancelIcon}
          handleSubmitClose={handleSubmitClose}
          handleSubmit={handleSubmit}
        />
      }
      {autoPayDeleteStatus &&
        <DeleteConfirmationPopup
          open={autoPayDeleteStatus}
          handleCancelIcon={handleCancelIcon}
          handleSubmitClose={handleSubmitDeleteClose}
          handleSubmit={handleSubmit}
          status={true}
          data={loansData}
        />
      }
      {deleteStatusOnMulti &&
        <DeleteConfirmationPopup
          open={deleteStatusOnMulti}
          handleCancelIcon={handleCancelIcon}
          handleSubmitClose={handleSubmitDeleteClose}
          handleSubmit={handleSubmit}
          data={loansData}
        />
      }
      {autoPayEditStatus &&
        <UpdateConfirmationOnMultiPopup
          open={autoPayEditStatus}
          status={true}
          handleCancelIcon={handleCancelIcon}
          handleSubmitClose={handleSubmitUpdateClose}
          handleSubmit={handleUpdateSubmit}
          data={loansData}
        />
      }
      {updateStatusOnMulti &&
        <UpdateConfirmationOnMultiPopup
          open={updateStatusOnMulti}
          status={false}
          handleCancelIcon={handleCancelIcon}
          handleSubmitClose={handleSubmitUpdateClose}
          handleSubmit={handleUpdateSubmit}
          data={loansData}
        />
      }
      {
        false &&
        <ErrorHandlingPopup
          open={false}
          handleCancelIcon={handleCancelIcon}
          handleSubmitClose={handleSubmitClose}
          handleSubmit={handleSubmit}
        />
      }
    </Box>
  )
})