import React, { useEffect, useState, useRef  } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  Box, Toolbar, IconButton,
  Menu, MenuItem, Badge, Button,
  FormControl, Select, Typography, CircularProgress, Grid, Table, TableBody, TableContainer, TableFooter,
  TablePagination, TableRow, Paper, TableHead
} from '@material-ui/core';
import { useStyles, StyledTableCell, StyledTableRow } from './styles'
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector, useDispatch } from 'react-redux';
import { getFaqContent, getEscrowTransaction } from '../../../actions';
import { numberWithCommas } from '../../../helpers';
import { HOW_IT_WORKS_PROPERTY_TAX } from '../../../helpers/constants';
import { reviewingRequest } from '../../../helpers';

import { useHistory } from 'react-router-dom';
import  {Faq} from './../Faq';
import moment from 'moment';

import './index.css';
import  coveredBanner  from '../../../assets/coveredBanner.png';
import window from '../../../assets/Refinance/window.png';
import  coveredInsurance  from '../../../assets/coveredInsurance.png';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import {CoveredTile} from './../CoveredTile';
import Cookies from 'js-cookie';

export const Taxes = (props) => {
  
  const mytheme = useSelector(state => state.theme);
  const { primaryColorCode, secondaryColorCode } = mytheme;
  const { accountNumber } = props;
  const privateLabelUrl = Cookies.get("privateLabelUrl")

  const theme = useTheme();
  const classes = useStyles();
  const componentRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();

  const [data, setFaqData] = useState()
  const [loading, setLoading] = useState(false)
  const [escrowDetails, setEscrowDetails] = useState([]);
  const [list, setPropertyLists] = useState([])
  const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'));
  const mobileMediaQuery = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    if (accountNumber != '') {
      getFaqData();
      getTransactionHistoryData()
      setPropertyTaxes()
    }
  },[accountNumber]);

  useEffect(() => {
    if (accountNumber != '') {
      setPropertyTaxes()
    }
  },[props?.taxes]);
 
  const setPropertyTaxes = () => {
    if (props?.taxes != null) {
     
      const data = props?.taxes.map((item, index) => {
        return {
          "coverageAmount": item.coverageAmount,
          "disbursementDate": item.disbursementDate,
          "escrowAmountDue": item.escrowAmountDue,
          "escrowLineType": item.escrowLineType,
          "escrowStatus": item.escrowStatus,
          "expirationDate": item.expirationDate,
          "lastPaidDate": item.lastPaidDate,
          "lineFrequency": item.lineFrequency,
          "lineType": item.lineType,
          "payeeName": item.payeeName,
          "policyTaxId": item.policyTaxId,
          "taxIdType": item.taxIdType,
          "status": index == 0 ? true : false,
          "isActiveRequest": item.isActiveRequest,
          "taskNumber": item.taskNumber,
          "controlNumber": item.controlNumber
        }})
      setPropertyLists(data)
    }
  }

   const setStatusValue = (index, status) => {
    let newObj = [...list]  
    newObj[index]['status'] = !status
    setPropertyLists(newObj)
  }

  const getFaqData = async () => {
   let a = await dispatch(getFaqContent('PC029'));
   setFaqData(a)
  }

  const getTransactionHistoryData = async () => {
    setLoading(true)
    const res = await getEscrowTransaction(props?.accountNumber);
    if (res) {
      let filterData = res.lstEscrowDetails.filter((item) => (item.escrowType == 'TAX'))
      setEscrowDetails(filterData)
    }
    setLoading(false)
  }
  
  const handleViewMore = () => {
    history.push({pathname: '/TransactionHistory' , search: 'escrow'});
  }

  return (
    <CssBaseline>
      <Box container="true" maxWidth='xl' className={classes.root} >
          <Box className={classes.formContainerAccordian} ref={componentRef}>
            <Box className={classes.taxformContainer1}>
              <Typography className={classes.howItWork}>How It Works</Typography>
                <Typography className={classes.howItWorkDesc}>
                 {HOW_IT_WORKS_PROPERTY_TAX}
                </Typography>
                <Grid container className={classes.accordianContainer}>
              <Grid item md={12} className={classes.accordianGrid} xs={12} lg={12}>
             
              <Box  style={{ paddingTop: '26px', paddingBottom: '60px'}}>
            
            {list.map((key, index) => {  
              return(
                <Box className={`${classes.accordian} ${'acc1'}`}>
                <Accordion elevation={0} expanded={key.status == true} onChange={() => setStatusValue(index, key.status)}>
              <AccordionSummary
                expandIcon={ <ExpandMoreIcon  className={classes.expand_icon}/>}
                
                aria-controls={`panel${index}c-content`}
                id={`panel${index}c-header`}
                className={classes.transactionText}
                style={{borderLeft: `6px solid ${primaryColorCode}`}}
                >
                <>
                  {  key.escrowStatus == 'Escrowed' ?
                    <Box>
                      {key.escrowLineType} - {key.isActiveRequest ? <span className={classes.progress}>IN PROGRESS</span> :
                      <>
                       {key.taxIdType} {key.policyTaxId ? key.policyTaxId : 'N/A'
                      } </>}
                    </Box>
                    :
                    <Box>
                    {key.escrowLineType} - {key.isActiveRequest ? <span className={classes.progress}>IN PROGRESS</span>:  <span className={classes.nonEscrowed}>NON ESCROWED</span>}
                  </Box>
                   }
                </>
              </AccordionSummary>
              <AccordionDetails >
                {  key.escrowStatus == 'Escrowed' &&  !key.isActiveRequest ?
              <Box className={classes.accDetails}>
                <Grid item md={6} xs={12} lg={12}> 
                <Box>
                
                  <Grid container>
                    <Grid item md={6} xs={12} lg={6}>
                      <Typography className={classes.boxText}>Tax Authority:</Typography>
                    </Grid>
                    <Grid item md={6} xs={12} lg={6}>
                      <Typography  className={classes.boxTextValue}>{key?.payeeName ? key?.payeeName : 'N/A'}</Typography>
                      </Grid>
                  
                    <Grid item md={6} xs={12} lg={6}>
                      <Typography  className={classes.boxText}>Amount Due:</Typography>
                    </Grid>
                    <Grid item md={6} xs={12} lg={6}>
                      <Typography  className={classes.boxTextValue}>
                        {   Math.sign(key?.escrowAmountDue) == -1 ? '-$' + numberWithCommas(parseFloat(Math.abs(key?.escrowAmountDue)).toFixed(2))  : '$' + numberWithCommas(parseFloat(key?.escrowAmountDue).toFixed(2)) }
                    </Typography>
                    </Grid>
                  </Grid>
                </Box>
                </Grid>
                <Grid item md={6} xs={12} lg={12}> 
                <Box>
                  <Box>
                  <Grid container>
                    <Grid item md={6} xs={12} lg={6}>
                      <Typography className={classes.boxText}>Last Paid Date:</Typography>
                    </Grid>
                    <Grid item md={6} xs={12} lg={6}>
                      <Typography  className={classes.boxTextValue}>{key?.lastPaidDate ? moment(key.lastPaidDate).format('ll') : 'N/A'}</Typography>
                    </Grid>

                    <Grid item md={6} xs={12} lg={6}>
                      <Typography className={classes.boxText}>Next Due Date:</Typography>
                    </Grid>
                    <Grid item md={6} xs={12} lg={6}>
                      <Typography  className={classes.boxTextValue}>{key?.disbursementDate ? moment(key.disbursementDate).format('ll') : 'N/A'}</Typography>
                    </Grid>
                  
                  </Grid>
                  </Box>
                </Box>
                </Grid>
               </Box> : null}
              <Box className={classes.escrowText}>
                  { key.escrowStatus == 'Escrowed' && !key.isActiveRequest &&
                  <Typography className={classes.escrowed}>Your loan is escrowed for this coverage, and these payments come out of your escrow account.</Typography> }
                  { key.escrowStatus != 'Escrowed' && !key.isActiveRequest && <Typography className={classes.escrowed}>This is not an item your loan is escrowed for. This amount must be paid directly by you to your tax authority.</Typography> }
                  { key.isActiveRequest && <Typography className={classes.escrowed}>
                     {reviewingRequest(key.taskNumber, key.controlNumber)}
                  </Typography> }
              </Box>
              </AccordionDetails>
              </Accordion>
            </Box>
            )
          })}
        
            </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
     </Box>
    
    {!props.displayStatus && (privateLabelUrl.toLowerCase() === "bsi") && (props.coveredBanner.bannerInfo.filter((item) => ((item.bannerName.toUpperCase() === 'COVERED') || (item.bannerName.toUpperCase() === 'MATIC'))).filter((key) => (key.isDisplay == true))[0]?.isDisplay) && <CoveredTile data={props.coveredBanner}/> }

      <Box container="true" maxWidth='xl' className={classes.transactionHistoryRoot} >
          <Box className={classes.formContainer} ref={componentRef}>
          <Grid container >
            <Grid item xs={12} sm={12} md={12}>
              <Box>
              
              <Typography className={classes.transactionHeading}>Property Taxes Transaction History</Typography> 
             <Typography className={classes.transactionDesc}>The table below shows amounts that we have remitted on your behalf during the past year.</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={0}  >
                <Box className={classes.paymentDetailsBox}>
                   <TableContainer className={classes.table}>
                    <Table  aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell  align="left">Payee</StyledTableCell>
                          <StyledTableCell  align="left">Type</StyledTableCell>
                          <StyledTableCell align="center">Date</StyledTableCell>
                          <StyledTableCell align="right">Amount</StyledTableCell>
                        </TableRow>
                      </TableHead>
                        <TableBody>
                            { escrowDetails?.length > 0 ? escrowDetails.slice(0,5).map((row, i) => (
                            <StyledTableRow key={i} >
                              <StyledTableCell align="left" >{row?.payee ? row?.payee : 'N/A'}</StyledTableCell>
                              <StyledTableCell align="left" >{row?.description ? row?.description : 'N/A'}</StyledTableCell>
                              <StyledTableCell align="center">{row?.effectiveDate ? moment(row.effectiveDate).format('ll') : 'N/A'} </StyledTableCell>
                              <StyledTableCell align="right" style={{color: '#636363', fontSize: '14px', fontWeight: '600'}}>{ row?.escrowAmount ?  
                                Math.sign(row?.escrowAmount) == -1 ? '-$' + numberWithCommas(parseFloat(Math.abs(row?.escrowAmount)).toFixed(2))  : '$' + numberWithCommas(parseFloat(row?.escrowAmount).toFixed(2)) : 'N/A'
                              }</StyledTableCell>

                            </StyledTableRow>)) : <StyledTableRow>
                                <StyledTableCell  colSpan={4}>
                                  <p className={classes.noDataFoundText}>No record found</p>
                                </StyledTableCell>
                              </StyledTableRow>
                            }
                           
                        </TableBody>
                      </Table>
                      { escrowDetails?.length > 5 ? 
                      <Box><Typography className={classes.detailedTransaction} style={{ color: '#0094FF', cursor: "pointer" }} onClick={() => handleViewMore()}>{'Show More'}</Typography></Box> : null}
                    </TableContainer>
                </Box>
              </Grid>
            </Grid>
           
          </Grid>
        
         </Box>
     </Box> 

    <Box container="true" maxWidth='xl' className={classes.faqRoot} >
          <Box className={classes.formContainer} ref={componentRef}>
              { data?.lstPageContentDetails?.length > 0 ?
                <Faq contentKey={'PropertyTaxes'}/> : null
              }
            </Box>
    </Box>
    </CssBaseline>
  )
}
