import { makeStyles } from '@material-ui/core/styles';
import background_img from '../../../../assets/Admin/background_img.png';
export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: "#cccccc",
    padding: 0,
  },
  wrapper: {
    width: '95%',    
    margin: '0 auto',
  },
  logoContainer: {
    padding: '0 16px',
    display: 'flex',
    alignItems: 'center',
    height: 100,
    backgroundColor: "#cccccc",
    backgroundImage: `url('${background_img}')`,
    backgroundRepeat: "repeat",
    backgroundSize: 'cover',
    backgroundPosition: "top right",
  },
  navContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'left',
  }
}));