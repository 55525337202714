import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Cookies from 'js-cookie';
import { CircularProgress } from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import { getPublicIp, getAppSource, getUserType } from '../../../../helpers';
import { handleSsoLogin } from '../../../../actions';
import { encrypt, decrypt } from '../../../../helpers/encrypt';

export const AdminSignin = (props) => {
  const history = useHistory();
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
 
  useEffect(() => {
    if (props?.error?.message.includes('not assigned')) {
      Cookies.set("errorStatus", props?.error?.message);
      history.replace({pathname: '/'})
      window.location.reload()
    } else {
       Cookies.set("errorStatus", "");
    }
  }, [])

  useEffect(() => {
      if (!authState || !authState.isAuthenticated) {
        setUserInfo(null);
      } else {
          const getUser = async () => {
              try {
                      const res = await oktaAuth.getUser();
                      const accessToken = await oktaAuth.getAccessToken();   
                      handleOktaLogin(accessToken)   
                      setUserInfo(res);
              } catch (error) {
                      console.log(error);
              }
      };
      getUser();
    }
    }, [authState, oktaAuth]); // Update if authState changes
  
    
  const handleNavigation = (id) => {
    const roleID = Number(id);
    if (roleID === 1) {
      history.replace({ pathname: "/Admindashboard" })
    }
    if (roleID === 2) {
      history.replace({ pathname: "/AdminUserDetails" })
    }
    if (roleID === 3) {
      history.replace({ pathname: "/Admindashboard" })
    }
   }

    const handleOktaLogin = async (token) => {

      const data = {
          "userName": null,
          "token": token,
          "privateLabelUrl": "BSI",
          "ipAddress": await getPublicIp(),
          "browser": Cookies.get("browser"),
          "appSource": getAppSource(),
          "userType": getUserType(),
        }

        var response = await handleSsoLogin(data);
        if (response && response.expiresIn) {
          Cookies.set("errorStatus", "");
          handleNavigation(decrypt(response.roleID))
        } else {
          Cookies.set("errorStatus", response?.accessToken);
          history.replace({ pathname: "/" })
        }
    }
 
 return (
    <div className='loader'><CircularProgress /></div>
  );
};
