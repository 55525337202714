import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 12,
    backgroundColor: "#ffffff",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    //padding: '2.2em 10em',  
    padding: '2.5em',
    position: 'relative',
  },
  heading: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: '24px',
    fontWeight: "600",
    letterSpacing: "0.63px",
    lineHeight: "29px"
  },
  dialogStyle: {
    borderRadius: '12px !important',
    width: "80%"
  },
  btn: {
    background: "#4571C4",
    padding: "10px",
    borderRadius: "4px",
    textDecoration: "none",
    color: "#ffffff",
    width: 192,
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "600",
    letterSpacing: "0.53px",
    lineHeight: "19px",
    textAlign: "center",
  },
  labelStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "400",
  },
  summaryText: {
    fontSize: "14px"
  },
  closeBtn: {
    height: "24px",
    width: "24px",
    backgroundColor: "#7B8390",
    borderRadius: "50%",
    display: "inline-block",
    textAlign: "center",
    color: "#ffffff",
    cursor: "pointer"
  },

  closeIcon: {
    position: 'absolute',
    top: 15,
    right: 15,
  }
}));