import React from "react";
import { Box, Dialog, IconButton } from '@material-ui/core';
import { useStyles } from './styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';

export const PropertyInsuranceQuoteAlert = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      PaperProps={{ classes: { root: classes.dialogStyle } }}
      onClose={props.toggleOpen}
      open={props.open}
    >

      <Box className={classes.root}>
        <Box className={classes.header}>
          <IconButton className={classes.iconStyle} onClick={props.toggleOpen}>
            <CancelIcon className={classes.iconStyle} />
          </IconButton>
        </Box>
        <Box className={classes.body}>
          {props.alertMessage !== 'success' ?
            <p className={`${classes.bodyText} ${classes.errorMsg}`}>
              {props.alertMessage}</p>
            :
            <a href={props.anchorLink} className={`${classes.submitAnchor}`} mt={2} color='primary' target="_blank"> View Quote Details </a>}

        </Box>
      </Box>
    </Dialog >
  )
}