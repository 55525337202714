import {OKTA_ISSUER,OKTA_CLIENTID} from '../helpers/constants'; // mention your client id and issue in either const or in .env file
const CLIENT_ID = OKTA_CLIENTID;
const ISSUER = OKTA_ISSUER;
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const REDIRECT_URI = window.location.origin + '/admin/signin';

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK
  },
};
