import signinIcon from '../../assets/SignIn/SignIn.svg';
import {
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#ccc',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${signinIcon})`,
    backgroundPosition: "bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'contain',
    '& .MuiFormControlLabel-root': {
      marginRight: '0px'
     }
  },

  formContainer: {
    width: '53%',
    minWidth: "33.3em",
    maxWidth: '58.8em',
    padding: '3.9em 4.9em',
    backgroundColor: '#fff',
    borderRadius: 12,
    marginTop: '6.6em',
    marginBottom: '6.6em',
    boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
      padding: '20px',
      margin: '50px 15px'
    },
  },

  title: {
    fontSize: '3.6em',
    fontWeight: '600',
    lineHeight: 1,
    textAlign: 'center',
    marginBottom: 33,
    marginTop: 0,
    fontFamily: 'Montserrat',
    color: "#535D6D",
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.4em',
    },
  },

  inputContainer: {
    marginBottom: '1.9em',
  },

  inputStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600",
    '& .MuiFormControlLabel-root': {
     marginRight: '0px'
    },
  },

  labelStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600",
  },

  signInBtn: {
    fontFamily: "Montserrat",
    marginBottom: 20,
    fontSize: 16,
    fontWeight: '600',
  },

  linkStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    fontWeight: "600",
    letterSpacing: "0.47px",
    display: 'block',
    textAlign: 'right',
    display: 'inline-block',
    paddingTop: 10,
  },

  orContainer: {
    width: '100%',
    borderTop: 'solid 1px #979797',
    position: 'relative',
    marginTop: 10,
  },

  orWraper: {
    width: 30,
    height: 30,
    position: 'absolute',
    top: -16,
    left: '45%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  orText: {
    color: "#727272",
    fontFamily: "Montserrat",
    fontSize: "1.4em",
    fontWeight: "500",
    margin: 0,
  },

  otherLinks: {
    marginTop: 20,
  },

  otherLinksText: {
    color: "#4D5666",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600",
    textAlign: 'center',
    marginTop: 0,
    fontStyle: 'normal',
    lineHeight: "24px",
    marginBottom: 20
  },

  otherLinkStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: '1em',
    fontWeight: "600",
    letterSpacing: "0.47px",
    textTransform: 'uppercase',
  },



  errorMsg: {
    fontSize: '1.4em',
    fontWeight: '500',
    textAlign: 'center',
    marginBottom: 10,
    fontFamily: 'Montserrat',
    color: "red",
  },

  errorAccLockMsg: {
    fontSize: '1.4em',
    fontWeight: '600',
    textAlign: 'center',
    marginBottom: 10,
    fontFamily: 'Montserrat',
    color: "red"
  },

  successMsg: {
    color: "green",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "600",
    letterSpacing: "0.53px",
    lineHeight: "18px",
    textAlign: 'center',
  },

  resendLinkLoader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '5px',
  },

  logoutSuccessMsg: {
    color: "green",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "600",
    letterSpacing: "0.53px",
    lineHeight: "18px",
    textAlign: 'center',
    paddingBottom: '10px'
  },

  activeRadioLabel: {
    fontWeight: "600",
  },
  radioLabel: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "600",
    cursor: 'pointer',
    color: '#535D6D',
    letterSpacing: '0.25px',
    lineHeight: '16px',
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: "#535D6D",
      fontWeight: "600",
    }
  },
 
  tooltipTitle: {
    padding: '4px',
    fontSize: '15px',
    color: "#535D6D",
    fontWeight: '600',
    fontFamily: 'Montserrat',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },

  desc: {
    padding: '3px',
    fontSize: '14px',
    fontFamily: 'MONTSERRAT',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },

  close: {
    float: 'right',
    // color: 'black',
    fontSize: '26px',
    cursor: 'pointer',
    marginTop:'-18px',
    marginRight: '-px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
  },

  saveUsername: {
    color: '#A0A0A0',
    display: 'inlineblock',
    fontSize: '1.2em',
    textAlign: 'right',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    /* padding-top: 8px; */
    letterSpacing: '0.47px',
    paddingTop: '3px'
  },

  checkboxStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600",
    paddingBottom: '3px',
    '& .MuiFormControlLabel-root': {
     marginRight: '0px'
    },
    '& .MuiSvgIcon-root': {
          fontSize: 17
      }
  },

  emailBtn: {
    fontFamily: "Montserrat",
    marginBottom: 20,
    marginTop: 30,
    fontSize: 16,
    fontWeight: '600',
    color: '#535D6D !important',
    border: 'solid 2px',
    borderColor: theme.palette.primary.main,
    boxShadow: "0px 4px 8px 0 rgba(0,0,0,0.14)"
  },

}));
