import React, { useState, useRef } from "react";
import { useSelector } from 'react-redux';
import "./Accordion.css";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

export const Accordion = (props) => {
  const mytheme = useSelector(state => state.theme);
  const { primaryColorCode } = mytheme;
  const [setActive, setActiveState] = useState("");
  const content = useRef(null);

  function toggleAccordion(event) {
    setActiveState("");
    let accContent = Array.from(document.querySelectorAll('.accordion__content'));
    let accordion_btn = Array.from(document.querySelectorAll('.accordion'));

    if (event.currentTarget.parentElement.querySelector('.accordion__content').classList.contains('show')) {
      accContent.forEach(node => {
        node.style.maxHeight = "0px";
        node.classList.remove('show');
      });
      event.currentTarget.classList.remove('active');
      event.currentTarget.parentElement.querySelector('.accordion__content').classList.remove('show');
      event.currentTarget.parentElement.querySelector('.accordion__content').style.maxHeight = "0px"
    } else {
      accContent.forEach(node => {
        node.style.maxHeight = "0px";
        node.classList.remove('show');
      });
      accordion_btn.forEach(node => {
        node.classList.remove('active');
      });
      event.currentTarget.classList.add('active');
      event.currentTarget.parentElement.querySelector('.accordion__content').classList.add('show');
      event.currentTarget.parentElement.querySelector('.accordion__content').style.maxHeight = `${content.current.scrollHeight}px`
    }
  }

  return (
    <div className="accordion__section" style={{ borderColor: primaryColorCode }}>
      <button className={`accordion ${setActive}`}
        onClick={(event) => toggleAccordion(event)}
      >
        <p className="accordion__title">{props.title}</p>
        <KeyboardArrowDownIcon classes={{ root: `arrowDown ${setActive}` }} />
      </button>
      <div
        ref={content}
        style={{ maxHeight: '0px' }}
        className="accordion__content"
      >
        <div
          className="accordion__text"
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      </div>
    </div>
  );
}

