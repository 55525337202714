import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderBottom: `3px solid ${theme.palette.primary.main}`,
  },
  accordionSum: {
    minHeight: '67px',
    paddingTop: '5px'
  },
  heading: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.5px',
    lineHeight: '20px',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
    padding: '10px 0 28px 0',
    [theme.breakpoints.down('sm')]: {
      flexDirectio: 'column'
    },
  },
  column: {
    flexBasis: '20%',

  },
  column2: {
    flexBasis: '30%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  loanOwner: {
    color: '#535D6D',
    fontFamily: 'Montserrat',
    fontSize: '24px;',
    fontWeight: 'bold',
    letterSpacing: '0.63px',
    lineHeight: '29px'
  },
  custAddress: {
    padding: '0 7% 0 18%',
    alignItems: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      padding: '0',
      marginBottom: 10,
    }
  },
  loanCareNo: {
    marginBottom: 10,
  },
  bodyFont: {
    color: 'rgba(0,0,0,0.74)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    letterSpacing: '0.5px',
    lineHeight: '20px'
  },
  phoneContainer: {
    justifyContent: "space-between",
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'intial'
    }
  },
  mr10: {
    marginRight: 15,
  },
  btn: {
    color: theme.palette.primary.contrastText,
    fontFamily: 'Montserrat',
    fontSize: '16px',
    height: '36px',
    width: '135px',
    fontWeight: '600',
    [theme.breakpoints.down('xs')]: {
      margin: '35px auto 0 auto'
    },
  },
  careNo: {
    marginBottom: '0',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '0',
    },
  },

  loanInfoIcon: {
    color: theme.palette.primary.contrastText,
    fontFamily: 'Montserrat',
    fontSize: '28px',
    marginRight: '30px'
  },

  wordBreak: {
    wordBreak: 'break-all',
  },

  noWrap: {
    flexWrap: 'nowrap',
  }

}));