import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable'
import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';
import * as serviceWorker from './serviceWorker';
import './index.css';
import App from './App';
import ErrorBoundary from './components/ErrorBoundary';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'

if (window.location.href.includes('myloanweb.com')) {
  ReactGA.initialize('UA-113324743-1');
  TagManager.initialize({ gtmId: 'UA-113324743-1' })
}

if (window.location.href.includes('stage') || window.location.href.includes('myloanweb.com')) {
  (function(c,l,a,r,i,t,y){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "jbffzuap1y");
}

const store = createStore(reducers, {}, applyMiddleware(thunk));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ErrorBoundary >
        <App />
      </ErrorBoundary>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
