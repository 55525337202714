import React from 'react';
import { useStyles } from './styles';
import { Box, Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const FileSizeAlert = (props) => {

  const { open, handleAlertClose, file} = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle }}} onClose={handleAlertClose} open={open}>
     <Box className={classes.root}>
      <IconButton onClick={handleAlertClose} className={classes.closeIco}>
        <CancelIcon />
      </IconButton>
      <Box className={classes.cardBody}>
        <Typography className={classes.statusTitle}>File Upload Error</Typography>
      </Box>
      
      <Box className={classes.cardBodyContainer}>
        <Box className={classes.statusContainer}>
         <Box className={classes.cardContainer}>
          <Box className={classes.imageContainer}>
            <InfoIcon className={classes.infoIcon} />
            <span className={classes.request}>{file} is exceeded limit</span>
          </Box> 
           <Typography className={classes.paragraph}>Please upload maximum 5 MB file only.</Typography> 
          </Box> 
        </Box>
      </Box>

      <Box className={classes.cardFooter}>
        <Button
          variant='contained'
          color='primary'
          className={classes.btn}
          onClick={handleAlertClose}
          >Ok</Button>
      </Box>
    </Box> 
  </Dialog>
  )
}
