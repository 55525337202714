import { makeStyles } from '@material-ui/core/styles';
import signinIcon from '../../assets/SignIn/SignIn.svg';

export const useStyles = makeStyles((theme) => ({
  pageBg: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#ccc',
    display: 'flex',
    justifyContent: 'center',
    backgroundImage: `url(${signinIcon})`,
    backgroundPosition: "bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'contain',
  },

  root: {
    width: "100%",
    maxWidth: "120em",
    padding: "2.4em 1.5em 8em",
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      padding: "2.4em 1.5em 4em",
    },
  },

  loaderContainer: {
    width: '100%',
    height: '100%',
    minHeight: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  container: {
    width: '100%',
  },

  link: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    marginTop: 14,
  },

  disabledLink: {
    color: "#7B8390",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    textDecoration: 'none',
    letterSpacing: '0.5px',
    lineHeight: '20px',
  },

  activeLink: {
    color: theme.palette.primary.main,
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    textDecoration: 'none',
  },
  
  pageHeading: {
    color: '#1A4664',
    fontFamily: 'Montserrat',
    fontSize: '22px',
    fontWeight: '700',
    letterSpacing: '0.18px',
    lineHeight: '24px',
    marginBottom: 25,
    marginTop: 24
  }

}));