import React from 'react';
import { Box, Typography, Button, Dialog } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useStyles } from './styles';

export const SaveAndExitPopup = (props) => {

  const {actionCallBack} = props

  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
       <Box textAlign="right">       
        <Button className={classes.saveExitBtn} 
        style={{color:"#535D6D", fontWeight:600}}
        color='primary' variant='contained' 
        onClick={handleClickOpen} 
        startIcon={<ExitToAppIcon />}>SAVE AND EXIT</Button>
      </Box>
      <Dialog
        //fullWidth={false}
        //maxWidth={'xl'}
        PaperProps={{ classes: { root: classes.dialogStyle } }} 
        onClose={handleClose} 
        open={open?open:false}
      >
        <Box className={classes.rootP}>
          <Typography align="right">
            <span style={{paddingLeft:"2px"}} onClick={() => handleClose()} className={classes.closeBtn}>X</span>
          </Typography>
          <Box py={1} borderBottom={1} >
            <Typography className={classes.title} id="alert-dialog-title">{"Are you sure you want to leave?"}</Typography>
          </Box>

          <Box py={2} px={1}>
            <Typography className={classes.summary}>Don’t worry, we have saved all the information filled by you in the form so far.
                 You can resume filling the form at a later date by accessing it from the To Do Page.</Typography>
          </Box>
          <Box className={classes.btnBox}>
            <Button className={classes.cancelBtn} variant="outlined" onClick={handleClose} color="secondary">
              CANCEL
          </Button>
            <Button className={classes.leaveBtn} variant="contained" onClick={()=>actionCallBack("leave")} color="secondary" autoFocus>
              LEAVE
          </Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}