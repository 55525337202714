import React from 'react';
import { useStyles } from './styles';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const DeleteAlert = (props) => {

  const { open, file, handleClose, handleDelete } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      width="600px"
      PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={handleClose} open={open}
    >
      <Box className={classes.root}>
        <IconButton className={classes.iconStyle} onClick={handleClose}>
          <CancelIcon />
        </IconButton>
        <Box className={classes.header}>
        
          <Typography className={classes.subTitle}>Are you sure you want to delete "{file}" ?</Typography>
          <Typography className={classes.paragraph}>This item will be deleted immediately. You can’t undo this action.</Typography>
        </Box>
       
        <Button
          color='primary'
          variant='contained'
          className={classes.btn}
          onClick={handleDelete}
        >Delete</Button>
      </Box>
    </Dialog>
  )
}
