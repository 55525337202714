import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 12,
    backgroundColor: "#FFFFFF",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    padding: '1em',
    [theme.breakpoints.down('xs')]: {
      padding: '1.6em',
    },
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxWidth: 570,
    maxHeight: "100%",
    backgroundColor: 'transparent'
  },

  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },

  iconStyle: {
    padding: 0,
    color: '#535D6D',
    fontSize: '24px'
  },

  body: {
    width: '100%',
    padding: '2px 20px 30px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 5px',
    },
  },

  checkIconContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  checIcon: {
    color: theme.palette.secondary.main,
    height: 36,
    width: 36
  },

  title: {
    color: "#4D4D4D",
    fontFamily: "Montserrat",
    fontSize: '14px',
    fontWeight: "500",
    textAlign: "center",
    letterSpacing: '0.15px',
    lineHeight: '20px',
    paddingBottom: '4px',
    paddinTop: '4px'
  },
 
  heading: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '20px',
    fontWeight: "500",
    textAlign: "center",
    letterSpacing: '0.15px',
    lineHeight: '20px',
    paddingBottom: '17px',
    paddinTop: '7px',
    borderBottom: '1px solid #d3d4d5',
  },

  popupSubHeading: {
    color: 'rgba(0,0,0,0.74)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '400',
    letterSpacing: '0.53px',
    lineHeight: '19px',
    margin: '42px 0 10px 0',
    textAlign: 'center'
  },

  popupCode: {
    backgroundColor: '#e3ecf1',
    padding: 17,
    '& h5': {
      color: 'rgba(0,0,0,0.87)',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: 600,
      letterSpacing: '0.53px',
      lineHeight: '19px',
      textAlign: 'center',
      marginBottom: 12,
    }
  },
 
  subtitle: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '14px',
    marginBottom: 20,
    textAlign: 'center'
  },

  subTitle: {
    color: "#3C3D3E",
    fontFamily: "Montserrat",
    fontSize: 18,
    marginBottom: 20,
    textAlign: 'center',
    fontWeight: 700,
    marginTop: 20
  },
  
  onTime: {
    color: "#0067CF",
    fontFamily: "Montserrat",
    fontSize: 20,
    textAlign: 'center',
    fontWeight: 700,
    marginTop: 10
  },

  everyTime: {
    color: "#0067CF",
    fontFamily: "Montserrat",
    fontSize: 20,
    marginBottom: 10,
    textAlign: 'center',
    fontWeight: 600,
  },

  flexBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },

  item: {
    margin: 5,
    width: '33.33%',
  },

  itemText: {
    color: "rgba(0,0,0,0.74)",
    fontFamily: "Montserrat",
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '20px',
    marginBottom: '10px'
  },

  itemSubText: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '20px',
    fontWeight: 'bold',
    letterSpacing: '0.15px',
    lineHeight: '24px',
  },

  buttonBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginBottom: 20,
  },

  btn: {
    width: 160,
    fontFamily: "Montserrat",
    fontSize: '1.6em',
  },

  footerText: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
  },

  okBtn: {
    display: 'block',
    margin: '20px auto 0 auto',
    fontWeight: 600,
    fontSize: '16px',
    fontFamily: "Montserrat",
    width: '160px',
    color: theme.palette.primary.contrastText,
  },
  
  autoPayBtn: {
    display: 'block',
    margin: '10px auto 0 auto',
    fontWeight: 700,
    fontSize: 18,
    fontFamily: "Montserrat",
    width: '200px',
    color: '#FFF',
    backgroundColor: '#0067CF',
    '&:hover': {
      color: '#FFF',
      backgroundColor: '#0067CF',
    }
  },

  checkImg: {
    width: 22,
    height: 22
  },
  
  successMsg: {
    color: "#198100",
    fontFamily: "Montserrat",
    fontSize: "16px",
    letterSpacing: "0.31px",
    lineHeight: "15px",
    fontWeight: "600",
    paddingTop: 4,
    paddingLeft : 5
  },
}));

