import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  covidList: {
    marginTop: 40,
  },
  text: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "500",
  },

  listHeading: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: "500",
    marginBottom: 20,
  },

  dot: {
    display: 'inline-block',
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: '#2d8fc1',
    position: 'absolute',
    left: 0,
    top: 15,

  },

  assistStepsList: {
    marginTop: 20,
    '& ol li': {
      color: "#585757",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "500",
      padding: '7px 0 7px 35px',
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        padding: '0',
      },
    },
    '& ol': {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '20px',
      },
    }
  },

  covidOptionsList: {
    listStyleType: 'disc',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '20px',
    },
    '& li': {
      color: "#585757",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "500",
      padding: '10px 0 10px 35px',
      position: 'relative',
      listStyleType: 'disc',
      [theme.breakpoints.down('sm')]: {
        padding: '0',
      },
    }
  },

  textMargin: {
    margin: '30px 0'
  },

  required: {
    color: '#ec3131',
    fontWeight: '600',
    marginLeft: '2px'
  },

}))