import React, { useState, useEffect, Fragment } from 'react';
import {
  Box, Button, IconButton, TextField, FormControl, CircularProgress, Grid, Typography, Divider,
  Select, InputLabel, MenuItem
} from '@material-ui/core';
import { useStyles } from './styles';
import Dialog from '@material-ui/core/Dialog';
import './slider.css'
import { checkDigitWithDecimal, numberWithCommas, renderDefaultMsg } from '../../helpers';
import { CustomChart } from '../CustomChart';
import { getAmortizationData } from '../../actions';
import moment from 'moment';
import CancelIcon from '@material-ui/icons/Cancel';

export const AmortizationCalculator = (props) => { 
  const classes = useStyles();  
  const { open, handleClose } = props;
  const [disableBtn, setdisableBtn] = useState(false)
  const [errorMessage, setErrorMessage] = useState('') 
  const [monthlyPayment, setMonthlyPayment] = useState(0)
  const [revisedMonthlyPayment, setRevisedMonthlyPayment] = useState(0)
  const [inputFrequency, setInputFrequency] = useState("M")
  const [inputAddPayment, setInputAddPayment] = useState(0)
  const [inputSlider, setInputSlider] = useState(0)
  const [intrestSaved, setIntrestSaved] = useState(0)
  const [reducedMonth, setReducedMonth] = useState(0)
  const [payoffDate, setPayoffDate] = useState('')
  const [prepareChartData, setPrepareChartData] = useState({
    projection: projection,
    historyData: historyData,
    original: original
  })
  const [data, setData] = useState(null);  
  const [loading, setLoading] = useState(false)  
  const [originalData, setOriginalData] = useState('')
  const [totalOldIntrest, setTotalOldIntrest] = useState(0)
  const [isChartDetails, setIsChartDetails] = useState(true)
  useEffect(() => {
    if (props.frequency || props.addPayment) {
      let pf = props.frequency ? props.frequency : 'M'
      let ad = props.addPayment ? props.addPayment : 0
      setInputFrequency(pf)
      setInputAddPayment(ad)
      setInputSlider(ad)
    }
  }, [data])

  useEffect(() => {
    if (props.accountNumber) {
      getData(props.accountNumber);
      setIsChartDetails(false)
    }
  }, [props.accountNumber]);

  const getData = async (accountNumber) => {
    setLoading(true)
    setErrorMessage('')
    const result = await getAmortizationData(accountNumber)
    if (result) {
      const { lstResponseStatus } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        //success
        setData(result);
        calculator(result, inputFrequency, inputAddPayment);
        setLoading(false)
      } else {
        setLoading(false)
        setErrorMessage(lstResponseStatus[0].statusDesc)
      }
    } else {
      setErrorMessage(renderDefaultMsg('400'))
      setLoading(false)
    }
  }

  const [inValidAddPayment, setInValidAddPayment] = useState(false)
  const handleChange = (inputName) => (event) => {
    if (inputName === 'inputAddPayment') {      
      if(!checkDigitWithDecimal(event.target.value)){
          return false
      } else if (event.target.value > parseFloat(principalLoan)) {
        setInValidAddPayment(true)
        return false
      } else {
        setInValidAddPayment(false)
      }

      setInputAddPayment(event.target.value)
      setInputSlider(event.target.value) //Update Slider
      if (event.target.value.length > 0) {
        setdisableBtn(true)
      }
      else {
        setdisableBtn(false)
      }
    } else if (inputName === 'inputSlider') {
      setInputSlider(event.target.value)
      setInputAddPayment(event.target.value)

      calculator(data, inputFrequency, event.target.value ? event.target.value : 0)

    } else if (inputName === 'inputFrequency') {
      setInputFrequency(event.target.value)
      calculator(data, event.target.value, inputAddPayment)
    }
  }
  /*====calculation Work=====*/
  const calculatorProperty = {
    regularPayment: '',
    monthlyDueDate: '',
    payment: '',
    interest: '',
    principal: '',
    balance: '',
    date1: '',
    date2: '',
    date3: '',
    totalInterest: ''
  }

  const round = x => (x + 0.005 - (x + 0.005) % 0.01 + '').replace(/(\...)(.*)/, '$1');
  const calculation = (principle, roi, months, frequency, startDate, addpayment,pi) => {
    var regularPayment = -pi //PMT(roi / (12 * 100), months, principle);
    var balance = principle;
    var interest = 200;
    var startdate = new Date(startDate);
    var dueDate = new Date(startDate);
    var lastDate = new Date(startDate);
    var date3 = null;
    var totalInterest = 0.0;
    var i = 0;
    var array = [];

    if (frequency == "M") {
      while (balance > 0) {
        let cal = Object.create(calculatorProperty);
        cal.regularPayment = round(regularPayment);

        if (i == 0) {
          cal.monthlyDueDate = dueDate;
          cal.payment = 0.0;
          cal.interest = 0.0;
          cal.principal = 0.0;
          cal.balance = principle;
        }
        else {
          if (i == 1) {
            dueDate = addMonths(startdate, 1);
          }
          else {
            dueDate = addMonths(dueDate, 1);
          }
          var d = new Date(dueDate);
          cal.monthlyDueDate = d;
          cal.date1 = d;
          cal.payment = ((regularPayment - addpayment));
          var payment = ((regularPayment - addpayment));
          if (i == 1) {
            cal.interest = interest;
          }
          else {
            interest = ((roi * balance / (12 * 100)));
            cal.interest = (roi * balance / (12 * 100));
          }
          cal.principal = (Math.min((-payment - interest), balance));
          balance = ((balance - (-payment) + interest));
          if (balance < 0) {
            balance = 0;
          }
          cal.balance = balance;
          totalInterest = (totalInterest + interest);
          cal.totalInterest = (totalInterest);
        }
        array.push(cal);
        i++;
      }
    }
    else {
      regularPayment = parseFloat(round(regularPayment / 2), 2);
      while (balance > 0) {
        let cal = Object.create(calculatorProperty);
        cal.regularPayment = regularPayment;
        if (i == 0) {
          cal.monthlyDueDate = dueDate;
          cal.date1 = new Date(startdate);
          cal.date2 = new Date(startdate);
          cal.date1.setDate(startdate.getDate() + 14);
          cal.date2.setDate(startdate.getDate() + 28);
          lastDate = new Date(cal.date2);
          cal.payment = 0.0;
          cal.interest = 0.0;
          cal.principal = 0.0;
          cal.balance = principle;
        }
        else {
          if (i == 1) {
            dueDate = addMonths(startdate, 1);
          }
          else {
            dueDate = addMonths(dueDate, 1);
          }
          var d = new Date(dueDate);
          cal.monthlyDueDate = d;
          cal.date1 = new Date(lastDate);
          cal.date2 = new Date(lastDate);
          cal.date3 = new Date(lastDate);
          cal.date1.setDate(lastDate.getDate() + 14);
          cal.date2.setDate(lastDate.getDate() + 28);
          var nxtduedate = new Date(dueDate);
          nxtduedate = addMonths(nxtduedate, 1);
          cal.date3.setDate(lastDate.getDate() + 42);
          lastDate = new Date(cal.date2);
          if (nxtduedate > cal.date3) {
            date3 = new Date(cal.date3);
            lastDate = new Date(cal.date3);
          }
          else {
            cal.date3 = null;
          }

          var payment = parseFloat(regularPayment * 2 - addpayment * 2);
          if (cal.date3 == null && date3 != null) {
            date3 = null;
            payment = (payment + regularPayment);
          }
          cal.payment = round(payment, 2);

          if (i == 1) {
            cal.interest = interest;
          }
          else {
            interest = ((roi * balance / (12 * 100)));
            cal.interest = round(interest, 2);
          }
          cal.principal = (Math.min((-payment - interest), balance));
          balance = ((balance - (-payment) + interest));
          if (balance < 0) {
            balance = 0;
          }
          cal.balance = balance;
          totalInterest = (totalInterest + interest);
          cal.totalInterest = (totalInterest);
        }
        array.push(cal);
        i++;
      }
    }
    return array;
  }

  const submitAmount = () => {
    setInputSlider(inputAddPayment)
    setInputAddPayment(inputAddPayment)
    calculator(data, inputFrequency, inputAddPayment)
    setIsChartDetails(true)
  }
  const addMonths = (date, months) => {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  }

  const calculator = (res, pf, aPayment) => {    
    let frequency = pf
    let principle = parseFloat(res.principalLoan)
    let roi = parseFloat(res.interestRate)
    let months = parseFloat(res.loanTermInMonths)
    let addpayment = aPayment === '' ? 0 : parseFloat(aPayment);
    let startDate = new Date()
    let payment = 0//PMT(roi / (12 * 100), months, principle);
    let array = calculation(principle, roi, months, frequency, startDate, addpayment, parseFloat(res.piAmount));
    let regularpayment = 0;
    let payoffDate = '';
    let totalInterest = 0;
    let data = (array);
    
    let projectionArr = data.map(row => {
      let label = new Date(row.monthlyDueDate).toDateString();
      return {
        x: label,
        y: row.balance,
        principal: row.principal,
        interest: row.interest
      }
    })

    if (frequency === 'M' && addpayment === 0) {
      setOriginalData(projectionArr)
      setPrepareChartData({
        projection: projectionArr,
        original: projectionArr
      })
    } else {
      setPrepareChartData({
        projection: projectionArr,
        original: originalData
      })
    }

    regularpayment = data[1].regularPayment;  

    let oldTotalIntrest = ""
    if (frequency === 'M' && addpayment === 0) {
      setTotalOldIntrest(data[data.length - 1].totalInterest)
      oldTotalIntrest = data[data.length - 1].totalInterest
    } else {
      oldTotalIntrest = totalOldIntrest ? totalOldIntrest : parseFloat(Math.abs(regularpayment)) * months - principle
    }
    let savedInt = oldTotalIntrest - data[data.length - 1].totalInterest    
    setIntrestSaved('$' + savedInt.toFixed(2))   

    setMonthlyPayment(regularpayment)
    setRevisedMonthlyPayment(data[1].payment)

    if (frequency === 'M' && addpayment === 0) {
      setReducedMonth(0)
    } else if(originalData && originalData.length>0){       
        setReducedMonth(originalData.length-data.length)
      } else {
        setReducedMonth(0)        
      }

    setPayoffDate(moment(data[data.length - 1].monthlyDueDate).format('ll'))
  }


  const PMT = (ir, np, pv, fv, type) => {
    /*
    * ir   - interest rate per month
    * np   - number of periods (months)
    * pv   - present value
    * fv   - future value
    * type - when the payments are due:
    *        0: end of the period, e.g. end of month (default)
    *        1: beginning of period
    */
    var pmt, pvif;

    fv || (fv = 0);
    type || (type = 0);

    if (ir === 0)
      return -(pv + fv) / np;

    pvif = Math.pow(1 + ir, np);
    pmt = - ir * pv * (pvif + fv) / (pvif - 1);

    if (type === 1)
      pmt /= (1 + ir);

    return pmt;
  }

  // const dateformat = (strdate) => {
  //   var currentTime = new Date((strdate));
  //   var month = currentTime.getMonth() + 1;
  //   var day = currentTime.getDate();
  //   var year = currentTime.getFullYear();
  //   if (year == 1 || isNaN(year)) {
  //     return '';
  //   }
  //   var date = month + "-" + day + "-" + year;
  //   return date;
  // }

  const getYearsAndMonths=(totalMonths)=>{    
    if(totalMonths>0){          
      let years = Math.floor(totalMonths/12)
      let months = totalMonths-(years*12)      
      let yandM =`${years} ${years<2?'Year':'Years'}, ${months} ${months<2?'Month':'Months'}`
      return yandM
    }   
    
  }

  const chartLabelsData = [2010, 2011, 2012, 2013, 2014, 2015]

  if (!data) {
    return <Box className={classes.noDataRoot}><CircularProgress /></Box>
  }

  const { 
    //isPaymentAllowed, isDueDateApproaching,
    totalAmountDue, 
    //paymentDueDate, lastPaymentDate, 
    accountNumber, principalLoan, interestRate, 
    //escrowBalance, origionalLoanAmount, origionationDate,
    //noteRate, paymentCycle, armCurrentRate, armNextPaymentChangeDate, armNextRateChangeDate,
    //piEscrow, piInterest, piPrincipal, emailID, mobileNumber, isCommConcent, 
    loanTermInMonths,
    //isMobileVerified, isAlreadyScheduleACHPayment, isGoGreen, bankDetails, 
    //isDelinquentDays, daylinquestdays,
    piAmount } = data;

  if (loading) {
    return (
      <Box className={classes.loadingRoot}>
        <CircularProgress />
      </Box>
    )
  }
  const handleBlur=()=>{
    calculator(data, inputFrequency, inputAddPayment);
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'xl'}
      PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={handleClose} open={open}
    >
      <Box className={classes.root}>
        <IconButton onClick={handleClose} className={classes.iconBtn}>
          <CancelIcon />
        </IconButton>

        {!loading && errorMessage !== '' ? (<Typography className={"errorMessage"}>{errorMessage}</Typography>) : null}
        <Typography className={"errorMessage"}>{errorMessage}</Typography>
        <Box className={classes.header}>
          <h2 className={classes.heading}>Amortization Calculator</h2>
        </Box>
        <Box className={classes.formContainer}>
          <Box className={classes.loanDetails}>
            <Box className={`${classes.loanDetailsItem} ${classes.flexBoxMobile}`}>
              <p className={classes.labelText}>Loan Amount</p>
              <p className={classes.boldText}> {`$ ${numberWithCommas(principalLoan)}`}</p>
            </Box>
            <Box className={`${classes.loanDetailsItem} ${classes.flexBoxMobile}`}>
              <p className={classes.labelText}>Loan Term</p>
              {/* <p className={classes.boldText}>{parseFloat(loanTermInMonths / 12).toFixed(2)} years</p> */}
              <p className={classes.boldText}>{getYearsAndMonths(loanTermInMonths)}</p>
            </Box>
            <Box className={`${classes.loanDetailsItem} ${classes.flexBoxMobile}`}>
              <p className={classes.labelText}>Interest Rate</p>
              <p className={classes.boldText}>{interestRate}%</p>
            </Box>
            <Box className={`${classes.loanDetailsItem} ${classes.flexBoxMobile}`}>
              <p className={classes.labelText}>Monthly Payment</p>              
              <p className={classes.boldText}>$ {numberWithCommas(piAmount)}</p>
            </Box>
          </Box>
          <Box className={classes.filterBox}>
            <Box className={classes.filterBoxWrapper}>
              <Box className={classes.loanDetailsItem} style={{ minWidth: '155px' }}>
                <FormControl className={classes.formControlMobile} variant="outlined" fullWidth>
                  <InputLabel id="select-outlined-label-frequency">Payment Frequency</InputLabel>
                  <Select
                    style={{ height: "40px" }}
                    color='primary'
                    labelId="select-outlined-label-frequency"
                    id="select-frequency"
                    value={inputFrequency}
                    onChange={handleChange('inputFrequency')}
                    label="Payment Frequency"
                    fullWidth >
                    <MenuItem value={'M'}>Monthly</MenuItem>
                    <MenuItem value={'B'}>Bi-Weekly</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box className={classes.loanDetailsItem}>
                <TextField
                  size={"small"}
                  error={inValidAddPayment}
                  helperText={inValidAddPayment ? `Max Additional Payment ${principalLoan}` : null}
                  color='primary'
                  id="outlined-additional"
                  label="Additional Payment"
                  variant="outlined"
                  type="text"
                  value={inputAddPayment}
                  onChange={handleChange('inputAddPayment')}
                  onBlurCapture={handleBlur}
                  fullWidth
                  inputProps={{
                    className: classes.inputStyle,
                  }}
                />
                <Box className={classes.filterInputBox}>
                  <Box className={classes.formControlMobile}>
                    <span className={classes.boldText}>$</span>
                    <span><input type="range" min="0" max={Math.abs(Math.round(totalAmountDue)) * 2}
                      onChange={handleChange("inputSlider")}
                      value={inputSlider || 0} /> </span>
                    <span className={classes.boldText}>$ {numberWithCommas(inputSlider)}</span>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.loanDetailsItem}>
                <p className={classes.labelText2}>Revised Payment</p>                
                {inputFrequency==='M'?(<p className={classes.boldText}>$ {numberWithCommas(Math.abs(revisedMonthlyPayment).toFixed(2))}</p>):
                (<p className={classes.boldText}>$ {numberWithCommas(Math.abs(revisedMonthlyPayment/2).toFixed(2))}</p>)}
              </Box>
              <Box className={`${classes.loanDetailsItem} ${classes.btnMobile}`}>
                <Button onClick={submitAmount} className={classes.amountSubmit}                  
                  variant="contained"
                  color="primary">
                  Calculate
                </Button>
              </Box>
            </Box>
          </Box>

          {isChartDetails ? (<Fragment>
            <Box className={classes.effectiveLoanDetails}>
              <Box className={classes.loanDetailsItem}>
                <p className={classes.labelText2}>Interest Saved</p>
                <p className={classes.boldText}>{numberWithCommas(intrestSaved)} <span className={classes.estimatedText}>(Estimated)</span></p>
              </Box>
              <Box className={classes.loanDetailsItem}>
                <p className={classes.labelText2}>Term Reduced By</p>
                <p className={classes.boldText}> {reducedMonth} Months <span className={classes.estimatedText}>(Estimated)</span> </p>
              </Box>
              <Box className={classes.loanDetailsItem}>
                <p className={classes.labelText2}>Expected Payoff Date </p>
                <p className={classes.boldText}>{payoffDate} <span className={classes.estimatedText}>(Estimated)</span> </p>
              </Box>
            </Box>
            <Box className={classes.labelsArea}>
              <Box className={classes.labelbox}>
                <Divider classes={{ root: classes.dividerColored }} />
                <p className={classes.labelText}>Projection</p>
              </Box>
              <Box className={classes.labelbox}>
                <Divider classes={{ root: classes.dividerDoted }} />
                <p className={classes.labelText}>Original Term</p>
              </Box>
            </Box>
            <Box className={classes.graphContainer}>
              <CustomChart
                chartLabels={chartLabelsData}
                chartData={prepareChartData}
              />
            </Box>
          </Fragment>) : null}
        </Box>
      </Box>
    </Dialog>
  )
}


const historyData = [
  { x: new Date('2020-01-15'), y: 100000 },
  { x: new Date('2020-02-15'), y: 90000 },
  { x: new Date('2020-03-15'), y: 80000 },
  { x: new Date('2020-04-15'), y: 70000 },
]

const projection = [
  { x: new Date('2016-04-15'), y: 7000 },
  { x: new Date('2016-05-15'), y: 6000 },
  { x: new Date('2016-06-15'), y: 5000 },
  { x: new Date('2016-07-15'), y: 4000 },
  { x: new Date('2016-08-15'), y: 3000 },
  { x: new Date('2016-09-15'), y: 2000 },
  { x: new Date('2016-10-15'), y: 1000 },
  { x: new Date('2016-11-15'), y: 0 }
]

const original = [
  { x: new Date('2020-01-15'), y: 100000 },
  { x: new Date('2020-02-15'), y: 90000 },
  { x: new Date('2020-03-15'), y: 80000 },
  { x: new Date('2020-04-15'), y: 70000 },
  { x: new Date('2020-05-15'), y: 60000 },
  { x: new Date('2020-06-15'), y: 50000 },
  { x: new Date('2020-07-15'), y: 40000 },
  { x: new Date('2020-08-15'), y: 30000 },
  { x: new Date('2020-09-15'), y: 20000 },
  { x: new Date('2020-10-15'), y: 10000 },
  { x: new Date('2020-11-15'), y: 0 },
]