import React, { useEffect } from 'react';
import clock from '../../assets/mdi_clock.png';
import openIcon from '../../assets/openInNew.png';
import './styles.css';

const defaultBodyStyles = {
  padding: '2px 15px 2px 15px',
  display: 'block !important',
  backgroundColor: '#4D4D4D',
};

const submitBtnStyle = {
  color: "#fff",
  borderRadius: "3px",
  padding: "0px 13px",
  border: "1px solid #EDB36A",
  backgroundColor: "#EDB36A",
  marginBottom: "4px",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  paddingTop: "9px",
  width: "95% ",
  maxHeight: "40px",
  height: "40px",
  margin: "0px auto 0px",
  fontSize: "25px",
  lineHeight: 0.5,
  color: '#121D22',
  textAlign: 'center',
  fontFamily: 'Montserrat',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  textTransform: 'uppercase',
};


const defaultMessageStyles = {
  width: '100%',
  padding: '3px',
  backgroundColor: '#4D4D4D',
  fontSize: '12px',
  lineHeight: 1.43,
  resize: 'none',
  border: 'none',
  color: '#ffffff',
  fontWeight: '500',
  fontFamily: 'Montserrat',
  paddingBottom: '4px'
};

const timeTake = {
  width: '100%',
  padding: '3px',
  backgroundColor: '#4D4D4D',
  fontSize: '14px',
  textAlign: 'center',
  lineHeight: '18px',
  border: 'none',
  color: '#ffffff',
  fontWeight: '500',
  fontFamily: 'Montserrat',
  paddingBottom: '4px'
};

const openIconStyle = {
  paddingLeft: '4px',
  paddingTop: '2px'
};

const defaultHeaderStyles = {
  lineHeight: '40px',
  paddingLeft: '15px',
  height: '40px',
  color: '#FFFFFF',
  fontSize: '16px',
  fontWeight: 'bold',
  overflow: 'hidden',
  fontFamily: 'Montserrat',
  position: 'relative',
  marginBottom: '10px'
};

const defaultIconStyles = {
  position: 'absolute',
  left: '46px',
  bottom: '11px',
  height: '15px',
  width: '15px'
}

const Body = ({
  bodyText,
  bodyStyles,
  handleSubmit
}) => {

  return (
    <div style={bodyStyles}>
        <div style={defaultMessageStyles}>
          <p style={defaultMessageStyles}>BSI Financial Services is assessing our logo and brand. </p>
          <p style={defaultMessageStyles}>As a valued customer of BSI, your opinions are important to this assessment. All feedback is anonymous.</p>
          <p style={defaultMessageStyles}>The survey will close on March 13, 2024. </p>
        </div>

        <button onClick={handleSubmit} style={submitBtnStyle} type="button">
          Start Survey
          <img src={openIcon} style={openIconStyle}/>
        </button>
        <div style={defaultHeaderStyles}>
          <img src={clock} style={defaultIconStyles}/>
          <p style={timeTake}>Takes less than 3 Minutes </p>
        </div>
    </div>
  )
};

Body.defaultProps = {
  bodyText: "",
  bodyStyles: defaultBodyStyles,
  showEmailInput: true,
  showRatingInput: true,
  showMessageInput: true,
  showNameInput: true,
  numberOfStars: 5,
};

export default Body;
