import { makeStyles, withStyles } from '@material-ui/core/styles';
import verificationImg from '../../assets/CreateAccount/VerifyAccount.svg'
import { TextField, OutlinedInput, InputLabel } from '@material-ui/core';
import capBg from '../../assets/capBg.svg';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'relative',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${verificationImg})`,
    backgroundPosition: "bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'contain',
    backgroundColor: "#cccccc"
  },

  formContainer: {
    width: '53%',
    minWidth: "33.3em",
    padding: '3.4em 8.4% 8.3em',
    backgroundColor: '#fff',
    borderRadius: 12,
    marginTop: '6.6em',
    marginBottom: '6.6em',
    boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '20px',
      margin: '50px 15px'
    },
  },

  title: {
    fontSize: '3.6em',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 39,
    fontFamily: 'Montserrat',
    color: "#727272",
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.4em',
    },
  },

  inputContainer: {
    marginBottom: '3.9em',
  },

  tabContainer: {
    width: '100%',
    border: "2px solid",
    borderRadius: 4,
    padding: 5,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  activeTabBtn: {
    border: 'none',
    borderWidth: 0,
    width: '48%',
    borderRadius: 4,
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "600",
    padding: 10,
    outline: "none",
    cursor: 'pointer',
  },

  tabBtn: {
    border: 'none',
    borderWidth: 0,
    borderRadius: 4,
    outline: "none",
    width: '48%',
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: 600,
    padding: 10,
    cursor: 'pointer',
  },
  radioBtn: {
    '& .MuiFormControlLabel-label': {
      fontFamily: 'Montserrat',
      fontSize: '16px',
    }
  },

  inputStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600"
  },

  labelStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "400",
  },

  signUpBtn: {
    marginBottom: 20,
    fontSize: 16,
    fontWeight: '600',
  },

  cancelBtn: {
    marginBottom: 20,
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600",
    color: "#535D6D"
  },

  errorMsg: {
    fontSize: '1.4em',
    fontWeight: 'bold',
    textAlign: 'justify',
    marginBottom: 10,
    fontFamily: 'Montserrat',
    color: "red",
  },

  error: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    textAlign: 'left',
    marginTop: 5,
    fontFamily: 'Montserrat',
    color: "red",
  },

  captchaContainer: {
    //    
  },

  captchFieldContainer: {
    marginLeft: 30,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      width: '100%',
      marginTop: '3.9em'
    },
  },

  // captcha css
  refreshButton: {
    backgroundColor: 'rgb(37, 171, 199)',
    color: 'rgb(255,255,255)',
    cursor: 'pointer'
  },
  volumeButton: {
    backgroundColor: 'rgb(37, 171, 199)',
    color: 'rgb(255,255,255)',
    cursor: 'pointer'
  },
  captchaarea: {
    backgroundImage: `url(${capBg})`,
    backgroundSize: 'cover',
    textAlign: 'center'
  },
  captchasize: {
    fontSize: '38px',
    padding: '2px',
    margin: 0,
    fontFamily: 'cursive',
    fontWeight: '600'
  },
  buttonarea: {
    textAlign: 'right',
    paddingTop: '3px'
  },
  // end captch css

}
));

export const CutomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#25ABC7',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#25ABC7',
      },
    },
  },
})(TextField);

export const CutomOutlinedInput = withStyles({
  root: {
    "&$focused $notchedOutline": {
      border: `2px solid #25abc7`
    }
  },
  focused: {},
  notchedOutline: {}
})(OutlinedInput);

export const CustomInputLabel = withStyles({
  root: {
    "&$focused": {
      color: "#25abc7",
    }
  },
  focused: {},
})(InputLabel);