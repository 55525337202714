import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Divider, InputAdornment, CircularProgress } from "@material-ui/core";
import { useStyles, DisableTooltip } from './styles';
import { useHistory } from 'react-router-dom';
import clsx from "clsx";
import { CancelAutoPayAlert, SnackBar, UpdatePaymentAlert } from '../../components';
import {
  renderDraftingOption, maskAccount, renderFullDraftingOption,
  numberWithCommas, checkDigitWithDecimal, renderDefaultMsg, getUserType, getAppSource, maskAccountNo
} from '../../helpers';
import { decrypt } from "../../helpers/encrypt";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Cookies from 'js-cookie';
import { addRecurringACHPayment, setPaymentOption, getBankAccountDetail } from '../../actions';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { AdditionalPaymentAlert } from "../AdditionalPaymentAlert";

var businessMoment = require('moment-business-days');

export const ManageAutoPaySetting = (props) => {
  
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedAccountData = useSelector(state => state.selectedAccountData);
  const [open, setOpen] = useState(false);
  const [editAdditionalPay, setEditAdditionalPay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [msg, setMsg] = useState('');
  const [severity, setSeverity] = useState('error');
  const [selectedBank, setSelectedBank] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [state, setState] = useState({
    regularPayment: '',
    paymentFrequncyType: '',
    additionalPrincipal: 0,
    additionalEscrow: 0,
    bankAccount: '',
    firstDraftDay: '',
    accountNumber: '',
    isEscrowAllowed: true,
    secondDraftDay: ''
  });

  const [additionaPayState, setAdditionaPayState] = useState({
    additionalPrincipal: '',
    additionalEscrow: '',
  });
  const [bankAccounts, setBankAccounts] = useState([]);
  const [paymentConfirmationNumber, setPaymentConfirmationNumber] = useState('');
  const [updateAlert, setUpdateAlert] = useState(false);
  const [message, showMsg] = useState(false);
  const [isAlertOpen, setAlertOpen] = useState(false);

  const closeUpdateAlert = () => {
    setUpdateAlert(false);
    dispatch(setPaymentOption("FAQ"));
    history.push('/PaymentOption');
  }

  useEffect(() => {
    if (props.data && bankAccounts.length) {
      const { lstRecurACH, accountNumber, isEscrowAllowed } = props.data;

      if (lstRecurACH && lstRecurACH.length !== 0) {
        const {
          draftAdditionalPrincipal, draftAdditionalEscrow,
          draftingOption, nextDraftDate, draftPayment,
          bankAccountNumber, bankAccountType, draftDate2
        } = lstRecurACH[0];

        // const selectedBankDetail = props.bankAccounts.filter(bankAccount => bankAccount.bankAccountNumber === bankAccountNumber);

        let selectBankAccDetail = bankAccounts.find(bankAccount => bankAccount.bankAccountNumber === bankAccountNumber);
        if (!selectBankAccDetail) {
          selectBankAccDetail = bankAccounts.find(bankAccount => bankAccount.isPreferredAcc === true);
          if (!selectBankAccDetail) {
            selectBankAccDetail = bankAccounts[0];
          }
        }

        setSelectedBank(selectBankAccDetail)
        setState({
          regularPayment: draftPayment,
          paymentFrequncyType: draftingOption,
          additionalPrincipal: draftAdditionalPrincipal,
          additionalEscrow: draftAdditionalEscrow,
          bankAccount: selectBankAccDetail ? `${selectBankAccDetail.nickName} ${maskAccountNo(decrypt(selectBankAccDetail.bankAccountNumber))}` : '',
          firstDraftDay: nextDraftDate,
          accountNumber: accountNumber,
          isEscrowAllowed: isEscrowAllowed,
          secondDraftDay: draftDate2
        });
        setAdditionaPayState({
          additionalPrincipal: draftAdditionalPrincipal,
          additionalEscrow: draftAdditionalEscrow,
        });
      } else {
        setState({ ...state, accountNumber: accountNumber, isEscrowAllowed: isEscrowAllowed })
      }
    }
  }, [props.data, bankAccounts])

  useEffect(() => {
    getBankAccounts();
  }, []);

  const getBankAccounts = async () => {
    const res = await getBankAccountDetail();
    if (res && res.lstBEBankAccountDetails && res.lstBEBankAccountDetails.length !== 0) {
      setBankAccounts(res.lstBEBankAccountDetails)
    }
  }

  const toggleOpen = () => {
    setOpen(!open)
  }

  const routeToRequestPayOff = () => {
    history.push('/PayoffRequest');
  }

  const handleCloseAlert = () => {
    setAlertOpen(false)
    scheduleAdditionalPay()
  }

  const handleClosePopup = () => {
    setAlertOpen(false);
  }
  
  const scheduleAdditionalPay = async () => {
    setLoading(true);
    setErrMsg('');
    showMsg(false);

    const { lstRecurACH, accountNumber, graceDays, maxPaymentAllowed } = props.data;
    const {
      bankABARoutingNumber, bankAccountNumber, bankAccountType, bankCity,
      bankName, bankState, draftStartDate, draftingOption, nameOnBankAccount,
      bankAccountIndicator, draftPayment, draftDate2, nextDraftDate
    } = lstRecurACH[0];
    
    const data = {
      "accountNumber": accountNumber,
      "paymentAmount": state.paymentFrequncyType === "M" ? state.regularPayment : state.regularPayment / 2,
      "abaRoutingNumber": bankABARoutingNumber,
      "bankAccountNumber": bankAccountNumber,
      "accountType": bankAccountType.toUpperCase() === 'SAVINGS' ? "S" : "C",
      "bankCity": bankCity,
      "bankName": bankName,
      "bankState": bankState,
      "draftStartDate": nextDraftDate,
      "draftingOption": draftingOption,
      "nameOnAccount": nameOnBankAccount,
      "borrowerType": renderFullDraftingOption(bankAccountIndicator),
      "additionalPrincipal": state.additionalPrincipal ? parseFloat(state.additionalPrincipal) : 0,
      "additionalEscrow": state.additionalEscrow ? parseFloat(state.additionalEscrow) : 0,
      "draftDate1": "",
      "draftDate2": draftDate2,
      "action": "C",
      "graceDays": graceDays,
      "bankProfileID": selectedBank.bankProfileID,
      "propertyState": selectedAccountData.propertyState,
      "investorCode": selectedAccountData.bsi_InvestorCode,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
      "delinquentDays": props.data.delinquentDays,
    }
    const res = await addRecurringACHPayment(data);
    if (res && res.confirmationNumber) {
      setLoading(false);
      setEditAdditionalPay(false);
      setPaymentConfirmationNumber(res.confirmationNumber);
      setUpdateAlert(true);
    } else {
      setLoading(false);
      setErrMsg(renderDefaultMsg('400'));
    }
   }
  
  const handleSaveAdditionalPay = async () => {
    setLoading(true);
    setErrMsg('');
    showMsg(false);

    const { lstRecurACH, accountNumber, graceDays, maxPaymentAllowed } = props.data;
    const {
      bankABARoutingNumber, bankAccountNumber, bankAccountType, bankCity,
      bankName, bankState, draftStartDate, draftingOption, nameOnBankAccount,
      bankAccountIndicator, draftPayment, draftDate2, nextDraftDate
    } = lstRecurACH[0];

    const finalAmount = parseFloat((state.paymentFrequncyType === "M" ? state.regularPayment : state.regularPayment / 2)) + parseFloat(state.additionalPrincipal) + parseFloat(state.additionalEscrow);
    if ((finalAmount > maxPaymentAllowed)) {
      const erMsg = <p><span>Payment Amount Restricted: Total payment amount can not be greater than $${numberWithCommas(maxPaymentAllowed)}.</span><p className={classes.errorMsg2}> To complete a payment greater than $${numberWithCommas(maxPaymentAllowed)}, please contact Customer Support.</p></p>

      setErrMsg(erMsg);
      setLoading(false);
      return;
    }

    if (finalAmount <= props.data.maxPaymentAllowed && state.additionalPrincipal >= props.data.unpaidPrincipalBalance) { 
      showMsg(true);
      setLoading(false);
      return;
    } 

    if (finalAmount <= props.data.maxPaymentAllowed && state.additionalPrincipal < props.data.unpaidPrincipalBalance && state.additionalPrincipal > (props.data.unpaidPrincipalBalance - 5000)) {
      setAlertOpen(true)
      return;
    } 

    const data = {
      "accountNumber": accountNumber,
      "paymentAmount": state.paymentFrequncyType === "M" ? state.regularPayment : state.regularPayment / 2,
      "abaRoutingNumber": bankABARoutingNumber,
      "bankAccountNumber": bankAccountNumber,
      "accountType": bankAccountType.toUpperCase() === 'SAVINGS' ? "S" : "C",
      "bankCity": bankCity,
      "bankName": bankName,
      "bankState": bankState,
      "draftStartDate": nextDraftDate,
      "draftingOption": draftingOption,
      "nameOnAccount": nameOnBankAccount,
      "borrowerType": renderFullDraftingOption(bankAccountIndicator),
      "additionalPrincipal": state.additionalPrincipal ? parseFloat(state.additionalPrincipal) : 0,
      "additionalEscrow": state.additionalEscrow ? parseFloat(state.additionalEscrow) : 0,
      "draftDate1": "",
      "draftDate2": draftDate2,
      "action": "C",
      "graceDays": graceDays,
      "bankProfileID": selectedBank.bankProfileID,
      "propertyState": selectedAccountData.propertyState,
      "investorCode": selectedAccountData.bsi_InvestorCode,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
      "delinquentDays": props.data.delinquentDays,
    }
    const res = await addRecurringACHPayment(data);
    if (res && res.confirmationNumber) {
      setLoading(false);
      setEditAdditionalPay(false);
      setPaymentConfirmationNumber(res.confirmationNumber);
      setUpdateAlert(true);
    } else {
      setLoading(false);
      setErrMsg(renderDefaultMsg('400'));
    }
  }

  const handleChange = (event) => {
    if (checkDigitWithDecimal(event.target.value)) {
      setState({ ...state, [event.target.name]: event.target.value })
    }
  }

  const toggleSnackBar = () => {
    setOpenSnackBar(!openSnackBar)
  }

  const handleEdit = () => {
    // +new Date(moment(state.firstDraftDay).format('L')) >= +new Date(conditionalDay.format('L'))
    if (businessMoment(state.firstDraftDay, 'MM-DD-YYYY').businessDiff(businessMoment(moment(), 'MM-DD-YYYY'), true) >= 3) {
      setEditAdditionalPay(true)
    } else {
      setSeverity('error')
      setMsg("You can not edit your payment, next scheduled payment is within 3 days.");
      setOpenSnackBar(true)
    }
  }

  const handleManageAutoPay = () => {

    if (businessMoment(state.firstDraftDay, 'MM-DD-YYYY').businessDiff(businessMoment(moment(), 'MM-DD-YYYY'), true) >= 3) {
      props.toggleCurrentSetting(false)
    } else {
      setSeverity('error')
      setMsg("You can not Manage your payment, next scheduled payment is within 3 days.");
      setOpenSnackBar(true)
    }
  }

  const handleCancelAutoPay = () => {

    if (businessMoment(state.firstDraftDay, 'MM-DD-YYYY').businessDiff(businessMoment(moment(), 'MM-DD-YYYY'), true) >= 3) {
      toggleOpen()
    } else {
      setSeverity('error')
      setMsg("You can not Cancel your payment, next scheduled payment is within 3 days.");
      setOpenSnackBar(true)
    }
  }

  return (
    <Box className={classes.setting}>
      <h2 className={classes.title}>Current Settings</h2>
      <Box className={classes.settingContent}>
        <h3 className={classes.settingSubTitle}>Payment Frequency</h3>
        <Box className={classes.flexContainer}>
          <Box className={classes.flexContainer}>
            <h4 className={clsx(classes.item, classes.subTitle)}>{state.paymentFrequncyType === "M" ? "Regular Monthly Payment" : 'Bi-Weekly Payment'}</h4>
            <h3 className={clsx(classes.item, classes.subTitle, classes.rightText)}>${state.paymentFrequncyType === "M" ? numberWithCommas(state.regularPayment.toFixed(2)) : numberWithCommas((state.regularPayment / 2).toFixed(2))}</h3>
          </Box>
          <Box className={classes.flexContainer}>
            {state.paymentFrequncyType === 'M' ? <p className={clsx(classes.subText, classes.item)}>Regular monthly payment of principal, interest, and escrow</p> : <p className={clsx(classes.subText, classes.item)}></p>}
            {/* <p className={clsx(classes.subText, classes.item, classes.rightText)}>Charged {renderDraftingOption(state.paymentFrequncyType)}</p> */}
          </Box>
        </Box>
      </Box>
   
      <Box className={classes.settingContent}>
        <Box className={classes.additionalPayHeader}>
          <h3 className={clsx(classes.settingSubTitle, classes.marginFix, classes.item, classes.borderNone)}>Additional Payment</h3>
          {/* <span>Benefits of Pay More</span> */}
          {(!editAdditionalPay && state.bankAccount) ? <Button onClick={() => handleEdit()} className={classes.linkText}>Edit</Button> : null}

          {(!editAdditionalPay && state.bankAccount === '') ? <DisableTooltip arrow title={'Please add a bank account first to edit.'}>
            <Button onClick={() => null} className={classes.linkText}>Edit</Button>
          </DisableTooltip> : null}

        </Box>
        {!editAdditionalPay ?
          <Box className={classes.flexContainer}>
            <Box className={clsx(classes.additionalPayItem)}>
              <h3 className={classes.additionalPaySubTitle}>Additional Principal</h3>
              <p className={clsx(classes.additionalPayAmount)}>${numberWithCommas(parseFloat(state.additionalPrincipal).toFixed(2))}</p>
            </Box>
           
            {state.isEscrowAllowed &&
              <Box className={clsx(classes.additionalPayItem)}>
                <h3 className={classes.additionalPaySubTitle}>Additional Escrow </h3>
                <p className={clsx(classes.additionalPayAmount)}>${numberWithCommas(parseFloat(state.additionalEscrow).toFixed(2))}</p>
              </Box>}
          </Box>
          :
          <Box>
            <Box className={classes.additionalPayTextFieldContainer}>
              <TextField
                color='secondary'
                label="Additional Principal"
                variant="outlined"
                value={state.additionalPrincipal}
                name='additionalPrincipal'
                onChange={handleChange}
                classes={{
                  root: classes.additionalPayTextField,
                }}
                InputProps={{
                  classes: { root: classes.textField },
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  classes: { root: classes.textField }
                }}
              />
              {state.isEscrowAllowed && <Divider className={classes.divider} orientation="vertical" flexItem />}
              {state.isEscrowAllowed && <TextField
                color='secondary'
                label="Additional Escrow"
                variant="outlined"
                value={state.additionalEscrow}
                name='additionalEscrow'
                onChange={handleChange}
                classes={{
                  root: classes.additionalPayTextField
                }}
                InputProps={{
                  classes: { root: classes.textField },
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  classes: { root: classes.textField }
                }}
              />}
            </Box>
            <Box className={classes.flexEnd}>
              <Box>
                <Button
                  disabled={loading || Cookies.get('adminToken')}
                  variant="contained"
                  color="primary"
                  onClick={handleSaveAdditionalPay}
                  className={classes.button}
                >
                  {/* {loading ? <CircularProgress color='inherit' size={20} /> : 'Update'} */}
                  Update
                </Button>
              </Box>
              <Button
                variant='outlined'
                onClick={() => {
                  setEditAdditionalPay(false); setState({
                    ...state,
                    additionalPrincipal: additionaPayState.additionalPrincipal,
                    additionalEscrow: additionaPayState.additionalEscrow,
                  });
                }}
                className={classes.button}
              >
                Cancel
              </Button>
            </Box>
            {errMsg.length !== 0 ? <p className={classes.errMsg}>{errMsg}</p> : null}
            {message ?  <>
                <p className={classes.errMsg}>
                 The amount you’ve designated is greater than your principal balance.
                 </p>
                 <p  className={classes.errMsg1}>Please select <a style={{color: 'blue'}} href={'./PayoffRequest'}>Request Payoff</a> if you intend to pay off your loan. The instructions regarding submission of funds to satisfy your loan in full will be outlined in the payoff statement. Please note that we cannot accept a payoff online. If you wish to make a payment towards your loan balance, please resubmit an amount that does not exceed your principal balance.</p>
                 </> : ''}
          </Box>
        }
      </Box>

      <Box className={classes.paymentContent}>
        <h3 className={classes.settingSubTitle}>Payment Information</h3>
        <Box className={classes.flexContainer}>
          <Box className={classes.paymentInfoItem}>
            <h4 className={classes.paymentInfoTitle}>Bank Account</h4>
            <p className={clsx(classes.paymentInfoSubTitle)}>{state.bankAccount}</p>
          </Box>
          <Box className={classes.paymentInfoItem}>
            <h3 className={classes.paymentInfoTitle}>Next Draft Date</h3>
            <p className={classes.paymentInfoSubTitle}>{moment(state.firstDraftDay).format('ll')}</p>
          </Box>
          {(state?.secondDraftDay && (state?.paymentFrequncyType == 'B')) && <Box className={classes.paymentInfoItem}>
            <h3 className={classes.paymentInfoTitle}>Second Draft Date</h3>
            <p className={classes.paymentInfoSubTitle}>{moment(state.secondDraftDay).format('ll')}</p>
          </Box>
         }
        </Box>
      </Box>

      <Box className={clsx(classes.settingContent, classes.flexContainer, classes.borderBottom)}>
        <Box className={classes.dummyitem}>
          <p className={clsx(classes.subText, classes.marginFix)}>In order to make any changes on payment frequency, payment date or bank details, create a new autopayment schedule with updated information.</p>
        </Box>
        <Box className={classes.dummyitem}>
          <Button
            className={classes.btn}
            color='primary'
            variant="contained"
            onClick={() => handleManageAutoPay()}
          >
            Manage Autopay
          </Button>
        </Box>
      </Box>

      <Box className={clsx(classes.settingContent, classes.flexContainer)}>
        <Box className={classes.dummyitem}>
          <p className={classes.subText}>Note:</p>
          <p className={classes.subText}>Cancelling Autopay will stop your regular payment. You will need to set a schedule for manual payment.</p>
        </Box>
        <Box className={classes.dummyitem}>
          <Button onClick={handleCancelAutoPay} className={classes.btn} variant="outlined" disabled={Cookies.get('adminToken')}>cancel autopay</Button>
        </Box>
      </Box>
      {props.data && props.data.lstRecurACH &&
        <CancelAutoPayAlert
          toggleOpen={toggleOpen}
          open={open}
          amount={props.data.lstRecurACH[0].draftAmount1}
          lastPaymentDate={state.firstDraftDay}
          bankAccount={state.bankAccount}
          accountNumber={state.accountNumber}
          handlePaymentOption={props.handlePaymentOption}
        />}
      { paymentConfirmationNumber.length ?
        <UpdatePaymentAlert
          confirmationNumber={paymentConfirmationNumber}
          closeUpdateAlert={closeUpdateAlert}
          open={updateAlert}
        /> : null}
      <SnackBar handleClose={toggleSnackBar}
        open={openSnackBar}
        severity={severity}
        message={msg}
      />
        {
          isAlertOpen &&
          <AdditionalPaymentAlert open={true} handleCloseAlert={handleCloseAlert} routeToRequestPayOff={routeToRequestPayOff} handleClose={handleClosePopup}
          single={true}/>
        }
    </Box>
  )
}