import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, TextField, InputAdornment, IconButton, Tooltip, CircularProgress, Button, TextareaAutosize, Divider } from '@material-ui/core';
import { useStyles } from './styles';
import { getUserType, getAppSource } from '../../helpers';
import { Link, useHistory } from 'react-router-dom';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import CustomBreadcrum from '../Breadcrum';
import { withStyles } from '@material-ui/core/styles';
import { fetchFaqs, submitRating, insertTelemetryActivity, eaWatchedVideo } from '../../actions';
import Cookies from 'js-cookie';
import Like from '../../assets/Help/like.png';
import Liked from '../../assets/Help/liked.png'
import Dislike from '../../assets/Help/dislike.png';
import DislikeClicked from '../../assets/Help/DislikeClicked.png';
import youtube_small from '../../assets/Help/youtube_small.svg';
import article_small from '../../assets/Help/article_small.svg';
import { SwitchButton } from '../SwitchButton';
import YouTubePlayer from './YoutubePlayer';
import { useLocation } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import './index.css';
import { Typography } from 'antd';


export const HelpArticle = (props) => {
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'));

  const location = useLocation();
  const classes = useStyles();
  const mytheme = useSelector(state => state.theme);
  const { primaryColorCode, secondaryColorCode } = mytheme;
  const privateLabelUrl = Cookies.get("privateLabelUrl")

  const [list, setList] = React.useState([]);
  const [data, setFaqLists] = React.useState([]);
  const [input, setInput] = React.useState('');
  const [faqExpandStatus, setFaqExpandStatus] = React.useState(true);
  const [loader, setLoaderStatus] = React.useState(true);
  const [searchCount, setTotalCount] = React.useState(0);
  const [searchInput, setSearchInput] = React.useState("");
  const [videoFilter,setVideoFilter] = React.useState(false);
  const [videoCount,setTotalVideoCount] = React.useState(0);
  const [activeVideo, setActiveVideo] = React.useState(null);
  const isAdmin = Cookies.get("adminToken");

  useEffect(() => {
    getFaqs()
  }, [props.page, mediaQuery]);
 
  const getFaqs = async () => {
    const response = await fetchFaqs()
    if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode === '0') {
      const res = response?.responseData?.responseData;
      setLoaderStatus(false)
      const updatedfaqList = res.map(item => {
        return {
          category: item.category.replaceAll('[PLName]', mytheme.privateLabelName),
          status: false,
          ...getVideoCounts(item?.faqQAs),
          faqQAs: item?.faqQAs?.map(ques => {
            return {
              question: ques.question.replaceAll('[PLName]', mytheme.privateLabelName),
              faqType:ques.faqType,
              answer: ques.answer.replaceAll('[PLName]', mytheme.privateLabelName).replaceAll('[CCNumber]', mytheme.customerCareNumber).replaceAll('[PLAddress]', mytheme.address).replaceAll('[CO]', privateLabelUrl == 'emm' ? 'C/O BSI Financial Services<br>' : '').replaceAll('[InsuranceNo]', mytheme.insuranceNo),
              status: false,
              videoId:ques.videoId,
              id:Math.floor(Math.random() * 1000)
            }
          })
        };
      });
      if ((props.page == '') && !location.search.includes('escrow') && !location.search.includes('eaAnalysis')) {
        setList(updatedfaqList)
        setFaqLists(updatedfaqList)
      } else if (props.page == 'CommonlyAskedQuestions') {
        const newObj = [...updatedfaqList]
        const index = newObj.findIndex((item) => item.category.toLowerCase() == 'financial hardship');
        newObj[index]['status'] = true
        setList(newObj)
        setFaqLists(newObj)
        setTimeout(() => {
          window.scrollTo({ top: 550, left: 0, behavior: 'smooth' });
        }, 1000);
      } else if (props.page == 'DisasterAssistance') {
        const newObj = [...updatedfaqList]
        newObj[5]['status'] = true
        setList(newObj)
        setFaqLists(newObj)
        setTimeout(() => {
          window.scrollTo({ top: 725, left: 0, behavior: 'smooth' });
        }, 1000);
      } else if (location.search.includes('escrow')) { 
        watchEAVideo();
        const newObj = [...updatedfaqList]
        newObj[6]['status'] = true
        newObj[6]['faqQAs'][1]['status'] = true;
        setList(newObj)
        setFaqLists(newObj)
        setTimeout(() => {
          window.scrollTo({ top: mediaQuery ? 1300 : 1000, left: 0, behavior: 'smooth' });
        }, 1000);
      } else if (location.search.includes('eaAnalysis')) {
        const newObj = [...updatedfaqList]
        newObj[6]['status'] = true
        newObj[6]['faqQAs'][1]['status'] = true;
        setList(newObj)
        setFaqLists(newObj)
        setTimeout(() => {
          window.scrollTo({ top: mediaQuery ? 1400 : 1100, left: 0, behavior: 'smooth' });
        }, 1000);
      }
    } else {
      setLoaderStatus(false)
    }
  }

  const watchEAVideo = async () => {
    const watchEAVideo = await eaWatchedVideo(props?.accountNumber);
  }

  useEffect(() => {
    if (list?.length > 0) {
      if (input == '') {
        const status = list.every(item => item.status == false);
        setFaqExpandStatus(status)
      } else {
        const status = list.every((item) => item.faqQAs.every(key => key.status == false));
        setFaqExpandStatus(status)
      }
    }
  }, [list])

  const handleVideoStart = (videoId) => {
    if (activeVideo && activeVideo !== videoId) {
      setActiveVideo(null); // Stop the currently active video
    }
    setActiveVideo(videoId); // Set the new video as active
  };

  const setStatusValue = (index, status) => {
    let newObj = [...list]
    newObj[index]['status'] = !status
    setList(newObj)
  }

  const setQuesValue = (index, ind, status) => {
    let newObj = [...list]
    newObj[index]['faqQAs'][ind]['status'] = !status;
    setList(newObj)
  }

  const sendActivityData = async (totalCount) => {
    const data = {
      "accountNumber": props?.accountNumber || null,
      "keyword": searchInput,
      "resultCount": totalCount,
      "category": null,
      "question": null,
      "isAnswerLiked": false,
      "answerFeedback": null,
      "activityType": "Faq",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };
    const isUserLoggedIn = Cookies.get("privateToken");
    let response;
    if (isUserLoggedIn) {
      response = await insertTelemetryActivity(data);
    }
    else {
      response = await submitRating(data);
    }
  }
  const handleChange = () => {
    setInput(searchInput);
    setVideoFilter(false);

    if (searchInput === '') {
      // setList(data)
      const updatedLists = data.map(item => {
        return {
          category: item.category,
          status: false,
          ...getVideoCounts(item?.faqQAs),
          faqQAs: item?.faqQAs?.map(ques => {
            return {
              question: ques.question,
              answer: ques.answer,
              faqType:ques.faqType,
              videoId:ques.videoId,
              status: false,
              id:Math.floor(Math.random() * 1000)
            }
          })
        };
      });
      setList(updatedLists)
    } else {
      const updatedFaqs = data.map(ques => {
        const updatedQuestionsList = ques?.faqQAs?.filter(item => {
          return (new RegExp('(?:^|\\s)'+searchInput.trim(), 'i').test(ques.category) || new RegExp('(?:^|\\s)'+searchInput.trim(), 'i').test(item.question) || new RegExp('(?:^|\\s)'+searchInput.trim(), 'i').test(item.answer))
        })
              
        if (updatedQuestionsList?.length > 0)
          return ({ ...ques, faqQAs: updatedQuestionsList });
      })
      const filterData = updatedFaqs?.filter(function (element) {
        return element !== undefined;
      });
      // if (filterData.length > 0) {
      //     let newObj = [...filterData]  
      //     newObj[0]['status'] = true
      //     setList(newObj)
      // } else {

      const faqLists = filterData.map(item => {
        return {
          category: item.category,
          status: true,
          ...getVideoCounts(item?.faqQAs),
          faqQAs: item?.faqQAs?.map(ques => {
            return {
              question: ques.question,
              videoId:ques.videoId,
              answer: ques.answer,
              faqType:ques.faqType,
              status: true,
              id:Math.floor(Math.random() * 1000),
              categoryCount :(item.category.toLowerCase().match(new RegExp(searchInput.trim()?.toLowerCase(), "g")) || []).length++, 
              questionCount: (ques.question.toLowerCase().match(new RegExp(searchInput.trim()?.toLowerCase(), "g")) || []).length++,
              answerCount: (ques.answer.toLowerCase().match(new RegExp(searchInput.trim()?.toLowerCase(), "g")) || []).length++
            }
          })
        };
      });

      var totalCount = 0;
      faqLists.forEach(item => {
        item.faqQAs.forEach(key => {
          totalCount += key.questionCount + key.answerCount+ key.categoryCount;
        });
      })

      setTotalCount(totalCount)
      setList(faqLists)
      sendActivityData(totalCount);
    }
  }

  const clearSearch = () => {
    setInput('')
    setSearchInput('');
    const updatedfaqList = data.map(item => {
      return {
        category: item.category,
        status: false,
        ...getVideoCounts(item?.faqQAs),
        faqQAs: item?.faqQAs?.map(ques => {
          return {
            question: ques.question,
            answer: ques.answer,
            videoId:ques.videoId,
            faqType:ques.faqType,
            status: false,
            id:Math.floor(Math.random() * 1000)
          }
        })
      };
    });
    setList(updatedfaqList)
  }

  const collapseAllFaq = () => {
    const updatedfaqList = list.map(item => {
      return {
        category: item.category,
        status: false,
        ...getVideoCounts(item?.faqQAs),
        faqQAs: item?.faqQAs?.map(ques => {
          return {
            question: ques.question,
            answer: ques.answer,
            videoId:ques.videoId,
            faqType:ques.faqType,
            status: false,
            id:Math.floor(Math.random() * 1000)
          }
        })
      };
    });
    setList(updatedfaqList)
  }
  //FAQ telemety changes
  const ratingAction = async (value, index, ind, category, question, comment) => {
    let newObj = [...list];
    const obj = {
      "accountNumber": props?.accountNumber,
      "keyword": null,
      "resultCount": 0,
      "category": category,
      "question": question,
      "isAnswerLiked": value === 'like' ? true : false,
      "answerFeedback": comment,
      "activityType": "Faq",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    }

    const isUserLoggedIn = Cookies.get("privateToken")
    if (value === 'unlikedOpen') {
      newObj[index]['faqQAs'][ind]['comment'] = '';
      newObj[index]['faqQAs'][ind]['unlikedOpen'] = true;
      newObj[index]['faqQAs'][ind]['unliked'] = true;
      newObj[index]['faqQAs'][ind]['like'] = false;
      setList(newObj);
    }
    else if (value === 'like') {
      newObj[index]['faqQAs'][ind]['unliked'] = false;
      newObj[index]['faqQAs'][ind]['like'] = true;
      newObj[index]['faqQAs'][ind]['unlikedOpen'] = false;
      setList(newObj);
      obj.isAnswerLiked = true;

      let response;
      if (isUserLoggedIn) {
        response = await insertTelemetryActivity(obj);
      }
      else {
        response = await submitRating(obj);
      }
    }
    else if (value === 'skipped') {
      //newObj[index]['faqQAs'][ind]['loader'] = true;
      ///call the api
      obj.isAnswerLiked = false;
      let response;
      if (isUserLoggedIn) {
        newObj[index]['faqQAs'][ind]['unlikedOpen'] = false;
       // newObj[index]['faqQAs'][ind]['loader'] = true;
        setList(newObj)
        response = await insertTelemetryActivity(obj);
        if (response && (response?.data?.responseData.lstResponseStatus[0]?.statusCode === '0' || response?.data?.responseData.lstResponseStatus[0]?.statusCode === 0)) {
         // newObj[index]['faqQAs'][ind]['loader'] = false;
        }
      }
      else {
        newObj[index]['faqQAs'][ind]['unlikedOpen'] = false;
        setList(newObj)
        response = await submitRating(obj);
        if (response && (response?.responseData.lstResponseStatus[0]?.statusCode === '0' || response?.responseData.lstResponseStatus[0]?.statusCode === 0)) {
         // newObj[index]['faqQAs'][ind]['loader'] = false;
        }
      }

    }
    else if (value === 'submit') {
      // newObj[index]['faqQAs'][ind]['unlikedOpen'] = false; 
      newObj[index]['faqQAs'][ind]['unliked'] = true;
      newObj[index]['faqQAs'][ind]['comment'] = comment;
     // newObj[index]['faqQAs'][ind]['loader'] = true;
      newObj[index]['faqQAs'][ind]['unlikedOpen'] = false;
      ///call the api
      obj.isAnswerLiked = false;
      obj.answerFeedback = comment;
      let response;
      if (isUserLoggedIn) {
        setList(newObj)
        response = await insertTelemetryActivity(obj);
        if (response && (response?.data?.responseData.lstResponseStatus[0]?.statusCode === '0' || response?.data?.responseData.lstResponseStatus[0]?.statusCode === 0)) {
         // newObj[index]['faqQAs'][ind]['loader'] = false;
        }
      }
      else {
        newObj[index]['faqQAs'][ind]['unlikedOpen'] = false;
        setList(newObj)
        response = await submitRating(obj);
        if (response && (response?.responseData.lstResponseStatus[0]?.statusCode === '0' || response?.responseData.lstResponseStatus[0]?.statusCode === 0)) {
          //newObj[index]['faqQAs'][ind]['loader'] = false;
        }
      }
    }
  
  }
  //Faq Telemetry changes
  const calculateCommentLenth = (value, index, ind) => {
    let newObj = [...list];
    if (!value) {
      newObj[index]['faqQAs'][ind]['totalChars'] = 0;
    }
    const length = value.length;
    if (isNaN(length)) {
      newObj[index]['faqQAs'][ind]['totalChars'] = 0;
    } else {
      newObj[index]['faqQAs'][ind]['totalChars'] = length;
      newObj[index]['faqQAs'][ind]['comment'] = value;
    }
    setList(newObj)
  }

  const handleSearch = () => {
    if (input === '') {
      const updatedLists = data.map(item => {
        return {
          category: item.category,
          status: false,
          ...getVideoCounts(item?.faqQAs),
          faqQAs: item?.faqQAs?.map(ques => {
            return {
              question: ques.question,
              videoId:ques.videoId,
              answer: ques.answer,
              faqType:ques.faqType,
              status: false,
              id:Math.floor(Math.random() * 1000)
            }
          })
        };
      });
      setList(updatedLists)
      // setList(data)
    } else {
      const updatedFaqs = data.map(ques => {
        const updatedQuestionsList = ques?.faqQAs?.filter(item => {
          return (item.question.toLowerCase().includes(input?.toLowerCase()) || item.answer.toLowerCase().includes(input?.toLowerCase()))
        })
        if (updatedQuestionsList?.length > 0)
          return ({ ...ques, faqQAs: updatedQuestionsList });
      })
      const filterData = updatedFaqs?.filter(function (element) {
        return element !== undefined;
      });

      const faqLists = filterData.map(item => {
        return {
          category: item.category,
          status: true,
          ...getVideoCounts(item?.faqQAs),
          faqQAs: item?.faqQAs?.map(ques => {
            return {
              question: ques.question,
              answer: ques.answer,
              videoId:ques.videoId,
              faqType:ques.faqType,
              status: true,
              id:Math.floor(Math.random() * 1000),
              categoryCount :(item.category.toLowerCase().match(new RegExp(searchInput?.toLowerCase(), "g")) || []).length++, 
              questionCount: (ques.question.toLowerCase().match(new RegExp(input?.toLowerCase(), "g")) || []).length++,
              answerCount: (ques.answer.toLowerCase().match(new RegExp(input?.toLowerCase(), "g")) || []).length++
            }
          })
        };
      });

      var totalCount = 0;
      faqLists.forEach(item => {
        item.faqQAs.forEach(key => {
          totalCount += key.questionCount + key.answerCount + key.categoryCount;
        });
      })
      setTotalCount(totalCount);
      setList(faqLists)
    }
  }
  const getVideoCounts=(faqList=[])=>{
    const totalArticles = faqList.length;
    const videoCount =  faqList.filter(item => {
      return item.faqType==='V';
    }) || []
    const textCount = totalArticles - videoCount.length;
    return {totalArticles:totalArticles,videoCount:videoCount.length,textCount:textCount}
  }
  const applyVideoFilter=(e)=>{
      setVideoFilter(e.fieldValue);
      setInput('')
      setSearchInput('');
      if(e.fieldValue){
        let total=0
        const updatedFaqs = data.map(ques => {

          const updatedQuestionsList = ques?.faqQAs?.filter(item => {
            return item.faqType==='V';
          })
          total = total+updatedQuestionsList.length
          if (updatedQuestionsList?.length > 0)
            return ({ ...ques, faqQAs: updatedQuestionsList});
        })
        const filterData = updatedFaqs?.filter(function (element) {
          return element !== undefined;
        });
        const faqLists = filterData.map(item => {
          return {
            category: item.category,
            status: true,
            ...getVideoCounts(item?.faqQAs),
            faqQAs: item?.faqQAs?.map(ques => {
              return {
                question: ques.question,
                answer: ques.answer,
                videoId:ques.videoId,
                faqType:ques.faqType,
                status: true,
                id:Math.floor(Math.random() * 1000)
               
              }
            })
          };
        });
  
       
        setTotalVideoCount(total);
        setList(faqLists)
  
      }
      else{
        setInput('')
        setSearchInput('');
        const updatedfaqList = data.map(item => {
          return {
            category: item.category,
            status: false,
            ...getVideoCounts(item?.faqQAs),
            faqQAs: item?.faqQAs?.map(ques => {
              return {
                question: ques.question,
                answer: ques.answer,
                videoId:ques.videoId,
                faqType:ques.faqType,
                status: false,
                id:Math.floor(Math.random() * 1000)
              }
            })
          };
        });
        setList(updatedfaqList)
      }
  }
  const handleSearchChange = (event) => {
    setSearchInput(event.target.value)
  }
  // if no videos then hide video filter from FAQ
  const checkAnyVideo=()=>{
    let total = 0 ;
    const updatedFaqs = data.map(ques => {

      const updatedQuestionsList = ques?.faqQAs?.filter(item => {
        return item.faqType==='V';
      })
      total = total+updatedQuestionsList.length
      
    })
    return total>0?true:false;

  }
  const keyPress = (e) => {
    if (e.keyCode == 13) {
      handleChange();
    }
  }

  if (!loader) {
    return (
      <React.Fragment>
        {props.activePage == 'ENROLLMENT' ?
          <Box className={classes.root}>
            <CustomBreadcrum backPage="Support" backPageLink="/HelpLanding" activePage={props.activePage} />
            <div dangerouslySetInnerHTML={{ __html: props.innerHtml }} className={classes.Margin0} />
          </Box> : props.activePage == 'FAQs' &&
          <>
            <Box className={classes.root}>
              <CustomBreadcrum backPage="Support" backPageLink="/HelpLanding" activePage={props.activePage} />
              <p className={classes.heading}>Frequently Asked Questions</p>
              <p className={classes.paragraph}>Do you have questions about the payment application? Don’t worry, so do many others! We’ve compiled a list of common questions we get asked and the answers to them so that it’s easy for you to find the answers.</p>
            </Box>
            <Box className={classes.searchContainer}>
              <h1 className={classes.heading1}>How can we help you?</h1>
              <TextField
                placeholder={'Search FAQs'}
                variant="outlined"
                InputLabelProps={{ shrink: false }}
                className={classes.textField}
                onChange={handleSearchChange}
                onKeyDown={keyPress}
                value={searchInput}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      {(searchInput !== "") &&
                        <IconButton
                          aria-label="clear search"
                          onClick={() => clearSearch()}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>}
                      <IconButton className={classes.searchIcon} onClick={(e) => handleChange(e)}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  className: classes.searchField,
                }}
              />

              {/* <TextField
                fullWidth
                color="primary"
                id={`outlined-`}
                variant="outlined"
                type="text"
                autoComplete="off"
                value={input}
                onChange={handleChange}
                className={classes.textField}
                placeholder="Search FAQs"
                InputLabelProps={{
                  classes: { root: classes.textField }
                }}
                InputProps={{
                  classes: { root: classes.textField, input: classes.textInput },
                  maxLength: 2,
                  endAdornment: (
                    <InputAdornment position="end">
                      {(input !== "") &&
                        <IconButton
                          aria-label="clear search"
                          onClick={() => clearSearch()}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>}
                    </InputAdornment>

                  ),
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="clear search"
                        onClick={() => handleSearch()}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>

                  )
                }}
              /> */}
              <Box className={classes.header}>
                {list.length == 0 ? <h1 className={classes.heading2}>We couldn't find any FAQ about "{input}", try adjusting your search terms.</h1> : input == '' &&
                  <h1 className={classes.heading3}> You can also browse the topics below to find what you are looking for.</h1>
                }
                {
                  (input != '' && list.length != 0) ? <h1 className={classes.searchCount}>{searchCount} Search Results for "{input}"</h1> : null
                }
                <Box className={classes.actionPanel}>

                 <Box className={classes.videosFilter}>
                    {!input && checkAnyVideo() && <> <Typography className={classes.filterText}> Show Videos Only</Typography>
                    <SwitchButton
                      status={videoFilter}
                      handleSwitch={applyVideoFilter}
                      switchName={"vcMobile"}
                      classes={'faqVideo'}
                      switchOFFText={'NO'}
                      switchONText={'YES'}
                    ></SwitchButton>
                    {videoFilter && <Typography className={classes.filterText} style={{marginLeft:'30px'}}>{videoCount} articles</Typography>}
                    </>}
                  </Box>
                  {(list.length > 0 && !faqExpandStatus) &&
                    <Box className={classes.collapseAllBtn}>
                      <Button className={classes.profileBtn}
                        variant="contained"
                        color="primary"
                        onClick={() => collapseAllFaq()}
                      >Collapse All
                      </Button>
                    </Box>
                  }
                </Box>
              </Box>
            </Box>

            {/* <YouTubePlayer
    onVideoStart={handleVideoStart}
    key={'HqzvtyRdmnI'+'_'+123}
    id={123}
    videoId={'HqzvtyRdmnI'}
    active={('HqzvtyRdmnI'+'_'+123) === activeVideo}/> */}

            <Box className={classes.mainContainer}>
              {list.map((key, index) => {
                return (
                  <Box className={`${classes.accordian} ${'faqAccordian'}`}>

                    <Accordion className={classes.acc} elevation={0} expanded={key?.status == true} onChange={() => setStatusValue(index, key?.status)}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon className={classes.expand_icon} />}
                          aria-controls={`panel${index}c-content`}
                          id={`panel${index}c-header`}
                          style={{ display: 'flex' }}
                        >
                          <Box>
                            <Box dangerouslySetInnerHTML={{ __html: key.category }} className={classes.categories} />
                            <Box className={classes.bottomBox}>
                              <Typography className={classes.faqCount}>{key?.totalArticles} Articles</Typography>
                              {key.videoCount!==0 && <Typography className={classes.faqCount}><img src={youtube_small} className={classes.ytSmall} />{key.videoCount} </Typography>}
                              {key.textCount!==0 && <Typography className={classes.faqCount}><img src={article_small} className={classes.ytSmall} /> {key.textCount}</Typography>}
                            </Box>
                          </Box>
                        </AccordionSummary>
                      <Divider/>
                      <AccordionDetails>
                         {key?.faqQAs?.map((ques, ind) => {
                         return (
                            <Box>
                              <Accordion elevation={0} expanded={ques?.status == true} style={{padding:'0px 20px'} }onChange={() => setQuesValue(index, ind, ques?.status)}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon className={classes.expand_icon} />}
                                  aria-controls={`panel${index}c-content`}
                                  id={`panel${index}c-header`}

                                  className={`${classes.transactionText} ${key?.faqQAs.length === ind+1?'borderBtm':''}`}
                                >
                                  {ques.faqType =='V' && <Typography className={classes.faqCount}><img src={youtube_small} /></Typography>}
                                  {ques.faqType =='T' && <Typography className={classes.faqCount}><img src={article_small}  /> </Typography>}
                                  <Box dangerouslySetInnerHTML={{ __html: ques.question }} className={classes.question} />
                                </AccordionSummary>
                                <AccordionDetails className={`${classes.line} ${key?.faqQAs.length === ind+1?'borderBtm':''}`}>
                                {ques.faqType =='V' && <Box style={{display:'flex',justifyContent:'center'}}>  <YouTubePlayer
                                      onVideoStart={handleVideoStart}
                                      key={ques.videoId+'_'+ques.id}
                                      id={ques.id}
                                      videoId={ques.videoId}
                                      active={(ques.videoId+'_'+ques.id) === activeVideo}
                                    /> </Box>}

                                  <Box  className={`${classes.desc} ${'faq'}`}>
                            
                                  <Box dangerouslySetInnerHTML={{ __html: ques.answer }} />
                                  
                                  </Box>
                                  <Box className={classes.rating}>
                                    <Typography className={classes.ratingText}>Rate this Content</Typography>
                                    {!ques.unliked && <Tooltip title="I dislike this" arrow><span> <img src={Dislike} className={classes.dislike} onClick={() => ratingAction('unlikedOpen', index, ind, key.category, ques.question)} /></span></Tooltip>}
                                    {ques.unliked && <Tooltip title="I dislike this" arrow><span> <img src={DislikeClicked} className={classes.dislike} /></span></Tooltip>}
                                    {!ques.like && <Tooltip title="I like this" arrow><span><img src={Like} className={classes.like} onClick={() => ratingAction('like', index, ind, key.category, ques.question)} /></span></Tooltip>}
                                    {ques.like && <Tooltip title="I like this" arrow><span><img src={Liked} className={classes.like}  /></span></Tooltip>}
                                   
                                  </Box>
                                  {ques.unlikedOpen && <Box className={classes.improveComment}>
                                    <Box className={classes.textareaSection}>
                                      <TextareaAutosize
                                        style={{ height: '96px' }}
                                        color="primary"
                                        id={`outlined_impacted25099`}
                                        variant="outlined"
                                        type="text"
                                        name="comments"
                                        maxLength="500"
                                        value={ques.comment}
                                        onChange={(e) => { calculateCommentLenth(e.target.value, index, ind) }}
                                        placeholder='Help us improve(optional)'
                                        InputLabelProps={{
                                          FormLabelClasses: {
                                            asterisk: classes.labelAsterisk
                                          }
                                        }}
                                        className={`${classes.textArea}`}
                                      >
                                      </TextareaAutosize>
                                      <Typography className={classes.textAreaValidation}>{ques.totalChars ? ques.totalChars : 0}/500</Typography>
                                    </Box>
                                    <Box className={classes.rating} style={{ 'marginTop': '15px' }}>
                                      <Button
                                        variant='outlined'
                                        className={classes.disableBtn}
                                        onClick={() => ratingAction('skipped', index, ind, key.category, ques.question)}
                                      > {'SKIP'}</Button>

                                      {!ques.loader && <Button
                                        variant='contained'
                                        color='primary'
                                        className={classes.btn}
                                        disabled={!ques.comment || isAdmin}
                                        onClick={() => ratingAction('submit', index, ind, key.category, ques.question, ques.comment)}
                                      > {'SUBMIT'}</Button>
                                      }
                                      {ques.loader && <Button disabled={true} type='submit' className={classes.subBtn} variant="contained" color='primary'>
                                        <CircularProgress color='primary' style={{ height: '24px', width: '24px' }} />
                                      </Button>}
                                    </Box>
                                  </Box>}
                                </AccordionDetails>
                              </Accordion>
                            </Box>)
                        })}
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                )
              })
              }
              {input !== '' && <p className={classes.finding}>Still not finding what you are looking for? Please contact <a href="../HelpLanding?contactus" target="_self">customer service</a>.</p>}
            </Box>
          </>
        }
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
          <Box my={1} mx={2}>
            <CircularProgress color='primary' />
          </Box>
        </Box>
      </React.Fragment>
    )
  }
}
