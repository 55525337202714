import React, { Fragment, useEffect, useState } from 'react';
import {
  Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Typography, Checkbox,
} from '@material-ui/core';
import { NextPrevButton } from '../NextPrevButton'
import { useStyles } from './styles';
import { SaveAndExitPopup } from '../SaveAndExitPopup';
import { useHistory } from 'react-router-dom';

export const SectionH = (props) => {
  const { parentStyle } = props
  const classes = useStyles();
  const history = useHistory();

  const nullCheck = (str) => str ? str : ''
  const [sectionH, setSectionH] = useState({
    isUpdated:false,
    /*Borrower*/
    borrower: "",
    borrowerEthnicity: "",
    borrowerRace: "",
    borrowerSex: "",
    /*Co-Borrower*/
    coBorrower: "",
    coBorrowerEthnicity: "",
    coBorrowerRace: "",
    coBorrowerSex: ""
  })

  const {
    /*Borrower*/
    borrower,
    borrowerEthnicity,
    borrowerRace,
    borrowerSex,
    /*Co-Borrower*/
    coBorrower,
    coBorrowerEthnicity,
    coBorrowerRace,
    coBorrowerSex
  } = sectionH

  useEffect(() => {
    if (props.data) {
      const { borrower, coBorrower } = props.data
      setSectionH({
        /*Borrower*/
        borrower: nullCheck(borrower.borrower),
        borrowerEthnicity: nullCheck(borrower.borrowerEthnicity),
        borrowerRace: nullCheck(borrower.borrowerRace),
        borrowerSex: nullCheck(borrower.borrowerSex),
        /*Co-Borrower*/
        coBorrower: nullCheck(coBorrower.coBorrower),
        coBorrowerEthnicity: nullCheck(coBorrower.coBorrowerEthnicity),
        coBorrowerRace: nullCheck(coBorrower.coBorrowerRace),
        coBorrowerSex: nullCheck(coBorrower.coBorrowerSex),
        /*isUpdated*/
        isUpdated:true
      })
    }
  }, [props.data])

  useEffect(()=>{
    if(sectionH.isUpdated){
      let formErrors = formValidationSectionH()
      setFormErrorMap(formErrors)
    }
  }, [sectionH.isUpdated])

  const handleChange = (event) => {
    setSectionH({ ...sectionH, [event.target.name]: event.target.value });
  };

  const handleSave = (e) => {
    let formErrors = formValidationSectionH()
    let formErrorLength = Object.keys(formErrors).length
    let sectionCompleteStatus = formErrorLength>0? "incomplete" : "complete"
    props.status({id:7, sectionName:"section_H", status:sectionCompleteStatus})

    const data = {
      "section_H": {
        borrower: {
          /*Borrower*/
          borrower,
          borrowerEthnicity,
          borrowerRace,
          borrowerSex
        },
        coBorrower: {
          /*Co-Borrower*/
          coBorrower,
          coBorrowerEthnicity,
          coBorrowerRace,
          coBorrowerSex
        },
        "sectionStatus":{
          "status":sectionCompleteStatus,
          "sectionName":"Section H:Information For Government Monitoring Purposes"
          },
      }
    }
    props.saveFromParent(data)
  }

  const saveAndExitAction = (action) => {
    if (action === "leave") {
      handleSave()
      history.push('/Dashboard')
    }
  }

  const checkBoxStatus = (status) => {
    if (status === "true" || status === true) {
      return true
    } else {
      return false
    }
  }

/*Error handler*/
const [formErrorMap, setFormErrorMap] = useState([])
const isEmpty=(str)=>str?false:true
  
const formValidationSectionH = ()=>{ 
  const formErrorMessage={}

  if(isEmpty(borrower)){
    formErrorMessage["borrower"]="This is required field"
  } else if(borrower==='Yes'){
    if(isEmpty(borrowerEthnicity)){
      formErrorMessage["borrowerEthnicity"]="This is required field"
    }
    if(isEmpty(borrowerRace)){
      formErrorMessage["borrowerRace"]="This is required field"
    }
    if(isEmpty(borrowerSex)){
      formErrorMessage["borrowerSex"]="This is required field"
    }
  }
  /*co-Borrower*/
  if(isEmpty(coBorrower)){
    formErrorMessage["coBorrower"]="This is required field"
  } else if(coBorrower==='Yes'){
    if(isEmpty(coBorrowerEthnicity)){
      formErrorMessage["coBorrowerEthnicity"]="This is required field"
    }
    if(isEmpty(coBorrowerRace)){
      formErrorMessage["coBorrowerRace"]="This is required field"
    }
    if(isEmpty(coBorrowerSex)){
      formErrorMessage["coBorrowerSex"]="This is required field"
    }
  }
  console.log("===form error H===",formErrorMessage)
  return formErrorMessage;
}

const errorHandler = (field) => {
  if (formErrorMap[field]) {
    let message = formErrorMap[field]==="invalid"?"Invalid Data, Please check the value" :"*"
    return message
  } else {
    return false
  }
}

const radioErrorHandler=(field)=>{
  if(errorHandler(field)){
    return (<span className={parentStyle.errorMessage}>{errorHandler(field)}</span>)
  }
}
/*Error handler end*/

  return (
    <Box className={classes.root}>
      <Box style={{ marginTop: "-85px" }}>
        <SaveAndExitPopup actionCallBack={saveAndExitAction}></SaveAndExitPopup>
      </Box>


      <Box height={"90%"} style={{ paddingTop: "25px" }}>
        <Typography className={classes.title}>Information For Government Monitoring Purposes </Typography>
        <form className={classes.formStyle}>
          <Box className={classes.inputBox}>
            <Typography className={classes.title}>Borrower</Typography>            
            <FormControl component="fieldset" className={classes.formControl} >
              <FormLabel component="legend" className={parentStyle.formLabel}>I wish to furnish this in formation
              {radioErrorHandler("borrower")} 
              </FormLabel>
              <RadioGroup name='borrower' value={borrower} onChange={handleChange}>
                <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            {/* <FormControlLabel
              control={<Checkbox checked={checkBoxStatus(borrower)} onChange={handleChange} name="borrower" color="primary" />}
              label="I do not wish to furnish this in formation" />
               */}               
            {borrower==="Yes" && <Fragment>
            <FormControl component="fieldset" className={classes.formControl} >
              <FormLabel component="legend" className={parentStyle.formLabel}>Ethnicity {radioErrorHandler("borrowerEthnicity")} </FormLabel>
              <RadioGroup name='borrowerEthnicity' value={borrowerEthnicity} onChange={handleChange}>
                <FormControlLabel className={parentStyle.labelStyle} value="Hispanic or Latino" control={<Radio />} label="Hispanic or Latino" />
                <FormControlLabel className={parentStyle.labelStyle} value="Not Hispanic or Latino" control={<Radio />} label="Not Hispanic or Latino" />
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset" className={classes.formControl} >
              <FormLabel component="legend" className={parentStyle.formLabel}>Race {radioErrorHandler("borrowerRace")}</FormLabel>
              <RadioGroup name='borrowerRace' value={borrowerRace} onChange={handleChange}>
                <FormControlLabel className={parentStyle.labelStyle} value="American Indian or Alaska Native" control={<Radio />} label="American Indian or Alaska Native" />
                <FormControlLabel className={parentStyle.labelStyle} value="Asian" control={<Radio />} label="Asian" />
                <FormControlLabel className={parentStyle.labelStyle} value="Black or African American" control={<Radio />} label="Black orAfrican American" />
                <FormControlLabel className={parentStyle.labelStyle} value="Native Hawaiian or Other Pacific Islander" control={<Radio />} label="Native Hawaiian or Other Pacific Islander" />
                <FormControlLabel className={parentStyle.labelStyle} value="White" control={<Radio />} label="White" />
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset" className={classes.formControl} >
              <FormLabel component="legend" className={parentStyle.formLabel}>Sex {radioErrorHandler("borrowerSex")}</FormLabel>
              <RadioGroup name='borrowerSex' value={borrowerSex} onChange={handleChange}>
                <FormControlLabel className={parentStyle.labelStyle} value="Female" control={<Radio />} label="Female" />
                <FormControlLabel className={parentStyle.labelStyle} value="Male" control={<Radio />} label="Male" />
              </RadioGroup>
            </FormControl>
            </Fragment>}
          </Box>

          <Box className={classes.inputBox}>
            <Typography className={classes.title}>Co Borrower</Typography>
            <FormControl component="fieldset" className={classes.formControl} >
              <FormLabel component="legend" className={parentStyle.formLabel}>I wish to furnish this in formation {radioErrorHandler("coBorrower")}</FormLabel>
              <RadioGroup name='coBorrower' value={coBorrower} onChange={handleChange}>
                <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            {/* <FormControlLabel
              control={<Checkbox checked={checkBoxStatus(coBorrower)} onChange={handleChange} name="coBorrower" color="primary" />}
              label="I do not wish to furnish this in formation" /> */}
            {coBorrower==="Yes" && <Fragment>
            <FormControl component="fieldset" className={classes.formControl} >
              <FormLabel component="legend" className={parentStyle.formLabel}>Ethnicity {radioErrorHandler("coBorrowerEthnicity")}</FormLabel>
              <RadioGroup name='coBorrowerEthnicity' value={coBorrowerEthnicity} onChange={handleChange}>
                <FormControlLabel className={parentStyle.labelStyle} value="Hispanic or Latino" control={<Radio />} label="Hispanic or Latino" />
                <FormControlLabel className={parentStyle.labelStyle} value="Not Hispanic or Latino" control={<Radio />} label="Not Hispanic or Latino" />
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset" className={classes.formControl} >
              <FormLabel component="legend" className={parentStyle.formLabel}>Race {radioErrorHandler("coBorrowerRace")}</FormLabel>
              <RadioGroup name='coBorrowerRace' value={coBorrowerRace} onChange={handleChange}>
                <FormControlLabel className={parentStyle.labelStyle} value="American Indian or Alaska Native" control={<Radio />} label="American Indian or Alaska Native" />
                <FormControlLabel className={parentStyle.labelStyle} value="Asian" control={<Radio />} label="Asian" />
                <FormControlLabel className={parentStyle.labelStyle} value="Black or African American" control={<Radio />} label="Black orAfrican American" />
                <FormControlLabel className={parentStyle.labelStyle} value="Native Hawaiian or Other Pacific Islander" control={<Radio />} label="Native Hawaiian or Other Pacific Islander" />
                <FormControlLabel className={parentStyle.labelStyle} value="White" control={<Radio />} label="White" />
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset" className={classes.formControl} >
              <FormLabel component="legend" className={parentStyle.formLabel}>Sex {radioErrorHandler("coBorrowerSex")}</FormLabel>
              <RadioGroup name='coBorrowerSex' value={coBorrowerSex} onChange={handleChange}>
                <FormControlLabel className={parentStyle.labelStyle} value="Female" control={<Radio />} label="Female" />
                <FormControlLabel className={parentStyle.labelStyle} value="Male" control={<Radio />} label="Male" />
              </RadioGroup>
            </FormControl>
            </Fragment>}
          </Box>
        </form>
      </Box>
      <Box height={"10%"}>
        <NextPrevButton
          handleNextCallback={handleSave}
          handlePrevCallBack={props.previous}
          activeStepIndex={props.activeSection}></NextPrevButton>
      </Box>
    </Box>
  )
}