import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: '100vh',
    backgroundColor: '#FFF'
  },

  imageStyle: {
    width: 350,
    height: 250,
  },

  goBackBtn: {
    display: 'block',
    fontFamily: "Montserrat",
    marginBottom: 20,
    fontSize: 16,
    fontWeight: '600',
  },

  loaderContainer: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

}));