import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { getHippoQuote } from '../../actions';
import Cookies from 'js-cookie';
import { MessageManager } from '../../components'
import { getAppSource, getUserType } from '../../helpers'

export const HippoPopup = (props) => {
  const mytheme = useSelector(state => state.theme);
  const { secondaryColorCode } = mytheme;
  const classes = useStyles();

  const { open, handleClose } = props;

  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [isProcess, setIsProcess] = useState(false)
  const [hippoData, setHippoData] = useState('')

  useEffect(() => {
    if (props.accountNumber) {
      getHippoData()
    }

  }, [props.accountNumber])

  const getHippoData = async () => {
    const requestData = {
      "accountNumber": props.accountNumber ? props.accountNumber : '',
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
    }

    setIsProcess(true)
    setErrorMessage('')
    setSuccessMessage('')
    const result = await getHippoQuote(requestData);
    setIsProcess(false)
    if (result) {
      const { lstResponseStatus } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        setHippoData(result.hippoDetail)
        setSuccessMessage(lstResponseStatus[0].statusDesc)
      } else {
        setErrorMessage(lstResponseStatus[0].statusDesc)
      }
    } else {
      setErrorMessage("Something went wrong")
    }


  }
  const htmlTotext = (str) => {
    return { __html: str };
  }

  const errorHandler = () => {
    if (errorMessage !== '') {
      return (<Box className={classes.summaryText} pt={3}>
        <Typography>We experienced an issue processing your quote. Please try again after a few minutes.</Typography>
      </Box>)
    } else if (isProcess) {
      return (<Box align="center" py={10}><CircularProgress /></Box>)
    }
  }

  return (
    <Dialog
      fullWidth={false}
      maxWidth={'xl'}
      PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={handleClose} open={open}>
      <Box className={classes.root}>
        <Box py={2} borderBottom={1}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
              <Typography className={classes.heading}>Hippo Home Insurance</Typography>
            </Grid>
            <Box className={classes.closeIcon}>
              <Typography >
                <span onClick={() => handleClose()} className={classes.closeBtn}>X</span>
              </Typography>
            </Box>
          </Grid>
        </Box>
        {/* {errorMessage?(<MessageManager message={errorMessage} type={"error"}></MessageManager>):null}
        {successMessage?(<MessageManager message={successMessage} type={"success"}></MessageManager>):null} */}

        {/* {errorMessage?(<Box className={classes.summaryText} pt={3}>
              <Typography>We experienced an issue processing your quote. Please try again after a few minutes.</Typography>
            </Box>):null} */}

        {hippoData && hippoData.messsageToborrower ? (
          <Box>
            <Box className={classes.summaryText} pt={3}>
              <div dangerouslySetInnerHTML={htmlTotext(hippoData.messsageToborrower)} />
            </Box>
            {/* <Box py={5}>
              <Typography className={classes.labelStyle}>Insurance Amount : <b>{hippoData.insuranceAmount}</b></Typography>
              <Typography className={classes.labelStyle}>Quote Premium: <b>{hippoData.quotePremium}</b></Typography>
            </Box> */}
            <Box align="center">
              <a className={classes.btn}
                style={{ background: secondaryColorCode }}
                target="_blank" href={hippoData.hippoQuoteUrl}>
                {(hippoData.hippoBtnText !== '' && hippoData.hippoBtnText !== null) ? hippoData.hippoBtnText : 'SEE MY QUOTE'}
              </a>
            </Box>
          </Box>) : errorHandler()}
      </Box>
    </Dialog>
  )
}