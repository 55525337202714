import React, { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useStyles } from './styles';
import { getPrivacySecurityContent } from '../../actions'
import { scrollTop } from '../../helpers';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';

export const NycRules = React.memo(() => {
  const classes = useStyles();
  const [data, setData] = useState('');
  const [isLogin, setLoginStatus] = useState(Cookies.get('privateToken') ? true : false);

  const dispatch = useDispatch();
  const nycRulesData = useSelector(state => state?.loanInfoReducer?.nycRules);
  const selectedAccountData = useSelector(state => state.selectedAccountData);
  
  useEffect(() => {
    if (selectedAccountData && nycRulesData && isLogin) {
      scrollTop()
      getData();
    }
  }, [selectedAccountData, nycRulesData]);

  useEffect(() => {
    if (!isLogin && !selectedAccountData && !nycRulesData) {
      scrollTop()
      getData();
    }
  }, [])

  const getData = async () => {
    // NYCRules- No change 6 Year +Zip
    // NYCRulesState- 6 Year +Zip+ Property State is 'NY'
    // NYCRulesStateOnly- If Property State is 'NY'

    const res = await dispatch(getPrivacySecurityContent('PC033'));
    if (res && res.lstPageContentDetails) {
    if (isLogin) {
      if ((selectedAccountData?.propertyState.toUpperCase() == "NY") && nycRulesData?.is6year && nycRulesData?.isNYCRules) {
        setData(res.lstPageContentDetails.filter((key) => key.contentKey == 'NYCRulesState')[0].contentValue);
      } else if ((selectedAccountData?.propertyState.toUpperCase() !== "NY") && nycRulesData?.is6year && nycRulesData?.isNYCRules) {
        setData(res.lstPageContentDetails.filter((key) => key.contentKey == 'NYCRules')[0].contentValue);
      } else if ((selectedAccountData?.propertyState.toUpperCase() == "NY") && nycRulesData?.is6year) {
        setData(res.lstPageContentDetails.filter((key) => key.contentKey == 'NYCRulesStateOnly')[0]?.contentValue);
      }
      } else {
        setData(res.lstPageContentDetails.filter((key) => key.contentKey == 'NYCRules')[0].contentValue);
      }
    } else {
      setData('');
    }
    scrollTop();
  }

  if (data) {
    return (
      <>
      <Box className={classes.rootBg}>
        <Box className={classes.root}>
          <div dangerouslySetInnerHTML={{ __html: data }} />
        </Box>
      </Box>
     </>
    )
  } else {
    return (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100vh'
        }}
      >
        <CircularProgress />
      </Box>
    )
  }
});
