import React from 'react';
import { useStyles } from './styles';
import { Box, Button } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import equalHousing from '../../assets/equal-housing.png';
import Cookies from 'js-cookie';
import { insertActivityLog } from '../../actions';
import { getAppSource, getUserType, isAdmin } from '../../helpers';

export const RefinanceCard = (props) => {

  const { bannerBody, bannerImageUrl, bannerTitle,
    buttonColor, buttonText, buttonURL, titleBgColor, bannerSubTitle, bannerName, refiCompany } = props.banner;
  const classes = useStyles();
  const history = useHistory();

  const route = () => {
    sendActivityData();
    history.push(buttonURL + `?refiCompany=${refiCompany}`);
  }

  const sendActivityData = async () => {
    const remark = `User clicked on refinance tile(${refiCompany}) on home page of MLW.`;
    const data = {
      "accountNumber":  Cookies.get("selectedAccount"),
      "activityCode": "UAL202",
      "activityStatus": "Success",
      "action": refiCompany,
      "controller": "Dashboard",
      "apiName": "GetAccountInfo",
      "activityRemark":  remark,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertActivityLog(data);
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.header} style={{ backgroundColor: titleBgColor }}>
        <h1 className={classes.title}>{bannerTitle}</h1>
      </Box>
      <Box className={classes.body} >
        {/* <h1 className={classes.bodyTitle}>Now We Are Partering with </h1> */}
        {/* <img className={classes.logo} src={bannerImageUrl} alt='logo' /> */}
        <Box className={classes.bodyTextContainer}>
          {/* {bannerBody} */}
          {/* <div className={classes.bodyText} dangerouslySetInnerHTML={{ __html: bannerBody }}></div> */}
          <div className={classes.bodyText}>
             {/* <div>
              <div style={{display: 'flex',alignItems: 'baseline'}}> 
              <img src={'https://www.goodmortgage.com/wp-content/uploads/2021/06/Goodmortgage-logo-phone-2.png'} alt="" style={{ marginRight: '10px', width: '245px'}} />
              </div>
              <p style={{textAlign: 'justify', textAlignLast: 'center'}} style={{fontFamily: 'arial', fontWeight: '100', fontSize: '13px', color: '#2d3034', padding: '0px 25px 0px 25px'}}>BSI, your loan servicer, is partnering with the lending team at Goodmortgage to offer you competitive rates on your home refinance or next home purchase. </p>
              <p style={{textAlign: 'center', fontFamily: 'arial', fontSize: '16px', color: '#2d3034', marginTop: '8px', marginRight: '26px'}}>Call <strong>(844) 829-6658</strong> or get started online for a free rate quote!</p>
            </div>  */}

            <div>
              <div style={{textAlign: 'center'}}> 
                <img src={bannerImageUrl} alt="logo" style={{maxWidth: '250px',  marginTop: '12px'}} />
              </div> 
              <div  dangerouslySetInnerHTML={{ __html: bannerBody }}></div> 
              {/* <p style={{textAlign: 'justify', textAlignLast: 'center'}} style={{fontFamily: 'arial', fontWeight: '100', fontSize: '16px', color: '#2d3034', padding: '0px 25px 0px 25px', marginTop: '12px'}}>As your loan servicer we offer competitive refinance rates. </p>
              <p style={{textAlign: 'center', fontFamily: 'arial', fontSize: '16px', color: '#2d3034', marginTop: '8px', marginRight: '26px'}}>Call 877. 335.9048 or click on the button below for an instant rate quote.</p> */}
            </div> 
            
             {/* <div>
             <div style="display: flex;align-items: baseline"> 
              <img src='https://www.goodmortgage.com/wp-content/uploads/2021/06/Goodmortgage-logo-phone-2.png' alt="logo" style="margin-right: 10px; width: 245px; margin-top: 12px" />
              </div>
              <p style="text-align: justify; text-align-last: center" style="font-family: arial; font-weight: 100; font-size: 13px; color: #2d3034; padding: 0px 25px 0px 25px;margin-top: 12px">>As your loan servicer we offer competitive refinance rates. </p>
              <p style="text-align: center; font-family: arial; font-size: 16px; color: #2d3034; margin-top: 8px; margin-right: 26px">>Call 877. 335.9048 or click on the button below for an instant rate quote.</p>
            </div> */}

            {/* <div>
             <div style="display: flex;align-items: baseline"> 
              <img src='https://www.goodmortgage.com/wp-content/uploads/2021/06/Goodmortgage-logo-phone-2.png' alt="good mortgage image" style="margin-right: 10px; width: 245px" />
              </div>
              <p style="text-align: justify; text-align-last: center" style="font-family: arial; font-weight: 100; font-size: 13px; color: #2d3034; padding: 0px 25px 0px 25px">BSI, your loan servicer, is partnering with the lending team at Goodmortgage to offer you competitive rates on your home refinance or next home purchase. </p>
              <p style="text-align: center; font-family: arial; font-size: 16px; color: #2d3034; margin-top: 8px; margin-right: 26px">Call <strong>(844) 829-6658</strong> or get started online for a free rate quote!</p>
            </div> */}
          </div>
        </Box>
      </Box>
      <Box className={classes.footer}>
        {/* { buttonURL.includes('Refinance') ? 
        <Button component={Link} to={buttonURL} className={classes.btn} style={{ backgroundColor: buttonColor }}>{buttonText}</Button> : */}
        { (bannerName?.toUpperCase() === 'REFINANCE') ?
        <Button onClick={() => route()} className={classes.btn} style={{ backgroundColor: buttonColor }}>{buttonText}</Button> 
        :
        <Button component={Link} to={buttonURL} className={classes.btn} style={{ backgroundColor: buttonColor }}>{buttonText}</Button> }
         {/* } */}
        <Box>
        <div style={{marginTop: '4px'}}>
         <img src={equalHousing} alt="" style={{ marginRight: '10px', marginTop: '9px', width: '30px'}} />
         <p style={{marginLeft: '38px', marginTop: '-28px', fontSize: '9px'}}>{bannerSubTitle?.split('#')[0]}</p>
         <p style={{marginLeft: '38px', marginTop: '-2px',  fontSize: '9px'}}>{bannerSubTitle?.split('#')[1]}</p>
         </div>
        </Box>
      </Box>
    </Box>
  )
}
