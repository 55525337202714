import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: 12,
    marginBottom: 28,
    backgroundColor: "#FFFFFF",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
  },


  title: {
    color: "rgba(0,0,0,0.6)",
    fontFamily: "Montserrat",
    fontSize: '1.8em',
    fontWeight: "500",
    textAlign: 'center',
  },

  header: {
    padding: '1.6em 2em',
    backgroundColor: '#FFF',
    borderRadius: "12px 12px 0 0",
    borderBottom: 'solid 1px #3064A6',
    display: 'flex',
    alignItems: 'center',
  },

  body: {
    maxWidth: 320,
    marginTop: 25,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 30,
  },

  bodyText: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    marginBottom: 22,
    maxWidth: 320,
    wordBreak: "break-word",
  },

  bodyLink: {
    color: "#ddd",
    fontSize: 14,
    fontFamily: "Montserrat",
    fontWeight: "600",
  },

  activeBodyLink: {
    color: "#535D6D",
    fontSize: 14,
    fontFamily: "Montserrat",
    fontWeight: "600",
  },


  bodyLinkLabel: {
    color: "#535D6D !important",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: "600 !important",
    textDecoration: 'underline',
    cursor: "pointer",
  },

  activeBodyLinkLabel: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: "600 !important",
    textDecoration: 'none',
    cursor: "pointer",
    textDecoration: 'underline',
  },

  linkStyle: {
    color: "#535D6D !important",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: "600 !important",
    textDecoration: 'none',
  },

  unlockIcon: {
    width: 20,
    height: 12,
    marginRight: 15,
  },

  stepperRoot: {
    padding: 0,
  }



}));


export const RedTooltip = withStyles((theme) => ({
  arrow: {
    color: "#B91717",
  },
  tooltip: {
    backgroundColor: '#B91717',
    color: '#fff',
    fontFamily: "Montserrat",
    boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
    padding: "0.5em 1.6em",
    fontSize: 16,
    maxWidth: 600,
  },
}))(Tooltip);