import React, { useEffect, useState } from 'react';
import {
  Box, Grid, IconButton, InputAdornment, CircularProgress, Typography,
  InputLabel, FormControl, Button
} from "@material-ui/core";
import { useHistory, useLocation } from 'react-router-dom';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useStyles, CutomOutlinedInput, CustomInputLabel } from './styles';
import Cookies from 'js-cookie';
import { scrollTop, renderDefaultMsg, getAppSource, getUserType } from '../../../../helpers';
import { encrypt } from './../../../../helpers/encrypt';
import { adminChangePassword, adminLogOutInfo } from './../../../../actions';
import { signOutAdmin } from './../../../../helpers/adminHelpers';

export const Adminchangepassword = (props) => {

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [isProcessPassword, setIsProcessPassword] = useState(false)
  const [isOldPassword, setIsOldPassword] = useState(false)
  const [inputOldPassword, setInputOldPassword] = useState('')
  const [showOldPassword, setShowOldPassword] = useState(false)
  const [inputNewPassword, setInputNewPassword] = useState('')
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [inputConfirmPassword, setInputConfirmPassword] = useState('')
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [isNewPasswordBox, setIsNewPasswordBox] = useState(false)
  const [validNewPassword, setValidNewPassword] = useState({
    isLengthPass: false,
    isLowercasePass: false,
    isUppercasePass: false,
    isNumberPass: false,
    isSpecialCharacterPass: false
  })
  const [isConfirmPasswordBox, setIsConfirmPasswordBox] = useState(false)
  const [isPasswordMatched, setIsPasswordMatched] = useState(false)

  useEffect(() => {
    scrollTop()
  }, []);

  const handleChange = (prop) => (event) => {
    setErrorMessage('')
    setSuccessMessage('')
    if (prop === 'inputOldPassword') {
      setInputOldPassword(event.target.value)
    } else if (prop === 'inputNewPassword') {
      let matchStatus = inputConfirmPassword === event.target.value ? true : false
      passwordFormate(event.target.value)
      setInputNewPassword(event.target.value)
      setIsConfirmPasswordBox(!matchStatus)
      setIsPasswordMatched(matchStatus)
    } else if (prop === 'inputConfirmPassword') {
      let matchStatus = inputNewPassword === event.target.value ? true : false
      setInputConfirmPassword(event.target.value)
      setIsConfirmPasswordBox(!matchStatus)
      setIsPasswordMatched(matchStatus)
    }
  }

  const passwordFormate = (str) => {
    let isLowercasePass = false
    let isUppercasePass = false
    let isNumberPass = false
    let isSpecialCharacterPass = false
    let isLengthPass = false
    let strengthSizePass = 0

    if (/\d/.test(str)) {
      isNumberPass = true
      strengthSizePass++
    }

    if (/[A-Z]/.test(str)) {
      isUppercasePass = true
      strengthSizePass++
    }
    if (/[a-z]/.test(str)) {
      isLowercasePass = true
      strengthSizePass++
    }
    if (str.length >= 8 && str.length <= 20) {
      isLengthPass = true
      strengthSizePass++
    }

    if (/[~`@!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(str)) {
      isSpecialCharacterPass = true
      strengthSizePass++
    }

    let isNewPasswordBox = strengthSizePass === 5 ? false : true
    setValidNewPassword({
      isLengthPass,
      isLowercasePass,
      isUppercasePass,
      isNumberPass,
      isSpecialCharacterPass
    })
    setIsNewPasswordBox(isNewPasswordBox)
  }

  const validClass = (isValid) => {
    if (isValid) {
      return `${classes.validationText} ${classes.validationSuccess}`
    } else {
      return `${classes.validationText} ${classes.validationError}`
    }
  }

  const handleSubmit = async () => {
    if (inputOldPassword !== '' && isNewPasswordBox === false && isPasswordMatched) {
      const requestData = {
        "userName": "",
        "oldPassword": encrypt(inputOldPassword),
        "newPassword": encrypt(inputNewPassword),
        "privateLabelUrl": Cookies.get("privateLabelUrl"),
        "ipAddress": Cookies.get('publicIp'),
        "browser": Cookies.get("browser"),
        "appSource": getAppSource(),
        "userType": getUserType()
      }
      setIsProcessPassword(true)
      setErrorMessage('')
      setSuccessMessage('')
      const result = await adminChangePassword(requestData);
      if (result) {
        const { lstResponseStatus } = result
        if (lstResponseStatus[0].statusCode === "0") {
          setSuccessMessage(lstResponseStatus[0].statusDesc)
          setIsProcessPassword(false)
          setTimeout(() => {
            handleSignOut()
          }, 1000);
        } else {
          setIsProcessPassword(false)
          setErrorMessage("Entered old password is incorrect")
        }
      } else {
        setIsProcessPassword(false)
        setErrorMessage(renderDefaultMsg('400'))
      }
    }
  }

  const disabledBtn = inputOldPassword !== '' && isNewPasswordBox === false && isPasswordMatched

  const handleSignOut = () => {
    adminLogOutInfo()
    signOutAdmin(() => history.push('/'))
  }

  return (
    <Box className={classes.bgg}>
      <Box className={classes.fullpart}>
        <Box className={classes.root}>
          <Typography className={classes.title}>Change Password</Typography>
          <Box className={classes.mainContainer}>
            <Box className={classes.formContainer}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box textAlign="center">
                        {successMessage ? (<Box><Typography className={classes.succesMessage}>{successMessage}</Typography></Box>) : null}
                        {errorMessage ? (<Box><Typography className={classes.errorMessage}>{errorMessage}</Typography></Box>) : null}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box className={classes.formContainer_updated}>
                        <form noValidate autoComplete="off">
                          <Box className={classes.inputContainer}>
                            <FormControl variant="outlined" >
                              <CustomInputLabel className={classes.labelStyle} htmlFor="outlined-old-password">Old Password</CustomInputLabel>
                              <CutomOutlinedInput
                                id="outlined-old-password"
                                type={showOldPassword ? 'text' : 'password'}
                                value={inputOldPassword}
                                onChange={handleChange('inputOldPassword')}                                
                                inputProps={{
                                  className: classes.inputStyle,
                                  maxLength: 20,
                                }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => setShowOldPassword(!showOldPassword)}
                                      edge="end"
                                    >
                                      {showOldPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                            {isOldPassword ? (
                              <Box border={1} mb={2} p={4} className={classes.validationBox}>
                                <Box pb={2}>
                                  <Typography className={classes.validationTitle}>Old Password Requirements</Typography>
                                </Box>
                                <Typography className={validClass(!isOldPassword)}>Old Password Required</Typography>
                              </Box>) : null}
                          </Box>

                          {/* New Password */}
                          <Box className={classes.inputContainer}>
                            <FormControl variant="outlined" >
                              <CustomInputLabel className={classes.labelStyle} htmlFor="outlined-new-password">New Password</CustomInputLabel>
                              <CutomOutlinedInput
                                id="outlined-new-password"
                                type={showNewPassword ? 'text' : 'password'}
                                value={inputNewPassword}
                                onChange={handleChange('inputNewPassword')}                                
                                inputProps={{
                                  className: classes.inputStyle,
                                  maxLength: 20
                                }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => setShowNewPassword(!showNewPassword)}
                                      edge="end"
                                    >
                                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                            {isNewPasswordBox ? (
                              <Box border={1} mb={2} p={4} className={classes.validationBox}>
                                <Box pb={2}>
                                  <Typography className={classes.validationTitle}>Password Requirements</Typography>
                                </Box>
                                <Typography className={validClass(validNewPassword.isLengthPass)}>Password must be between 8 and 20 characters long</Typography>
                                <Typography className={validClass(validNewPassword.isLowercasePass)}>Must contain atleast 1 lower case letter [a-z]</Typography>
                                <Typography className={validClass(validNewPassword.isUppercasePass)}>Must also contain 1 upper case letter [A-Z]</Typography>
                                <Typography className={validClass(validNewPassword.isNumberPass)}>Must also contain 1 digit [0-9]</Typography>
                                <Typography className={validClass(validNewPassword.isSpecialCharacterPass)}>Must also contain 1 special character (#, %, $, !, &, @)</Typography>
                              </Box>) : null}
                          </Box>

                          {/* Confirm Password */}
                          <Box className={classes.inputContainer}>
                            <FormControl variant="outlined" >
                              <CustomInputLabel className={classes.labelStyle} htmlFor="outlined-new-password">Confirm Password</CustomInputLabel>
                              <CutomOutlinedInput
                                id="outlined-confirm-password"
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={inputConfirmPassword}
                                onChange={handleChange('inputConfirmPassword')}                                
                                inputProps={{
                                  className: classes.inputStyle,
                                  maxLength: 20
                                }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                      edge="end"
                                    >
                                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                            {isConfirmPasswordBox ? (
                              <Box border={1} mb={2} p={4} className={classes.validationBox}>
                                <Box pb={2}>
                                  <Typography className={classes.validationTitle}>Confirm Password Requirements</Typography>
                                </Box>
                                <Typography className={validClass(isPasswordMatched)}>Please confirm that the password in both fields match.</Typography>
                              </Box>) : null}
                          </Box>
                          <Box textAlign="center">
                            {isProcessPassword ? (<Button style={{ width: "90px" }} className={classes.btnStyle} disabled={true} variant="contained"><CircularProgress /></Button>) : (
                              <Button
                                onClick={() => handleSubmit()}
                                className={classes.btnStyle}
                                variant="contained"
                                color="primary"
                                disabled={!disabledBtn}>
                                SUBMIT</Button>)}
                          </Box>
                        </form>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>

  )
}