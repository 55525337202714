import React, { useEffect, useState } from 'react';
import {
  Box, Grid, CircularProgress, TableContainer, Paper, Table, TableBody, TableHead, TableCell,
  TableRow, TableFooter, TablePagination, Button, MenuItem, FormControl, Select, TextField, Checkbox
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { useStyles } from './styles';
import { Editinvestor } from './editinvestor';
import { Mapinvestor } from './mapinvestor';
import { DeleteInvestorComponent } from './deleteinvestorcode';
import { scrollTop, renderDefaultMsg, getAppSource, getUserType } from '../../../../helpers';
import { Typography } from '@material-ui/core';
import { encrypt, decrypt } from './../../../../helpers/encrypt';
import { getinvestorcodeLabel, getSuperAdminPrivatelabel } from './../../../../actions';
import { SnackBar } from '../../../../components';
import Cookies from 'js-cookie';

export const Admininvestorcode = (props) => {

  const classes = useStyles();
  const [dataError, setDataError] = useState('');
  const [investorCodeList, setInvestorCodeList] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);
  const [editinvestor, seteditinvestor] = useState(false);
  const [mapinvestor, setmapinvestor] = useState(false);
  const [labelvalue, setlabelvalue] = React.useState('');
  const [privatelabels, setprivatelabels] = useState([]);
  const [investorcodeerror, setinvestorcodeerror] = useState(false);
  const [delteinvestorcode, setdelteinvestorcode] = useState(false);
  const [tableDataError, setTableDataError] = useState('');
  const [loader, setLoader] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [hasError, setHasError] = useState(false);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [state, setState] = useState({
    investorCode: '',
  });

  const [investorValues, setinvestorValues] = useState({
    investorCode: '',
    labelName: '',
    id: '',
    privateLabelCode: '',
    isVA: false
  });

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false)
  }

  const handleSnackBarHandler = (msg) => {
    setHasError(false);
    setSnackBarMsg(msg);
    setOpenSnackBar(true);
  }

  const getSuperAdminPrivatelabeldata = async (e) => {
    setState({ investorCode: '' })
    setlabelvalue('');
    setDataLoader(true);
    const response = await getSuperAdminPrivatelabel();
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode == '0') {
      setDataError('');
      setDataLoader(false);
      setprivatelabels(response.privateLabelList);
    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
      setDataLoader(false);
      setDataError(response.lstResponseStatus[0].statusDesc);
    } else {
      setDataLoader(false);
      setDataError(renderDefaultMsg('400'));
    }
  }

  const getSelectedLabelData = async () => {
    setInvestorCodeList([]);
    setTableDataError('');
    setLoader(true);
    const data = {
      "investorCode": state.investorCode ? encrypt(state.investorCode) : '',
      "privateLabelCode": labelvalue ? labelvalue : '',
      "pageIndex": page,
      "pageSize": rowsPerPage,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
    }
    const response = await getinvestorcodeLabel(data);
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
      setDataError('');
      setLoader(false);
      setInvestorCodeList(response.lstInvestorCode);
      setState({ investorCode: '' });
      setTotalRecords(response.totalRecords)
    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
      setLoader(false);
      setTableDataError(response.lstResponseStatus[0].statusDesc);
    } else {
      setLoader(false);
      setTableDataError(renderDefaultMsg('400'));
    }
  }

  const handleChange = async (event) => {
    setlabelvalue(event.target.value);
  };

  const clickBack = () => {
    seteditinvestor(false)
    setmapinvestor(false)
  }

  const closeDialog = () => {
    setInvestorCodeList([]);
    setState({ investorCode: '' })
    setlabelvalue('');
    setdelteinvestorcode(false);
    getSelectedLabelData();
  }

  const mapInvestorcode = () => {
    setmapinvestor(true)
    seteditinvestor(true)
  }

  const handleinput = (prop) => (event) => {
    if ((/^[0-9]+$/).test(event.target.value) || event.target.value === '') {
      setState({ ...state, [prop]: event.target.value.slice(0, 10) });
    }
  };

  const getvalidateData = async () => {
    setlabelvalue('');
    setPage(0)
    getSelectedLabelData();
  }

  const deleteInvestorcode = async (id, code, labelname, privateLabelCode) => {
    await setinvestorValues({ ...investorValues, investorCode: decrypt(code), labelName: labelname, id: id, privateLabelCode: privateLabelCode })
    setdelteinvestorcode(true)
  }

  const editInvestorcode = async (id, code, labelname, privateLabelCode, isVA) => {
    await setinvestorValues({ ...investorValues, investorCode: decrypt(code), labelName: labelname, id: id, privateLabelCode: privateLabelCode, isVA: isVA })
    seteditinvestor(true)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    getSelectedLabelData()
  }, [page, rowsPerPage, labelvalue])


  useEffect(() => {
    getSuperAdminPrivatelabeldata()
    scrollTop()
  }, []);


  if (dataLoader) {
    return (
      <Box className={classes.loaderRoot}>
        <CircularProgress />
      </Box>
    )
  }
  if (dataError !== '') {
    return (
      <Box className={classes.dataErrorMsg}>
        {dataError}
      </Box>
    )
  }

  return (
    <Box className={classes.bgg} >
      <Box className={classes.fullpart}>
        <Box className={classes.root}>

          {!editinvestor && <h1 className={classes.title}>Investor Code Mapping</h1>}
          {editinvestor && !mapinvestor && <h1 className={classes.title}>Edit Mapped Investor Code</h1>}
          {editinvestor && mapinvestor && <h1 className={classes.title}>Mapped Investor Code</h1>}

          {!editinvestor && <div className={classes.firstarea}>
            <Box className={classes.mainContainer}>
              <Box my={1} mx={2} className={classes.formContainer}>
                <Grid container>
                  <Grid item md={4} sm={4}>
                    <div className={classes.navinnerArea}>
                      <div className={classes.part_1}>
                        <Typography className={classes.namee}>
                          Company Name
                        </Typography>
                      </div>
                      <div className={classes.part_2}>
                        <FormControl variant="outlined" className={classes.formControl}>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={labelvalue}
                            onChange={handleChange}
                            displayEmpty
                            inputProps={{
                              className: classes.inputStyleBox,
                              'aria-label': 'Without label',
                              form: {
                                autoComplete: 'off',
                              },
                            }}
                            InputLabelProps={{
                              classes: { root: classes.textField }
                            }}
                          >
                            <MenuItem value=''>
                              ALL
                            </MenuItem>
                            {privatelabels.map(item => (
                              <MenuItem key={item.privateLabelCode} value={item.privateLabelCode}>
                                {item.privateLabelName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </Grid>
                  <Grid item md={5} sm={5}>
                    <div className={classes.navinnerArea}>
                      <div className={classes.inner_part_1}>
                        <div style={{ width: '100%' }}>
                          <div className={classes.part_1_chn}>
                            <Typography className={classes.namee}>
                              Investor Code
                            </Typography>
                          </div>
                        </div>
                      </div>
                      <div className={classes.inner_part_2}>
                        <div>
                          <TextField
                            color="primary"
                            autoComplete="off"
                            id="outlined-loanNumber"
                            variant="outlined"
                            type="text"
                            value={state.investorCode}
                            onChange={handleinput('investorCode')}
                            inputProps={{
                              className: classes.inputStyle_1,
                              maxLength: 10,
                              form: {
                                autoComplete: 'off',
                              },
                            }}
                            InputLabelProps={{
                              className: classes.inputStyle_2,
                              classes: { root: classes.textField }
                            }}

                          />
                        </div>
                        <div>
                        </div>
                      </div>
                      <div className={classes.inner_part_1}>
                        <div style={{ width: '100%' }}>
                          <div className={classes.part_1} style={{ paddingTop: '7px' }}>
                            <Button onClick={getvalidateData} type='submit' className={classes.searchbtn} size="large" variant="contained" color="primary">
                              Search
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>

                  <Grid item md={3} sm={3} style={{ textAlign: 'center', paddingTop: '9px' }}>
                    <div className={classes.buttoncheckarea}>
                      <div className={classes.buttoncheck_2}>
                        <Button onClick={mapInvestorcode} type='submit' className={classes.searchbtn} size="large" variant="contained" color="primary">
                          Map Investor Code
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </div>}

          {!editinvestor && <div className={classes.firstarea}>
            <Box className={classes.mainContainer}>
              <Box my={1} mx={2} className={classes.formContainer} style={{ paddingBottom: '25px' }}>
                <TableContainer component={Paper}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">Company Name</StyledTableCell>
                        <StyledTableCell align="center">Investor Code</StyledTableCell>
                        <StyledTableCell align="center">Restricted Client?</StyledTableCell>
                        <StyledTableCell align="center">Edit</StyledTableCell>
                        <StyledTableCell align="center">Delete</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(!loader && investorCodeList.length) ? investorCodeList.map((row, i) => (<StyledTableRow key={i}>
                        <StyledTableCell align="left" >{row.privateLabelName} </StyledTableCell>
                        <StyledTableCell align="center" >{decrypt(row.investorCode)} </StyledTableCell>
                        <StyledTableCell align="center" ><Checkbox disabled checked={row.isVA} /> </StyledTableCell>
                        <StyledTableCell align="center" >
                          <Button
                            onClick={() => editInvestorcode(row.investorCodeId, row.investorCode, row.privateLabelName, row.privateLabelCode, row.isVA)}
                            className={classes.color_part}
                            color="primary"
                            className={classes.link}
                          >Edit</Button>
                        </StyledTableCell>
                        <StyledTableCell align="center" >
                          <Button
                            onClick={() => deleteInvestorcode(row.investorCodeId, row.investorCode, row.privateLabelName, row.privateLabelCode)}
                            className={classes.color_part}
                            color="primary"
                            className={`${classes.link} ${classes.deleteBtn}`}
                          >Delete</Button>

                        </StyledTableCell>
                      </StyledTableRow>))
                        : <StyledTableRow>
                          <StyledTableCell colSpan={8}>
                            {loader ? <CircularProgress className={classes.dataLoaderCir} /> : <p className={classes.noDataFoundText}>{tableDataError}</p>}
                          </StyledTableCell>
                        </StyledTableRow>}
                    </TableBody>

                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 50]}
                          count={totalRecords}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                          }}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>

                  </Table>
                </TableContainer>
                {investorcodeerror && <Typography className={classes.dataErrorMsg_1}>{investorcodeerror}</Typography>}
              </Box>
            </Box>
          </div>}
          {editinvestor && <div className={classes.firstarea}>
            <Box className={classes.mainContainer}>
              <Box my={1} mx={2} className={classes.formContainer} style={{ paddingBottom: '25px' }}>

                {editinvestor && mapinvestor && <Mapinvestor clickBack={clickBack} closeDialog={closeDialog} />}

                {editinvestor && !mapinvestor && <Editinvestor
                  clickBack={clickBack}
                  labelName={investorValues.labelName}
                  investorCode={investorValues.investorCode}
                  id={investorValues.id}
                  privateLabelCode={investorValues.privateLabelCode}
                  isVA={investorValues.isVA}
                  closeDialog={closeDialog} />}
              </Box>
            </Box>
          </div>}
          {delteinvestorcode && <div className={classes.firstarea}>
            <DeleteInvestorComponent
              labelName={investorValues.labelName}
              investorCode={investorValues.investorCode}
              id={investorValues.id}
              closeDialog={closeDialog}
              delteinvestorcode={delteinvestorcode}
              handleSnackBarHandler={handleSnackBarHandler}
            />
          </div>}

        </Box>
      </Box >

      {snackBarMsg.length !== 0 && <SnackBar
        severity={hasError ? 'error' : "success"}
        message={snackBarMsg} open={openSnackBar}
        handleClose={handleCloseSnackBar}
      />}

    </Box >
  )
}

const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "6px 16px",
    fontFamily: "Montserrat",
  },
  head: {
    backgroundColor: "#535D6D",//theme.palette.common.black,
    color: "#ffffff",//theme.palette.common.white,
    fontSize: "14px",
    fontWeight: "bold",
    letterSpacing: "0.36px",
    lineHeight: "17px",
    fontFamily: "Montserrat",
    borderRight: "1px solid #ffffff",
    verticalAlign: "top",
    padding: "10px"
  },
  body: {
    fontSize: 14,
    color: "#202020",
    letterSpacing: "0.36px",
    lineHeight: "17px",
    fontFamily: "Montserrat"
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);