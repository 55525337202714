import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
    borderRadius: 12,
    backgroundColor: '#FFFFFF',
    position: 'relative',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    '& .MuiIconButton-root': {
      padding: 4
    },
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxWidth: '40%',
    maxHeight: "100%",
    backgroundColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%'
    }
  },

  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "400",
    textAlign: 'center',
    margin: 16,
  },

  cardBody: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '30px 4em 20px 4em',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },

  statusContainer: {
    marginBottom: 25
  },

  statusBody: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "bold",
  },

  bodyText: {
    color: "#0C0B0B",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    marginBottom: 30,
    marginTop: 0,
  },

  linkStyle: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    fontWeight: "600",
    marginTop: 5,
  },

  btn: {
    width: 188,
    fontFamily: "Montserrat",
    fontSize: '1.5em',
    fontWeight: "600",
  },

  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },
 
  statusTitle: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: 24,
    fontWeight: "700",
    textAlign: 'center'
  },

  statusSubTitle: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "500",
    marginBottom: 40,
    marginTop: 0,
    marginTop: 16
  },
 
  
  accessDenied: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600",
    marginBottom: 40,
    marginTop: 0,
    textAlign: 'center',
    marginTop: 16
  },

  paragraph: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "500",
    marginBottom: 12,
    marginTop: 0,
    textAlign: 'center',
    marginTop: 10
  },

  paymentMenu: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "500",
    marginBottom: 20,
    textAlign: 'center',
    marginTop: 22
  },

  signInBtn: {
    fontFamily: "Montserrat",
    marginBottom: 20,
    fontSize: 16,
    fontWeight: '600',
    width: 200,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      width: "50%"
    },
  },

  buttonContainer: {
    textAlign: 'center'
  },
 
  cardTitleBody: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '2px 2em',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

}));

