import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useStyles } from './styles';
import {
  Box, Grid, Typography, Button, CircularProgress, FormControl, InputLabel,
  MenuItem, Select, TextField
} from '@material-ui/core';
import captchaBG from '../../assets/captchaBG.svg';
import RefreshIcon from '@material-ui/icons/Refresh';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import ErrorIcon from '@material-ui/icons/Error';
import { ThirdPartyPdfFile, ThirdPartyAlert } from '../../components';
import { validateEmail, checkDigitOnly, validateZip, returnCaptchaCode, scrollTop, renderDefaultMsg, getAppSource, getUserType, getLoanLabel, getBorrowerLabel, getMortgageLabel, getPointPrivateLabel } from '../../helpers'
import Cookies from 'js-cookie';
import { encrypt } from '../../helpers/encrypt';
import CustomBreadcrum from '../Breadcrum';
import { getAllStateList, thirdPartyAuthorization, getThirdPartySubOrdinateData } from '../../actions';

export const ThirdPartyRequest = React.memo(() => {
  const mytheme = useSelector(state => state.theme);
  const { primaryColorCode, secondaryColorCode } = mytheme;
  const props = { primaryColorCode: primaryColorCode, secondaryColorCode: secondaryColorCode };
  const classes = useStyles(props);

  const [loading, setLoading] = useState(false);
  const [captchaCode, setCaptchaCode] = useState('');
  const [captchaErr, setcaptchaErr] = useState(false);
  const [volumeIcon, setvolumeIcon] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [errors, setErrors] = React.useState({});
  const [touched, setTouched] = React.useState({});
  const [stateErrorMsg, setStateErrorMsg] = useState('');
  const [stateData, setStateData] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [state, setState] = useState({
    loanNo: '', lastName: '', state: '', zip: '', requester: '', email: '', request: '', identity: '', captcha: '', otherIdentity: '',
  });

  const [invalidBorrower, setInvalidBorrower] = useState(false);
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [popupData, setPopupData] = useState('');
  const [otherItentityBox, setOtherItentityBox] = useState(false);
  const [submittedRequestType, setSubmittedRequestType] = useState('');
  const [thirdPartyEmailID, setThirdPartEmail] = useState('');
  const [warning24HoursMsg, setWarning24HoursMsg] = useState(false);
  const [subOrdinate, setSubOrdinateStatus] = useState(false);

  const captchaTimer = useRef(null);
  useEffect(() => {
    getState();
    return () => clearInterval(captchaTimer.current);
  }, []);

  const handleTimer = () => {
    captchaTimer.current = setInterval(() => {
      getCaptchaCode()
    }, 180000);
    return () => clearInterval(captchaTimer.current);
  }

  const handleReset = () => {
    clearInterval(captchaTimer.current);
  }

  useEffect(() => {
    if (stateData.length !== 0 && !formSubmit) {
      getCaptchaCode()
      handleTimer();
      return () => clearInterval(captchaTimer.current);
    }
  }, [stateData])

  const handleCloseSuccessAlert = () => {
    setOpenSuccessAlert(false);
  }

  const handleOpenSuccessAlert = () => {
    setOpenSuccessAlert(true)
  }

  const getCaptchaCode = async () => {
    await storeCaptchaCode()
  }

  const storeCaptchaCode = () => {
    setcaptchaErr(false)
    const cptchaCode = returnCaptchaCode();
    setCaptchaCode(cptchaCode);
  }

  const check = () => {
    setvolumeIcon(true)
    setTimeout(function () { setvolumeIcon(false) }, 2500);
  }

  const speakk = () => {
    let speech = new SpeechSynthesisUtterance();
    speech.lang = "en-UK";
    speech.text = captchaCode;
    speech.volume = 2;
    speech.rate = 0.25;
    speech.pitch = 1.4;

    window.speechSynthesis.speak(speech);
  }

  const getState = async (e) => {
    const response = await getAllStateList();
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
      setStateErrorMsg('');
      setStateData(response.stateList);
    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '1001') {
      setStateErrorMsg(response.lstResponseStatus[0].statusDesc);
    } else {
      setStateErrorMsg(renderDefaultMsg('400'));
    }
    scrollTop()
  }

  const handleFormTextChange = (prop) => (event) => {
    if (prop === 'loanNo') {
      if (checkDigitOnly(event.target.value)) {
        setState({
          ...state,
          [event.target.name]: event.target.value
        })
      }
      return;
    } else if (prop === 'identity') {
      if (event.target.value === '99') {
        setOtherItentityBox(true);
        setState({
          ...state,
          [event.target.name]: event.target.value,
          ['otherIdentity']: ''
        })
      } else {
        setOtherItentityBox(false);
        setState({
          ...state,
          [event.target.name]: event.target.value
        })
      }
      return;
    } else if (prop === "zip") {
      if (checkDigitOnly(event.target.value)) {
        if (event.target.value.length <= 5) {
          setTouched({
            ...touched,
            [event.target.name]: true
          });
          setState({
            ...state,
            [event.target.name]: event.target.value
          })
        }
      }
      return;
    }

    setTouched({
      ...touched,
      [event.target.name]: true
    });

    setState({
      ...state,
      [event.target.name]: event.target.value
    })
    return;
  }

  const handleBlur = event => {
    const { name, value } = event.target;
    setErrors({
      ...errors,
      ...(errors && { [name]: touched[name] && (name === 'zip' ? validateZip(value) : (name === 'email' && validateEmail(value))) })
    });
  };

  const handleLoanNoBlur = event => {
    const { name } = event.target;
    if (name === 'loanNo' && state.loanNo !== '') {
      checkSubOrdinate();
    }
  }

  const checkErrorObject = () => {
    const errArr = Object.values(errors).filter((item, i) => {
      return item !== null;
    })
    return errArr.length;
  }

  const handleFormStateChange = (event) => {
    setSelectedState(event.target.value);
  }

  const resetForm = () => {
    setOtherItentityBox(false);
    setInvalidBorrower(false);
    setSelectedState('');
    setState({
      loanNo: '', lastName: '', state: '', zip: '', requester: '', email: '', request: '', identity: '', captcha: ''
    });
    getCaptchaCode();
    handleReset();
    handleTimer();
    scrollTop();
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setInvalidBorrower(false);
    setLoading(true);
    setSubmittedRequestType('');
    setErrorMsg('');
    setWarning24HoursMsg(false);
    if (state.request !== "Loss Mitigation Package") {
    if (captchaCode.toLowerCase() === state.captcha.toLowerCase()) {
      setcaptchaErr(false);

      const data = {
        "accountNumber": state.loanNo !== '' ? encrypt(state.loanNo) : '',
        "borrowerLastName": state.lastName,
        "borrowerState": selectedState.stateCode,
        "borrowerZip": state.zip !== '' ? encrypt(state.zip) : '',
        "thirdPartyName": state.requester,
        "thirdPartyEmailID": state.email !== '' ? encrypt(state.email) : '',
        "requestType": state.request,
        "relationWithBorrower": state.identity,
        "otherRelationship": state.identity === '99' ? state.otherIdentity : ' ',
        "expirationDate": "",
        "type": "B",
        "privateLabelUrl": Cookies.get("privateLabelUrl"),
        "ipAddress": Cookies.get('publicIp'),
        "browser": Cookies.get("browser"),
        "appSource": getAppSource(),
        "userType": getUserType(),
        "userName": "",
      };

      const response = await thirdPartyAuthorization(data);
      if (response) {
        setLoading(false);
        if (response.IsError) {
          setErrorMsg(response.ErrorMesg);
          return;
        } else {
          if (response.is24hrSubmittedRecord) {
            setWarning24HoursMsg(response.lstResponseStatus[0].statusDesc);
            scrollTop();
            return;
          } else if (!response.isA360Verified) {
            resetForm();
            setInvalidBorrower(true);
            scrollTop();
            return;
          } else if (response.isA360Verified && !response.isMyLoanVerified && !response.isVerified) {
            Cookies.set("requestType", state.request)
            resetForm();
            setFormSubmit(true);
          } else if (response.isA360Verified && response.isMyLoanVerified && !response.isVerified) {
            resetForm();
            handleOpenSuccessAlert();
            setPopupData('isVerifiedFalse');
            setSubmittedRequestType(state.request);
            setThirdPartEmail(state.email);
          } else if (response.isA360Verified && response.isMyLoanVerified && response.isVerified) {
            resetForm();
            handleOpenSuccessAlert();
            setPopupData('isVerifiedTrue');
            setSubmittedRequestType(state.request);
            setThirdPartEmail(state.email);
          } else {
            setErrorMsg(renderDefaultMsg('400'));
            setSubmittedRequestType('');
            setThirdPartEmail('')
            setState({ ...state, captcha: '' });
            getCaptchaCode();
            handleReset();
            handleTimer();
          }
        }
      } else {
        setLoading(false);
        setErrorMsg(renderDefaultMsg('400'));
        setState({ ...state, captcha: '' });
        getCaptchaCode();
        handleReset();
        handleTimer();
      }
    } else {
      setLoading(false);
      setState({ ...state, captcha: '' });
      getCaptchaCode();
      handleReset();
      handleTimer();
      setcaptchaErr(true);
    }
    } else {
      setLoading(false);
      setState({ ...state, captcha: '' });
      getCaptchaCode();
      handleReset();
      resetForm()
      handleTimer();
      window.open("https://www.bsifinancial.com/pdfs/Loss_Mit_Form_071624.pdf", '_blank')
    }
  }
  
  const checkSubOrdinate = async () => {
    setLoading(true);
    const data = {
      "accountNumber": state.loanNo !== '' ? encrypt(state.loanNo) : '',
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };
    const response = await getThirdPartySubOrdinateData(data);
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
      setStateErrorMsg('');
      setSubOrdinateStatus(response.isSubordinate);
      setLoading(false);
    } else {
      setErrorMsg(renderDefaultMsg('400'))
      window.scrollTo({ top: 500, left: 0, behavior: 'smooth' });
      // setStateErrorMsg(response.lstResponseStatus[0].statusDesc);
      setLoading(false);
    }
  }

  const disableBtn = state.loanNo.length === 0 || state.lastName.length === 0 ||
    selectedState === '' || state.zip.length === 0 || state.requester.length === 0 ||
    state.email.length === 0 || state.request.length === 0
    || state.identity.length === 0 || (state.identity === '99' && state.otherIdentity.length === 0)
    || state.captcha.length === 0;

  if (!stateData.length) {
    return (
      <Box className='loaderRoot'>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box className={classes.mainRoot}>
      <Box className={classes.root} >
        <CustomBreadcrum backPage="Home" backPageLink="/" activePage="Third-Party Requester" />
        <Box className={classes.topContainer} mx={0}>
          <Typography className={classes.pageHeading} color="textPrimary" component='h4'>Third-Party Requester</Typography>
        </Box>

        {formSubmit && <Box className={classes.infoBox}>
          <Box className={classes.flexBox}>
            <ErrorIcon className={classes.errorIconStyle} />
            <h3 className={classes.subTitle} className={classes.seondaryColor} >
              We are sorry to inform you that unfortunately your request can’t be processed online.
              You can see the alternate options given below to send the request to the  {getBorrowerLabel()?.toLowerCase()} .
            </h3>
          </Box>
        </Box>}
        {invalidBorrower && <Box className={classes.infoBox} style={{ width: '100%' }}>
          <Box className={classes.flexBox}>
            <ErrorIcon className={classes.errorIconStyle} />
            <h3 className={classes.subTitle} className={classes.seondaryColor} >
              Invalid {getBorrowerLabel()} Details. Please correct the information you have entered and retry.
            </h3>
          </Box>
        </Box>}

        {warning24HoursMsg && <Box className={classes.infoBox} style={{ width: '100%' }}>
          <Box className={classes.flexBox}>
            <ErrorIcon className={classes.errorIconStyle} />
            <h3 className={classes.subTitle} className={classes.seondaryColor} >
              {warning24HoursMsg}
            </h3>
          </Box>
        </Box>}

        <Box className={classes.mainContainer}>
          <Box my={1} mx={2} className={classes.formContainer}>
            {!formSubmit ?
              <form className={classes.formRoot} noValidate autoComplete="off" onSubmit={handleFormSubmit}>
                <p className={`${classes.bobyText} ${classes.topFormText}`}>
                  Please enter all of the information below. If the information is successfully matched,
                  we will provide you with status information through email.
              </p>
                <h3 className={classes.subTitle}>{getBorrowerLabel()} Information</h3>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6} md={6}>
                    <div>
                      <TextField
                        id="outlined-loan-input"
                        label={`${getLoanLabel()} Number`}
                        color="primary"
                        type="text"
                        value={state.loanNo}
                        name="loanNo"
                        variant="outlined"
                        fullWidth
                        onChange={handleFormTextChange('loanNo')}
                        onBlur={handleLoanNoBlur}
                        InputLabelProps={{
                          classes: { root: classes.textField }
                        }}
                        InputProps={{
                          classes: { root: classes.textField, input: classes.textInput },
                          maxLength: 10,
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div>
                      <TextField
                        id="outlined-last-input"
                        label={`${getBorrowerLabel()} Last Name`}
                        color="primary"
                        type="text"
                        value={state.lastName}
                        name="lastName"
                        variant="outlined"
                        fullWidth
                        onChange={handleFormTextChange('lastName')}
                        InputLabelProps={{
                          classes: { root: classes.textField }
                        }}
                        InputProps={{
                          classes: { root: classes.textField, input: classes.textInput },
                          maxLength: 100,
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div>
                      <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                        <InputLabel id="borrower-type-select-outlined">Select {getBorrowerLabel()} State</InputLabel>
                        {stateErrorMsg === '' && <Select
                          labelId="borrower-state-outlined-label"
                          id="borrower-state-select-outlined"
                          name="state"
                          color="primary"
                          label={`Select ${getBorrowerLabel()} State`}
                          className={classes.inputStyle}
                          value={selectedState}
                          onChange={handleFormStateChange}
                          fullWidth >
                          {stateData.length !== 0 && stateData.map((item, index) => {
                            return (
                              <MenuItem value={item} key={(index - 1) + item.stateCode}>{item.stateName}</MenuItem>
                            )
                          })}
                        </Select>}
                      </FormControl>
                      {stateErrorMsg !== '' && <p className={classes.errorMsg}>{stateErrorMsg}</p>}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div>
                      <TextField
                        id="outlined-zip-input"
                        label={`${getBorrowerLabel()} Zip Code`}
                        color="primary"
                        name="zip"
                        type="text"
                        value={state.zip}
                        variant="outlined"
                        fullWidth
                        onChange={handleFormTextChange('zip')}
                        onBlur={handleBlur}
                        InputLabelProps={{
                          classes: { root: classes.textField }
                        }}
                        InputProps={{
                          classes: { root: classes.textField, input: classes.textInput },
                          maxLength: 5
                        }}
                      />
                      {touched.zip && <p className={classes.validationErr}>{errors.zip}</p>}
                    </div>
                  </Grid>
                </Grid>

                <h3 className={classes.subTitle}>Requester Information</h3>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6} md={6}>
                    <div>
                      <TextField
                        id="outlined-requester-input"
                        label="Third-Party Requester/Company"
                        color="primary"
                        type="text"
                        value={state.requester}
                        name="requester"
                        variant="outlined"
                        fullWidth
                        onChange={handleFormTextChange('requester')}
                        InputLabelProps={{
                          classes: { root: classes.textField }
                        }}
                        InputProps={{
                          classes: { root: classes.textField, input: classes.textInput }
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div>
                      <TextField
                        id="outlined-email-input"
                        label="Third-Party Email Address"
                        color="primary"
                        type="text"
                        name="email"
                        value={state.email}
                        variant="outlined"
                        fullWidth
                        onChange={handleFormTextChange('email')}
                        onBlur={handleBlur}
                        InputLabelProps={{
                          classes: { root: classes.textField }
                        }}
                        InputProps={{
                          classes: { root: classes.textField, input: classes.textInput },
                          maxLength: 100,
                        }}
                      />
                      {touched.email && <p className={classes.validationErr}>{errors.email}</p>}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div>
                      <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                        <InputLabel id="borrower-type-select-outlined">Select Request</InputLabel>
                        <Select
                          labelId="request-outlined-label"
                          id="request-select-outlined"
                          color="primary"
                          name="request"
                          value={state.request}
                          label="Request"
                          className={classes.inputStyle}
                          onChange={handleFormTextChange('request')}
                          fullWidth
                        >
                         {!getPointPrivateLabel() && <MenuItem value="ACH Form" >ACH Form</MenuItem>}
                          <MenuItem value="Discuss Loan" >Discuss {getLoanLabel()}</MenuItem>
                          <MenuItem value="Full Authorization">Full Authorization</MenuItem>
                          {(selectedState.stateCode === 'WA') && !getPointPrivateLabel() && <MenuItem value="Housing Councelor" key="">Housing Counselor</MenuItem>}
                          {/* {!getPointPrivateLabel() && <MenuItem value="Loss Mitigation Package">Loss Mitigation Package</MenuItem>} */}
                          <MenuItem value="Payoff">Payoff</MenuItem>
                          {!getPointPrivateLabel() && <MenuItem value="Reinstatement Quote">Reinstatement Quote</MenuItem>}
                          <MenuItem value="Verification of Mortgage">Verification of {getMortgageLabel()}</MenuItem>
                          {subOrdinate && !getPointPrivateLabel() &&  <MenuItem value="subordination request">2nd Lien Subordination Request</MenuItem>}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div>
                      <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                        <InputLabel id="borrower-type-select-outlined">Relationship to {getBorrowerLabel()}</InputLabel>
                        <Select
                          labelId="request-outlined-label"
                          id="request-select-outlined"
                          color="primary"
                          name="identity"
                          value={state.identity}
                          label={`Relationship to ${getBorrowerLabel()}`}
                          className={classes.inputStyle}
                          onChange={handleFormTextChange('identity')}
                          fullWidth
                        >
                          <MenuItem value="01" >Attorney</MenuItem>
                          <MenuItem value="02">Counselor</MenuItem>
                          <MenuItem value="03">Friend</MenuItem>
                          <MenuItem value="04">Realtor/Closer</MenuItem>
                          <MenuItem value="05">Spouse</MenuItem>
                          <MenuItem value="06">Unknown</MenuItem>
                          <MenuItem value="99">Other</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  {otherItentityBox && <Grid item xs={12} sm={12} md={12}>
                    <div className={classes.otherName}>
                      <TextField
                        id="outlined-requester-input"
                        label="Other Relationship Name"
                        color="primary"
                        type="text"
                        value={state.otherIdentity}
                        name="otherIdentity"
                        variant="outlined"
                        fullWidth
                        onChange={handleFormTextChange('otherIdentity')}
                        InputLabelProps={{
                          classes: { root: classes.textField }
                        }}
                        InputProps={{
                          classes: { root: classes.textField, input: classes.textInput },
                          maxLength: 100
                        }}
                      />
                    </div>
                  </Grid>}
                  <Grid item xs={12} sm={6} md={6}>
                    <div>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                          <Grid container>
                            <Grid item xs={10} sm={10} md={10}>
                              <div className={classes.captchaarea} style={{ backgroundImage: captchaBG, paddingTop: '12px' }}>
                                <div id="captcha">
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} className={classes.buttonarea}>
                              {volumeIcon ? <VolumeOffIcon className={classes.volumeButton} />
                                : <VolumeUpIcon className={classes.volumeButton} onClick={() => { speakk({ text: captchaCode }); check() }} />}<br />
                              <RefreshIcon className={classes.refreshButton} onClick={() => { getCaptchaCode() }} />

                            </Grid>
                          </Grid>

                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <TextField
                            id="outlined-loan-input"
                            label="Captcha"
                            color="primary"
                            type="text"
                            value={state.captcha}
                            name="captcha"
                            variant="outlined"
                            fullWidth
                            onChange={handleFormTextChange('captcha')}
                            InputLabelProps={{
                              classes: { root: classes.textField }
                            }}
                            inputProps={{
                              className: classes.inputStyle
                            }}
                          />
                          {captchaErr && <p className={classes.error}>Please fill the correct Captcha</p>}

                        </Grid>

                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div>
                      <p className={classes.bobyText}>
                        *Note:<br />
                        Please enter all of the information exactly as has been provided to you by the {getBorrowerLabel()?.toLowerCase()}(s)
                        along with your email address and click on the "submit" button.
                    </p>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <p className={classes.errorMsg}>{errorMsg !== '' ? errorMsg : ''}</p>
                    <Box className={`${classes.buttonContainer}`} display="flex" justifyContent="center" style={{ paddingBottom: '34px' }}>
                      <Button className={`${classes.resetButton}`} mt={2}
                        variant="outlined"
                        //color="primary"
                        onClick={resetForm}>
                        Reset
                      </Button>
                      <Button className={`${classes.addAccButton}`} mt={2}
                        type="submit"
                        variant="contained"
                        color='primary'
                        disabled={loading || disableBtn || checkErrorObject() !== 0 ? true : false}>
                        {!loading ? 'Submit' : <CircularProgress color='primary' style={{ width: 25, height: 25 }} />}
                      </Button>
                    </Box>
                  </Grid>

                </Grid>
              </form>
              : <ThirdPartyPdfFile/>
            }
          </Box>
        </Box>

        {popupData !== '' && <ThirdPartyAlert
          open={openSuccessAlert}
          data={popupData}
          handleClose={handleCloseSuccessAlert}
          submittedRequestType={submittedRequestType}
          thirdPartyEmailID={thirdPartyEmailID} />}
      </Box>
    </Box>
  )
})