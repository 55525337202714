import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TableRow, TableCell } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  main: {
    width: '95%',
    margin: '0 auto',
    padding: "3em 0em",
  },

  title: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Montserrat",
    fontSize: "24px",
    marginBottom: 20,
    fontWeight: "600",
  },

  formStyle: {
    width: '100%',
  },

  searchBox: {
    width: '100%',
    backgroundColor: '#FFF',
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    padding: '2em 1.5em 1em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',    
  },

  inputBox: {
    width: 200,
    marginBottom: 10,
    marginRight: 10
  },

  privateLabel: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "600",
  },

  inputStyle: {
    color: "rgba(0, 0, 0, 0.74)",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "500",
  },


  searchByBox: {
    display: 'flex',
    alignItems: 'centr',
  },

  searchBtnBox: {
    marginBottom: 10,
  },

  searchBtn: {
    height: 35,
  },

  includeAllLabel: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "600",
  },

  tableBox: {
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: 12,
  },

  loaderBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  noDataFoundText: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "600",
  },

  link: {
    width: 30,
    height: 30,
    cursor: "pointer",
    color: '#000'
  },

  linkCustomer: {
    width: 43,
    height: 43,
    cursor: "pointer",
    color: '#000'
  }

}));


export const StyledTableCell = withStyles((theme) => ({
  root: {    
    padding: "10px"
  },
  head: {
    backgroundColor: "#535D6D",
    color: "#ffffff",
    fontSize: "14px",
    fontWeight: "bold",
    letterSpacing: "0.36px",
    fontFamily: "Montserrat",
    borderRight: "1px solid #ffffff",
    verticalAlign: "top",
    padding: "10px"
  },
  body: {
    fontSize: "14px",
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Montserrat"
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);