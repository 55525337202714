import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({


    inputFeedback: {
        width: 441,
        background: '#7EB1CC',
        // borderRadius: 4,
        position: 'fixed',
        bottom: 38,
        right: 107,
        zIndex: 1000,
        height: 149,
        textAlign: 'center',
        // "width": "100%",
        // "height": "50px",
        "background": "white",
        "padding": "0",
        "display": "flex !important",
        "alignItems": "center !important"
      },

      //  defaultButtonStyles: {
      //   fontSize:'16px',
      //   background: "#34495e",
      //   color: "#fff",
      //   fontWeight: "600",
      //   zIndex: 99,
      //   padding:'16px 29px 4px 59px',
      //   position:'fixed',
      //   borderRadius:'5px 5px 0 0',
      //   border: 'none',
      //   bottom:0,
      //   backgroundColor:'#7EB1CC',
      //   // width: 'auto',
      //   cursor: 'pointer',
      //   minWidth: '222px !important',
      //   right: '1% !important',
      //   ['@media (max-width:720px)']: {
      //     padding:'16px 12px 4px 34px !important',
      //     // display: "inline",
      //     // paddingRight: "1%",
      //     // width: "24%"
      //   }
      // }
      rating:{
        display: 'flex',
      /// justifyContent: 'space-between'
    }
}))