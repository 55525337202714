import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import {
  Box, Button, IconButton,
  InputAdornment, FormControl, CssBaseline, CircularProgress, Grid, TextField,
  OutlinedInput, InputLabel
} from '@material-ui/core';
import { useStyles } from './styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { accountVerifyResetPassword } from '../../actions';
import Cookies from 'js-cookie';
import { encrypt } from '../../helpers/encrypt';
import { getPublicIp, getAppSource, getUserType, scrollTop, returnCaptchaCode, renderDefaultMsg } from '../../helpers';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RefreshIcon from '@material-ui/icons/Refresh';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import captchaBG from '../../assets/captchaBG.svg';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const tooltipStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  customWidth: {
    maxWidth: 800,
    fontSize: 14,
    bottom: 23,
  },
  noMaxWidth: {
    maxWidth: 'none',
  },

}));

export const ResetPasswordBySsn = () => {
  const mytheme = useSelector(state => state.theme);
  const { secondaryColorCode } = mytheme;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(true)
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [captchaCode, setCaptchaCode] = useState('')
  const [captchaErr, setcaptchaErr] = useState(false);
  const [volumeIcon, setvolumeIcon] = useState(false);
  const [values, setValues] = useState({
    showPassword: false,
    borrowerSSN: '',
    zipCode: '',
    captcha: ''
  });
  const captchaTimer = useRef(null);

  useEffect(() => {
    createCaptcha()
    handleTimer();
    return () => clearInterval(captchaTimer.current);
  }, []);

  const handleTimer = () => {
    captchaTimer.current = setInterval(() => {
      createCaptcha()
    }, 180000);
    return () => clearInterval(captchaTimer.current);
  }

  const handleReset = () => {
    clearInterval(captchaTimer.current);
  }

  const createCaptcha = () => {
    setcaptchaErr(false)
    const cpatchCode = returnCaptchaCode();
    setCaptchaCode(cpatchCode)
  }

  const check = () => {
    setvolumeIcon(true)
    setTimeout(function () { setvolumeIcon(false) }, 2000);
  }

  const speakk = () => {
    let speech = new SpeechSynthesisUtterance();
    speech.lang = "en-UK";
    speech.text = captchaCode;
    speech.volume = 2;
    speech.rate = 0.25;
    speech.pitch = 1.4;
    window.speechSynthesis.speak(speech);
  }

  const handleChange = (prop) => (event) => {
    if (prop === 'borrowerSSN') {
      if ((/^[0-9]+$/).test(event.target.value) || event.target.value === '') {
        setValues({ ...values, [prop]: event.target.value.slice(0, 4) });
      }
      return;
    } else if (prop === "zipCode") {
      if ((/^[0-9]+$/).test(event.target.value) || event.target.value === '') {
        setValues({ ...values, [prop]: event.target.value.slice(0, 5) });
      }
      return;
    }

    setValues({ ...values, [prop]: event.target.value });
    return;
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (captchaCode.toLowerCase() === values.captcha.toLowerCase()) {
      setLoading(true);
      setcaptchaErr(false);
      const data = {
        "borrowerSSN": encrypt(values.borrowerSSN),
        "propertyZipCode": values.zipCode,
        "privateLabelUrl": Cookies.get("privateLabelUrl"),
        "ipAddress": await getPublicIp(),
        "browser": Cookies.get("browser"),
        "appSource": getAppSource(),
        "userType": getUserType(),
        "userName": Cookies.get("userName")
      }
      const res = await accountVerifyResetPassword(data);
      if (res) {
      const { lstResponseStatus } = res;
      setLoading(false);
      if (res && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        if (res && res.isSucess) {
          setValues({ ...values, borrowerSSN: '', zipCode: '', captcha: '' });
          history.push(`/ResetPassword?username=${res.userName}`);
        } else {
          setErrorMsg(lstResponseStatus[0].statusDesc);
          setValues({ ...values, captcha: '' });
          handleReset();
          createCaptcha();
          handleTimer();
          scrollTop();
        }
     } else {
       setErrorMsg('The information provided is not valid. Please check your information and try again.')
       setValues({ ...values, captcha: '' });
       handleReset();
       createCaptcha();
       handleTimer();
       scrollTop();
     }
    } else {
      setErrorMsg(renderDefaultMsg('400'))
    }
    }  else {
      createCaptcha();
      setcaptchaErr(true);
      setValues({ ...values, captcha: '' });
    }
  }
  
  const { borrowerSSN, zipCode, showPassword, captcha } = values;
  const disableBtn = zipCode.length === 5 && (borrowerSSN.length === 4) && loading === false 

  return (
    <CssBaseline>
      <Box container="true" maxWidth='xl' className={classes.root}>
        <Box className={classes.formContainer}>
          <p className={classes.title}>Reset Password</p>
          <p className={classes.errorMsg} dangerouslySetInnerHTML={{ __html: errorMsg !== '' ? errorMsg : '' }} />
         
          <form noValidate onSubmit={handleSubmit}>
 
            <Box className={classes.inputContainer}>
              <FormControl variant="outlined" fullWidth >
                <InputLabel className={classes.labelStyle} htmlFor="outlined-adornment-password">Borrower SSN/EIN Last 4 Digits</InputLabel>
                <OutlinedInput
                  color="primary"
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  value={borrowerSSN}
                  onChange={handleChange('borrowerSSN')}
                  labelWidth={activeTab ? 250 : 150}
                  inputProps={{
                    className: classes.inputStyle,
                    maxLength: activeTab ? 4 : 35,
                    autocomplete: "new-password",
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box> 
             
            <Box className={classes.inputContainer}>
              <TextField
                color="primary"
                id="outlined-zip"
                label="Property Zip Code"
                variant="outlined"
                type="text"
                value={zipCode}
                onChange={handleChange('zipCode')}
                fullWidth
                inputProps={{
                  className: classes.inputStyle,
                  maxLength: 5,
                  form: {
                    autocomplete: 'off',
                  },
                }}
                InputLabelProps={{
                  classes: { root: classes.textField }
                }}
              />
            </Box>
            <Box className={clsx(classes.inputContainer, classes.captchaContainer)}>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <Grid container>
                      <Grid item xs={10} sm={10} md={10}>
                        <div className={classes.captchaarea} style={{ backgroundImage: captchaBG, paddingTop: '10px' }}>
                          <div id="captcha">
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} className={classes.buttonarea}>
                        {volumeIcon ? <VolumeOffIcon className={classes.volumeButton} /> : <VolumeUpIcon className={classes.volumeButton} onClick={() => { speakk({ text: captchaCode }); check() }} />}<br />
                        <RefreshIcon className={classes.refreshButton} onClick={() => { createCaptcha() }} />
                      </Grid>
                    </Grid>

                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      id="outlined-loan-input"
                      label="Captcha"
                      color="primary"
                      type="text"
                      value={captcha}
                      name="captcha"
                      variant="outlined"
                      fullWidth
                      onChange={handleChange('captcha')}
                      InputLabelProps={{
                        classes: { root: classes.textField }
                      }}
                      inputProps={{
                        className: classes.inputStyle
                      }}
                    />
                    {captchaErr && <p className={classes.error}>Please enter correct Captcha.</p>}
                  </Grid>
                </Grid>
              </div>
            </Box>
            <Box >
              <Button disabled={(!disableBtn || !captcha)} type='submit' className={classes.submitBtn} size="large" fullWidth variant="contained" color="primary">
                {loading ? <CircularProgress /> : "Next"}
              </Button>
              <Button component={Link} to='/RecoverPassword?back' fullWidth className={classes.sendBtn} variant='outlined' color="primary" >Back</Button>
            </Box>
          </form>
        </Box>
      </Box>
    </CssBaseline>
  )
}
