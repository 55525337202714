import React, { useEffect } from 'react';
import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Chart from 'chart.js';
import 'chartjs-plugin-labels';
import { numberWithCommas } from '../../helpers';

export const PaymentBreakdown = (props) => {
  const classes = useStyles();
  const { piEscrow, piInterest, piPrincipal, piTotalMonthlyPayment, isPaymentAllowed } = props;
  const config = {
    type: 'doughnut',
    data: {
      labels: ['Principal', 'Interest', 'Escrow'],
      datasets: [{
        backgroundColor: ["#7A9D7D", "#8D789C", "#25ABC7"],
        data: [Number(piPrincipal).toFixed(2), Number(piInterest).toFixed(2), Number(piEscrow).toFixed(2)],
        borderDashOffset: 0.0,
        pointBorderColor: "rgba(245,245,245,1)",
        pointBackgroundColor: "rgba(80,81,81,1)",
        pointHoverBorderWidth: 5,
        pointBorderWidth: 5,
        pointRadius: 8,
        pointHoverRadius: 9,
        pointHitRadius: 8,
      }],
    },

    options: {
      cutoutPercentage: 60,
      legend: {
        position: 'bottom',
        labels: {
          fontColor: "#0F355C",
          fontFamily: "Montserrat",
          fontSize: 14,
        }
      },
      plugins: {
        labels: [
          {
            position: 'outside',
            textMargin: 4,
            fontSize: 14,
            fontFamily: 'Montserrat',
            fontWeight: 'bold',
            render: function (args) {
              return args.label + ' $' + numberWithCommas(args.value);
            },
          }
        ]
      },
    },
  }


  useEffect(() => {
    if (isPaymentAllowed) {
      var ctx = document.getElementById('myChart').getContext('2d');
      new Chart(ctx, config);
    }
  }, [isPaymentAllowed])

  return (
    <Box className={classes.root}>
      <Box className={classes.flexBox}>
       <h1 className={classes.title}>Payment Breakdown</h1>
       {isPaymentAllowed && <h1 className={classes.totalText}>Total Monthly Payment $ {numberWithCommas(parseFloat(piTotalMonthlyPayment).toFixed(2))}</h1>}
      </Box>
      {isPaymentAllowed ?
        <div>
          <canvas id="myChart" width="300" height="200" />
        </div>
        :
        <div className={classes.disabledRoot}>
          <p className={classes.errorTitle}>Due to the status of your loan <b>Payment Breakdown</b> is not available</p>
        </div>
      }
    </Box>
  )
}
