import axios from 'axios';
import Cookies from 'js-cookie';
import { preLoginToken } from '../actions';
import { getPublicIp, getUserType, getAppSource } from '../helpers'


export let baseUrl;
switch (process.env.REACT_APP_ENV) {
  case 'QA':
    baseUrl = 'https://myloanwebAPIQA.bsifinancial.com'
    break;
  case 'UAT':
    baseUrl = 'https://mlw-uatapi.bsifinancial.com'
    break;
  case 'STAGING':
    baseUrl = 'https://mlw-stageapi.bsifinancial.com'
    break;
  case 'PRODUCTION':
    baseUrl = 'https://mlw-prod-api.bsifinancial.com'
    break;
  default:
    baseUrl = 'https://myloanwebAPIQA.bsifinancial.com'
    break;
}

// ===============================Public Route ===============================

export const axiosPublic = axios.create({
  baseURL: baseUrl,
});

axiosPublic.interceptors.request.use(async (config) => {
  const token = await Cookies.get('publicToken')
  config.headers = {
    'Content-Type': "application/json",
    'Authorization': `Bearer ${token}`
  }
  return config;
}, (e) => Promise.reject(e));

axiosPublic.interceptors.response.use(res => {
  if (res.status !== 200) {
    const error = new Error(res.data.message);
    error.response = res;
    throw error;
  }
  return res;
}, async err => {
  if (401 === err.response.status) {
    localStorage.removeItem('accountVerification');//redirect to accountVerification if token expire 
    await preLoginToken();
    window.location.reload();
  }
  return Promise.reject(err)
});

// ===============================Protected Route ===============================

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  })

  failedQueue = [];
}

export const axiosProtected = axios.create({
  baseURL: baseUrl,
});


// Add a request interceptor
axiosProtected.interceptors.request.use(
  config => {
    const token = Cookies.get('privateToken');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => {
    Promise.reject(error)
  }
);


//Add a response interceptor
axiosProtected.interceptors.response.use((response) => {
  return response
}, async (error) => {
  const originalRequest = error.config;
  if (error.response.status === 401 && originalRequest.url === '/Authentication/GetBorrowertoken') {
    Cookies.remove('privateToken');
    Cookies.remove('selectedAccount');
    Cookies.remove('totalAccounts');
    Cookies.remove('accessTokenID');
    Cookies.remove('adminToken');
    // window.location.href = `/${Cookies.get("privateLabelUrl")}/SignIn`;
    return Promise.reject(error);
  }

  if (error.response.status === 401 && !originalRequest._retry) {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject })
      })
        .then(token => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return axiosProtected.request(originalRequest);
        })
        .catch(err => {
          return Promise.reject(err);
        })
    }

    originalRequest._retry = true;
    isRefreshing = true;

    const data = {
      "accessTokenID": Cookies.get("accessTokenID"),
      "privateLabelURL": Cookies.get("privateLabelUrl"),
      "ipAddress": await getPublicIp(),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }

    return new Promise((resolve, reject) => {
      axiosProtected.post('/Authentication/GetBorrowertoken', data)
        .then(res => {
          if (res.status === 200) {
            Cookies.set("privateToken", res.data.accessToken);
            Cookies.set("accessTokenID", res.data.accessTokenID);
            axiosProtected.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.accessToken;
            originalRequest.headers['Authorization'] = 'Bearer ' + res.data.accessToken;
            processQueue(null, data.token);
            resolve(axiosProtected(originalRequest));
            // return axiosProtected(originalRequest);
          }
        })
        .catch((err) => {
          processQueue(err, null);
          reject(err);
        })
        .then(() => { isRefreshing = false })
    })
  }
  return Promise.reject(error);
});
