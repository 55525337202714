import React from "react";
import { Box, Button, Dialog, IconButton } from "@material-ui/core";
import { useStyles } from './styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import moment from 'moment';
import { decrypt } from "../../helpers/encrypt";

export const AutoPaySuccessAlert = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { dueDate, bankAccount, totalAmount, confirmationCode, deletedConfirmationCode } = props;

  return (
    <Dialog
      fullScreen={fullScreen}
      PaperProps={{ classes: { root: classes.dialogStyle } }}
      onClose={props.handleClose}
      open={props.open}
    >
      <Box className={classes.root}>
        <Box className={classes.header}>
          <IconButton className={classes.iconStyle} onClick={props.handleClose}>
            <CancelIcon />
          </IconButton>
        </Box>
        <Box className={classes.body}>
          <h1 className={classes.title}>Successfully Scheduled AutoPay</h1>
          <p className={classes.subTitle}>Never Miss A Payment.Always Pay on Time</p>
          <p className={classes.smallText}>Your Monthly/ Bi-Weekly Autopay Payment has been scheduled. Check the details of your payment below.</p>
          <Box className={classes.mainContainer}>
            <h3 className={classes.mainBoxTitle}>Request ID #  {decrypt(confirmationCode)}</h3>
            <Box className={classes.flexBox}>
              <Box className={classes.item}>
                <h3 className={classes.itemText}>Total Amount</h3>
                <h3 className={classes.itemSubText}>${totalAmount}</h3>
              </Box>
              <Box className={classes.item}>
                <h3 className={classes.itemText}>Payment Date </h3>
                <h3 className={classes.itemSubText}>{moment(dueDate).format('ll')}</h3>
              </Box>
              <Box className={classes.item}>
                <h3 className={classes.itemText}>Bank Account</h3>
                <h3 className={classes.itemSubText}>{bankAccount}</h3>
              </Box>
            </Box>
          </Box>
          <Box className={classes.buttonBox}>
            <Button onClick={props.handleOk} className={classes.btn} variant="contained" color='primary' >OK</Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}