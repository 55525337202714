import React, { Component } from 'react';
import Form from './Form';
import PropTypes from 'prop-types';
import { insertActivityLog } from '../../actions';
import { maskLoan, getUserType, getAppSource,isAdmin, getPointPrivateLabel, getMortgageLabel, isAdminCheck, getLoanLabel } from '../../helpers'
import './styles.css'
import Cookies from 'js-cookie';

const handleCustomPosition = ((position, formStyles) => {
	var customFormStyles;
	if (position === "left") {
		customFormStyles = { ...formStyles, left: "5%" };
	}
	else {
		customFormStyles = { ...formStyles, right: "8%" };
	}
	return customFormStyles;
})

class Feedback extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showButton: false,
			showForm: true,
			showModal: false,
			nameInput: '',
			messageInput: '',
			emailInput: '',
			resText: this.props.buttonText,
			triggerText: '',
			disableButton: false,
			bodyText: this.props.text
		};
		this.handleButtonClick = this.handleButtonClick.bind(this);
		this.handleCloseWithoutSave = this.handleCloseWithoutSave.bind(this);
		this.handleCloseWithSave = this.handleCloseWithSave.bind(this);
		this.handleMessageInput = this.handleMessageInput.bind(this);
	}

	handleMessageInput(inputName, content) {
		if (inputName === 'email') {
			this.setState({ emailInput: content });
		} else if (inputName === 'name') {
			this.setState({ nameInput: content });
		} else if (inputName === 'message') {
			this.setState({ messageInput: content },()=>{
			});
		}
	}
	
	handleNameInput(nameInput) {
		this.setState({ nameInput: nameInput });
	}
	handleEmailInput(emailInput) {
		this.setState({ emailInput: emailInput });
	}
	handleButtonClick() {
		const { handleButtonClick } = this.props;

		this.setState({ showButton: false, showForm: true });
		handleButtonClick();
	}
	

	handleCloseWithSave() {

		const { handleCloseWithSave, showButtonOnClose } = this.props;
		if (showButtonOnClose) {
			this.setState({ showButton: true });
		}
		this.setState({ showForm: false });
		handleCloseWithSave();
	}


	handleCloseWithoutSave() {

		const { handleCloseWithoutSave, showButtonOnClose } = this.props;
		if (showButtonOnClose) {
			this.setState({ showButton: false });
		}
		this.setState({ 
		showForm: false,
		messageInput: '',
         });
		handleCloseWithoutSave();
		this.props.hideSurvey()

	}

	 handleSubmit = async() => {
	  window.open('https://docs.google.com/forms/d/e/1FAIpQLSebE78T60ZchRm8x9xuOEjsKOlD_gRQ3jduP4efj_f5eXdL9A/viewform', "_blank")
		const text = "User clicked on start survey button on BSI brand survey wizard"
		const data = {
		  "accountNumber":  Cookies.get("selectedAccount"),
		  "activityCode": "UAL202",
		  "activityStatus": "Success",
		  "action": 'BsiBrandSurvey',
		  "controller": "Dashboard",
		  "apiName": "BsiBrandSurvey",
		  "activityRemark":  text,
		  "privateLabelUrl": Cookies.get("privateLabelUrl"),
		  "ipAddress": Cookies.get('publicIp'),
		  "browser": Cookies.get("browser"),
		  "appSource": getAppSource(),
		  "userType": getUserType(),
		  "userName": "",
		};
	
		await insertActivityLog(data);
	  }
	

	render() {
		const {
			headerText,
			buttonText,
			position,
			buttonStyles,
			headerStyles,
			headerBtnStyles,
			headerBtnText,
			bodyText,
			showNameInput,
			showEmailInput,
			showMessageInput,
			style
		} = this.props;

		return (
			<div >
				{this.state.showForm &&
						<Form
							style={style}
							headerText={headerText}
							position={position}
							headerStyles={headerStyles}
							headerBtnStyles={headerBtnStyles}
							headerBtnText={headerBtnText}
							handleCloseWithoutSave={this.handleCloseWithoutSave}
							handleCloseWithSave={this.handleCloseWithSave}
							handleSubmit={this.handleSubmit}
							bodyText={this.state.bodyText}
							showNameInput={showNameInput}
							showEmailInput={showEmailInput}
							showMessageInput={showMessageInput}
							nameInput={this.state.nameInput}
							emailInput={this.state.emailInput}
							ratingInput={this.state.ratingInput}
							messageInput={this.state.messageInput}
							handleMessageInput={this.handleMessageInput}
							handleCustomPosition={handleCustomPosition}
                            requestSubmit={false}
						/>
	}
			</div>
		)
	}
}

Feedback.propTypes = {
	headerText: PropTypes.string,
	bodyText: PropTypes.string,
	position: PropTypes.string,
	handleClose: PropTypes.func,
	handleSubmit: PropTypes.func,
	handleButtonClick: PropTypes.func,
	showButtonOnClose: PropTypes.bool,
	showButtonOnSubmit: PropTypes.bool,
	buttonStyles: PropTypes.object,
	headerStyles: PropTypes.object,
	headerBtnStyles: PropTypes.object,
	bodyStyles: PropTypes.object,
	footerStyles: PropTypes.object,
	buttonText: PropTypes.string,
	headerBtnText: PropTypes.string,
	showEmailInput: PropTypes.bool,
	showRatingInput: PropTypes.bool,
	showMessageInput: PropTypes.bool,
	handleCloseWithoutSave: PropTypes.func,
	handleCloseWithSave: PropTypes.func
}

Feedback.defaultProps = {
	position: 'right',
	handleSubmit: () => { },
	handleCloseWithSave: () => { },
	handleCloseWithoutSave: () => { },
	handleButtonClick: () => { },
	showButtonOnClose: true,
	showButtonOnSubmit: true,
	modal: false
}

export default Feedback;
