import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '16px'
  },

  statusSuccess: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600",
    marginBottom: 20,
    textAlign: 'center',
    borderBottom: '1px solid #e3dddd',
    paddingBottom: '16px',
  },

  statusFail: {
    color: "red",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600",
    marginBottom: 20,
    textAlign: 'center'
  },

  container: {
    backgroundColor: '#FFF',
    padding: 15,
    marginBottom: 20,
    borderBottom: "solid 1px #e3dddd",
  },

  displayFlex: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  item: {
    minWidth: '40%',
    marginRight: 10,
  },

  text: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "500",
    marginBottom: 10,
  },

  boldText: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600",
    marginLeft: 10,
  },

  btn: {
    display: 'block',
    margin: '0 auto',
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600",
  },

  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },

  iconStyle: {
    padding: 0,
    color: '#535D6D',
    fontSize: '24px'
  },


}));