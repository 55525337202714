import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: 12,
    backgroundColor: "#fff",
    marginTop: 28,
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    padding: '3.6em 7.7em',
    [theme.breakpoints.down('xs')]: {
      padding: '1.6em',
    },
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    letterSpacing: "0.15px",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: '500',
    marginBottom: 30,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 30,
    },
  },

  cardBody: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  statusContainer: {
    marginBottom: 30,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: 20,
    },
  },

  statusTitle: {
    color: "rgba(0,0,0,0.74)",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "500",
    marginBottom: 12,
    marginTop: 0,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 7,
    },
  },

  marginFix: {
    marginBottom: 0,
  },

  statusSubTitle: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '10px',
    fontWeight: "500",
    marginBottom: 8,
  },


  statusBody: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "bold",
    letterSpacing: "0.15px",
  },

  seeMore: {
    marginBottom: 30,
    borderBottom: "1px solid #ddd",
  },

  expandBtn: {
    color: '#535D6D',
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: "600",
    textTransform: 'capitalize',
    marginBottom: 20,
    textDecoration: 'underline',
  },

  cardFooter: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start !important',
  },

  footerItem: {
    marginBottom: 33,
    width: '48%',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
  },

  footerLink: {
    color: theme.palette.secondary.main,
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    fontWeight: '600',
    display: 'block',
    marginTop: 16,
    textAlign: 'center',
  },

  btn: {
    width: '100%',
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "500",
    [theme.breakpoints.down('xs')]: {
      width: 264,
      display: 'block',
      margin: '0 auto',
      fontSize: '1.4em',
    },
  },

  disabledBtn: {
    width: '100%',
    backgroundColor: "#A0A0A0",
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "500",
    '&:hover': {
      backgroundColor: "#A0A0A0",
    },
    [theme.breakpoints.down('xs')]: {
      width: 264,
      display: 'block',
      margin: '0 auto',
      fontSize: '1.4em',
    },
  },

  showAndHideContainer: {
    width: '100%',
  },

  detaiContainer: {
    width: '100%',
  },

  detailTitle: {
    color: " rgba(0,0,0,0.87)",
    fontWeight: '500',
    fontFamily: "Montserrat",
    fontSize: '2em',
    letterSpacing: "0.15px",
    marginBottom: 30,
  },

  detailTitleContainer: {
    paddingTop: 20,
    borderTop: 'solid 1px #ddd'
  },

  flexContainer: {
    display: "flex",
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },


  detailLink: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    fontWeight: '600',
    display: 'block',
    textAlign: 'center',
    textDecoration: "underline",
    textTransform: "inherit",
    background: 'none',
    '&:hover': {
      textDecoration: "underline",
      textTransform: "inherit",
      background: 'none'
    }
  },

}));


export const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.secondary.main,
    fontSize: '40px'
  },
  tooltip: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    padding: '20px 15px',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    letterSpacing: '0.55px',
    lineHeight: '21px',
    width: '500px',
    maxWidth: '500px',
    borderRadius: '10px'
  },
}));

