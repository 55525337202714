import React, {useState} from 'react';
import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import window from '../../assets/Refinance/window.png';
import { insertActivityLog } from '../../actions'
import Cookies from 'js-cookie';
import { getUserType, getAppSource } from '../../helpers';
import { CoveredBannerPopup } from '../CoveredBannerPopup';

export const CoveredBanner = (props) => {

  const { buttonColor, buttonText, buttonURL, bannerImageUrl, bannerName } = props.banner;
  const [closePopup, setClosePopup] = useState(false);
  const classes = useStyles();
 
  const handleCancelIcon = () => {
    setClosePopup(false)
  }

  const handleActivity = async () => {
    setClosePopup(true)
    const data = {
      "accountNumber":  Cookies.get("selectedAccount"),
      "activityCode": "UAL202",
      "activityStatus": "Success",
      "action": (bannerName.toLowerCase() == 'matic') ? 'MaticTile' : 'CoveredTile',
      "controller": "Dashboard",
      "apiName": "GetAccountInfo",
      "activityRemark": `User clicked on ${(bannerName.toLowerCase() == 'matic') ? 'matic' : 'covered'} insurance tile button on home page`,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertActivityLog(data);
  }

  return (
    <>
      <Box className={classes.root}>

        <Box className={classes.body}>
          <img className={classes.logo} src={bannerImageUrl} alt='banner logo' />
        </Box> 

        <Box className={classes.footer}>
          {buttonURL && buttonURL.length ? <a className={classes.btn} style={{ backgroundColor: buttonColor }} onClick={() => handleActivity()}>{buttonText} <img src={window} className={classes.openIcon}/></a> : null}
        </Box>
      </Box>
     { closePopup &&
      <CoveredBannerPopup open={closePopup} handleCancelIcon={handleCancelIcon} data={props.banner} page="home"/>
     }
     </>
  )
}
