import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useStyles } from './styles';
import { getHelpContent } from '../../actions';
import { scrollTop } from '../../helpers';
import './style.css';
import { PropertyInsuranceQuote } from '../../components';

import Cookies from 'js-cookie';
import { getAppSource, getUserType } from '../../helpers'
import { insertActivityLog } from '../../actions'

export const Refinance = React.memo((props) => {
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState('');
  const [insuranceQuote, setInsuranceQuote] = useState(false);
  const dispatch = useDispatch();

  const [activeAccountNo, setActiveAccountNo] = useState('');
  const getAccountNo = Cookies.get('selectedAccount')

  useEffect(() => {
    scrollTop()
    if (getAccountNo !== '') {
      setActiveAccountNo(getAccountNo)
    }
  }, [getAccountNo])

  // const switchPage = async (accountNumber) => {
  //   setActiveAccountNo(accountNumber);
  // }


  useEffect(() => {
    getData();
    scrollTop();
  }, []);

  useEffect(() => {
    if (data && data.length !== 0) {
      setTimeout(() => {
        clickEvent();
        clickEventGetInstantQuote();
      }, 2000);
    }
  }, [data]);

  const getData = async () => {
    const res = await dispatch(getHelpContent('PC014'));
    if (res && res.lstPageContentDetails && res.lstPageContentDetails.length) {
      const result = res.lstPageContentDetails.filter((item) => {
        return item.contentKey.toLowerCase() === 'refinance';
      });
      setData(result[0])
    } else {
      setData('');
    }
  }

  const routePage = () => {
    sendActivityData()
    window.open('https://bsifinancial.com/ob-form.asp', '_blank');
  }

  const sendActivityData = async () => {
    const data = {
      "accountNumber": activeAccountNo,
      "activityCode": "UAL192",
      "activityStatus": "Success",
      "action": "GetAccountInfo",
      "controller": "Dashboard",
      "apiName": "GetAccountInfo",
      "activityRemark": "User clicked on Get an instant quote.",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertActivityLog(data);
  }

  const toggleInsuranceQuote = () => {
    setInsuranceQuote(!insuranceQuote);
  }

  const clickEvent = () => {
    let loadFaq = document.getElementById('loadFaqAnswer');
    loadFaq.addEventListener("click", function (e) {
      e.preventDefault();
      history.push('/RefinanceMortgageFaq')
    })
  }

  const clickEventGetInstantQuote = () => {
    const getHippoQuote = document.getElementById('GetHippo');
    getHippoQuote.addEventListener("click", function (e) {
      e.preventDefault();
      toggleInsuranceQuote();
    })
  }

  if (data !== '') {
    return (
      <Box className={classes.rootBg}>
        <Box className={classes.imgWrapper}>
          <Box className={`${classes.wrapperBody}`}>
            <Box className={`${classes.root} `} flexDirection="column">
              <Typography className={classes.refinanceHeader}>{data.contentHeader}</Typography>
              <Typography className={classes.refinanceSubHeader}>{data.contentSubHeader}</Typography>
              <Button className={`${classes.addAccButton}`} mt={2}
                variant="contained"
                target="_blank"
                onClick={routePage}
                color="primary"
              >
                Get an instant quote
              </Button>
            </Box>
          </Box>
        </Box>
        <Box className={classes.root} >
          <Box className={classes.html}>
            <div dangerouslySetInnerHTML={{ __html: data.contentValue }} />
          </Box>
        </Box>

        {insuranceQuote && <PropertyInsuranceQuote
          toggleOpen={toggleInsuranceQuote}
          open={insuranceQuote}
        />}

      </Box>
    )
  } else {
    return (
      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
        <Box my={1} mx={2}>
          <CircularProgress color='primary' />
        </Box>
      </Box>
    )
  }
});
