import React, { useEffect, useState } from 'react';
import { Box, Breadcrumbs, CircularProgress } from "@material-ui/core";
import { useStyles } from './styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getContent } from '../../actions';
import { scrollTop } from '../../helpers';
import CustomBreadcrum from '../Breadcrum';

export const FeesSchedule = () => {

  const classes = useStyles();
  const [content, setContent] = useState([])
  const dispatch = useDispatch()

  const getContentData = async () => {
    const res = await dispatch(getContent('PC021'));
    if (res) {
      setContent(res);
      scrollTop();
    }
  };

  useEffect(() => {
    scrollTop();
    getContentData();
  }, []);

  return (
    <Box className={classes.pageBg}>
      <Box className={classes.root}>
       
        <CustomBreadcrum backPage="Support" backPageLink="/HelpLanding" activePage="Fees Schedule" />
        <h1 className={classes.pageHeading} color="textPrimary" component='h4'>Fees Schedule</h1>

        {content.length !== 0 ?
          < Box className={classes.container} dangerouslySetInnerHTML={{ __html: content[0].contentValue }} />
          :
          <Box className={classes.loaderContainer}>
            <CircularProgress />
          </Box>
        }
      </Box>
    </Box>
  )
}