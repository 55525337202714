import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormControlLabel-root': {
      marginTop: '12px'
    }
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiFormGroup-root': {
      marginLeft: '20px'
    }
  },

  required: {
    color: '#ec3131',
    fontWeight: '600',
    marginLeft: '2px'
  },

  checkboxLebel: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "500",
  },

  radioLabel: {
    marginTop: '0 !important',
    height: '25px'
  },


  radioStyle: {
    '&.Mui-checked': {
      color: '#535D6D'
    }
  },

  checkBoxStyle: {
    '&.Mui-checked': {
      color: '#535D6D'
    }
  },

  covidLinkStyle: {
    display: 'inline-block', color: "#535D6D", cursor: 'pointer', textDecoration: 'underline', fontWeight: '600'
  },
}))