import React, { Fragment, useState } from 'react';
import { useStyles } from './styles';
import { Box, Button, Typography } from '@material-ui/core';
import equalHousing  from '../../assets/equal-housing.png';
import { insertActivityLog } from '../../actions'
import Cookies from 'js-cookie';
import { getAppSource, getUserType } from '../../helpers'

export const AlliedMortgageCard = (props) => {
  const { bannerBody, bannerImageUrl, bannerTitle,
    buttonColor, buttonText, buttonURL, titleBgColor, bannerSubTitle } = props.banner;

  const classes = useStyles();
 
  const sendActivityData = async () => {
    const remark = "AlliedMortgage - User clicked on Refinance Card button";
    const data = {
      "accountNumber": props.accountNumber,
      "activityCode": "UAL192",
      "activityStatus": "Success",
      "action": "GetAccountInfo",
      "controller": "Dashboard",
      "apiName": "GetAccountInfo",
      "activityRemark":  remark,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertActivityLog(data);
  }

  const route = () => {
    sendActivityData()
    window.open(buttonURL, '_blank')
  }

  return (<Fragment>
    <Box className={classes.root}>
      <Box className={classes.body} >
        <img className={classes.logo} src={bannerImageUrl} alt='logo' />
        <Box className={classes.bodyTextContainer}>
           <div  dangerouslySetInnerHTML={{ __html: bannerBody }}></div> 
        </Box>

      </Box>
      <Box className={classes.footer}>
       
        <Button onClick={() => route()} className={classes.btn} style={{ backgroundColor: buttonColor }}>{buttonText}</Button>
        <Box>
        <div style={{marginTop: '8px'}}>
        <img src={equalHousing} alt="" style={{ marginRight: '-26px', width: '30px', float: 'right' }} />

         <Typography style={{float: 'right',  fontSize: '9px'}}>{"NMLS# 1067"}</Typography>
         <Typography style={{float: 'right', marginRight: '-50px', marginTop: '12px',  fontSize: '9px'}}>{"225E.City Ave.,Suite 102"}</Typography>
         <Typography style={{float: 'right', marginRight: '-100px', marginTop: '23px',  fontSize: '9px'}}>{"Bala Cynwyd, PA 19004"}</Typography>

         </div>
        </Box>
      </Box>
    </Box>
   
  </Fragment>
  )
}
