import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 800,
    borderRadius: 12,
    backgroundColor: '#FFF',
    // boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)"
  },

  // dialogStyle: {
  //   borderRadius: '12px !important',
  //   maxHeight: "100%",
  //   backgroundColor: 'transparent',
  //   [theme.breakpoints.down('md')]: {
  //       width: '100%',
  //       padding: '20px',
  //       margin: '50px 15px'
  //     },
  // },

  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "400",
    textAlign: 'center',
    margin: 14,
  },

  cardBody1: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '12px 2em',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },
  // root: {
  //   maxWidth: 800,
  //   borderRadius: 12,
  //   backgroundColor: '#EAECED',
  //   position: 'relative',
  //   boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
  // },

  dialogStyle: {
    borderRadius: '12px !important',
    maxWidth: '90%',
    maxHeight: "100%",
    backgroundColor: 'transparent'
  },

  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 30,
    marginTop: 50,
  },

  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "400",
  },

  cardBody2: {
    width: '100%',
    // display: 'flex',
    // justifyContent: 'space-between',
    // alignItems: 'center',
    padding: '0 48px',
    marginTop: 26,
    marginBottom: 26,
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      padding: '1.6em',
    },
  },
  
  body: {
    width: '100%',
    backgroundColor: '#E5ECF0',
    borderRadius: 8,
    padding: '0 20px',
    borderRadius: '4px',
    border: "1px solid #D9D9D9",
    // display: 'flex',
    // justifyContent: 'space-between',
    // alignItems: 'center',
    padding: '0 22px',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      padding: '1.6em',
    },
  },
  
  list: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'end',
      alignItems: 'end',
    },
  },
  
  infoTooltipIcon: {
    fill: "#007FFF",
    marginRight: "5px",
    width: "19px", 
    height: "19px",
    [theme.breakpoints.down('sm')]: {
      marginTop: "12px"
    }
  },

  cardBody3: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 5.5em',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      padding: '1.6em',
    },
  },



  statusContainer: {
    marginBottom: 15,
    paddingRight: 10,
    [theme.breakpoints.down('sm')]: {
      flex: '50%',
    },
  },

  modalTitle: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '22px',
    fontWeight: "700",
    padding: '3px 2px 2px 3px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px'
    }
  },
  
  statusTitle: {
    color: "#4D4D4D",
    fontFamily: "Montserrat",
    fontSize: '16px',
    fontWeight: "500",
    marginBottom: 10,
    marginTop: 25,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  viewMore:{
    color: '#007FFF',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    cursor:'pointer',
    textDecorationLine: 'underline',
    display: "none",
    [theme.breakpoints.down('sm')]: {
      display: "block"
    },
  },
 
  desktopView:{
    paddingTop: "10px",
    display: "flex",
    [theme.breakpoints.down('sm')]: {
      display: "none"
    },
  },
  mobileView:{
    paddingTop: "10px",
    display: "none",
    [theme.breakpoints.down('sm')]: {
      display: "flex"
    },
  },


  statusBody: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: 18,
    fontWeight: "bold",
    [theme.breakpoints.down('sm')]: {
      fontSize: 16
    }
  },

  bodyText: {
    color: "#0C0B0B",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    marginBottom: 30,
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 8,
    }
  },

  cardFooter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: "#fff",
    padding: '20px 5.5em',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      padding: '1.6em',
    },
  },

  footerText: {
    color: "#0C0B0B",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    fontWeight: "400",
    padding: "2px 0"
  },
  
  autoPayEnrolled: {
    color: "#0C0B0B",
    fontFamily: "Montserrat",
    fontSize: 18,
    fontWeight: "400",
  },

  onTime: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: "700"
  },

  linkStyle: {
    color: "#007FFF",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600",
    marginTop: 5,
    textDecoration: 'underline'
  },

  footerItem: {
    marginBottom: 10,
    maxWidth: '100%',
    width:'100%',
    display: 'flex',
    justifyContent: 'flex-start',
    textAlign: 'start',
    flexWrap: 'wrap',
    '&.end':{
      textAlign: 'end',
      justifyContent: 'flex-end',

    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      textAlign: 'center',
      '&.end':{
        textAlign: 'center',
        justifyContent: 'center',
      },
    }
  },

  btn: {
    width: 188,
    fontFamily: "Montserrat",
    fontSize: '1.5em',
    fontWeight: "600",
  },

  closeIcon: {
    position: 'absolute',
    right: 0
  },

  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },
  webView:{
    display: "flex",
    [theme.breakpoints.down('sm')]: {
      display: "none"
    },
  },
  mobView:{
    display: "none",
    [theme.breakpoints.down('sm')]: {
      display: "flex"
    },
  },

}));

