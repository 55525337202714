import { makeStyles } from '@material-ui/core/styles';
import signinIcon from '../../assets/SignIn/SignIn.svg';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2.4em 0 9em 0",
    margin: '0 auto',
    width: '100%',
    backgroundColor: '#ccc',
    backgroundImage: `url(${signinIcon})`,
    backgroundPosition: "bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'contain',
    [theme.breakpoints.down('md')]: {
      padding: "2.4em 1.5em 5em 1.5em",
    },

  },

  loader: {
    width: '100%',
  },

  loaderContainer: {
    width: '100%',
    height: '100%',
    minHeight: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  citLoaderContainer: {
    width: '100%',
    height: '100%',
    minHeight: '27vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  flexContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    },
  },

  linkStyle: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.6px',
    lineHeight: '19px',
  },

  flexDiv: {
    display: 'flex',
    '& div:first-child': {
      width: '40%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    },
  },

  mainContainerWidth: {
    maxWidth: "120em",
    width: '100%'
  },
  bgWhith: {
    background: "#fff"
  },
  title: {
    color: "#1A4664",
    fontFamily: "Montserrat",
    fontSize: "22px",
    letterSpacing: '0.18px',
    lineHeight: '24px',
    fontWeight: "600",
    marginTop: 24,
    marginBottom: 25,

  },

  getStartedContainer: {
    padding: '60px 90px',
    [theme.breakpoints.down('md')]: {
      padding: '60px 16px',
    },
  },

  stepTitle: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '30px',
    fontWeight: 500,
    letterSpacing: '0.23px',
    lineHeight: '40px',
    textAlign: 'center',
    minHeight: '80px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      lineHeight: '28px',
    },
  },

  btnContainer: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  btn: {
    height: '50px',
    width: '267px',
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 600,
    textTransform: 'uppercase',
  },

  exitBtn: {
    height: '45px',
    width: 'auto',
    borderRadius: '20px',
    padding: '0 16px',
    position: 'absolute',
    right: '45px',
    top: '-23px'
  },

  step2Btn: {
    margin: '0 50px',
    [theme.breakpoints.down('md')]: {
      margin: '10px 20px',
    },
  },

  downloadBox: {
    boxSizing: 'border-box',
    width: '100%',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '12px',
    overflow: 'hidden',
    padding: '20px',
    margiBbottom: '25px',
    backgroundColor: '#EAECED',
  },

  primaryText: {
    color: theme.palette.primary.contrastText
  },


  errorMessage: {
    color: "#E30909",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 600,
    letterSpacing: "0.36px",
    padding: "0 5px"
  },


  bodyDarkText: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.5px',
    lineHeight: '20px',
  },

  text: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: '0.5px',
    lineHeight: '20px',
  },

  formHeading: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: '600',
    letterSpacing: '0.5px',
    lineHeight: '20px',
    margin: '20px 0',
    textAlign: 'center',
  },

  list: {
    '& li': {
      padding: '7px 0',
      color: 'rgba(0,0,0,0.87)',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '500',
      letterSpacing: '0.5px',
      lineHeight: '20px',
    }
  },

  paraMargin: {
    marginTop: '10px'
  },

  anchorLinkStyle: {
    fontFamily: "Montserrat",
    fontSize: '16px',
    color: '#535D6D',
    fontWeight: '600',
    letterSpacing: '0.6px',
  },
  anchorBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    boxShadow: '0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    width: '200px',
    height: '50px',
    fontSize: '16px',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    textTransform: 'uppercase',
    paddingLeft: '8px',
    paddingRight: '8px',
    textDecoration: 'none',
    borderRadius: '4px'
  },

  dataError: {
    color: 'red',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.6px',
    lineHeight: '19px',
  },


}));

