import { makeStyles } from '@material-ui/core/styles';
import signinIcon from '../../assets/SignIn/SignIn.svg';



export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#ddd',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${signinIcon})`,
    backgroundPosition: "bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'contain',
  },

  wrapper: {
    maxWidth: '58.8em',
    padding: '3.9em 4.9em',
    marginTop: '4.5em',
    marginBottom: '4.5em',
    backgroundColor: '#fff',
    borderRadius: 12,
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '20px',
      margin: '50px 15px'
    },
  },

  title: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: '3.6em',
    fontWeight: "600",
    letterSpacing: "0.5px",
    marginBottom: 33,
    marginTop: 0,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.6em',
    },
  },

  inputContainer: {
    marginBottom: 20,
  },

  inputStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: '600',
  },

  hintText: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    letterSpacing: '0.4px',
    marginBottom: 20,
    marginTop: 0,
  },

  btnContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 100,
    [theme.breakpoints.down('md')]: {
      marginTop: 20,
      marginBottom: 10,
    },
  },

  sendBtn: {
    fontWeight: '600',
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    display: 'block',
    minWidth: '140px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  resendBtnText: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: '0.9em',
    fontWeight: '600',
    textDecoration: 'underline',
    border: 'none',
    backgroundColor: '#fff',
    outline: 'none',
    cursor: "pointer",
  },

  expireText: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: 'bold',
    minWidth: 140,
  },

  timeText: {
    color: "#7EB1CC",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: 'bold',
    minWidth: 140,
  },

  timerContainer: {
    width: 300,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '20px auto',
  },

  circle: {
    width: '120px',
    height: '120px',
  },

  errorMsg: {
    fontSize: '1.4em',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
    fontFamily: 'Montserrat',
    color: "red",
  },

  timerBox: {
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  timerText: {
    color: '#7EB1CC',
    fontFamily: 'Montserrat',
    fontSize: '32px',
    fontWeight: '600',
    letterSpacing: '0',
    textAlign: 'center',
    lineHeight: '24px'
  },

  timerSmallText: {
    fontSize: '16px',
    margin: '0'
  },
 
  sendBtn: {
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: '600',
    marginBottom: 20,
    color: '#535D6D !important',
    border: 'solid 2px',
    borderColor: theme.palette.primary.main,
    boxShadow: "0px 4px 8px 0 rgba(0,0,0,0.14)",
    height: 50,
  },
   
  submitBtn: {
    marginBottom: 20,
    fontSize: 16,
    fontWeight: '600',
  },
}));

