import { makeStyles } from '@material-ui/core/styles';
import signinIcon from '../../assets/SignIn/SignIn.svg';

export const useStyles = makeStyles((theme) => (
  {
    root: {
      width: '100%',
      minHeight: '100vh',
      backgroundColor: '#ccc',
      display: 'flex',
      justifyContent: 'center',
      backgroundImage: `url(${signinIcon})`,
      backgroundPosition: "bottom left",
      backgroundRepeat: "no-repeat",
      backgroundSize: 'contain',
    },
    container: {
      width: '90%',
      minWidth: "33.3em",
      marginTop: '0.8em',
      marginBottom: '9em',
      maxHeight:'90%',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '5em',
      },
    },

    formContainer: {
      padding: '1.4em 1em',
      backgroundColor: '#fff',
      borderRadius: 12,
      boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      '&.scroll':{
        [theme.breakpoints.up('sm')]: {
          overflowX: 'auto',
           maxHeight: '100vh',
        }
      }
    },

    detailsContainer: {
      paddingTop: '1.5em',
      paddingRight: '1.5em',
      paddingLeft: '2.5em',
      paddingBottom: '3.4em',
      marginLeft: "3em",
      backgroundColor: '#fff',
      borderRadius: 12,
      boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        marginTop: "3em",
        boxShadow: "none"
      },
    },

    title: {
      fontSize: '22px',
      fontWeight: '700',
      fontFamily: 'Montserrat',
      color: "#1A4664",
      letterSpacing: "0.63px",
      lineHeight: "29px", 
      paddingBottom: '10px',
      marginTop: 24
    },
    textBold: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "600",
      letterSpacing: "0.36px",
      lineHeight: "19px",
    },
    shortMessage: {
      color: "#B3B8C0",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "500",
      letterSpacing: "0.36px",
      lineHeight: "19px",
    },
    unread: {
      backgroundColor: "#EFEFEF"
    },
    alignRight: {
      textAlign: "right"
    },
    borderBottom: {
      borderBottom: "1px solid darkgrey"
    },
    bodyTitle: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "24px",
      fontWeight: "700",
      letterSpacing: "0.63px",
      lineHeight: "29px",
    },
    bodyText: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "16px",
      letterSpacing: "0.36px",
      lineHeight: "21px",
    },
    closeBtn: {
      fontSize: '25px',
      display: "inline-block",
      textAlign: "center",
      color: "#535D6D",
      cursor: "pointer",
    },

    link: {
      cursor: "pointer"
    },
    active: {
      color: theme.palette.primary.contrastText,
    },
    activeRow: {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
      boxShadow: "0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)"
    },

    shortText: {
      whiteSpace: "nowrap",
      maxWidth: "80%",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    prevBtn: {
      marginRight: "60px",
      cursor: "pointer"
    },
    nextBtn: {
      marginLeft: "60px",
      cursor: "pointer"
    },

    showInMobile: {
      display: "none",
      [theme.breakpoints.down('sm')]: {
        display: "block"
      },
    },

    inActiveMobile: {
      display: "none",
    },
    hideInMobile: {
      [theme.breakpoints.down('sm')]: {
        display: "none"
      },
    },

    nextPrevArrow: {
      color: '#535D6D',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      letterSpacing: '0.6px',
      textDecoration: 'underline'
    },

    secondaryColorCode: {
      color: theme.palette.secondary.main,
    },

  }
));