import React, { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useStyles } from './styles';
import { getPrivacySecurityContent } from '../../actions'
import { scrollTop } from '../../helpers';

export const EmailTextPolicy = React.memo(() => {
  const classes = useStyles();
  const [data, setData] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    scrollTop()
    getData();
  }, []);

  const getData = async () => {
    const res = await dispatch(getPrivacySecurityContent('PC030'));
    if (res && res.lstPageContentDetails) {
      setData(res.lstPageContentDetails[0].contentValue);
    } else {
      setData('');
    }
    scrollTop();
  }

  if (data) {
    return (
      <Box className={classes.rootBg}>
        <Box className={classes.root}>
          <div dangerouslySetInnerHTML={{ __html: data }} />
        </Box>
      </Box>

    )
  } else {
    return (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100vh'
        }}
      >
        <CircularProgress />
      </Box>
    )
  }
});
