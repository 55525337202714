import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  tile: {
    backgroundColor: theme.palette.secondary.main
  },


  btn: {
    height: 40,
    width: 160,
    borderRadius: 4,
    backgroundColor: theme.palette.secondary.main,
    fontSize: "1.6em",
    fontFamily: "Montserrat",
    color: theme.palette.secondary.contrastText,
    fontWeight: '600',
    cursor: "pointer",
    outline: "none",
    textAlign: "center",
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: '#FFF',
    }
  },

  whoWeAre: {
    backgroundColor: theme.header.backgrounColor,
  },

  prevBtn: {
    display: "block",
    left: '0',
    zIndex: '1',
    height: '100%',
    width: '88px',
    position: 'absolute',
    top: '0',
    cursor: 'pointer',
    marginLeft: '35px',
    [theme.breakpoints.down('xs')]: {
        width: '60px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
    },
},

nextBtn: {
    display: "block",
    right: '0',
    zIndex: '1',
    height: '100%',
    width: '88px',
    position: 'absolute',
    top: '0',
    cursor: 'pointer',
    marginRight: '35px',
    [theme.breakpoints.down('xs')]: {
        width: '60px',
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: '10px',
    },
},

prevArrow: {
  right: 'left',
  zIndex: '999',
  color: '#fff',
  fontWeight: '800',
  color: '#fff',
  opacity: '1',
  top: '50%',
  left: '50%',
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  fontSize: '95px'
},

nextArrow: {
  right: '25px',
  zIndex: '999',
  color: '#fff',
  fontWeight: '800',
  color: '#fff',
  opacity: '1',
  top: '50%',
  left: '50%',
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  fontSize: '95px',
}

}));


