import React from 'react';
import { useStyles } from './styles';
import { Box, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { useHistory } from 'react-router-dom';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { TRANSFER_ALERT_POPUP_HEADING, TRANSFER_ALERT_POPUP_SUB_HEADING, TRANSFER_ALERT_POPUP_SUB_SUB_HEADING, TO_PRIVATE_LABEL_URL, TO_PRIVATE_LABEL_PHONE } from '../../helpers/constants';

export const TransferAlert = (props) => {
  const { fromPrivateLabel, toPrivateLabel, toPrivateLabelPhone,
    toPrivateLabelURL
  } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={props?.handleClose} open={open}>
      <Box className={classes.root}>
        {props?.show &&
          <IconButton onClick={props?.handleClose} className={classes.closeIcon}>
            <CancelIcon />
          </IconButton>
        }
        <Box className={classes.cardBody}>
          <Box className={classes.statusContainer}>
          <h1 className={classes.title}>{TRANSFER_ALERT_POPUP_HEADING}</h1>
            { ((!fromPrivateLabel?.includes('BSI')) && (!toPrivateLabel?.includes('BSI'))) &&
             <>
              <p className={classes.statusTitle}>BSI Financial Services has been servicing your loan on behalf of {fromPrivateLabel}. Your loan has now been acquired by {toPrivateLabel}. You will notice a change in our correspondence. Future notices and statements will be sent directly from in the name of {toPrivateLabel}.</p>
              <p className={classes.statusSubTitle}>{TRANSFER_ALERT_POPUP_SUB_HEADING}</p>
              <p className={classes.statusTitle}>{TRANSFER_ALERT_POPUP_SUB_SUB_HEADING}</p>
              <p className={classes.statusSubTitles}> Website: <a target="_self" href={toPrivateLabelURL}>{toPrivateLabelURL}</a></p>
              <p className={classes.statusSubTitles}> Customer Care Phone Number: {toPrivateLabelPhone} </p>
             </>
            }
            { ((!fromPrivateLabel?.includes('BSI')) && (toPrivateLabel?.includes('BSI'))) &&
             <>
              <p className={classes.statusTitle}>BSI Financial Services has been servicing your loan on behalf of {fromPrivateLabel}. Your loan has now been acquired by {toPrivateLabel}. You will notice a change in our correspondence. Future notices and statements will be sent directly from in the name of {toPrivateLabel}.</p>
              <p className={classes.statusSubTitle}> {TRANSFER_ALERT_POPUP_SUB_HEADING} </p>
              <p className={classes.statusTitle}> {TRANSFER_ALERT_POPUP_SUB_SUB_HEADING}</p>
              <p className={classes.statusSubTitles}> Website: <a target="_self" href={toPrivateLabelURL}>{toPrivateLabelURL}</a></p>
              <p className={classes.statusSubTitles}> Customer Care Phone Number: {toPrivateLabelPhone} </p>
             </>
            }
            { ((fromPrivateLabel?.includes('BSI')) && (!toPrivateLabel?.includes('BSI'))) &&
             <>
              <p className={classes.statusTitle}>BSI Financial Services has been servicing your loan on behalf of the prior owner of your loan. Your loan has now been acquired by {toPrivateLabel}. You will notice a change in our correspondence. Future notices and statements will be sent directly from in the name of {toPrivateLabel}.</p>
              <p className={classes.statusSubTitle}> {TRANSFER_ALERT_POPUP_SUB_HEADING} </p>
              <p className={classes.statusTitle}> {TRANSFER_ALERT_POPUP_SUB_SUB_HEADING}</p>
              <p className={classes.statusSubTitles}> Website: <a target="_self" href={toPrivateLabelURL}>{toPrivateLabelURL}</a></p>
              <p className={classes.statusSubTitles}> Customer Care Phone Number: {toPrivateLabelPhone} </p>
             </>
            }
            {props?.show &&
            <Button type='submit' className={classes.okBtn} size="medium"  variant="contained" color="primary" onClick={props?.handleClose}>
              ok
            </Button>
           }
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}