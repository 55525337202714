export const verydisappointed = <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M31 22C31.8333 22 32.5417 21.7083 33.125 21.125C33.7083 20.5417 34 19.8333 34 19C34 18.1667 33.7083 17.4583 33.125 16.875C32.5417 16.2917 31.8333 16 31 16C30.1667 16 29.4583 16.2917 28.875 16.875C28.2917 17.4583 28 18.1667 28 19C28 19.8333 28.2917 20.5417 28.875 21.125C29.4583 21.7083 30.1667 22 31 22ZM17 22C17.8333 22 18.5417 21.7083 19.125 21.125C19.7083 20.5417 20 19.8333 20 19C20 18.1667 19.7083 17.4583 19.125 16.875C18.5417 16.2917 17.8333 16 17 16C16.1667 16 15.4583 16.2917 14.875 16.875C14.2917 17.4583 14 18.1667 14 19C14 19.8333 14.2917 20.5417 14.875 21.125C15.4583 21.7083 16.1667 22 17 22ZM24 27C21.7333 27 19.675 27.6417 17.825 28.925C15.975 30.2083 14.6333 31.9 13.8 34H34.2C33.3667 31.9 32.025 30.2083 30.175 28.925C28.325 27.6417 26.2667 27 24 27ZM24 44C21.2333 44 18.6333 43.475 16.2 42.425C13.7667 41.375 11.65 39.95 9.85 38.15C8.05 36.35 6.625 34.2333 5.575 31.8C4.525 29.3667 4 26.7667 4 24C4 21.2333 4.525 18.6333 5.575 16.2C6.625 13.7667 8.05 11.65 9.85 9.85C11.65 8.05 13.7667 6.625 16.2 5.575C18.6333 4.525 21.2333 4 24 4C26.7667 4 29.3667 4.525 31.8 5.575C34.2333 6.625 36.35 8.05 38.15 9.85C39.95 11.65 41.375 13.7667 42.425 16.2C43.475 18.6333 44 21.2333 44 24C44 26.7667 43.475 29.3667 42.425 31.8C41.375 34.2333 39.95 36.35 38.15 38.15C36.35 39.95 34.2333 41.375 31.8 42.425C29.3667 43.475 26.7667 44 24 44Z" fill="#D9D9D9" />
</svg>
export const verydisappointedfill = <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <path d="M31 22C31.8333 22 32.5417 21.7083 33.125 21.125C33.7083 20.5417 34 19.8333 34 19C34 18.1667 33.7083 17.4583 33.125 16.875C32.5417 16.2917 31.8333 16 31 16C30.1667 16 29.4583 16.2917 28.875 16.875C28.2917 17.4583 28 18.1667 28 19C28 19.8333 28.2917 20.5417 28.875 21.125C29.4583 21.7083 30.1667 22 31 22ZM17 22C17.8333 22 18.5417 21.7083 19.125 21.125C19.7083 20.5417 20 19.8333 20 19C20 18.1667 19.7083 17.4583 19.125 16.875C18.5417 16.2917 17.8333 16 17 16C16.1667 16 15.4583 16.2917 14.875 16.875C14.2917 17.4583 14 18.1667 14 19C14 19.8333 14.2917 20.5417 14.875 21.125C15.4583 21.7083 16.1667 22 17 22ZM24 27C21.7333 27 19.675 27.6417 17.825 28.925C15.975 30.2083 14.6333 31.9 13.8 34H34.2C33.3667 31.9 32.025 30.2083 30.175 28.925C28.325 27.6417 26.2667 27 24 27ZM24 44C21.2333 44 18.6333 43.475 16.2 42.425C13.7667 41.375 11.65 39.95 9.85 38.15C8.05 36.35 6.625 34.2333 5.575 31.8C4.525 29.3667 4 26.7667 4 24C4 21.2333 4.525 18.6333 5.575 16.2C6.625 13.7667 8.05 11.65 9.85 9.85C11.65 8.05 13.7667 6.625 16.2 5.575C18.6333 4.525 21.2333 4 24 4C26.7667 4 29.3667 4.525 31.8 5.575C34.2333 6.625 36.35 8.05 38.15 9.85C39.95 11.65 41.375 13.7667 42.425 16.2C43.475 18.6333 44 21.2333 44 24C44 26.7667 43.475 29.3667 42.425 31.8C41.375 34.2333 39.95 36.35 38.15 38.15C36.35 39.95 34.2333 41.375 31.8 42.425C29.3667 43.475 26.7667 44 24 44Z" fill="#FF6B6B" />
</svg>
export const disappointed = <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M31 22C31.8333 22 32.5417 21.7083 33.125 21.125C33.7083 20.5417 34 19.8333 34 19C34 18.1667 33.7083 17.4583 33.125 16.875C32.5417 16.2917 31.8333 16 31 16C30.1667 16 29.4583 16.2917 28.875 16.875C28.2917 17.4583 28 18.1667 28 19C28 19.8333 28.2917 20.5417 28.875 21.125C29.4583 21.7083 30.1667 22 31 22ZM17 22C17.8333 22 18.5417 21.7083 19.125 21.125C19.7083 20.5417 20 19.8333 20 19C20 18.1667 19.7083 17.4583 19.125 16.875C18.5417 16.2917 17.8333 16 17 16C16.1667 16 15.4583 16.2917 14.875 16.875C14.2917 17.4583 14 18.1667 14 19C14 19.8333 14.2917 20.5417 14.875 21.125C15.4583 21.7083 16.1667 22 17 22ZM24 27C21.7333 27 19.675 27.6417 17.825 28.925C15.975 30.2083 14.6333 31.9 13.8 34H17.1C17.8333 32.7667 18.8083 31.7917 20.025 31.075C21.2417 30.3583 22.5667 30 24 30C25.4333 30 26.7583 30.3583 27.975 31.075C29.1917 31.7917 30.1667 32.7667 30.9 34H34.2C33.3667 31.9 32.025 30.2083 30.175 28.925C28.325 27.6417 26.2667 27 24 27ZM24 44C21.2333 44 18.6333 43.475 16.2 42.425C13.7667 41.375 11.65 39.95 9.85 38.15C8.05 36.35 6.625 34.2333 5.575 31.8C4.525 29.3667 4 26.7667 4 24C4 21.2333 4.525 18.6333 5.575 16.2C6.625 13.7667 8.05 11.65 9.85 9.85C11.65 8.05 13.7667 6.625 16.2 5.575C18.6333 4.525 21.2333 4 24 4C26.7667 4 29.3667 4.525 31.8 5.575C34.2333 6.625 36.35 8.05 38.15 9.85C39.95 11.65 41.375 13.7667 42.425 16.2C43.475 18.6333 44 21.2333 44 24C44 26.7667 43.475 29.3667 42.425 31.8C41.375 34.2333 39.95 36.35 38.15 38.15C36.35 39.95 34.2333 41.375 31.8 42.425C29.3667 43.475 26.7667 44 24 44Z" fill="#D9D9D9" />
</svg>
export const disappointedfill = <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <path d="M31 22C31.8333 22 32.5417 21.7083 33.125 21.125C33.7083 20.5417 34 19.8333 34 19C34 18.1667 33.7083 17.4583 33.125 16.875C32.5417 16.2917 31.8333 16 31 16C30.1667 16 29.4583 16.2917 28.875 16.875C28.2917 17.4583 28 18.1667 28 19C28 19.8333 28.2917 20.5417 28.875 21.125C29.4583 21.7083 30.1667 22 31 22ZM17 22C17.8333 22 18.5417 21.7083 19.125 21.125C19.7083 20.5417 20 19.8333 20 19C20 18.1667 19.7083 17.4583 19.125 16.875C18.5417 16.2917 17.8333 16 17 16C16.1667 16 15.4583 16.2917 14.875 16.875C14.2917 17.4583 14 18.1667 14 19C14 19.8333 14.2917 20.5417 14.875 21.125C15.4583 21.7083 16.1667 22 17 22ZM24 27C21.7333 27 19.675 27.6417 17.825 28.925C15.975 30.2083 14.6333 31.9 13.8 34H17.1C17.8333 32.7667 18.8083 31.7917 20.025 31.075C21.2417 30.3583 22.5667 30 24 30C25.4333 30 26.7583 30.3583 27.975 31.075C29.1917 31.7917 30.1667 32.7667 30.9 34H34.2C33.3667 31.9 32.025 30.2083 30.175 28.925C28.325 27.6417 26.2667 27 24 27ZM24 44C21.2333 44 18.6333 43.475 16.2 42.425C13.7667 41.375 11.65 39.95 9.85 38.15C8.05 36.35 6.625 34.2333 5.575 31.8C4.525 29.3667 4 26.7667 4 24C4 21.2333 4.525 18.6333 5.575 16.2C6.625 13.7667 8.05 11.65 9.85 9.85C11.65 8.05 13.7667 6.625 16.2 5.575C18.6333 4.525 21.2333 4 24 4C26.7667 4 29.3667 4.525 31.8 5.575C34.2333 6.625 36.35 8.05 38.15 9.85C39.95 11.65 41.375 13.7667 42.425 16.2C43.475 18.6333 44 21.2333 44 24C44 26.7667 43.475 29.3667 42.425 31.8C41.375 34.2333 39.95 36.35 38.15 38.15C36.35 39.95 34.2333 41.375 31.8 42.425C29.3667 43.475 26.7667 44 24 44Z" fill="#FB8A22" />
</svg>
export const neutral = <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M31 22C31.8333 22 32.5417 21.7083 33.125 21.125C33.7083 20.5417 34 19.8333 34 19C34 18.1667 33.7083 17.4583 33.125 16.875C32.5417 16.2917 31.8333 16 31 16C30.1667 16 29.4583 16.2917 28.875 16.875C28.2917 17.4583 28 18.1667 28 19C28 19.8333 28.2917 20.5417 28.875 21.125C29.4583 21.7083 30.1667 22 31 22ZM17 22C17.8333 22 18.5417 21.7083 19.125 21.125C19.7083 20.5417 20 19.8333 20 19C20 18.1667 19.7083 17.4583 19.125 16.875C18.5417 16.2917 17.8333 16 17 16C16.1667 16 15.4583 16.2917 14.875 16.875C14.2917 17.4583 14 18.1667 14 19C14 19.8333 14.2917 20.5417 14.875 21.125C15.4583 21.7083 16.1667 22 17 22ZM18 31H30V28H18V31ZM24 44C21.2333 44 18.6333 43.475 16.2 42.425C13.7667 41.375 11.65 39.95 9.85 38.15C8.05 36.35 6.625 34.2333 5.575 31.8C4.525 29.3667 4 26.7667 4 24C4 21.2333 4.525 18.6333 5.575 16.2C6.625 13.7667 8.05 11.65 9.85 9.85C11.65 8.05 13.7667 6.625 16.2 5.575C18.6333 4.525 21.2333 4 24 4C26.7667 4 29.3667 4.525 31.8 5.575C34.2333 6.625 36.35 8.05 38.15 9.85C39.95 11.65 41.375 13.7667 42.425 16.2C43.475 18.6333 44 21.2333 44 24C44 26.7667 43.475 29.3667 42.425 31.8C41.375 34.2333 39.95 36.35 38.15 38.15C36.35 39.95 34.2333 41.375 31.8 42.425C29.3667 43.475 26.7667 44 24 44Z" fill="#D9D9D9" />
</svg>
export const neutralfill = <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <path d="M31 22C31.8333 22 32.5417 21.7083 33.125 21.125C33.7083 20.5417 34 19.8333 34 19C34 18.1667 33.7083 17.4583 33.125 16.875C32.5417 16.2917 31.8333 16 31 16C30.1667 16 29.4583 16.2917 28.875 16.875C28.2917 17.4583 28 18.1667 28 19C28 19.8333 28.2917 20.5417 28.875 21.125C29.4583 21.7083 30.1667 22 31 22ZM17 22C17.8333 22 18.5417 21.7083 19.125 21.125C19.7083 20.5417 20 19.8333 20 19C20 18.1667 19.7083 17.4583 19.125 16.875C18.5417 16.2917 17.8333 16 17 16C16.1667 16 15.4583 16.2917 14.875 16.875C14.2917 17.4583 14 18.1667 14 19C14 19.8333 14.2917 20.5417 14.875 21.125C15.4583 21.7083 16.1667 22 17 22ZM18 31H30V28H18V31ZM24 44C21.2333 44 18.6333 43.475 16.2 42.425C13.7667 41.375 11.65 39.95 9.85 38.15C8.05 36.35 6.625 34.2333 5.575 31.8C4.525 29.3667 4 26.7667 4 24C4 21.2333 4.525 18.6333 5.575 16.2C6.625 13.7667 8.05 11.65 9.85 9.85C11.65 8.05 13.7667 6.625 16.2 5.575C18.6333 4.525 21.2333 4 24 4C26.7667 4 29.3667 4.525 31.8 5.575C34.2333 6.625 36.35 8.05 38.15 9.85C39.95 11.65 41.375 13.7667 42.425 16.2C43.475 18.6333 44 21.2333 44 24C44 26.7667 43.475 29.3667 42.425 31.8C41.375 34.2333 39.95 36.35 38.15 38.15C36.35 39.95 34.2333 41.375 31.8 42.425C29.3667 43.475 26.7667 44 24 44Z" fill="#FFD63D" />
</svg>
export const satisfied = <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M31 22C31.8333 22 32.5417 21.7083 33.125 21.125C33.7083 20.5417 34 19.8333 34 19C34 18.1667 33.7083 17.4583 33.125 16.875C32.5417 16.2917 31.8333 16 31 16C30.1667 16 29.4583 16.2917 28.875 16.875C28.2917 17.4583 28 18.1667 28 19C28 19.8333 28.2917 20.5417 28.875 21.125C29.4583 21.7083 30.1667 22 31 22ZM17 22C17.8333 22 18.5417 21.7083 19.125 21.125C19.7083 20.5417 20 19.8333 20 19C20 18.1667 19.7083 17.4583 19.125 16.875C18.5417 16.2917 17.8333 16 17 16C16.1667 16 15.4583 16.2917 14.875 16.875C14.2917 17.4583 14 18.1667 14 19C14 19.8333 14.2917 20.5417 14.875 21.125C15.4583 21.7083 16.1667 22 17 22ZM24 35C26.2667 35 28.325 34.3583 30.175 33.075C32.025 31.7917 33.3667 30.1 34.2 28H30.9C30.1667 29.2333 29.1917 30.2083 27.975 30.925C26.7583 31.6417 25.4333 32 24 32C22.5667 32 21.2417 31.6417 20.025 30.925C18.8083 30.2083 17.8333 29.2333 17.1 28H13.8C14.6333 30.1 15.975 31.7917 17.825 33.075C19.675 34.3583 21.7333 35 24 35ZM24 44C21.2333 44 18.6333 43.475 16.2 42.425C13.7667 41.375 11.65 39.95 9.85 38.15C8.05 36.35 6.625 34.2333 5.575 31.8C4.525 29.3667 4 26.7667 4 24C4 21.2333 4.525 18.6333 5.575 16.2C6.625 13.7667 8.05 11.65 9.85 9.85C11.65 8.05 13.7667 6.625 16.2 5.575C18.6333 4.525 21.2333 4 24 4C26.7667 4 29.3667 4.525 31.8 5.575C34.2333 6.625 36.35 8.05 38.15 9.85C39.95 11.65 41.375 13.7667 42.425 16.2C43.475 18.6333 44 21.2333 44 24C44 26.7667 43.475 29.3667 42.425 31.8C41.375 34.2333 39.95 36.35 38.15 38.15C36.35 39.95 34.2333 41.375 31.8 42.425C29.3667 43.475 26.7667 44 24 44Z" fill="#D9D9D9" />
</svg>
export const satisfiedfill = <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <path d="M31 22C31.8333 22 32.5417 21.7083 33.125 21.125C33.7083 20.5417 34 19.8333 34 19C34 18.1667 33.7083 17.4583 33.125 16.875C32.5417 16.2917 31.8333 16 31 16C30.1667 16 29.4583 16.2917 28.875 16.875C28.2917 17.4583 28 18.1667 28 19C28 19.8333 28.2917 20.5417 28.875 21.125C29.4583 21.7083 30.1667 22 31 22ZM17 22C17.8333 22 18.5417 21.7083 19.125 21.125C19.7083 20.5417 20 19.8333 20 19C20 18.1667 19.7083 17.4583 19.125 16.875C18.5417 16.2917 17.8333 16 17 16C16.1667 16 15.4583 16.2917 14.875 16.875C14.2917 17.4583 14 18.1667 14 19C14 19.8333 14.2917 20.5417 14.875 21.125C15.4583 21.7083 16.1667 22 17 22ZM24 35C26.2667 35 28.325 34.3583 30.175 33.075C32.025 31.7917 33.3667 30.1 34.2 28H30.9C30.1667 29.2333 29.1917 30.2083 27.975 30.925C26.7583 31.6417 25.4333 32 24 32C22.5667 32 21.2417 31.6417 20.025 30.925C18.8083 30.2083 17.8333 29.2333 17.1 28H13.8C14.6333 30.1 15.975 31.7917 17.825 33.075C19.675 34.3583 21.7333 35 24 35ZM24 44C21.2333 44 18.6333 43.475 16.2 42.425C13.7667 41.375 11.65 39.95 9.85 38.15C8.05 36.35 6.625 34.2333 5.575 31.8C4.525 29.3667 4 26.7667 4 24C4 21.2333 4.525 18.6333 5.575 16.2C6.625 13.7667 8.05 11.65 9.85 9.85C11.65 8.05 13.7667 6.625 16.2 5.575C18.6333 4.525 21.2333 4 24 4C26.7667 4 29.3667 4.525 31.8 5.575C34.2333 6.625 36.35 8.05 38.15 9.85C39.95 11.65 41.375 13.7667 42.425 16.2C43.475 18.6333 44 21.2333 44 24C44 26.7667 43.475 29.3667 42.425 31.8C41.375 34.2333 39.95 36.35 38.15 38.15C36.35 39.95 34.2333 41.375 31.8 42.425C29.3667 43.475 26.7667 44 24 44Z" fill="#7FE517" />
</svg>
export const verysatisfied = <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_19645_8723)">
        <path d="M23.98 4C12.94 4 4 12.96 4 24C4 35.04 12.94 44 23.98 44C35.04 44 44 35.04 44 24C44 12.96 35.04 4 23.98 4ZM17 16C18.66 16 20 17.34 20 19C20 20.66 18.66 22 17 22C15.34 22 14 20.66 14 19C14 17.34 15.34 16 17 16ZM24 36C19.44 36 15.56 32.68 14 28H34C32.44 32.68 28.56 36 24 36ZM31 22C29.34 22 28 20.66 28 19C28 17.34 29.34 16 31 16C32.66 16 34 17.34 34 19C34 20.66 32.66 22 31 22Z" fill="#D9D9D9" />
    </g>
    <defs>
        <clipPath id="clip0_19645_8723">
            <rect width="48" height="48" fill="white" />
        </clipPath>
    </defs>
</svg>
export const verysatisfiedfill = <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <g clip-path="url(#clip0_20221_11113)">
        <path d="M23.98 4C12.94 4 4 12.96 4 24C4 35.04 12.94 44 23.98 44C35.04 44 44 35.04 44 24C44 12.96 35.04 4 23.98 4ZM17 16C18.66 16 20 17.34 20 19C20 20.66 18.66 22 17 22C15.34 22 14 20.66 14 19C14 17.34 15.34 16 17 16ZM24 36C19.44 36 15.56 32.68 14 28H34C32.44 32.68 28.56 36 24 36ZM31 22C29.34 22 28 20.66 28 19C28 17.34 29.34 16 31 16C32.66 16 34 17.34 34 19C34 20.66 32.66 22 31 22Z" fill="#01D301" />
    </g>
    <defs>
        <clipPath id="clip0_20221_11113">
            <rect width="48" height="48" fill="white" />
        </clipPath>
    </defs>
</svg>

export const checkmark = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.667 21.866L8.40033 15.5993L10.267 13.7327L14.667 18.1327L25.867 6.93268C23.3337 4.39935 19.867 2.66602 16.0003 2.66602C8.66699 2.66602 2.66699 8.66602 2.66699 15.9993C2.66699 23.3327 8.66699 29.3327 16.0003 29.3327C23.3337 29.3327 29.3337 23.3327 29.3337 15.9993C29.3337 13.466 28.667 11.1993 27.467 9.19935L14.667 21.866Z" fill="#3EF450"/>
</svg>
