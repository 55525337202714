import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
    rootSlider: {
        padding: '2.5em 0 9em 0'
    },
    slickContainer: {
        background: '#E8E8E8', 
        padding: '25px 0',
        height: '240px',
        '& .slick-active': {
            outline: 'none'
        },
        [theme.breakpoints.down('sm')]: {
            height: '160px',
            '& .slick-list .MuiPaper-rounded': {
                width: 'auto'
            },
        },
    },
    prevBtn: {
        display: "block",
        background: 'rgba(0,0,0,0.35)',
        left: '0',
        zIndex: '1',
        height: '100%',
        width: '88px',
        position: 'absolute',
        top: '0',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            width: '60px',
        },
    },
    nextBtn: {
        display: "block",
        background: 'rgba(0,0,0,0.35)',
        right: '0',
        zIndex: '1',
        height: '100%',
        width: '88px',
        position: 'absolute',
        top: '0',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            width: '60px',
        },
    },
    mainHeading: {
        padding: '0 3.1em 0.7em',
        fontWeight: 'bold',
        fontSize: '24px',
        fontFamily: 'Montserrat',
        color: '#727272',
        letterSpacing: '0.63px',
        [theme.breakpoints.down('sm')]: {
            padding: '1em 1.4em 0.5em 1.4em',
        },
    },
    prevArrow: {
        right: 'left',
        zIndex: '999',
        color: '#fff',
        fontWeight: '800',
        color: '#fff',
        opacity: '1',
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        fontSize: '95px'
    },
    nextArrow: {
        right: '25px',
        zIndex: '999',
        color: '#fff',
        fontWeight: '800',
        color: '#fff',
        opacity: '1',
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        fontSize: '95px'
    }
}));