import { FETCH_ACCOUNTDATA } from '../types';

export default (state = {}, action) => {

  switch (action.type) {
    case FETCH_ACCOUNTDATA:
      return action.payload
    default:
      return state;
  }
}

