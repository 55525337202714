import Cookies from 'js-cookie';
import { axiosProtected } from '../config';
import { FETCH_MESSAGE } from '../types';
import { getAppSource, getUserType } from '../helpers';

export const getNotifications = () => async dispatch => {
  const data = {
    "isBellRequest": true,
    "userName": "",
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType()
  }

  try {
    const messages = await axiosProtected.post('/User/GetNotifications', data);
    if (messages && messages.data) {
      dispatch({
        type: FETCH_MESSAGE,
        payload: { 'data' : messages.data.notifications, 'count' : messages.data.unreadCount }
        
      });
      return  { 'data' : messages.data.notifications, 'count' : messages.data.unreadCount }
    }
    return null;
  } catch (e) {
    return null;
  }
}

export const getAllNotifications = async (data) => {
  try {
    const res = await axiosProtected.post('/User/GetNotifications', data);
    return res.data
  } catch (e) {
    return null
  }
}

export const updateNotification = async (data) => {
  try {
    const res = await axiosProtected.post('/User/UpdateNotification', data);
    return res.data
  } catch (e) {
    return null
  }
}