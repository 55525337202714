import React from 'react';
import { useStyles } from './styles';
import { Box, Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import { insertActivityLog } from '../../actions';
import Cookies from 'js-cookie';
import { getUserType, getAppSource, isAdminCheck } from '../../helpers';
import { SAVE_TIME_POPUP_HEADING, BASIC_INFO_POPUP_SUB_HEADING, DISCLAIMER } from '../../helpers/constants';

export const CoveredBannerPopup = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { buttonURL, coveredURL, bannerName } = props.data;
 
  const handleClick = (url, type) => {
    window.open(url, '_blank')
    props.handleCancelIcon()
    if ((type == 'manually') && (props.page == 'home')) {
       const remark = `User clicked on FILL MANUALLY button on popup for ${(bannerName.toLowerCase() == 'matic') ? 'matic' : 'covered'} tile on home page`
       handleActivity(remark)
    } else if ((type == 'manually') && (props.page !== 'home')) {
      const remark = `User clicked on FILL MANUALLY button on popup for ${(bannerName.toLowerCase() == 'matic') ? 'matic' : 'covered'} tile on taxes and insurance page`
      handleActivity(remark)
    } else if ((type == 'prefill') && (props.page == 'home')) {
      const remark = `User clicked on PROCEED button on popup for ${(bannerName.toLowerCase() == 'matic') ? 'matic' : 'covered'} tile on home page`
      handleActivity(remark)
   } else if ((type == 'prefill') && (props.page !== 'home')) {
     const remark = `User clicked on PROCEED button on popup for ${(bannerName.toLowerCase() == 'matic') ? 'matic' : 'covered'} tile on taxes and insurance page`
     handleActivity(remark)
   }
  }
  
  const handleActivity = async (activityRemark) => {
    const data = {
      "accountNumber":  Cookies.get("selectedAccount"),
      "activityCode": "UAL202",
      "activityStatus": "Success",
      "action": 'CoveredTile',
      "controller": "Dashboard",
      "apiName": "GetAccountInfo",
      "activityRemark": activityRemark,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertActivityLog(data);
  }

  const handleClose = () => {
    props.handleCancelIcon();
    handleActivity(props?.page == 'home' ? `User clicked on CROSS button on popup for ${(bannerName.toLowerCase() == 'matic') ? 'matic' : 'covered'} tile on home page` : `User clicked on CROSS button on popup for ${(bannerName.toLowerCase() == 'matic') ? 'matic' : 'covered'} tile on taxes and insurance page`)
  }

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={props.handleCancelIcon} open={props.open}>
     
      <Box className={classes.root}>
        <IconButton className={classes.closeIcon} onClick={() => handleClose()}>
            <CancelIcon />
        </IconButton>
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>{SAVE_TIME_POPUP_HEADING}</Typography>
        </Box>
        
        <Box className={classes.cardBody2}>
          <Box className={classes.statusContainer}>
           <Box className={classes.cardContainer}>
            <Box className={classes.imageContainer}>
             <Typography className={classes.paragraph}>{BASIC_INFO_POPUP_SUB_HEADING}</Typography>
             </Box>
            </Box> 
          </Box>
        </Box>

        <Box className={classes.cardFooter}>
         <Box className={classes.footerItem}>
            <Button
              variant='outlined'
              className={classes.btn}
              onClick={() => handleClick(coveredURL, 'manually')}
              disabled={isAdminCheck()}
            > FILL MANUALLY</Button>
          </Box>

          <Box className={classes.footerItem}>
              <Button
                variant='contained'
                color='primary'
                className={classes.btn}
                onClick={() => handleClick(buttonURL, 'prefill')}
                disabled={isAdminCheck()}
              >PROCEED & PREFILL</Button>
          </Box> 
        </Box>
        <Typography className={classes.disclaimer}>({DISCLAIMER})</Typography>

      </Box> 
    </Dialog>
  )
}
