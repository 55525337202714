import { Drawer, Button, Paper, Box, Grid, Typography, Checkbox, Radio, RadioGroup, FormControlLabel, FormLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { styled } from '@material-ui/styles';
import { useStyles } from './styles';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { propTypes } from 'react-client-captcha';
import moment from 'moment';


const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  // padding: theme.spacing(1),
  textAlign: 'center',
  // color: theme.palette.text.secondary,
}));

const FilterDrawer=({filterValuesApplied,clearAllFilter,applyFilter,closeDrawer})=> {
  const [state, setState] = React.useState({
    right: true,
  });

  const [localFilterValues, setLocalFilterValues] = useState({ ...filterValuesApplied });
  
  // Update localFilterValues when filterValuesApplied prop changes.
  useEffect(() => {
    setLocalFilterValues({ ...filterValuesApplied });
  }, [filterValuesApplied]);

  const [requestDate, setRequestedDates] = useState()
  const [enableApplyAfterClearAll,setEnableApplyAfterClearAll] = useState(false);
  const classes = useStyles();

//   React.useEffect(()=>{
//     setLocalFilterValues(props.filterValuesApplied)
// },[props.filterValuesApplied])
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const closeD=()=>{
    setEnableApplyAfterClearAll(false) 
    closeDrawer();
   
  }
  const clearAllLocalFilter=()=>{
    setLocalFilterValues({ status: {'In Progress':false,'Completed':false,'Clarification Needed':false,'Submitted':false}, customEnd: null, customStart: null, requestedDates: { startDate: '', endDate: '', requestedPeriod: '' } })
    setEnableApplyAfterClearAll(true) 
   }
  const selectCustomDate = (e, value) => {
    setEnableApplyAfterClearAll(false) 
    let filterValue = { ...localFilterValues };
    filterValue['requestedDates'][value] = new Date(e)
    if (value == 'customStart'){
      filterValue['requestedDates']['startDate'] = new Date(e)
      filterValue['customStart'] = new Date(e);
    }
    if (value == 'customEnd'){
      filterValue['requestedDates']['endDate'] = new Date(e)
      filterValue['customEnd'] = new Date(e);
    }
    setLocalFilterValues(filterValue);
  }
   const selectCheckBox=(e,value)=>{
    setEnableApplyAfterClearAll(false);
       // props.selectCheckBox(value);
        const filterValue = { ...localFilterValues };
        filterValue['status'][value] = !filterValue['status'][value];
        setLocalFilterValues(filterValue);
   }

   const applyFilters =()=>{
    setEnableApplyAfterClearAll(false)
      applyFilter(null,localFilterValues);
   }
  
   const selectRadioButton = (e, value) => {
    // props?.selectRadioButton(val)
    let filterValue = { ...localFilterValues };
    if (value == 'today') {
      const date = new Date()
      date.setDate(date.getDate())
      filterValue['requestedDates']['startDate'] = new Date(date)//Safari fix removed toLocaleString()
      filterValue['requestedDates']['endDate'] = new Date()
      filterValue['requestedDates']['requestedPeriod'] = 'today';

    } else if (value == 'week') {
      const date = new Date()
      const yesterday = new Date();
      yesterday.setDate(date.getDate() - 1);
      date.setDate(date.getDate() - 6)
      filterValue['requestedDates']['startDate'] = new Date(date)
      filterValue['requestedDates']['endDate'] = new Date(yesterday)
      filterValue['requestedDates']['requestedPeriod'] = 'week';
    } else if (value == 'month') {
      const date = new Date();
      const yesterday = new Date();
      yesterday.setDate(date.getDate() - 1);
      date.setDate(date.getDate() - 30)
      filterValue['requestedDates']['startDate'] = new Date(date)
      filterValue['requestedDates']['endDate'] = new Date(yesterday)
      filterValue['requestedDates']['requestedPeriod'] = 'month';
    } else if (value == 'custom') {
       filterValue['requestedDates']['startDate'] = null
       filterValue['requestedDates']['endDate'] = null;
      filterValue['requestedDates']['requestedPeriod'] = 'custom';
    }
    setLocalFilterValues(filterValue);
  
    setRequestedDates(value)
   }

   const getDisableStatus=()=>{
       const obj={ status: {},customEnd:null,customStart:null, requestedDates: { startDate: '', endDate: '', requestedPeriod: '' } };
       const keys  = Object.keys(localFilterValues?.status) || [];
       const data = keys.filter((data)=>{
        return localFilterValues.status[data] === true;
       }) || [];

       if(enableApplyAfterClearAll){
          return false;
        }
       if(localFilterValues.requestedDates.requestedPeriod == 'custom'){
          if(!localFilterValues.requestedDates.startDate || !localFilterValues.requestedDates.endDate){
            return true;
          }
       }
       else if(!data.length && !localFilterValues.requestedDates.startDate && !localFilterValues.requestedDates.endDate){
        return true;
       }
       

       if(!data.length && !localFilterValues.requestedDates.requestedPeriod){
        return true;
       }

       if(localFilterValues.requestedDates.requestedPeriod ==='custom' && moment(localFilterValues.requestedDates.customStart).format('L')>moment(localFilterValues.requestedDates.customEnd).format('L')){
        return true
       }

       return false;
   }
   const getFilters=()=>{
    const keys  = Object.keys(localFilterValues?.status) || [];
    const data = keys.filter((data)=>{
     return localFilterValues.status[data] === true;
    }) || [];

    if(!data.length && !localFilterValues.requestedDates.requestedPeriod){
     return false;
    }
    else return true;
   }

    // Create a local state to manage the filtering values.
   
  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={()=>{}}
          >
            <Grid container spacing={1} style={{ padding: '25px', width: '360px' }}>
              <Grid item xs={12} sm={12} md={6}>
                <Typography className={classes.filterText}>Filters</Typography>
              </Grid>
              {getFilters() && <Grid item xs={12} sm={12} md={6}>
                <Typography className={classes.filterLink} onClick={clearAllLocalFilter}>Clear All</Typography>
              </Grid>}
              <Grid item xs={12} sm={12} md={12} style={{marginTop:'15px'}}>
                <Typography className={`${classes.filterText} ${classes.filterHeads}`}>Status</Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <Box className={classes.checkboxText}>
                  <Checkbox inputProps={{ 'aria-label': 'Checkbox' }}  onClick={(e)=>selectCheckBox(e,'Submitted')} checked={localFilterValues?.status?.Submitted}/> Submitted
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box className={classes.checkboxText}>
                  <Checkbox inputProps={{ 'aria-label': 'Checkbox' }} onClick={(e)=>selectCheckBox(e,'Completed')} checked={localFilterValues?.status?.Completed}/> Completed
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box className={classes.checkboxText}>
                  <Checkbox inputProps={{ 'aria-label': 'Checkbox' }}  onClick={(e)=>selectCheckBox(e,'In Progress')} checked={localFilterValues?.status['In Progress']}/> In Progress
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box className={classes.checkboxText}>
                  <Checkbox inputProps={{ 'aria-label': 'Checkbox' }}  onClick={(e)=>selectCheckBox(e,'Clarification Needed')} checked={localFilterValues?.status['Clarification Needed']}/> <span style={{position:'absolute',marginTop:'9px', paddingLeft:'5px'}}>Clarification Needed</span>
                </Box>
              </Grid>

            
              <Grid item xs={12} sm={12} md={12}>
                <Typography className={`${classes.filterText} ${classes.filterHeads}`}>Requested Date</Typography>
              </Grid>
              <RadioGroup style={{ flexDirection: 'row', paddingLeft: '16px' }}  value={requestDate} aria-label="digital_comm" name="digital_comm">
                <Grid item xs={12} sm={12} md={6}>
                  <Box className={classes.checkboxText}>

                    <FormControlLabel value="today" control={<Radio />} label="Today" checked={localFilterValues.requestedDates.requestedPeriod == 'today' ? true : false} onClick={(e)=>selectRadioButton(e,'today')}/>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box className={classes.checkboxText}>
                    <FormControlLabel value="week" control={<Radio />} label="Last 7 days" checked={localFilterValues.requestedDates.requestedPeriod == 'week' ? true : false} onClick={(e)=>selectRadioButton(e,'week')} />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box className={classes.checkboxText}>
                    <FormControlLabel value="month" control={<Radio />} label="Last 30 days" checked={localFilterValues.requestedDates.requestedPeriod == 'month' ? true : false} onClick={(e)=>selectRadioButton(e,'month')} />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box className={classes.checkboxText}>
                    <FormControlLabel value="custom" control={<Radio />} label="Custom"  checked={localFilterValues.requestedDates.requestedPeriod == 'custom' ? true : false} onClick={(e)=>selectRadioButton(e,'custom')}/>
                  </Box>
                </Grid>

              </RadioGroup>
              {localFilterValues.requestedDates.requestedPeriod == 'custom' &&<><Grid item xs={12} sm={12} md={6} style={{ marginTop: '10px' }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    id="date-picker-From"
                    label="Start Date"
                    emptyLabel="dd/mm/yyyy"
                    size="small"
                    value={localFilterValues.customStart}
                    maxDate={moment(new Date()).add(0, 'days').format('L')}
                    onChange={(e) => selectCustomDate(e,'customStart')}
                    autoOk
                    inputProps={{
                      className: classes.inputStyle,
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={6} style={{ marginTop: '10px' }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>

                   <DatePicker
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    id="date-picker-to"
                    label="End Date"
                    minDate={moment(localFilterValues.requestedDates.customStart).format('L')}
                    maxDate={moment(new Date()).add(0, 'days').format('L')}
                    size="small"
                    value={localFilterValues.customEnd}
                    emptyLabel="dd/mm/yyyy"
                    onChange={(e) => selectCustomDate(e,'customEnd')}
                   // disabled={!localFilterValues.requestedDates.customStart}
                    autoOk
                    inputProps={{
                      className: classes.inputStyle,
                    }}
                  /> 
                </MuiPickersUtilsProvider>
              </Grid>
              </>}
              <Grid item xs={6} sm={6} md={6} style={{ marginTop: '25px' }}>
                <Button
                  variant='outlined'
                  className={classes.btn}
                  onClick={closeD}
                > {'CANCEL'}</Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6} style={{ marginTop: '25px' }}>
                <Button
                  variant='contained'
                  disabled={getDisableStatus()}
                  color='primary'
                  className={classes.btn}
                  onClick={()=>applyFilters()}
                > {'APPLY'}</Button>
              </Grid>
            </Grid>

          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
export default FilterDrawer;