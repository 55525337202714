import React, { useEffect, useState, useRef } from 'react';
import {
  Box, Button, CircularProgress, Grid,
  TextField, Radio,
  RadioGroup, FormControlLabel, FormGroup, Checkbox
} from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getCovid19Data, saveCovidMortgageAssistance, setEditProfileComponent } from '../../actions';
import { CovidConfirmAlert, SnackBar } from '../../components';
import CustomBreadcrum from '../Breadcrum';
import { CovidCheckbox } from './CovidCheckbox';
import { CovidQnA } from './CovidQnA';
import { CovidList } from './CovidList';
import { Link, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { decrypt, encrypt } from '../../helpers/encrypt';
import { checkDigitOnly, scrollTop, validateEmail, renderDefaultMsg, getAppSource, getUserType, isAdmin } from '../../helpers'
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@material-ui/icons/Print';

import covid from '../../assets/FinancialHardship/covid.png';

export const CovidFinancialHardship = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const componentRef = useRef();
  const selectedAccount = useSelector(state => state.selectedAccount);
  const mytheme = useSelector(state => state.theme);
  const [submitStatus, setSubmitStatus] = useState(false);
  const { customerCareNumber } = mytheme;
  const [covidDataLoading, setCovidDataLoading] = useState(false);
  const [covid19Data, setCovid19Data] = useState('');
  const [covidError, setCovidError] = React.useState('');
  const [covidDataLoadError, setCovidDataLoadError] = useState('')
  const [covidConfirmAlert, setCovidConfirmAlert] = useState(false);
  const [idAddDeferementSubmit, setIdAddDeferementSubmit] = useState(false);
  const [successSnackBar, setSuccessSnackBar] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [isQAgree, setIsQAgree] = useState(false);
  const [isQAgreeError, setIsQAgreeError] = useState('');

  // validation 
  const [errors, setErrors] = React.useState({});
  const [touched, setTouched] = React.useState({});
  const [mobileError, setMobileError] = useState('');

  const [isSubmit, setIsSubmit] = useState(false);
  const [covidConfirmAlertProgress, setCovidConfirmAlertProgress] = useState(false);
  const [showCovidForm, setShowCovidForm] = useState(false);
  const [covidPostData, setCovidPostData] = useState({
    propertyAddress: '',
    primaryName: '',
    primaryAddress1: '',
    emailID: '',
    mobileNumber: '',
    renovationDate: new Date(),
    monthlyMortgagePaymentDate: new Date(),
    owner: '',
    rental: '',
    vacant: ''
  });

  const [addAssistState, setAddAssistState] = useState({
    extend_forB: '',
    extend_time: '',
    experience_hardship: '',
    default_resolved: '',
    additionalPaymentForbearance: '',
    willing_to_deliquency: '',
    afford_repayment: '',
    existing_pay: '',
    long_term: '',
  });
  const [borrowerInfo, setBorowerInfo] = useState({
    borowerName: '',
    borowerEmail: '',
    primPhone: '',
    otherPhone: '',
    primPhoneType: 'C',
    otherPhoneType: '',
  });

  const resetCovidForm = () => {
    setSuccessMsg('');
    setSuccessSnackBar(false);
    setIsQAgree(false);
    setIsQAgreeError('');
    setCovidError('');
    setErrors({});
    setTouched({});
    setMobileError('');
    scrollTop();
    setBorowerInfo({
      borowerName: '',
      borowerEmail: '',
      primPhone: '',
      otherPhone: '',
      primPhoneType: 'C',
      otherPhoneType: '',
    });
    setCovidPostData({
      propertyAddress: '',
      primaryName: '',
      primaryAddress1: '',
      emailID: '',
      mobileNumber: '',
      owner: '',
      rental: '',
      vacant: ''

    });
    setAddAssistState({
      extend_forB: '',
      extend_time: '',
      experience_hardship: '',
      default_resolved: '',
      additionalPaymentForbearance: '',
      willing_to_deliquency: '',
      afford_repayment: '',
      existing_pay: '',
      long_term: '',
    });
  }

  useEffect(() => {
    if (selectedAccount) {
      resetCovidForm();
      getCovidHardshipData();
    }
    scrollTop();
  }, [selectedAccount])

  const getCovidHardshipData = async () => {
    setCovidDataLoading(true);
    setCovidDataLoadError('');
    const response = await getCovid19Data(selectedAccount);
    if (response && response.lstResponseStatus && response.lstResponseStatus.length && response.lstResponseStatus[0].statusCode === '0') {
      setCovid19Data({
        ...response,
        isAdditionalDefermentQ7: (!response.isbtnDefermentSubmit && response.isAdditionalDefermentQ8) ? false : response.isAdditionalDefermentQ7
      });
      setAddAssistState({
        extend_forB: response.isForbearanceExtRequested,
        extend_time: response.forbearanceExtPeriod,
        experience_hardship: response.experienceHardship,
        default_resolved: response.reasonForDefaultResolve,
        additionalPaymentForbearance: response.additionalPaymentForbearance,
        willing_to_deliquency: response.willingResolveDelinquency,
        afford_repayment: response.ableToAffordRepaymentPlan,
        existing_pay: response.contractualMonthlyPayment,
        long_term: response.reasonForDefaultIs,
      });
      let covidType = '';
      if (response.isNotQualified === true) {
        covidType = 'NQ';
      } else if (response.isQualified === true) {
        covidType = 'Q';
      } else if (response.isBPLMain === true || response.isBPLDC === true) {
        covidType = 'B';
      } else if (response.isAdditionalDeferment === true) {
        covidType = 'AD';
      }
      setCovidPostData({
        ...covidPostData,
        propertyAddress: response.propertyAddress,
        primaryName: response.borrowerDetails !== null && response.borrowerDetails.primaryName !== null ? response.borrowerDetails.primaryName : '',
        mailingAddress: response.borrowerDetails !== null && response.borrowerDetails.primaryAddress1 !== null ?
          response.borrowerDetails !== null && response.borrowerDetails.primaryAddress1 : '' +
            response.borrowerDetails !== null && response.borrowerDetailsprimaryAddress2 !== null ?
            response.borrowerDetails !== null && response.borrowerDetails.primaryAddress2 : '',
        primaryAddress1: response.borrowerDetails !== null && response.borrowerDetails.primaryAddress1 !== null ?
          response.borrowerDetails !== null && response.borrowerDetails.primaryAddress1 : '',
        primaryAddress2: response.borrowerDetails !== null && response.borrowerDetailsprimaryAddress2 !== null ?
          response.borrowerDetails !== null && response.borrowerDetails.primaryAddress2 : '',
        emailID: response.borrowerDetails !== null && decrypt(response.borrowerDetails.emailID),
        mobileNumber: response.borrowerDetails !== null && (response.borrowerDetails.mobileNumber ? decrypt(response.borrowerDetails.mobileNumber) : ''),
        delinquentDays: response.delinquentDays ? response.delinquentDays : '0',
        CovidType: covidType,
        isLoanIsInFinalMonth: response.isLoanIsInFinalMonth,
        forbearanceStatus: response.forbearanceStatus,
        forbearanceStatusDate: response.forbearanceStatusDate
      })
      setCovidDataLoadError('');
    } else if (response && response.lstResponseStatus && response.lstResponseStatus.length && response.lstResponseStatus[0].statusCode !== '0') {
      setCovidDataLoadError(response.lstResponseStatus[0].statusDesc);
      setCovidDataLoading(false);
    } else {
      setCovidDataLoadError(renderDefaultMsg('400'));
      setCovidDataLoading(false);
    }
    setCovidDataLoading(false);
    scrollTop();
  };

  const isQRadioHandler = (event) => {
    if (event.target.checked === true) {
      setIsQAgree(event.target.checked);
      setIsQAgreeError('');
    } else {
      setIsQAgree(event.target.checked);
    }
  };

  const handleSnackBarClose = () => {
    setSuccessSnackBar(false);
  };

  const toggleCovidAlertBox = () => {
    setCovidConfirmAlert(!covidConfirmAlert);
    setIsSubmit(false);
    setCovidConfirmAlertProgress(false);
  }

  const textChangeHandler = (event) => {

    if ((event.target.name === 'mobileNumber') || (event.target.name === 'alternatePhoneNumber')) {
      if (checkDigitOnly(event.target.value) && event.target.value.length <= 10) {
        setCovidPostData({ ...covidPostData, [event.target.name]: event.target.value })
      }
    } else if (event.target.name === 'emailID') {
      setCovidPostData({ ...covidPostData, [event.target.name]: event.target.value.slice(0, 110) })
      setTouched({
        ...touched,
        [event.target.name]: true
      });
    } else {
      setCovidPostData({ ...covidPostData, [event.target.name]: event.target.value })
    }
  }

  const handleBlur = event => {
    const { name, value } = event.target;
    setErrors({
      ...errors,
      ...(errors && { [name]: touched[name] && (name === 'zip' ? '' : (name === 'emailID' && validateEmail(value))) })
    });
  };

  const verifyPhone = (event) => {
    if (event.target.value.length !== 0) {
      if (event.target.value.length === 10) {
        setMobileError('');
      } else {
        setMobileError('Mobile number is invalid.');
      }
    } else {
      setMobileError('');
    }
  }

  const checkErrorObject = () => {
    const errArr = Object.values(errors).filter((item, i) => {
      return item !== null;
    })
    return errArr.length;
  }


  const borowerRadioChangeHandler = (event) => {
    setBorowerInfo({ ...borrowerInfo, [event.target.name]: event.target.value })
  };
  const textChangePostDataHandler = (name, val) => {
    setCovidPostData({
      ...covidPostData,
      [name]: val
    })
  }

  const checkboxChangePostDataHandler = (event) => {
    setCovidPostData({
      ...covidPostData,
      [event.target.name]: event.target.checked
    })
  }

  // isQualified
  const isQualifiedCheckBoxHandler = (event) => {
    setCovidPostData({
      ...covidPostData,
      [event.target.name]: event.target.checked
    })
  }
  const isQualifiedRadioHandler = (event) => {
    setCovidPostData({
      ...covidPostData,
      [event.target.name]: event.target.value
    })
  }

  const covidHardshipDescHandler = (event) => {
    if (event.target.name === 'percentComplete') {
      if (checkDigitOnly(event.target.value) && event.target.value.length <= 2) {
        setCovidPostData({
          ...covidPostData,
          [event.target.name]: event.target.value
        })
        return;
      }
    } else if (event.target.name === 'impactedCov19') {
      if (event.target.value.length <= 60) {
        setCovidPostData({
          ...covidPostData,
          [event.target.name]: event.target.value
        })
        return;
      }
    } else {
      setCovidPostData({
        ...covidPostData,
        [event.target.name]: event.target.value
      })
    }

  };

  const dateCovidHardshipDescHandler = (name) => (date) => {
    setCovidPostData({
      ...covidPostData,
      [name]: date
    })
  };


  const setBackDate = (date) => {
    setCovidPostData({
      ...covidPostData,
      setGoBackdt: moment(date).format('L')
    })
  }

  const submitQualifiedCovidData = () => {
    if (!isQAgree) {
      setIsQAgreeError('Please check the option I Agree to proceed.');
    } else {
      setIsQAgreeError('');
      setIsSubmit(true);
      setCovidConfirmAlert(true);
    }
  }

  const submitAddDefermentData = async () => {
    await confirmCovidSubmit('deferementSubmit');
  }

  const confirmCovidSubmit = async (type = null) => {
    setIsSubmit(true);
    setCovidError('');
    setSuccessMsg('');
    setCovidConfirmAlertProgress(true);
    const data = {
      "accountNumber": selectedAccount,
      "borrowerName": covidPostData.primaryName,
      "email": covidPostData.emailID ? encrypt(covidPostData.emailID) : '',
      "phoneNumber": covidPostData.mobileNumber ? encrypt(covidPostData.mobileNumber) : '',
      "phoneNumberType": borrowerInfo.primPhoneType ? borrowerInfo.primPhoneType : 'C',
      "alternatePhoneNumber": covidPostData.alternatePhoneNumber ? encrypt(covidPostData.alternatePhoneNumber) : '',
      "alternatePhoneNumberType": borrowerInfo.otherPhoneType ? borrowerInfo.otherPhoneType : 'C',
      "propertyAddress": covidPostData.propertyAddress,
      "mailingAddress": covidPostData.mailingAddress,
      "propertyIsCurrently": covidPostData.accountType ? covidPostData.accountType : '',
      "propertyIs": (covidPostData.owner ? 'O, ' : '') + (covidPostData.rental ? 'R, ' : '') + (covidPostData.vacant ? 'V' : ''),
      "impacted250": covidPostData.impacted250 ? covidPostData.impacted250 : '',
      "workShutdown": covidPostData.workShutdown ? covidPostData.workShutdown : '',
      "payingWhileShutdown": covidPostData.payingWhileShutdown ? covidPostData.payingWhileShutdown : '',
      "unemploymentMeantime": covidPostData.unemploymentMeantime ? covidPostData.unemploymentMeantime : '',
      "setGoBack": covidPostData.setGoBack ? covidPostData.setGoBack : '',
      "setGoBackdt": covidPostData.setGoBack === 'Y' ? covidPostData.setGoBackdt ? moment(covidPostData.setGoBackdt).format('L') : moment(new Date()).format('L') : '',
      "otherSourceOfIncome": covidPostData.otherSourceOfIncome ? covidPostData.otherSourceOfIncome : '',
      "paymentsDuringShutdown": covidPostData.paymentsDuringShutdown ? covidPostData.paymentsDuringShutdown : '',
      "suspensionOfPayments": covidPostData.forbearanceOf ? covidPostData.forbearanceOf : '',
      "contactedViaEmail": covidPostData.contactedViaEmail ? covidPostData.contactedViaEmail === true ? true : false : false,
      "contactedOnCellPhone": covidPostData.contactedOnCellPhone ? covidPostData.contactedOnCellPhone === true ? true : false : false,
      "impactedCov19": covidPostData.impactedCov19,
      "isRentalProperty": covidPostData.isRentalProperty ? covidPostData.isRentalProperty : '',
      "isTenantInProperty": covidPostData.isTenantInProperty ? covidPostData.isTenantInProperty : '',
      "isTenantMakingFullPayments": covidPostData.isTenantMakingFullPayments ? covidPostData.isTenantMakingFullPayments : '',
      "areYouProhibitedEvictingTenant": covidPostData.areYouProhibitedEvictingTenant ? covidPostData.areYouProhibitedEvictingTenant : '',
      "isPropertyRenovatedOrRehabilitated": covidPostData.isPropertyRenovatedOrRehabilitated ? covidPostData.isPropertyRenovatedOrRehabilitated : '',
      "percentComplete": covidPostData.percentComplete ? covidPostData.percentComplete : '',
      "renovationDate": covidPostData.renovationDateRadio === 'Yes' ? moment(covidPostData.renovationDate).format('L') : '',
      "foreseeCashFlow": covidPostData.foreseeCashFlow ? covidPostData.foreseeCashFlow : '',
      "isPropertyListedForSale": covidPostData.isPropertyListedForSale ? covidPostData.isPropertyListedForSale : '',
      "haveAnotherSourceOfIncome": covidPostData.haveAnotherSourceOfIncome ? covidPostData.haveAnotherSourceOfIncome : '',
      "monthlyMortgagePaymentDate": covidPostData.monthlyMortgagePaymentDate ? moment(covidPostData.monthlyMortgagePaymentDate).format('L') : moment().format('L'),
      "loanBalanceCompleteDate": covidPostData.loanBalanceCompleteDate ? moment(covidPostData.loanBalanceCompleteDate).format('L') : moment().format('L'),
      "CovidType": covidPostData.CovidType ? covidPostData.CovidType : '',
      "experienceHardship": addAssistState.experience_hardship,
      "reasonForDefaultIs": addAssistState.long_term,
      "reasonForDefaultResolve": addAssistState.default_resolved,
      "additionalPaymentForbearance": addAssistState.additionalPaymentForbearance,
      "willingResolveDelinquency": addAssistState.willing_to_deliquency,
      "ableToAffordRepaymentPlan": addAssistState.afford_repayment,
      "contractualMonthlyPayment": addAssistState.existing_pay,
      "delinquentDays": covidPostData.delinquentDays,   //getting in get api
      "isLoanIsInFinalMonth": covidPostData.isLoanIsInFinalMonth,   //getting in api
      "isForbearanceExtRequested": addAssistState.extend_forB,
      "forbearanceExtPeriod": addAssistState.extend_time,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    const response = await saveCovidMortgageAssistance(data);
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
      setIsSubmit(false);
      setCovidError('');
      setCovidConfirmAlertProgress(false);
      setCovidConfirmAlert(false);
      setSuccessMsg('Form successfully submitted!');
      setSuccessSnackBar(true);
      if (type === 'deferementSubmit') {
        resetCovidForm();
        scrollTop();
        getCovidHardshipData();
        return;
      } else {
        resetCovidForm();
        scrollTop();
        getCovidHardshipData();
      }
    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
      setIsSubmit(false);
      if (type === 'deferementSubmit') {
        setIdAddDeferementSubmit(false);
      } else {
        setSubmitStatus(false);
      }
      setCovidConfirmAlertProgress(false);
      setCovidError(response.lstResponseStatus[0].statusDesc);
      setCovidConfirmAlert(false);
    } else {
      setIsSubmit(false);
      setCovidConfirmAlertProgress(true);
      setCovidConfirmAlert(false);
      setCovidError(renderDefaultMsg('400'));
    }
  }

  const disableSubmitBtn = () => {
    if (!covid19Data.isbtnDefermentSubmit && covid19Data.isAdditionalDefermentQ8 && addAssistState.extend_forB === '') {
      return true
    }
    if (addAssistState.extend_forB === '' || addAssistState.extend_forB !== '' && ((addAssistState.extend_forB === 'N' && addAssistState.experience_hardship === '')
      || (addAssistState.extend_forB === 'Y' && addAssistState.extend_time === ''))) {
      return true
    }
    if (addAssistState.experience_hardship !== '' && addAssistState.experience_hardship === 'Y' && (addAssistState.long_term === '' || addAssistState.default_resolved === '')) {
      return true
    }
    if (addAssistState.default_resolved !== ''
      && ((addAssistState.default_resolved === 'N' && covid19Data.isliFinalMonth && !covid19Data.isNotFinalMonth && addAssistState.additionalPaymentForbearance === '')
        || (addAssistState.default_resolved === 'Y' && addAssistState.willing_to_deliquency === ''))) {
      return true
    }
    if (addAssistState.additionalPaymentForbearance !== '' && addAssistState.additionalPaymentForbearance === 'N' && addAssistState.willing_to_deliquency === '') {
      return true
    }
    if (addAssistState.willing_to_deliquency !== '' && addAssistState.willing_to_deliquency === 'N' && (addAssistState.afford_repayment === null || addAssistState.afford_repayment === '')) {
      return true
    }
    if (addAssistState.afford_repayment !== '' && addAssistState.afford_repayment === 'N' && addAssistState.existing_pay === '') {
      return true
    }
    return false
  }


  const handleRadioExtend = (event) => {
    let val = event.target.value;
    if (event.target.name === 'extend_forB') {
      let val = event.target.value;
      if (val === 'Y') {
        setCovid19Data({
          ...covid19Data,
          isForbearanceExtDays: true,
          isAdditionalDefermentQ2: false,
          isAdditionalDefermentQ3: false,
          isAdditionalDefermentQ4: false,
          isAdditionalDefermentQ5: false,
          isAdditionalDefermentQ6: false,
          isAdditionalDefermentQ7: false,
        })
        setAddAssistState({
          ...addAssistState,
          [event.target.name]: event.target.value,
          extend_time: '',
          experience_hardship: '',
          default_resolved: '',
          additionalPaymentForbearance: '',
          willing_to_deliquency: '',
          afford_repayment: '',
          existing_pay: '',
          long_term: '',
        });
      } else if (val === 'N') {
        setAddAssistState({ ...addAssistState, [event.target.name]: event.target.value });
        setCovid19Data({
          ...covid19Data,
          isForbearanceExtDays: false,
          isAdditionalDefermentQ2: false,
          isAdditionalDefermentQ3: false,
          isAdditionalDefermentQ4: false,
          isAdditionalDefermentQ5: false,
          isAdditionalDefermentQ6: false,
          isAdditionalDefermentQ7: true,
        })
      }
    } else if (event.target.name === 'experience_hardship') {
      let val = event.target.value;

      if (val === 'Y') {
        setCovid19Data({
          ...covid19Data,
          isAdditionalDefermentQ2: true,
          isAdditionalDefermentQ3: true,
          isLMA90Up: false,
          isLMA90Down: false
        });
        setAddAssistState({ ...addAssistState, [event.target.name]: event.target.value });

      } else if (val === 'N') {
        setCovid19Data({
          ...covid19Data,
          isAdditionalDefermentQ2: false,
          isAdditionalDefermentQ3: false,
          isAdditionalDefermentQ4: false,
          isAdditionalDefermentQ5: false,
          isAdditionalDefermentQ6: false,
          isLMA90Up: false,
          isLMA90Down: false

        });
        setAddAssistState({
          ...addAssistState,
          [event.target.name]: event.target.value,
          extend_time: '',
          default_resolved: '',
          additionalPaymentForbearance: '',
          willing_to_deliquency: '',
          afford_repayment: '',
          existing_pay: '',
          long_term: '',
        });
      }
    } else if (event.target.name === 'default_resolved') {
      setAddAssistState({ ...addAssistState, [event.target.name]: event.target.value });
      if (val === 'Y') {
        setCovid19Data({
          ...covid19Data,
          isAdditionalDefermentQ4: true,
        });
        setAddAssistState({
          ...addAssistState,
          [event.target.name]: event.target.value,
          additionalPaymentForbearance: '',
          willing_to_deliquency: '',
          afford_repayment: '',
          existing_pay: '',
        });
      } else if (val === 'N') {
        setCovid19Data({
          ...covid19Data,
          isAdditionalDefermentQ4: false,
          isAdditionalDefermentQ5: false,
          isAdditionalDefermentQ6: false,
        });
        setAddAssistState({
          ...addAssistState,
          [event.target.name]: event.target.value,
          additionalPaymentForbearance: '',
          willing_to_deliquency: '',
          afford_repayment: '',
          existing_pay: '',
        });
      }
    } else if (event.target.name === 'additionalPaymentForbearance') {
      setAddAssistState({ ...addAssistState, [event.target.name]: event.target.value });
      if (val === 'N') {
        setCovid19Data({
          ...covid19Data,
          isAdditionalDefermentQ4: true,
        })
      } else if (val === 'Y') {
        setCovid19Data({
          ...covid19Data,
          isAdditionalDefermentQ4: false,
        })
      }
    } else if (event.target.name === 'willing_to_deliquency') {
      if (val === 'N') {
        setCovid19Data({
          ...covid19Data,
          isAdditionalDefermentQ5: true,
        });
        setAddAssistState({ ...addAssistState, [event.target.name]: event.target.value });

      } else if (val === 'Y') {
        setCovid19Data({
          ...covid19Data,
          isAdditionalDefermentQ5: false,
          isAdditionalDefermentQ6: false,
        });
        setAddAssistState({
          ...addAssistState,
          [event.target.name]: event.target.value,
          afford_repayment: '',
          existing_pay: '',
        });
      }
    } else if (event.target.name === 'afford_repayment') {
      if (val === 'N') {
        setCovid19Data({
          ...covid19Data,
          isAdditionalDefermentQ6: true,
        });
        setAddAssistState({ ...addAssistState, [event.target.name]: event.target.value });
      } else if (val === 'Y') {
        setCovid19Data({
          ...covid19Data,
          isAdditionalDefermentQ6: false,
        });
        setAddAssistState({
          ...addAssistState,
          [event.target.name]: event.target.value,
          existing_pay: '',
        });
      }
    } else {
      setAddAssistState({ ...addAssistState, [event.target.name]: event.target.value });
    }
  };

  const routePage = async (link) => {
    await dispatch(setEditProfileComponent(true));
    history.push(link);
  }

  const cancelForm = () => {
    history.push('/FinancialHardship');
  }

  if (covidDataLoadError !== '') {
    return (
      <Box className={classes.loaderContainer}>
        <p className={classes.dataLoadError}>{covidDataLoadError}</p>
      </Box>
    );
  }

  if (covidDataLoading || covid19Data === '') {
    return (
      <Box className={classes.loaderContainer}>
        <CircularProgress />
      </Box>
    );
  }
  if (!showCovidForm) {
    return (
    <Box style={{ background: '#e8e8e8' }}>
    <Box className={classes.root}>
      <CustomBreadcrum backPage="Financial Hardship" backPageLink="/FinancialHardship" activePage="Covid-19" />
      <h1 className={classes.title}>Financial Hardship Due to Covid-19</h1>
      <Box className={classes.container}>

        <Box className={classes.covidInfoContainer}>
          <Box className={classes.covidStatus}>
          <h2 className={classes.subTitle}>Are you having difficulty making payments due to COVID-19?</h2>
          {Cookies.get("isAgencyLoan") == 'true' &&
            <p className={classes.text}>Have you been impacted by COVID-19? If you have experienced difficulty in making your mortgage payments due to the impact of COVID-19, please contact your Single Point of Contact directly, or otherwise call our Loss Mitigation team at 866-949-0136 to discuss your options, which may include a forbearance.</p> }
             {Cookies.get("isAgencyLoan") != 'true' &&
            <p className={classes.text}>Have you been impacted by COVID-19? If you have experienced difficulty in making your mortgage payments due to the impact of COVID-19, please contact your Single Point of Contact directly, or otherwise call our Loss Mitigation team at 888-482-7271 to discuss any options that may be available to you.</p>
            }
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>)
  }

  if ((submitStatus || covid19Data.isCITActive) && showCovidForm) {
    return (
      <Box style={{ background: '#e8e8e8' }}>
        <Box className={classes.root}>
          <CustomBreadcrum backPage="Financial Hardship" backPageLink="/FinancialHardship" activePage="Covid-19" />
          <h1 className={classes.title}>Financial Hardship Due to Covid-19</h1>
          <Box className={classes.container}>

            <Box className={classes.covidInfoContainer}>
              <Box className={classes.covidStatus}>
                <p className={classes.text}>Your application has already been submitted and is current being reviewed/processed.</p>
                <p className={classes.text}>No further action is required.</p><br />
                <p className={classes.text}>Your Forbearance status is available below and can be viewed at any time by going to the Loan Details &gt;
                 <Link to="/WorkoutStatus" className={classes.covidLinkStyle}> Workout Status page</Link></p>
                <p className={classes.text}>Forbearance status: {covid19Data.forbearanceStatus}</p>
                <p className={classes.helpText}> Note: It can take up to 24 hours for your request status to be updated.</p>
              </Box>
            </Box>
          </Box>
        </Box>
        {successMsg !== '' && <SnackBar open={successSnackBar} handleClose={handleSnackBarClose} severity="success" message={successMsg} />}
      </Box>
    )
  }
  if (showCovidForm) {

  return (
    <Box className={classes.pageBg}>
      <Box className={classes.root}>
        <CustomBreadcrum backPage="Financial Hardship" backPageLink="/FinancialHardship" activePage="Covid-19" />
        <h1 className={classes.title}>Financial Hardship Due to Covid-19</h1>
        <Box className={classes.container} ref={componentRef}>

          <Box className={classes.covidInfoContainer}>
            {(covid19Data.isBPLMain || covid19Data.isBPLDC || covid19Data.isNotQualified || covid19Data.isQualified) ?
              <h2 className={classes.subTitle}>Are you having difficulty making payments due to COVID-19?</h2> : null}

            {(covid19Data.isBPLMain || covid19Data.isBPLDC || covid19Data.isNotQualified) ?
              <p className={classes.text} >Depending on the status of your loan, your individual circumstances, and
              other factors, the following option may be available to you, though this option has certain eligibility
                requirements and is subject to lender approval.</p> : null}

            {covid19Data.isQualified && <CovidList isQualified={'true'} />}

            {covid19Data.isNotQualified && <>
              <CovidList forbearance={'true'} />
              <Box className={classes.forbearanceAppForm}>
                <Box className={`${classes.flexBox} ${classes.marTopBottom20}${classes.flexWrap} ${classes.xsMb10}`}>
                  <img className={classes.covidImg} src={covid} alt='covid icon' />
                  <h2 className={classes.forbearanceAppFormTitle}>COVID-19 Forbearance Application</h2>
                </Box>
                <p className={classes.text} >If you are having mortgage payment challenges related to COVID-19, please complete and submit this application. We will contact you within five business days to acknowledge receipt and let you know if you need to send additional information or documents.</p>
              </Box>
            </>}
          </Box>

          {covid19Data.isNotQualified && <Box>
            <Box className={classes.headingContainer}>
              <Box className={`${classes.flexBox} ${classes.justifyCenter} ${classes.flexWrap}`}>
                <h3 className={`${classes.formTitle} ${classes.xsMb10}`}>Borrower Information</h3>
                <Button
                  className={classes.btn}
                  type='submit' size="large" variant="contained" color="primary" onClick={() => routePage('/UserProfile/Profile')}>
                  EDIT PROFILE
                  </Button>
              </Box>
            </Box>
            <Box className={`${classes.formContainer} `}>
              <Box className={`${classes.form} ${classes.formBg}`}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      color="primary"
                      id={`outlined - `}
                      variant="outlined"
                      name="primaryName"
                      type="text"
                      label={<Box>Borrower's Name<span className={classes.required}>*</span></Box>}
                      value={covidPostData.primaryName}
                      onChange={textChangeHandler}
                      InputLabelProps={{
                        classes: { root: classes.textField }
                      }}
                      InputProps={{
                        maxLength: 100,
                        classes: { root: classes.textField, input: classes.textInput },
                      }} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      color='primary'
                      id={`outlined - `}
                      variant="outlined"
                      name="emailID"
                      type="text"
                      label={<Box>Borrower's Email<span className={classes.required}>*</span></Box>}
                      value={covidPostData.emailID}
                      onChange={textChangeHandler}
                      onBlur={handleBlur}
                      InputLabelProps={{
                        classes: { root: classes.textField }
                      }}
                      InputProps={{
                        maxLength: 100,
                        classes: { root: classes.textField, input: classes.textInput }
                      }} />
                    {touched.emailID && <p className={classes.validationErr}>{errors.emailID}</p>}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      color="primary"
                      id={`outlined - `}
                      variant="outlined"
                      type="text"
                      label={<Box>Primary Phone Number<span className={classes.required}>*</span></Box>}
                      name="mobileNumber"
                      value={covidPostData.mobileNumber ? covidPostData.mobileNumber : ''}
                      onChange={textChangeHandler}
                      onBlur={verifyPhone}
                      InputLabelProps={{
                        classes: { root: classes.textField }
                      }}
                      InputProps={{
                        classes: { root: classes.textField, input: classes.textInput },
                        maxLength: 12,
                      }} />
                    {mobileError !== '' && <p className={classes.validationErr}>{mobileError}</p>}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <RadioGroup row className={`${classes.justifyCenter} ${classes.mt10}`}
                      value={borrowerInfo.primPhoneType}
                      onChange={borowerRadioChangeHandler}>
                      <FormControlLabel
                        value="C"
                        name="primPhoneType"
                        checked={borrowerInfo.primPhoneType === 'C' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="Cell"
                        classes={{
                          label: borrowerInfo.primPhoneType === 'C' ? classes.activeRadioLabel : classes.radioLabel
                        }}
                      />
                      <FormControlLabel
                        value="H"
                        name="primPhoneType"
                        checked={borrowerInfo.primPhoneType === 'H' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="Home"
                        classes={{
                          label: borrowerInfo.primPhoneType === 'H' ? classes.activeRadioLabel : classes.radioLabel
                        }}
                      />
                      <FormControlLabel
                        value="W"
                        name="primPhoneType"
                        checked={borrowerInfo.primPhoneType === 'W' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="Work"
                        classes={{
                          label: borrowerInfo.primPhoneType === 'W' ? classes.activeRadioLabel : classes.radioLabel
                        }}
                      />
                      <FormControlLabel
                        value="O"
                        name="primPhoneType"
                        checked={borrowerInfo.primPhoneType === 'O' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="Other"
                        classes={{
                          label: borrowerInfo.primPhoneType === 'O' ? classes.activeRadioLabel : classes.radioLabel
                        }}
                      />
                    </RadioGroup >
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      color="primary"
                      id={`outlined - `}
                      variant="outlined"
                      type="text"
                      label="Alternate Phone Number"
                      name="alternatePhoneNumber"
                      value={covidPostData.alternatePhoneNumber ? covidPostData.alternatePhoneNumber : ''}
                      onChange={textChangeHandler}
                      InputLabelProps={{
                        classes: { root: classes.textField }
                      }}
                      InputProps={{
                        classes: { root: classes.textField, input: classes.textInput },
                        maxLength: 12,
                      }} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <RadioGroup row className={`${classes.justifyCenter} ${classes.mt10}`}
                      value={borrowerInfo.otherPhoneType}
                      onChange={borowerRadioChangeHandler}>
                      <FormControlLabel
                        value="C"
                        name="otherPhoneType"
                        checked={borrowerInfo.otherPhoneType === 'C' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="Cell"
                        classes={{
                          label: borrowerInfo.otherPhoneType === 'C' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                      <FormControlLabel
                        value="H"
                        name="otherPhoneType"
                        checked={borrowerInfo.otherPhoneType === 'H' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="Home"
                        classes={{
                          label: borrowerInfo.otherPhoneType === 'H' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                      <FormControlLabel
                        value="W"
                        name="otherPhoneType"
                        checked={borrowerInfo.otherPhoneType === 'W' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="Work"
                        classes={{
                          label: borrowerInfo.otherPhoneType === 'W' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                      <FormControlLabel
                        value="O"
                        name="otherPhoneType"
                        checked={borrowerInfo.otherPhoneType === 'O' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="Other"
                        classes={{
                          label: borrowerInfo.otherPhoneType === 'O' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                    </RadioGroup >
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box className={classes.headingContainer}>
              <Box className={`${classes.flexBox} ${classes.justifyCenter}`}>
                <h3 className={classes.formTitle}>Property Details</h3>
              </Box>
            </Box>
            <Box className={`${classes.formContainer} `}>
              <Box className={`${classes.form} ${classes.formBg}`}>
                <Grid container >
                  <Grid item xs={12} md={4}>
                    <p className={`${classes.formText} ${classes.xsMb10}`}>Property Address:<span className={classes.required}>*</span></p>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Box className={classes.flexBox}>
                      <p className={classes.formText}>{covid19Data.propertyAddress}</p>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container className={classes.marginFixed}>
                  <Grid item xs={12} md={4}>
                    <p className={`${classes.formText} ${classes.mt20} ${classes.xsMb10}`}>Mailing Address:</p>
                  </Grid>
                  <Grid item xs={9} md={5}>
                    <TextField
                      fullWidth
                      color="primary"
                      id={`outlined - `}
                      variant="outlined"
                      type="text"
                      name="mailingAddress"
                      label="Borrower's Address"
                      value={covidPostData.mailingAddress ? covidPostData.mailingAddress : ''}
                      onChange={(event) => textChangePostDataHandler(event.target.name, event.target.value)}
                      InputLabelProps={{
                        classes: { root: classes.textField }
                      }}
                      InputProps={{
                        classes: { root: classes.textField, input: classes.textInput }
                      }} />
                  </Grid>
                  <Grid item xs={3} xs={3}>
                    <Button className={classes.editBtn} color="secondary" component={Link} to="/UserProfile/Profile">
                      Edit
                    </Button>
                  </Grid>
                </Grid>

                <Grid container className={classes.marginFixed}>
                  <Grid item xs={12} md={4}>
                    <p className={classes.formText}>The property is currently:<span className={classes.required}>*</span></p>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <RadioGroup className={classes.propertyRadio}
                      value={covidPostData.accountType ? covidPostData.accountType : ''}
                      onChange={(event) => textChangePostDataHandler(event.target.name, event.target.value)}
                    >
                      <FormControlLabel
                        value="P"
                        name="accountType"
                        checked={covidPostData.accountType && covidPostData.accountType === 'P' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="A primary Residence"
                        classes={{
                          label: covidPostData.accountType && covidPostData.accountType === 'P' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                      <FormControlLabel
                        value='S'
                        name="accountType"
                        checked={covidPostData.accountType && covidPostData.accountType === 'S' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="A second Home"
                        classes={{
                          label: covidPostData.accountType && covidPostData.accountType === 'S' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                      <FormControlLabel
                        value='I'
                        name="accountType"
                        checked={covidPostData.accountType && covidPostData.accountType === 'I' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="An investment Property"
                        classes={{
                          label: covidPostData.accountType && covidPostData.accountType === 'I' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                    </RadioGroup >
                  </Grid>
                </Grid>

                <Grid container className={classes.marginFixed}>
                  <Grid item xs={12} md={4}>
                    <p className={classes.formText}>The property is:<span className={classes.required}>*</span></p>
                    <p className={classes.formText}>(select all that apply)	 </p>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <FormGroup row className={classes.propertyRadio}>
                      <FormControlLabel
                        control={<Checkbox
                          checked={covidPostData.owner ? true : false}
                          onChange={(event) => checkboxChangePostDataHandler(event)}
                          name="owner"
                          value="O" />}
                        label="Owner Occupied"
                        classes={{
                          label: covidPostData.owner && covidPostData.owner === 'O' ? classes.activeRadioLabel : classes.radioLabel
                        }}
                      />
                      <FormControlLabel
                        control={<Checkbox
                          checked={covidPostData.rental ? true : false}
                          onChange={(event) => checkboxChangePostDataHandler(event)}
                          name="rental"
                          value="R" />}
                        label="Rental Occupied"
                        classes={{
                          label: covidPostData.rental && covidPostData.rental === 'R' ? classes.activeRadioLabel : classes.radioLabel
                        }}
                      />
                      <FormControlLabel
                        control={<Checkbox
                          checked={covidPostData.vacant ? true : false}
                          onChange={(event) => checkboxChangePostDataHandler(event)}
                          name="vacant"
                          value="V" />}
                        label="Vacant"
                        classes={{
                          label: covidPostData.vacant && covidPostData.vacant === 'V' ? classes.activeRadioLabel : classes.radioLabel
                        }}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box className={`${classes.impectedCovid} `}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Box className={classes.covidQnA}>
                    <h4 className={classes.subHeading}>How have you been impacted by COVID-19?<span className={classes.required}>*</span></h4>

                    <TextField
                      style={{ height: '117px' }}
                      fullWidth
                      color="primary"
                      id={`outlined_impacted250`}
                      variant="outlined"
                      type="text"
                      name="impacted250"
                      label="(Please complete to submit to Loan Servicing Center)"
                      value={covidPostData.impacted250 ? covidPostData.impacted250 : ''}
                      onChange={covidHardshipDescHandler}
                      InputLabelProps={{
                        classes: { root: classes.textField }
                      }}
                      InputProps={{
                        classes: { root: classes.textField250, input: classes.textInput }
                      }} />

                    <Box className={classes.covidImpectRadio}>
                      <Grid container className={classes.radioBtnMargin}>
                        <Grid item xs={12} sm={6}>
                          <p className={classes.subText}>If work is shut down, is it<span className={classes.required}>*</span></p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <RadioGroup row className={`${classes.justifyCenter}`}
                            value={covidPostData.workShutdown ? covidPostData.workShutdown : ''}
                            onChange={covidHardshipDescHandler}>
                            <FormControlLabel
                              value="T"
                              name="workShutdown"
                              checked={covidPostData.workShutdown === 'T' ? true : false}
                              control={<Radio classes={{ root: classes.radioStyle }} />}
                              label="Temporary"
                              classes={{
                                label: covidPostData.workShutdown === 'T' ? classes.activeRadioLabel : classes.radioLabel
                              }} />
                            <FormControlLabel
                              value="P"
                              name="workShutdown"
                              checked={covidPostData.workShutdown === 'P' ? true : false}
                              control={<Radio classes={{ root: classes.radioStyle }} />}
                              label="Permanent"
                              classes={{
                                label: covidPostData.workShutdown === 'P' ? classes.activeRadioLabel : classes.radioLabel
                              }} />
                          </RadioGroup >
                        </Grid>
                      </Grid>
                      <Grid container className={classes.radioBtnMargin}>
                        <Grid item xs={12} sm={6}>
                          <p className={classes.subText}>Are you currently being paid by your employer?<span className={classes.required}>*</span></p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <RadioGroup row className={`${classes.justifyCenter}`}
                            value={covidPostData.payingWhileShutdown ? covidPostData.payingWhileShutdown : ''}
                            onChange={covidHardshipDescHandler}>
                            <FormControlLabel
                              value="Y"
                              name="payingWhileShutdown"
                              checked={covidPostData.payingWhileShutdown === 'Y' ? true : false}
                              control={<Radio classes={{ root: classes.radioStyle }} />}
                              label="Yes"
                              classes={{
                                label: covidPostData.payingWhileShutdown === 'Y' ? classes.activeRadioLabel : classes.radioLabel
                              }} />
                            <FormControlLabel
                              value="N"
                              name="payingWhileShutdown"
                              checked={covidPostData.payingWhileShutdown === 'N' ? true : false}
                              control={<Radio classes={{ root: classes.radioStyle }} />}
                              label="No"
                              classes={{
                                label: covidPostData.payingWhileShutdown === 'N' ? classes.activeRadioLabel : classes.radioLabel
                              }} />
                          </RadioGroup >
                        </Grid>
                      </Grid>
                      <Grid container className={classes.radioBtnMargin}>
                        <Grid item xs={12} sm={6}>
                          <p className={classes.subText}>Will you be collecting unemployment?<span className={classes.required}>*</span>	</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <RadioGroup row className={`${classes.justifyCenter}`}
                            value={covidPostData.unemploymentMeantime ? covidPostData.unemploymentMeantime : ''}
                            onChange={covidHardshipDescHandler}>
                            <FormControlLabel
                              value="Y"
                              name="unemploymentMeantime"
                              checked={covidPostData.unemploymentMeantime === 'Y' ? true : false}
                              control={<Radio classes={{ root: classes.radioStyle }} />}
                              label="Yes"
                              classes={{
                                label: covidPostData.unemploymentMeantime === 'Y' ? classes.activeRadioLabel : classes.radioLabel
                              }} />
                            <FormControlLabel
                              value="N"
                              name="unemploymentMeantime"
                              checked={covidPostData.unemploymentMeantime === 'N' ? true : false}
                              control={<Radio classes={{ root: classes.radioStyle }} />}
                              label="No"
                              classes={{
                                label: covidPostData.unemploymentMeantime === 'N' ? classes.activeRadioLabel : classes.radioLabel
                              }} />
                          </RadioGroup >
                        </Grid>
                      </Grid>
                      <Grid container className={classes.radioBtnMargin}>
                        <Grid item xs={12} sm={6}>
                          <p className={classes.subText}>Is there a date you are set to go back?<span className={classes.required}>*</span></p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <RadioGroup row className={`${classes.justifyCenter}`}
                            value={covidPostData.setGoBack ? covidPostData.setGoBack : ''}
                            onChange={covidHardshipDescHandler}>
                            <FormControlLabel
                              value="Y"
                              name="setGoBack"
                              checked={covidPostData.setGoBack === 'Y' ? true : false}
                              control={<Radio classes={{ root: classes.radioStyle }} />}
                              label="Yes"
                              classes={{
                                label: covidPostData.setGoBack === 'Y' ? classes.activeRadioLabel : classes.radioLabel
                              }} />
                            <FormControlLabel
                              value="N"
                              name="setGoBack"
                              checked={covidPostData.setGoBack === 'N' ? true : false}
                              control={<Radio classes={{ root: classes.radioStyle }} />}
                              label="No"
                              classes={{
                                label: covidPostData.setGoBack === 'N' ? classes.activeRadioLabel : classes.radioLabel
                              }} />
                          </RadioGroup >

                          {covidPostData.setGoBack === 'Y' && <Box style={{ marginTop: '7px' }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                name="setGoBackdt"
                                color='secondary'
                                autoOk={true}
                                disableToolbar
                                variant="inline"
                                inputVariant="outlined"
                                format="MM/dd/yyyy"
                                id="date-picker-inline"
                                value={covidPostData.setGoBackdt ? covidPostData.setGoBackdt : new Date()}
                                onChange={setBackDate}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                                InputProps={{
                                  classes: { root: classes.textField, input: classes.input },
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </Box>}

                        </Grid>
                      </Grid>

                      <Grid container className={classes.radioBtnMargin}>
                        <Grid item xs={12} sm={6}>
                          <p className={classes.subText}>Is there any other source of income?<span className={classes.required}>*</span> </p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <RadioGroup row className={`${classes.justifyCenter}`}
                            value={covidPostData.otherSourceOfIncome ? covidPostData.otherSourceOfIncome : ''}
                            onChange={covidHardshipDescHandler}>
                            <FormControlLabel
                              value="Y"
                              name="otherSourceOfIncome"
                              checked={covidPostData.otherSourceOfIncome === 'Y' ? true : false}
                              control={<Radio classes={{ root: classes.radioStyle }} />}
                              label="Yes"
                              classes={{
                                label: covidPostData.otherSourceOfIncome === 'Y' ? classes.activeRadioLabel : classes.radioLabel
                              }} />
                            <FormControlLabel
                              value="N"
                              name="otherSourceOfIncome"
                              checked={covidPostData.otherSourceOfIncome === 'N' ? true : false}
                              control={<Radio classes={{ root: classes.radioStyle }} />}
                              label="No"
                              classes={{
                                label: covidPostData.otherSourceOfIncome === 'N' ? classes.activeRadioLabel : classes.radioLabel
                              }} />
                          </RadioGroup >
                        </Grid>
                      </Grid>
                      <Grid container className={classes.radioBtnMargin}>
                        <Grid item xs={12} sm={6}>
                          <p className={classes.subText}>Do you expect to make payments during the shutdown?<span className={classes.required}>*</span></p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <RadioGroup row className={`${classes.justifyCenter}`}
                            value={covidPostData.paymentsDuringShutdown ? covidPostData.paymentsDuringShutdown : ''}
                            onChange={covidHardshipDescHandler}>
                            <FormControlLabel
                              value="Y"
                              name="paymentsDuringShutdown"
                              checked={covidPostData.paymentsDuringShutdown === 'Y' ? true : false}
                              control={<Radio classes={{ root: classes.radioStyle }} />}
                              label="Yes"
                              classes={{
                                label: covidPostData.paymentsDuringShutdown === 'Y' ? classes.activeRadioLabel : classes.radioLabel
                              }} />
                            <FormControlLabel
                              value="N"
                              name="paymentsDuringShutdown"
                              checked={covidPostData.paymentsDuringShutdown === 'N' ? true : false}
                              control={<Radio classes={{ root: classes.radioStyle }} />}
                              label="No"
                              classes={{
                                label: covidPostData.paymentsDuringShutdown === 'N' ? classes.activeRadioLabel : classes.radioLabel
                              }} />
                          </RadioGroup >
                        </Grid>

                      </Grid>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box className={classes.agrementContainer}>
                    <Box className={classes.termAndCondition}>
                      <h3 className={classes.aggTitle}>Borrower Certification and Agreement</h3>
                      <Box display="block" displayPrint="none">
                        <Box className={classes.termText}>
                          <p className={`${classes.text}`}> 1.	I certify and acknowledge that all of the information in this Mortgage Assistance Application is truthful, and the hardship I identified contributed to my need for mortgage relief. Knowingly submitting false information may violate Federal and other applicable law
                         </p><p className={`${classes.text} ${classes.mt10}`}>2.	I agree to provide my servicer with all required documents, including any additional supporting documentation as requested, and will respond in a timely manner to all servicer or authorized third party* communications.
                         </p><p className={`${classes.text} ${classes.mt10}`}> 3.	I acknowledge and agree that my servicer is not obligated to offer me assistance based solely on the representations in this document or other documentation submitted in connection with my request.
                         </p><p className={`${classes.text} ${classes.mt10}`}> 4.	I consent to the servicer or authorized third party* obtaining a current credit report for the borrower and co-borrower.
                         </p><p className={`${classes.text} ${classes.mt10}`}> 5.	I consent to the disclosure by my servicer, authorized third party,* or any investor/guarantor of my mortgage loan(s), of any personal information collected during the mortgage assistance process and of any information about any relief I receive, to any third party that deals with my first lien or subordinate lien (if applicable) mortgage loan(s), including Fannie Mae, Freddie Mac, or any investor, insurer, guarantor, or servicer of my mortgage loan(s) or any companies that provide support services to them, for purposes permitted by applicable law. Personal information may include, but is not limited to: (a) my name, address, telephone number, (b) my Social Security number, (c) my credit score, (d) my income, and (e) my payment history and information about my account balances and activity.
                         </p><p className={`${classes.text} ${classes.mt10}`}>  6.	I agree that the terms of this borrower certification and agreement will apply to any forbearance plan that I may be offered based on this application.
                        </p>
                          <p className={`${classes.text} ${classes.mt10}`}>7. I consent to being contacted concerning this application for mortgage assistance at any telephone number, including mobile telephone number, or email address I have provided to the lender, servicer, or authorized third party.* By submitting this application with my cell phone number and/or email address, I am granting express consent to contact me at that the number and e-mail address provided about my Loan. My consent allows the servicer of my loans to use text messaging, artificial or pre-recorded voice messages and automatic dialing technology for informational and account service calls. Message and data rates may apply.
                          * An authorized third party may include, but is not limited to, a housing counseling agency, Housing Finance Agency (HFA) or other similar entity that is assisting me in obtaining a foreclosure prevention alternative.
</p>
                        </Box>
                      </Box>
                      <Box display="none" displayPrint="block">
                        <Box className={classes.termTexta}>
                          <p className={`${classes.text}`}> 1.	I certify and acknowledge that all of the information in this Mortgage Assistance Application is truthful, and the hardship I identified contributed to my need for mortgage relief. Knowingly submitting false information may violate Federal and other applicable law
                         </p><p className={`${classes.text} ${classes.mt10}`}>2.	I agree to provide my servicer with all required documents, including any additional supporting documentation as requested, and will respond in a timely manner to all servicer or authorized third party* communications.
                         </p><p className={`${classes.text} ${classes.mt10}`}> 3.	I acknowledge and agree that my servicer is not obligated to offer me assistance based solely on the representations in this document or other documentation submitted in connection with my request.
                         </p><p className={`${classes.text} ${classes.mt10}`}> 4.	I consent to the servicer or authorized third party* obtaining a current credit report for the borrower and co-borrower.
                         </p><p className={`${classes.text} ${classes.mt10}`}> 5.	I consent to the disclosure by my servicer, authorized third party,* or any investor/guarantor of my mortgage loan(s), of any personal information collected during the mortgage assistance process and of any information about any relief I receive, to any third party that deals with my first lien or subordinate lien (if applicable) mortgage loan(s), including Fannie Mae, Freddie Mac, or any investor, insurer, guarantor, or servicer of my mortgage loan(s) or any companies that provide support services to them, for purposes permitted by applicable law. Personal information may include, but is not limited to: (a) my name, address, telephone number, (b) my Social Security number, (c) my credit score, (d) my income, and (e) my payment history and information about my account balances and activity.
                         </p><p className={`${classes.text} ${classes.mt10}`}>  6.	I agree that the terms of this borrower certification and agreement will apply to any forbearance plan that I may be offered based on this application.
                        </p>
                          <p className={`${classes.text} ${classes.mt10}`}>7. I consent to being contacted concerning this application for mortgage assistance at any telephone number, including mobile telephone number, or email address I have provided to the lender, servicer, or authorized third party.* By submitting this application with my cell phone number and/or email address, I am granting express consent to contact me at that the number and e-mail address provided about my Loan. My consent allows the servicer of my loans to use text messaging, artificial or pre-recorded voice messages and automatic dialing technology for informational and account service calls. Message and data rates may apply.
                          * An authorized third party may include, but is not limited to, a housing counseling agency, Housing Finance Agency (HFA) or other similar entity that is assisting me in obtaining a foreclosure prevention alternative.
</p>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <div className={classes.agreeContainer}>
                    <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox
                          checked={isQAgree}
                          onChange={(event) => isQRadioHandler(event)}
                          name="agree"
                        />}
                        label="I agree to the Terms and Conditions"
                        className={classes.inputStyle}
                        classes={{
                          label: isQAgree ? classes.activeRadioLabel : classes.radioLabel
                        }}
                      />
                    </FormGroup>
                    {isQAgreeError !== '' && <p className={classes.isQAgreeError}>{isQAgreeError}</p>}
                  </div>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} sm={12} style={{ position: 'relative' }} className={classes.covidBtnContainer}>
                  <Box p={1} textAlign="right" className={classes.printBtn}>
                    <ReactToPrint
                      trigger={() => <Box className={classes.printBtnIcon} >
                        Print Form
                      <PrintIcon className={classes.printIconStyle} />
                      </Box>}
                      content={() => componentRef.current}
                    />
                  </Box>
                  {covidError !== '' && <p className={classes.ErrorMsg}>{covidError}</p>}
                  <Box className={`${classes.btnContainer}`}
                    display="flex" justifyContent="center" style={{ paddingBottom: '34px', paddingTop: '40px' }}>
                    <Button className={`${classes.btn} ${classes.resetButton}`} mt={2}
                      variant="outlined"
                      onClick={cancelForm}>
                      Cancel
                  </Button>
                    <Button className={`${classes.btn} ${classes.submitButton}`} mt={2}
                      disabled={isAdmin() || (isSubmit || mobileError !== '' || (checkErrorObject() !== 0) || !covidPostData.mobileNumber || !covidPostData.primaryName || !covidPostData.emailID
                        || !covidPostData.accountType || (!covidPostData.owner && !covidPostData.rental && !covidPostData.vacant)
                        || !covidPostData.impacted250 || !covidPostData.workShutdown || !covidPostData.payingWhileShutdown
                        || !covidPostData.unemploymentMeantime || !covidPostData.otherSourceOfIncome || !covidPostData.paymentsDuringShutdown) ? true : false}
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={submitQualifiedCovidData}>
                      {isSubmit ? <CircularProgress color='primary' style={{ width: 25, height: 25 }} /> : 'SUBMIT'}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>}

          {covid19Data.isQualified && <Box className={classes.covidInfoContainer}>
            <h3 className={`${classes.listHeading} ${classes.extendHeading}`}
              style={{ marginTop: '20px', marginBottom: '20px' }}>To request additional assistance, please
              complete the form below: </h3>
            <CovidCheckbox
              click={isQualifiedCheckBoxHandler}
              change={isQualifiedRadioHandler}
              forbearanceOf={covidPostData.forbearanceOf ? covidPostData.forbearanceOf : false}
              suspensionOfPayments={covidPostData.suspensionOfPayments ? covidPostData.suspensionOfPayments : false}
              contactedViaEmail={covidPostData.contactedViaEmail ? covidPostData.contactedViaEmail : false}
              contactedOnCellPhone={covidPostData.contactedOnCellPhone ? covidPostData.contactedOnCellPhone : false}
              impactedCov19={covidPostData.impactedCov19 ? covidPostData.impactedCov19 : false}
            />
            <div className={classes.agreeContainer}>
              <FormGroup row>
                <FormControlLabel
                  control={<Checkbox
                    checked={isQAgree}
                    onChange={(event) => isQRadioHandler(event)}
                    name="agree"
                  />}
                  label="I agree to the Terms and Conditions"
                  className={classes.inputStyle}
                  classes={{
                    label: isQAgree ? classes.activeRadioLabel : classes.radioLabel
                  }}
                />
              </FormGroup>
              {isQAgreeError !== '' && <p className={classes.isQAgreeError}>{isQAgreeError}</p>}
            </div>

            <Grid container>
              <Grid item xs={12} sm={12} style={{ position: 'relative' }} className={classes.covidBtnContainer}>
                <Box p={1} textAlign="right" className={classes.printBtn}>
                  <ReactToPrint
                    trigger={() => <Box className={classes.printBtnIcon} >
                      Print Form
                      <PrintIcon className={classes.printIconStyle} />
                    </Box>}
                    content={() => componentRef.current}
                  />
                </Box>
                {covidError !== '' && <p className={classes.ErrorMsg}>{covidError}</p>}
                <Box className={`${classes.btnContainer}`}
                  display="flex" justifyContent="center" style={{ paddingBottom: '34px', paddingTop: '40px' }}>
                  <Button className={`${classes.btn} ${classes.resetButton}`} mt={2}
                    variant="outlined"
                    onClick={cancelForm}>
                    Cancel
                  </Button>
                  <Button className={`${classes.btn} ${classes.submitButton}`} mt={2}
                    disabled={isAdmin() || (isSubmit || (!covidPostData.suspensionOfPayments || !covidPostData.forbearanceOf || !covidPostData.impactedCov19)) ? true : false}
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={submitQualifiedCovidData}>
                    {isSubmit ? <CircularProgress color='primary' style={{ width: 25, height: 25 }} /> : 'SUBMIT'}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>}

          {covid19Data.isAdditionalDeferment ?
            <Box className={classes.formSection} style={{ marginTop: '20px' }} id="printMe" >
              <h3 className={`${classes.listHeading} ${classes.extendHeading}`}
                style={{ marginBottom: '20px' }}>To request additional assistance, please complete the form below. Once all questions have been answered, please hit the 'Submit' to submit your application </h3>

              {covid19Data.isAdditionalDefermentQ8 ?
                <Grid container className={classes.radioBtnMargin}>
                  <Grid item xs={12} sm={6}>
                    <p className={`${classes.subText} ${classes.subTextMargin}`}>Are you requesting to extend your forbearance?<span className={classes.required}>*</span> </p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <RadioGroup row className={`${classes.smallWidth}`}
                      value={addAssistState.extend_forB}
                      onChange={handleRadioExtend}>
                      <FormControlLabel
                        name="extend_forB"
                        value="Y"
                        checked={addAssistState.extend_forB === 'Y' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="Yes"
                        classes={{
                          label: addAssistState.extend_forB === 'Y' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                      <FormControlLabel
                        name="extend_forB"
                        value="N"
                        checked={addAssistState.extend_forB === 'N' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="No"
                        classes={{
                          label: addAssistState.extend_forB === 'N' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                    </RadioGroup >
                    {covid19Data.isForbearanceExtDays && <Box className={classes.radioBtnMargin}>
                      <p className={`${classes.subText}`} style={{ marginBottom: '12px' }}>
                        Please select your forbearance extension period<span className={classes.required}>*</span></p>
                      <RadioGroup row className={classes.smallWidth}
                        value={addAssistState.extend_time}
                        onChange={handleRadioExtend}>
                        <FormControlLabel
                          name="extend_time"
                          value="3 Months"
                          checked={addAssistState.extend_time === '3 Months' ? true : false}
                          control={<Radio classes={{ root: classes.radioStyle }} />}
                          label="3 Months"
                          classes={{
                            label: addAssistState.extend_time === '3 Months' ? classes.activeRadioLabel : classes.radioLabel
                          }} />
                        <FormControlLabel
                          name="extend_time"
                          value="6 Months"
                          checked={addAssistState.extend_time === '6 Months' ? true : false}
                          control={<Radio classes={{ root: classes.radioStyle }} />}
                          label="6 Months"
                          classes={{
                            label: addAssistState.extend_time === '6 Months' ? classes.activeRadioLabel : classes.radioLabel
                          }} />
                      </RadioGroup >
                    </Box>}

                  </Grid>
                </Grid> : null}

              {covid19Data.isAdditionalDefermentQ7
                ?
                <Grid container className={classes.radioBtnMargin}>
                  <Grid item xs={12} sm={6}>
                    <p className={`${classes.subText} ${classes.subTextMargin}`}>Did you experience a hardship due to Covid-19?<span className={classes.required}>*</span> </p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <RadioGroup row className={`${classes.smallWidth}`}
                      value={addAssistState.experience_hardship}
                      onChange={handleRadioExtend}>
                      <FormControlLabel
                        name="experience_hardship"
                        value="Y"
                        checked={addAssistState.experience_hardship === 'Y' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="Yes"
                        classes={{
                          label: addAssistState.experience_hardship === 'Y' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                      <FormControlLabel
                        name="experience_hardship"
                        value="N"
                        checked={addAssistState.experience_hardship === 'N' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="No"
                        classes={{
                          label: addAssistState.experience_hardship === 'N' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                    </RadioGroup >
                    <Box>
                      {covid19Data.isbtnDefermentSubmit && covid19Data.isLMA90Up &&
                        <>
                          <ul className={classes.covidDeferList}>
                            <li><p className={`${classes.subText}`}>
                              You will need to complete a
                        <Link to="/additionalform" className={classes.covidLinkStyle}>Loss Mitigation Application(LMA)</Link> to determine what type of assistance might available</p>
                            </li></ul>
                          <p className={`${classes.subText} ${classes.innerTextPadding}`}>
                            Please download and fill out LMA. You can email the completed form for custimercare@bsifinancial.com</p>
                        </>}

                      {covid19Data.isbtnDefermentSubmit && covid19Data.isLMA90Down &&
                        <ul className={classes.covidDeferList}>
                          <li><p className={`${classes.subText}`}>
                            Unfortunately, you are not eligibe for Covid-19 assistance, but you stil have options. Someone will be contecting
                        in next 3 business days. If you'd like to apply for help now, Please fill a <Link to="/additionalform" className={classes.covidLinkStyle}>LMA</Link> and email it back to
                        customercare@bsifinancial.com </p></li>
                        </ul>}
                    </Box>
                  </Grid>
                </Grid> : null}

              {covid19Data.isAdditionalDefermentQ2 ?
                <Grid container className={classes.radioBtnMargin}>
                  <Grid item xs={12} sm={6}>
                    <p className={`${classes.subText} ${classes.subTextMargin}`}>Is the Reason for Default temporary or long term?<span className={classes.required}>*</span></p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <RadioGroup row className={`${classes.smallWidth} ${classes.smallWidth}`}
                      value={addAssistState.long_term}
                      onChange={handleRadioExtend}>
                      <FormControlLabel
                        name="long_term"
                        value="T"
                        checked={addAssistState.long_term === 'T' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="Temporary"
                        classes={{
                          label: addAssistState.long_term === 'T' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                      <FormControlLabel
                        name="long_term"
                        value="LT"
                        checked={addAssistState.long_term === 'LT' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="Long Term"
                        classes={{
                          label: addAssistState.long_term === 'LT' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                    </RadioGroup >
                  </Grid>
                </Grid> : null}

              {covid19Data.isAdditionalDefermentQ3 ?
                <Grid container className={classes.radioBtnMargin}>
                  <Grid item xs={12} sm={6}>
                    <p className={`${classes.subText} ${classes.subTextMargin}`}>Has the Reason for Default been Resolved?<span className={classes.required}>*</span> </p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <RadioGroup row className={`${classes.smallWidth}`}
                      value={addAssistState.default_resolved}
                      onChange={handleRadioExtend}>
                      <FormControlLabel
                        name="default_resolved"
                        value="Y"
                        checked={addAssistState.default_resolved === 'Y' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="Yes"
                        classes={{
                          label: addAssistState.default_resolved === 'Y' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                      <FormControlLabel
                        name="default_resolved"
                        value="N"
                        checked={addAssistState.default_resolved === 'N' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="No"
                        classes={{
                          label: addAssistState.default_resolved === 'N' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                    </RadioGroup >

                    {covid19Data.isbtnDefermentSubmit && <Box>

                      {covid19Data.isNotFinalMonth &&
                        <><p className={`${classes.subText} ${classes.innerText}`}>If you are not yet in the final month of
                        your forbearance plan, please check back at that time to see whether you are eligible for a automatic extension
                        of your Forbearance period. If however, you would like to seek a different Loss Mitigation options,
                        such as a loan modification, we will need you to fill out a <Link to="/additionalform" className={classes.covidLinkStyle}>LMA</Link> in order to proceed with further
                        Loss Mitigation</p>
                          <p className={`${classes.subText} ${classes.innerText}`}>Please fill out the <Link to="/additionalform" className={classes.covidLinkStyle}>LMA</Link> and email it back to the customercare@bsifinancial.com</p></>}
                    </Box>}

                    {addAssistState.default_resolved === 'N' && covid19Data.isLoanIsInFinalMonth &&
                      <><p className={`${classes.subText}`} style={{ marginTop: '25px', marginBottom: '10px' }}>
                        Do you need an additional 6 months of payment forbearance<span className={classes.required}>*</span></p>
                        <RadioGroup row className={classes.smallWidth}
                          value={addAssistState.additionalPaymentForbearance}
                          onChange={handleRadioExtend}>
                          <FormControlLabel
                            name="additionalPaymentForbearance"
                            value="Y"
                            checked={addAssistState.additionalPaymentForbearance === 'Y' ? true : false}
                            control={<Radio classes={{ root: classes.radioStyle }} />}
                            label="Yes"
                            classes={{
                              label: addAssistState.additionalPaymentForbearance === 'Y' ? classes.activeRadioLabel : classes.radioLabel
                            }} />
                          <FormControlLabel
                            name="additionalPaymentForbearance"
                            value="N"
                            checked={addAssistState.additionalPaymentForbearance === 'N' ? true : false}
                            control={<Radio classes={{ root: classes.radioStyle }} />}
                            label="No"
                            classes={{
                              label: addAssistState.additionalPaymentForbearance === 'N' ? classes.activeRadioLabel : classes.radioLabel
                            }} />
                        </RadioGroup ></>}

                  </Grid>
                </Grid> : null}

              {covid19Data.isAdditionalDefermentQ4 ?
                <Grid container className={classes.radioBtnMargin}>
                  <Grid item xs={12} sm={6}>
                    <p className={`${classes.subText} ${classes.subTextMargin}`}>Are you willing or able to resolve the deliquency by bringing the account current with Reinstallment<span className={classes.required}>*</span> </p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <RadioGroup row className={`${classes.smallWidth}`}
                      value={addAssistState.willing_to_deliquency}
                      onChange={handleRadioExtend}>
                      <FormControlLabel
                        name="willing_to_deliquency"
                        value="Y"
                        checked={addAssistState.willing_to_deliquency === 'Y' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="Yes"
                        classes={{
                          label: addAssistState.willing_to_deliquency === 'Y' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                      <FormControlLabel
                        name="willing_to_deliquency"
                        value="N"
                        checked={addAssistState.willing_to_deliquency === 'N' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="No"
                        classes={{
                          label: addAssistState.willing_to_deliquency === 'N' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                    </RadioGroup >

                    {covid19Data.isbtnDefermentSubmit && covid19Data.willingResolveDelinquency === 'Y' && <Box>
                      <ul className={classes.covidDeferList}>
                        <li>
                          <p className={`${classes.subText}`}>
                            <Link to="/PaymentOption" className={classes.anchorLink}>Click here</Link> to make a payment now</p>
                        </li>
                      </ul>
                    </Box>}
                  </Grid>
                </Grid> : null}

              {covid19Data.isAdditionalDefermentQ5 ?
                <Grid container className={classes.radioBtnMargin}>
                  <Grid item xs={12} sm={6}>
                    <p className={`${classes.subText} ${classes.subTextMargin}`}>Are you able to afford a repayment plan to bring the account current?<span className={classes.required}>*</span></p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <RadioGroup row className={`${classes.smallWidth}`}
                      value={addAssistState.afford_repayment}
                      onChange={handleRadioExtend}>
                      <FormControlLabel
                        name="afford_repayment"
                        value="Y"
                        checked={addAssistState.afford_repayment === 'Y' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="Yes"
                        classes={{
                          label: addAssistState.afford_repayment === 'Y' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                      <FormControlLabel
                        name="afford_repayment"
                        value="N"
                        checked={addAssistState.afford_repayment === 'N' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="No"
                        classes={{
                          label: addAssistState.afford_repayment === 'N' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                    </RadioGroup >
                    {covid19Data.isbtnDefermentSubmit && covid19Data.ableToAffordRepaymentPlan === 'Y' && <Box>
                      <ul className={classes.covidDeferList}>
                        <li><p className={`${classes.subText}`}>
                          We will call you within 2 -5 business working days to discuss the repayment amount that you can afford</p>
                        </li>
                      </ul>
                    </Box>}
                  </Grid>
                </Grid> : null}

              {covid19Data.isAdditionalDefermentQ6 ?
                <Grid container className={classes.radioBtnMargin}>
                  <Grid item xs={12} sm={6}>
                    <p className={`${classes.subText} ${classes.subTextMargin}`}>Are you able to continue making your existing contarctual monthly payment?<span className={classes.required}>*</span> </p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <RadioGroup row className={`${classes.smallWidth}`}
                      value={addAssistState.existing_pay}
                      onChange={handleRadioExtend}>
                      <FormControlLabel
                        name="existing_pay"
                        value="Y"
                        checked={addAssistState.existing_pay === 'Y' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="Yes"
                        classes={{
                          label: addAssistState.existing_pay === 'Y' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                      <FormControlLabel
                        name="existing_pay"
                        value="N"
                        checked={addAssistState.existing_pay === 'N' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="No"
                        classes={{
                          label: addAssistState.existing_pay === 'N' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                    </RadioGroup >
                    {covid19Data.isbtnDefermentSubmit && <Box><ul className={classes.covidDeferList}>
                      {covid19Data.contractualMonthlyPayment === "Y" &&
                        <li><p className={`${classes.subText}`}>
                          Your account will be reviewed after a deferment and if approved you will receive it in the mail
                    within 7 business days, and if not approved we will call you in 5 business days.</p></li>}

                      {covid19Data.contractualMonthlyPayment === "N" && <>
                        <li><p className={`${classes.subText}`}>
                          You will need to complete the <Link to="/additionalform" className={classes.covidLinkStyle}>LMA</Link> to review
                           available for loss mitigation options</p></li>
                        <li><p className={`${classes.subText}`} style={{ marginTop: '15px' }}>
                          Please fill out a  <Link to="/additionalform" className={classes.covidLinkStyle}>LMA</Link> and email  it back to customercare@bsifinancial.com.
                   You will receive the Loss Mitigation Application in the mail within 5 business days</p></li></>}
                    </ul>
                    </Box>}
                  </Grid>
                </Grid> : null}

              <Grid container>
                <Grid item xs={12} sm={12} style={{ position: 'relative' }}>
                  <Box p={1} textAlign="right" className={classes.printBtn}>
                    <ReactToPrint
                      trigger={() => <Box className={classes.printBtnIcon} >
                        Print Form
                      <PrintIcon className={classes.printIconStyle} />
                      </Box>}
                      content={() => componentRef.current}
                    />
                  </Box>
                  {covidError !== '' ? <p className={classes.ErrorMsg}>{covidError}</p> : null}
                  <Box className={`${classes.btnContainer}`}
                    display="flex" justifyContent="center" style={{ paddingBottom: '34px', paddingTop: '40px' }}>
                    <Button className={`${classes.btn} ${classes.resetButton}`} mt={2}
                      variant="outlined"
                      onClick={cancelForm}>
                      Cancel
                  </Button>
                    <Button className={`${classes.btn} ${classes.submitButton}`} mt={2}
                      disabled={isAdmin() || isSubmit || (covid19Data.isCITActive ? true : !covid19Data.isAllowToAddRecord) || disableSubmitBtn()}
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={submitAddDefermentData}>
                      {isSubmit ? <CircularProgress color='primary' style={{ width: 25, height: 25 }} /> : 'SUBMIT'}
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} style={{ textAlign: 'center' }}>
                  <p className={classes.helpText}><b>Note:</b> Only two application submissions are allowed in 24 hours period. If your application requires further review please allow our team 3-6 business days.</p>
                </Grid>
              </Grid>
            </Box> : null}

          {(covid19Data.isBPLDC || covid19Data.isBPLMain) ? <Box className={classes.covidInfoContainer}>
            <CovidList possibleOptions={'true'} />
            <Box className={classes.assistStepsList}>
              <h3 className={classes.listHeading}>To see what option(s) may be available for your loan</h3>
              <ol className={classes.orderedList}>
                <li>
                  Confirm the contact information on file is correct. <Link to="/UserProfile/Profile" className={classes.covidLinkStyle}>Click here to check</Link>.
              </li>
                <li>Contact your account representative at {customerCareNumber}.
                Please be prepared to describe your hardship to account representative.
                The list of questions may help to prepare to discuss:
               </li>
              </ol>
            </Box>
            {covid19Data.isBPLDC && <><CovidQnA
              click={covidHardshipDescHandler}
              dateClick={dateCovidHardshipDescHandler}
              covidData={covidPostData} />

              <Box className={classes.agreeContainer}>
                <div>
                  <FormGroup row>
                    <FormControlLabel
                      control={<Checkbox
                        checked={isQAgree}
                        onChange={(event) => isQRadioHandler(event)}
                        name="agree"
                      />}
                      label="I agree to the Terms and Conditions"
                      className={classes.inputStyle}
                      classes={{
                        label: isQAgree ? classes.activeRadioLabel : classes.radioLabel
                      }}
                    />
                  </FormGroup>
                  {isQAgreeError !== '' && <p className={classes.isQAgreeError}>{isQAgreeError}</p>}
                </div>
              </Box>

              <Grid container>
                <Grid item xs={12} sm={12} style={{ position: 'relative' }} className={classes.covidBtnContainer}>
                  <Box p={1} textAlign="right" className={classes.printBtn}>
                    <ReactToPrint
                      trigger={() => <Box className={classes.printBtnIcon} >
                        Print Form
                      <PrintIcon className={classes.printIconStyle} />
                      </Box>}
                      content={() => componentRef.current}
                    />
                  </Box>
                  {covidError !== '' && <p className={classes.ErrorMsg}>{covidError}</p>}

                  <Box className={`${classes.btnContainer}`}
                    display="flex" justifyContent="center" style={{ paddingBottom: '34px', paddingTop: '40px' }}>
                    <Button className={`${classes.btn} ${classes.resetButton}`} mt={2}
                      variant="outlined"
                      onClick={cancelForm}>
                      Cancel
                  </Button>
                    <Button className={`${classes.btn} ${classes.submitButton}`} mt={2}
                      disabled={isAdmin() || (isSubmit || !covidPostData.impactedCov19 || !covidPostData.isRentalProperty || !covidPostData.isTenantInProperty
                        || (covidPostData.isTenantInProperty === 'Y' && !covidPostData.isTenantMakingFullPayments) || !covidPostData.areYouProhibitedEvictingTenant
                        || !covidPostData.isPropertyRenovatedOrRehabilitated
                        || (covidPostData.isPropertyRenovatedOrRehabilitated === 'Yes' && (covidPostData.percentComplete === '' || (covidPostData.renovationDateRadio === 'Yes' && !covidPostData.renovationDate) || !covidPostData.foreseeCashFlow))
                        || !covidPostData.isPropertyListedForSale || !covidPostData.haveAnotherSourceOfIncome
                        || !covidPostData.monthlyMortgagePaymentDate) ? true : false}
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={submitQualifiedCovidData}>
                      {isSubmit ? <CircularProgress color='primary' style={{ width: 25, height: 25 }} /> : 'SUBMIT'}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </>}

            {covid19Data.isBPLMain ? <Box className={classes.covidList}>
              <h3 className={classes.listHeading}>Things to consider when applying for COVID-19 assistance. </h3>
              <ul className={classes.covidOptionsList}>
                <li> How you been impacted by COVID-19?</li>
                <li>Is the property associated with this loan a rental property?</li>
                <li>Is there a tenant in the property
                  <ul className={classes.covidOptionsList}>
                    <li>If so, is the tenant continuing to make full payments?</li>
                    <li>If not, are you prohibited from evicting the tenant?</li>
                  </ul>
                </li>
                <li>Is the property still being renovated or rehabilitated?
                  <ul className={classes.covidOptionsList}>
                    <li>If so, how was the project impacted?</li>
                    <li>What percentage of project id completed?</li>
                    <li>Do you have a date when your renovation will resume, if on hold, or or an anticipated completion date?</li>
                    <li>Do you have foresee cash flow in future?</li>
                  </ul>
                </li>
                <li>Is the property listed for sale?</li>
                <li>Do you have another source of income?</li>
                <li>When do you anticipate being able to make complete monthly mortgage payment?</li>
                <li>If your loan is nearing maturity, when do you anticipate being able to satisfy your complete loan balance?</li>
              </ul>

              <Box p={1} textAlign="right" className={classes.printBtns}>
                <ReactToPrint
                  trigger={() => <Box className={classes.printBtnIcon}>
                    Print Form
                      <PrintIcon className={classes.printIconStyle} />
                  </Box>}
                  content={() => componentRef.current}
                />
              </Box>
            </Box> : null}
          </Box> : null}

        </Box>
      </Box>

      {
        covidConfirmAlert && <CovidConfirmAlert
          toggleOpen={toggleCovidAlertBox}
          open={covidConfirmAlert}
          confirmCovidSubmit={confirmCovidSubmit}
          covidConfirmAlertProgress={covidConfirmAlertProgress}
        />
      }


    </Box >
  );
  }
}
