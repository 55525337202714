import React from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './styles';

export const BoxWithShadow = React.memo((props) => {
  const classes = useStyles();
  return (<Box className={classes.mainContainer} style={{ background: props.bgColor, boxShadow: props.boxShadow, position: props.position }}>
    {props.children}
  </Box>
  )
})