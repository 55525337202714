import React from 'react';
import { useStyles } from './styles';
import { Box, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';


export const ManageAutoPayAlert = (props) => {

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={props.handleClosePopup} open={true}>
     
      <Box className={classes.root}>
        <IconButton onClick={props.handleClosePopup} className={classes.closeIcon}>
          <CancelIcon />
        </IconButton>
          <Box className={classes.cardBody}>
              <p className={classes.statusTitle}>
                Do you wish to continue with one time payment?
              </p>
        </Box>
        <Box className={classes.cardFooter}>
          <Box className={classes.footerItem}>
            <Button
              variant='contained'
              color='primary'
              className={classes.btn}
              onClick={props.handleClosePopup}
            > No</Button>
          </Box>
          <Box className={classes.footerItem}>
            <Button
              variant='contained'
              color='primary'
              className={classes.btn}
              onClick={props.routeToManageAutoPay}
            > Yes</Button>
          </Box>
        </Box>
      </Box> 
     
    </Dialog>
  )
}
