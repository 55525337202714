import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box, CssBaseline, CircularProgress, Grid, Typography, Button,
  FormControlLabel, FormGroup, Checkbox, FormControl, InputLabel, Select, MenuItem, ClickAwayListener
} from '@material-ui/core';
import { CutomTextFieldEmail, CutomTextField, useStyles } from './styles';
import {
  submitPayoff, getContent, getPayoffVOMDetails,getPartialSurvey, resendEmailPayoff,
  readandConvertToPDF, downloadPayoffPDF,insertActivityLog, uploadPayoffDocuments, deletePayoffDocuments, downloadPayoffDocument, getPayoffReasons
} from '../../actions';
import Cookies from 'js-cookie';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker, DateTimePicker
} from '@material-ui/pickers';
import { decrypt, encrypt } from '../../helpers/encrypt';
import { renderDefaultMsg, scrollTop, checkValidEmail, maskLoan, isAdmin, getAppSource, getUserType, getPublicIp, getPointPrivateLabel } from '../../helpers';
import { FILE_LENGTH, FILE_LENGTH_LIMIT } from '../../helpers/constants';
import { useSelector } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import ReactToPrint from 'react-to-print';
import TermAndConditions from '../TermAndConditions'
import { withStyles } from '@material-ui/core/styles';
import { MessageManager } from '../MassageManager';
import { Timer } from '../Timer';
import {PayoffRequestSurvey} from './PayoffRequestSurvey';
import {PayoffRequestSurveyQuestions} from './PayoffRequestSurveyQuestions';
import PrintIcon from '@material-ui/icons/Print';
import moment from 'moment';
import CustomBreadcrum from '../Breadcrum';
import { SnackBar } from '../../components';
import PropTypes from 'prop-types';
import clock from '../../assets/clock.png';
import checkmark from '../../assets/checkmark.png';
import fileReplace from '../../assets/fileReplace.png';
import image from '../../assets/image.png';

import { styled } from '@material-ui/core/styles';
import { FileSizeAlert } from './FileSizeAlert';
import { UploadFileLengthValidationAlert } from './UploadFileLengthValidationAlert';
import { DeleteConfirmation } from './DeleteConfirmationOnAccount/DeleteConfirmation';
import DeleteIcon from '@material-ui/icons/Delete';
const businessMoment = require('moment-business-days');
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import tick from '../../assets/PayoffRequest/tick.svg';

const LightTooltip = withStyles((theme) => ({
  arrow: {
    color: '#235A86'
  },
  tooltip: {
    backgroundColor: '#235A86',
    color: '#fffff',
    fontFamily: "Montserrat",
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    padding: "16px",
    fontSize: 14,
    maxWidth: 400,
    fontWeight: 500,
    borderRadius: 8
  },
}))(Tooltip);

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const PayoffRequest = (props) => {
  const mytheme = useSelector(state => state.theme);
  const classes = useStyles();
  const dispatch = useDispatch();
  const componentRef = useRef();
  const themeData = useTheme();
  const mediaQuery = useMediaQuery(themeData.breakpoints.down('sm'));

  const [isProcessSubmit, setIsProcessSubmit] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [inputEmail, setInputEmail] = useState('')
  const [instructions, setInstructions] = useState('')
  const [tncSummary, setTncSummary] = useState('')
  const [tooltipText, setTooltipText] = useState('')
  const [inputDate, setInputDate] = React.useState('');
  const [isLoader, setIsLoader] = useState(false)
  const [userEmail, setUserEmail] = useState('')
  const [isRestrict, setIsRestrict] = useState(false)
  const [requestSurvey,setRequestSurvey] = useState(false);
  const [requestSurveyQuestions,setRequestSurveyQuestions] = useState(false);

  const [downloadLink, setDownloadLink] = useState('')
  const [isProcessSendMail, setIsProcessSendMail] = useState(false)
  const [isProcessDownload, setIsProcessDownload] = useState(false)
  const [payOffAmt, setPayOffAmt] = useState('');
  const [isEmailSent, setIsEmailSent] = useState('');
  const [isGlobalNoteUpdated, setIsGlobalNoteUpdated] = useState('');
  const [checkboxState, setCheckboxState] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [minDisableDate, setMinDisableDate] = useState(null);
  const [selectedInputDate, setSelectedInputDate] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [hasError, setHasError] = useState(false);
  const [requiredDate, setRequiredDate] = useState(false);
  const [resendMailState, setResendMailState] = useState('');
  const [leftTime, setLeftTime] = useState(0)
  const [progress, setProgress] = React.useState(100);
  const [message, setMessage] = useState("In order to provide your payoff quote do not refresh or click away from this page. Doing so could result in an error in generating your quote.")
  const [isValidEmail, setIsValidEmail] = useState(true)
  const [requestStatus, setRequestStatus] = useState(null);
  const [createdDate, setCreatedDate] = useState();
  const [requestId, setRequestId] = useState();
  const [tatDays, setTatDays] = useState(parseInt(Cookies.get("payOffRequestSLA")));
  const [tatStatus, setTatStatus] = useState(false);
  const [onlineStatus, setOnlineStatus] = useState(false);
  const [emailSentStatus, setEmailSentStatus] = useState(false);
  const [tooltipMsg, setTooltipMsg] = useState('');
  const [isRequestSubmitted,setRequestSubmitted]=useState(false);
  const [surveyStatus,setSurveyStatus] = useState(false);
  const [partiallySubmitedData,setPartiallySubmitedData] = useState();
  const [popupTexts, setPopupTexts] = useState({heading: 'Payoff Survey - Mortgage Awareness and Feedback', title: "Thank you for being a valued customer of BSI. We noticed you're exploring refinancing options or are attempting to pay off your loan. We value your feedback and, in order for us to better serve you, we would appreciate your insights by taking 2 minutes to answer a few questions for us.", show: true})

  //useEffect(() => {
  //     let timer = 120
  //     const interval = setInterval(() => {
  //         setProgress((prevProgress) => (prevProgress === 0 ? 100 : prevProgress - .825));
  //         if (timer === 0) {
  //             setMessage("")
  //             setLeftTime("Completed")
  //             clearInterval(interval)
  //         } else {
  //             setLeftTime(timer--)
  //         }
  //     }, 1000);
  //     return () => clearInterval(interval);
  // }, []);
  const [payOffRequest, setPayOffRequest] = useState('');
  const [allowedExtensionsStatus, setAllowedExtensionsStatus] = useState(false);
  const [file, setFilesName] = useState('');
  const [fileLengthStatus, setFileLengthStatus] = useState(false);
  const [showMessageStatus, setShowMessageStatus] = useState(false);
  const [replaceStatus, setReplaceStatus] = useState(false);
  const [documentId, setDocumentId] = useState(0);
  const [documentCode, setDocumentCode] = useState(0);
  const [alertStatus, setAlertStatus] = useState(false);
  const [specialChar, setFileContainSpecialChar] = useState(false);
  const [docs, setDocs] = useState([]);
  const [replaceIndex, setReplaceIndex] = useState();
  const [deleteStatus, setDeletePopup] = useState(false);
  const [counter, setCounter] = useState(1);
  const [calenderRestrictionStatus, setCalenderRestStatus] = useState(false);
  const [payoffReasons, setPayoffReasons] = useState([]);
  const [supportedDocDesc, setSupportedDocDesc] = useState(null);

  const loanInfoData = useSelector(state => state?.loanInfoReducer);
  const accounts = useSelector(state => state?.accounts);
  const [openToolTip, setOpenToolTip] = useState(false);
  const [openPayOffToolTip, setOpenPayOffToolTip] = useState(false)
  
  const handlePayOffTooltipOpen = () => {
    setOpenPayOffToolTip(true);
  };

  const handlePayOffTooltipClose = () => {
    setOpenPayOffToolTip(false);
  };

  const handleTooltipClose = () => {
    setOpenToolTip(false);
  };

  const handleTooltipOpen = () => {
    setOpenToolTip(true);
  };

  const handleAlertClose = () => {
    setAlertStatus(false)
  }
 
  const handleFileLengthAlertClose = () => setFileLengthStatus(false)


  useEffect(() => {
    getContentData();
   // getPayoffRequestStatus();
    scrollTop();
  }, [])
 
  useEffect(() => {
    if ((accounts?.length > 0) && loanInfoData.bankDetail && props && props.accountNumber) {
      const index = accounts.findIndex((item) => item.accountNumber == props.accountNumber);
      if ((accounts[index]?.propertyState?.toUpperCase() == 'FL') && (loanInfoData.bankDetail.bankruptcyFlag?.toUpperCase() !== 'A') && (loanInfoData.bankDetail.fclStatus?.toUpperCase() !== 'ACTIVE') && (loanInfoData.bankDetail.fclStatus?.toUpperCase() !== 'HOLD')) {
        setCalenderRestStatus(true)
        getPayoffDetail(true);
      } else {
        setCalenderRestStatus(false)
        getPayoffDetail(false);
      }
    }
  }, [loanInfoData])
  
  useEffect(()=>{
    const previousPage = Cookies.get('previousPage');
    setTimeout(()=>{
      Cookies.remove('previousPage');
    },10)

    return(()=>{
      if(previousPage==='maturity' && !isRequestSubmitted){
         Cookies.set('maturityExtensionMenu',true);
      }
      else if(isRequestSubmitted){
        Cookies.remove('maturityExtensionMenu');
      }
    })
  },[])

  useEffect(() => {
    if (requestStatus == "InProgress") {
     let interval = setInterval(() => {
      getPayoffDetail(calenderRestrictionStatus);
    }, 60000);
    return () => {
      clearInterval(interval);
    }
  }
  }, [requestStatus])
  
  useEffect(() => {
    if (props.accountNumber) {
      getPayoffReason();
    }
  }, [props.accountNumber])


  const getContentData = async () => {
    setIsLoader(true)
    const res = await dispatch(getContent('PC018'));
    setIsLoader(false)
    if (res && res.length !== 0) {
      setInstructions(res[0].contentHeader)
      setTooltipText(res[0].contentSubHeader)
      setTncSummary(res[0].contentValue)
    }
  }
 
  const getPayoffReason = async () => {
    const requestData = {
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }

    const result = await getPayoffReasons(requestData);
    if (result) {
      const { lstResponseStatus, responseData } = result?.responseData;
      if (result && (lstResponseStatus[0]?.statusCode === "0" || lstResponseStatus[0]?.statusCode === 0)) {
        setPayoffReasons(responseData)
      } else {
        setErrorMessage(lstResponseStatus[0]?.statusDesc)
      }
    } else {
      setErrorMessage(renderDefaultMsg('400'))
    }
  }
 
  const setReason = (item) => {
    setSupportedDocDesc(item.documentDesc)
    setPayOffRequest(item.payoffReason)
  }
  
  let allReasons = '';
  if (payoffReasons && payoffReasons.length) {
    allReasons = payoffReasons.map((item, index) => {
      return (
        <MenuItem value={item.payoffReason} key={item.payoffReason} onClick={() => setReason(item)}>{item.payoffReason}</MenuItem>
      )
    })
  }

  const getPartiallySubmittedSurvey=async()=>{
    const requestData = {
      "accountNumber": props.accountNumber ? props.accountNumber : '',
      "SurveyType": "Payoff",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }

    const result = await getPartialSurvey(requestData);

    if (result) {
      const { lstResponseStatus, responseData } = result?.responseData;
      if (result && (lstResponseStatus[0]?.statusCode === "0" || lstResponseStatus[0]?.statusCode === 0)) {
        setPartiallySubmitedData(responseData);
        setRequestSurveyQuestions(true);
        
      } else {
        setErrorMessage(lstResponseStatus[0]?.statusDesc)
      }
    } else {
      setErrorMessage(renderDefaultMsg('400'))
    }
  }

  const handleCloseSurvey=(flag)=>{
    if(flag)
    setRequestSurveyQuestions(false);
    setSurveyStatus(flag);
  }

  const getPayoffDetail = async (calenderRestrictionStatus) => {
    setEmailSentStatus(false)
    setCheckboxState(false);
    setResendMailState('');
    const requestData = {
      "accountNumber": props.accountNumber ? props.accountNumber : '',
      "RequestType": "payoff",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }

    setErrorMessage('')
    setSuccessMessage('')
    const result = await getPayoffVOMDetails(requestData);

    if (result && result.responseData) {
      const { lstResponseStatus, responseData } = result.responseData;
      if (result && (lstResponseStatus[0]?.statusCode === "0" || lstResponseStatus[0]?.statusCode === 0)) {
        let ccEmail = responseData?.ccEmail ? decrypt(responseData?.ccEmail) : '';
        const isSurveyPartiallySubmitted = responseData?.isSurveyPartiallySubmitted;
        let dt = ((responseData?.goodThroughDate !== '') && responseData?.isRestrict24hr) ? ymdDate(responseData.goodThroughDate) : (calenderRestrictionStatus ? businessMoment().businessAdd(7)._d : moment().add(15, 'day'))
        let userEmail = responseData?.userEmailId ? decrypt(responseData.userEmailId) : ''
        setUserEmail(userEmail);
        setSurveyStatus(isSurveyPartiallySubmitted);
        setInputEmail(responseData?.isRestrict24hr ? ccEmail : "")
        setInputDate(dt)
        setRequestStatus(responseData.requestStatus)
        setCreatedDate(responseData.createdDate)
        setRequestId(responseData.id)
        setOnlineStatus(responseData.isOnline)
        ymdDate(inputDate)
        setIsRestrict(responseData.isRestrict24hr)
        setDownloadLink(responseData.payOffStatementPDFLocationToView)
        setPayOffAmt(responseData.PayOffAmount);
        setIsEmailSent(responseData.IsEmailSent);
        setIsGlobalNoteUpdated(responseData.IsGlobalNoteUpdated);
        moment(responseData.goodThroughDate) <= moment(new Date()) ? setMinDisableDate(null) : setMinDisableDate(moment(new Date()).add(1, 'days'))
        setTatStatus((moment(new Date()) < moment(new Date(responseData.createdDate)).add(tatDays, 'days')) ? true : false)
        const tooltipMessage = `Email will be resend to ${userEmail.toString()}${responseData.ccEmail ? '; ' + ccEmail.toString() : ''}`;
        setTooltipMsg(tooltipMessage)
      } else {
        setErrorMessage(lstResponseStatus[0]?.statusDesc)
      }
    }
    //  else {
    //   setErrorMessage(renderDefaultMsg('400'))
    // }
    scrollTop();
  }
  
  const checkboxChangeHandler = (event) => {
    setCheckboxState(event.target.checked)
  }
 
  const handleChange = (filed) => (event) => {
    if (filed === "inputEmail") {
      setInputEmail(event.target.value)
    } else if (filed === "inputDate") {
      setInputDate(event.target.value)
    }
  }
  
  const validateEmail = () => {
    setIsValidEmail(true)
    let isValid = true
    if (inputEmail) {
      let emailArray = inputEmail.split(";")
      for (let value of emailArray) {
        if (!checkValidEmail(value.trim())) {
          isValid = false
          setIsValidEmail(false)
          break
        }
      }
    }
    return isValid;
  }

  const handleSubmit = async () => {
    if (inputDate === null || inputDate === '') {
      setRequiredDate(true);
      return false;
    }
    setRequiredDate(false);
    if (!validateEmail()) {
      return false;
    }
    var privateLabelUrl =  Cookies.get("privateLabelUrl");
    const requestData = {
      "accountNumber": props.accountNumber ? props.accountNumber : '',
      "ccEmailId": inputEmail ? encrypt(inputEmail) : '',
      "goodThroughDate": dateFormat(inputDate),
      "privateLabelUrl": privateLabelUrl,
      "payOffReason": getPointPrivateLabel() ? payOffRequest : null,
      "documentIds": getPointPrivateLabel() ? docs.map((key) => key.documentId) : null,
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }

    setErrorMessage('')
    setSuccessMessage('')
    setIsProcessSubmit(true)

    const result = await submitPayoff(requestData);

    if (result) {
      const { lstResponseStatus, responseData } = result.responseData;
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {

        if((Cookies.get('refinanceFlag') == 'true') && !isAdmin()) {
             setRequestSurvey(true);
         }
         
         setRequestSubmitted(true);

        if (responseData.isAutoPayoff) {
          scrollTop();
          setIsProcessSubmit(false)
          setPayOffRequest('')
          snackBarHandler('Payoff Request Submitted Successfully.');
          getPayoffDetail(calenderRestrictionStatus);
          setSupportedDocDesc(null)
        } else {
          setIsProcessSubmit(false)
          setPayOffRequest('')
          scrollTop()
          snackBarHandler(`Your payoff request has been submitted successfully. Based on your loan status and availability of document, it may take up to ${'3-5'} business days to process your request.`);
          getPayoffDetail(calenderRestrictionStatus);
          setSupportedDocDesc(null)
        }
      } else {
        setErrorMessage(renderDefaultMsg('400'))
        setIsProcessSubmit(false)
      }

    } else {
      setErrorMessage(renderDefaultMsg('400'))
      setIsProcessSubmit(false)
    }
  }

  const emailResend = async () => {
    const requestData = {
      "accountNumber": props.accountNumber ? props.accountNumber : '',
      "id": requestId,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }

    setErrorMessage('')
    setSuccessMessage('')
    const result = await resendEmailPayoff(requestData);

    if (result) {
      const { lstResponseStatus } = result.responseData;
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        setSuccessMessage(lstResponseStatus[0].statusDesc);
        setEmailSentStatus(true)
        setResendMailState('Success');
      } else {
        setErrorMessage(lstResponseStatus[0].statusDesc)
      }
    } else {
      setErrorMessage(renderDefaultMsg('400'))
    }
    setIsProcessSendMail(false)
  }

  const ymdDate = (dt) => {
    let curDate = new Date(dt)
    let month = curDate.getMonth() + 1 < 10 ? `0${curDate.getMonth() + 1}` : curDate.getMonth() + 1
    return `${month}/${curDate.getDate()}/${curDate.getFullYear()}`
  }
  //Request payoff survey functions
  const handleSubmitClose=(button)=>{
    setRequestSurvey(false);
    setSurveyStatus(true);
    sendActivityData(button)
  }

  const sendActivityData = async (button) => {
    const remark = `Payoff Survey - User clicked on ${button} button`;
    const data = {
      "accountNumber": props.accountNumber,
      "activityCode": "UAL071",
      "activityStatus": "Success",
      "action": button,
      "controller": "PayoffRequest",
      "apiName": "PayoffRequest",
      "activityRemark":  remark,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertActivityLog(data);
  }

  const handleSubmitSurvey=()=>{
    setRequestSurveyQuestions(true);
    setRequestSurvey(false);
  }

  const downloadPDF = async () => {
    const data = {
      "accountNumber": props.accountNumber ? props.accountNumber : '',
      "id": requestId,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }

    setIsProcessDownload(true)
    setErrorMessage('')
    setSuccessMessage('')
    setEmailSentStatus(false)

    const response = await downloadPayoffPDF(data)

    if (response) {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${decrypt(props.accountNumber)}_PO`); //or any other extension
      document.body.appendChild(link);
      link.click();
    } else {
      setErrorMessage(renderDefaultMsg('400'))
    }
    setIsProcessDownload(false)
  }

  const handleDateChange = (filed) => (date) => {
    if (filed === "inputDate") {
      setInputDate(date);
      setSelectedInputDate(date)
      setIsOpen(false);
    }
  }

  const dateFormat = (selectedDate) => {
    if (selectedDate) {
      let dt = new Date(selectedDate)
      return `${dt.getMonth() + 1}/${dt.getDate()}/${dt.getFullYear()}`
    } else {
      return ''
    }
  }

  const setOpenState = () => {
    setMinDisableDate(moment(new Date()).add(1, 'days'));
    setIsOpen(true);
  }

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false)
  }

  const snackBarHandler = (msg) => {
    setHasError(false);
    setSnackBarMsg(msg);
    setOpenSnackBar(true);
  }
 
  const payoffGeneratedDate = (dateStr) => {
    var utcDate = dateStr+'Z';
    const dt = new Date(utcDate)
    const format1 = "MM/DD/YYYY HH:mm"
    return moment(dt).format(format1);
  }
 
  const handleDropdownChange = (event) => {
    setPayOffRequest(event.target.value)
  }
   
  const handleFile = (event, replace) => {
    var allowedExtensions = /(\.png|\.jpg|\.pdf)$/i;
    var specialCharacters =  /[\/:*?“<>|]/;
    var fileSize =  event.target.files[0].size / 1024 / 1024
    if (fileSize > 5) {
      setFilesName(event.target.files[0].name)
      setAlertStatus(true)
    } else if (!allowedExtensions.exec(event.target.value)) { 
      setFilesName(event.target.files[0].name)
      setAllowedExtensionsStatus(true)
    } else if (event.target.files[0].name.length > FILE_LENGTH_LIMIT) {
      setFilesName(event.target.files[0].name)
      setFileLengthStatus(true)
    }  else if (specialCharacters.test(event.target.files[0].name)) {
      setFilesName(event.target.files[0].name)
      setFileContainSpecialChar(true) 
    } else {
      onFileUpload(event.target.files[0], replace)
    }
  };
  
  const replaceDocument = (index, status, id) => {
    setReplaceStatus(status)
    setReplaceIndex(index)
    setDocumentId(id)
  }

  const onFileUpload = async (file, replace) => {
    setErrorMessage('');
    const res = await uploadPayoffDocuments(props.accountNumber, file, docs.length, replace, documentId, counter);
    if (res && res?.responseData?.lstResponseStatus && res?.responseData.lstResponseStatus[0]?.statusCode == '0') { 
      setCounter(counter + 1)
      if (replace) {
        let newObj = [...docs]  
        newObj[replaceIndex]['fileName'] = file.name
        newObj[replaceIndex]['documentId'] = res?.responseData?.responseData
        setReplaceStatus(false)
        setReplaceIndex()
        setDocumentId()
        setDocs(newObj)
      } else {
        let newObj = [...docs]
        newObj.push({fileName: file.name, documentId: res.responseData.responseData})
        setDocs(newObj)
      }
    } else if (res && res?.responseData?.lstResponseStatus && res?.responseData.lstResponseStatus[0]?.statusCode !== "0") {
      setErrorMessage(res.lstResponseStatus[0].statusDesc)
      scrollTop()
    } else {
      setErrorMessage(renderDefaultMsg('400'));
      scrollTop()
   }
  };
 
  const deleteDoc = (id, index, name) => {
    setReplaceIndex(index)
    setDocumentId(id)
    setDeletePopup(true)
    setFilesName(name)
  }

  const handleClose = () => {
    setDeletePopup(false)
  }

  const handleOk = () => {
    deleteDocuments()
  }

  const deleteDocuments = async () => {
    const response = await deletePayoffDocuments(props.accountNumber, documentId)
    if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode == 0) {  
      setDeletePopup(false)
      let newObj = [...docs]  
      newObj.splice(replaceIndex, 1)
      setDocs(newObj)
      setDocumentId()
      setReplaceIndex()
    }  else if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode !== "0") {
      setErrorMsg(response.lstResponseStatus[0].statusDesc)
      scrollTop()
    } else {
      setErrorMessage(renderDefaultMsg('400'));
      scrollTop()
   }
  } 

  const downloadDocuments = async (docName, documentId) => {
    const response = await downloadPayoffDocument(props.accountNumber, documentId)
    if (response) {
      const url = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', docName);
      document.body.appendChild(link);
      link.click();
    }
  } 

  const handleInputFile = (e) => {
    e.target.value = null;
  }

  const shortText = (text) => {
    if (text !== '') {
      return text.length < 25 ? text : text.substr(0, 25) + '...'
    } else {
      return text
    }
  }

  const validate = () => {
   if ((payOffRequest == '') && getPointPrivateLabel()) {
    return true
   } else {
    return false
   }
  }

  const disabledBtn = userEmail != '' && inputDate != '' && isRestrict === false;
  return (
    <CssBaseline>
      <Box container="true" maxWidth='xl' className={classes.root} >
        {isLoader ? (<Box py={5}><CircularProgress /></Box>) : (
          <Box className={classes.container}>
            <Box mt={3} mb={3.4}>
              <CustomBreadcrum backPage={getPointPrivateLabel() ? "Contract Details" : "Loan Details"} backPageLink="/LoanInfo" activePage="Request Payoff" />
              <Typography className={classes.headingText}>Request Payoff</Typography>
            </Box>
            <Box className={classes.formContainer} ref={componentRef}>
              <Box className={classes.instructionsContainer}>
              {errorMessage ? (<Box><Typography className={classes.errorText}>{errorMessage}</Typography></Box>) : null}

              { (!onlineStatus && tatStatus) &&
                  <Box className={classes.infoTopBox}>
                    <Box alignItems="center">
                      <Typography className={classes.info}>Your payoff request has been submitted on {moment(createdDate).format( "MM/DD/YYYY")}, {getPointPrivateLabel() ? 'it may take 3-5 business days to generate once all required documentation is received.' : 'it may take 3-5 business days to generate.'} </Typography>
                      <Typography className={classes.info}>Once it's ready, we'll send it to you via a secure email. </Typography>
                    </Box>
                    <Typography className={classes.feelFree} style={{paddingBottom: "15px", paddingTop: "15px", textAlign: "center"}}>If you don't see an email from us with your quote, please check your spam folder. Need immediate assistance? <a href="./HelpLanding?contactus" target="_self" className={classes.contactUs}>Contact us</a></Typography>
                  </Box>
              }
             
              { (requestStatus !== null) &&
                <Box className={classes.downloadContainer}>
                <Grid container direction="row">
                  <Grid item xs={0} sm={0} md={3}></Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <Box mt={1} className={classes.mbPhone}>
                            {(downloadLink !== '' && (errorMessage === '') && (requestStatus == "Completed")) ?
                              <Box style={{marginTop: "20px"}}>
                               
                                <Box className={classes.cardContainer}>
                                  <span style={{display: 'flex'}}><img src={tick} />  <Typography className={classes.payoffQuote}>To view your payoff quote please {isProcessDownload ? (<CircularProgress />) : (<span className={classes.downloadPdfLink} onClick={() => downloadPDF()}>Click here</span>)}</Typography></span>
                                 
                                </Box>
                               
                              
                              {/* <Box style={{ paddingTop: "18px", display: "flex", justifyContent: "center" }}>
                                <Typography className={classes.resendEmail} >Haven't received email of online payoff quote? <span className={emailSentStatus ? classes.disableEmailLink : classes.resendEmailLink} onClick={() => emailResend()}>Resend Email</span></Typography>
                                  <Typography>
                                   
                                    {!mediaQuery && <LightTooltip
                                      title={tooltipMsg}
                                      arrow placement="bottom" >
                                      <InfoIcon className={classes.infoTooltipIcon} />
                                    </LightTooltip> }
                                    {mediaQuery && 
                                    <ClickAwayListener onClickAway={handleTooltipClose}>
                                    <LightTooltip
                                      title={tooltipMsg}
                                        arrow placement="bottom"
                                        open={ openToolTip }
                                      >
                                        <InfoIcon className={classes.infoTooltipIcon} onClick={ handleTooltipOpen}/>
                                      </LightTooltip>
                                    </ClickAwayListener>}

                                  </Typography>
                                </Box> */}
                               {/* { emailSentStatus &&  <Box style={{ paddingTop: "5px", display: "flex", justifyContent: "center" }}><img className={classes.checkImg} src={checkmark} alt='img' /> <span className={classes.emailSendLink}>Email sent successfully!</span></Box> } */}
                              </Box> : null}

                              {(requestStatus == "InProgress") &&
                                <Box >
                                 <img className={classes.clockImg} src={clock} alt='img' />
                                 <Typography className={classes.generatePayoff} style={{paddingBottom: "10px"}}>Your request for payoff is being processed and it will take 3-5 minutes.</Typography>
                                 <Typography className={classes.feelFree} style={{paddingBottom: "20px"}}>Feel free to explore other features and come back here to check the status.</Typography>
                                </Box> 
                               }
                              
                          </Box> 
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                        { (downloadLink !== '' && (errorMessage === '') && (requestStatus == "Completed")) &&
                          <Typography className={classes.generatedDate}>Generated on {payoffGeneratedDate(createdDate)}</Typography> }
                        </Grid>
                  </Grid>
                </Box>
              }
              {surveyStatus && (Cookies.get('refinanceFlag') == 'true') && <Box style={{display:'flex'}}>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Typography className={classes.surveyLink}>{'To complete your payoff survey'}<span className={'link'} onClick={getPartiallySubmittedSurvey}>Click here</span></Typography>
                    </Grid>
              </Box>}
              {requestSurvey && <PayoffRequestSurvey open={requestSurvey} handleSubmit={handleSubmitSurvey} accountNumber={props.accountNumber} handleSubmitClose={handleSubmitClose} popupTexts={popupTexts} displayRefinance={Cookies.get('refinanceFlag') == 'true' ? true : false}></PayoffRequestSurvey>}
              {requestSurveyQuestions && <PayoffRequestSurveyQuestions accountNumber={props.accountNumber} open={requestSurveyQuestions} handleSubmitClose={handleSubmitClose} partiallySubmitedData={partiallySubmitedData} isPartial={surveyStatus} setPartialSurvey={handleCloseSurvey} displayRefinance={Cookies.get('refinanceFlag') == 'true' ? true : false} RequestType='Payoff'></PayoffRequestSurveyQuestions>}
                <Box pb={3}>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Typography className={classes.subHeading}>{'Instructions'}</Typography>
                    <Box display="flex">
                      <Typography className={`${classes.desktopViewOnly} ${classes.underline}`} >What is a Payoff Request?</Typography>
                      <Typography>
                         
                        {!mediaQuery && <LightTooltip
                            title={tooltipText}
                            arrow placement="bottom" >
                            <InfoIcon style={{ fill: "#535D6D", marginLeft: "5px" }} />
                          </LightTooltip> }
                          {mediaQuery && 
                          <ClickAwayListener onClickAway={handlePayOffTooltipClose}>
                          <LightTooltip
                            title={tooltipText}
                              arrow placement="bottom"
                              open={ openPayOffToolTip }
                            >
                              <InfoIcon style={{ fill: "#535D6D", marginLeft: "5px" }} onClick={ handlePayOffTooltipOpen}/>
                            </LightTooltip>
                          </ClickAwayListener>}
                      </Typography>
                    </Box>
                  </Grid>
                </Box>
                <Box pb={3} className={classes.instText}>
                  <Typography className={classes.text}>{instructions}</Typography>
                </Box>
                <Box pb={1}>
                  <Typography className={classes.subHeading}>{`Account ${maskLoan(decrypt(props.accountNumber))}`}</Typography>
                </Box>
              </Box>
              <Box height={"100%"}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="stretch">
                  <Grid item xs={12} sm={12} md={7}>
                    <Box height={"100%"} className={classes.accountContainer}>
                      <Box py={3} px={2} className={classes.accountEmail}>
                        <Typography>Email Address</Typography>
                        <Typography style={{ fontWeight: '600' }}>{userEmail}</Typography>
                      </Box>
                      <Box className={classes.inputContainer}>
                        <CutomTextFieldEmail
                          error={!isValidEmail}
                          id="outlined-ccemail"
                          label="Email Address CC"
                          variant="outlined"
                          type="text"
                          value={inputEmail}
                          onChange={handleChange('inputEmail')}
                          onBlurCapture={() => validateEmail()}
                          fullWidth
                          multiline
                          rows={4}
                          rowsMax={4}
                          disabled={isRestrict}
                          InputProps={{
                            classes: { root: classes.textAreaField, input: classes.input },
                          }}
                        />
                        <Typography className={classes.smallText}>Note: Email Addresses should be semi-colon separated.</Typography>
                      </Box>
                      <Box className={`${classes.inputContainer} ${classes.marBtmFixed}`}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            // clearable
                            error={(requiredDate && (inputDate === '' || inputDate === null)) ? true : false}
                            disabled={isRestrict}
                            color='primary'
                            // autoOk
                            disableToolbar
                            // minDate={moment().add(1, 'days')}
                            minDate={minDisableDate}
                            maxDate={calenderRestrictionStatus ? businessMoment().businessAdd(7)._d : undefined}
                            variant="inline"
                            inputVariant="outlined"
                            format="MM/dd/yyyy"
                            fullWidth
                            id="date-picker-inline"
                            label="Good Through Date"
                            placeholder="Good Through Date"
                            // value={inputDate ? inputDate : (calenderRestrictionStatus ? businessMoment().businessAdd(7)._d : moment().add(15, 'day'))}
                            value={inputDate ? inputDate : moment().add(15, 'day')}
                            onChange={handleDateChange('inputDate')}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                              onFocus: e => {
                                setOpenState();
                              }
                            }}
                            InputProps={{
                              classes: { root: classes.textField, input: classes.input },
                            }}
                            
                            PopoverProps={{
                              disableRestoreFocus: true,
                              onClose: () => {
                                setIsOpen(false);
                              }
                            }}
                            InputProps={{
                              onFocus: () => {
                                setOpenState();
                              }
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            open={isOpen}

                          />
                        </MuiPickersUtilsProvider>
                      </Box>
                      { getPointPrivateLabel() &&
                      <>
                      {/* <Box className={`${classes.inputContainer} ${classes.marTop}`}>
                        <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                        <InputLabel id="borrower-type-select-outlined">Select Payoff Reason <span style={{ color: "red" }}>*</span></InputLabel>
                        <Select
                          labelId="request-outlined-label"
                          id="request-select-outlined"
                          color="primary"
                          name="request"
                          value={payOffRequest}
                          label="Select Payoff Reason*"
                          className={classes.inputStyle}
                          onChange={handleDropdownChange}
                          fullWidth
                          disabled={isRestrict}
                        >
                          <MenuItem value="Sale">Sale</MenuItem>
                          <MenuItem value="Refinance">Refinance</MenuItem>
                          <MenuItem value="Own Funds">Own Funds</MenuItem>
                        </Select>
                        </FormControl>
                      </Box> */}
                      <Box className={`${classes.inputContainer} ${classes.marTop}`}>
                        <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}
                          >
                          <InputLabel id="borrower-type-select-outlined">Select Payoff Reason <span style={{ color: "red" }}>*</span></InputLabel>
                          <Select
                            labelId="borrower-type-outlined-label"
                            value={payOffRequest}
                            label="Select Payoff Reason*"
                            className={classes.inputStyle}
                            fullWidth
                            color="primary"
                            disabled={isRestrict}
                          >
                            {allReasons}
                          </Select>
                        </FormControl>
                      </Box>
                      <Box className={classes.fileUpload}>
                          <Box className={classes.chooseaFile}>
                            Attach a file:
                          </Box>
                          <Box>
                          <input
                                accept=".pdf, image/png, image/jpg"
                                type="file"
                                name='selectedFile'
                                style={{display: 'none'}}
                                id="upload-button"
                                onClick={(e) => handleInputFile(e)}
                                onChange={(e) => handleFile(e, true)}
                                
                              />
                            <Button component="label" variant="contained" className={ (isRestrict || (docs.length == 5)) ? classes.fileBtnDisable : classes.fileButton} disabled={(docs.length) == 5 || isRestrict}>
                              CHOOSE FILE
                              <VisuallyHiddenInput type="file" accept=".png, .jpg, .pdf" onClick={(e) => handleInputFile(e)}
                              onChange={(e) => handleFile(e, false)} />
                            </Button>
                          </Box>
                          <Box className={`${classes.chooseFile} info`} >
                            Max 5 files(PDF, JPG, PNG) allowed of 5 MB each
                          </Box>
                        </Box>
                       {(supportedDocDesc !== null) && <Box className={classes.suportedDoc}>(Supported Document: <span className={classes.suportedDocDesc}>{supportedDocDesc}</span>)</Box>}
                       
                          <Grid container >
                            { !isRestrict && docs?.length > 0 && docs.map((key, ind) => {  
                             return (
                              <Grid item xs={12} sm={12} md={6}>
                             
                                <Box className={classes.uploadedImageContainer}>
                                  <Box className={classes.uploadedImage}>
                                      <img className={classes.checkImg} src={image} alt='img' /> 
                                      <Typography className={classes.imageText} onClick={() => downloadDocuments(key.fileName, key?.documentId)}>{shortText(key.fileName)} </Typography>
                                      <LightTooltip
                                        arrow  placement="top"
                                          title="Delete"
                                        >
                                         <DeleteIcon color="action" onClick={() => deleteDoc(key?.documentId, ind, key.fileName)} style={{cursor: 'pointer', color: '#4D4D4D', marginRight: 10}}></DeleteIcon>
                                      </LightTooltip>
                                      <label htmlFor="upload-button">
                                      <LightTooltip
                                        arrow  placement="top"
                                          title="Replace"
                                        >
                                        <img className={classes.checkImg} src={fileReplace} alt='img' style={{cursor: 'pointer'}} onClick={() => replaceDocument( ind, true, key.documentId)}/> 
                                        </LightTooltip>
                                      </label>
                                  </Box>
                                </Box>
 
                              </Grid>
                                )})}
                          </Grid> 
                          </>
                      }
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5}>
                    <Box height={"100%"} className={classes.tncContainer}>
                      <Box py={2} >
                        <Typography className={classes.tncHeading}>Terms and Conditions</Typography>
                      </Box>
                      <Box className={classes.tncTextContainer}>
                        <Box className={classes.tncSummary}>
                          <TermAndConditions data={tncSummary}></TermAndConditions>
                        </Box>
                      </Box>

                      <Box className={classes.checkboxContainer}>
                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox
                              checked={checkboxState}
                              onChange={(event) => checkboxChangeHandler(event)}
                              name="readTnC"
                            />}
                            label="I agree to the Terms and Conditions"
                            className={classes.inputStyle}
                            classes={{
                              label: checkboxState ? classes.activeRadioLabel : classes.radioLabel
                            }}
                          />
                        </FormGroup>

                        <Box p={1} textAlign="right" className={classes.printBtn}>
                          <ReactToPrint
                            trigger={() => <Box className={classes.printBtnIcon}>
                              Print Form
                                  <PrintIcon style={{ fill: '#535D6D', fontSize: 25, marginLeft: "5px", }} />
                            </Box>}
                            content={() => componentRef.current}
                          />
                        </Box>
                      </Box>
                      { (isRestrict || (loanInfoData && loanInfoData?.purgeLoanInfo?.liquidationStatus)) &&
                        <Box className={classes.infoBox}>
                          <Box display='flex' alignItems="center">
                            <InfoIcon className={classes.infoIcon} />
                            {(loanInfoData?.purgeLoanInfo?.liquidationType == 'PayOff') &&
                              <Typography className={classes.infoText}>Your loan has been paid in full.</Typography>
                            }
                            { (loanInfoData?.purgeLoanInfo?.liquidationType == 'ServiceTransfer') &&
                              <Typography className={classes.infoText}>Your loan has been Transferred to New Servicer.</Typography>
                            }
                            { (loanInfoData?.purgeLoanInfo?.liquidationType == 'ChargeOff') &&
                              <Typography className={classes.infoText}>Your loan has been charged off.</Typography>
                            }
                            { (loanInfoData?.purgeLoanInfo?.liquidationType == 'FCLCompleted') &&
                              <Typography className={classes.infoText}>A foreclosure sale date has been held with respect to this property.</Typography>
                            }
                            { (isRestrict && !loanInfoData?.purgeLoanInfo?.liquidationStatus) &&
                              <Typography className={classes.infoText}>Only 1 request can be processed in a 24-hour period.</Typography>
                            }
                          </Box>
                        </Box>
                      }
                      {/* {successMessage ? (<MessageManager message={successMessage} type={"success"}></MessageManager>) : null} */}
                      {/* {!errorMessage ? (<MessageManager message={errorMessage} type={"error"}></MessageManager>) : null} */}

                  
                      <Box textAlign="right" pt={6}>
                        {isProcessSubmit ? (<Button className={classes.btn} disabled={true} variant="contained">
                          <CircularProgress /></Button>) :
                          (<Button
                            onClick={() => handleSubmit()}
                            className={classes.btn}
                            variant="contained"
                            color="primary"
                            disabled={resendMailState === '' ? (!disabledBtn || !checkboxState || successMessage !== '' || isAdmin() || validate()) : !checkboxState}>
                            SUBMIT </Button>)}
                      </Box>
                      {/* {isProcessSubmit ? (<Timer></Timer>) : null} */}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>)}

      </Box>
      {/* {snackBarMsg.length !== 0 && <SnackBar
        severity={hasError ? 'error' : "success"}
        message={snackBarMsg} open={openSnackBar}
        handleClose={handleCloseSnackBar}
      />} */}
      <FileSizeAlert
        open={alertStatus}
        handleAlertClose={handleAlertClose}
        file={file}
      />
      <UploadFileLengthValidationAlert
        open={fileLengthStatus}
        handleFileLengthAlertClose={handleFileLengthAlertClose}
        file={file}
       />
       <DeleteConfirmation 
        open={deleteStatus}
        handleClose={handleClose}
        handleOk={handleOk}
        file={file}
        />
    </CssBaseline>
  )
}


function CircularProgressWithLabel(props) {
  const showTimer = (rt) => {
      if (rt === 'Completed') {
          return rt
      }
      rt = parseInt(rt)
      let minutes = Math.floor(rt / 60);
      let seconds = rt % 60

      if (seconds < 10) {
          seconds = `0${seconds}`;
      }
      return `0${minutes}:${seconds}`;
  }

  return (
      <Box position="relative" display="inline-flex">
          <CircularProgress size="5rem" variant="static" {...props} />
          <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
          >
              <Typography variant="h6" component="div" color="textSecondary">
                  {showTimer(props.remaingTime)}</Typography>
          </Box>
      </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and static variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
