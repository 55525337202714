import React from 'react';
import { useStyles } from './styles';
import {
  Box, Button, CircularProgress, InputAdornment, TextField
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import gogreenLogo from '../../assets/gogreenLogo.svg';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import { decrypt, encrypt } from '../../helpers/encrypt';
import Cookies from 'js-cookie';
import { updateLanveraEStatement } from '../../actions';
import Switch from '@material-ui/core/Switch';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { SwitchButton } from '../SwitchButton';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory, useLocation } from 'react-router-dom';
import { isAdmin, getAppSource, getUserType } from '../../helpers';

export const GoGreenAlert = (props) => {
  const history = useHistory();
  const { open, handleClose, accountNumber } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [values, setValues] = React.useState({
    email: decrypt(props.email),
    phone: props.phone ? decrypt(props.phone) : '',
  });
  const [loading, setLoading] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState('');
  const [emailNotifications, setEmailNotifications] = React.useState(true);
  const [electronicNotifications, setElectronicNotifications] = React.useState(true);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));



  const validateEmail = (email) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)) {
    // if (/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(values.email)) {
      return true
    }
    return false;
  }

  const handleChange = (prop) => (event) => {
    if (prop === 'phone') {
      if ((/^[0-9]+$/).test(event.target.value) || event.target.value === '') {
        setValues({ ...values, [prop]: event.target.value });
      }
    }
    setValues({ ...values, [prop]: event.target.value });
    return;
  }

  const handleEmailNotification = () => {
    setEmailNotifications(!emailNotifications);

    // setState({ ...state, [item.fieldName]: item.fieldValue });
  }

  const handleElectronicNotifications = () => {
    setElectronicNotifications(!electronicNotifications);

    // setState({ ...state, [item.fieldName]: item.fieldValue });
  }



  const { email, phone } = values;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrMsg('');
    if (validateEmail(email) === false) {
      setErrMsg('Please enter a valid email.');
      setLoading(false);
      return;
    }
    const data = {
      "accountNumber": accountNumber,
      // "mobileNumber": encrypt(phone),
      // "email": encrypt(email),
      "isLanveraEStatements": electronicNotifications,
      "isLanveraESmtNotification": emailNotifications,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }
    const res = await updateLanveraEStatement(data);

    if (res) {
      setLoading(false);
      setErrMsg('');
      // setValues({ phone: '', email: '' });
      handleClose();
    } else {
      setLoading(false);
      setErrMsg('Something went Wrong.');
    }
  }

  const handleRoute = () => {
    history.push('/UserProfile/Profile');
  }

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={handleClose} open={open}>
      <Box className={classes.root}>
        <IconButton onClick={handleClose} className={classes.iconBtn}>
          <CancelIcon />
        </IconButton>
        <Box className={classes.header}>
          <Box className={classes.iconContainer}>
            <img className={classes.gogreenLogo} src={gogreenLogo} alt='icon' />
            <h1 className={classes.title}>Go Green, GO Paperless</h1>
          </Box>
        </Box>
        {errMsg.length !== 0 ? <p className={classes.errMsg}>{errMsg}</p> : null}
        <p className={classes.popupInfo}>Go Green, Sign up for e-Statements. View your billing statement online and protect the environment and your identity by going paperless.</p>
        <Box className={classes.cardBody}>
          <form className={classes.formStyle} noValidate autoComplete="off" onSubmit={handleSubmit}>
            <div className={classes.displayFlex}>
              <Box className={`${classes.inputContainer} ${classes.relative}`} >
                <TextField
                  disabled
                  color='primary'
                  fullWidth
                  type='text'
                  error={false}
                  id="outlined-error"
                  label="Email Address"
                  variant="outlined"
                  onChange={handleChange('email')}
                  value={email}
                  inputProps={{
                    className: classes.inputStyle,
                  }}
                />
                <div className={classes.editBtn} onClick={handleRoute}>
                  <EditIcon />
                </div>
              </Box>
              <Box className={`${classes.inputContainer} ${classes.relative}`} >
                <TextField
                  disabled
                  color='primary'
                  fullWidth
                  type='text'
                  error={false}
                  id="outlined-error"
                  label="Mobile Number"
                  variant="outlined"
                  onChange={handleChange('phone')}
                  value={phone}
                  inputProps={{
                    className: classes.inputStyle,
                    maxLength: 10,
                  }}
                />
                <div className={classes.editBtn} onClick={handleRoute}>
                  <EditIcon />
                </div>
              </Box>
            </div>
            <Box className={classes.inputContainer}>
              <p className={classes.labelStyle}>Do you wish to go paperless and receive your statements electronically?</p>
              <SwitchButton
                status={electronicNotifications}
                handleSwitch={handleElectronicNotifications}
                switchName={"phone"}></SwitchButton>
            </Box>
            <Box className={classes.inputContainer}>
              <p className={classes.labelStyle}>Do you wish to receive email notifications when your statements are available?</p>
              <SwitchButton
                status={emailNotifications}
                handleSwitch={handleEmailNotification}
                switchName={"phone"}></SwitchButton>
            </Box>
            <Box className={clsx(classes.inputContainer, classes.btnContainer)}>
              {!loading ?
                <Button color='primary'
                  variant='contained'
                  type='submit'
                  className={classes.btn}
                  disabled={isAdmin()}
                >
                  Enroll now
                </Button>
                :
                <Button type='submit' disabled={true} className={classes.disableBtn}><CircularProgress /></Button>
              }
            </Box>
          </form>
        </Box>
      </Box>
    </Dialog>
  )
}
