import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "95%",
    minHeight: '100vh',
    padding: "3em 0em",
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      padding: "2em 1.5em",
    },
  },
  fullpart: {
    width: '100%',
    maxWidth: "100%",
  },
  bgg: {
    background: 'url(/static/media/signinIcon.de91850d.svg) no-repeat center center',
    backgroundSize: 'cover',
    width: '100%',   
    backgroundColor: '#ccc',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  
  inputStyle: {
    padding: '10px',
    fontSize:'11px'
  },
  
 
  mainContainer: {
    background: 'rgb(255, 255, 255)',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 14px 7px, rgba(0, 0, 0, 0.2) 0px 1px 3px 0px',
    position: 'relative',
    borderRadius: '12px',
    minHeight:"70vh"
  },
  
  formContainer: {
    padding: '23px 30px 0 26px',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 10px 0 10px',
    },
    '& .MuiFormLabel-root': {
      color: '#535D6D',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      letterSpacing: '0.36px',
      lineHeight: '19px',
      background: '#fff',
      paddingRight: '5px'
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: props => props.secondaryColorCode,
    }
  },

  

  textField: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: '600'
  },

 


  errorMsg: {
    fontSize: '1.4em',
    fontWeight: 'bold',    
    marginBottom: 10,
    marginTop: 5,
    fontFamily: 'Montserrat',
    color: "red",
  },
  successMessage: {
    fontSize: '1.4em',
    fontWeight: 'bold',   
    marginBottom: 10,
    marginTop: 5,
    fontFamily: 'Montserrat',
    color: "green",
  },  
/*New Style*/
title: {
  width: '100%',
  color: "#202020",
  fontFamily: "Montserrat",
  fontSize: "24px",
  fontWeight: "600",
  marginBottom: 18,
},
  userDetailBox: {
    background: 'rgb(255, 255, 255)',   
    position: 'relative',
    borderRadius: '12px',
    display:"flex",
    alignItems: "center",
    justifyContent: "center",
  },
  labelText: {
    color: 'rgba(0,0,0,0.74)',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    letterSpacing: '0.5px',
    lineHeight: '20px',
    fontWeight:600,
    padding:'10px',   
  },
  valueText:{
    color: 'rgba(0,0,0,0.74)',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    letterSpacing: '0.5px',
    lineHeight: '20px',
    fontWeight:500,
    padding:'10px',
  },
  inputText: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "bold",
    width: '100%',
  },
  searchLable:{
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontWeight: "600",
    paddingRight:"10px"
  },
  searchBox: {
    width: '100%',
    backgroundColor: '#FFF',    
    borderRadius:12,
    padding: '2em 1.5em 1em',    
    justifyContent: 'space-between',
    alignItems: 'center',    
  },
  btnStyle:{height:"35px"},
  appBar: {
    width: "100%",
    backgroundColor: theme.palette.secondary.main,
    color: theme.header.fontColor,
    padding: '0px 16px'
  },
  infoIcon: {
    paddingRight: "10px",
    fontSize: '35px',
    color: theme.palette.secondary.contrastText,
  },
  infoText: {
    color: theme.palette.secondary.contrastText,
    fontFamily: "Montserrat",
    fontSize: "16px",
    letterSpacing: "0.36px",
    lineHeight: "19px",
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center'
  },

}));