import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 12,
    backgroundColor: "#F5F6F7",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    padding: '2em',
    [theme.breakpoints.down('xs')]: {
      padding: '1.6em',
    },
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxWidth: 522,
    maxHeight: "100%",
    backgroundColor: 'transparent'
  },

  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },

  iconStyle: {
    padding: 0,
    color: '#535D6D',
  },

  body: {
    width: '100%',
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "500",
    letterSpacing: '0.15px',
    textAlign: "center",
    margin: '5px 30px 40px 30px',
    borderBottom: '1px solid #d7dadc',
    paddingBottom: '17px',
    [theme.breakpoints.down('xs')]: {
      margin: '5px 10px 40px 10px',
    },
  },

  subTitle: {
    color: "#0C0B0B",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    letterSpacing: '0.5px',
    textAlign: "center",
    marginBottom: 2,
  },

  smallText: {
    color: "#0C0B0B",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    letterSpacing: '0.25px',
    textAlign: "center",
    marginBottom: 20,
  },

  mainContainer: {
    backgroundColor: '#e6edf0',
    padding: 16,
    marginBottom: 20,
  },

  mainBoxTitle: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    textAlign: "center",
    fontWeight: '600',
    letterSpacing: '0.5px',
    lineHeight: '20px',
    marginBottom: 20,
  },

  flexBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },

  item: {
    margin: 5,
  },

  itemText: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: '500',
  },

  itemSubText: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: 'bold',
    letterSpacing: '0.15px',
    lineHeight: '24px',
    marginTop: '8px'
  },

  buttonBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },

  btn: {
    width: 160,
    fontFamily: "Montserrat",
    fontSize: '1.6em',
  },


}));

