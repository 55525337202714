import React, { useEffect, useState, useRef } from 'react';
import { Box, Breadcrumbs, Button, CircularProgress, TextField } from "@material-ui/core";
import { useStyles } from './styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import PrintIcon from '@material-ui/icons/Print';
import { getFinancialInformation, updateFinancialInformation, getContent } from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import { scrollTop, checkDigitWithDecimal, renderDefaultMsg, getUserType, getAppSource, isAdmin } from '../../helpers';
import Cookies from 'js-cookie';
import { SnackBar } from '../../components';
import { useHistory } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import moment from 'moment';




export const FinancialInfo = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const selectedAccount = useSelector(state => state.selectedAccount)
  const [letterError, setLetterError] = useState(false);
  const [financialData, setFinancialData] = useState({
    dateLastSaved: '',
    //-------------------Borrower info ------------------//
    borrowerPrimaryNameInfo: '',
    borrowerNumOfLivingPeopleAddressInfo: 0,
    borrowerNumOfDependentsAtThisAddressInfo: 0,
    borrowerNumOfCarsOwnedInfo: 0,
    //-------------------Secondary-Borrower  info ----------------//
    secondaryBorrowerNameInfo: '',
    secondaryBorrowerNumOfLivingPeopleAddressInfo: 0,
    secondaryBorrowerNumOfDependentsAtThisAddressInfo: 0,
    //-------------------Borrower income ------------------//
    monthlyEmploymentIncomeGross: 0.00,
    monthlyEmploymentIncomeNet: 0.00,
    additionalIncomeGross: 0.00,
    additionalIncomeNet: 0.00,
    //-------------------Co-Borrower income ----------------//
    coBorrowerMonthlyEmploymentIncomeGross: 0.00,
    coBorrowerMonthlyEmploymentIncomeNet: 0.00,
    coBorrowerAdditionalIncomeGross: 0.00,
    coBorrowerAdditionalIncomeNet: 0.00,
    //-------------------Monthly Expense Details-----------//
    mortgagePayment: 0.00,
    alimony: 0.00,
    childSupport: 0.00,
    dependentCare: 0.00,
    otherMortgages: 0.00,
    personalLoans: 0.00,
    autoLoans: 0.00,
    rent: 0.00,
    medicalExpenses: 0.00,
    insuranceExpense: 0.00,
    autoExpense: 0.00,
    hoaFees: 0.00,
    food: 0.00,
    spendingMoney: 0.00,
    utilities: 0.00,
    creditCard1: 0.00,
    creditCard2: 0.00,
    creditCard3: 0.00,
    creditCard4: 0.00,
    autoInsurance: 0.00,
    //-------------------Asset Details---------------------//
    propertyPurchasePrice: 0.00,
    otherRealEstate: 0.00,
    checkingAccounts: 0.00,
    savingsMoneyMarket: 0.00,
    lifeInsuranceCashValue: 0.00,
    iraKeoghAccounts: 0.00,
    esopAccounts: 0.00,
    stocksBondscds: 0.00,
    otherInvestments: 0.00,
    //-------------------Financial Hardship Letter----------//
    financialHardshipLetter: '',
  });
  const [saveDraft, setSaveDraft] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState([])
  const history = useHistory();
  const dispatch = useDispatch();
  const componentRef = useRef();

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    history.push('/FinancialHardship')
  }

  const getFinancialData = async () => {
    setLoading(true);
    setOpen(false);
    scrollTop();
    const res = await getFinancialInformation(selectedAccount);
    if (res && res.lstResponseStatus[0].statusCode === '0') {
      setFinancialData({
        dateLastSaved: res.dateLastSaved,
        //-------------------Borrower info ------------------//
        borrowerPrimaryNameInfo: res.borrowerName,
        borrowerNumOfLivingPeopleAddressInfo: res.borrower1PersonsAtAddress,
        borrowerNumOfDependentsAtThisAddressInfo: res.borrower1DependentsAtAddress,
        borrowerNumOfCarsOwnedInfo: res.borrowerCarsOwned,
        //-------------------Secondary-Borrower  info ----------------//
        secondaryBorrowerNameInfo: res.coBorrowerName,
        secondaryBorrowerNumOfLivingPeopleAddressInfo: res.borrower2PersonsAtAddress,
        secondaryBorrowerNumOfDependentsAtThisAddressInfo: res.borrower2DependentsAtAddress,
        //-------------------Borrower income ------------------//

        monthlyEmploymentIncomeGross: res.borrowerGrossMonthlyInc,
        monthlyEmploymentIncomeNet: res.borrowerNetMonthlyInc,
        additionalIncomeGross: res.borrowerAddGrossMonthlyInc,
        additionalIncomeNet: res.borrowerAddNetMonthlyInc,
        totalIncomeGross: res.borrowerGrossTotalMonthlyInc,
        totalIncomeNet: res.borrowerNetTotalMonthlyInc,
        //-------------------Co-Borrower income ----------------//
        coBorrowerMonthlyEmploymentIncomeGross: res.coBorrowerGrossMonthlyInc,
        coBorrowerMonthlyEmploymentIncomeNet: res.coBorrowerNetMonthlyInc,
        coBorrowerAdditionalIncomeGross: res.coBorrowerAddGrossMonthlyInc,
        coBorrowerAdditionalIncomeNet: res.coBorrowerAddNetMonthlyInc,
        coBorrowerTotalIncomeGross: res.coBorrowerGrossTotalMonthlyInc,
        coBorrowerTotalIncomeNet: res.coBorrowerNetTotalMonthlyInc,
        //-------------------Monthly Expense Details-----------//
        mortgagePayment: res.mortgagePmt,
        alimony: res.alimonyMonthly,
        childSupport: res.childSupportMonthly,
        dependentCare: res.dependentCareMonthly,
        otherMortgages: res.otherMortgagesMonthly,
        personalLoans: res.personalLoansMonthly,
        autoLoans: res.autoLoansMonthly,
        rent: res.rentMonthly,
        medicalExpenses: res.medicalExpensesMonthly,
        insuranceExpense: res.medicalInsuranceMonthly,
        autoExpense: res.autoExpensesMonthly,
        hoaFees: res.hoaFees_DuesMonthly,
        food: res.foodMonthly,
        spendingMoney: res.spendingMoneyMonthly,
        utilities: res.utilitiesMonthly,
        creditCard1: res.creditCard1Monthly,
        creditCard2: res.creditCard2Monthly,
        creditCard3: res.creditCard3Monthly,
        creditCard4: res.creditCard4Monthly,
        autoInsurance: res.autoInsuranceMonthly,
        totalMonthlyExpense: res.totalMonthlyExpense,
        //-------------------Asset Details---------------------//
        propertyPurchasePrice: res.propertyPurchasePrice,
        otherRealEstate: res.otherRealEstateValue,
        checkingAccounts: res.checkingAccountsValue,
        savingsMoneyMarket: res.savingsMoneyMarketValue,
        lifeInsuranceCashValue: res.lifeInsuranceCashValue,
        iraKeoghAccounts: res.iraKeoghAccountsValue,
        esopAccounts: res.esop401KAccountsValue,
        stocksBondscds: res.stocks_Bonds_CDsValue,
        otherInvestments: res.otherInvestmentsValue,
        totalAssetsValue: res.totalAssets,
        //-------------------Financial Hardship Letter----------//
        financialHardshipLetter: res.letterComment ? res.letterComment : ''
      })
    }
    setLoading(false);
    scrollTop();
  };


  const getContentData = async () => {
    const res = await dispatch(getContent('PC022'));
    if (res) {
      setContent(res);
      scrollTop();
    }
  }

  useEffect(() => {
    getContentData();
  }, []);

  useEffect(() => {
    if (selectedAccount) {
      getFinancialData();
    }
  }, [selectedAccount]);

  const handleChange = (e) => {
    if (checkDigitWithDecimal(e.target.value)) {
      setFinancialData({ ...financialData, [e.target.name]: e.target.value })
    }
  }

  const handleFinancialLetterChange = (e) => {
    if (financialHardshipLetter.length > 0) {
      setLetterError(false);
    }
    setFinancialData({ ...financialData, [e.target.name]: e.target.value });
  }

  const {
    dateLastSaved,
    //-------------------Borrower info ------------------//
    borrowerPrimaryNameInfo,
    borrowerNumOfLivingPeopleAddressInfo,
    borrowerNumOfDependentsAtThisAddressInfo,
    borrowerNumOfCarsOwnedInfo,
    //-------------------Secondary-Borrower  info ----------------//
    secondaryBorrowerNameInfo,
    secondaryBorrowerNumOfLivingPeopleAddressInfo,
    secondaryBorrowerNumOfDependentsAtThisAddressInfo,
    //-------------------Borrower income ------------------//
    monthlyEmploymentIncomeGross,
    monthlyEmploymentIncomeNet,
    additionalIncomeGross,
    additionalIncomeNet,
    //-------------------Co-Borrower income ----------------//
    coBorrowerMonthlyEmploymentIncomeGross,
    coBorrowerMonthlyEmploymentIncomeNet,
    coBorrowerAdditionalIncomeGross,
    coBorrowerAdditionalIncomeNet,
    //-------------------Monthly Expense Details-----------//
    mortgagePayment,
    alimony,
    childSupport,
    dependentCare,
    otherMortgages,
    personalLoans,
    autoLoans,
    rent,
    medicalExpenses,
    insuranceExpense,
    autoExpense,
    hoaFees,
    food,
    spendingMoney,
    utilities,
    creditCard1,
    creditCard2,
    creditCard3,
    creditCard4,
    autoInsurance,
    //-------------------Asset Details---------------------//
    propertyPurchasePrice,
    otherRealEstate,
    checkingAccounts,
    savingsMoneyMarket,
    lifeInsuranceCashValue,
    iraKeoghAccounts,
    esopAccounts,
    stocksBondscds,
    otherInvestments,
    //-------------------Financial Hardship Letter---------//
    financialHardshipLetter,
  } = financialData;


  const handleSave = async (isDraft) => {

    if (!isDraft && financialHardshipLetter.length === 0) {
      setLetterError(true);
      return;
    }
    isDraft ? setSaveDraft(true) : setSubmit(true);
    setErrMsg('');
    setSuccessMsg('');
    setOpen(false);
    const data = {
      "accountNumber": selectedAccount,
      "borrower1PersonsAtAddress": borrowerNumOfLivingPeopleAddressInfo,
      "borrower1DependentsAtAddress": borrowerNumOfDependentsAtThisAddressInfo,
      "borrowerCarsOwned": borrowerNumOfCarsOwnedInfo,
      "borrower2PersonsAtAddress": secondaryBorrowerNumOfLivingPeopleAddressInfo,
      "borrower2DependentsAtAddress": secondaryBorrowerNumOfDependentsAtThisAddressInfo,
      "borrowerGrossMonthlyInc": monthlyEmploymentIncomeGross,
      "borrowerNetMonthlyInc": monthlyEmploymentIncomeNet,
      "borrowerAddGrossMonthlyInc": additionalIncomeGross,
      "borrowerAddNetMonthlyInc": additionalIncomeNet,
      "coBorrowerGrossMonthlyInc": coBorrowerMonthlyEmploymentIncomeGross,
      "coBorrowerNetMonthlyInc": coBorrowerMonthlyEmploymentIncomeNet,
      "coBorrowerAddGrossMonthlyInc": coBorrowerAdditionalIncomeGross,
      "coBorrowerAddNetMonthlyInc": coBorrowerAdditionalIncomeNet,
      "alimonyMonthly": alimony,
      "childSupportMonthly": childSupport,
      "dependentCareMonthly": dependentCare,
      "otherMortgagesMonthly": otherMortgages,
      "personalLoansMonthly": personalLoans,
      "autoLoansMonthly": autoLoans,
      "rentMonthly": rent,
      "medicalExpensesMonthly": medicalExpenses,
      "medicalInsuranceMonthly": insuranceExpense,
      "autoExpensesMonthly": autoExpense,
      "hoaFees_DuesMonthly": hoaFees,
      "foodMonthly": food,
      "spendingMoneyMonthly": spendingMoney,
      "utilitiesMonthly": utilities,
      "creditCard1Monthly": creditCard1,
      "creditCard2Monthly": creditCard2,
      "creditCard3Monthly": creditCard3,
      "creditCard4Monthly": creditCard4,
      "autoInsuranceMonthly": autoInsurance,
      "otherRealEstateValue": otherRealEstate,
      "checkingAccountsValue": checkingAccounts,
      "savingsMoneyMarketValue": savingsMoneyMarket,
      "lifeInsuranceCashValue": lifeInsuranceCashValue,
      "iraKeoghAccountsValue": iraKeoghAccounts,
      "esop401KAccountsValue": esopAccounts,
      "stocks_Bonds_CDsValue": stocksBondscds,
      "otherInvestmentsValue": otherInvestments,
      "letterComment": financialHardshipLetter,
      "isDraft": isDraft,
      "isSubmit": isDraft ? false : true,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
    }
    const res = await updateFinancialInformation(data);
    // if (res && res.lstResponseStatus[0].statusCode !== '0') {
    //   setErrMsg(res.lstResponseStatus[0].statusDesc);
    // } else {
    //   getFinancialData();
    //   setSuccessMsg(isDraft ? "Save as draft was successful" : 'Update was successful');
    //   setOpen(true)
    // }

    if (res && res.lstResponseStatus[0].statusCode === '0') {
      getFinancialData();
      setSuccessMsg(isDraft ? "Save as draft was successful" : 'Update was successful');
      setOpen(true)
    } else if (res && res.lstResponseStatus[0].statusCode !== '0') {
      setErrMsg(res.lstResponseStatus[0].statusDesc);
    } else {
      setErrMsg(renderDefaultMsg('400'));
    }
    isDraft ? setSaveDraft(false) : setSubmit(false);
  }

  const calculateTotalMonthlyExpense = () => {
    let total = 0;
    total = total + (mortgagePayment ? parseFloat(mortgagePayment) : 0) +
      (alimony ? parseFloat(alimony) : 0) + (childSupport ? parseFloat(childSupport) : 0) +
      (dependentCare ? parseFloat(dependentCare) : 0) + (otherMortgages ? parseFloat(otherMortgages) : 0) +
      (personalLoans ? parseFloat(personalLoans) : 0) + (autoLoans ? parseFloat(autoLoans) : 0) +
      (rent ? parseFloat(rent) : 0) + (medicalExpenses ? parseFloat(medicalExpenses) : 0) +
      (insuranceExpense ? parseFloat(insuranceExpense) : 0) + (autoExpense ? parseFloat(autoExpense) : 0) +
      (hoaFees ? parseFloat(hoaFees) : 0) + (food ? parseFloat(food) : 0) +
      (spendingMoney ? parseFloat(spendingMoney) : 0) + (utilities ? parseFloat(utilities) : 0) +
      (creditCard1 ? parseFloat(creditCard1) : 0) + (creditCard2 ? parseFloat(creditCard2) : 0) +
      (creditCard3 ? parseFloat(creditCard3) : 0) + (creditCard4 ? parseFloat(creditCard4) : 0) +
      (autoInsurance ? parseFloat(autoInsurance) : 0);
    return parseFloat(total).toFixed(2);
  }

  const calulateTotalAssetsValue = () => {

    let total = 0;
    total = total +
      (propertyPurchasePrice ? parseFloat(propertyPurchasePrice) : 0) +
      (otherRealEstate ? parseFloat(otherRealEstate) : 0) +
      (checkingAccounts ? parseFloat(checkingAccounts) : 0) +
      (savingsMoneyMarket ? parseFloat(savingsMoneyMarket) : 0) +
      (lifeInsuranceCashValue ? parseFloat(lifeInsuranceCashValue) : 0) +
      (iraKeoghAccounts ? parseFloat(iraKeoghAccounts) : 0) +
      (esopAccounts ? parseFloat(esopAccounts) : 0) +
      (stocksBondscds ? parseFloat(stocksBondscds) : 0) +
      (otherInvestments ? parseFloat(otherInvestments) : 0);
    return parseFloat(total).toFixed(2);
  }



  if (loading) {
    return (
      <Box className={classes.loader}>
        <Box className={classes.loaderContainer}>
          <CircularProgress />
        </Box>
        {successMsg.length !== 0 && <SnackBar open={open} handleClose={handleClose} severity="success" message={successMsg} />}
      </Box>
    )
  }

  return (
    <Box className={classes.rooBg}>
      <Box className={classes.root}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" style={{color: '#2B2D30'}}/>} aria-label="breadcrumb">
          <Link color="inherit" to='/PaymentOption' className={classes.disabledLink}>
            Payments
        </Link>
          <Link color="inherit" to='/FinancialHardship' className={classes.disabledLink}>
            Financial Hardship
        </Link>
          <Link color="primary" to='/FinancialInfo' className={classes.activeLink}>
            Financial Information Form
        </Link>
        </Breadcrumbs>
        <h1 className={classes.title}>Financial Hardship Information Form</h1>
        <Box className={classes.container}>
          <Box className={classes.containerWraper} ref={componentRef}>
            <Box className={classes.sectionContainer}>
              <h3 className={classes.subTitle}>Information</h3>
              <Box className={classes.flexBox}>
                <Box className={classes.infoBox}>
                  <Box className={classes.infoBoxHeader}>
                    <h3 className={classes.infoBoxTitle}>Borrower </h3>
                  </Box>
                  <Box className={classes.infoBoxBody}>
                    <Box className={classes.infoInputContainer}>
                      <p className={classes.label}>Primary Borrower Name</p>
                      <p className={classes.inputText}>{borrowerPrimaryNameInfo}</p>
                    </Box>
                    <Box className={classes.infoInputContainer}>
                      <p className={classes.label}># of People living at this Address</p>
                      <TextField
                        onChange={handleChange}
                        value={borrowerNumOfLivingPeopleAddressInfo}
                        name='borrowerNumOfLivingPeopleAddressInfo'
                        color='primary'
                        id="outlined-People-living"
                        variant="outlined"
                        InputProps={{
                          classes: { root: classes.inputText, input: classes.input }
                        }}
                      />
                    </Box>
                    <Box className={classes.infoInputContainer}>
                      <p className={classes.label}># of Dependents at this Address</p>
                      <TextField
                        onChange={handleChange}
                        value={borrowerNumOfDependentsAtThisAddressInfo}
                        name='borrowerNumOfDependentsAtThisAddressInfo'
                        color='primary'
                        id="outlined-address"
                        variant="outlined"
                        InputProps={{
                          classes: { root: classes.inputText, input: classes.input }
                        }}
                      />
                    </Box>
                    <Box className={classes.infoInputContainer}>
                      <p className={classes.label}># of Cars Owned</p>
                      <TextField
                        onChange={handleChange}
                        name='borrowerNumOfCarsOwnedInfo'
                        value={borrowerNumOfCarsOwnedInfo}
                        color='primary'
                        id="outlined-owned"
                        variant="outlined"
                        InputProps={{
                          classes: { root: classes.inputText, input: classes.input }
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.infoBox}>
                  <Box className={classes.infoBoxHeader}>
                    <h3 className={classes.infoBoxTitle}>Secondary Borrower  </h3>
                  </Box>
                  <Box className={classes.infoBoxBody}>
                    <Box className={classes.infoInputContainer}>
                      <p className={classes.label}>Secondary Borrower Name</p>
                      <p className={classes.inputText}>{secondaryBorrowerNameInfo}</p>
                    </Box>
                    <Box className={classes.infoInputContainer}>
                      <p className={classes.label}># of People living at this Address</p>
                      <TextField
                        onChange={handleChange}
                        name='secondaryBorrowerNumOfLivingPeopleAddressInfo'
                        value={secondaryBorrowerNumOfLivingPeopleAddressInfo}
                        color='primary'
                        id="outlined-people-address"
                        variant="outlined"
                        InputProps={{
                          classes: { root: classes.inputText, input: classes.input }
                        }}
                      />
                    </Box>
                    <Box className={classes.infoInputContainer}>
                      <p className={classes.label}># of Dependents at this Address</p>
                      <TextField
                        onChange={handleChange}
                        name='secondaryBorrowerNumOfDependentsAtThisAddressInfo'
                        value={secondaryBorrowerNumOfDependentsAtThisAddressInfo}
                        color='primary'
                        id="outlined-dependents-address"
                        variant="outlined"
                        InputProps={{
                          classes: { root: classes.inputText, input: classes.input }
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={classes.sectionContainer}>
              <h3 className={classes.subTitle}>Income</h3>
              <Box className={classes.flexBox}>
                <Box className={classes.infoBox}>
                  <Box className={classes.infoBoxHeader}>
                    <h3 className={classes.infoBoxTitle}>Borrower </h3>
                  </Box>
                  <Box className={classes.infoBoxBody}>
                    <Box className={classes.infoInputContainer}>
                      <Box className={classes.growBox} />
                      <Box className={classes.multiFieldContainer}>
                        <p className={classes.topLabel}>Gross</p>
                        <p className={classes.topLabel}>Net</p>
                      </Box>
                    </Box>
                    <Box className={classes.infoInputContainer}>
                      <p className={classes.label}>Monthly Employment Income</p>
                      <Box className={classes.multiFieldContainer}>
                        <TextField
                          onChange={handleChange}
                          name='monthlyEmploymentIncomeGross'
                          value={monthlyEmploymentIncomeGross}
                          color='primary'
                          id="outlined-name-gross"
                          variant="outlined"
                          InputProps={{
                            classes: { root: classes.incomeInputText, input: classes.input }
                          }}
                        />
                        <TextField
                          onChange={handleChange}
                          name='monthlyEmploymentIncomeNet'
                          value={monthlyEmploymentIncomeNet}
                          color='primary'
                          id="outlined-name-net"
                          variant="outlined"
                          InputProps={{
                            classes: { root: classes.incomeInputText, input: classes.input }
                          }}
                        />
                      </Box>
                    </Box>
                    <Box className={classes.infoInputContainer}>
                      <p className={classes.label}>Additional Income</p>
                      <Box className={classes.multiFieldContainer}>
                        <TextField
                          onChange={handleChange}
                          name='additionalIncomeGross'
                          value={additionalIncomeGross}
                          color='primary'
                          id="outlined-address-gross"
                          variant="outlined"
                          InputProps={{
                            classes: { root: classes.incomeInputText, input: classes.input }
                          }}
                        />
                        <TextField
                          onChange={handleChange}
                          name='additionalIncomeNet'
                          value={additionalIncomeNet}
                          color='primary'
                          id="outlined-address-net"
                          variant="outlined"
                          InputProps={{
                            classes: { root: classes.incomeInputText, input: classes.input }
                          }}
                        />
                      </Box>
                    </Box>
                    <Box className={classes.infoInputContainer}>
                      <p className={classes.label}>Total Income</p>
                      <Box className={classes.multiFieldContainer}>
                        <TextField
                          name='totalIncomeGross'
                          value={parseFloat((monthlyEmploymentIncomeGross ? parseFloat(monthlyEmploymentIncomeGross) : 0) + (additionalIncomeGross ? parseFloat(additionalIncomeGross) : 0)).toFixed(2)}
                          disabled
                          color='primary'
                          id="outlined-owned-gross"
                          variant="outlined"
                          InputProps={{
                            classes: { root: classes.incomeInputText, input: classes.input }
                          }}
                        />
                        <TextField
                          name='totalIncomeNet'
                          value={parseFloat((monthlyEmploymentIncomeNet ? parseFloat(monthlyEmploymentIncomeNet) : 0) + (additionalIncomeNet ? parseFloat(additionalIncomeNet) : 0)).toFixed(2)}
                          disabled
                          color='primary'
                          id="outlined-owned-net"
                          variant="outlined"
                          InputProps={{
                            classes: { root: classes.incomeInputText, input: classes.input }
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.infoBox}>
                  <Box className={classes.infoBoxHeader}>
                    <h3 className={classes.infoBoxTitle}>Co-Borrower </h3>
                  </Box>
                  <Box className={classes.infoBoxBody}>
                    <Box className={classes.infoInputContainer}>
                      <Box className={classes.growBox} />
                      <Box className={classes.multiFieldContainer}>
                        <p className={classes.topLabel}>Gross</p>
                        <p className={classes.topLabel}>Net</p>
                      </Box>
                    </Box>
                    <Box className={classes.infoInputContainer}>
                      <p className={classes.label}>Monthly Employment Income</p>
                      <Box className={classes.multiFieldContainer}>
                        <TextField
                          onChange={handleChange}
                          name='coBorrowerMonthlyEmploymentIncomeGross'
                          value={coBorrowerMonthlyEmploymentIncomeGross}
                          color='primary'
                          id="outlined-co-borrower-name-gross"
                          variant="outlined"
                          InputProps={{
                            classes: { root: classes.incomeInputText, input: classes.input }
                          }}
                        />
                        <TextField
                          onChange={handleChange}
                          name='coBorrowerMonthlyEmploymentIncomeNet'
                          value={coBorrowerMonthlyEmploymentIncomeNet}
                          color='primary'
                          id="outlined-co-borrower-name-net"
                          variant="outlined"
                          InputProps={{
                            classes: { root: classes.incomeInputText, input: classes.input }
                          }}
                        />
                      </Box>
                    </Box>
                    <Box className={classes.infoInputContainer}>
                      <p className={classes.label}>Additional Income</p>
                      <Box className={classes.multiFieldContainer}>
                        <TextField
                          onChange={handleChange}
                          name='coBorrowerAdditionalIncomeGross'
                          value={coBorrowerAdditionalIncomeGross}
                          color='primary'
                          id="outlined-co-borrower-address-gross"
                          variant="outlined"
                          InputProps={{
                            classes: { root: classes.incomeInputText, input: classes.input }
                          }}
                        />
                        <TextField
                          onChange={handleChange}
                          name='coBorrowerAdditionalIncomeNet'
                          value={coBorrowerAdditionalIncomeNet}
                          color='primary'
                          id="outlined-co-borrower-address-net"
                          variant="outlined"
                          InputProps={{
                            classes: { root: classes.incomeInputText, input: classes.input }
                          }}
                        />
                      </Box>
                    </Box>
                    <Box className={classes.infoInputContainer}>
                      <p className={classes.label}>Total Income</p>
                      <Box className={classes.multiFieldContainer}>
                        <TextField
                          disabled
                          value={parseFloat((coBorrowerMonthlyEmploymentIncomeGross ? parseFloat(coBorrowerMonthlyEmploymentIncomeGross) : 0) + (coBorrowerAdditionalIncomeGross ? parseFloat(coBorrowerAdditionalIncomeGross) : 0)).toFixed(2)}
                          name='coBorrowerTotalIncomeGross'
                          color='primary'
                          id="outlined-co-borrower-owned-gross"
                          variant="outlined"
                          InputProps={{
                            classes: { root: classes.incomeInputText, input: classes.input }
                          }}
                        />
                        <TextField
                          disabled
                          name='coBorrowerTotalIncomeNet'
                          value={parseFloat((coBorrowerMonthlyEmploymentIncomeNet ? parseFloat(coBorrowerMonthlyEmploymentIncomeNet) : 0) + (coBorrowerAdditionalIncomeNet ? parseFloat(coBorrowerAdditionalIncomeNet) : 0)).toFixed(2)}
                          color='primary'
                          id="outlined-co-borrower-owned-net"
                          variant="outlined"
                          InputProps={{
                            classes: { root: classes.incomeInputText, input: classes.input }
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={classes.sectionContainer}>
              <h3 className={classes.subTitle}>Monthly Expense Details</h3>
              <Box className={classes.expenseBox}>
                <Box className={classes.expenseBoxInner}>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>Mortgage Payment</p>
                    <TextField
                      disabled
                      onChange={handleChange}
                      value={mortgagePayment}
                      name='mortgagePayment'
                      color='primary'
                      id="outlined-Mortgage-Payment"
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>Alimony</p>
                    <TextField
                      onChange={handleChange}
                      value={alimony}
                      name='alimony'
                      color='primary'
                      id="outlined-Alimony"
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>Child Support</p>
                    <TextField
                      onChange={handleChange}
                      name='childSupport'
                      value={childSupport}
                      color='primary'
                      id="outlined-Child-Support"
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>Dependent Care</p>
                    <TextField
                      onChange={handleChange}
                      name='dependentCare'
                      value={dependentCare}
                      color='primary'
                      id="outlined-Dependent-Care"
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>Other Mortgages</p>
                    <TextField
                      onChange={handleChange}
                      name='otherMortgages'
                      value={otherMortgages}
                      color='primary'
                      id="outlined-Other Mortgages"
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>Personal Loans</p>
                    <TextField
                      onChange={handleChange}
                      name='personalLoans'
                      value={personalLoans}
                      color='primary'
                      id="outlined-Personal-Loans"
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>Auto Loans</p>
                    <TextField
                      onChange={handleChange}
                      name='autoLoans'
                      value={autoLoans}
                      color='primary'
                      id="outlined-Auto-Loans"
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>Rent</p>
                    <TextField
                      onChange={handleChange}
                      name='rent'
                      value={rent}
                      color='primary'
                      id="outlined-Rent"
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>Medical Expenses</p>
                    <TextField
                      onChange={handleChange}
                      value={medicalExpenses}
                      name='medicalExpenses'
                      color='primary'
                      id="outlined-Medical-Expenses"
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>Insurance Expenses</p>
                    <TextField
                      onChange={handleChange}
                      name='insuranceExpense'
                      value={insuranceExpense}
                      color='primary'
                      id="outlined-Insurance-Expense"
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                </Box>
                <Box className={classes.expenseBoxInner}>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>Auto Expense</p>
                    <TextField
                      onChange={handleChange}
                      name='autoExpense'
                      value={autoExpense}
                      color='primary'
                      id="outlined-Auto-Expense"
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>HOA Fees/ Due</p>
                    <TextField
                      onChange={handleChange}
                      value={hoaFees}
                      name='hoaFees'
                      color='primary'
                      id="outlined-HOA-Fees"
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>Food</p>
                    <TextField
                      onChange={handleChange}
                      value={food}
                      name='food'
                      color='primary'
                      id="outlined-Food"
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>Spending Money</p>
                    <TextField
                      onChange={handleChange}
                      value={spendingMoney}
                      name='spendingMoney'
                      color='primary'
                      id="outlined-Spending-Money"
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>Utilities</p>
                    <TextField
                      onChange={handleChange}
                      value={utilities}
                      name='utilities'
                      color='primary'
                      id="outlined-Utilities"
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>Credit Card 1</p>
                    <TextField
                      onChange={handleChange}
                      value={creditCard1}
                      name='creditCard1'
                      color='primary'
                      id="outlined-Credit-Card-1"
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>Credit Card 2</p>
                    <TextField
                      onChange={handleChange}
                      value={creditCard2}
                      name='creditCard2'
                      color='primary'
                      id="outlined-Credit-Card-2"
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>Credit Card 3</p>
                    <TextField
                      onChange={handleChange}
                      value={creditCard3}
                      name='creditCard3'
                      color='primary'
                      id="outlined-Credit-Card-3"
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>Credit Card 4</p>
                    <TextField
                      onChange={handleChange}
                      value={creditCard4}
                      name='creditCard4'
                      color='primary'
                      id="outlined-Credit-Card-4"
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>Auto Insurance </p>
                    <TextField
                      onChange={handleChange}
                      value={autoInsurance}
                      name='autoInsurance'
                      color='primary'
                      id="outlined-Auto-Insurance "
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className={classes.expenseBoxFooter}>
                <Box className={classes.infoInputContainer}>
                  <p className={classes.label}>Total Monthly Expense </p>
                  <TextField
                    disabled
                    name='totalMonthlyExpense'
                    value={calculateTotalMonthlyExpense()}
                    color='primary'
                    id="outlined-Total-Monthly-Expense"
                    variant="outlined"
                    InputProps={{
                      classes: { root: classes.inputText, input: classes.input }
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box className={classes.sectionContainer}>
              <h3 className={classes.subTitle}>Asset Details</h3>
              <Box className={classes.expenseBox}>
                <Box className={classes.expenseBoxInner}>
                  <Box className={classes.infoInputContainer}>
                    <Box className={classes.growBox} />
                    <p className={classes.assetDetailtopLabel}>Estimated Value</p>
                  </Box>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>Property Purchase Price</p>
                    <TextField
                      disabled
                      value={propertyPurchasePrice}
                      name='propertyPurchasePrice'
                      color='primary'
                      id="outlined-Property-Purchase-Price"
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>Other Real Estate</p>
                    <TextField
                      onChange={handleChange}
                      value={otherRealEstate}
                      name='otherRealEstate'
                      color='primary'
                      id="outlined-Other-Real-Estate"
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>Checking Accounts </p>
                    <TextField
                      onChange={handleChange}
                      value={checkingAccounts}
                      name='checkingAccounts'
                      color='primary'
                      id="outlined-checkingAccounts"
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>Savings/Money Market</p>
                    <TextField
                      onChange={handleChange}
                      value={savingsMoneyMarket}
                      name='savingsMoneyMarket'
                      color='primary'
                      id="outlined-Savings-Market"
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>Life Insurance Cash Value</p>
                    <TextField
                      onChange={handleChange}
                      value={lifeInsuranceCashValue}
                      name='lifeInsuranceCashValue'
                      color='primary'
                      id="outlined-Life-Insurance-Cash-Value"
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                </Box>
                <Box className={classes.expenseBoxInner}>
                  <Box className={classes.infoInputContainer}>
                    <Box className={classes.growBox} />
                    <p className={classes.assetDetailtopLabel}>Estimated Value</p>
                  </Box>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>IRA/KEOGH Accounts</p>
                    <TextField
                      onChange={handleChange}
                      name='iraKeoghAccounts'
                      value={iraKeoghAccounts}
                      color='primary'
                      id="outlined-iraKeoghAccounts"
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>401K/ESOP Accounts</p>
                    <TextField
                      onChange={handleChange}
                      value={esopAccounts}
                      name='esopAccounts'
                      color='primary'
                      id="outlined-esopAccounts"
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>Stocks/Bonds/CDS</p>
                    <TextField
                      onChange={handleChange}
                      value={stocksBondscds}
                      name='stocksBondscds'
                      color='primary'
                      id="outlined-stocksBondscds"
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                  <Box className={classes.infoInputContainer}>
                    <p className={classes.label}>Other Investments</p>
                    <TextField
                      onChange={handleChange}
                      value={otherInvestments}
                      name='otherInvestments'
                      color='primary'
                      id="outlined-otherInvestments2"
                      variant="outlined"
                      InputProps={{
                        classes: { root: classes.inputText, input: classes.input }
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className={classes.expenseBoxFooter}>
                <Box className={classes.infoInputContainer}>
                  <p className={classes.label}>Total Assets Value</p>
                  <TextField
                    disabled
                    value={calulateTotalAssetsValue()}
                    color='primary'
                    id="outlined-Total-Assets-Value"
                    variant="outlined"
                    InputProps={{
                      classes: { root: classes.inputText, input: classes.input }
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box className={clsx(classes.sectionContainer, classes.flexBox)}>
              <Box className={classes.letterBox}>
                <h3 className={classes.subTitle}>Financial Hardship Letter<span className={classes.requiredText}>*</span></h3>
                <TextField
                  required
                  error={letterError}
                  name='financialHardshipLetter'
                  color="primary"
                  value={financialHardshipLetter}
                  onChange={handleFinancialLetterChange}
                  fullWidth
                  placeholder='(Please complete to submit to Loan Servicing Center)'
                  id="outlined-multiline-static"
                  variant="outlined"
                  multiline
                  rows={4}
                  InputProps={{
                    classes: { root: classes.letterField, input: classes.input }
                  }}
                />
                <Box className={classes.dateBox}>
                  <h3 className={classes.lastDateSaved}>Date Last Saved</h3>
                  <p className={classes.tncText}>{dateLastSaved}</p>
                </Box>
              </Box>
              <Box display="block" displayPrint="none" className={classes.TncContainer}>
                <h3 className={classes.tncTitle}>Acknowledgement</h3>
                {content.length !== 0 && <p className={classes.tncText} dangerouslySetInnerHTML={{ __html: content[0].contentValue }} ></p>}
              </Box>
              <Box display="none" displayPrint="block" className={classes.tncContainerHidden}>
                <h3 className={classes.tncTitle}>Acknowledgement</h3>
                {content.length !== 0 && <p className={classes.tncText} dangerouslySetInnerHTML={{ __html: content[0].contentValue }} ></p>}
              </Box>
            </Box>
          </Box>
          {letterError ? <p className={classes.errorText}>Financial hardship letter field is required</p> : null}
          {errMsg.length !== 0 ? <p className={classes.errorText}>{errMsg}</p> : null}
          <Box className={classes.btnBox}>
            <ReactToPrint
              trigger={() => <Button
                variant='outlined'
                color='primary'
                className={clsx(classes.btn)}
              >Print Form</Button>}
              content={() => componentRef.current}
            />
            <Button
              variant='outlined'
              //color='default'
              className={clsx(classes.resetBtn)}
              onClick={() => handleCancel()}
            >Cancel</Button>
            <Button
              disabled={isAdmin() || saveDraft}
              variant='contained'
              color='secondary'
              className={clsx(classes.btn, classes.saveAsBtn)}
              onClick={() => handleSave(true)}
            >{saveDraft ? <CircularProgress size={25} /> : 'save as draft'}</Button>
            <Button
              disabled={isAdmin() || submit}
              variant='contained'
              color='primary'
              className={clsx(classes.btn, classes.submitBtn)}
              onClick={() => handleSave(false)}
            >{submit ? <CircularProgress size={25} /> : 'Submit'}</Button>
          </Box>
          <p className={classes.noteText}>Note: Form can be submitted once in 6 months.</p>
        </Box>
        {errMsg.length !== 0 && <SnackBar open={open} handleClose={handleClose} severity="error" message={errMsg} />}
      </Box>
    </Box>
  );
}
