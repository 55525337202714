import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginBottom: 90,
    [theme.breakpoints.down('md')]: {
      marginBottom: 56,
    },
  },
  root1: {
    display: 'flex',
    marginBottom: 184,
    [theme.breakpoints.down('md')]: {
      marginBottom: 146,
    },
  },

  appBar: {
    width: "100%",
    boxShadow: 'none !important',
    backgroundColor: theme.header.backgrounColor,
    [theme.breakpoints.up('md')]: {
      padding: '0px 5em'
    },
  },

  marqueeRoot: {
    marginTop: 30,
    [theme.breakpoints.down('sm')]: {
      marginTop: 25,
    },
  },
  marqueeRoot2: {
    marginTop: 60,
    [theme.breakpoints.down('sm')]: {
      marginTop: 55,
    },
  },
  marqueeAppBar: {
    marginTop: 27,
    zIndex: 999
  },
  marqueeAppBar2: {
    marginTop: 56,
    zIndex: 999
  },
  marqueeAppBar3: {
    marginTop: 90,
    zIndex: 999
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: theme.header.fontColor,
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,

  grow: {
    flexGrow: 1,
  },

  linkContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  },

  linkStyle: {
    color: theme.header.fontColor,
    borderRightColor: theme.header.fontColor,
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: '600',
    textDecoration: 'none',
    padding: '0 15px',
    borderRight: 'solid 1px',
    letterSpacing: '0.53px',
  },

  signInBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    marginLeft: 30,
    height: 40,
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: '600',
    letterSpacing: '0.53px',
    borderColor: theme.palette.primary.main,
    border: '1px solid',
    borderRadius: 4,
    textTransform: 'uppercase',
    padding: '10px 20px',
    outline: "none",
    cursor: 'pointer',
    boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  },
 
  createAccBtn: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    marginLeft: 30,
    height: 40,
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: '600',
    letterSpacing: '0.53px',
    borderColor: theme.palette.secondary.main,
    border: '1px solid',
    borderRadius: 4,
    textTransform: 'uppercase',
    padding: '10px 20px',
    outline: "none",
    cursor: 'pointer',
    boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  },

  logoContainer: {
    display: 'block',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
  },

  logoStyle: {
    width: 350,
    height: 74,
    margin: '9px 0',
    [theme.breakpoints.down('md')]: {
      width: 153,
      height: '100%',
      margin: '9px 0',
    },
  },

  profileIcon: {
    display: 'none',
    color: theme.header.fontColor,
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },

  linkText: {
    textDecoration: 'none',
  },

  menuItemStyle: {
    fontFamily: "Montserrat",
    fontSize: 16,
    color: '#202020',
    borderBottom: 'solid 1px #ddd',
    marginLeft: 16,
    '&:hover': {
      backgroundColor: '#fff'
    }
  },

  signIn: {
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: 10,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    textTransform: 'uppercase',
    paddingTop: 4
  },

  mobileCheck: {
    display: 'none',
    color: theme.header.fontColor,
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '185px'
    },
  }


}));
