import React from 'react';
import './styles.css'

const defaultButtonStyles = {
  position: 'absolute',
  fontSize: '16px',
  right: '15px',
  color: '#ffffff',
  background: 'none',
  border: 0,
  fontWeight: 'bold',
  lineHeight: '40px',
  height: '40px',
  cursor: 'pointer',
  backgroundColor: '#121D22'
};

const defaultHeaderStyles = {
  lineHeight: '40px',
  paddingLeft: '20px',
  height: '40px',
  borderRadius: '8px 8px 0 0',
  backgroundColor: '#121D22',
  color: '#FFFFFF',
  fontSize: '16px',
  fontWeight: 'bold',
  overflow: 'hidden',
  fontFamily: 'Montserrat',
  position: 'relative'
};

const defaultIconStyles = {
  position: 'absolute',
  right: '39px',
  bottom: '6px',
  height: '26px',
  width: '14px',
  zIndex: '999',
  cursor: 'pointer',
}

const Header = ({
  headerText,
  headerBtnText,
  handleCloseWithoutSave,
  headerStyles,
  headerBtnStyles,
}) => (
  <div style={headerStyles}>
    {headerText}
    <span></span>
    <button onClick={handleCloseWithoutSave} style={headerBtnStyles} type="button">
      {headerBtnText}
    </button>
  </div>
);

Header.defaultProps = {
  headerText: 'BSI Brand Refresh Survey',
  headerBtnText: 'X',
  headerBtnStyles: defaultButtonStyles,
  headerStyles: defaultHeaderStyles,
  iconStyles: defaultIconStyles
};

export default Header;
