import React from 'react';
import { useStyles } from './styles';
import {
  Box, Grid,
  TextField, Radio,
  RadioGroup, FormControlLabel,
} from "@material-ui/core";

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';


export const CovidQnA = (props) => {
  const classes = useStyles();
  const { click, covidData, dateClick } = props;

  return (
    <div className={classes.root}>
      <Box className={classes.covidQnA}>
        <h4 className={classes.subText} style={{ marginBottom: '20px' }}>
          How have you been impacted by COVID-19?<span className={classes.required}>*</span></h4>
        <TextField
          style={{ height: '117px' }}
          fullWidth
          color="primary"
          id={`outlined_impacted250`}
          variant="outlined"
          type="text"
          label="(Please complete to submit to Loan Servicing Center)"
          name="impactedCov19"
          value={covidData.impactedCov19 ? covidData.impactedCov19 : ''}
          onChange={click}
          InputLabelProps={{
            classes: { root: classes.textField }
          }}
          InputProps={{
            maxLength: 60,
            classes: { root: classes.textField250, input: classes.textInput }
          }} />

        <Box className={classes.covidImpectRadio}>
          <Grid container className={classes.radioBtnMargin}>
            <Grid item xs={12} sm={6}>
              <p className={classes.subText}>Is the property associated with this loan a rental property<span className={classes.required}>*</span></p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <RadioGroup row className={`${classes.justifyCenter}`}
                value={covidData.isRentalProperty ? covidData.isRentalProperty : ''}
                onChange={click}>
                <FormControlLabel
                  name="isRentalProperty"
                  value="Yes"
                  checked={covidData.isRentalProperty && covidData.isRentalProperty === 'Yes' ? true : false}
                  control={<Radio classes={{ root: classes.radioStyle }} />}
                  label="Yes"
                  classes={{
                    label: covidData.isRentalProperty && covidData.isRentalProperty === 'Yes' ? classes.activeRadioLabel : classes.radioLabel
                  }} />
                <FormControlLabel
                  name="isRentalProperty"
                  value="No"
                  checked={covidData.isRentalProperty && covidData.isRentalProperty === 'No' ? true : false}
                  control={<Radio classes={{ root: classes.radioStyle }} />}
                  label="No"
                  classes={{
                    label: covidData.isRentalProperty && covidData.isRentalProperty === 'No' ? classes.activeRadioLabel : classes.radioLabel
                  }} />
              </RadioGroup >
            </Grid>
          </Grid>
          <Grid container className={classes.radioBtnMargin}>
            <Grid item xs={12} sm={6}>
              <p className={classes.subText}>Is there a tenant in the property?<span className={classes.required}>*</span></p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <RadioGroup row className={`${classes.justifyCenter}`}
                value={covidData.isTenantInProperty ? covidData.isTenantInProperty : ''}
                onChange={click}>
                <FormControlLabel
                  name="isTenantInProperty"
                  value="Y"
                  checked={covidData.isTenantInProperty && covidData.isTenantInProperty === 'Y' ? true : false}
                  control={<Radio classes={{ root: classes.radioStyle }} />}
                  label="Yes"
                  classes={{
                    label: covidData.isTenantInProperty && covidData.isTenantInProperty === 'Y' ? classes.activeRadioLabel : classes.radioLabel
                  }} />
                <FormControlLabel
                  name="isTenantInProperty"
                  value="N"
                  checked={covidData.isTenantInProperty && covidData.isTenantInProperty === 'N' ? true : false}
                  control={<Radio classes={{ root: classes.radioStyle }} />}
                  label="No"
                  classes={{
                    label: covidData.isTenantInProperty && covidData.isTenantInProperty === 'N' ? classes.activeRadioLabel : classes.radioLabel
                  }} />
              </RadioGroup >
            </Grid>
          </Grid>

          {covidData.isTenantInProperty && covidData.isTenantInProperty === 'Y' ?
            <Grid container className={classes.radioBtnMargin}>
              <Grid item xs={12} sm={6}>
                <p className={classes.subText}>Is Tenant making full payment?<span className={classes.required}>*</span></p>
              </Grid>
              <Grid item xs={12} sm={6}>
                <RadioGroup row className={`${classes.justifyCenter}`}
                  value={covidData.isTenantMakingFullPayments ? covidData.isTenantMakingFullPayments : ''}
                  onChange={click}>
                  <FormControlLabel
                    name="isTenantMakingFullPayments"
                    value="Y"
                    checked={covidData.isTenantMakingFullPayments && covidData.isTenantMakingFullPayments === 'Y' ? true : false}
                    control={<Radio classes={{ root: classes.radioStyle }} />}
                    label="Yes"
                    classes={{
                      label: covidData.isTenantMakingFullPayments && covidData.isTenantMakingFullPayments === 'Y' ? classes.activeRadioLabel : classes.radioLabel
                    }} />
                  <FormControlLabel
                    name="isTenantMakingFullPayments"
                    value="N"
                    checked={covidData.isTenantMakingFullPayments && covidData.isTenantMakingFullPayments === 'N' ? true : false}
                    control={<Radio classes={{ root: classes.radioStyle }} />}
                    label="No"
                    classes={{
                      label: covidData.isTenantMakingFullPayments && covidData.isTenantMakingFullPayments === 'N' ? classes.activeRadioLabel : classes.radioLabel
                    }} />
                </RadioGroup >
              </Grid>
            </Grid> : null}


          <Grid container className={classes.radioBtnMargin}>
            <Grid item xs={12} sm={6}>
              <p className={classes.subText}>Are you prohibited evicting Tenant?<span className={classes.required}>*</span></p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <RadioGroup row className={`${classes.justifyCenter}`}
                value={covidData.areYouProhibitedEvictingTenant ? covidData.areYouProhibitedEvictingTenant : ''}
                onChange={click}>
                <FormControlLabel
                  name="areYouProhibitedEvictingTenant"
                  value="Y"
                  checked={covidData.areYouProhibitedEvictingTenant && covidData.areYouProhibitedEvictingTenant === 'Y' ? true : false}
                  control={<Radio classes={{ root: classes.radioStyle }} />}
                  label="Yes"
                  classes={{
                    label: covidData.areYouProhibitedEvictingTenant && covidData.areYouProhibitedEvictingTenant === 'Y' ? classes.activeRadioLabel : classes.radioLabel
                  }} />
                <FormControlLabel
                  name="areYouProhibitedEvictingTenant"
                  value="N"
                  checked={covidData.areYouProhibitedEvictingTenant && covidData.areYouProhibitedEvictingTenant === 'N' ? true : false}
                  control={<Radio classes={{ root: classes.radioStyle }} />}
                  label="No"
                  classes={{
                    label: covidData.areYouProhibitedEvictingTenant && covidData.areYouProhibitedEvictingTenant === 'N' ? classes.activeRadioLabel : classes.radioLabel
                  }} />
              </RadioGroup >
            </Grid>
          </Grid>

          <Grid container className={classes.radioBtnMargin}>
            <Grid item xs={12} sm={6}>
              <p className={classes.subText}>Is the property still being renovated or rehabilitated<span className={classes.required}>*</span>	</p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <RadioGroup row className={`${classes.justifyCenter}`}
                value={covidData.isPropertyRenovatedOrRehabilitated ? covidData.isPropertyRenovatedOrRehabilitated : ''}
                onChange={click}>
                <FormControlLabel
                  name="isPropertyRenovatedOrRehabilitated"
                  value="Yes"
                  checked={covidData.isPropertyRenovatedOrRehabilitated && covidData.isPropertyRenovatedOrRehabilitated === 'Yes' ? true : false}
                  control={<Radio classes={{ root: classes.radioStyle }} />}
                  label="Yes"
                  classes={{
                    label: covidData.isPropertyRenovatedOrRehabilitated && covidData.isPropertyRenovatedOrRehabilitated === 'Yes' ? classes.activeRadioLabel : classes.radioLabel
                  }} />
                <FormControlLabel
                  name="isPropertyRenovatedOrRehabilitated"
                  value="No"
                  checked={covidData.isPropertyRenovatedOrRehabilitated && covidData.isPropertyRenovatedOrRehabilitated === 'No' ? true : false}
                  control={<Radio classes={{ root: classes.radioStyle }} />}
                  label="No"
                  classes={{
                    label: covidData.isPropertyRenovatedOrRehabilitated && covidData.isPropertyRenovatedOrRehabilitated === 'No' ? classes.activeRadioLabel : classes.radioLabel
                  }} />
              </RadioGroup >
            </Grid>
          </Grid>


          {covidData.isPropertyRenovatedOrRehabilitated && covidData.isPropertyRenovatedOrRehabilitated === 'Yes' && <React.Fragment><Grid container className={classes.radioBtnMargin}>
            <Grid item xs={12} sm={6}>
              <p className={classes.subText}>What percent of the project is complete?<span className={classes.required}>*</span></p>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                color="primary"
                id={`outlined-`}
                variant="outlined"
                type="text"
                name="percentComplete"
                value={covidData.percentComplete ? covidData.percentComplete : ''}
                onChange={click}
                className={classes.textField}
                InputLabelProps={{
                  classes: { root: classes.textField }
                }}
                InputProps={{
                  classes: { root: classes.textField, input: classes.textInput },
                  maxLength: 2
                }} />
            </Grid>
          </Grid>

            <Grid container className={classes.radioBtnMargin}>
              <Grid item xs={12} sm={6}>
                <p className={classes.subText}>Do you have a date when your renovation will resume, if on hold,
              or an anticipated completion date?<span className={classes.required}>*</span></p>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container >
                  <Grid item xs={12} sm={12}>
                    <RadioGroup row className={`${classes.justifyCenter}`}
                      value={covidData.renovationDateRadio ? covidData.renovationDateRadio : ''}
                      onChange={click} style={{ marginBottom: '20px' }}>
                      <FormControlLabel
                        name="renovationDateRadio"
                        value="Yes"
                        checked={covidData.renovationDateRadio && covidData.renovationDateRadio === 'Yes' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="Yes"
                        classes={{
                          label: covidData.renovationDateRadio && covidData.renovationDateRadio === 'Yes' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                      <FormControlLabel
                        name="renovationDateRadio"
                        value="No"
                        checked={covidData.renovationDateRadio && covidData.renovationDateRadio === 'No' ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} />}
                        label="No"
                        classes={{
                          label: covidData.renovationDateRadio && covidData.renovationDateRadio === 'No' ? classes.activeRadioLabel : classes.radioLabel
                        }} />
                    </RadioGroup >
                  </Grid>

                  {covidData.renovationDateRadio && covidData.renovationDateRadio === 'Yes' && <Grid item xs={12} sm={8}><MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      name="renovationDate"
                      color="primary"
                      autoOk={true}
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      format="MM/dd/yyyy"
                      id="date-picker-inline"
                      value={covidData.renovationDate && covidData.renovationDate}
                      onChange={dateClick('renovationDate')}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      InputProps={{
                        classes: { root: classes.textField, input: classes.input },
                      }}
                    />
                  </MuiPickersUtilsProvider></Grid>}

                </Grid>
              </Grid>
            </Grid>


            <Grid container className={classes.radioBtnMargin}>
              <Grid item xs={12} sm={6}>
                <p className={classes.subText}>Do you foresee cash flow in the future?<span className={classes.required}>*</span></p>
              </Grid>
              <Grid item xs={12} sm={6}>
                <RadioGroup row className={`${classes.justifyCenter}`}
                  value={covidData.foreseeCashFlow ? covidData.foreseeCashFlow : ''}
                  onChange={click}>
                  <FormControlLabel
                    name="foreseeCashFlow"
                    value="Y"
                    checked={covidData.foreseeCashFlow && covidData.foreseeCashFlow === 'Y' ? true : false}
                    control={<Radio classes={{ root: classes.radioStyle }} />}
                    label="Yes"
                    classes={{
                      label: covidData.foreseeCashFlow && covidData.foreseeCashFlow === 'Y' ? classes.activeRadioLabel : classes.radioLabel
                    }} />
                  <FormControlLabel
                    name="foreseeCashFlow"
                    value="N"
                    checked={covidData.foreseeCashFlow && covidData.foreseeCashFlow === 'N' ? true : false}
                    control={<Radio classes={{ root: classes.radioStyle }} />}
                    label="No"
                    classes={{
                      label: covidData.foreseeCashFlow && covidData.foreseeCashFlow === 'N' ? classes.activeRadioLabel : classes.radioLabel
                    }} />
                </RadioGroup >
              </Grid>
            </Grid></React.Fragment>}


          <Grid container className={classes.radioBtnMargin}>
            <Grid item xs={12} sm={6}>
              <p className={classes.subText}>Is the property listed for sale?<span className={classes.required}>*</span></p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <RadioGroup row className={`${classes.justifyCenter}`}
                value={covidData.isPropertyListedForSale ? covidData.isPropertyListedForSale : ''}
                onChange={click}>
                <FormControlLabel
                  name="isPropertyListedForSale"
                  value="Yes"
                  checked={covidData.isPropertyListedForSale && covidData.isPropertyListedForSale === 'Yes' ? true : false}
                  control={<Radio classes={{ root: classes.radioStyle }} />}
                  label="Yes"
                  classes={{
                    label: covidData.isPropertyListedForSale && covidData.isPropertyListedForSale === 'Yes' ? classes.activeRadioLabel : classes.radioLabel
                  }} />
                <FormControlLabel
                  name="isPropertyListedForSale"
                  value="No"
                  checked={covidData.isPropertyListedForSale && covidData.isPropertyListedForSale === 'No' ? true : false}
                  control={<Radio classes={{ root: classes.radioStyle }} />}
                  label="No"
                  classes={{
                    label: covidData.isPropertyListedForSale && covidData.isPropertyListedForSale === 'No' ? classes.activeRadioLabel : classes.radioLabel
                  }} />
              </RadioGroup >
            </Grid>
          </Grid>
          <Grid container className={classes.radioBtnMargin}>
            <Grid item xs={12} sm={6}>
              <p className={classes.subText}>Do you have another source of income?<span className={classes.required}>*</span> </p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <RadioGroup row className={`${classes.justifyCenter}`}
                value={covidData.haveAnotherSourceOfIncome ? covidData.haveAnotherSourceOfIncome : ''}
                onChange={click}>
                <FormControlLabel
                  name="haveAnotherSourceOfIncome"
                  value="Yes"
                  checked={covidData.haveAnotherSourceOfIncome && covidData.haveAnotherSourceOfIncome === 'Yes' ? true : false}
                  control={<Radio classes={{ root: classes.radioStyle }} />}
                  label="Yes"
                  classes={{
                    label: covidData.haveAnotherSourceOfIncome && covidData.haveAnotherSourceOfIncome === 'Yes' ? classes.activeRadioLabel : classes.radioLabel
                  }} />
                <FormControlLabel
                  name="haveAnotherSourceOfIncome"
                  value="No"
                  checked={covidData.haveAnotherSourceOfIncome && covidData.haveAnotherSourceOfIncome === 'No' ? true : false}
                  control={<Radio classes={{ root: classes.radioStyle }} />}
                  label="No"
                  classes={{
                    label: covidData.haveAnotherSourceOfIncome && covidData.haveAnotherSourceOfIncome === 'No' ? classes.activeRadioLabel : classes.radioLabel
                  }} />
              </RadioGroup >
            </Grid>
          </Grid>
          <Grid container className={classes.radioBtnMargin}>
            <Grid item xs={12} sm={6}>
              <p className={classes.subText}>When do you anticipated being
              able to make complete monthly mortgage payment?<span className={classes.required}>*</span></p>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  name="monthlyMortgagePaymentDate"
                  color="primary"
                  autoOk={true}
                  disableToolbar
                  variant="inline"
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  id="date-picker-inline"
                  value={covidData.monthlyMortgagePaymentDate && covidData.monthlyMortgagePaymentDate}
                  onChange={dateClick('monthlyMortgagePaymentDate')}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  InputProps={{
                    classes: { root: classes.textField, input: classes.input },
                  }}
                />
              </MuiPickersUtilsProvider>

            </Grid>
            <Grid item xs={12} sm={2}>
            </Grid>
          </Grid>

          <Grid container className={classes.radioBtnMargin}>
            <Grid item xs={12} sm={6}>
              <p className={classes.subText}>If your loan is nearing maturity,
              when do you anticipated being able to satisfy your complete loan balance?</p>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  name="loanBalanceCompleteDate"
                  color="primary"
                  autoOk={true}
                  disableToolbar
                  variant="inline"
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  id="date-picker-inline"
                  value={covidData.loanBalanceCompleteDate && covidData.loanBalanceCompleteDate}
                  onChange={dateClick('loanBalanceCompleteDate')}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  InputProps={{
                    classes: { root: classes.textField, input: classes.input },
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={4}>
            </Grid>

          </Grid>

        </Box>
      </Box>
    </div>
  );
}
