import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { useStyles } from './styles';
import CustomBreadcrum from '../Breadcrum';
import GetAppIcon from '@material-ui/icons/GetApp';
import InputIcon from '@material-ui/icons/Input';
import { isAdmin, renderDefaultMsg, getAppSource, getUserType } from '../../helpers';
import { gettingStartedfinancialHardshipFlows, addCITTask, getCITInfo, insertTelemetryActivity } from '../../actions';
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';

export const GettingStarted = (props) => {
  const { accountNumber } = props
  const myloanTheme = useSelector(state => state.theme);

  const history = useHistory();
  const classes = useStyles();
  const [successResponse, setSuccessResponse] = useState(false);
  const [citInfo, setCitInfo] = useState(null);
  const [applicationType, setApplicationType] = useState(null);
  const [loanType, setLoanType] = useState(null);
  const [masterId, setMasterId] = useState(null);
  const [specialLoanType, setSpecialLoanType] = useState(false);
  const [flowDataError, setFlowDataError] = useState('');
  const [citInfoError, setCitInfoError] = useState('');
  const [addCitLoader, setAddCitLoader] = useState(false);
  const [loanTypeName, setLoanTypeName] = useState('');
  const [step1Q, setStep1Q] = useState(false);
  const [step2Q, setStep2Q] = useState(false);
  const [step3Q, setStep3Q] = useState(false);
  const [downLoadForm, setDownLoadForm] = useState(false);
  const [citResult, setCitResult] = useState(false);
  const [citError, setCitError] = useState(false);
  const [downloadForm710, setDownloadForm710] = useState(false);

  useEffect(() => {
    getFinancialHardshipFlows();
    // getCITInformation();
  }, [])

  const activityDetails = async () => {
    const data = {
      "accountNumber": accountNumber,
      "keyword": null,
      "resultCount": null,
      "category": 'Loss Mitigation Application',
      "question": null,
      "isAnswerLiked": null,
      "answerFeedback": null,
      "activityType": "Getting Started",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };
    await insertTelemetryActivity(data);
  }

  const getFinancialHardshipFlows = async () => {
    setFlowDataError('');
    setSuccessResponse(false);
    setStep1Q(false);
    setStep3Q(false);
    setDownLoadForm(false);
    setStep2Q(false);
    setDownloadForm710(false);
    setCitResult(false);
    setCitError(false);
    const response = await gettingStartedfinancialHardshipFlows(accountNumber);
    if (response) {
      setSuccessResponse(true);
      setStep1Q(true);
    }
    if (response && response.lstResponseStatus && response.lstResponseStatus.length && response.lstResponseStatus[0].statusCode === '0') {
      setApplicationType(response.applicationType);
      setLoanType(response.loanType);
      setMasterId(response.masterId);
      setSpecialLoanType(response.isbpl);
    } else if (response && response.lstResponseStatus && response.lstResponseStatus.length && response.lstResponseStatus[0].statusCode !== '0') {
      setFlowDataError(response.lstResponseStatus[0].statusDesc);
    } else {
      setFlowDataError(renderDefaultMsg('400'));
    }
  };

  const getCITInformation = async () => {
    const response = await getCITInfo(accountNumber);
    if (response && response.lstResponseStatus && response.lstResponseStatus.length && response.lstResponseStatus[0].statusCode === '0') {
      setCitInfo(response);
    } else if (response && response.lstResponseStatus && response.lstResponseStatus.length && response.lstResponseStatus[0].statusCode !== '0') {
      setCitInfoError(response.lstResponseStatus[0].statusDesc);
    } else {
      setCitInfoError(renderDefaultMsg('400'));
    }
  };

  const addCitApi = async (citNo, citMsg) => {
    const obj = {
      accNo: accountNumber,
      citNo: citNo,
      citMsg: citMsg
    }
    setAddCitLoader(true);
    setCitResult(true);

    const response = await addCITTask(obj);
    if (response && response.lstResponseStatus && response.lstResponseStatus.length && response.lstResponseStatus[0].statusCode === '0') {
      setCitError(false);
      setAddCitLoader(false);
    } else if (response && response.lstResponseStatus && response.lstResponseStatus.length && response.lstResponseStatus[0].statusCode !== '0') {
      setCitError(true);
      setAddCitLoader(false);
    } else {
      setCitError(true);
      setAddCitLoader(false);
    }
  };

  const addSilentCitApi = async (citNo, citMsg) => {
    const obj = {
      accNo: accountNumber,
      citNo: citNo,
      citMsg: citMsg
    }
    const response = await addCITTask(obj);
  };

  const toUpperCase = (str) => {
    return str.toUpperCase();
  }

  const checkFlowInfo = () => {
    if (specialLoanType) {
      setLoanTypeName('BPL');
      setStep1Q(false);
      setStep3Q(false);
      setDownLoadForm(false);
      setStep2Q(true);
      return;
    }
    if ((loanType === 2 || loanType === 3 || loanType === 8) && applicationType === 4) {
      setLoanTypeName('GOVT');
      setStep1Q(false);
      setStep2Q(false);
      setStep3Q(false);
      setDownloadForm710(false);
      setDownLoadForm(true);
      // addSilentCitApi(301, 'Loan type GOVT Borrower Inquiry on MyLoanWeb for Retention received ')
      
      return;
    }
    if (masterId !== null && (toUpperCase(masterId.trim()) === 'FNMA' || toUpperCase(masterId.trim()) === 'FHLMC')) {
      setLoanTypeName('AGENCY');
      setStep1Q(false);
      setStep3Q(false);
      setDownLoadForm(false);
      setStep2Q(true);
      return;

    }
    setLoanTypeName('NONAGENCY');
    setStep1Q(false);
    setStep3Q(false);
    setDownLoadForm(false);
    setStep2Q(true);
  }

  const checkStep2Click = (type) => {
    if (type === 'YES') {
      if (loanTypeName === 'BPL') {
        setStep1Q(false);
        setDownloadForm710(false);
        setDownLoadForm(false);
        setStep2Q(false);
        setStep3Q(true);
        return;
      } else if (loanTypeName === 'AGENCY') {
        // addSilentCitApi(301, 'Loan type Agency Borrower Inquiry for Retention received from MyLoanWeb.');

        setDownloadForm710(true);
        setStep1Q(false);
        setDownLoadForm(true);
        setStep2Q(false);
        setStep3Q(false);
        return;
      } else {
        // addSilentCitApi(301, 'Loan type Non Agency Borrower Inquiry for Retention received from MyLoanWeb.');
 
        setStep1Q(false);
        setDownloadForm710(false);
        setDownLoadForm(true);
        setStep2Q(false);
        setStep3Q(false);
        return;
      }
    } else {
      setStep1Q(false);
      setDownLoadForm(false);
      setStep2Q(false);
      setStep3Q(false);
      // addCitApi(300, 'Loan type ' + loanTypeName + ' Borrower Inquiry on MyLoanWeb for Liquidation received.');
      setCitResult(true);

    }
  }

  const checkStep3Click = (type) => {
    if (type === 'YES') {
      setStep1Q(false);
      setDownLoadForm(false);
      setStep2Q(false);
      setStep3Q(false);
      // addCitApi(300, 'Loan type BPL Borrower Inquiry on MyLoanWeb for Retention Options, has a change in income. ');
      setCitResult(true);
    } else {
      setStep1Q(false);
      setDownLoadForm(false);
      setStep2Q(false);
      setStep3Q(false);
      // addCitApi(300, 'Loan type BPL not receiving income. Borrower Inquiry on MyLoanWeb for Retention Options, has no income');
      setCitResult(true);

    }
  }

  const routePage = () => {
    setStep1Q(false);
    setStep2Q(false);
    setStep3Q(false);
    setDownLoadForm(false);
    setCitResult(false);
    setDownloadForm710(false);
    history.push('/FinancialHardship')
  }

  if (!successResponse) {
    return (
      <Box className={classes.loaderContainer}>
        <CircularProgress />
      </Box>
    )
  }

  if (successResponse && flowDataError !== '') {
    return (
      <Box className={classes.loaderContainer}>
        <p className={classes.dataError}>{flowDataError}</p>
      </Box>
    )
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.flexContainer}>
        <Box className={classes.mainContainerWidth}>
          <CustomBreadcrum backPage="Payments" backPageLink="/PaymentOption" activePage="Financial Hardship" />
          <h1 className={classes.title}>Getting Started</h1>
        </Box>
      </Box>
      <Box className={`${classes.flexContainer} ${classes.bgWhith}`} style={{ position: 'relative' }}>
        <Button className={`${classes.btn} ${classes.exitBtn}`} mt={2}
          type="submit"
          variant="contained"
          color='primary'
          onClick={routePage}
          startIcon={<InputIcon className={classes.primaryText} />} >
          EXIT
        </Button>
        <Box className={`${classes.container} ${classes.mainContainerWidth}`}>
          <Box className={classes.getStartedContainer}>

            {<Box className={classes.step1}>
              {/* <h2 className={classes.stepTitle}>
                We are sorry to hear you're facing a financial hardship. In order to help you, we need to ask a few questions.
                </h2>
              <Box className={classes.btnContainer}>
                <Button className={`${classes.btn}`} mt={2}
                  type="submit"
                  variant="contained"
                  color='primary'
                  disabled={isAdmin()}
                  onClick={checkFlowInfo}
                >
                  Continue
                </Button>
              </Box> */}
              <Box className={classes.formDownLoadConatiner}>
              <div className={classes.downloadBox}>

                <div>
                  <p className={classes.bodyDarkText}>Loss Mitigation Application</p>
                  <div class="dividerr" style={{ width: '100%' }}></div>
                  <div className={classes.flexContainer}>
                    <div>
                      <p className={classes.text}>
                        <ul className={classes.list}>
                          <li>Please download a Loss Mitigation Application and follow the instructions within form.</li>
                          <li>Send the completed application and supporting documents to us, for faster processing, you can email the completed
                            application and supporting documents to <span className={classes.linkStyle}> <a href={'mailto:'+'workouts@bsifinancial.com'} >workouts@bsifinancial.com</a>
                              {/* {myloanTheme.privateLabelCode === 'BSI021' ? 'workouts@bsifinancial.com' : 'workouts@myloanweb.com'} */}
                              
                            </span></li>
                        </ul>
                      </p>
                      <a href="https://www.bsifinancial.com/pdfs/Loss_Mit_Form_071624.pdf"
                      target='_blank'
                      className={`${classes.btn} ${classes.anchorBtn}`} mt={2} onClick={activityDetails}
                    >
                      <GetAppIcon className={classes.primaryText} />
                            Download
                    </a>

                    </div>
                  </div>
                </div> 
              </div>
            </Box>
            </Box>}

            {step2Q && <Box className={classes.step2}>
              <h2 className={classes.stepTitle}>
                Do you intend to keep this property?
                </h2>
              <Box className={classes.btnContainer}>
                <Button className={`${classes.btn} ${classes.step2Btn}`} mt={2}
                  type="submit"
                  variant="contained"
                  color='primary'
                  disabled={false}
                  onClick={() => checkStep2Click('NO')}
                >
                  {true ? 'No, I want to sell it' : <CircularProgress color='primary' />}
                </Button>
                <Button className={`${classes.btn} ${classes.step2Btn}`} mt={2}
                  type="submit"
                  variant="contained"
                  color='primary'
                  disabled={false}
                  onClick={() => checkStep2Click('YES')}
                >
                  {true ? 'Yes, i want to keep it' : <CircularProgress color='primary' />}
                </Button>
              </Box>
            </Box>}

            {step3Q && <Box className={classes.step3}>
              <h2 className={classes.stepTitle}>
                Are you receiving any income?
                </h2>
              <Box className={classes.btnContainer}>
                <Button className={`${classes.btn} ${classes.step2Btn}`} mt={2}
                  type="submit"
                  variant="contained"
                  color='primary'
                  disabled={false}
                  onClick={() => checkStep3Click('NO')}
                >
                  {true ? 'No, I am not' : <CircularProgress color='primary' />}
                </Button>
                <Button className={`${classes.btn} ${classes.step2Btn}`} mt={2}
                  type="submit"
                  variant="contained"
                  color='primary'
                  disabled={false}
                  onClick={() => checkStep3Click('YES')}
                >
                  {true ? 'Yes, i am' : <CircularProgress color='primary' />}
                </Button>
              </Box>
            </Box>}

            {downLoadForm && <Box className={classes.formDownLoadConatiner}>
              <div className={classes.downloadBox}>

                <div>
                  <p className={classes.bodyDarkText}>Loss Mitigation Application</p>
                  <div class="dividerr" style={{ width: '100%' }}></div>
                  <div className={classes.flexContainer}>
                    <div>
                      <p className={classes.text}>
                        <ul className={classes.list}>

                          <li>Please download a Loss Mitigation Application and follow the instructions within form.</li>
                          <li>Send the completed application and supporting documents to us, for faster processing, you can email the completed
                            application and supporting documents to <span className={classes.linkStyle}>
                            workouts@bsifinancial.com
                            </span></li>

                        </ul>
                      </p>
                    </div>

                    <a href="https://www.bsifinancial.com/pdfs/Loss_Mit_Form_071624.pdf"
                      target='_blank'
                      className={`${classes.btn} ${classes.anchorBtn}`} mt={2}
                    >
                      <GetAppIcon className={classes.primaryText} />
                            Download
                    </a>

                  </div>
                </div> 
                  {/* // <div >
                  // <p className={classes.bodyDark}>Mortgage Assistance Application (Form 710)</p>
                  // <div class="dividerr" style={{ width: '100%' }}></div>
                  // <div className={classes.flexContainer}>
                  //   <div>
                  //     <p className={classes.text}>
                  //       <ul className={classes.list}>

                  //         <li>Please download a Mortgage Assistance Application and follow the instructions within form.</li>
                  //         <li>Send the completed application and supporting documents to us, for faster processing, you can email the completed application and supporting documents to <span className={classes.linkStyle}>{myloanTheme.privateLabelCode === 'BSI021' ? 'workouts@bsifinancial.com' : 'workouts@myloanweb.com'}</span></li>

                  //       </ul>
                  //     </p>
                  //   </div>
                  //   <a href="http://d363gb003p3wz6.cloudfront.net/images/Assets/Mortgage Assistance Application (Form 710).pdf"
                  //     target='_blank'
                  //     className={`${classes.btn} ${classes.anchorBtn}`} mt={2}
                  //   >
                  //     <GetAppIcon className={classes.primaryText} />
                  //           Download
                  //         </a> */}

                  {/* </div></div> */}
                  

              </div>
            </Box>}

            {citResult && <Box className={classes.step3}>
              {(!addCitLoader && !citError) &&
                ((loanTypeName === 'AGENCY' || loanTypeName === 'NONAGENCY') ?
                  <div>
                    <div className={classes.flexDiv}>
                      <div>
                        <p className={classes.bodyDarkText}>To get started, we need:</p>
                        <ul className={classes.list}>
                          <li>A copy of the Listing Agreement</li>
                          <li>A copy of the MLS print out</li>
                          <li>Mortgage Comparables obtained by a real estate agent/broker.</li>
                          <li>If applicable, please provide <Link to="/ManageThirdPartyRequest" className={classes.anchorLinkStyle}>authorization</Link> to your real estate agent/broker.</li>
                        </ul>
                      </div>
                      <div>
                        <p className={classes.bodyDarkText}>And if there is an offer, we also need</p>
                        <ul className={classes.list}>
                          <li>Offer Agreement</li>
                          <li>Proof of funds</li>
                          <li>Estimated closings statement</li>
                          <li>Preliminary HUD or settlement statement</li>
                          <li>Approval letter for the buyer</li>
                        </ul>
                      </div>
                    </div>
                    <p className={`${classes.text} ${classes.paraMargin}`}>Send the completed application and supporting documents to us, for faster processing, you can email the completed application and supporting documents to <span className={classes.linkStyle}>workouts@bsifinancial.com</span></p>

                  </div> :
                  <h2 className={classes.stepTitle}>
                    Thank you for submitting the request. Please give us 48 hours to process it.
                </h2>)}
              {citError && <p className={classes.errorMessage}>{renderDefaultMsg('400')}</p>}
              {addCitLoader && <Box className={classes.citLoaderContainer}>
                <CircularProgress />
              </Box>}
            </Box>}

          </Box>

        </Box>
      </Box>
    </Box>
  )
}