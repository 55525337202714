import React, { useState, useEffect } from "react";
import {
  Box, Grid, Button, CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup, InputLabel,
  MenuItem, Select, TextField, Dialog, IconButton,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { useStyles } from './styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';

import { getAllStateList, getRefinanceHippoQuote } from '../../actions'
import { checkValidEmail, renderDefaultMsg, getAppSource, getUserType } from '../../helpers';
import { encrypt, decrypt } from '../../helpers/encrypt';
import Cookies from 'js-cookie';
import moment from 'moment';

import { PropertyInsuranceQuoteAlert } from './PropertyInsuranceQuoteAlert'

export const PropertyInsuranceQuote = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [loader, setLoader] = useState(false);
  const [stateErrorMsg, setStateErrorMsg] = useState('');
  const [stateData, setStateData] = useState([]);
  const [verifyFieldError, setVerifyFieldError] = useState({ emailAddress: '', zip: '' });
  const [errorMsg, setErrorMsg] = useState('');
  const [state, setState] = useState({
    firstName: '', lastName: '', emailAddress: '', DOB: '',
    streetAddress: '', selectedState: '', zip: '', city: '', condominiumRadio: 'N',
  });

  const [quoteAlert, setQuoteAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [anchorLink, setAnchorLink] = useState('');

  const getState = async (e) => {
    const response = await getAllStateList();
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
      setStateErrorMsg('');
      setStateData(response.stateList);
    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
      setStateErrorMsg(response.lstResponseStatus[0].statusDesc);
    } else {
      setStateErrorMsg(renderDefaultMsg('400'));
    }
  }

  const resetForm = () => {
    setAlertMessage('');
    setErrorMsg('');
    setState({
      ...state,
      firstName: '', lastName: '', emailAddress: '', DOB: null,
      streetAddress: '', selectedState: '', zip: '', city: '', condominiumRadio: '',
    });
  }

  const handleFormStateChange = (event) => {
    setState({ ...state, selectedState: event.target.value });
  }

  const changeDateOfBirth = (date) => {
    setState({
      ...state,
      ['DOB']: date
    })
  }

  const handleStateChangeHandler = (event) => {
    if (event.target.name === 'zip') {
      if (validateZip) {
        setState({ ...state, [event.target.name]: parseFloat(event.target.value.slice(0, 5)) });
        return;
      }
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  }

  const verifyField = (event) => {
    if (event.target.name === 'zip' ? validateZip(event.target.value) : checkValidEmail(event.target.value)) {
      setVerifyFieldError({ ...verifyFieldError, [event.target.name]: '' });
      return;
    } else {
      setVerifyFieldError({
        ...verifyFieldError,
        [event.target.name]: event.target.name === 'zip' ? 'Please enter a valid zip code.' : 'Please enter a valid email.'
      })
    }
  }

  const validateZip = (code) => {
    if ((/^[0-9]+$/).test(code) && code.length === 5) {
      return true;
    } else if (code.trim() === "") {
      return "Zip is required";
    }
    return false;
  }

  const submitForm = async (e) => {
    e.preventDefault();
    setLoader(true);
    setAlertMessage('');
    setErrorMsg('');
    setQuoteAlert(false);
    setAnchorLink('');
    const data = {
      "propertyAddress1": state.streetAddress,
      "propertyCity": state.city,
      "propertyState": state.selectedState.stateCode,
      "propertyZip": state.zip,
      "iScondo": state.condominiumRadio,
      "primaryName": `${state.firstName} ${state.lastName}`,
      "primaryFirstName": state.firstName,
      "primaryLastName": state.lastName,
      // "DOB": moment(state.DOB).format('L'),
      "currentInsurancePremium": 0,
      "email": encrypt(state.emailAddress),
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    }

    const response = await getRefinanceHippoQuote(data);
    if (response) {
      setLoader(false);
      toggleQuoteAlert()
      if (response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
        setState({
          firstName: '', lastName: '', emailAddress: '', DOB: '',
          streetAddress: '', selectedState: '', zip: '', city: '', condominiumRadio: '',
        });
        setAlertMessage('success');
        setAnchorLink(response.hippoDetail.hippoQuoteUrl);
      } else if (response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
        setAlertMessage('Sorry, we are unable to provide a quote for this propety.');
      }
    } else {
      setQuoteAlert(false);
      setErrorMsg(renderDefaultMsg('400'));
      setLoader(false);
    }
  }

  const toggleQuoteAlert = () => {
    setQuoteAlert(!quoteAlert);
  }

  useEffect(() => {
    getState();
  }, []);

  const disableBtn = state.firstName.trim().length === 0 || state.lastName.trim().length === 0
    || state.emailAddress === '' || state.DOB === '' || state.streetAddress.trim().length === 0
    || state.selectedState === '' || state.zip.length === 0 || state.city.trim().length === 0
    || state.condominiumRadio.length === 0 || verifyFieldError.emailAddress !== '' || verifyFieldError.zip !== '';


  return (
    <Dialog
      disableBackdropClick
      fullScreen={fullScreen}
      PaperProps={{ classes: { root: classes.dialogStyle } }}
      onClose={props.toggleOpen}
      open={props.open}
    >

      {stateData.length === 0 ?
        <Box className={classes.root}>
          <Box className={classes.loaderRoot}>
            <CircularProgress />
          </Box>
        </Box>
        :
        <Box className={classes.root}>
          <Box className={classes.header}>
            <IconButton className={classes.iconStyle} onClick={props.toggleOpen}>
              <CancelIcon className={classes.iconStyle} />
            </IconButton>
          </Box>
          <Box className={classes.body}>

            <h2 className={classes.title}>Get your personalized insurance quote </h2>
            <p className={classes.subTitle}>Complete and submit this form and you'll receive an instant quote for property & casualty insurance.</p>

            <form className={classes.formRoot} noValidate autoComplete="off" onSubmit={null}>
              <Grid container row spacing={4}>
                <Grid item xs={12} sm={6} md={6}>
                  <div>
                    <TextField
                      label="First Name"
                      color="primary"
                      type="text"
                      value={state.firstName}
                      name="firstName"
                      variant="outlined"
                      fullWidth
                      onChange={handleStateChangeHandler}
                      InputLabelProps={{
                        classes: { root: classes.textField }
                      }}
                      InputProps={{
                        classes: { root: classes.textField, input: classes.textInput },
                        maxLength: 20,
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div>
                    <TextField
                      label="Last Name"
                      color="primary"
                      type="text"
                      value={state.lastName}
                      name="lastName"
                      variant="outlined"
                      fullWidth
                      onChange={handleStateChangeHandler}
                      InputLabelProps={{
                        classes: { root: classes.textField }
                      }}
                      InputProps={{
                        classes: { root: classes.textField, input: classes.textInput },
                        maxLength: 20,
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div>
                    <TextField
                      label="Email Address"
                      color="primary"
                      type="text"
                      value={state.emailAddress}
                      name="emailAddress"
                      variant="outlined"
                      onChange={handleStateChangeHandler}
                      onBlur={verifyField}
                      fullWidth
                      InputLabelProps={{
                        classes: { root: classes.textField }
                      }}
                      InputProps={{
                        classes: { root: classes.textField, input: classes.textInput },
                        maxLength: 100,
                      }}
                    />
                    {verifyFieldError.emailAddress !== '' && <p className={classes.validationErr}>{verifyFieldError.emailAddress}</p>}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        fullWidth
                        color='primary'
                        autoOk={true}
                        disableToolbar
                        disableFuture
                        openTo="year"
                        views={["year", "month", "date"]}

                        format="MM/dd/yyyy"
                        variant="inline"
                        inputVariant="outlined"
                        label="Date of Birth"
                        value={state.DOB ? state.DOB : null}
                        onChange={changeDateOfBirth}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        InputProps={{
                          classes: { root: classes.textField, input: classes.inputDate },
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Grid>
              </Grid>


              <p className={classes.textBoxTitle}>
                Property Information
            </p>

              <Grid container row spacing={4}>
                <Grid item xs={12} sm={6} md={6}>
                  <div>
                    <TextField
                      label="Street Address"
                      color="primary"
                      type="text"
                      value={state.streetAddress}
                      name="streetAddress"
                      variant="outlined"
                      fullWidth
                      onChange={handleStateChangeHandler}
                      InputLabelProps={{
                        classes: { root: classes.textField }
                      }}
                      InputProps={{
                        classes: { root: classes.textField, input: classes.textInput },
                        maxLength: 200,
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div>
                    <TextField
                      id="outlined-loan-input"
                      label="City"
                      color="primary"
                      type="text"
                      value={state.city}
                      name="city"
                      variant="outlined"
                      fullWidth
                      onChange={handleStateChangeHandler}
                      InputLabelProps={{
                        classes: { root: classes.textField }
                      }}
                      InputProps={{
                        classes: { root: classes.textField, input: classes.textInput },
                        maxLength: 20,
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div>
                    <Box className={''}>
                      <Grid container spacing={5} direction="row" justify="center" >
                        <Grid item xs={12} md={7}>
                          <Box >
                            <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                              <InputLabel id="state-type-select-outlined">Select State</InputLabel>
                              {stateErrorMsg === '' && <Select
                                name="state"
                                color="primary"
                                label="Select State"
                                className={classes.inputStyle}
                                value={state.selectedState}
                                onChange={handleFormStateChange}
                                fullWidth >
                                {stateData.length !== 0 && stateData.map((item, index) => {
                                  return (
                                    <MenuItem value={item} key={(index - 1) + item.stateCode}>{item.stateName}</MenuItem>
                                  )
                                })}
                              </Select>}
                            </FormControl>
                            {stateErrorMsg !== '' && <p className={classes.errorMsg}>{stateErrorMsg}</p>}
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <Box >
                            <TextField
                              id="outlined-zip"
                              label="Zip"
                              variant="outlined"
                              type="text"
                              name="zip"
                              value={state.zip}
                              onChange={handleStateChangeHandler}
                              onBlur={verifyField}
                              fullWidth
                              inputProps={{
                                className: classes.inputStyle,
                                maxLength: 5,
                              }}
                            />
                            {verifyFieldError.zip !== '' && <p className={classes.validationErr}>{verifyFieldError.zip}</p>}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>


                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div>
                    <p className={classes.textField} style={{ margin: 0 }}>Is this a condominium? </p>
                    <div className={classes.radioBtn}>
                      <RadioGroup row aria-label="condominiumRadio" name="condominiumRadio" value={state.condominiumRadio}
                        onChange={handleStateChangeHandler}>
                        <FormControlLabel value="Y" checked={state.condominiumRadio == 'Y' ? true : false}
                          control={<Radio />} label="Yes" />
                        <FormControlLabel value="N" checked={state.condominiumRadio == 'N' ? true : false}
                          control={<Radio />} label="No" />
                      </RadioGroup>
                    </div>
                  </div>
                </Grid>

              </Grid>

              <Grid item xs={12} sm={12}>
                <p className={classes.errorMsg}>{errorMsg !== '' ? errorMsg : ''}</p>
                <Box className={`${classes.buttonContainer}`} display="flex" justifyContent="center" mt={4}>
                  <Button className={`${classes.resetButton}`} mt={2}
                    size="large"
                    variant="outlined"
                    color="primary"
                    onClick={resetForm}
                  >
                    Reset
                </Button>
                  <Button className={`${classes.submitBtn}`} mt={2}
                    disabled={loader || disableBtn}
                    size="large"
                    type="submit"
                    variant="contained"
                    color='primary'
                    onClick={submitForm}>
                    {!loader ? 'Submit for Quote' : <CircularProgress color='primary' style={{ width: 25, height: 25 }} />}
                  </Button>
                </Box>
              </Grid>

            </form>

            <p className={classes.helperText}>
              *Note: This estimate is based on information provided to Hippo by BSI Financial Services. The quoted premium will be finalized following the application completion and may vary from the above estimate. Hippo quotes are valid for 30 days.
            <br /><br />
            You can choose to stop receiving "pre-screened" offers of insurance from this and other companies by calling toll free 1-888-567-8688. See PRE-SCREEN & OPT OUT NOTICE below for details.
            <br /><br />
            PRE-SCREEN & OPT OUT NOTICE<br />
            This "pre-screened" offer of insurance is based on information in your credit report indicating that you meet certain criteria. This offer is not quaranteed if you do not meet our criteria. If you do not want to receive pre-screened offers of insurance from this and other companies, call TransUnion at 1-999-567-8688 or visit the website at www.optoutscreen.com; or write TransUnion Opt Out Request, PO Box 505, Woodlyn, PA 19094-0505
        </p>

          </Box>
        </Box>}



      {
        quoteAlert && <PropertyInsuranceQuoteAlert
          toggleOpen={toggleQuoteAlert}
          open={quoteAlert}
          alertMessage={alertMessage}
          anchorLink={anchorLink}
        // open={true}
        />
      }

    </Dialog >
  )
}