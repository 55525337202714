import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',         
  },
  subTitle:{  
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: "600",
    letterSpacing: "0.63px",
    lineHeight: "29px",
  },
  subTitle2: {      
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "20px",
    letterSpacing: "0.44px",
    lineHeight: "24px",
  },

  detailContainer:{
    boxSizing: "border-box",     
    border: "1px solid #2D62A8",
    borderRadius: "12px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
  
  },
  detailText:{      
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "16px",
    //letterSpacing: "0.36px",
    //lineHeight: "19px",
    letterSpacing: "0.44px",
    lineHeight: "24px",    
  },
  //====New
  inputText: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "bold",
    width: '100%',
  },

  flexBox: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  inputField: {
    width: '43%',
    marginBottom: 40,
  },

  title: {
    width: '100%',
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: "600",
    marginBottom: 18,
  },

  inputBox: {
    width: '100%',
    marginBottom: 20,
  },

  formControl: {
    width: '100%',
    marginBottom: 20,
  },

  formLabel: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "20px",
    marginBottom: 10,
  },

  labelStyle: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "16px",
  },

  formStyle: {
    width: '100%',
  },
  questionText:{   
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "20px",
    marginBottom: 10, 
    // color: "#202020",
    // fontFamily: "Montserrat",
    // fontSize: "20px",
    // letterSpacing: "0.44px",
    // lineHeight: "24px",
    // marginBottom:"10px"
  },
  inputFieldFullWidth: {
    width: '100%',
    //marginBottom: 40,
  },
  
infoIcon:{
  verticalAlign: "middle",
  fontSize: "10px"
},
}));

