import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, TextField } from '@material-ui/core';
import { useStyles } from './styles';
import { recoverUsername } from '../../actions';
import Cookies from 'js-cookie';
import { encrypt } from '../../helpers/encrypt';
import { getAppSource, getPointPrivateLabel, getPublicIp, getUserType, scrollTop } from '../../helpers';
import { Link } from 'react-router-dom';
import { TransferAlert } from '../../components';

export const RecoverUsername = (props) => {

  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('');
  const [emailErrorMsg, setEmailErrorMsg] = useState('');
  const [isTransfer, setTransferStatus] = useState(false);
  const [transferData, setTransferData] = useState({
    fromPrivateLabel: "", toPrivateLabelPhone: "",
    toPrivateLabelURL: "", toPrivateLabel: ""
  });

  const validateEmail = (email) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    // if (/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(email)) {
      return true
    }
    return false;
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setPhone('')
  };

  const handlePhoneChange = (event) => {
    if ((/^[0-9]+$/).test(event.target.value) || event.target.value === '') {
      setPhone(event.target.value);
      setEmail('')
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (email.length || phone.length) {

      if (email.length && !phone.length) {
        if (validateEmail(email) === false) {
          setEmailErrorMsg('Please enter a valid email address.');
          setLoading(false);
          setErrorMsg("");
          return;
        }
      }

      setEmailErrorMsg('');
      const data = {
        "emailID": email.length !== 0 ? encrypt(email) : '',
        "mobileNumber": phone.length !== 0 ? encrypt(phone) : '',
        "privateLabelUrl": Cookies.get("privateLabelUrl"),
        "ipAddress": await getPublicIp(),
        "browser": Cookies.get("browser"),
        "appSource": getAppSource(),
        "userType": getUserType(),
        "userName": ""
      }

      const res = await recoverUsername(data);
       if (res && !res.isTransfer) {
        props.handleStep(true, encrypt(email), email.length !== 0 ? true : false, res.lstResponseStatus[0].statusCode === '0' ? true : false,  res.emailID);

        setLoading(false);
        setEmail('');
        setPhone('');
        setErrorMsg("");
      } else if (res && res.isTransfer) {
        setTransferData(res)
        setTransferStatus(getPointPrivateLabel() ? false : true)
      } else {
        setErrorMsg(email.length !== 0 ? 'Please enter registered email address.' : 'Please enter registered mobile number.');
        setLoading(false);
        setEmail('');
        setPhone('');
      }
    } else {
      setEmailErrorMsg('Please enter a valid email address.');
      setLoading(false);
      return;
    }
  }

  const disableBtn = (phone.length === 0 && email.length !== 0) || (phone.length !== 0 && email.length === 0);

  return (
    <>
    <Box container="true" maxWidth='xl' className={classes.root}>
      <Box className={classes.wrapper}>
        <p className={classes.title}>Recover Username</p>
        <p className={classes.hintText}>Looks like you forgot your username.</p>
        <p className={classes.hintText}>Please enter the email address or registered mobile number associated with your account to receive your username.</p>
        <p className={classes.errorMsg}>{errorMsg !== '' ? errorMsg : ''}</p>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Box className={classes.inputContainer}>
            <TextField
              color='primary'
              fullWidth
              type='text'
              error={false}
              id="outlined-error"
              label="Enter Email Address"
              variant="outlined"
              onChange={handleEmailChange}
              value={email}
              inputProps={{
                root: classes.inputStyle
              }}
            />
            {emailErrorMsg !== '' && <p className={classes.errorMsg} style={{ textAlign: 'left' }}>{emailErrorMsg}</p>}

          </Box>
          <Box className={classes.orContainer}>
            <Box className={classes.orWraper}><p className={classes.orText}>or</p></Box>
          </Box>
          <Box className={classes.inputContainer}>
            <TextField
              color='primary'
              fullWidth
              type='text'
              error={false}
              id="outlined-error"
              label="Enter Mobile Number"
              variant="outlined"
              onChange={handlePhoneChange}
              value={phone}
              inputProps={{
                root: classes.inputStyle,
                maxLength: 10,
              }}

            />
          </Box>
          <Box className={classes.btnContainer}>
            {loading ?
              <Button disabled={true} type='submit' className={classes.sendBtn} variant="contained" color="primary" fullWidth><CircularProgress color='primary' /></Button>
              :
              <Button disabled={!disableBtn} type='submit' className={classes.submitBtn} variant="contained" color="primary" fullWidth>Send Username</Button>
            }
            <Button component={Link} to='/SignIn' fullWidth className={classes.backBtn} variant='outlined' color="primary" >Back to Sign In</Button>
          </Box>
        </form>
      </Box>
    </Box>
      {/* transfer notification popup */}
    {isTransfer &&
          <TransferAlert
            fromPrivateLabel={transferData.fromPrivateLabel}
            toPrivateLabel={transferData.toPrivateLabel}
            toPrivateLabelPhone={transferData.toPrivateLabelPhone}
            toPrivateLabelURL={transferData.toPrivateLabelURL}
          />}
    </>      
  )
}

export default RecoverUsername