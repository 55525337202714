import React, { useEffect, useState } from 'react';
import { Box, CssBaseline, Grid, Typography, Button, CircularProgress, IconButton, Tooltip,
    InputAdornment, FormControl, TextField, Select,
    InputLabel, TextareaAutosize, Checkbox, FormControlLabel, FormGroup, ClickAwayListener } from '@material-ui/core';
import { useStyles } from './styles';
import { withStyles } from '@material-ui/core/styles';
import { renderDefaultMsg, checkDigitWithDecimal, isAdmin } from '../../helpers';
import CustomBreadcrum from '../Breadcrum';
import { useSelector } from 'react-redux';
import { getDashboardData, getDefaultReasonsData, getPromiseDetails, submitPromiseRequest, updatePromiseRequest } from '../../actions';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import {
    MuiPickersUtilsProvider, KeyboardDatePicker
  } from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import Cookies from 'js-cookie';
import { ThankyouAlert } from './Thankyou';
import EditIcon from '@material-ui/icons/Edit';
import { getUserType, getAppSource } from '../../helpers'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const LightTooltip = withStyles((theme) => ({
  arrow: {
    color: '#235A86',
  },
  tooltip: {
    backgroundColor: '#235A86',
    color: '#fffff',
    fontFamily: "Montserrat",
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    padding: "16px",
    fontSize: 14,
    maxWidth: 350,
    fontWeight: 500,
    borderRadius: 8,
  },
  }))(Tooltip);

export const PromiseToPay = (props) => {
 
  const myRef = React.useRef({});
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const mytheme = useSelector(state => state.theme);
  const accountInfoData = useSelector(state => state?.accountInfoData);
  const selectedAccountData = useSelector(state => state.selectedAccountData);
  const { primaryColorCode, secondaryColorCode } = mytheme;
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'))

  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(true);
  const [amount, setAmount] = useState(0)
  const [selectedDate, setSelectedDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [defaultReasons, setReasons] = useState([]);
  const [reason, setReason] = useState();
  const [editPromiseStatus, setEditPromiseStatus] = useState(false);
  const [thankyouStatus, setThankyouStatus] = useState(false);
  const [totalAmountDue, setTotalAmountDue] = useState(0);
  const [monthlyAmountDue, setMonthlyAmountDue] = useState(0);
  const [dueDate, setDueDate] = useState();
  const [paymentLateDate, setPaymentLateDate] = useState();
  const [amountErrStatus , setAmountError] = useState(false);
  const [comments , setComments] = useState();
  const [reasonStatus, showReasons] = useState(false);
  const [submitDetails, setSubmitDetails] = useState({});
  const [otherStatus, setOtherStatus] = useState(false);
  const [submitAmount, setSubmitAmount] = useState(0);
  const [submitDate, setSubmitDate] = useState(new Date());
  const [submitReason , setSubmitReason] = useState();
  const [submitComment, setSubmitComment] = useState();
  const [reasonId, setReasonId] = useState();
  const [updateStatus, setUpdateStatus] = useState(false);
  const [amountExceedStatus, setAmountExceedError] = useState(false);
  const [detailsStatus, setDetailsStatus] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [checkboxState, setCheckboxState] = useState(false);
  const [openToolTip, setOpenToolTip] = useState(false);
  
  const handleTooltipClose = () => {
    setOpenToolTip(false);
  };

  const handleTooltipOpen = () => {
    setOpenToolTip(true);
  };

  const checkboxChangeHandler = (event) => {
    setCheckboxState(event.target.checked)
  }

  const getData = async (accountNumber) => {
    const data = {
      "accountNumber": accountNumber,
      "propertyState": selectedAccountData && selectedAccountData.propertyState ? selectedAccountData.propertyState : '', 
      "investorCode": selectedAccountData && selectedAccountData.bsi_InvestorCode ? selectedAccountData.bsi_InvestorCode : '', 
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };

    const res = await dispatch(getDashboardData(data));
    if (res) {
        setLoader(false)
        setTotalAmountDue(res?.totalAmountDue ? res?.totalAmountDue : '')
        setMonthlyAmountDue(res?.monthlyAmountDue ? res?.monthlyAmountDue : '')
        setDueDate(res?.paymentDueDate)
        setPaymentLateDate(res?.paymentLateDate)
        setAmount(res?.monthlyAmountDue)
        if (res?.isPromisePayMenu == false) {
          history.push('/Dashboard')
        }
    }
  }
 
  useEffect(() => {
    if (accountInfoData?.accountNumber) {
        setLoader(false)
        setTotalAmountDue(accountInfoData?.totalAmountDue ? accountInfoData?.totalAmountDue : '')
        setMonthlyAmountDue(accountInfoData?.monthlyAmountDue ? accountInfoData?.monthlyAmountDue : '')
        setDueDate(accountInfoData?.paymentDueDate)
        setPaymentLateDate(accountInfoData?.paymentLateDate)
        setAmount(accountInfoData?.monthlyAmountDue)
        if (accountInfoData?.isPromisePayMenu == false) {
          history.push('/Dashboard')
        }
    } else {
        getData(props?.accountNumber)
    }
  },[accountInfoData])
  
  useEffect(() => {
    if (props?.accountNumber != '') {
      getPromiseSubmitDetails()
    }
  }, [props?.accountNumber])

  const getPromiseSubmitDetails = async () => {
    setErrorMsg('')
    setLoader(true)
    const data = {
      "accountNumber": props?.accountNumber, 
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };

    const res = await getPromiseDetails(data);
    
    if (res?.responseData?.lstResponseStatus[0]?.statusCode === '0') {
        setDetailsStatus(true)
        setLoader(false)
        getReasonsData()
        setSubmitDetails(res?.responseData?.responseData)
        setSubmitStatus(true)
        setSubmitAmount(res?.responseData?.responseData?.promiseAmount?.toFixed(2))
        setSubmitDate(res?.responseData?.responseData?.promiseDate)
        setSubmitComment(res?.responseData?.responseData?.comment)

        if (res?.responseData?.responseData?.reasonValue != null) {
          showReasons(true)
          setReasonId(res?.responseData?.responseData?.reasonKey)
          setSubmitReason(res?.responseData?.responseData?.reasonValue)
        } else {
          showReasons(false)
          setSubmitReason(null)
        }
        if (res?.responseData?.responseData?.reasonValue?.toLowerCase() == 'Other'.toLowerCase()) {
          setOtherStatus(true)
        } else {
          setOtherStatus(false)
        }
      } else {
        setDetailsStatus(true)
        setSubmitStatus(false)
        setLoader(false)
      }
  }

  const getReasonsData = async () => {
    const data = {
      "accountNumber": props?.accountNumber, 
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };

    const res = await getDefaultReasonsData(data);
    if (res?.responseData.lstResponseStatus[0]?.statusCode === '0') {
      setReasons(res?.responseData?.responseData)
    } 
  }
 
  const submitData = async () => {

    setLoading(true)
    const data = {
        "accountNumber": props?.accountNumber, 
        "promiseDate": selectedDate,
        "promiseAmount": amount,
        "reason": reasonStatus ? reasonId : null,
        "reasonValue": reasonStatus ? reason : null,
        "comment": (reasonStatus && otherStatus) ? comments : null,
        "privateLabelUrl": Cookies.get("privateLabelUrl"),
        "ipAddress": Cookies.get('publicIp'),
        "browser": Cookies.get("browser"),
        "appSource": getAppSource(),
        "userType": getUserType(),
        "userName": ""
    };

    const res = await submitPromiseRequest(data);
    if (res?.responseData.lstResponseStatus[0]?.statusCode === '0') {
      setLoading(false)
      setLoader(false)
      setSubmitStatus(true)
      setThankyouStatus(true)
      getPromiseSubmitDetails()
    } else {
      setLoading(false)
      setLoader(false)
      setErrorMsg(res?.responseData?.lstResponseStatus[0]?.statusDesc);
    }
  }

  const handleUpdateClick = async () => {

    setThankyouStatus(false)
    setLoading(true)
    const data = {
        "promisePayId" : submitDetails?.promisePayId,
        "accountNumber": props?.accountNumber, 
        "promiseDate": submitDate,
        "promiseAmount": submitAmount,
        "reason": reasonStatus ? reasonId : null,
        "reasonValue": reasonStatus ? submitReason : null,
        "comment": (reasonStatus && otherStatus && (submitReason?.toLowerCase() == 'Other'.toLowerCase())) ? submitComment : null,
        "privateLabelUrl": Cookies.get("privateLabelUrl"),
        "ipAddress": Cookies.get('publicIp'),
        "browser": Cookies.get("browser"),
        "appSource": getAppSource(),
        "userType": getUserType(),
        "userName": ""
    };
   
    const res = await updatePromiseRequest(data);
    if (res?.responseData?.lstResponseStatus[0]?.statusCode === '0') {
        setErrorMsg('')
        setLoading(false)
        setEditPromiseStatus(false)
        setSubmitStatus(true)
        setUpdateStatus(true)
        getPromiseSubmitDetails()
    } else {
      setErrorMsg(res?.responseData?.lstResponseStatus[0]?.statusDesc);
      setLoading(false)
    }
  }

  if (loader) {
    return (
      <Box className={classes.loader}>
        <CircularProgress />
      </Box>
    )
  }
 
  const checkValidations = () => {

    if (!checkboxState || isAdmin() || (selectedDate == null) || amountErrStatus || amountExceedStatus || (reasonStatus && (reason == undefined)) || ((reason?.toLowerCase() == 'Other'.toLowerCase()) && ((comments == undefined) || (comments == "")))) {
        return true
    } else {
        return false
    }
  }

  const checkChanges = () => {

    if ((submitDetails?.promiseAmount == submitAmount) && (submitDetails?.promiseDate == submitDate) && (submitDetails?.reasonValue == null) && (submitDetails?.comment == null) || amountErrStatus || amountExceedStatus || (reasonStatus && (submitReason == undefined)) || ((submitReason?.toLowerCase() == 'Other'.toLowerCase()) && ((submitComment == undefined) || (submitComment == "")))) {
        return true
    } else if ((submitDetails?.promiseAmount == submitAmount) && (submitDetails?.promiseDate == submitDate) && (submitDetails?.reasonValue == submitReason) && (submitDetails?.comment == submitComment) || amountErrStatus || amountExceedStatus || (reasonStatus && (submitReason == undefined)) || ((submitReason?.toLowerCase() == 'Other'.toLowerCase()) && ((submitComment == undefined) || (submitComment == "")))) {
        return true
    } else if ((submitDetails?.promiseAmount == submitAmount) && (submitDetails?.promiseDate == submitDate) && (submitDetails?.reasonValue == submitReason) && (submitDetails?.comment == null) || amountErrStatus || amountExceedStatus || (reasonStatus && (submitReason == undefined)) || ((submitReason?.toLowerCase() == 'Other'.toLowerCase()) && ((submitComment == undefined) || (submitComment == "")))) {
        return true
    } else {
        return false
    }
  }

  const handleDateChange = async (date) => {
    setSelectedDate(date);
    setIsOpen(false)
    if ((new Date(moment(date).format('MM/DD/YYYY')) > new Date(moment(paymentLateDate).format('MM/DD/YYYY')))) {
        showReasons(true)
        getReasonsData()
    } else {
        showReasons(false)
        setReason()
        setComments()
    }
  }
 
  const handleEditDateChange = async (date) => {
    setSubmitDate(moment(date).format('YYYY-MM-DD'));
    setIsOpen(false)
    if ((new Date(moment(date).format('MM/DD/YYYY')) > new Date(moment(paymentLateDate).format('MM/DD/YYYY')))) {
        showReasons(true)
        getReasonsData()
    } else {
        showReasons(false)
        setSubmitReason()
        setSubmitComment()
    }
  }

  const handleAmountChange = (event) => {
    if (checkDigitWithDecimal(event.target.value)) {
      setAmount(event.target.value)
      if (Number(event.target.value) < Number(monthlyAmountDue)) {
        setAmountError(true)
      } else {
        setAmountError(false)
      }
      if (Number(event.target.value) >= Number(50000)) {
        setAmountExceedError(true)
      } else {
        setAmountExceedError(false)
      }
    }
  }

  const handleEditAmountChange = (event) => {
    if (checkDigitWithDecimal(event.target.value)) {
      setSubmitAmount(event.target.value)
      if (Number(event.target.value) < Number(monthlyAmountDue)) {
        setAmountError(true)
      } else {
        setAmountError(false)
      }
      if (Number(event.target.value) >= Number(50000)) {
        setAmountExceedError(true)
      } else {
        setAmountExceedError(false)
      }
    }
  }

  const defaultReason = (data, id) => {
      setReason(data)
      setReasonId(id)
      if (data?.toLowerCase() == 'Other'.toLowerCase()) {
        setOtherStatus(true)
      } else {
        setOtherStatus(false)
      }
  }
 
  const setDefaultReason = (data, id) => {
      setSubmitReason(data)
      setReasonId(id)
      if (data?.toLowerCase() == 'Other'.toLowerCase()) {
        setOtherStatus(true)
      } else {
        setOtherStatus(false)
      }
  }

  const submitForm = () => {
    submitData()
  }

  let allDefaultReasons = '';
  if (defaultReasons && defaultReasons.length) {
    allDefaultReasons = defaultReasons.map((item, index) => {
      return (
        <MenuItem value={item.ddL_Value} key={item.ddL_Value} onClick={() => defaultReason(item.ddL_Value, item.ddL_Key)}>{item.ddL_Value}</MenuItem>
      )
    })
  }

  let defaultForReasons = '';
  if (defaultReasons && defaultReasons.length) {
    defaultForReasons = defaultReasons.map((item, index) => {
      return (
        <MenuItem value={item.ddL_Value} key={item.ddL_Value} onClick={() => setDefaultReason(item.ddL_Value, item.ddL_Key)}>{item.ddL_Value}</MenuItem>
      )
    })
  }

  const handleCancelClick = () => {
    setUpdateStatus(false)
    setEditPromiseStatus(false)
    setSubmitStatus(true) 
    getPromiseSubmitDetails()
  }

  const routeToPayment = () => {
    history.push('/PaymentOption')
  }

  const routeToDashboard = () => {
    history.push('/Dashboard')
  }

  const editPromise = () => {
    setEditPromiseStatus(true)
  }
 
  const handleAddCommentChange = (event) => {
    setComments(event.target.value)
  }
 
  const handleEditCommentChange = (event) => {
    setSubmitComment(event.target.value)
  }

  const calculateCommentLenth = () => {
    const length = comments?.length 
    if (isNaN(length)) {
        return 0
    } else {
        return length
    }
  }

  const calculateEditCommentLenth = () => {
    const length = submitComment?.length 
    if (isNaN(length)) {
        return 0
    } else {
        return length
    }
  }

  return (
    <CssBaseline>
       <Box container="true" maxWidth='xl' className={classes.root}>      
        <Box className={classes.container}>
        <CustomBreadcrum backPage="Payments" backPageLink="/PaymentOption" activePage="Promise Plan" />
          <h1 className={classes.pageHeading} color="textPrimary" component='h4'>Promise Plan</h1>
          { detailsStatus &&
          <Box>
          {!editPromiseStatus &&
          <>
         { !submitStatus ?
            <Box className={classes.infoStrip}>
                  <Grid container>
                    <Grid item md={12} xs={12} lg={12}>
                      <Box className={classes.infoSection}>
                        <Typography className={classes.heading}>Make a Payment Today or  Set Up Automatic Payments</Typography>
                          <Grid container>
                            <Grid item  md={3} xs={6} lg={3}>
                              <Typography className={classes.paymentHeading}>Total Amount Due</Typography>
                              <Typography className={classes.paymentSubHeading}>{'$ ' + totalAmountDue}</Typography>
                             
                            </Grid>
                            <Grid item  md={3} xs={6} lg={3}>
                              <Typography className={classes.paymentHeading}>Monthly Amount Due</  Typography>
                              <Typography className={classes.paymentSubHeading}>{ '$ ' + monthlyAmountDue}</Typography>
                            </Grid>

                            <Grid item  md={3} xs={6} lg={3}>
                              <Typography className={classes.paymentHeading}>Payment Due Date</Typography>
                              <Typography className={classes.paymentSubHeading}>{ moment(dueDate).format('MM/DD/YYYY')}</Typography>
                             
                            </Grid>
                            <Grid item  md={3} xs={6} lg={3}>
                              <Box className={classes.buttonContainer}>
                                <Button type='submit' className={classes.setUpPayment} size="medium" onClick={() => routeToPayment()} variant="contained" color="primary">
                                SET UP PAYMENT
                                </Button>
                              </Box>
                            </Grid>
                        </Grid>              
                      </Box>     
                    </Grid> 
            </Grid>
          
            <Box className={classes.formSection}>
              <hr className={classes.line} />
              <Typography className={classes.subHeading}> Not ready to set up and make a payment today? Set Up a Promise Plan!
                <IconButton
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                >
                  
                {!mediaQuery && <LightTooltip
                        title={"A promise plan lets us know when you plan to make your payment and why you could not  make the payment on the original due date."}
                        arrow placement="top" >
                        <InfoIcon classes={{ root: classes.colorIcon }} />
                      </LightTooltip> }
                      {mediaQuery && 
                      <ClickAwayListener onClickAway={handleTooltipClose}>
                      <LightTooltip
                        title={"A promise plan lets us know when you plan to make your payment and why you could not  make the payment on the original due date."}
                          arrow placement="top"
                          open={ openToolTip }
                        >
                          <InfoIcon classes={{ root: classes.colorIcon }} onClick={handleTooltipOpen}/>
                        </LightTooltip>
                      </ClickAwayListener>}
                </IconButton>
              </Typography>

                <Grid container>
                    <Grid item md={12} xs={12} lg={12}>
                      <Box className={classes.detailsSection}>
                          <Grid container>
                            <Grid item  md={6} xs={12} lg={6}>
                            <Box className={classes.inputContainer}>
                                <TextField
                                    error={amountErrStatus || amountExceedStatus}
                                    helperText={amountErrStatus ? 'The minimum amount to pay is $' + `${monthlyAmountDue}` + ' .Please enter an amount equal to or greater than this.' : amountExceedStatus ? 'Payment Amount Restricted: Total payment amount can not be equal to or greater than $50,000' : ""}
                                    required
                                    color='primary'
                                    id="outlined-loanNumber"
                                    label="Amount"
                                    variant="outlined"
                                    type="text"
                                    value={amount}
                                    onChange={(event) => handleAmountChange(event)}
                                     fullWidth
                                    className={`${classes.inputStyle} ${classes.pl20}`}
                                    classes={{
                                        root: classes.additionalPayTextField
                                    }}
                                    InputProps={{
                                        classes: { root: classes.textField },
                                        startAdornment: (
                                            <InputAdornment position="start">
                                            <AttachMoneyIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    InputLabelProps={{
                                        classes: { root: classes.textField },
                                        formlabelclasses: {
                                          asterisk: classes.labelAsterisk
                                        }
                                    }}
                                />
                              </Box>  
                            </Grid>

                            <Grid item  md={6} xs={12} lg={6}>
                            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
                              <Grid container className={classes.datePicker}>
                                 <KeyboardDatePicker
                                    required                           
                                    maxDate={moment(dueDate).add(30, 'day')}
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="MM/DD/YYYY"
                                    margin="normal"
                                    id="date-picker1-inline"
                                    label="Date"
                                    defaultValue={null}
                                    minDate={new Date()}
                                    placeholder="MM/DD/YYYY"
                                    value={selectedDate ? selectedDate : null}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}

                                    KeyboardButtonProps={{
                                        onFocus: e => {
                                        setIsOpen(true);
                                        }
                                    }}
                                    PopoverProps={{
                                        disableRestoreFocus: true,
                                        onClose: () => {
                                        setIsOpen(false);
                                        }
                                    }}
                                    InputProps={{
                                        onFocus: () => {
                                        setIsOpen(true);
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    open={isOpen}

                                    />

                                </Grid>
                                </MuiPickersUtilsProvider>
                            </Grid>                           
                        </Grid>              
                      </Box>     
                    </Grid> 
              </Grid>
              <Grid container>
                <Grid item md={12} xs={12} lg={12}>
                  <Grid container>
                  {reasonStatus && allDefaultReasons?.length > 0 &&
                    <Grid item md={6} xs={12} lg={6}>
                        <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                            <InputLabel id="borrower-type-select-outlined">Reason For Payment Delay *</InputLabel>
                            <Select
                            labelId="borrower-type-outlined-label"
                            inputRef={el => myRef.current['borrowerTypeFocus'] = el}
                            required
                            label="Reason For Payment Delay *"
                            className={classes.inputStyle}
                            fullWidth
                            >
                            {allDefaultReasons}
                            </Select>
                        </FormControl>
                </Grid>
            }
            {(otherStatus && reasonStatus && (reason?.toLowerCase() == 'Other'.toLowerCase())) &&
                <Grid item md={6} xs={12} lg={6}>
                    <Box className={classes.textareaSection}>
                      <TextareaAutosize
                        style={{ height: '56px'}}
                        color="primary"
                        onChange={(event) => handleAddCommentChange(event)}
                        id={`outlined_impacted250`}
                        variant="outlined"
                        type="text"
                        name="comments"
                        maxLength="100"
                        value={comments}
                        placeholder="Add Comments*"
                        className={classes.textArea}
                        />
                        <Typography className={classes.textAreaValidation}>{calculateCommentLenth()}/100</Typography>
                     </Box>      
                </Grid>
             }
              </Grid>
              </Grid>
              </Grid>
              {(reasonStatus && (allDefaultReasons?.length > 0)) && <Typography className={classes.finding}> The promise to pay date that has been entered is after the grace period. Making a late payment may result in additional fees. <a href="./FeesSchedule" target="_self" className={classes.feeScedule}>Click here</a> to view the fee schedule.</Typography>}
             
              {/* <Typography className={classes.finding}>Making a late payment or breaking the terms of your mortgage agreement may result in additional fees. Click here to view <a href="./FeesSchedule" target="_self" className={classes.feeScedule}>fee schedule</a>.</Typography> */}
              <Box style={{paddingTop: '15px'}}>
                <FormGroup row>
                  <FormControlLabel
                      control={<Checkbox
                      checked={checkboxState}
                      onChange={(event) => checkboxChangeHandler(event)}
                      className={classes.checkboxContainer}
                      name="agree"
                  />}
                      label={<><Typography> By making a payment promise, I am only promising to pay on a certain date. I acknowledge that I must take additional action to initiate payment by that date and I will receive confirmation once the payment is made.<span style={{color:"red"}}>*</span></Typography></>}
                      className={classes.inputStyle}
                      classes={{
                          label: classes.radioLabel
                      }}
                  />
                </FormGroup>
              </Box>
              <p className={classes.errorMsg} dangerouslySetInnerHTML={{ __html: errorMsg !== '' ? errorMsg : '' }} />

              <Grid container className={classes.footerSection}>
                <Grid item md={12} xs={12} lg={12}>
                    <Grid container style={{ marginTop: '12px' }}>
                        <Grid item xs={5} md={6} className={classes.pr35}>
                            <Box className={classes.cancelBtnBox}>
                            <Button
                                className={classes.cancelBtn}
                                variant="outlined"
                                onClick={() => routeToDashboard()} 
                            >CANCEL</Button>
                            </Box>
                        </Grid>
                        <Grid item xs={7} md={6} className={classes.pl35}>
                            <Box className={classes.saveBtnBox}>
                            {loading ?
                              <Button disabled={true} type='submit' className={classes.saveBtn} variant="contained" color='primary'>
                                <CircularProgress color='primary' />
                              </Button>
                              :
                             <Button
                                className={classes.saveBtn}
                                disabled={checkValidations()}
                                variant="contained"
                                onClick={() => submitForm()} 
                                color="primary">
                               CONFIRM</Button> }
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
             </Grid>
            </Box>
          </Box>
          :
          <Box className={classes.infoStrip}>
           <Grid container>
            <Grid item md={12} xs={12} lg={12}>
              <Box className={classes.activePromise}>
                  <Grid container>
                    <Grid item  md={6} xs={6} lg={6}>
                     <Typography className={classes.heading}>My Active Promise</Typography>
                    </Grid>
                    <Grid item  md={6} xs={6} lg={6}>
                      <Typography className={classes.modify} onClick={() => editPromise()}><EditIcon className={classes.editIcon} />Modify</Typography>
                    </Grid>
                  </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12} xs={12} lg={12}>
              <Box className={classes.listSection}>
                  <Grid container>
                    <Grid item  md={2} xs={6} lg={2}>
                      <Typography className={classes.paymentHeading}>Promise Date</Typography>
                      <Typography className={classes.paymentSubHeading}>{submitDetails?.promiseDate}</Typography>
                     
                    </Grid>
                    <Grid item  md={2} xs={6} lg={2}>
                      <Typography className={classes.paymentHeading}>Amount </  Typography>
                      <Typography className={classes.paymentSubHeading}> {'$ ' +  submitDetails?.promiseAmount?.toFixed(2)}</Typography>
                    </Grid>
                   {submitDetails?.reasonValue != null &&
                    <Grid item  md={4} xs={6} lg={4}>
                      <Typography className={classes.paymentHeading}>Reason For Payment Delay</  Typography>
                      <Typography className={classes.paymentSubHeading}>{submitDetails?.reasonValue}</Typography>
                    </Grid>
                   }
                   {(submitDetails?.reasonValue?.toLowerCase() == 'Other'?.toLowerCase()) &&
                    <Grid item  md={2} xs={6} lg={2}>
                      <Typography className={classes.paymentHeading}>Comments</Typography>
                      <Typography className={classes.paymentSubHeading}>{submitDetails?.comment}</Typography>
                    </Grid>
                   }
                    <Grid item  md={3} xs={6} lg={3}>
                      <Typography className={classes.paymentHeading}>Date Of Submission</Typography>
                      <Typography className={classes.paymentSubHeading}>{submitDetails?.createdDate}</Typography>
                    </Grid>
                </Grid>   
                {updateStatus && <p className={classes.successMsg}>{'Changes Saved Successfully!'}</p>}           
              </Box>     
            </Grid> 
        </Grid>
  
        <Box className={classes.formSection}>
            <hr className={classes.line} />
            <Typography className={classes.subHeading}> Transaction History </Typography>
            <Typography className={classes.transaction}>Need to review your Transaction history? Click here to access your <a href="./TransactionHistory" target="_self" className={classes.feeScedule}>transaction records</a>.</Typography>
        </Box>
       </Box>
      }
      </>
      }
      { editPromiseStatus &&
         <Box className={classes.infoStrip}>
         <Grid container>
           <Grid item md={12} xs={12} lg={12}>
             <Box className={classes.infoSection}>
               <Typography className={classes.editPromiseHeading}>My Active Promise</Typography>    
             </Box>     
           </Grid> 
       </Grid>
 
   <Box className={classes.formSection}>
  
       <Grid container>
           <Grid item md={12} xs={12} lg={12}>
             <Box className={classes.detailsSection}>
                 <Grid container>
                   <Grid item  md={6} xs={12} lg={6}>
                   <Box className={classes.inputContainer}>
                       <TextField
                           required
                           error={amountErrStatus || amountExceedStatus}
                           helperText={amountErrStatus ? 'The minimum amount to pay is $' + `${monthlyAmountDue}` + ' .Please enter an amount equal to or greater than this.' : amountExceedStatus ? 'Payment Amount Restricted: Total payment amount can not be equal to or greater than $50,000' : ""}
                           color='primary'
                           id="outlined-loanNumber"
                           label="Amount"
                           variant="outlined"
                           type="text"
                           value={submitAmount}
                           onChange={(event) => handleEditAmountChange(event)}
                           fullWidth
                           className={`${classes.inputStyle} ${classes.pl20}`}
                           classes={{
                               root: classes.additionalPayTextField
                           }}
                           InputProps={{
                               classes: { root: classes.textField },
                               startAdornment: (
                                   <InputAdornment position="start">
                                     <AttachMoneyIcon />
                                   </InputAdornment>
                               ),
                           }}
                           InputLabelProps={{
                               classes: { root: classes.textField }
                           }}
                       />
                     </Box>  
                   </Grid>

                   <Grid item  md={6} xs={12} lg={6}>
                   <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
                     <Grid container className={classes.datePicker}>
                        <KeyboardDatePicker  
                           required                          
                           maxDate={moment(dueDate).add(30, 'day')}
                           variant="inline"
                           inputVariant="outlined"
                           format="MM/DD/YYYY"
                           margin="normal"
                           id="date-picker-inline"
                           label="Date"
                           minDate={new Date()}
                           value={submitDate}
                           placeholder="MM/DD/YYYY"
                           onChange={handleEditDateChange}
                           KeyboardButtonProps={{
                               'aria-label': 'change date',
                           }}

                           KeyboardButtonProps={{
                               onFocus: e => {
                               setIsOpen(true);
                               }
                           }}
                           PopoverProps={{
                               disableRestoreFocus: true,
                               onClose: () => {
                               setIsOpen(false);
                               }
                           }}
                           InputProps={{
                               onFocus: () => {
                               setIsOpen(true);
                               }
                           }}
                           InputLabelProps={{
                               shrink: true,
                           }}
                           open={isOpen}

                           />

                       </Grid>
                       </MuiPickersUtilsProvider>
                   </Grid>                           
               </Grid>              
             </Box>     
           </Grid> 
     </Grid>
     <Grid container>
       <Grid item md={12} xs={12} lg={12}>
         <Grid container>
            { (reasonStatus && (defaultForReasons?.length > 0)) &&
                <Grid item md={6} xs={12} lg={6}>
                  <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                    <InputLabel id="borrower-type-select-outlined">Reason For Payment Delay *</InputLabel>
                      <Select
                        labelId="borrower-type-outlined-label"
                        inputRef={el => myRef.current['borrowerTypeFocus'] = el}
                        value={submitReason}
                        label="Reason For Payment Delay *"
                        className={classes.inputStyle}
                        fullWidth
                        >
                        {defaultForReasons}
                      </Select>
                    </FormControl> 
            </Grid>
            }
            { (reasonStatus && (submitReason?.toLowerCase() == 'Other'.toLowerCase()) && otherStatus) &&
              <Grid item md={6} xs={12} lg={6}>
                  <Box className={classes.textareaSection}>
                    <TextareaAutosize
                      style={{ height: '56px'}}
                      fullWidth
                      onChange={(event) => handleEditCommentChange(event)}
                      color="primary"
                      id={`outlined_impacted250`}
                      variant="outlined"
                      type="text"
                      value={submitComment}
                      name="impacted250"
                      maxLength="100"
                      placeholder="Add Comments*"
                      className={classes.textArea}
                      />
                      <Typography className={classes.textAreaValidation}>{calculateEditCommentLenth()}/100</Typography>
                    </Box>   
              </Grid>
            }
            </Grid>
          </Grid>
        </Grid>
        { (reasonStatus && (defaultForReasons?.length > 0)) && <Typography className={classes.finding}> The promise to pay date that has been entered is after the grace period. Making a late payment may result in additional fees. <a href="./FeesSchedule" target="_self" className={classes.feeScedule}>Click here</a> to view the fee schedule.</Typography>}
        <p className={classes.errorMsg} dangerouslySetInnerHTML={{ __html: errorMsg !== '' ? errorMsg : '' }} />
     <Grid container className={classes.footerSection}>
       <Grid item md={12} xs={12} lg={12}>
           <Grid container style={{ marginTop: '12px' }}>
               <Grid item xs={5} md={6} className={classes.pr35}>
                   <Box className={classes.cancelBtnBox}>
                   <Button
                       className={classes.cancelBtn}
                       variant="outlined"
                       onClick={() => handleCancelClick()} 
                   >CANCEL</Button>
                   </Box>
               </Grid>
               <Grid item xs={7} md={6} className={classes.pl35}>
                   <Box className={classes.saveBtnBox}>
                     {loading ?
                        <Button disabled={true} type='submit' className={classes.saveBtn} variant="contained" color='primary'>
                          <CircularProgress color='primary' />
                        </Button>
                        :
                       <Button
                       className={classes.saveBtn}
                       disabled={checkChanges() || isAdmin()}
                       variant="contained"
                       onClick={() => handleUpdateClick()} 
                       color="primary">
                      UPDATE</Button> }
                   </Box>
               </Grid>
           </Grid>
       </Grid>
    </Grid>
   </Box>

    <Box className={classes.formSection}>
            <hr className={classes.line} />
            <Typography className={classes.subHeading}> Transaction History </Typography>
            <Typography className={classes.transaction}>Need to review your Transaction history? Click here to access your <a href="./TransactionHistory" target="_self" className={classes.feeScedule}>transaction records</a>.</Typography>
        </Box>
    </Box>
      }
     </Box>
     }
     </Box>
    </Box>
    {
      thankyouStatus && <ThankyouAlert open={true}/>
    }
    </CssBaseline>
  )
}
