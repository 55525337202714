import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField, OutlinedInput, InputLabel } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 12,
    backgroundColor: "#ffffff",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    padding: '0.5em 3em 2.5em 3em',
    [theme.breakpoints.down('xs')]: {
      padding: '2em 1.6em',
    },
  },

  heading: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '20px',
    fontWeight: "500",
    letterSpacing: "0.63px",
    lineHeight: "29px"
  },
  dialogStyle: {
    maxHeight: '100% !important',
    borderRadius: '12px !important',
    width: "35%",
    [theme.breakpoints.down('md')]: {
      width: "38%",
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  desc: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: "14px",
    letterSpacing: "0.53px",
    lineHeight: "18px",
  },
  resendCode: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 600,
    letterSpacing: "0.53px",
    lineHeight: "18px",
    cursor: "pointer",
    textDecoration: "underline",
    margin: '1.2em 0 1.5em 0'
  },
  timeLineText: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 800,
    letterSpacing: "0.6px",
    lineHeight: "19px"
  },
  cancelBtnBox: {
    float: "right"
  },

  saveBtn: {
    width: 192,
    height: 40,
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "600",
    letterSpacing: "0.53px",
    lineHeight: "19px",
    textAlign: "center",
  },
  cancelBtn: {
    width: 192,
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "600",
    letterSpacing: "0.53px",
    lineHeight: "19px",
    textAlign: "center",
    border: "2px solid"
  },
  inputContainer: {
    marginBottom: '1.6em',
    marginTop: '1.6em',

  },
  inputContainerWithText: {
    marginBottom: '2em',
  },
  inputContainerLeft: {
    marginBottom: '3.9em',
    marginRight: '3.5em'
  },
  inputContainerLeftWithText: {
    marginBottom: '2em',
    marginRight: '3.5em'
  },

  inputContainerRight: {
    marginBottom: '3.9em',
    marginLeft: '3.5em'
  },

  inputStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600"
  },

  labelStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "400",

  },
  suggestionText: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "300",
    letterSpacing: "0.31px",
    lineHeight: "18px",
    paddingLeft: "24px"
  },
  errorText: {
    color: "red",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "600",
    letterSpacing: "0.31px",
    lineHeight: "18px",
    marginBottom: '12px'     
  },
  pl35: {
    paddingLeft: "35px"
  },
  pr35: {
    paddingRight: "35px"
  },

  expireText: {

    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: 'bold',
    minWidth: 140,
    color: theme.palette.secondary.main,
  },

  timerContainer: {
    width: 300,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '20px auto',
  },

  circle: {
    width: '120px',
    height: '120px',
  },

  timerBox: {
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  timerText: {
    color: theme.palette.secondary.main,
    fontFamily: 'Montserrat',
    fontSize: '32px',
    fontWeight: '600',
    letterSpacing: '0',
    textAlign: 'center',
    lineHeight: '24px'
  },

  timerLoader: {
    color: theme.palette.secondary.main,
  },

  timerSmallText: {
    fontSize: '16px',
    margin: '0'
  },
  //============overwrite class===
  paperScrollPaper: { maxHeight: "100%" },

  iconStyle: {
    padding: 0,
    color: '#535D6D',
    fontSize: '24px',
    cursor: 'pointer'
  },
}));

export const CutomTextField = withStyles((theme) => ({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.primary.main
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main
      },
    },
  },
}))(TextField);

export const CutomOutlinedInput = withStyles((theme) => ({
  root: {
    "&$focused $notchedOutline": {
      border: `2px solid ${theme.palette.primary.main}`,
    }
  },
  focused: {},
  notchedOutline: {}
}))(OutlinedInput);

export const CustomInputLabel = withStyles((theme) => ({
  root: {
    "&$focused": {
      color: theme.palette.primary.main,
    }
  },
  focused: {},
}))(InputLabel);

