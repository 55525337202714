import React from 'react';
import { useStyles } from './styles';
import { Box, Button } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Link } from 'react-router-dom';



export const Success = (props) => {
  const classes = useStyles();


  return (
    <Box container="true" maxWidth='xl' className={classes.root}>
      <Box className={classes.wrapper}>
        <Box className={classes.checkboxContainer}>
          <CheckCircleOutlineIcon fontSize='large' color="primary" classes={{ root: classes.icon }} />
        </Box>
        <p className={classes.title}>{props.title}</p>
        <p className={classes.hintText}>{props.hintText}</p>
        <Box className={classes.btnContainer}>
          <Link to={`${props.path}`} style={{ textDecoration: 'none' }}>
            <Button className={classes.sendBtn} variant="contained" color="primary">{props.btnTitle}</Button>
          </Link>
        </Box>
      </Box>
    </Box>
  )
}
