import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '10px 20px',
    //marginTop: 20,
    [theme.breakpoints.down('md')]: {
      marginTop: 20,
    },
  },

  title: {
    width: '100%',
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: "600",
    marginBottom: 10,
  },

  canvasContainer: {
    width: '100%',
    marginBottom: 20,
  },


  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  table: {
    width: '100%',
  },

  borderCell: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "14px",
    borderRight: 'solid 1px #ddd'
  },

  nonBorderCell: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "14px",
  },
  actionBtn:{
    display:"flex",
    alignItems:"center"
  },
  link:{
    cursor:"pointer"
  }
}));

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#535D6D",
    padding: 10,
    fontFamily: "Montserrat",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
export const ReportTooltip = withStyles((theme) => ({  
  tooltip: {
    marginTop: 0,    
    fontSize: 12,
    maxWidth: 'none',
    padding: 10,    
  },
}))(Tooltip);