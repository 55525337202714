import signinIcon from '../../../assets/SignIn/SignIn.svg';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, withStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => (
  {
    loadingRoot: {
      width: '100%',
      height: '100vh',
      padding: '6.4em 6%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#ccc',
    },
    root: {
      width: '100%',
      minHeight: '100vh',
      backgroundColor: '#ccc',
      display: 'flex',
      justifyContent: 'center',
      backgroundImage: `url(${signinIcon})`,
      backgroundPosition: "bottom left",
      backgroundRepeat: "no-repeat",
      backgroundSize: 'contain',
    },
    container: {
      width: '120em',
      minWidth: "33.3em",
      marginTop: '3.6em',
      marginBottom: '6.6em',
      [theme.breakpoints.down('sm')]: {
        margin: '42px 16px',
      },
    },

    formContainer: {
      minHeight: "300px",
      backgroundColor: '#fff',
      borderRadius: 12,
      boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    appBar: {
      width: "100%",
      backgroundColor: "#1A4664",
      color: theme.header.fontColor,
      padding: '0px 16px',
      borderRadius: '4px'
    },

    tabStyle: {
      display: 'flex',
      flexWrap: 'wrap',
      boxSizing: 'border-box',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',        
      }
    },

    containerPadding: {
      padding: "4em 7em",
      [theme.breakpoints.down('sm')]: {
        padding: "30px 16px",
      }
    },

    tabContainer: {
      borderRadius: "12px",
      backgroundColor: "#F5F5F5",
      [theme.breakpoints.down('sm')]: {
        overflowX: 'scroll',
      }
    },
    tabShedow: {     
      //
    },
    tabs: {
      color: "#888b90",
      fontFamily: "Montserrat",
      fontSize: "23px",
      fontWeight: "600",
      letterSpacing: "0.63px",
      lineHeight: "29px",
      textAlign: "center",
      cursor: "pointer",
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
      borderBottom: '1px solid #d2cfcf',
      cursor: 'pointer',
      boxShadow: '0px -2px 3px #6c6363',
      width: '33.33%',      
      padding: '17px 10px 20px 10px',
      [theme.breakpoints.down('md')]: {
        fontSize: "20px",
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: "20px",
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        padding: '16px 10px',
      },
    },
    tabsDisable: {
      backgroundColor: 'lightgrey',
      color: "#888b90",
      fontFamily: "Montserrat",
      fontSize: "23px",
      fontWeight: "600",
      letterSpacing: "0.63px",
      lineHeight: "29px",
      textAlign: "center",
      cursor: "default",
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
      borderBottom: '1px solid #d2cfcf',
      boxShadow: '0px -2px 3px #6c6363',
      width: '33.33%',      
      padding: '17px 10px 20px 10px',
      [theme.breakpoints.down('md')]: {
        fontSize: "20px",
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: "20px",
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        padding: '16px 10px',
      },
    },
    linkStyle: {
      color: "inherit",
      textDecoration: "none"
    },
    activeTab: {
      borderTopLeftRadius: "12px",
      borderTopRightRadius: "12px",
      backgroundColor: "#ffffff",
      color: "#535D6D",
      fontWeight: "900",
      borderBottom: 'none',
      '& .linkStyle': {
        color: "#727272",

      },
    },
    labelText: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "16px",
      letterSpacing: "0.36px",
      lineHeight: "19px",
    },
    labelTextBold: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "600",
      letterSpacing: "0.36px",
      lineHeight: "19px",
      marginTop: '7px',
      marginBottom: '7px',
      paddingLeft: '56px'
    },
    labelTextBoldMobile: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "600",
      letterSpacing: "0.36px",
      lineHeight: "19px",
      marginTop: '7px',
      marginBottom: '7px',
      paddingLeft: '49px'
    },
    icon: {
      float: "left",
      marginRight: "30px"
    },
    profileBtn: {
      width: "192px",
      fontSize: "16px",
      fontFamily: "Montserrat",
      fontWeight: "600",
      letterSpacing: "0.53px",
      lineHeight: "19px",
      textAlign: "center",
      height: '40px'
    },
    closeBtn: {
      height: "20px",
      width: "20px",
      backgroundColor: "#ffffff",
      color: "#3064A6",
      borderRadius: "50%",
      display: "inline-block",
      textAlign: "center",
      fontSize: "14px",
      fontWeight: 600,
      cursor: "pointer"
    },

    pl60: {
      paddingLeft: "60px"
    },
    infoIcon: {
      paddingRight: "10px",
      fontSize: '35px',
      color: theme.palette.secondary.contrastText,
    },
    infoText: {
      color: theme.palette.secondary.contrastText,
      fontFamily: "Montserrat",
      fontSize: "16px",
      letterSpacing: "0.36px",
      lineHeight: "19px",
    },
    infolink: {
      fontWeight: 600,
      cursor: "pointer",
      textDecoration: "underline"
    },
    mobileInfoTooltip: {
      boxSizing: "border-box",      
      border: "1px solid #E8E8E8",
      borderRadius: "12px",
      backgroundColor: "#FFFFFF",
      boxShadow: "0 3px 6px 0 rgba(0,35,64,0.2)",
      position: "absolute",
      zIndex: "9",
      width: "360px",
      marginLeft: "136px",
      marginTop: "-5px",
      padding: "4px"
    },
    emailInfoTooltip: {
      boxSizing: "border-box",
      border: "1px solid #E8E8E8",
      borderRadius: "12px",
      backgroundColor: "#FFFFFF",
      boxShadow: "0 3px 6px 0 rgba(0,35,64,0.2)",
      position: "absolute",
      zIndex: "9",
      width: "360px",
      marginLeft: "112px",
      marginTop: "-29px",
      padding: "4px"
    },

    profileMargin: {
      marginBottom: '26px',
      paddingRight: '20px'
    },

    labelTextMobile: {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '60px'
      },
    },

    pl14: {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '14px'
      },
    },

    pt0: {
      [theme.breakpoints.down('xs')]: {
        paddingTop: '0'
      },
    },

    noteText: {
      fontFamily: "Montserrat",
      fontSize: "14px",
      color: 'rgba(0, 0, 0, 0.74)',
      fontWeight: '500',
    },

    wordBreak: {
      wordBreak: 'break-all'
    },

    errorIcon: {
      // fontSize: "25px",
      float: "left",
      width: '18px',
      height: '18px',
      marginRight: "3px",
      // paddingLeft: '56px'
    },
    verifyBtn: {
      float: 'right',
      fontSize: "16px",
      fontFamily: "Montserrat",
      fontWeight: "600",
      letterSpacing: "0.53px",
      lineHeight: "19px",
      textAlign: "center",
      height: '40px',
      padding: '6px !important'
    },
    
    labelTextname: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "600",
      letterSpacing: "0.36px",
      lineHeight: "19px",
      marginTop: '7px',
      marginBottom: '7px',
      // paddingLeft: '56px'
    },
    emailContainer: {
      paddingLeft: 30
    },
    verifyText: {
      backgroundColor: '#7EB1CC',
      color: 'white',
      fontFamily: "Montserrat",
      fontSize: "13px",
      fontWeight: "600",
      letterSpacing: "0.36px",
      // lineHeight: "13px",
      border: 'none',
      cursor: "pointer",

      paddingLeft: '13px',
      paddingRight: '13px',
      borderRadius: '4px',
      paddingTop: '5px',
      paddingBottom: '5px',
      // padding: '0.5px 1px !important'
      // textDecoration: 'underline'
    },

  }
));

export const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: '#25ABC7',
    fontSize: '40px'
  },
  tooltip: {
    color: "#fff",
    backgroundColor: '#25ABC7',
    padding: '20px 15px',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    letterSpacing: '0.55px',
    lineHeight: '21px',
    width: '500px',
    maxWidth: '500px',
    borderRadius: '10px'
  },
}));


export const RedTooltip = withStyles((theme) => ({
  arrow: {
    color: "#B91717",
  },
  tooltip: {
    backgroundColor: '#B91717',
    color: '#fff',
    fontFamily: "Montserrat",
    boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
    padding: "0.5em 1.6em",
    fontSize: 16,
    maxWidth: 600,
  },
}))(Tooltip);
