import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '25px',
    [theme.breakpoints.down('md')]: {
      padding: '25px 16px',
    }
  },

  manageAutoPayMultiLoan: {
    width: '100%',
  },

  loaderContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  header: {
    width: '100%',
    border: "2px solid rgba(0,0,0,0.12)",
    borderRadius: 12,
    backgroundColor: '#eaeced',
  },

  headerImg: {
    width: 35,
    height: 35,
    marginRight: 25,
  },

  headerTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: 20,
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
    padding: '20px 53px',
    [theme.breakpoints.down('md')]: {
      padding: '20px 16px',
    }
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: " 2.4em",
    fontWeight: "400",
    letterSpacing: '0.18px',
    [theme.breakpoints.down('md')]: {
      marginBottom: 0
    }
  },

  content: {
    width: '100%',
    padding: '0 53px 40px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '0 16px 40px',
    }
  },


  text: {
    color: "rgba(0,0,0,0.74)",
    fontFamily: "Montserrat",
    fontSize: " 1.6em",
    fontWeight: "400",
    letterSpacing: '0.5px',
    marginBottom: 10,
  },

  inline: {
    display: 'inline',
  },

  headerSubText: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: " 2em",
    fontWeight: "bold",
  },

  linkText: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: " 1.4em",
    fontWeight: "bold",
    marginLeft: 40,
    textDecoration: 'underline',
    textTransform: "capitalize",
  },

  setting: {
    width: '100%',
    padding: '35px 60px'
  },

  settingContent: {
    width: '100%',
    padding: '0 15px 35px',
    borderBottom: 'solid 1px #4571C4',
    marginBottom: 35,
  },

  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  settingSubTitle: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 2.1em",
    fontWeight: "bold",
    marginBottom: 35,
  },

  item: {
    maxWidth: '50%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      marginTop: 40,
    }
  },

  subTitle: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 1.6em",
    fontWeight: "bold",
    marginBottom: 20
  },

  subText: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 1.4em",
  },

  additionalPayHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 35,
  },

  marginFix: {
    marginBottom: 0
  },

  additionalPaySubTitle: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 1.6em",
    fontWeight: "bold",
    marginRight: 17,
  },

  additionalPaySubTitleBig: {
    fontSize: "1.8em",
  },

  additionalPayItem: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },

  btn: {
    width: 212,
    fontFamily: "Montserrat",
    fontSize: " 1.4em",
  },

  infoBox: {
    width: '100%',
    border: "solid 2px",
    borderColor: theme.palette.primary.main,
    borderRadius: 10,
    backgroundColor: "#fff9f3",
    padding: 12,
    marginBottom: 40,
  },

  infoBoxText: {
    fontFamily: "Montserrat",
    fontSize: " 1.6em",
    color: 'rgba(0, 0, 0, 0.74)',
    fontWeight: '500',
    margin: 0,
  },

  infoBoxBtn: {
    fontFamily: "Montserrat",
    fontSize: " 1.6em",
    textAlign: 'right',
    color: '#535D6D',
    fontWeight: '600',
    border: 'none',
    padding: 0,
    textDecoration: 'underline',
    '&:hover': {
      border: 'none',
      background: 'none',
    }
  },

  flexEnd: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  viewDetailsLink: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: " 14px",
    fontWeight: "bold",
    marginLeft: 10,
    textDecoration: 'underline',
  }


}));

