import React, { useState } from 'react';
import { useStyles } from './styles';
import { Box, Button, CircularProgress, TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { recoverPassword } from '../../actions';
import Cookies from 'js-cookie';
import { encrypt } from '../../helpers/encrypt';
import { getAppSource, getPointPrivateLabel, getPublicIp, getUserType, renderDefaultMsg } from '../../helpers';
import { TransferAlert } from '../../components';

export const RecoverPassword = (props) => {

  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('');
  const [isTransfer, setTransferStatus] = useState(false);
  const [transferData, setTransferData] = useState({
    fromPrivateLabel: "", toPrivateLabelPhone: "",
    toPrivateLabelURL: "", toPrivateLabel: ""
  });

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMsg("");
    const data = {
      "userName": encrypt(username),
      "mediumType": "Email",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": await getPublicIp(),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType()
    }
    const res = await recoverPassword(data);
    if (res) {
      if (res && res.lstResponseStatus[0].statusCode === "0" && !res.isTransfer) { 
        Cookies.set('userName', encrypt(username))
        setLoading(false);
        setUsername('');
        props.handleStep("2", res)
      } else if (res && res.isTransfer) {
        setLoading(false);
        setTransferData(res)
        setTransferStatus(getPointPrivateLabel() ? false : true)
      } else {
        setErrorMsg(res.lstResponseStatus[0].statusDesc);
        setLoading(false);
        setUsername('');
      }
    } else {
      setErrorMsg(renderDefaultMsg('400'));
      setLoading(false);
      setUsername('');
    }
  }

  const disableBtn = username.length !== 0 || loading

  return (
    <>
    <Box container="true" maxWidth='xl' className={classes.root}>
      <Box className={classes.wrapper}>
        <p className={classes.title}>Forgot Password</p>
        <p className={classes.hintText}>We now offer 3 convenient options to reset your password. Please enter your username to view these options.</p>
        <p className={classes.errorMsg}>{errorMsg.length !== 0 ? errorMsg : ''}</p>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Box>
            <TextField
              color='primary'
              fullWidth
              error={false}
              id="outlined-error"
              label="Username"
              variant="outlined"
              onChange={handleUsernameChange}
              value={username}
              inputProps={{
                className: classes.inputStyle
              }}
            />
          </Box>
          <Link to='/RecoverUsername' className={classes.forgotPasswordText}>Forgot Username</Link>

          <Box className={classes.btnContainer}>
            {loading ?
              <Button fullWidth disabled={true} type='submit' className={classes.submitBtn} variant="contained" color="primary"><CircularProgress color='primary' /></Button>
              :
              <Button fullWidth disabled={!disableBtn} type='submit' className={classes.submitBtn} variant="contained" color="primary">Next</Button>
            }
            <Button component={Link} to='/SignIn' fullWidth className={classes.sendBtn} variant='outlined' color="primary" >Back to Sign In</Button>
          </Box>
        </form>
      </Box>
    </Box>
      {/* transfer notification popup */}
    {isTransfer &&
        <TransferAlert
          fromPrivateLabel={transferData.fromPrivateLabel}
          toPrivateLabel={transferData.toPrivateLabel}
          toPrivateLabelPhone={transferData.toPrivateLabelPhone}
          toPrivateLabelURL={transferData.toPrivateLabelURL}
        />}
    </>   
  )
}
