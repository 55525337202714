import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import { Box, Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

var businessMoment = require('moment-business-days');
import moment from 'moment';

export const DeleteConfirmation = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {popupText=null} = props;
  const [btnStatus, setStatus] = useState();

  useEffect(() => {

      var status = [];
      props?.data?.map((key) => {
       key?.lstRecurACH.map((val) => {
        if (businessMoment(val?.nextDraftDate, 'MM-DD-YYYY').businessDiff(businessMoment(moment(), 'MM-DD-YYYY'), true) >= 3) {
           status.push(false)
        } else {
           status.push(true);
        }
      })
     })

    const index = status.findIndex((item) => item == true)
    if (index == -1) {
      setStatus(false)
    } else {
      setStatus(true)
    }
    
  },[props])

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={props.handleCancelIcon} open={props.open}>
     
      <Box className={classes.root}>
          {popupText?.showCloseIcon && <IconButton onClick={props.handleSubmitClose} className={classes.closeIco}>
            <CancelIcon />
          </IconButton>}
        <Box className={classes.cardBody}>
            {!popupText &&  <Typography className={classes.statusTitle}>Bank Account Deletion Request</Typography>}
            {popupText &&  <Typography className={classes.statusTitle}>{popupText?.statusTitle}</Typography>}
        </Box>
        
        <Box className={classes.cardBody2}>
          <Box className={classes.statusContainer}>
           <Box className={classes.cardContainer}>
             {!popupText && <p className={classes.paragraph}>Are you sure you want to delete this bank account?</p>}
             {popupText && <p className={classes.paragraph}>{popupText?.statusBody}</p> } 
            </Box> 
          </Box>
        </Box>

        <Box className={classes.cardFooter}>
         <Box className={classes.footerItem}>
            <Button
              variant='outlined'
              className={classes.btn}
              onClick={props.handleSubmitClose}
            > {popupText?.handleSubmitCloseText?popupText?.handleSubmitCloseText:'Cancel'}</Button>
          </Box>

          <Box className={classes.footerItem}>
            { btnStatus ?
              <Tooltip arrow title="You can not manage this bank account as next scheduled payment in the autopay is within 3 days.">
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.disableBtn}
                > {popupText?.handleSubmitText?popupText?.handleSubmitText:'Delete'}</Button>
              </Tooltip> :
              <Button
                variant='contained'
                color='primary'
                className={classes.btn}
                onClick={props.handleSubmit}
              > {popupText?.handleSubmitText?popupText?.handleSubmitText:'Delete'}</Button>
            }
          </Box> 
        </Box>
       
      </Box> 
    </Dialog>
  )
}
