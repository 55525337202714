import React, { useEffect, useState } from 'react';
import { Box, Breadcrumbs, CircularProgress } from "@material-ui/core";
import { Lossmitigation } from './Lossmitigation';
import { useStyles } from './styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getContent } from '../../actions';
import { scrollTop, renderDefaultMsg } from '../../helpers';
import { Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { getTodolist } from '../../actions';
import { useSelector } from 'react-redux';
import './style.css'

export const Todolist = () => {
  const classes = useStyles();
  const [content, setContent] = useState([])
  const [show, setshow] = useState()
  const [parameter, setparameter] = useState()
  const [status, setstatus] = useState()
  const [completedTask, setcompletedTask] = useState([])
  const [underReview, setunderReview] = useState([])
  const [showcompletedTask, setshowcompletedTask] = useState()
  const [showunderReview, setshowunderReview] = useState()
  const [yourTask, setyourTask] = useState([])
  const [document, setdocument] = useState([])
  const [incomplete, setincomplete] = useState([]);
  const [dataError, setDataError] = useState('');
  const [dataLoader, setDataLoader] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [workoutStatusNull, setWorkoutStatusNull] = useState(false);

  const dispatch = useDispatch()
  const selectedAccount = useSelector(state => state.selectedAccount);
  const getContentData = async () => {
    const res = await dispatch(getContent('PC023'));
    if (res) {
      setContent(res)
      scrollTop();
    }
  };

  const getTodolistdata = async (e) => {
    setWorkoutStatusNull(false);
    setDataLoader(true);
    const response = await getTodolist(selectedAccount);
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
      setDataError('');
      setDataLoader(false);
      if (response.workOutStatus === null) {
        setWorkoutStatusNull(true);
      } else {
        setWorkoutStatusNull(false);
        if (response.workOutStatus !== null && ((response.workOutStatus.underReview !== null) && response.workOutStatus.completedTask !== null)) {
          setparameter(true)
        }
        else {
          setshow(false)
          setparameter(false)
        }
        if (response.workOutStatus !== null && (response.workOutStatus.underReview !== null)) {
          setunderReview(response.workOutStatus.underReview.tasks);
          setshowunderReview(true)
          setparameter(true)
        }
        else {
          setshowunderReview(false)
        }
        if (response.workOutStatus !== null && response.workOutStatus.completedTask !== null) {
          setshowcompletedTask(true)
          setcompletedTask(response.workOutStatus.completedTask.tasks);
          setparameter(true)
        }
        else {
          setshowcompletedTask(false)
        }
      }

      // if (response.yourTask.lossMitigationApplication === null) {
      //   setshow(false)
      // }
      // else {
      //   setshow(true);
      //   setIsSubmit(response.yourTask.lossMitigationApplication.isSubmit);
      //   setyourTask(response.yourTask.lossMitigationApplication);
      //   setdocument(response.yourTask.lossMitigationApplication.documentRequired);
      //   setincomplete(response.yourTask.lossMitigationApplication.incompleteSection);
      // }
    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
      setDataError(response.lstResponseStatus[0].statusDesc);
    } else {
      setDataError(renderDefaultMsg('400'));
    }
    scrollTop()
  }

  useEffect(() => {
    if (selectedAccount) {
      getContentData();
      getTodolistdata();
    }
  }, [selectedAccount]);

  if (dataLoader) {
    return (
      <Box className={classes.loaderRoot}>
        <CircularProgress />
      </Box>
    )
  }
  if (dataError !== '') {
    return (
      <Box className={classes.dataErrorMsg}>
        {dataError}
      </Box>
    )
  }

  return (
    <Box className={classes.bgg}>
      <Box className={classes.root}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" to='/LoanInfo' className={classes.disabledLink}>
            Loan Details
          </Link>
          <Link color="inherit" to='/WorkoutStatus' className={classes.activeLink}>
            Workout Status
          </Link>
        </Breadcrumbs>
        <Box className={classes.topContainer} mx={0}>
          <Typography className={classes.pageHeading} component='h4'>Application Status</Typography>
        </Box>

        {false && <Box className={classes.mainContainer} style={{ marginTop: '28px' }}>
          <Box my={1} mx={2} className={classes.formContainer}>
            <Typography className={classes.firsthead}>Your Tasks</Typography>
            <Typography className={classes.secondhead}>We will post any ongoing tasks that you have started or any tasks we need you to complete in this section. Any documents that you need to collect for your open applications will also appear here.</Typography>
            <div className={classes.divider}></div>
            {!show && <div className={classes.innerbox}>
              <div className={classes.widd}>
                <CheckCircleIcon style={{ width: '50px', color: '#535D6D', height: '50px', textAlign: 'center' }} />
              </div>
              <Typography className={classes.pending}>You have no pending tasks</Typography>
            </div>}
            {false && <div style={{ padding: '23px 0px', margin: '0px auto' }}>
              <Lossmitigation yourTask={yourTask} document={document} incomplete={incomplete} isSubmit={isSubmit} />
            </div>}
          </Box>
        </Box>}

        <Box className={classes.mainContainer} style={{ marginTop: '28px' }}>
          <Box my={1} mx={2} className={classes.formContainer}>
            <Typography className={classes.firsthead}>Workout Status</Typography>
            <Typography className={classes.secondhead}>We are working very hard to make sure that we review all the details that you have provided us with to leave no stones unturned when it comes to your loan.</Typography>
            <div className={classes.divider}></div>

            <Box display='flex' className={classes.primaryTheme}>
              <InfoIcon className={classes.infoIcon} />
              <Typography className={classes.infoText}>It can take up to 24 hours for your request status to be updated.</Typography>
            </Box>

            {workoutStatusNull ? <div className={classes.innerbox}>
              <div className={classes.widd}>
                <CheckCircleIcon style={{ width: '50px', color: '#535D6D', height: '50px', textAlign: 'center' }} />
              </div>
              <Typography className={classes.pending}>No pending workout status</Typography>
            </div> :
              <Box>
                {showunderReview && <Typography className={`${classes.firsthead} ${classes.secondcss}`}>Under Review</Typography>}
                {showunderReview && <>
                  {underReview[0] && <Box style={{ width: '100%' }}>
                    <div className={classes.reviewloop}>
                      <div className={classes.refresh}>
                        <AutorenewIcon className={classes.refresh_size} />
                      </div>
                      <div className={classes.dataa}>

                        {underReview[0] && <><Typography className={classes.firstHead}> {underReview[0].taskName}</Typography>
                          <Typography className={classes.seconHead}>
                            {/* Received on : {underReview[0].submittedDate} */}
                          </Typography></>
                        }

                      </div>
                    </div>
                    <div className={classes.divider}></div>
                  </Box>}
                  {underReview[1] && <Box style={{ width: '100%' }}>
                    <div className={classes.reviewloop}>
                      <div className={classes.refresh}>
                        <AutorenewIcon className={classes.refresh_size} />
                      </div>
                      <div className={classes.dataa}>

                        {underReview[1] && <><Typography className={classes.firstHead}> {underReview[1].taskName}</Typography>

                          <Typography className={classes.seconHead}>
                            {/* Received on : {underReview[1].submittedDate} */}
                          </Typography></>}

                      </div>
                    </div>
                    <div className={classes.divider}></div>
                  </Box>}
                </>}
                {/* {showcompletedTask && <Typography className={`${classes.firsthead} ${classes.secondcss}`}>Completed</Typography>} */}
                {showcompletedTask && <> {completedTask[0] && <Box style={{ width: '100%' }}>
                  <div className={classes.reviewloop}>
                    <div className={classes.refresh}>
                      <CheckCircleIcon className={classes.refresh_size} />
                    </div>
                    <div className={classes.dataa}>

                      {completedTask[0] && <><Typography className={classes.firstHead}>{completedTask[0].taskName}</Typography>
                        <Typography className={classes.seconHead}>
                          {/* Received on : {completedTask[0].submittedDate} */}
                        </Typography></>
                      }

                    </div>
                  </div>
                  <div className={classes.divider}></div>
                </Box>}
                  {completedTask[1] && <Box style={{ width: '100%' }}>
                    <div className={classes.reviewloop}>
                      <div className={classes.refresh}>
                        <CheckCircleIcon className={classes.refresh_size} />
                      </div>
                      <div className={classes.dataa}>

                        {completedTask[1] && <><Typography className={classes.firstHead}> {completedTask[1].taskName}</Typography>
                          <Typography className={classes.seconHead}>
                            {/* Received on : {completedTask[1].submittedDate} */}
                          </Typography></>}

                      </div>
                    </div>
                    <div className={classes.divider}></div>
                  </Box>}
                </>}
              </Box>}
          </Box>
        </Box>
      </Box>
    </Box >
  )
}