import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  inputText: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "bold",
    width: '100%',
  },
  flexBox:{
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    //alignItems: 'center'
  },

  contactBoxFlex: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    //justifyContent: 'space-between',
    //alignItems: 'center'
  },
  contactBoxFlex2: {
    //width: '100%',
    display: 'flex',
    //flexWrap: 'wrap',
    //justifyContent: 'space-between',
    //alignItems: 'center'
  },
  addressBox:{
    width:"400px"
  },

  inputField: {
    width: '43%',
    marginBottom: 40,
  },

  title: {    
    marginBottom: 18,  
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: "24px",
    letterSpacing: "0.18px",
    lineHeight: "24px",
  },

  inputBox: {
    width: '100%',
    marginBottom: 20,
  },

  formControl: {
    width: '100%',
    marginBottom: 20,
  },

  formLabel: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "20px",
    marginBottom: 10,
  },

  labelStyle: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "16px",
  },

  formStyle: {
    width: '100%',
  },
  questionText:{    
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "20px",
    letterSpacing: "0.44px",
    lineHeight: "24px",
    marginBottom:"10px"
  },
  inputFieldFullWidth: {
    width: '100%',
    //marginBottom: 40,
  },
  
infoIcon:{
  verticalAlign: "middle",
  fontSize: "10px"
},
acknowledgementText : {
  color: "rgba(0,0,0,0.74)",
  fontFamily: "Montserrat",
  fontSize: "16px",
  letterSpacing: "0.5px",
  lineHeight: "20px",
  marginBottom: 10,
},
bold:{  
  textDecoration: "underline",
  fontWeight:600
},
downloadBox:{
  boxSizing: "border-box", 
  border: "2px solid #F7B15C",
  borderRadius: "12px",
  background:"#EAECED"
},
downloadTitle:{ 
  color: "rgba(0,0,0,0.87)",
  fontFamily: "Montserrat",
  fontSize: "20px",
  letterSpacing: "0.15px",
  lineHeight: "24px",
  paddingBottom:"15px"
},
smallText:{ 
  color: "rgba(18,18,18,0.87)",
  fontFamily: "Montserrat",
  fontSize: "12px",
  letterSpacing: "0.4px",
  lineHeight: "16px",
},
errorMsg: {
  fontSize: '1.4em',
  fontWeight: 'bold',
  textAlign: 'center',
  marginBottom: 10,
  marginTop: 5,
  fontFamily: 'Montserrat',
  color: "red",
},
downloadd: {
  width: '230px',
  height: '40px',
  color: "#535D6D",
  fontFamily: "Montserrat",
  borderRadius: '4px',
  fontSize: '1.6em',
  fontWeight: "600",
  letterSpacing: "0.53px",
  lineHeight: "19px",
  textAlign: "center",
  ['@media (max-width:950px)']: { // eslint-disable-line no-useless-computed-key
    width: '150px',
    fontSize: '1.3em',
  },
  ['@media (max-width:750px)']: { // eslint-disable-line no-useless-computed-key
    width: '120px',
    fontSize: '1em',
  }

},

}));