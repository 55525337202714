import React, { useEffect, useState } from 'react';
import {
  Box, Button, FormControl, CircularProgress, Grid, Typography
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useStyles, CutomTextField } from './styles';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import Cookies from 'js-cookie';
import { decrypt, encrypt } from '../../../helpers/encrypt';
import { renderDefaultMsg, getUserType, getAppSource, checkCellPhoneLength, removeHyphenHandler, isAdmin, getBorrowerLabel } from '../../../helpers';
import { updateCoBorrower } from '../../../actions'
import InfoIcon from '@material-ui/icons/Info';
import Popup from "../../PopUp";

export const EditCOBorower = (props) => {
  
  const { open, handleClose } = props;
  const classes = useStyles();
  const [formSubmit, setFormSubmit] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMessage, setSuccessMessage] = useState('')
  const [formInput, setFormInput] = useState({
    id: 0,
    inputMobile: '',
    inputWorkPhone: '',
    inputWorkPhoneExt: '',
    inputAddress1: '',
    inputAddress2: '',
    inputCity: '',
    inputState: '',
    inputZip: ''
  })

  useEffect(() => {
    if (props.editData) {
      setFormInput({
        ...formInput,
        id: props.editData.id,
        inputMobile: props.editData.coborrowerHomePhone ? removeHyphenHandler(props.editData.coborrowerHomePhone) : '',
        inputAddress1: props.editData.coBorrowerMailAddress1 ? props.editData.coBorrowerMailAddress1 : '',
        inputAddress2: props.editData.coBorrowerMailAddress2,
        inputWorkPhone: props.editData.coborrowerWorkPhone ? props.editData.coborrowerWorkPhone : '',
        inputWorkPhoneExt: props.editData.coborrowerWorkPhoneExtension ? props.editData.coborrowerWorkPhoneExtension : '',
        inputCity: props.editData.coBorrowerMailCity ? props.editData.coBorrowerMailCity : '',
        inputState: props.editData.coBorrowerMailState ? props.editData.coBorrowerMailState.trim() : '',
        inputZip: props.editData.coBorrowerMailZip ? props.editData.coBorrowerMailZip : ''
      })
      setErrorMsg('')
      setSuccessMessage('')
    }
  }, [props.editData])

  const [formInputError, setFormInputError] = useState({
    inputMobile: '',
    inputWorkPhone: '',
    inputWorkPhoneExt: '',
    inputAddress1: '',
    inputAddress2: '',
    inputCity: '',
    inputState: '',
    inputZip: ''
  })
  
  const [warningPopup, setWarningPopup] = useState({show:false})
  const theme = useSelector(state => state.theme)
  const CeaseDesistFlag = props?.cesaeDesistFlag;
  const history = useHistory();

  const goToContactUs = () => {
    history.push('/contactus');
  }

  const disabledBtn = formInput.inputAddress1.trim().length === 0 ||
    formInput.inputCity.trim().length === 0 ||
    formInput.inputState === '' ||
    formInput.inputZip.trim().length === 0;

  const handleChange = (prop) => (event) => {
    if (prop === 'inputZip') {
      if ((/^[0-9]+$/).test(event.target.value) || event.target.value === '') {
        setFormInput({ ...formInput, [prop]: event.target.value.slice(0, 5) });
      }
      return;
    } if (prop === 'inputMobile' || prop === 'inputWorkPhone') {
      if ((/^[0-9]+$/).test(event.target.value) || event.target.value === '') {
        setFormInput({ ...formInput, [prop]: event.target.value.slice(0, 10) });
      }
      return;
    } if (prop === 'inputWorkPhoneExt') {
      if ((/^[0-9]+$/).test(event.target.value) || event.target.value === '') {
        setFormInput({ ...formInput, [prop]: event.target.value.slice(0, 4) });
      }
      return;
    } else {
      setFormInput({ ...formInput, [prop]: event.target.value });
    }
  }

  const validateForm = () => {
    const formErrors = {
      inputMobile: '', inputAddress1: '',
      inputAddress2: '', inputCity: '', inputState: '', inputZip: '',
      inputWorkPhone: '',
    }
    let errorCount = 0

    if (!formInput.inputAddress1) {
      formErrors.inputAddress1 = "Please enter Address";
      errorCount++
    }
    if (!formInput.inputCity) {
      formErrors.inputCity = "Please Enter City";
      errorCount++
    }
    if (!formInput.inputZip) {
      formErrors.inputZip = "Please Enter Zip";
      errorCount++
    }

    if (formInput.inputMobile && checkCellPhoneLength(formInput.inputMobile) === false) {
      formErrors.inputMobile = "Please Enter Valid Mobile Number";
      errorCount++
    }

    if (formInput.inputWorkPhone && checkCellPhoneLength(formInput.inputWorkPhone) === false) {
      formErrors.inputWorkPhone = "Please Enter Valid Work Mobile Number";
      errorCount++
    }
    if (formInput.inputWorkPhoneExt && (formInput.inputWorkPhoneExt.length !== 4)) {
      formErrors.inputWorkPhoneExt = "Please Enter Valid Work Phone Ext";
      errorCount++
    }

    setFormInputError(formErrors)
    return errorCount;
  }

  const handleError = (inputName) => {
    if (formInputError[inputName]) {
      return (<Typography className={classes.errorText}>{formInputError[inputName]}</Typography>)
    } else {
      return null
    }
  }

  const validClass = (inputName) => {
    if (formInputError[inputName]) {
      return classes.inputContainerWithText
    } else {
      return classes.inputContainer
    }
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateForm() > 0) {
      return false
    }
    const selectedAccount = await Cookies.get('selectedAccount');
    setFormSubmit(true);
    const data = {
      "id": formInput.id,
      "accountNumber": selectedAccount,
      "coBorrowerMailAddress1": formInput.inputAddress1,
      "coBorrowerMailAddress2": formInput.inputAddress2,
      "coBorrowerMailCity": formInput.inputCity,
      "coBorrowerMailState": formInput.inputState,
      "coBorrowerMailZip": formInput.inputZip,
      "coborrowerHomePhone": encrypt(formInput.inputMobile),
      "coborrowerWorkPhone": encrypt(formInput.inputWorkPhone),
      "coborrowerWorkPhoneExtension": encrypt(formInput.inputWorkPhoneExt),
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }
    setErrorMsg("");
    setSuccessMessage("")

    const response = await updateCoBorrower(data);
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
      setFormSubmit(false);
      //setErrorMsg("");
      setSuccessMessage("Co-Borrower record updated successfully.")
      // setFormInput({
      //   inputMobile: '',
      //   inputWorkPhone: '',
      //   inputWorkPhoneExt: '',
      //   inputAddress1: '',
      //   inputAddress2: '',
      //   inputCity: '',
      //   inputState: '',
      //   inputZip: ''
      // });
      handleClose();
    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '1001') {
      setErrorMsg(response.lstResponseStatus[0].statusDesc);
      // setFormInput({
      //   inputMobile: '',
      //   inputWorkPhone: '',
      //   inputWorkPhoneExt: '',
      //   inputAddress1: '',
      //   inputAddress2: '',
      //   inputCity: '',
      //   inputState: '',
      //   inputZip: ''
      // });
    } else {
      setErrorMsg(renderDefaultMsg('400'));
      setFormSubmit(false);
      // setFormInput({
      //   inputMobile: '',
      //   inputWorkPhone: '',
      //   inputWorkPhoneExt: '',
      //   inputAddress1: '',
      //   inputAddress2: '',
      //   inputCity: '',
      //   inputState: '',
      //   inputZip: ''
      // });
    }
  }

  const openPopup=()=>{
    setWarningPopup({show:true});
  }

  const closePopupFunc=()=>{
    setWarningPopup({show:false});
  }

  let allStateData = '';
  if (props.stateData) {
    allStateData = props.stateData.map((item, index) => {
      return (
        <MenuItem value={item.stateCode} key={(index - 1) + item.stateCode}>{item.stateName}</MenuItem>
      )
    })
  }

  return (
    <Dialog
      fullWidth={false}
      maxWidth={'xl'}
      PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={handleClose} open={open}
    >
      <Box className={classes.root}>
        <Box py={2} borderBottom={1}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
              <Typography className={classes.heading}>Update Co-{getBorrowerLabel()}</Typography>
            </Grid>
            <Grid item>
              <Typography align="right">
                <CancelIcon className={classes.closeBtn} onClick={handleClose} />
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.appBar}>
          <Box display='flex' alignItems="center">
            <InfoIcon className={classes.infoIcon} />
            <Typography className={classes.infoText}>It can take up to 24 hours for your request status to be updated.</Typography>
          </Box>
        </Box>
        {CeaseDesistFlag && <Box display='flex' py={1} px={2} mb={3} className={classes.appBar}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <Box display='flex' alignItems="center">
                  <InfoIcon className={classes.infoIcon} />
                  <Typography className={classes.infoText}>The ability to update below mailing address is unavailable.</Typography>
                  <a class={classes.infoLink} onClick={openPopup}>Read more</a>
                </Box>
              </Grid>
            </Grid>
          </Box>}
        <Box mt={3}>

          <form noValidate autoComplete="off" onSubmit={handleFormSubmit}>
            {errorMsg !== '' ? (<Box pb={2}><Typography className={classes.errorText}>{errorMsg}</Typography></Box>) : null}
            {successMessage !== '' ? (<Box pb={2}><Typography className={classes.successMessage}>{successMessage}</Typography></Box>) : null}
            <Grid container direction="row" justify="center" >

              <Grid item md={6} className={classes.pr35}>
                <Box className={validClass('inputAddress1')}>
                  <CutomTextField
                    id="outlined-address1"
                    label="Address Line 1"
                    variant="outlined"
                    type="text"
                    color="primary"
                    value={formInput.inputAddress1}
                    onChange={handleChange('inputAddress1')}
                    fullWidth
                    inputProps={{
                      className: classes.inputStyle,
                    }}
                    disabled={CeaseDesistFlag}
                  />
                  {handleError('inputAddress1')}
                </Box>
                <Box className={validClass('inputAddress2')}>
                  <CutomTextField
                    id="outlined-address2"
                    label="Address Line 2 (Optional)"
                    variant="outlined"
                    type="text"
                    color="primary"
                    value={formInput.inputAddress2}
                    onChange={handleChange('inputAddress2')}
                    fullWidth
                    inputProps={{
                      className: classes.inputStyle,
                    }}
                    disabled={CeaseDesistFlag}
                  />
                </Box>

                <Box className={validClass('inputCity')}>
                  <CutomTextField
                    id="outlined-city"
                    label="City"
                    variant="outlined"
                    type="text"
                    color="primary"
                    value={formInput.inputCity}
                    onChange={handleChange('inputCity')}
                    fullWidth
                    inputProps={{
                      className: classes.inputStyle,
                    }}
                    disabled={CeaseDesistFlag}
                  />
                  {handleError('inputCity')}
                </Box>

                <Box className={validClass('inputState')}>
                  <Grid container direction="row" justify="center" alignItems="center" >
                    <Grid item md={6} className={classes.fullWidth}>
                      <Box fullWidth className={classes.pL0}>
                        {handleError('inputState')}
                        <FormControl variant="outlined" className={CeaseDesistFlag ? classes.formControl : classes?.select} style={{ width: '100%' }}>
                          <InputLabel id="borrower-type-select-outlined">State</InputLabel>
                          <Select
                            labelId="borrower-type-outlined-label"
                            id="borrower-type-select-outlined"
                            color="primary"
                            value={formInput.inputState}
                            defaultValue={formInput.inputState}
                            onChange={handleChange('inputState')}
                            label="State"
                            inputProps={{
                              className: classes.inputStyle,
                            }}
                            fullWidth
                            disabled={CeaseDesistFlag}
                          >
                            {allStateData !== '' && allStateData}
                            {props.stateErrorMsg !== '' && <p>{props.stateErrorMsg}</p>}
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item md={6} className={classes.zipCode}>
                      <Box pl={4} className={classes.zipCode}>
                        <CutomTextField
                          id="outlined-zip"
                          label="Zip"
                          variant="outlined"
                          type="text"
                          color="primary"
                          value={formInput.inputZip}
                          onChange={handleChange('inputZip')}
                          fullWidth
                          inputProps={{
                            className: classes.inputStyle,
                            maxLength: 5
                          }}
                          disabled={CeaseDesistFlag}
                        />
                        {handleError('inputZip')}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item md={6} className={classes.pl35}>
                <Box className={validClass('inputMobile')}>
                  <CutomTextField
                    id="outlined-mobile"
                    label="Mobile Number (Optional)"
                    variant="outlined"
                    type="text"
                    color="primary"
                    value={formInput.inputMobile}
                    onChange={handleChange('inputMobile')}
                    fullWidth
                    inputProps={{
                      className: classes.inputStyle,
                      maxLength: 10,
                    }}
                  />
                  {handleError('inputMobile')}
                </Box>

                <Box className={validClass('inputMobile')}>

                  <Grid container direction="row" justify="center" alignItems="flex-start" >
                    <Grid item md={8} className={`${classes.fullWidth} ${classes.mbPhone0}`}>
                      <Box pr={4} fullWidth className={classes.pL0}>
                        <Box className={validClass('inputMobile')}>
                          <CutomTextField
                            id="outlined-mobile-work"
                            label="Work Mobile Number"
                            variant="outlined"
                            type="text"
                            color="primary"
                            value={formInput.inputWorkPhone}
                            onChange={handleChange('inputWorkPhone')}
                            fullWidth
                            inputProps={{
                              className: classes.inputStyle,
                              maxLength: 10,
                            }}
                          />
                          {handleError('inputWorkPhone')}
                        </Box>

                      </Box>
                    </Grid>
                    <Grid item md={4} className={classes.zipCode}>
                      <Box className={classes.zipCode}>
                        <Box className={validClass('inputMobile')}>
                          <CutomTextField
                            id="outlined-mobile-ext"
                            label="Work Ext"
                            variant="outlined"
                            type="text"
                            color="primary"
                            value={formInput.inputWorkPhoneExt}
                            onChange={handleChange('inputWorkPhoneExt')}
                            fullWidth
                            inputProps={{
                              className: classes.inputStyle,
                              maxLength: 4,
                            }}
                          />
                          {handleError('inputWorkPhoneExt')}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>

                  {/* <p className={classes.errorText}>{errorMsg !== '' ? errorMsg : ''}</p> */}
                </Box>

              </Grid>
              <Grid item md={12} className={classes.updateBtnContainer}>
                <Box>
                  {formSubmit ?
                    (<Button className={classes.saveBtn} disabled={true} variant="contained">
                      <CircularProgress color="primary" style={{ height: '24px', width: '24px' }} />
                    </Button>) :
                    (
                      <Button
                        type="submit"
                        color="primary"
                        className={classes.saveBtn}
                        variant="contained"
                        disabled={isAdmin || disabledBtn}>
                        Update</Button>)}
                </Box>
              </Grid>

            </Grid>
          </form>
        </Box>
      </Box>
      {warningPopup?.show && <Popup open={warningPopup?.show} closePopup={closePopupFunc} isWarningPopup={false} isMessagePopup={true}>
        <div className={classes.popupBody}>
           <p className={classes.loanDetail}>
           According to our records, you are represented by an attorney or have otherwise requested that we cease communicating with you directly regarding your account. If you are no longer represented by counsel or wish for us to communicate with you, please send us a request in writing to <span onClick={() => goToContactUs()} className={classes.customerCareEmail}>customer care email</span> with your loan number and a statement that you are not represented by an attorney and wish for us to communicate with you directly.
           </p>
          </div>
          </Popup>}
    </Dialog >
    
  )
}