import { makeStyles } from '@material-ui/core/styles';
import signinIcon from '../../assets/SignIn/SignIn.svg';

export const useStyles = makeStyles((theme) => ({
  pageBg: {
    width: '100%',
    backgroundColor: '#ccc',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${signinIcon})`,
    backgroundPosition: "bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'contain',
  },

  mainHeading: {
    padding: '0.2em 3.5em 0.3em',
    fontWeight: '700',
    fontSize: '2.5em',
    color: '#727272',
    [theme.breakpoints.down('sm')]: {
      padding: '1.5em 1.2em 0 2em',
      fontSize: '2em',
      fontFamily: 'Montserrat',
    },
  },

  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '2.4em 1.6em 9em',
    fontFamily: 'Montserrat',
    width: '100%',
    maxWidth: '122em',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: '2.4em 1.6em 5em',
    },
  },
  pageHeading: {
    color: '#1A4664',
    fontFamily: 'Montserrat',
    fontSize: '22px',
    fontWeight: '700',
    letterSpacing: '0.18px',
    lineHeight: '24px',
    marginBottom: 25,
    marginTop: 24,
  },
  rightMenuContainer: {
    display: 'flex',
    textAlign: 'right',
    position: 'absolute',
    top: '20px',
    right: '35px',
    alignItems: 'center'
  },
  PreferredAccount: {
    color: '#76BD1D',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '0.47px',
    lineHeight: '18px',
    textAlign: 'center',
    width: '210px',
    border: '1px solid #979797',
    borderRadius: '6px',
    backgroundColor: '#FFFFFF',
    textTransform: 'initial',
    height: '33px'
  },

  formContainer: {
    padding: '20px 30px 0 30px',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 10px 0 10px',
    },
    '& .MuiFormLabel-root': {
      color: '#535D6D',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '500',
      letterSpacing: '0.36px',
      lineHeight: '19px',
      background: '#fff',
      paddingRight: '5px'
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.palette.primary.main,
      fontWeight: '600',
    }
  },

  inputStyle: {
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600",
    '&.Mui-checked': {
      color: '#000',
    },
    '& .MuiInputLabel-outlined': {
      color: 'rgba(0,0,0,0.87)',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '500',
      letterSpacing: '0.15px',
      lineHeight: '20px',
      background: '#fff'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#535D6D'
    },
    '& .Mui-focused.MuiInputLabel-outlined': {
      color: theme.palette.primary.main,
      fontWeight: '500',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '& .MuiSelect-select': {
      color: 'rgba(0,0,0,0.87)',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      letterSpacing: '0.15px',
      lineHeight: '20px'
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    }
  },


  accRadioType: {
    color: '#202020',
    fontFamily: 'Montserrat',
    fontSize: '2em',
    letterSpacing: '0.44px',
    lineHeight: '1.3em',
  },

  radioBtn: {
    '& .Mui-checked': {
      color: '#000'
    },
    '& .MuiFormControlLabel-label': {
      color: '#000000',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      letterSpacing: '0.53px',
      lineHeight: '19px',
    }
  },

  checkImg: {
    width: '100%',
    marginBottom: '10px'
  },

  cancelAccButton: {
    fontFamily: 'Montserrat',
    letterSpacing: '0.36px',
    fontWeight: '600',
    marginRight: '25px',
    width: '180px',
    height: '40px',
    border: '2px solid',
    //color: '#535D6D !important',
    borderColor: theme.palette.primary.main,
    boxShadow: "0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('xs')]: {
      marginBottom: '28px',
      marginRight: 0,
    },
  },
  addAccButton: {
    width: '180px',
    height: '40px',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    textTransform: 'uppercase'
  },
  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  checkboxItem: {
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(0,0,0,0.74)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    letterSpacing: '0.5px',
    lineHeight: '19px',
    marginLeft: '3px',
    '& svg': {
      marginRight: '3px'
    }
  },
  errorMsg: {
    fontSize: '1.4em',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
    marginTop: 5,
    fontFamily: 'Montserrat',
    color: "red",
  },
  dataLoader: {
    width: '100px',
    height: '100px',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'block',
    margin: '0 auto',
    zIndex: '999',
  },
  loaderRoot: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loaderValidate: {
    width: '100%',
    height: '65vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 99
  },

  rowMargin: {
    // marginBottom: 45,
  },
  abaError: {
    color: 'red',
    fontFamily: 'Montserrat',
    fontSize: '0.75rem',
    fontWeight: '600',
    letterSpacing: '0.53px',
    lineHeight: '14px',
    marginTop: 6,
    marginLeft: 14
    // cursor: 'pointer'
  },

  aBaBankDetails: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '600',
    letterSpacing: '0.53px',
    lineHeight: '14px',
    marginTop: 6,
    paddingLeft: 14
  },

  fullWidth: {
    width: '100%'
  },

  validateRLoader: {
    height: '25px', width: '25px', position: 'absolute', right: '12px', top: '10px'
  }
}));