import React from 'react';
import { useStyles } from './styles';
import { Typography, Box, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { setPaymentOption } from '../../actions';
import { insertActivityLog } from '../../actions';
import { getAppSource, getUserType, renderDefaultMsg, isAdminCheck } from '../../helpers';
import Cookies from 'js-cookie';

export const DeliquentLoansAlert = (props) => {

  const classes = useStyles();
  const theme = useTheme()
  const dispatch = useDispatch()
  const history = useHistory()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const userName = Cookies.get('firstLastName')

  const handleMakePayment = () => {
    Cookies.set("showDeliqPopup", false)
    activityLog("User clicked on Make payment button on home page delinquency popup")
    dispatch(setPaymentOption('ONETIMEPAYMENT'));
    history.push('/PaymentOption')
  }

  const handleClose = () => {
    Cookies.set("showDeliqPopup", false)
    props.closeDeliquentLoanPopup()
    if (!isAdminCheck()) {
      activityLog("User clicked on Dismiss button on home page delinquency popup")
    }
  }

  const activityLog = async (text) => {
    const data = {
      "accountNumber": props.accountNumber ? props.accountNumber : '',
      "activityCode": "UAL000",
      "activityStatus": "Success",
      "action": "GetAccountInfo",
      "controller": "Dashboard",
      "apiName": "GetAccountInfo",
      "activityRemark":  text,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };
    await insertActivityLog(data);
  }

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle }}} onClose={props?.handleClose} open={props.deliquentLoansAlertStatus}>
     
      <Box className={classes.root}>
          
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>Opportunity to Waive Late Fee</Typography>
        </Box>
    
        <Box className={classes.cardBody2}>
          <Box className={classes.statusContainer}>
              <Typography className={classes.heading}>Dear {userName},</Typography>
              <Typography className={classes.paragraph}>We understand that missed payments can happen, and we’re here to help you get back on track.  To show our appreciation and commitment to your satisfaction, we are waiving all late fees when you fully reinstate your account.</Typography>
              <Typography className={classes.gratitude}>Additionally, we’d like to offer you a gift card for a coffee (or treat) on us as a small token of our gratitude.</Typography>
              
              <Typography className={classes.availabilty}>This is a valid and temporary good faith offer available through Friday, June 28th.</Typography>
              <Typography className={classes.paragraph}>You can submit payment on MyLoanWeb or Call Us at <a href={"tel:" + '800-327-7861'} style={{textDecoration: 'none', cursor: 'pointer', color: '#000000'}}><b>800-327-7861.</b></a></Typography>
              <Typography className={classes.paragraph}><b>Note: </b> Kindly dismiss if your payment has already been submitted.</Typography>
            </Box> 
        </Box>
    
        <Box className={classes.cardFooter}>
         <Box className={classes.footerItem}>
            <Button
              variant='outlined'
              className={classes.btn}
              onClick={() => handleClose()}
            > DISMISS</Button>
          </Box>
          <Box className={classes.footerItem}>
              <Button
                variant='contained'
                color='primary'
                className={classes.btn}
                disabled={isAdminCheck()}
                onClick={() => handleMakePayment()}
              >MAKE PAYMENT</Button>
          </Box> 
        </Box>
      </Box> 
    </Dialog>
  )

}
