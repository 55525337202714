import React from 'react';
import { useStyles } from './styles';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { InputAdornment, FormControl, OutlinedInput, InputLabel } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { decrypt, encrypt } from '../../helpers/encrypt';
import { checkDigitOnly, sequentialDigits, digitsStartsWith, digitsInPositions, checkAllDigits,
  getPublicIp, getAppSource, getUserType, renderDefaultMsg, digitsEndsWith, reverseSequentialDigits } from '../../helpers';
import { updateSSN } from '../../actions';
import Cookies from 'js-cookie';

export const IncorrectTinSsnAlert = (props) => {
  const { details } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [showDetails, setShowDetails] = React.useState(true)
  const [activeTab, setActiveTab] = React.useState(true)
  const [showSSnForm, setShowSSNForm] = React.useState(false)
  const [radioValue, setRadioValue] = React.useState(true);
  const [values, setValues] = React.useState({
    showPassword: false,
    borrowerSSN: '',
    borrowerEIN: ''
  });
  const [invalidSsn, setInvalidSsn] = React.useState(false)
  const [invalidEin, setInvalidEin] = React.useState(false)
  const [errorMsg, setErrorMsg] = React.useState('')
  const [updateInProgress, setUpdateInProgress] = React.useState(false)
  const [confirmDetails, setShowConfirmDetails] = React.useState(false)

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  }

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  const validateSsn = (ssn) => {
    if (sequentialDigits(ssn) && checkAllDigits(ssn) && digitsStartsWith(ssn) && digitsInPositions(ssn) && digitsEndsWith(ssn) && reverseSequentialDigits(ssn)) {
      setInvalidSsn(false)
    } else {
      setInvalidSsn(true)
    }
  }

  const validateEin = (ein) => {
    if (sequentialDigits(ein) && checkAllDigits(ein) && reverseSequentialDigits(ein)) {
      setInvalidEin(false)
    } else {
      setInvalidEin(true)
    }
  }

  const handleChange = (prop) => (event) => {
    if (checkDigitOnly(event.target.value)) {
          setValues({ ...values, [prop]: event.target.value });
      }
      return;
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const updateStatus = () => {
    setShowDetails(false)
    setShowSSNForm(true)
    setShowConfirmDetails(false)
  }

  const updateConfirmSSNStatus = () => {
    setShowDetails(false)
    setShowSSNForm(false)
    setShowConfirmDetails(true)
  }

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
    setValues({
      borrowerSSN: '',
      borrowerEIN: ''});
      setInvalidEin(false)
      setInvalidSsn(false)
  };
  
  const updateAllStatus = () => {
    setShowDetails(false)
    setShowSSNForm(false)
    setShowConfirmDetails(false)
    setUpdateInProgress(true)
  };

  const handleUpdate = async () => {
      const data = {
        "accountNumber": details.accountNumber,
        "pBorrowerSSN": radioValue ? encrypt(values.borrowerSSN) : encrypt(values.borrowerEIN),
        "secBorrowerSSN": " ",
        "pUpdateType": radioValue ? 'SSN' : 'EIN',
        "sUpdateType": "",
        "privateLabelUrl": Cookies.get("privateLabelUrl"),
        "ipAddress": await getPublicIp(),
        "browser": Cookies.get("browser"),
        "appSource": getAppSource(),
        "userType": getUserType(),
        "userName": ""
      }
      
    
      const res = await updateSSN(data);
      if (res) {
      const { lstResponseStatus } = res;
      if (res && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        updateAllStatus();
        setUpdateInProgress(true);
      } else {
        setErrorMsg(lstResponseStatus[0].statusDesc)
      }
    }  else {
      setErrorMsg(renderDefaultMsg('400'))
    }
  };
  
  const handleOk = () => {
    window.location.reload(false);
    setOpen(false);
  }

  const disableBtn = () => {
    if (radioValue) {
      if (values.borrowerSSN.length <= 8 || values.borrowerSSN == '' && values.borrowerEIN == '') {
        return true
      } else {
        return false
      }
    } else {
      if (values.borrowerEIN.length <= 8 || values.borrowerEIN == '') {
        return true
      } else {
        return false
      }
    }
  }

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={handleClose} open={open}>
      { showDetails && !details.isRestrict24hr ?
      <Box className={classes.root}>
        <IconButton onClick={handleClose} className={classes.closeIcon}>
          <CancelIcon />
        </IconButton>
          <Box className={classes.cardBody}>
            <Box className={classes.statusContainer}>
              <p className={classes.statusTitle}>
              As the servicer of your mortgage loan, {details.privateLabelName} is required by the Internal Revenue Service (IRS) to obtain your correct Taxpayer Identification Number (TIN) to report mortgage interest you paid every tax year.</p>
              <p className={classes.statusSubTitle}> It looks like the EIN/ SSN that we have on file for you is invalid. To update this information please verify your account information:</p>
              {/* <p className={classes.statusSubTitles}> <b>Loan Number:</b> {decrypt(details.accountNumber)}</p>
              <p className={classes.statusSubTitles}> <b>Phone Number:</b> {decrypt(details.phoneNumber)} </p>
              <p className={classes.statusSubTitles}> <b>Property Zip Code:</b> {decrypt(details.propertyZipCode)} </p> */}
              <Box className={classes.boxMargin}>
               <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" className={classes.custAddress}>
                    <Box>
                       <Typography variant='h6' className={classes.bodyFont}>
                        Loan Number </Typography>
                       <Typography variant='h6'  className={classes.textFont}>
                       {decrypt(details.accountNumber)}</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" className={classes.custAddress}>
                    <Box>
                       <Typography variant='h6'  className={classes.bodyFont}>
                       Property Zip Code </Typography>
                       <Typography variant='h6'  className={classes.textFont}>
                       {decrypt(details.propertyZipCode)}</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" className={classes.custAddress}>
                    <Box>
                       <Typography variant='h6'  className={classes.bodyFont}>
                       Phone Number </Typography>
                       <Typography variant='h6'  className={classes.textFont}>
                       {decrypt(details.phoneNumber)}</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              </Box>
            </Box>
        </Box>
        <Box className={classes.cardFooter}>
          <Box className={classes.footerItem}>
            <Button
              variant='contained'
              color='primary'
              className={classes.btn}
              onClick={() => handleClose()}
            >Cancel</Button>
          </Box>
          <Box className={classes.footerItem}>
            <Button
              variant='contained'
              color='primary'
              className={classes.btn}
              onClick={() => updateStatus()}
            >Submit</Button>
          </Box>
        </Box>
      </Box> :
      <>
      {  showSSnForm ?
       <Box className={classes.root}>
        <IconButton onClick={handleClose} className={classes.closeIcon}>
          <CancelIcon />
        </IconButton>
        <Box className={classes.cardBody}>
         <Box className={classes.statusContainer}>
          <p className={classes.statusUpdateTinTitle}>
          Please update the EIN/ SSN for the following user <b>{details.pBorrowerName}</b> <b>{decrypt(details.borrowerSSN)}</b>.
          </p>
          </Box>
        </Box>
        <Box className={classes.form}>
        <div className={classes.radioBtn} style={{ marginTop: "-14px" }}>
              <RadioGroup row aria-label="account_type" name="account_type" value={radioValue} style={{ justifyContent: "flex-start" }}
                onChange={handleRadioChange}>
                <FormControlLabel value="true" checked={radioValue}
                  control={<Radio />} label="SSN" />
                <FormControlLabel value=""
                    control={<Radio />} label="EIN" />
              </RadioGroup>
         </div>
         {radioValue ? 
        <FormControl variant="outlined" fullWidth>
           <InputLabel className={classes.labelStyle} htmlFor="outlined-adornment-password"> Borrower SSN</InputLabel>
              <OutlinedInput
                error={invalidSsn}
                color="primary"
                id="outlined-adornment-password"
                type={values.showPassword ? 'text' : 'password'}
                value={values.borrowerSSN}
                label="Borrower SSN"
                variant="outlined"
                onChange={handleChange('borrowerSSN')}
                onKeyUp={() => validateSsn(values.borrowerSSN)}
               
                inputProps={{
                  className: classes.inputStyle,
                  maxLength: 9,
                  autocomplete: "new-password",
                  form: {
                    autocomplete: 'off',
                  },
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              { invalidSsn ? <div className={classes.invalid}>Invalid SSN</div> : null }
             </FormControl> :
             <FormControl variant="outlined" fullWidth>
             <InputLabel className={classes.labelStyle} htmlFor="outlined-adornment-password"> Borrower EIN</InputLabel>
                <OutlinedInput
                 error={invalidEin}
                  color="primary"
                  id="outlined-adornment-password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.borrowerEIN}
                  label="Borrower EIN"
                  variant="outlined"
                  onChange={handleChange('borrowerEIN')}
                  onKeyUp={(e) => validateEin(e.target.value)}
                  labelWidth={activeTab ? 250 : 150}
                  InputLabelProps={{
                    classes: { root: classes.textField }
                  }}
                  inputProps={{
                    className: classes.inputStyle,
                    maxLength: 9,
                    autocomplete: "new-password",
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                { invalidEin   ? <div className={classes.invalid}>Invalid EIN</div> : null }
                </FormControl>}
        </Box>
        <Box className={classes.cardFooter}>
         <Box className={classes.footerItem}>
            <Button
              variant='contained'
              color='primary'
              className={classes.btn}
              onClick={() => handleClose()}
            >Cancel</Button>
          </Box>
          <Box className={classes.footerItem}>
            <Button
              disabled={invalidEin || invalidSsn || disableBtn()}
              variant='contained'
              color='primary'
              className={classes.btn}
              onClick={() => updateConfirmSSNStatus()}
            >Submit</Button>
          </Box>
        </Box>
       </Box> : confirmDetails ?
        <Box className={classes.root}>
        <IconButton onClick={handleClose} className={classes.closeIcon}>
          <CancelIcon />
        </IconButton>
        <Box className={classes.cardBody}>
         <Box className={classes.statusContainer}>
          <p className={classes.statusUpdateTinTitle}>
          You have entered <b>{radioValue ? values.borrowerSSN : values.borrowerEIN}</b>, please confirm that this is the correct {radioValue ? 'SSN' : 'EIN'} for <b>{details.pBorrowerName}</b>.</p>
          <p className={classes.statusUpdateTinSubTitle}> 
          If this is not correct please select edit to re-enter the {radioValue ? 'SSN' : 'EIN'}. 
          </p>
          </Box>
          <p className={classes.errorMsg} dangerouslySetInnerHTML={{ __html: errorMsg !== '' ? errorMsg : '' }} />
        </Box>
        
        <Box className={classes.cardFooter}>
         <Box className={classes.footerItem}>
            <Button
              variant='contained'
              color='primary'
              className={classes.btn}
              onClick={() => updateStatus()}
            >Edit</Button>
          </Box>
          <Box className={classes.footerItem}>
            <Button
              variant='contained'
              color='primary'
              className={classes.btn}
              onClick={() => handleUpdate()}
            >Update</Button>
          </Box>
        </Box>
       </Box> : ''
       }
      
      </>
    }
     { updateInProgress ? 
        <Box className={classes.root}>
        <IconButton onClick={handleClose} className={classes.closeIcon}>
          <CancelIcon />
        </IconButton>
        <Box className={classes.cardBody}>
         <Box className={classes.statusContainer}>
       

          <p className={classes.statusUpdateTinTitle}>Thank you for updating your EIN/ SSN, please allow 24-48 hours for the system to update with this information to register your online account.</p>
          </Box>
        </Box>
        <Box className={classes.cardFooter}>
         <Box className={classes.footerItem}></Box>
          <Box className={classes.footerItem}>
            <Button
              variant='contained'
              color='primary'
              className={classes.btn}
              onClick={() => handleOk()}
            >OK</Button>
          </Box>
        </Box>
       </Box> : ''
       }
       { 
         details.isRestrict24hr ?
          <Box className={classes.root}>
          <IconButton onClick={handleClose} className={classes.closeIcon}>
            <CancelIcon />
          </IconButton>
          <Box className={classes.cardBody}>
           <Box className={classes.statusContainer}>
           <p className={classes.errorMsg} dangerouslySetInnerHTML={{ __html: errorMsg !== '' ? errorMsg : '' }} />
  
            <p className={classes.statusUpdateTinTitle}>You have recently updated your EIN/ SSN. Please allow 24-48 hours from the time you updated your information, to register for an online account.</p>
            </Box>
          </Box>
          <Box className={classes.cardFooter}>
           <Box className={classes.footerItem}></Box>
            <Box className={classes.footerItem}>
              <Button
                variant='contained'
                color='primary'
                className={classes.btn}
                onClick={() => handleOk()}
              >OK</Button>
            </Box>
          </Box>
         </Box> : ''
       }
    </Dialog>
  )
}
