import React, { useEffect, useState } from 'react';
import {
  Box, CssBaseline, Grid, Button, CircularProgress, IconButton,
  InputAdornment, TextField
} from '@material-ui/core';
import { useStyles } from './styles';
import { getUserType, getAppSource, renderDefaultMsg } from '../../helpers';
import SearchIcon from "@material-ui/icons/Search";
import CustomBreadcrum from '../Breadcrum';
import { getFormLists, insertTelemetryActivity } from '../../actions';
import { Link } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import Cookies from 'js-cookie';
import databaseIcon from '../../assets/RequestDocument/Database.png';

export const FormCenter = (props) => {

  const classes = useStyles();

  const [loader, setLoader] = useState(true);
  const [noResultFoundOnSearch, setResultFoundOnSearch] = useState(false);
  const [errMessage, setErrorMessage] = useState('');
  const [searchInput, setSearchInput] = useState("");
  const [requestFormLists, setRequestFormLists] = useState([]);
  const [formLists, setFormLists] = useState([])
  const [searchCount, setTotalCount] = React.useState(0);

  const handleSearch = (event) => {
    setSearchInput(event.target.value)
  }

  const getFormDetails = async (loan) => {
    const data = {
      "accountNumber": loan?.accountNumber,
      "userName": "",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType()
    }

    const result = await getFormLists(data);
    if (result) {
      if (result && ((result?.responseData?.lstResponseStatus[0]?.statusCode === "0") || (result?.responseData?.lstResponseStatus[0]?.statusCode === 0))) {
        setFormLists(result.responseData.responseData)
        setRequestFormLists(result.responseData.responseData)
        setLoader(false)
      } else {
        setFormLists([])
        setRequestFormLists([])
        setLoader(false)
        setErrorMessage(result.lstResponseStatus[0]?.StatusDesc)
      }
    } else {
      setFormLists([])
      setRequestFormLists([])
      setLoader(false)
      setErrorMessage(renderDefaultMsg('400'))
    }
  }

  useEffect(() => {
    if (props?.accountNumber) {
      getFormDetails(props)
    }
  }, [props.accountNumber])

  if (loader) {
    return (
      <Box className={classes.loader}>
        <CircularProgress />
      </Box>
    )
  }

  const handleSearchClick = (e) => {
    var lists = requestFormLists;
    e?.preventDefault();
    setResultFoundOnSearch(false)
    if (searchInput === '') {
      setFormLists(requestFormLists)
    } else {
        const updatedDocLists = lists.filter((item) => {
          return item.formName.toLowerCase().includes(searchInput?.toLowerCase())
        })
        const docLists = updatedDocLists.map(item => {
          return {
            formName: item.formName,
            formUrl: item.formUrl,
            description: item.description,
            isFillForm: item.isFillForm,
            count: (item.formName.toLowerCase().match(new RegExp(searchInput?.toLowerCase(), "g")) || []).length++,
            }
        })
        setFormLists(docLists)

        var totalCount = 0;
        docLists.forEach(item => {
            totalCount += item.count;
        })
        sendActivityData(false, searchInput, totalCount)
        setTotalCount(totalCount)
        if (updatedDocLists == 0) {
          setResultFoundOnSearch(true)
        }
    }
  }

  const clearSearch = () => {
    setSearchInput('')
    setFormLists(requestFormLists)
    setResultFoundOnSearch(false)
  }

  const sendActivityData = async (type, name, count) => {
    const data = {
      "accountNumber": props?.accountNumber,
      "keyword": type ? null : name,
      "resultCount": type ? 0 : count,
      "category": type ? name : null,
      "question": null,
      "isAnswerLiked": type ? true : false,
      "answerFeedback": null,
      "activityType": "Form Center",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertTelemetryActivity(data);
  }

  const downloadForm = (url, formName) => {
    sendActivityData(true, formName)
    var a = document.createElement('a');
    a.target="_blank"
    a.href = url
    a.click()
  }
  
  return (
    <CssBaseline>
      <Box container="true" maxWidth='xl' className={classes.root}>
        <Box className={classes.container}>
          <CustomBreadcrum backPage="Documents" backPageLink="/DocumentCenter" activePage="Form Center" />
          <h1 className={classes.pageHeading} color="textPrimary" component='h4'>Form Center</h1>
            <Box className={classes.infoStrip}>
            <h2 className={classes.title}>Forms To Help You Manage Your Loan</h2>
            <h2 className={classes.description}>The following are the forms most requested by our customers. Please use a form to expedite your request and allow us to provide you responsive service.</h2>
            <p className={classes.errorMsg} dangerouslySetInnerHTML={{ __html: errMessage !== '' ? errMessage : '' }} />

           {  (errMessage == '') &&  <Grid container spacing={1} justifyContent="space-around" columns={{ xs: 12, sm: 12, md: 12 }}>
                
                <Grid item xs={12} sm={12} md={12} className={classes.searchBox}>
                  <Grid container spacing={1} justifyContent="space-around" columns={{ xs: 12, sm: 12, md: 12 }} >
                    
                    <Grid item xs={12} sm={9} md={9} className={classes.requestBtnBox}>
                      <form noValidate autoComplete="off" onSubmit={handleSearchClick}>
                        <TextField
                          placeholder={'Type Form Name'}
                          variant="outlined"
                          InputLabelProps={{ shrink: false }}
                          className={classes.searchTextBox}
                          onChange={handleSearch}
                          value={searchInput}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment>
                                {(searchInput !== "") &&
                                  <IconButton
                                    aria-label="clear search"
                                    onClick={() => clearSearch()}
                                    edge="end"
                                  >
                                    <ClearIcon />
                                  </IconButton>}
                                <IconButton className={classes.searchIcon} onClick={() => handleSearchClick()}>
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                            className: classes.searchField,
                          }}
                        />
                      </form>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid> }
              
             
                {formLists?.map((key, index) => {  
                  return(
                  <Box className={classes.formContainer} key={index}>
                    <Grid container>
                            <Grid item xs={7} md={7}>
                              <Box className={classes.formTitle}>
                                {key.formName}
                              </Box>
                            </Grid>
                            { !key.isFillForm ?
                              <Grid item xs={5} md={5}>
                                { key.formUrl.includes(',') ?
                                <Box className={classes.downloadForm}>
                                 <Button
                                  className={classes.saveBtn}
                                  variant="contained"
                                  onClick={() => downloadForm(key.formUrl.split(",")[0], key.formName)}
                                  color="primary">
                                  DOWNLOAD FORM 1</Button>
                                  <Button
                                  className={classes.saveBtn}
                                  variant="contained"
                                  onClick={() => downloadForm(key.formUrl.split(",")[1], key.formName)}
                                  color="primary">
                                  DOWNLOAD FORM 2</Button>
                                 </Box> 
                                   : 
                                  <Button
                                  className={classes.saveBtn}
                                  variant="contained"
                                  onClick={() => downloadForm(key.formUrl, key.formName)}
                                  color="primary">
                                  DOWNLOAD FORM</Button>
                                  }
                              </Grid> : 
                              <Grid item xs={5} md={2}>
                                <Link to={'/'+`${key.formUrl}`} style={{ textDecoration: "none" }}>
                                  <Button
                                    className={classes.cancelBtn}
                                    variant="outlined"
                                  >FILL FORM ONLINE</Button>
                              </Link> 
                            </Grid>
                          }
                      </Grid>
                      <h2 className={classes.formText} dangerouslySetInnerHTML={{ __html: key.description }}></h2>
                  </Box>)
                })}
       
                { noResultFoundOnSearch &&
                  <Box className={classes.noRecordFoundContainer}>
                    <img src={databaseIcon} />
                    <h2 className={classes.documentHead2}>No Matching Results Found</h2>
                  </Box>
                }

            </Box>
        </Box>
      </Box>
    </CssBaseline>
  )
}
