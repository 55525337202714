import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import { decrypt } from '../../helpers/encrypt';
import { useStyles } from './styles';
import { getLoanLabel } from '../../helpers';

export const UserLoanInfo = (props) => {
    const classes = useStyles();
    const { accountNumber, propertyAddressLND } = props;

    return (
        <Box className={classes.root} >
            <Box my={1} mx={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Box className={classes.userLoan} display="flex" alignItems="center">
                            <HomeIcon style={{ fontSize: 50, marginRight: '30', color: '#fff' }} />
                            <Typography className={classes.loanNo} variant='body1' >
                                {getLoanLabel()} # {decrypt(`${accountNumber}`)}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid className={classes.addressContainer} container spacing={0} display="flex" alignItems="baseline" >
                            <Typography className={classes.address}>
                                Property Address
                          </Typography>
                            <Typography className={`${classes.address} ${classes.addressDetails}`} dangerouslySetInnerHTML={{ __html: propertyAddressLND }}>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}