import { makeStyles,withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 650,
    borderRadius: 12,
    backgroundColor: '#EAECED',
    position: 'relative',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxWidth: '90%',
    maxHeight: "100%",
    backgroundColor: 'transparent'
  },

  header: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '12px 2em',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  iconContainer: {

  },

  title: {
    color: "#555D6C",
    fontFamily: "Montserrat",
    fontSize: '22px',
    fontWeight: "700",
    textAlign: 'center',
    padding: 14
  },
  radiogroup: {
    marginTop: '25px'
  },
  cardBody: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    // textAlign: 'center',
    padding: '1.80rem 5em',
    backgroundColor: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  statusContainer: {
    marginBottom: 30,
  },

  statusTitle: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "500",
    marginBottom: 12,
    marginTop: 36,
  },

  statusBody: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "bold",
  },

  bodyText: {
    color: "#0C0B0B",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    marginBottom: 30,
    marginTop: 0,
  },

  linkStyle: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    fontWeight: "600",
    marginTop: 5,
  },

  btn: {
    width: 188,
    fontFamily: "Montserrat",
    fontSize: '1.5em',
    fontWeight: "600",
  },

  closeIcon: {
    position: 'absolute',
    right: 10,
  },

  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },

  statusSubTitle: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "500",
    marginBottom: 12,
    marginTop: 12,
  },

  statusSubTitles: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '1.7em',
    fontWeight: "500",
    marginBottom: 2,
    marginTop: 0,
  },

  inputStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600"
  },

  cardFooter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: "#fff",
   // padding: '4px 6.5em 0',
    // boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      flexDirection:'column-reverse'
    },
  },
  skip:{
    marginRight:40,
    [theme.breakpoints.down('sm')]: {
      marginRight:0,
    },
  },

  footerText: {
    color: "#0C0B0B",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    fontWeight: "400",
  },

  

  footerItem: {
    marginBottom: 33,
    maxWidth: 175,
  },

 
  label: {
    color: '#3D3D3D',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    '&.value': {
      fontWeight: '700'
    }
  },
  onOffText: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "16px",
    letterSpacing: "0.6px",
    fontWeight: '600',
    lineHeight: '20px',
  },
  btn: {
    width: "200px",
    height: '40px',
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: "600",
    letterSpacing: "0.53px",
    lineHeight: "19px",
    textAlign: "center",
  },
  textRight:{
    marginTop:20
  },
  btnDisable: {
    width: "192px",
    height: '40px',
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: "600",
    letterSpacing: "0.53px",
    lineHeight: "19px",
    textAlign: "center",
    backgroundColor: 'lightgrey'
  },
 
  errorMsg: {
    fontSize: '1.4em',
    fontWeight: 'bold',
    textAlign: 'center',
    paddingBottom: 12,
    fontFamily: 'Montserrat',
    color: "red",
    backgroundColor: "#fff",
  },

}));

export const RedTooltip = withStyles((theme) => ({
  arrow: {
    color: "#B91717",
  },
  tooltip: {
    backgroundColor: '#B91717',
    color: '#fff',
    fontFamily: "Montserrat",
    boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
    padding: "0.6em",
    fontSize: 16,
    maxWidth: 600,
  },
}))(Tooltip);

export const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.secondary.main,
    fontSize: '40px'
  },
  tooltip: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    padding: '20px 15px',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    letterSpacing: '0.55px',
    lineHeight: '21px',
    width: '400px',
    maxWidth: '500px',
    borderRadius: '10px'
  },
}));