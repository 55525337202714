import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';


export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 12,
    backgroundColor: "#fff",
    position: 'relative',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    padding: '2em 4.6em',
    [theme.breakpoints.down('sm')]: {
      padding: '1.6em',
    },
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxWidth: 1100,
    maxHeight: '95vh',
  },

  header: {
    width: '100%',
    position: 'relative',
  },

  iconStyle: {
    position: 'absolute',
    top: 0,
    right: 0,
    [theme.breakpoints.down('sm')]: {
      zIndex: '99',
    },
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    textAlign: "center",
    marginBottom: 20,
    fontWeight: "400",
  },

  errorMsg: {
    color: "#B00020",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    textAlign: "center",
    fontWeight: '600',
    marginBottom: 22,
  },


  consentText: {
    color: "#000",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
  },

  consentRule: {
    color: "#000",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    fontWeight: "600",
  },

  inputBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // marginBottom: 20,
  },



  cardBody: {
    width: '100%',
  },

  btn: {
    width: 189,
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "600",
    display: 'block',
    margin: '2em auto 1.5em',
  },
  btnDisable: {
    width: 189,
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "600",
    display: 'block',
    margin: '2em auto 1.5em',
    backgroundColor: 'lightgrey'
  },
}));



export const RedTooltip = withStyles((theme) => ({
  arrow: {
    color: "#B91717",
  },
  tooltip: {
    backgroundColor: '#B91717',
    color: '#fff',
    fontFamily: "Montserrat",
    boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
    padding: "0.6em",
    fontSize: 16,
    maxWidth: 600,
  },
}))(Tooltip);


export const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.secondary.main,
    fontSize: '40px'
  },
  tooltip: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    padding: '20px 15px',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    letterSpacing: '0.55px',
    lineHeight: '21px',
    width: '400px',
    maxWidth: '500px',
    borderRadius: '10px'
  },
}));

