import React, {useState} from 'react';
import { useStyles } from './styles';
import { Box, Button, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import InfoIcon from '@material-ui/icons/Info';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import Cookies from 'js-cookie';
import { decrypt } from '../../helpers/encrypt';
import { isAdminCheck } from '../../helpers';

export const EscrowAnalysisAlert = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const [open, setOpen] = useState(props?.escrowAlert);

  const handleSkip = async () => {
    setOpen(false);
    if (props.page == 'desktop') {
      const allAccountsDetail = await Cookies.get("loanAccounts");
      let allAccounts = JSON.parse(allAccountsDetail);
      const index = allAccounts.findIndex((item) => item.accountNumber == decrypt(props.accountNumber));
      const newData = [...allAccounts];
      newData[index].eaPopup1Status = false;
      Cookies.set("loanAccounts", newData);
    } else {
      const allAccountsDetail = await Cookies.get("loanAccounts");
      let allAccounts = JSON.parse(allAccountsDetail);
      const index = allAccounts.findIndex((item) => item.accountNumber == decrypt(props.accountNumber));
      const newData = [...allAccounts];
      newData[index].eaPopup2Status = false;
      Cookies.set("loanAccounts", newData);
    }
  }

  const handleRoute = () => {
    history.push('/HelpDescription/FAQs?escrow');
  }
 
  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }}  open={open}>
      <Box className={classes.root}>
         <Box className={classes.cardTitleBody}>
           <p className={classes.statusTitle}>Escrow Analysis Statement</p>
         </Box> 
        <Box className={classes.cardBody}>
          <Box className={classes.statusContainer}>
            
            <Box className={classes.cardContent1}>
                <InfoIcon className={classes.infoIcon} /> 
                <p className={classes.paragraph}>Have questions on how your escrow analysis is processed? </p>
            </Box>
            <p className={classes.description}>You can watch a short video that explains how your monthly escrow payment is calculated and the reasons behind any changes in your payment. </p>
          </Box>

          <Box className={classes.buttonContainer}>
      
            <Grid container className={classes.btn}>
              <Grid item xs={6} md={6}>
                <Button
                  className={classes.skipBtn}
                  variant="outlined"
                  onClick={() => handleSkip()} 
                  >REMIND NEXT TIME</Button>
                </Grid>
                <Grid item xs={6} md={6}>
                <Button type='submit' disabled={isAdminCheck()} className={classes.continueBtn} size="medium" onClick={() => handleRoute()} variant="contained" color="primary">
                  Watch Video
                </Button>
              </Grid>
            </Grid>
             
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}
