import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';


export const useStyles = makeStyles((theme) => (
  {
    summaryText: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "14px",
      letterSpacing: "0.6px",
      paddingRight: "30px",
      textAlign: 'justify',
      [theme.breakpoints.down('sm')]: {
        paddingRight: "0px",
        paddingBottom: "22px"
      },
    },
    onOffText: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "16px",
      letterSpacing: "0.6px",
      fontWeight: '600',
      lineHeight: '20px',
    },
    btn: {
      width: "192px",
      height: '40px',
      fontSize: "16px",
      fontFamily: "Montserrat",
      fontWeight: "600",
      letterSpacing: "0.53px",
      lineHeight: "19px",
      textAlign: "center",
    },
    successMsg: {
      fontSize: '1.4em',
      fontWeight: 'bold',
      color: 'green',
      textAlign: 'center',
      marginBottom: 10,
      marginTop: 5,
      fontFamily: 'Montserrat',
    },
  
    btnDisable: {
      width: "192px",
      height: '40px',
      fontSize: "16px",
      fontFamily: "Montserrat",
      fontWeight: "600",
      letterSpacing: "0.53px",
      lineHeight: "19px",
      textAlign: "center",
      backgroundColor: 'lightgrey'
    },
    textRight: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'right'
      },
    },
    errorMessage: {
      color: 'red',
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontWeight: 600,
      letterSpacing: '0.36px',
      lineHeight: '19px',
      maxWidth: '80%',
      margin: '0 auto 20px auto'
    },

  }
));



export const RedTooltip = withStyles((theme) => ({
  arrow: {
    color: "#B91717",
  },
  tooltip: {
    backgroundColor: '#B91717',
    color: '#fff',
    fontFamily: "Montserrat",
    boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
    padding: "0.6em",
    fontSize: 16,
    maxWidth: 600,
  },
}))(Tooltip);

export const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.secondary.main,
    fontSize: '40px'
  },
  tooltip: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    padding: '20px 15px',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    letterSpacing: '0.55px',
    lineHeight: '21px',
    width: '400px',
    maxWidth: '500px',
    borderRadius: '10px'
  },
}));