import React, { useEffect, useState } from 'react';
import { useStyles, StyledTableCell, StyledTableRow } from './styles';
import { Box, Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { decrypt } from '../../../helpers/encrypt';
import { maskAccountNo } from '../../../helpers';
import Tooltip from '@material-ui/core/Tooltip';

var businessMoment = require('moment-business-days');
import moment from 'moment';

export const DeleteConfirmationPopup = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [btnStatus, setStatus] = useState();

  useEffect(() => {

      var status = [];
      props?.data?.map((key) => {
       key?.lstRecurACH.map((val) => {
        if (businessMoment(val?.nextDraftDate, 'MM-DD-YYYY').businessDiff(businessMoment(moment(), 'MM-DD-YYYY'), true) >= 3) {
           status.push(false)
        } else {
           status.push(true);
        }
      })
     })

    const index = status.findIndex((item) => item == true)
    if (index == -1) {
      setStatus(false)
    } else {
      setStatus(true)
    }
    
  },[props])

  const renderTableBody = () => {
    return props?.data?.map((row, index) => {
      return (
        <StyledTableRow  key={`${index}`}>
          <StyledTableCell align="center">{decrypt(row.accountNumber)}</StyledTableCell>
          <StyledTableCell align="center">{maskAccountNo(decrypt(row.lstRecurACH[0]?.bankAccountNumber))}  { `${'('} ${ row.lstRecurACH[0]?.bankName }  ${')'}`}</StyledTableCell>
        </StyledTableRow>)
    })
  } 

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={props.handleCancelIcon} open={props.open}>
     
      <Box className={classes.root}>
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>Bank Account Deletion Request</Typography>
        </Box>
        <Box className={classes.cardBody2}>
          <Box className={classes.statusContainer}>
           <Box className={classes.cardContainer}>
              <Box className={classes.card1} >
                  <Box className={classes.cardContent}>
                   {/* <InfoIcon className={classes.cardIcon} /> */}
                   {  props?.status ?
                    <p className={classes.paragraph}>Deleting this bank account will terminate the autopay setup for your loan account, resulting in the cancellation of automatic payments.</p> :
                    <p className={classes.paragraph}>Deleting this bank account would affect the autopay setup with the below mentioned loan accounts:</p>
                   }
                  </Box>
                </Box>
            </Box> 
            { !props?.status &&
            <TableContainer>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Loan Account</StyledTableCell>
                    <StyledTableCell align="center">Bank Details</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderTableBody()}
                </TableBody>
              </Table>
            </TableContainer> }
            <p className={classes.proceed}> Do you want to proceed? </p>
          </Box>
        </Box>

        <Box className={classes.cardFooter}>
          <Box className={classes.footerItem}>
            <Button
              variant='outlined'
              className={classes.btn}
              onClick={props.handleSubmitClose}
            > Cancel</Button>
          </Box>
          <Box className={classes.footerItem}>
           { btnStatus ?
              <Tooltip arrow title="You can not manage this bank account as next scheduled payment in the autopay is within 3 days.">
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.disableBtn}
                > Delete</Button>
              </Tooltip> :
              <Button
                variant='contained'
                color='primary'
                className={classes.btn}
                onClick={props.handleSubmit}
              > Delete</Button>
            }
          </Box>
        </Box>
      </Box> 
    </Dialog>
  )
}
