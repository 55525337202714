import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 800,
    borderRadius: 12,
    backgroundColor: '#EAECED',
    position: 'relative',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxWidth: '90%',
    maxHeight: "100%",
    backgroundColor: 'transparent'
  },

  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "400",
    textAlign: 'center',
    margin: 16,
  },

  cardBody: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 5.5em',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      padding: '1.6em',
    },
  },

  statusContainer: {
    marginBottom: 30,
  },

  statusTitle: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "500",
    marginBottom: 12,
    marginTop: 16,
  },

  statusBody: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "bold",
  },

  bodyText: {
    color: "#0C0B0B",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    marginBottom: 30,
    marginTop: 0,
  },

  linkStyle: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    fontWeight: "600",
    marginTop: 5,
  },

  btn: {
    width: 188,
    fontFamily: "Montserrat",
    fontSize: '1.5em',
    fontWeight: "600",
  },

  closeIcon: {
    position: 'absolute',
    right: 10,
  },

  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },

  statusSubTitle: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "500",
    marginBottom: 12,
    marginTop: 12,
  },

  statusSubTitles: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '1.7em',
    fontWeight: "500",
    marginBottom: 2,
    marginTop: 0,
  },
  
  inputStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600"
  },

  labelStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "400",
  },

  cardFooter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: "#fff",
    padding: '20px 5.5em 0',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    },
  },

  footerText: {
    color: "#0C0B0B",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    fontWeight: "400",
  },

  linkStyle: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    fontWeight: "600",
    marginTop: 5,
  },

  footerItem: {
    marginBottom: 33,
    maxWidth: 175,
  },

  textField: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: '500'
  },

  invalid: {
    marginTop: 8,
    color: 'red',
    fontSize: 13,
    marginLeft: 8
  },

  bodyFont: {
    color: 'rgba(0,0,0,0.74)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    letterSpacing: '0.5px',
    lineHeight: '20px',
    paddingTop: '4px'
  },
  textFont: {
    color: '#202020',
    fontSize: '16px',
    marginTop: '6px',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    lineHeight: '19px',
    letterSpacing: '0.36px'
  },
  boxMargin: {
    marginLeft: 75
  },
  msg: {
        color: "#fd8e07",
        fontFamily: "Inter",
        fontSize: '20px',
        fontWeight: "600",
        marginBottom: 10,
        marginTop: 10
    },
}));
