import { Typography, TextareaAutosize } from '@material-ui/core';
import React, { useEffect } from 'react';
//import { RatingComponent } from 'react-rating-emoji';
import { styled } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import { useStyles } from './styles';
import closeImg from '../../assets/closeImg.png';

import {
  verydisappointed, verydisappointedfill, disappointed,
  disappointedfill, neutral, neutralfill, satisfied, satisfiedfill,verysatisfied, verysatisfiedfill, checkmark
} from "./svgs.js";

import './styles.css';
import { isAdminCheck } from '../../helpers';

const defaultBodyStyles = {
  padding: '10px 10px 30px 10px',
  fontSize: '14px',
  display: 'block !important',
  backgroundColor: '#4D4D4D',
};

const submitBtnStyle = {
  color: "#fff",
  float: "right",
  borderRadius: "3px",
  padding: "0px 13px",
  border: "1px solid #EDB36A",
  backgroundColor: "#EDB36A",
  marginBottom: "4px",
  cursor: "pointer",
  display: "block",
  width: "100% ",
  maxHeight: "50px",
  height: "50px",
  margin: "18px auto 18px",
  fontSize: "25px",
  lineHeight: 0.5,
  color: '#121D22',
  textAlign: 'center',
  fontFamily: 'Montserrat',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  textTransform: 'uppercase',
};

const defaultMessageStylesTextarea = {
  fontFamily: "Montserrat",
  fontSize: "14px",
  fontWeight: 500,
  width: '100%',
  padding: "10px",
  height: '64px',
  border: 'none',
  outline: 'none',
  borderRadius: '4px',
  border: '1px solid #9E9E9E',
  "&::placeholder": {    
    fontSize: '14px', 
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1
   }
 };

const defaultMessageStyles = {
  display: 'flex',
  justifyContent: 'center',
  width: '300px',
  padding: '3px',
  backgroundColor: '#4D4D4D',
  fontSize: '14px',
  lineHeight: 1.43,
  resize: 'none',
  border: 'none',
  color: '#ffffff',
  fontWeight: '500',
  fontFamily: 'Montserrat',
  fontSize: '14px',
  paddingBottom: '8px'
};

const messageBoxStyles = {
  marginTop: '15px'
}

const thankYouStyles = {
  color: '#3EF450',
  fontWeight: '600',
  fontFamily: 'Montserrat',
  fontSize: '18px',
  textAlign: 'center',
  paddingTop: '8px'
}

const inputVitalStyles = {
  color: '#fff',
  fontWeight: '500',
  fontFamily: 'Montserrat',
  fontSize: '14px',
  paddingBottom: '14px',
  paddingLeft: '8px',
  paddingTop: '8px'
}

const iconStyles = {
  position: 'absolute',
  right: '15px',
  height: '23px',
  width: '23px',
  zIndex: '999',
  cursor: 'pointer',
}

const thankYouContainer = {
  backgroundColor: '#4D4D4D'
}

const textAreaValidation = {
  color: '#fff',
  float: 'right'
}

let ratingGiven = false;

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: 'rgba(0, 0, 0, 0.26)',
  },
}));

const Body = ({
  bodyText,
  bodyStyles,
  nameInput,
  messageInput,
  emailInput,
  ratingInput,
  handleMessageInput,
  showNameInput,
  showMessageInput,
  showRatingInput,
  showEmailInput,
  numberOfStars,
  handleSubmit,
  requestSubmit,
  handleCloseWithoutSave
}) => {

  const classes = useStyles();
  let iconsObj = {
    1: {
      icon: verydisappointed,
      iconFilled: verydisappointedfill,
      label: "Very Bad",
      filled: false,
      color: "#FF6B6B"
    },
    2: {
      icon: disappointed,
      iconFilled: disappointedfill,
      label: "Bad",
      filled: false,
      color: "#FB8A22"
    },
    3: {
      icon: neutral,
      iconFilled: neutralfill,
      label: "Average",
      filled: false,
      color: "#FFD63D"
    },
    4: {
      icon: satisfied,
      iconFilled: satisfiedfill,
      label: "Good",
      filled: false,
      color: "#7FE517"
    },
    5: {
      icon: verysatisfied,
      iconFilled: verysatisfiedfill,
      label: "Excellent",
      filled: false,
      color: "#01D301"
    }
  };
  const [customIcons, setCustomIcons] = React.useState(iconsObj)
  useEffect(() => {
    ratingGiven = false;
  });

  const hoverIcons = (event, newHover) => {
    if (newHover === -1) {
      const customIcon = { ...customIcons };
      Object.keys(customIcons).map((index) => {
        if (!customIcon[index].clicked)
          customIcon[index].filled = false;
      });
      setCustomIcons(customIcon);
    }
    else {
      const value = newHover;
      const customIcon = { ...customIcons };
      Object.keys(customIcons).map((index) => {
        if (index !== value && !customIcon[index].clicked)
          customIcon[index].filled = false;
      });
      customIcon[value].filled = true;
      setCustomIcons(customIcon);
    }
  }

  const handleRating = (newRating) => {
    const value = parseInt(newRating.target.value);
    const customIcon = { ...customIcons };
    Object.keys(customIcons).map((index) => {
      customIcon[index].filled = false;
      customIcon[index].clicked = false;
    });
    customIcon[value].filled = true;
    customIcon[value].clicked = true;
    setCustomIcons(customIcon);
    handleMessageInput('rating', newRating.target.value);
  }

  function IconContainer(props) {
    let { value, ...other } = props;
    value = parseInt(value);
    return <div {...other}><span>{customIcons[value]?.filled ? customIcons[value].iconFilled : customIcons[value].icon}</span><span className={'tooltipText'} style={{ backgroundColor: customIcons[value].color }}>{customIcons[value].label}</span></div>;
  }

  const calculateCommentLenth = () => {
    const length = messageInput?.length
    if (isNaN(length)) {
      return 0
    } else {
      return length
    }
  }

  return (
    <div style={bodyStyles}>
      {requestSubmit ?
        <>
          <div style={thankYouContainer}>
            <img src={closeImg} alt="close icon" style={iconStyles} onClick={handleCloseWithoutSave} />
            <div style={{ textAlign: 'center', paddingTop: '20px' }}>
              {checkmark}
            </div>
            <p style={thankYouStyles}>THANK YOU!</p>
            <p style={inputVitalStyles}>Your input is vital in helping us enhance our services.</p>
          </div>
        </> :
        <>
          <div style={defaultMessageStyles}>
            <p style={defaultMessageStyles}>{bodyText}</p>
          </div>

          {showRatingInput && (
            <div style={defaultMessageStyles}>
              {/* <RatingComponent rating={ratingInput} onClick={handleRating} /> */}
              <StyledRating
                value={ratingInput}
                name={'highlight-selected-only'}
                // getLabelText={(value) => customIcons[value].label}          
                className={classes.rating}
                onChange={handleRating}
                IconContainerComponent={IconContainer}
                onChangeActive={(event, newHover) => {
                  hoverIcons(event, newHover)
                }}
                highlightSelectedOnly
              />
            </div>
          )}

          {ratingInput != -1 &&
            <>
            <div style={messageBoxStyles}>
              <TextareaAutosize
                value={messageInput}
                placeholder="Tell us more(Optional)"
                onChange={(e) => handleMessageInput('message', e.target.value)}
                style={defaultMessageStylesTextarea}
                maxLength="500"
              />
              <Typography style={textAreaValidation}>{calculateCommentLenth()}/500</Typography>
            </div>
            <button onClick={handleSubmit} style={submitBtnStyle} type="button" disabled={isAdminCheck()}>
              Send Feedback
            </button>
            </>
          }
        </>
      }
    </div>
  )
};

Body.defaultProps = {
  bodyText: "",
  bodyStyles: defaultBodyStyles,
  showEmailInput: true,
  showRatingInput: true,
  showMessageInput: true,
  showNameInput: true,
  numberOfStars: 5,
};

export default Body;
