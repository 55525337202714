import { makeStyles } from '@material-ui/core/styles';
import refinance from '../../assets/refinance.svg';
import signinIcon from '../../assets/SignIn/SignIn.svg';

export const useStyles = makeStyles((theme) => ({
  rootBg: {
    width: '100%',
    minHeight: '100vh',
    backgroundImage: `url(${signinIcon})`,
    backgroundPosition: "bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'contain',
  },
  pageHeading: {
    fontWeight: '700',
    fontFamily: 'Montserrat',
    fontSize: '2.5em',
    color: '#727272',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2em',
      fontFamily: 'Montserrat',
    },
  },
  imgWrapper: {
    width: '100',
    height: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#ddd',
    background: "url(" + refinance + ") no-repeat",
    backgroundSize: 'cover',
  },
  wrapperBody: {
    background: 'linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%)',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    display: 'flex',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '1.6em 1.6em 8em',
    fontFamily: 'Montserrat',
    width: '100%',
    maxWidth: '122em',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: '1.6em 1.6em 5em 1.6em',
    },
  },
  bgImgContainer: {

  },
  refinanceSubHeader: {
    color: '#FFFFFF',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: '600',
    letterSpacing: '0.53px',
    lineHeight: '24px',
    marginTop: 20,
    marginBottom: 20,
  },
  refinanceHeader: {
    color: '#FFFFFF',
    fontFamily: 'Montserrat',
    fontSize: '40px',
    fontWeight: '600',
    letterSpacing: '0.53px',
    lineHeight: '40px',
  },
  refinanceBodyFont: {
    color: '#202020',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    letterSpacing: '0.36px',
    lineHeight: '19px',
    padding: '12px 0'
  },
  refinanceBodyHeading: {
    fontSize: '18px',
    fontWeight: '600'
  },
  listItem: {
    listStyleType: 'circle',
    padding: '0 20px',
    margin: '8px 0',
  },
  newLoanSideBar: {
    width: '100%',
    border: '1px solid #25ABC7',
    borderRadius: '12px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: 20
    },
  },
  sidBarHeadingBg: {
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
  },
  sideBarHeading: {
    padding: '16px 45px',
    color: '#FFFFFF',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: '600',
    letterSpacing: 0,
    lineHeight: '24px',
    textAlign: 'center',

  },
  sideBarBody: {
    padding: '10px 45px',
  },
  sideBarItem: {
    margin: '25px 0'
  },
  sideBarItemFont: {
    color: '#0C0B0B',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    letterSpacing: '0.47px',
    lineHeight: '18px',
    textAlign: 'left'
  },
  sideBarIcon: {
    marginRight: '30px',
  },
  addAccButton: {
    fontFamily: 'Montserrat',
    color: '#fff',
    display: 'block',
    marginTop: '30px',
    marginBottom: '20px',
    width: '260px',
    fontSize: '17px',
    fontWeight: '600',
    textAlign: 'center'
  },
  leftBar: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }
}));