import React, { Fragment, useEffect, useState } from 'react';
import { Box, Typography, FormControlLabel, Checkbox, RadioGroup, Radio, FormControl, FormLabel } from '@material-ui/core';
import { CustomizedTablesLM } from '../CustomizedTablesLM'
import { NextPrevButton } from '../NextPrevButton'
import { SaveAndExitPopup } from '../SaveAndExitPopup';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';

export const SectionC = (props) => {
  const { parentStyle } = props
  const classes = useStyles();
  const history = useHistory();

  const [cFormData, setCFormData] = useState({
    qc1: "",
    isUpdated:false
  })
  const { qc1 } = cFormData
  const [tableRowData, setTableRowData] = useState([
    { holderName: "", balance: "", phone: "", loanNumber: "" }
  ])

  const handleChange = (event) => {
    setCFormData({ ...cFormData, [event.target.name]: event.target.value });
  };

  const addMoreRow = () => {
    let addObj = { holderName: "", balance: "", phone: "", loanNumber: "" }
    setTableRowData([...tableRowData, addObj])
  }

  const removeRow=()=>{
    let newArray = [...tableRowData]
    let newArrayLen = newArray.length
    if(newArrayLen>1){
      newArray.splice(newArrayLen-1, 1)
    }   
    setTableRowData(newArray)
  }

  const handleTableData = (dataObj) => {
    setTableRowData([...dataObj])
  }

  useEffect(() => {
    if (props.data) {
      const { questions, table } = props.data
      const { question1 } = questions;
      setCFormData({
        qc1: question1.answer,
        isUpdated:true
      })

      if (Array.isArray(table.tableRow)) {
        setTableRowData([...table.tableRow])
      } else {
        setTableRowData([table.tableRow])
      }
    }
  }, [props.data])

  useEffect(()=>{
    if(cFormData.isUpdated){
      let formErrors = formValidationSectionC()
      setFormErrorMap(formErrors)
    }
  }, [cFormData.isUpdated])


  const handleSave = (e) => {

    let formErrors = formValidationSectionC()
    let formErrorLength = Object.keys(formErrors).length
    let sectionCompleteStatus = formErrorLength>0? "incomplete" : "complete"
    props.status({id:2,sectionName:"section_C", status:sectionCompleteStatus})

    const data = {
      "section_C": {
        "questions": {
          "question1": {
            "questionText": "Do you have any additional Liens / Mortgages or Judgements?",
            "answer": cFormData.qc1
          },
        },
        "table": {
          "tableRow": tableRowData,
        },
        "sectionStatus":{
          "status":sectionCompleteStatus,
          "sectionName":"Section C:Additional Liens / Mortgages or Judgements"
          },

      }
    }
    console.log("===Section C data====", data)
    props.saveFromParent(data)
  }

  const saveAndExitAction = (action) => {
    if (action === "leave") {
      handleSave()
      history.push('/Dashboard')
    }
  }

  /*Error handler*/
const [formErrorMap, setFormErrorMap] = useState([])
const isEmpty=(str)=>str?false:true
  
const formValidationSectionC = ()=>{ 
  const formErrorMessage={}
  if(isEmpty(qc1)){
    formErrorMessage["qc1"]="This is required field"
  }
  /*Table Row Data*/
  const { holderName, balance, phone, loanNumber } = tableRowData[0]
  if(isEmpty(holderName)){
    formErrorMessage["holderName"]="This is required field"
  }
  if(isEmpty(balance)){
    formErrorMessage["balance"]="This is required field"
  }
  if(isEmpty(phone)){
    formErrorMessage["phone"]="This is required field"
  }
  if(isEmpty(loanNumber)){
    formErrorMessage["loanNumber"]="This is required field"
  }
  console.log("===c====",formErrorMessage)
  return formErrorMessage;
}

const errorHandler = (field) => {
  if (formErrorMap[field]) {
    let message = formErrorMap[field]==="invalid"?"Invalid Data, Please check the value" :"*"
    return message
  } else {
    return false
  }
}
const tableErrorHandler=()=>{ 
  if(formErrorMap.holderName){
        return (<span className={parentStyle.errorMessage}>Must Complect Liens / Mortgages or Judgements First Row </span>)
  }
  if(formErrorMap.balance){
    return (<span className={parentStyle.errorMessage}>Must Complect Liens / Mortgages or Judgements First Row </span>)
  }
  if(formErrorMap.phone){
    return (<span className={parentStyle.errorMessage}>Must Complect Liens / Mortgages or Judgements First Row </span>)
  }
  
  if(formErrorMap.loanNumber){
    return (<span className={parentStyle.errorMessage}>Must Complect Liens / Mortgages or Judgements First Row </span>)
  }  
}
/*Error handler end*/

  return (
    <Box className={classes.root}>
      <Box style={{ marginTop: "-85px" }}>
        <SaveAndExitPopup actionCallBack={saveAndExitAction}></SaveAndExitPopup>
      </Box>
      <Box height="90%" style={{ paddingTop: "25px" }}>
        <Box pb={3}>
          <Typography className={classes.subTitle}>Additional Liens / Mortgages or Judgements</Typography>
        </Box>
        <Fragment>
          <Box pb={3}>
            <FormControl component="fieldset" className={classes.formControl} >
              <FormLabel component="legend" className={parentStyle.formLabel}>
                Do you have any additional Liens / Mortgages or Judgements?
                <span className={parentStyle.errorMessage}>{errorHandler('qc1')}</span>
                </FormLabel>
              <RadioGroup name='qc1' value={cFormData.qc1} onChange={handleChange}>
                <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Box>
          {cFormData.qc1 === "Yes" ? (<Fragment>
            <Box py={2}>
              <Typography className={classes.tableTitle}>Enter the additional Liens / Mortgages or Judgements in the table</Typography>
              {cFormData.isUpdated ? tableErrorHandler() : null}              
            </Box>
            <Box>
            <CustomizedTablesLM data={tableRowData} onRowChange={handleTableData} ></CustomizedTablesLM>
            </Box>
            <Box py={3}>
              <span className={classes.addMore} onClick={() => addMoreRow()} >+Add New Row</span>
              {tableRowData.length>1 && <span className={classes.removeBtn} onClick={() => removeRow()} >- Remove Row</span>}
            </Box>
          </Fragment>) : null}

        </Fragment>
      </Box>
      <NextPrevButton
        handleNextCallback={handleSave}
        handlePrevCallBack={props.previous}
        activeStepIndex={props.activeSection}></NextPrevButton>
    </Box>
  )
}