import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '17px 0 6px 0'
  },
  accordionSum: {
    minHeight: '67px',
  },
  heading: {
    color: '#202020',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0.36px',
    lineSeight: '19px',
  },
  userLoan: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  loanNo: {
    color: 'rgba(255,255,255,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '24px',
    fontWeight: '600',
    letterSpacing: '0.18px',
    lineHeight: '24px',
  },
  addressContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginTop: 20,
    },
  },
  address: {
    color: 'rgba(255,255,255,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: '500',
    letterSpacing: '0.15px',
    lineHeight: '24px',
    flexBasis: '41%',
    marginBottom: 9,
  },

  addressDetails: {
    fontWeight: '600',
    flexBasis: '58%'
  },

  wordBreak: {
    wordBreak: 'break-all'
  }
}));