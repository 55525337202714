import React, { useEffect } from 'react';
import { Box, Typography, CircularProgress } from '@material-ui/core';
import { getHelpContent, getHelpTileContent } from '../../actions';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

import { useStyles } from './styles';
import { scrollTop } from '../../helpers';

export const Tile = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const helpContent = useSelector(state => state.helpLandingReducer);

  const dispatch = useDispatch();
  useEffect(() => {
    getData()
  }, []);

  const getData = async () => {
    await dispatch(getHelpContent('PC005'));
    scrollTop();
  }

  const routeChange = async (htmlContent, pageName, subHeader) => {
    if ((subHeader !== null) && (subHeader !== '')) {
      if (subHeader === '/ManageThirdPartyRequest') {
        props.token === 'publicToken' ? history.push(`/ThirdPartyRequest`) : history.push(`${subHeader}`);
        return;
      }
      history.push(`${subHeader}`);
    } else {
      await dispatch(getHelpTileContent(htmlContent));
      history.push(`/HelpDescription/${pageName}`);
    }
  }

  let listOfHelpArticle = ''
  if (helpContent && helpContent.lstPageContentDetails) {
    if (helpContent.lstPageContentDetails != null) {
      listOfHelpArticle = helpContent.lstPageContentDetails.map((item, index) => {
        return (
          <Card key={item.contentKey} className={classes.paper} onClick={() => routeChange(item.contentValue, item.contentKey, item.contentSubHeader)}
            style={{ order: item.sortOrder }}>
            <CardContent className={classes.cardTitle}>
              <Box className={classes.tileFlex} display='flex' flexDirection="column" justifyContent="center" alignItems="center" spacing={3}>
                <Box className={classes.tileIcon}>
                  <img src={item.contentHeader} />
                </Box>
                <Typography className={classes.Heading} variant="h6" component="h6" color="primary">
                  {item.contentKey}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        )
      })
    }
  } else {
    return (
      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
        <Box my={1} mx={2}>
          <CircularProgress color='primary' />
        </Box>
      </Box>
    )
  }

  return (
    <div className={classes.bg}>
      <Typography className={classes.mainHeading} variant="h4" component="h4" >
        Have questions? Let us help you
      </Typography>
      <Box className={classes.root} >
        {listOfHelpArticle}
      </Box>
    </div>
  );
}
