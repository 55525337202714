import Cookies from 'js-cookie';
import { axiosProtected } from '../config';
import moment from 'moment';
import { FETCH_ACCOUNT } from '../types';
import { getAppSource, getUserType } from '../helpers';
import { FETCH_ACCOUNT_INFO } from '../types';
import { ANNOUNCEMENT_STATUS } from '../types';

export const hideAnnouncement = (data) => async (dispatch) => {
  dispatch({
    type: ANNOUNCEMENT_STATUS,
    payload: data
  })
}

export const getDashboardData =  (data) => async dispatch  => {
  try {
    const dashboardData = await axiosProtected.post('/Dashboard/GetAccountInfo', data);
    dispatch({
      type: FETCH_ACCOUNT_INFO,
      payload: dashboardData.data
    });
    return dashboardData.data
  } catch (e) {    
    return null
  }
}

export const getMultiPropertyLoans = async () => {
  const data = {
    "accountNumber": "",
    "userName": '',
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
  }

  try {
    const loanData = await axiosProtected.post('/Dashboard/GetMultiPropertyLoans', data);
    if (loanData && loanData.data && loanData.data.lstResponseStatus[0].statusCode === '0') {
      return loanData.data
    }
    return null;
  } catch (e) {    
    return null;
  }
}

export const getPaymentHistory = async (accountNumber) => {
  const historyThruDate = moment().format('MM/DD/YYYY');
  const historyFromDate = moment().subtract(30, "days").format("MM/DD/YYYY");
  const data = {
    "accountNumber": accountNumber,
    "historyFromDate": historyFromDate,
    "historyThruDate": historyThruDate,   
    "historyNextSequence": 0,
    "historyNextTime": 0,
    "paymentType": "ALL",
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }
  try {
    const paymentHistory = await axiosProtected.post('/Dashboard/GetPaymentHistory', data);
    if (paymentHistory && paymentHistory.data && paymentHistory.data.lstResponseStatus[0].statusCode === '0') {
      return paymentHistory.data.lstAccountHistory
    }
    return [];
  } catch (e) {    
    return null;
  }
}

export const getEscrowTransaction = async (accountNumber) => {
  const data = {
    "accountNumber": accountNumber,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }
  try {
    const escrowTransaction = await axiosProtected.post('/DashBoard/GetEscrowTransaction', data);
    if (escrowTransaction && escrowTransaction.data && escrowTransaction.data.lstResponseStatus[0].statusCode === '0') {
      return escrowTransaction.data
    }
    return null;
  } catch (e) {    
    return null;
  }
}

export const updateLanveraEStatement = async (data) => {
  try {
    const res = await axiosProtected.post('/Dashboard/UpdateLanveraEStatement', data);
    if (res && res.data && res.data.lstResponseStatus[0].statusCode === '0') {
      return res.data
    }
    return null;
  } catch (e) {    
    return null;
  }
}

export const updateCommunicationConsent = async (data) => {
  try {    
    const res = await axiosProtected.post('/MyProfile/UpdateCommPreference', data);
    if (res && res.data && res.data.lstResponseStatus[0].statusCode === '0') {
      return res.data
    }
    return null;
  } catch (e) {    
    return null;
  }
}

export const getAllLoans = () => async dispatch => {
  const data = {
    "accountNumber": '',
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }

  try {
    const loans = await axiosProtected.post('/Dashboard/GetMultiLoanDetails', data);
    if (loans && loans.data && loans.data.lstResponseStatus[0].statusCode === '0') {
      dispatch({
        type: FETCH_ACCOUNT,
        payload: loans.data.loanDetailList
      });
      return loans.data.loanDetailList
    }
    return null;
  } catch (e) {   
    return null;
  }
}

export const getDefaultReasonsData =  async (data) => {
  try {
    const defaultReasonsData = await axiosProtected.post('/PromisePay/GetReasonForDefault', data);
    return defaultReasonsData.data
  } catch (e) {    
    return null
  }
}

export const submitPromiseRequest = async (data) => {
  try {
    const promiseRequestData = await axiosProtected.post('/PromisePayment/SubmitRequest', data);
    return promiseRequestData.data
  } catch (e) {    
    return null
  }
}

export const getPromiseDetails = async (data) => {
  try {
    const promiseRequestData = await axiosProtected.post('/PromisePayment/GetDetail', data);
    return promiseRequestData.data
  } catch (e) {    
    return null
  }
}

export const updatePromiseRequest = async (data) => {
  try {
    const promiseRequestData = await axiosProtected.post('/PromisePayment/UpdateRequest', data);
    return promiseRequestData.data
  } catch (e) {    
    return null
  }
}

export const getMaturityDetails = async (data) => {
  try {
    const maturityExtRequestData = await axiosProtected.post('/Maturity/GetMaturityExtensionRequest', data);
    return maturityExtRequestData.data
  } catch (e) {    
    return null
  }
}

export const submitMaturityDetails = async (data) => {
  try {
    const maturityExtRequestData = await axiosProtected.post('/Maturity/InsertExtensionRequest', data);
    return maturityExtRequestData.data
  } catch (e) {    
    return null
  }
}