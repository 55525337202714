import React from 'react';
import { Box, Typography, Button, Dialog, IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { useStyles } from './styles';

export const ConfirmPopup = (props) => {
  const {open, handleClose, actionCallBack} = props
  const classes = useStyles();
  return (
    <div>
      <Dialog       
        PaperProps={{ classes: { root: classes.dialogStyle } }} 
        onClose={handleClose} 
        open={open?open:false}
      >
        <Box className={classes.rootP}>        
          <Box align="right">
            <IconButton onClick={() => handleClose()}>
              <CancelIcon />            
            </IconButton>
          </Box>
          {/* <Typography align="right">
            <span style={{paddingLeft:"2px"}} onClick={() => handleClose()} className={classes.closeBtn}>X</span>
          </Typography> */}
          <Box py={1}>
            <Typography className={classes.title} id="alert-dialog-title">{"Are you sure you want to Submit Loss Mitigation Application"}</Typography>
          </Box>
          <Box className={classes.btnBox}>
            <Button className={classes.cancelBtn} variant="outlined" onClick={handleClose} color="secondary">
              CANCEL
          </Button>
            <Button className={classes.leaveBtn} variant="contained" onClick={()=>actionCallBack("save")} color="secondary" autoFocus>
              SUBMIT
          </Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}