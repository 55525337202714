import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    margin: '3.2%',
    borderRadius: '16px',
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.2)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      height: '164px',
    },
  },
  tileFlex: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start'
    },
  },

  tileIcon: {
    [theme.breakpoints.down('sm')]: {
      width: '30%',
      textAlign: 'center'
    },
    '& img': {
      width: '70px',
      height: '70px',
      [theme.breakpoints.down('xs')]: {
        width: '50px',
        height: '50px',
      },
    }
  },
  media: {
    width: '70px',
    height: '70px'
  },
  Heading: {
    color: theme.palette.secondary.contrastText,
    textTransform: 'uppercase',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.53px',
    lineHeight: '19px',
    paddingTop: '1em',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '15px',
    },
  }
}));