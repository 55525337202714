import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: 12,
    marginBottom: 28,
    backgroundColor: "#FFFFFF",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
  },

  bodyTitle: {
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: '3em',
    fontWeight: "500",
    textAlign: 'center',
    marginTop: 31,
    maxWidth: 221,
  },

  title: {
    color: "#121D22",
    fontSize: '1.8em',
    fontWeight: "700",
    fontFamily: "Montserrat",
    textAlign: 'center',
    maxWidth: '290px',
    wordBreak: "break-word",
  },

  header: {
    padding: '1.6em 4.3em',
    backgroundColor: '#C9CAC8',
    borderRadius: "12px 12px 0 0",
  },

  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  bodyText: {
    // marginLeft: 10,
    // maxWidth: '290px',
    // color: "rgba(0,0,0,0.74)",
    // fontWeight: '400',
    // fontFamily: "Montserrat",
    // fontSize: '16px',
    // wordBreak: "break-word",
    // '& p': {
    //   maxWidth: '290px',
    //   color: "rgba(0,0,0,0.74)",
    //   fontWeight: '400',
    //   fontFamily: "Montserrat",
    //   fontSize: '16px',
    //   wordBreak: "break-word",
    // }
  },

  bodyTextContainer: {
    display: 'flex',
    // marginBottom: 20,
    width: "100%"
  },

  footer: {
    padding: '1.6em 4em 3.5em'
  },

  logo: {
    height: '150px',
    width: '200px',
    // marginTop: 34,
    // marginBottom: 26,
  },

  btn: {
    width: '100%',
    backgroundColor: "#19CF7C",
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "700",
    '&:hover': {
      backgroundColor: "#19CF7C",
    }
  },
 
  lateFees: {

  }

}));

