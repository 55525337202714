import Cookies from 'js-cookie';
import { axiosProtected, baseUrl } from '../config';
import axios from "axios";

export const getDocumentTypeData =  async (data) => {
  try {
    const defaultReasonsData = await axiosProtected.post('/Document/DocumentType', data);
    return defaultReasonsData.data
  } catch (e) {    
    return null
  }
}

export const getDocumentList =  async (data) => {
  try {
    const defaultReasonsData = await axiosProtected.post('/Document/GetDocumentRequest', data);
    return defaultReasonsData.data
  } catch (e) {    
    return null
  }
}

export const submitDocumentRequest =  async (data) => {
    try {
      const defaultReasonsData = await axiosProtected.post('/Document/InsertDocumentRequest', data);
      return defaultReasonsData.data
    } catch (e) {    
      return null
    }
}

export const getRequestDetails =  async (data) => {
  try {
    const defaultReasonsData = await axiosProtected.post('/Document/RequestDetail', data);
    return defaultReasonsData.data
  } catch (e) {    
    return null
  }
}

export const downloadRequestDocument = async (data) => {
  const token = await Cookies.get('privateToken')
  try {
    const res = await axiosProtected({
        url: `${baseUrl}/Document/DownloadDocument`,
        method: 'POST',
        responseType: 'blob', 
        data: data,
        // headers: {
        //   Authorization: `Bearer ${token}`
        // }
      })
      return res
  } catch (e) {      
    return null
  }
}

export const submitRequestDetails = async (data) => {
  try {
    const defaultReasonsData = await axiosProtected.post('/Document/SubmitBorrowerResponse', data);
    return defaultReasonsData.data
  } catch (e) {    
    return null
  }
}

export const getFormLists = async (data) => {
  try {
    const defaultReasonsData = await axiosProtected.post('/Form/GetFormList', data);
    return defaultReasonsData.data
  } catch (e) {    
    return null
  }
}

