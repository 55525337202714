import { Box, Button, Divider, Grid, TextareaAutosize, Typography,CircularProgress } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { downloadRequestDocument, getRequestDetails, submitRequestDetails } from '../../../actions';
import { getAppSource, getUserType, maskLoan, renderDefaultMsg, isAdminCheck } from '../../../helpers';
import { decrypt } from '../../../helpers/encrypt';
import { useStyles } from './styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import downloadIcon from '../../../assets/RequestDocument/Download.png';
import infoIcon from '../../../assets/RequestDocument/Info.png';

import ClarificationNeeded from '../../../assets/RequestDocument/ClarificationNeeded.svg';
import Completed from '../../../assets/RequestDocument/Completed.svg';
import InProgress from '../../../assets/RequestDocument/InProgress.svg';

import Pending2 from '../../../assets/RequestDocument/Pending2.svg';
import Pending3 from '../../../assets/RequestDocument/Pending3.svg';
import SubmittedYellow from '../../../assets/RequestDocument/SubmittedYellow.svg';

import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import StepIcon  from '@material-ui/core/Stepper';
import Pagination from '@material-ui/lab/Pagination';
import Cookies from 'js-cookie';
import moment from 'moment';
import './index.css';

const useStyles1 = makeStyles((theme) => ({
  stepIcon: {
    marginTop: '-10px',
    padding: '5px'
  },
}));

const CustomStepIcon = (props,label,status) => {
  const classes = useStyles1();
  const { active, completed=true, icon } = props;
  
  // Define custom icons for active and completed steps
  let displayedIcon=SubmittedYellow;
  if(status==='Submitted'){
    if(icon===1){
      displayedIcon=SubmittedYellow;
    }
    else if(icon===2){
      displayedIcon=Pending2;
    }
    else if(icon===3){
      displayedIcon=Pending3;
    }
  }
  else if(status==='In Progress'){
    if(icon===1){
      displayedIcon=Completed;
    }
    else if(icon===2){
      displayedIcon=InProgress;
    }
    else if(icon===3){
      displayedIcon=Pending3;

    }
  }
  else if(status==='Clarification Needed'){
    if(icon===1){
      displayedIcon=Completed;
    }
    else if(icon===2){
      displayedIcon=ClarificationNeeded;
    }
    else if(icon===3){
      displayedIcon=Pending3;
    }
  }
    else if(status==='Completed'){
      if(icon===1){
        displayedIcon=Completed;
      }
      else if(icon===2){
        displayedIcon=Completed;
      }
      else if(icon===3){
        displayedIcon=Completed;
      }
  }
  // Use the Warning icon for incomplete steps
  
  return (
    <div
      style={{  marginTop: '-10px', padding: '5px'}}
    >      <img src={displayedIcon}/>
    </div>
  );
};

export const Requests = (props) => {

  const mytheme = useSelector(state => state.theme);
  const { accountNumber, requestLists = [] } = props;

  const theme = useTheme();
  const classes = useStyles();
  const componentRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'));
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false)
  const [documentLists, setDocumentList] = useState([]);
  const [userComment, setUserComment] = useState();
  const [openClassification, setOpenClassification] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [steps, setStepperSteps] = useState([{ status: 'Submitted', createdDateTime: null }, { status: 'InProgress', createdDateTime: null }, { status: 'Completed', createdDateTime: null }]);
  const [page, setPage] = React.useState(1);
  const [countPerPage, setCountPerPage] = useState(6);
  const [errorMsg, setErrorMsg] = useState("");
  const [commentStatus, setCommentStatus] = useState(false);

  const userName = Cookies.get('firstLastName')

  const handleChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (requestLists) {
      setDocumentList(requestLists)
      setPage(1);
    }
  }, [requestLists]);

  const getSteps = (details) => {
    var stepperStatus = [];
    stepperStatus = [{ status: 'Submitted', createdDateTime: null }, { status: 'In Progress', createdDateTime: null }, { status: 'Completed', createdDateTime: null }];
    let index = details.requestStatusHistory.findIndex(element => element.status === "Clarification Needed")
    let completedStatus = details.requestStatusHistory.findIndex(element => element.status === "Completed")
    
    if (completedStatus !== -1) {
      setCommentStatus(true)
    } else {
      setCommentStatus(false)
    }

     
    if (index !== -1) {
      stepperStatus[1].status = 'Clarification Needed'
    } else {
      stepperStatus[1].status = 'In Progress'
    }

    let stepperData = stepperStatus.map((e) => {
      let temp = details.requestStatusHistory.find(element => element.status === e.status)
      if (temp) {
        e.createdDateTime = temp.createdDateTime;
      }
      return e;
    })
    return stepperData;
  }

  const setStatusValue = async (index, key) => {
    if (key?.openStatus) {
      let newDocLists = [...documentLists]
      newDocLists = newDocLists.map((data) => {
        if (data.requestId === key?.requestId) {
          data['openStatus'] = false;
          const agentData = data?.clarificationHistory.filter((data)=> data.commentByAgent===true)
          agentData.map((val, index, rows) => {
            if (index  === 0) {
              data['completedMessage'] = val.comment;
            }
          })
        }
        return data;
      })
      setDocumentList(newDocLists)
    } else {
      const data = {
        "requestId": key?.requestId,
        "accountNumber": null,
        "privateLabelUrl": Cookies.get("privateLabelUrl"),
        "ipAddress": Cookies.get('publicIp'),
        "browser": Cookies.get("browser"),
        "appSource": getAppSource(),
        "userType": getUserType(),
        "userName": ""
      };

      const res = await getRequestDetails(data);
      if (res?.responseData.lstResponseStatus[0]?.statusCode === '0' || res?.responseData.lstResponseStatus[0]?.statusCode === 0) {
        const details = res?.responseData?.responseData;
        let newDocLists = [...documentLists]
        newDocLists = newDocLists.map((data) => {
          data['openStatus'] = false;
          return data;
        })
        newDocLists = newDocLists.map((data) => {
          if (data.requestId === key?.requestId) {
            data['openStatus'] = true;
            data['description'] = details?.description;
            data['statusHistory'] = details?.requestStatusHistory;
            data['requestStatusHistory'] = getSteps(details);
            data['clarificationHistory'] = [...details?.clarificationHistory];
            data['documentPath'] = details?.documentPath;
            if((data['status'] === 'Clarification Needed') || ((data['status'] === 'Completed') && (details.documentPath == null)))
            setOpenClassification(true);
            else 
            setOpenClassification(false);

            const agentData = data?.clarificationHistory.filter((data)=>data.commentByAgent===true)
            agentData?.map((val, index, rows) => {
              if (index  === 0) {
                data['completedMessage'] = val.comment;
              }
            })
          }

          return data;
        })
        setUserComment(null)
        setDocumentList(newDocLists);

      }
    }
  }
 
  const totalPages = Math.ceil(documentLists.length / countPerPage);

  const applyStatusTextColor = (key) => {
    if (key.status == 'Submitted') {
      return '#F59720'
    } else if (key.status == 'In Progress') {
      return '#007FFF'
    } else if (key.status == 'Clarification Needed') {
      return '#E02020'
    } else if (key.status == 'Completed') {
      return '#198100'
    }
  }

  const applyStatusBgColor = (key) => {
    if (key.status == 'Submitted') {
      return '#FDEAD2'
    } else if (key.status == 'Clarification Needed') {
      return '#F9D2D2'
    } else if (key.status == 'In Progress') {
      return '#CCE5FF'
    } else if (key.status == 'Completed') {
      return '#D1E6CC'
    }
  }

  const downloadDocument = async (key, value) => {
    let newDocLists = [...documentLists]
    newDocLists = newDocLists.map((data) => {
      if (data.requestId === key?.requestId) {
        data[`downloadDocumentLoader_${value}`] = true
      }
      return data;
    })
    setDocumentList(newDocLists)   

    const data = {
      "requestId": key.requestId,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };
    const response = await downloadRequestDocument(data)
    if (response) {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', decrypt(key?.accountNumber) + '_' + key.documentType + '.pdf');
      document.body.appendChild(link);
      link.click();
      let newDocLists = [...documentLists]
      newDocLists = newDocLists.map((data) => {
        if (data.requestId === key?.requestId) {
          data[`downloadDocumentLoader_${value}`] = false
        }
        return data;
      })
      setDocumentList(newDocLists)
      props.showFeedBackWidget()
    } else {
      let newDocLists = [...documentLists]
      newDocLists = newDocLists.map((data) => {
        if (data.requestId === key?.requestId) {
          data[`downloadDocumentLoader_${value}`] = false
        }
        return data;
      })
      setDocumentList(newDocLists)
      setErrorMsg(renderDefaultMsg('400'))
    }
  }

  const calculateCommentLenth = () => {
    const length = userComment?.length
    if (isNaN(length)) {
      return 0
    } else {
      return length
    }
  }

  const setUserCommentList = (event) => {
    setUserComment(event.target.value)
  }

  const openClass = () => {
    setOpenClassification(!openClassification)
  }

  const handleSubmit = async (index, key) => {
    const data = {
      "requestId": key?.requestId,
      "comment": userComment,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };
    setLoader(true)
    const res = await submitRequestDetails(data);
    if (res?.responseData.lstResponseStatus[0]?.statusCode === '0' || res?.responseData.lstResponseStatus[0]?.statusCode === 0) {
      const obj = {
        agentName: '',
        comment: userComment,
        commentByAgent: false,
        createdDateTime: moment(new Date()).format('MM/DD/YYYY')
      }
      setLoader(false)
      //Filter the data on basis of request ID instead of index
      let newDocLists = [...documentLists]
      newDocLists = newDocLists.map((data) => {
        if (data.requestId === key?.requestId) {
          data['clarificationHistory'].unshift(obj);
          data['status'] = 'In Progress';
          data['requestStatusHistory'][1]['status'] = 'In Progress';
        }
        return data;
      })
      setDocumentList(newDocLists);
      setUserComment('');
    }
  }

  const getAbbrevation = (word) => {
    const bits = word.trim().split(' ');
    return bits
      .map((bit) => bit.charAt(0))
      .join('')
      .toUpperCase();
  }

  return (
    <CssBaseline>
      <Box container="true" maxWidth='xl' className={classes.root} >
        <Box ref={componentRef}>

          <Grid container className={classes.accordianContainer}>
            <Grid item md={12} xs={12} lg={12}>

              <Box style={{ paddingBottom: '20px' }}>

                {documentLists.slice((page - 1) * countPerPage, page * countPerPage)?.map((key, index) => {  
                  return(
                    <Box className={`${classes.accordian} ${'acc'}`} key={key.requestId}>
                    <Accordion elevation={0} expanded={key?.openStatus == true} onChange={() => setStatusValue(index, key)}>
                      <AccordionSummary
                        style={{flexDirection: "row-reverse"}}
                        expandIcon={ <ExpandMoreIcon  className={classes.expand_icon}/>}
                        aria-controls={`panel${index}c-content`}
                        id={`panel${index}c-header`}
                        className={classes.transactionText}
                        >

                          <Grid container>
                            <Grid item md={12} xs={12} lg={12}>

                              <Grid container>
                                <Grid item md={8} xs={7} lg={8} style={{ margin: 'auto' }}>
                                  <Box>
                                    <Box className={classes.requestId}>Request #{key.requestNumber}</Box>
                                    <Box className={classes.documentType}>{key.documentType} { (!mediaQuery && (props?.accounts.length > 1)) && <> | Loan {maskLoan(decrypt(key?.accountNumber))} </>} </Box>
                                  </Box>
                                </Grid>
                                { !mediaQuery &&
                                <Grid item md={2} xs={0} lg={2} style={{ margin: 'auto' }}>
                                
                                   {(key.documentPath !== null && !key.downloadDocumentLoader_1) &&
                                      <Box style={{ display: 'inline-flex', margin: 'auto', cursor: "pointer" }}>
                                        <img src={downloadIcon} alt="download" style={{ height: '24px', width: '24px' }}/>
                                        <Box className={classes.downloadLink} onClick={() => downloadDocument(key, 1)}>Download</Box>
                                      </Box>
                                    }
                                    {(key.downloadDocumentLoader_1) && 
                                      <Box className={classes.loadingRoot}>
                                        <CircularProgress />
                                      </Box> 
                                    } 

                                </Grid>
                                }
                                <Grid item md={2} xs={5} lg={2} style={{ margin: 'auto' }}>
                                 <Box>
                                    <Box className={classes.transferBox} style={{ color: applyStatusTextColor(key), backgroundColor: applyStatusBgColor(key) }}>{key.status}</Box>
                                    {(key.status == 'Completed') ?
                                      <Box className={classes.documentDate}>Archive in {key.archiveDays} days</Box> :
                                      <Box className={classes.documentDate}>{moment(key.updatedDate).format('MM/DD/YYYY')}</Box>
                                    }
                                    
                                  </Box>
                                </Grid>
                              </Grid>

                            </Grid>
                          </Grid>

                        </AccordionSummary>
                        <AccordionDetails >
                          <Box className={classes.accDetails}>
                            <Box>
                              {key?.requestStatusHistory &&
                                <Stepper activeStep={key?.statusHistory?.length - 1} alternativeLabel>
                                  {key?.requestStatusHistory.map((label,index) => (
                                    <Step key={label} >
                                      <StepLabel StepIconComponent={(props)=>CustomStepIcon(props,label,key.status)} status={label} step={index} >
                                        <Box>
                                          <Box className={classes.label}>{label.status}</Box>
                                          {label?.createdDateTime !== null && <Box className={classes.date}>{moment(label.createdDateTime).format('MM/DD/YYYY')}</Box>}
                                         {/* {((label.status == 'Completed') && commentStatus) && <Box className={classes.completedText} dangerouslySetInnerHTML={{ __html: key.completedMessage }}></Box>} */}

                                          {(key.documentPath !== null && label.status == 'Completed' && !key.downloadDocumentLoader_2) &&
                                            <Box style={{ display: 'inline-flex', margin: 'auto', cursor: "pointer" }}>
                                              <img src={downloadIcon} alt="download" className={classes.downloadIcon}/>
                                              <Box className={classes.downloadLink} onClick={() => downloadDocument(key, 2)}>Download</Box>
                                            </Box>
                                          }
                                           {(label.status == 'Completed' && key.downloadDocumentLoader_2)  &&
                                            <Box className={classes.loadingRoot}>
                                              <CircularProgress />
                                            </Box> 
                                          } 
                                        </Box>
                                      </StepLabel>
                                    </Step>
                                  ))}
                                </Stepper>
                              }
                            </Box>

                            <Box className={classes.detailsSection}>
                            {((key?.description !== undefined) && (key?.description !== "") && (key?.description !== null)) &&
                              <Box className={classes.details}>Request Details</Box>
                            }
                           
                              <Box className={classes.documentDetails}>
                                <Grid container>
                                  <Grid item md={6} lg={6} xs={12} >
                                    <Box>
                                    { (mediaQuery && (props?.accounts.length > 1)) &&
                                     <Grid container>
                                        <Grid item md={1} xs={6} lg={2}>
                                          <Typography className={classes.documentDesc}>Loan Number: </Typography>
                                        </Grid>
                                        <Grid item md={11} xs={6} lg={10}>
                                          <Typography className={classes.documentValue}>{maskLoan(decrypt(key?.accountNumber))}</Typography>
                                        </Grid>
                                      </Grid>
                                      }
                                     {((key?.description !== undefined) && (key?.description !== "") && (key?.description !== null)) &&
                                      <Grid container>
                                        <Grid item md={2} xs={12} lg={2}>
                                          <Typography className={classes.documentDesc}>Description: </Typography>
                                        </Grid>
                                        <Grid item md={10} xs={12} lg={10}>
                                          { (key?.description.split('\\')[0]) &&
                                          <Typography className={classes.documentValue}>{key?.description.split('\\')[0]}</Typography> }
                                          { (key?.description.split('\\')[1]) && <Typography className={classes.documentValue}>{key?.description.split('\\')[1]}</Typography> }
                                        </Grid>
                                      </Grid>
                                     }
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>

                            {key?.clarificationHistory?.length > 0 && <Box className={classes.clarificationSection}>
                              <Box className={classes.details1}><ExpandMoreIcon className={`${openClassification ? classes.collapse_icon : classes.expand_icon}`} onClick={openClass} /> <span className={classes.requestHistory} onClick={openClass} >Request History</span></Box>
                              {openClassification && <Box className={classes.documentDetails}>
                                {key?.clarificationHistory.map((commentData, i, rows) => {

                                  return (<Grid container>
                                    <Grid item md={12} lg={12} xs={12} style={{ marginBottom: '15px' }} >
                                      <Box>
                                        <Grid container>
                                        {((-1 === i - 1) && (key.status == 'Clarification Needed')) && <Grid item xs={12} sm={12} md={12}>
                                            <Grid item xs={12} sm={12} md={6} style={{ display: 'flex', marginTop: '10px' }}>
                                              <Grid xs={2} sm={2} md={1}>

                                                {<Typography className={classes.nameTagUN}>
                                                  <span className='text'>{getAbbrevation(userName)}</span>
                                                </Typography>}
                                              </Grid>

                                              <Grid xs={10} sm={10} md={10} style={{display: 'flex'}}>
                                                <Typography className={classes.headingText}>{userName}</Typography>
                                              {/* </Grid>
                                              <Grid xs={4} sm={4} md={4}> */}
                                                <Typography className={classes.headingDate}>{moment(new Date()).format('MM/DD/YYYY')}</Typography>
                                              </Grid>
                                            </Grid>

                                            {/* <Divider orientation="vertical" flexItem className={classes.verticalHr} /> */}
                                            <Box className={`${classes.agentComment} wdt`}>
                                              <Box className={classes.textareaSection}>
                                                <TextareaAutosize
                                                  style={{ height: '56px' }}
                                                  color="primary"
                                                  id={`outlined_impacted250`}
                                                  variant="outlined"
                                                  type="text"
                                                  name="comments"
                                                  autoFocus
                                                  maxLength="500"
                                                  onChange={(event) => setUserCommentList(event)}
                                                  value={userComment}
                                                  placeholder='Mention your clarifications into a single comment'
                                                  className={`${classes.textArea}`}
                                                />
                                                <Typography className={classes.textAreaValidation}>{calculateCommentLenth()}/500</Typography>
                                              </Box>
                                              {loader ?
                                              <Button disabled={true} type='submit' className={classes.subBtn} variant="contained" color='primary'>
                                                <CircularProgress color='primary' style={{height:'24px',width:'24px'}}/>
                                              </Button>:
                                              <Button
                                                onClick={() => handleSubmit(index, key, key.userComment)}
                                                className={classes.subBtn}
                                                variant="contained"
                                                disabled={!userComment || isAdminCheck()}
                                                color="primary">
                                                SUBMIT </Button>}
                                            </Box>
                                          </Grid>
                                          }

                                          <Grid item xs={12} sm={12} md={6} style={{ display: 'flex' }}>
                                            <Grid xs={2} sm={2} md={1}>
                                              {commentData.commentByAgent && <Typography className={classes.nameTag}>
                                                {<span className='text'>AC</span>}
                                              </Typography>}
                                              {!commentData.commentByAgent && <Typography className={classes.nameTagUN}>
                                                <span className='text'>{getAbbrevation(userName)}</span>
                                              </Typography>}
                                              {/* <Divider orientation="vertical" flexItem className={classes.verticalHr} /> */}
                                            </Grid>

                                            <Grid xs={10} sm={10} md={10} style={{display: 'flex'}}>
                                              <Typography className={classes.headingText}>{commentData.commentByAgent ? commentData.agentName : userName}</Typography>
                                            {/* </Grid>
                                            <Grid xs={4} sm={4} md={4}> */}
                                              <Typography className={classes.headingDate}>{moment(commentData.createdDateTime).format('MM/DD/YYYY')}</Typography>
                                            </Grid>
                                          </Grid>

                                          <Grid item xs={12} sm={12} md={12} style={{ display: 'flex' }}>
                                            <Typography className={classes.agentComment} dangerouslySetInnerHTML={{ __html: commentData.comment }}></Typography>
                                          </Grid>

                                         

                                          {/* <Grid item xs={12} sm={12} md={6} style={{ display: 'flex', marginLeft: '15px', marginTop: '10px' }}>
                                          <Divider className={classes.horizontalHr} />
                                          <Grid xs={2} sm={2} md={1}>
                                            <Typography className={classes.nameTagUN}>
                                              <span className='text'>UN</span>
                                            </Typography>
                                          </Grid>
                                          <Grid xs={4} sm={4} md={2}>
                                            <Typography className={classes.headingText}>User Name</Typography>
                                          </Grid>
                                          <Grid xs={2} sm={2} md={2}>
                                            <Typography className={classes.headingDate}>06/30/2023</Typography>
                                          </Grid>
                                        </Grid> */}
                                          {/* 
                                        <Grid item xs={12} sm={12} md={12} className={classes.commentSection}>
                                          <Box className={`${classes.agentComment} wdt`}>
                                            <Box className={classes.textareaSection}>
                                              <TextareaAutosize
                                                style={{ height: '56px' }}
                                                color="primary"
                                                id={`outlined_impacted250`}
                                                variant="outlined"
                                                type="text"
                                                name="comments"
                                                maxLength="100"
                                                onChange={(event) => setUserCommentList(event)}
                                                value={userComment}
                                                placeholder='Enter Your Comment*'
                                                className={classes.textArea}
                                              />
                                              <Typography className={classes.textAreaValidation}>{calculateCommentLenth()}/100</Typography>
                                            </Box>
                                            <Button
                                              onClick={() => handleSubmit(index, key, key.userComment)}
                                              className={classes.subBtn}
                                              variant="contained"
                                              disabled={!userComment}
                                              color="primary">
                                              SUBMIT </Button>
                                          </Box>
                                          <Typography className={classes.agentComment}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </Typography>
                                        </Grid> */}
                                        </Grid>

                                      </Box>
                                    </Grid>
                                  </Grid>)
                                })}
                              </Box>}
                            </Box>}
                          </Box>

                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  )
                })}

                <Grid container>
                  <Grid item md={8} xs={12} lg={8}>
                    <Box className={classes.warningBox}>
                      <img src={infoIcon} style={{ height: '16px' }} />
                      <Box className={classes.disappeared}>The request(s) will be archived from the "Document Requests" history post 90 days from "Completed" status.</Box>
                    </Box>
                  </Grid>

                  <Grid item md={4} xs={12} lg={4}>
                    <Box className={classes.pagination}>
                      <Pagination count={totalPages} page={page} onChange={handleChange} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box >

    </CssBaseline >
  )
}
