import { makeStyles } from '@material-ui/core/styles';
import beforeLoginBg from '../../assets/beforeLoginBg.svg';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "120em",
    padding: "2.4em 1.5em 9em 1.5em",

    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: "2.4em 1.5em 5em 1.5em",
    },
  },

  bg: {
    backgroundColor: '#ccc',
    backgroundImage: `url(${beforeLoginBg})`,
    backgroundPosition: "top left",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'cover'
  },

  loaderContainer: {
    width: '100%',
    height: '100%',
    minHeight: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  container: {
    width: '100%',
  },

  link: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    marginTop: 14,
  },

  disabledLink: {
    color: "#7B8390",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "bold",
    textDecoration: 'none',
  },

  activeLink: {
    color: theme.palette.primary.main,
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    textDecoration: 'none',
  },

}));