import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Grid, Typography, Button, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { BoxWithShadow } from "../BoxWithShadow";
import person from '../../assets/LoanDetails/person.svg';
import phone from '../../assets/LoanDetails/phone.svg';
import { useStyles } from './styles';
import { decrypt } from '../../helpers/encrypt';
import CustomBreadcrum from '../Breadcrum';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import {
  InputAdornment, FormControl,
  OutlinedInput, InputLabel
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { checkDigitOnly, checkAllDigits, sequentialDigits, digitsStartsWith, digitsInPositions,
  digitsEndsWith, reverseSequentialDigits, getBorrowerLabel, getLoanLabel } from '../../helpers';
import { ConfirmEinSsnPopup } from '..';
import { getAppSource, getUserType, renderDefaultMsg } from '../../helpers'
import { getInValidSSNBorrowerData } from '../../actions';
import Cookies from 'js-cookie';

export const UpdateSsn = React.memo((props) => {
 
  const accountInfo = useSelector(state => state.accountInfoData.lstIsInValidSSNData);
  const selectedAccountData = useSelector(state => state.selectedAccountData);

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = React.useState(true)
  const [data, setData] = React.useState(accountInfo);
  const [status, setPopupStatus] = React.useState(false)
  const [invalidSsn, setInvalidSsn] = React.useState(false)
  const [invalidEin, setInvalidEin] = React.useState(false)
  const [arrayIndex, setIndex] = React.useState()
  const [errorMsg, setErrorMsg] = useState('')

  useEffect(() => {
    configureAccData()
  },[])
  
  useEffect(() => {
    getInValidSSNBorrowers()
  }, [selectedAccountData.accountNumber])

  const getInValidSSNBorrowers = async () => {
    setLoading(true);
    const selectedAccount = await Cookies.get('selectedAccount');
    const data = {
      "accountNumber": selectedAccount,
      "propertyState": selectedAccountData && selectedAccountData.propertyState ? selectedAccountData.propertyState : '',
      "investorCode": selectedAccountData && selectedAccountData.bsi_InvestorCode ? selectedAccountData.bsi_InvestorCode : '', 
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };
    const response = await getInValidSSNBorrowerData(data);
    if (response) {
      if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
        if (response.lstIsInValidSSNData.length > 0) {
          let configureAccountData = [];
          response.lstIsInValidSSNData.map(val => {
            configureAccountData.push({accountNumber: val.accountNumber, borrowerSSN: val.borrowerSSN, borrowerTypeName: val.borrowerTypeName, pBorrowerName: val.pBorrowerName, phoneNumber: val.phoneNumber, propertyZipCode: val.propertyZipCode, ssn: '', ein: '', 'radioButtonStatus' : 'true', 'showSsn': false, 'showEin': false })
          })
          setData(configureAccountData)
          setLoading(false);
        } else {
          setLoading(false);
          handleCancel();
        }
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
      setErrorMsg(renderDefaultMsg('400'));
    }
  }

  const configureAccData = () => {
   if (data !== undefined) { 
    let configureAccountData = [];
    data.map(val => {
      configureAccountData.push({accountNumber: val.accountNumber, borrowerSSN: val.borrowerSSN, borrowerTypeName: val.borrowerTypeName, pBorrowerName: val.pBorrowerName, phoneNumber: val.phoneNumber, propertyZipCode: val.propertyZipCode, ssn: '', ein: '', 'radioButtonStatus' : 'true', 'showSsn': false, 'showEin': false })
    })
    setData(configureAccountData);
    } else {
      getInValidSSNBorrowers();
    }
  }

  const handleChange = (e, index) => {
    if (checkDigitOnly(e.target.value)) {
      const { name, value } = e.target;
      const list = [...data];
      list[index][name] = value;
      setData(list);
    }
  };

  const validateSsn = (ssn, index) => {
    if (sequentialDigits(ssn) && checkAllDigits(ssn) && digitsStartsWith(ssn) && digitsInPositions(ssn) && digitsEndsWith(ssn) && reverseSequentialDigits(ssn)) {
      setInvalidSsn(false)
    } else {
      setIndex(index)
      setInvalidSsn(true)
    }
  }

  const validateEin = (ein, index) => {
    if (sequentialDigits(ein) && checkAllDigits(ein) && reverseSequentialDigits(ein)) {
      setInvalidEin(false)
    } else {
      setIndex(index)
      setInvalidEin(true)
    }
  }
  
  const handleClickShowPassword = (status, type , index) => {
    const list = [...data];
    list[index][type] = status;
    setData(list);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleRadioChange = (e, index) => {
    const { value } = e.target;
    let list = [...data];
    list[index]['radioButtonStatus'] = value;
    if (value) {
      list[index]['ssn'] = '';
    } else {
      list[index]['ein'] = '';
    }
    setData(list);
    setInvalidEin(false)
    setInvalidSsn(false)
  };
 
  const handleCancel = () => history.replace({ pathname: "/Dashboard" });
  
  const handleSubmit = () => {
    setPopupStatus(true);
  }
  
  const handleCloseAlert = () => {
    setPopupStatus(false)
  }

  if (loading) {
    return (
      <Box style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100vh'
      }} >
        <CircularProgress />
      </Box>
    )
  }

  const singleTypeCheck = () => {
     if (data && data.length !== 0 && data.length == 1) { 
       
       if (
          ((data[0]?.borrowerTypeName == 'Primary' && data[0]?.radioButtonStatus == 'true' && 
          data[0].ssn == '') || (data[0]?.borrowerTypeName == 'Primary' && data[0]?.radioButtonStatus == '' && data[0]?.ein == ''))) {
            return true
          } else if (
            ((data[0]?.borrowerTypeName == 'Secondary' && data[0]?.radioButtonStatus == 'true' && data[0]?.ssn == '') || (data[0]?.borrowerTypeName == 'Secondary' && data[0]?.radioButtonStatus == '' && data[0]?.ein == ''))) {
              return true
          } else {
            return false
          }
     }
  }

  const bothTypeCheck = () => {
    if (data && data.length !== 0 && data.length ==2) { 
      if (
        ((data[0]?.borrowerTypeName == 'Primary' && data[0]?.radioButtonStatus == 'true' && data[0].ssn == '') || (data[0]?.borrowerTypeName == 'Primary' && data[0]?.radioButtonStatus == '' && data[0]?.ein == ''))) {
          return true
        } else if (
          ((data[0]?.borrowerTypeName == 'Secondary' && data[0]?.radioButtonStatus == 'true' && data[0]?.ssn == '') || (data[0]?.borrowerTypeName == 'Secondary' && data[0]?.radioButtonStatus == '' && data[0]?.ein == ''))) {
            return true
        } else if (
          ((data[1]?.borrowerTypeName == 'Primary' && data[1]?.radioButtonStatus == 'true' && data[1].ssn == '') || (data[1]?.borrowerTypeName == 'Primary' && data[1]?.radioButtonStatus == '' && data[1]?.ein == ''))) {
            return true
          } else if (
            ((data[1]?.borrowerTypeName == 'Secondary' && data[1]?.radioButtonStatus == 'true' && data[1]?.ssn == '') || (data[1]?.borrowerTypeName == 'Secondary' && data[1]?.radioButtonStatus == '' && data[1]?.ein == ''))) {
              return true
          } else {
          return false
        }
    }
  }

  // if (data && data.length !== 0) {
    return (
      <Box className={classes.pageBackground}>
        <Box className={classes.root} >
          <CustomBreadcrum backPage="Dashboard" backPageLink="/Dashboard" activePage="Dashboard" />
          <h1 className={classes.pageHeading} color="textPrimary" component='h4'>Update EIN/SSN</h1>
          <BoxWithShadow bgColor='#fff' boxShadow='0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)'>
          {errorMsg !== '' ? <p className={classes.errorMsg}>{errorMsg}</p> : ''}
          { errorMsg == '' ?
           <p className={classes.title}>Please update the EIN/SSN for the following users</p> : ''
          }
          { data && data.map((data, index) => {
          return (
          <>
            <Box my={1} mx={2} key={index}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Grid className={classes.loanOwnerInfo} container direction="row" justify="flex-start" alignItems='center'>
                    <img src={person} alt="person icon" className={classes.loanInfoTitleIcon} />
                    <Typography variant='h6' className={classes.loanOwner}>
                       {data.borrowerTypeName} {getBorrowerLabel()}: {data.pBorrowerName}</Typography>
                   </Grid> 
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" className={classes.custAddress}>
                    <Box>
                       <Typography variant='h6' className={classes.bodyFont}>
                        {getLoanLabel()} Number </Typography>
                       <Typography variant='h6'  className={classes.textFont}>
                       {decrypt(data.accountNumber)}</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" className={classes.custAddress}>
                    <Box>
                       <Typography variant='h6'  className={classes.bodyFont}>
                        SSN </Typography>
                       <Typography variant='h6'  className={classes.textFont}>
                       {decrypt(data.borrowerSSN)}</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" className={classes.custAddress}>
                    <Box>
                       <Typography variant='h6'  className={classes.bodyFont}>
                       Property Zip Code </Typography>
                       <Typography variant='h6'  className={classes.textFont}>
                       {decrypt(data.propertyZipCode)}</Typography>
                    </Box>
                  </Box>
                </Grid>
                { decrypt(data.phoneNumber)  == 0 ? null :
                  <Grid item xs={12} sm={6}>
                    <Box display="flex" className={classes.custAddress}>
                      <Box>
                        <Typography variant='h6'  className={classes.bodyFont}>
                          Phone Number </Typography>
                        <Typography variant='h6'  className={classes.textFont}>
                          {decrypt(data.phoneNumber)}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                }
              </Grid>
            </Box>
            <Box className={classes.form}>
            <div className={classes.radioBtn} style={{ marginTop: "-14px" }}>
              <RadioGroup row aria-label="radio_type" name="radio_type" value={data.radioButtonStatus} style={{ justifyContent: "flex-start" }}
                onChange={e => handleRadioChange(e, index)}>
                <FormControlLabel value="true" checked={data.radioButtonStatus}
                  control={<Radio />} label="SSN" />
                <FormControlLabel value="" 
                    control={<Radio />} label="EIN" />
              </RadioGroup>
            </div>
            {data.radioButtonStatus ? 
            <FormControl variant="outlined">
           <InputLabel className={classes.labelStyle} htmlFor={'a'+index}> {getBorrowerLabel()} SSN</InputLabel>
              <OutlinedInput
                error={invalidSsn && index == arrayIndex}
                color="primary"
                id={'a'+index}
                type={data.showSsn ? 'text' : 'password'}
                label={`${getBorrowerLabel()} SSN`}
                variant="outlined"
                name="ssn"
                value={data.ssn}
                onChange={e => handleChange(e, index)}
                onKeyUp={() => validateSsn(data.ssn, index)}
                labelWidth={activeTab ? 250 : 150}
                inputProps={{
                  className: classes.inputStyle,
                  maxLength: 9,
                  autocomplete: "new-password",
                  form: {
                    autocomplete: 'off',
                  },
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={() => handleClickShowPassword(!data.showSsn, 'showSsn', index)}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {data.showSsn ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
             { invalidSsn && index == arrayIndex ? <div className={classes.invalid}>Invalid SSN</div> : null }
             </FormControl> :
             <FormControl variant="outlined">
             <InputLabel className={classes.labelStyle} htmlFor={'a'+index}> {getBorrowerLabel()} EIN</InputLabel>
                <OutlinedInput
                  error={invalidEin && index == arrayIndex}
                  color="primary"
                  id={'a'+index}
                  type={data.showEin ? 'text' : 'password'}
                  label={`${getBorrowerLabel()} EIN`}
                  variant="outlined"
                  value={data.ein}
                  name="ein"
                  onChange={e => handleChange(e, index)}
                  onKeyUp={() => validateEin(data.ein, index)}
                  labelWidth={activeTab ? 250 : 150}
                  InputLabelProps={{
                    classes: { root: classes.textField }
                  }}
                  inputProps={{
                    className: classes.inputStyle,
                    maxLength: 9,
                    autocomplete: "new-password",
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={() => handleClickShowPassword(!data.showEin,'showEin', index)}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {data.showEin ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                { invalidEin && index == arrayIndex  ? <div className={classes.invalid}>Invalid EIN</div> : null }
                </FormControl>}
                <hr style={{ marginTop: '14px' }}/>
             </Box>  
             </>) })}
             { errorMsg == '' ?
             <Box className={classes.cardFooter}>
              <Box className={classes.footerItem}>
                  <Button
                    variant='contained'
                    color='primary'
                    className={classes.btn}
                    onClick={() => handleCancel()}
                  >Cancel</Button>
                </Box>
                <Box className={classes.footerItem}>
                  <Button
                    disabled={invalidSsn || invalidEin || bothTypeCheck() || singleTypeCheck()}
                    variant='contained'
                    color='primary'
                    onClick={() => handleSubmit()}
                    className={classes.btn}
                  >Submit</Button>
                </Box>
              </Box> : ''
            }
             </BoxWithShadow>
        </Box>
        {status ? <ConfirmEinSsnPopup handleClose={handleCloseAlert} details={data}/>: null}
      </Box>
    )
  // }
})
