import React from 'react';
import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { useHistory } from 'react-router-dom';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Cookies from 'js-cookie';

export const AccountLockedAlert = (props) => {

  const theme = useTheme()
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleClose = () => setOpen(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();

  const goToRecoverPwd = () => {
    Cookies.remove('privateToken')
    history.push('/RecoverPassword?accountlocked');
  }

  return (
  <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }}  open={open} onClose={props.handleClose}>
    <Box className={classes.root}>
       <IconButton onClick={props.handleClose} className={classes.closeIcon}>
        <CancelIcon />
      </IconButton> 
      <Box className={classes.cardBody}>
        <Box className={classes.statusContainer}>
          <p className={classes.statusTitle}>Account Temporarily Locked</p>
          <p className={classes.statusSubTitle}>Your account has been temporarily locked for security reasons due to too many failed login attempts.</p>
          <p className={classes.statusSubTitle}>To unlock your account, please  <span onClick={() => goToRecoverPwd()} className={classes.resetPassword}>reset your password</span></p>
        </Box>
      </Box>
    </Box>
  </Dialog>
  )
}
