import React from 'react';
import { useStyles } from './styles';
import { Box } from "@material-ui/core";

export const CovidList = (props) => {
  const classes = useStyles();
  const { possibleOptions, forbearance, isQualified } = props;

  return (
    <>
      {possibleOptions ? <Box className={classes.covidList}>
        <h3 className={classes.listHeading}>Possible options may include, but are not limited to: </h3>
        <ul className={classes.covidOptionsList}>
          <li>
            A waiver of penalities or fee associated with short term extension of your maturity balance.
        </li>
          <li>Default interest waiver for up to 60 days.</li>
          <li>Payment plans incorporating a forbearance(either a reducing or suspension of payments) for a certain number of months, requiring either a single reinstallment payment at the end of the plan's term or term repayment over a specific period
          of time as outlined within the plan.
        </li>
          <li>A loan modification incorporating both the temporary reduction of payment amount and the extension of the loan's
          maturity date.
        </li>
        </ul>
        <p className={`${classes.text} ${classes.textMargin}`}>Please note that not all options listed above are available from all Lenders.
        Most Lenders requires that an account be current at the time of the request to be eligible for COVID-19 related assistance.
       If you are in a active bankruptcy, we recommend that you consult your bankruptcy attorney for information regarding your options.</p>
      </Box> : null}

      {(forbearance || isQualified) ? <>
        {(forbearance || isQualified) ? <Box className={classes.covidList}>
          <h3 className={classes.listHeading}>COVID-19 Forbearance</h3>
          <ul className={classes.covidOptionsList}>
            <li>
              Your payments would be temporarily suspended for a certain period of time. This is called a forbearance.
            </li>
            <li>
              At the end of the forbearance period, you would need to reinstate your
              loan with all amounts that were due during this period. If you are unable to reinstate your loan,
              let us know and we will work with you to apply for another loss mitigation alternative, such as an
              extended forbearance, a deferral to put the suspended payments at the end of your loan term, or a loan modification,
              as appropriate.
            </li>
            <li>
              You would not be charged late fees during the forbearance period,
              but your loan would continue to accrue interest.
            </li>
            <li>
              If you are set up for automated payments and you are approved for a
              forbearance, your forbearance acceptance will not cancel your scheduled payments.
              You will need to contact us separately to suspend this service.
            </li>
            <li>
              If you are in an active bankruptcy, we recommend that
              you consult your bankruptcy attorney for information regarding your options.
            </li>
          </ul>
        </Box> : null}
        {forbearance ? <Box className={classes.assistStepsList}>
          <h3 className={classes.listHeading}>To apply for assitance, follow these four steps</h3>
          <ol >
            <li> Complete the COVID-19 application below.</li>
            <li> Confirm the email address on file is correct.<br />
              <b>PLEASE NOTE:</b> We may reach out to you for additional documentation in connection with your review.<br />
            If  approved, the Forbearance Plan Agreement will be sent to your email address.
            </li>
            <li>Print, sign and return the Forbearance Plan Agreement.</li>
            <li>Save the Forbearance Plan for your records.</li>
          </ol>
        </Box> : null}
      </> : null}
    </>
  )
}