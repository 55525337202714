import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => (
  {
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      fontFamily: 'Montserrat',
      '& > *': {
        margin: theme.spacing(1),
      },
      '&:last-child': {
        paddingBottom: '4em'
      },

      width: "100%",
      maxWidth: "123em",
      padding: "2.4em 1.5em 1em 1.5em",
      margin: '0 auto',
      [theme.breakpoints.down('md')]: {
        padding: "2.4em 1.5em 1em 1.5em",
      },
    },
    searchContainer: {
      maxWidth: "140em",
      padding: "0.5em 1.5em 0.5em 1.5em",
      margin: '0 auto',
      [theme.breakpoints.down('md')]: {
        padding: "2.4em 1.5em 1em 1.5em",
      },
    },
    mainContainer: {
      width: "100%",
      maxWidth: "123em",
      padding: "1em 1.5em 1em 1.5em",
      margin: '0 auto',
      [theme.breakpoints.down('md')]: {
        padding: "2.4em 1.5em 1em 1.5em",
      },
    },
    header: {
      width: '100%',
      // display: 'flex',
      // flexWrap: 'wrap',
      // justifyContent: 'space-between',
      // alignItems: 'center',
      padding: '0em 9em 0.5em 0em',
      [theme.breakpoints.down('sm')]: {
        padding: '0em 0.5em 0em',
        flexWrap: 'initial'
      },
    },
    collapseAllBtn: {
      display: 'block',
      color: "#FFF",
      fontFamily: "Montserrat",
      fontSize: '1.4em',
      cursor: 'pointer',
      fontWeight: "600",
      float: 'right'
    },
    videosFilter:{
      display: 'flex',
      color: "#FFF",
      fontFamily: "Montserrat",
      fontSize: '1.4em',
      cursor: 'pointer',
      fontWeight: "600",
      marginLeft:'90px',
      paddingTop:'30px',
      alignItems:'baseline',
      [theme.breakpoints.down('sm')]: {
        marginLeft:'0px',
      },
    },
    filterText:{
      color: '#121D22',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal',
      marginRight:10
    },
    actionPanel:{
      display: 'flex',
      alignItems: 'center',
      justifyContent:'space-between',
      marginBottom:'-20px',
      [theme.breakpoints.down('sm')]: {
        flexDirection:'column'
      },
    },
    heading: {
      fontSize: '22px',
      letterSpacing: '.6px',
      marginTop: '24px',
      marginBottom: '10px',
      fontWeight: '700',
      color: '#1A4664',
      lineHeight: '29px',
      fontFamily: "Montserrat",
      [theme.breakpoints.down('sm')]: {
        fontWeight: '600',
        fontSize: '20px'
      },
    },
    heading1: {
      fontSize: '24px',
      letterSpacing: '.6px',
      marginTop: '10px',
      marginBottom: '10px',
      fontWeight: '700',
      color: '#727272',
      lineHeight: '29px',
      fontFamily: "Montserrat",
      textAlign: "center",
      [theme.breakpoints.down('sm')]: {
        fontWeight: '600',
        fontSize: '20px'
      },
    },
    heading2: {
      fontSize: '20px',
      letterSpacing: '.6px',
      fontWeight: '700',
      color: '#727272',
      lineHeight: '29px',
      fontFamily: "Montserrat",
      // textAlign: "center",
      marginLeft: '290px',
      [theme.breakpoints.down('sm')]: {
        fontWeight: '500',
        fontSize: '18px',
        marginLeft: '0px'
      },
    },
    heading3: {
      fontSize: '20px',
      letterSpacing: '.6px',
      fontWeight: '700',
      color: '#727272',
      lineHeight: '29px',
      fontFamily: "Montserrat",
      marginLeft: '290px',
      marginBottom: '20px',
      [theme.breakpoints.down('sm')]: {
        fontWeight: '500',
        fontSize: '18px',
        marginLeft: '0px'
      },
    },
    finding: {
      fontSize: '18px',
      textAlign: 'center',
      fontFamily: 'MONTSERRAT',
      fontWeight: '600',
      marginTop: '15px'
    },
    paragraph: {
      fontSize: '16px',
      letterSpacing: '.4px',
      marginTop: '10px',
      fontWeight: '400',
      lineHeight: '29px',
      fontFamily: "Montserrat"
    },
    desc: {
      padding: '10px 61px 20px 10px',
      [theme.breakpoints.down('sm')]: {
        padding: '10px'
      },
    },
    searchField: {
      borderRadius: '80px',
      background: '#ffffff',
      marginLeft: '-2px',
      height: '45px',
      width:'70%',
      [theme.breakpoints.down('sm')]: {
        borderRadius: '80px'
      }
    },
    searchIcon: {
      borderRadius: '80px',
      marginRight: '-18px',
      border: '1px solid #EDB36A',
      padding: '6px 20px',
      backgroundColor: '#EDB36A',
      "&:hover": {
        backgroundColor: "#EDB36A"
      },
      [theme.breakpoints.down('sm')]: {
        padding: '6px 8px',
        marginRight: '-19px'
      },
    },
    searchIconDisable: {
      borderRadius: '80px',
      marginRight: '-18px',
      border: '1px solid #949494',
      padding: '6px 20px',
      backgroundColor: '#949494',
      pointerEvents: "none",
      "&:hover": {
        backgroundColor: "#949494"
      },
      [theme.breakpoints.down('sm')]: {
        padding: '9px 10px',
      },
    },
    textField: {
      width: '90%',
      alignItems:'center',
      margin: '15px 70px',
      borderRadius: '50px',
      '& .MuiOutlinedInput-input': {
        padding: '18.5px 20px',
        fontSize: '18px',
        fontFamily: 'Montserrat',
        fontWeight: '600'
      },
      [theme.breakpoints.down('sm')]: {
        margin: '8px 20px'
      },
    },
    autocomplete: {
      width: '35%',
      marginLeft: '500px',
      marginBottom: '40px',
      border: '1px solid #d4d4d4',
      padding: '10px',
      cursor: 'pointer',
      backgroundColor: '#fff'
    },
    categories: {
      color: '#202020',
      fontSize: '18px',
      textDecoration: 'none',
      fontWeight: '700',
      fontFamily: "Montserrat",
      marginLeft:16,
      marginTop:'10px'
    },
    question: {
      padding: '22px 45px 22px 15px',
      fontSize: '16px',
      letterSpacing: '.4px',
      lineHeight: '19px',
      textDecoration: 'none',
      color: '#202020',
      fontWeight: '700',
      fontFamily: "Montserrat",
      transition: 'all .2s linear'
    },
    noDataFoundText: {
      fontFamily: "Montserrat",
      textAlign: 'center',
      fontSize: '16px',
      padding: '5px',
      fontWeight: '600'
    },
    container: {
      width: '90%',
      minWidth: "33.3em",
      marginBottom: '9em',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '5em',
      },
    },
    formContainerAccordian: {
      // minHeight: "300px",
      backgroundColor: '#FAFAFA',
      borderRadius: '0px 0px 10px 10px',
      boxShadow: '0px 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    expand_icon: {
      color: '#000000',
      fontSize: '30px',
      fontWeight: '600',
      width: '40px'
    },

    line: {
      borderBottom: '3px solid #F7B15C',
      '&.borderBtm':{
        borderBottom: 'none !important',
      },
    },
    transactionText: {
      color: '#626262',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '10px',
      fontFamily: 'Inter',
      borderRadius: '0px 5px 0px 0px',
      borderBottom: '3px solid #F7B15C',
      marginBottom: '10px',
      '&.borderBtm':{
        borderBottom: 'none !important',
      },
      
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '18px',
      },
      padding:'0px 20px'
    },

    accordian: {
      width: '100%',
    },
    profileBtn: {
      width: "150px",
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: "600",
      letterSpacing: "0.53px",
      lineHeight: "19px",
      textAlign: "center",
      height: '30px'
    },
    Margin0: {
      [theme.breakpoints.down('md')]: {
        margin: 0,
      }
    },
    searchCount: {
      fontSize: '24px',
      letterSpacing: '.6px',
      fontWeight: '700',
      color: '#727272',
      lineHeight: '29px',
      fontFamily: "Montserrat",
      marginLeft: '105px',
      [theme.breakpoints.down('sm')]: {
        fontWeight: '500',
        fontSize: '18px',
        marginLeft: '0px'
      },
    },
    improveComment: {
      borderRadius: '8px',
      border: '1px solid #B4B4B4',
      background: '#FAFAFA',
      margin: '15px 0px',
      padding: '25px',
    },
    rating: {
      display: 'flex',
      justifyContent: 'end'
    },
    disableBtn: {
      borderRadius: '4px',
      border: '1px solid #EDB36A',
      background: '#FFFFFF',
      marginRight:'15px',
      '& .MuiButton-label': {
        color: '#555D6C',
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: 'normal'
      }
    },
    btn: {
      borderRadius: '4px',
      '&:hover': {
        background: '#EDB36A',
      }
    },
    ratingText: {
      color: '#202020',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '24px',
      cursor: 'pointer',
      marginRight: '12px'
    },
    like: {
      cursor: 'pointer',
      marginRight: '24px'
    },
    dislike: {
      cursor: 'pointer',
      marginRight:'16px'
    },
    textareaSection: {
      paddingTop: '16px',
      '& .MuiFormLabel-root': {
        asterisk: {
          color: 'red',
          '&$error': {
            color: 'red'
          },
        }
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: '15px'
      },
    },
    textAreaValidation: {
      color: '#202020',
      textAlign: 'right',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '24px'
    },
    textArea: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: 500,
      width: '100%',
      padding: "10px",
      height: '56px',
      borderRadius: '4px',
      border: 'none',
      backgroundColor: 'transparent',
      outline: 'none',
      border: '1px solid #9E9E9E',
      "&::placeholder": {
        fontSize: '14px',
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '1rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        lineHeight: 1
      }
    },
    acc:{
      borderRadius: '10px',
      border: '2px solid #F7B15C',
      marginBottom:'16px',
      flexDirection:'column'
    },
    faqCount:{
       color: '#202020',
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
      margin:'10px 10px 10px 15px',
      display:'flex',
      alignItems: 'center'

    },
    bottomBox:{
      display: 'flex',
      alignItems: 'center'
    },
    youtubeCount:{
      marginRight:'10px'
    },
    articleCount:{
      marginRight:'10px'
    },
    ytSmall:{
      marginRight:'10px'
    },
    videoResponsive:{
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      marginBottom:'10px'
    },
    videoContainer:{
      [theme.breakpoints.down('sm')]: {
         '& iframe':{
          width: '300px', 
          height: '315px', 
         }
      },
    }

  })
)
