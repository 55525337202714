import { makeStyles } from '@material-ui/core/styles';
import background_img from '../../assets/Admin/background_img.png';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    position: 'relative'
  },

  header: {
    width: '100%',
    height: 100,
    padding: 16,
    backgroundColor: "#cccccc",
    backgroundImage: `url(${background_img})`,
    ackgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },

  icon: {
    width: 100,
    height: 100,
  },

  body: {
    width: '100%',
    height: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  container: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },

  title: {
    fontSize: '24px',
    fontWeight: '600',
    fontFamily: 'Montserrat',
    color: "#535D6D",
    marginBottom: 20,
    textAlign: 'center',
  },

  subTitle: {
    fontSize: '16px',
    fontWeight: '400',
    fontFamily: 'Montserrat',
    color: "#535D6D",
    textAlign: 'center',
  },

  footer: {
    width: '100%',
    height: 50,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#cccccc",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }


}));