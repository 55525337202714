import React, { useState, useEffect } from 'react';
import { useStyles } from './styles';
import { Box, Button, Tooltip, ClickAwayListener } from '@material-ui/core';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { EscrowTransactionAlert } from '../../components';
import { getEscrowTransaction } from '../../actions';
import { useSelector } from 'react-redux';
import { numberWithCommas } from '../../helpers';

import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import HelpIcon from '@material-ui/icons/Help';
import { AmortizationCalculator } from '../AmortizationCalculator'

import Cookies from 'js-cookie';
import { getAppSource, getUserType } from '../../helpers'
import { insertActivityLog, getClientName } from '../../actions'
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { ESCROW_PAYMENT } from '../../helpers/constants';

const LightToolTip = withStyles((theme) => ({
  arrow: {
    color: '#235A86',
  },
  tooltip: {
    backgroundColor: '#235A86',
    color: '#fffff',
    fontFamily: "Montserrat",
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    padding: "16px",
    fontSize: 14,
    maxWidth: 350,
    fontWeight: 500,
    borderRadius: 8
  },
}))(Tooltip);


export const LoanSummary = (props) => {
  const themeData = useTheme();
  const content = useSelector(state => state.content);
  const privateLabelUrl = Cookies.get("privateLabelUrl")

  const { principalLoan, interestRate, escrowBalance, origionalLoanAmount,
    origionationDate, isPaymentAllowed, accountNumber, isRefinance,
    noteRate, paymentCycle, armCurrentRate, armNextPaymentChangeDate, armNextRateChangeDate, isArm, refinanceURL, refiCompany, isPaymentAllowedOnDashboard, displayPaymentCycle  } = props;

  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const mediaQuery = useMediaQuery(themeData.breakpoints.down('sm'));

  const [expand, setExpand] = useState(false);
  const [open, setOpen] = useState(false);
  const [escrowDetails, setEscrowDetails] = useState([]);
  const [loading, setLoading] = useState(false)
  const [isCalculator, setIsCalculator] = useState(false)
  const [openToolTip, setOpenToolTip] = useState(false);

  // const checkUrl = isRefinance ? refinanceURL.includes('Refinance') : false;

  const handleCalculator = (status) => {
    setIsCalculator(status)
  }


  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const theme = useSelector(state => state.theme);
  const { menuSecondaryColorCode, secondaryColorCode } = theme;


  const [openEscrowToolTip, setOpenEscrowToolTip] = useState(false);

  const handleTooltipClose = () => {
    setOpenToolTip(false);
  };
  const handleTooltipOpen = () => {
    setOpenToolTip(true);
  };

  const handleEscrowTooltipClose = () => {
    setOpenEscrowToolTip(false);
  };
  const handleEscrowTooltipOpen = () => {
    setOpenEscrowToolTip(true);
  };

  const getData = async () => {
    setLoading(true)
    const res = await getEscrowTransaction(accountNumber);
    if (res) {
      setEscrowDetails(res.lstEscrowDetails)
    }
    setLoading(false)
  }
  
  useEffect(() => {
    getData()
    getClientShortName()
  }, []);
  
  const getClientShortName = async () => {

    const data = {
      "accountNumber" : accountNumber,
      "userName": Cookies.get('userName'),
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType()
    }

    const result = await dispatch(getClientName(data));
    if (result) {
      const { lstResponseStatus, featureToggle } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        Cookies.set('isDocRequestAllowed', featureToggle?.isDocRequestAllowed);
      }
    }
  }
  
  const renderContent = (content) => {
    if (content && content.lstPageContentDetails && content.lstPageContentDetails.length !== 0) {
      return content.lstPageContentDetails.filter(item => item.contentKey.toUpperCase() === "ESCROWBALANCE")
        .map(item => {
          return item.contentValue
        })
    }
  }

  const sendActivityData = async () => {
    const text = "User clicked on Refinance my Home button on home page under Loan Summary section.";
    const remark =  refinanceURL.includes('gomortgage') ? 'GoMortgage - ' + text : 
    refinanceURL.includes('alliedmg') ? 'AlliedMortgage - ' + text :  `User clicked on Refinance my Home button on home page under Loan Summary section on MLW and redirected to refinance tile (${refiCompany})`
    const data = {
      "accountNumber": accountNumber,
      "activityCode": "UAL202",
      "activityStatus": "Success",
      "action": refiCompany,
      "controller": "Dashboard",
      "apiName": "GetAccountInfo",
      "activityRemark":  remark,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertActivityLog(data);
  }
 
  const route = () => {
    sendActivityData();
    if (refinanceURL !== null && refinanceURL !== "") {
      if (refinanceURL.toLowerCase().includes('RefinanceAdvertisement'.toLowerCase())) {
        history.push(refinanceURL  + `?refiCompany=${refiCompany}`)
      } else {
        window.open(refinanceURL, '_blank')
      }
    }
  }

  return (
    <Box className={classes.root}>
      <h1 className={classes.title}>Loan Summary</h1>
      <Box className={classes.cardBody}>
        <Box className={classes.statusContainer}>
          <p className={classes.statusTitle}>Outstanding Principal</p>
          <h3 className={classes.statusBody}>{principalLoan ? `$ ${numberWithCommas(parseFloat(principalLoan).toFixed(2))}` : ""}</h3>
        </Box>
        <Box className={classes.statusContainer}>
          <p className={clsx(classes.statusTitle)}>Interest Rate<span className={classes.statusSubTitle}></span></p>
          <h3 className={classes.statusBody}>{interestRate ? `${parseFloat(interestRate).toFixed(3)}%` : ''}</h3>
        </Box>
        <Box className={classes.statusContainer}>
          <p className={classes.statusTitle} style={{ display: 'flex' }}>Escrow Balance
             {!mediaQuery && <LightToolTip
                title={renderContent(content)}
                arrow placement="bottom" >
                <HelpIcon style={{ color: secondaryColorCode, marginLeft: '3px' }}  />
              </LightToolTip> }
              {mediaQuery && 
              <ClickAwayListener onClickAway={handleTooltipClose}>
               <LightToolTip
                  title={renderContent(content)}
                  arrow placement="bottom"
                  open={ openToolTip }
                >
                  <HelpIcon style={{ color: secondaryColorCode, marginLeft: '3px' }} onClick={ handleTooltipOpen} />
                </LightToolTip>
              </ClickAwayListener>}
          </p>

          <h3 className={classes.statusBody} style={{ display: 'flex' }}>
            {escrowBalance && escrowBalance != 'NA' ?  (Math.sign(escrowBalance) == -1 ?
             `-$ ${numberWithCommas(parseFloat(Math.abs(escrowBalance)).toFixed(2))}` :
             `$ ${numberWithCommas(parseFloat(escrowBalance).toFixed(2))}`) :
              <>N/A 
              {!mediaQuery && <LightToolTip
                title={ESCROW_PAYMENT}
                arrow placement="bottom" >
                <HelpIcon style={{ color: secondaryColorCode, marginLeft: '3px' }}  />
              </LightToolTip> }
              {mediaQuery && 
              <ClickAwayListener onClickAway={handleEscrowTooltipClose}>
               <LightToolTip
                  title={ESCROW_PAYMENT}
                  arrow placement="bottom"
                  open={ openEscrowToolTip }
                >
                  <HelpIcon style={{ color: secondaryColorCode, marginLeft: '3px' }} onClick={ handleEscrowTooltipOpen} />
                </LightToolTip>
              </ClickAwayListener>}
              </>
             }
          </h3>
        </Box>
      </Box>
      <Box className={classes.seeMore}>
        <Button className={classes.expandBtn}
          endIcon={expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          onClick={() => setExpand(!expand)}
        >
          {expand ? 'Hide Details' : 'See More Details'}
        </Button>
        {expand && <Box className={classes.showAndHideContainer}>
          <Box className={classes.detaiContainer}>
            <Box className={classes.detailTitleContainer}><h1 className={classes.detailTitle}>Additional Loan Details</h1></Box>
            <Box className={classes.flexContainer}>
              <Box className={classes.statusContainer}>
                <p className={classes.statusTitle}>Original Loan Amount</p>
                <h3 className={classes.statusBody}>{origionalLoanAmount ? `$ ${numberWithCommas(parseFloat(origionalLoanAmount).toFixed(2))}` : ''}</h3>
              </Box>
              <Box>
                <Button component={Link} to='/LoanInfo' className={classes.detailLink} >View Borrower’s Information</Button>
                <Button className={classes.detailLink} onClick={handleClickOpen}>View escrow payment activity</Button>
              </Box>
            </Box>
          </Box>
          <Box className={classes.detaiContainer}>
            <Box className={classes.detailTitleContainer}><h1 className={classes.detailTitle}>Note Information</h1></Box>
            <Box className={classes.cardBody}>
              <Box className={classes.statusContainer}>
                <p className={classes.statusTitle}>Origination Date</p>
                <h3 className={classes.statusBody}>{moment(origionationDate).isValid() ? moment(origionationDate).format("ll") : ''}</h3>
              </Box>
              <Box className={classes.statusContainer}>
                <p className={classes.statusTitle}>Note Rate</p>
                <h3 className={classes.statusBody}>{noteRate ? `${parseFloat(noteRate).toFixed(3)}%` : "N/A"}</h3>
              </Box>
              <Box className={classes.statusContainer}>
              { displayPaymentCycle &&
                <>
                <p className={classes.statusTitle}>Payment Cycle</p>
                <h3 className={classes.statusBody}>{paymentCycle}</h3>
                </> }
              </Box>
              
            </Box>
          </Box>
          {isArm && <Box className={classes.detaiContainer}>
            <Box className={classes.detailTitleContainer}><h1 className={classes.detailTitle}>ARM Information</h1></Box>
            <Box className={classes.cardBody}>
              <Box className={classes.statusContainer}>
                <p className={classes.statusTitle}>Current Rate</p>
                <h3 className={classes.statusBody}>{armCurrentRate ? `${parseFloat(armCurrentRate).toFixed(3)} %` : 'N/A'}</h3>
              </Box>
              <Box className={classes.statusContainer}>
                <p className={classes.statusTitle}>Next Rate Change</p>
                <h3 className={classes.statusBody}>{moment(armNextRateChangeDate).isValid() ? moment(armNextRateChangeDate).format("ll") : 'N/A'}</h3>
              </Box>
              <Box className={classes.statusContainer}>
                <p className={classes.statusTitle}>Next Payment Change</p>
                <h3 className={classes.statusBody}>{moment(armNextPaymentChangeDate).isValid() ? moment(armNextPaymentChangeDate).format("ll") : 'N/A'}</h3>
              </Box>
            </Box>
          </Box>}
        </Box>}
      </Box>
      <Box className={classes.cardFooter} >
        <Box className={classes.footerItem}>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => { handleCalculator(true); props.hideMonthlyincome() }}
            disabled={!isPaymentAllowedOnDashboard} className={classes.btn}>Amortization calculator</Button>
          {/* <Link className={classes.footerLink} to='/' >Learn More About Amortization</Link> */}
          {/* <p className={classes.statusTitle} style={{ marginTop: '16px', marginBottom: 0, textAlign: 'center' }}>Learn More About Amortization</p> */}
        </Box>
        <Box className={classes.footerItem}>
          {/* { checkUrl ?  */}
          { (privateLabelUrl?.toUpperCase() === "BSI") &&
          <Button  variant='contained'
          color='secondary'
          onClick={() => route()}
          disabled={!isRefinance} className={classes.btn}> Refinance my home</Button> }
           {/* :
          <Button  onClick={() => route()}  variant='contained'
          // color='secondary'
           disabled={!isPaymentAllowed || !isRefinance} className={classes.btn}> Refinance my home</Button> } */}
         
          {/* <Link className={classes.footerLink} to='/Refinance'>Learn More About Refinancing</Link> */}
        </Box>
      </Box>
      <EscrowTransactionAlert
        open={open}
        handleClose={handleClose}
        escrowDetails={escrowDetails}
        loading={loading}
      />

      {isCalculator && <AmortizationCalculator accountNumber={accountNumber} open={isCalculator} handleClose={() => handleCalculator(false)}></AmortizationCalculator>}
    </Box>
  )
}
