import React, {useEffect} from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => (
    {
labelStyle:{        
  color: "#202020",
  fontFamily: "Montserrat",
  fontSize: "20px",
  letterSpacing: "0.44px",
  lineHeight: "24px",
    }
}))

export const CustomRadioButton=(props)=> {

  const classes = useStyles()
  const [value, setValue] = React.useState(props.answer);
  const {labelText, buttons, radioName, qType} =  props
 
  useEffect(() => {
    if (props.answer) {
        setValue(props.answer)
    } 
}, [props.answer]);

  const handleChange = (questionType)=>(event) => {
    setValue(event.target.value);
    props.handleChangeCallback({qType:questionType,qId:event.target.name,answer:event.target.value})
  };
  //console.log(radioName,"========",buttons.length)
  return (
    <FormControl component="fieldset">
      <FormLabel className={classes.labelStyle} component="legend">{labelText}</FormLabel>
      <RadioGroup aria-label={radioName} name={radioName} value={value} onChange={handleChange(qType)}>
          {buttons && buttons.length!==-1?(buttons.map((item,index)=>(<FormControlLabel key={index} value={item.radioLabel} control={<Radio />} label={item.radioLabel} />))):null}
        
        {/* <FormControlLabel value="male" control={<Radio />} label="Male" />
        <FormControlLabel value="other" control={<Radio />} label="Other" />
        <FormControlLabel value="disabled" disabled control={<Radio />} label="(Disabled option)" /> */}
      </RadioGroup>
    </FormControl>
  );
}