import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField, OutlinedInput, InputLabel } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 8em',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },

  heading: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: '24px',
    fontWeight: "600",
    letterSpacing: "0.63px",
    lineHeight: "29px"
  },
  dialogStyle: {
    borderRadius: '12px !important',
    width: "80%"
  },
  cancelBtnBox: {
    float: "right",
    [theme.breakpoints.down('sm')]: {
      float: "left",
    }
  },

  mobileFullWidth: {
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      padding: 0,
    }
  },

  saveBtnBox: {
    float: "left",
    [theme.breakpoints.down('sm')]: {
      float: "right",
    }
  },
  appBar: {
    width: "100%",
    backgroundColor: theme.palette.secondary.main,
    color: theme.header.fontColor,
    padding: '0px 16px',
    borderRadius: '4px'
  },
  infoIcon: {
    paddingRight: "10px",
    fontSize: '35px',
    color: theme.palette.secondary.contrastText,
  },
  infoText: {
    color: theme.palette.secondary.contrastText,
    fontFamily: "Montserrat",
    fontSize: "16px",
    letterSpacing: "0.36px",
    lineHeight: "19px",
  },
  infoLink: {
    color: theme.palette.secondary.contrastText,
    fontFamily: "Montserrat",
    fontSize: "16px",
    letterSpacing: "0.36px",
    lineHeight: "19px",
    fontWeight:'700',
    marginLeft:'10px',
    textDecoration:'underline',
    cursor:'pointer'
  },
  loanDetail:{
    padding:'20px 40px 30px',
    fontSize:'15px',
    color:'#535D6D',
    fontFamily: 'Montserrat',
    fontWeight: '600'
  },
  saveBtn: {
    width: 192,
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "600",
    letterSpacing: "0.53px",
    lineHeight: "19px",
    textAlign: "center",
    height: '40px',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  cancelBtn: {
    width: 192,
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "600",
    letterSpacing: "0.53px",
    lineHeight: "19px",
    textAlign: "center",
    border: "2px solid",
    height: '40px',
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
    borderColor: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  inputContainer: {
    marginBottom: '3em',
  },
  inputContainerWithText: {
    marginBottom: '2em',
  },
  inputContainerLeft: {
    marginBottom: '3.9em',
    marginRight: '3.5em'
  },
  inputContainerLeftWithText: {
    marginBottom: '2em',
    marginRight: '3.5em'
  },

  inputContainerRight: {
    marginBottom: '3.9em',
    marginLeft: '3.5em'
  },

  inputStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600"
  },

  labelStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "400",

  },
  suggestionText: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "500",
    letterSpacing: "0.4px",
    lineHeight: "16px",
    // paddingLeft: "24px"
  },
  errorText: {
    color: "red",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "600",
    letterSpacing: "0.31px",
    lineHeight: "18px",
  },
  pl35: {
    paddingLeft: "12px",
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0
    },
  },
  pr35: {
    paddingRight: "12px",
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0
    },
  },
  closeBtn: {
    height: "24px",
    width: "24px",
    backgroundColor: "#7B8390",
    borderRadius: "50%",
    display: "inline-block",
    textAlign: "center",
    color: "#ffffff",
    cursor: "pointer"
  },
  validationError: {
    color: "red",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "600",
    letterSpacing: "0.31px",
    lineHeight: "18px",
    marginTop: '5px'
  },
  //============overwrite class===
  paperScrollPaper: { maxHeight: "100%" },

  errorMsgStyle: {
    marginBottom: '20px',
    textAlign: 'center',
  },

  verifyLoader: {
    position: 'absolute', right: '10%', top: '8px'
  },
  verifyText: {
    backgroundColor: '#7EB1CC',
    color: 'white',
    fontFamily: "Montserrat",
    fontSize: "13px",
    fontWeight: "600",
    letterSpacing: "0.36px",
    border: 'none',
    cursor: "pointer",
    paddingLeft: '13px',
    paddingRight: '13px',
    borderRadius: '4px',
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  customerCareEmail: {
    color: '#007FFF',
    cursor: "pointer",
    textDecoration: 'underline'
  },
}));

export const CutomTextField = withStyles((theme) => ({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.primary.main
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main
      },
    },
    '& .Mui-disabled':{
      opacity:'0.5'
    }
  },
}))(TextField);

export const CutomOutlinedInput = withStyles((theme) => ({
  root: {
    "&$focused $notchedOutline": {
      border: `2px solid ${theme.palette.primary.main}`,
    }
  },
  focused: {},
  notchedOutline: {}
}))(OutlinedInput);

export const CustomInputLabel = withStyles((theme) => ({
  root: {
    "&$focused": {
      color: theme.palette.primary.main
    }
  },
  focused: {},
}))(InputLabel);

export const SimpleTooltip = withStyles((theme) => ({
  arrow: {
    color: "#44ac44",
  },
  tooltip: {
    backgroundColor: '#44ac44',
    color: '#fff',
    fontFamily: "Montserrat",
    boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
    padding: "0.6em 1.6em",
    fontSize: 16,
    maxWidth: 600,
  },
}))(Tooltip);

export const RedTooltip = withStyles((theme) => ({
  arrow: {
    color: "#B91717",
  },
  tooltip: {
    backgroundColor: '#B91717',
    color: '#fff',
    fontFamily: "Montserrat",
    boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
    padding: "0.5em 1.6em",
    fontSize: 16,
    maxWidth: 600,
  },
}))(Tooltip);

