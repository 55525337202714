import axios from 'axios';
import Cookies from 'js-cookie';
import { adminProtected } from '../config/adminConfig';
import { baseUrl } from '../config';
import { getAppSource, getUserType } from '../helpers';

export const getBorrowerToken = async (borrowerUserName, privateLabelCode, privateLabelURL) => {
  const data = {
    "borrowerUserName": borrowerUserName,
    "privateLabelCode": privateLabelCode,
    "privateLabelURL": privateLabelURL,
    "userName": '',
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
  };

  try {
    const response = await adminProtected.post(`Admin/GetBorrowerToken`, data);
    if (response) {
      Cookies.remove('privateToken')
      Cookies.remove('privateToken');
      Cookies.remove('selectedAccount');
      Cookies.remove('totalAccounts');
      Cookies.remove('accessTokenID');
      Cookies.set('privateToken', response.data.accessToken);
      Cookies.set("accessTokenID", response.data.accessTokenID);
      return response.data
    }
  } catch (e) {
    return null
  }
}
export const getGetMaintenanceMessage = async () => {
  const data = {
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }

  try {
    const response = await adminProtected.post('Admin/GetMaintenanceMessage', data);
    if (response.status === 200) {
      return response.data;
    }
    return null
  } catch (e) {
    return null
  }
}

export const getGetCompanyDetails = async () => {
  const data = {
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }

  try {
    const response = await adminProtected.post('Admin/GetCompanyDetails', data);
    if (response.status === 200) {
      return response.data;
    }
    return null
  } catch (e) {
    return null
  }
}

export const UpdateCompanyDetails = async (data) => {
  try {
    const response = await adminProtected.post('Admin/UpdateCompanyDetails', data);
    if (response.status === 200) {
      return response.data;
    }
    return null
  } catch (e) {
    return null
  }
}

export const UpdateMaintenanceMessage = async (data) => {
  try {
    const response = await adminProtected.post('Admin/UpdateMaintenanceMessage', data);
    if (response.status === 200) {
      return response.data;
    }
    return null
  } catch (e) {
    return null
  }
}

export const UpdateAlertMessage = async (data) => {
  try {
    const response = await adminProtected.post('Admin/ManageEmergencyAlert', data);
    if (response.status === 200) {
      return response.data;
    }
    return null
  } catch (e) {
    return null
  }
}

export const getPrivatelabel = async () => {
  const data = {
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
  }

  try {
    const response = await adminProtected.post('Admin/GetPrivateLabelList', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const uploadNotificationFile = async (data) => {
  try {
    const response = await adminProtected.post('Admin/LinkAccountNumberToNotification', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const getInvestorCode = async (data) => {
  try {
    const response = await adminProtected.post('Admin/GetInvestorCode', data);
    return response.data;
  } catch (e) {
    return null
  }
}


export const getNotification = async (data) => {
  try {
    const response = await adminProtected.post('Admin/GetNotification', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const downloadNotifications = async (data) => {
  try {
    const res = await adminProtected({
      url: '/Admin/DownloadAccountNumberNotifications',
      method: 'POST',
      responseType: 'blob', // important
      data: data,
    })
    return res.data
  } catch (e) {
    return null
  }
}

export const getuserLists = async (data) => {
  try {
    const response = await adminProtected.post('Admin/GetAllUsers', data);
    return response.data;
  } catch (e) {
    return;
  }
}

export const getBorrowerNotification = async (data) => {
  try {
    const response = await adminProtected.post('Admin/GetBorrowerNotification', data);
    return response.data;
  } catch (e) {
    return;
  }
}

export const activateDeactivateNotification = async (data) => {
  try {
    const response = await adminProtected.post('/Admin/ActivateDeactivateNotification', data);
    return response.data;
  } catch (e) {
    return;
  }
}

export const getActivitylog = async (data) => {
  try {
    const response = await adminProtected.post('Admin/GetUserActivityLog', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const getResetpassword = async (data) => {
  try {
    const response = await adminProtected.post('Admin/ResetUserPassword', data);
    if (response.status === 200) {
      return response.data;
    }
    return null
  } catch (e) {
    return null
  }
}

export const adminChangePassword = async (data) => {
  try {
    const response = await adminProtected.post('Admin/ChangePassword', data);
    if (response.status === 200) {
      return response.data;
    }
    return null
  } catch (e) {
    return null
  }
}

export const getUnlockuser = async (data) => {
  try {
    const response = await adminProtected.post('Admin/UnlockUser', data);
    if (response.status === 200) {
      return response.data;
    }
    return null
  } catch (e) {
    return null
  }
}

export const getActivateUser = async (data) => {
  try {
    const response = await adminProtected.post('Admin/ActivateUser', data);
    if (response.status === 200) {
      return response.data;
    }
    return null
  } catch (e) {
    return null
  }
}

export const getUpdateBorrowerDetails = async (data) => {
  try {
    const response = await adminProtected.post('Admin/UpdateBorrowerDetails', data);
    if (response.status === 200) {
      return response.data;
    }
    return null
  } catch (e) {
    return null
  }
}

export const DownloadUserActivityLog = async (data) => {
  const token = await Cookies.get('adminToken')
  try {
    const res = await axios({
      url: `${baseUrl}/Admin/DownloadUserActivityLog`,
      method: 'POST',
      responseType: 'blob', // important
      data: data,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return res
  } catch (e) {
    return null
  }
}

export const ResendActivationEmail = async (data) => {
  try {
    const response = await adminProtected.post('Admin/ResendActivationEmail', data);
    if (response.status === 200) {
      return response.data;
    }
    return null
  } catch (e) {
    return null
  }
}

export const getReportPaymentDetails = async (data) => {
  try {
    const response = await adminProtected.post('Admin/GetReportPaymentDetails', data);
    if (response.status === 200) {
      return response.data;
    }
    return null
  } catch (e) {
    return null
  }
}


export const getMonthlyWeeklyPaymentDetails = async (data) => {
  try {
    const response = await adminProtected.post('Admin/GetReportPaymentType', data);
    if (response.status === 200) {
      return response.data;
    }
    return null
  } catch (e) {
    return null
  }
}


export const getNewRegLoginReport = async (data) => {
  try {
    const response = await adminProtected.post('Admin/GetReportNewUserRegistration', data);
    if (response.status === 200) {
      return response.data;
    }
    return null
  } catch (e) {
    return null
  }
}

export const totalLoginUserReport = async (data) => {
  try {
    const response = await adminProtected.post('Admin/GetReportTotalLoginUser', data);
    if (response.status === 200) {
      return response.data;
    }
    return null
  } catch (e) {
    return null
  }
}

export const getReportSagentServcieCalls = async (data) => {
  try {
    const response = await adminProtected.post('Admin/GetReportSagentServcieCalls', data);
    return response.data;
  } catch (e) {
    return null
  }
}


export const getReportWebServiceCallByName = async (data) => {
  try {
    const response = await adminProtected.post('Admin/GetReportWebServiceCallByName', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const getAlertMessage = async (privateLabelCode) => {
  const data = {
    "privateLabelCode": privateLabelCode,
    "privateLabelUrl": privateLabelCode,
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": "sting"
  }

  try {
    const response = await adminProtected.post('Admin/GetEmergencyAlert', data);
    if (response.status === 200) {
      return response.data;
    }
    return null
  } catch (e) {
    return null
  }
}