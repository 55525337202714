import React from 'react';
import { useStyles } from './styles';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const CitLengthAlert = (props) => {

  const { open, handleCitLengthAlertClose, lineType, citEscrowStatus } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      width="600px"
      PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={handleCitLengthAlertClose} open={open}
    >
      <Box className={classes.root}>
        <IconButton className={classes.iconStyle} onClick={handleCitLengthAlertClose}>
          <CancelIcon />
        </IconButton>
        <Box className={classes.header}>
          <Typography className={classes.subTitle}>You have exceeded number of add/remove line items per request limit, try reducing add/remove items from the request or submit {lineType} changes separately per request.</Typography>
        </Box>
        
        <Button
          color='primary'
          variant='contained'
          className={classes.btn}
          onClick={handleCitLengthAlertClose}
        >OK</Button> 
      </Box>
    </Dialog>
  )
}
