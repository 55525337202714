import Cookies from 'js-cookie';
import { getAppSource, getUserType } from '../helpers'
import { axiosProtected } from '../config';

export const gettingStartedfinancialHardshipFlows = async (accNo, PropertyState = null) => {
  const data = {
    "accountNumber": accNo,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "PropertyState": PropertyState,
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  };
  try {
    const response = await axiosProtected.post('/FinancialHardship/FinancialHardshipFlows', data);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      return error.response.data;
    }
    return error
  }
}

export const getCITInfo = async (accNo) => {
  const data = {
    "accountNumber": accNo,
    "taskNumber": 100,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  };
  try {
    const response = await axiosProtected.post('/Payoff/GetCITInfo', data);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      return error.response.data;
    }
    return error
  }
}

export const addCITTask = async (obj) => {
  const data = {
    "accountNumber": obj.accNo,
    "taskNumber": obj.citNo,
    "taskMessage": obj.citMsg,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  };
  try {
    const response = await axiosProtected.post('/Payoff/AddCITTask', data);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      return error.response.data;
    }
    return error
  }
}
