import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '23px 27px 40px'
  },

  loader: {
    width: '100%',
    minHeight: "85vh",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  payFrequency: {
    width: '100%',
    borderBottom: "solid 1px #535D6D",
    paddingBottom: '10px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  sectionTitle: {
    color: '#202020',
    fontFamily: 'Montserrat',
    fontSize: '2em',
    fontWeight: '500',
  },

  switchBackBtn: {
    fontFamily: 'Montserrat',
    fontSize: '1.6em',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      fontSize: '1.2em',
    },
    color: '#535D6D',
    border: 'none',
    textDecoration: 'underline',
    '&:hover': {
      border: 'none',
      background: 'none',
    }
  },

  frequencyContainer: {
    width: '100%',
    paddingLeft: 20,
  },

  labelContainer: {
    width: '100%',
    padding: '30px 0',
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)'
  },

  radioStyle: {
    padding: '0 9px',
    color: '#535D6D !important'
  },

  addCount: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "600",
    textDecoration: "underline",
  },

  activeRadioLabel: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "bold",
  },

  radioLabel: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: "600",
  },

  subText: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 12px",
    marginTop: 10,
  },

  flexBetween: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  justiRight: {
    justifyContent: 'flex-end',
  },

  subTextPadding: {
    marginLeft: 30,
  },

  payInfo: {
    width: '100%',
    marginTop: 35,
  },

  payInfoHeader: {
    width: '100%',
    borderBottom: "solid 1px #535D6D",
    paddingBottom: '10px',
    marginTop: 35,
  },

  bankAccountContainer: {
    width: '100%',
    padding: '30px 15px 20px'
  },

  formControl: {
    width: 328,
    '& .MuiSelect-select.MuiSelect-select': {
      fontFamily: "Montserrat",
      fontSize: " 16px",
      fontWeight: '600'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },

  textField: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: '600'
  },
  formControlBiwText:{
    width:'65%'
  },
  formControlMText:{
    width:'65%'
  },
  formControlRegularText:{
    width:' 227px'
  },

  addBtnContainer: {
    width: '100%',
    marginTop: 10,
  },

  paymentDetail: {
    width: '100%',
    backgroundColor: 'rgba(179,184,192,0.1)',
    padding: 20,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  subSection: {
    width: '45%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 20,
    }
  },

  subHeader: {
    width: '100%',
    borderBottom: "solid 1px #535D6D",
    paddingBottom: '10px',
  },

  termAndCondition: {
    width: '100%',
    backgroundColor: '#fff',
    padding: '13px',
    height: 162,
    overflowY: 'auto',
    border: "solid 1px #535D6D",
    marginTop: 12,
  },


  termAndConditionPrint: {
    width: '100%',
    backgroundColor: '#fff',
    padding: '13px',
    // height: 162,
    overflowY: 'auto',
    border: "solid 1px #535D6D",
    marginTop: 12,
  },

  printButton: {
    padding: 0,
    margin: '10px 0'

  },

  termText: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: '400'
  },

  multiloanTable: {
    width: '100%',
    margin: '40px 0 20px'
  },

  table: {
    minWidth: 700,
    width: '100%',
  },

  tableHeadCell: {
    borderRight: 'solid 1px #fff'
  },

  flexEnd: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },

  paymentBtn: {
    width: 200,
    fontFamily: "Montserrat",
    fontSize: " 14px",
    margin: '0 5px',
    [theme.breakpoints.down('sm')]: {
      margin: '10px auto',
    }
  },

  paymentBtnContainer: {
    marginTop: 105,
    [theme.breakpoints.down('sm')]: {
      marginTop: 45,
    }
  },

  paymentText: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: '600',
    marginTop: 10,
  },

  tableText: {
    color: '#202020',
    fontFamily: "Montserrat",
    fontSize: " 16px",
    marginRight: 5,
  },

  toolTipCell: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  deleteIcon: {
    cursor: "pointer",
  },

  flex: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },

  input: {
    padding: '11px 6px',
    maxWidth: '100px',
    minWidth: 95,
  },

  textInput: {
    maxWidth: '50px',
    padding: '11px 6px',
  },
 
  paymentAmountErroIcon: {
    position: 'absolute',
    bottom: -10,
    right: -8,
  },

  errorMsg: {
    color: 'red',
    fontSize: 14,
    fontFamily: 'Montserrat',
    fontWeight: '600',
    lineHeight: '19px',
    marginTop: '10px',
    letterSpacing: '0.36px',
  },


  paymentDayComponent: {
    border: '2px solid #25abc7',
    borderRadius: '30px',
    position: 'relative',
    width: '36px',
    height: '36px',
    lineHeight: '34px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiPickersDay-daySelected': {
      background: '#25abc7',
      color: '#fff',
    },
    '& .MuiPickersDay-hidden': {
      opacity: '0.3',
    }
  },

  greenZoneComponent: {
    // border: '2px solid #25ABC7',
    '& .MuiPickersDay-daySelected': {
      border: '2px solid #25ABC7',
      background: '#25ABC7',
      color: '#fff',
    },
    '& .MuiPickersDay-hidden': {
      opacity: '0.3',
    }
  },

  redZoneComponent: {
    '& .MuiPickersDay-daySelected': {
      border: '2px solid #EB4F4F',
      background: '#EB4F4F',
      color: '#fff',
    },
    '& .MuiPickersDay-hidden': {
      opacity: '0.3',
    }
  },

  meaningBox: {
    width: '100%',
  },

  meaningBoxTitle: {
    color: '#202020',
    fontFamily: "Montserrat",
    fontSize: "16px",
  },

  meaningBoxText: {
    color: '#202020',
    fontFamily: "Montserrat",
    fontSize: "14px",
  },

  errorMsg2: {
    marginTop: 12,
    color: 'black',
    // textAlign: 'center',
    fontSize: 12,
    fontFamily: 'Montserrat',
    fontWeight: '600',
    lineHeight: '19px',
    letterSpacing: '0.36px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      bottom: 0,
    },
  },

  draftDate: {
    display: 'flex',
  },
  draftDateBiw: {
    '& .MuiFormControl-marginNormal': {
      width: '208px',
    }
  },
  draftDateBiwMonthly: {
    '& .MuiFormControl-marginNormal': {
      width: '160px',
    }
  },

  loanDetail:{
    padding:'20px 40px 30px',
    fontSize:'15px',
    color:'#535D6D',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    textAlign:'justify'
  },

  linkStyle2:{
     color : '#007FFF',
     cursor:'pointer',
     textDecoration:'underline'
  },

  closeIcon:{
    // float:'right',
    cursor:'pointer',
    width:'18px',
    height:'18px',
    position:'absolute',
    marginTop: '3px',
    marginRight: '3px',
    right:'0px'
 },
 innerContent:{
  position: 'absolute',
  overflowY: 'auto',
  height: '94%',
  width:'100%',
  padding:'0px 0px 21px 20px',
  marginTop:'24px'
 },
 howItWorks:{
  position: 'absolute',
  top: '10%',
  left: '10%',
  width: '80%',
  background: '#ffffff',
  height: '65%',
  margin: '0px auto'
 },
 howDWork:{
  color:' #007FFF',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '17px',
  textDecoration: 'underline',
  cursor:'pointer',
  paddingLeft: '10px'
 },
 est: {
  backgroundColor: '#D9D9D9',
  borderRadius: 16,
  textAlign: "center",
  color: "#121D22",
  fontSize: 14,
  fontFamily: 'Montserrat',
  fontWeight: '700',
  margin: "auto",
  width: '85px',
  marginTop: 4
}
}));

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#535d6d',
    color: theme.palette.common.white,
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: 'bold',
    padding: 5,
   
  },
  body: {
    color: '#202020',
    fontFamily: "Montserrat",
    fontSize: " 16px",
    padding: 5,
    '& .MuiInputAdornment-positionEnd': {
      marginRight: "0px",
    },
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export const LightTooltip = withStyles((theme) => ({
  arrow: {
    color: '#235A86',
    // backgroundColor: '#ffffff',
  },
  tooltip: {
    backgroundColor: '#235A86',
    color: '#fffff',
    fontFamily: "Montserrat",
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    padding: "16px",
    fontSize: 14,
    maxWidth: 350,
    fontWeight: 500,
    borderRadius: 8,
    // border: "1px solid #4D4D4D"
  },
}))(Tooltip);

export const WhiteTooltip = withStyles((theme) => ({
  arrow: {
    color: "#B91717",
  },
  tooltip: {
    backgroundColor: '#FFF',
    color: '#000',
    fontFamily: "Montserrat",
    boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
    padding: "1.6em",
    fontSize: 13,
    maxWidth: 600
  },
}))(Tooltip);

export const RedTooltip = withStyles((theme) => ({
  arrow: {
    color: "#B91717",
  },
  tooltip: {
    backgroundColor: '#B91717',
    color: '#fff',
    fontFamily: "Montserrat",
    boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
    padding: "1.6em",
    fontSize: 16,
    maxWidth: 600,
  },
}))(Tooltip);

export const SimpleTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 16,
    color: '#202020',
  },
}))(Tooltip);