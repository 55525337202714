import { FETCH_CONTENT_LANDING } from '../types';


const initialState = {
  landing: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONTENT_LANDING:
      return { ...state, landing: action.payload }
    default:
      return state;
  }
}

