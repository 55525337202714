import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '120em',
    margin: '0 auto',
    padding: '4.6em 0'
  },

  title: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: "2.4em",
    fontWeight: "bold",
    margin: '7px 0 20px'
  },

  main: {
    width: '100%',
    display: 'flex',
  },

  navBar: {
    width: 118,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  activeNavItem: {
    width: '100%',
    height: 175,
    marginBottom: 6,
    backgroundColor: '#fff',
    borderLeft: 'solid 5px #3064A6',
    borderRadius: "12px 0 0 12px",
    boxShadow: "0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)"
  },

  navItem: {
    width: '100%',
    height: 175,
    marginBottom: 6,
    opacity: '0.66',
    backgroundColor: '#fff',
    borderLeft: 'solid 5px #3064A6',
    borderRadius: "12px 0 0 12px",
    boxShadow: "0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)"
  },

  navIcon: {
    display: 'block',
    width: 60,
    height: 36,
    margin: '37px auto 13px',
  },

  navItemTitle: {
    color: "#3064A6",
    fontFamily: "Montserrat",
    fontSize: "1.6em",
    fontWeight: "500",
    textAlign: 'center',
  },

  mainContent: {
    maxWidth: '110em',
    flexGrow: 1,
    backgroundColor: '#fff',
    minHeight: '85vh',
    borderRadius: "0 12px 12px 12px",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
  },

  banner: {
    width: '100%',
    padding: '23px 40px',
    backgroundColor: "#F9CB7F",
    boxShadow: "0 3px 7px 0 rgba(0,0,0,0.14)",
    display: 'flex',
    alignItems: 'center',
    marginBottom: 30,
    borderRadius: 9,
  },

  redBanner: {
    width: '100%',
    padding: '23px 40px',
    backgroundColor: "#B91717",
    boxShadow: "0 3px 7px 0 rgba(0,0,0,0.14)",
    display: 'flex',
    alignItems: 'center',
    marginBottom: 30,
    borderRadius: 9,
  },

  countBox: {
    border: "solid 2px #585757",
    borderRadius: 9,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 30,
  },

  countText: {
    color: "#0D0D0D",
    fontFamily: "Montserrat",
    fontSize: "2.1em",
    fontWeight: "600",
    padding: 15,
  },

  bannerText: {
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: "1.6em",
    fontWeight: "500",
  },

  whiteColor: {
    color: '#fff'
  },

  whiteBorder: {
    border: "solid 2px #FFF",
  },




  //=================
  // mainContent: {
  //   maxWidth: '110em',
  //   flexGrow: 1,
  //   backgroundColor: '#fff',
  //   minHeight: '85vh',
  //   borderRadius: "0 12px 12px 12px",
  //   boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
  // },
  recentTransactionsContainer: {
    backgroundColor: '#fff',
    borderRadius: 12,
    marginBottom: '4em',
    //paddingTop: '4em'
    maxWidth: '120em',
    flexGrow: 1,
    //borderRadius: "0 12px 12px 12px",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
  },
  headingText: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: "400",
    letterSpacing: "0.63px",
    lineHeight: "29px",

  },
  link: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    letterSpacing: "0.36px",
    lineHeight: "19px",
    cursor: "pointer",
    textDecoration: "underline"
  },







}));

