import Cookies from 'js-cookie';
import { axiosPublic } from '../config';
import { getAppSource, getUserType } from '../helpers';

export const activateUser = async (username) => {
  const data = {
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get("publicIp"),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": username
  }

  try {
    const res = await axiosPublic.post('/OT/ActivateUser', data);
    return res.data
  } catch (e) {
    return null
  }
}