import React from 'react';
import './style.css'

export const SwitchButton = (props) => {
    const {status, handleSwitch, switchName} = props

    const handleChange=()=>{
        if(handleSwitch){
            handleSwitch({fieldName:switchName, fieldValue:!status})
        }
    }

    return (<label className="switchbox switches">
    <input type="checkbox" onChange={()=>handleChange()} checked={props.status} />
    <span className="slideres round sliderText">YES <span className="offButton">NO</span></span>    
  </label>)
}