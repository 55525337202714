import { makeStyles } from '@material-ui/core/styles';
const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },

  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },

  heading: {
    color: '#25ABC7',
    fontFamily: 'Montserrat',
    fontSize: '1.4em',
    fontWeight: '600',
    textAlign: 'center',
    textTransform: 'uppercase'
  },

  link: {
    textDecoration: 'none',
    cursor: 'pointer',
  },

  linkText: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '1.4em',
    fontWeight: '600',
    textTransform: 'uppercase',
    textDecoration: 'none'
  },


  linkContainer: {
    width: '100%',
    height: 50,
    display: 'flex',
    flexDirection: 'row',
    alignItems: "center",
    justifyContent: 'space-between',
    padding: "0px 16px",
    backgroundColor: '#FFF',
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.87)',
  },


  activeLinkText: {
    color: '#FFF',
  },

  activeLink: {
    backgroundColor: '#3064A6',
  },

  subMenuContainer: {
    width: '100%',
    backgroundColor: '#fff',
    padding: '15px',
    boxShadow: "0 3px 6px 0 rgba(0,35,64,0.2)",
    marginBottom: 10,
  },

  subMenu: {
    maxWidth: '90%',
    margin: '0 auto',
  },

  subMenuLink: {
    display: 'block',
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "600",
    padding: '10px 0',
    textDecoration: 'none'
  },

  appBar: {
    width: "100%",
    height: 60,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 15px',
  },

  logoStyle: {
    width: 153,
    height: '100%',
    margin: '9px 0',
  },
  icon: {
    marginLeft: '-17px',
    height: '24px',
    width: '30px' , 
    position: 'absolute',
    marginBottom: '28px'
  },
  badge: {
    fontSize: 11,
    fontWeight: 400,
    marginLeft: '-17px',
    marginTop: '-30px',
    height: '18px',
    width: '30px' , 
    position: 'absolute',
    padding: '1px 5px',
    borderRadius: 20,
    color: 'white'
  },
  menuBadge: {
    fontSize: 13,
    fontWeight: 600,
    marginLeft: '240px',
    marginTop: '-19px',
    height: '20px',
    width: '40px' , 
    position: 'absolute',
    padding: '0px 5px',
    borderRadius: 20,
    color: 'white'
  },
}));