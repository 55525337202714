import React from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './styles';
import './style.css';

export const FaqRender = ( props) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
  
      <div dangerouslySetInnerHTML={{ __html: props.innerHtml }} className={classes.Margin0} />
    </Box>
  )
}