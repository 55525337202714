import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import { decrypt } from '../../helpers/encrypt';


export const AdditionalPaymentMultiAlert = (props) => {
  console.log(props)
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const { lowPrincipalBalanceData } = props
  const handleClick = (loan) => {history.push(`/PayoffRequest?loan=${loan}`)} 
  
  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle }}} onClose={props.handleClose} open={true}>
     
      <Box className={classes.root}>
          
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>Important Notice Regarding Your Loan</Typography>
        </Box>
        
        <Box className={classes.cardBody2}>
          <Box className={classes.statusContainer}>
           <Box className={classes.cardContainer}>
            <Box className={classes.imageContainer}>
             <Box className={classes.mainContainer}>
             <InfoIcon className={classes.infoIcon} />
               <Typography className={classes.paragraph}>We see that one or more of your loan accounts is within <b>$5,000</b> of your unpaid principal balance.</Typography>
              </Box>
              </Box>
            </Box> 
          </Box>
        </Box>

        <Box className={classes.cardBody2}>
          <Box className={classes.statusContainer}>
           <Box className={classes.cardContainerRequestPayoff}>
           {lowPrincipalBalanceData.length > 0 && lowPrincipalBalanceData?.map((item, index) => { 
            if (item.status) {
              return(
            <Box className={classes.container} style={{backgroundColor: ((index % 2) == 0)  ? "#F5F5F5" : "#FFFFF"}}>
            <Typography className={classes.loan}>{decrypt(item.accNumber)}</Typography>
            <Typography className={classes.requestPayoff} onClick={() => handleClick(item.accNumber)}>Request Payoff</Typography>
            </Box>) }
            })}
            </Box> 
          </Box>
        </Box>

        <Box className={classes.cardFooter}>
          <Box className={classes.footerItem}>
              <Button
                variant='contained'
                color='primary'
                className={classes.btn}
                onClick={props.handleCloseAlert}
              >Skip & Continue Payment</Button>
          </Box> 
        </Box>
       
      </Box> 
    </Dialog>
  )
}

