import { makeStyles, withStyles } from '@material-ui/core/styles';

import { TextField, OutlinedInput, InputLabel } from '@material-ui/core';


export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 12,
    backgroundColor: "#ffffff",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    padding: '1em 2.5em 3em 2.5em'
  },

  heading: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: '24px',
    fontWeight: "600",
    letterSpacing: "0.63px",
    lineHeight: "29px",
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  dialogStyle: {
    borderRadius: '12px !important',
    width: "80%"
  },
  cancelBtnBox: {
    float: "right",
  },

  saveBtn: {
    width: 192,
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "600",
    letterSpacing: "0.53px",
    lineHeight: "19px",
    textAlign: "center",
    height: '37px',
    margin: '0 auto',
    display: 'block'
  },
  cancelBtn: {
    width: 192,
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "600",
    letterSpacing: "0.53px",
    lineHeight: "19px",
    textAlign: "center",
    border: "2px solid"
  },
  inputContainer: {
    marginBottom: '2.5em',
  },
  inputContainerWithText: {
    marginBottom: '2em',
  },
  inputContainerLeft: {
    marginBottom: '3.9em',
    marginRight: '3.5em'
  },
  inputContainerLeftWithText: {
    marginBottom: '2em',
    marginRight: '3.5em'
  },

  inputContainerRight: {
    marginBottom: '3.9em',
    marginLeft: '3.5em'
  },

  inputStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600"
  },

  labelStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "400",

  },
  suggestionText: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "300",
    letterSpacing: "0.31px",
    lineHeight: "18px",
    paddingLeft: "24px"
  },
  errorText: {
    color: "red",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "600",
    letterSpacing: "0.31px",
    lineHeight: "18px",
  },
  pl35: {
    paddingLeft: "35px",
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      width: '100%'
    },
  },
  pr35: {
    paddingRight: "35px",
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      width: '100%'
    },
  },
  fullWidth: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: '2.5em',
      paddingRight: 0,
      width: '100%'
    },
  },
  mbPhone0: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
    },
  },
  zipCode: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      paddingLeft: 0,
      width: '100%'
    },
  },
  pL0: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      paddingLeft: 0,
      width: '100%'
    },
  },
  closeBtn: {
    fontsize: "24px",
    cursor: "pointer",
  },
  paperScrollPaper: { maxHeight: "100%" },
  infoStrip: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 14px 7px, rgba(0, 0, 0, 0.2) 0px 1px 3px 0px',
    margin: '8px 0',
    width: '100%',
    padding: '3px 6em 3px 7em',
    borderRadius: '12px',
    [theme.breakpoints.up('sm')]: {
      height: '42px',
    },
  },
  infoIcon: {
    paddingRight: "10px",
    fontSize: '35px',
    color: theme.palette.secondary.contrastText,
  },
  infoText: {
    color: theme.palette.secondary.contrastText,
    fontFamily: "Montserrat",
    fontSize: "16px",
    letterSpacing: "0.36px",
    lineHeight: "24px",
  },
  successMessage: {
    color: "green",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "600",
    letterSpacing: "0.31px",
    lineHeight: "18px",    
  },
  loanDetail:{
    padding:'20px 40px 30px',
    fontSize:'15px',
    color:'#535D6D',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    textAlign:'justify'
  },
  appBar: {
    width: "100%",
    backgroundColor: "#1A4664",
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 14px 7px, rgba(0, 0, 0, 0.2) 0px 1px 3px 0px',
    color: theme.header.fontColor,
    padding: '0px 16px',
    borderRadius: '4px',
    margin: '8px 0'
  },
  infoIcon: {
    paddingRight: "10px",
    fontSize: '35px',
    color: theme.palette.secondary.contrastText,
  },
  infoText: {
    color: theme.palette.secondary.contrastText,
    fontFamily: "Montserrat",
    fontSize: "16px",
    letterSpacing: "0.36px",
    lineHeight: "19px",
  },
  infoLink: {
    color: theme.palette.secondary.contrastText,
    fontFamily: "Montserrat",
    fontSize: "16px",
    letterSpacing: "0.36px",
    lineHeight: "19px",
    fontWeight:'700',
    marginLeft:'10px',
    textDecoration:'underline',
    cursor:'pointer'
  },
  formControl: {
    '& .MuiSelect-select': {
      opacity:'0.5'
    },
    '& .MuiFormLabel-root': {
      opacity:'0.5'
    }
  },
  customerCareEmail: {
    color: '#007FFF',
    cursor: "pointer",
    textDecoration: 'underline'
  },
}));

export const CutomTextField = withStyles((theme) => ({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& .Mui-disabled':{
      opacity:'0.5'
    }
  },
}))(TextField);

export const CutomOutlinedInput = withStyles((theme) => ({
  root: {
    "&$focused $notchedOutline": {
      border: `2px solid ${theme.palette.primary.main}`,
    }
  },
  focused: {},
  notchedOutline: {}
}))(OutlinedInput);

export const CustomInputLabel = withStyles((theme) => ({
  root: {
    "&$focused": {
      color: theme.palette.primary.main
    }
  },
  focused: {},
}))(InputLabel);

