
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => (
  {
    root: {
      width: '100%',
      maxWidth: '120em',
      margin: '0 auto',
      padding: '1.5em 1.6em 4em'
    },

    container: {
      width: '100%',
      minWidth: "33.3em"
    },

    formContainer: {
      minHeight: "300px",
      backgroundColor: '#fff',
      borderRadius: 12,
      boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    instructionsContainer: {
      padding: "2.3em 4em 0 4em",
      [theme.breakpoints.down('sm')]: {
        padding: "1.6em 1.6em 0 1.6em"
      },
    },

    instText: {
      paddingLeft: 0,
      paddingTop: 20,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: "0"
      },
    },

    text: {
      color: "#4D4D4D",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "700",
      letterSpacing: "0.5px",
      lineHeight: "20px"
    },
    straightForward: {
      color: "#4D4D4D",
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "500",
      letterSpacing: "0.5px",
      lineHeight: "20px"
    },
    formInputContainer: {
    },
    inputContainer: {
      marginBottom: '3.9em',
      width: "75%",
      [theme.breakpoints.down('sm')]: {
        width: "100%"
      },
    },

    linkStyle: {
      color: '#1A4664',
      fontWeight: '700',
      textDecoration: 'underline',
      cursor: "pointer"
    },
    
    footerText: {
      color: '#4D4D4D',
      fontWeight: '700',
      // textDecoration: 'underline'
    },
    actionText: {
      color: "#000000",
      fontFamily: "Montserrat",
      fontSize: "16px",
      letterSpacing: "0.31px",
      lineHeight: "18px",
      fontWeight: "600"
    },
    payoffQuote: {
      color: "#000000",
      fontFamily: "Montserrat",
      fontSize: "16px",
      letterSpacing: "0.31px",
      lineHeight: "18px",
      fontWeight: "600",
      [theme.breakpoints.down('sm')]: {
        lineHeight: "20px",
        fontWeight: "500"
      },
    },
    paperless: {
      color: "#1A4664",
      fontFamily: "Montserrat",
      fontSize: "22px",
      letterSpacing: "0.31px",
      lineHeight: "18px",
      fontWeight: "700",
      [theme.breakpoints.down('sm')]: {
        lineHeight: "20px",
        fontWeight: "600",
        fontSize: "16px"
      }
    },
    congrats: {
      color: "#000000",
      fontFamily: "Montserrat",
      fontSize: "24px",
      letterSpacing: "0.31px",
      lineHeight: "18px",
      fontWeight: "600",
      [theme.breakpoints.down('sm')]: {
        lineHeight: "20px",
        fontWeight: "500",
        fontSize: "16px"
      }
    },

    activeHrs: {
      color: "#121D22",
      fontFamily: "Montserrat",
      fontSize: "16px",
      letterSpacing: "0.31px",
      lineHeight: "18px",
      fontWeight: "500"
    },

    primaryColor: {
      backgroundColor: `${theme.primary}!important`,
    },

    divider: {
      padding: "0 15px"
    },

    underline: {
      textDecoration: 'none',
      color: '#535D6D',
      fontWeight: '600',
      fontSize: "16px",
      fontFamily: "Montserrat",
      letterSpacing: '0.6px',
      lineHeight: '19px',
    },

    mt10: {
      marginTop: '13px'
    },

    mbPhone: {
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '2em',
      },
    },

    paperlessStatement: {
      boxSizing: "border-box",
      border: "1px solid #D9D9D9",
      backgroundColor: "#F4F4F4",
      marginTop: "1em",
      paddingLeft: "1.2em",
      paddingRight: ".6em",
      paddingTop: "1.3em",
      borderRadius: '8px',
      marginBottom: '25px',
      [theme.breakpoints.down('sm')]: {
        height: "150px",
        paddingTop: "0px"
      }
    },

    infoText: {
      color: "#121D22",
      fontFamily: "Montserrat",
      fontSize: '14px',
      fontWeight: "400",
    },

    checkIcon: {
      width: 56,
      height: 56
    },

    goGreen: {
      width: 116,
      height: 146
    },

    line: {
      border: '1px solid #D9D9D9'
    },
    
    checkImg: {
      width: 22,
      height: 22
    },

    infoTopIcon: {
      color: "#007FFF",
      width: 24,
      height: 24,
      marginRight: 10,
      marginLeft: 15
    },
 
    infoIcon: {
      color: "#E02020",
      width: 20,
      height: 20,
      marginRight: 10,
    },

    infoBox: {
      width: '100%',
      alignItems: 'center',
      backgroundColor: '#FFF0F0',
      borderRadius: 4,
      padding: '0.5em',
      borderRadius: '4px',
      border: "1px solid #F85757",
      // marginLeft: 8
    },
  
    infoTopBox: {
      width: '100%',
      alignItems: 'center',
      backgroundColor: '#F4F4F4',
      borderRadius: 8,
      padding: '0.5em',
      borderRadius: '4px',
      border: "1px solid #D9D9D9",
      marginBottom: 20,
      marginTop: 5
    }
  }
));
