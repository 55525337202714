 //Generic toaster implementation

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    snackBarError: {
    '& .MuiSnackbarContent-root': {
      background: 'red',
      fontFamily: "Montserrat",
    }
  },
  snackBarSuccess: {
    '& .MuiSnackbarContent-root': {
      backgroundColor:'#235A86',
      fontFamily: "Montserrat",
      maxWidth: "650px"
    }
  },
  close: {
    padding: theme.spacing(0.5),
  },
}));