import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => (
  {
    root: {
      width: '100%',
      minHeight: '100vh',
      backgroundColor: '#ccc',
      display: 'flex',
      justifyContent: 'center',
      backgroundPosition: "bottom left",
      backgroundRepeat: "no-repeat",
      backgroundSize: 'contain',
    },
    infoStrip: {
      backgroundColor: '#EEE',
      margin: '8px',
      width: '100%',
      margin: '19px 0px 19px 0px',
      border: '1px solid #EEE',
      borderRadius: '10px',
      padding: '10px 20px',
      [theme.breakpoints.up('sm')]: {
        // height: '229px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '5px 20px',
      },
    },
    infoBox: {
      backgroundColor: '#7EB1CC',
      margin: '8px',
      width: '100%',
      margin: '19px 0px 19px 0px',
      border: '1px solid #B4B4B4',
      borderRadius: '10px'
    },
    information: {
      color: '#ffffff',
      fontFamily: "Inter",
      fontSize: "16px",
      letterSpacing: "0.36px",
      lineHeight: "19px",
      padding: '10px 0px 5px 20px'
    },
    image: {
      marginLeft: '300px',
      marginTop: '-230px',
      marginBottom: '20px',
      height: '65px',
      [theme.breakpoints.down('sm')]: {
        height: '0px'
      },
    },
    errorMsg: {
      fontSize: '1.4em',
      fontWeight: 'bold',
      textAlign: 'justify',
      marginBottom: 10,
      fontFamily: 'Inter',
      color: "red",
      marginTop: 30
    },
    nextEscrow: {
      padding: '0px 40px',
      [theme.breakpoints.down('sm')]: {
        padding: '0px 15px'
      },
    },
    escrowSection: {
      padding: '35px 40px 10px 42px',
      [theme.breakpoints.down('sm')]: {
        padding: '30px 40px 10px 15px',
      },
    },
    escrowHeading: {
      color: '#EDB36A',
      fontFamily: "Inter",
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: '600'
    },
    loader: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    escrowDesc: {
      color: '#EDB36A',
      fontFamily: "Inter",
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: '600',
      paddingBottom: '38px',
      paddingTop: '38px'
    },
    escrowParagraph: {
      color: '#979797',
      fontFamily: "Montserrat",
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: '400'
    },
    boxText: {
      padding: '10px 0px 2px 2px',
      fontSize: '16px',
      fontWeight: '500',
      color: '#979797',
      lineHeight: '32px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
      }
    },
    infoText: {
      color: '#474747',
      fontFamily: "Montserrat",
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: '400',
      padding: '5px 20px 20px 16px'
    },

    infoHeading: {
      color: '#626262',
      fontFamily: "Montserrat",
      fontSize: "18px",
      lineHeight: "22px",
      fontWeight: '700',
      padding: '26px 20px 5px 16px'
    },
    container: {
      width: '120em',
      minWidth: "33.3em",
      marginTop: '3.6em',
      marginBottom: '6.6em',
      [theme.breakpoints.down('sm')]: {
        margin: '42px 16px',
      },
    },
    pageHeading: {
      color: '#1A4664',
      fontFamily: 'Montserrat',
      fontSize: '22px',
      fontWeight: '700',
      letterSpacing: '0.18px',
      lineHeight: '24px',
      marginBottom: 25,
      marginTop: 24
    },
    formContainer: {
      // minHeight: "144px",
      backgroundColor: '#fff',
      borderRadius: 4,
      marginTop: 25,
      border: '1px solid #CCC',
      padding: '16px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    appBar: {
      width: "100%",
      backgroundColor: theme.palette.secondary.main,
      color: theme.header.fontColor,
      padding: '0px 16px',
      borderRadius: '4px'
    },

    containerPadding: {
      padding: "1px 0px"
    },

    linkStyle: {
      color: "inherit",
      textDecoration: "none"
    },
    activeTab: {
      borderTopLeftRadius: "12px",
      borderTopRightRadius: "12px",
      backgroundColor: "#ffffff",
      color: "#fafafa",
      // fontWeight: "600",
      borderBottom: 'none',
      '& .linkStyle': {
        color: "#727272",

      },
    },
    labelText: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "16px",
      letterSpacing: "0.36px",
      lineHeight: "19px",
    },
    labelTextBold: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "600",
      letterSpacing: "0.36px",
      lineHeight: "19px",
      marginTop: '7px',
      marginBottom: '7px',
      paddingLeft: '56px'
    },
    labelTextBoldMobile: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "600",
      letterSpacing: "0.36px",
      lineHeight: "19px",
      marginTop: '7px',
      marginBottom: '7px',
      paddingLeft: '49px'
    },
    icon: {
      float: "left",
      marginRight: "30px"
    },
    profileBtn: {
      width: "192px",
      fontSize: "16px",
      fontFamily: "Montserrat",
      fontWeight: "600",
      letterSpacing: "0.53px",
      lineHeight: "19px",
      textAlign: "center",
      height: '40px'
    },
    closeBtn: {
      height: "20px",
      width: "20px",
      backgroundColor: "#ffffff",
      color: "#3064A6",
      borderRadius: "50%",
      display: "inline-block",
      textAlign: "center",
      fontSize: "14px",
      fontWeight: 600,
      cursor: "pointer"
    },

    pl60: {
      paddingLeft: "60px"
    },
    infoIcon: {
      padding: "6px 6px",
      fontSize: '35px',
      color: theme.palette.secondary.contrastText,
    },
    infolink: {
      fontWeight: 600,
      cursor: "pointer",
      textDecoration: "underline"
    },
   
    profileMargin: {
      marginBottom: '26px',
      paddingRight: '20px'
    },

    labelTextMobile: {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '60px'
      },
    },

    pl14: {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '14px'
      },
    },

    pt0: {
      [theme.breakpoints.down('xs')]: {
        paddingTop: '0'
      },
    },

    noteText: {
      fontFamily: "Montserrat",
      fontSize: "14px",
      color: 'rgba(0, 0, 0, 0.74)',
      fontWeight: '500',
    },

    wordBreak: {
      wordBreak: 'break-all'
    },

    errorIcon: {
      // fontSize: "25px",
      float: "left",
      width: '18px',
      height: '18px',
      marginRight: "3px",
      // paddingLeft: '56px'
    },
 
    emailContainer: {
      paddingLeft: 30
    },
    verifyText: {
      backgroundColor: '#7EB1CC',
      color: 'white',
      fontFamily: "Montserrat",
      fontSize: "13px",
      fontWeight: "600",
      letterSpacing: "0.36px",
      // lineHeight: "13px",
      border: 'none',
      cursor: "pointer",

      paddingLeft: '13px',
      paddingRight: '13px',
      borderRadius: '4px',
      paddingTop: '5px',
      paddingBottom: '5px',
      // padding: '0.5px 1px !important'
      // textDecoration: 'underline'
    },
    documentNameSelect: {
      borderRadius: '80px 0px 0px 80px',
      marginTop: 0,
      width: '200px',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      },
    },
    searchBox: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center'
      },
    },
    requestBtnBox: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center'
      },
    },
    groupByBox: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        display: '-webkit-box'
      },
    },
    groupByRadio: {
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '3px',
        color: '#2B2D30',
        '& .MuiFormControlLabel-root': {
          marginRight: '1px',
          marginLeft: '-8px'
        },
      },
    },
    radioBtnName: {
      color: '#2B2D30',
      fontSize: 12,
      fontStyle: 'normal',
      fontFamily: 'Montserrat',
      fontWeight: 600
    },
    requestBtn: {
      borderRadius: '4px',
      background: '#EDB36A',
      width: '259px',
      textAlign: 'center',
      fontSize: '14px',
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal',
      textTransform: 'uppercase'
    },
    searchLabel: {
      color: '#2B2D30',
      fontSize: '14px',
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal',
      marginTop: '11px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px'
      },
    },
    documentRequestSection: {
      textAlign: "center"
    },
    textareaSection: {
      paddingTop: '16px',
      '& .MuiFormLabel-root': {
        asterisk: {
          color: 'red',
          '&$error': {
            color: 'red'
          },
        }
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: '15px'
      },
    },
    textArea: {
      color: "#535D6D",
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: "bold",
      width: '100%',
      padding: "10px",
      height: '56px',
      borderRadius: '4px',
      border: '1px solid #9E9E9E'
    },
   
    asteriskReplacement: {
      content: '"asterisk"',
      position: 'absolute',
      marginLeft: '-30px', 
      color: 'red', 
    },
    inputStyle: {
      color: "#535D6D",
      fontFamily: "Montserrat",
      fontSize: 16,
      fontWeight: 600,
      '& .MuiFormLabel-root': {
        asterisk: {
          color: 'red',
          '&$error': {
            color: '#db3131'
          },
        }
      },
    },
    requestContainer: {
      padding: "3em 28em 3em 28em",
      [theme.breakpoints.down('sm')]: {
        padding: "1.6em 1.6em 0 1.6em"
      },
    },
    searchIcon: {
      borderRadius: '80px',
      marginRight: '-17px',
      border: '1px solid #EDB36A',
      padding: '5px 18px',
      backgroundColor: '#EDB36A',
      "&:hover": {
        backgroundColor: "#EDB36A"
      },
      [theme.breakpoints.down('sm')]: {
        padding: '5px 8px',
        marginRight: '-18px'
      },
    },
    searchIconDisable: {
      borderRadius: '80px',
      marginRight: '-21px',
      border: '1px solid #949494',
      padding: '9px 18px',
      backgroundColor: '#949494',
      pointerEvents: "none",
      "&:hover": {
        backgroundColor: "#949494"
      },
      [theme.breakpoints.down('sm')]: {
        padding: '9px 10px',
      },
    },
    searchField: {
      borderRadius: '80px',
      background: '#ffffff',
      marginLeft: '-2px',
      height: '45px',
      [theme.breakpoints.down('sm')]: {
        borderRadius: '80px'
      }
    },
    searchTextBox: {
      '& .MuiOutlinedInput-input': { 
        padding: '18.5px 20px',
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        lineHeight: 'normal',
        fontStyle: 'normal'
      },
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: '25px'
      },
    },
    radioBtnDesc: {
      color: '#000000',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      letterSpacing: '0.44px',
      lineHeight: '24px',
      fontWeight: 600,
      paddingTop: '28px'
    },
    textAreaValidation: {
      color: '#202020',
      float: 'right'
    },
    formTitle: {
      fontFamily: "Montserrat",
      fontSize: '16px',
      fontWeight: "600",
      letterSpacing: "0.53px",
      height: '20px',
      color: '#000',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        fontSize: '14px',
        marginBottom: '60px',
        letterSpacing: '0.10px'
      },
    },
    saveBtn: {
      float: "right",
      width: 195,
      fontFamily: "Montserrat",
      fontSize: '14px',
      fontWeight: "600",
      letterSpacing: "0.53px",
      lineHeight: "17px",
      textAlign: "center",
      height: '40px',
      marginLeft: 12,
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
        fontSize: 12,
        marginTop: 6
      },
    },
    cancelBtn: {
      fontFamily: "Montserrat",
      fontSize: '14px',
      fontWeight: "600",
      letterSpacing: "0.53px",
      lineHeight: "17px",
      textAlign: "center",
      height: '40px',
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
        fontSize: '12px'
      },
    },
    requestNewDoc: {
      width: 300,
      fontFamily: "Montserrat",
      fontSize: '16px',
      paddingTop: 20,
      marginLeft: 20,
      fontWeight: "700",
      letterSpacing: "0.53px",
      lineHeight: "19px",
      height: '40px',
      color: '#202020',
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px'
      },
    },
    disableRequestFlow: {
      borderRadius: '4px',
      border: '1px solid #EDB342',
      background: '#FFF2E1',
      padding: '10px',
      textAlign: 'center',
      margin: '10px auto',
      width: '95%',
      fontSize: '10px',
      '& .icon':{
        fill: '#EDB36A',
        marginBottom: '-6px',
        marginRight: '5px',
        height: '20px'
      },
      '& .text': {
        color: '#4D4D4D',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 'normal'
      }
    },
    documentHeadDisable: {
      color: '#555D6C',
      borderRadius: '4px',
      border: '1px solid #9E9E9E',
      background: '#BBB',
      cursor: "default",
      textAlign: 'center',
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal',
      textTransform: 'uppercase'
    },
    documentHead2: {
      color: '#202020',
      fontFamily: 'Montserrat',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 'normal',
      paddingTop: '10px'
    },
    title: {
      color: '#202020',
      fontFamily: 'Montserrat',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },
    description: {
      color: '#4D4D4D',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal',
      paddingTop: '20px',
      paddingBottom: '20px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },
    formText: {
      marginTop: 10
    },
    formDescription: {
      // color: '#4D4D4D',
      // fontFamily: 'Montserrat',
      // fontSize: '16px',
      // fontStyle: 'normal',
      // fontWeight: '600',
      // lineHeight: 'normal',
      // paddingTop: '16px',
      // [theme.breakpoints.down('sm')]: {
      //   fontSize: '14px',
      // },
    },
    customerCareEmail: {
      color: '#335771',
      cursor: "pointer",
      textDecoration: 'underline'
    },
    image: {
      height: '16px',
      width: '16px',
      cursor: 'pointer'
    },
    noRecordFoundContainer: {
      textAlign: 'center',
      marginTop: 110,
      marginBottom: 150
    },
    noRequestData: {
      color: '#555D6C',
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 'normal',
      paddingTop: '10px'
    },
    errorMsg: {
      fontSize: '1.4em',
      fontWeight: 'bold',
      textAlign: 'justify',
      marginBottom: 10,
      fontFamily: 'Inter',
      color: "red",
      marginTop: 30
    },
    downloadForm: {
      display: 'flex',
      float: 'right',
      [theme.breakpoints.down('sm')]: {
        display: 'block'
      },
    }
  }));
