import React, { Fragment, useEffect, useState } from 'react';
import { Box, Breadcrumbs, StepLabel, Stepper, Step, StepContent, Button, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import clsx from "clsx";
import { Link } from 'react-router-dom';
import { useStyles } from './styles';
import { SectionA } from './SectionA';
import { SectionB } from './SectionB';
import { SectionC } from './SectionC';
import { SectionD } from './SectionD';
import { SectionE } from './SectionE';
import { SectionF } from './SectionF';
import { SectionG } from './SectionG';
import { SectionH } from './SectionH';
import { SectionI } from './SectionI';
import { SectionComplete } from './SectionComplete'


import { getLossMitigationData, saveLossMitigationData } from '../../actions';
import Cookies from 'js-cookie';
import { SaveAndExitPopup } from './SaveAndExitPopup';
import { maskAccount, scrollTop, getAppSource, getUserType } from '../../helpers';

import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CachedIcon from '@material-ui/icons/Cached';
import AdjustIcon from '@material-ui/icons/Adjust';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { decrypt } from '../../helpers/encrypt';

const LightTooltip = withStyles((theme) => ({
  arrow: {
    "&:before": {
      boxShadow: "0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)",
    },
    color: "#FFFFFF",
  },
  tooltip: {
    marginTop: 0,
    color: '#202020',
    fontSize: 16,
    maxWidth: 'none',
    padding: 10,
    fontFamily: "Montserrat",
    letterSpacing: "0.36px",
    linHeight: "19px",
    borderRadius: "12px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)",
  },
}))(Tooltip);

const stepperData = [
  { id: 0, label: 'Section A', tooltipTitle: 'Borrower Information' },
  { id: 1, label: 'Section B', tooltipTitle: 'Required Documentation/ Hardship Affidavit' },
  { id: 2, label: 'Section C', tooltipTitle: 'Additional Liens / Mortgages or Judgements' },
  { id: 3, label: 'Section D', tooltipTitle: 'Bankruptcy' },
  { id: 4, label: 'Section E', tooltipTitle: 'Income/Expenses For Household' },
  { id: 5, label: 'Section F', tooltipTitle: 'Property' },
  { id: 6, label: 'Section G', tooltipTitle: 'OtherProperty For Which Assistance Is Requested' },
  { id: 7, label: 'Section H', tooltipTitle: 'Information For Government Monitoring Purposes' },
  { id: 8, label: 'Section I', tooltipTitle: 'Acknowledgment And Agreement' },
];

const data = {
  "sections": {
    "section_A": {
      "borrower": {
        "name": "ABC",
        "dob": "10/11/2020",
        "ssn": "123",
        "cellNumber": "123456789",
        "phoneNumber": "1234567",
        "emailId": 'abc@gmail.com'
      },
      "coBorrower": {
        "name": "ABC",
        "dob": "10/11/2020",
        "ssn": "123",
        "cellNumber": "123456789",
        "phoneNumber": "1234567",
        "emailId": 'abc@gmail.com'
      },
      "questions": {
        "question1": {
          "questionText": "Is any borrower a service man?",
          "answer": "No"
        },
        "question2": {
          "questionText": "Have you recently been deployed away from your principal residence or recently received a Permanent Change of Station (PCS) order?",
          "answer": "Yes"
        },
        "question3": {
          "questionText": "Do you intend to occupy this as your primary residence sometimes in the future?",
          "answer": "Yes"
        },
        "question4": {
          "questionText": "Is any borrower the surviving spouse of a deceased service member who was on active duty at the time of death?",
          "answer": "Yes"
        },
        "question5": {
          "questionText": "I want to:",
          "answer": "Be reviewed for all mortgage assistance options"
        },
        "question6": {
          "questionText": "The property is my:",
          "answer": "Primary Residence"
        },
        "question7": {
          "questionText": "The property is:",
          "answer": "Other",
          "otherText": "abc"
        },
        "question8": {
          "questionText": `Have you previously requested mortgage payment assistance through BSI ?`,
          "answer": "Primary Residence"
        },
        "question9": {
          "questionText": `How many single family properties other than your principal residence do you and/or any co-borrower(s) own individually, jointly, or with others ?`,
          "answer": "", //"inputBox"
        },
        "question10": {
          "questionText": `Is the mortgage on your principal residencecurrent?`,
          "answer": "Yes",
        },
        "question11": {
          "questionText": `If "No", number of months your payment is pastdue(if known)`,
          "answer": "", // input Box
        },
        "question12": {
          "questionText": `Number of People in Household`,
          "answer": "", // input Box          
        },
        "question13": {
          "questionText": `Mailing Address`,
          "answer": "", // input Box 
        },
        "question14": {
          "questionText": `Property Address(if same as mailing address, just write “same”):`,
          "answer": "", // input Box          
        },


        "question15": {
          "questionText": `Is the property listed for sale?`,
          "answer": "Yes",
        },
        "question16": {
          "questionText": `If yes, what was the listing date?`,
          "answer": "", // input Box          
        },
        "question17": {
          "questionText": `Have you received an offer on the property?`,
          "answer": "No",
        },
        "question18": {
          "questionText": `Date of Offer`,
          "answer": "", // input Box          
        },
        "question19": {
          "questionText": `Amount of Offer`,
          "answer": "", // input Box          
        },
        "question20": {
          "questionText": `Closing Date`,
          "answer": "", // input Box          
        },
        "question21": {
          "questionText": `Agent’s Name`,
          "answer": "", // input Box          
        },
        "question22": {
          "questionText": `Agent’s Phone Number`,
          "answer": "", // input Box          
        },
        "question23": {
          "questionText": `Have you contacted a credit counseling agency for help?`,
          "answer": "Yes",
        },
        "question24": {
          "questionText": `Counselor's Name`,
          "answer": "", // input Box          
        },
        "question25": {
          "questionText": `Agency Name`,
          "answer": "", // input Box          
        },
        "question26": {
          "questionText": `Counselor's Phone Number`,
          "answer": "", // input Box          
        },
        "question27": {
          "questionText": `Counselor's Email`,
          "answer": "", // input Box          
        },
        "question28": {
          "questionText": `Who pays the real estate tax bill on your property?`,
          "answer": "",
        },
        "question29": {
          "questionText": `Are the taxes current?`,
          "answer": "",
        },
        "question30": {
          "questionText": `Condominium or HOA Fees?`,
          "answer": "",
        },
        "question31": {
          "questionText": `Are the fees paid current?`,
          "answer": "",
        },
        "question32": {
          "questionText": `Name and address that fees are paid to`,
          "answer": "", // input Box          
        },
        "question33": {
          "questionText": `Who pays the insurance premiums for your property?`,
          "answer": "Yes",
        },
        "question34": {
          "questionText": `Is the policy current?`,
          "answer": "No",
        },
        "question35": {
          "questionText": `Name(s) of Insurance Company`,
          "answer": "", // input Box          
        },
        "question36": {
          "questionText": `Insurance Company Phone Number(s)`,
          "answer": "", // input Box          
        },


      }
    },
  }
}

export const LossMitigation = (props) => {

  const { accountNumber } = props

  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [showSections, setShowSections] = useState(true)

  const handleNext = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    setActiveStep((activeStep) => activeStep + 1);
    scrollTop()
  };

  const handlePrev = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = false;
    setCompleted(newCompleted);
    setActiveStep((activeStep) => activeStep - 1);
    scrollTop()
  };

  const handleStep = (step) => {
    setActiveStep(step);
    if (showSections === false) {
      setShowSections(true)
    }
  };

  const renderSection = () => {
    switch (activeStep) {
      case 0:
        return <SectionA next={handleNext} previous={handlePrev} activeSection={activeStep}
          data={allSectionsLMData.section_A}
          saveFromParent={saveLossMitiData}
          status={handleSectionStatus}
          parentStyle={classes} />
      case 1:
        return <SectionB
          next={handleNext}
          previous={handlePrev}
          activeSection={activeStep}
          data={allSectionsLMData.section_B}
          saveFromParent={saveLossMitiData}
          status={handleSectionStatus}
          parentStyle={classes} />
      case 2:
        return <SectionC
          next={handleNext}
          previous={handlePrev}
          activeSection={activeStep}
          data={allSectionsLMData.section_C}
          saveFromParent={saveLossMitiData}
          status={handleSectionStatus}
          parentStyle={classes} />
      case 3:
        return <SectionD
          next={handleNext}
          previous={handlePrev}
          activeSection={activeStep}
          data={allSectionsLMData.section_D}
          saveFromParent={saveLossMitiData}
          status={handleSectionStatus}
          parentStyle={classes} />
      case 4:
        return <SectionE
          next={handleNext}
          previous={handlePrev}
          activeSection={activeStep}
          data={allSectionsLMData.section_E}
          saveFromParent={saveLossMitiData}
          status={handleSectionStatus}
          parentStyle={classes} />
      case 5:
        return <SectionF
          next={handleNext}
          previous={handlePrev}
          activeSection={activeStep}
          data={allSectionsLMData.section_F}
          saveFromParent={saveLossMitiData}
          status={handleSectionStatus}
          parentStyle={classes} />
      case 6:
        return <SectionG
          next={handleNext}
          previous={handlePrev}
          activeSection={activeStep}
          data={allSectionsLMData.section_G}
          saveFromParent={saveLossMitiData}
          status={handleSectionStatus}
          parentStyle={classes} />
      case 7:
        return <SectionH
          next={handleNext}
          previous={handlePrev}
          activeSection={activeStep}
          data={allSectionsLMData.section_H}
          saveFromParent={saveLossMitiData}
          status={handleSectionStatus}
          parentStyle={classes} />
      case 8:
        return <SectionI
          next={handleNext}
          previous={handlePrev}
          activeSection={activeStep}
          data={allSectionsLMData.section_I}
          saveFromParent={saveLossMitiData}
          status={handleSectionStatus}
          parentStyle={classes}
          allSectionStatus={sectionStepperData} />
      default:
        return <SectionA next={handleNext}
          previous={handlePrev} activeSection={activeStep}
          data={allSectionsLMData.section_A}
          saveFromParent={saveLossMitiData}
          status={handleSectionStatus}
          parentStyle={classes} />
    }
  }

  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [allSectionsLMData, setAllSectionsLMData] = useState('')
  const [isSaveProcess, setIsSaveProcess] = useState(false)
  const [isProcessLM, setIsProcessLM] = useState(false)

  useEffect(() => {
    getLMData()
  }, []);


  const getLMData = async () => {
    const requestData = {
      "accountNumber": accountNumber ? accountNumber : "W3gd+B4rY/gwvA+9iHtiYg==",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get("publicIp"),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }
    setErrorMessage('')
    setSuccessMessage('')
    setIsProcessLM(true)
    const result = await getLossMitigationData(requestData)
    setIsProcessLM(false)
    if (result) {

      const { lstResponseStatus, lossMitigation } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        let allSectionData = JSON.parse(lossMitigation.lossMitigationData)
        console.log("=====allSectionData=====", allSectionData);
        setIsSubmitted(lossMitigation.isSubmit)
        if (lossMitigation.isSubmit === true) {
          setShowSections(false)
        }
        let newSSD = sectionStepperData.map(row => {
          if (allSectionData.Sections[row.key] && allSectionData.Sections[row.key].sectionStatus) {
            let sStatus = allSectionData.Sections[row.key].sectionStatus.status
            row.status = sStatus
          }
          return row
        })
        setSectionStepperData(newSSD)
        setAllSectionsLMData(allSectionData.Sections)
      } else {
        setErrorMessage(lstResponseStatus[0].statusDesc)
      }
    } else {
      setErrorMessage('Something went wrong')
    }
  }

  const saveLossMitiData = async (postData) => {
    let obj1 = allSectionsLMData
    const returnedTarget = Object.assign(obj1, postData);
    const finalObj = { Sections: obj1 }

    let submitStatus = activeStep === 8 ? true : false

    const requestData = {
      "isSubmit": submitStatus,
      "lossMitigationData": JSON.stringify(finalObj),
      "accountNumber": accountNumber, //"W3gd+B4rY/gwvA+9iHtiYg==",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get("publicIp"),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }

    setErrorMessage('')
    setSuccessMessage('')
    setIsSaveProcess(true)
    const result = await saveLossMitigationData(requestData)
    setIsSaveProcess(false)
    if (result) {
      const { lstResponseStatus } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        //to do  
        getLMData() // get Updated Data
        handleNext() // process to next step 
        //scrollTop() // scroll to top               
      } else {
        setErrorMessage(lstResponseStatus[0].statusDesc)
      }
    } else {
      setErrorMessage('Something went wrong')
    }
  }


  const renderSteeper = (status, stepper) => {
    if (activeStep === stepper.id) {
      return (<RadioButtonCheckedIcon
        color="primary"
        className={classes.stepperIcon}
        //completed={completed[stepper.id]}
        onClick={() => handleStep(stepper.id)}
      //classes={{ label: completed[stepper.id] || activeStep === stepper.id ? classes.aciveLabelStyle : classes.labelStyle }}
      />)
    } else {
      if (status === 'complete') {
        return (<CheckCircleOutlineIcon
          color="primary"
          className={classes.stepperIcon}
          //completed={completed[stepper.id]}
          onClick={() => handleStep(stepper.id)}
        //classes={{ label: completed[stepper.id] || activeStep === stepper.id ? classes.aciveLabelStyle : classes.labelStyle }}
        />)
      } else if (status === 'incomplete') {
        return (<div className={classes.circle}>
          <CachedIcon
            style={{ color: "#F2C777", fontSize: "20px" }}
            //className={classes.stepperIcon}
            //completed={completed[stepper.id]}
            onClick={() => handleStep(stepper.id)}
          // classes={{ label: completed[stepper.id] || activeStep === stepper.id ? classes.aciveLabelStyle : classes.labelStyle }}
          /></div>)
      } else {
        return (<RadioButtonUncheckedIcon
          //completed={completed[stepper.id]}
          className={classes.stepperIcon}
          onClick={() => handleStep(stepper.id)}
        //classes={{ label: completed[stepper.id] || activeStep === stepper.id ? classes.aciveLabelStyle : classes.labelStyle }}
        />)
      }
    }
  }

  /*Manage section Status*/
  const [sectionStepperData, setSectionStepperData] = useState([
    { id: 0, label: 'Section A', key: "section_A", tooltipTitle: 'Borrower Information', status: "" },
    { id: 1, label: 'Section B', key: "section_B", tooltipTitle: 'Required Documentation/ Hardship Affidavit', status: "" },
    { id: 2, label: 'Section C', key: "section_C", tooltipTitle: 'Additional Liens / Mortgages or Judgements', status: "" },
    { id: 3, label: 'Section D', key: "section_D", tooltipTitle: 'Bankruptcy', status: "" },
    { id: 4, label: 'Section E', key: "section_E", tooltipTitle: 'Income/Expenses For Household', status: "" },
    { id: 5, label: 'Section F', key: "section_F", tooltipTitle: 'Property', status: "" },
    { id: 6, label: 'Section G', key: "section_G", tooltipTitle: 'OtherProperty For Which Assistance Is Requested', status: "" },
    { id: 7, label: 'Section H', key: "section_H", tooltipTitle: 'Information For Government Monitoring Purposes', status: "" },
    { id: 8, label: 'Section I', key: "section_I", tooltipTitle: 'Acknowledgment And Agreement', status: "" },
  ])
  const handleSectionStatus = (statusObj) => {
    let newStatusArray = sectionStepperData.map(row => {
      if (row.key === statusObj.sectionName) {
        row.status = statusObj.status
      }
      return row
    })
    setSectionStepperData(newStatusArray)
  }


  return (
    <Box className={classes.root}>
      <Box className={classes.flexContainer}>
        <Box className={classes.mainContainerWidth}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small"  style={{color: '#2B2D30'}} />} aria-label="breadcrumb">
            <Link color="inherit" to='/HelpLanding' className={classes.disabledLink}>
              Help
        </Link>
            <Link color="primary" to='/HardshipOptions' className={classes.activeLink}>
              Hardship Options
        </Link>
          </Breadcrumbs>
          <h1 className={classes.title}>Loss Mitigation Application: Loan #{accountNumber ? maskAccount(decrypt(accountNumber)) : ''}</h1>
        </Box>
      </Box>
      <Box className={`${classes.flexContainer} ${classes.bgWhith}`}>
        <Box className={`${classes.container} ${classes.mainContainerWidth}`}>
          <Box className={classes.stepperContainer}>
            <Stepper classes={{ root: classes.stepperStyle }} activeStep={activeStep} nonLinear orientation="vertical">

              {sectionStepperData.map(stepper => {
                //stepperData.map(stepper => {
                return (
                  <Step key={stepper.id} expanded={false} >

                    {/* <StepLabel
                        completed={completed[stepper.id]}
                        onClick={() => handleStep(stepper.id)}
                        classes={{ label: completed[stepper.id] || activeStep === stepper.id ? classes.aciveLabelStyle : classes.labelStyle }}
                      >
                        {stepper.label}
                      </StepLabel > */}
                    <Box display="flex">
                      {renderSteeper(stepper.status, stepper)}
                      <LightTooltip title={stepper.tooltipTitle} aria-label={stepper.tooltipTitle} placement="bottom-start" arrow>
                        <Typography
                          onClick={() => handleStep(stepper.id)}
                          className={activeStep === stepper.id ? classes.aciveLabelStyle : classes.labelStyle}
                          style={{ marginTop: "2px", marginLeft: "10px" }}>{stepper.label}</Typography></LightTooltip>
                    </Box>

                  </Step>
                )
              })
              }

              {/* {
                stepperData.map(stepper => {
                  return (
                    <Step key={stepper.id} expanded={false} >
                       <LightTooltip title={stepper.tooltipTitle} aria-label={stepper.tooltipTitle} arrow>
                      <StepLabel
                        completed={completed[stepper.id]}
                        onClick={() => handleStep(stepper.id)}
                        classes={{ label: completed[stepper.id] || activeStep === stepper.id ? classes.aciveLabelStyle : classes.labelStyle }}
                      >
                        {stepper.label}
                      </StepLabel>                      
                      </LightTooltip>
                    </Step>
                  )
                })
              } */}
            </Stepper>
          </Box>
          <Box className={classes.contantContainer}>
            <Box className={classes.sectionBox}>
              {/* {renderSection()} */}
              {isSubmitted && showSections === false ? (<SectionComplete></SectionComplete>) : renderSection()}

            </Box>
            {/* <Box className={classes.btnContainer}>
              <Button className={classes.arrowBack} size="small" onClick={handlePrev} disabled={activeStep === 0}>
                <ArrowBackIcon />
              Back
            </Button>
              <Button size="small" onClick={handleNext} disabled={activeStep === 8}>
                Next
              <ArrowForwardIcon />
              </Button>
            </Box> */}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}