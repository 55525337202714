import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { useStyles, CutomTextField } from './styles';
import Dialog from '@material-ui/core/Dialog';
import CancelIcon from '@material-ui/icons/Cancel';
import { resendCode, validateOTP } from '../../../actions';
import Cookies from 'js-cookie';
import { getAppSource, getUserType, maskEmail, maskMobile, renderDefaultMsg } from '../../../helpers';
import { decrypt, encrypt } from '../../../helpers/encrypt';
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";


export const EmailVerification = (props) => {

  const { open, handleClose, email, emailVal, viewProfile } = props;
  const classes = useStyles();
  const [inputCode, setInputCode] = useState('')
  const [isProcessResendOpt, setIsProcessResendOpt] = useState(false)
  const [isProcessSave, setIsProcessSave] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [progress, setProgress] = useState(0);
  const [leftTime, setLeftTime] = useState(0);
  const increment = useRef(null);

  useEffect(() => {
    handleTimer()
  }, []);

  const handleTimer = () => {
    let timer = 600
    increment.current = setInterval(() => {
      setProgress((prevProgress) => (prevProgress === 0 ? 100 : prevProgress - 5 / 30));
      if (timer === 0) {
        setLeftTime("00:00")
        clearInterval(increment.current)
      } else {
        setLeftTime(timer--)
      }
    }, 1000);
    return () => clearInterval(increment.current);
  }

  const handleReset = () => {
    clearInterval(increment.current);
  }

  const handleChange = (prop) => (event) => {
    setInputCode(event.target.value);
  }

  const reSendOTP = async () => {
    handleReset();
    setInputCode('');
    setProgress(0);
    setLeftTime(0);
    handleTimer();

    const data = {
      "otpType": "VerifyEmail",
      "mediumType": "Email",
      "mobileNumber": "",
      "email": props.email ? encrypt(props.email) : '',
      "loanNo": "",
      "accountNumber": props.accountNumber,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get("publicIp"),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": props.userName ? props.userName : ''
    }
    setErrorMessage('')
    setSuccessMessage('')
    setIsProcessResendOpt(true)
    const result = await resendCode(data)

    if (result) {
      const { lstResponseStatus } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        setIsProcessResendOpt(false)
        setSuccessMessage(lstResponseStatus[0].statusDesc)
      } else {
        setIsProcessResendOpt(false)
        setErrorMessage(lstResponseStatus[0].statusDesc)
      }
    } else {
      setIsProcessResendOpt(false)
      setErrorMessage(renderDefaultMsg('400'))
    }
  }

  const handleSubmit = async (e) => {
    const data = {
      "otp": inputCode ? encrypt(inputCode) : '',
      "otpType": viewProfile ? "verifyfromA360": "VerifyEmail",
      "AccountNumber": props.accountNumber,
      "mediumType": "Email",
      "mobileNumber": "",
      "email": props.email ? encrypt(props.email) : '',
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get("publicIp"),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": props.userName ? props.userName : ''
    }
    setErrorMessage('')
    setSuccessMessage('')
    setIsProcessSave(true)

    const result = await validateOTP(data);
    if (result) {
      setIsProcessSave(false)
      const { lstResponseStatus } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        props.updateDone(encrypt(props.email))
      } else {
        setErrorMessage(lstResponseStatus[0].statusDesc)
      }
    } else {
      setIsProcessSave(false)
      setErrorMessage(renderDefaultMsg('400'))
    }
  }

  const CircularProgressWithLabel = (props) => {
    const showTimer = (rt) => {
      if (rt === 'Completed') {
        return rt
      }
      rt = parseInt(rt)
      let minutes = Math.floor(rt / 60);
      let seconds = rt % 60

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      return `0${minutes}:${seconds}`;
    }

    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress size="8rem" variant="static" {...props} className={classes.timerLoader} />
        <Box
          className={classes.timerBox}
        >
          <Typography
            className={classes.timerText}>
            {showTimer(props.remaingTime)}
          </Typography>
          <p className={`${classes.timerText} ${classes.timerSmallText}`}>mins</p>
        </Box>
      </Box>
    );
  }

  const redirectEdit = () => {
    handleClose()
    props.goToEdit('isEvPopup')
  }

  return (
    <Dialog
      fullWidth={false}
      maxWidth={'xl'}
      PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={handleClose} open={open}
    >
      <Box className={classes.root}>
        <Box py={2} borderBottom={1} style={{ borderColor: '#e6e3e3' }}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
              <Typography className={classes.heading}>Verify Email</Typography>
            </Grid>
            <Grid item>
              <Typography align="right">
                <CancelIcon onClick={() => handleClose()} className={classes.iconStyle} />
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box mt={3}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Box>
            <Typography className={classes.desc} style={{marginBottom: '0.6rem'}}>A Verification Code has been sent to your updated email address. Please enter the Verification Code here to confirm the email address.</Typography>
          </Box>

            <Box className={classes.inputContainer}>
              <CutomTextField
                id="outlined-code"
                label="Verification Code"
                variant="outlined"
                type="text"
                value={inputCode}
                onChange={handleChange('inputCode')}
                fullWidth
                inputProps={{
                  className: classes.inputStyle,
                  //maxLength: 10,
                }}
              />
              {errorMessage ? (<Box><Typography className={classes.errorText}>{errorMessage}</Typography></Box>) : null}
            </Box>

            {viewProfile && <Box>
            <Typography className={classes.desc}>Verification Code not received? Please check the junk folder for your email and confirm the email address is correct too. To use a different email address instead, 
            <a  style={{cursor:'pointer', textDecoration: 'underline'}} onClick={redirectEdit}> click here</a>.</Typography>
          </Box>}
          </form>
          
          <Box mt={1} mb={2}>
            {isProcessResendOpt ? (<Box><CircularProgress color="primary" /></Box>) : (
              <Typography
                onClick={() => reSendOTP()}
                className={classes.resendCode}>Resend Code</Typography>)}
          </Box>
          <Box mt={1} mb={2}>
            <Typography className={classes.timeLineText}>Code will be valid for 10 minutes</Typography>
          </Box>
          <Box className={classes.timerContainer}>
            <p className={classes.expireText}>Time Remaining:</p>
            <CircularProgressWithLabel remaingTime={leftTime} value={progress} />
          </Box>

          <Box textAlign="center" pt={2}>
            {isProcessSave ? (<Button
              disabled={true}
              className={classes.saveBtn}
              variant="contained"
              disabled={true}><CircularProgress color="primary" /></Button>) :
              (<Button
                onClick={() => handleSubmit()}
                className={classes.saveBtn}
                variant="contained"
                color="primary"
                disabled={!inputCode || (leftTime === '00:00')}>
                SUBMIT</Button>)}
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}