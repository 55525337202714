import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    // marginBottom: 142,
    // [theme.breakpoints.down('sm')]: {
    //   marginBottom: 56,
    // },

    marginBottom: 152,
    [theme.breakpoints.down('md')]: {
      marginBottom: 75,
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 111,
    },

  },

  marqueeNotRoot: {
    display: 'flex'
  },

  marqueeRoot: {
    marginTop: 27,
    [theme.breakpoints.down('sm')]: {
      marginTop: 16,
    },
  },


  appBar: {
    width: "100%",
    backgroundColor: theme.header.backgrounColor,
    [theme.breakpoints.up('md')]: {
      padding: '0px 5em'
    },
  },

  marqueeAppBar: {
    marginTop: 29,
  },

  marqueeNotAppBar: {
    width: "100%",
  },

  menuButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,

  grow: {
    flexGrow: 1,
  },

  linkContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  },

  linkStyle: {
    color: theme.header.fontColor,
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: '600',
    '&.isActive': {
      // borderBottom: 'solid 6px',
      // borderColor: theme.header.fontColor,
      // borderRadius: '0'
    }
  },
  adminLinkStyle: {
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: '600',
  },

  addressListStyle: {
    // color: "#535D6D",
    fontWeight: '600',
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    textTransform: 'uppercase',
    display: 'block',
  },

  menuLinkStyle: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    borderBottom: 'solid 1px #ddd',
    whiteSpace: 'break-spaces',
  },

  signOut: {
    marginLeft: 30,
    color: '#fff',
    height: 40,
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: '600',
    backgroundColor: 'transparent',
    borderColor: '#FFF',
    border: '1px solid',
    borderRadius: 4,
    textTransform: 'uppercase',
    padding: '10px 20px',
    outline: "none",
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  },

  logoContainer: {
    display: 'block',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      display: 'flex',
      // justifyContent: 'center',
      alignItems: 'center'
    },
  },

  logoStyle: {
    width: 350,
    height: 74,
    margin: '9px 0',
    [theme.breakpoints.down('md')]: {
      width: 153,
      height: '100%',
      margin: '9px 0',
    },
  },

  profileIcon: {
    display: 'none',
    color: theme.palette.primary.main
  },

  colorIcon: {
    color: theme.header.fontColor,
  },

  menuContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },

  menuWrapper: {
    width: '80%',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      height: '46px'
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  },

  backtoAdmin: {
    position: 'absolute',
    top: 0,
    right: 0,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  },

  addressContainer: {
    width: '30%',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },

  addressContainerSmallScreen: {
    width: '100%',
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },


  formControl: {
    width: '100%',
    height: 36,
    textAlign: 'center',
    '& .MuiSelect-icon': {
      color: theme.palette.primary.contrastText
    }
  },

  selectStyle: {
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 1px 5px 0px rgba(0, 0, 0, 0.2)',
    borderRadius: 4,
    '& .MuiSelect-icon': {
      color: theme.palette.primary.contrastText
    }
  },

  profileIcon: {
    color: '#fff',
  },

  iconTopMargin: {
    marginTop: 20,
    marginRight: -26,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginRight: -38
    },
  },

  iconTopMargin2: {
    marginTop: 20,
    marginLeft: 10,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginRight: -16
    },
  },

  iconTopMargin3: {
    marginTop: 20,
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  menuItemStyle: {
    fontFamily: "Montserrat",
    fontSize: 16,
    color: '#202020',
    borderBottom: 'solid 1px #ddd',
    marginLeft: 16,
    '&:hover': {
      backgroundColor: '#fff'
    }
  },
  select: {
    backgrounColor: theme.palette.primary.main
  },

  positionRelative: {
    position: 'relative'
  },
  borderBottom: {
    borderColor: '#202020',
    borderBottom: 'solid 6px',
    borderRadius: 0,
    width: '100%',
    display: 'block',
    position: 'absolute',
    bottom: '5px'
  },
  icon: {
    marginLeft: '-17px',
    height: '24px',
    width: '30px' , 
    position: 'absolute'
  },
  badge: {
    fontSize: 12,
    fontWeight: 600,
    marginLeft: '-25px',
    marginTop: '-4px',
    height: '20px',
    width: '36px' , 
    position: 'absolute',
    padding: '1px 5px',
    borderRadius: 20,
    color: 'white'
  },
  menuBadge: {
    fontSize: 13,
    fontWeight: 600,
    marginLeft: '210px',
    height: '20px',
    width: '38px' , 
    position: 'absolute',
    padding: '0px 5px',
    borderRadius: 20,
    color: 'white'
  },
}));

export const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    border: 'solid 1px',
    borderColor: theme.palette.primary.main,
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      'Montserrat',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      backgroundColor: theme.palette.primary.main,
    },
  },
}))(InputBase);
