import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
  mainHeading: {
    padding: '24px 0 0.3em',
    maxWidth: '1190px',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontWeight: '700',
    fontSize: '22px',
    fontFamily: 'Montserrat',
    color: '#1A4664',
    letterSpacing: '0.63px',
    [theme.breakpoints.down('md')]: {
      padding: '24px 1.4em 0.5em 1.4em',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '24px 1.4em 0.5em 1.4em',
    },
  },
  cardTitle: {
    padding: '18px 6px 18px 6px',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 6px 10px 6px',
    },
    '&:last-child': {
      paddingBottom: '18px',
      [theme.breakpoints.down('xs')]: {
        paddingBottom: '10px',
      },
    }
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
    },
    width: '100%',
    padding: '1em 0',
    maxWidth: '122em',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('md')]: {
      padding: '1em 1.5em',
    },
    '&:last-child': {
      paddingBottom: '4em',
      [theme.breakpoints.down('xs')]: {
        paddingBottom: '1em',
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: '1em 2.5em',
    },
  },
  bg: {
    background: 'rgb(214 207 207 / 54%)',
    margin: '0 -15px'
  },
  paper: {
    width: '17.5%',
    margin: '1.2%',
    borderRadius: '16px',
    cursor: 'pointer',
    boxShadow: '0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.2)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    backgroundColor: theme.palette.primary.main
  },
  tileFlex: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start'
    },
  },
  tileIcon: {
    [theme.breakpoints.down('sm')]: {
      width: '30%',
      textAlign: 'center'
    },
    '& img': {
      width: '70px',
      height: '70px',
      [theme.breakpoints.down('xs')]: {
        width: '50px',
        height: '50px',
      },
    }
  },
  media: {
    width: '70px',
    height: '70px'
  },
  Heading: {
    maxWidth: 200,
    wordBreak: "break-word",
    color: theme.palette.secondary.contrastText,
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.53px',
    lineHeight: '19px',
    paddingTop: '1em',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '15px',
    },
  }
}));