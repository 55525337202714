import React from 'react';
import { useStyles, StyledTableCell, StyledTableRow } from './styles';
import { Box, Button, CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { numberWithCommas } from '../../helpers';
import moment from 'moment';


export const EscrowTransactionAlert = (props) => {
  const { open, handleClose, escrowDetails, loading } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


  function createData(date, paymentType, description, amaount, payee) {
    return { date, paymentType, description, amaount, payee };
  }

  const renderTableBody = () => {
    const data = escrowDetails.map(item => createData(item.effectiveDate, item.escrowType, item.description, item.escrowAmount, item.payee));
    return data.map((row, index) => {
      return (
        <StyledTableRow key={`${row.date}${index}`}>
          <StyledTableCell component="th" scope="row">
           { moment(row.date).format('ll') }
          </StyledTableCell>
          <StyledTableCell align="center">{row.paymentType}</StyledTableCell>
          <StyledTableCell align="center">{row.description}</StyledTableCell>
          <StyledTableCell align="center">{numberWithCommas(parseFloat(row.amaount).toFixed(2))}</StyledTableCell>
          <StyledTableCell align="center">{row.payee}</StyledTableCell>
        </StyledTableRow>)
    })
  }

 const closeDialog = (event, reason) => {
  handleClose()
 }

  return (
    <Dialog
      fullScreen={fullScreen}
      PaperProps={{ classes: { root: classes.dialogStyle } }}  onClose={(event, reason) => {
          closeDialog(event, reason)
    }} open={open}
    >
      <Box className={classes.root}>
        <IconButton className={classes.iconStyle} onClick={(event, reason) => {
          closeDialog(event, reason)
        }
    }>
          <CancelIcon />
        </IconButton>
        <Box className={classes.header}>
          <h1 className={classes.title}>Escrow Transactions</h1>
          <p className={classes.subTitle}>Payments made on your behalf for Taxes, Home Insurance, and Mortgage Insurance</p>
        </Box>
        <Box className={classes.cardBody}>
          {!loading ? escrowDetails.length !== 0 ? <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Effective Date</StyledTableCell>
                  <StyledTableCell align="center">Type</StyledTableCell>
                  <StyledTableCell align="center">Description</StyledTableCell>
                  <StyledTableCell align="center">Escrow Amount</StyledTableCell>
                  <StyledTableCell align="center">Payee</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renderTableBody()}
              </TableBody>
            </Table>
          </TableContainer>
            :
            <Box className={classes.noDataFound}>
              <p className={classes.title}>No data found</p>
            </Box>
            :
            <Box className={classes.noDataFound}>
              <CircularProgress />
            </Box>
          }
        </Box>
        <Button
          color='primary'
          variant='contained'
          className={classes.btn}
          onClick={handleClose}
        >Close</Button>
      </Box>
    </Dialog>
  )
}
