import React from 'react';
import { Box, Typography } from '@material-ui/core';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { useStyles } from './styles';

export function TileCard(props) {
    const classes = useStyles();

    return (<React.Fragment>
        <Card className={classes.paper} onClick={props.onClick}>
            <CardContent>
                <Box className={classes.tileFlex} display='flex' flexDirection="column" justifyContent="center" alignItems="center" spacing={3}>
                    <Box className={classes.tileIcon}>
                        <img src={props.imgUrl} alt="tile img" />
                    </Box>
                    <Typography className={classes.Heading} variant="h6" component="h6" color="primary">
                        {props.tileCon}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    </React.Fragment>
    );
}
