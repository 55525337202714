import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import { useStyles } from './styles';

export const AdditionalPaymentAlert = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle }}} onClose={props.handleClose} open={true}>
     
      <Box className={classes.root}>
          
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>Important Notice Regarding Your Loan</Typography>
        </Box>
    
        <Box className={classes.cardBody2}>
          <Box className={classes.statusContainer}>
           <Box className={classes.cardContainer}>
            <Box className={classes.imageContainer}>
             <Box className={classes.mainContainer}>
             <InfoIcon className={classes.infoIcon} />
              <Typography className={classes.paragraph}>We see that the designated additional principal amount is within <b>$5,000</b> of your unpaid principal balance.</Typography>
              </Box>
             <ul>
              <Typography className={classes.paragraph}><li>If you intend to payoff your loan, please select a <b>Request Payoff</b> quote.</li></Typography>
              <Typography className={classes.paragraph}><li>If you are intending to make a principal prepayment, please <b>Continue</b> with your payment.</li></Typography>
              </ul>
              </Box>
            </Box> 
          </Box>
        </Box>
    
        <Box className={classes.cardFooter}>
         <Box className={classes.footerItem}>
            <Button
              variant='outlined'
              className={classes.btn}
              onClick={props.routeToRequestPayOff}
            > REQUEST PAYOFF</Button>
          </Box>
          <Box className={classes.footerItem}>
              <Button
                variant='contained'
                color='primary'
                className={classes.btn}
                onClick={props.handleCloseAlert}
              >CONTINUE</Button>
          </Box> 
        </Box>
       
      </Box> 
    </Dialog>
  )
}

