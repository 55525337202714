import React from "react";
import { Box, Button, Dialog, IconButton } from "@material-ui/core";
import { useStyles } from './styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';

import { encrypt, decrypt } from '../../../helpers/encrypt';
import { maskAccount, maskAccountNo, numberWithCommas } from '../../../helpers';
import moment from 'moment';
import checkmark from '../../../assets/checkmark.png';

export const SuccessMsgAlert = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      PaperProps={{ classes: { root: classes.dialogStyle } }}
      onClose={props.toggleOpen}
      open={props.open}
    >
      <Box className={classes.root}>
        <Box className={classes.header}>
          <IconButton className={classes.iconStyle} onClick={props.toggleOpen}>
            <CancelIcon className={classes.iconStyle} />
          </IconButton>
        </Box>
        <Box className={classes.body}>
        { ((props.isAutoPaySch == null) && props?.showAutoPay) ?
          <>
          <Box style={{display: "flex", justifyContent: "center" }}>
            <img className={classes.checkImg} src={checkmark} alt='img' /> 
            <span className={classes.successMsg}>Your payment scheduled successfully!</span>
          </Box> 
        
          <p className={classes.onTime}> ON TIME</p>
          <p className={classes.everyTime}> EVERY TIME</p>
          <h1 className={classes.title}>Set your date and never miss a payment.</h1>
        
          <Box style={{borderBottom: "1px solid #d3d4d5", paddingBottom: '25px'}}>
            <Button className={classes.autoPayBtn}
              onClick={props.routeToSetUpAutoPay}
              type='submit' size="small" variant="contained" >
              SET UP AUTOPAY
            </Button>
          </Box>
          <p className={classes.subTitle}> Payment Details</p> 
          </>
          :
          <>
            <h1 className={classes.heading}>Successfully Scheduled Payment</h1>
            <h3 className={classes.popupSubHeading}> Never Miss A Payment. Always Pay on Time.</h3>
            <p className={classes.subtitle}>
              Your One-Time Payment has been scheduled successfully. Check the details of your payment below.
            </p>
          </>
         }
          <Box className={classes.popupCode}>
            <h5 className={classes.popupBody}>Request ID # {decrypt(props?.code)} </h5>
            <Box className={classes.flexBox}>
              <Box className={classes.item}>
                <h3 className={classes.itemText}>Amount</h3>
                <h3 className={classes.itemSubText}>${numberWithCommas(props.totalAmount.toFixed(2))}</h3>
              </Box>
              <Box className={classes.item}>
                <h3 className={classes.itemText}>Payment Date </h3>
                <h3 className={classes.itemSubText}>{moment(props.paymentDate).format('ll')}</h3>
              </Box>
              <Box className={classes.item}>
                <h3 className={classes.itemText}>Bank Account</h3>
                <h3 className={classes.itemSubText}>{`${props.selectedBank.nickName} ${maskAccountNo(decrypt(props.selectedBank.bankAccountNumber))}`}</h3>
              </Box>
            </Box>
          </Box>

          <Button className={classes.okBtn}
            onClick={props.toggleOpen}
            type='submit' size="small" variant="contained" color="primary" >
            OK
          </Button>

        </Box>
      </Box>
    </Dialog >
  )
}