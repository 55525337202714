import React from 'react';
import { useStyles } from './styles';
import { Box, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useTheme } from '@material-ui/core/styles';
import { decrypt } from '../../helpers/encrypt';
import Cookies from 'js-cookie';

export const AccessAlert = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const allAccountsDetail = Cookies.get("loanAccounts");
  let allAccounts = JSON.parse(allAccountsDetail);
  const filterAcc = allAccounts.filter((item) => item?.accountNumber == decrypt(Cookies.get('selectedAccount')));
 
  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} open={props?.status} BackdropProps={{style: {backdropFilter: "blur(3px)",
    backgroundColor:'rgba(0,0,0,0.4)'}}}>
      <Box className={classes.root}>
         <Box className={classes.cardTitleBody}>
           <p className={classes.statusTitle}>Access Denied</p>
         </Box> 
        <Box className={classes.cardBody}>
          <Box className={classes.statusContainer}>
            <p className={classes.statusSubTitle}>Loan:<span className={classes.accessDenied}> {decrypt(Cookies.get('selectedAccount'))} - {filterAcc[0]?.propertyAddress1}</span>{props?.type == 'maturity' ? 'does not meet the criteria for an extension consideration currently.' : 'does not qualify you to view this page.'} </p>
          </Box>
          <Box className={classes.buttonContainer}>
            <Button type='submit' className={classes.signInBtn} size="medium" onClick={props?.accessDeniedPopup} variant="contained" color="primary">
                OK
              </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}
