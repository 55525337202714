import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 680,
    borderRadius: 12,
    backgroundColor: 'white',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)"
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxHeight: "100%",
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '20px',
        margin: '50px 15px'
      },
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "400",
    textAlign: 'center',
    margin: 14,
  },

  cardBody: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '12px 2em',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  cardContainer: {
    width: '100%',
    padding: '2px 2em',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  statusTitle: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: '18px',
    fontWeight: "700",
    padding: '3px 2px 2px 3px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px'
    }
  },

  statusBody: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "bold",
  },

  linkStyle: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    fontWeight: "600",
    marginTop: 5,
  },

  paragraph: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "500",
    marginBottom: 8,
    paddingTop: 8,
    lineHeight: '24px'
 },

closeIcon: {
  position: 'absolute',
  right: 2,
  [theme.breakpoints.down('sm')]: {
   right: 10
  },
},

tick: {
  width: '16px', 
  height: '16px',
  marginRight: 10,
  marginTop: 10
},

link: {
  color: '#007FFF',
  textDecoration: 'underline',
  cursor: 'pointer'
},

transferBox: {
  backgroundColor: '#E5ECF0',
  borderRadius: 2,
  padding: '1em',
  margin: '2em',
  borderRadius: '8px',
  border: '2px solid #D9D9D9'
},

servicerText: {
  color: "#121D22",
  fontFamily: "Montserrat",
  fontSize: 15,
  fontWeight: "700",
  textTransform: 'uppercase'
},

contactTime: {
  color: '#121D22',
  fontWeight: '700',
  fontSize: 16
},

noUnderLine: {
  textDecoration: 'none'
},

iconStyle: {
  height: 14,
  width: 15, 
  color: '#121D22', 
  marginRight: '6px', 
  marginLeft: '6px'
},

informationText: {
  color: "#121D2",
  fontFamily: "Montserrat",
  fontSize: 15,
  fontWeight: "400"
},

callUs: {
  paddingTop: 10,
  color: "#121D2",
  fontFamily: "Montserrat",
  fontSize: 15,
  fontWeight: "400"
},

newServicer: {
  alignItems: 'center',
  backgroundColor: '#FFF2E1',
  borderRadius: 4,
  padding: 4,
  margin: "2em",
  borderRadius: '8px',
  border: '4px solid #EDB342'
},

paidInFullIcon: {
  color: "#EDB36A",
  width: 25,
  height: 25,
  marginRight: 10,
},

information: {
  color: "#000000",
  fontFamily: "Montserrat",
  fontSize: '1.6em',
  fontWeight: "400",
},

}));
