import { makeStyles } from '@material-ui/core/styles';
import signinIcon from '../../assets/SignIn/SignIn.svg';

export const useStyles = makeStyles((theme) => ({
  pageBg: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#ccc',
    backgroundImage: `url(${signinIcon})`,
    backgroundPosition: "bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'contain',
  },

  root: {
    maxWidth: "120em",
    margin: "0 auto",
    padding: "2.4em 0em 9em",
    [theme.breakpoints.down('md')]: {
      padding: "2.4em 1.5em 5em 1.5em",
    },
  },

  loaderContainer: {
    width: '100%',
    height: '100%',
    minHeight: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  title: {
    color: "#1A4664",
    fontFamily: "Montserrat",
    letterSpacing: '0.18px',
    lineHeight: '24px',
    fontSize: "22px",
    fontWeight: "700",
    marginTop: 24,
    marginBottom: 25,
  },

  subTitle: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: "21px",
    fontWeight: "600",
    marginBottom: 30,
  },

  text: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "500",
    textAlign: 'justify',
  },

  container: {
    padding: '46px 77px',
    marginBottom: 40,
    borderRadius: 12,
    backgroundColor: "#FFFFFF",
    boxDhadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('md')]: {
      padding: '46px 15px',
    },
  },

  havingDifficulty: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  havingDifficultyContent: {
    maxWidth: 534,
    marginRight: 10,
    [theme.breakpoints.down('md')]: {
      marginBottom: 20,
      maxWidth: "100%",
    },
  },

  havingDifficultyImgContainer: {
    maxWidth: 466,
    height: 311,
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      marginBottom: 20,
    },
  },

  havingDifficultyImg: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      margin: '0 auto'
    },
  },

  cardContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: 40,
  },

  card: {
    maxWidth: 500,
    // padding: '20px 20px',
    padding: '24px 24px 40px 24px',
    borderRadius: 12,
    backgroundColor: "#eaeced",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    marginBottom: 60,
    display: 'flex',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      width: '100%',
      padding: '15px',
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },

  cardIcon: {
    width: 95,
    height: 95,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
    },
  },

  cardContent: {
    marginLeft: 20,
  },

  cardTitle: {
    color: theme.palette.primary.main,
    fontFamily: "Montserrat",
    fontSize: "2em",
    fontWeight: "600",
    marginBottom: '20px',
    letterSpacing: '0.15px',
    linHheight: '24px',
  },

  cardText: {
    color: "rgba(0,0,0,0.74)",
    fontFamily: "Montserrat",
    letterSpacing: '0.5px',
    lineHeight: '20px',
    fontSize: "18px",
    fontWeight: "500",
    marginBottom: 45,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 60,
    },
  },

  btnContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    bottom: '24px',
    right: '23px',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      right: '12px',
      position: 'relative',
    },
  },

  cardBtn: {
    color: theme.palette.primary.contrastText,
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    width: '320px',
    letterSpacing: '1.43px',
    lineHeight: '16px',
    height: '40px',
  },

  linkBtn: {
    cursor: 'pointer',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    textDecoration: 'none',
    textAlign: 'center',
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    width: '320px',
    letterSpacing: '1.43px',
    lineHeight: '40px',
    height: '40px',
    borderRadius: '4px',
    borderSizing: 'border-box',
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },

  link: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    marginTop: 14,
  },

  disabledLink: {
    color: "#7B8390",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    letterSpacing: '0.5px',
    textDecoration: 'none',
  },

  activeLink: {
    color: theme.palette.primary.main,
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    textDecoration: 'none',
  },

  accFinancialContainer: {
    // display: 'flex',
    // justifyContent: 'space-between',
    // flexWrap: 'wrap'
  },

  accFinancialImgContainer: {
    width: '12%',
    float: 'left',
    height: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      float: 'none',
      height: 'auto',
      textAlign: 'center',
    },

  }


}));