import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  mt10: {
    marginTop: 16,
  },

  radioStyle: {
    padding: '0 5px',
    color: "#7B8390",
    '&.Mui-checked': {
      color: "#202020 !important",
    }
  },

  activeRadioLabel: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "bold",
  },

  radioLabel: {
    color: "rgba(0,0,0,0.74)",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: "500",
  },

  marginFixed: {
    marginTop: 42,
  },

  impectedCovid: {
    marginTop: '50px',
    padding: '0 37px',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },

  formSection: {
    marginTop: '50px',
    padding: '0 37px',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },

  subHeading: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: '500',
    letterSpacing: '0.55px',
    lineHeight: '25px',
    marginBottom: '20px'
  },

  covidImpectRadio: {
    marginTop: '28px',
    '& .MuiFormControlLabel-root': {
      width: '47%',
      [theme.breakpoints.down('xs')]: {
        margin: 0,
        marginTop: '12px'
      },
    },
  },

  text: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: '400',
    paddingRight: '30px',
  },

  radioBtnMargin: {
    marginTop: '25px'
  },

  subText: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    letterSpacing: '0.36px',
    lineHeight: '19px',
    [theme.breakpoints.up('sm')]: {
      margin: 0,
      paddingRight: '20px',
    },
  },

  justifyCenter: {
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  textField: {
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: '600'
  },

  textField250: {
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: '600',
    height: '117px',
    display: 'block',
  },

  textArea: {
    boxSizing: 'border-box',
    height: '177px !important',
    width: '100%',
    border: '1px solid #3064A6',
    borderRadius: '9px',
    padding: '30px 20px'
  },

  required: {
    color: '#ec3131',
    fontWeight: '600',
    marginLeft: '2px'
  },

}))