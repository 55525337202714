import { makeStyles } from '@material-ui/core/styles';
import signinIcon from '../../assets/SignIn/SignIn.svg';

export const useStyles = makeStyles((theme) => ({
  rooBg: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#ccc',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${signinIcon})`,
    backgroundPosition: "bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'contain',
  },
  root: {
    width: "100%",
    maxWidth: "120em",
    padding: "2.4em 1.5em 9em 1.5em",
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      padding: "3.3em 1.5em 5em 1.5em",
    },
  },

  loader: {
    width: '100%',
  },

  loaderContainer: {
    width: '100%',
    height: '100%',
    minHeight: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  title: {
    color: "#1A4664",
    fontFamily: "Montserrat",
    fontSize: "22px",
    letterSpacing: '0.18px',
    lineHeight: '24px',
    fontWeight: "700",
    marginTop: 24,
    marginBottom: 25,
  },

  subTitle: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: "20px",
    letterSpacing: '0.15px',
    lineHeight: '24px',
    fontWeight: "500",
    marginBottom: 15,
  },

  text: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "500",
  },

  container: {
    width: '100%',
    marginBottom: 40,
    borderRadius: 12,
    backgroundColor: "#FFFFFF",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
  },

  containerWraper: {
    padding: '40px 25px',
    width: '100%',
    borderRadius: 12,
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down('md')]: {
      padding: '25px 15px',
    },
  },

  sectionContainer: {
    width: '100%',
    marginBottom: 16,
  },

  link: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    marginTop: 14,
  },

  disabledLink: {
    color: '#4D4D4D',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.5px',
    lineHeight: 'normal',
    textDecoration: 'none',
    cursor: 'pointer',
    fontStyle: 'normal',
    textDecoration: 'none'
  },

  activeLink: {
    color: '#121D22',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.5px',
    lineHeight: 'normal',
    textDecoration: 'none'
  },

  flexBox: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  infoBox: {
    width: '49%',
    border: `solid 1px ${theme.palette.primary.main}`,
    borderRadius: 6,
    backgroundColor: "#FAFAFA",
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      overflow: 'auto',
      marginBottom: 20,
    },
  },

  infoBoxHeader: {
    width: '100%',
    minWidth: 450,
    padding: '13px 24px',
    borderBottom: `solid 1px ${theme.palette.primary.main}`,
  },

  infoBoxTitle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
  },

  infoBoxBody: {
    width: '100%',
    minWidth: 450,
    padding: '10px 24px',
  },

  infoInputContainer: {
    width: '100%',
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  label: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "500",
  },

  inputText: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "bold",
    maxWidth: 150,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 100,
      minWidth: 75,
    },
  },

  input: {
    padding: "13.5px 14px",
  },

  incomeInputText: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "bold",
    width: 120,
    margin: '0 2px',
  },

  growBox: {
    flexGrow: 1,
  },

  multiFieldContainer: {
    width: 245,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  topLabel: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "500",
    width: 120,
  },

  expenseBox: {
    width: '100%',
    overflow: 'auto',
    padding: '30px 24px',
    border: `solid 1px ${theme.palette.primary.main}`,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: "#FAFAFA",
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  expenseBoxInner: {
    width: '48%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: 20,
    },
  },

  expenseBoxFooter: {
    width: '100%',
    padding: '20px 24px 5px',
    border: `solid 1px ${theme.palette.primary.main}`,
    backgroundColor: "#FAFAFA",
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    borderTop: 'none',
  },

  assetDetailtopLabel: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "500",
    width: 150,
  },

  letterBox: {
    width: '49%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: 20,
    },
  },

  TncContainer: {
    width: '49%',
    maxHeight: 313,
    border: 'solid 1px #979797',
    padding: 25,
    borderRadius: 9,
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },


  tncContainerHidden: {
    width: '49%',
    // maxHeight: 313,
    border: 'solid 1px #979797',
    padding: 25,
    borderRadius: 9,
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  letterField: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "bold",
  },

  tncTitle: {
    color: "rgba(0,0,0,0.74)",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "400",
    marginBottom: 16,
  },

  lastDateSaved: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    marginBottom: 16,
  },


  tncText: {
    "& p": {
      color: "rgba(0,0,0,0.74)",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "400",
    }
  },

  btnBox: {
    width: '100%',
    padding: '25px 25px 15px',
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      padding: '25px 15px',
    },
  },

  btn: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    display: 'block',
    fontFamily: "Montserrat",
    fontSize: "14px",
    margin: 5,
    width: 230,
    height: 40,
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
      margin: '5px',
    },
  },

  resetBtn: {
    width: 230,
    height: 40,
    //color: '#727272',
    //borderColor: theme.palette.primary.main,
    fontWeight: '600',
    margin: '5px',
  },

  saveAsBtn: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },

  submitBtn: {
    color: theme.palette.primary.contrastText,
    fontWeight: '600',
    height: '40px',
  },

  dateBox: {
    width: '100%',
    border: 'solid 1px #979797',
    padding: '15px 15px',
    marginTop: 20,
    borderRadius: 4,
  },

  requiredText: {
    color: 'red',
    fontSize: 16,
  },

  noteText: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "500",
    textAlign: 'right',
    paddingBottom: 25,
    paddingRight: 25,
    marginTop: 0,
  },

  errorText: {
    color: "red",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "500",
    textAlign: 'center'
  }
}));