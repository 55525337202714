import React, { useEffect, useState, Fragment } from 'react';
import {
  Box, TextField, FormControl,
  FormLabel, RadioGroup, FormControlLabel, Radio, Button, Typography,
  TableContainer, Table, TableBody, TableHead, TableCell,
  TableRow, Paper, Tooltip, InputAdornment, Grid,
} from '@material-ui/core';
import { NextPrevButton } from '../NextPrevButton'
import { SaveAndExitPopup } from '../SaveAndExitPopup';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';
import { withStyles } from '@material-ui/core/styles';
import { checkDigitOnly, checkDigitWithDecimal, scrollTop } from '../../../helpers';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider, KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';

export const SectionE = (props) => {
  const { parentStyle } = props
  const classes = useStyles();
  const history = useHistory()


  const [sectionE, setSectionE] = useState({
    isUpdated: false,
    /*Borrower*/
    bMonthlyIncome: "",
    bEmployedByCompany: "",
    bCompantName1: "",
    bEmploymentDate1: "",
    bCompantName2: "",
    bEmploymentDate2: "",
    bCompantName3: "",
    bEmploymentDate3: "",
    bSelfEmployed: "",
    bOwnershipPercent: "",
    bIndependentContractor: "",
    /*Co-Borrower*/
    cbMonthlyIncome: "",
    cbEmployedByCompany: "",
    cbCompantName1: "",
    cbEmploymentDate1: "",
    cbCompantName2: "",
    cbEmploymentDate2: "",
    cbCompantName3: "",
    cbEmploymentDate3: "",
    cbSelfEmployed: "",
    cbOwnershipPercent: "",
    cbIndependentContractor: "",
    /*OTHER INCOME/EXPENSES*/
    oIEq1: "",
    oIEq2: "",
    oIEq3: "",
    oIEq4: "",
    oIEq5: "",
    oIEq6: "",
    oIEPaymentType1: "", oIEAmount1: "",
    oIEPaymentType2: "", oIEAmount2: "",
    oIEPaymentType3: "", oIEAmount3: "",
    /*HouseHold Income*/
    incomeH1: "", incomeH2: "", incomeH3: "", incomeH4: "", incomeH5: "", incomeH6: "", incomeH7: "", incomeH8: "", incomeH9: "", incomeH10: "", incomeH11: "", incomeHtotal: "", incomeHOtherText: "",
    /*HouseHold EXPENSES*/
    expensesH1: "", expensesH2: "", expensesH3: "", expensesH4: "", expensesH5: "", expensesH6: "", expensesH7: "", expensesH8: "", expensesH9: "", expensesH10: "", expensesH11: "", expensesHtotal: "", expensesHOtherText: "",
    /*HouseHold Assets*/
    assetsH1: "", assetsH2: "", assetsH3: "", assetsH4: "", assetsH5: "", assetsH6: "", assetsH7: "", assetsH8: "", assetsHtotal: "", assetsHOtherText,

    /*ADDITIONAL LIVING EXPENSES*/
    borrowerALE1: "", coBorrowerALE1: "", nonBorrowerALE1: "", totalALE1: "",
    borrowerALE2: "", coBorrowerALE2: "", nonBorrowerALE2: "", totalALE2: "",
    borrowerALE3: "", coBorrowerALE3: "", nonBorrowerALE3: "", totalALE3: "",
    borrowerALE4: "", coBorrowerALE4: "", nonBorrowerALE4: "", totalALE4: "",
    borrowerALE5: "", coBorrowerALE5: "", nonBorrowerALE5: "", totalALE5: "",
    borrowerALE6: "", coBorrowerALE6: "", nonBorrowerALE6: "", totalALE6: "",
    borrowerALE7: "", coBorrowerALE7: "", nonBorrowerALE7: "", totalALE7: "",
    borrowerALE8: "", coBorrowerALE8: "", nonBorrowerALE8: "", totalALE8: "",
    borrowerALE9: "", coBorrowerALE9: "", nonBorrowerALE9: "", totalALE9: "",
    borrowerALE10: "", coBorrowerALE10: "", nonBorrowerALE10: "", totalALE10: "",

  })

  const {
    /*Borrower*/
    bMonthlyIncome,
    bEmployedByCompany,
    bCompantName1,
    bEmploymentDate1,
    bCompantName2,
    bEmploymentDate2,
    bCompantName3,
    bEmploymentDate3,
    bSelfEmployed,
    bOwnershipPercent,
    bIndependentContractor,
    /*Co-Borrower*/
    cbMonthlyIncome,
    cbEmployedByCompany,
    cbCompantName1,
    cbEmploymentDate1,
    cbCompantName2,
    cbEmploymentDate2,
    cbCompantName3,
    cbEmploymentDate3,
    cbSelfEmployed,
    cbOwnershipPercent,
    cbIndependentContractor,
    /*OTHER INCOME/EXPENSES*/
    oIEq1,
    oIEq2,
    oIEq3,
    oIEq4,
    oIEq5,
    oIEq6,
    oIEPaymentType1, oIEAmount1,
    oIEPaymentType2, oIEAmount2,
    oIEPaymentType3, oIEAmount3,
    /*HouseHold Income*/
    incomeH1, incomeH2, incomeH3, incomeH4, incomeH5, incomeH6, incomeH7, incomeH8, incomeH9, incomeH10, incomeH11,
    incomeHtotal,
    incomeHOtherText,

    /*HouseHold EXPENSES*/
    expensesH1, expensesH2, expensesH3, expensesH4, expensesH5, expensesH6, expensesH7, expensesH8, expensesH9,
    expensesH10, expensesH11, expensesHtotal, expensesHOtherText,
    /*HouseHold Assets*/
    assetsH1, assetsH2, assetsH3, assetsH4, assetsH5, assetsH6, assetsH7, assetsH8, assetsHtotal, assetsHOtherText,
    /*ADDITIONAL LIVING EXPENSES*/
    borrowerALE1, coBorrowerALE1, nonBorrowerALE1, totalALE1,
    borrowerALE2, coBorrowerALE2, nonBorrowerALE2, totalALE2,
    borrowerALE3, coBorrowerALE3, nonBorrowerALE3, totalALE3,
    borrowerALE4, coBorrowerALE4, nonBorrowerALE4, totalALE4,
    borrowerALE5, coBorrowerALE5, nonBorrowerALE5, totalALE5,
    borrowerALE6, coBorrowerALE6, nonBorrowerALE6, totalALE6,
    borrowerALE7, coBorrowerALE7, nonBorrowerALE7, totalALE7,
    borrowerALE8, coBorrowerALE8, nonBorrowerALE8, totalALE8,
    borrowerALE9, coBorrowerALE9, nonBorrowerALE9, totalALE9,
    borrowerALE10, coBorrowerALE10, nonBorrowerALE10, totalALE10,

  } = sectionE

  const nullCheck = (str) => str ? str : ''
  useEffect(() => {
    if (props.data) {
      const { additionalLivingExpenses, borrowerIncome, coBorrowerIncome, householdAssets, householdExpenses,
        householdIncome, otherIncomeExpenses } = props.data

      const updateStateObj = {
        /*Borrower*/
        bMonthlyIncome: nullCheck(borrowerIncome.bMonthlyIncome),
        bEmployedByCompany: nullCheck(borrowerIncome.bEmployedByCompany),
        bCompantName1: nullCheck(borrowerIncome.bCompantName1),
        bEmploymentDate1: nullCheck(borrowerIncome.bEmploymentDate1),
        bCompantName2: nullCheck(borrowerIncome.bCompantName2),
        bEmploymentDate2: nullCheck(borrowerIncome.bEmploymentDate2),
        bCompantName3: nullCheck(borrowerIncome.bCompantName3),
        bEmploymentDate3: nullCheck(borrowerIncome.bEmploymentDate3),
        bSelfEmployed: nullCheck(borrowerIncome.bSelfEmployed),
        bOwnershipPercent: nullCheck(borrowerIncome.bOwnershipPercent),
        bIndependentContractor: nullCheck(borrowerIncome.bIndependentContractor),
        /*Co-Borrower*/
        cbMonthlyIncome: nullCheck(coBorrowerIncome.cbMonthlyIncome),
        cbEmployedByCompany: nullCheck(coBorrowerIncome.cbEmployedByCompany),
        cbCompantName1: nullCheck(coBorrowerIncome.cbCompantName1),
        cbEmploymentDate1: nullCheck(coBorrowerIncome.cbEmploymentDate1),
        cbCompantName2: nullCheck(coBorrowerIncome.cbCompantName2),
        cbEmploymentDate2: nullCheck(coBorrowerIncome.cbEmploymentDate2),
        cbCompantName3: nullCheck(coBorrowerIncome.cbCompantName3),
        cbEmploymentDate3: nullCheck(coBorrowerIncome.cbEmploymentDate3),
        cbSelfEmployed: nullCheck(coBorrowerIncome.cbSelfEmployed),
        cbOwnershipPercent: nullCheck(coBorrowerIncome.cbOwnershipPercent),
        cbIndependentContractor: nullCheck(coBorrowerIncome.cbIndependentContractor),
        /*OTHER INCOME/EXPENSES*/
        oIEq1: nullCheck(otherIncomeExpenses.oIEq1),
        oIEq2: nullCheck(otherIncomeExpenses.oIEq2),
        oIEq3: nullCheck(otherIncomeExpenses.oIEq3),
        oIEq4: nullCheck(otherIncomeExpenses.oIEq4),
        oIEq5: nullCheck(otherIncomeExpenses.oIEq5),
        oIEq6: nullCheck(otherIncomeExpenses.oIEq6),
        oIEPaymentType1: nullCheck(otherIncomeExpenses.oIEPaymentType1),
        oIEAmount1: nullCheck(otherIncomeExpenses.oIEAmount1),
        oIEPaymentType2: nullCheck(otherIncomeExpenses.oIEPaymentType2),
        oIEAmount2: nullCheck(otherIncomeExpenses.oIEAmount2),
        oIEPaymentType3: nullCheck(otherIncomeExpenses.oIEPaymentType3),
        oIEAmount3: nullCheck(otherIncomeExpenses.oIEAmount3),
        isUpdated: true
      }

      householdIncome.map((item) => {
        //"otherText":incomeHOtherText,
        if (item.otherText) {
          updateStateObj["incomeHOtherText"] = item.otherText
        }
        updateStateObj[item.key] = item.value ? item.value : ''
        return item
      })

      householdExpenses.map((item) => {
        if (item.otherText) {
          updateStateObj["expensesHOtherText"] = item.otherText
        }
        updateStateObj[item.key] = item.value ? item.value : ''
        return item
      })
      householdAssets.map((item) => {
        if (item.otherText) {
          updateStateObj["assetsHOtherText"] = item.otherText
        }
        updateStateObj[item.key] = item.value ? item.value : ''
        return item
      })
      if (Array.isArray(additionalLivingExpenses)) {
        additionalLivingExpenses.map((item) => {
          for (var key in item) {
            if (key !== "title") {
              updateStateObj[key] = item[key]
            }
          }
          return item
        })
      }
      setSectionE(updateStateObj)
    }
  }, [props.data])

  useEffect(() => {
    if (sectionE.isUpdated) {
      let formErrors = formValidationSectionE()
      setFormErrorMap(formErrors)
    }
  }, [sectionE.isUpdated])


  const handleChange = (event) => {
    let numberFieldList = ["bMonthlyIncome", "cbMonthlyIncome", "oIEq3", "oIEq4", "oIEq6",
      "oIEAmount1", "oIEAmount2", "oIEAmount3"]

    if (numberFieldList.indexOf(event.target.name) > -1) {
      if (checkDigitWithDecimal(event.target.value) === false) {
        return false
      }
    }
    setSectionE({ ...sectionE, [event.target.name]: event.target.value });
  };
  const handleDateChange = (inputName) => (date) => {
    setSectionE({ ...sectionE, [inputName]: date });
  }

  const handleSave = () => {
    let formErrors = formValidationSectionE()
    let formErrorLength = Object.keys(formErrors).length
    let sectionCompleteStatus = formErrorLength > 0 ? "incomplete" : "complete"
    props.status({ id: 4, sectionName: "section_E", status: sectionCompleteStatus })

    const data = {
      "section_E": {
        "borrowerIncome": {
          bMonthlyIncome: bMonthlyIncome,
          bEmployedByCompany: bEmployedByCompany,
          bCompantName1: bCompantName1,
          bEmploymentDate1: bEmploymentDate1,
          bCompantName2: bCompantName2,
          bEmploymentDate2: bEmploymentDate2,
          bCompantName3: bCompantName3,
          bEmploymentDate3: bEmploymentDate3,
          bSelfEmployed: bSelfEmployed,
          bOwnershipPercent: bOwnershipPercent,
          bIndependentContractor: bIndependentContractor,
        },
        "coBorrowerIncome": {
          cbMonthlyIncome: cbMonthlyIncome,
          cbEmployedByCompany: cbEmployedByCompany,
          cbCompantName1: cbCompantName1,
          cbEmploymentDate1: cbEmploymentDate1,
          cbCompantName2: cbCompantName2,
          cbEmploymentDate2: cbEmploymentDate2,
          cbCompantName3: cbCompantName3,
          cbEmploymentDate3: cbEmploymentDate3,
          cbSelfEmployed: cbSelfEmployed,
          cbOwnershipPercent: cbOwnershipPercent,
          cbIndependentContractor: cbIndependentContractor,
        },
        "otherIncomeExpenses": {
          oIEq1: oIEq1,
          oIEq2: oIEq2,
          oIEq3: oIEq3,
          oIEq4: oIEq4,
          oIEq5: oIEq5,
          oIEq6: oIEq6,
          oIEPaymentType1: oIEPaymentType1,
          oIEAmount1: oIEAmount1,
          oIEPaymentType2: oIEPaymentType2,
          oIEAmount2: oIEAmount2,
          oIEPaymentType3: oIEPaymentType3,
          oIEAmount3: oIEAmount3,
        },
        "householdIncome": [
          { title: "Monthly Gross Wages", key: "incomeH1", value: incomeH1 },
          { title: "Monthly Self-Employment Income", key: "incomeH2", value: incomeH2 },
          { title: "Monthly Overtime", key: "incomeH3", value: incomeH1 },
          { title: "Monthly Unemployment Income", key: "incomeH4", value: incomeH4 },
          { title: "Monthly Tips, Commissions, Bonus", key: "incomeH5", value: incomeH5 },
          { title: "Monthly Non-Taxable Social Security/SSDI", key: "incomeH6", value: incomeH6 },
          { title: "Monthly Taxable Social Security Benefits or Other Monthly Income from Annuities or Retirement Plans", key: "incomeH7", value: incomeH7 },
          { title: "Monthly Child Support/Alimony2", key: "incomeH8", value: incomeH8 },
          { title: "Monthly Gross Rents Received3", key: "incomeH9", value: incomeH9 },
          { title: "Monthly Food Stamps/Welfare", key: "incomeH10", value: incomeH10 },
          { title: "Monthly Other", "otherText": incomeHOtherText, key: "incomeH11", value: incomeH11 },
          { title: "Total Monthly Income", key: "incomeHtotal", value: incomeHtotal },
        ],

        "householdExpenses": [
          { title: "Monthly First Mortgage Principal and Interest Payment1", key: "expensesH1", value: expensesH1 },
          { title: "Monthly Second Mortgage Principal and Interest Payment1", key: "expensesH2", value: expensesH2 },
          { title: "Monthly Homeowners’ Insurance1", key: "expensesH3", value: expensesH3 },
          { title: "Monthly Homeowners’ Insurance1", key: "expensesH4", value: expensesH4 },
          { title: "Monthly HOA/Condo Fees/Co-OP Fees/Property Maintenance1", key: "expensesH5", value: expensesH5 },
          { title: "Monthly Mortgage Payments on Other Properties4", key: "expensesH6", value: expensesH6 },
          { title: "Monthly Credit Cards/Installment Loan(s) (total minimum payment)", key: "expensesH7", value: expensesH7 },
          { title: "Monthly Child Support/Alimony Payments", key: "expensesH8", value: expensesH8 },
          { title: "Monthly Auto Lease/Payment", key: "expensesH9", value: expensesH9 },
          { title: "Monthly Other", "otherText": expensesHOtherText, key: "expensesH10", value: expensesH10 },
          { title: "Total Monthly Expenses/Debt", key: "expensesHtotal", value: expensesHtotal },
        ],
        "householdAssets": [
          { title: "Checking Account(s)", key: "assetsH1", value: assetsH1 },
          { title: "Checking Account(s)", key: "assetsH2", value: assetsH2 },
          { title: "Savings/Money Market", key: "assetsH3", value: assetsH3 },
          { title: "CDs", key: "assetsH4", value: assetsH4 },
          { title: "Stocks/Bonds", key: "assetsH5", value: assetsH5 },
          { title: "Other Cash on Hand", key: "assetsH6", value: assetsH6 },
          { title: "Other Real Estate(estimated value)", key: "assetsH7", value: assetsH7 },
          { title: "Other", "otherText": assetsHOtherText, key: "assetsH8", value: assetsH8 },
          { title: "Total Assets", key: "assetsHtotal", value: assetsHtotal },
        ],
        "additionalLivingExpenses": [ //ADDITIONAL LIVING EXPENSES
          { title: "Tuition/School", borrowerALE1: borrowerALE1, "coBorrowerALE1": coBorrowerALE1, "nonBorrowerALE1": nonBorrowerALE1, "totalALE1": totalALE1 },
          { title: "ChildCare(daycare,babysitting)", borrowerALE2: borrowerALE2, "coBorrowerALE2": coBorrowerALE2, "nonBorrowerALE2": nonBorrowerALE2, "totalALE2": totalALE2 },
          { title: "Automobile Expenses (insurance/maintenance/gas)", borrowerALE3: borrowerALE3, "coBorrowerALE3": coBorrowerALE3, "nonBorrowerALE3": nonBorrowerALE3, "totalALE3": totalALE3 },
          { title: "Food", borrowerALE4: borrowerALE4, "coBorrowerALE4": coBorrowerALE4, "nonBorrowerALE4": nonBorrowerALE4, "totalALE4": totalALE4 },
          { title: "Life Insurance Premium", borrowerALE5: borrowerALE5, "coBorrowerALE5": coBorrowerALE5, "nonBorrowerALE5": nonBorrowerALE5, "totalALE5": totalALE5 },
          { title: "Medical", borrowerALE6: borrowerALE6, "coBorrowerALE6": coBorrowerALE6, "nonBorrowerALE6": nonBorrowerALE6, "totalALE6": totalALE6 },
          { title: "Utilities", borrowerALE7: borrowerALE7, "coBorrowerALE7": coBorrowerALE7, "nonBorrowerALE7": nonBorrowerALE7, "totalALE7": totalALE7 },
          { title: "Clothing", borrowerALE8: borrowerALE8, "coBorrowerALE8": coBorrowerALE8, "nonBorrowerALE8": nonBorrowerALE8, "totalALE8": totalALE8 },
          { title: "Cable,Internet,Phone", borrowerALE9: borrowerALE9, "coBorrowerALE9": coBorrowerALE9, "nonBorrowerALE9": nonBorrowerALE9, "totalALE9": totalALE9 },
          { title: "Total LivingExpenses", borrowerALE10: borrowerALE10, "coBorrowerALE10": coBorrowerALE10, "nonBorrowerALE10": nonBorrowerALE10, "totalALE10": totalALE10 },
        ],
        "sectionStatus": {
          "status": sectionCompleteStatus,
          "sectionName": "Section E:Income/Expenses For Household"
        },

      }
    }
    console.log("======section E=====", data)
    props.saveFromParent(data)
  }
  const saveAndExitAction = (action) => {
    if (action === "leave") {
      handleSave()
      history.push('/Dashboard')
    }
  }
  const handleChangeAmount = (event) => {    
    if (checkDigitWithDecimal(event.target.value) === false) {
      return false
    } else {      
      setSectionE({ ...sectionE, [event.target.name]: event.target.value });      
    }
  }

  const reducer = (accumulator, currentValue) => {    
    if (parseFloat(currentValue) > 0) {
      return parseFloat(accumulator) + parseFloat(currentValue)
    } else {
      return parseFloat(accumulator) + 0
    }
  };
  
  const getTotal = (tableName) => {
    if (tableName === 'houseHoldIncome') {
      let houseHoldIncome = [incomeH1, incomeH2, incomeH3, incomeH4, incomeH5,
        incomeH6, incomeH7, incomeH8, incomeH9, incomeH10, incomeH11]
      let total = houseHoldIncome.reduce(reducer, 0)
      setSectionE({ ...sectionE, ["incomeHtotal"]: total });      
    } else if(tableName === 'houseHoldExpenses'){
      let houseHoldExpenses =[expensesH1, expensesH2, expensesH3, expensesH4, expensesH5, 
        expensesH6, expensesH7, expensesH8, expensesH9,expensesH10, expensesH11];
        let total = houseHoldExpenses.reduce(reducer, 0)
      setSectionE({ ...sectionE, ["expensesHtotal"]: total }); 
    } else if(tableName === 'houseHoldAssets'){
      let houseHoldAssets = [assetsH1, assetsH2, assetsH3, assetsH4, assetsH5, assetsH6, assetsH7, assetsH8]
        let total = houseHoldAssets.reduce(reducer, 0)
        setSectionE({ ...sectionE, ["assetsHtotal"]: total });
    }    
  }
  const totalLivingExpenses=(tableName)=>{
    if (tableName === 'borrowerALE') {
      let borrowerALE = [borrowerALE1, borrowerALE2,borrowerALE3,borrowerALE4,borrowerALE5,
        borrowerALE6,borrowerALE7,borrowerALE8,borrowerALE9]
      let total = borrowerALE.reduce(reducer, 0)
      setSectionE({ ...sectionE, ["borrowerALE10"]: total });      
    } else if (tableName === 'coBorrowerALE') {
      let coBorrowerALE = [coBorrowerALE1,coBorrowerALE2,coBorrowerALE3,coBorrowerALE4,coBorrowerALE5,
        coBorrowerALE6,coBorrowerALE7,coBorrowerALE8,coBorrowerALE9]
      let total = coBorrowerALE.reduce(reducer, 0)
      setSectionE({ ...sectionE, ["coBorrowerALE10"]: total });      
    } else if (tableName === 'nonBorrowerALE') {
      let nonBorrowerALE = [nonBorrowerALE1,nonBorrowerALE2,nonBorrowerALE3,nonBorrowerALE4,nonBorrowerALE5,
        nonBorrowerALE6,nonBorrowerALE7,nonBorrowerALE8,nonBorrowerALE9]
      let total = nonBorrowerALE.reduce(reducer, 0)
      setSectionE({ ...sectionE, ["nonBorrowerALE10"]: total });      
    } 
  }

  const renderHouseHoldIncomeTable = () => {
    return (<TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell colSpan={2} align="center">HOUSEHOLD INCOME </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataHI.map((row, index) => (
            <StyledTableRow key={`${row.key}_${index}`}>
              <StyledTableCell align="left">{row.title}
                {row.key === "incomeH11" && <span><input name={"incomeHOtherText"} value={incomeHOtherText} onChange={handleChange} className={classes.otherInputBox} type="text" /></span>}
              </StyledTableCell>
              <StyledTableCell>
                {row.key === "incomeHtotal" ? (<input name={row.key} value={incomeHtotal} onChange={handleChangeAmount}
                  className={classes.tableInputBox} type="text" readOnly={true} />) :
                  (<TextField size="small" onChange={handleChangeAmount}
                    value={getTableInputValue(row.key)}
                    name={row.key}
                    onBlurCapture={() => getTotal("houseHoldIncome")}                    
                    color='secondary' variant="outlined"
                    className={classes.inputFieldTable}
                    inputProps={{
                      className: classes.inputTextTable,
                      maxLength: 10,
                      form: { autocomplete: 'off', },
                    }}
                  />
                  )}
              </StyledTableCell>
            </StyledTableRow>))}
        </TableBody>
      </Table>
    </TableContainer>)
  }
  const renderHouseHoldExpensesTable = () => {
    return (<TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell colSpan={2} align="center">HOUSEHOLD EXPENSES/DEBT</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataHExpenses.map((row, index) => (
            <StyledTableRow key={`${row.key}_${index}`}>
              <StyledTableCell align="left">{row.title}
                {row.key === "expensesH10" && <span><input name={"expensesHOtherText"} value={expensesHOtherText} onChange={handleChange} className={classes.otherInputBox} type="text" /></span>}
              </StyledTableCell>
              {/* <StyledTableCell ><input name={row.key} value={getTableInputValue(row.key)} onChange={handleChangeAmount} className={classes.tableInputBox} type="text" /></StyledTableCell> */}
              <StyledTableCell>
                {row.key === "expensesHtotal" ? (<input name={row.key} value={expensesHtotal} className={classes.tableInputBox} type="text" readOnly={true} />) :
                  (<TextField size="small" onChange={handleChangeAmount}
                    value={getTableInputValue(row.key)}
                    name={row.key}
                    onBlurCapture={() => getTotal("houseHoldExpenses")}                    
                    color='secondary' variant="outlined"
                    className={classes.inputFieldTable}
                    inputProps={{
                      className: classes.inputTextTable,
                      maxLength: 10,
                      form: { autocomplete: 'off', },
                    }}
                  />
                  )}
              </StyledTableCell>
            </StyledTableRow>))}
        </TableBody>
      </Table>
    </TableContainer>)
  }

  const renderAssetsTable = () => {
    return (<TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell colSpan={2} align="center">HOUSEHOLD ASSETS
            <Typography>associatedwiththeproperty and/orborrower(s) excluding retirement funds</Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assetsData.map((row, index) => (
            <StyledTableRow key={`${row.key}_${index}`}>
              <StyledTableCell align="left">{row.title}
                {row.key === "assetsH8" && <span><input name={"assetsHOtherText"} value={assetsHOtherText} onChange={handleChange} className={classes.otherInputBox} type="text" /></span>}
              </StyledTableCell>
              {/* <StyledTableCell ><input name={row.key} value={getTableInputValue(row.key)} onChange={handleChangeAmount} className={classes.tableInputBox} type="text" /></StyledTableCell> */}
              <StyledTableCell>
                {row.key === "assetsHtotal" ? (<input name={row.key} value={assetsHtotal} className={classes.tableInputBox} type="text" readOnly={true} />) :
                  (<TextField size="small" onChange={handleChangeAmount}
                    value={getTableInputValue(row.key)}
                    name={row.key}
                    onBlurCapture={() => getTotal("houseHoldAssets")}                    
                    color='secondary' variant="outlined"
                    className={classes.inputFieldTable}
                    inputProps={{
                      className: classes.inputTextTable,
                      maxLength: 10,
                      form: { autocomplete: 'off', },
                    }}
                  />
                  )}
              </StyledTableCell>
            
            
            </StyledTableRow>))}
        </TableBody>
      </Table>
    </TableContainer>)
  }
  const renderLivingExpensesTable = () => {
    return (<TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell colSpan={5} align="center"> ADDITIONAL LIVING EXPENSES</StyledTableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center"> </TableCell>
            <TableCell align="center">Borrower</TableCell>
            <TableCell align="center">Co-Borrower</TableCell>
            <TableCell align="center">Non-Borrower5</TableCell>
            <TableCell align="center">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {addLivingExpensesData.map((row, index) => (
            <StyledTableRow key={`${row.key}_${index}`}>
              <StyledTableCell width={"40%"} align="left">{row.title}</StyledTableCell>
              <StyledTableCell width={"15%"}>
                {/* <input name={row.borrowerkey} value={getTableInputValue(row.borrowerkey)} onChange={handleChangeAmount} className={classes.tableInputBox} type="text" /> */}
                {row.borrowerkey === "borrowerALE10" ? (<input name={row.borrowerkey} value={borrowerALE10} className={classes.tableInputBox} type="text" readOnly={true} />) :
                  (<TextField size="small" onChange={handleChangeAmount}
                    value={getTableInputValue(row.borrowerkey) || ''}
                    name={row.borrowerkey}
                    onBlurCapture={() => totalLivingExpenses("borrowerALE")}                    
                    color='secondary' variant="outlined"
                    className={classes.inputFieldTable}
                    inputProps={{
                      className: classes.inputTextTable,
                      maxLength: 10,
                      form: { autocomplete: 'off', },
                    }}
                  />
                  )}
              </StyledTableCell>
              <StyledTableCell width={"15%"}>
                {/* <input name={row.coBorrowerkey} value={getTableInputValue(row.coBorrowerkey)} onChange={handleChangeAmount} className={classes.tableInputBox} type="text" /> */}
              {row.coBorrowerkey === "coBorrowerALE10" ? (<input name={row.coBorrowerkey} value={coBorrowerALE10} className={classes.tableInputBox} type="text" readOnly={true} />) :
                  (<TextField size="small" onChange={handleChangeAmount}
                    value={getTableInputValue(row.coBorrowerkey) || ''}
                    name={row.coBorrowerkey}
                    onBlurCapture={() => totalLivingExpenses("coBorrowerALE")}                    
                    color='secondary' variant="outlined"
                    className={classes.inputFieldTable}
                    inputProps={{
                      className: classes.inputTextTable,
                      maxLength: 10,
                      form: { autocomplete: 'off', },
                    }}
                  />
                  )}  
              
              </StyledTableCell>
              <StyledTableCell width={"15%"}>
                {/* <input name={row.nonBorrowerKey} value={getTableInputValue(row.nonBorrowerKey)} onChange={handleChangeAmount} className={classes.tableInputBox} type="text" /> */}
                
                {row.nonBorrowerKey === "nonBorrowerALE10" ? (<input name={row.nonBorrowerKey} value={nonBorrowerALE10} className={classes.tableInputBox} type="text" readOnly={true} />) :
                  (<TextField size="small" onChange={handleChangeAmount}
                    value={getTableInputValue(row.nonBorrowerKey) || ''}
                    name={row.nonBorrowerKey}
                    onBlurCapture={() => totalLivingExpenses("nonBorrowerALE")}                    
                    color='secondary' variant="outlined"
                    className={classes.inputFieldTable}
                    inputProps={{
                      className: classes.inputTextTable,
                      maxLength: 10,
                      form: { autocomplete: 'off', },
                    }} 
                    />
                  )} 
              </StyledTableCell>              
              <StyledTableCell width={"15%"}>
                {/* <input name={row.totalKey} value={getTableInputValue(row.totalKey)} onChange={handleChangeAmount} className={classes.tableInputBox} type="text" /> */}
                <input name={row.totalKey} 
                //value={getTableInputValue(row.totalKey)} 
                value={getRowTotal(row.borrowerkey,row.coBorrowerkey,row.nonBorrowerKey)} 
                onChange={handleChangeAmount} 
                className={classes.tableInputBox} 
                type="text" />
                </StyledTableCell>
            </StyledTableRow>))}
        </TableBody>
      </Table>
    </TableContainer>)
  }

  const getRowTotal =(bor,cob,nbor)=>{    
    let borrowerAmt = sectionE[bor]?sectionE[bor]:0;
    let coBorrowerAmt = sectionE[cob]?sectionE[cob]:0;
    let nonBorrowerAmt = sectionE[nbor]?sectionE[nbor]:0;
    return parseFloat(borrowerAmt)+parseFloat(coBorrowerAmt)+parseFloat(nonBorrowerAmt)
  }

  const getTableInputValue = (itemKey) => {
    return sectionE[itemKey]?sectionE[itemKey]:'';
  }
  /*======Next Prev Work section wise=====*/
  const subSteps = ["BorrowerIncome", "OtherIncome", "HouseholdIncome",
    "HouseholdExpenses", "HouseholdAssets", "AdditionalLivingExpenses"]
  const subStepsLength = subSteps.length - 1
  const [activeSubStep, setActiveSubStep] = useState(0);
  const nextSubStep = () => {
    if (activeSubStep < subStepsLength) {
      setActiveSubStep(activeSubStep + 1)
      scrollTop()
    } else {
      // go to next
      handleSave()
    }
  }
  const backSubStep = () => {
    if (activeSubStep !== 0 && activeSubStep <= subStepsLength) {
      setActiveSubStep(activeSubStep - 1)
    } else if (activeSubStep === 0) {
      props.previous()
    }
    scrollTop()
  }

  /*Error handler*/
  const [formErrorMap, setFormErrorMap] = useState([])
  const isEmpty = (str) => str ? false : true

  const formValidationSectionE = () => {
    const formErrorMessage = {}
    /*Borrower Income*/
    if (isEmpty(bMonthlyIncome)) {
      formErrorMessage["bMonthlyIncome"] = "This is required field"
    }
    if (isEmpty(bEmployedByCompany)) {
      formErrorMessage["bEmployedByCompany"] = "This is required field"
    } else if (bEmployedByCompany === 'Yes') {
      if (isEmpty(bCompantName1)) {
        formErrorMessage["bCompantName1"] = "This is required field"
      }
      if (isEmpty(bEmploymentDate1)) {
        formErrorMessage["bEmploymentDate1"] = "This is required field"
      }
    }
    if (isEmpty(bSelfEmployed)) {
      formErrorMessage["bSelfEmployed"] = "This is required field"
    } else if (bSelfEmployed === 'Yes') {
      if (isEmpty(bOwnershipPercent)) {
        formErrorMessage["bOwnershipPercent"] = "This is required field"
      }
    }
    if (isEmpty(bIndependentContractor)) {
      formErrorMessage["bIndependentContractor"] = "This is required field"
    }
    /*co Borrower Income*/
    //optional
    /*Other Income/Expenses*/
    if (isEmpty(oIEq1)) {
      formErrorMessage["oIEq1"] = "This is required field"
    } else if (oIEq1 === "Yes") {
      if (isEmpty(oIEq2)) {
        formErrorMessage["oIEq2"] = "This is required field"
      }
      if (isEmpty(oIEq3)) {
        formErrorMessage["oIEq3"] = "This is required field"
      }
      if (isEmpty(oIEq4)) {
        formErrorMessage["oIEq4"] = "This is required field"
      }
      if (isEmpty(oIEq5)) {
        formErrorMessage["oIEq5"] = "This is required field"
      } else if (oIEq5 === 'Yes') {
        if (isEmpty(oIEq6)) {
          formErrorMessage["oIEq6"] = "This is required field"
        }
      }
      if (isEmpty(oIEPaymentType1)) {
        formErrorMessage["oIEPaymentType1"] = "This is required field"
      }
      if (isEmpty(oIEAmount1)) {
        formErrorMessage["oIEAmount1"] = "This is required field"
      }
      /*HOUSEHOLD INCOME*/
      // if (isEmpty(incomeH1)) {
      //   formErrorMessage["incomeH1"] = "This is required field"
      // }
      /*HOUSEHOLD EXPENSES/DEBT*/
      // if (isEmpty(expensesH1)) {
      //   formErrorMessage["expensesH1"] = "This is required field"
      // }
      /*HOUSEHOLD ASSETS*/
      // if (isEmpty(assetsH1)) {
      //   formErrorMessage["assetsH1"] = "This is required field"
      // }
      /*ADDITIONAL LIVING EXPENSES*/
      //to do

    }


    //console.log("===form state E===",sectionE)
    //setFormErrorMap(formErrorMessage)
    return formErrorMessage;
  }


  const errorHandler = (field) => {
    if (formErrorMap[field]) {
      let message = formErrorMap[field]==="invalid"?"Invalid Data, Please check the value" :"*"
      return message
    } else {
      return false
    }
  }

  const radioErrorHandler = (field) => {
    if (errorHandler(field)) {
      return (<span className={parentStyle.errorMessage}>{errorHandler(field)}</span>)
    }
  }
  /*Error handler end*/

  return (
    <Box className={classes.root}>
      <Box style={{ marginTop: "-85px" }}>
        <SaveAndExitPopup actionCallBack={saveAndExitAction}></SaveAndExitPopup>
      </Box>
      <Box height="90%" style={{ paddingTop: "25px" }}>
        <Typography className={classes.title}>Income/Expenses For Household</Typography>
        <form className={classes.formStyle}>

          {activeSubStep === 0 && <Fragment>
            <Box className={classes.inputBox}>
              <Typography className={classes.subtitle}>Borrower Income</Typography>
              <Typography className={parentStyle.questionText}>Borrower Monthly Income</Typography>
              <TextField
                size="small"
                error={errorHandler('bMonthlyIncome') !== false}
                helperText={errorHandler('bMonthlyIncome')}
                onChange={handleChange}
                value={bMonthlyIncome}
                name="bMonthlyIncome"
                //label="Borrower Monthly Income $"
                color='secondary'
                variant="outlined"
                //className={classes.inputField}
                className={parentStyle.inputFieldNumber}
                InputProps={{
                  //startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  classes: { root: parentStyle.inputText }
                }}
              />
              <FormControl component="fieldset" className={classes.formControl} >
                <FormLabel component="legend" className={parentStyle.formLabel}>I am employed by a company {radioErrorHandler("bEmployedByCompany")} </FormLabel>
                <RadioGroup name='bEmployedByCompany' value={bEmployedByCompany} onChange={handleChange}>
                  <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
              {bEmployedByCompany === "Yes" ? (<Fragment>
                <Box className={classes.flexBox}>
                  <TextField
                    size="small"
                    error={errorHandler('bCompantName1') !== false}
                    helperText={errorHandler('bCompantName1')}
                    onChange={handleChange}
                    value={bCompantName1}
                    name="bCompantName1"
                    label="First Company Name"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />
                  
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker 
                      views={['year', 'month', 'date']}                     
                      size="small"
                      error={errorHandler('bEmploymentDate1') !== false}
                      helperText={errorHandler('bEmploymentDate1')}
                      color='secondary' autoOk={true}
                      //disableToolbar
                      //minDate={new Date()}
                      variant="inline" inputVariant="outlined" format="MM/dd/yyyy" fullWidth

                      id="id-bEmploymentDate1"
                      label="First Employment start date"
                      value={bEmploymentDate1}
                      name="bEmploymentDate1"
                      onChange={handleDateChange("bEmploymentDate1")}
                      KeyboardButtonProps={{ 'aria-label': 'change date', }}
                      placeholder={"MM/dd/yyyy"}
                      className={parentStyle.inputField}
                      InputProps={{ classes: { root: parentStyle.inputText } }}
                      InputLabelProps={{ shrink: true, }}
                    />
                  </MuiPickersUtilsProvider>
                </Box>

                <Box className={classes.flexBox}>
                  <TextField
                    size="small"
                    error={errorHandler('bCompantName2') !== false}
                    helperText={errorHandler('bCompantName2')}
                    onChange={handleChange}
                    value={bCompantName2}
                    name="bCompantName2"
                    label="Second Company Name"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />
                  {/* <TextField
                    size="small"
                    error={errorHandler('bEmploymentDate2')!==false}
                    helperText={errorHandler('bEmploymentDate2')}
                    onChange={handleChange}
                    value={bEmploymentDate2}
                    name="bEmploymentDate2"
                    label="Second Employment start date"
                    placeholder="dd/mm/yyyy"
                    InputLabelProps={{shrink: true, }}
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  /> */}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      //disabled={isRestrict}
                      views={['year', 'month', 'date']}
                      size="small"
                      error={errorHandler('bEmploymentDate2') !== false}
                      helperText={errorHandler('bEmploymentDate2')}
                      color='secondary' autoOk={true}
                      //disableToolbar
                      //minDate={new Date()}
                      variant="inline" inputVariant="outlined" format="MM/dd/yyyy" fullWidth

                      id="id-bEmploymentDate2"
                      label="Second Employment start date"
                      value={bEmploymentDate2}
                      name="bEmploymentDate2"
                      onChange={handleDateChange("bEmploymentDate2")}

                      KeyboardButtonProps={{ 'aria-label': 'change date', }}
                      placeholder={"MM/dd/yyyy"}
                      className={parentStyle.inputField}
                      InputProps={{ classes: { root: parentStyle.inputText } }}
                      InputLabelProps={{ shrink: true, }}
                    />
                  </MuiPickersUtilsProvider>
                </Box>

                <Box className={classes.flexBox}>
                  <TextField
                    size="small"
                    error={errorHandler('bCompantName3') !== false}
                    helperText={errorHandler('bCompantName3')}
                    onChange={handleChange}
                    value={bCompantName3}
                    name="bCompantName3"
                    label="Third Company Name"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />
                  {/* <TextField
                    size="small"
                    error={errorHandler('bEmploymentDate3')!==false}
                    helperText={errorHandler('bEmploymentDate3')}
                    onChange={handleChange}
                    value={bEmploymentDate3}
                    name="bEmploymentDate3"
                    label="Third Employment start date"
                    placeholder="dd/mm/yyyy"
                    InputLabelProps={{shrink: true, }}
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  /> */}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      //disabled={isRestrict}
                      views={['year', 'month', 'date']}
                      size="small"
                      error={errorHandler('bEmploymentDate3') !== false}
                      helperText={errorHandler('bEmploymentDate3')}
                      color='secondary' autoOk={true}
                      //disableToolbar
                      //minDate={new Date()}
                      variant="inline" inputVariant="outlined" format="MM/dd/yyyy" fullWidth

                      id="id-bEmploymentDate3"
                      label="Third Employment start date"
                      value={bEmploymentDate3}
                      name="bEmploymentDate3"
                      onChange={handleDateChange("bEmploymentDate3")}

                      KeyboardButtonProps={{ 'aria-label': 'change date', }}
                      placeholder={"MM/dd/yyyy"}
                      className={parentStyle.inputField}
                      InputProps={{ classes: { root: parentStyle.inputText } }}
                      InputLabelProps={{ shrink: true, }}
                    />
                  </MuiPickersUtilsProvider>
                </Box>
              </Fragment>) : null}

              <FormControl component="fieldset" className={classes.formControl} >
                <FormLabel component="legend" className={parentStyle.formLabel}>I am self-employed{radioErrorHandler("bSelfEmployed")}</FormLabel>
                <RadioGroup name='bSelfEmployed' value={bSelfEmployed} onChange={handleChange}>
                  <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
              {bSelfEmployed === "Yes" && <Fragment>
                <Typography className={parentStyle.questionText}>Percent of ownership %</Typography>
                <TextField
                  size="small"
                  error={errorHandler('bOwnershipPercent') !== false}
                  helperText={errorHandler('bOwnershipPercent')}
                  onChange={handleChange}
                  value={bOwnershipPercent}
                  name="bOwnershipPercent"
                  //label="Percent of ownership %"
                  color='secondary'
                  variant="outlined"
                  className={parentStyle.inputFieldNumber}
                  InputProps={{
                    classes: { root: parentStyle.inputText }
                  }}
                /></Fragment>}

              <FormControl component="fieldset" className={classes.formControl} >
                <FormLabel component="legend" className={parentStyle.formLabel}>I am Independent contractor {radioErrorHandler("bIndependentContractor")}</FormLabel>
                <RadioGroup name='bIndependentContractor' value={bIndependentContractor} onChange={handleChange}>
                  <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>

            </Box>

            <Box className={classes.inputBox}>
              <Typography className={classes.subtitle}>Co-Borrower Income</Typography>
              <Typography className={parentStyle.questionText}>Co-Borrower Monthly Income $</Typography>
              <TextField
                size="small"
                error={errorHandler('cbMonthlyIncome') !== false}
                helperText={errorHandler('cbMonthlyIncome')}
                onChange={handleChange}
                value={cbMonthlyIncome}
                name="cbMonthlyIncome"
                //label="Co-Borrower Monthly Income $"
                color='secondary'
                variant="outlined"
                className={parentStyle.inputFieldNumber}
                InputProps={{
                  //startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  classes: { root: parentStyle.inputText }
                }}
              />
              <FormControl component="fieldset" className={classes.formControl} >
                <FormLabel component="legend" className={parentStyle.formLabel}>I am employed by a company{radioErrorHandler("cbEmployedByCompany")}</FormLabel>
                <RadioGroup name='cbEmployedByCompany' value={cbEmployedByCompany} onChange={handleChange}>
                  <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
              {cbEmployedByCompany === "Yes" ? (<Fragment>
                <Box className={classes.flexBox}>
                  <TextField
                    size="small"
                    error={errorHandler('cbCompantName1') !== false}
                    helperText={errorHandler('cbCompantName1')}
                    onChange={handleChange}
                    value={cbCompantName1}
                    name="cbCompantName1"
                    label="First Company Name"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />
                  {/* <TextField
                  size="small"
                  error={errorHandler('cbEmploymentDate1')!==false}
                  helperText={errorHandler('cbEmploymentDate1')}
                    onChange={handleChange}
                    value={cbEmploymentDate1}
                    name="cbEmploymentDate1"
                    label="First Employment start date"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  /> */}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      //disabled={isRestrict}
                      views={['year', 'month', 'date']}
                      size="small"
                      error={errorHandler('cbEmploymentDate1') !== false}
                      helperText={errorHandler('cbEmploymentDate1')}
                      color='secondary' autoOk={true}
                      //disableToolbar
                      //minDate={new Date()}
                      variant="inline" inputVariant="outlined" format="MM/dd/yyyy" fullWidth

                      id="id-cbEmploymentDate1"
                      label="First Employment start date"
                      value={cbEmploymentDate1}
                      name="cbEmploymentDate1"
                      onChange={handleDateChange("cbEmploymentDate1")}

                      KeyboardButtonProps={{ 'aria-label': 'change date', }}
                      placeholder={"MM/dd/yyyy"}
                      className={parentStyle.inputField}
                      InputProps={{ classes: { root: parentStyle.inputText } }}
                      InputLabelProps={{ shrink: true, }}
                    />
                  </MuiPickersUtilsProvider>
                </Box>

                <Box className={classes.flexBox}>
                  <TextField
                    size="small"
                    error={errorHandler('cbCompantName2') !== false}
                    helperText={errorHandler('cbCompantName2')}
                    onChange={handleChange}
                    value={cbCompantName2}
                    name="cbCompantName2"
                    label="Second Company Name"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />
                  {/* <TextField
                  size="small"
                  error={errorHandler('cbEmploymentDate2')!==false}
                  helperText={errorHandler('cbEmploymentDate2')}
                    onChange={handleChange}
                    value={cbEmploymentDate2}
                    name="cbEmploymentDate2"
                    label="Second Employment start date"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  /> */}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      //disabled={isRestrict}
                      views={['year', 'month', 'date']}
                      size="small"
                      error={errorHandler('cbEmploymentDate2') !== false}
                      helperText={errorHandler('cbEmploymentDate2')}
                      color='secondary' autoOk={true}
                      //disableToolbar
                      //minDate={new Date()}
                      variant="inline" inputVariant="outlined" format="MM/dd/yyyy" fullWidth

                      id="id-cbEmploymentDate2"
                      label="Second Employment start date"
                      value={cbEmploymentDate2}
                      name="cbEmploymentDate2"
                      onChange={handleDateChange("cbEmploymentDate2")}

                      KeyboardButtonProps={{ 'aria-label': 'change date', }}
                      placeholder={"MM/dd/yyyy"}
                      className={parentStyle.inputField}
                      InputProps={{ classes: { root: parentStyle.inputText } }}
                      InputLabelProps={{ shrink: true, }}
                    />
                  </MuiPickersUtilsProvider>
                </Box>

                <Box className={classes.flexBox}>
                  <TextField
                    size="small"
                    error={errorHandler('cbCompantName3') !== false}
                    helperText={errorHandler('cbCompantName3')}
                    onChange={handleChange}
                    value={cbCompantName3}
                    name="cbCompantName3"
                    label="Third Company Name"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />
                  {/* <TextField
                  size="small"
                  error={errorHandler('cbEmploymentDate3')!==false}
                  helperText={errorHandler('cbEmploymentDate3')}
                    onChange={handleChange}
                    value={cbEmploymentDate3}
                    name="cbEmploymentDate3"
                    label="Third Employment start date"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  /> */}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      //disabled={isRestrict}
                      views={['year', 'month', 'date']}
                      size="small"
                      error={errorHandler('cbEmploymentDate3') !== false}
                      helperText={errorHandler('cbEmploymentDate3')}
                      color='secondary' autoOk={true}
                      //disableToolbar
                      //minDate={new Date()}
                      variant="inline" inputVariant="outlined" format="MM/dd/yyyy" fullWidth

                      id="id-cbEmploymentDate3"
                      label="Third Employment start date"
                      value={cbEmploymentDate3}
                      name="cbEmploymentDate3"
                      onChange={handleDateChange("cbEmploymentDate3")}

                      KeyboardButtonProps={{ 'aria-label': 'change date', }}
                      placeholder={"MM/dd/yyyy"}
                      className={parentStyle.inputField}
                      InputProps={{ classes: { root: parentStyle.inputText } }}
                      InputLabelProps={{ shrink: true, }}
                    />
                  </MuiPickersUtilsProvider>
                </Box>
              </Fragment>) : null}

              <FormControl component="fieldset" className={classes.formControl} >
                <FormLabel component="legend" className={parentStyle.formLabel}>I am self-employed{radioErrorHandler("cbSelfEmployed")} </FormLabel>
                <RadioGroup name='cbSelfEmployed' value={cbSelfEmployed} onChange={handleChange}>
                  <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
              {cbSelfEmployed === "Yes" && <Fragment>
                <Typography className={parentStyle.questionText}>Percent of ownership %</Typography>
                <TextField
                  size={"small"}
                  error={errorHandler('cbOwnershipPercent') !== false}
                  helperText={errorHandler('cbOwnershipPercent')}
                  onChange={handleChange}
                  value={cbOwnershipPercent}
                  name="cbOwnershipPercent"
                  //label="Percent of ownership %"
                  color='secondary'
                  variant="outlined"
                  className={parentStyle.inputFieldNumber}
                  InputProps={{
                    classes: { root: parentStyle.inputText }
                  }}
                /></Fragment>}

              <FormControl component="fieldset" className={classes.formControl} >
                <FormLabel component="legend" className={parentStyle.formLabel}>I am Independent contractor{radioErrorHandler("cbIndependentContractor")} </FormLabel>
                <RadioGroup name='cbIndependentContractor' value={cbIndependentContractor} onChange={handleChange}>
                  <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>

            </Box>
          </Fragment>}
          {activeSubStep === 1 && <Fragment>
            <Box className={classes.inputBox}>
              <Typography className={classes.subtitle}>Other Income/Expenses </Typography>
              <FormControl component="fieldset" className={classes.formControl} >
                <FormLabel component="legend" className={parentStyle.formLabel}>Is there a person not on the mortgage note who lives
                in the residence and contributes financially to the household?{radioErrorHandler("oIEq1")} </FormLabel>
                <RadioGroup name='oIEq1' value={oIEq1} onChange={handleChange}>
                  <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
              {oIEq1 === "Yes" && <Box>
                {/* <Typography>First and Last Name</Typography> */}
                <TextField
                  size="small"
                  error={errorHandler('oIEq2') !== false}
                  helperText={errorHandler('oIEq2')}
                  onChange={handleChange}
                  value={oIEq2}
                  name="oIEq2"
                  label="First and Last Name"
                  color='secondary'
                  variant="outlined"
                  className={parentStyle.inputField}
                  InputProps={{
                    classes: { root: parentStyle.inputText }
                  }}
                />
                <Box className={classes.flexBox}>
                  {/* <Typography>Monthly amount contributed to the household (not including the amount contribted to the Mortgage): $</Typography> */}
                  <TextField
                    size="small"
                    error={errorHandler('oIEq3') !== false}
                    helperText={errorHandler('oIEq3')}
                    onChange={handleChange}
                    value={oIEq3}
                    name="oIEq3"
                    label="Monthly amount contributed to the household"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />

                  {/* <Typography>Monthly amount contributed to the Mortgage: $</Typography> */}
                  <TextField
                    size="small"
                    error={errorHandler('oIEq4') !== false}
                    helperText={errorHandler('oIEq4')}
                    onChange={handleChange}
                    value={oIEq4}
                    name="oIEq4"
                    label="Monthly amount contributed to the Mortgage: $"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />
                </Box>

                <FormControl component="fieldset" className={classes.formControl} >
                  <FormLabel component="legend" className={parentStyle.formLabel}>Are there living expenses for
                  this person?{radioErrorHandler("oIEq5")} </FormLabel>
                  <RadioGroup name='oIEq5' value={oIEq5} onChange={handleChange}>
                    <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
                {/* <Typography>Ifyes, monthly amount of expenses: $</Typography> */}
                {oIEq5 === "Yes" &&
                  <TextField
                    size="small"
                    error={errorHandler('oIEq6') !== false}
                    helperText={errorHandler('oIEq6')}
                    onChange={handleChange}
                    value={oIEq6}
                    name="oIEq6"
                    label="Monthly amount of expenses: $"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />}


                <Typography className={parentStyle.questionText}>
                  List any one-time payments you received that appear on your most recent tax return:
                  (Examples: one-time pension disbursements, tax refunds, bonuses, insurance distributions)
</Typography>

                <Box className={classes.flexBox}>
                  <TextField
                    size="small"
                    error={errorHandler('oIEPaymentType1') !== false}
                    helperText={errorHandler('oIEPaymentType1')}
                    onChange={handleChange}
                    value={oIEPaymentType1}
                    name="oIEPaymentType1"
                    label="Payment Type 1"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />
                  <TextField
                    size="small"
                    error={errorHandler('oIEAmount1') !== false}
                    helperText={errorHandler('oIEAmount1')}
                    onChange={handleChange}
                    value={oIEAmount1}
                    name="oIEAmount1"
                    label="Amount 1 $"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />
                </Box>


                <Box className={classes.flexBox}>
                  <TextField
                    size="small"
                    error={errorHandler('oIEPaymentType2') !== false}
                    helperText={errorHandler('oIEPaymentType2')}
                    onChange={handleChange}
                    value={oIEPaymentType2}
                    name="oIEPaymentType2"
                    label="Payment Type 2"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />
                  <TextField
                    size="small"
                    error={errorHandler('oIEAmount2') !== false}
                    helperText={errorHandler('oIEAmount2')}
                    onChange={handleChange}
                    value={oIEAmount2}
                    name="oIEAmount2"
                    label="Amount 2 $"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />
                </Box>


                <Box className={classes.flexBox}>
                  <TextField
                    size="small"
                    error={errorHandler('oIEPaymentType3') !== false}
                    helperText={errorHandler('oIEPaymentType3')}
                    onChange={handleChange}
                    value={oIEPaymentType3}
                    name="oIEPaymentType3"
                    label="Payment Type 3"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />
                  <TextField
                    size="small"
                    error={errorHandler('oIEAmount3') !== false}
                    helperText={errorHandler('oIEAmount3')}
                    onChange={handleChange}
                    value={oIEAmount3}
                    name="oIEAmount3"
                    label="Amount 3 $"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />
                </Box>
              </Box>}

            </Box>
          </Fragment>}
          {activeSubStep === 2 && <Fragment>
            {radioErrorHandler("incomeH1")}
            <Box display="flex" className={classes.inputBox}>
              {renderHouseHoldIncomeTable()}
            </Box>

            {/* <Grid container direction="row" justify="center" alignItems="stretch">
              <Grid item md={6}><Box px={1}>{renderHouseHoldIncomeTable()}</Box> </Grid>
              <Grid item md={6}><Box px={1}> {renderHouseHoldExpensesTable()}</Box></Grid>
            </Grid> */}



          </Fragment>}
          {activeSubStep === 3 && <Fragment>
            {radioErrorHandler("expensesH1")}
            <Box className={classes.inputBox}>
              {renderHouseHoldExpensesTable()}
            </Box>
          </Fragment>}
          {activeSubStep === 4 && <Fragment>
            {radioErrorHandler("assetsH1")}
            <Box className={classes.inputBox}>
              {renderAssetsTable()}
            </Box>
          </Fragment>}
          {activeSubStep === 5 && <Fragment>
            <Box className={classes.inputBox}>
              {renderLivingExpensesTable()}
            </Box>
          </Fragment>}
        </form>
      </Box>
      {/* <NextPrevButton        
        handleNextCallback ={handleSave} 
        handlePrevCallBack={props.previous} 
        activeStepIndex={props.activeSection}></NextPrevButton> */}
      <NextPrevButton
        //handleNextCallback={props.next}
        handleNextCallback={nextSubStep}
        handlePrevCallBack={backSubStep}
        activeStepIndex={props.activeSection}></NextPrevButton>
    </Box>
  )
}

const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "5px 10px"
  },
  head: {
    backgroundColor: "#535D6D",//theme.palette.common.black,
    color: "#ffffff",//theme.palette.common.white,
    fontSize: "16px",
    fontWeight: "bold",
    letterSpacing: "0.36px",
    lineHeight: "19px",
    fontFamily: "Montserrat",
    borderRight: "1px solid #ffffff",
    verticalAlign: "top",
    padding: "10px"
  },
  body: {
    fontSize: 16,
    color: "#202020",
    letterSpacing: "0.36px",
    lineHeight: "19px",
    fontFamily: "Montserrat"
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const LightTooltip = withStyles((theme) => ({
  arrow: {
    "&:before": {
      boxShadow: "0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)",
    },
    color: "#FFFFFF",
  },
  tooltip: {
    color: '#202020',
    fontSize: 16,
    maxWidth: 'none',
    padding: 10,
    fontFamily: "Montserrat",
    letterSpacing: "0.36px",
    linHeight: "19px",
    borderRadius: "12px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)",
  },
}))(Tooltip);


const dataHI = [
  { title: "Monthly Gross Wages", key: "incomeH1", toolTipText: "" },
  { title: "Monthly Self-Employment Income", key: "incomeH2", toolTipText: "" },
  { title: "Monthly Overtime", key: "incomeH3", toolTipText: "" },
  { title: "Monthly Unemployment Income", key: "incomeH4", toolTipText: "" },
  { title: "Monthly Tips, Commissions, Bonus", key: "incomeH5", toolTipText: "" },
  { title: "Monthly Non-Taxable Social Security/SSDI", key: "incomeH6", toolTipText: "" },
  { title: "Monthly Taxable Social Security Benefits or Other Monthly Income from Annuities or Retirement Plans", key: "incomeH7", toolTipText: "" },
  { title: "Monthly Child Support/Alimony2", key: "incomeH8", toolTipText: "Notice: Alimony, child support, or separate maintenance income need not be revealed if you do not choose to have it considered for repaying this loan" },
  { title: "Monthly Gross Rents Received3", key: "incomeH9", toolTipText: "Include rental income received from all properties you own EXCEPT a property for which you are seeking mortgage assistance in Section H." },
  { title: "Monthly Food Stamps/Welfare", key: "incomeH10", toolTipText: "" },
  { title: "Monthly Other", key: "incomeH11", toolTipText: "" },
  { title: "Total Monthly Income", key: "incomeHtotal", toolTipText: "" },
]
const dataHExpenses = [
  { title: "Monthly First Mortgage Principal and Interest Payment1", key: "expensesH1", toolTipText: "The amount of the monthly payment made to your lender - including, if applicable, monthly principal, interest, real property taxes and insurance premiums." },
  { title: "Monthly Second Mortgage Principal and Interest Payment1", key: "expensesH2", toolTipText: "The amount of the monthly payment made to your lender - including, if applicable, monthly principal, interest, real property taxes and insurance premiums." },
  { title: "Monthly Homeowners’ Insurance1", key: "expensesH3", toolTipText: "The amount of the monthly payment made to your lender - including, if applicable, monthly principal, interest, real property taxes and insurance premiums." },
  { title: "Monthly Homeowners’ Insurance1", key: "expensesH4", toolTipText: "The amount of the monthly payment made to your lender - including, if applicable, monthly principal, interest, real property taxes and insurance premiums." },
  { title: "Monthly HOA/Condo Fees/Co-OP Fees/Property Maintenance1", key: "expensesH5", toolTipText: "The amount of the monthly payment made to your lender - including, if applicable, monthly principal, interest, real property taxes and insurance premiums." },
  { title: "Monthly Mortgage Payments on Other Properties4", key: "expensesH6", toolTipText: "Include mortgage payments on all properties you own EXCEPT your principal residence and the property for which you are seeking mortgage assistanceinSection H." },
  { title: "Monthly Credit Cards/Installment Loan(s) (total minimum payment)", key: "expensesH7", toolTipText: "" },
  { title: "Monthly Child Support/Alimony Payments", key: "expensesH8", toolTipText: "" },
  { title: "Monthly Auto Lease/Payment", key: "expensesH9", toolTipText: "" },
  { title: "Monthly Other", key: "expensesH10", toolTipText: "" },
  { title: "Total Monthly Expenses/Debt", key: "expensesHtotal", toolTipText: "" },
]
const assetsData = [
  { title: "Checking Account(s)", key: "assetsH1" },
  { title: "Checking Account(s)", key: "assetsH2" },
  { title: "Savings/Money Market", key: "assetsH3" },
  { title: "CDs", key: "assetsH4" },
  { title: "Stocks/Bonds", key: "assetsH5" },
  { title: "Other Cash on Hand", key: "assetsH6" },
  { title: "Other Real Estate(estimated value)", key: "assetsH7" },
  { title: "Other", key: "assetsH8" },
  { title: "Total Assets", key: "assetsHtotal" },
]

const addLivingExpensesData = [ //ADDITIONAL LIVING EXPENSES
  { title: "Tuition/School", borrowerkey: "borrowerALE1", coBorrowerkey: "coBorrowerALE1", nonBorrowerKey: "nonBorrowerALE1", totalKey: "totalALE1" },
  { title: "ChildCare(daycare,babysitting)", borrowerkey: "borrowerALE2", coBorrowerkey: "coBorrowerALE2", nonBorrowerKey: "nonBorrowerALE2", totalKey: "totalALE2" },
  { title: "Automobile Expenses (insurance/maintenance/gas)", borrowerkey: "borrowerALE3", coBorrowerkey: "coBorrowerALE3", nonBorrowerKey: "nonBorrowerALE3", totalKey: "totalALE3" },
  { title: "Food", borrowerkey: "borrowerALE4", coBorrowerkey: "coBorrowerALE4", nonBorrowerKey: "nonBorrowerALE4", totalKey: "totalALE4" },
  { title: "Life Insurance Premium", borrowerkey: "borrowerALE5", coBorrowerkey: "coBorrowerALE5", nonBorrowerKey: "nonBorrowerALE5", totalKey: "totalALE5" },
  { title: "Medical", borrowerkey: "borrowerALE6", coBorrowerkey: "coBorrowerALE6", nonBorrowerKey: "nonBorrowerALE6", totalKey: "totalALE6" },
  { title: "Utilities", borrowerkey: "borrowerALE7", coBorrowerkey: "coBorrowerALE7", nonBorrowerKey: "nonBorrowerALE7", totalKey: "totalALE7" },
  { title: "Clothing", borrowerkey: "borrowerALE8", coBorrowerkey: "coBorrowerALE8", nonBorrowerKey: "nonBorrowerALE8", totalKey: "totalALE8" },
  { title: "Cable,Internet,Phone", borrowerkey: "borrowerALE9", coBorrowerkey: "coBorrowerALE9", nonBorrowerKey: "nonBorrowerALE9", totalKey: "totalALE9" },
  { title: "Total LivingExpenses", borrowerkey: "borrowerALE10", coBorrowerkey: "coBorrowerALE10", nonBorrowerKey: "nonBorrowerALE10", totalKey: "totalALE10" },
]