import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import CancelIcon from '@material-ui/icons/Cancel';
import warning from '../../assets/warning-orange.png';

class Popup extends Component {
  render() {
    const { classes,closePopup,open,isWarningPopup,isMessagePopup } = this.props;
    return (
      <div className={classes.popup}>
        <div className={classes.popup_inner} style={{ maxWidth: this.props.maxWidth }}>
        {isWarningPopup && <img
            src={warning}
            className={classes.warningIcon} />}

         {!isMessagePopup && <h1 className={`${classes.h1} ${isWarningPopup?classes.warningPopup:''}`} style={this.props.h1Style} >{this.props.heading}:   
          {isWarningPopup && <CancelIcon
            onClick={closePopup}
            className={classes.closeIcon} />} </h1>}
            {isMessagePopup && <CancelIcon
            onClick={closePopup}
            className={classes.closeIconMsg} />}
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Popup);