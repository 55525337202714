import React, { useEffect, useState } from 'react';
import {
  Box, Typography, FormControlLabel, FormLabel, FormGroup,
  FormControl, TextField, RadioGroup, Radio, Checkbox
} from '@material-ui/core';
import { useStyles } from './styles';
import { CustomizedTextInput } from '../CustomizedTextInput'
import { CustomRadioButton } from '../CustomRadioButton'
import { NextPrevButton } from '../NextPrevButton'
import { SaveAndExitPopup } from '../SaveAndExitPopup';
import { useHistory } from 'react-router-dom';
import { checkDigitOnly } from '../../../helpers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider,KeyboardTimePicker, KeyboardDatePicker} from '@material-ui/pickers';

export const SectionD = (props) => {
  const { parentStyle } = props
  const classes = useStyles();
  const history = useHistory();

  const [dFormData, setDFormData] = useState({
    isApplicableSectionD: "",
    bankruptcyField: "",
    bankruptcyFieldOther: "",
    isDischarged: "",
    caseNumber: "",
    fillingDate: "",
    isUpdated: false
  })
  const { isApplicableSectionD, bankruptcyField, bankruptcyFieldOther, isDischarged, caseNumber, fillingDate } = dFormData
  const nullCheck = (str) => str ? str : ''
  const handleChange = (event) => {
    if (event.target.name === "caseNumber") {
      if (checkDigitOnly(event.target.value) === false) {
        return false
      }
    }

    setDFormData({ ...dFormData, [event.target.name]: event.target.value });
  };
  const handleChangeDate =(inputName) => (date) => {
    setDFormData({ ...dFormData, [inputName]: date });
}

  useEffect(() => {
    if (props.data) {
      const { questions } = props.data
      const { question1, question2, question3, question4, question5 } = questions;

      setDFormData({
        isApplicableSectionD: nullCheck(question1.answer),
        bankruptcyField: nullCheck(question2.answer),
        bankruptcyFieldOther: nullCheck(question2.otherText),
        isDischarged: nullCheck(question3.answer),
        caseNumber: nullCheck(question4.answer),
        fillingDate: nullCheck(question5.answer),
        isUpdated: true
      })
    }
  }, [props.data])

  useEffect(() => {
    if (dFormData.isUpdated) {
      let formErrors = formValidationSectionD()
      setFormErrorMap(formErrors)
    }
  }, [dFormData.isUpdated])

  const handleSave = (e) => {
    let formErrors = formValidationSectionD()
    let formErrorLength = Object.keys(formErrors).length
    let sectionCompleteStatus = formErrorLength > 0 ? "incomplete" : "complete"
    props.status({ id: 3, sectionName: "section_D", status: sectionCompleteStatus })

    const data = {
      "section_D": {
        "questions": {
          "question1": {
            "questionText": "Is this section applicable to you",
            "answer": isApplicableSectionD,
          },
          "question2": {
            "questionText": "Select the type of bankruptcy field",
            "answer": bankruptcyField,
            "otherText": bankruptcyField === "Other" ? bankruptcyFieldOther : ''
          },
          "question3": {
            "questionText": "Has your bankruptcy been discharged ?",
            "answer": isDischarged
          },
          "question4": {
            "questionText": "Bankruptcy case number",
            "answer": caseNumber
          },
          "question5": {
            "questionText": "Filling Date",
            "answer": fillingDate
          },
        },
        "sectionStatus": {
          "status": sectionCompleteStatus,
          "sectionName": "Section D:Bankruptcy"
        },

      }
    }
    console.log("===Section D====", data)
    props.saveFromParent(data)
  }

  const saveAndExitAction = (action) => {
    if (action === "leave") {
      handleSave()
      history.push('/Dashboard')
    }
  }

  const checkBoxStatus = (status) => {
    if (status === "true" || status === true) {
      return true
    } else {
      return false
    }
  }
  /*Error handler*/
  const [formErrorMap, setFormErrorMap] = useState([])
  const isEmpty = (str) => str ? false : true

  const formValidationSectionD = () => {
    const formErrorMessage = {}    
    if (isApplicableSectionD === 'Yes') {
      if (isEmpty(bankruptcyField)) {
        formErrorMessage["bankruptcyField"] = "This is required field"
      } else if (bankruptcyField === "Other") {
        if (isEmpty(bankruptcyFieldOther)) {
          formErrorMessage["bankruptcyFieldOther"] = "This is required field"
        }
      }
      if (isEmpty(isDischarged)) {
        formErrorMessage["isDischarged"] = "This is required field"
      }
      if (isEmpty(caseNumber)) {
        formErrorMessage["caseNumber"] = "This is required field"
      }
      if (isEmpty(fillingDate)) {
        formErrorMessage["fillingDate"] = "This is required field"
      }
    }
    //console.log("===form error D===", formErrorMessage)
    return formErrorMessage;
  }


  const errorHandler = (field) => {
    if (formErrorMap[field]) {
      let message = formErrorMap[field]==="invalid"?"Invalid Data, Please check the value" :"*"
      return message
    } else {
      return false
    }
  }
  /*Error handler end*/

  return (
    <Box className={classes.root}>
      <Box style={{ marginTop: "-85px" }}>
        <SaveAndExitPopup actionCallBack={saveAndExitAction}></SaveAndExitPopup>
      </Box>
      <Box height="90%" style={{ paddingTop: "25px" }}>
        {/* <Box pb={3}>
          <Typography className={classes.title}>Bankruptcy</Typography>
        </Box> */}
        {/* <Box>
          <FormControlLabel
            control={<Checkbox checked={isApplicableSectionD} onChange={handleChange} name="isApplicableSectionD" color="primary" />}
            label="Check this box if this section does not apply to you." />
        </Box> */}


        <Box className={classes.inputBox}>
          <Typography className={parentStyle.title}>Bankruptcy</Typography>
          <FormControl component="fieldset" className={parentStyle.formControl} >
            <FormLabel component="legend" className={parentStyle.formLabel}>Is this section applicable to you
            <span className={parentStyle.errorMessage}> {errorHandler('isApplicableSectionD')}</span>
            </FormLabel>
            <RadioGroup name='isApplicableSectionD' value={isApplicableSectionD} onChange={handleChange}>
              <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
              <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
          {/* <FormControlLabel
            control={<Checkbox checked={checkBoxStatus(isApplicableSectionD)} onChange={handleChange} name="isApplicableSectionD" color="primary" />}
            label="Check this box if this section does not apply to you." /> */}
        </Box>
        {isApplicableSectionD === 'Yes' ? (
          <Box className={classes.inputBox}>
            <FormControl component="fieldset" className={parentStyle.formControl} >
              <FormLabel component="legend" className={parentStyle.formLabel}>Select the type of bankruptcy field
            <span className={parentStyle.errorMessage}> {errorHandler('bankruptcyField')}</span>
              </FormLabel>
              <RadioGroup name='bankruptcyField' value={bankruptcyField} onChange={handleChange}>
                <FormControlLabel className={parentStyle.labelStyle} value="Chapter 7" control={<Radio />} label="Chapter 7" />
                <FormControlLabel className={parentStyle.labelStyle} value="Chapter 13" control={<Radio />} label="Chapter 13" />
                <FormControlLabel className={parentStyle.labelStyle} value="Other" control={<Radio />} label="Other" />
              </RadioGroup>
            </FormControl>
            {bankruptcyField === 'Other' && <TextField
              size="small"
              error={errorHandler('bankruptcyFieldOther') !== false}
              helperText={errorHandler('bankruptcyFieldOther')}
              onChange={handleChange}
              value={bankruptcyFieldOther}
              name="bankruptcyFieldOther"
              label="Other Text"
              color='secondary'
              variant="outlined"
              className={parentStyle.inputField}
              InputProps={{
                classes: { root: parentStyle.inputText }
              }}
            />}

            <FormControl component="fieldset" className={parentStyle.formControl} >
              <FormLabel component="legend" className={parentStyle.formLabel}>Has your bankruptcy been discharged ?
            <span className={parentStyle.errorMessage}> {errorHandler('isDischarged')}</span>
              </FormLabel>
              <RadioGroup name='isDischarged' value={isDischarged} onChange={handleChange}>
                <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>

            <Box className={classes.flexBox}>
              <TextField
                size="small"
                error={errorHandler('caseNumber') !== false}
                helperText={errorHandler('caseNumber')}
                onChange={handleChange}
                value={caseNumber}
                name="caseNumber"
                label="Bankruptcy Case Number"
                color='secondary'
                variant="outlined"
                className={parentStyle.inputField}
                InputProps={{
                  classes: { root: parentStyle.inputText }
                }}
              />
              {/* <TextField
                size="small"
                error={errorHandler('fillingDate') !== false}
                helperText={errorHandler('fillingDate')}
                onChange={handleChange}
                value={fillingDate}
                name="fillingDate"
                label="Filling Date"
                color='secondary'
                variant="outlined"
                className={parentStyle.inputField}
                InputProps={{
                  classes: { root: parentStyle.inputText }
                }}
              /> */}
               <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  //disabled={isRestrict}
                  views={['year', 'month', 'date']}
                  error={errorHandler('fillingDate') !== false}
                  helperText={errorHandler('fillingDate')}
                  size={"small"}
                  color='secondary'
                  autoOk={true}
                  //disableToolbar
                  //minDate={new Date()}
                  variant="inline"
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  fullWidth
                  id="id-fillingDate"
                  label="Filling Date"
                  value={fillingDate || null}
                  name="fillingDate"
                  onChange={handleChangeDate("fillingDate")}
                  KeyboardButtonProps={{'aria-label': 'change date',}}
                  placeholder={"MM/dd/yyyy"}
                  className={parentStyle.inputField}
                  InputProps={{ classes: { root: parentStyle.inputText }}}
                  InputLabelProps={{shrink: true,}}
                />
              </MuiPickersUtilsProvider>
            </Box>
          </Box>) : null}
      </Box>
      <NextPrevButton
        handleNextCallback={handleSave}
        handlePrevCallBack={props.previous}
        activeStepIndex={props.activeSection}></NextPrevButton>

    </Box>

  )
}