import React, { useState } from "react";
import { Box, Button, Dialog, IconButton, CircularProgress } from "@material-ui/core";
import { useStyles1 } from './styles';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import moment from 'moment';
import { deleteRecurringACHPayment } from '../../actions';
import { decrypt } from '../../helpers/encrypt';
import { maskAccount, numberWithCommas, renderDefaultMsg } from '../../helpers';


export const DeleteAutoPayAlertMultiLoan = (props) => {
  const classes = useStyles1();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('')
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { selectedDeleteAccount, open, handleClose } = props;
  const { accountNumber, lstRecurACH } = selectedDeleteAccount;
  const { draftAmount1, draftStartDate } = lstRecurACH[0];
  const [isSuccess, setIsSuccess] = useState(false);
  const [confirmationNumber, setConfirmationNumber] = useState('');

  const handlePaymentDelete = async () => {
    setLoading(true);
    setErrMsg('');
    const res = await deleteRecurringACHPayment(accountNumber);
    if (res && res.confirmationNumber) {
      setLoading(false);
      setIsSuccess(true)
      setConfirmationNumber(res.confirmationNumber)
    } else {
      setErrMsg(renderDefaultMsg('400'));
      setLoading(false);
    }
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      PaperProps={{ classes: { root: classes.dialogStyle } }}
      open={open}
    >
      {!isSuccess ?
        <Box className={classes.root}>
          <Box className={classes.header}>
            <IconButton className={classes.iconStyle} onClick={() => handleClose()}>
              <CancelIcon />
            </IconButton>
          </Box>
          <Box className={classes.body}>
            {/* <Box className={classes.checkIconContainer}>
              <CheckCircleIcon classes={{ root: classes.checIcon }} />
            </Box> */}
            <h1 className={classes.title}>Cancel  Autopay Settings</h1>
            {errMsg.length !== 0 ? <p className={classes.errMsg}>{errMsg}</p> : null}
            <p className={classes.subTitle}>The new Autopay set up process may take one or two business days to reflect on your account. We encourage you to keep that in mind before canceling current settings</p>
            <Box className={classes.flexBox}>
              <Box className={classes.item}>
                <h3 className={classes.itemText}>Amount</h3>
                <h3 className={classes.itemSubText}>${numberWithCommas(parseFloat(draftAmount1).toFixed(2))}</h3>
              </Box>
              <Box className={classes.item}>
                <h3 className={classes.itemText}>Last Payment Date </h3>
                <h3 className={classes.itemSubText}>{moment(draftStartDate).format("ll")}</h3>
              </Box>
              <Box className={classes.item}>
                <h3 className={classes.itemText}>Loan Number</h3>
                <h3 className={classes.itemSubText}>{maskAccount(decrypt(accountNumber))}</h3>
              </Box>
            </Box>
            <Box className={classes.buttonBox}>
              <Button onClick={() => handleClose()} className={classes.btn} variant="outlined" color='primary'>Cancel</Button>
              <Button onClick={() => handlePaymentDelete()} disabled={loading} className={classes.btn} variant="contained" color='primary' >{loading ? <CircularProgress size={20} color='inherit' /> : 'Confirm'}</Button>
            </Box>
            <p className={classes.footerText}>If you have any further payment related question please visit <Link to='/' className={classes.anchorLinkStyle}>Payment FAQs </Link></p>
          </Box>
        </Box>
        :
        <Box className={classes.root}>
          <Box className={classes.header}>
            <IconButton className={classes.iconStyle} onClick={() => handleClose(true)}>
              <CancelIcon />
            </IconButton>
          </Box>
          <h1 className={classes.successMsg}>Payment Deleted Successfully.</h1>
          <p className={classes.successSubTitle}>Request ID: {decrypt(confirmationNumber)}</p>
          <Box className={classes.buttonBox}>
            <Button onClick={() => handleClose(true)} className={classes.btn} variant="outlined" color='primary'>Ok</Button>
          </Box>
        </Box>
      }
    </Dialog >
  )
}