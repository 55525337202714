import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Box, Grid, Dialog, Button, CircularProgress } from '@material-ui/core';
import { useStyles } from './styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';

import Cookies from 'js-cookie';
import { encrypt, decrypt } from '../../../helpers/encrypt';
import { renderDefaultMsg, getAppSource, getUserType, scrollTop, isAdmin } from '../../../helpers'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import checkImg from '../../../assets/checkImg.svg';
import { getBorrowerCoborrowerName, getBankDetails, addEditBankAccount, insertActivityLog } from '../../../actions'
import { checkAllDigits, consecutiveDigits, reverseConsecutiveDigits } from '../../../helpers';

export const AddEditAccount = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const myRef = React.useRef({});
  const classes = useStyles();
  const [formSubmit, setFormSubmit] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [thirdPartyName, setThirdPartyName] = useState(false);
  const [coBoName, setCoBoName] = useState([]);
  const [coBoError, setCoBoError] = useState('');
  const [editDataLoader, setEditDataLoader] = useState(false);
  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState(false);
  const [abAError, setAbAError] = useState('');
  const [ifBankNameEmpty, setIfBankNameEmpty] = useState(false);
  const [aBAValChanged, setABAValChanged] = useState(false);
  const [formItem, setFormItem] = React.useState({
    bankProfileID: 0,
    bankName: "",
    abaRoutingNumber: "",
    nickName: "",
    bankAccountNumber: "",
    accountType: "C",
    isPreferredAcc: false,
    isSave: props.renderPage === 'autoPay' ? true : false,
    borrowerName: '',
    borrowerType: '',
    thirdBorrowerName: '',
    confirmBankAccNumber: ''
  });

  const [validAbAAccNumber, setValidAbAAccNumber] = useState(false);
  const [validateRountingLoader, setValidateRountingLoader] = useState(false);
  const [invalidAbaRoutingNoStatus, setInvalidAbaRoutingNo] = useState(false)

  const [formInputError, setFormInputError] = useState({
    bankName: "",
    abaRoutingNumber: "",
    nickName: "",
    bankAccountNumber: "",
    borrowerType: '',
    thirdBorrowerName: '',
    errorCount: 0,
    confirmBankAccNumber: ''
  })

  const [values, setValues] = React.useState({
    showAccNumber: false,
    showConfirmAccNumber: true,
    userName: ''
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getBorroCoborroName();
  }, [])

  const handleChange = (event) => {
    if (event.target.name === 'checkPreferredAcc') {
      setFormItem({ ...formItem, ['isPreferredAcc']: event.target.checked });
    } else if (event.target.name === 'saveAccount') {
      setFormItem({ ...formItem, ['isSave']: event.target.checked });
    }
  };

  const handleRadioChange = (event) => {
    setValue(event.target.value);
    setError(false);
    setFormItem({ ...formItem, ['accountType']: event.target.value });
  };

  const getBorroCoborroName = async () => {
    const selectedAccount = await Cookies.get('selectedAccount');
    const data = {
      "accountNumber": selectedAccount,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };
    setEditDataLoader(true);
    const response = await getBorrowerCoborrowerName(data);
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
      setCoBoName(response.borrowerNames);

      setEditDataLoader(false);
      setCoBoError('');
    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
      setCoBoName(response);
      setCoBoError(response.lstResponseStatus[0].statusDesc);
      setThirdPartyName(false);
      setEditDataLoader(false);
    } else {
      setCoBoError(renderDefaultMsg('400'));
      setEditDataLoader(false);
    }
  }

  const borowerName = (data) => {

    if (data.borrowerType.length) {
      setFormInputError({
        ...formInputError,
        borrowerType: ''
      })
    }

    if (data.borrowerType === "T") {
      setThirdPartyName(true);
      setFormItem({ ...formItem, ['borrowerType']: data.borrowerType });

    } else {
      setThirdPartyName(false);
      setFormItem({
        ...formItem, ['borrowerType']: data.borrowerType,
        ['borrowerName']: data.borrowerName
      });
    }
  }

  const handleFormTextChange = (key, val) => {
    if (val.trim().length) {
      setFormInputError({
        ...formInputError,
        [key]: ''
      })
    }
    if (val.trim().length) {
      if (key === 'nickName') {

        const bankAccounts = props.bankAccounts || [];
        const filterNickName = bankAccounts?.filter(details => details?.nickName?.toLowerCase() == val?.toLowerCase())

        if ((filterNickName?.length > 0) && (filterNickName?.nickName?.toLowerCase() !== val?.toLowerCase())) {
          setFormInputError({
            ...formInputError,
            [key]: 'Account nick name already in use'
          })
        } else {
          setFormInputError({
            ...formInputError,
            [key]: ''
          })
        }
      } else {
        setFormInputError({
          ...formInputError,
          [key]: ''
        })
      }
    }

    if (key === 'abaRoutingNumber') {
      if ((/^[0-9]+$/).test(decrypt(val)) || decrypt(val) === '') {
        setFormItem({ ...formItem, [key]: val });
        setABAValChanged(true);
      }
    } else if (key === 'bankAccountNumber') {
      if ((/^[0-9]+$/).test(val) || val === '') {
        if (val.trim().length) {
          const bankAccounts = props.bankAccounts || [];

          const filterAccountNumber = bankAccounts?.filter(acc => (decrypt(acc?.bankAccountNumber) == val));
          console.log('filterAccountNumber',bankAccounts?.filter(acc => (console.log(decrypt(acc?.bankAccountNumber)))));
          if ((filterAccountNumber?.length > 0) && ((filterAccountNumber?.bankAccountNumber == null) ?  true :  (decrypt(accForEditDetail?.bankAccountNumber) !== val))) {
            setFormInputError({
              ...formInputError,
              [key]: 'Bank account number already in use'
            })
          } else {
            setFormInputError({
              ...formInputError,
              [key]: ''
            })
          }
        }
        setFormItem({ ...formItem, [key]: val });

      }
    } else if (key === 'bankName') {
      setFormItem({ ...formItem, [key]: val.replace(/[^\w\s]/gi, "") });
    } else if (key === 'confirmBankAccNumber') {
      if ((/^[0-9]+$/).test(val) || val === '') {
        if (val.trim().length) {
            setFormInputError({
              ...formInputError,
              [key]: ''
            })
        }
        setFormItem({ ...formItem, [key]: val });
      }
    } else {
      setFormItem({ ...formItem, [key]: val });
    }

  }

  const checkAbA = (val) => {
    if (val.length == 9 && !invalidAbaRoutingNoStatus) {
       validateRoutingNo(val)
    }
  }

  const validateAbaRoutingNo = (val) => {
    if ((/^[0-9]+$/).test(val)) {
      if (consecutiveDigits(val) && checkAllDigits(val) && reverseConsecutiveDigits(val)) {
        setAbAError('')
        setInvalidAbaRoutingNo(false)
      } else {
        setAbAError('Invalid ABA Routing Number')
        setFormItem({ ...formItem, bankName: '', bankCity: '' })
        setInvalidAbaRoutingNo(true)
      }
    } else {
      setAbAError('')
      setInvalidAbaRoutingNo(false)
    }
  }

  const togglePopup = () => {
    setFormItem({
      bankProfileID: 0,
      bankName: "",
      abaRoutingNumber: "",
      nickName: "",
      bankAccountNumber: "",
      accountType: "S",
      isPreferredAcc: false,
      isSave: false,
      borrowerName: '',
      borrowerType: '',
      confirmBankAccNumber: ""
    });
    setAbAError('');
    props.toggleOpen();
  }


  const validateForm = () => {
    const formErrors = {
      bankName: "",
      abaRoutingNumber: "",
      nickName: "",
      bankAccountNumber: "",
      borrowerType: '',
      thirdBorrowerName: '',
      confirmBankAccNumber: ''
    }
    let errorCount = 0

    setFormInputError({ ...formErrors, errorCount })

    if (formItem.nickName.trim() === '') {
      formErrors.nickName = "Nick name cannot be blank";
      errorCount++
    }
    if (formItem.borrowerType === '') {
      formErrors.borrowerType = "Name on bank account cannot be blank";
      errorCount++
    }
    if (formItem.borrowerType === 'T' && formItem.thirdBorrowerName.trim() === '') {
      formErrors.thirdBorrowerName = "Name on bank account(Other) cannot be blank";
      errorCount++
    }

    if (formItem.abaRoutingNumber.trim() === '') {
      formErrors.abaRoutingNumber = "ABA Routing Number cannot be blank";
      errorCount++
    }
    if (formItem.bankAccountNumber.trim() === '') {
      formErrors.bankAccountNumber = "Account number cannot be blank";
      errorCount++
    }
    if (formItem.bankName.trim() === '') {
      formErrors.bankName = "Bank name cannot be blank";
      errorCount++
    }
    if (formItem.confirmBankAccNumber.trim() === '') {
      formErrors.confirmBankAccNumber = "Confirm account number cannot be blank";
      errorCount++
    }

    setFormInputError({
      ...formErrors,
      errorCount
    })

    return errorCount
  }

  const handleUserActivityLog = async (accountNumber, abaRoutingNumber, bankAccountNumber) => {

    const newData = {
      "accountNumber": encrypt(accountNumber),
      "activityCode": "UAL062",
      "activityStatus": "Success",
      "action": "AddBankAccount",
      "controller": "Payment",
      "apiName": "TempBankAccountSave",
      "activityRemark": `Bank Account Added successfuly without save abaRoutingNumber=${decrypt(abaRoutingNumber)}, bankAccountNumber=${bankAccountNumber}`,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };
    await insertActivityLog(newData)
  }


  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateForm() === 0 && abAError === '') {
      setFormSubmit(true);
      const data = {
        "bankProfileID": formItem.bankProfileID,
        "bankName": encrypt(formItem.bankName),
        "bankCity": '',
        "bankState": '',
        "abaRoutingNumber": formItem.abaRoutingNumber,
        "borrowerType": formItem.borrowerType,
        "borrowerName": formItem.borrowerType == 'T' ? formItem.thirdBorrowerName : formItem.borrowerName,
        "nickName": formItem.nickName,
        "bankAccountNumber": encrypt(formItem.bankAccountNumber),
        "accountType": formItem.accountType,
        "isPreferredAcc": formItem.isPreferredAcc,
        "isSave": props.renderPage === 'autoPay' ? true : formItem.isSave,
        "privateLabelUrl": Cookies.get("privateLabelUrl"),
        "ipAddress": Cookies.get('publicIp'),
        "browser": Cookies.get("browser"),
        "appSource": getAppSource(),
        "userType": getUserType(),
        "userName": ""
      }

      if (!formItem.isSave) {
        props.addedAcc({
          "bankProfileID": formItem.bankProfileID,
          "bankName": encrypt(formItem.bankName),
          "bankCity": formItem.bankCity,
          "bankState": formItem.bankState,
          "abaRoutingNumber": formItem.abaRoutingNumber,
          "borrowerType": formItem.borrowerType,
          "borrowerName": formItem.borrowerType == 'T' ? formItem.thirdBorrowerName : formItem.borrowerName,
          "nickName": formItem.nickName,
          "bankAccountNumber": encrypt(formItem.bankAccountNumber),
          "accountType": formItem.accountType,
          "isPreferredAcc": formItem.isPreferredAcc,
          "isSave": props.renderPage === 'autoPay' ? true : formItem.isSave
        });
        //add activity
        await handleUserActivityLog(formItem.bankAccountNumber, formItem.abaRoutingNumber, formItem.bankAccountNumber)
        setFormItem({
          bankProfileID: 0,
          bankName: "",
          abaRoutingNumber: "",
          nickName: "",
          bankAccountNumber: "",
          accountType: "S",
          isPreferredAcc: false,
          isSave: false,
          borrowerName: '',
          borrowerType: '',
        });
        setFormSubmit(false);
        props.toggleOpen('NotSaved');
      } else {
        const response = await addEditBankAccount(data);
        if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
          setErrorMsg("");
          setFormSubmit(false);
          setFormItem({
            bankProfileID: 0,
            bankName: "",
            abaRoutingNumber: "",
            nickName: "",
            bankAccountNumber: "",
            accountType: "S",
            isPreferredAcc: false,
            isSave: false,
            borrowerName: '',
            borrowerType: '',
          });
          props.toggleOpen('Saved');
          props.newAddedBank(encrypt(formItem.bankAccountNumber));
        } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
          setErrorMsg(response.lstResponseStatus[0].statusDesc);
          setFormSubmit(false);
        } else {
          setErrorMsg(renderDefaultMsg('400'));
          setFormSubmit(false);
        }
      }
    } else {
      if (formItem.nickName.trim() === '') {
        myRef.current['nickNameFocus'].focus();
        return;
      }
      if (formItem.borrowerType === '') {
        myRef.current['borrowerTypeFocus'].focus();
        return;
      }
      if (formItem.borrowerType === 'T' && formItem.thirdBorrowerName.trim() === '') {
        myRef.current['thirdBorrowerNameFocus'].focus();
        return;
      }
      if (formItem.abaRoutingNumber.trim() === '' || abAError !== '') {
        myRef.current['abaRoutingNumberFocus'].focus();
        return;
      }
      if (formItem.bankAccountNumber.trim() === '') {
        myRef.current['bankAccountNumberFocus'].focus();
        return;
      }
      if (formItem.bankName.trim() === '') {
        myRef.current['bankNameFocus'].focus();
        return;
      }
      if (formItem.confirmBankAccNumber.trim() === '') {
        myRef.current['confirmBankAccountNumberFocus'].focus();
        return;
      }
    }
  }
  
  
  const validateRoutingNo = async (routingNo) => {
    setValidAbAAccNumber(false);
    setValidateRountingLoader(true)
    const data = {
      "routingNumber": routingNo,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };
    const response = await getBankDetails(data);
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
      setValidateRountingLoader(false);
      setABAValChanged(false);
      let { bankName, routingNumber, city, state } = response.bankDetail;
      bankName = decrypt(bankName);
      setFormItem({
        ...formItem,
        bankName: bankName && bankName.length !== 0 && bankName !== 'NA' ? bankName.replace(/[^\w\s]/gi, "") : formItem.bankName.length ? formItem.bankName : '',
        abaRoutingNumber: routingNumber,
        bankCity: city,
        bankState: state,
      })

      setAbAError('');
      setValidAbAAccNumber(true);
      (decrypt(response.bankDetail.bankName) === '' || decrypt(response.bankDetail.bankName) === 'NA' && formItem.bankName === '')
        ? setFormInputError({ ...formInputError, bankName: 'Bank name cannot be blank.' }) : setFormInputError({ ...formInputError, bankName: '' })
    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
      setValidateRountingLoader(false);
      // setABAValChanged(false)
      setAbAError(response.lstResponseStatus[0].statusDesc);
      setValidAbAAccNumber(false);
      setFormItem({ ...formItem, bankName: '', bankCity: '' })
    } else {
      setValidateRountingLoader(false);
      setABAValChanged(false)
      setAbAError('Invalid ABA Routing Number.');
      setValidAbAAccNumber(false);
      setFormItem({ ...formItem, bankName: '', bankCity: '' })
    }
  };
  
  const validateConfirmAccNo = () => {
    if (formItem?.bankAccountNumber?.length > 0 && formItem?.confirmBankAccNumber?.length > 0) {
      if (formItem?.bankAccountNumber !== formItem.confirmBankAccNumber) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      validateRoutingNo(event.target.value)
    }
  }
  // const disableBtn = ((formItem.nickName ? formItem.nickName.trim().length === 0 ? true : false : true) ||
  //   (formItem.abaRoutingNumber ? formItem.abaRoutingNumber.trim().length === 0 ? true : false : true) ||
  //   formItem.bankAccountNumber === '' ||
  //   (formItem.bankAccountNumber ? formItem.bankAccountNumber.trim().length === 0 ? true : false : true) ||
  //   (formItem.bankName ? formItem.bankName.trim().length === 0 ? true : false : true) ||
  //   abAError !== '' || !validAbAAccNumber);

  const disableBtn = false;

  const disableCopyPaste = (e) => {
    e.preventDefault();
  }

  let allCoBorrwerNameData = '';
  if (coBoName && coBoName.length) {
    allCoBorrwerNameData = coBoName.map((item, index) => {
      return (
        <MenuItem value={item.borrowerType} key={item.id} onClick={() => borowerName(item)}>{item.borrowerName}</MenuItem>
      )
    })
  }

  if (editDataLoader) {
    return (
      <Dialog
        fullScreen={fullScreen}
        PaperProps={{ classes: { root: classes.dialogStyle } }}
        onClose={props.toggleOpen}
        open={props.open}
      >
        <Box className={classes.root}>
          <Box className='loaderRoot'>
            <CircularProgress />
          </Box>
        </Box>
      </Dialog >
    )
  }

  return (
    <div id="poupDialog">
      <Dialog
        fullScreen={fullScreen}
        PaperProps={{ classes: { root: classes.dialogStyle } }}
        onClose={props.toggleOpen}
        open={props.open}

      >
        <Box className={classes.root}  >
          <CancelIcon className={classes.iconStyle} onClick={togglePopup} />
          <h3 className={classes.pageHeading}>Add Bank Account</h3>
          <Box my={1} mx={2} className={classes.formContainer}>
            <form className={classes.formRoot} onSubmit={handleFormSubmit} noValidate autoComplete="off" >
              <Grid container spacing={6}>
                <Grid item xs={12} sm={12} className={classes.rowMargin} style={{ marginTop: 20 }}>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={6} md={4}>
                      <div>
                        <TextField
                          inputRef={el => myRef.current['nickNameFocus'] = el}
                          error={formInputError.nickName !== '' ? true : false}
                          helperText={formInputError.nickName !== '' ? formInputError.nickName : null}
                          label="Account Nickname test"
                          color="primary"
                          type="text"
                          value={formItem.nickName}
                          variant="outlined"
                          className={classes.inputStyle}
                          fullWidth
                          onChange={event => handleFormTextChange('nickName', event.target.value)}
                          inputProps={{
                            maxLength: 15
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <div>
                        <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}
                          error={formInputError.borrowerType !== '' ? true : false}>
                          <InputLabel id="borrower-type-select-outlined">Name on Bank Account</InputLabel>
                          <Select
                            labelId="borrower-type-outlined-label"
                            inputRef={el => myRef.current['borrowerTypeFocus'] = el}
                            value={formItem.borrowerType}
                            color="primary"
                            label="Borrower Name"
                            className={classes.inputStyle}
                            fullWidth
                          >
                            {allCoBorrwerNameData}
                          </Select>
                          <FormHelperText>{formInputError.borrowerType !== '' ? formInputError.borrowerType : null}</FormHelperText>
                        </FormControl>

                        {coBoError !== '' ? <p className={classes.errorMsg}>{coBoError}</p> : null}
                      </div>
                    </Grid>
                    {thirdPartyName ? <Grid item xs={12} sm={6} md={4}>
                      <div>
                        <TextField
                          inputRef={el => myRef.current['thirdBorrowerNameFocus'] = el}
                          error={formInputError.thirdBorrowerName !== '' ? true : false}
                          helperText={formInputError.thirdBorrowerName !== '' ? formInputError.thirdBorrowerName : null}
                          label="Name on Bank Account(Other)"
                          color="primary"
                          type="text"
                          value={formItem.thirdBorrowerName}
                          variant="outlined"
                          className={classes.inputStyle}
                          fullWidth
                          onChange={event => handleFormTextChange('thirdBorrowerName', event.target.value)}
                          inputProps={{
                            maxLength: 30
                          }}
                        />
                      </div>
                    </Grid> : null}
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} style={{ marginBottom: -10 }}>
                  <Grid container spacing={5}>

                    <Grid item xs={12} sm={6} md={4}>
                      <div style={{ position: 'relative' }}>
                        <TextField
                          inputRef={el => myRef.current['abaRoutingNumberFocus'] = el}
                          error={(formInputError.abaRoutingNumber !== '' || abAError !== '') ? true : false}
                          helperText={(formInputError.abaRoutingNumber !== '' || abAError !== '') ? formInputError.abaRoutingNumber : null}
                          label="ABA Routing Number"
                          color="primary"
                          type="text"
                          value={formItem.abaRoutingNumber ? decrypt(formItem.abaRoutingNumber) : ''}
                          variant="outlined"
                          className={classes.inputStyle}
                          fullWidth
                          onKeyDown={handleKeyDown}
                          onBlur={(event) => checkAbA(event.target.value)}
                          onChange={event => handleFormTextChange('abaRoutingNumber', encrypt(event.target.value))}
                          onKeyUp={(event) => validateAbaRoutingNo(event.target.value)}
                          inputProps={{
                            maxLength: 9
                          }}
                        />
                        {abAError !== '' && <h6 className={classes.abaError}>{abAError}</h6>}

                        {((formItem.bankName !== '' || formItem.bankCity !== '') && (formItem.bankCity !== 'NA')) ?
                          <h6 className={classes.aBaBankDetails}>{formItem.bankName && `${formItem.bankName}, `} {formItem.bankCity}</h6> : null}

                        {validateRountingLoader && <CircularProgress
                          className={classes.validateRLoader}
                          color='primary' />}

                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <div>
                        <TextField
                          inputRef={el => myRef.current['bankNameFocus'] = el}
                          error={formInputError.bankName !== ''}
                          helperText={formInputError.bankName !== '' ? formInputError.bankName : null}
                          disabled={validateRountingLoader}
                          label="Bank Name"
                          color="primary"
                          type="text"
                          value={formItem.bankName}
                          variant="outlined"
                          className={classes.inputStyle}
                          fullWidth
                          onChange={event => handleFormTextChange('bankName', event.target.value)}
                          onCut={disableCopyPaste}
                          onCopy={disableCopyPaste}
                          onPaste={disableCopyPaste}
                          inputProps={{
                            maxLength: 50
                          }}
                        />
              
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} >
                      <Box className={classes.accRadioType}>Account Type</Box>
                      <div className={classes.radioBtn}>
                        <RadioGroup row aria-label="account_type" name="account_type" value={value}
                          onChange={handleRadioChange}>
                          <FormControlLabel value="C" checked={formItem.accountType == 'C' ? true : false}
                            control={<Radio color="secondary" />} label="Checking" />
                          <FormControlLabel value="S" checked={formItem.accountType == 'S' ? true : false}
                            control={<Radio color="secondary" />} label="Savings" />
                        </RadioGroup>
                      </div>
                    </Grid>

                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} >
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={6} md={4}>
                      <div>
                      <TextField
                          inputRef={el => myRef.current['bankAccountNumberFocus'] = el}
                          error={formInputError.bankAccountNumber !== '' ? true : false}
                          helperText={formInputError.bankAccountNumber !== '' ? formInputError.bankAccountNumber : null}
                          disabled={validateRountingLoader}
                          label="Account Number"
                          color="primary"
                          type="password"
                          value={formItem.bankAccountNumber ? formItem.bankAccountNumber : ''}
                          variant="outlined"
                          className={classes.inputStyle}
                          fullWidth
                          onChange={event => handleFormTextChange('bankAccountNumber', event.target.value)}
                          inputProps={{
                            maxLength: 17
                          }}
                        />
              
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <div>
                      <TextField
                          inputRef={el => myRef.current['confirmBankAccountNumberFocus'] = el}
                          error={validateConfirmAccNo()}
                          helperText={validateConfirmAccNo() ? "Bank Account number doesn't match" : null}
                          disabled={validateRountingLoader}
                          label="Confirm Account Number"
                          color="primary"
                          type="text"
                          value={formItem.confirmBankAccNumber ? formItem.confirmBankAccNumber : ''}
                          variant="outlined"
                          className={classes.inputStyle}
                          fullWidth
                          onChange={event => handleFormTextChange('confirmBankAccNumber', event.target.value)}
                          inputProps={{
                            maxLength: 17
                          }}
                          onCut={disableCopyPaste}
                          onCopy={disableCopyPaste}
                          onPaste={disableCopyPaste}
                        />
              
                      </div>
                    </Grid>

                    {/* <Grid item xs={12} sm={12} md={4}>
                      <div>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formItem.isPreferredAcc}
                                onChange={handleChange}
                                name="checkPreferredAcc"
                                color="primary"
                                className={`${classes.inputStyle} ${classes.checkboxColorCode}`}
                              >
                              </Checkbox>
                            }
                            label={<Box className={classes.checkboxItem}>
                              <BookmarkIcon /> Make preferred account</Box>}
                          />
                        </FormGroup>
                      </div>
                    </Grid> */}

                    {props.renderPage !== 'autoPay' ? <Grid item xs={12} sm={12} md={4} className={classes.padding0}>
                      <div>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formItem.isSave}
                                onChange={handleChange}
                                name="saveAccount"
                                color="primary"
                                className={`${classes.inputStyle} ${classes.checkboxColorCode}`}
                              >
                              </Checkbox>
                            }
                            label={<Box className={classes.checkboxSaveAccount}>
                              Save Account for future</Box>}
                          />
                        </FormGroup>
                      </div>
                    </Grid> : null}

                    <Grid item xs={12} sm={12}>
                      <div>
                        <img className={classes.checkImg} src={checkImg} alt="check image" />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <p className={classes.errorMsg}>{errorMsg !== '' ? errorMsg : ''}</p>
                  <Box className={`${classes.buttonContainer}`} display="flex" justifyContent="center" style={{ paddingBottom: '34px' }}>
                    <Button className={`${classes.cancelAccButton}`} mt={2}
                      variant="outlined"
                      onClick={togglePopup}>
                      Cancel
              </Button>
                    {formSubmit ?
                      <Button className={`${classes.addAccButton}`} mt={2}
                        type="submit"
                        variant="contained"
                        disabled={formSubmit}>
                        <CircularProgress style={{ height: '24px', width: '24px' }} color='primary' />
                      </Button>
                      :
                      <Button className={`${classes.addAccButton}`} mt={2}
                        type="submit"
                        color="primary"
                        variant="contained"
                        // disabled={disableBtn || validateRountingLoader || formItem.bankAccountNumber === ''
                        //   || (formItem.borrowerType === 'T' && !formItem.thirdBorrowerName.trim().length)}
                        disabled={isAdmin() || formInputError?.bankAccountNumber !== '' || formInputError?.nickName || disableBtn || validateRountingLoader || (formItem?.bankAccountNumber !== formItem?.confirmBankAccNumber)}
                      >
                        Add Account
                    </Button>
                    }

                  </Box>
                </Grid>

              </Grid>
            </form>

          </Box>

        </Box>
      </Dialog >
    </div>
  )
}