import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: 12,
    marginBottom: 28,
    backgroundColor: "#FFFFFF",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
  },


  title: {
    color: "rgba(0,0,0,0.6)",
    fontFamily: "Montserrat",
    fontSize: '1.8em',
    fontWeight: "500",
    // textAlign: 'center',
  },

  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '1.6em 2em',
    backgroundColor: '#FFF',
    borderRadius: "12px 12px 0 0",
    borderBottom: 'solid 1px #ddd',
  },

  body: {
    padding: '2em 6em 1em',
  },

  bodyLink: {
    display: 'block',
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "600",
    marginBottom: 15,
  },

  insertLink: {
    width: 20,
    height: 12,
    marginRight: 15,
  }



}));

