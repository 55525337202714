import React from 'react';
import { useStyles } from './styles';
import { Box, Typography, IconButton, Grid, Dialog } from '@material-ui/core';
import { getMortgageLabel, getLoanLabel, getAccountLabel } from '../../helpers'
import { useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { getDocLanverDetails } from '../../actions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CancelIcon from '@material-ui/icons/Cancel';
import tick from '../../assets/tick.png';
import Cookies from 'js-cookie';
import InfoIcon from '@material-ui/icons/Info';
import moment from 'moment';

export const PurgedDaysPopup = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const browserName = Cookies.get('browser');
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDocumentCenter = async () => {
    if (browserName.includes('Safari ')) {
    const res = await getDocLanverDetails(props.selectedAccount);
      if (res) {
      var newWin = window.open(res.url,"_blank");             
      if(!newWin || newWin.closed || typeof newWin.closed=='undefined') 
        { 
            alert('A popup blocker is preventing the application from opening the page. To allow pop-ups, go to Safari app and choose Safari>Preferences, then click websites and allow popup for your website.')
        }
      }
    } else {
      history.push('/DocumentCenter')
    }
  }

  const getText = () => {
   if (props?.liquidationType == 'foreclosure') {
    return 'A foreclosure sale date has been held with respect to this property.'
   } else if (props?.liquidationType == 'chargedOff') {
    return `Your ${getLoanLabel()?.toLowerCase()} has been charged off.`
   } else if (props?.liquidationType == 'paidInFull') {
    return `Your ${getLoanLabel()?.toLowerCase()} has been paid in full.`
   }
  }

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={props?.closePurgedDaysPopup} open={props?.open}>
     <IconButton onClick={props?.closePurgedDaysPopup} className={classes.closeIcon}>
        <CancelIcon />
      </IconButton>
      <Box className={classes.root}>
       
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>{getAccountLabel()} Access Info</Typography>
        </Box>
        
        <Box className={classes.cardContainer}>

          { ((props?.liquidationType == 'foreclosure') || (props?.liquidationType == 'chargedOff') || (props?.liquidationType == 'paidInFull')) &&  
          <Box className={classes.transferBox}>
            <Grid container direction="row" alignItems="center" style={{ paddingLeft: '5px' }}>
              <Grid item md={12} xs={12} lg={12}>
                <Box display='flex' alignItems="center">
                  <InfoIcon className={classes.paidInFullIcon} />
                  <Typography className={classes.informationText}>{getText()}</Typography>
                </Box>
              </Grid>
            </Grid>
         </Box>}
            
         { (props?.liquidationType == 'chargedOff') &&
            <Typography className={classes.paragraph}>We would like to inform you that your {getMortgageLabel()?.toLowerCase()} {getLoanLabel()?.toLowerCase()} has been charged off by the owner of the {getLoanLabel()?.toLowerCase()}. As a result, you will no longer be able to access your {getAccountLabel()?.toLowerCase()} after <b>{moment(props?.loanInfoData?.purgedDate).format('MM/DD/YYYY')}</b>.</Typography>}

            {((props?.liquidationType == 'foreclosure') || (props?.liquidationType == 'paidInFull')) &&
            <Typography className={classes.paragraph}>We would like to inform you that your {getMortgageLabel()?.toLowerCase()} {getLoanLabel()?.toLowerCase()} has been closed with us. As a result, you will no longer be able to access your {getAccountLabel()?.toLowerCase()} after <b>{moment(props?.loanInfoData?.purgedDate).format('MM/DD/YYYY')}</b>.</Typography>}
           
             <Box style={{display: 'flex'}}><img src={tick} alt="check icon" className={classes.tick}/><Typography className={classes.paragraph}>For future reference, you can save/print any important information from the {getAccountLabel()?.toLowerCase()}.</Typography></Box> 
             <Box style={{display: 'flex'}}><img src={tick} alt="check icon" className={classes.tick}/><Typography className={classes.paragraph}>You can also view/download any available documents in <a className={classes.link} onClick={() => handleDocumentCenter()}>document center</a> .</Typography></Box>
        </Box>
    
      </Box> 
    </Dialog>
  )
}
