import Cookies from 'js-cookie';
import { axiosPublic } from '../config';
import { FETCH_HELP_CONTENT, FETCH_HELP_TILE_CONTENT, FETCH_FAQ_CONTENT } from '../types';
import { getAppSource, getUserType } from '../helpers'

export const getHelpContent = (pageCode) => async dispatch => {
  const data = {
    "pageCode": pageCode,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }

  try {
    let content = '';
    content = await axiosPublic.post('/OT/GetPageContent ', data);
    dispatch({
      type: FETCH_HELP_CONTENT,
      payload: content.data
    })
    return content.data;
  } catch (e) {
    return null
  }
}

export const getFaqContent = (pageCode) => async dispatch => {
  const data = {
    "pageCode": pageCode,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }

  try {
    let content = '';
    content = await axiosPublic.post('/OT/GetPageContent ', data);
    dispatch({
      type: FETCH_FAQ_CONTENT,
      payload: content.data
    })
    return content.data;
  } catch (e) {
    return null
  }
}

export const getHelpTileContent = (htmlContent) => async (dispatch) => {
  dispatch({
    type: FETCH_HELP_TILE_CONTENT,
    payload: htmlContent
  })
  return htmlContent;
}