import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: "120em",
    padding: "4.5em 0",
    margin: '0 auto',
    background: "#E8E8E8",
    [theme.breakpoints.down('md')]: {
      padding: "7em 1.5em",
    },

  },

  loader: {
    width: '100%',
  },

  loaderContainer: {
    width: '100%',
    height: '100%',
    minHeight: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainContainerWidth: {
    width: "120em"
  },
  bgWhith: {
    background: "#fff"
  },
  title: {
    color: "#1A4664",
    fontFamily: "Montserrat",
    fontSize: "22px",
    fontWeight: "700",
    //marginTop: 30,
    //marginBottom: 55,
    marginTop: 24,
    marginBottom: 14,
  },

  link: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    marginTop: 14,
  },

  disabledLink: {
    color: '#4D4D4D',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.5px',
    lineHeight: 'normal',
    textDecoration: 'none',
    cursor: 'pointer',
    fontStyle: 'normal',
    textDecoration: 'none'
  },

  activeLink: {
    color: '#121D22',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.5px',
    lineHeight: 'normal',
    textDecoration: 'none'
  },

  container: {
    //width: '100%',
    //width: '90%',
    //width:"120em",
    //padding: '60px 80px',
    padding: '60px 0 60px 0',
    backgroundColor: '#fff',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      padding: "1.5em",
    },
  },

  stepperContainer: {
    width: '15%',
    maxWidth: 205,
    borderRight: 'solid 1px #828282'
  },

  stepperStyle: {
    padding: 0,
  },

  aciveLabelStyle: {
    color: "#F7B15C",//"#3064A6 !important",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: "600",
    cursor: "pointer",
  },

  labelStyle: {
    color: "#202020 !important",
    fontFamily: "Montserrat",
    fontSize: "16px",
    cursor: "pointer",
  },

  contantContainer: {
    //width: '80%',
    width: '85%',
  },

  btnContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center',
    marginTop: 40,
  },

  arrowBack: {
    marginRight: 126,
  },

  sectionBox: {
    width: '100%',
    minHeight: '50vh',
    paddingLeft: 100,
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  stepperIcon: {
    fontSize: "30px",
    //marginRight:"10px"
  },
  // circel:{
  //   //color: "rgb(242, 199, 119)",
  //   height: "25px",
  //   width: "25px",
  //   border: "2px solid #000000",
  //   borderRadius: "50%",
  // },
  circle: {
    boxSizing: 'border-box',
    height: '26px',
    width: '26px',
    border: '2px solid #F2C777',
    borderRadius: '50%',
    display: 'inline-block',
    textAlign: "center"
  },

  /*Comman style*/
  inputText: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "bold",
    width: '100%',
  },

  flexBox: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  inputField: {
    width: '43%',
    marginBottom: 40,
  },

  title: {
    width: '100%',
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: "600",
    marginBottom: 18,
  },
  subtitle: {
    width: '100%',
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: "600",
    marginBottom: 18,
  },

  inputBox: {
    width: '100%',
    marginBottom: 20,
  },

  formControl: {
    width: '100%',
    marginBottom: 20,
  },

  formLabel: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "16px",
    letterSpacing: "0.44px",
    lineHeight: "24px",
    marginBottom: 10,
  },
  questionText: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "16px",
    letterSpacing: "0.44px",
    lineHeight: "24px",
    marginBottom: 10,
  },

  // labelStyle: {
  //   color: "#202020",
  //   fontFamily: "Montserrat",
  //   fontSize: "16px",
  // },

  formStyle: {
    width: '100%',
  },

  inputFieldFullWidth: {
    width: '100%',
    //marginBottom: 40,
  },

  infoIcon: {
    verticalAlign: "middle",
    fontSize: "10px"
  },
  /*End*/

  inputFieldNumber: {
    width: '150px',
    marginBottom: 20,
  },
  inputFieldDate: {
    width: '200px',
    marginBottom: 20,
  },
  inputTextTextarea: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "bold",
    width: '100%',
    padding: "10px"
  },
  errorMessage: {
    color: "#E30909",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 600,
    letterSpacing: "0.36px",
    //lineHight: "19px",
    padding: "0 5px"
  }


}));

