import { makeStyles, withStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 12,
    position: 'relative',
    backgroundColor: "#F5F6F7",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    padding: '2.2em 0em',
    [theme.breakpoints.down('xs')]: {
      padding: '1.6em',
    },
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxWidth: '40%',
    maxHeight: "100%",
    backgroundColor: 'transparent',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90%',
    },
  },

  header: {
    width: '100%',
  },

  iconStyle: {
    position: 'absolute',
    top: 10,
    right: 10,
  },

  title: {
    color: "#444444",
    fontFamily: "Inter",
    fontSize: '16px',
    fontWeight: "500",
    marginBottom: 15,
    marginRight: 45,
    padding: '0px 16px'
  },
  subTitle: {
    color: "#979797",
    fontFamily: "Inter",
    fontWeight: "600",
    marginBottom: 22,
    padding: '0px 34px',
    width: '100%',
    marginTop: 20
  },
  paragraph: {
    width: '100%',
    color: "#979797",
    fontFamily: "Inter",
    fontSize: '16ps',
    fontWeight: "600",
    marginBottom: 22,
    padding: '0px 25px',
  },
  cardBody: {
    width: '100%',
  },
  btn: {
    width: 189,
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "600",
    display: 'block',
    // margin: '2em auto 1.5em',
    float: "right",
    marginRight: "25px",
    textTransform: "capitalize"
  },
}));

