
export const styles = theme => ({
  root: {
    width: '100%',
    height: '100vh',
    backgroundColor: '#ECEBE6',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  iconStyle: {
    display: 'block',
    width: 50,
    height: 50,
    marginBottom: 10,
    margin: '0 auto',
  },

  container: {
    minWidth: 200,
    minHeight: 200,
  },

  button: {
    color: "#131414",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "600",
    display: 'block',
    margin: '20px auto',
  }
});
