import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';

import { Box, Grid, Typography, Button } from '@material-ui/core';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import home from '../../../assets/LoanDetails/home.svg';
import phone from '../../../assets/LoanDetails/phone.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from './styles';

export default function DetailedAccordion(props) {
  const classes = useStyles();

  const { id, coBorrowerMailAddress1, coBorrowerMailAddress2, coBorrowerMailCity, coBorrowerMailCountry,
    coBorrowerMailState, coBorrowerMailZip, coborrowerHomePhone, coBorrowerName,
    coborrowerWorkPhone, coborrowerWorkPhoneExtension } = props;


  return (
    <div className={classes.root}>
      <Accordion elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
          className={classes.accordionSum}>
          <div className={classes.column} style={{ flexBasis: '100%' }}>
            <Typography className={classes.heading} fontWeight="fontWeightBold">
              Co-Borrower {id} {coBorrowerName}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Grid container >
            <Grid item xs={12} sm={6}>
              <Box display="flex" className={classes.custAddress}>
                <img src={home} alt="phone icon" className={classes.loanInfoIcon} />
                <Box>
                  <p className={`${classes.bodyFont} ${classes.wordBreak}`}>
                    {coBorrowerMailAddress1 ? coBorrowerMailAddress1 : ''}
                  </p>
                  <p className={`${classes.bodyFont} ${classes.wordBreak}`}>
                    {coBorrowerMailAddress2 ? coBorrowerMailAddress2 : ''}
                  </p>
                  <p className={classes.bodyFont}>
                    {coBorrowerMailCity ? coBorrowerMailCity + ', ' : ''}
                    {coBorrowerMailState ? coBorrowerMailState + ', ' : ''}
                    {coBorrowerMailZip ? coBorrowerMailZip : ''}
                  </p>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid className={classes.phoneContainer} container spacing={0} alignItems="flex-end" >
                <Box>
                  <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.loanCareNo}>
                    {coborrowerHomePhone !== '' && <PhoneAndroidIcon className={classes.loanInfoIcon} style={{ color: '#535D6D', fontSize: '28px', marginRight: '20px' }} />}
                    <Typography variant='body1' className={`${classes.bodyFont} ${classes.careNo}`}>
                      {coborrowerHomePhone}
                    </Typography>
                  </Grid>
                  <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.loanCareNo}>
                    {(coborrowerWorkPhone != '0' && coborrowerWorkPhone != '') || (coborrowerWorkPhoneExtension != '0' && coborrowerWorkPhoneExtension != '') ?
                      <img src={phone} alt="phone icon" className={classes.loanInfoIcon} />
                      : null}

                    <Box className={classes.phoneExt} display="flex">
                      <Typography variant='body1' className={`${classes.bodyFont} ${classes.mr10}`}>
                        {coborrowerWorkPhone != '0' && coborrowerWorkPhone}
                      </Typography>

                      <Box display="flex">
                        {(coborrowerWorkPhoneExtension != '0' && coborrowerWorkPhoneExtension != '') ?
                          <><Typography variant='body1' className={`${classes.bodyFont} ${classes.mr10}`}>
                            <b>Ext</b>
                          </Typography>
                            <Typography variant='body1' className={classes.bodyFont}>
                              {coborrowerWorkPhoneExtension != '0' && coborrowerWorkPhoneExtension}
                            </Typography></> : null}
                      </Box>
                    </Box>
                  </Grid>
                </Box>
                <Button
                  className={classes.btn}
                  type='submit' size="large" variant="contained" color="primary" onClick={() => props.Click(props.item, 'coBorower')}>
                  Edit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div >
  );
}
