import React, { useState, useEffect } from 'react';
import {
  Box, Button, IconButton,
  InputAdornment, FormControl,
  CssBaseline, CircularProgress, OutlinedInput, TextField, InputLabel,
  FormControlLabel, FormGroup, Checkbox
} from '@material-ui/core';
import { useStyles } from './styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Link } from 'react-router-dom';
import { encrypt,decrypt } from '../../helpers/encrypt';
import { getAppSource, getPublicIp, getUserType, renderDefaultMsg, getBorrowerLabel, getPointPrivateLabel } from '../../helpers';
import Cookies from 'js-cookie';
import { handleSignIn, resendActivationEmail, getClientName } from '../../actions';
import { useHistory } from 'react-router-dom';
import { MultiAccountAlert, TransferAlert, AccountLockedAlert, TermsConditions, PurgedLoanPopup } from '../../components';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {  Popover } from 'antd';

export const SignIn = () => {

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [values, setValues] = React.useState({
    showPassword: false,
    password: '',
    userName: ''
  });
  const [state, setState] = React.useState({
    userName: '',
    resedMailMsg: '',
  });
  const [resendState, setResendState] = React.useState({ resendMailLoading: false, errorMes: "", successMessage: "" });
  const [resendMailMsg, setResendMailMsg] = useState(false);
  const [resendMailLoading, setResendMailLoading] = useState(false);
  const [resUserName, setResUserName] = useState('');
  const [openMultiUserIdAlert, setOpenMultiUserIdAlert] = useState(false);

  const [isTransfer, setTransferStatus] = useState(false);
  const [transferData, setTransferData] = useState({
    fromPrivateLabel: "", toPrivateLabelPhone: "",
    toPrivateLabelURL: "", toPrivateLabel: ""
  });
  const [openAccountLockedStatus, setAccountLockedStatus] = useState(false);
  const [passwordChangeSuccess, setPwdChangedStatus] = useState(location.search.includes('?password=changed'));
  const [checkboxState, setCheckboxState] = useState(false);
  const [open, setOpen] = useState(false);
  const [accountTempMsg, setAccountTempLockMsg] = useState(false);
  const [purgedLoanPopup, setPurgedLoanStatus] = useState(false);
  const [purgedLoanMsg, setPurgedLoanMsg] =  useState();

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    if (!checkboxState && (values.userName.length !== 0)) {
      setOpen(newOpen);
    } else {
      setOpen(false)
    }
  };

  useEffect(() => {
    let usernames = Cookies.get('username')
    if (usernames != undefined) {
      let parseUserNames = JSON.parse(usernames)
      if (parseUserNames?.length > 0) {
      let userNames = parseUserNames?.filter(key => key.privateLabelName?.toLowerCase() == Cookies.get('privateLabelUrl')?.toLowerCase())
      if (userNames?.length > 0) {
          setValues({
            showPassword: false,
            password: '',
            userName: userNames[0].username
          })
          setCheckboxState(true)
        }
      }
    }
  },[Cookies.get('username')])

  useEffect(() => {
    if (passwordChangeSuccess) {
      history.push('/SignIn')
    } 
  },[passwordChangeSuccess])
 
  const closePurgedLoanPopup = () => {
    setPurgedLoanMsg()
    setPurgedLoanStatus(false)
  }

  const handleCloseAlert = () => {
    setOpenMultiUserIdAlert(false)
  }

  const handleForgotPasswordCloseAlert = () => {
    Cookies.remove('privateToken')
    setAccountLockedStatus(false)
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    if (Cookies.get('username') != '' && (prop == 'userName')) {
        setCheckboxState(false);
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getClientShortName = async (forCeaseandPersist=false) => {

    const data = {
      "accountNumber" : null,
      "userName": encrypt(values.userName.trim()),
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType()
    }

    const result = await dispatch(getClientName(data));
    if (result) {
      const { lstResponseStatus, bankDetail, featureToggle } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        Cookies.set('clientName', bankDetail.clientShortName)
        Cookies.set('isAgency', bankDetail?.agencyFlag == "N" ? true : false)
        Cookies.set('escrow', bankDetail?.displayEscrowMenu)
        Cookies.set('ceaseDesistFlag', bankDetail?.ceaseDesistFlag);
        // Cookies.set('isDocRequestAllowed', featureToggle?.isDocRequestAllowed);
        Cookies.set("payOffRequestSLA", bankDetail?.payOffRequestSLA);
       
        if (bankDetail?.displayEscrowMenu) {
         window.location.reload(false)
          return true
        } else {
         window.location.reload(false)
        }
      }
    }
  }

  const handleSubmit = async (e) => {
  
    e.preventDefault();
    setResUserName(values.userName);
    setLoading(true);
    setErrorMsg('');

    setResendState({
      ...resendState,
      errorMes: "",
      successMessage: ''
    })

    const data = {
      "userName": encrypt(values.userName.trim()),
      "password": encrypt(values.password),
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": await getPublicIp(),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType()
    }

    const res = await handleSignIn(data);
    var redirectUrl = Cookies.get('redirectUrl')
    setAccountTempLockMsg(false)
    Cookies.set("showPromisePopup", true)
    Cookies.set('isMaturityExtenstionOpened',true);
    Cookies.set('isUpcomingPayDueOpened',true);
    Cookies.set("showDeliqPopup", true)

    if (!res) {
      setLoading(false);
      setErrorMsg(renderDefaultMsg('400'));
      Cookies.remove('redirectUrl')
    }
    if (res && res.expiresIn) {

      Cookies.set("firstLastName", decrypt(res?.firstName) +' '+decrypt(res?.lastName))
      if (checkboxState) {
        let name = Cookies.get('username')
        if (name != undefined) {
          let savedUsernames = [];
          savedUsernames = JSON.parse(name)
          var index = savedUsernames.findIndex((item) => item.privateLabelName?.toLowerCase() == Cookies.get('privateLabelUrl')?.toLowerCase())
          if (index == -1) {
            savedUsernames.push({'privateLabelName' : Cookies.get("privateLabelUrl")?.toLowerCase(), 'username': values.userName})
            Cookies.set("username", savedUsernames)
          }
           else {
            savedUsernames[index]['username'] = values.userName;
            Cookies.set("username", savedUsernames)
          }
        } else {
          const userNameArr = [];
          userNameArr?.push({privateLabelName : Cookies.get("privateLabelUrl")?.toLowerCase(), username: values.userName}) 
          Cookies.set("username", userNameArr)
        }
      } else {
        let username = Cookies.get('username')
        if (username != undefined) {
          let arr = JSON.parse(username)
          var index = arr.findIndex((item) => item.privateLabelName?.toLowerCase() == Cookies.get('privateLabelUrl')?.toLowerCase())
            if (index !== -1) {
              arr.splice(index,1)
              Cookies.set("username", arr)
            }
        }
      }
      Cookies.set("isSemiAnnuallyPopUpAppeared", res.isSemiAnnuallyPopUpAppeared)
      //Cookies.set("isSemiAnnuallyPopUpAppeared", true)

     Cookies.set("userName", encrypt(values.userName.trim().toLowerCase()))
      if (!res.isVerified) {
        getClientShortName(true);
        Cookies.remove('redirectUrl')
        history.push('/UserProfile/Profile');
      } else if (res && redirectUrl?.includes('Communication')) { 
        getClientShortName(true);
        history.push('/UserProfile/CommunicationPreferences');
      } else if (res && redirectUrl?.includes('Identity')) { 
        getClientShortName(true);
        history.push('/IdentityTheft');
      } else if (res && redirectUrl?.includes('EscrowInfo')) { 
        Cookies.set('escrow', true)
        const escrowStatus = getClientShortName(true);
        if (escrowStatus) {
            history.push('/EscrowInfo');
        }
      } else if (res && redirectUrl?.includes('DocumentRequest')) { 
        getClientShortName(true)
        history.push(`/DocumentRequest?requestId=${Cookies.get('requestId')}`);
      } else {
        Cookies.remove('redirectUrl')
        if (res.hasMultiUser) {
          getClientShortName(true);
          setLoading(false);
          setOpenMultiUserIdAlert(true)
        } else {
          setLoading(false);
          setValues({ password: '', userName: '' });
          getClientShortName(true);
          Cookies.set("logoutMsgCount", 0)
          history.replace({ pathname: "/Dashboard" });
        }
      }
    }
    if (res && !res.expiresIn) {
      Cookies.set("userName", encrypt(values.userName.trim().toLowerCase()))
      if (res.accessToken.includes("[ResendLink]")) {
        setResendMailMsg(true)
      } else {
        setErrorMsg(res.accessToken);
        setResendMailMsg(false)
        if (res.accessToken.includes('locked') || res.accessToken.includes('maximum')) {
          setAccountLockedStatus(true)
          setAccountTempLockMsg(true)
        } else if (res.accessToken.includes('account that is no longer available')) {
          setErrorMsg('');
          setPurgedLoanMsg(res.accessToken);
          setPurgedLoanStatus(true)
        }
      }
      setLoading(false);
      if (checkboxState) {
        setValues({
          password: '',
          userName: values.userName,
        });
      } else {
        setValues({
          password: '',
          userName: '',
        });
        setCheckboxState(false);
      }
      setPwdChangedStatus("");
    }
    if (res && res.isTransfer) {
      Cookies.set("userName", encrypt(values.userName.trim().toLowerCase()))
      setTransferData(res)
      setTransferStatus(getPointPrivateLabel() ? false : true)
    }
  }

  const resendMail = async (e) => {
    const data = {
      "firstName": "",
      "emailID": "",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get("publicIp"),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": encrypt(resUserName.trim()),
    }

    setErrorMsg("");
    setResendMailMsg(false);
    setResendState({ ...resendState, resendMailLoading: true, errorMes: "", successMessage: "" })
    const result = await resendActivationEmail(data);
    if (result) {
      const { lstResponseStatus } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        setResendState({
          ...resendState,
          resendMailLoading: false, errorMes: "",
          successMessage: 'An email with the verification link has been sent to your registered email address.'
        })
      } else {
        setResendState({ ...resendState, resendMailLoading: false, errorMes: lstResponseStatus[0].statusDesc, successMessage: '' })
      }
    } else {
      setResendState({ ...resendState, errorMes: renderDefaultMsg('400'), resendMailLoading: false, successMessage: '' })
    }
  }
 
  const checkboxChangeHandler = () => {
    saveUserName()
  }

  const saveUserName = () => {
    if (values.userName.length === 0) {
      setCheckboxState(false)
    } else {
      setCheckboxState(!checkboxState)
    }
  }

  const disableBtn = values.password.length === 0 || values.userName.length === 0;

  return (
    <CssBaseline>
      <Box container="true" maxWidth='xl' className={classes.root}>
        <Box className={classes.formContainer} >
          <p className={classes.title}>Sign In</p>
          {!resendMailMsg && errorMsg !== '' && !accountTempMsg && <p className={classes.errorMsg}>{errorMsg !== '' ? errorMsg : ''}</p>}

          {!resendMailMsg && errorMsg !== '' && accountTempMsg && 
           <>
            <p className={classes.errorAccLockMsg}>{errorMsg !== '' ? errorMsg.split('#')[0] : ''}</p>
            <p className={classes.errorMsg}>{errorMsg !== '' ? errorMsg.split('#')[1] : ''}</p>
           </>
          }

          {resendState.resendMailLoading &&
            <Box className={classes.resendLinkLoader}>
              <CircularProgress color='primary' />
            </Box>
          }
          {resendMailMsg && errorMsg === '' &&
            <p className={classes.errorMsg}> Activation link expired. To resend the activation link again please click on <span style={{ cursor: "pointer", fontWeight: 600, textDecoration: "underline" }} onClick={() => resendMail()}> Resend email </span> or contact customer care.</p>
          }
          {resendState.successMessage !== '' && resendState.errorMes === '' && <p className={classes.successMsg}>{resendState.successMessage}</p>}
          {resendState.successMessage === '' && resendState.errorMes !== '' && <p className={classes.errorMsg}>{resendState.errorMes}</p>}
  
          {passwordChangeSuccess && <p className={classes.logoutSuccessMsg}>Your password has been changed successfully.</p>}
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Box className={classes.inputContainer}>
              <TextField
                color='primary'
                id="outlined-loanNumber"
                label="Username"
                variant="outlined"
                type="text"
                value={values.userName}
                onChange={handleChange('userName')}
                fullWidth
                inputProps={{
                  className: classes.inputStyle
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelStyle
                  }
                }}
              />
               {/* Save Username Popover  */}
               <FormGroup row style={{'justifyContent': 'space-between', 'height': '35px'}} >
                 <Box textAlign="left">
                  <Popover content={
                    <>
                      <div className={classes.tooltipTitle}>Save Username  <a className={`${close} ${classes.close}`} onClick={hide}>
                          &times;
                        </a>
                      </div>
                      <div className={classes.desc}>We don't recommend saving your username if you're on shared device.</div>
                      </>
                    }
                    trigger="click"
                    open={open}
                    placement="bottom"
                    overlayStyle={{width: 300}}
                    onOpenChange={handleOpenChange}>
                   <FormControlLabel
                    control={<Checkbox
                      checked={checkboxState}
                      onClick={() => checkboxChangeHandler()}
                      name="save username"
                    />}
                    // label="Save Username"
                    className={classes.checkboxStyle}
                    classes={{
                      label: checkboxState ? classes.radioLabel : classes.radioLabel
                    }}
                  /> 
                  </Popover>
                  <span className={classes.saveUsername}>Save Username</span>
                  </Box>
                  <Box textAlign="right"><Link className={classes.linkStyle} to="/RecoverUsername" tabIndex="-1" >Forgot Username</Link></Box>
                </FormGroup>
            </Box>
            <Box className={classes.inputContainer}>
              <FormControl variant="outlined" fullWidth >
                <InputLabel className={classes.labelStyle} htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  color='primary'
                  id="outlined-adornment-password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange('password')}
                  labelWidth={100}
                  className={classes.inputStyle}
                  endAdornment={
                    <InputAdornment position="end" tabIndex="-1">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        tabIndex="-1"
                      >
                        {values.showPassword ? <Visibility tabIndex="-1" /> : <VisibilityOff tabIndex="-1" />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Box textAlign="right">
                <Link className={classes.linkStyle} to="/RecoverPassword" tabIndex="-1" >Forgot Password</Link>
              </Box>
            </Box>
            {loading ?
              <Button disabled={true} type='submit' className={classes.signInBtn} size="large" fullWidth variant="contained">
                <CircularProgress color='primary' />
              </Button>
              :
              <Button disabled={disableBtn} type='submit' className={classes.signInBtn} size="large" fullWidth variant="contained" color="primary">
                sign In
              </Button>
            }
          </form>
          <Box className={classes.orContainer}>
            <Box className={classes.orWraper}><p className={classes.orText}>or</p></Box>
          </Box>
          <Button component={Link} to='/EmailSignin' className={classes.emailBtn} size="large" fullWidth   variant='outlined' color="primary">
            SIGN IN WITH EMAIL
          </Button>
          <Box className={classes.otherLinks}>
            <p className={classes.otherLinksText} >Don’t have an account yet? <Link className={classes.otherLinkStyle} to="/AccountVerification">Create an account</Link></p>
            <p className={classes.otherLinksText} >Not a {getBorrowerLabel().toLowerCase()}? Request access for <Link className={classes.otherLinkStyle} to="/ThirdPartyRequest">Third-Party</Link></p>
          </Box>
        </Box>
        {openMultiUserIdAlert &&
          <MultiAccountAlert
            userName={values.userName}
            open={openMultiUserIdAlert}
            handleClose={handleCloseAlert}
          />}
        {/* transfer dialog box */}
        {isTransfer &&
          <TransferAlert
            fromPrivateLabel={transferData.fromPrivateLabel}
            toPrivateLabel={transferData.toPrivateLabel}
            toPrivateLabelPhone={transferData.toPrivateLabelPhone}
            toPrivateLabelURL={transferData.toPrivateLabelURL}
          />}
        {openAccountLockedStatus &&
          <AccountLockedAlert
            handleClose={handleForgotPasswordCloseAlert}
            accountLocked={errorMsg}
          />
        }
        {purgedLoanPopup &&
          <PurgedLoanPopup open={purgedLoanPopup} closePurgedLoanPopup={closePurgedLoanPopup} type={'signin'} purgedLoanMsg={purgedLoanMsg}
          />
        }
      </Box>
    </CssBaseline>
  )
}
