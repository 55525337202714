import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 12,
    backgroundColor: "#F5F6F7",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    padding: '3.2em 3.8em',
    [theme.breakpoints.down('xs')]: {
      padding: '1.6em',
    },
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxWidth: 570,
    maxHeight: "100%",
    backgroundColor: 'transparent'
  },

  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  body: {
    width: '100%',
  },

  checkIconContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  checIcon: {
    color: theme.palette.secondary.main,
    height: 36,
    width: 36
  },

  title: {
    color: "#0F355C",
    fontFamily: "Montserrat",
    fontSize: '2.4em',
    fontWeight: "600",
    textAlign: "center",
  },

  text: {
    color: '#0C0B0B',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '400',
    letterSpacing: '0.53px',
    lineHeight: '20px',
    margin: '5px 0 22px 0',
  },

  subTitle: {
    color: "#0C0B0B",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    marginBottom: 20,
    textAlign: 'center'
  },

  flexBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  btns: {
    margin: '0 10px',
    width: '125px',
    fontFamily: "Montserrat",
    fontSize: '1.6em',
  }

}));

