import React from 'react';
import { useStyles } from './styles';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const FileExtensionAlert = (props) => {

  const { open, handleExtensionAlertClose, file} = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      width="600px"
      PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={handleExtensionAlertClose} open={open}
    >
      <Box className={classes.root}>
        <IconButton className={classes.iconStyle} onClick={handleExtensionAlertClose}>
          <CancelIcon />
        </IconButton>
        <Box className={classes.header}>
        
           <Typography className={classes.subTitle}>The specified file "{file}" could not be uploaded.</Typography>
          <Typography className={classes.paragraph}>Files with allowed extensions: .jpeg, .jpg, .png and .pdf.</Typography>
        </Box>
        
        <Button
          color='primary'
          variant='contained'
          className={classes.btn}
          onClick={handleExtensionAlertClose}
        >OK</Button> 
      </Box>
    </Dialog>
  )
}
