import React from 'react';
import { useStyles } from './styles';
import { Box, Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const PurgedLoanPopup = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={props?.closePurgedLoanPopup} open={props?.open}>
     
      <Box className={classes.root}>
       
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>{props.type == 'signin' ? 'Account Doesn’t Exist' : 'Access Denied'}</Typography>
        </Box>
        
        <Box className={classes.cardContainer}>
          <Box className={classes.statusContainer}>
             {/* <Typography className={classes.paragraph}>{props.type == 'signin' ? 'You are trying to access an account that is no longer available since it has been closed for more than 7 years.' : 'You are no longer able to register for the account you are trying to set up since it has been closed for at least 18 months.' }</Typography> */}
             <Typography className={classes.paragraph}>{props?.purgedLoanMsg}</Typography>
          </Box>
        </Box>

        <Box className={classes.cardFooter}>
            <Button
              variant="contained" 
              color="primary"
              onClick={() => props?.closePurgedLoanPopup()}
              className={classes.btn}
            > OK</Button>
        </Box>
    
      </Box> 
    </Dialog>
  )
}
