import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField, OutlinedInput, InputLabel } from '@material-ui/core';
export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 12,
    backgroundColor: "#ffffff",
    position: 'relative',
    padding: '25px',
    [theme.breakpoints.down('xs')]: {
      padding: '1.6em',
    },
  },

  labelsArea: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  labelbox: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 10px'
  },

  dividerDoted: {
    width: '50px',
    border: 'solid 1px',
    borderColor: 'rgb(0, 0, 0)',
  },

  dividerColored: {
    width: '50px',
    border: 'solid 1px',
    borderColor: '#F7B15C',
  },


  innerPart1: {
    width: '15%',
    height: '18px',
    float: 'left',
    backgroundColor: '#99C2F6'
  },
  innerPart1_1: {
    width: '15%',
    height: '18px',
    float: 'left',
    backgroundColor: '#3064A6'
  },
  innerPart1_2: {
    width: '15%',
    height: '18px',
    float: 'left',

  },
  midline: {
    width: '100%',
    height: '1px',
    borderStyle: 'dashed',
    borderColor: '#000',
    borderWidth: '1px',
    display: 'inline-flex'
  },
  amountSubmit: {
    fontSize: '1.6em',
    fontFamily: 'Montserrat',    
  },
  innerPart2: {
    width: '70%',
    marginLeft: '10px',
    color: 'rgba(0,0,0,0.74)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    letterSpacing: '0.5px',
    lineHeight: '20px',
    float: 'left',
  },

  header: {
    width: '92%',
    padding: '25px 0 10px',
    margin: '0 auto 10px',
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
  },

  heading: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "400",
    letterSpacing: "0.15px",
  },

  iconBtn: {
    position: 'absolute',
    top: 10,
    right: 10,
  },

  dialogStyle: {
    borderRadius: '12px !important',
    width: "90%"
  },
  formContainer: {
    width: '100%',
  },
  loanDetails: {
    width: '92%',
    margin: '0 auto 10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'inline',
    },
  },

  graphContainer: {
    width: '92%',
    margin: '0 auto 36px',
  },

  effectiveLoanDetails: {
    width: '92%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 0',
    [theme.breakpoints.down('sm')]: {
      display: 'inline',
    },
  },

  loanDetailsItem: {
    margin: 5,
  },

  filterBox: {
    width: '100%',
    padding: '15px 0',
    backgroundColor: "#EAECED",
  },

  filterBoxWrapper: {
    width: '92%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',    
    [theme.breakpoints.down('sm')]: {
      display: 'inline',
    },
  },

  filterInputBox: {
    paddingTop: 15,
  },

  labelText: {
    color: "rgba(0, 0, 0, 0.74)",
    fontFamily: "Montserrat",
    fontSize: "1.6em",
    fontWeight: "400",
    letterSpacing: "0.5px",
    margin: 0,
    [theme.breakpoints.down('md')]: {
      width: "auto",
    },
    [theme.breakpoints.down('sm')]: {
      width: "50%",
      fontSize: "1.4em",
    },
  },
  labelText2: {
    color: "rgba(0, 0, 0, 0.74)",
    fontFamily: "Montserrat",
    fontSize: "1.6em",
    fontWeight: "400",
    letterSpacing: "0.5px",
    margin: 0,
    [theme.breakpoints.down('md')]: {     
      fontSize: "1.6em",
    },
    [theme.breakpoints.down('sm')]: {     
      fontSize: "1.4em",
    },
    
  },

  estimatedText: {
    color: "rgba(0, 0, 0, 0.74)",
    fontFamily: "Montserrat",
    fontSize: "0.8em",
    fontWeight: "400",
    letterSpacing: "0.5px",
    margin: 0,
  },

  boldText: {
    color: "rgba(0, 0, 0, 0.74)",
    fontFamily: "Montserrat",
    fontSize: "2em",
    fontWeight: "bold",
    letterSpacing: "0.15px",
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.6em",
    },
  },

  containermargin: {
    ['@media (max-width:650px)']: { // eslint-disable-line no-useless-computed-key
      marginTop: '10px',
      marginBottom: '10px'

    },
  },
  boxwid: {
    width: '50%',
    margin: '0 auto',
    ['@media (max-width:650px)']: { // eslint-disable-line no-useless-computed-key
      margin: '0px'

    },
  },
  inputContainer: {
    width: "75%",
  },
  closeBtn: {
    height: "24px",
    width: "24px",
    backgroundColor: "#7B8390",
    borderRadius: "50%",
    display: "inline-block",
    textAlign: "center",
    color: "#ffffff",
    cursor: "pointer"
  },
  /*Mobile only*/
  flexBoxMobile:{    
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  formControlMobile:{
    [theme.breakpoints.down('sm')]: {      
      marginBottom:"20px"
    },
  },
  btnMobile:{
    textAlign:"center"
  },
  
}));


export const CutomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#25ABC7',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#25ABC7',
      },
    },
  },
})(TextField);

export const CutomOutlinedInput = withStyles({
  root: {
    "&$focused $notchedOutline": {
      border: `2px solid #25abc7`
    }
  },
  focused: {},
  notchedOutline: {}
})(OutlinedInput);

export const CustomInputLabel = withStyles({
  root: {
    "&$focused": {
      color: "#25abc7",
    }
  },
  focused: {},
})(InputLabel);