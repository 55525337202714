import React, { useEffect } from "react";
import { Box, Button, Dialog, IconButton, CircularProgress } from "@material-ui/core";
import { useStyles } from './styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import { decrypt } from '../../helpers/encrypt';
import { checkForMultiAccount, deleteMigratedUserName } from '../../actions';
import { renderDefaultMsg, maskLoan } from '../../helpers';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Cookies from 'js-cookie';




export const MultiAccountAlert = ({ userName, open, handleClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedUser, setSelectedUser] = React.useState('');
  const [accounts, setAccounts] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState('');
  const [successMsg, setSuccessMsg] = React.useState('');

  const handleChange = (event) => {
    setSelectedUser(event.target.value);
  };

  useEffect(() => {
    getUsers()
  }, []);

  const getUsers = async () => {
    setLoading(true);
    setErrMsg('');
    const res = await checkForMultiAccount();
    if (!res) {
      setErrMsg(renderDefaultMsg('400'));
    }
    if (res && res.lstResponseStatus[0].statusCode === '0') {
      setAccounts(res.lstMultiUsers);
      const selectedUser = res.lstMultiUsers.find(user => decrypt(user.userName) === userName)
      setSelectedUser(selectedUser ? selectedUser.userName : res.lstMultiUsers[0].userName);
    }
    if (res && res.lstResponseStatus[0].statusCode !== '0') {
      setErrMsg(res.lstResponseStatus[0].statusDesc);
    }
    setLoading(false)
  }

  const handleDelete = async () => {
    setLoading(true);
    setErrMsg('');
    setSuccessMsg('');
    const res = await deleteMigratedUserName({
      "lstMultiUsers": accounts,
      "selectedUserName": selectedUser
    });
    if (!res) {
      setErrMsg(renderDefaultMsg('400'));
    }
    if (res && res.lstResponseStatus[0].statusCode === '0') {
      setSuccessMsg("Users Deleted Successfully.")
    }
    if (res && res.lstResponseStatus[0].statusCode !== '0') {
      setErrMsg(res.lstResponseStatus[0].statusDesc);
    }
    setLoading(false)
  }

  const handleOK = () => {
    setErrMsg('');
    setSuccessMsg('');
    handleClose();
    Cookies.remove('privateToken');
    Cookies.remove('accessTokenID');
  }


  return (
    <Dialog
      fullScreen={fullScreen}
      PaperProps={{ classes: { root: classes.dialogStyle } }}
      onClose={handleClose}
      open={open}
    >
      <Box className={classes.root}>
        <Box className={classes.header}>
          <IconButton className={classes.iconStyle} onClick={handleClose}>
            <CancelIcon />
          </IconButton>
        </Box>
        <h1 className={classes.title}>Current Active Accounts</h1>
        {errMsg.length !== 0 ? <p className={classes.errMsg}>{errMsg}</p> : null}
        {successMsg.length === 0 ?
          <Box className={classes.body}>
            <p className={classes.subTitle}>
              It appears that you have multiple accounts registered in MyLoanWeb.
              MyLoanWeb now offers the ability to have multiple loans under one account.
              Please select which of your account you would like to make the primary account.
              All other accounts will be deleted from the system,
              but the loan data will be moved under the primary account.
              Please click 'Accept' below to acknowledge that these loans will be added to
              your primary account and that the other accounts will be removed.
          </p>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">Select Account</FormLabel>
              <RadioGroup aria-label="account" name="account" value={selectedUser} onChange={handleChange}>
                {
                  accounts.map((account) => {
                    return (
                      <FormControlLabel
                        className={classes.menuItem}
                        key={account.accountNumber}
                        value={account.userName}
                        control={<Radio />}
                        label={`${decrypt(account.userName)}, ${maskLoan(decrypt(account.accountNumber))}, ${decrypt(account.emailID)}`}
                      />
                    )
                  })
                }
              </RadioGroup>
            </FormControl>
            <Box className={classes.buttonBoxOk}>
              <Button onClick={handleDelete}
                className={classes.btn}
                variant="outlined"
                color='primary'
                disabled={loading}
              >
                {loading ? <CircularProgress size={'20px'} /> : 'Accept'}
              </Button>
            </Box>
          </Box>
          :
          <Box className={classes.body}>
            <p className={classes.subTitle}>
              Thank you for choosing <b> {decrypt(selectedUser)}</b> as primary account now you can manage all loans
              using this user name please click ok and sign in again.
            </p>
            <Box className={classes.buttonBoxOk}>
              <Button onClick={handleOK}
                className={classes.btn}
                variant="outlined"
                color='primary'
                disabled={loading}
              >
                OK
              </Button>
            </Box>
          </Box>
        }
      </Box>
    </Dialog >
  )
}