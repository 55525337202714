import React, { useEffect, useState, useRef  } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  Box, Typography, CircularProgress, Grid, 
} from '@material-ui/core';
import { useStyles } from './styles'
import  coveredBanner  from '../../../assets/coveredBanner.png';
import window from '../../../assets/Refinance/window.png';
import  coveredInsurance  from '../../../assets/coveredInsurance.png';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { insertActivityLog } from '../../../actions'
import Cookies from 'js-cookie';
import { getUserType, getAppSource } from '../../../helpers';
import { CoveredBannerPopup } from '../../CoveredBannerPopup';

export const CoveredTile = (props) => {
  
  const theme = useTheme();
  const classes = useStyles();
  const componentRef = useRef();
 
  const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'));
  const mobileMediaQuery = useMediaQuery(theme.breakpoints.down('xs'));
  const [closePopup, setClosePopup] = useState(false);
  const [bannerInfo, setBannerInfo] = useState();

  useEffect(() => {
    const filterBanner = props.data.bannerInfo.filter((item) => ((item.bannerName.toUpperCase() === 'COVERED') || (item.bannerName.toUpperCase() === 'MATIC'))).filter((key) => (key.isDisplay == true))[0];
    setBannerInfo(filterBanner)
  }, [props.data])
  
  const handleCancelIcon = () => {
    setClosePopup(false)
  }

  const handleActivity = async () => {
    setClosePopup(true)
    const data = {
      "accountNumber":  Cookies.get("selectedAccount"),
      "activityCode": "UAL202",
      "activityStatus": "Success",
      "action": (bannerInfo.bannerName.toLowerCase() == 'matic') ? 'MaticTile' : 'CoveredTile',
      "controller": "Dashboard",
      "apiName": "GetAccountInfo",
      "activityRemark": `User clicked on ${(bannerInfo.bannerName.toLowerCase() == 'matic') ? 'matic' : 'covered'} insurance tile button on taxes and insurance page`,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertActivityLog(data);
  }


  return (
    <CssBaseline>
     
     { (!mobileMediaQuery) && bannerInfo &&
     <Box container="true" maxWidth='xl' className={classes.transactionHistoryRoot} >
        <Box className={classes.coveredContainer} ref={componentRef}>
          <Box className={classes.body}>
             <img className={classes.logo} src={bannerInfo?.bannerBody} alt='desktop banner' />
            <Box>
              <a className={(bannerInfo.bannerName.toUpperCase() == 'COVERED') ? classes.quickQuoteBtn : classes.matic} onClick={() => handleActivity()}>{bannerInfo?.buttonText}<img src={window} className={classes.openIcon}/></a> 
            </Box>
          </Box>
       </Box>
      </Box>
    }

    { (mediaQuery && mobileMediaQuery) && bannerInfo &&
      <Box className={classes.coveredBannerRootCont}>
        <Box className={classes.bodyContainer} >         
          <img className={classes.logo} src={bannerInfo?.bannerImageUrl} alt='mobile image' />
        </Box>

        <Box className={classes.footer}>
          <a className={classes.btnInsurance} onClick={() => handleActivity()}>{bannerInfo?.buttonText} <img src={window} className={classes.openIcon}/></a>
        </Box>
      </Box>
   }
  
   { closePopup &&
      <CoveredBannerPopup open={closePopup} handleCancelIcon={handleCancelIcon} data={bannerInfo} page="taxes"/>
     }
    </CssBaseline>
  )
}
