import { combineReducers } from 'redux';
import themeReducer from './themeReducer';
import contentReducer from './contentReducer';
import accountVerificationReducer from './accountVerificationReducer';
import multiLoanReducer from './multiLoanReducer';
import helpLandingReducer from "./helpLandingReducer";
import helpContent from './helpContent';
import contentPrivacy from './contentPrivacy';
import fetchAccountReducer from './fetchAccountReducer';
import notificationsReducer from './notificationsReducer'
import userLoanInfo from './userLoanInfo';
import accForEditReducer from './accForEditReducer';
import selectAccountReducer from './selectAccountReducer';
import selectAccountDataReducer from './selectAccountDataReducer';
import setPaymentOptionReducer from './setPaymentOptionReducer';
import { USER_LOGOUT } from '../types';
import pageContentReducer from './pageContentReducer';
import thirdPartyReducer from './thirdPartyReducer';
import setEditProfileReducer from './setEditProfileReducer';
import upcomingTransReducer from './upcomingTransReducer';
import accInfoReducer from './accInfoReducer';
import faqReducer from './faqReducer';
import escrowReducer from './escrowReducer';
import releaseAnnouncement from './releaseAnnouncement';
import announcementReducer from './announcementReducer';
import emergencyAlertReducer from './emergencyAlertReducer';
import setEditProfileDataReducer from './setEditProfileDataReducer';
import reducer from './reducer'; //Generic toaster implementation
import loanInfoReducer from './loanInfoReducer';
// export default combineReducers({
const appReducer = combineReducers({
  theme: themeReducer,
  content: contentReducer,
  pageContent: pageContentReducer,
  accountVerification: accountVerificationReducer,
  multiLoanResponse: multiLoanReducer,
  helpLandingReducer: helpLandingReducer,
  htmlContent: helpContent,
  contentPrivacy: contentPrivacy,
  accounts: fetchAccountReducer,
  messages: notificationsReducer,
  userLoanInfor: userLoanInfo,
  accForEditDetail: accForEditReducer,
  selectedAccountData: selectAccountDataReducer,
  selectedAccount: selectAccountReducer,
  paymentOption: setPaymentOptionReducer,
  thirdPartyPopupState: thirdPartyReducer,
  setEditProfileReducer: setEditProfileReducer,
  setEditProfileDataReducer:setEditProfileDataReducer,
  upcomingTransState: upcomingTransReducer,
  accountInfoData: accInfoReducer,
  faqReducer: faqReducer,
  escrowReducer: escrowReducer,
  releaseAnnouncement: releaseAnnouncement,
  announcementReducer: announcementReducer,
  emergencyAlertReducer: emergencyAlertReducer,
  toaster:reducer,
  loanInfoReducer: loanInfoReducer
});

export default (state, action) => {
  if (action.type === USER_LOGOUT) {
    delete state.accountVerification;
    delete state.multiLoanResponse;
    delete state.accounts;
    delete state.messages;
    delete state.userLoanInfor;
    delete state.accForEditDetail;
    delete state.selectedAccountData;
    delete state.selectedAccount;
    delete state.paymentOption;
    delete state.thirdPartyPopupState;
    delete state.setEditProfileReducer;
    delete state.accountInfoData;
    delete state.escrowReducer;
  }

  return appReducer(state, action)
}
