import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 550,
    borderRadius: 12,
    backgroundColor: 'white',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)"
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxHeight: "100%",
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '20px',
        margin: '50px 15px'
      },
  },

  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "400",
    textAlign: 'center',
    margin: 14,
  },

  cardBody: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '12px 2em',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  cardBody2: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '8px 3em',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  statusTitle: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '18px',
    fontWeight: "700",
    padding: '3px 2px 2px 3px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px'
    }
  },

  statusBody: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "bold",
  },

  linkStyle: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    fontWeight: "600",
    marginTop: 5,
  },

  btn: {
    width: 234,
    height: '35px',
    fontFamily: "Montserrat",
    fontSize: '1.5em',
    fontWeight: "600",
    margin: "10px",
    [theme.breakpoints.down('sm')]: {
        width: 80,
        fontSize: '1.3em'
    }
  },

  closeIcon: {
    position: 'absolute',
    right: 42,
    paddingTop: 18,
    [theme.breakpoints.down('sm')]: {
        right: 10
    }
  },

  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },
  
  inputStyle1: {
    '& .MuiFormControlLabel-label': {
      color: "#000000",
      fontFamily: "Montserrat",
      fontSize: 16,
      fontWeight: "500"
    }
  },

  labelStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "400",
  },

  cardFooter: {
    width: '100%',
    display: 'flex',
    backgroundColor: "#fff",
    padding: '0px 12px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 10px 0',
      justifyContent: 'space-evenly'
    },
  },

  footerItem: {
    marginBottom: 33,
    marginRight: 20,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0
    },
  },

  boxMargin: {
    marginLeft: 75
  },

  paragraph: {
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "500",
    marginBottom: 12,
    textAlign: 'center'
 },

 proceed: {
  color: "#202020",
  fontFamily: "Montserrat",
  fontSize: 15,
  fontWeight: "700",
  marginBottom: 0,
  marginTop: 22,
  textAlign: 'center',
},

cardContainer: {
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: 15,
},

cardIcon: {
  width: 28,
  height: 22,
  color: "#EDB36A",
  marginTop: 22,
  [theme.breakpoints.down('sm')]: {
    marginBottom: 10,
  },
},

cardContent: {
  display: 'flex'
},

cardTitle: {
  color: "#000000",
  fontFamily: "Montserrat",
  fontSize: "12px",
  fontWeight: "600",
  marginBottom: '20px',
  letterSpacing: '0.15px',
  linHheight: '24px',
},

feeScedule: {
  color: '#007FFF',
  cursor: "pointer"
},

checkboxContainer: {
  marginBottom: "6px",
  [theme.breakpoints.down('xs')]: {
    marginBottom: "6px",
  },
}
}));


export const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "10px"
  },
  head: {
    backgroundColor: "#E7EDF0",
    color: "#3C3D3E",
    fontSize: "15px",
    fontWeight: "bold",
    letterSpacing: "0.36px",
    fontFamily: "Montserrat",
    verticalAlign: "top",
    padding: "10px"
  },
  body: {
    fontSize: "15px",
    fontWeight: "500",
    color: "#1E1F1F",
    fontFamily: "Montserrat",
    backgroundColor: "#E7EDF0"
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);