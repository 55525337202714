import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from "@material-ui/core";
import { useHistory, useLocation } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SecurityIcon from '@material-ui/icons/Security';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import { useStyles } from './styles';
import Cookies from 'js-cookie';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PublishIcon from '@material-ui/icons/Publish';
import { scrollTop, renderDefaultMsg, getAppSource, getUserType } from '../../../../helpers';
import { Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { encrypt, decrypt } from './../../../../helpers/encrypt';
import {getuserLists, getUpdateBorrowerDetails, getResetpassword,
  ResendActivationEmail, getUnlockuser, DownloadUserActivityLog, getActivateUser
} from './../../../../actions';
import { Adminuseractivitylog } from '../Adminuseractivitylog';
import RefreshIcon from '@material-ui/icons/Refresh';


export const Admindetails = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [checked, setChecked] = React.useState(false);
  const [unlock, setunlock] = React.useState(false);
  const [reset, setreset] = React.useState(true);
  const [activate, setactivate] = React.useState(false);
  const [dataError, setDataError] = useState('');
  const [resetPassword, setresetPassword] = useState('');
  const [unlockdata, setunlockdata] = useState('');
  const [activationmail, setactivationmail] = useState('');
  const [activatedata, setactivatedata] = useState('');
  const [dataLoader, setDataLoader] = useState(false);
  const [editemail, seteditemail] = useState(false);
  const [emailerror, setemailerror] = useState(false);
  const [newmail, setnewmail] = useState(false);
  const [isSubmit, setIsSubmit] = useState('');
  const [valuu, setvaluu] = useState('');
  const [values, setValues] = React.useState({
    password: '',
    email: ""
  });
  const [variables, setvariables] = React.useState({
    account: '',
    username: '',
    firstname: '',
    lastname: "",
    zip: "",
    email: "",
    SSN: "",
    status: "",
    statusId: "",
    companyName: ""
  });

  const [passwordLoader, setPasswordLoader] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [activationLinkLoader, setActivationLinkLoader] = useState(false);
  const [activateLoader, setActivateLoader] = useState(false);
  const [unlockLoader, setUnlockLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [emailMessage, setEmailMessage] = useState('');
  const [successApiCall, setSuccessApiCall] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [apiLoaderData, setApiLoaderData] = useState('');

  const editEmail = async () => {
    seteditemail(true)
    setnewmail(false)    
    setValues({...values, ['email']: decrypt(variables.email)})        
  }

  const closeEmaill = async () => {
    seteditemail(false)
    setemailerror(false)
  }

  const handleinput = (name) => event => {
    setValues({
      ...values,
      [name]: event.target.value
    })
  }

  // export to excel need to set later
  const getDownloadUserActivityLog = async (e) => {
    setDataLoader(true);
    var name = encrypt(variables.username);
    if (values.sDate && values.endD) {
      const dataa = {
        "userName": name,
        "pageIndex": 0,
        "pageSize": 0,
        "fromDate": values.sDate,
        "toDate": values.endD,
        "isForAdmin": checked,
        "isDownload": 1,
        "privateLabelUrl": Cookies.get("privateLabelUrl"),
        "ipAddress": Cookies.get('publicIp'),
        "browser": Cookies.get("browser"),
        "appSource": getAppSource(),
        "userType":getUserType(),
      }
      const response = await DownloadUserActivityLog(dataa);
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
        console.log(url)
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', variables.username + '_ActivityLog.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
        setDataLoader(false);
      }
      else {
        setDataLoader(false);
        setDataError(renderDefaultMsg('400'))
      }
    }
  }

  const getResetPasswordData = async (e) => {
    setPasswordLoader(true);
    setactivatedata('');
    setDataError('');
    setactivationmail('');
    setunlockdata('');
    setresetPassword('');
    setDataError('');
    setEmailMessage('');
    const dataa = {
      "userName": encrypt(variables.username),
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
    }
    const response = await getResetpassword(dataa);
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
      setDataError('');
      setPasswordLoader(false);
      if (response && response.newPassword !== null) {
        setresetPassword(response.newPassword)
      }
    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
      setPasswordLoader(false);
      setDataError(response.lstResponseStatus[0].statusDesc);
    } else {
      setPasswordLoader(false);
      setDataError(renderDefaultMsg('400'));
    }
  }

  // unloack api call
  const getUnlockUserData = async (e) => {
    setUnlockLoader(true);
    setactivatedata('');
    setDataError('');
    setactivationmail('');
    setunlockdata('');
    setresetPassword('');
    setDataError('');
    setEmailMessage('');
    var name = encrypt(variables.username);
    const dataa = {
      "userName": name,
      "isLocked": false,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
    }
    const response = await getUnlockuser(dataa);
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
      setDataError('');
      setUnlockLoader(false);
      setunlockdata(response.lstResponseStatus[0].statusDesc);
      getUsersList();
    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
      setUnlockLoader(false);
      setDataError(response.lstResponseStatus[0].statusDesc);
    } else {
      setUnlockLoader(false);
      setDataError(renderDefaultMsg('400'));
    }
  }
  // end api call

  // resend email
  const getResendActivationEmail = async (e) => {
    setActivationLinkLoader(true);
    setactivatedata('');
    setDataError('');
    setactivationmail('');
    setunlockdata('');
    setresetPassword('');
    setDataError('');
    setEmailMessage('');
    var name = encrypt(variables.username);
    const dataa = {
      "userName": name,
      "emailID": variables.email,
      "firstName": variables.firstname,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
    }
    const response = await ResendActivationEmail(dataa);

    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode == '0') {
      setDataError('');
      setActivationLinkLoader(false);
      setactivationmail(response.lstResponseStatus[0].statusDesc)

    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
      setActivationLinkLoader(false);
      setDataError(response.lstResponseStatus[0].statusDesc);
    } else {
      setActivationLinkLoader(false);
      setDataError(renderDefaultMsg('400'));
    }
  }
  // end resend email

  const getUsersList = async () => {
    setSuccessApiCall(false);
    setApiLoader(true);
    setunlock(false);
    setreset(true);
    setactivate(false);
    setApiLoaderData('');
    setactivatedata('');
    setDataError('');
    setactivationmail('');
    setunlockdata('');
    setresetPassword('');
    setDataError('');
    setEmailMessage('');

    const data = {
      "pageIndex": 0,
      "pageSize": 10,
      "PrivateLabelCode": 'All',
      "searchById": '1',
      "searchValue": variables.account,
      "IsIncludeAll": true,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
    }
    const res = await getuserLists(data);
    if (res && res.lstResponseStatus[0].statusCode === '0') {
      setApiLoader(false)
      setvariables({
        ...variables,
        companyName: res.lstUserDetails[0].companyName,
        SSN: decrypt(res.lstUserDetails[0].borrowerSSN),
        status: res.lstUserDetails[0].accountStatus,
        statusId: res.lstUserDetails[0].accountStatusID,
        account: res.lstUserDetails[0].accountNumber,
        username: decrypt(res.lstUserDetails[0].userName),
        firstname: res.lstUserDetails[0].firstName,
        lastname: res.lstUserDetails[0].lastName,
        zip: res.lstUserDetails[0].zipCode,
        email: res.lstUserDetails[0].emailID
      });
      setValues({...values, ['email']: decrypt(res.lstUserDetails[0].emailID)})

      if (res.lstUserDetails[0].accountStatus === 'Locked') {
        setunlock(true)
      }
      if (res.lstUserDetails[0].accountStatus === 'Inactive') {
        setreset(false)
      }
      if (res.lstUserDetails[0].accountStatus === 'Pending' || res.lstUserDetails[0].accountStatus === 'Inactive') {
        setactivate(true)
      }
    } else {
      setApiLoader(false);     
    }
  }

  // activate api call
  const getActivateUserData = async (e) => {
    setActivateLoader(true);
    setactivatedata('');
    setDataError('');
    setactivationmail('');
    setunlockdata('');
    setresetPassword('');
    setDataError('');
    var name = encrypt(variables.username);
    const dataa = {
      "userName": name,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
    }
    const response = await getActivateUser(dataa);
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
      setDataError('');
      setActivateLoader(false);
      setactivatedata(response.lstResponseStatus[0].statusDesc);
      getUsersList();
    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
      setActivateLoader(false);
      setDataError(response.lstResponseStatus[0].statusDesc);
    } else {
      setActivateLoader(false);
      setDataError(renderDefaultMsg('400'));
    }
  }
  // end api call

  //email validation function
  const getvalidateData = async () => {
    if (values.email !== '') {
      let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regEmail.test(values.email)) {
        setemailerror(true)
      }
      else {
        getUpdateBorrowerDetailsdata()
        setemailerror(false)
      }
    }
    else {
      setemailerror(true)
    }
  }

  const getUpdateBorrowerDetailsdata = async (e) => {
    setUpdateLoader(true);
    setDataError('');
    setresetPassword('');
    setEmailMessage('');
    var name = encrypt(variables.username);
    var emaill = encrypt(values.email);
    const dataa = {
      "accountNumber": variables.account,
      "UserName": name,
      "EmailID": emaill,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
    }
    const response = await getUpdateBorrowerDetails(dataa);
    localStorage.setItem('email', values.email)
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode == '0') {
      setEmailMessage(response.lstResponseStatus[0].statusDesc);
      setUpdateLoader(false);
      seteditemail(false)  
      getUsersList()    
    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
      setUpdateLoader(false);
      setDataError(response.lstResponseStatus[0].statusDesc);
      seteditemail(false)
    } else {
      setUpdateLoader(false);
      setDataError(renderDefaultMsg('400'));
      seteditemail(false)
    }
    var ema = localStorage.getItem('email')
    setIsSubmit(ema)
  }
  // end email update

  useEffect(() => {
    const { state = {} } = location;
    if (state.hasOwnProperty('companyName')) {
      setvariables({
        ...variables,
        companyName: state.companyName,
        SSN: decrypt(state.borrowerSSN),
        status: state.accountStatus,
        statusId: state.accountStatusID,
        account: state.accountnumber,
        username: decrypt(state.name),
        firstname: state.firstname,
        lastname: state.lastname,
        zip: state.zipcode,
        email: state.email
      });
      setValues({...values, ['email']: decrypt(state.email)})

      if (state.accountStatus === 'Locked') {
        setunlock(true)
      }
      if (state.accountStatus === 'Inactive') {
        setreset(false)
      }
      if (state.accountStatus === 'Pending' || state.accountStatus === 'Inactive') {
        setactivate(true)
      }      
    } else {
      routePage();
    }
  }, []);

  const routePage = () => {
    history.push('/Admindashboard');
  }

  if (dataLoader) {
    return (
      <Box className={classes.loaderRoot}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box className={classes.bgg}>
      <Box className={classes.fullpart}>
        <Box className={classes.root}>
          <div className={classes.firstarea}>
            <h1 className={classes.title}>Borrower Details</h1>
            <Box className={classes.mainContainer}>
              <Box my={1} mx={2} className={classes.formContainer} style={{ paddingBottom: '8px', position: 'relative' }}>
                <RefreshIcon className={classes.refreshIcon} onClick={getUsersList} />
                {apiLoaderData !== '' && <Box className={classes.apiLoader}><p className={classes.errorMsg}>{apiLoaderData}</p></Box>}
                {apiLoader ? <Box className={classes.apiLoader}>
                  <CircularProgress />
                </Box> :

                  <> <div className={classes.container}>
                    <div className={classes.division_}>
                      <div className={classes.innerDivisionArea}>
                        <div className={classes.innerDivisionArea_1}>
                          <div className={classes.innerwid}>
                            <div className={classes.innermanageArea}>
                              <AccountCircleIcon className={classes.iconss} />
                            </div>
                            <div className={classes.innermanageArea_1}>
                              <Typography className={classes.text_part}>User Name </Typography>
                            </div>
                          </div>
                        </div>
                        <div className={classes.innerDivisionArea_2}>
                          <Typography className={classes.data_part}>{variables.username}</Typography>
                        </div>
                      </div>

                      <div className={classes.innerDivisionArea}>
                        <div className={classes.innerDivisionArea_1}>
                          <div className={classes.innerwid}>
                            <div className={classes.innermanageArea}>
                              <InfoIcon className={classes.iconss} />
                            </div>
                            <div className={classes.innermanageArea_1}>
                              <Typography className={classes.text_part}>Account Status </Typography>
                            </div>
                          </div>

                        </div>
                        <div className={classes.innerDivisionArea_2}>
                          <Typography className={classes.data_part}>
                            {variables.status}

                          </Typography>
                        </div>
                      </div>

                      <div className={classes.innerDivisionArea}>
                        <div className={classes.innerDivisionArea_1}>
                          <div className={classes.innerwid}>
                            <div className={classes.innermanageArea}>
                              <AccountCircleIcon className={classes.iconss} />
                            </div>
                            <div className={classes.innermanageArea_1}>
                              <Typography className={classes.text_part}>First Name </Typography>
                            </div>
                          </div>

                        </div>
                        <div className={classes.innerDivisionArea_2}>
                          <Typography className={classes.data_part}>{variables.firstname}</Typography>
                        </div>
                      </div>

                      <div className={classes.innerDivisionArea}>
                        <div className={classes.innerDivisionArea_1}>
                          <div className={classes.innerwid}>
                            <div className={classes.innermanageArea}>
                              <SecurityIcon className={classes.iconss} />
                            </div>
                            <div className={classes.innermanageArea_1}>
                              <Typography className={classes.text_part}>SSN </Typography>
                            </div>
                          </div>
                        </div>
                        <div className={classes.innerDivisionArea_2}>
                          <Typography className={classes.data_part}>{variables.SSN}</Typography>
                        </div>
                      </div>

                      <div className={classes.innerDivisionArea}>
                        <div className={classes.innerDivisionArea_1}>
                          <div className={classes.innerwid}>
                            <div className={classes.innermanageArea}>
                              <AccountBalanceWalletIcon className={classes.iconss} />
                            </div>
                            <div className={classes.innermanageArea_1}>
                              <Typography className={classes.text_part}>Property Zip  </Typography>
                            </div>
                          </div>

                        </div>
                        <div className={classes.innerDivisionArea_2}>
                          <Typography className={classes.data_part}>
                            {decrypt(variables.zip)}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div className={classes.division}>
                      <div className={classes.innerDivisionArea}>
                        <div className={classes.innerDivisionArea_1_2}>
                          <div className={classes.innerwid}>
                            <div className={classes.innermanageArea}>
                              <FormatListNumberedIcon className={classes.iconss} />
                            </div>
                            <div className={classes.innermanageArea_1}>
                              <Typography className={classes.text_part}>Loan Number  </Typography>
                            </div>
                          </div>

                        </div>
                        <div className={classes.innerDivisionArea_2_2}>
                          <Typography className={classes.data_part}>{decrypt(variables.account)}</Typography>
                        </div>
                      </div>

                      <div className={classes.innerDivisionArea}>
                        <div className={classes.innerDivisionArea_1_2}>
                          <div className={classes.innerwid}>
                            <div className={classes.innermanageArea}>
                              <EmailIcon className={classes.iconss} />
                            </div>
                            <div className={classes.innermanageArea_1}>
                              <Typography className={classes.text_part}>Email Address </Typography>
                            </div>
                          </div>

                        </div>
                        <div className={classes.innerDivisionArea_2_2} style={{ padding: editemail ? '0px' : null }}>
                          <div className={classes.mid_area}>
                            <div className={classes.midd_1}>

                              {!editemail && <Typography className={classes.data_part}>{valuu === "" ? decrypt(variables.email) : valuu}</Typography>}
                              {editemail && <TextField
                                color="primary"
                                autoComplete="off"
                                id="outlined-loanNumber"
                                fullWidth
                                variant="outlined"
                                type="text"
                                // value={values.email == "" ? decrypt(variables.email) : values.email}
                                value={values.email}
                                onChange={handleinput('email')}

                                inputProps={{
                                  className: classes.inputStyle_1,
                                  maxLength: 30,
                                  form: {
                                    autocomplete: 'off',
                                  },
                                }}
                                InputLabelProps={{
                                  className: classes.inputStyle_2,
                                  classes: { root: classes.textField }
                                }}

                              />}
                              {emailerror && <Typography color="error" style={{ fontSize: '11px' }}>Please enter valid email address</Typography>}
                            </div>
                            <div className={classes.midd_2}>
                              {!editemail && <EditIcon style={{ color: 'rgb(172, 123, 64)', cursor: 'pointer' }} onClick={editEmail} />}
                              {editemail && (updateLoader ? <CircularProgress className={classes.loadingProgress} style={{ marginTTop: '10px', }} />
                                : <PublishIcon className={classes.editEmailController} onClick={getvalidateData} />)}

                            </div>
                            <div className={classes.midd_2}>
                              {editemail && <CloseIcon className={classes.editEmailController} style={{ color: 'red' }} onClick={closeEmaill} />}
                            </div>
                          </div>

                        </div>
                      </div>

                      <div className={classes.innerDivisionArea}>
                        <div className={classes.innerDivisionArea_1_2}>
                          <div className={classes.innerwid}>
                            <div className={classes.innermanageArea}>
                              <AccountCircleIcon className={classes.iconss} />
                            </div>
                            <div className={classes.innermanageArea_1}>
                              <Typography className={classes.text_part}>Last Name </Typography>
                            </div>
                          </div>

                        </div>
                        <div className={classes.innerDivisionArea_2_2}>
                          <Typography className={classes.data_part}>{variables.lastname}</Typography>
                        </div>
                      </div>

                      {resetPassword && <div className={classes.innerDivisionArea}>
                        <div className={classes.innerDivisionArea_1_2}>
                          <div className={classes.innerwid}>
                            <div className={classes.innermanageArea}>
                              <LockOpenIcon className={classes.iconss} />
                            </div>
                            <div className={classes.innermanageArea_1}>
                              <Typography className={classes.text_part}>Temporary Password </Typography>
                            </div>
                          </div>

                        </div>
                        <div className={classes.innerDivisionArea_2}>
                          <Typography className={classes.data_part}>{decrypt(resetPassword)}</Typography>
                        </div>
                      </div>}

                    </div>
                  </div>
                    <div className={classes.firstarea} style={{ textAlign: 'center' }}>
                      {resetPassword && <Typography className={classes.success}>The temporary password has been sent to the user’s registered email address.</Typography>}
                      {unlockdata && <Typography className={classes.success}>{unlockdata}</Typography>}
                      {activatedata && <Typography className={classes.success}>{activatedata}</Typography>}
                      {activationmail && <Typography className={classes.success}>{activationmail}</Typography>}
                      {dataError !== '' ? <Typography className={classes.errorMessage}>{dataError}</Typography> : null}
                      {emailMessage !== '' ? <Typography className={classes.success}>{emailMessage}</Typography> : null}
                    </div>
                    <div className={classes.container}>
                      <div className={classes.innercontainer}>
                        <div className={`${classes.button_det_1} ${classes.leftmargin}`} >
                          <Button
                            onClick={getUnlockUserData}
                            disabled={!unlock} type='submit'
                            className={`${classes.searchbtn} ${classes.unloackBtn}`}
                            size="large" variant="contained" color="primary">
                            {unlockLoader ? <CircularProgress className={classes.loadingProgress} /> : 'Unlock'}
                          </Button>
                        </div>
                        <div className={classes.button_det_1}>
                          <Button
                            onClick={getActivateUserData}
                            disabled={!activate || activateLoader}
                            type='submit'
                            className={`${classes.searchbtn} ${classes.activateBtn}`}
                            size="large" variant="contained" color="primary">
                            {activateLoader ? <CircularProgress className={classes.loadingProgress} /> : 'Activate'}
                          </Button>
                        </div>
                        <div className={classes.button_det}>
                          <Button
                            onClick={getResendActivationEmail}
                            disabled={!activate || activationLinkLoader}
                            type='submit'
                            className={`${classes.searchbtn} ${classes.resendBtn}`}
                            size="large" variant="contained" color="primary">
                            {activationLinkLoader ? <CircularProgress className={classes.loadingProgress} /> : 'Send Reactivation Link'}
                          </Button>
                        </div>
                        <div className={classes.button_det_4}>
                          <Button onClick={getResetPasswordData}
                            disabled={!reset || passwordLoader}
                            type='submit'
                            className={`${classes.searchbtn} ${classes.resetBtn}`}
                            size="large" variant="contained" color="primary">
                            {passwordLoader ? <CircularProgress className={classes.loadingProgress} /> : 'Reset Password'}
                          </Button>
                        </div>
                        <div className={classes.button_det_2}>
                          <Button type='submit'
                            className={classes.searchbtn}
                            size="large" variant="contained"
                            color="primary" style={{ width: '155px' }}
                            onClick={() => routePage('Admin')}>
                            Back to Admin
                      </Button>
                        </div>
                      </div>
                    </div>
                  </>}

              </Box>
            </Box>
          </div>

          <div className={classes.firstarea}>
            <Box className={classes.mainContainer}>
              <Adminuseractivitylog loanNumber={decrypt(variables.account)} />
            </Box>
          </div>

        </Box>

      </Box>

    </Box>
  )
}
