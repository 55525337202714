import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Grid, Typography, Button, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { BoxWithShadow } from "../BoxWithShadow";
import DetailedAccordion from './Accordion';
import SecAccordion from './Accordion/secBorower';
import { UserLoanInfo } from '../UserLoanInfo';
import { custLoanInfo, getStateList, setEditProfileComponent, getClientName } from '../../actions';
import home from '../../assets/LoanDetails/home.svg';
import mail from '../../assets/LoanDetails/mail.svg';
import person from '../../assets/LoanDetails/person.svg';
import phone from '../../assets/LoanDetails/phone.svg';
import { EditSecondaryBo } from './EditDetails/index';
import { EditCOBorower } from './EditCoDetails';
import InfoIcon from '@material-ui/icons/Info';
import { useStyles } from './styles';
import Cookies from 'js-cookie';
import { decrypt } from '../../helpers/encrypt';
import { renderDefaultMsg, getUserType, getAppSource, scrollTop, getPublicIp, getBorrowerLabel, getLoanLabel, getPointPrivateLabel } from '../../helpers';
import CustomBreadcrum from '../Breadcrum';

export const LoanInfo = React.memo((props) => {
  const mytheme = useSelector(state => state.theme);
  const userLoanInfor = useSelector(state => state.userLoanInfor);
  const { primaryColorCode } = mytheme;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const [isCOOpen, setIsCOOpen] = useState(false);
  const [isSEOpen, setIsSEOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [stateErrorMsg, setStateErrorMsg] = useState('');
  const [stateData, setStateData] = useState('');
  const [cesaeDesistFlag, setCeaseDesistFlag] = useState(false);

  useEffect(() => {
    getData();
    getState()
    getClientShortName(Cookies.get('selectedAccount'))
  }, []);

  useEffect(() => {
    scrollTop()
    if (props.accountNumber) {
      getData(props.accountNumber);
      getClientShortName(props.accountNumber);
    }
  }, [props.accountNumber]);

  const getClientShortName = async (acccountNumber) => {

    const data = {
      "accountNumber" : acccountNumber,
      "userName": Cookies.get('userName'),
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType()
    }

    const result = await dispatch(getClientName(data));
    if (result) {
      const { lstResponseStatus, bankDetail } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        setCeaseDesistFlag(bankDetail?.ceaseDesistFlag);
      }
    }
  }

  const getData = async (accNo) => {
    setLoading(true);
    const selectedAccount = await Cookies.get('selectedAccount');
    const data = {
      "accountNumber": accNo || selectedAccount,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };
    const response = await dispatch(custLoanInfo(data));
    if (response) {
      setLoading(false)
    }
    scrollTop();
  }

  const getState = async (e) => {
    const response = await getStateList();
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
      setStateErrorMsg('');
      setStateData(response.stateList);
    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
      setStateErrorMsg(response.lstResponseStatus[0].statusDesc);
    } else {
      setStateErrorMsg(renderDefaultMsg('400'));
    }
  }

  const routePage = async (link) => {
    await dispatch(setEditProfileComponent(true));
    history.push(link);
  }

  if (loading) {
    return (
      <Box style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100vh'
      }} >
        <CircularProgress />
      </Box>
    )
  }

  const showPopup = (item, borower) => {
    setEditData(item)

    if (borower == 'secondaryBorower') {
      setIsSEOpen(true)
    } else if (borower == 'coBorower') {
      setIsCOOpen(true);
    }
  }

  let accordionData = '';
  if (!loading && userLoanInfor.length !== 0) {
    accordionData = userLoanInfor.lstCoborrowerDetails.map((item) => {
      return (
        <DetailedAccordion key={item.coBorrowerName} Click={() => showPopup(item, 'coBorower')}  {...item} />
      )
    })
  }

  let secBorowerData = '';
  if (!loading && userLoanInfor.length !== 0 && userLoanInfor.lstSecondaryborrowerDetails.length) {
    secBorowerData = <SecAccordion {...userLoanInfor.lstSecondaryborrowerDetails[0]}
      Click={() => showPopup(userLoanInfor.lstSecondaryborrowerDetails[0], 'secondaryBorower')} />
  }

  if (userLoanInfor && userLoanInfor.borrowerDetails && userLoanInfor.length !== 0) {
    return (
      <Box className={classes.pageBackground}>
        <Box className={classes.root} >
          <CustomBreadcrum backPage={getPointPrivateLabel() ? "Contract Details" : "Loan Details"} backPageLink="/LoanInfo" activePage={getPointPrivateLabel() ? "Contract Information" : "Loan Information" } />
          <h1 className={classes.pageHeading} color="textPrimary" component='h4'>{getLoanLabel()} Information</h1>

          <BoxWithShadow bgColor={primaryColorCode} boxShadow='0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)'>
            <UserLoanInfo {...userLoanInfor.propertyAddress} />
          </BoxWithShadow>

          <Box className={classes.infoStrip}>
            <Box display='flex' alignItems="center">
              <InfoIcon className={classes.infoIcon} />
              <Typography className={classes.infoText}>It can take up to 24 hours for your request status to be updated.</Typography>
            </Box>
          </Box>

          <BoxWithShadow bgColor='#fff' boxShadow='0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)'>
            <Box my={1} mx={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Grid className={classes.loanOwnerInfo} container direction="row" justify="flex-start" alignItems='center'>
                    <img src={person} alt="person icon" className={classes.loanInfoTitleIcon} />
                    <Typography variant='h5' className={classes.loanOwner}>
                      {getBorrowerLabel()}: {userLoanInfor.borrowerDetails.primaryName}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" className={classes.custAddress}>
                    <img src={home} alt="home icon" className={classes.loanInfoIcon} />
                    <Box>
                      <p className={`${classes.bodyFont} ${classes.wordBreak}`}>
                        {`${userLoanInfor.borrowerDetails.primaryAddress1 ?
                          userLoanInfor.borrowerDetails.primaryAddress1 : ''}`}
                      </p>
                      <p className={`${classes.bodyFont} ${classes.wordBreak}`}>
                        {`${userLoanInfor.borrowerDetails.primaryAddress2 ?
                          userLoanInfor.borrowerDetails.primaryAddress2 : ''}`}
                      </p>
                      <p className={`${classes.bodyFont} ${classes.wordBreak}`}>
                        {userLoanInfor.borrowerDetails.primaryCity ? userLoanInfor.borrowerDetails.primaryCity + ', ' : ''}
                        {userLoanInfor.borrowerDetails.primaryState ? userLoanInfor.borrowerDetails.primaryState + ', ' : ''}
                        {decrypt(userLoanInfor.borrowerDetails.primaryZip)}
                      </p>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingRight: 0 }}>
                  <Grid className={classes.phoneContainer} container spacing={0} alignItems="flex-end" >
                    <Box className={classes.mailWidth}>
                      <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.loanCareNo}>
                        {userLoanInfor.borrowerDetails.mobileNumber ?
                          <>
                            <img src={phone} alt="phone icon" className={classes.loanInfoIcon} />
                            <Typography variant='body1' className={`${classes.bodyFont} ${classes.careNo}`}>
                              {decrypt(userLoanInfor.borrowerDetails.mobileNumber)}
                            </Typography></> : null}
                      </Grid>
                      <Grid container direction="row" justify="flex-start" alignItems="center">
                        {userLoanInfor.borrowerDetails.emailID ?
                          <>
                            <img src={mail} alt="mail icon" className={classes.loanInfoIcon} />
                            <Typography variant='body1' className={`${classes.bodyFont} ${classes.wordBreak}`}>
                              {decrypt(userLoanInfor.borrowerDetails.emailID)}
                            </Typography></> : null}
                      </Grid>
                    </Box>
                    <Button onClick={() => routePage('/UserProfile/Profile')} className={classes.btn}
                      type='submit' size="large" variant="contained" color="primary">
                      Edit
                  </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </BoxWithShadow>
          {(accordionData != '' || secBorowerData != '') &&
            <BoxWithShadow bgColor='#fff' boxShadow='0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)'>
              <Box my={1} mx={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Grid className={classes.loanOwnerInfo} container direction="row" justify="flex-start" alignItems='center'>
                      <img src={person} alt="home icon" className={classes.loanInfoTitleIcon} />
                      <Typography variant='h5' className={classes.loanOwner}>
                        Co {getBorrowerLabel()} Information</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Box className={classes.accordionData}>
                  {secBorowerData}
                  {accordionData}

                  <EditSecondaryBo editData={editData} open={isSEOpen}
                    handleClose={() => setIsSEOpen(false)} stateData={stateData} stateErrorMsg={stateErrorMsg} cesaeDesistFlag={cesaeDesistFlag}></EditSecondaryBo>

                  <EditCOBorower editData={editData} open={isCOOpen}
                    handleClose={() => setIsCOOpen(false)} stateData={stateData} stateErrorMsg={stateErrorMsg} cesaeDesistFlag={cesaeDesistFlag}></EditCOBorower>
                </Box>
              </Box>
            </BoxWithShadow>
          }
        </Box>
      </Box>
    )
  }
})