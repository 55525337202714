import React, { useState } from 'react';
import { useStyles } from './styles';
import { Box, Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import { decrypt } from '../../../helpers/encrypt';

export const AccessDenied = (props) => {
  
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={props.handleAccessDeniedSubmit} open={props.open}>
     
      <Box className={classes.root}>
  
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>Access Denied</Typography>
        </Box>

        <Box className={classes.cardBody2}>
          <Box className={classes.statusContainer}>
           <Box className={classes.cardContainer}>
              <Box className={classes.card1} >
                  <Box className={classes.cardContent}>
                     <InfoIcon className={classes.cardIcon} /> 
                     <p className={classes.paragraph}> You are unable to request any documents due to the status of your “<b>Loan {decrypt(props.selectedLoan.accountNumber)}</b>”. To request the required document, please get in touch with us directly.</p>
                  </Box>
                </Box>
            </Box> 
          </Box>
        </Box>

        <Box className={classes.singleLoanFooter}>
            <Button
              variant='contained'
              color='primary'
              className={classes.btn}
              onClick={props.handleAccessDeniedSubmit}
            > OK</Button>
        </Box>
      </Box> 
    </Dialog>
  )
}
