import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 550,
    borderRadius: 12,
    backgroundColor: 'white',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)"
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxHeight: "100%",
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '20px',
        margin: '50px 15px'
      },
  },

  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "400",
    textAlign: 'center',
    margin: 14,
  },

  cardBody: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '12px 2em',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  cardBody2: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '8px 2em',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  statusTitle: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '18px',
    fontWeight: "700",
    padding: '3px 2px 2px 3px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px'
    }
  },

  statusBody: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "bold",
  },

  linkStyle: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    fontWeight: "600",
    marginTop: 5,
  },

  btn: {
    width: 188,
    height: '32px',
    fontFamily: "Montserrat",
    fontSize: '1.5em',
    fontWeight: "600",
    margin: "10px",
    [theme.breakpoints.down('sm')]: {
        width: 80
    }
  },

  closeIcon: {
    position: 'absolute',
    right: -6,
    paddingTop: 4,
    [theme.breakpoints.down('sm')]: {
        right: 10
    }
  },

  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },
  
  inputStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600"
  },

  labelStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "400",
  },

  cardFooter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: "#fff",
    padding: '8px 76px 0',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      padding: '8px 64px 0'
    },
  },

  footerItem: {
    marginBottom: 33,
    maxWidth: 175,
    marginRight: 25
  },

  boxMargin: {
    marginLeft: 75
  },

  paragraph: {
    color: "#E02020",
    fontFamily: "Montserrat",
    fontSize: 15,
    fontWeight: "500",
    marginBottom: 8,
    textAlign: 'center'
 },

 proceed: {
  color: "#202020",
  fontFamily: "Montserrat",
  fontSize: 15,
  fontWeight: "700",
  marginBottom: 0,
  marginTop: 22,
  textAlign: 'center',
},


cardContainer: {
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',
  marginTop: 15,
},

card1: {
  maxWidth: '100%',
  padding: '10px',
  borderRadius: 12,
  border: '1px solid #F85757',
  backgroundColor: "#FFF0F0",
  boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
  marginBottom: 20,
  // display: 'flex',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    width: '100%',
    padding: '15px',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%'
  }
},

cardIcon: {
  width: 28,
  height: 22,
  color: "#E02020",
  marginTop: 15,
  [theme.breakpoints.down('sm')]: {
    marginBottom: 10,
  },
},

cardContent: {
  display: 'flex'
},

cardTitle: {
  color: "#000000",
  fontFamily: "Montserrat",
  fontSize: "12px",
  fontWeight: "600",
  marginBottom: '20px',
  letterSpacing: '0.15px',
  linHheight: '24px',
},
}));

