import axios from 'axios';
import Cookies from 'js-cookie';
import { axiosProtected, baseUrl } from '../config';
import { getAppSource, getUserType } from '../helpers'

export const getTodolist = async (accountNumber) => {
  const data = {
    "accountNumber": accountNumber,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }

  try {
    const response = await axiosProtected.post('/FinancialHardship/GetToDoList', data);
    return response.data;
  } catch (e) {
    return null
  }
}
export const getPdf = async (obj) => {
  const token = await Cookies.get('privateToken')
  const data = {
    "accountNumber": obj.selectedAccount,
    "isSubmit": obj.isSubmit,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }
  try {
    const res = await axios({
      url: `${baseUrl}/FinancialHardship/DownloadForm`,
      method: 'POST',
      responseType: 'blob', // important
      data: data,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return res
  } catch (e) {
    return null
  }
}
