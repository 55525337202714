import { makeStyles } from '@material-ui/core/styles';
import signinIcon from '../../assets/SignIn/SignIn.svg';

export const useStyles = makeStyles((theme) => ({
  pageBg: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#ccc',
    display: 'flex',
    justifyContent: 'center',
    backgroundImage: `url(${signinIcon})`,
    backgroundPosition: "bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'contain',
  },

  root: {
    width: "100%",
    maxWidth: "1230px",
    padding: "2.3em 1.5em 9em",
    margin: '0 auto',
    textAlign: 'initial',
    [theme.breakpoints.down('sm')]: {
      width: '2.3em 1.5em 5em',
    },
  },

  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },

  justifyCenter: {
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  flexWrap: {
    flexWrap: 'wrap'
  },

  smallWidth: {
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& .MuiFormControlLabel-root': {
      width: '46%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: '10px'
      },
    },
  },

  title: {
    color: "#1A4664",
    fontFamily: "Montserrat",
    fontSize: "22px",
    letterSpacing: '0.18px',
    lineHeight: '24px',
    fontWeight: "700",
    marginTop: 24,
    marginBottom: 25,
  },

  subTitle: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: "22px",
    fontWeight: "500",
    marginBottom: 30,
  },

  innerText: {
    marginTop: '10px',
    marginBottom: '10px',
    fontWeight: "400 !important",
  },

  container: {
    padding: '46px 0',
    marginBottom: 40,
    borderRadius: 12,
    backgroundColor: "#FFFFFF",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('md')]: {
      padding: '46px 15px',
    },
  },

  covidInfoContainer: {
    padding: '0 100px',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },

  headingContainer: {
    padding: '35px 60px',
    [theme.breakpoints.down('sm')]: {
      padding: '35px 0',
    },
  },

  formContainer: {
    padding: '0 37px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },

  covidList: {
    marginTop: 0,
  },

  listHeading: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: "500",
    marginBottom: 20,
  },

  covidOptionsList: {
    listStyleType: 'disc',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '20px',
    },
    '& li': {
      color: "#585757",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "500",
      padding: '10px 0 10px 35px',
      position: 'relative',
      listStyleType: 'disc',
      [theme.breakpoints.down('sm')]: {
        padding: '0',
      },
    }
  },

  covidDeferList: {
    listStyleType: 'disc',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '20px',
    },
    '& li': {
      color: "#585757",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "500",
      padding: '7px 0 0 0',
      position: 'relative',
      listStyleType: 'disc',
      [theme.breakpoints.down('sm')]: {
        padding: '0',
      },
    }
  },

  innerTextPadding: {
    paddingLeft: '40px',
    marginTop: '25px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '20px',
    },
  },

  assistStepsList: {
    marginTop: 20,
    '& ol li': {
      color: "#585757",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "500",
      padding: '7px 0 7px 35px',
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        padding: '0',
      },
    },
    '& ol': {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '20px',
      },
    }
  },

  orderedList: {
    marginBottom: '25px'
  },

  dot: {
    display: 'inline-block',
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: '#2d8fc1',
    position: 'absolute',
    left: 0,
    top: 15,

  },

  covidImg: {
    marginRight: 20,
    maxWidth: 60,
  },

  marTopBottom20: {
    marginTop: 20,
    marginBottom: 20,
  },

  forbearanceAppForm: {
    marginTop: 20,
    marginBottom: 20,
    '& .subTitle': {
      marginBottom: 0
    }
  },

  forbearanceAppFormTitle: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: "22px",
    fontWeight: "500",
    letterSpacing: '0.15px',
    lineHeight: '24px',
  },

  formTitle: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: '25px',
    letterSpacing: '0.15px',
  },

  formBg: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 6,
    background: '#FAFAFA'
  },

  form: {
    padding: '30px 22px'
  },

  textField: {
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: '600'
  },

  textField250: {
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: '600',
    height: '117px',
    display: 'block',
  },

  formText: {
    color: '#535D6D',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: '600',
    letterSpacing: '0.4px',
    lineHeight: '22px'
  },

  propertyRadio: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    '& .MuiFormControlLabel-root': {
      width: '33%',
      marginRight: 0,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginTop: '10px',
        marginLeft: 0
      },
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#0F355C',
      '&+.MuiTypography-root': {
        color: '#0F355C',
        fontWeight: 'bold',
      }
    }
  },

  xsMb10: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: '10px !important',
    },
  },

  radioStyle: {
    padding: '0 5px',
    color: "#7B8390",
    '&.Mui-checked': {
      color: "#202020 !important",
    }
  },

  activeRadioLabel: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "bold",
  },

  radioLabel: {
    color: "rgba(0,0,0,0.74)",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: "500",
  },

  marginFixed: {
    marginTop: 42,
  },

  impectedCovid: {
    marginTop: '50px',
    padding: '0 37px',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },

  formSection: {
    marginTop: '50px',
    padding: '0 37px',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },

  subHeading: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: '500',
    letterSpacing: '0.55px',
    lineHeight: '25px',
    marginBottom: '20px'
  },
  textArea: {
    boxSizing: 'border-box',
    height: '177px !important',
    width: '100%',
    border: '1px solid #8eaed6',
    borderRadius: '9px',
    padding: '30px 20px'
  },

  covidImpectRadio: {
    marginTop: '28px',
    '& .MuiFormControlLabel-root': {
      width: '47%',
      margin: '10px 0',
      [theme.breakpoints.down('xs')]: {
        margin: 0,
        marginTop: '12px'
      },
    },
  },

  subText: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    letterSpacing: '0.36px',
    lineHeight: '19px',
  },

  termAndCondition: {
    width: '100%',
    backgroundColor: '#fff',
    padding: '36px',
    marginTop: 12,
    border: '1px solid #979797',
    borderRadius: '9px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },

  aggTitle: {
    color: '#0F355C',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.53px',
    lineHeight: '25px',
    margin: '0 0 32px 0'
  },

  termText: {
    height: '442px',
    overflowY: 'auto',
  },

  text: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: '400',
    paddingRight: '30px',
  },

  helpText: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "500",
    marginTop: '25px'
  },

  radioBtnMargin: {
    marginTop: '30px'
  },

  btn: {
    width: 230,
    height: 47,
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: '600',
    margin: '0 24px',
    [theme.breakpoints.down('xs')]: {
      margin: '0 3px',
    },
  },

  resetButton: {
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    boxShadow: "0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)",
  },

  mt20: {
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },

  editBtn: {
    color: '#535D6D',
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: '0.36px',
    lineHeight: '19px',
    textAlign: 'right',
    textDecoration: 'underline',
    cursor: 'pointer',
    backgroundColor: 'inherit',
    border: 'none'
  },

  loaderContainer: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  ErrorMsg: {
    color: 'red',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.36px',
    lineHeight: '19px',
    marginBottom: '10px',
    marginTop: '10px',
    textAlign: 'center',
    width: '80%',
    margin: '15px auto 0 auto'
  },

  isQAgreeError: {
    color: 'red',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.36px',
    lineHeight: '19px',
    marginBottom: '10px',
    marginTop: '10px',
    margin: '0'
  },

  mt10: {
    marginTop: '13px'
  },

  printBtn: {
    position: 'absolute',
    left: 0,
    bottom: '35px',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      position: 'initial',
      padding: 0,
      marginTop: '20px',
    },
  },

  printBtnIcon: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "600",
    cursor: 'pointer',
    color: '#535D6D',
    textDecoration: 'underline'
  },

  covidBtnContainer: {
    position: 'relative',
    margin: '0',
  },

  agreeContainer: {
    marginTop: '20px',
    marginLeft: 0,
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#0F355C',
    }
  },

  required: {
    color: '#ec3131',
    fontWeight: '600',
    marginLeft: '2px'
  },

  validationErr: {
    fontSize: '12px',
    fontWeight: '500',
    left: 0,
    fontFamily: 'Montserrat',
    color: "red",
    margin: '3px 0'
  },

  inputStyle: {
    color: "#535D6D",
    fontWeight: "500",
  },

  subTextMargin: {
    margin: '3px 0 !important',
    paddingRight: '20px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0',
    },
  },

  dataLoadError: {
    fontSize: '18px',
    fontWeight: '600',
    fontFamily: 'Montserrat',
    color: "red",
  },

  linkStyle: {
    display: 'inline-block', color: "#535D6D", cursor: 'pointer', marginLeft: '5px'
  },

  covidLinkStyle: {
    display: 'inline-block', color: "#535D6D", cursor: 'pointer', textDecoration: 'underline', fontWeight: '600'
  },

  printIconStyle: {
    fill: "#535D6D", fontSize: 25, marginLeft: "5px"
  },


}));