import React from 'react';
import { useStyles } from './styles';
import { Box, Button, Grid } from '@material-ui/core';
import { maskMobile, numberWithCommas, isAdmin, changesReflectStatus, isHEI } from '../../helpers';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { setPaymentOption } from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';

export const UpcomingPayment = (props) => {
  const mytheme = useSelector(state => state.theme);
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'));
  const loanInfoData = useSelector(state => state?.loanInfoReducer);
  const selectedAccountData = useSelector(state => state.selectedAccountData);

  const { totalAmountDue, paymentDueDate,
    lastPaymentDate, accountNumber, isPaymentAllowed,
    isAlreadyScheduleACHPayment, bankDetails,
    isDueDateApproaching, daylinquestdays, isDelinquentDays,
    piTotalMonthlyPayment, monthlyAmountDue,
    paidInFullFlag,
    serviceReleasedFlag,
    newServicerName,
    setUpAutoPay,
    buydown
  } = props;

  const makePayment = (isAlreadyScheduleACHPayment) => {
    // if (!isAlreadyScheduleACHPayment && (setUpAutoPay?.length > 0)) {
    //   dispatch(setPaymentOption('MANAGEAUTOPAY'));
    // } else {
    //   dispatch(setPaymentOption('ONETIMEPAYMENT'));
    // }
    if (isAlreadyScheduleACHPayment) {
      dispatch(setPaymentOption('ONETIMEPAYMENT'));
    }
    history.push('/PaymentOption')
  }

  const handleAutoPayNavigation = () => {
    dispatch(setPaymentOption('MANAGEAUTOPAY'));
  }

  const handlePaidInFull = () => {
    if (paidInFullFlag) {
      return (
        <></>
        // <Box className={classes.paddingFix}>
        //   <InfoIcon style={{ fill: '#B00020' }} />
        //   <p className={classes.alertTitle}>This account is paid in full.</p>
        // </Box>
      )
    }
    else if (serviceReleasedFlag) {
      return ( <></>
        // <Box className={classes.paddingFix}>
        //   <InfoIcon style={{ fill: '#B00020' }} />
        //   <p className={classes.alertTitle}>Transferred to New Servicer <b>{newServicerName}</b></p>
        // </Box>
      )
    } else if (isPaymentAllowed && !isDueDateApproaching && isDelinquentDays) {
      return (
        <Box className={`${classes.flexBox} ${classes.currentLoanBox} `}>
          <InfoIcon style={{ fill: '#C3791A' }} />
          <p className={classes.currentLoanMsg}>Please make a one-time payment to bring your loan current.</p>
        </Box>
      )
    } else {
      return null
    }
  }

  const getNycRuleStatus = () => {
    if ((selectedAccountData?.propertyState.toUpperCase() == "NY") && loanInfoData.nycRules?.is6year && loanInfoData.nycRules?.isNYCRules) {
      return true;
    } else if ((selectedAccountData?.propertyState.toUpperCase() !== "NY") && loanInfoData.nycRules?.is6year && loanInfoData.nycRules?.isNYCRules) {
      return true;
    } else if ((selectedAccountData?.propertyState.toUpperCase() == "NY") && loanInfoData.nycRules?.is6year) {
      return true;
    } else {
      return false;
    }
  }
  
  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <h1 className={classes.title}>Payment Summary</h1>
        {isPaymentAllowed ? !isDueDateApproaching ? isDelinquentDays &&
          <Button className={`${classes.alertBtn} ${classes.alertBtnDelequent}`}>{daylinquestdays} Days Past Due</Button> :
          <Button className={`${classes.alertBtn} ${classes.alertBtnCurrent}`}>Due Date Approaching</Button>
          : <Button className={`${classes.alertBtn} ${classes.noPointer}`}>{daylinquestdays} Days Past Due</Button>}
      </Box>

      {handlePaidInFull()}

      {!isPaymentAllowed &&
        <Box className={classes.paddingFix}>
          <InfoIcon style={{ fill: '#B00020' }} />
          <p className={classes.alertTitle}>Due to the status of your loan payment details are disabled, please contact us directly to discuss your payment options and the amount you owe.</p>
        </Box>
      }
      {isPaymentAllowed && <Box className={classes.cardBody}>
        <Box className={classes.statusContainer}>
          <p className={classes.statusTitle}>Total Amount Due </p>
          <h3 className={classes.statusBody}>${numberWithCommas(parseFloat(totalAmountDue).toFixed(2))}</h3>
        </Box>
        <Box className={classes.statusContainer}>
          <p className={classes.statusTitle}>Monthly Amount Due </p>
          <h3 className={classes.statusBody}>${(Number(buydown) > 0) ? numberWithCommas(parseFloat(monthlyAmountDue - buydown).toFixed(2)) : numberWithCommas(parseFloat(monthlyAmountDue).toFixed(2)) }</h3>
          {(Number(buydown) > 0) && <p className={classes.duydownText}>Total ${numberWithCommas(parseFloat(monthlyAmountDue).toFixed(2))} - Buydown ${numberWithCommas(parseFloat(buydown).toFixed(2))}</p>}
        </Box>
        <Box className={classes.statusContainer}>
          <p className={classes.statusTitle}>Payment Due Date</p>
          <h3 className={classes.statusBody}>{moment(paymentDueDate).format("ll")}</h3>
        </Box>

      </Box>}

      <Box className={classes.cardFooter}>
        {isPaymentAllowed ? !isAlreadyScheduleACHPayment ?
          (setUpAutoPay?.length > 0) && <Box className={classes.footerItem}>
            <p className={classes.footerText}>Never Miss A Payment or Pay Late Fees Again</p>
            <Link
              onClick={() => handleAutoPayNavigation()}
              className={classes.linkStyle} to='/PaymentOption'>Sign Up For Auto Payment</Link>
          </Box> :
          <Box className={classes.footerItem}>
            <Box className={classes.flexBoxAuto}>
              <CheckCircleIcon style={{ color: '#535D6D', marginRight: 5, marginBottom: 12 }} />
              <p className={classes.footerText}> Autopay:<b className={classes.marginFix}> Enrolled</b></p>
            </Box>
            <p className={`${classes.footerText} ${classes.bankDetails}`}>{bankDetails}</p>
          </Box>
          :
          <Box className={classes.footerItem}>
            <p className={classes.financialText}>Click here to know about</p>
            <Link to='/FinancialHardship' className={classes.financialLink}>Financial Hardship</Link>
          </Box>
        }
        {isPaymentAllowed && <Box className={classes.statusContainer}>
          <p className={classes.paymentText}>Last Payment</p>
          <h3 className={classes.footerText}>{moment(lastPaymentDate).isValid() ? moment(lastPaymentDate).format("ll") : "N/A"}</h3>
        </Box>}
           
        {(mediaQuery && !changesReflectStatus(loanInfoData?.purgeLoanInfo) && getNycRuleStatus() && !isHEI()) && 
        <Box className={classes.nycContainer}><p className={classes.nycRules}>Your debt may be time-barred.</p>
        <p className={classes.nycRules}><Link to='/NYCrules' className={classes.financialLink}>Click here</Link> for more details.</p></Box>
          }

        <Box className={classes.footerItem}>
          <Button
            onClick={() => makePayment(isAlreadyScheduleACHPayment)}
            variant='contained'
            color='primary'
            disabled={isAdmin() || !isPaymentAllowed}
            className={classes.btn}
          >Make a payment</Button>
        </Box>

        { !mediaQuery &&
        <Grid container>
          <Grid item xs={10} sm={10} md={8}>
            </Grid>
            <Grid item xs={10} sm={10} md={4}>
        {(!changesReflectStatus(loanInfoData?.purgeLoanInfo) && getNycRuleStatus() && !isHEI()) && 
        <Box className={classes.nycContainer}><p className={classes.nycRules}>Your debt may be time-barred.</p>
        <p className={classes.nycRules}><Link to='/NYCrules' className={classes.financialLink}>Click here</Link> for more details.</p></Box>
          }
          </Grid>
        </Grid>
        }

      </Box>
    </Box>
  )
}
