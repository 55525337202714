import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 12,
    backgroundColor: "#F5F6F7",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    padding: '4em',
    [theme.breakpoints.down('xs')]: {
      padding: '1.6em',
    },
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxWidth: 522,
    maxHeight: "100%",
    backgroundColor: 'transparent'
  },

  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },

  iconStyle: {
    position: 'absolute',
    right: 0,
    top: 0,
  },

  body: {
    width: '100%',
  },

  title: {
    color: "#0F355C",
    fontFamily: "Montserrat",
    fontSize: '2.4em',
    fontWeight: "500",
    textAlign: "center",
    marginBottom: 15,
    marginTop: 10,
  },
  warning: {
    display: 'flex',
    fontSize: '16px',
    '&>div': {
      color: '#535D6D',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '500',
      letterSpacing: '0.31px',
      lineHeight: '19px',
    },
    '& p': {
      color: 'red',
      margin: 0,
      fontSize: 16
    },
    '& h5': {
      fontWeight: '500',
      marginTop: '10px',
    }
  },

  buttonBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },

  btn: {
    width: 160,
    fontFamily: "Montserrat",
    fontSize: '1.6em',
  },
 
  imageContainer: {
    display: "flex"
  },
  infoIcon: {
    paddingRight: "10px",
    fontSize: '30px',
    color: "#F59720"
  },
  paragraph: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: "500",
    paddingTop: 5,
    paddingLeft: 28
 },
 processingFee: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: "500",
    paddingTop: 5,
 }
}));

