import { makeStyles, withStyles } from '@material-ui/core/styles';
import verificationImg from '../../assets/CreateAccount/VerifyAccount.svg'
import { TextField, OutlinedInput, InputLabel } from '@material-ui/core';
import capBg from '../../assets/capBg.svg';
import signinIcon from '../../assets/SignIn/SignIn.svg';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#ddd',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${signinIcon})`,
    backgroundPosition: "bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'contain',
  },

  formContainer: {
    width: '42%',
    padding: '2.9em 4.9em',
    marginTop: '4.5em',
    marginBottom: '4.5em',
    backgroundColor: '#fff',
    borderRadius: 12,
    boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '20px',
      margin: '50px 15px'
    },
  },

  title: {
    color: '#535D6D',
    fontSize: '3.6em',
    fontWeight: '600',
    textAlign: 'center',
    marginBottom: 33,
    fontFamily: 'Montserrat',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.4em',
    },
  },

  inputContainer: {
    marginBottom: '3.9em',
  },

  inputStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600"
  },

  labelStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "400",
  },

  submitBtn: {
    marginBottom: 20,
    fontSize: 16,
    fontWeight: '600',
  },

  errorMsg: {
    fontSize: '1.4em',
    fontWeight: 'bold',
    textAlign: 'justify',
    marginBottom: 15,
    fontFamily: 'Montserrat',
    color: "red",
  },

  error: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    textAlign: 'left',
    marginTop: 5,
    fontFamily: 'Montserrat',
    color: "red",
  },

  captchaContainer: {
    //    
  },

  captchFieldContainer: {
    marginLeft: 30,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      width: '100%',
      marginTop: '3.9em'
    },
  },

  // captcha css
  refreshButton: {
    backgroundColor: 'rgb(37, 171, 199)',
    color: 'rgb(255,255,255)',
    cursor: 'pointer'
  },
  volumeButton: {
    backgroundColor: 'rgb(37, 171, 199)',
    color: 'rgb(255,255,255)',
    cursor: 'pointer'
  },
  captchaarea: {
    backgroundImage: `url(${capBg})`,
    backgroundSize: 'cover',
    textAlign: 'center'
  },
  captchasize: {
    fontSize: '38px',
    padding: '2px',
    margin: 0,
    fontFamily: 'cursive',
    fontWeight: '600'
  },
  buttonarea: {
    textAlign: 'right',
    paddingTop: '3px'
  },
  // end captch css
  sendBtn: {
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: '600',
    marginBottom: 20,
    color: '#535D6D !important',
    border: 'solid 2px',
    borderColor: theme.palette.primary.main,
    boxShadow: "0px 4px 8px 0 rgba(0,0,0,0.14)",
    height: 50,
  },
}
));

export const CutomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#25ABC7',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#25ABC7',
      },
    },
  },
})(TextField);

export const CutomOutlinedInput = withStyles({
  root: {
    "&$focused $notchedOutline": {
      border: `2px solid #25abc7`
    }
  },
  focused: {},
  notchedOutline: {}
})(OutlinedInput);

export const CustomInputLabel = withStyles({
  root: {
    "&$focused": {
      color: "#25abc7",
    }
  },
  focused: {},
})(InputLabel);