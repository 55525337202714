import { makeStyles } from '@material-ui/core/styles';
import signinIcon from '../../assets/signinIcon.svg';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#ddd',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${signinIcon})`,
    backgroundPosition: "top left",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'cover',
  },

  wrapper: {
    maxWidth: '55em',
    padding: '6.3em 6em',
    marginTop: '4.5em',
    marginBottom: '4.5em',
    backgroundColor: '#fff',
    borderRadius: 12,
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '20px',
      margin: '50px 15px'
    },
  },

  title: {
    color: "#727272",
    fontFamily: "Montserrat",
    fontSize: '16px',
    fontWeight: "600",
    letterSpacing: "0.35px",
    marginBottom: 12,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
  },

  hintText: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    letterSpacing: '0.4px',
    marginBottom: 20,
    textAlign: 'center',
  },

  btnContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 30,
    [theme.breakpoints.down('md')]: {
      marginTop: 20,
      marginBottom: 10,
    },
  },

  sendBtn: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    display: 'block',
    minWidth: '140px',
    fontWeight: '600'
  },

  checkboxContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 28,
  },

  icon: {
    fontSize: 35,
  },

  heading: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: '22px',
    letterSpacing: '0.4px',
    fontWeight: 600,
    paddingLeft: 20
  }

}));

