import React from 'react';
import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import insertLink from '../../assets/Dashboard/insert_link.png'


export const QuickLinksCard = (props) => {
  const mytheme = useSelector(state => state.theme);
  const { secondaryColorCode } = mytheme;

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <img src={insertLink} alt='icon' className={classes.insertLink} />
        <h1 className={classes.title}>Quick Links</h1>
      </Box>
      <Box className={classes.body}>
        <Link className={classes.bodyLink} to='/DocumentCenter'>Download 1098 Form</Link>
        <Link className={classes.bodyLink} to='/vom'>Verification of Mortgage</Link>
        <Link className={classes.bodyLink} to='/FeesSchedule'>Fees</Link>
        <Link className={classes.bodyLink} to='/PayoffRequest'>Payoff </Link>
        <Link className={classes.bodyLink} to='/HelpLanding'>Help</Link>
      </Box>
    </Box>
  )
}
