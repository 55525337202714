import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import {
  Box, Button, IconButton,
  InputAdornment, FormControl, CssBaseline, CircularProgress, Grid, TextField,
  OutlinedInput, InputLabel, Tooltip
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useStyles } from './styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { verifyAccount, getAccountData } from '../../actions';
import Cookies from 'js-cookie';
import { encrypt } from '../../helpers/encrypt';
import { getPublicIp, getAppSource, getUserType, scrollTop, returnCaptchaCode, renderDefaultMsg, getLoanLabel, getBorrowerLabel } from '../../helpers';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RefreshIcon from '@material-ui/icons/Refresh';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import captchaBG from '../../assets/captchaBG.svg';
import { makeStyles } from '@material-ui/core/styles';
import { IncorrectTinSsnAlert } from '../IncorrectTinSsnAlert';
import { PurgedLoanPopup } from '../PurgedLoanPopup';

const tooltipStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  customWidth: {
    maxWidth: 800,
    fontSize: 14,
    bottom: 23,
  },
  noMaxWidth: {
    maxWidth: 'none',
  },

}));

export const AccountVerification = () => {
  const mytheme = useSelector(state => state.theme);
  const { secondaryColorCode } = mytheme;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(!true)
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [captchaCode, setCaptchaCode] = useState('')
  const [captchaErr, setcaptchaErr] = useState(false);
  const [volumeIcon, setvolumeIcon] = useState(false);
  const [radioValue, setRadioValue] = useState(true);
  const [values, setValues] = useState({
    showPassword: false,
    borrowerSSN: '',
    foreignId: '',
    loanNumber: '',
    zipCode: '',
    captcha: '',
  });
  const captchaTimer = useRef(null);
  const [isTinIncorrect, setTinIncorrectStatus] = useState(false);
  const [accountVerificationData, setAccVerificationDetails] = useState({})
  const [purgedLoanPopup, setPurgedLoanStatus] = useState(false);
  const [purgedLoanMsg, setPurgedLoanMsg] =  useState();

  useEffect(() => {
    createCaptcha()
    handleTimer();
    return () => clearInterval(captchaTimer.current);
  }, []);

  const handleTimer = () => {
    captchaTimer.current = setInterval(() => {
      createCaptcha()
    }, 180000);
    return () => clearInterval(captchaTimer.current);
  }

  const handleReset = () => {
    clearInterval(captchaTimer.current);
  }

  const createCaptcha = () => {
    setcaptchaErr(false)
    // var code;
    // document.getElementById('captcha').innerHTML = "";
    // var charsArray = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    // var lengthOtp = 6;
    // var captcha = [];
    // for (var i = 0; i < lengthOtp; i++) {
    //   var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
    //   if (captcha.indexOf(charsArray[index]) == -1)
    //     captcha.push(charsArray[index]);
    //   else i--;
    // }
    // var canv = document.createElement("canvas");

    // var fir = Math.floor(Math.random() * 10)
    // var secc = Math.floor(Math.random() * 10)
    // var third = Math.floor(Math.random() * 10)
    // captcha[1] = fir;
    // captcha[3] = secc;
    // captcha[5] = third;

    // canv.id = "captcha";
    // canv.width = 170;
    // canv.height = 40;
    // canv.padding = 10;
    // var ctx = canv.getContext("2d");
    // ctx.font = "600 36px 'Montserrat'";
    // ctx.fillText(captcha.join(""), 0, 30);
    // code = captcha.join("");
    // document.getElementById("captcha").appendChild(canv); // adds the canvas to the body element
    const cpatchCode = returnCaptchaCode();
    setCaptchaCode(cpatchCode)
  }
  const check = () => {
    setvolumeIcon(true)
    setTimeout(function () { setvolumeIcon(false) }, 2000);
  }

  const speakk = () => {
    let speech = new SpeechSynthesisUtterance();
    speech.lang = "en-UK";
    speech.text = captchaCode;
    speech.volume = 2;
    speech.rate = 0.25;
    speech.pitch = 1.4;
    window.speechSynthesis.speak(speech);
  }

  const handleChange = (prop) => (event) => {
    if (prop === 'loanNumber') {
      if ((/^[0-9]+$/).test(event.target.value) || event.target.value === '') {
        setValues({ ...values, [prop]: event.target.value.slice(0, 10) });
      }
      return;
    } else if (prop === 'borrowerSSN') {
      if (activeTab) {
        if (!(/[^\w\s]/gi).test(event.target.value) && !(/\s/g).test(event.target.value)) {
          console.log(event.target.value, 'valuee..')
          setValues({ ...values, [prop]: event.target.value.slice(0, 4) });
        }
      } else {
        if (!(/[^\w\s]/gi).test(event.target.value) && !(/\s/g).test(event.target.value)) {
          setValues({ ...values, [prop]: event.target.value.slice(0, 35) });
        }
      }
      return;
    } else if (prop === 'foreignID') {
      if (activeTab) {
        if (!(/[^\w!@#$%^&*()-_+-/*=`~\s]/gi).test(event.target.value) && !(/\s/g).test(event.target.value)) {
          setValues({ ...values, [prop]: event.target.value.slice(0, 4) });
        }
      } else {
        if (!(/[^\w!@#$%^&*()-_+-/*=`~\s]/gi).test(event.target.value) && !(/\s/g).test(event.target.value)) {
          setValues({ ...values, [prop]: event.target.value.slice(0, 35) });
        }
      }
      return;
    }
    else if (prop === "zipCode") {
      if ((/^[0-9]+$/).test(event.target.value) || event.target.value === '') {
        setValues({ ...values, [prop]: event.target.value.slice(0, 5) });
      }
      return;
    }

    setValues({ ...values, [prop]: event.target.value });
    return;
  };

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (captchaCode.toLowerCase() === values.captcha.toLowerCase()) {
      setLoading(true);
      setcaptchaErr(false);
      const data = {
        "accountNumber": activeTab ? encrypt(values.loanNumber) : '',
        "borrowerSSN": radioValue ? encrypt(values.borrowerSSN) : encrypt(values.foreignId),
        "propertyZipCode": values.zipCode,
        "privateLabelUrl": Cookies.get("privateLabelUrl"),
        "ipAddress": await getPublicIp(),
        "browser": Cookies.get("browser"),
        "appSource": getAppSource(),
        "userType": getUserType(),
        "userName": ""
      }
      const res = await verifyAccount(data);
      if (res) {
      const { lstResponseStatus } = res;
      setLoading(false);
      if (res && res.isSuccess) {
        setValues({ ...values, borrowerSSN: '', foreignId: '', loanNumber: '', zipCode: '', captcha: '' });
        dispatch(getAccountData(res))
        localStorage.setItem('accountVerification',JSON.stringify(res));
        history.push('/CreateAccount');
      } else {
        setErrorMsg(lstResponseStatus[0].statusDesc);
        setValues({ ...values, captcha: '' });
        handleReset();
        createCaptcha();
        handleTimer();
        scrollTop(); 
        if (res.isInvalidSSN) {
          setAccVerificationDetails(res)
          setTinIncorrectStatus(true)
        } else if((res?.purgeLoanInfo !== null) && (res?.purgeLoanInfo !== 'null')) {
          setErrorMsg('')
          setPurgedLoanStatus(true)
          setPurgedLoanMsg(lstResponseStatus[0].statusDesc);
        }
       }
      } else {
        setLoading(false);
        setErrorMsg(renderDefaultMsg('400'))
        scrollTop(); 
      }
    } else {
      createCaptcha();
      setcaptchaErr(true);
      setValues({ ...values, captcha: '' });
    }
  }

  const handleActiveTab = (flag) => {
    setValues({
      showPassword: false,
      borrowerSSN: '',
      foreignId: '',
      loanNumber: '',
      zipCode: '',
      captcha: '',
    });
    // setRadioValue(true)
    setErrorMsg('');
    setActiveTab(flag);
  }
  
  const closePurgedLoanPopup = () => {
    setPurgedLoanMsg()
    setPurgedLoanStatus(false)
  }

  const { borrowerSSN, foreignId, zipCode, loanNumber, showPassword, captcha } = values;
  const disableBtn = activeTab ? loanNumber.length >= 3 && zipCode.length === 5 && (borrowerSSN.length === 4 || foreignId.length === 4) && loading === false : zipCode.length === 5 && (borrowerSSN.length > 8 || foreignId.length > 8) && loading === false;

  const tooltipClasses = tooltipStyles();

  return (
    <CssBaseline>
      <Box container="true" maxWidth='xl' className={classes.root}>
        <Box className={classes.formContainer}>
          <p className={classes.title}>Verify Your Account</p>
          <p className={classes.errorMsg} dangerouslySetInnerHTML={{ __html: errorMsg !== '' ? errorMsg : '' }} />
          <Box className={clsx(classes.inputContainer, classes.tabContainer)} style={{ borderColor: secondaryColorCode, }}>
           
            <button onClick={() => handleActiveTab(false)} variant="contained" className={activeTab ? classes.tabBtn : classes.activeTabBtn} style={{ backgroundColor: activeTab ? '#fff' : secondaryColorCode, color: activeTab ? secondaryColorCode : "#fff" }}>Verify by SSN/EIN</button>
            <button onClick={() => handleActiveTab(true)} variant="contained" className={activeTab ? classes.activeTabBtn : classes.tabBtn} style={{ backgroundColor: activeTab ? secondaryColorCode : '#fff', color: activeTab ? '#FFF' : secondaryColorCode }}>Verify by {getLoanLabel()} Number</button>
          </Box>
          <form noValidate onSubmit={handleSubmit} autocomplete="off">
            {activeTab && <Box className={classes.inputContainer}>
              <TextField
                id="outlined-loanNumber"
                autoComplete="off"
                label={`${getLoanLabel()} Number`}
                variant="outlined"
                type="text"
                value={loanNumber}
                onChange={handleChange('loanNumber')}
                fullWidth
                inputProps={{
                  className: classes.inputStyle,
                  maxLength: 10,
                  form: {
                    autocomplete: 'off',
                  },
                }}
                InputLabelProps={{
                  classes: { root: classes.textField }
                }}
              />
            </Box>}
            <div className={classes.radioBtn} style={{ marginTop: "-14px" }}>
              <RadioGroup row aria-label="account_type" name="account_type" value={radioValue} style={{ justifyContent: "flex-start" }}
                onChange={handleRadioChange}>
                <FormControlLabel value="true" checked={radioValue}
                  control={<Radio />} label="By SSN/EIN" />
                <Tooltip title={activeTab ? "Enter the last 4 digits/characters of foreign ID exactly as it is." : "Enter the full foreign ID exactly as it is."} classes={{ tooltip: tooltipClasses.customWidth }} placement="right-start" arrow>
                  <FormControlLabel value=""
                    control={<Radio />} label="By Foreign ID" />
                </Tooltip>
              </RadioGroup>
            </div>
            {radioValue ? <Box className={classes.inputContainer}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel className={classes.labelStyle} htmlFor="outlined-adornment">{activeTab ? `${getBorrowerLabel()} SSN/EIN Last 4 Digits` : `${getBorrowerLabel()} SSN/EIN`}</InputLabel>
                <OutlinedInput
                  color="primary"
                  id="outlined-adornment"
                  type={showPassword ? 'text' : 'password'}
                  value={borrowerSSN}
                  onChange={handleChange('borrowerSSN')}
                  labelWidth={activeTab ? 250 : 150}
                  inputProps={{
                    className: classes.inputStyle,
                    maxLength: activeTab ? 4 : 35,
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box> :
              <Box className={classes.inputContainer}>
                <FormControl variant="outlined" fullWidth >
                  <InputLabel className={classes.labelStyle} htmlFor="outlined-adornment">{activeTab ? `${getBorrowerLabel()} Foreign ID Last 4 Digits/Characters` :  `${getBorrowerLabel()} Foreign ID`}</InputLabel>
                  <OutlinedInput
                    color="primary"
                    id="outlined-adornment"
                    type={showPassword ? 'text' : 'password'}
                    value={foreignId}
                    onChange={handleChange('foreignId')}
                    labelWidth={activeTab ? 370 : 170}
                    inputProps={{
                      className: classes.inputStyle,
                      maxLength: activeTab ? 4 : 35,
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>}
            <Box className={classes.inputContainer}>
              <TextField
                color="primary"
                id="outlined-zip"
                label="Property Zip Code"
                variant="outlined"
                autoComplete="off"
                type="text"
                value={zipCode}
                onChange={handleChange('zipCode')}
                fullWidth
                inputProps={{
                  className: classes.inputStyle,
                  maxLength: 5,
                  form: {
                    autocomplete: 'off',
                  },
                }}
                InputLabelProps={{
                  classes: { root: classes.textField }
                }}
              />
            </Box>
            <Box className={clsx(classes.inputContainer, classes.captchaContainer)}>
              <div>
                <Grid container row spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <Grid container>
                      <Grid item xs={10} sm={10} md={10}>
                        <div className={classes.captchaarea} style={{ backgroundImage: captchaBG, paddingTop: '10px' }}>
                          <div id="captcha">
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} className={classes.buttonarea}>
                        {volumeIcon ? <VolumeOffIcon className={classes.volumeButton} /> : <VolumeUpIcon className={classes.volumeButton} onClick={() => { speakk({ text: captchaCode }); check() }} />}<br />
                        <RefreshIcon className={classes.refreshButton} onClick={() => { createCaptcha() }} />
                      </Grid>
                    </Grid>

                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      id="outlined-loan-input"
                      label="Captcha"
                      color="primary"
                      autoComplete="off"
                      type="text"
                      value={captcha}
                      name="captcha"
                      variant="outlined"
                      fullWidth
                      onChange={handleChange('captcha')}
                      InputLabelProps={{
                        classes: { root: classes.textField }
                      }}
                      inputProps={{
                        className: classes.inputStyle
                      }}
                    />
                    {captchaErr && <p className={classes.error}>Please enter correct Captcha.</p>}
                  </Grid>
                </Grid>
              </div>
            </Box>
            <Box >
              <Button disabled={(!disableBtn || !captcha)} type='submit' className={classes.signUpBtn} size="large" fullWidth variant="contained" color="primary">
                {loading ? <CircularProgress /> : "sign up"}
              </Button>
            </Box>
            <Box>
              <Link to='/' style={{ textDecoration: "none" }}>
                <Button className={classes.cancelBtn} size="large" fullWidth
                  variant='outlined'
                //color='primary'
                >
                  cancel
                </Button>
              </Link>
            </Box>
          </form>
        </Box>
       {/* Incorrect tin/ssn dialog box */}  
       {isTinIncorrect &&
        <IncorrectTinSsnAlert
          details={accountVerificationData}
       />}
      {purgedLoanPopup &&
        <PurgedLoanPopup open={purgedLoanPopup} closePurgedLoanPopup={closePurgedLoanPopup} type={'signup'} purgedLoanMsg={purgedLoanMsg}
        />
      }
      </Box>
    </CssBaseline>
  )
}
