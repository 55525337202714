import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
  mainHeading: {
    padding: '0.2em 0 0.3em',
    maxWidth: '1175px',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontWeight: 'bold',
    fontSize: '2.4em',
    color: '#727272',
    letterSpacing: '0.53px',
    lineHeight: '29px',
    fontFamily: 'Montserrat',
    [theme.breakpoints.down('md')]: {
      padding: '0.2em 1.5em 0.3em',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '1.5em 1.7em 0 1.7em',
      fontSize: '20px',
      lineHeight: '24px',
    },
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    fontFamily: 'Montserrat',
    '& > *': {
      margin: theme.spacing(1),
    },
    width: '100%',
    padding: '0',
    maxWidth: '120em',
    marginLeft: 'auto',
    marginRight: 'auto',
    '&:last-child': {
      paddingBottom: '9em',
      [theme.breakpoints.down('sm')]: {
        paddingBottom: '5em',
      },
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 5em',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '1em 3.5em',
    },
  },
  bg: {
    background: 'rgb(214 207 207 / 54%)',
    margin: '0 -15px'
  },
  paper: {
    width: '100%',
    margin: '15px 0',
    padding: '10px 0',
    minHeight: '90px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  tileFlex: {
    padding: '5px 0 0 0',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      justifyContent: 'flex-start'
    },
  },
  tileIcon: {
    [theme.breakpoints.down('sm')]: {
      width: '30%',
      textAlign: 'center'
    },
  },

  helpAddress: {
    fontSize: '18px',
    fontWeight: '600',
    fontFamily: 'Montserrat',
    color: '#4D4D4D',
    letterSpacing: '0.44px',
    lineHeight: '24px'
  },

  bodyText: {
    fontSize: '20px',
    fontWeight: '500',
    fontFamily: 'Montserrat',
    color: '#202020',
    letterSpacing: '0.44px',
    lineHeight: '24px'
  },

  visitTimeFont: {
    fontSize: '14px',
    fontWeight: '600',
    letterSpacing: '0.44px',
    fontFamily: 'Montserrat',
    color: '#4D4D4D',
    lineHeight: '18px',
    marginTop: '10px'
  },

  flexItem: {
    width: '33%',
    padding: '10px 30px',
    borderRight: '2px solid #a5a2a2',
    '&:last-child': {
      borderRight: 'none',
      height: '100%',
      borderLeft: '2px solid #a5a2a2',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      borderRight: 'none',
      borderBottom: '1px solid #a5a2a2',
      padding: '20px 20px',
      '&:last-child': {
        borderBottom: 'none',
        borderLeft: 'none',
      },
    },
  },

  noborder: {
    [theme.breakpoints.up('md')]: {
      border: 'none !important'
    }
  },

  flex: {
    display: 'flex',
    alignItems: 'unset'
  },

  linkStyle: {
    color: '#535D6D',
    fontWeight: '600',
    textDecoration: 'underline'
  },

  noUnderLine: {
    textDecoration: 'none'
  },

  iconStyle: {
    fontSize: 30, color: '#535D6D', marginRight: '7px'
  },

  icon: {
    height: 18, width: 18, color: '#535D6D', marginRight: '6px', marginLeft: '6px'
  },

  separate: {
    borderTop: '2px solid #a5a2a2'
  },

  timing: {
    fontWeight: '600'
  }
}));