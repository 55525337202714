import React, {useState} from 'react';
import { useStyles } from './styles';
import { Box, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';

import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';

export const ThankyouAlert = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(props?.open);

  const handleClose = () => setOpen(false);

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }}  open={open} onClose={handleClose}>
      <Box className={classes.root}>
         <IconButton onClick={handleClose} className={classes.closeIcon}>
          <CancelIcon />
        </IconButton> 
        <Box className={classes.cardBody}>
          <Box className={classes.statusContainer}>
            <p className={classes.statusTitle}>Thank You!</p>
            <p className={classes.statusSubTitle}>Thank You for setting up your promise plan.</p>
            <p className={classes.paragraph}> We have received the information and will apply it to your account soon. </p>
            <p className={classes.paymentMenu}> If you have any questions or concerns, please  <a href="./HelpLanding?contactus" target="_self" className={classes.contactUs}>contact us</a>.</p>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}
