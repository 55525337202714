import React from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './styles';
import myloanweb from '../../assets/Admin/myloanweb.png';

export const NoBase = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <img src={myloanweb} alt='icon' />
      </Box>
      <Box className={classes.body}>
        <Box className={classes.container}>
          <h1 className={classes.title}>Welcome to MyLoanWeb.com</h1>
          <p className={classes.subTitle}>Welcome to your mortgage servicing center. We help consumers through clear and transparent communication on their loans and helping them through our various programs in case they have financial difficulties.</p>
        </Box>
      </Box>
      <Box className={classes.footer}>
        <p className={classes.subTitle}>© 2021 Myloanweb.com, All rights reserved</p>
      </Box>
    </Box>
  )
}