import React, { useState } from 'react';
import { useStyles, RedTooltip } from './styles';
import { Box } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import unlockIcon from '../../assets/unlockIcon.png';
import { CommunicationConsentAlert } from '../../components';
import { GoGreenAlert } from '../../components';
import { useDispatch } from 'react-redux';
import { setPaymentOption } from '../../actions'
import insertLink from '../../assets/Dashboard/insert_link.png'


export const UnlockBenefitCard = (props) => {
  const { email, phone, accountNumber, isCommConcent,
    isMobileVerified, isAlreadyScheduleACHPayment, isGoGreen, isCommConcentMobile, IsSmsConsent, isEmailVerified
  } = props;

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [openGoGreen, setOpenGoGreen] = useState(false)
  const handleClickOpen = () => setOpen(true);
  const handleClose = (status) =>  {
    if (status) {
      props.handePopupClose()
    }
  
    setOpen(false)
  };
  const handleClickOpenGoGreen = () => setOpenGoGreen(true);
  const handleCloseGoGreen = () => setOpenGoGreen(false);
  const history = useHistory()
  const dispatch = useDispatch()

  const getSteps = () => {
    return ['Create Profile', 'Communication Consent', 'Verify Phone Number', 'Set Up Auto Payment', 'Go for Paperless Billing'];
  }

  const steps = getSteps();


  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNavigation = (link) => {
    history.push(link)
  }

  const handleAutoPayNavigation = () => {
    dispatch(setPaymentOption('MANAGEAUTOPAY'));
    history.push('/PaymentOption')
  }

  const isVerified = () => {
    return (isMobileVerified && isEmailVerified)
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <img className={classes.unlockIcon} src={insertLink} alt='icon' />
        <h1 className={classes.title}>Take Full Advantage</h1>
      </Box>
      <Box className={classes.body}>
        <p className={classes.bodyText}>Complete your profile to access all of the features available in your account. </p>
        <Box>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            classes={{
              root: classes.stepperRoot
            }}
          >
            <Step>
              <StepLabel
                classes={{ root: classes.activeBodyLink, label: classes.linkStyle }}
                icon={<CheckCircleIcon />}
              >
                Create your profile
              </StepLabel>
            </Step>
            <Step>
            {(isMobileVerified && isEmailVerified) || true ?

                <StepLabel classes={{ root: isCommConcent ? classes.activeBodyLink : classes.bodyLink, label: isCommConcent ? classes.activeBodyLinkLabel : classes.bodyLinkLabel }} icon={<CheckCircleIcon />} onClick={() => handleClickOpen()}>Agree to receive electronic messages</StepLabel> :
                <RedTooltip arrow title={`Please verify your ${!isEmailVerified == true ? 'email' : ' '}${(!isEmailVerified && !isMobileVerified) ? ' and' : ''}${!isMobileVerified ? ' mobile number' : ''}.`}>
                  <StepLabel classes={{ root: isCommConcent && isVerified ? classes.activeBodyLink : classes.bodyLink, label: isCommConcent && isVerified ? classes.activeBodyLinkLabel : classes.bodyLinkLabel }} icon={<CheckCircleIcon />}
                    onClick={() => { isMobileVerified && isEmailVerified && handleClickOpen() }}
                  >
                    <span style={{ color: 'lightgrey', textDecoration: 'none' }}>Agree to receive electronic messages</span></StepLabel></RedTooltip>}
            </Step>
            {/* <Step>
              <StepLabel classes={{ root: isMobileVerified ? classes.activeBodyLink : classes.bodyLink, label: isMobileVerified ? classes.activeBodyLinkLabel : classes.bodyLinkLabel }} icon={<CheckCircleIcon />} onClick={() => handleNavigation('/UserProfile/Profile')}>Verify Phone Number</StepLabel>
            </Step> */}
            <Step >
              <StepLabel onClick={() => handleAutoPayNavigation()} classes={{ root: isAlreadyScheduleACHPayment ? classes.activeBodyLink : classes.bodyLink, label: isAlreadyScheduleACHPayment ? classes.activeBodyLinkLabel : classes.bodyLinkLabel }} icon={<CheckCircleIcon />} >Set up auto payment, and save time and postage</StepLabel>
            </Step>
            <Step>
              {(isMobileVerified && isEmailVerified) || true ?
                <StepLabel classes={{ root: isGoGreen ? classes.activeBodyLink : classes.bodyLink, label: isGoGreen ? classes.activeBodyLinkLabel : classes.bodyLinkLabel }} icon={<CheckCircleIcon />} onClick={() => handleClickOpen()}>Go paperless for the ultimate convenience</StepLabel> :
                <RedTooltip arrow title={`Please verify your ${!isEmailVerified == true ? 'email' : ''}${(!isEmailVerified && !isMobileVerified) ? ' and' : ''}${!isMobileVerified ? ' mobile number' : ''}.`}>
                  <StepLabel classes={{ root: isGoGreen ? classes.activeBodyLink : classes.bodyLink, label: isGoGreen ? classes.activeBodyLinkLabel : classes.bodyLinkLabel }} icon={<CheckCircleIcon />} onClick={() => { isMobileVerified && isEmailVerified && handleClickOpen() }}><span style={{ color: 'lightgrey', textDecoration: 'none' }}>Go paperless for the ultimate convenience</span></StepLabel></RedTooltip>}
            </Step>
          </Stepper>
        </Box>
      </Box>
      <CommunicationConsentAlert
        open={open}
        handleClose={handleClose}
        email={email}
        phone={phone}
        accountNumber={accountNumber}
        isCommConcent={isCommConcent}
        isCommConcentMobile={isCommConcentMobile}
        isGoGreen={isGoGreen}
        IsSmsConsent={IsSmsConsent}
        isVerified={isMobileVerified && isEmailVerified}
        isMobileVerified={isMobileVerified}
        isEmailVerified={isEmailVerified}
      />
      <GoGreenAlert
        handleClose={handleCloseGoGreen}
        open={openGoGreen}
        accountNumber={props.accountNumber}
        email={email}
        phone={phone}
        isVerified={isMobileVerified && isEmailVerified}
        isMobileVerified={isMobileVerified}
        isEmailVerified={isEmailVerified}
      />
    </Box>
  )
}
