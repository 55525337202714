import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 670,
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    position: 'relative',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    '& .MuiIconButton-root': {
      padding: 4
    },
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxWidth: '70%',
    maxHeight: "100%",
    backgroundColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%'
    }
  },

  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "400",
    textAlign: 'center',
    margin: 16,
  },

  cardBody: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '30px 100px 20px 100px',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      padding: '30px 20px 20px 20px'
    },
  },

  statusContainer: {
    marginBottom: 20,
    marginTop: 20
  },

  statusBody: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "bold",
  },

  bodyText: {
    color: "#0C0B0B",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    marginBottom: 30,
    marginTop: 0,
  },

  btn: {
    width: 188,
    fontFamily: "Montserrat",
    fontSize: '1.5em',
    fontWeight: "600",
  },

  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },
 
  statusTitle: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: 20,
    fontWeight: "700",
    marginTop: 0,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      fontWeight: "600"
    },
  },

  paragraph: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: 20,
    fontWeight: "500",
    [theme.breakpoints.down('sm')]: {
      fontSize: 15
    },  
  },
 
  statusSubTitle: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: 18,
    fontWeight: "500",
    lineHeight: "30px",
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    },
  },

  continueBtn: {
    fontFamily: "Montserrat",
    marginBottom: 20,
    fontSize: '1.6em',
    fontWeight: '600',
    width: 190,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      width: 105,
      height: '30px'
    },
  },

  buttonContainer: {
    textAlign: 'center',
    margin: '20px 10px'
  },
 
  checkBoxLabel: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600"
  },
 
  skipBtn: {
    width: 190,
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "600",
    letterSpacing: "0.53px",
    lineHeight: "19px",
    textAlign: "center",
    border: "2px solid",
    height: '40px',
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
    borderColor: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      width: 105,
      fontSize: 14,
      height: '30px'
    },
  },
 
  titleBody: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '12px 2em',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  cardIcon: {
    width: 18,
    height: 18,
    color: "#007FFF"
  },

  cardContent2: {
    display: 'flex',
    marginBottom: 20,
    marginTop: 20
  },

  info: {
    color: "#4D4D4D",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: "500",
    fontStyle: 'italic',
    paddingLeft: 5
  },
  
  infoIcon: {
    width: 24,
    height: 24,
    color: "#F59720",
    [theme.breakpoints.down('sm')]: {
      width: 22,
      height: 22
    },
  },

  radioBtn: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 5,
      paddingRight: 5
    },
  },

  cardTitleBody: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '12px 2em',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  labelText: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "700",
  },

  textareaSection: {
    paddingTop: '16px',
    '& .MuiFormLabel-root': {
      asterisk: {
        color: 'red',
        '&$error': {
          color: 'red'
        },
      }
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '15px'
    },
  },

  textArea: {
     color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 500,
    width: '100%',
    padding: "10px",
    height: '56px',
    border: 'none',
    backgroundColor: 'transparent',
    outline: 'none',
    borderRadius: '4px',
    border: '1px solid #9E9E9E',
    "&::placeholder": {    
      fontSize: '14px', 
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '1rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      lineHeight: 1
     }
  },

  textBox: {
    width: '100%',
    '& .MuiFormLabel': {
      asterisk: {
        color: 'red',
        '&$error': {
          color: 'red'
        },
      }
    }
  },

  labelAsterisk: {
    color: "red"
  },

  textAreaValidation: {
    color: '#202020',
    float: 'right'
  },

  inputStyle: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600",
    lineHeight: 24
  },
 
  labelStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "400",
  },

  viewconsent: {
    color: '#007FFF',
    cursor: "pointer",
    textDecoration: 'underline',
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600",
    paddingLeft: 10
  },

  infoBox: {
    width: '100%',
    alignItems: 'center',
    backgroundColor: '#FFF0F0',
    borderRadius: 4,
    padding: '0.5em',
    borderRadius: '4px',
    border: "1px solid #F85757",
  },

  infoTopBox: {
    width: '100%',
    alignItems: 'center',
    backgroundColor: '#F4F4F4',
    borderRadius: 8,
    padding: '0.5em',
    borderRadius: '4px',
    border: "1px solid #D9D9D9",
    marginBottom: 20,
    marginTop: 5
  },

  feelFree: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: "15px",
    letterSpacing: "0.31px",
    lineHeight: "18px",
    fontWeight: "400",
    padding: 10,
    maxHeight: '100px',
    overflow: "auto"
  },

  closeIcon: {
    position: 'absolute',
    right: 1,
    paddingTop: 8,
    [theme.breakpoints.down('sm')]: {
        right: 2
    }
  },

  icon: {
    marginLeft: 12,
    fontSize: '30px',
    color: '#121D22',
    height: '20px',
    width: '20px',
    cursor: "pointer",
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      height: '16px',
      width: '16px',
      marginLeft: 2
    }
  },

  validationError: {
    color: "red",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "600",
    letterSpacing: "0.31px",
    lineHeight: "18px",
    marginTop: '5px'
  },
}));

