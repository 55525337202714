import React, { useState, useEffect } from 'react';
import { useStyles } from './styles';
import {
  Box, Button,
  Select, FormControl,
  MenuItem, InputLabel, CircularProgress, FormControlLabel
} from '@material-ui/core';
import {
  getPrivatelabel, getInvestorCode,
  downloadNotifications, getNotification, uploadNotificationFile
} from '../../../../actions';
import Cookies from 'js-cookie';
import { getAppSource, getUserType, maskLoan } from '../../../../helpers';
import { decrypt } from '../../../../helpers/encrypt';
import Backdrop from '@material-ui/core/Backdrop';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


export const MapNotification = () => {

  const classes = useStyles();

  const [state, setState] = useState({
    company: null,
    investor: [],
    notificationId: '',
    selectedFile: null,
  });

  const [privateLabels, setPrivateLabel] = useState([]);
  const [investorCodes, setInvestorCodes] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorData, setErrorData] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = (event) => {
    setSelectAll(event.target.checked)
    if (event.target.checked) {
      setState({ ...state, investor: [...investorCodes] });
    } else {
      setState({ ...state, investor: [] });
    }
  }

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const onTagsChange = (event, values) => {
    setState({ ...state, investor: values });
  }

  const handleFile = (event) => {
    setState({ ...state, [event.target.name]: event.target.files[0] });
  };

  const getPrivateLabelData = async () => {
    setIsLoading(true)
    const res = await getPrivatelabel();
    if (res && res.lstResponseStatus[0].statusCode === "0") {
      setPrivateLabel(res.privateLabelList)
    }
    setIsLoading(false)
  }

  const getInvestorCodeData = async () => {
    const { privateLabelCode } = state.company;
    setIsLoading(true)
    const data = {
      "investorCode": '',
      "pageIndex": 0,
      "pageSize": 10,
      "isAll": "true",
      "privateLabelCode": privateLabelCode,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
    }
    const res = await getInvestorCode(data);
    if (res && res.lstResponseStatus[0].statusCode === "0") {
      setInvestorCodes(res.lstInvestorCode)
    }
    setIsLoading(false)
  }

  const getNotificationTitle = async () => {
    setIsLoading(true)
    const data = {
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
      "notificationTitle": ""
    }
    const res = await getNotification(data);
    if (res && res.lstResponseStatus[0].statusCode === "0") {
      setNotifications(res.lstNotification)
    }
    setIsLoading(false)
  }

  const handleDownload = async () => {
    setErrorMsg('')
    setIsLoading(true)
    const { privateLabelCode } = state.company;
    const data = {
      "privateLabelCode": privateLabelCode,
      "investorCodeList": state.investor.map(item => item.investorCode).join(','),
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    }
    const res = await downloadNotifications(data);
    if (res && res.lstResponseStatus && res.lstResponseStatus[0].statusCode !== "0") {
      setErrorMsg(res.lstResponseStatus[0].statusDesc)
    } else {
      const url = window.URL.createObjectURL(new Blob([res], { type: 'text/csv;charset=utf-8;' }));
      const link = document.createElement('a');
      link.setAttribute("href", url);
      link.setAttribute("download", 'notifications.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    setIsLoading(false);
    setState({ ...state, investor: [] });
  }

  const onFileUpload = async () => {
    setIsLoading(true)
    setErrorMsg('');
    const formData = new FormData();
    formData.append('formFile', state.selectedFile);
    formData.append('fileName', state.selectedFile.name);
    formData.append('notificationId', state.notificationId);
    formData.append('privateLabelUrl', Cookies.get("privateLabelUrl"));
    formData.append('browser', Cookies.get("browser"));
    formData.append('appSource', getAppSource());
    formData.append('userType', getUserType());
    formData.append('ipAddress', Cookies.get('publicIp'));

    const res = await uploadNotificationFile(formData);
    if (res && res.lstResponseStatus[0].statusCode !== "0") {
      setErrorMsg(res.lstResponseStatus[0].statusDesc);
      setErrorData(res.lstBELinkAccountResponse ? [...res.lstBELinkAccountResponse] : [])
    }
    setIsLoading(false)
  };

  useEffect(() => {
    getPrivateLabelData()
    getNotificationTitle()
  }, []);

  useEffect(() => {
    if (state.company && state.company.privateLabelCode) {
      getInvestorCodeData()
    }
  }, [state.company])

  if (isLoading) {
    return (
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }
  return (
    <Box className={classes.bgg}>
      <Box className={classes.root}>
        <h1 className={classes.title}>Map Borrower For Notification</h1>
        <Box className={classes.tableBox}>
          <form className={classes.formStyle}>
            <Box className={classes.stepBox}>
              <h3 className={classes.stepText}>Step: 1</h3>
            </Box>
            <Box className={classes.noteBox}>
              <p className={classes.noteText}><b>Note :</b> Download the Templates for Loan Numbers of corresponding Company ( OR All Company) to check or modify Loan Number for setting up the Notification type on Borrower Portal.</p>
            </Box>
            <Box className={classes.inputContainer}>
              <FormControl variant="outlined" size='small' className={classes.formControl}>
                <InputLabel id="company-name">Select Company</InputLabel>
                <Select
                  name='company'
                  labelWidth={120}
                  labelId="company-name"
                  id="company-name-field"
                  value={state.company}
                  onChange={handleChange}
                  // displayEmpty
                  className={classes.selectEmpty}
                >
                  <MenuItem value="" disabled>Select Company</MenuItem>
                  {privateLabels.map(label => {
                    return (
                      <MenuItem key={label.id} value={label}>{label.privateLabelName}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>

              <Box className={classes.autocompleteContainer}>
                <Autocomplete
                  value={state.investor}
                  multiple
                  fullWidth
                  size='small'
                  id="investor-name-field"
                  onChange={onTagsChange}
                  options={investorCodes}
                  disableCloseOnSelect
                  getOptionLabel={(option) => decrypt(option.investorCode)}
                  renderOption={(option, { selected }) => {
                    return (
                      <React.Fragment>
                        <Checkbox
                          color='primary'
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {decrypt(option.investorCode)}
                      </React.Fragment>
                    )
                  }}

                  renderInput={(params) => (
                    <TextField
                      name='investor'
                      {...params}
                      variant="outlined"
                      label="Select Investor"
                    />
                  )}
                />
                {investorCodes.length !== 0 ? <FormControlLabel
                  className={classes.selectAll}
                  control={
                    <Checkbox
                      checked={selectAll}
                      onChange={handleSelectAll}
                      color="primary"
                    />
                  }
                  label="All"
                /> : null}
              </Box>

              <Button onClick={handleDownload} color='primary' variant='contained'>Download</Button>
            </Box>
          </form>
        </Box>
        {errorMsg.length ? <Box className={classes.errorBox}>
          <p className={classes.errorText}>{errorMsg}</p>
          {errorData.length !== 0 ? errorData.map(data => {
            return (
              <Box className={classes.errorDataBox} key={data.loanNumber}>
                <p className={classes.errorDescription}>Loan Number: {maskLoan(decrypt(data.loanNumber))}</p>
                <p className={classes.errorDescription}>Loan Status: {data.loanStatus}</p>
              </Box>
            )
          }) : null}
        </Box> : null}
        <Box className={classes.tableBox}>
          <form className={classes.formStyle}>
            <Box className={classes.stepBox}>
              <h3 className={classes.stepText}>Step: 2</h3>
            </Box>
            <Box className={classes.noteBox}>
              <p className={classes.noteText}><b>Note :</b> Please Upload downloaded template (in CSV format) for setting up the Notification type on Borrower Portal.</p>
            </Box>
            <Box className={classes.inputContainer}>
              <FormControl variant="outlined" size='small' className={classes.formControl}>
                <InputLabel id="notification-id">Select Notification Title</InputLabel>
                <Select
                  name='notificationId'
                  labelWidth={170}
                  labelId="notification-id"
                  id="notification-title-field"
                  value={state.notificationId}
                  onChange={handleChange}
                  // displayEmpty
                  className={classes.selectEmpty}
                >
                  <MenuItem value="" disabled>Select Notification Title</MenuItem>
                  {notifications.map(notification => {
                    return (
                      <MenuItem key={notification.notificationId} value={notification.notificationId}>{notification.notificationTitle}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              <input
                accept=".csv"
                type="file"
                name='selectedFile'
                onChange={handleFile}
              />
              <Button onClick={onFileUpload} color='primary' variant='contained'>upload</Button>
            </Box>
          </form>
        </Box>
      </Box >
    </Box >
  )
}

