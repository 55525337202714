import { makeStyles } from '@material-ui/core/styles';
import capBg from '../../assets/capBg.svg';
import signinIcon from '../../assets/SignIn/SignIn.svg';


export const useStyles = makeStyles((theme) => ({

  mainHeading: {
    padding: '0.2em 3.5em 0.3em',
    fontWeight: '700',
    fontSize: '2.5em',
    color: '#727272',
    [theme.breakpoints.down('sm')]: {
      padding: '1.5em 1.2em 0 2em',
      fontSize: '2em',
      fontFamily: 'Montserrat',
    },
  },

  mainRoot: {
    width: '100%',
    backgroundColor: '#ccc',
    backgroundImage: `url(${signinIcon})`,
    backgroundPosition: "bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'contain',
  },


  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '2.4em 1.6em 9em',
    fontFamily: 'Montserrat',
    width: '100%',
    maxWidth: '122em',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: '2.4em 1.6em 5em',
    },
  },

  mainContainer: {
    background: 'rgb(255, 255, 255)',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 14px 7px, rgba(0, 0, 0, 0.2) 0px 1px 3px 0px',
    position: 'relative',
    borderRadius: '12px'
  },

  pageHeading: {
    color: '#1A4664',
    fontFamily: 'Montserrat',
    fontSize: '22px',
    fontWeight: '700',
    letterSpacing: '0.63px',
    lineHeight: '29px',
    marginBottom: 25,
    marginTop: 24
  },

  flexBox: {
    display: 'flex',
    alignItems: 'center'
  },

  subTitle: {
    color: '#363636',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: '600',
    letterSpacing: '0.47px',
    lineHeight: '22px',
    margin: '30px 0 23px 0'
  },

  seondaryColor: {
    color: theme.palette.secondary.contrastText,
    marginLeft: '17px',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.5px',
    lineHeight: '20px',
  },

  topFormText: {
    margin: '20px 0'
  },

  bobyText: {
    color: '#535D6D',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: '0.36px',
    lineHeight: '19px',
  },

  rightMenuContainer: {
    display: 'flex',
    textAlign: 'right',
    position: 'absolute',
    top: '20px',
    right: '35px',
    alignItems: 'center'
  },

  formContainer: {
    padding: '40px 30px 0 30px',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 10px 0 10px',
    },
    '& .MuiFormLabel-root.MuiInputLabel-outlined': {
      color: '#535D6D',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '500',
      letterSpacing: '0.36px',
      lineHeight: '19px',
      background: '#fff',
      paddingRight: '5px'
    },
    '& .MuiFormLabel-root.MuiInputLabel-outlined.Mui-focused': {
      color: theme.palette.primary.main,
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      color: '#535D6D',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      letterSpacing: '0.36px',
      lineHeight: '19px',
    },
  },

  otherName: {
    width: '48.5%', float: 'right',
    [theme.breakpoints.down('sm')]: {
      width: '100%', float: 'none'
    },
  },

  textField: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: '500'
  },

  textInput: {
    fontWeight: '600'
  },

  formText: {
    color: '#535D6D',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: '600',
    letterSpacing: '0.4px',
    lineHeight: '22px'
  },

  resetButton: {
    fontFamily: 'Montserrat',
    letterSpacing: '0.36px',
    fontWeight: '600',
    marginRight: '25px',
    width: '180px',
    height: '40px',
    border: "2px solid",
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
    borderColor: theme.palette.primary.main,
    //color: theme.palette.primary.contrastText,
    '&:hover': {
      border: "2px solid",
      borderColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '28px',
      marginRight: 0,
    },
  },
  addAccButton: {
    width: '180px',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    textTransform: 'uppercase',
    height: '40px',
  },
  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },


  errorMsg: {
    fontSize: '1.4em',
    fontWeight: '600',
    textAlign: 'center',
    marginBottom: 10,
    marginTop: 5,
    fontFamily: 'Montserrat',
    color: "red",
  },

  validationErr: {
    fontSize: '12px',
    fontWeight: '500',
    top: 1,
    left: 0,
    fontFamily: 'Montserrat',
    color: "red",
    margin: '2px 0'
  },

  loaderRoot: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  rowMargin: {
    marginBottom: 40,
  },

  infoBox: {
    borderRadius: '6px',
    backgroundColor: theme.palette.secondary.main,
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
    padding: '15px 60px',
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      padding: '7px 20px',
    },
  },

  refreshButton: {
    backgroundColor: 'rgb(37, 171, 199)',
    color: 'rgb(255,255,255)',
    cursor: 'pointer'
  },
  volumeButton: {
    backgroundColor: 'rgb(37, 171, 199)',
    color: 'rgb(255,255,255)',
    cursor: 'pointer'
  },
  captchaarea: {
    background: 'linear-gradient(90deg, #fff 2px, transparent 1%) center, linear-gradient(#fff 2px, transparent 1%) center, #ccc',
    backgroundImage: `url(${capBg})`,
    backgroundSize: 'cover',
    textAlign: 'center'
  },
  captchasize: {
    fontSize: '38px',
    padding: '2px',
    margin: 0,
    fontFamily: 'cursive',
    fontWeight: '600'
  },
  buttonarea: {
    textAlign: 'right',
    paddingTop: '3px'
  },
  error: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    textAlign: 'left',
    marginTop: 5,
    fontFamily: 'Montserrat',
    color: "red",
  },

  errorIconStyle: {
    fill: '#B00020', fontSize: '40px'
  }

}));