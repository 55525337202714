import Cookies from 'js-cookie';
import { axiosProtected } from '../config';
import { getAppSource, getUserType } from '../helpers';

export const getAmortizationData = async (accountNo) => {
    const data = {
        "accountNumber": accountNo,
        "privateLabelUrl": Cookies.get("privateLabelUrl"),
        "ipAddress": Cookies.get('publicIp'),
        "browser": Cookies.get("browser"),
        "appSource": getAppSource(),
        "userType": getUserType(),
        "userName": ""
      }
  
    try {
      const res = await axiosProtected.post('/DashBoard/GetAmortizationData', data);
      return res.data
    } catch (e) {      
      return null
    }
  }