import React, { useEffect } from 'react';
import { Box, Button } from "@material-ui/core";
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import { scrollTop } from '../../helpers';
import fourzerofour from '../../assets/404.png';

export const PageNotFound = () => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    scrollTop()
  }, []);

  const goBack = () => {
    history.replace({ pathname: "/Dashboard" });
  }

  return (
    <Box className={classes.root}>
      <div className={classes.loaderContainer}>
        <img src={fourzerofour} alt="icon" className={classes.imageStyle} />
        {/* <Button onClick={goBack} className={classes.goBackBtn} variant="contained" color="primary">
          Go back
        </Button> */}
      </div>
    </Box>
  )
}