import { makeStyles } from '@material-ui/core/styles';

export const useStyles1 = makeStyles((theme) => ({
  root: {
    borderRadius: 12,
    backgroundColor: "#F5F6F7",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    padding: '2.2em 2.2em',
    [theme.breakpoints.down('xs')]: {
      padding: '1.6em',
    },
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxWidth: 570,
    maxHeight: "100%",
    backgroundColor: 'transparent'
  },

  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },

  iconStyle: {
    padding: 0,
    color: '#535D6D',
  },

  body: {
    width: '100%',
    padding: '0 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '0',
    },
  },

  checkIconContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  checIcon: {
    color: theme.palette.secondary.main,
    height: 36,
    width: 36
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "500",
    letterSpacing: '0.15px',
    textAlign: "center",
    margin: '5px 30px 40px 30px',
    borderBottom: '1px solid #d7dadc',
    paddingBottom: '17px',
    [theme.breakpoints.down('xs')]: {
      margin: '5px 10px 35px 10px',
    },
  },

  subTitle: {
    color: "#0C0B0B",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    letterSpacing: '0.25px',
    lineHeight: '16px',
    marginBottom: '30px',
  },

  flexBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '30px',
    background: '#e6edf0',
    padding: '12px 20px',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },

  item: {
    margin: 5,
  },

  itemText: {
    color: "rgba(0,0,0,0.74)",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: '500',
  },

  itemSubText: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: 'bold',
    letterSpacing: '0.15px',
    lineHeight: '24px',
    marginTop: '8px'
  },

  buttonBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginBottom: 20,
  },

  btn: {
    width: 160,
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    // color: '#535D6D',
  },

  footerText: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    marginTop: '30px'
  },

  errMsg: {
    color: "#EB4F4F",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: '500',
    marginBottom: 10,
    textAlign: "center",
  },

  successMsg: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "500",
    letterSpacing: '0.15px',
    textAlign: "center",
    margin: '5px 20px 35px 20px',
    borderBottom: '1px solid #d7dadc',
    paddingBottom: '17px',
    [theme.breakpoints.down('xs')]: {
      margin: '5px 10px 35px 10px',
    },
  },

  successSubTitle: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    textAlign: "center",
    marginBottom: 20,
  },

  anchorLinkStyle: {
    color: '#535D6D',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: '600',
    letterSpacing: '0.45px',
  }

}));

