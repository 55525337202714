import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  snackBarSuccess: {
    '& .MuiAlert-filledSuccess': {
      backgroundColor: '#235A86 !important',
      fontFamily: "Montserrat",
      fontWeight: "500",
      color: '#fff',
      width: "auto",
      maxWidth:'600px',
      [theme.breakpoints.down('sm')]: {
       width: "100%"
      }
    }
  },
  snackBarError: {
    '& .MuiAlert-filledSuccess': {
      background: '#f44336',
      fontFamily: "Montserrat",
      fontWeight: "500",
      color: '#fff'
    }
  },  
}));

export const SnackBar = (props) => {
  const classes = useStyles();
  const { open, handleClose, severity, message } = props;

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        className={severity == 'success' ? classes.snackBarSuccess : classes.snackBarError}
      >
        <Alert onClose={handleClose} className={severity == 'success' ? classes.snackBarSuccess : classes.snackBarError}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
