import React, { useEffect, useState } from 'react';
import { Box, Breadcrumbs, CircularProgress } from "@material-ui/core";
import { useStyles } from './styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getContent } from '../../actions';
import { scrollTop } from '../../helpers';
import './style.css';
import CustomBreadcrum
 from '../Breadcrum';
export const Whatsnew = () => {

  const classes = useStyles();
  const [content, setContent] = useState([])
  const dispatch = useDispatch()

  const getContentData = async () => {
    const res = await dispatch(getContent('PC026'));
    if (res) {
      setContent(res)
    }
  };

  useEffect(() => {
    getContentData();
    scrollTop()
  }, []);

  return (
    <Box className={classes.bg}>
      <Box className={classes.root}>
       
        <CustomBreadcrum backPage="Home" backPageLink="/Dashboard" activePage="What's New?" />

        {/* <div class="container">
          <div class="about-us" style={{width:'331px'}}>What's New?</div>
          <div class="rectangle_1">
            <div class="pics">
              <img src={image1} />
            </div>
            <div class="incider_text">
              <span>A Little About BSI</span>
              <p>BSI was founded as Bank Services, Inc. in Titusville, PA on January 2, 1986. Initially, we serviced motorcycle leases and automobile loans, and we were a licensed repossessor of motor vehicles. Today, BSI Financial Services employs more than 300 people and occupies seven offices in four states.</p>
              <div class="click-here-to-view-o">
                Click here to view our corporate website
            </div>
            </div>
          </div>
          <div class="rectangle_2">

            <div class="incider_text_2">
              <span>Our Mission</span>
              <ul>
                <li>Always do the right thing. Be transparent. Do not conceal mistakes.</li>
                <li>Always improve by solving problems. Look for a better way.</li>
                <li>Deliver on expectations. Your word is your bond.</li>
                <li>Win with humility. We support each other and celebrate as a team.</li>
                <li>Be frugal. Spend the company’s money wisely so we can invest in our future.</li>
              </ul>
            </div>
            <div class="pics">
              <img src={image2} />
            </div>
          </div>
          <div class="rectangle_1 gett" style={{marginTop:'50px'}}>
            <div class="pics">
              <img src={image1_1} />
            </div>
            <div class="incider_text">
              <span>A Little About BSI</span>
              <p>BSI was founded as Bank Services, Inc. in Titusville, PA on January 2, 1986. Initially, we serviced motorcycle leases and automobile loans, and we were a licensed repossessor of motor vehicles. Today, BSI Financial Services employs more than 300 people and occupies seven offices in four states.</p>
              <div class="click-here-to-view-o">
                Click here to view our corporate website
            </div>
            </div>
          </div>
          <div class="rectangle_2">

            <div class="incider_text_2">
              <span>Our Mission</span>
              <ul>
                <li>Always do the right thing. Be transparent. Do not conceal mistakes.</li>
                <li>Always improve by solving problems. Look for a better way.</li>
                <li>Deliver on expectations. Your word is your bond.</li>
                <li>Win with humility. We support each other and celebrate as a team.</li>
                <li>Be frugal. Spend the company’s money wisely so we can invest in our future.</li>
              </ul>
            </div>
            <div class="pics">
              <img src={image1_2} />
            </div>
          </div>
          

        </div> */}
        {content.length !== 0 ?
          < Box className={classes.container} dangerouslySetInnerHTML={{ __html: content[0].contentValue }} />
          :
          <Box className={classes.loaderContainer}>
            <CircularProgress />
          </Box>
        }
      </Box>
    </Box>
  )
}