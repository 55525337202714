import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    borderRadius: '12px',
    padding: '2em 6em 1.4em 7em',
    [theme.breakpoints.down('sm')]: {
      padding: '1em',
    },
  },
}));