import Cookies from 'js-cookie';
import { axiosProtected } from '../config';
import { getAppSource, getUserType } from '../helpers';
import { SELECT_CURRENT_ACCOUNT, SET_PAYMENT_OPTION, SELECT_CURRENT_ACCOUNT_DATA, SET_UPCOMING_TRANS_STATE } from '../types'

export const selectCurrenAccountNum = (accountNum) => async (dispatch) => {
  dispatch({
    type: SELECT_CURRENT_ACCOUNT,
    payload: accountNum
  })
};

export const selectCurrenAccount = (account) => async (dispatch) => {
  dispatch({
    type: SELECT_CURRENT_ACCOUNT_DATA,
    payload: account
  })
};

export const getRecurringACHInfo = async (accountNum) => {
  const data = {
    "accountNumber": accountNum,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": "",
  };

  try {
    const response = await axiosProtected.post('/Payment/GetRecurringACHInfo', data);
    return response.data;
  } catch (e) {
    return null
  }
}


export const deleteRecurringACHPayment = async (accountNum) => {
  const data = {
    "accountNumber": accountNum,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": "",
  };

  try {
    const response = await axiosProtected.post('/Payment/DeleteRecurringACHPayment', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const addRecurringACHPayment = async (data) => {
  try {
    const response = await axiosProtected.post('/Payment/AddRecurringACHPayment', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const getUpcomingSchedulePayment = async (accountNum) => {
  const data = {
    "accountNumber": accountNum,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": "",
  };

  try {
    const response = await axiosProtected.post('/Payment/UpcomingSchedulePayment', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const deleteUpcomingSchedulePay = async (accountNum,draftDate) => {
  const data = {
    "accountNumber": accountNum,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": "",
    "draftDate":draftDate
  };
  try {
    const response = await axiosProtected.post('/Payment/DeleteOneTimePayment', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const setPaymentOption = (option) => async (dispatch) => {
  dispatch({
    type: SET_PAYMENT_OPTION,
    payload: option
  })
}

export const getPaymentFAQContent = async () => {
  const data = {
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": "",
  };
  try {
    const response = await axiosProtected.post('/Payment/GetPaymentFAQ', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const getMLRecurringACHInfo = async (accountNum) => {
  const data = {
    "accountNumber": accountNum,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": "",
  };

  try {
    const response = await axiosProtected.post('/Payment/GetMLRecurringACHInfo', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const addMLRecurringACHPayment = async (list) => {
  const data = {
    "lstMLRecurringPaymentModel": list,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": "",
  };

  try {
    const response = await axiosProtected.post('/Payment/AddMLRecurringACHPayment', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const getFinancialHardShipTiles = async (list) => {
  const data = {
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": "",
  };

  try {
    const response = await axiosProtected.post('/FinancialHardship/GetFinancialHardShipTiles', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const getFinancialInformation = async (accountNum) => {
  const data = {
    "accountNumber": accountNum,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": "",
  };

  try {
    const response = await axiosProtected.post('/FinancialHardship/GetFinancialInformation', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const updateFinancialInformation = async (data) => {
  try {
    const response = await axiosProtected.post('/FinancialHardship/UpdateFinancialInformation', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const upcomingTransState = (payload) => async (dispatch) => {
  dispatch({
    type: SET_UPCOMING_TRANS_STATE,
    payload: payload
  });
}