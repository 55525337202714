import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({

  mainHeading: {
    padding: '0.2em 3.5em 0.3em',
    fontWeight: '700',
    fontSize: '2.5em',
    color: '#727272',
    [theme.breakpoints.down('sm')]: {
      padding: '1.5em 1.2em 0 2em',
      fontSize: '2em',
      fontFamily: 'Montserrat',
    },
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '2em 168px 6em 168px',
    fontFamily: 'Montserrat',
    width: '100%',
    maxWidth: '122em',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 30px 0',
    },
  },


  pageHeading: {
    color: '#727272',
    fontFamily: 'Montserrat',
    fontSize: '24px',
    fontWeight: 'bold',
    letterSpacing: '0.63px',
    lineHeight: '29px',
    marginBottom: 38,
    marginTop: 26,
  },

  subTitle: {
    color: '#363636',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: '600',
    letterSpacing: '0.47px',
    lineHeight: '22px',
    margin: '0 0 23px 0'
  },

  topFormText: {
    margin: '20px 0'
  },

  bobyText: {
    color: '#535D6D',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: '0.36px',
    lineHeight: '19px',
  },

  pdfHeading: {
    color: '#535D6D',
    marginBottom: '15px',
    fontWeight: '600'
  },

  textField: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: '600'
  },

  formText: {
    color: '#535D6D',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: '600',
    letterSpacing: '0.4px',
    lineHeight: '22px'
  },

  smallText: {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: '500',
    letterSpacing: '0.32px',
    lineHeight: '15px',
    textAlign: 'center',
  },

  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start'
  },

  pdfFile: {
    marginLeft: '15px'
  },

  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center'
  },

  topPDFText: {
    margin: '35px 0 35px 0',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0 35px 0',
    },
  },

  pdfBox: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '10px',
    backgroundColor: '#F8F8F8',
    width: '100%'
  },
  pdfContainer: {
    padding: '18px 40px 18px 65px',
    [theme.breakpoints.down('sm')]: {
      padding: '18px 15px 18px 15px',
    },
  },

  cancelAccButton: {
    fontFamily: 'Montserrat',
    letterSpacing: '0.36px',
    fontWeight: '600',
    marginRight: '25px',
    // width: '180px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '28px',
      marginRight: 0,
    },
  },
  addAccButton: {
    width: '180px',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    textTransform: 'uppercase',
    marginTop: '70px'
  },

  button: {
    fontFamily: 'Montserrat',
    fontWeight: '600',
    textTransform: 'uppercase',
    marginTop: '70px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    outline: 'none',
    padding: '10px 20px',
    fontSize: '16px',
    borderRadius: '4px',
    letterSpacing: '0.53px',
    textAlign: 'right',
    textDecoration: 'none',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      padding: '10px 10px',
    },

  },

  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  validationErr: {
    fontSize: '12px',
    fontWeight: '500',
    top: 3,
    left: 0,
    fontFamily: 'Montserrat',
    color: "red",
  },

  downLoadIcon: {
    fill: theme.palette.primary.contrastText,
    fontSize: '20px',
    marginLeft: '10px'
  }

}));