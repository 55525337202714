import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 12,
    position: 'relative',
    backgroundColor: "#F5F6F7",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    padding: '2.2em 2.8em',
    [theme.breakpoints.down('xs')]: {
      padding: '1.6em',
    },
  },

  noDataFound: {
    width: '100%',
    // minWidth: 400,
    height: '100%',
    // minHeight: 400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxWidth: '90%',
    maxHeight: "100%",
    backgroundColor: 'transparent'
  },

  header: {
    width: '100%',

  },

  iconStyle: {
    position: 'absolute',
    top: 10,
    right: 10,
  },

  title: {
    color: "#131414",
    fontFamily: "Montserrat",
    fontSize: '2.4em',
    fontWeight: "600",
    textAlign: "center",
    marginBottom: 22,
    marginRight: 45,
  },

  subTitle: {
    color: "#161616",
    fontFamily: "Montserrat",
    fontSize: '1.6em',

    textAlign: "center",
    marginBottom: 22,
  },

  cardBody: {
    width: '100%',
  },

  btn: {
    width: 189,
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "600",
    display: 'block',
    margin: '2em auto 1.5em',
  },
}));


export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#535d6d",
    color: theme.palette.common.white,
    borderRight: 'solid 1px #fff',
    fontFamily: "Montserrat",
    fontSize: 14,
  },
  body: {
    fontSize: 14,
    fontFamily: "Montserrat",
    color: '#585757'
  },
}))(TableCell);



export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
