 //Generic toaster implementation
import { SHOW_TOASTER } from '../types'

export default (state = false, action) => {
  switch (action.type) {
    case SHOW_TOASTER:
      return action.payload
    default:
      return state;
  }
}