import Cookies from 'js-cookie';
import { FETCH_MULTI_LOAN, FETCH_LOAN_INFO } from '../types';
import { getAppSource, getPublicIp, getUserType } from '../helpers'
import { axiosProtected } from '../config';

export const multiLoan = () => async dispatch => {
  const data = {
    "accountNumber": "",
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": await getPublicIp(),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }
  try {
    const multiLoanData = await axiosProtected.post('/Dashboard/GetMultiPropertyLoans', data);
    dispatch({
      type: FETCH_MULTI_LOAN,
      payload: multiLoanData.data
    });
    return multiLoanData.data;
  } catch (e) {
    return null;
  }
}


export const confirmLoanStatus = async () => {
  const data = {
    "accountNumber": "",
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": await getPublicIp(),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }
  try {
    const details = await axiosProtected.post('/Dashboard/UpdateMultiPropertyLoans', data);
    return details.data
  } catch (e) {
    return null
  }
}

export const custLoanInfo = (data) => async (dispatch) => {
  try {
    const userLoanData = await axiosProtected.post('/Customer/GetLoanInformation', data);
    if (userLoanData && userLoanData.data.lstResponseStatus && userLoanData.data.lstResponseStatus[0].statusCode === '0') {
      dispatch({
        type: FETCH_LOAN_INFO,
        payload: userLoanData.data
      });
      return userLoanData.data;
    }
  } catch (e) {
    return null;
  }
}