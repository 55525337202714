import React, { useState, useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';

import { Box, Grid, Typography, Button } from '@material-ui/core';
import home from '../../../assets/LoanDetails/home.svg';
import mail from '../../../assets/LoanDetails/mail.svg';
import phone from '../../../assets/LoanDetails/phone.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from './styles';
import { getBorrowerLabel } from '../../../helpers';

export default function SecAccordion(props) {
  const classes = useStyles();
  const [item, setItem] = useState([]);

  const { secondaryBorrowerName, secondaryBorrowerAddress1, secondaryBorrowerAddress2, secondaryBorrowerCity,
    secondaryBorrowerState, secondaryBorrowerZip, secondaryEmailAddress,
    secondaryCellPhoneNumber, secondaryWorkPhoneNumber, secondaryWorkExtension } = props;

  useEffect(() => {
    setItem({
      ...item,
      secondaryBorrowerName, secondaryBorrowerAddress1, secondaryBorrowerAddress2, secondaryBorrowerCity,
      secondaryBorrowerState, secondaryBorrowerZip, secondaryEmailAddress,
      secondaryCellPhoneNumber, secondaryWorkPhoneNumber, secondaryWorkExtension
    })
  }, [])

  return (
    <div className={classes.root}>
      <Accordion elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
          className={classes.accordionSum}>
          <div className={classes.column} style={{ flexBasis: '100%' }}>
            <Typography className={classes.heading} fontWeight="fontWeightBold">
              Secondary {getBorrowerLabel()} {secondaryBorrowerName}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Grid container >
            <Grid item xs={12} sm={6}>
              <Box display="flex" className={classes.custAddress}>
                <img src={home} alt="phone icon" className={classes.loanInfoIcon} />
                <Box>
                  <Typography variant='body1' className={`${classes.bodyFont} ${classes.wordBreak}`}>
                    {secondaryBorrowerAddress1 ? secondaryBorrowerAddress1 : ''}
                  </Typography>
                  <Typography variant='body1' className={`${classes.bodyFont} ${classes.wordBreak}`}>
                    {secondaryBorrowerAddress2 ? secondaryBorrowerAddress2 : ''}
                  </Typography>
                  <Typography variant='body1' className={classes.bodyFont}>
                    {secondaryBorrowerCity ? secondaryBorrowerCity + ', ' : ''}
                    {secondaryBorrowerState ? secondaryBorrowerState + ', ' : ''}
                    {secondaryBorrowerZip ? secondaryBorrowerZip : ''}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid className={classes.phoneContainer} container spacing={0} alignItems="flex-end" >
                <Box>
                  <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.loanCareNo}>
                    {secondaryCellPhoneNumber ? <>
                      <img src={phone} alt="phone icon" className={classes.loanInfoIcon} />
                      <Typography variant='body1' className={`${classes.bodyFont} ${classes.careNo}`}>
                        {secondaryCellPhoneNumber}
                      </Typography></> : null}
                  </Grid>
                  <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.noWrap}>
                    {secondaryEmailAddress ? <>
                      <img src={mail} alt="mail icon" className={classes.loanInfoIcon} />
                      <Typography variant='body1' className={classes.bodyFont}>
                        {secondaryEmailAddress}
                      </Typography></> : null}
                  </Grid>
                </Box>
                <Button
                  color="primary"
                  className={classes.btn}
                  type='submit' size="large" variant="contained"
                  onClick={() => props.Click(item, 'secondaryBorower')}>
                  Edit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
