import React, { useEffect, useState, Fragment } from 'react';

import { Box, CssBaseline, CircularProgress, Grid, Typography } from '@material-ui/core';
//import { useStyles } from './styles';
import { useStyles } from './styles';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

export const NextPrevButton = (props) => {

    const {activeStepIndex, subStep} = props

    const classes = useStyles();    

    const handleBack = () =>props.handlePrevCallBack()

    const handleNext = () => props.handleNextCallback()
   
    return (<Box pt={2} className={classes.nextPrevContainer}>
        {/* {activeStepIndex===0 && subStep===0 ?(<Typography className={`${classes.nextPrevBtn} ${classes.disabled} `} ><ArrowBackIcon />Back</Typography>):
        (<Typography className={`${classes.nextPrevBtn} ${classes.link}`} onClick={() => handleBack()}><ArrowBackIcon />Back</Typography>)} */}
        {activeStepIndex===0 && subStep===0 ?null:(<Typography className={`${classes.nextPrevBtn} ${classes.link}`} onClick={() => handleBack()}><ArrowBackIcon />Back</Typography>)}

        {/* {activeStepIndex===8?(<Typography className={`${classes.nextPrevBtn} ${classes.disabled}`}>Next <ArrowForwardIcon /></Typography>):
        (<Typography className={`${classes.nextPrevBtn} ${classes.link}`} onClick={() => handleNext()}>Next <ArrowForwardIcon /></Typography>)} */}
        {activeStepIndex===8?null:(<Typography className={`${classes.nextPrevBtn} ${classes.link}`} onClick={() => handleNext()}>Next <ArrowForwardIcon /></Typography>)}
    </Box>)
}