import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { getHelpContent, getFaqContent } from '../../../actions';
import { useParams, useLocation } from 'react-router-dom';

import { useSelector, useDispatch } from "react-redux";
import { FaqRender } from '../../../components';
import Cookies from 'js-cookie';
import { scrollTop } from '../../../helpers';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  rootBg: {
    margin: '0px 10px 10px 20px'
  },
  taxInfo: {
    lineHeight: '19px',
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '22px',
    color: '#626262',
    padding: '30px 0px 0px 25px'
  },
  howItWork: {
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '17px',
    color: '#444444',
    fontFamily: 'Inter',
    paddingBottom: '20px',
    padding: '20px 0px 0px 25px'
  },
  howItWorkDesc: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '17px',
    color: '#444444',
    fontFamily: 'Inter',
    width: '1039px',
    padding:  '10px 0px 0px 25px'
  },
}));

export const Faq = (props) => {

  const classes = useStyles();
  const fetchedHelpDesContent = useSelector(state => state?.faqReducer);
  let storedHelpDesContent = useSelector(state => state?.htmlContent);
 
  const privateToken = Cookies.get("privateToken");
  const publicToken = Cookies.get("publicToken");

  let pageName = props.contentKey
  const dispatch = useDispatch();
  const location = useLocation();
  
  const [hardshipFaq, setHardshipFaq] = useState('');

  if (pageName === 'PropertyTaxes') {
   
    storedHelpDesContent = null;
    if (fetchedHelpDesContent === null) {
        getData()
    }
  }

  const getData = async () => {
    await dispatch(getFaqContent('PC029'));
  }

  const updateFaq = async () => {
    // faqHeadingAccordion();
    faqListAccordion();
  }

  useEffect(() => {
      updateFaq()
  }, [fetchedHelpDesContent, pageName])

  useEffect(() => {
    setTimeout(() => {
     
        getData();
        updateFaq()
      
    }, 1000);
  }, []);

  useEffect(() => {
    const { state = {} } = location;
    if (state.hasOwnProperty('tilesName')) {
      setHardshipFaq(state.tilesName);
    } else {
      setHardshipFaq('');
    }
  }, []);

  let innHtml = '';
  if (storedHelpDesContent != null) {
    innHtml = storedHelpDesContent;
  } else if (storedHelpDesContent == null && fetchedHelpDesContent != null) {
    if (fetchedHelpDesContent?.lstPageContentDetails && fetchedHelpDesContent?.lstPageContentDetails?.length) {
      const erHtml = fetchedHelpDesContent?.lstPageContentDetails.filter(item => item.contentKey.toLowerCase() == pageName.toLowerCase());
      innHtml = erHtml[0]?.contentValue;
     
    }
  }

  const faqListAccordion = () => {
    let acc = document.getElementsByClassName('accordion-set');
    let accInnerContent = Array.from(document.querySelectorAll('.inner-content'));
    let allFaIcon = Array.from(document.querySelectorAll('.faq-arrow'));
    let i;

    for (i = 0; i < acc.length; i++) {
      let anchor = acc[i].querySelector('a');
      let panel = acc[i].querySelector('.inner-content');
      let faIcon = acc[i].querySelector('.faq-arrow');
      anchor.addEventListener("click", function (e) {
        e.preventDefault();
        if (panel.style.display === "block") {
          panel.style.display = "none";
          if (faIcon !== null) {
            faIcon.classList.add("faq-arrow-down");
            faIcon.classList.remove("faq-arrow-up");
          }

        } else {
          accInnerContent.forEach(node => {
            node.style.display = "none";
          });
          allFaIcon.forEach(node => {
            node.classList.add("faq-arrow-down");
            node.classList.remove("faq-arrow-up");
          });
          panel.style.display = "block";
          if (faIcon !== null) {
            faIcon.classList.remove("faq-arrow-down");
            faIcon.classList.add("faq-arrow-up");
          }
        }
      });
    }
  }

  const openFaqHeadingAccordion = async () => {
    
    let acc = await document.getElementsByClassName('qns-container');
    let panel = await acc[2].querySelector('.accordion-Child');
    let faIcon = acc[2].querySelector('.header-arrow');

    if (hardshipFaq === 'CommonlyAskedQuestions') {
      panel.style.display = "block";
      if (faIcon !== null) {
        faIcon.classList.remove("faq-arrow-down");
        faIcon.classList.add("faq-arrow-up");
      }
      setTimeout(function () {
        window.scrollTo({ top: 290, left: 0, behavior: 'smooth' });
      }, 1000);

    } else {
      panel.style.display = "none";
      if (faIcon !== null) {
        faIcon.classList.add("faq-arrow-down");
        faIcon.classList.remove("faq-arrow-up");
      }
      scrollTop();
    }
  }

  const faqHeadingAccordion = () => {
    let acc = document.getElementsByClassName('qns-container');
    let accInnerContent = Array.from(document.querySelectorAll('.accordion-Child'));
    let allFaIcon = Array.from(document.querySelectorAll('.header-arrow'));
    let allQnAFaIcon = Array.from(document.querySelectorAll('.faq-arrow'));
    let qNaInnerContent = Array.from(document.querySelectorAll('.inner-content'));
    let i;
    for (i = 0; i < acc.length; i++) {
      let anchor = acc[i].querySelector('a');
      let panel = acc[i].querySelector('.accordion-Child');
      let faIcon = acc[i].querySelector('.header-arrow');

    }

    hardshipFaq === 'CommonlyAskedQuestions' && openFaqHeadingAccordion();
  }


  if (innHtml) {
    return (
      <React.Fragment>
        <Box className={classes.rootBg}>
          <FaqRender innerHtml={innHtml} activePage={'FAQs'} />
        </Box>
      </React.Fragment>
    )
  }
}
