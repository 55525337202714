import { STORE_ACC_FOR_EDIT, CLEAR_STORE } from '../types'
export default (state = null, action) => {
  switch (action.type) {
    case STORE_ACC_FOR_EDIT:
      return action.payload
    case CLEAR_STORE:
      return null
    default:
      return state;
  }
}