import React from 'react';
import { useStyles } from './styles';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';

export const UploadFileLengthValidationAlert = (props) => {

  const { open, handleFileLengthAlertClose, file} = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle }}} onClose={handleFileLengthAlertClose} open={open}>
    <Box className={classes.root}>
     <IconButton onClick={handleFileLengthAlertClose} className={classes.closeIco}>
       <CancelIcon />
     </IconButton>
     <Box className={classes.cardBody}>
       <Typography className={classes.statusTitle}>File Upload Error</Typography>
     </Box>
     
     <Box className={classes.cardBodyContainer}>
       <Box className={classes.statusContainer}>
        <Box className={classes.cardContainer}>
         <Box className={classes.imageContainer}>
           <InfoIcon className={classes.infoIcon} />
           <span className={classes.request}>The uploaded file name is too long.</span>
         </Box> 
          <Typography className={classes.paragraph}>Please change the name to less than <span className={classes.character}>100 characters.</span></Typography> 
         </Box> 
       </Box>
     </Box>

     <Box className={classes.cardFooter}>
       <Button
         variant='contained'
         color='primary'
         className={classes.btn}
         onClick={handleFileLengthAlertClose}
         >Ok</Button>
     </Box>
   </Box> 
 </Dialog>
  )
}
