import React from 'react';
import { Box, Button, Typography, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from './styles';
import moment from 'moment';
import { decrypt } from '../../helpers/encrypt';
import { maskAccountNo, isAdmin } from '../../helpers';
import Cookies from 'js-cookie';

export const ReviewPayment = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {paymentData, bankData, handleCloseReviewPopup, handleContinueReviewPopup, totalPayment} = props;

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  
  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle }}} open={true}>
     
      <Box className={classes.root}>
          
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>Review & Make Payment</Typography>
        </Box>
        
        <Box className={classes.cardBody2}>
          <Box className={classes.statusContainer}>
           <Box className={classes.cardContainer}>
            <Box className={classes.imageContainer}>
              <Typography className={classes.paragraph}>
              <Grid container>
                <Grid item md={6} xs={6} lg={6}  style={{textAlign :"end"}}>
                  <span className={classes.detail}>Payment Date:</span> </Grid>
                  <Grid item md={6} xs={6} lg={6}   style={{textAlign :"start"}}
>
                  <span className={classes.paymentDetail}>{moment(paymentData.paymentDate).format("ll")}</span></Grid></Grid></Typography>
             {bankData?.bankName && <Typography className={classes.paragraph}>
             <Grid container>
                <Grid item md={6} xs={6} lg={6} style={{textAlign :"end"}}> <span className={classes.detail}>Bank Account:</span> </Grid>
                <Grid item md={6} xs={6} lg={6}   style={{textAlign :"start"}}><span className={classes.paymentDetail}>{bankData?.nickName}, {maskAccountNo(decrypt(bankData?.bankAccountNumber))}</span></Grid></Grid></Typography>}
              </Box>
            </Box> 

            <Box className={classes.cardContainer}>
              <Box className={classes.container}>
              
              <Typography className={classes.paragraph}> 
               <Grid container>
                <Grid item md={6} xs={6} lg={6} style={{textAlign :"end"}}>
                  <span className={classes.detail}> {paymentData.paymentType === 'isCustomAmount' && 'Custom Amount'}
                      {paymentData.paymentType === 'Regular' && 'Regular Monthly Payment'}
                      {paymentData.paymentType === 'Outstanding' && 'Total Outstanding Balance'}
                      {paymentData.paymentType === 'onlyForbearance' && 'Only Forbearance Fee'}:</span></Grid> 
                      <Grid item md={6} xs={6} lg={6} style={{textAlign :"start"}}>
                      <span className={classes.paymentDetail}> {paymentData.paymentTypeValue ? "$" + numberWithCommas(parseFloat(paymentData.paymentTypeValue).toFixed(2)) : '$ 0.00'}</span>
                      </Grid>
                    </Grid> 
                </Typography>

              {(paymentData.principalAmount !== 0) && <Typography className={classes.paragraph}>
              <Grid container>
                <Grid item md={6} xs={6} lg={6} style={{textAlign :"end"}}><span className={classes.detail}>Additional Principal Amount: </span></Grid>
                <Grid item md={6} xs={6} lg={6} style={{textAlign :"start"}}><span className={classes.paymentDetail}> ${numberWithCommas(Number(paymentData.principalAmount).toFixed(2))}</span></Grid></Grid></Typography>}
              {(paymentData.escrowAmount !== 0) && <Typography className={classes.paragraph}>
              <Grid container>
                <Grid item md={6} xs={6} lg={6} style={{textAlign :"end"}}><span className={classes.detail}>Additional Escrow Amount:</span> </Grid>
                <Grid item md={6} xs={6} lg={6} style={{textAlign :"start"}}><span className={classes.paymentDetail}>${numberWithCommas(Number(paymentData.escrowAmount).toFixed(2))}</span></Grid></Grid></Typography>}
              {/* {(paymentData.processingFee !== 0) && <Typography className={classes.paragraph}>
              <Grid container>
                <Grid item md={6} xs={6} lg={6} style={{textAlign :"end"}}><span className={classes.detail}>Processing Fee:</span></Grid>
                <Grid item md={6} xs={6} lg={6} style={{textAlign :"start"}}><span className={classes.paymentDetail}>${numberWithCommas(paymentData.processingFee.toFixed(2))}</span></Grid>
                </Grid>
                </Typography>} */}
              {(paymentData.forbearanceApplicationFee !== 0 ) && <Typography className={classes.paragraph}>
              <Grid container>
                <Grid item md={6} xs={6} lg={6} style={{textAlign :"end"}}><span className={classes.detail}>Forbearance Application Fee:</span> </Grid>
                <Grid item md={6} xs={6} lg={6} style={{textAlign :"start"}}><span className={classes.paymentDetail}>${numberWithCommas(paymentData.forbearanceApplicationFee.toFixed(2))}</span></Grid></Grid></Typography>}
              </Box>
            </Box> 

            <Box className={classes.totalCardContainer}>
              <Box className={classes.container}>
               <Typography className={classes.paragraph}>
               <Grid container>
                <Grid item md={6} xs={6} lg={6} style={{textAlign :"end"}}><span className={classes.paymentTotal}>Payment Total: </span> </Grid>
                <Grid item md={6} xs={6} lg={6} style={{textAlign :"start"}}><span className={classes.paymentTotalDetails}>${numberWithCommas(totalPayment.toFixed(2))}</span></Grid></Grid>
                </Typography>
              </Box>
            </Box> 
          </Box>
        </Box>
    
        <Box className={classes.cardFooter}>
         <Box className={classes.footerItem}>
            <Button
              variant='outlined'
              className={classes.btn}
              onClick={handleCloseReviewPopup}
            > EDIT</Button>
          </Box>
          <Box className={classes.footerItem}>
              <Button
                variant='contained'
                color='primary'
                className={classes.btn}
                disabled={Cookies.get('adminToken') || isAdmin()}
                onClick={handleContinueReviewPopup}
              >MAKE PAYMENT</Button>
          </Box> 
        </Box>
       
      </Box> 
    </Dialog>
  )
}

