import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import { Box, Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';

export const PayOffVomPifPopup = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {popupText=null} = props;

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={props.handleCancelIcon} open={props.open}>
     
      <Box className={classes.root}>
          <IconButton onClick={props.handleCancelIcon} className={classes.closeIco}>
            <CancelIcon />
          </IconButton>
        <Box className={classes.cardBody}>
            {popupText &&  <Typography className={classes.statusTitle}>{popupText?.statusTitle}</Typography>}
        </Box>
        
        <Box className={classes.cardBody2}>
          <Box className={classes.statusContainer}>
           <Box className={classes.cardContainer}>
             {popupText && <p className={classes.paragraph}>{popupText?.statusBody}</p> } 
            </Box> 
          </Box>
        </Box>

        <Box className={classes.cardFooter}>
            <Button
                variant='contained'
                color='primary'
                className={classes.btn}
                onClick={props.handleSubmit}
              > {popupText?.handleSubmitText}</Button>
        </Box> 
       
      </Box> 
    </Dialog>
  )
}
