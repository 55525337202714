import { makeStyles } from '@material-ui/core/styles';
import signinIcon from '../../assets/SignIn/SignIn.svg';

export const useStyles = makeStyles((theme) => ({
  pageBackground: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#ccc',
    backgroundImage: `url(${signinIcon})`,
    backgroundPosition: "bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'contain',
  },
  mainHeading: {
    padding: '0.2em 3.5em 0.3em',
    fontWeight: '700',
    fontSize: '2.5em',
    color: '#727272',
    [theme.breakpoints.down('sm')]: {
      padding: '1.5em 1.2em 0 2em',
      fontSize: '2em',
      fontFamily: 'Montserrat',
    },
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    fontFamily: 'Montserrat',
    '& > *': {
      margin: theme.spacing(1),
    },
    width: '100%',
    padding: '2.4em 0 1em 0',
    maxWidth: '120em',
    margin: '0 auto',
    '&:last-child': {
      paddingBottom: '9em'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '2.5em 2em',
    },
  },
  pageHeading: {
    color: '#535D6D',
    fontFamily: 'Montserrat',
    fontSize: '24px',
    fontWeight: '600',
    letterSpacing: '0.18px',
    lineHeight: '24px',
    marginTop: '3px',
    marginBottom: '18px',
  },
  loanOwnerInfo: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginTop: 10
    },
  },
  loanOwner: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '24px;',
    fontWeight: '500',
    letterSpacing: '0.18px',
    lineHeight: '24px',
    [theme.breakpoints.down('xs')]: {
      margin: '16px 0 20px 0',
    },
  },
  custAddress: {
    padding: '0 5% 2% 11%',
    marginTop: '4px',
    alignItems: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      padding: '0',
    }
  },
  mailWidth: {
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  bodyFont: {
    color: 'rgba(0,0,0,0.74)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    letterSpacing: '0.5px',
    lineHeight: '20px',
    paddingTop: '4px'
  },
  textFont: {
    color: '#202020',
    fontSize: '16px',
    marginTop: '6px',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    lineHeight: '19px',
    letterSpacing: '0.36px'
  },
  phoneContainer: {
    marginBottom: '7px',
    justifyContent: "space-between",
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'intial'
    }
  },
  btn: {
    color: theme.palette.primary.contrastText,
    fontFamily: 'Montserrat',
    fontSize: '16px',
    height: '40px',
    width: '135px',
    fontWeight: '600',
    [theme.breakpoints.down('md')]: {
      marginTop: '20px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '30px auto'
    },

  },
  careNo: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: '20px',
    },
  },
  loanCareNo: {
    marginBottom: '10px',
  },
  accordionData: {
    padding: '20px 0'
  },

  infoIcon: {
    paddingRight: "10px",
    fontSize: '35px',
    color: theme.palette.secondary.contrastText,
  },
  infoText: {
    color: theme.palette.secondary.contrastText,
    fontFamily: "Montserrat",
    fontSize: "16px",
    letterSpacing: "0.36px",
    lineHeight: "24px",
  },

  loanInfoIcon: {
    color: theme.palette.secondary.contrastText,
    fontFamily: 'Montserrat',
    fontSize: '28px',
    marginRight: '30px'
  },

  loanInfoTitleIcon: {
    fontFamily: 'Montserrat',
    fontSize: '50px',
    marginRight: '14px'
  },

  infoStrip: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 14px 7px, rgba(0, 0, 0, 0.2) 0px 1px 3px 0px',
    margin: '8px',
    width: '100%',
    padding: '3px 6em 3px 7em',
    borderRadius: '12px',
    [theme.breakpoints.up('sm')]: {
      height: '42px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '3px 2em 3px 2em',
    },
  },

  wordBreak: {
    wordBreak: 'break-all',
  },

  linkStyle: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    fontWeight: "600",
    marginTop: 5,
  },

  btn: {
    width: 188,
    fontFamily: "Montserrat",
    fontSize: '1.5em',
    fontWeight: "600",
  },

  closeIcon: {
    position: 'absolute',
    right: 10,
  },

  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },
 
  inputStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600"
  },

  labelStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "400",
  },

  form: {
    marginLeft: 68,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 25
    },
  },

  title: {
    marginBottom: 10,
    marginLeft:74,
    fontSize: 18,
    fontFamily: 'Montserrat',
    fontWeight: 600,
  },

  cardFooter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: "#fff",
    padding: '20px 5.5em 0',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    },
  },

  footerText: {
    color: "#0C0B0B",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    fontWeight: "400",
  },

  linkStyle: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    fontWeight: "600",
    marginTop: 5,
  },

  footerItem: {
    marginBottom: 33,
    maxWidth: 175,
  },

  invalid: {
    marginTop: 8,
    color: 'red',
    fontSize: 13,
    marginLeft: 8
  },

  errorMsg: {
    fontSize: '1.4em',
    fontWeight: '500',
    textAlign: 'center',
    marginBottom: 10,
    fontFamily: 'Montserrat',
    color: "red",
  }

}));