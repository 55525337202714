import signinIcon from '../../assets/SignIn/SignIn.svg';
import {
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#ccc',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${signinIcon})`,
    backgroundPosition: "bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'contain',
  },

  formContainer: {
    width: '53%',
    minWidth: "33.3em",
    maxWidth: '58.8em',
    padding: '3.9em 4.9em',
    backgroundColor: '#fff',
    borderRadius: 12,
    marginTop: '6.6em',
    marginBottom: '6.6em',
    boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
      padding: '20px',
      margin: '50px 15px'
    },
  },

  title: {
    fontSize: '3.6em',
    fontWeight: '600',
    lineHeight: 1,
    textAlign: 'center',
    marginBottom: 33,
    marginTop: 0,
    fontFamily: 'Montserrat',
    color: "#535D6D",
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.4em',
    },
  },

  inputContainer: {
    marginBottom: '1.9em',
  },

  inputStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600"
  },

  labelStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600",
    [theme.breakpoints.down('sm')]: {
      fontSize: 11
    },
  },

  signInBtn: {
    fontFamily: "Montserrat",
    marginBottom: 20,
    fontSize: 16,
    fontWeight: '600',
  },

  linkStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    fontWeight: "600",
    letterSpacing: "0.47px",
    display: 'block',
    textAlign: 'right',
    display: 'inline-block',
    paddingTop: 5,
  },

  orContainer: {
    width: '100%',
    borderTop: 'solid 1px #979797',
    position: 'relative',
    marginTop: 10,
  },

  orWraper: {
    width: 30,
    height: 30,
    position: 'absolute',
    top: -16,
    left: '45%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  orText: {
    color: "#727272",
    fontFamily: "Montserrat",
    fontSize: "1.4em",
    fontWeight: "500",
    margin: 0,
  },

  otherLinks: {
    marginTop: 20,
  },

  otherLinksText: {
    color: "#A0A0A0",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    fontWeight: "500",
    textAlign: 'center',
    marginTop: 0,
    marginBottom: 20,
  },

  otherLinkStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: '1em',
    fontWeight: "600",
    letterSpacing: "0.47px",
    textTransform: 'uppercase',
  },

  errorMsg: {
    fontSize: '1.4em',
    fontWeight: '500',
    textAlign: 'center',
    marginBottom: 20,
    fontFamily: 'Montserrat',
    color: "red",
  },

  successMsg: {
    color: "#198100",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "600",
    letterSpacing: "0.53px",
    lineHeight: "18px",
    marginBottom: 20,
    textAlign: 'center'
  },

  resendLinkLoader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '5px',
  },

  usernameBtn: {
    fontFamily: "Montserrat",
    marginBottom: 20,
    marginTop: 20,
    fontSize: 16,
    fontWeight: '600',
    color: '#535D6D !important',
    border: 'solid 2px',
    borderColor: theme.palette.primary.main,
    boxShadow: "0px 4px 8px 0 rgba(0,0,0,0.14)"
  },

  otpBtn: {
    fontFamily: "Montserrat",
    marginBottom: 10,
    marginTop: 10,
    fontSize: 16,
    fontWeight: '600',
  },

  note: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: "600",
    letterSpacing: "0.47px",
    marginBottom: 40,
    textAlign: 'center'
  },

  timer: {
    color: '#7DB1CC'
  },

  resendCode: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    fontWeight: "600",
    letterSpacing: "0.47px",
    display: 'block',
    textAlign: 'right',
    display: 'inline-block',
    paddingTop: 5,
    textDecoration: 'underline',
    cursor: 'pointer'
  },

  disableResend: {
    color: '#B4B4B4',
    cursor: "none",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    fontWeight: "600",
    letterSpacing: "0.47px",
    display: 'block',
    textAlign: 'right',
    display: 'inline-block',
    paddingTop: 5,
    textDecoration: 'underline'
  },

  invalidCode: {
    fontSize: '1.4em',
    fontWeight: '600',
    textAlign: 'center',
    marginBottom: 20,
    fontFamily: 'Montserrat',
    color: "#E02020",
    letterSpacing: "0.47px",
    display: 'block',
    textAlign: 'right',
    display: 'inline-block',
    paddingTop: 5,
  },

  signinLink: {
    color: '#007FFF',
    cursor: "pointer"
  }

}));
