import React, { useState, useEffect } from 'react';
import { useStyles } from './styles';
import { Box, Button, ButtonGroup, Menu, MenuItem, AppBar, Toolbar } from '@material-ui/core';
import myloanweb from '../../../../assets/Admin/myloanweb.png';
import { Link, useHistory } from 'react-router-dom';
import { signOutAdmin } from '../../../../helpers/adminHelpers';
import Cookies from 'js-cookie';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { deleteAccessTokenIDAdmin } from '../../../../actions';
import { decrypt } from '../../../../helpers/encrypt';
import { LogoutPopup } from '../../../LogoutPopup';

export const AdminHeader = () => {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLogedIn, setIsLogedIn] = useState(false);
  const history = useHistory();
  const roleID = Number(decrypt(Cookies.get('roleID')))

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (Cookies.get('adminToken')) {
      setIsLogedIn(true);
    } else {
      setIsLogedIn(false);
    }
  }, [isLogedIn]);

  const handleSignOut = () => {
    setIsLogedIn(false);
    deleteAccessTokenIDAdmin();
    signOutAdmin(() => history.push('/'))
  }


  return (
    <AppBar position='static' className={classes.root}>
      <Toolbar className={classes.root}>
        <Box className={classes.wrapper}>
          <Box className={classes.logoContainer}>
            <img src={myloanweb} alt="logo" />
          </Box>
          <Box className={classes.navContainer}>
            <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
              {roleID && (roleID === 1 || roleID === 3) && <Button component={Link} to='/Admindashboard'>
                Home
              </Button>}
              {roleID && (roleID === 1 || roleID === 3) && <Button component={Link} to='/Adminuseractivitylog'>
                User activity log
              </Button>}
              {roleID && roleID === 1 && <Button component={Link} to='/Admincompanydetails'>
                Company Details
              </Button>}
              {roleID && (roleID === 1 || roleID === 2) && <Button component={Link} to='/AdminUserDetails'>Admin Details</Button>}
              {roleID && roleID === 1 && <Button aria-controls="support-menu" ria-haspopup="true" onClick={handleClick} endIcon={<ArrowDropDownIcon />}>
                Support
              </Button>}
            </ButtonGroup>
            <Menu
              style={{ borderBottomRightRadius: 0 }}
              getContentAnchorEl={null}
              id="support-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose} component={Link} to='/Admininvestorcode'>Investor Code Mapping</MenuItem>
              <MenuItem onClick={handleClose} component={Link} to='/UpdatePageContent'>Update Page Content</MenuItem>
              <MenuItem onClick={handleClose} component={Link} to='/Adminmaintainence'>Maintainence Message</MenuItem>
              <MenuItem onClick={handleClose} component={Link} to='/AlertMessage'>Emergency Alert</MenuItem>
              <MenuItem onClick={handleClose} component={Link} to='/Admindeleteuser'>Delete User</MenuItem>
              <MenuItem onClick={handleClose} component={Link} to='/NotificationPopup'>Borrower Notification Setup</MenuItem>
              <MenuItem onClick={handleClose} component={Link} to='/AdminNotification'>Manage Notification</MenuItem>
            </Menu>
            <ButtonGroup variant={'contained'} color="primary" aria-label="contained primary button group">
              <Button style={{ borderLeft: "1px solid rgb(172, 123, 64)", borderBottomLeftRadius: 0 }} component={Link} to='/Adminchangepassword'>
                Change Password
             </Button>
              <Button onClick={() => handleSignOut()}>
                {isLogedIn ? 'Sign Out' : 'Sign In'}
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
        {isLogedIn &&  <LogoutPopup isAdmin={true}/>}
      </Toolbar>
    </AppBar>
  );
}
