import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {
  withStyles,
  makeStyles
} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',

  },

  payRoot: {
    padding: '2.2em 2.5em'
  },

  flexBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 9,
    flexWrap: 'wrap',
  },

  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh'
  },
  
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 14,
  },

  borderBottom: {
    borderBottom: '1px solid #cdcfd0',
  },

  subHeading: {
    color: '#202020',
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: '500',
    letterSpacing: '0.44px',
    lineHeight: '24px',
    paddingBottom: 9,
  },
  swtichBack: {
    color: '#535D6D',
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: '600',
    letterSpacing: '0.36px',
    lineHeight: '19px',
    textAlign: 'right',
    textDecoration: 'underline',
    cursor: 'pointer',
    backgroundColor: 'inherit',
    border: 'none',
    '&:hover': {
      border: 'none',
    }
  },

  bankAccountDetails: {
    padding: '3em 4em',
  },

  bankAccountError: {
    color: 'red',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 600,
    letterSpacing: '0.36px',
    lineHeight: '19px',
    marginBottom: 6,
  },

  formControl: {
    width: '35%',
    '& .MuiSelect-select.MuiSelect-select': {
      fontFamily: "Montserrat",
      fontSize: " 16px",
      fontWeight: '600'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 20,
    },
  },

  addAccountBtn: {
    color: '#535D6D;',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '600',
    letterSpacing: '0.36px',
    lineHeight: '18px',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginTop: '10px',
  },

  radioInfo: {
    color: '#535D6D'
  },

  checkBoxColor: {
    color: '#535D6D !important'
  },

  textField: {
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: '600'
  },

  table: {
    padding: '0 1em 4em 1em'
  },


  paymentDetail: {
    width: '100%',
    backgroundColor: 'rgba(179,184,192,0.1)',

    padding: '2.2em 2.5em'
  },

  flex: {
    // padding: 20,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  subSection: {
    width: '45%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 20,
    }
  },

  subHeader: {
    width: '100%',
    borderBottom: "solid 1px #cdcfd0",
    paddingBottom: '10px',
  },

  sectionTitle: {
    color: '#202020',
    fontFamily: 'Montserrat',
    fontSize: '2em',
    fontWeight: '500',
  },

  subText: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: "16px",
    marginTop: 10,
  },

  termAndCondition: {
    width: '100%',
    backgroundColor: '#fff',
    padding: '13px',
    height: 162,
    overflowY: 'auto',
    border: "solid 1px #535D6D",
    marginTop: 12,
  },

  termText: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: '400'
  },

  termNConitionBox: {
    boxSizing: "border-box",
    height: "162px",
    border: "1px solid rgba(156,156,156,0.5)",
    backgroundColor: "#FFFFFF",
    marginTop: "1em",
    paddingLeft: "1.6em",
    paddingRight: ".8em",
    paddingTop: "1.3em",
    marginBottom: '810pxem',
    // [theme.breakpoints.down('sm')]: {
    //   marginBottom: '8em'
    // },
  },
  printButton: {
    padding: 0,
    marginTop: '12px !important',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '8em'
    },
  },
  printBtnIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "600",
    cursor: 'pointer',
    color: '#535D6D',
    textDecoration: 'underline',
  },
  tncTextContainer: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "16px",
    letterSpacing: "0.36px",
    lineHeight: "19px",
    height: "115px",
    overflow: "auto",
    paddingRight: "1em",
  },

  btns: {
    // display: 'flex',
    // justifyContent: 'space-between',
    paddingTop: 20,
    paddingBottom: 20,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    display: 'grid',
    justifyContent: 'end',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      flexDirection: 'column',
      alignItems: 'center'
    },
    '& button': {
      width: 222,
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontWeight: '600',
      letterSpacing: '0.36px',
      [theme.breakpoints.down('sm')]: {
        margin: '5px 0'
      },
    }
  },

  disabledBtn: {
    backgroundColor: '#94aecf !important'
  },

  displayflex: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiFormHelperText-contained.Mui-error': {
      marginLeft: 0,
      marginRight: 0
    }
  },

  table: {
    minWidth: 1011,
    width: '100%'
  },

  input: {
    padding: '11px 6px',
    maxWidth: '100px',
    minWidth: 90,
  },

  textInput: {
    maxWidth: '70px',
    padding: '11px 6px',
  },

  tableContainer: {
    margin: '0 20px 40px 20px'
  },

  dollarSign: {
    marginRight: '5px'
  },

  loaderContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabError: {
    fontSize: "16px",
    color: 'red',
    fontWeight: "bold",
    letterSpacing: "0.36px",
    lineHeight: "19px",
    fontFamily: "Montserrat",
    borderRight: "1px solid #ffffff",
    verticalAlign: "top",
    textAlign: 'center'
  },

  textField: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: '600',
    '&.MuiOutlinedInput-adornedEnd': {
      paddingRight: 0
    },
    '& .MuiIconButton-root': {
      padding: 0,
      width: 0,
      background: 'inherit',
      boxSizing: 'border-box',
      paddingRight: '17px',
    },
  },

  addedAccount: {
    '& .MuiFormLabel-root.Mui-focused': {
      color: "#25abc7",
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: "#25abc7",
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: "#25abc7",
      },
    },
  },
  paymentDayComponent: {
    border: '2px solid #25abc7',
    borderRadius: '30px',
    position: 'relative',
    width: '36px',
    height: '36px',
    lineHeight: '34px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiPickersDay-daySelected': {
      background: '#25abc7',
      color: '#fff',
    },
    '& .MuiPickersDay-hidden': {
      opacity: '0.3',
    }
  },

  greenZoneComponent: {
    '& .MuiPickersDay-daySelected': {
      border: '2px solid #25ABC7',
      background: '#25ABC7',
      color: '#fff',
    },
    '& .MuiPickersDay-hidden': {
      opacity: '0.3',
    }
  },

  redZoneComponent: {
    '& .MuiPickersDay-daySelected': {
      border: '2px solid #EB4F4F',
      background: '#EB4F4F',
      color: '#fff',
    },
    '& .MuiPickersDay-hidden': {
      opacity: '0.3',
    }
  },
  dayWithDot: {
    position: 'absolute',
    height: 0,
    width: 0,
    border: '2px solid',
    borderRadius: 4,
    borderColor: theme.palette.primary.main,
    right: '50%',
    transform: 'translateX(1px)',
    top: '80%'
  },

  relative: {
    position: 'relative'
  },
  paymentAmountErroIcon: {
    position: 'absolute',
    bottom: -10,
    right: -8,
  },

  errorMsg: {
    color: 'red',
    fontSize: 14,
    fontFamily: 'Montserrat',
    fontWeight: '600',
    lineHeight: '19px',
    letterSpacing: '0.36px',
    height: '150px',
    [theme.breakpoints.down('sm')]: {
      height: '110px',
    },
  },
  errorMsg2: {
    marginTop: 12,
    color: 'black',
    // textAlign: 'center',
    fontSize: 12,
    fontFamily: 'Montserrat',
    fontWeight: '600',
    lineHeight: '19px',
    letterSpacing: '0.36px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      bottom: 0,
    },
  },
 est: {
    backgroundColor: '#D9D9D9',
    borderRadius: 16,
    textAlign: "center",
    color: "#121D22",
    fontSize: 14,
    fontFamily: 'Montserrat',
    fontWeight: '700',
    margin: "auto",
    width: '85px',
    marginTop: 4
 },
 icon: {
  paddingTop: 4,
  fontSize: 26,
  color: "#007FFF"
 },
 spinner: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100vh',
  flexDirection: 'column'
},
processing: {
  color: "#121D22",
  fontFamily: "Montserrat",
  fontSize: 30,
  fontWeight: "600",
  paddingTop: 10,
  [theme.breakpoints.down('sm')]: {
    fontSize: 20
  },
},
refresh: {
  color: "#121D22",
  fontFamily: "Montserrat",
  fontSize: 22,
  fontWeight: "500",
  paddingTop: 10,
  [theme.breakpoints.down('sm')]: {
    fontSize: 14
  },
}
}));

export const LightToolTip = withStyles((theme) => ({
  arrow: {
    color: '#235A86',
    // backgroundColor: '#ffffff',
  },
  tooltip: {
    backgroundColor: '#235A86',
    color: '#fffff',
    fontFamily: "Montserrat",
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    padding: "16px",
    fontSize: 14,
    maxWidth: 400,
    fontWeight: 500,
    borderRadius: 8,
    // border: "1px solid #4D4D4D"
  },
}))(Tooltip);

export const RedTooltip = withStyles((theme) => ({
  arrow: {
    color: "#B91717",
  },
  tooltip: {
    backgroundColor: '#B91717',
    color: '#fff',
    fontFamily: "Montserrat",
    boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
    padding: "1.6em",
    fontSize: 16,
    maxWidth: 600,
  },
}))(Tooltip);

export const WhiteTooltip = withStyles((theme) => ({
  arrow: {
    color: "#B91717",
  },
  tooltip: {
    backgroundColor: '#FFF',
    color: '#000',
    fontFamily: "Montserrat",
    boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
    padding: "1.6em",
    fontSize: 13,
    maxWidth: 600
  },
}))(Tooltip);

export const SimpleTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 16,
    color: '#202020',
  },
}))(Tooltip);


export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#535D6D",
    color: "#ffffff",
    fontSize: "16px",
    fontWeight: "bold",
    letterSpacing: "0.36px",
    lineHeight: "19px",
    fontFamily: "Montserrat",
    borderRight: "1px solid #ffffff",
    verticalAlign: "top",
    padding: '10px',
  },

  body: {
    fontSize: 16,
    color: "#202020",
    letterSpacing: "0.36px",
    lineHeight: "19px",
    fontFamily: "Montserrat",
    padding: '15px 5px',
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);