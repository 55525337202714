import { axiosProtected, axiosPublic } from '../config';
import { HIDE_THIRD_PARTY_POPUP } from '../types';

export const thirdPartyAuthorization = async (data) => {
  try {
    const response = await axiosPublic.post('/OT/ThirdPartyAuthorization', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const thirdPartyAuthorizationPrivate = async (data) => {
  try {
    const response = await axiosProtected.post('/ThirdParty/AddNewThirdParty', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const getThirdPartyData = async (data) => {
  try {
    const response = await axiosProtected.post('/ThirdParty/GetThirdPartyData', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const approveThirdPartyAccess = async (data) => {
  try {
    const response = await axiosProtected.post('ThirdParty/ApproveThirdPartyAccess', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const hideThirdPartyPopupOnSinleLoan = () => async (dispatch) => {
  dispatch({
    type: HIDE_THIRD_PARTY_POPUP,
    payload: false
  })
}
