import Cookies from 'js-cookie';
import { getAppSource, getUserType } from '../helpers'
import { axiosProtected } from '../config';
import { STORE_ACC_FOR_EDIT, CLEAR_STORE } from '../types';

export const getPreferredBankAccount = async (accData) => {
  const data = {
    "bankProfileId": accData.bankProfileID,
    "isPreferredAcc": true,
    "accountNumber": "",
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  };
  try {
    const response = await axiosProtected.post('/Payment/PreferredBankAccount', data);
    if (response && response.data) {
      return response.data;
    }
    return null;
  } catch (e) {
    return null
  }
}

export const getDeleteBankAccount = async (accData) => {
  const data = {
    "bankProfileId": accData.bankProfileID,
    "isDeleted": true,
    "isPreferredAcc": false,
    "accountNumber": "",
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": "",
    "abaRoutingNumber": accData.abaRoutingNumber,
    "bankAccountNumber": accData.bankAccountNumber,
    "bankName": accData.bankName
  };
  try {
    const response = await axiosProtected.post('/Payment/DeleteBankAccount', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const addEditBankAccount = async (data) => {
  try {
    const response = await axiosProtected.post('/Payment/AddEditBankAccount', data);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      return error.response.data;
    }
    return error
  }
}

// store accunt for edit
export const storeAccForEdit = (data) => async (dispatch) => {
  dispatch({
    type: STORE_ACC_FOR_EDIT,
    payload: data
  })
}

export const storeClearAfterEdit = (data) => async (dispatch) => {
  dispatch({
    type: CLEAR_STORE,
    payload: null
  })
}

export const updateSecondaryBorrower = async (data) => {
  try {
    const response = await axiosProtected.post('/Customer/UpdateSecondaryBorrower', data);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      return error.response.data;
    }
    return error
  }
}

export const updateCoBorrower = async (data) => {
  try {
    const response = await axiosProtected.post('/Customer/UpdateCoBorrower', data);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      return error.response.data;
    }
    return error
  }
}

export const getDocLanverDetails = async (accNo) => {
  const data = {
    "accountNumber": accNo,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  };
  try {
    const response = await axiosProtected.post('/User/GetDocLanverDetails', data);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      return error.response.data;
    }
    return error
  }
}

export const getAchAddEditBankDetails = async (accData) => {
  const data = {
    "bankProfileId": accData.bankProfileID,
    "isDeleted": false,
    "isPreferredAcc": false,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": "",
    "abaRoutingNumber": accData.abaRoutingNumber,
    "bankAccountNumber": accData.bankAccountNumber
  };
  try {
    const response = await axiosProtected.post('/Payment/ACHForEditOrDeleteBank', data);
    return response.data;
  } catch (e) {
    return null
  }
}
