import React, { useState } from 'react';
import { Box, Grid, CircularProgress, Button, MenuItem, FormControl, Select, Checkbox } from "@material-ui/core";
import { useStyles } from './styles';
import Cookies from 'js-cookie';
import { scrollTop, renderDefaultMsg, getAppSource, getUserType } from '../../../../helpers';
import { Typography } from '@material-ui/core';
import { encrypt } from './../../../../helpers/encrypt';
import TextField from '@material-ui/core/TextField';
import { updateInvestorcodelabel } from './../../../../actions';

export const Editinvestor = (props) => {

  const classes = useStyles();
  const [dataError, setDataError] = useState('');
  const [dataLoader, setDataLoader] = useState(false);
  const [message, setmessage] = useState('');
  const [investorerror, setinvestorerror] = useState(false);
  const [values, setValues] = useState({
    investorCode: '',
    isVA: props.isVA
  });
  const [loader, setLoader] = useState(false);
  const [submitErrorMsg, setSubmitErrorMsg] = useState('');

  const handleInputHandler = (prop) => (event) => {
    setinvestorerror(false)
    if ((/^[0-9]+$/).test(event.target.value) || event.target.value === '') {
      setValues({ ...values, [prop]: event.target.value.slice(0, 10) });
    }
  };

  const handleChangeVALoan = (event) => {
    setValues({ ...values, isVA: event.target.checked })
  }

  const goBack = () => {
    props.clickBack();// can pass callback data here
  }

  const cancelBtnHandler = () => {
    props.clickBack();// can pass callback data here
    props.closeDialog()
  }

  const getvalidateData = async () => {
    updateinvestorCodedata()
    // if (values.investorCode !== '') {
    //   setinvestorerror(false)
    //   updateinvestorCodedata()
    // }
    // else {
    //   setinvestorerror(true)
    // }
  }

  const updateinvestorCodedata = async () => {
    setLoader(true);
    setSubmitErrorMsg('');
    setmessage('');
    const dataa = {
      "investorCode": values.investorCode ? encrypt(values.investorCode) : '',
      "isVA": values.isVA,
      "privateLabelUrl": "BSI",
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "InvestorCodeId": props.id,
      "privateLabelCode": props.privateLabelCode,
      "PrivateLabelName": props.labelName
    }
    const response = await updateInvestorcodelabel(dataa);
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
      setValues({ ...values, investorCode: '' })
      setLoader(false);
      setmessage(response.lstResponseStatus[0].statusDesc)
    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
      setLoader(false);
      setSubmitErrorMsg(response.lstResponseStatus[0].statusDesc)
    } else {
      setLoader(false);
      setSubmitErrorMsg(renderDefaultMsg('400'));
    }
  }

  if (dataLoader) {
    return (
      <Box className={classes.loaderRoot}>
        <CircularProgress />
      </Box>
    )
  }
  if (dataError !== '') {
    return (
      <Box className={classes.dataErrorMsg}>
        {dataError}
      </Box>
    )
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={10}>
          <div className={classes.firstareaa_1}>
            <div className={classes.inerr_1_p}>
              <Typography className={classes.text_part}>Company Name</Typography>
            </div>
            <div className={classes.inerr_1_p}>
              <FormControl className={classes.formControl} disabled >
                <Select
                  size="small"
                  labelId="demo-simple-select-disabled-label"
                  id="demo-simple-select-disabled"
                  value={props.labelName}
                  variant="outlined"
                  inputProps={{
                    className: classes.selectBoxStyle,
                    'aria-label': 'Without label',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                >
                  <MenuItem value={props.labelName}>
                    {props.labelName}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={classes.firstareaa_1}>
            <div className={classes.inerr_1_p}>
              <Typography className={classes.text_part}>Mapped Investor Code</Typography>
            </div>
            <div className={classes.inerr_1_p} style={{ marginTop: '10px', marginBottom: '10px', paddingLeft: '9px' }}>
              <Typography className={classes.data_part}>{props.investorCode}</Typography>
            </div>
          </div>
          <div className={classes.firstareaa} style={{ marginTop: '10px' }}>
            <div className={classes.inerr_1_p}>
              <Typography className={classes.text_part} style={{ paddingTop: '20px' }}>New Investor Code</Typography>
            </div>
            <div style={{ position: "absolute", paddingTop: '20px', marginTop: "35px" }}>
              <Typography className={classes.text_part}>Restricted Client?</Typography>
            </div>
            <div className={classes.inerr_1_p} style={{ paddingLeft: '9px' }}>
              <TextField
                size="small"
                style={{ width: '100%' }}
                color="primary"
                autoComplete="off"
                id="outlined-loanNumber"
                variant="outlined"
                type="text"
                value={values.investorCode}
                onChange={handleInputHandler('investorCode')}
                inputProps={{
                  className: classes.textFieldStyle,
                  maxLength: 10,
                  form: {
                    autocomplete: 'off',
                  },
                }}
                InputLabelProps={{
                  className: classes.inputStyle_2,
                  classes: { root: classes.textField }
                }}
              />
              {investorerror && <Typography color="error" style={{ fontSize: '11px' }}>Please enter investorcode</Typography>}
              {submitErrorMsg != '' && <p className={classes.errMessage}>{submitErrorMsg}</p>}
              {message !== '' && <p className={classes.successMsg}>{message}</p>}



              <div className={classes.holder} style={{ marginTop: '12px' }}>
                <Checkbox checked={values.isVA} onChange={(e) => handleChangeVALoan(e)} />
              </div>


              <div className={classes.holder} style={{ marginTop: '12px' }}>
                <div className={classes.buttonContainer}>
                  <Button onClick={getvalidateData} type='submit' disabled={loader} className={classes.searchbtn} size="large" variant="contained" color="primary">
                    {loader ? <CircularProgress className={classes.disableBtnLoader} /> : 'Update'}
                  </Button>

                  <Button onClick={cancelBtnHandler} type='submit' className={classes.searchbtn} size="large" variant="contained" color="primary">
                    Cancel
                    </Button>
                </div>
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} md={2} style={{ textAlign: 'center', paddingTop: '5px' }}>
          <Button onClick={cancelBtnHandler} type='submit' className={classes.searchbtn} size="large" variant="contained" color="primary">
            goback
        </Button>
        </Grid>
      </Grid>
    </div>
  )
}