//import axios from 'axios';
import { axiosProtected } from '../config';

export const getLossMitigationData = async (data) => {
  try {
    const res = await axiosProtected.post('/FinancialHardship/GetLossMitigationData', data);
    return res.data
  } catch (e) {    
    return null
  }
}

export const saveLossMitigationData = async (data) => {  
  try {
    const res = await axiosProtected.post('/FinancialHardship/InsertLossMitigationData', data);
    return res.data
  } catch (e) {    
    return null
  }
}