import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: 10,
    marginBottom: 28,
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    position: 'relative'
  },

  title: {
    color: "#FFF",
    fontSize: 22,
    fontWeight: "700",
    fontFamily: "Montserrat",
    textAlign: 'center',
    maxWidth: '290px',
    wordBreak: "break-word",
    paddingTop: 22
  },

  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  footer: {
    padding: '0em 45px 0em'
  },

  logo: {
    width: '100%'
  },

  btn: {
    top: '84%',
    position: 'absolute',
    width: '75%',
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: '16px',
    fontWeight: "700",
    display: "block",
    textAlign: "center",
    textDecoration: "none",
    textTransform: "uppercase",
    borderRadius: "4px",
    padding: "9px",
    height: '40px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: "#19CF7C",
      textDecoration: "none",
    }
  }
}));

