import React from 'react';
import { useStyles } from './styles';
import {
  Box, FormControl, FormGroup,
  FormControlLabel, Checkbox, Radio, RadioGroup
} from '@material-ui/core';

import { Link } from 'react-router-dom';

export const CovidCheckbox = (props) => {
  const classes = useStyles();
  const { click, change, suspensionOfPayments, forbearanceOf,
    contactedViaEmail, contactedOnCellPhone, impactedCov19 } = props;

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox
              checked={suspensionOfPayments}
              onChange={(e) => click(e)}
              name="suspensionOfPayments"
              classes={{ root: classes.checkBoxStyle }} />}
            label={<Box className={classes.checkboxLebel}>
              I am submitting this request for suspension of payment to my account<span className={classes.required}>*</span>
            </Box>}
          />
          <Box className={classes.radioText} style={{ marginLeft: '31px' }}><Box className={`${classes.checkboxLebel}`} >

            <Box className={`${classes.flexBox} ${classes.checkboxLebel}`}>
              I would like to request a forbearance of <span className={classes.required}> *</span>
              <RadioGroup row
                value={""}
              >
                <FormControlLabel
                  name="forbearanceOf"
                  value="1"
                  checked={forbearanceOf === '1' ? true : false}
                  onChange={(e) => change(e)}
                  control={<Radio classes={{ root: classes.radioStyle }} />}
                  label="90 Days"
                  className={classes.radioLabel}
                  classes={{
                    label: classes.radioLabel
                  }} />
                <FormControlLabel
                  name="forbearanceOf"
                  value="2"
                  checked={forbearanceOf === '2' ? true : false}
                  onChange={(e) => change(e)}
                  control={<Radio classes={{ root: classes.radioStyle }} />}
                  label="180 Days"
                  className={classes.radioLabel}
                  classes={{
                    label: classes.radioLabel
                  }} />
              </RadioGroup >
            </Box>
          </Box></Box>
          <FormControlLabel
            control={<Checkbox
              checked={impactedCov19}
              onChange={(e) => click(e)}
              name="impactedCov19"
              classes={{ root: classes.checkBoxStyle }} />}
            label={<Box className={classes.checkboxLebel}>
              I affirm that i am experiencing a financing hardship during the covid-19 emergency<span className={classes.required}>*</span>
            </Box>}
          />
          <FormControlLabel
            control={<Checkbox
              checked={contactedViaEmail}
              onChange={(e) => click(e)}
              name="contactedViaEmail"
              classes={{ root: classes.checkBoxStyle }} />}
            label={<Box className={classes.checkboxLebel}>I am ready to be contacted regarding this hardship request and
            the servicing of my loan via email at the address including in
              "Account &gt; <Link to="/UserProfile/Profile" className={classes.covidLinkStyle}>My Profile</Link>" (Optional)</Box>}
          />
          <FormControlLabel
            control={<Checkbox
              checked={contactedOnCellPhone}
              onChange={(e) => click(e)}
              name="contactedOnCellPhone"
              classes={{ root: classes.checkBoxStyle }} />}
            label={<Box className={classes.checkboxLebel}>I am ready to be contacted regarding this hardship request and
            the servicing of my loan via automated dialing system or text
            messages [Message and data rates apply] at the cell phone number included in
              "Account &gt; <Link to="/UserProfile/Profile" className={classes.covidLinkStyle}>My Profile</Link>" (Optional)</Box>}
          />
        </FormGroup>
      </FormControl>
    </div>
  );
}
