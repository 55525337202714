import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
  Box, Button, Checkbox, TextField, Divider, Select,
  FormControl, InputLabel, MenuItem, CircularProgress, Link,
  Tooltip
} from "@material-ui/core";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import DateFnsUtils from '@date-io/date-fns';
import PrintIcon from '@material-ui/icons/Print';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import ReactToPrint from 'react-to-print'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InfoIcon from '@material-ui/icons/Info';
import { useStyles , StyledTableCell, StyledTableRow, RedTooltip, WhiteTooltip, SimpleTooltip } from './styles';
import moment from 'moment';
import { maskAccount, scrollTop, checkDigitWithDecimal, renderDefaultMsg, numberWithCommas, getAppSource, getUserType, achSuspensionMessage, maskAccountNo } from '../../helpers';
import { encrypt, decrypt } from '../../helpers/encrypt';
import { insertActivityLog } from '../../actions';
import Cookies from 'js-cookie';

import {
  getBankAccountDetail, getContent,
  getMLOneTimeACHPaymentDetails, postMLOneTimeACHPaymentDetails,
  getUpdatedProcessFee, setPaymentOption
} from '../../actions';
import { AddEditAccount } from '../SharedComponent/AddBankAccAlert';
import { ProcessFeeAlert } from '../SharedComponent/ProcessFeeAlert';
import TermAndConditions from '../TermAndConditions';
import { SnackBar, MultiLoanSuccessAlert } from '../../components';
import { AdditionalPaymentAlert } from "../AdditionalPaymentAlert";
import { useHistory } from 'react-router-dom';
import {MULTILOAN_ACCOUNT_TOOLTIP} from '../../helpers/constants';
import { debounce } from "debounce";
import { withStyles } from '@material-ui/core/styles';
import { AdditionalPaymentMultiAlert } from '../AdditionalPaymentMultiAlert';
import { ReviewMultiPayment } from '../ReviewMultiPayment';
import spinner from '../../assets/spinner.gif';

const LightToolTip = withStyles((theme) => ({
  arrow: {
    color: '#235A86',
  },
  tooltip: {
    backgroundColor: '#235A86',
    color: '#fffff',
    fontFamily: "Montserrat",
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    padding: "16px",
    fontSize: 14,
    maxWidth: 350,
    fontWeight: 500,
    borderRadius: 8,
  },
}))(Tooltip);

export const OtpMultiLoan = (props) => {
  const classes = useStyles();
  const componentRef = useRef();
  const selectedAccount = useSelector(state => state.selectedAccount);
  const selectedAccountData = useSelector(state => state.selectedAccountData);
  const loanInfoData = useSelector(state => state?.loanInfoReducer);

  const dispatch = useDispatch();
  const history = useHistory();

  const [loans, setLoans] = React.useState([]);
  const [initialLoans, setInitialLoans] = React.useState([]);
  const [makePayLoading, setMakePayLoading] = useState(false);
  const [selectedAccountNo, setSelectedAccountNo] = useState('');
  const [selectedBank, setSelectedBank] = useState('');
  const [selectedBankError, setSelectedBankError] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [successData, setSuccessData] = useState([]);
  const [tncSummary, setTncSummary] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);

  const [msg, setMsg] = useState("");
  const [hasError, setHasError] = useState(false);
  const [makePaymentError, setMakePaymentError] = useState('');

  // calender state
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [processFeeAlert, setProcessFeeAlert] = useState(false);
  const [bankName, setBankName] = useState([]);
  const [newAddedAccount, setNewAddedAccount] = useState(null);
  const [removeForbearanceColumn, setRemoveForbearanceColumn] = useState(false);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [accountNumber, setAccountNumber] = useState();
  const [disablePaymentButton, setPaymentBtnDisable]= useState(false);
  const [lowPrincipalBalanceData, setLowPrincipalBalanceData] = useState([]);
  const [reviewPopupStatus, setReviewPopupStatus] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [lateFee, setLateFeeType] = useState('');

  const handleBankAccountChange = (event) => {
    setSelectedBank(event.target.value);
  }
  const showPopupAccount = () => {
    setShowPopup(true);
  }

  const sendActivityData = async () => {
    const data = {
      "accountNumber": selectedAccount,
      "activityCode": "UAL082",
      "activityStatus": "Success",
      "action": 'DraftMLOneTimeACHPaymentDetails',
      "controller": 'Payment',
      "apiName": "Payment/DraftMLOneTimeACHPaymentDetails",
      "activityRemark": 'UI- Borrower Clicked on One Time Multi Loan Make payment Button',
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertActivityLog(data);
  }

  useEffect(() => {
    getBankData();
    getOtpTableData();
    getContentData();
    // scrollTop();
  }, [])

  useEffect(() => {
    if (bankName && bankName.length) {
      const selectBankAccDetail = bankName.find(bankAccount => bankAccount.bankAccountNumber === newAddedAccount);
      if (selectBankAccDetail) {
        changeSelectedBank(selectBankAccDetail);
        setNewAddedAccount(null);
      } else {
        const selectBankAccPreff = bankName.find(bankAccount => bankAccount.isPreferredAcc === true);
        selectBankAccPreff ? changeSelectedBank(selectBankAccPreff) : changeSelectedBank(bankName[0])
      }
    }
  }, [bankName, bankName.length]);

  const newAddedBankAccount = (newAddedBank) => {
    setNewAddedAccount(newAddedBank);
  }

  const getOtpTableData = async () => {
    setRemoveForbearanceColumn(false);
    setMakePaymentError('');
    const response = await getMLOneTimeACHPaymentDetails(selectedAccount);
    if (response && response.lstResponseStatus && response.lstResponseStatus.length && response.lstResponseStatus[0].statusCode === '0') {
      await checkForbearanceFeeForZero(response.lstMultipleOneTimeACH);
      setLoans(response.lstMultipleOneTimeACH)
      setInitialLoans(response.lstMultipleOneTimeACH.map(loan => { return { ...loan } }))
    }
  };

  const checkForbearanceFeeForZero = async (otpArray) => {
    let loanArr = await otpArray.find(data => (data.forbearanceApplicationFee !== 0 && data.forbearanceApplicationFee !== null));
    if (loanArr) {
      setRemoveForbearanceColumn(false)
      return;
    } else {
      setRemoveForbearanceColumn(true)
      return;
    }
  }

  const disableDates = (estDateTime) => {
    var dat = new Date(estDateTime);
    dat.setDate(dat.getDate() + 10);
    return dat;
  }

  const renderDayInPicker = index => (date, selectedDate, dayInCurrentMonth, dayComponent) => {
    let latePaymentGreenDate = moment();
    if (moment(initialLoans[index].paymentDueDate, 'MM-DD-YYYY').diff(moment(latePaymentGreenDate, 'MM-DD-YYYY')) > 0) {
      latePaymentGreenDate = initialLoans[index].paymentDueDate
    }

    if (moment(latePaymentGreenDate, 'MM-DD-YYYY').diff(moment(date, 'MM-DD-YYYY')) === 0) {
      return (<div className={`${classes.paymentDayComponent}`}>
        {dayComponent}
      </div>)
    }

    if (moment(loans[index].latePaymentDate, 'MM-DD-YYYY').diff(moment(date, 'MM-DD-YYYY')) >= 0) {
      return (<div className={`${classes.greenZoneComponent}`}>
        {dayComponent}
      </div>)
    }

    if (moment(loans[index].latePaymentDate, 'MM-DD-YYYY').diff(moment(date, 'MM-DD-YYYY')) < 0) {
      return (<div className={`${classes.redZoneComponent}`}>
        {dayComponent}
      </div>)
    }
    return dayComponent
  }

  const changePaymentDueDate = index => async (date) => {
    setLateFeeType('')
    setMakePaymentError('');
    const newLoans = [...loans];
    newLoans[index].loanPaymentDueDate = moment(date).format('L');

    const updatedProcessFee = await updateFee(date, newLoans[index].forbearanceStatus, newLoans[index].propertyState,
      newLoans[index].loanStatus, newLoans[index].investorCode, newLoans[index].interestRate,
      newLoans[index].isBPL, newLoans[index].delinquentDays, newLoans[index].paymentDueDate);

    newLoans[index].processingFee.isDisplay = updatedProcessFee.isDisplay;
    newLoans[index].processingFee.processingFeeNote = updatedProcessFee.processingFeeNote;
    newLoans[index].processingFee.processingFee = updatedProcessFee.processingFee;
    newLoans[index].processingFee.delinquentDays = updatedProcessFee.delinquentDays;

    if ((moment(loans[index].loanPaymentDueDate) > moment(loans[index].latePaymentDate)) && (moment(loans[index].latePaymentDate) > moment(loans[index].estDateTime)) && (updatedProcessFee.processingFee > 0)) {
       
        setLateFeeType('both')
        setProcessFeeAlert(true);
        setIsOpen(false);
        newLoans[index].processingFee.processingFeeNote = updatedProcessFee.processingFeeNote;
        newLoans[index].processingFee.processingFee = updatedProcessFee.processingFee;
        newLoans[index].processingFee.delinquentDays = updatedProcessFee.delinquentDays;

    } else if (updatedProcessFee.processingFee > 0) {
     
      setLateFeeType('processing')
      setProcessFeeAlert(true);
      setIsOpen(false);
      newLoans[index].processingFee.processingFeeNote = updatedProcessFee.processingFeeNote;
      newLoans[index].processingFee.processingFee = updatedProcessFee.processingFee;
      newLoans[index].processingFee.delinquentDays = updatedProcessFee.delinquentDays;
    } else if ((moment(loans[index].loanPaymentDueDate) > moment(loans[index].latePaymentDate)) && (moment(loans[index].latePaymentDate) > moment(loans[index].estDateTime))) {
     
      setLateFeeType('late')
      setProcessFeeAlert(true);
      setIsOpen(false);
      newLoans[index].processingFee.processingFeeNote = updatedProcessFee.processingFeeNote;
      newLoans[index].processingFee.processingFee = updatedProcessFee.processingFee;
      newLoans[index].processingFee.delinquentDays = updatedProcessFee.delinquentDays;
  } 

    setLoans(newLoans);

  }

  const updateFee = async (date = selectedDate, forbearanceStatus,
    propertyState, loanStatus, investorCode, interestRate, isBPL, delinquentDays, paymentDuDate) => {
    const data = {
      "accountNumber": selectedAccount,
      "paymentDate": moment(date).format('L'),
      "forbearanceStatus": forbearanceStatus,
      "propertyState": propertyState,
      "transferStatus": loanStatus,
      "investorCode": investorCode,
      "interestRate": interestRate,
      "isBPL": isBPL,
      "delinquentDays": delinquentDays,
      "paymentDueDate": paymentDuDate,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };
    const response = await getUpdatedProcessFee(data);
    if (response) {
      return response.processingFee;
    }
  }

  const handlePrincipalTextChange = index => (event) => {
    if (checkDigitWithDecimal(event.target.value)) {
      const newLoans = [...loans];
      newLoans[index].additionalPrincipal = event.target.value ? event.target.value : '';

      if (newLoans[index].additionalPrincipal >= newLoans[index].unpaidPrincipalBalance) {
        newLoans[index].additionalPrincipalAmountError = true;
        newLoans[index].isSelected = false;
      } else {
        newLoans[index].additionalPrincipalAmountError = false;
        // newLoans[index].isSelected = true;
      }
      setLoans(newLoans);
    }
  }

  const handleEscrowTextChange = index => (event) => {
    if (checkDigitWithDecimal(event.target.value)) {
      const newLoans = [...loans];
      newLoans[index].additionalEscrow = event.target.value ? event.target.value : '';
      setLoans(newLoans);
    }
  }

  const handlePaymentAmountChange = index => (event) => {
    const newLoans = [...loans];
    newLoans[index].totalPaymentAmount = event.target.value ? event.target.value : '';
    // if (newLoans[index].hasOwnProperty("paymentAmountError")) {
    //   if (newLoans[index].paymentAmountError === true) {
    //     if (newLoans[index].totalPaymentAmount >= newLoans[index].monthlyAmountDue) {
    //       newLoans[index].paymentAmountError = false;
    //     } else {
    //       newLoans[index].paymentAmountError = true;
    //     }
    //   }
    // }
    if (newLoans[index].totalPaymentAmount >= newLoans[index].monthlyAmountDue) {
      newLoans[index].paymentAmountError = false;
    } else {
      newLoans[index].paymentAmountError = true;
    }
    setLoans(newLoans);
  }
  // if (!newLoans[index].isCustomAmount) {
  //     newLoans[index].paymentAmountError = true;
  //     newLoans[index].isSelected = false;
  //   } else {
  //     newLoans[index].paymentAmountError = false;
  //     newLoans[index].isSelected = true;
  //   }
  const paymentAmountOnBlur = index => (event) => {
    const newLoans = [...loans];
    if (newLoans[index].hasOwnProperty("isSelected")) {
      if (newLoans[index].isSelected === true) {
        if (newLoans[index].totalPaymentAmount < newLoans[index].monthlyAmountDue) {
          if (!newLoans[index].isCustomAmount) {
            newLoans[index].paymentAmountError = true;
            newLoans[index].isSelected = false;
          } else {
            newLoans[index].paymentAmountError = false;
            newLoans[index].isSelected = true;
          }
        } else {
          newLoans[index].isSelected = true;
        }
      }
    }
    setLoans(newLoans);
  }

  const principalAmountOnBlur = index => (event) => {
    const newLoans = [...loans];
    if (newLoans[index].hasOwnProperty("isSelected")) {
      if (newLoans[index].isSelected === true) {
        if (newLoans[index].additionalPrincipal >= newLoans[index].unpaidPrincipalBalance) {
          newLoans[index].additionalPrincipalAmountError = true;
          newLoans[index].isSelected = false;
        } else {
          newLoans[index].additionalPrincipalAmountError = false;
          newLoans[index].isSelected = true;
        }
      }
    }
    setLoans(newLoans);
  }

  const toggleProcessFeeAlert = () => {
    setLateFeeType('')
    setProcessFeeAlert(!processFeeAlert)
  }

  const getBankData = async () => {
    const data = {
      "accountNumber": "",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };
    const response = await getBankAccountDetail(data);
    if (response && response.lstResponseStatus && response.lstResponseStatus.length && response.lstResponseStatus[0].statusCode === '0') {
      setBankName(response.lstBEBankAccountDetails);
      setSelectedBankError('');
    } else if (response && response.lstResponseStatus && response.lstResponseStatus.length && response.lstResponseStatus[0].statusCode !== '0') {
      setSelectedBankError(response.lstResponseStatus[0].statusDesc);
    } else {
      setSelectedBankError(renderDefaultMsg('400'));
    }
  };

  const ToggleAccountPopup = (saved = null) => {
    if (saved === 'Saved') {
      getBankData();
    }
    setShowPopup(!showPopup);
  }

  const getContentData = async () => {
    const res = await dispatch(getContent('PC016'));
    if (res && res.length !== 0) {
      setTncSummary(res[0].contentValue)
    }
  }

  const changeSelectedBank = (data) => {
    setSelectedAccountNo(data.nickName);
    setSelectedBank(data);
  }

  const addedNewAccount = (newAcc) => {
    bankName.push(newAcc);
    changeSelectedBank(newAcc);
    setSelectedBankError('');
  }

  const validateBankDetails = () => {
    if (selectedBank === '') {
      return true;
    }
    return false;
  }

  const handleCloseAlert = () => {
    setAlertOpen(false)
    setReviewPopupStatus(true)

  }

  const handleReviewPopupSubmit = () => {
    setReviewPopupStatus(false)
    makePayment()
  }

  const handleReviewPopupClose = () => {
    setReviewPopupStatus(false)
    setPaymentBtnDisable(false)
  }

  const handleClose = () => {
    setAlertOpen(false);
  }

  const routeToRequestPayOff = () => {
    Cookies.set('selectedAccount', accountNumber);
    history.push('/PayoffRequest');
  }

  const makePayload = () => {
    const selectedLoans = loans.filter(item => item.isSelected === true);
    const list = selectedLoans.map((item) => {
      const processingNote = item.processingFee.processingFeeNote.replace("[PaymentAmount]", item.monthlyAmountDue);
      // const processingNote = pNote[0] + item.monthlyAmountDue;

      return {
        "accountNumber": item.accountNumber,
        "abaRoutingNumber": selectedBank.abaRoutingNumber,
        "bankAccountNumber": selectedBank.bankAccountNumber,
        "accountType": selectedBank.accountType,
        "bankCity": selectedBank.bankCity && selectedBank.bankCity.length ? selectedBank.bankCity : 'NA',
        "bankName": selectedBank.bankName && selectedBank.bankName.length ? decrypt(selectedBank.bankName) : 'NA',
        "bankState": selectedBank.bankState && selectedBank.bankState.length ? selectedBank.bankState : 'NA',
        "paymentDate": item.loanPaymentDueDate ? item.loanPaymentDueDate : moment(new Date()).format('L'),
        "borrowerName": selectedBank.borrowerName,
        "borrowerType": selectedBank.borrowerType,
        "paymentAmount": item.totalPaymentAmount ? Number(item.totalPaymentAmount) : item.monthlyAmountDue,
        "additionalPrincipal": Number(item.additionalPrincipal),
        "additionalEscrow": Number(item.additionalEscrow),
        "confirmationNumber": 0,
        "achDraftStatus": "",
        "draftFee": item.processingFee.processingFee,
        "graysDays": item.graceDays,
        "propertyState": selectedAccountData.propertyState,
        "investorCode": selectedAccountData.bsi_InvestorCode,
        "bankProfileID": selectedBank.bankProfileID,
        "processingFeeNote": processingNote,
        "delinquentDays": item.processingFee.delinquentDays,
        "loanStatus": item.loanStatus,
        "isCustomAmount": item.isCustomAmount,
        "IsForbearanceApplicationFeePayed": item.forbearanceApplicationFee === 0 ? false : true,
        "warningCode": item.warningCode,
        "isAgency": loanInfoData.bankDetail.agencyFlag,
      }
    });
    return list;
  }

  const callDebounce = () => {
     debounce(makePaymentSubmit(), 500);         
  }

  const makePayment = async () => {
    setMakePayLoading(true);
    if (validateBankDetails()) {
      setMakePayLoading(false);
      setSelectedBankError('Please add a bank Account')
      setPaymentBtnDisable(false)
    } else {
      window.scrollTo({ top: 360, left: 0})
      setSelectedBankError('');
      setHasError(false);
      setMsg('');
      setMakePaymentError('');
      setDataLoading(true)
      const list = makePayload();

      const res = await postMLOneTimeACHPaymentDetails(list);
      if (res && res.lstResponseStatus[0].statusCode === '0') {
        setDataLoading(false)
        setSuccessData(res.lstMLOneTimePaymentResponse)
        handleOpenSuccessAlert();
        setPaymentBtnDisable(false)
        sendActivityData()
      } else if (res && res.lstResponseStatus[0].statusCode !== '0') {
        setDataLoading(false)
        setHasError(true);
        setMsg(res.lstResponseStatus[0].statusDesc);
        setPaymentBtnDisable(false)
        if (res.lstResponseStatus[0].statusDesc?.toUpperCase()?.includes("DUPLICATE DRAFT")) { 
          setOpenSnackBar(false)
          setMakePaymentError('Duplicate draft found for the selected date on selected loan(s). Try selecting a new date to continue.');
        } else {
          setOpenSnackBar(true)
        }
      } else {
        setDataLoading(false)
        setMakePaymentError(renderDefaultMsg('400'));
        setPaymentBtnDisable(false)
      }

      setMakePayLoading(false);

    }
  }

  const makePaymentSubmit = async () => {
    if (validateBankDetails()) {
      setSelectedBankError('Please add a bank Account')
    } else {
    setLowPrincipalBalanceData([])
    setPaymentBtnDisable(true)
    const selectedLoans = loans.filter(item => item.isSelected === true);
    console.log(selectedLoans)
    const checkAdditionalPayment = selectedLoans.map((item) => {
      if (item.additionalPrincipal > (item.unpaidPrincipalBalance - 5000)) {
        return {
          status: true,
          accNumber: item.accountNumber
        };
      } else {
        return {
          status: false,
          accNumber: item.accountNumber
        };
      }
    });
    const statusItem = checkAdditionalPayment.map((item) => {
      if (item.status) {
        return {
          status: item.status,
          accNumber: item.accNumber
        };
      }
    });
 
    setLowPrincipalBalanceData(checkAdditionalPayment)
    setMakePayLoading(true);
    if (validateBankDetails()) {
      setMakePayLoading(false);
      setSelectedBankError('Please add a bank Account')
      setPaymentBtnDisable(false)
    } else if (statusItem[0]?.status) {
      setAccountNumber(statusItem[0].accNumber);
      setAlertOpen(true)
      setMakePayLoading(false);
    } else if (!reviewPopupStatus) {
        setMakePayLoading(false);
        setReviewPopupStatus(true)
    } else {
      window.scrollTo({ top: 360, left: 0})
      setSelectedBankError('');
      setHasError(false);
      setMsg('');
      setMakePaymentError('');
      setDataLoading(true)
      const list = makePayload();
      const res = await postMLOneTimeACHPaymentDetails(list);
      if (res && res.lstResponseStatus[0].statusCode === '0') {
        setDataLoading(false)
        setSuccessData(res.lstMLOneTimePaymentResponse)
        handleOpenSuccessAlert();
        setPaymentBtnDisable(false)
        sendActivityData()
      } else if (res && res.lstResponseStatus[0].statusCode !== '0') {
        setDataLoading(false)
        setHasError(true);
        setMsg(res.lstResponseStatus[0].statusDesc);
        setPaymentBtnDisable(false)
        if (res.lstResponseStatus[0].statusDesc?.toUpperCase()?.includes("DUPLICATE DRAFT")) { 
          setOpenSnackBar(false)
          setMakePaymentError('Duplicate draft found for the selected date on selected loan(s). Try selecting a new date to continue.');
        } else {
          setOpenSnackBar(true)
        }
      } else {
        setDataLoading(false)
        setMakePaymentError(renderDefaultMsg('400'));
        setPaymentBtnDisable(false)
      }

      setMakePayLoading(false);
    }
   }
  }

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false)
  }

  const handleCloseSuccessAlert = () => {
    setOpenSuccessAlert(false);
    getOtpTableData()
  }

  const handleOpenSuccessAlert = () => {
    setOpenSuccessAlert(true)
  }

  const handleLoanSelection = (index) => {
    const newLoans = [...loans];
    if (newLoans[index].hasOwnProperty("isSelected")) {
      if (newLoans[index].isSelected) {
        newLoans[index].isSelected = false;
      } else {
        if (newLoans[index].hasOwnProperty("totalPaymentAmount")) {
          if (newLoans[index].totalPaymentAmount < newLoans[index].monthlyAmountDue) {
            if (!newLoans[index].isCustomAmount) {
              newLoans[index].paymentAmountError = true;
              newLoans[index].isSelected = false;
            } else {
              newLoans[index].paymentAmountError = false;
              newLoans[index].isSelected = true;
            }
          } else {
            newLoans[index].paymentAmountError = false;
            newLoans[index].isSelected = true;
          }
        } else {
          newLoans[index].paymentAmountError = false;
          newLoans[index].isSelected = true;
        }
        if (newLoans[index].additionalPrincipal >= newLoans[index].unpaidPrincipalBalance) {
          newLoans[index].additionalPrincipalAmountError = true;
          newLoans[index].isSelected = false;
        } else {
          newLoans[index].additionalPrincipalAmountError = false;
          newLoans[index].isSelected = true;
        }
      }
    } else {
      if (newLoans[index].hasOwnProperty("totalPaymentAmount")) {
        if (newLoans[index].totalPaymentAmount < newLoans[index].monthlyAmountDue) {
          if (!newLoans[index].isCustomAmount) {
            newLoans[index].paymentAmountError = true;
            newLoans[index].isSelected = false;
          } else {
            newLoans[index].paymentAmountError = false;
            newLoans[index].isSelected = true;
          }
        } else {
          newLoans[index].paymentAmountError = false;
          newLoans[index].isSelected = true;
        }
      } else {
        newLoans[index].paymentAmountError = false;
        newLoans[index].isSelected = true;
      }
      if (newLoans[index].additionalPrincipal >= newLoans[index].unpaidPrincipalBalance) {
        newLoans[index].additionalPrincipalAmountError = true;
        newLoans[index].isSelected = false;
      } else {
        newLoans[index].additionalPrincipalAmountError = false;
        newLoans[index].isSelected = true;
      }
    }
    setLoans(newLoans);
  }

  const handleLoanSelectionOld = (index) => {
    const newLoans = [...loans];
    let PaymentAmountError = false
    let AdditionalPrincipalError = false

    if (newLoans[index].hasOwnProperty("isSelected")) {
      if (newLoans[index].isSelected) {
        newLoans[index].isSelected = false;
      } else {
        if (newLoans[index].hasOwnProperty("totalPaymentAmount")) {
          if (newLoans[index].totalPaymentAmount < newLoans[index].monthlyAmountDue) {
            if (!newLoans[index].isCustomAmount) {
              PaymentAmountError = true
            }
          }
        }
        if (newLoans[index].additionalPrincipal >= newLoans[index].unpaidPrincipalBalance) {
          AdditionalPrincipalError = true
        }
      }
    } else {
      if (newLoans[index].hasOwnProperty("totalPaymentAmount")) {
        if (newLoans[index].totalPaymentAmount < newLoans[index].monthlyAmountDue) {
          if (!newLoans[index].isCustomAmount) {
            PaymentAmountError = true
          }
        }
      }
      if (newLoans[index].additionalPrincipal >= newLoans[index].unpaidPrincipalBalance) {
        AdditionalPrincipalError = true
      }
    }

    if (PaymentAmountError) {
      newLoans[index].paymentAmountError = true;
      newLoans[index].isSelected = false;
    } else if (AdditionalPrincipalError) {
      newLoans[index].additionalPrincipalAmountError = true;
      newLoans[index].isSelected = false;
    } else {
      newLoans[index].isSelected = true;
    }
    setLoans(newLoans);
  }

  const renderPaymentTotal = () => {
    let paymentTotal = 0;
    loans.filter(loan => loan.isSelected === true)
      .map((loan) => {
        if (loan.totalPaymentAmount) {
          paymentTotal = paymentTotal + Number(loan.totalPaymentAmount);
        } else {
          paymentTotal = paymentTotal + Number(loan.monthlyAmountDue);
        }
        if (loan.additionalPrincipal) {
          paymentTotal = paymentTotal + Number(loan.additionalPrincipal)
        }
        if (loan.additionalEscrow) {
          paymentTotal = paymentTotal + Number(loan.additionalEscrow)
        }
        if (loan.processingFee) {
          paymentTotal = paymentTotal + (loan.processingFee.isDisplay ? Number(loan.processingFee.processingFee) : 0)
        }
        if (loan.forbearanceApplicationFee) {
          paymentTotal = paymentTotal + Number(loan.forbearanceApplicationFee)
        }
      });
    return paymentTotal.toFixed(2);
  }

  const handleTotalAmount = (loan) => {
    let total = loan.totalPaymentAmount ? Number(loan.totalPaymentAmount) : loan.monthlyAmountDue;
    total = total + (loan.processingFee.isDisplay ? loan.processingFee.processingFee : 0) + loan.forbearanceApplicationFee;
    if (loan.additionalPrincipal) {
      total = total + parseFloat(loan.additionalPrincipal)
    }
    if (loan.additionalEscrow) {
      total = total + parseFloat(loan.additionalEscrow)
    }
    return total.toFixed(2);
  }

  const TextFieldComponent = (props) => {
    return <TextField {...props} disabled={true} />
  }

  if (loans.length === 0) {
    return (
      <Box className={classes.loader}>
        <CircularProgress />
      </Box>
    )
  }

  if (dataLoading === false) {
  return (
    <Box className={classes.root} ref={componentRef} >

      <Box className={classes.payRoot}>
        <Box className={`${classes.flexBox} ${classes.borderBottom}`}>
          <h3 className={classes.subHeading}>
            Payment Information
          </h3>
          <Button className={classes.swtichBack}
            onClick={() => props.changeLoanType('S')}>
            SWITCH BACK TO SINGLE LOAN PAYMENT
          </Button>
        </Box>
      </Box>

      <Box className={classes.bankAccountDetails}>
        {bankName && bankName.length ? <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel className={classes.textField} id="select-account">Bank Account</InputLabel>
          <Select
            color="primary"
            labelId="select-account"
            id="select-account-outlined"
            label="Bank Account"
            value={selectedBank}
            onChange={handleBankAccountChange}
          >
            {
              bankName && bankName.length && bankName.map((bank, index) => {
                return (
                  <MenuItem
                    value={bank}
                    key={bank.bankAccountNumber}>
                    {`${bank.nickName} ${maskAccountNo(decrypt(bank.bankAccountNumber))}`}</MenuItem>
                )
              })
            }

          </Select>
        </FormControl> : null}
        {(selectedBankError !== '' || bankName.length === 0) &&
          <h5 className={classes.bankAccountError} style={{ textAlign: 'left', marginLeft: 0 }}>
            {selectedBankError}
          </h5>}

        <Box className={classes.flexContainer}>
          <Button
            className={classes.addAccountBtn}
            startIcon={<AddCircleOutlineIcon className={classes.radioInfo} style={{ fontSize: 20 }} />}
            onClick={showPopupAccount}
          >
            Add Bank Account</Button>
        </Box>
      </Box>

      <Box className={classes.tableContainer}>
        <TableContainer component={Paper} >
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" >Loan Number</StyledTableCell>
                <StyledTableCell align="center" >Payment Amount</StyledTableCell>
                <StyledTableCell align="center" >Monthly Amount</StyledTableCell>
                {/* <StyledTableCell align="center" >Process- ing Fee 
                  <LightToolTip arrow
                          title={"Processing fee of $7.00 is applied for payments overdue by more than 5 days."}
                          placement="bottom-end" >
                          <InfoIcon style={{ fill: '#fff', marginLeft: "5px" }} />
                        </LightToolTip>
                 </StyledTableCell> */}
                {!removeForbearanceColumn && <StyledTableCell align="center" >Forbearance Application Fee</StyledTableCell>}
                <StyledTableCell align="center" >Additional Principal Amount</StyledTableCell>
                <StyledTableCell align="center" >Additional Escrow Amount</StyledTableCell>
                <StyledTableCell align="center" >Total Amount</StyledTableCell>
                <StyledTableCell align="center" style={{ width: '120px' }}>Draft Date <Box className={classes.est}>Eastern Time</Box></StyledTableCell>
                <StyledTableCell align="center" >Select</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>


              {loans.map((tableData, index) => {
                return (
                  <>
                  <StyledTableRow key={tableData.accountNumber}
                    style={!tableData.isPaymentAllowed ? { background: '#f7f7f7' } : null}>
                    <StyledTableCell align="center"
                      style={!tableData.isPaymentAllowed ? { color: '#b7b7b7' } : null}>
                      <Box className={classes.displayflex}>
                        <LightToolTip title={`${decrypt(tableData.accountNumber)} - ${tableData.propertyAddressLine1}`}>
                          <p>{maskAccount(decrypt(tableData.accountNumber))}</p>
                        </LightToolTip>
                        {!tableData.isPaymentAllowed && !tableData.isPaymentNotAllowedInvCode 
                         && <RedTooltip arrow
                          title={tableData.delinquentDays + ' ' + tableData.delinquentMessage}
                          placement="bottom-end" >
                          <InfoIcon style={{ fill: '#E30909', marginLeft: "5px" }} />
                        </RedTooltip>}
                        {tableData.isPaymentAllowed && tableData.delinquentDays > 1 && 
                        !tableData.isPaymentNotAllowedInvCode && <LightToolTip arrow
                          title={tableData.delinquentDays + ' ' + tableData.delinquentMessage}
                          placement="bottom-end" >
                          <InfoIcon style={{ fill: '#ffc31b', marginLeft: "5px" }} />
                        </LightToolTip>}
                        {tableData.isPaymentNotAllowedInvCode && <RedTooltip arrow
                          title={MULTILOAN_ACCOUNT_TOOLTIP}
                          placement="bottom-end" >
                          <InfoIcon style={{ fill: '#E30909', marginLeft: "5px" }} />
                        </RedTooltip>}
                       
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="center" style={!tableData.isPaymentAllowed ? { color: '#b7b7b7' } : null}>
                      <Box className={`${classes.displayflex}`} style={{ position: 'relative' }}>
                        <span className={classes.dollarSign}>$ </span> <TextField
                          error={tableData.paymentAmountError ? true : false}
                          disabled={!tableData.isPaymentAllowed}
                          color='primary'
                          id="outlined-paymentAmount"
                          variant="outlined"
                          type="text"
                          name='paymentAmount'
                          value={tableData.totalPaymentAmount ? tableData.totalPaymentAmount : tableData.monthlyAmountDue}
                          onChange={handlePaymentAmountChange(index)}
                          onBlur={paymentAmountOnBlur(index)}
                          InputLabelProps={{
                            classes: { root: classes.textField }
                          }}
                          InputProps={{
                            classes: { root: classes.textField, input: classes.textInput }
                          }} />

                        {
                          tableData.paymentAmountError ?
                            <Box className={classes.paymentAmountErroIcon}><RedTooltip arrow
                              title={'Payment amount should be greater than or equal to monthly amount'} placement="bottom" >
                              <InfoIcon style={{ fill: 'red' }} />
                            </RedTooltip></Box>
                            : null
                        }

                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="center" style={!tableData.isPaymentAllowed ? { color: '#b7b7b7' } : null}>${tableData.monthlyAmountDue}</StyledTableCell>
                    {/* <StyledTableCell align="center" style={!tableData.isPaymentAllowed ? { color: '#b7b7b7' } : null}>
                      {tableData.processingFee.isDisplay ? `$ ${tableData.processingFee.processingFee}` : '-'}
                    </StyledTableCell> */}
                    {!removeForbearanceColumn && <StyledTableCell align="center" style={!tableData.isPaymentAllowed ? { color: '#b7b7b7' } : null}>${tableData.forbearanceApplicationFee}</StyledTableCell>}
                    <StyledTableCell align="center" style={!tableData.isPaymentAllowed ? { color: '#b7b7b7' } : null}>
                      <Box className={classes.displayflex} style={{ position: 'relative' }}>
                        <span className={classes.dollarSign}>$ </span> <TextField
                          error={tableData.additionalPrincipalAmountError ? true : false}
                          disabled={!tableData.isPaymentAllowed}
                          color='primary'
                          id={`outlined-principal${index}`}
                          variant="outlined"
                          type="text"
                          onBlur={principalAmountOnBlur(index)}
                          value={tableData.additionalPrincipal}
                          onChange={handlePrincipalTextChange(index)}
                          InputLabelProps={{
                            classes: { root: classes.textField }
                          }}
                          InputProps={{
                            classes: { root: classes.textField, input: classes.textInput }
                          }}
                        />
                        {
                          tableData.additionalPrincipalAmountError ?
                            <Box className={classes.paymentAmountErroIcon}><LightToolTip arrow
                              title={'The amount you’ve designated on the selected account is greater than its principal balance. Please select Request Payoff if you intend to pay off your loan. If you wish to make a payment towards your loan balance, please resubmit an amount that does not exceed your principal balance.'} placement="bottom" >
                              <InfoIcon style={{ fill: 'red' }} />
                            </LightToolTip></Box>
                            : null
                        }
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="center" style={!tableData.isPaymentAllowed ? { color: '#b7b7b7' } : null}>
                      {tableData.isEscrowAllowed ? <Box className={classes.displayflex} style={{ position: 'relative' }}>
                        <span className={classes.dollarSign}>$ </span><TextField
                          disabled={!tableData.isPaymentAllowed || !tableData.isEscrowAllowed}
                          color='primary'
                          id={`outlined-escrow${index}`}
                          variant="outlined"
                          type="text"
                          value={tableData.additionalEscrow}
                          onChange={handleEscrowTextChange(index)}
                          InputLabelProps={{
                            classes: { root: classes.textField }
                          }}
                          InputProps={{
                            classes: { root: classes.textField, input: classes.textInput }
                          }} />
                      </Box> : '-'}
                    </StyledTableCell>
                    <StyledTableCell align="center" style={!tableData.isPaymentAllowed ? { color: '#b7b7b7' } : null}>
                      ${handleTotalAmount(tableData)}
                    </StyledTableCell>
                    <StyledTableCell align="center" style={!tableData.isPaymentAllowed ? { color: '#b7b7b7' } : null}>
                      <Box className={`${classes.displayflex}`} style={{ position: 'relative' }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disabled={!tableData.isPaymentAllowed}
                          color='primary'
                          autoOk={true}
                          disableToolbar
                          minDate={moment(tableData.estDateTime)}
                          maxDate={disableDates(tableData.estDateTime)}
                          variant="inline"
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                          id="date-picker-inline"
                          value={tableData.loanPaymentDueDate ? tableData.loanPaymentDueDate : moment(tableData.estDateTime)}
                          onChange={changePaymentDueDate(index)}
                          renderDay={renderDayInPicker(index)}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          InputProps={{
                            classes: { root: classes.textField, input: classes.input },
                          }}
                          TextFieldComponent={TextFieldComponent}
                        />
                      </MuiPickersUtilsProvider>
                        { tableData.isPaymentAllowed && (moment(tableData?.estDateTime).format('YYYY-MM-DD') == (tableData.loanPaymentDueDate ? moment(tableData.loanPaymentDueDate).format('YYYY-MM-DD') : (tableData.warningCode === '3' ? moment(tableData.estDateTime).add(1, 'day').format('YYYY-MM-DD') : moment(tableData.estDateTime).format('YYYY-MM-DD')))) &&
                            <Box ><LightToolTip arrow
                              title={'Note: Cancellations may not be possible for payments drafted on the same day.'} placement="bottom" >
                              <InfoIcon className={classes.icon} />
                            </LightToolTip></Box>
                          
                        }
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="center" style={{ width: '50px' }}>
                      <Box>
                        <Checkbox
                          checked={tableData.hasOwnProperty("isSelected") ? tableData.isSelected : false}
                          disabled={!tableData.isPaymentAllowed}
                          onChange={() => handleLoanSelection(index)}
                          className={classes.checkBoxColor}
                        />

                      </Box>
                    </StyledTableCell>

                  </StyledTableRow>

                  </>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Divider variant="fullWidth" />

      <Box className={classes.paymentDetail}>
        <Box className={classes.flex}>
          <Box className={classes.subSection}>
            <Box className={classes.subHeader}>
              <h3 className={classes.sectionTitle}>Authorization</h3>
            </Box>
            <Box className={`${classes.termNConitionBox} `}>
              <Box className={classes.tncTextContainer}>
                <TermAndConditions data={tncSummary} />

              </Box>
            </Box>

            <Box p={1} textAlign="right" className={classes.printButton}>
              <ReactToPrint
                trigger={() => <Box className={classes.printBtnIcon}>
                  Print Form
                  <PrintIcon style={{ fill: '#535D6D', fontSize: 25, marginLeft: "5px", }} />
                </Box>}
                content={() => componentRef.current}
              />
            </Box>
          </Box>
          <Box className={classes.subSection} style={{ position: "relative" }}>
            <Box className={classes.subHeader}>
              <h3 className={classes.sectionTitle}>Payment Details</h3>
            </Box>
            <Box className={classes.flexBox}>
              <h4 className={classes.subText}>Payment Total</h4>
              <h3 className={classes.subText}>${renderPaymentTotal()}</h3>
            </Box>

            {loans && loans.length && renderPaymentTotal() > loans[0].maxPaymentAllowed && <h5 className={classes.errorMsg}>
              Payment Amount Restricted: Total payment amount can not be greater than ${numberWithCommas(loans[0].maxPaymentAllowed)}.<p className={classes.errorMsg2}> To complete a payment greater than ${numberWithCommas(loans[0].maxPaymentAllowed)}, please contact Customer Support.</p></h5>
            }

            {makePaymentError !== '' && <h5 className={classes.errorMsg}>{makePaymentError}</h5>}

            <Box className={classes.btns}>
              {/* <Button className={classes.cancelBtn}
                type='submit'
                size="large"
                variant="outlined"
                onClick={() => dispatch(setPaymentOption('FAQ'))}>
                Cancel
              </Button> */}
              {makePayLoading ?
                <Button className={classes.paymentBtn}
                  disabled={true}
                  className={classes.disabledBtn}
                  type='submit'
                  size="large"
                  variant="contained"
                  color="primary">
                  <CircularProgress color='primary' style={{ width: 25, height: 25 }} />
                </Button>
                : <Button className={classes.paymentBtn}
                  className={classes.enabledBtn}
                  type='submit'
                  size="large"
                  variant="contained"
                  id="reviewContineBtn"
                  color="primary"
                  disabled={disablePaymentButton || (bankName.length === 0 || renderPaymentTotal() > loans[0].maxPaymentAllowed || loans.filter(item => item.isSelected === true).length === 0) ? true : false}
                  onClick={() => callDebounce()}>
                  REVIEW & CONTINUE
                </Button>}
            </Box>

          </Box>
        </Box>
      </Box>

      {showPopup ? <AddEditAccount
        newAddedBank={newAddedBankAccount}
        toggleOpen={ToggleAccountPopup}
        open={showPopup}
        bankAccounts={bankName}
        addedAcc={addedNewAccount}
        renderPage={'oneTime'}
      /> : null}

      {processFeeAlert ? <ProcessFeeAlert
        handleClose={toggleProcessFeeAlert}
        open={processFeeAlert} lateFee={lateFee} /> : null}

      {msg !== '' && <SnackBar
        severity={hasError ? 'error' : "success"}
        message={msg} open={openSnackBar}
        handleClose={handleCloseSnackBar}
      />}

      {successData.length !== 0 &&
        <MultiLoanSuccessAlert
          open={openSuccessAlert}
          data={successData}
          handleClose={handleCloseSuccessAlert}
          selectedBank={selectedBank}
        />}
      {
        isAlertOpen &&
        <AdditionalPaymentMultiAlert open={true} handleCloseAlert={handleCloseAlert} routeToRequestPayOff={routeToRequestPayOff} handleClose={handleClose}
          single={false} lowPrincipalBalanceData={lowPrincipalBalanceData}/>
      }
      { reviewPopupStatus &&
        <ReviewMultiPayment loans={loans} selectedBank={selectedBank} handleReviewPopupSubmit={handleReviewPopupSubmit} handleReviewPopupClose={handleReviewPopupClose}/>
      }
    </Box>
  )
  }
  return (
    // <Box className={classes.loader}>
    //   <CircularProgress />
    // </Box>
    <Box className={classes.spinner}>
     <img src={spinner} alt="spinner"/>
     <Typography className={classes.processing}>Processing</Typography>
     <Typography className={classes.refresh}>Please do not refresh the page or click the “Back” or “Close” button of your browser</Typography>
   </Box>
  )

}