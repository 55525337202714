import React, { useEffect, useState, useRef } from 'react';
import { useStyles, StyledTableCell, ReportTooltip } from './styles';
import {
  Paper, Box, FormControlLabel, Switch,
  Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@material-ui/core';
import Chart from "chart.js";
import moment from 'moment'
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

export const SargentWebServiceCallsByName = ({ chartData, toggleFullScreen, chartId }) => {

  const classes = useStyles();
  const [serviceCallsByName, setServiceCallsByName] = useState(false)
  const [chartInstance, setChartInstance] = useState();
  const webServiceByNameRef = useRef(null);


  const handleChange = (event) => {
    setServiceCallsByName(event.target.checked);
  };

  const makeConfig = ({ data, labels }) => {
    const config = {
      type: 'line',
      data: {
        labels: [...labels],
        datasets: data.map(item => ({ ...item, fill: false }))
      }
    }
    if (chartInstance) {
      chartInstance.config = config;
      chartInstance.update();
    }
  }


  useEffect(() => {
    if (webServiceByNameRef) {
      const ctx = webServiceByNameRef.current;
      const chartInstance = new Chart(ctx, null);
      setChartInstance(chartInstance)
    }
  }, []);

  useEffect(() => {
    if (chartInstance && chartData) {
      makeConfig(chartData)
    }
  }, [chartData]);


  return (
    <Paper className={classes.root}>
      <Box className={classes.flexBetween}>
        <p className={classes.title}>Sagent Web Service Calls(By Name)</p>
        {chartData && chartData.labels && (<Box className={classes.actionBtn}>
          <ReportTooltip title="Grid View">
            <FormControlLabel
              control={
                <Switch
                  checked={serviceCallsByName}
                  onChange={handleChange}
                  name="serviceCallsByName"
                  color="primary"
                />
              }
            // label="Data"
            /></ReportTooltip>
          {chartId === 'swsCallsByNameFS' ? (<ReportTooltip title="Exit FullScreen">
            <span className={classes.link} onClick={toggleFullScreen}><FullscreenExitIcon /></span>
          </ReportTooltip>) : (<ReportTooltip title="FullScreen View">
            <span className={classes.link} onClick={toggleFullScreen}>
              <FullscreenIcon /></span></ReportTooltip>)}

        </Box>)}
      </Box>
      <Box className={classes.canvasContainer}>
        <canvas id={chartId} ref={webServiceByNameRef}></canvas>
      </Box>

      {serviceCallsByName && <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <StyledTableCell className={classes.borderCell}>Date</StyledTableCell>
              <StyledTableCell className={classes.borderCell} align="center">Web Service Name</StyledTableCell>
              <StyledTableCell className={classes.nonBorderCell} align="center">Total  Calls</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chartData && chartData.gridData.map((row, i) => (
              <TableRow key={`${row.createdDate}${i}`}>
                <TableCell lassName={classes.nonBorderCell}>{row.createdDate}</TableCell>
                <TableCell lassName={classes.nonBorderCell} align="center">{row.webServiceName}</TableCell>
                <TableCell lassName={classes.nonBorderCell} align="center">{row.totalCalls}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>}

    </Paper>
  )
}