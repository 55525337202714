import React, { lazy, Fragment } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Cookies from 'js-cookie';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import config from './config/oktaConfig';
import { useHistory } from 'react-router-dom';
import { AdminSignin } from './components/Admin/AdminComponent/AdminSignin';
import { AdminHeader } from '../src/components';

const AdminPage = lazy(() => import("./pages/AdminPages/AdminPage"));
const AdminUserNameSignInPage = lazy(() => import("./pages/AdminPages/AdminUserNameSignInPage"));
const Admindashboard = lazy(() => import("./pages/AdminPages/AdmindashboardPage"));
const Admindetails = lazy(() => import("./pages/AdminPages/AdmindetailsPage"));
const Admininvestorcode = lazy(() => import("./pages/AdminPages/AdmininvestorcodePage"));
const AdminUserDetailsPage = lazy(() => import("./pages/AdminPages/AdminUserDetailsPage"));
const Admincompanydetails = lazy(() => import("./pages/AdminPages/AdmincompanydetailsPage"));
const Adminchangepassword = lazy(() => import("./pages/AdminPages/AdminchangepasswordPage"));
const Admindeleteuser = lazy(() => import("./pages/AdminPages/AdmindeleteuserPage"));
const Adminuseractivitylog = lazy(() => import("./pages/AdminPages/AdminuseractivitylogPage"));
const Adminmaintainence = lazy(() => import("./pages/AdminPages/AdminmaintainencePage"));
const UpdatePageContentPage = lazy(() => import("./pages/AdminPages/UpdatePageContentPage"));
const NotificationSetupPage = lazy(() => import("./pages/AdminPages/NotificationSetupPage"));
const MapNotificationPage = lazy(() => import("./pages/AdminPages/MapNotificationPage"));
const AdminManageNotificationPage = lazy(() => import("./pages/AdminPages/AdminManageNotificationPage"));
const AdminReportPage = lazy(() => import("./pages/AdminPages/AdminReportPage"));
const AdminSignInPage = lazy(() => import("./pages/AdminPages/AdminSignInPage"));
const AlertMessage =  lazy(() => import("./pages/AdminPages/AlertMessagePage"));

const ProtectedRoute = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (Cookies.get('adminToken')) {
          return (
            <Fragment>
              <AdminHeader />
              {children}
            </Fragment>
          )
        }
        return <Redirect to={{ pathname: "/", state: { from: location } }} />
      }}
    />
  );
}

const RoutesAdmin = () => {
 
  const history = useHistory();
  const oktaAuth = new OktaAuth(config.oidc);
  
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history?.replace(toRelativeUrl(originalUri || "/", window?.location?.origin));
  };

  const base = Cookies.get("privateLabelUrl");
  const basename = `/${base}/`;
  
  return (
    <Router basename={basename}>
      
      <Switch>
       <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <LoginCallback exact path="/signin" component={AdminSignInPage}
        errorComponent={AdminSignin}/>
        <Route exact path='/signin' component={AdminSignInPage}></Route> 
        <Route exact path='/' >
          <AdminPage />
        </Route>
        <Route exact path='/AdminSignin'>
          <AdminUserNameSignInPage />
        </Route>  
        <ProtectedRoute exact path='/Admininvestorcode' >
          <Admininvestorcode />
        </ProtectedRoute>
        <ProtectedRoute exact path='/UpdatePageContent' >
          <UpdatePageContentPage />
        </ProtectedRoute>
        <ProtectedRoute exact path='/Admindashboard'>
          <Admindashboard />
        </ProtectedRoute>
        <ProtectedRoute exact path='/Adminmaintainence'>
          <Adminmaintainence />
        </ProtectedRoute>
        <ProtectedRoute exact path='/Admindetails'>
          <Admindetails />
        </ProtectedRoute>
        <ProtectedRoute exact path='/AdminUserDetails' >
          <AdminUserDetailsPage />
        </ProtectedRoute>
        <ProtectedRoute exact path='/Adminchangepassword' >
          <Adminchangepassword />
        </ProtectedRoute>
        <ProtectedRoute exact path='/Admincompanydetails' >
          <Admincompanydetails />
        </ProtectedRoute>
        <ProtectedRoute exact path='/Admindeleteuser' >
          <Admindeleteuser />
        </ProtectedRoute>
        <ProtectedRoute exact path='/Adminuseractivitylog' >
          <Adminuseractivitylog />
        </ProtectedRoute>
       
        <ProtectedRoute exact path='/NotificationPopup' >
          <NotificationSetupPage />
        </ProtectedRoute>
        <ProtectedRoute exact path='/mapNotification' >
          <MapNotificationPage />
        </ProtectedRoute>
        <ProtectedRoute exact path='/AdminNotification' >
          <AdminManageNotificationPage />
        </ProtectedRoute>
        <ProtectedRoute exact path='/Report' >
          <AdminReportPage />
        </ProtectedRoute>
        <ProtectedRoute exact path='/AlertMessage'>
          <AlertMessage />
        </ProtectedRoute>
        </Security>
      </Switch>
     
    </Router>
  )
}

export default RoutesAdmin