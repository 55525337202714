import React from 'react';
import { useStyles } from './styles';
import { Box, Button, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { Link, useHistory } from 'react-router-dom';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { setPaymentOption } from '../../actions';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { numberWithCommas } from '../../helpers';
import InfoIcon from '@material-ui/icons/Info';
import Cookies from 'js-cookie';

export const UpcomingPaymentAlert = (props) => {
  const { totalAmountDue, paymentDueDate, isAlreadyScheduleACHPayment,
    bankDetails, lastPaymentDate, piTotalMonthlyPayment, setUpAutoPay, buydown
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch()
  const content = useSelector(state => state.content);
  const [open, setOpen] = React.useState(true);
  const [toggle,setToggle] = React.useState(false);
  const handleClickOpen = () => setOpen(true);
  
  const handleClose = () => {
    setOpen(false);
    Cookies.set('isUpcomingPayDueOpened','false');
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();

  const renderContent = (content) => {
    if (content && content.lstPageContentDetails && content.lstPageContentDetails.length !== 0) {
      return content.lstPageContentDetails.filter(item => item.contentKey.toUpperCase() === "UPCOMINGPAYMENT")
        .map(item => {
          return (
            <p key={item.contentID} className={classes.bodyText} dangerouslySetInnerHTML={{ __html: item.contentValue }}></p>
          )
        })
    }
  }

  const handleAutoPayNavigation = () => {
    Cookies.set('isUpcomingPayDueOpened','false');
   // history.push('/PaymentOption')
   setTimeout(()=>{
    dispatch(setPaymentOption('MANAGEAUTOPAY'));
   },2002)
    
  }

  const handleMakePayment = () => {
    // if (!isAlreadyScheduleACHPayment && (setUpAutoPay?.length > 0)) {
    //   dispatch(setPaymentOption('MANAGEAUTOPAY'));
    // } else {
    //   dispatch(setPaymentOption('ONETIMEPAYMENT'));
    // }
    Cookies.set('isUpcomingPayDueOpened','false');
    history.push('/PaymentOption')
  }
  const handleToggleText=()=>{
    setToggle(!toggle);
  }
  return (
    <Dialog  PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={handleClose} open={open}>
      <Box className={classes.root}>
        <IconButton onClick={handleClose} className={classes.closeIcon}>
          <CancelIcon />
        </IconButton>
        <Box className={classes.cardBody1}>
           <Typography className={classes.modalTitle}>Upcoming Payment Due Date</Typography>
        </Box>
   
        <Box className={classes.cardBody2}>
         <Box className={classes.body}>
          <Box className={classes.list}>
          <Box className={classes.statusContainer}>
            <p className={classes.statusTitle}>Total Amount Due </p>
            <h3 className={classes.statusBody}>${totalAmountDue}</h3>
          </Box>
          <Box className={classes.statusContainer}>
            <p className={classes.statusTitle}>Monthly Amount Due </p>
            <h3 className={classes.statusBody}>
          ${(Number(buydown) > 0) ? numberWithCommas(parseFloat(piTotalMonthlyPayment - buydown).toFixed(2)) : numberWithCommas(parseFloat(piTotalMonthlyPayment).toFixed(2)) }</h3>
          </Box>
          <Box className={classes.statusContainer}>
            <p className={classes.statusTitle}>Payment Due Date</p>
            <h3 className={classes.statusBody}>{moment(paymentDueDate).format('ll')}</h3>
          </Box>
          <Box className={classes.statusContainer}>
            <p className={classes.statusTitle}>Last Payment</p>
            <h3 className={classes.statusBody}>{moment(lastPaymentDate).isValid() ? moment(lastPaymentDate).format("ll") : "N/A"}</h3>
          </Box>
         </Box>
         <Box className={classes.desktopView}>
           <InfoIcon className={classes.infoTooltipIcon} />
           {renderContent(content)}
         </Box>
         {toggle && <Box className={classes.mobileView}>
           <InfoIcon className={classes.infoTooltipIcon} />
           {renderContent(content)}
         </Box>}
         <Typography className={classes.viewMore} onClick={handleToggleText}>{toggle?'View Less':'View More'}</Typography>
         </Box>  
        </Box>
        
        <Box className={classes.cardFooter}>
            <Box className={`${classes.mobView} ${classes.footerItem} end`}>
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.btn}
                  onClick={() => handleMakePayment()}
                >Make a payment</Button>
              </Box>
          {(!isAlreadyScheduleACHPayment) ? (setUpAutoPay?.length > 0) &&
            <Box className={classes.footerItem}>
              <p className={classes.onTime}>On Time Every Time</p>
              <p className={classes.footerText}>Set your date and never miss a payment.</p>
              <Link
                onClick={() => handleAutoPayNavigation()}
                className={classes.linkStyle}
                to='/PaymentOption'>Set up AutoPay</Link>
            </Box> :
            <Box className={classes.footerItem}>
              <Box className={classes.flexBox}>
                <CheckCircleIcon style={{ color: 'rgb(37, 171, 199)' }} />
                <p className={classes.autoPayEnrolled}> Autopay:<b className={classes.marginFix}> Enrolled</b></p>
              </Box>
              <p className={classes.autoPayEnrolled}>{bankDetails}</p>
            </Box>
          }
          <Box className={`${classes.webView}  ${classes.footerItem} end`}>
            <Button
              variant='contained'
              color='primary'
              className={classes.btn}
              onClick={() => handleMakePayment()}
            >Make a payment</Button>
           </Box>
         
        </Box>
      </Box>
    </Dialog>
  )
}
