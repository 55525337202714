import React, { useState, useEffect } from 'react';
import { Box, Button } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { useStyles, StyledTableCell, StyledTableRow } from './styles';
import {
  TextField, Select, FormControl,
  MenuItem, InputLabel, FormControlLabel,
  Checkbox, Table, TableBody, TableContainer, TableFooter,
  TablePagination, TableRow, Paper, TableHead, CircularProgress
} from '@material-ui/core';
import { getPrivatelabel, getuserLists, getBorrowerToken } from '../../../../actions';
import { decrypt, encrypt } from './../../../../helpers/encrypt';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import adminIcon from '../../../../assets/Admin/adminIcon.svg'
import { checkDigitOnly, checkValidEmail, getAppSource, getUserType } from '../../../../helpers'
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { INFO_ADMIN_RECORD_TWO } from '../../../../helpers/constants';


const SimpleTooltip = withStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#202020',
    fontFamily: "Montserrat",
    boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
    padding: "1em",
    fontSize: 16,
    maxWidth: 300,
  },
}))(Tooltip);

export const Admindashboard = () => {

  const classes = useStyles();
  const [privateLabelCode, setPrivateLabelCode] = useState('All');
  const [searchByKey, setSearchByKey] = useState('1');
  const [searchByValue, setSearchByValue] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [privateLabels, setPrivateLabels] = useState([]);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userList, setUserList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [emailAddressError, setEmailAddressError] = useState(false);
  const [statusDesc, setStatusDesc] = useState('');
  const [paginationStatus, setPaginationStatus] = useState(false);
  const [privateLabelStatus, setPrivateLabelChange] = useState(false);
  const [searchStatus, setSearchStatus] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPaginationStatus(true)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePrivateLabelCodeChange = (event) => {
    setPrivateLabelChange(true)
    setPrivateLabelCode(event.target.value);
  };

  const handleSearchKeyChange = (event) => {
    setEmailAddressError(false);
    setSearchByValue('')
    setSearchByKey(event.target.value);
  };

  const handleSearchValueChange = (event) => {
    if (searchByKey === '1') {
      if (checkDigitOnly(event.target.value)) {
        setSearchByValue(event.target.value.trim());
      }
      return;
    } else if (searchByKey === '2') {
      setEmailAddressError(false);
      setSearchByValue(event.target.value.trim());
      return;
    }
    setSearchByValue(event.target.value.trim());
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
  };

  const validateEmailAddress = (emailId) => {
    checkValidEmail(emailId) ? setEmailAddressError(false) : setEmailAddressError(true)
  }

  const handleNavigation = async (borrowerUserName, privateLabelCode, privateLabelURL) => {
    setLoader(true)
    const res = await getBorrowerToken(borrowerUserName, privateLabelCode, privateLabelURL);
    if (res) {
      setLoader(false);
      // window.open(`/${privateLabelURL}/Dashboard`, '_blank', 'noopener,noreferrer');

      window.location.href = `/${privateLabelURL}/Dashboard`
      // window.location.target= '_blank';

    }
    setLoader(false)
  }

  const getPrivatelabels = async () => {
    const res = await getPrivatelabel();
    if (res && res.lstResponseStatus[0].statusCode === '0') {
      setPrivateLabels(res.privateLabelList);
    }
  }

  const handleSearch = () => {
    setPage(0)
    getUsersList();
  }
  
  const getUsersList = async () => {
    setLoader(true);
    setUserList([]);
    setTotalRecords(0)
    setStatusDesc('')
    setSearchStatus(true)
    const data = {
      "pageIndex": page,
      "pageSize": rowsPerPage,
      "PrivateLabelCode": privateLabelCode,
      "searchById": searchByKey,
      "searchValue": encrypt(searchByValue),
      "IsIncludeAll": selectAll,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
    }
    const res = await getuserLists(data);
    if (res && res.lstResponseStatus[0].statusCode === '0') {
      setUserList(res.lstUserDetails);
      setTotalRecords(res.totalRecords)
    } else if (res && res.lstResponseStatus[0].statusCode !== '0') {
      setStatusDesc(res.lstResponseStatus[0].statusDesc)
    }
    setLoader(false);
  }
 
  useEffect(() => {
    getPrivatelabels();
  }, []);

  useEffect(() => {
    if (privateLabelStatus) {
      handleSearch()
    }
  }, [privateLabelCode]);

  useEffect(() => {
    if (paginationStatus) {
      getUsersList();
    }
   
  }, [page, rowsPerPage])

  return (
    <Box className={classes.bgg}>
      <Box className={classes.root}>
        <h1 className={classes.title}>Dashboard</h1>
        <div className={classes.formStyle}>
          <Box className={classes.searchBox}>
            <Box className={classes.inputBox}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="company-name" className={classes.privateLabel}>Company Name</InputLabel>
                <Select
                  labelId="company-name"
                  id='company-name-select'
                  labelWidth={125}
                  displayEmpty
                  value={privateLabelCode}
                  onChange={handlePrivateLabelCodeChange}
                  inputProps={{
                    className: classes.inputStyle,
                  }}
                >
                  <MenuItem value='All'>All</MenuItem>
                  {privateLabels.length ?
                    privateLabels.map(label => (
                      <MenuItem
                        key={label.id}
                        value={label.privateLabelCode}
                      >
                        {label.privateLabelName}
                      </MenuItem>
                    )) : null}
                </Select>
              </FormControl>
            </Box>

            <Box className={classes.searchByBox}>
              <Box className={classes.inputBox}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="company-name" className={classes.privateLabel}>Search By</InputLabel>
                  <Select
                    labelId="search-by"
                    id='search-by-select'
                    labelWidth={80}
                    displayEmpty
                    value={searchByKey}
                    onChange={handleSearchKeyChange}
                    inputProps={{
                      className: classes.inputStyle,
                    }}
                  >
                    <MenuItem value={'1'}>Loan Number</MenuItem>
                    <MenuItem value={'2'}>Email Id </MenuItem>
                    <MenuItem value={'3'}>User Name</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box className={classes.inputBox}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    error={(emailAddressError && searchByValue !== '')}
                    id="search-by-value"
                    variant="outlined"
                    placeholder={searchByKey === '1' ? 'Enter Loan Number' : searchByKey === '2' ? 'Enter Email Id' : 'Enter User Name'}
                    value={searchByValue}
                    onChange={handleSearchValueChange}
                    onBlur={searchByKey === '2' ? validateEmailAddress : null}
                    inputProps={{
                      className: classes.textInputStyle,
                      maxLength: searchByKey === '1' ? 10 : searchByKey === '2' ? 100 : 100,
                    }}
                  />
                </FormControl>
              </Box>
            </Box>

            <Box className={classes.inputBox}>
              <FormControlLabel
                classes={{ label: classes.includeAllLabel }}
                control={
                  <Checkbox
                    checked={selectAll}
                    onChange={handleSelectAll}
                    color="primary"
                  />
                }
                label="Include All"
                labelPlacement="start"
              />
              <SimpleTooltip placement='top-start' arrow title={INFO_ADMIN_RECORD_TWO}>
                <InfoIcon style={{ fontSize: '25px', top: '9px', position: 'relative', left: '10px', cursor: 'pointer' }} />
              </SimpleTooltip>
            </Box>

            <Box className={classes.searchBtnBox}>
              <Button
                className={classes.searchBtn}
                type='submit'
                color='primary' variant='contained'
                onClick={handleSearch}
              >
                Search
              </Button>
            </Box>
          </Box>
        </div>
        <Box className={classes.tableBox}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">User Name </StyledTableCell>
                  <StyledTableCell align="center">Full Name</StyledTableCell>
                  <StyledTableCell align="center">Loan Number</StyledTableCell>
                  <StyledTableCell align="center">Zip Code</StyledTableCell>
                  <StyledTableCell align="center">Email</StyledTableCell>
                  <StyledTableCell align="center">Company Name</StyledTableCell>
                  <StyledTableCell align="center">Admin Details</StyledTableCell>
                  <StyledTableCell align="center">Customer Details</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loader && userList.length ?
                  userList.map(row => {
                    return (
                      <StyledTableRow key={row.id}>
                        <StyledTableCell align="center" >{decrypt(row.userName)} </StyledTableCell>
                        <StyledTableCell align="center" >{`${row.firstName} ${row.lastName}`} </StyledTableCell>
                        <StyledTableCell align="center" >{decrypt(row.accountNumber)} </StyledTableCell>
                        <StyledTableCell align="center" >{decrypt(row.zipCode)} </StyledTableCell>
                        <StyledTableCell align="center">{decrypt(row.emailID)} </StyledTableCell>
                        <StyledTableCell align="center">{row.companyName} </StyledTableCell>
                        <StyledTableCell align="center">
                          <Link to={{
                            pathname: '/Admindetails',
                            state: {
                              companyName: row.companyName, accountStatus: row.accountStatus,
                              accountStatusID: row.accountStatusID, borrowerSSN: row.borrowerSSN,
                              accountnumber: row.accountNumber, zipcode: row.zipCode, email: row.emailID,
                              name: row.userName, firstname: row.firstName, lastname: row.lastName
                            }
                          }}
                          >
                            <img className={classes.link} src={adminIcon} alt='icon' />
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          className={classes.link}
                          align="center"
                        >
                          <a onClick={() => handleNavigation(row.userName, row.privateLabelCode, row.privateLabelURL)}>
                            <SupervisorAccountIcon classes={{ root: classes.linkCustomer }} /></a>
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                  })
                  : ( searchStatus ?
                    <StyledTableRow>
                      <StyledTableCell colSpan={8}>
                        <Box className={classes.loaderBox}>
                          {loader ? <CircularProgress /> : (statusDesc) ? <p className={classes.noDataFoundText}>{statusDesc}</p> : <p className={classes.noDataFoundText}>No Data Found</p>}
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow> :
                    <StyledTableRow>
                      <StyledTableCell colSpan={8}>
                        <Box className={classes.loaderBox}>
                          <p className={classes.noDataFoundText}>Click On Search For Results</p>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    count={totalRecords}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  /></TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Box >
    </Box >
  )
}

