import React, { useState, Fragment } from 'react';
import { Box, Grid, CircularProgress, Button, Paper, Typography } from "@material-ui/core";
import EmailIcon from '@material-ui/icons/Email';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import InfoIcon from '@material-ui/icons/Info';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import { useStyles } from './styles';
import Cookies from 'js-cookie';
import { scrollTop, renderDefaultMsg, getAppSource, getUserType } from '../../../../helpers';
import { encrypt, decrypt } from './../../../../helpers/encrypt';
import TextField from '@material-ui/core/TextField';
import { CheckUserExist, DeleteUser } from './../../../../actions';

export const Admindeleteuser = () => {

  const classes = useStyles();
  const [dataLoader, setDataLoader] = useState(false);
  const [userdata, setUserdata] = useState([]);
  const [values, setValues] = useState({
    user: '',
    email: '',
    loan: '',
  });

  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [isUserDetails, setIsUserDetails] = useState(false)

  const handleChange = (prop) => (event) => {
    setErrorMessage('')
    setSuccessMessage('')
    setValues({ ...values, [prop]: event.target.value });
  };

  const checkUserExistdata = async () => {
    if (!values.user) {
      setErrorMessage("Please enter username")
      return
    }
    const requestData = {
      "accountNumber": "",
      "userName": encrypt(values.user),
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
    }
    setDataLoader(true);
    setErrorMessage('')
    setSuccessMessage('')
    setIsUserDetails(false)
    const result = await CheckUserExist(requestData);
    if (result) {
      const { lstResponseStatus, userName, emailID, accountNumber, firstName, lastName, roleId, roleName } = result

      if (lstResponseStatus[0].statusCode === "0") {
        setIsUserDetails(true)
        setUserdata(result)
        setValues({
          user: userName ? decrypt(userName) : '',
          email: emailID ? decrypt(emailID) : '',
          loan: accountNumber ? decrypt(accountNumber) : '',
          firstName: firstName ? firstName : '',
          lastName: lastName ? lastName : '',
          roleId: roleId ? decrypt(roleId) : '',
          roleName: roleName ? roleName : ''
        })
      } else {
        setIsUserDetails(false)
        setErrorMessage(lstResponseStatus[0].statusDesc)
      }
    } else {
      setIsUserDetails(false)
      setErrorMessage(renderDefaultMsg('400'))
    }
    setDataLoader(false);
  }

  const clearData = async () => {
    setValues({ user: '' })
    setErrorMessage('')
    setSuccessMessage('')
    setIsUserDetails(false)
  }

  const deleteUserAction = async () => {
    if (!values.user) {
      setErrorMessage(renderDefaultMsg('400'))
      return
    }

    const requestData = {
      "accountNumber": "",
      "AdminUserName": encrypt(values.user),
      "RoleId": userdata.roleId,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
    }

    setErrorMessage('')
    setSuccessMessage('')

    const result = await DeleteUser(requestData);
    if (result) {
      const { lstResponseStatus } = result
      if (lstResponseStatus[0].statusCode === "0") {
        setIsUserDetails(false)
        setSuccessMessage(lstResponseStatus[0].statusDesc)
        setValues({ user: '' })
        setUserdata('')
      } else {
        setErrorMessage(lstResponseStatus[0].statusDesc)
      }
    } else {
      setErrorMessage(renderDefaultMsg('400'))
    }
    setDataLoader(false);
  }

  return (
    <Box className={classes.bgg}>
      <Box className={classes.fullpart}>
        <Box className={classes.root}>
          <Typography className={classes.title}>Delete User</Typography>
          <Paper className={classes.mainContainer}>
            <Box display='flex' alignItems="center" className={classes.appBar}>
              <InfoIcon className={classes.infoIcon} />
              <Typography className={classes.infoText}><b>Note</b> : Its mandatory to check User Details before deleting User.</Typography>
            </Box>
            {errorMessage !== '' ? (<Box px={3} pt={2}><Typography className={classes.errorMsg}>{errorMessage}</Typography></Box>) : null}
            {successMessage !== '' ? (<Box px={3} pt={2}><Typography className={classes.successMessage}>{successMessage}</Typography></Box>) : null}

            <Box p={3} className={classes.flexBox}>
              <Typography className={classes.searchLable}>User Name</Typography>
              <TextField
                size="small"
                color="primary"
                variant="outlined"
                type="text"
                value={values.user}
                onChange={handleChange('user')}
                inputProps={{
                  className: classes.inputText,
                  maxLength: 20,
                  form: {
                    autocomplete: 'off',
                  },
                }}
                InputLabelProps={{
                  className: classes.inputStyle_2,
                  classes: { root: classes.textField }
                }} />
              <Box px={2}>
                <Button
                  onClick={checkUserExistdata}
                  className={classes.btnStyle}
                  size="large"
                  variant="contained"
                  color="primary"> Check User Details </Button>
              </Box>
              <Box>{dataLoader ? (<CircularProgress />) : null}</Box>
            </Box>
            {isUserDetails ? (<Fragment>
              <Box p={3}>
                <Box display='flex' alignItems="center" className={classes.appBar}>
                  <InfoIcon className={classes.infoIcon} />
                  <Typography className={classes.infoText}>User Details</Typography>
                </Box>

                <Box px={3} className={classes.userDetailBox}>
                  <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item md={3}>
                      <Box className={classes.flexBox}>
                        <AccountCircleIcon />
                        <Typography className={classes.labelText}>User ID</Typography>
                      </Box>

                    </Grid>
                    <Grid item md={3}>
                      <Typography className={classes.valueText}>{values.user}</Typography>
                    </Grid>
                    <Grid item md={3}>
                      <Box className={classes.flexBox}>

                        <GroupWorkIcon />
                        <Typography className={classes.labelText}>User Role</Typography>
                      </Box>

                    </Grid>
                    <Grid item md={3}>
                      <Typography className={classes.valueText}>{userdata.roleName}</Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box px={3}>
                  <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item md={3}>
                      <Box className={classes.flexBox}>
                        <FormatListNumberedIcon />
                        <Typography className={classes.labelText}>Loan Number</Typography>
                      </Box>
                    </Grid>
                    <Grid item md={3}>
                      <Typography className={classes.valueText}>{values.loan}</Typography>
                    </Grid>
                    <Grid item md={3}>
                      <Box className={classes.flexBox}>
                        <AccountCircleIcon />
                        <Typography className={classes.labelText}>First Name</Typography>
                      </Box>
                    </Grid>
                    <Grid item md={3}>
                      <Typography className={classes.valueText}>{userdata.firstName}</Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box px={3}>
                  <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item md={3}>
                      <Box className={classes.flexBox}>
                        <AccountCircleIcon />
                        <Typography className={classes.labelText}>Last Name</Typography>
                      </Box>
                    </Grid>
                    <Grid item md={3}>
                      <Typography className={classes.valueText}>{userdata.lastName}</Typography>
                    </Grid>
                    <Grid item md={3}>
                      <Box className={classes.flexBox}>
                        <EmailIcon />
                        <Typography className={classes.labelText}>Email</Typography>
                      </Box>
                    </Grid>
                    <Grid item md={3}>
                      <Typography className={classes.valueText}>{values.email}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <Box className={classes.flexBox} justifyContent="center" py={3}>
                <Box px={1}>
                  <Button
                    onClick={deleteUserAction}
                    className={classes.btnStyle}
                    variant="contained" color="primary">
                    Delete
                            </Button>
                </Box>
                <Box px={1}>
                  <Button
                    onClick={clearData}
                    className={classes.btnStyle}
                    variant="contained" color="primary">
                    Cancel
                            </Button>
                </Box>
              </Box>
            </Fragment>) : null}
          </Paper>
        </Box>
      </Box>
    </Box>)
}