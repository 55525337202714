import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "95%",
    minHeight: '100vh',
    padding: "3em 0em",
    margin: '0 auto',
  },
  fullpart: {
    width: '100%',
    maxWidth: "100%",
  },
  bgg: {
    background: 'url(/static/media/signinIcon.de91850d.svg) no-repeat center center',
    backgroundSize: 'cover',
    width: '100%',    
    backgroundColor: '#ccc',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  loaderContainer: {
    width: '100%',
    height: '100%',
    minHeight: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  table: {
    minWidth: 350,
  },

  subTitle: {
    color: '#363636',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: '600',
    letterSpacing: '0.47px',
    lineHeight: '22px',
    margin: '30px 0 23px 0'
  },

  errorMsg: {
    fontSize: '1.4em',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
    marginTop: 5,
    fontFamily: 'Montserrat',
    color: "red",
  },

  loaderRoot: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  dataErrorMsg: {
    width: '100%',
    height: '50vh',
    display: 'flex',
    textAlign:'center',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'red',
    fontSize: '22px',
  },

errorMsg: {
    fontSize: '14px',
    fontWeight: '500',
    fontFamily: 'Montserrat',
    color: "red",
    marginBottpm: '10px'
  },
  
  data_part: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.5px',
    lineHeight: '20px',
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: '14px',
    },
  },
  title: {
    width: '100%',
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: "600",
    marginBottom: 18,
  },

}));