import React from 'react';
import { useStyles } from './styles';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { SUBMIT_TEXT1, SUBMIT_TEXT2 } from "../../../helpers/constants";

export const SubmitAlert = (props) => {

  const { open, handleSubmitClose, handleSubmit, submitMessages, showMessageStatus  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      width="600px"
      PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={handleSubmitClose} open={open}
    >
      <Box className={classes.root}>
        <IconButton className={classes.iconStyle} onClick={handleSubmitClose}>
          <CancelIcon />
        </IconButton>
        <Box className={classes.header}>
        
         { !showMessageStatus ? 
          <>
            <Typography className={classes.subTitle}>{SUBMIT_TEXT1}</Typography>
            <Typography className={classes.paragraph}>{SUBMIT_TEXT2}</Typography>
          </> :
          <>
          {
          submitMessages?.map((item) =>
           (
          <>
           {item.isCITFailed &&
              <Typography className={classes.msg}>{item.escrowLineType} - We encounter some error.</Typography>
              }
              {item.isMessageLimitFailed &&
                <Typography className={classes.msg}>{item.escrowLineType} - Cit message limit reach.</Typography>}
               {(!item.isCITFailed && !item.isMessageLimitFailed) &&
              <Typography className={classes.msg}>
              {item.escrowLineType} request has been successfully submitted.</Typography> }
              </>
           ))
          } 
         </>
       }
        </Box>
       
        <Button
          color='primary'
          variant='contained'
          className={classes.btn}
          onClick={handleSubmit}
        >OK</Button>
      </Box>
    </Dialog>
  )
}
