import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  filterText: {
    color: '#000',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '16px'
  },
  filterHeads: {
    fontSize: '14px'
  },
  filterLink: {
    color: '#007FFF',
    textAlign: 'right',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontStyle: 'normal',
    cursor:'pointer',
    fontWeight: '500',
    lineHeight: '16px',
    textDecorationLine: 'underline'
  },
  checkboxText: {
    color: '#000',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    '& .MuiFormControlLabel-label':{
      color: '#000',
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '500',
    }
  },
  btn:{
    width:'150px'
  }

}));