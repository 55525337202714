import { makeStyles } from '@material-ui/core/styles';
import signinIcon from '../../assets/SignIn/SignIn.svg';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#ddd',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${signinIcon})`,
    backgroundPosition: "bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'contain',
  },

  wrapper: {
    maxWidth: '58.8em',
    padding: '3.9em 4.9em',
    marginTop: '4.5em',
    marginBottom: '4.5em',
    backgroundColor: '#fff',
    borderRadius: 12,
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '20px',
      margin: '50px 15px'
    },
  },

  title: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: '3.6em',
    fontWeight: "600",
    letterSpacing: "0.5px",
    marginBottom: 33,
    marginTop: 0,
    lineHeight: 1,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.6em',
    },
  },

  forgotPasswordText: {
    fontFamily: "Montserrat",
    color: "#535D6D",
    fontSize: 14,
    fontWeight: "600",
    display: 'block',
    textAlign: 'right',
    marginBottom: 20,
    marginTop: 5,
  },

  hintText: {
    color: "rgba(0, 0, 0, 0.74)",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    letterSpacing: '0.4px',
    marginBottom: 25,
    marginTop: 0,
  },

  labelStyle: {
    fontFamily: "Montserrat",
    fontSize: '1.6em',
  },

  btnContainer: {
    width: '100%',
    marginTop: 40,
    marginBottom: 5,
  },

  returnBtn: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    display: 'block',
  },

  needHelp: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: '1em',
    marginLeft: 10,
  },

  sendBtn: {
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: '600',
    marginBottom: 20,
    color: '#535D6D !important',
    border: 'solid 2px',
    borderColor: theme.palette.primary.main,
    boxShadow: "0px 4px 8px 0 rgba(0,0,0,0.14)",
    height: 50,
  },

  submitBtn: {
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: '600',
    marginBottom: 20,
    height: 50,
  },

  errorMsg: {
    fontSize: '1.4em',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
    fontFamily: 'Montserrat',
    color: "red",
  },

  inputStyle: {
    fontFamily: "Montserrat",
    fontSize: '16px',
    fontWeight: '600',
    color: 'rgba(0,0,0,0.87)'
  }

}));

