import { axiosProtected, axiosPublic } from '../config';

export const getHippoQuote = async (data) => {
  try {
    const res = await axiosProtected.post('/DashBoard/GetHippoQuote', data);
    return res.data
  } catch (e) {
    return null
  }
}

export const getRefinanceHippoQuote = async (data) => {
  try {
    const response = await axiosPublic.post('/OT/GetHippoQuote', data);
    return response.data;
  } catch (e) {
    return null
  }
}