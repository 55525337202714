import { FETCH_SECURITY } from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_SECURITY:
      return action.payload
    default:
      return state;
  }
}

