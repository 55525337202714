import React, { useState, useEffect, useRef } from "react";
import { Box, IconButton, Dialog, Button, Typography, Grid } from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import { useStyles } from './styles';
import { useStyles1 } from '../DeleteAutoPayAlertMultiLoan/styles'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import autoPayIcon from '../../assets/PaymentOptions/calendar.png';
import faqIcon from '../../assets/PaymentOptions/question.png';
import oneTimePayIcon from '../../assets/PaymentOptions/repeat.png';
import { PaymentFaqs, OneTimePayment, ManageAutoPay, FinancialHardshipAlert, TransferInfoAlert, PaidInFullPopup } from '../../components';
import { getRecurringACHInfo, setPaymentOption, getUpcomingSchedulePayment, deleteUpcomingSchedulePay } from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import clsx from "clsx";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { decrypt } from '../../helpers/encrypt';
import { scrollTop, renderDefaultMsg, achSuspensionMessage, getPayOffStatus, getServiceTransferStatus, getChargedOffStatus, getFCLCompletedStatus, getMortgageLabel, getLoanLabel } from '../../helpers';
import UpcomingPaymentTrns from '../UpcomingPaymentTrns';
import ErrorIcon from '@material-ui/icons/Error';
import { PaymentTransactionHistory } from '../PaymentTransactionHistory';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@material-ui/icons/Print';
import { ACCOUNT_TOOLTIP } from "../../helpers/constants";
import Cookies from 'js-cookie';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

export const PaymentOption = () => {
  const classes = useStyles();
  const classesPopup = useStyles1();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const selectedAccount = useSelector(state => state.selectedAccount);
  const upcomingTransState = useSelector(state => state.upcomingTransState);
  const paymentOption = useSelector(state => state.paymentOption);
  const loanInfoData = useSelector(state => state?.loanInfoReducer?.purgeLoanInfo);
  const [open, setOpen] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const printRef = useRef(null);
  const [transections, setTransection] = useState([]);
  const [dataError, setDataError] = useState('');
  const [openStatus, ContinueWithOneTimePayment] = useState(false);
  const [customerCareNo, setCustomerCareNo] = useState('');
  const [confirmationNumber,setConfirmationNumber] = useState('');
  const [isFromWarningPopup,setIsFromWarningPopup] = useState(false);
  const [isFromWarningMultiL,setIsFromWarningMultiL] = useState(false);
  const [deleteSuccess,setIsDeleteSuccess] = useState(false);
  const [deleteFailed,setDeleteFailed] = useState(false);
  const [errDeleteMsg,setIsErrDeleteMsg] = useState(false);
  const [isFromMultiLoan,setIsFromMultiLoan] = useState(false);
  const [transferAlertStatus, setTransferAlertStatus] = useState(false);
  const [accountAccessInfo, setAccountAccessInfo] = useState(false);
  const [liquidationType, setLiquidationType] = useState();

  const getFaqData = async () => {
    setLoading(true)
    const res = await getRecurringACHInfo(selectedAccount);
    if (res && res.lstResponseStatus[0].statusCode === '0') {
      routeValidation(res);
      setData(res);
      setLoading(false)
    } else {
      setErrMsg(renderDefaultMsg('400'))
    }
    await fetchUpcomingSchedulePayment()
    // scrollTop();
  }

  const routeValidation = (res) => {
    if (!res.isPaymentAllowed) {
      dispatch(setPaymentOption("FAQ"));
      return;
    }
    else {
      if (!res.isRecurringAllowed && paymentOption === 'MANAGEAUTOPAY') {
        dispatch(setPaymentOption("FAQ"));
        return;
      }
    }
  }


  const fetchUpcomingSchedulePayment = async () => {
    setLoading(true);
    setDataError('');
    const response = await getUpcomingSchedulePayment(selectedAccount);
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
      setTransection(response.paymentDetailList);
    } else {
      setDataError(response ? response.lstResponseStatus[0].statusDesc : 'Something Bad Happened.');
    }
    setLoading(false);
  }

  const deleteUpcomingSchedulePayment = async (draftDate, accountNumber) => {
    setLoading(true);
    setDataError('');
    const response = await deleteUpcomingSchedulePay(accountNumber, draftDate);
   if(response){
    if (response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
      setIsDeleteSuccess(true);
      setConfirmationNumber(response.confirmationNumber)
      fetchUpcomingSchedulePayment();
    } else {
      const msg = response.lstResponseStatus[0].statusDesc;
      setDeleteFailed(true);
      setIsErrDeleteMsg(msg);
    }
  }
  else{
    setDeleteFailed(true);
    setIsErrDeleteMsg(renderDefaultMsg('400'));
  }
    setLoading(false);
  }


  const updateFaq = (option) => {
    getFaqData();
  }


  useEffect(() => {
    // scrollTop();
    if (selectedAccount) {
      setData()
      getFaqData()
    }
  }, [selectedAccount, paymentOption, upcomingTransState]);

  const handlePaymentOption = (option,isFromWarning=false,isFromWarningMultiL=false) => {
    ContinueWithOneTimePayment(false);
    setIsFromWarningMultiL(isFromWarningMultiL);
    setIsFromWarningPopup(isFromWarning);
    if (data?.isPaymentAllowed) {
      dispatch(setPaymentOption(option));  
    }
  };
  const redirectToManageAutopay = (isMulti) => {
    setIsFromMultiLoan(isMulti);
    dispatch(setPaymentOption('MANAGEAUTOPAY'));
   //if multiloan the redirect multiloan section
    if(isMulti){
    setTimeout(()=>{
      setIsFromMultiLoan(!isMulti);
    },2000)
   }
  }

  const renderPaymentOption = () => {
    ContinueWithOneTimePayment(true)
    dispatch(setPaymentOption("MANAGEAUTOPAY"));
  }

  const contentData = (data) => {
    setCustomerCareNo(data?.customerCareNumber)
  }

  const renderPaymentOptions = () => {
    if (data) {
      switch (paymentOption) {
        case "FAQ":
          return <PaymentFaqs handlePaymentOption={handlePaymentOption} data={data} loading={loading} updateFaq={updateFaq} contentData={contentData} selectedAccount={selectedAccount} transections={transections} redirectToManageAutopay={redirectToManageAutopay} deleteUpcomingSchedulePayment={deleteUpcomingSchedulePayment} dataError={dataError} fetchUpcomingSchedulePayment={fetchUpcomingSchedulePayment} customerCareNo={customerCareNo}
          />
        case "ONETIMEPAYMENT":
          return <OneTimePayment click={handlePaymentOption} data={data} routeToAutoPay={renderPaymentOption} isFromWarningPopup={isFromWarningPopup} isFromWarningMultiL={isFromWarningMultiL} customerCareNo={customerCareNo} />
        case "MANAGEAUTOPAY":
          return <ManageAutoPay data={data} loading={loading} handlePaymentOption={handlePaymentOption} openStatus={openStatus} customerCareNo={customerCareNo} isFromMultiLoan={isFromMultiLoan}/>
        default:
          return <PaymentFaqs handlePaymentOption={handlePaymentOption} data={data} loading={loading} updateFaq={updateFaq} contentData={contentData} />
      }
    }
  };


  const handleClose = () => {
    setOpen(false);
  };

  const handlePopupClose=()=>{
    setIsDeleteSuccess(false);
    setDeleteFailed(false);
  }

  const closePopup = () => {
    setTransferAlertStatus(false)
  }

  const handlePaidInFull = (type) => {
    setLiquidationType(type)
    setAccountAccessInfo(true)
  }
 
  const closePaidInFullPopup  = () => {
    setLiquidationType()
    setAccountAccessInfo(false)
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.root}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small"  style={{color: '#2B2D30'}} />} aria-label="breadcrumb">
          <Link color="inherit" to='/PaymentOption' className={classes.disabledLink}>
            Payments
          </Link>
          <Link color="inherit" to='/' className={classes.activeLink}>
            {paymentOption === 'FAQ' && 'Payment Options'}
            {paymentOption === 'ONETIMEPAYMENT' && 'One-Time Payment'}
            {paymentOption === 'MANAGEAUTOPAY' && 'Manage Autopay'}
          </Link>
        </Breadcrumbs>


        <h1 className={classes.title}>
          {paymentOption === 'FAQ' && 'Payment Options'}
          {paymentOption === 'ONETIMEPAYMENT' && 'One-Time Payment'}
          {paymentOption === 'MANAGEAUTOPAY' && 'Manage Autopay'}
        </h1>
        {/* {data && loading === false ?
          <Box className={data.delinquentDays < 2 ? classes.greenBanner : data.isPaymentAllowed ? classes.banner : classes.redBanner}>
            <Box className={data.delinquentDays < 2 ? clsx(classes.countBox, classes.whiteBorder) : data.isPaymentAllowed ? classes.countBox : clsx(classes.countBox, classes.whiteBorder)}><p className={data.delinquentDays < 2 ? clsx(classes.countText, classes.whiteColor) : data.isPaymentAllowed ? classes.countText : clsx(classes.countText, classes.whiteColor)}>{data.delinquentDays}</p></Box>
            <p className={data.delinquentDays < 2 ? clsx(classes.bannerText, classes.whiteColor) : data.isPaymentAllowed ? classes.bannerText : clsx(classes.bannerText, classes.whiteColor)}>{data.delinquentMessage}</p>
            {!data.isPaymentAllowed && <IconButton onClick={() => setOpen(true)}><ErrorIcon style={{ fill: '#fff' }} /></IconButton>}
          </Box>
          :
          null
        } */}

       
          { getPayOffStatus(loanInfoData) && loanInfoData &&
            <Box className={classes.transferBox}>
              <Grid container direction="row" alignItems="center" style={{ paddingLeft: '5px' }}>
                <Grid item>
                  <Box display='flex' alignItems="center">
                    <InfoIcon className={classes.paidInFullIcon} />
                    <Typography className={classes.informationText}>Your loan has been paid in full. For more information <a className={classes.clickLink} onClick={() => handlePaidInFull('paidInFull')}> Click here</a></Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box> }

          { getServiceTransferStatus(loanInfoData) && loanInfoData &&
              <Box className={classes.transferBox}>
                <Grid container direction="row" alignItems="center" style={{ paddingLeft: "10px" }}>
                  <Grid item md={12} xs={12} lg={12}>
                    <Box display='flex' alignItems="center" >
                      <InfoIcon className={classes.paidInFullIcon} />
                      <Typography className={classes.informationText}>Your loan has been transferred to new servicer <span className={classes.servicerText}>{loanInfoData.newServicerName}.</span> For more information <a className={classes.clickLink} onClick={() => setTransferAlertStatus(true)}>Click here</a> </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box> }

          { getChargedOffStatus(loanInfoData) && loanInfoData &&
          <Box className={classes.transferBox}>
            <Grid container direction="row" alignItems="center" style={{ paddingLeft: '5px' }}>
              <Grid item md={12} xs={12} lg={12}>
                <Box display='flex' alignItems="center">
                  <InfoIcon className={classes.paidInFullIcon} />
                  <Typography className={classes.informationText}>This {getMortgageLabel()?.toLowerCase()} {getLoanLabel()?.toLowerCase()} has been charged off. For more information <a className={classes.clickLink} onClick={() => handlePaidInFull('chargedOff')}> Click here</a></Typography>
                </Box>
              </Grid>
            </Grid>
          </Box> }  

          { getFCLCompletedStatus(loanInfoData) && loanInfoData &&
          <Box className={classes.transferBox}>
            <Grid container direction="row" alignItems="center" style={{ paddingLeft: '5px' }}>
              <Grid item md={12} xs={12} lg={12}>
                <Box display='flex' alignItems="center">
                  <InfoIcon className={classes.paidInFullIcon} />
                  <Typography className={classes.informationText}>A foreclosure sale date has been held with respect to this property. For more information<a className={classes.clickLink} onClick={() => handlePaidInFull('foreclosure')}> Click here</a></Typography>
                </Box>
              </Grid>
            </Grid>
          </Box> }
        
        <>
        {data && loading === false && !data.isPaymentNotAllowedInvCode && !data.isACHSuspended ?

          <Box className={(data.isPaymentAllowed && data.delinquentDays < 2) ? classes.greenBanner : (data.isPaymentAllowed) ? classes.banner : classes.redBanner}>
            <Box className={data.delinquentDays < 2 ? clsx(classes.countBox, classes.whiteBorder) : data.isPaymentAllowed ? classes.countBox : clsx(classes.countBox, classes.whiteBorder)}><Typography className={data.delinquentDays < 2 ? clsx(classes.countText, classes.whiteColor) : data.isPaymentAllowed ? classes.countText : clsx(classes.countText, classes.whiteColor)}>{data.delinquentDays}</Typography></Box>
            <p className={data.delinquentDays < 2 ? clsx(classes.bannerText, classes.whiteColor) : data.isPaymentAllowed ? classes.bannerText : clsx(classes.bannerText, classes.whiteColor)}>{data.delinquentMessage}</p>
            {!data.isPaymentAllowed && <IconButton onClick={() => setOpen(true)}><ErrorIcon style={{ fill: '#fff' }} /></IconButton>}
          </Box>
          :
          null
        }
        {data && loading === false && data.isPaymentNotAllowedInvCode && !data.isACHSuspended ?
          <Box className={classes.redBanner}>
            <Box><p className={classes.whiteColor}>{ACCOUNT_TOOLTIP}</p>
            </Box>
          </Box>
          :
          null
        }
        {data && loading === false && data.isPaymentNotAllowedInvCode && data.isACHSuspended ?
          <Box className={classes.redBanner}>
            <Box><p className={classes.whiteColor}>{ACCOUNT_TOOLTIP}</p>
            </Box>
          </Box>
          :
          null
        }
        {data && loading === false && data.isACHSuspended && data.isPaymentAllowed ?
          <Box className={classes.redBanner}>
            <Box><p className={classes.whiteColor}>{achSuspensionMessage(customerCareNo)}</p>
            </Box>
          </Box>
          :
          null
        }
        {data && loading === false && data.isACHSuspended && !data.isPaymentAllowed ?
          <Box className={classes.redBanner}>
            <Box className={clsx(classes.countBox, classes.whiteBorder)}><Typography className={clsx(classes.countText, classes.whiteColor)}>{data.delinquentDays}</Typography></Box>
            <p className={clsx(classes.bannerText, classes.whiteColor)}>{data.delinquentMessage}</p>
            {!data.isPaymentAllowed && <IconButton onClick={() => setOpen(true)}><ErrorIcon style={{ fill: '#fff' }} /></IconButton>}
          </Box>
          :
          null
        }
        </>
       
       
       {data?.isRecurringAllowed && <>
       { ((paymentOption === 'ONETIMEPAYMENT' && 'One-Time Payment') && !isFromWarningPopup && data && (data?.lstRecurACH == null)) &&
         <Box className={classes.recurringDraft}>
            <Grid container direction="row" alignItems="center" style={{ paddingLeft: '5px' }}>
              <Grid item>
                <Box display='flex' alignItems="center">
                  <InfoIcon className={classes.infoIcon} />
                  <Typography className={classes.recurringDraftText}>Would you like to have your monthly payment set up as a recurring draft? <span className={classes.enrollNow} onClick={() => renderPaymentOption()}>Enroll Now</span></Typography>
                </Box>
              </Grid>
            </Grid>
          </Box> }
          </>
       }
       

        <Box className={classes.main}>
          <Box className={classes.navBar}>
            <Box
              className={paymentOption !== 'FAQ' ? classes.navItem : classes.activeNavItem}
              onClick={() => handlePaymentOption('FAQ')}
            >
              <img src={faqIcon} alt='icon' className={classes.navIcon} />
              <h3 className={paymentOption !== 'FAQ' ? classes.inActiveNavItemTitle : classes.navItemTitle}>Payment Options</h3>
            </Box>
            <Box
              className={paymentOption !== 'ONETIMEPAYMENT' ? classes.navItem : classes.activeNavItem}
              onClick={() => handlePaymentOption('ONETIMEPAYMENT')}
            >
              <img src={oneTimePayIcon} alt='icon' className={classes.navIcon} />
              <h3 className={paymentOption !== 'ONETIMEPAYMENT' ? classes.inActiveNavItemTitle : classes.navItemTitle}>One-Time Payment</h3>
            </Box>
            {data && data.isRecurringAllowed === true ?
              <Box className={paymentOption !== 'MANAGEAUTOPAY' ? classes.navItem : classes.activeNavItem}
                onClick={() => handlePaymentOption('MANAGEAUTOPAY')}
              >
                <img src={autoPayIcon} className={classes.navIcon} alt='icon' />
                <h3 className={paymentOption !== 'MANAGEAUTOPAY' ? classes.inActiveNavItemTitle : classes.navItemTitle}>Manage Autopay</h3>
              </Box>
              :
              <Box className={classes.navItem}>
                <img src={autoPayIcon} className={classes.navIcon} alt='icon' />
                <h3 className={paymentOption !== 'MANAGEAUTOPAY' ? classes.inActiveNavItemTitle : classes.navItemTitle}>Manage Autopay</h3>
              </Box>
            }

          </Box>
          <Box className={classes.mainContent}>
            {errMsg.length !== 0 ?
              <Box className={classes.mainContentWrapper}>
                <p className={classes.errText}>{errMsg}</p>
              </Box>
              : renderPaymentOptions()
            }
          </Box>
        </Box>
        {/* table */}
        {(paymentOption !== ('FAQ' || 'Payment Options')) &&
          <>
            <Box className={classes.schedulePaymentBox} >
              <Box className={classes.flexBox}>
                <h4 className={clsx(classes.headingText, classes.marginFix)}>Scheduled Payments</h4>
                <Box className={classes.printButton} textAlign="right">
                  <ReactToPrint
                    trigger={() =>
                      <Box className={classes.printIconText}>
                        <h5 className={classes.printForm}>Print</h5>
                        <PrintIcon style={{ width: '24px', height: '24px' }} />
                      </Box>}
                    content={() => printRef.current} />
                </Box>
              </Box>
              {data && loading === false && data.isACHSuspended ?
                <Box><p className={classes.achSuspension}>{achSuspensionMessage(customerCareNo)}</p>
                </Box>
                :
                null
              }
              <UpcomingPaymentTrns
                selectedAccount={selectedAccount}
                ref={printRef}
                rows={transections}
                dataError={dataError}
                deleteUpcomingSchedulePayment={deleteUpcomingSchedulePayment}
                redirectToManageAutopay={redirectToManageAutopay}
                loading={loading}
                data={data}
              />
            </Box>
            <Box>
              <PaymentTransactionHistory
                accountNumber={selectedAccount}
              />
            </Box>
          </>
        }
        <FinancialHardshipAlert
          handleClose={handleClose}
          open={open}
        />
        {deleteSuccess && <Dialog fullScreen={fullScreen}
          PaperProps={{ classes: { root: classesPopup.dialogStyle } }}
          open={deleteSuccess}>
          <Box className={classesPopup.root}>
            <Box className={classesPopup.header}>
              <IconButton className={classesPopup.iconStyle} onClick={() => handlePopupClose()}>
                <CancelIcon />
              </IconButton>
            </Box>
            <h1 className={classesPopup.successMsg}>Payment Deleted Successfully.</h1>
            <p className={classesPopup.successSubTitle}>Request ID: {confirmationNumber}</p>
            <Box className={classesPopup.buttonBox}>
              <Button onClick={() => handlePopupClose()} className={classesPopup.btn} variant="outlined" color='primary'>Ok</Button>
            </Box>
          </Box> </Dialog >}

        {deleteFailed && <Dialog fullScreen={fullScreen}
          PaperProps={{ classes: { root: classesPopup.dialogStyle } }}
          open={deleteFailed}>
          <Box className={classesPopup.root}>
            <Box className={classesPopup.header}>
              <IconButton className={classesPopup.iconStyle} onClick={() => handlePopupClose()}>
                <CancelIcon />
              </IconButton>
            </Box>
            <h1 className={classesPopup.successMsg}>Payment Delete Failed</h1>
            <p className={classesPopup.successSubTitle}>{errDeleteMsg}</p>
            <Box className={classesPopup.buttonBox}>
              <Button onClick={() => handlePopupClose()} className={classesPopup.btn} variant="outlined" color='primary'>Ok</Button>
            </Box>
          </Box> </Dialog >}
          {/* Transfer alert popup */}
          {
            transferAlertStatus && loanInfoData &&
              <TransferInfoAlert closePopup={closePopup} transferAlertStatus={transferAlertStatus} loanInfoData={loanInfoData} liquidationType={'nonPurged'}/>
          } 
          { accountAccessInfo && loanInfoData &&
            <PaidInFullPopup closePaidInFullPopup={closePaidInFullPopup} open={accountAccessInfo} selectedAccount={selectedAccount} liquidationType={liquidationType} loanInfoData={loanInfoData}/>
          }
      </Box >
    </Box>
  )
}