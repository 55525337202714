import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 12,
    backgroundColor: "#FFFFFF",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    padding: '2em',
    // height: '85vh',
    overflowY: 'auto',
    [theme.breakpoints.down('xs')]: {
      padding: '1.6em',
    },
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxWidth: '100em',
    minWidth: '400px',
    maxHeight: "100%",
    backgroundColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '95%',
      minWidth: 'auto',
      top: '10%',
    },
  },

  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },

  iconStyle: {
    padding: 0,
    color: '#535D6D',
    fontSize: '24px'
  },

  body: {
    width: '100%',
    padding: '10px 20px 30px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 5px',
    },
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '20px',
    fontWeight: "500",
    letterSpacing: '0.15px',
    lineHeight: '20px',
    paddingBottom: '17px',
    paddinTop: '7px',
  },

  flexBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  bobyText: {
    color: "rgba(0,0,0,0.74)",
    fontFamily: "Montserrat",
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '20px',
    marginBottom: '10px',
    height: '40px'
  },

  bobyTextBold: {
    fontWeight: '600',
  },

  submitAnchor: {
    width: '250px',
    color: '#535D6D',
    fontSize: '16px',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    textTransform: 'uppercase',
    height: '40px',
    padding: '13px 22px',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '6px',
    textDecoration: 'none',
    margin: '0 auto',
    display: 'block',
    textAlign: 'center',
    lineHeight: '17px',
  },

  errorMsg: {
    fontSize: '16px',
    fontWeight: '600',
    textAlign: 'center',
    fontFamily: 'Montserrat',
    color: "red",
    margin: 0
  },


}));

