export const FETCH_THEME = "FETCH_THEME";
export const FETCH_CONTENT = 'FETCH_CONTENT';
export const FETCH_CONTENT_LANDING = 'FETCH_CONTENT';
export const FETCH_ACCOUNTDATA = 'FETCH_ACCOUNTDATA';
export const FETCH_MULTI_LOAN = 'FETCH_ACCOUNTDATA';
export const FETCH_HELP_CONTENT = 'FETCH_HELP_CONTENT';
export const FETCH_HELP_TILE_CONTENT = 'FETCH_HELP_TILE_CONTENT';
export const FETCH_SECURITY = 'FETCH_SECURITY';
export const SELECT_ACCOUNT = 'SELECT_ACCOUNT';
export const FETCH_ACCOUNT = 'FETCH_ACCOUNT';
export const FETCH_MESSAGE = 'FETCH_MESSAGE';
export const FETCH_LOAN_INFO = 'FETCH_LOAN_INFO';
export const STORE_ACC_FOR_EDIT = 'STORE_ACC_FOR_EDIT';
export const CLEAR_STORE = 'CLEAR_STORE';
export const SELECT_CURRENT_ACCOUNT = 'SELECT_CURRENT_ACCOUNT';
export const SELECT_CURRENT_ACCOUNT_DATA = 'SELECT_CURRENT_ACCOUNT_DATA';
export const SET_PAYMENT_OPTION = 'SET_PAYMENT_OPTION';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
export const USER_LOGOUT = 'USER_LOGOUT';
export const HIDE_THIRD_PARTY_POPUP = 'HIDE_THIRD_PARTY_POPUP';
export const SET_EDIT_PROFILE = 'SET_EDIT_PROFILE';
export const SET_EDIT_PROFILE_DATA='SET_EDIT_PROFILE_DATA'
export const SET_UPCOMING_TRANS_STATE = 'SET_UPCOMING_TRANS_STATE';
export const FETCH_ACCOUNT_INFO = 'FETCH_ACCOUNT_INFO';
export const FETCH_FAQ_CONTENT = 'FETCH_FAQ_CONTENT';   
export const FETCH_ESCROW_CONTENT = 'FETCH_ESCROW_CONTENT';
export const RELEASE_ANNOUNCEMENT = 'RELEASE_ANNOUNCEMENT';
export const ANNOUNCEMENT_STATUS = 'ANNOUNCEMENT_STATUS';
export const EMERGENCY_ALERT = 'EMERGENCY_ALERT';
export const SHOW_TOASTER = 'SHOW_TOASTER'; //Generic toaster implementation
export const FETCH_LOAN_DATA = 'FETCH_LOAN_DATA';
