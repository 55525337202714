import React, { useState } from "react";
import { Box, Button, Dialog, IconButton, CircularProgress } from "@material-ui/core";
import { useStyles } from './styles';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import moment from 'moment';
import { deleteRecurringACHPayment } from '../../actions';
import { decrypt } from '../../helpers/encrypt'
import { isAdmin, numberWithCommas, renderDefaultMsg } from "../../helpers";

export const CancelAutoPayAlert = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('')
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { amount, lastPaymentDate, bankAccount } = props;
  const [isSuccess, setIsSuccess] = useState(false);
  const [confirmationNumber, setConfirmationNumber] = useState('');

  const handlePaymentDelete = async () => {
    setLoading(true);
    setErrMsg('');
    const res = await deleteRecurringACHPayment(props.accountNumber);
    if (res && res.confirmationNumber) {
      setLoading(false);
      setIsSuccess(true)
      setConfirmationNumber(res.confirmationNumber)
    } else {
      setErrMsg(renderDefaultMsg('400'));
      setLoading(false);
    }
  }

  const handleClose = () => {
    if (isSuccess) {
      props.toggleOpen();
      props.handlePaymentOption("FAQ")
    } else {
      props.toggleOpen();
    }
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      PaperProps={{ classes: { root: classes.dialogStyle } }}
      onClose={handleClose}
      open={props.open}
    >
      {!isSuccess ?
        <Box className={classes.root}>
          <Box className={classes.header}>
            <IconButton className={classes.iconStyle} onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </Box>
          <Box className={classes.body}>
            <h1 className={classes.title}>Cancel  Autopay Settings</h1>
            {errMsg.length !== 0 ? <p className={classes.errMsg}>{errMsg}</p> : null}
            <p className={classes.subTitle}>The new Autopay set up process may take one or two business days to reflect on your bank account. We encourage you to keep that in mind before canceling current settings!</p>
            <Box className={classes.flexBox}>
              <Box className={classes.item}>
                <h3 className={classes.itemText}>Amount</h3>
                <h3 className={classes.itemSubText}>${numberWithCommas(parseFloat(amount).toFixed(2))}</h3>
              </Box>
              <Box className={classes.item}>
                <h3 className={classes.itemText}>Payment Date </h3>
                <h3 className={classes.itemSubText}>{moment(lastPaymentDate).format("ll")}</h3>
              </Box>
              <Box className={classes.item}>
                <h3 className={classes.itemText}>Bank Account</h3>
                <h3 className={classes.itemSubText}>{bankAccount}</h3>
              </Box>
            </Box>
            <Box className={classes.buttonBox}>
              <Button onClick={props.toggleOpen} className={classes.btn} variant="outlined">Cancel</Button>
              <Button onClick={() => handlePaymentDelete()} disabled={isAdmin() || loading} className={classes.btn} variant="contained" color='primary' >{loading ? <CircularProgress size={20} color='inherit' /> : 'Confirm'}</Button>
            </Box>
            {/* <p className={classes.footerText}>If you have any further payment related question please visit <Link to='/' className={classes.anchorLinkStyle}>Payment FAQs </Link></p> */}
          </Box>
        </Box>
        :
        <Box className={classes.root}>
          <Box className={classes.header}>
            <IconButton className={classes.iconStyle} onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </Box>
          <h1 className={classes.successMsg}>Payment Deleted Successfully.</h1>
          <p className={classes.successSubTitle}>Request ID: {decrypt(confirmationNumber)}</p>
          <Box className={classes.buttonBoxOk}>
            <Button
              onClick={handleClose}
              className={classes.btn}
              variant="outlined"
              disabled={isAdmin()}
            >
              Ok
            </Button>
          </Box>
        </Box>
      }
    </Dialog >
  )
}