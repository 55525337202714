export const styles = theme => ({
  h1: {
    margin: '0',
    background: theme.header.backgrounColor,
    color: theme.header.fontColor,
    padding: '7px 12px',
    fontSize: '16p'
  },
  popupBody: {
    padding: '20px 30px 30px 30px',
    fontSize: '14px'
  },
  closeIcon:{
     float:'right',
     cursor:'pointer',
     width:'18px',
     height:'18px',
     marginTop:'-10px',
     right: '4px',
     position: 'absolute'
  },
  closeIconMsg:{
    float:'right',
    cursor:'pointer',
    width:'18px',
    height:'18px',
    marginTop:'2px',
    right: '4px',
    position: 'absolute'
 },
  warningPopup:{
    borderBottom: '1px solid #C4C6CA',
    width: '85%',
    margin: '8px auto',
    color: '#555D6C',
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '20px',
    background:'white'
  },
  warningIcon:{
    height: '18px',
    background: 'transparent',
    float: 'left',
    margin: '20px',
    marginLeft: '40px',
    width: '18px'
  },
  popup: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    margin: 'auto',
    backgroundColor: 'rgba(0,0,0, 0.5)',
    zIndex: 10000,

    overflow: 'scroll',
  },
  popup_inner: {
    position: 'absolute',
    margin: 'auto',
    background: 'white',
    borderRadius: '6px',
    maxWidth: '37%',
    width: '100%',
    left: '0',
    right: '0',
    top: '8%',
    maxHeight: '80vh',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
    },
  }
});