import React, { useState, useEffect, useRef } from "react";
import {
  Box, Button, CircularProgress, FormControlLabel,
  Radio, RadioGroup, FormControl, InputLabel, Select,
  MenuItem, Table, TableBody, TableContainer,
  TableHead, TableRow, Paper, TextField, InputAdornment,
  Tooltip, Typography} from "@material-ui/core";
import { useStyles, StyledTableCell, StyledTableRow, LightTooltip, RedTooltip, SimpleTooltip, WhiteTooltip } from './styles';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InfoIcon from '@material-ui/icons/Info';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DeleteIcon from '@material-ui/icons/Delete';
import Checkbox from '@material-ui/core/Checkbox';
import DateFnsUtils from '@date-io/date-fns';
import clsx from "clsx";
import { maskAccount, scrollTop, checkDigitWithDecimal, numberWithCommas, renderDefaultMsg, maskAccountNo } from '../../helpers';
import { decrypt } from '../../helpers/encrypt';
import { getMLRecurringACHInfo, addMLRecurringACHPayment, getContent, setPaymentOption, getBankAccountDetail, upcomingTransState } from '../../actions';
import { DeleteAutoPayAlertMultiLoan, SnackBar, MultiLoanSuccessAlert, AdditionalPaymentMultiAlert } from '../../components';
import { AddEditAccount } from '../SharedComponent/AddBankAccAlert';
import { ProcessFeeAlert } from '../SharedComponent/ProcessFeeAlert';
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@material-ui/icons/Print';
import Cookies from 'js-cookie';
import { AdditionalPaymentAlert } from "../AdditionalPaymentAlert";
import { useHistory } from 'react-router-dom';
import {MULTILOAN_ACCOUNT_TOOLTIP } from '../../helpers/constants';
import { achSuspensionMessage } from '../../helpers';
import { LateFeeAckAlert } from "../SharedComponent/LateFeeAckAlert";
import Popup from "../PopUp";
import Modal from '@material-ui/core/Modal';
import HelpIcon from '@material-ui/icons/Help';
import Backdrop from '@material-ui/core/Backdrop';
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles } from '@material-ui/core/styles';

const LightToolTip = withStyles((theme) => ({
  arrow: {
    color: '#235A86',
    // backgroundColor: '#ffffff',
  },
  tooltip: {
    backgroundColor: '#235A86',
    color: '#fffff',
    fontFamily: "Montserrat",
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    padding: "16px",
    fontSize: 14,
    maxWidth: 350,
    fontWeight: 500,
    borderRadius: 8,
    // border: "1px solid #4D4D4D"
  },
}))(Tooltip);

const businessMoment = require('moment-business-days');




export const ManageAutoPayMultiLoan = (props) => {
  const classes = useStyles();
  const upcmingTransState = useSelector(state => state.upcomingTransState);
  const history = useHistory();
  const componentRef = useRef();
  const [paymentFrequncyType, setPaymentFrequncyType] = useState("M");
  const [selectedBank, setSelectedBank] = useState('');
  const [loans, setLoans] = useState([]);
  const [initialLoans, setInitialLoans] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [selectedDeleteAccount, setSelectedDeleteAccount] = useState(null);
  const [submitData, setSubmitData] = useState(false);
  const [msg, setMsg] = useState("");
  const [hasError, setHasError] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [successData, setSuccessData] = useState([]);
  const [tncSummary, setTncSummary] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [selectedDate, setSelectedDate] = React.useState(businessMoment().businessAdd(3)._d);
  const [isOpen, setIsOpen] = useState(false);
  const [processFeeAlert, setProcessFeeAlert] = useState(false);
  const [warningPopup, setWarningPopup] = useState({show:false,message:''})
  const [latePaymentDate, setLatePaymentDate] = useState();
  const dispatch = useDispatch();
  const [newAddedAccount, setNewAddedAccount] = useState(null)
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [accountNumber, setAccountNumber] = useState();
  const [howItWorks, setHowItWorks] = useState()
  const [openHowItWorksPopup,setOpenHowItWorksPopup] = useState(false)
  const [ackPopup, setAckPopup] = useState(false);
  const [BiWeeklyLateFeeAck, setBiWeeklyLateFeeAck] = useState(false);
  let clientName = Cookies.get('clientName');
  clientName = clientName?.toLowerCase()?.trim();
  const isToorak = clientName === 'toorak';
  const [isToorakLoan,setToorakLoan] = useState(isToorak);
  const [lowPrincipalBalanceData, setLowPrincipalBalanceData] = useState([]);
  const [lateFee, setLatePaymentType] = useState();

  const getContentData = async () => {
    const res = await dispatch(getContent('PC017'));
    if (res && res.length !== 0) {
      const biWeeklyContent = res?.filter(key => key.contentKey == 'Bi-WeeklyHowItWorks')
      const termAndCondition = res?.filter(key => key.contentKey == 'T&C')
      if (termAndCondition?.length > 0) {
        setTncSummary(termAndCondition[0].contentValue)
      }
      if (biWeeklyContent?.length > 0) {
        setHowItWorks(biWeeklyContent[0].contentValue)
      } 
    }
  }

  const howItWorksPopup = () => {
    setOpenHowItWorksPopup(true);
  }
  const closeHowItWorksPopup = () => {
    setOpenHowItWorksPopup(false);
  }

  function disableWeekends(date) {
    if (paymentFrequncyType !== 'M') {
      return (date.getDay() === 0 || date.getDay() === 6)
    }
    return false
  }

  const handleChange = (event) => {
    setPaymentFrequncyType(event.target.value)
  }

  const handleBankAccountChange = (event) => {
    setSelectedBank(event.target.value);
  }

  const showPopupAccount = () => {
    setShowPopup(true);
  }

  const ToggleAccountPopup = (saved = null) => {
    if (saved === 'Saved') {
      getBankAccounts();
    }
    setShowPopup(!showPopup);
  }

  useEffect(() => {
    getData();
    getContentData();
    getBankAccounts();
  }, [])

  useEffect(() => {
    if (bankAccounts && bankAccounts.length !== 0) {
      const selectBankAccDetail = bankAccounts.find(bankAccount => bankAccount.bankAccountNumber === newAddedAccount);
      if (selectBankAccDetail) {
        setSelectedBank(selectBankAccDetail);
        setNewAddedAccount(null);
      } else {
        const selectBankAccPreff = bankAccounts.find(bankAccount => bankAccount.isPreferredAcc === true);
        selectBankAccPreff ? setSelectedBank(selectBankAccPreff) : setSelectedBank(bankAccounts[0])
      }
      setBankAccounts(bankAccounts)

    }
  }, [bankAccounts])

  const newAddedBankAccount = (newAddedBank) => {
    setNewAddedAccount(newAddedBank);
  }

  const getBankAccounts = async () => {
    const res = await getBankAccountDetail();
    if (res && res.lstBEBankAccountDetails && res.lstBEBankAccountDetails.length !== 0) {

      const isPreferredAcc = res.lstBEBankAccountDetails.filter(bankDetail => bankDetail.isPreferredAcc === true);
      isPreferredAcc.length === 0 ? setSelectedBank(res.lstBEBankAccountDetails[0]) : setSelectedBank(isPreferredAcc[0]);
      setBankAccounts(res.lstBEBankAccountDetails)

    }
  }

  const getData = async () => {
    setLoading(true)
    scrollTop();
    const res = await getMLRecurringACHInfo(props.selectedAccount);
    if (res && res.lstResponseStatus && res.lstResponseStatus[0].statusCode === '0') {
      let lstMultiRec = [...res.lstMultipleRecurringPayment]
      lstMultiRec = lstMultiRec.map((loan) => {
      setSelectedDate(businessMoment(loan.estDateTime).businessAdd(3)._d);
      if (businessMoment(loan.paymentDueDate, 'MM-DD-YYYY').businessDiff(businessMoment(businessMoment(loan.estDateTime).businessAdd(3)._d, 'MM-DD-YYYY'), true) > 0) {
         loan.paymentDueDate = loan.paymentDueDate
      } else {
         loan.paymentDueDate = businessMoment(loan.estDateTime).businessAdd(3)._d
      }
       return loan;
      })
     
      setLoans(lstMultiRec)
      // setLoans(res.lstMultipleRecurringPayment)
      setInitialLoans(res.lstMultipleRecurringPayment.map(loan => { return { ...loan } }))
      setLoading(false)
    }
  }
  

  const handleTotalAmount = (loan) => {
    if (loan.lstRecurACH && loan.lstRecurACH.length !== 0) {
      const total = loan.lstRecurACH[0].draftAmount1;
      return parseFloat(total).toFixed(2);
    } else {
      let total = parseFloat(paymentFrequncyType === "M" ? loan.monthlyAmountDue : loan.monthlyAmountDue / 2);
      if (loan.additionalPrincipal) {
        total = total + parseFloat(loan.additionalPrincipal)
      }
      if (loan.additionalEscrow) {
        total = total + parseFloat(loan.additionalEscrow)
      }
      // return parseFloat(total).toFixed(2);
      return total.toFixed(2);
    }
  }

  const handlePrincipalAmount = index => (event) => {
    const newLoans = [...loans];
   
    if (checkDigitWithDecimal(event.target.value)) {
      if (loans[index].lstRecurACH && loans[index].lstRecurACH.length !== 0) {
        const newLoans = [...loans];
        newLoans[index].lstRecurACH[0].draftAdditionalPrincipal = event.target.value ? event.target.value : '';
        // newLoans[index].additionalEscrow = event.target.value ? parseFloat(event.target.value) : '';
        setLoans(newLoans);
        return;
      }
      newLoans[index].additionalPrincipal = event.target.value ? event.target.value : '';
      // newLoans[index].additionalPrincipal = event.target.value ? parseFloat(event.target.value) : '';
      setLoans(newLoans);
    }

    if (newLoans[index].additionalPrincipal >= newLoans[index].unpaidPrincipalBalance) {
      newLoans[index].additionalPrincipalAmountError = true;
      // newLoans[index].isSelected = false;
    } else {
      newLoans[index].additionalPrincipalAmountError = false;
      // newLoans[index].isSelected = true;
    }

    if(loans[index].isSelected) {
      newLoans[index].isSelected = false;
      setLoans(newLoans)
    }
  }

  const handleEscrowAmount = index => (event) => {
    const newLoans = [...loans];
    if (checkDigitWithDecimal(event.target.value)) {
      if (loans[index].lstRecurACH && loans[index].lstRecurACH.length !== 0) {
        const newLoans = [...loans];
        newLoans[index].lstRecurACH[0].draftAdditionalEscrow = event.target.value ? event.target.value : '';
        // newLoans[index].additionalEscrow = event.target.value ? parseFloat(event.target.value) : '';
        setLoans(newLoans);
        return;
      }
      const newLoans = [...loans];
      newLoans[index].additionalEscrow = event.target.value ? event.target.value : '';
      // newLoans[index].additionalEscrow = event.target.value ? parseFloat(event.target.value) : '';
      setLoans(newLoans);
    }
    if(loans[index].isSelected) {
      newLoans[index].isSelected = false;
      setLoans(newLoans)
    }
  }

  const handleDraftDate = index => (date) => {
    const newLoans = [...loans];
    newLoans[index].paymentDueDate = moment(date).format("L");
    setLoans(newLoans);
  }

  // calender dates
  const renderDayInPicker = index => (date, selectedDate, dayInCurrentMonth, dayComponent) => {
    const newLoans = [...loans];
    let latePaymentGreenDate = businessMoment().businessAdd(3)._d;
    if (businessMoment(initialLoans[index].paymentDueDate, 'MM-DD-YYYY').businessDiff(businessMoment(latePaymentGreenDate, 'MM-DD-YYYY'), true) > 0) {
      latePaymentGreenDate = initialLoans[index].paymentDueDate
    }

    if (businessMoment(latePaymentGreenDate, 'MM-DD-YYYY').diff(businessMoment(date, 'MM-DD-YYYY'), true) === 0) {
      return (<div className={`${classes.paymentDayComponent}`}>
        {dayComponent}
      </div>)
    }

    // new Date(state.latePaymentDate).getTime() >= date.getTime()
    if (businessMoment(newLoans[index].latePaymentDate, 'MM-DD-YYYY').businessDiff(businessMoment(date, 'MM-DD-YYYY'), true) >= 0) {
      return (<div className={`${classes.greenZoneComponent}`}>
        {dayComponent}
      </div>)
    }

    // new Date(state.latePaymentDate).getTime() < date.getTime()
    if (businessMoment(newLoans[index].latePaymentDate, 'MM-DD-YYYY').businessDiff(businessMoment(date, 'MM-DD-YYYY'), true) < 0) {
      return (<div className={`${classes.redZoneComponent}`}>
        {dayComponent}
      </div>)
    }
    return dayComponent
  }
  /**isFromWarning is a  flag  when user redirect from late payment warning popup 
   * isFromWarningMultiL is a flag when redirect from late payment flow warning popup multiloan
   */
  const routeToManageAutoPay = (isFromWarning,isFromWarningMultiL) => {
    setWarningPopup({show: false, message: ''})
    props.handlePaymentOption("ONETIMEPAYMENT",isFromWarning,isFromWarningMultiL);
  }

  const handleDateChange = index => async (date) => {

    const newLoans = [...loans];
    newLoans[index].paymentDueDate = moment(date).format('L');
    newLoans[index]['draftDate2'] = moment(date).add(newLoans[index]?.daysToCalculateSecondDraftDate, 'days').format('L'); 
    newLoans[index].isSelected = false;
    setLoans(newLoans);
    setLatePaymentDate(newLoans[index]?.latePaymentDate)

    /*1. if draftdate1 and draftdate2 both are greater than grace period
        show warning and below message*/
    /*2. if  draftdate2 is greater than grace period
          show warning and below message*/
       
    // if (businessMoment(date, 'MM-DD-YYYY').businessDiff(businessMoment(newLoans[index].latePaymentDate, 'MM-DD-YYYY'), true) > 0) {
    //   if (((paymentFrequncyType !== 'B') && (newLoans[index]?.lstRecurACH == null)) || (newLoans[index]?.lstRecurACH && newLoans[index]?.lstRecurACH[0]?.draftingOption !== "B")) {
    //     setProcessFeeAlert(true);
    //     setIsOpen(true)
    //   }
    // } 
    
    // else if(businessMoment(moment(date).add(newLoans[index]?.daysToCalculateSecondDraftDate, 'days').format('L') , 'MM-DD-YYYY').businessDiff(businessMoment(newLoans[index].latePaymentDate, 'MM-DD-YYYY'), true) > 0) {
    //   if (((paymentFrequncyType == 'B') && (newLoans[index]?.lstRecurACH == null)) || (newLoans[index]?.lstRecurACH && newLoans[index]?.lstRecurACH[0]?.draftingOption == "B")) {
    //    setWarningPopup({show:true,message:'msg2'});
    //   }
    // }
  }

  const renderSelectedDate = (loan) => {
    if (loan.lstRecurACH && loan.lstRecurACH.length !== 0) {
      return loan.lstRecurACH[0].nextDraftDate;
    } else {      
      if (businessMoment(loan.paymentDueDate, 'MM-DD-YYYY').businessDiff(businessMoment(selectedDate, 'MM-DD-YYYY'), true) > 0) {
        return loan.paymentDueDate
      } else {
        return selectedDate
      }
    }
  }

  const renderSecondDraftDate = (loan) => {
    
    if (loan.lstRecurACH && loan.lstRecurACH.length !== 0) {
      return loan.lstRecurACH[0].draftDate2;
    } else {
      if (loan?.draftDate2 == null) {
        if (businessMoment(loan.paymentDueDate, 'MM-DD-YYYY').businessDiff(businessMoment(selectedDate, 'MM-DD-YYYY'), true) > 0) {
          return moment(loan.paymentDueDate).add(loan?.daysToCalculateSecondDraftDate, 'days').format('L') 
        } else {
          return moment(selectedDate).add(loan?.daysToCalculateSecondDraftDate, 'days').format('L') 
        }
      } else {
        return loan?.draftDate2
      } 
    }
  }

  const toggleProcessFeeAlert = () => {
    setLatePaymentType()
    setProcessFeeAlert(!processFeeAlert)
  }

  const toggleProcessFeeWarning = () => {
    setWarningPopup({show:!warningPopup.show, message: ''})
  }
  // end calender date
 
  const principalAmountOnBlur = index => (event) => {
    const newLoans = [...loans];
    if (newLoans[index].hasOwnProperty("isSelected")) {
      if (newLoans[index].isSelected === true) {
        if (newLoans[index].additionalPrincipal >= newLoans[index].unpaidPrincipalBalance) {
          newLoans[index].additionalPrincipalAmountError = true;
          newLoans[index].isSelected = false;
        } else {
          newLoans[index].additionalPrincipalAmountError = false;
          newLoans[index].isSelected = true;
        }
      }
    }
    setLoans(newLoans);
  }

  const checkWarningStatus = (index) => {
    setLatePaymentType()
    if (businessMoment(loans[index]?.paymentDueDate, 'MM-DD-YYYY').businessDiff(businessMoment(loans[index].latePaymentDate, 'MM-DD-YYYY'), true) > 0) {
      if (((paymentFrequncyType == 'B') && (loans[index]?.lstRecurACH == null)) || (loans[index]?.lstRecurACH && loans[index]?.lstRecurACH[0]?.draftingOption == "B")) {
        setWarningPopup({show:true,message:'msg1'});
      } else {
        setLatePaymentType('late')
        setProcessFeeAlert(true);
        setIsOpen(true)
      }
    } else if(businessMoment(moment(loans[index]?.paymentDueDate).add(loans[index]?.daysToCalculateSecondDraftDate, 'days').format('L') , 'MM-DD-YYYY').businessDiff(businessMoment(loans[index].latePaymentDate, 'MM-DD-YYYY'), true) > 0) {
      if (((paymentFrequncyType == 'B') && (loans[index]?.lstRecurACH == null)) || (loans[index]?.lstRecurACH && loans[index]?.lstRecurACH[0]?.draftingOption == "B")) {
       setWarningPopup({show:true,message:'msg2'});
      }
    }
    
  }

  const handleLoanSelection = (index) => {
    const newLoans = [...loans];
    if (newLoans[index].hasOwnProperty("isSelected")) {
      if (newLoans[index].isSelected) {
        newLoans[index].isSelected = false;
      } else {
        checkWarningStatus(index)
        // newLoans[index].isSelected = true;
      if (newLoans[index].additionalPrincipal >= newLoans[index].unpaidPrincipalBalance) {
        newLoans[index].additionalPrincipalAmountError = true;
        newLoans[index].isSelected = false;
      } else {
        newLoans[index].additionalPrincipalAmountError = false;
        newLoans[index].isSelected = true;
      }
     }
    } else {

      // newLoans[index].isSelected = true;
      if (newLoans[index].additionalPrincipal >= newLoans[index].unpaidPrincipalBalance) {
        newLoans[index].additionalPrincipalAmountError = true;
        newLoans[index].isSelected = false;
      } else {
        newLoans[index].additionalPrincipalAmountError = false;
        newLoans[index].isSelected = true;
        checkWarningStatus(index)
      }
    }
    setLoans(newLoans);
  }

  const renderPaymentTotal = () => {
    let paymentTotal = 0;
    loans.filter(loan => loan.isSelected === true)
      .map((loan) => {
        if (paymentFrequncyType === "M") {
          paymentTotal = paymentTotal + Number(loan.monthlyAmountDue) + Number(loan.additionalPrincipal) + Number(loan.additionalEscrow);
        } else {
          paymentTotal = paymentTotal + (Number(loan.monthlyAmountDue) / 2) + Number(loan.additionalPrincipal) + Number(loan.additionalEscrow);
        }
      });
    return parseFloat(paymentTotal).toFixed(2);
  }

  const handleDelete = async (index) => {
    setOpenDeleteAlert(true);
    setSelectedDeleteAccount(loans[index]);
  }

  const handleDeleteCheck = (index) => {
    if (businessMoment(loans[index].lstRecurACH[0].nextDraftDate, 'MM-DD-YYYY').businessDiff(businessMoment(moment(), 'MM-DD-YYYY'), true) >= 3) {
      return true
    } else {
      return false
    }
  }



  const handleCloseDeleteAlert = async (flag = false) => {
    if (flag) {
      await dispatch(upcomingTransState(!upcmingTransState));
      getData()
    }
    setOpenDeleteAlert(false)
  }

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false)
  }

  const handleCloseSuccessAlert = () => {
    setOpenSuccessAlert(false);
    getData()
  }

  const handleOpenSuccessAlert = () => {
    setOpenSuccessAlert(true)
  }

  const handleCloseAlert = () => {
    setAlertOpen(false)
    makePayment()
  }
 
  const handleClose = () => {
    setAlertOpen(false);
  }
  
  const routeToRequestPayOff = () => {
    Cookies.set('selectedAccount', accountNumber);
    history.push('/PayoffRequest');
  }
 
  const makePayload = () => {
    const selectedLoans = loans.filter(item => item.isSelected === true);
    const list = selectedLoans.map((item) => {
      //let draftDate2 =  moment(item.paymentDueDate).add(item?.daysToCalculateSecondDraftDate, 'days').format('L')
      let draftDate2 = (item?.lstRecurACH && item?.lstRecurACH?.length > 0) ? item?.lstRecurACH[0]?.draftDate2 : item?.draftDate2 == null ? moment(item.paymentDueDate).add(item?.daysToCalculateSecondDraftDate, 'days').format('L') : item?.draftDate2 || '';
       return {
        "paymentAmount": paymentFrequncyType === "M" ? item.monthlyAmountDue : (item.monthlyAmountDue / 2),
        "accountNumber": item.accountNumber,
        "abaRoutingNumber": selectedBank.abaRoutingNumber,
        "bankAccountNumber": selectedBank.bankAccountNumber,
        "accountType": selectedBank.accountType,
        "bankCity": selectedBank.bankCity && selectedBank.bankCity.length ? selectedBank.bankCity : 'NA',
        "bankName": selectedBank.bankName && selectedBank.bankName.length ? decrypt(selectedBank.bankName) : 'NA',
        "bankState": selectedBank.bankState && selectedBank.bankState.length ? selectedBank.bankState : 'NA',
        "draftStartDate": item.paymentDueDate,
        "draftingOption": paymentFrequncyType,
        "nameOnAccount": selectedBank.borrowerName,
        "borrowerType": selectedBank.borrowerType,
        "additionalPrincipal": item.lstRecurACH && item.lstRecurACH.length !== 0 ? item.lstRecurACH[0].draftAdditionalPrincipal : item.additionalPrincipal,
        "additionalEscrow": item.lstRecurACH && item.lstRecurACH.length !== 0 ? item.lstRecurACH[0].draftAdditionalEscrow : item.additionalEscrow,
        "draftDate1": "",
        // "draftDate2": draftDate2 || '',
        "biWeeklyLateFeeAck": draftDate2 ? BiWeeklyLateFeeAck : false,
        "action": "E",
        "graysDays": item.graceDays,
        "propertyState": item.propertyState,
        "investorCode": item.investorCode,
        "bankProfileID": selectedBank.bankProfileID,
        "delinquentDays": item.delinquentDays,
      }
    });

    return list;
  }
 
  const makePayment = async () => {
    setSubmitData(true);
    setHasError(false);
    setMsg('')
    const list = makePayload();
    const res = await addMLRecurringACHPayment(list);
    if (res && res.lstResponseStatus[0].statusCode === '0') {
      setSuccessData(res.lstMLRecurringPayment);
      setSubmitData(false);
      handleOpenSuccessAlert();
    } else {
      setHasError(true);
      setMsg(res ? res.lstResponseStatus[0].statusDesc : renderDefaultMsg('400'));
      setSubmitData(false);
      setOpenSnackBar(true)
    }
  }
/**
 * Check acknowledement popup conditions and show ACK propup
 * Show selected warning popup 
 */
  const checkAckCondtions = (selectedLoan)=> {
  
    let flag = 0;
    selectedLoan.map((loan)=> {
      if (paymentFrequncyType == 'B' && businessMoment(loan?.paymentDueDate, 'MM-DD-YYYY').businessDiff(businessMoment(loan?.latePaymentDate, 'MM-DD-YYYY'), true) > 0) {
          flag = 1;
      } else if (paymentFrequncyType == 'B' && businessMoment(moment(loan?.paymentDueDate).add(loan?.daysToCalculateSecondDraftDate, 'days').format('L') , 'MM-DD-YYYY').businessDiff(businessMoment(loan?.latePaymentDate, 'MM-DD-YYYY'), true) > 0) {
        if (((paymentFrequncyType == 'B') && (loan?.lstRecurACH == null)) || (loan?.lstRecurACH && loan?.lstRecurACH[0]?.draftingOption == "B")) {
          flag = 1;
        }
      } else if (paymentFrequncyType == 'M' && businessMoment(loan?.paymentDueDate, 'MM-DD-YYYY').businessDiff(businessMoment(loan?.latePaymentDate, 'MM-DD-YYYY'), true) > 0)   {
          flag = 1;
       } 
      });
      return flag;
  }

  const handleSubmit = async (proceed=false) => {

    const selectedLoan = loans.filter(item => item.isSelected === true);
    let flag = 0;
    if(!proceed){
      flag = checkAckCondtions(selectedLoan);
    }
    if(flag==1){
      setTimeout(()=>{
        setAckPopup(true);
      },100)  
      return;
    }

    setAckPopup(false);
    setSubmitData(true);
    setHasError(false);
    setMsg('')

    const checkAdditionalPayment = selectedLoan.map((item) => {
      if(item.additionalPrincipal > (item.unpaidPrincipalBalance - 5000)) {
        return {
          status: true,
          accNumber: item.accountNumber
        };
      } else {
        return {
          status: false,
          accNumber: item.accountNumber
        };
      }
    });
    const statusItem = checkAdditionalPayment.map((item) => {
      if(item.status) {
        return {
          status: item.status,
          accNumber: item.accNumber
        };
      } 
    });
    setLowPrincipalBalanceData(checkAdditionalPayment)
    if (statusItem[0]?.status) 
    { 
      setAccountNumber(statusItem[0].accNumber);
      setAlertOpen(true)
      setSubmitData(false);
    } else {
    const list = makePayload();
    const res = await addMLRecurringACHPayment(list);
    if (res && res.lstResponseStatus[0].statusCode === '0') {
      setSuccessData(res.lstMLRecurringPayment);
      setSubmitData(false);
      handleOpenSuccessAlert();
    } else {
      setHasError(true);
      setMsg(res ? res.lstResponseStatus[0].statusDesc : renderDefaultMsg('400'));
      setSubmitData(false);
      setOpenSnackBar(true)
    }
   }
  }

  const disableMakePayment = () => {
    const selectedLoans = loans.filter(item => item.isSelected === true);
    if (selectedLoans.length !== 0) {
      return false
    }
    return true
  }
   //When click on continue on Acknowledgment popup
   const handleAckSubmit = ()=>{
    //scheduleAutoPay();
    setAckPopup(false);
  }
   //Close Acknowledgement popup
   const handleAckPopupClose=()=>{
    setAckPopup(false)
  }
  //BiWeeklyLateFeeAck

  const TextFieldComponent = (props) => {
    return <TextField {...props} disabled={true} />
  }
 
  if (loans.length !== 0 && loading === false) {
    return (
      <Box className={classes.root} ref={componentRef}>
        <Box className={classes.payFrequency}>
          <h3 className={classes.sectionTitle}>Payment Frequency</h3>
          <Button onClick={() => props.handleMultiLoan()} className={classes.switchBackBtn}>SWITCH BACK TO SINGLE LOAN PAYMENT</Button>
        </Box>
        <RadioGroup value={paymentFrequncyType} className={classes.frequencyContainer} onChange={handleChange}>
          <Box className={classes.labelContainer}>
            <Box className={classes.flex}>
              <FormControlLabel
                value="M"
                control={<Radio classes={{ root: classes.radioStyle }} />} label="Regular Monthly Payment"
                classes={{
                  label: paymentFrequncyType === "M" ? classes.activeRadioLabel : classes.radioLabel
                }}
              />
              <LightToolTip title="Regular monthly payment of principal, interest, and escrow" arrow placement="bottom">
                <InfoIcon style={{ fill: '#535D6D' }} />
              </LightToolTip>
            </Box>
            <Box className={clsx(classes.flexBetween)}>
              <p className={clsx(classes.subText, classes.subTextPadding)}>Regular monthly payment of principal, interest, and escrow</p>
              {/* <p className={classes.subText}>Charged once a Month</p> */}
            </Box>
          </Box>
          {loans[0].isBiWeeklyAllowed && <Box>
          <Box className={classes.labelContainer}>
            <Box className={classes.flex}>
              <FormControlLabel
                value='B'
                control={<Radio classes={{ root: classes.radioStyle }} />}
                label="Bi-Weekly Payment"
                classes={{
                  label: paymentFrequncyType === "B" ? classes.activeRadioLabel : classes.radioLabel
                }}
              />
              {/* <Tooltip title="Pay half of your monthly amount every two weeks.">
                <InfoIcon style={{ fill: '#535D6D' }} />
              </Tooltip> */}
              <LightToolTip title="Pay half of your monthly amount every two weeks." arrow placement="bottom">
                  <HelpIcon  style={{ fill: '#535D6D' }}  />
              </LightToolTip>
              <a className={classes.howDWork} onClick={howItWorksPopup}>How does it work?</a>
            </Box>
            <Box className={clsx(classes.flexBetween)} >
              <p className={clsx(classes.subText, classes.subTextPadding)}>Pay half of your monthly amount every two weeks.</p>
              {/* <p className={classes.subText}>Every other week</p> */}
            </Box>
          </Box>
          </Box>}
        </RadioGroup >
        <Box className={classes.payInfo}>
          <Box className={classes.payInfoHeader}>
            <h3 className={classes.sectionTitle}>Payment Information</h3>
          </Box>
          <Box className={classes.bankAccountContainer}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel className={classes.textField} id="select-account">Bank Account</InputLabel>
              <Select
                labelId="select-account"
                id="select-account-outlined"
                label="Bank Account"
                value={selectedBank}
                onChange={handleBankAccountChange}
              >
                {bankAccounts && bankAccounts.length !== 0 &&
                  bankAccounts.map((bank) => {
                    return (
                      <MenuItem
                        key={bank.bankAccountNumber}
                        value={bank}
                        className={classes.textField}
                      >
                        {`${bank.nickName} ${maskAccountNo(decrypt(bank.bankAccountNumber))}`}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
            <Box className={classes.addBtnContainer}>
              <Button
                startIcon={<AddCircleOutlineIcon />}
                className={classes.addCount}
                onClick={showPopupAccount}
              >
                Add Bank Account
          </Button>
            </Box>
          </Box>
        </Box>

        <Box className={classes.multiloanTable}>
          <Box className={classes.meaningBox}>
            <h3 className={classes.meaningBoxTitle}>Payment Amount:-</h3>
            <p className={classes.meaningBoxText}><b>M:</b> Regular Monthly Payment</p>
            <p className={classes.meaningBoxText}><b>B:</b> Bi-Weekly Payment</p>
          </Box>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell className={classes.tableHeadCell}>Loan Number</StyledTableCell>
                  <StyledTableCell className={classes.tableHeadCell} align="center">Payment Amount</StyledTableCell>
                  <StyledTableCell className={classes.tableHeadCell} align="center">Add Principal Amount</StyledTableCell>
                  <StyledTableCell className={classes.tableHeadCell} align="center">Add Escrow Amount</StyledTableCell>
                  <StyledTableCell className={classes.tableHeadCell} align="center">Total Amount</StyledTableCell>
                  <StyledTableCell className={classes.tableHeadCell} align="center">Bank Account</StyledTableCell>
                  <StyledTableCell className={classes.tableHeadCell} align="center">Draft Date <Box className={classes.est}>Eastern Time</Box></StyledTableCell>
                  <StyledTableCell className={classes.tableHeadCell} align="center">Select</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loans.map((loan, index) => {
                  return (
                    <StyledTableRow key={loan.accountNumber}>
                      <StyledTableCell component="th" scope="row">
                        <Box className={classes.toolTipCell}>
                          <LightToolTip title={`${decrypt(loan.accountNumber)} - ${loan.propertyAddressLine1}`}>
                            <p className={classes.tableText}>{maskAccount(decrypt(loan.accountNumber))}</p>
                          </LightToolTip>
                          {!loan.isPaymentAllowed && !loan.isPaymentNotAllowedInvCode &&
                          !loan.isACHSuspended &&
                            <RedTooltip title={loan.delinquentDays + ' ' + loan.delinquentMessage}
                              arrow color="red">
                              <InfoIcon style={{ fill: '#E30909' }} />
                            </RedTooltip> 
                          }
                          {loan.isPaymentAllowed && !loan.isPaymentNotAllowedInvCode &&
                            loan.isRecurringAllowed === false  && !loan.isACHSuspended &&
                              <LightTooltip title={loan.delinquentDays + ' ' + loan.delinquentMessage}
                                arrow>
                                <InfoIcon style={{ fill: '#FFA500' }} />
                              </LightTooltip>
                          }
                           {loan.isPaymentNotAllowedInvCode && !loan.isACHSuspended &&
                            <RedTooltip title={MULTILOAN_ACCOUNT_TOOLTIP}
                              arrow color="red">
                              <InfoIcon style={{ fill: '#E30909' }} />
                            </RedTooltip> 
                          }
                          {
                            loan.isACHSuspended && 
                            <RedTooltip title={achSuspensionMessage(props.customerCareNo)}
                              arrow color="red">
                              <InfoIcon style={{ fill: '#E30909' }} />
                            </RedTooltip> 
                          }
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        ${loan.lstRecurACH === null ? paymentFrequncyType === "M" ? `${numberWithCommas(parseFloat(loan.monthlyAmountDue).toFixed(2))} (${paymentFrequncyType})` : `${numberWithCommas(parseFloat(loan.monthlyAmountDue / 2).toFixed(2))} (${paymentFrequncyType})` : `${numberWithCommas(parseFloat(loan.lstRecurACH[0].draftingOption === "M" ? loan.lstRecurACH[0].draftPayment : loan.lstRecurACH[0].draftPayment / 2).toFixed(2))} (${loan.lstRecurACH[0].draftingOption})`}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                      <Box className={classes.displayflex} style={{ position: 'relative' }}>
                        <TextField
                          error={loan.additionalPrincipalAmountError ? true : false}
                          disabled={!loan.isPaymentAllowed || (loan.lstRecurACH && loan.lstRecurACH.length !== 0) || loan.isRecurringAllowed === false}
                          id={`outlined-principal${index}`}
                          variant="outlined"
                          value={loan.lstRecurACH && loan.lstRecurACH.length !== 0 ? loan.lstRecurACH[0].draftAdditionalPrincipal : loan.additionalPrincipal}
                          onChange={handlePrincipalAmount(index)}
                          onBlur={principalAmountOnBlur(index)}
                          InputProps={{
                            classes: { root: classes.textField, input: classes.textInput },
                            startAdornment: (
                              <InputAdornment position="start">
                                <AttachMoneyIcon />
                              </InputAdornment>
                            )
                          }}
                        />
                          {
                          loan.additionalPrincipalAmountError ?
                            <Box className={classes.paymentAmountErroIcon}><LightToolTip arrow
                            title={'The amount you’ve designated on the selected account is greater than its principal balance. Please select Request Payoff if you intend to pay off your loan. If you wish to make a payment towards your loan balance, please resubmit an amount that does not exceed your principal balance.'} placement="bottom" >
                            <InfoIcon style={{ fill: 'red' }} />
                          </LightToolTip></Box>
                            : null
                         }
                         </Box>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {loan.isEscrowAllowed ?
                          <TextField
                            disabled={!loan.isPaymentAllowed || (loan.lstRecurACH && loan.lstRecurACH.length !== 0) || loan.isRecurringAllowed === false}
                            id={`outlined-escrow${index}`}
                            variant="outlined"
                            onChange={handleEscrowAmount(index)}
                            value={loan.lstRecurACH && loan.lstRecurACH.length !== 0 ? loan.lstRecurACH[0].draftAdditionalEscrow : loan.additionalEscrow}
                            InputProps={{
                              classes: { root: classes.textField, input: classes.textInput },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AttachMoneyIcon />
                                </InputAdornment>
                              )
                            }}
                          /> : '-'}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        ${numberWithCommas(handleTotalAmount(loan))}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {loan.lstRecurACH === null ? selectedBank !== '' ? maskAccountNo(decrypt(selectedBank.bankAccountNumber)) : '-' : maskAccountNo(decrypt(loan.lstRecurACH[0].bankAccountNumber))}
                      </StyledTableCell>
                      <StyledTableCell align="left" className={`${classes.draftDate} ${((paymentFrequncyType == 'B') || (loan?.lstRecurACH && loan?.lstRecurACH[0]?.draftingOption == "B"))?classes.draftDateBiw:''}
                 `}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                          <KeyboardDatePicker
                            disabled={!loan.isPaymentAllowed || (loan.lstRecurACH && loan.lstRecurACH.length !== 0) || loan.isRecurringAllowed === false}
                            color='secondary'
                            variant="outlined"
                            inputVariant="outlined"
                            shouldDisableDate={disableWeekends}
                            minDate={loan.lstRecurACH === null ? businessMoment(loan.estDateTime).businessAdd(3)._d : null}
                            maxDate={loan.maxAutoPaymentDate ? ((isToorakLoan && paymentFrequncyType == 'B')?undefined:loan.maxAutoPaymentDate) : undefined}
                            renderDay={renderDayInPicker(index)}
                            autoOk={true}
                            disableToolbar
                            // variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            value={renderSelectedDate(loan)}
                            onChange={handleDateChange(index)}
                            id={`date-picker-inline${index}`}
                            label={(((paymentFrequncyType == 'B') && (loan?.lstRecurACH == null)) || (loan?.lstRecurACH && loan?.lstRecurACH[0]?.draftingOption == "B")) ? "First Draft Date" : "Draft Date"}
                            InputProps={{
                              classes: { root: `${classes.textField}`, input: classes.input },
                            }}
                            // TextFieldComponent={TextFieldComponent}
                          />
                        </MuiPickersUtilsProvider>
                        {(((paymentFrequncyType == 'B') && (loan?.lstRecurACH == null)) || (loan?.lstRecurACH && loan?.lstRecurACH[0]?.draftingOption == "B")) && <Box className={`${classes.formControlBiw} ${loan?.lstRecurACH && loan?.lstRecurACH[0]?.draftingOption === "B"?classes.formControlBiwText:classes.formControlMText}`}>
                        <Box  style={{display:'block',marginTop:'15px',marginLeft:'10px'}}>
                        {<Box>
                         <Typography
                            sx={{ display: 'inline' }}
                            style={{color:'#959698'}}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            Second Draft Date 
                         
                          <LightToolTip placement='top-start' arrow title="The date is calculated based on your selected first draft date i.e. first draft date+14 calendar days due to payment being Bi-weekly.">
                          <InfoIcon style={{ fill: '#555D6C',marginBottom:'-8px',marginLeft:'5px' }} />

                          </LightToolTip>
                          </Typography>
                          <Typography
                            sx={{ display: 'inline' }}
                            style={{marginBottom:'10px',color:'#535D6D'}}
                            component="span"
                            variant="body2"
                            color="secondary"
                          >
                             {/* {(loan?.lstRecurACH && loan?.lstRecurACH?.length > 0) ? loan?.lstRecurACH[0]?.draftDate2 : loan?.draftDate2 == null ? moment(loan.paymentDueDate).add(loan?.daysToCalculateSecondDraftDate, 'days').format('L') : loan?.draftDate2} */}
                             {renderSecondDraftDate(loan)}
                          </Typography>
                          </Box>}
                          </Box>
                       </Box>}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {loan.lstRecurACH && loan.lstRecurACH.length !== 0 ?
                          handleDeleteCheck(index) ?
                            (Cookies.get('adminToken') ? <div className={classes.deleteIcon} >
                              <DeleteIcon />
                            </div> : <div className={classes.deleteIcon} onClick={() => handleDelete(index)}>
                              <DeleteIcon />
                            </div>
                            ) :
                            (<div className={classes.deleteIcon}>
                              <LightToolTip title="You can not delete your payment, next scheduled payment is within 3 days." arrow placement="bottom">
                                <DeleteIcon />
                              </LightToolTip>
                            </div>
                            )
                          :
                          <Checkbox
                            checked={loan.hasOwnProperty("isSelected") ? loan.isSelected : false}
                            disabled={!loan.isPaymentAllowed || loan.isRecurringAllowed === false}
                            onChange={() => handleLoanSelection(index)}
                          />
                        }
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box className={classes.paymentDetail}>
          <Box className={classes.subSection}>
            <Box className={classes.subHeader}>
              <h3 className={classes.sectionTitle}>Important Notice</h3>
            </Box>
            <Box display='block' displayPrint="none" className={classes.termAndCondition}>
              <p className={classes.termText} dangerouslySetInnerHTML={{ __html: tncSummary }} />
            </Box>
            <Box display='none' displayPrint="block" className={classes.termAndConditionPrint}>
              <p className={classes.termText} dangerouslySetInnerHTML={{ __html: tncSummary }} />
            </Box>
            <Box p={1} textAlign="right" className={classes.printButton}>
              <ReactToPrint
                trigger={() => <Button className={classes.addCount}>Print Form <PrintIcon /></Button>}
                content={() => componentRef.current} />
            </Box>
          </Box>
          <Box className={classes.subSection}>
            <Box className={classes.subHeader}>
              <h3 className={classes.sectionTitle}>Payment Details</h3>
            </Box>
            <Box className={classes.flexBetween}>
              <h4 className={classes.paymentText}>Payment Total</h4>
              <h3 className={classes.paymentText}>${renderPaymentTotal()}</h3>
            </Box>

            {renderPaymentTotal() > loans[0].maxPaymentAllowed &&
              <h5 className={classes.errorMsg}>Payment Amount Restricted: Total payment amount
              can not be greater than ${numberWithCommas(loans[0].maxPaymentAllowed)}.<p className={classes.errorMsg2}> To complete a payment greater than ${numberWithCommas(loans[0].maxPaymentAllowed)},
              please contact Customer Support.</p></h5>
            }

            <Box className={clsx(classes.flexEnd, classes.paymentBtnContainer)}>
              <Button
                className={classes.paymentBtn}
                variant='outlined'
                onClick={() => dispatch(setPaymentOption('FAQ'))}
              >Cancel</Button>
              <Button
                onClick={() => handleSubmit()}
                disabled={Cookies.get('adminToken') || bankAccounts.length === 0 || submitData || disableMakePayment() || renderPaymentTotal() > loans[0].maxPaymentAllowed}
                className={classes.paymentBtn}
                color='primary'
                variant='contained'
              >{submitData ? <CircularProgress color='inherit' size={25} /> : 'Schedule Autopay'}</Button>
            </Box>
          </Box>
        </Box>
        {msg.length !== 0 && <SnackBar
          severity={hasError ? 'error' : "success"}
          message={msg} open={openSnackBar}
          handleClose={handleCloseSnackBar}
        />}
        {selectedDeleteAccount &&
          <DeleteAutoPayAlertMultiLoan
            open={openDeleteAlert}
            selectedDeleteAccount={selectedDeleteAccount}
            handleClose={handleCloseDeleteAlert}
          />
        }
        {successData.length !== 0 &&
          <MultiLoanSuccessAlert
            open={openSuccessAlert}
            data={successData}
            handleClose={handleCloseSuccessAlert}
            selectedBank={selectedBank}
            paymentFrequncyType={paymentFrequncyType}
          />}

        {showPopup === true ? <AddEditAccount
          newAddedBank={newAddedBankAccount}
          toggleOpen={ToggleAccountPopup}
          bankAccounts={bankAccounts}
          open={showPopup}
          renderPage={'autoPay'}
        /> : null}

        {processFeeAlert === true ? <ProcessFeeAlert
          handleClose={toggleProcessFeeAlert}
          open={processFeeAlert} lateFee={lateFee}/> : null}

        { isAlertOpen &&
          <AdditionalPaymentMultiAlert open={true} handleCloseAlert={handleCloseAlert} routeToRequestPayOff={routeToRequestPayOff} handleClose={handleClose}
          single={false} lowPrincipalBalanceData={lowPrincipalBalanceData}/>
        }

        {warningPopup?.show &&
        <Popup heading={'Warning'} open={warningPopup?.show} closePopup={toggleProcessFeeWarning} isWarningPopup={true}>
          <div className={classes.popupBody}>
        
          {warningPopup?.message ==='msg1' && <p className={classes.loanDetail}>
          Scheduling Payment for this date may result in a late fee on your payment every month. To avoid late fees, you can either adjust your first draft date or ensure your full monthly payment is received by the end of the grace period date of {latePaymentDate} by making a <a className={classes.linkStyle2} onClick={()=>routeToManageAutoPay(true,true)}>one-time payment</a> in advance of the first scheduled biweekly draft.
          </p>}
          {warningPopup?.message ==='msg2' && <p className={classes.loanDetail}>
          Scheduling Payment for this date will result in a late fee on your payment every month due to second draft date falling after the grace period. To avoid late fees, you can either adjust your first draft date or ensure your full monthly payment is received by the end of the grace period date of  {latePaymentDate} by making a <a className={classes.linkStyle2} onClick={()=>routeToManageAutoPay(true,true)}>one-time payment</a> in advance of the first scheduled biweekly draft.
          </p>}
          </div>
        </Popup>
       }
       {
          ackPopup &&
          <LateFeeAckAlert open={true} handleSubmit={()=>handleSubmit(true)} selectedLoans={loans} setBiWeeklyLateFeeAck={setBiWeeklyLateFeeAck}  isForSingleLoan={false} handleClose={handleAckPopupClose} paymentFrequncyType={paymentFrequncyType} />
        } 
        {
        <Modal
          aria-labelledby="transition-modal-title"
          open={openHowItWorksPopup}
          onClose={closeHowItWorksPopup}
          slots={{ backdrop: Backdrop }}
          fullWidth
          maxWidth="sm"
        >
           <Box className={classes.howItWorks}>
              <CancelIcon
                onClick={closeHowItWorksPopup}
                className={classes.closeIcon} />
              <Box className={classes.innerContent}>
                <div dangerouslySetInnerHTML={{ __html: howItWorks }} />
              </Box>
            </Box>
        </Modal>
      }
      </Box>
    )
  } else {
    return (
      <Box className={classes.loader}>
        <CircularProgress />
      </Box>
    )
  }
}