import { makeStyles } from '@material-ui/core/styles';
import signinIcon from '../../assets/SignIn/SignIn.svg';


export const useStyles = makeStyles((theme) => ({

  container: {
    width: '100%',
    backgroundColor: '#ccc',
    backgroundImage: `url(${signinIcon})`,
    backgroundPosition: "bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'contain'
  },

  root: {
    width: '100%',
    maxWidth: '120em',
    margin: '0 auto',
    padding: '2.5em 1.6em',
  },

  title: {
    color: "#1A4664",
    fontFamily: "Montserrat",
    fontSize: "22px",
    fontWeight: "700",
    margin: '24px 0 24px'
  },

  main: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },

  navBar: {
    width: '10%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
  },

  activeNavItem: {
    width: '100%',
    height: 175,
    cursor: 'pointer',
    backgroundColor: '#FFF',
    borderLeft: 'solid 5px',
    borderColor: theme.palette.primary.main,
    borderRadius: "12px 0 0 12px",
    boxShadow: "0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('md')]: {
      width: '33%',
      height: 'auto',
      padding: 5,
      marginBottom: 0,
      boxShadow: 'none',
      borderRadius: "12px 12px 0 0",
      borderLeft: 'none',
      borderTop: 'solid 5px',
      borderColor: theme.palette.primary.main,
    },
  },

  navItem: {
    width: '100%',
    height: 175,
    cursor: 'pointer',
    opacity: '0.66',
    backgroundColor: '#f5f5f5',
    borderLeft: 'solid 5px',
    borderColor: theme.palette.primary.main,
    borderRadius: "12px 0 0 12px",
    boxShadow: "0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('md')]: {
      width: '33%',
      height: 'auto',
      padding: 5,
      marginBottom: 0,
      boxShadow: 'none',
      borderRadius: "12px 12px 0 0",
      borderLeft: 'none',
      borderTop: 'solid 5px',
      borderColor: theme.palette.primary.main,
    },
  },

  navIcon: {
    display: 'block',
    width: 45,
    height: 45,
    margin: '37px auto 13px',
    [theme.breakpoints.down('md')]: {
      margin: '0 auto',
      width: 23,
      height: 24,
    },
  },

  navItemTitle: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: "1.6em",
    fontWeight: "600",
    letterSpacing: " 0.5px",
    textAlign: 'center',
  },

  inActiveNavItemTitle: {
    color: "rgba(0,0,0,0.74)",
    fontFamily: "Montserrat",
    fontSize: "1.6em",
    fontWeight: "400",
    letterSpacing: " 0.5px",
    textAlign: 'center',
  },

  mainContent: {
    width: '90%',
    flexGrow: 1,
    backgroundColor: '#fff',
    // minHeight: '85vh',
    // paddingBottom: 52,
    borderRadius: "0 12px 12px 12px",
    // boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('md')]: {
      borderRadius: "0 0 12px 12px",
      width: '100%',
    },
  },

  mainContentWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  banner: {
    width: '100%',
    padding: '6px 40px',
    backgroundColor: "#F9CB7F",
    boxShadow: "0 3px 7px 0 rgba(0,0,0,0.14)",
    display: 'flex',
    alignItems: 'center',
    marginBottom: 24,
    borderRadius: 9,
  },

  redBanner: {
    width: '100%',
    padding: '8px 40px',
    backgroundColor: "#B91717",
    boxShadow: "0 3px 7px 0 rgba(0,0,0,0.14)",
    display: 'flex',
    alignItems: 'center',
    marginBottom: 30,
    borderRadius: 9,
  },

  greenBanner: {
    // width: '100%',
    // padding: '23px 40px',
    // backgroundColor: "green",
    // boxShadow: "0 3px 7px 0 rgba(0,0,0,0.14)",
    // display: 'flex',
    // alignItems: 'center',
    // marginBottom: 30,
    // borderRadius: 9,
    display: 'none'
  },

  countBox: {
    border: "solid 2px #585757",
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15
  },

  countText: {
    color: "#0D0D0D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600",
    padding: 6,
  },

  bannerText: {
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: "1.6em",
    fontWeight: "500",
  },

  whiteColor: {
    color: '#fff'
  },

  whiteBorder: {
    border: "solid 2px #FFF",
  },

  // schedule payment box
  schedulePaymentBox: {
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 14px 7px, rgba(0, 0, 0, 0.2) 0px 1px 3px 0px',
    width: '100%',
    padding: '3em 2.2em',
    borderRadius: '12px',
    margin: '37px 0',
    backgroundColor: '#fff',
  },
  headingText: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: '400',
    letterSpacing: '0.63px',
    lineHeight: '29px',
    marginBottom: 40,
  },

  disabledLink: {
    color: '#4D4D4D',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.5px',
    lineHeight: 'normal',
    textDecoration: 'none !important',
    cursor: 'pointer',
    fontStyle: 'normal',
  },

  activeLink: {
    color: '#121D22',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.5px',
    lineHeight: 'normal',
    textDecoration: 'none !important'
  },

  errText: {
    color: "red",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    textAlign: 'center',
    padding: '2em',
  },

  printIconText: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer',
  },

  printForm: {
    fontSize: '14px',
    color: '#535D6D',
    fontFamily: "Montserrat",
    fontWeight: '600',
    textDecoration: 'underline',
  },

  flexBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },

  marginFix: {
    marginBottom: 0
  },
  achSuspension: {
    color: 'red',
    marginBottom: 20
  },
  transferBox: {
    width: '100%',
    alignItems: 'center',
    backgroundColor: '#FFF2E1',
    borderRadius: 4,
    padding: '1em',
    marginBottom: '2em',
    borderRadius: '8px',
    border: '4px solid #EDB342'
  },

  recurringDraft: {
    width: '100%',
    alignItems: 'center',
    backgroundColor: '#1A4664',
    borderRadius: 4,
    padding: '1em',
    marginBottom: '2em',
  },

  warningIcon: {
    color: "#F7B15C",
    width: 25,
    height: 25,
    marginRight: 10
  },

  transferInfoIcon: {
    color: "rgba(0,0,0,0.87)",
    width: 25,
    height: 25,
    marginRight: 10,
    cursor: "pointer"
  },

  informationText: {
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "400",
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4em'
    }
  },

  recurringDraftText: {
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },

  enrollNow: {
    fontWeight: 700,
    textDecoration: 'underline',
    cursor: "pointer"
  },

  infoIcon: {
    color: "#FFFFFF",
    width: 25,
    height: 25,
    marginRight: 10
  },

  paidInFullIcon: {
    color: "#EDB36A",
    width: 25,
    height: 25,
    marginRight: 10,
  },

  clickLink: {
    color: '#007FFF',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontStyle: 'normal',
    cursor:'pointer',
    fontWeight: '700',
    lineHeight: '16px',
    textDecorationLine: 'underline'
  },
}));

