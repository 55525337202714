import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Button, CircularProgress } from '@material-ui/core';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { decrypt } from '../../helpers/encrypt';
import { isAdminCheck, maskLoan, numberWithCommas, renderDefaultMsg, getLoanLabel, getPointPrivateLabel, getAccountLabel } from '../../helpers'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import Popup from "../PopUp";
import { confirmLoanStatus } from '../../actions';
import moment from 'moment';
import Cookies from 'js-cookie';
import InfoIcon from '@material-ui/icons/Info';

export const MultiLoanSelect = (props) => {

  const classes = useStyles();
  const [showPopup, setShowPopup] = useState(false);
  const [confirmErrorMsg, setConfirmErrorMsg] = useState('');
  const multiLoanResponse = props.data;
  const theme = useSelector(state => state.theme);
  const { customerCareNumber } = theme;


  const togglePopup = () => {
    setConfirmErrorMsg('');
    confirmLoanStatus().then(function (result) {
      if (result.lstResponseStatus[0].statusCode === '0' || result.lstResponseStatus[0].statusCode === '1001') {
        setShowPopup(false);
      }
    }).catch((e) => {
      setConfirmErrorMsg(renderDefaultMsg('400'));
    });
  }

  const viewMyloan=(accountNumber)=>{
    Cookies.set('isUpcomingPayDueOpened',true);
    props.switchPage(accountNumber);
  }
  
  const getLiquidizationText = (item) => {
    if ((item.liquidationStatus) && (item.liquidationType == 'FCLCompleted') && (item.isLoanPurged)) {
      return `This ${getLoanLabel().toLowerCase()} is no longer available since it is foreclosed.`
    } else if ((item.liquidationStatus) && (item.liquidationType == 'FCLCompleted') && (item.remainingPurgedDays < 31)) {
      return `left to access this ${getAccountLabel().toLowerCase()} since it is foreclosed.`
    } else if ((item.liquidationStatus) && (item.liquidationType == 'ChargeOff') && (item.isLoanPurged)) {
      return `This ${getLoanLabel().toLowerCase()} is no longer available since it is charged off.`
    } else if ((item.liquidationStatus) && (item.liquidationType == 'ChargeOff') && (item.remainingPurgedDays < 31)) {
      return `left to access this ${getAccountLabel().toLowerCase()} since it is charged off.`
    } else if ((item.liquidationStatus) && (item.liquidationType == 'PayOff') && (item.isLoanPurged)) {
      return `This ${getLoanLabel().toLowerCase()} is no longer available since it is paid in full.`
    } else if ((item.liquidationStatus) && (item.liquidationType == 'PayOff') && (item.remainingPurgedDays < 31)) {
      return `left to access this ${getAccountLabel().toLowerCase()} since it is paid in full.`
    } else if ((item.liquidationStatus) && (item.liquidationType == 'ServiceTransfer') && (item.isLoanPurged)) {
      return `This ${getLoanLabel().toLowerCase()} is no longer available since it is service transferred.`
    } else if ((item.liquidationStatus) && (item.liquidationType == 'ServiceTransfer') && (item.remainingPurgedDays < 31)) {
      return `left to access this ${getAccountLabel().toLowerCase()} since it is service transferred.`
    }
  }

  useEffect(() => {
    if (multiLoanResponse) {
      if (multiLoanResponse.lstNewAddedloans != null || multiLoanResponse.lstRemovedloans != null) {
        const addLoan = multiLoanResponse.lstNewAddedloans.length;
        const removeLoan = multiLoanResponse.lstRemovedloans.length;
        if (addLoan || removeLoan) {
          setShowPopup(true);
        }
      }
    }
  }, [multiLoanResponse]);

  let listOfLoans = '';
  let listOfAddedRemovedLoans = '';
  let newLoanArr = '';
  if (multiLoanResponse !== null) {
    if (multiLoanResponse.lstMultiloansDetail != null) {
      if ((multiLoanResponse.lstNewAddedloans.length) || (multiLoanResponse.lstRemovedloans.length)) {
        const addLoan = multiLoanResponse.lstNewAddedloans.length;
        const removeLoan = multiLoanResponse.lstRemovedloans.length;
        if (addLoan || removeLoan) {
          if (addLoan)
            newLoanArr = multiLoanResponse.lstNewAddedloans;
          else if (removeLoan)
            newLoanArr = multiLoanResponse.lstRemovedloans;
          else if (addLoan && removeLoan)
            newLoanArr = [...multiLoanResponse.lstNewAddedloans, ...multiLoanResponse.lstRemovedloans]
          listOfAddedRemovedLoans = newLoanArr.map((item) => {
            return (
              <Box key={item.accountNumber} className={classes.allLoanDetails}>
                <Typography className={classes.addedRemovedLoan}>
                  Loan number : <span className={classes.textBold}>{maskLoan(decrypt(item.accountNumber))}</span>
                </Typography>
                <Typography className={classes.addedRemovedLoan}>{item.propertyAddress}</Typography>
              </Box>
            )
          })
        }
      }
    }
  }

  if (multiLoanResponse) {
    if (multiLoanResponse.lstMultiloansDetail != null) {
      listOfLoans = multiLoanResponse.lstMultiloansDetail.map((item, index) => {
        return (
          <Box className={classes.root} key={item.accountNumber}>
            <Box className={classes.mainContainer} >
              <Box my={1} mx={2} className={classes.flexBox}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={9}>
                    <Grid className={classes.divHeight} container direction="column" justifyContent="space-between" height="100%">
                      <Box >
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={4}>
                            <Typography className={classes.sunHeading} >
                              {getLoanLabel()} # {item.accountNumber !== null && maskLoan(decrypt(item.accountNumber))}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Typography className={classes.sunHeading} >
                              {item.propertyAddress}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                      { (!getPointPrivateLabel() && ((!item.isLoanPurged) || (item.liquidationStatus && (item.remainingPurgedDays > 30)))) &&
                      <Box >
                        {item.isPaymentAllowed ?
                          <Grid container spacing={1} style={{ marginTop: '5px' }}>
                            <Grid item xs={12} sm={8} className={classes.item1}>
                              <Grid container spacing={3}>
                                <Grid item xs={6} sm={6}>
                                  <Typography className={classes.bodyFont} >
                                    Principal Balance</Typography>
                                  <Typography className={classes.h3Font} >
                                    ${numberWithCommas(Number(item.currentPrincipalBalance).toFixed(2))}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <Typography className={classes.bodyFont} >Total Amount Due</Typography>
                                  <Typography className={classes.h3Font} >
                                    ${numberWithCommas(Number(item.totalAmountDue).toFixed(2))}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={4} className={classes.item2}>
                              <Typography className={classes.bodyFont} >Payment Due Date</Typography>
                              <Box className={classes.h3Font} >
                                {moment(item.nextPaymentDueDate).format('ll')}
                                {item.isPaymentAllowed && <> {!item.isAlreadyScheduleACHPayment && (item.daylinquestdays > 1) ?
                                  <Box display={{ xs: 'flex! important', sm: 'none! important' }}
                                    className={item.isDueDateApproaching ? classes.dueDate :
                                      item.isDelinquentDays ? `${classes.dueDate} ${classes.delequentDays}` :
                                        (item.daylinquestdays === 0 || item.daylinquestdays === 1) ? classes.dueDate : classes.notDueDate}
                                    style={{
                                      color: item.isDueDateApproaching && '#7A9D7D'
                                    }}
                                    variant="outlined">
                                    {item.isDueDateApproaching && <Box component="span" m={1} className={classes.watchIcon} >
                                      <WatchLaterIcon />
                                    </Box>}
                                    {item.isDueDateApproaching && <> Due Date Approaching </>}
                                    {item.isDelinquentDays && <> {item.daylinquestdays} Days Past Due </>}
                                  </Box> : null}</>
                                }
                                {!item.isPaymentAllowed && <Box className={classes.defaulter}
                                  display={{ xs: 'flex! important', md: 'none! important' }}>
                                  {item.daylinquestdays} days Past Due
                                                        </Box>
                                }
                              </Box>
                            </Grid>
                          </Grid> : <Grid container spacing={3}>
                            <Box className={classes.paymentNotAllowedContainer} display="flex" >
                              <ErrorIcon style={{ marginRight: '0.5em', color: '#B00020' }} />
                              <Typography className={classes.paymentNotAllowed} >
                                Due to the status of your {getLoanLabel().toLowerCase()} payment details are disabled, please contact us directly to discuss your payment options and the amount you owe.
                              </Typography>
                            </Box>
                          </Grid>
                        }
                      </Box>
                      }
                    { ((item.liquidationStatus) && (item.remainingPurgedDays < 31) && (!item.isLoanPurged)) &&
                    <Box className={classes.transferBox}>
                      <Grid container direction="row" alignItems="center" style={{ paddingLeft: '5px' }}>
                        <Grid item md={12} xs={12} lg={12}>
                          <Box display='flex' alignItems="center">
                            <InfoIcon className={classes.paidInFullIcon} />
                            <Typography className={classes.informationText}><span className={classes.purgedDays}>{item.remainingPurgedDays} days </span>{getLiquidizationText(item)}</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>}
                    
                    { item.isLoanPurged &&
                    <Box className={classes.accountAccess}>
                      <Grid container direction="row" alignItems="center" style={{ paddingLeft: '5px' }}>
                        <Grid item md={12} xs={12} lg={12}>
                          <Box display='flex' alignItems="center">
                            <InfoIcon className={classes.infoIcon} />
                            <Typography className={classes.informationText}>{getLiquidizationText(item)}</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box> }

                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                      {item.isPaymentAllowed && <> {!item.isAlreadyScheduleACHPayment && (item.daylinquestdays > 1) ?
                        <Box display={{ xs: 'none! important', sm: 'flex! important' }}
                          className={item.isDueDateApproaching ? classes.dueDate :
                            item.isDelinquentDays ? `${classes.dueDate} ${classes.delequentDays}` :
                              (item.daylinquestdays === 0 || item.daylinquestdays === 1) ? classes.dueDate : classes.notDueDate}
                          style={{
                            color: item.isDueDateApproaching && '#50a923'
                          }}
                          variant="outlined">
                          {item.isDueDateApproaching && <Box component="span" m={1} className={classes.watchIcon}>
                            <WatchLaterIcon />
                          </Box>}
                          {item.isDueDateApproaching && <> Due Date Approching </>}
                          {item.isDelinquentDays && <> {item.daylinquestdays} Days Past Due </>}
                        </Box> : (item.isAlreadyScheduleACHPayment && !getPointPrivateLabel()) ? <Box className={classes.paymentSch}>
                          <Box className={classes.autopayCon}>
                            <CheckCircleIcon style={{ color: 'rgb(37, 171, 199)' }} />
                                            Autopay:<b className={classes.marginFix}> Enrolled</b></Box>
                          {item.bankDetails}</Box> : null
                      }</>
                      }
                      {!item.isPaymentAllowed && <Box className={classes.defaulter}>
                        {item.daylinquestdays} days Past Due
                                        </Box>
                      }
                      <Button className={classes.button} mt={2}
                        variant="contained"
                        color='primary'
                        onClick={()=>viewMyloan(item.accountNumber)}
                        disabled={item.isLoanPurged}
                      >
                        View My {getLoanLabel()}</Button>
                    </Grid>
                  </Grid>

                </Grid>
              </Box>
            </Box>
          </Box>
        )
      })
    } else {
      listOfLoans = <Box className={classes.root}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box my={1} mx={2}>
          <Typography className={classes.h3Font} >
            <CircularProgress color='primary' />
          </Typography>
        </Box>
      </Box>
    }
  } else if (multiLoanResponse) {
    if (multiLoanResponse.isError === true) {
      listOfLoans = <Box className={classes.root} >
        <Box my={1} mx={2}>
          <Typography className={classes.h3Font} >
            {renderDefaultMsg('400')}
          </Typography>
        </Box>
      </Box>
    }
  }

  return (<Box className={classes.bg}>
    <Typography className={`${classes.pageHeading}`} >
      {getLoanLabel() + 's'} you are paying
            </Typography>
    { listOfLoans}

    {showPopup ?
      <Popup heading={(multiLoanResponse.lstNewAddedloans.length && !multiLoanResponse.lstRemovedloans.length)
        ? `Add A New ${getLoanLabel()}` : (!multiLoanResponse.lstNewAddedloans.length && multiLoanResponse.lstRemovedloans.length)
          ? `${getLoanLabel()} No Longer Active` : `Add/Remove A New ${getLoanLabel()}`} open={showPopup}>
        <div className={classes.popupBody}>
          <Typography className={classes.loanDetail}>
            {(multiLoanResponse.lstNewAddedloans.length && !multiLoanResponse.lstRemovedloans.length) ? `We’ve identified that the below ${getLoanLabel()?.toLowerCase() + 's'} are available to be added to your account. Please click ‘Confirm’ below to acknowledge the addition of the ${getLoanLabel()?.toLowerCase() + 's'} to your account.` : null}
            {(!multiLoanResponse.lstNewAddedloans.length && multiLoanResponse.lstRemovedloans.length) ? `We’ve identified that the below ${getLoanLabel()?.toLowerCase() + 's'} are no longer active in our system and will be removed from your account. Please click ‘Confirm’ below to acknowledge that this ${getLoanLabel()?.toLowerCase()} will be removed from your account viewing abilities.` : null}
            {(multiLoanResponse.lstNewAddedloans.length && multiLoanResponse.lstRemovedloans.length) ? `We’ve identified that the below ${getLoanLabel()?.toLowerCase() + 's'} are available to be added/Remove to your account. Please click ‘Confirm’ below to acknowledge the addition of the ${getLoanLabel()?.toLowerCase() + 's'} to your account.` : null}
          </Typography>
          {listOfAddedRemovedLoans}
          <Typography className={`${classes.loanDetail} ${classes.loanErrorContect}`}>If you believe this to be an error,
                    please contact Customer Service at:  {customerCareNumber}</Typography>

          {confirmErrorMsg !== '' && <Typography className={`${classes.errorDisplay}`}>{confirmErrorMsg}</Typography>}

          <Button className={`${classes.button} ${classes.loanConfirm}`} mt={2}
            variant="contained"
            color='primary'
            disabled={isAdminCheck()}
            onClick={() => togglePopup()}>
            Confirm
          </Button>
        </div>
      </Popup>
      : null
    }

  </Box>
  )
}