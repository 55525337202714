import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => (
  {

    link: {
      cursor: "pointer"
    },
    nextPrevContainer: {
      width: '100%',
     // height: '10%',
      display: 'flex',
      justifyContent: 'center',      
    },
    nextPrevBtn: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "20px",
      letterSpacing: "0.44px",
      lineHeight: "24px",
      padding: "0 50px",
      display: "flex"
    },
    disabled:{
      opacity: "0.5",
      cursor: "not-allowed"
    }

  }
));

