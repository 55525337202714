import React from 'react';
import { useStyles } from './styles';
import { Box, Button } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Link } from 'react-router-dom';

export const UserNameSent = (props) => {
 
  const classes = useStyles();

  return (
    <Box container="true" maxWidth='xl' className={classes.root}>
      <Box className={classes.wrapper}>
        <Box className={classes.checkboxContainer}>
          <CheckCircleOutlineIcon fontSize='large' color="primary" classes={{ root: classes.icon }} />
          <p className={classes.heading}>Email Sent!</p>
        </Box>

        <p className={classes.title}>An Email has been sent to {props.emailId}</p>
        <p className={classes.title}>If the address provided is associated with an username in our records, you will receive an email with required details.</p>
        <p className={classes.title}>If you do not receive the email within a few moments, please check your spam/junk folder or other filtering tools.</p>
        
        <Box className={classes.btnContainer}>
          <Link to={`${props.path}`} style={{ textDecoration: 'none' }}>
            <Button className={classes.sendBtn} variant="contained" color="primary">{props.btnTitle}</Button>
          </Link>
        </Box>
      </Box>
    </Box>
  )
}
