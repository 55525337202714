import React from 'react';
import { useStyles } from './styles';
import { Box, Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import checkmark from '../../../assets/checkmark.png';

export const DocumentReqSubmitPopup = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle }}} onClose={props.handleCancelIcon} open={props.open}>
      <Box className={classes.root}>
        <IconButton onClick={props.handleCancelIcon} className={classes.closeIco}>
          <CancelIcon />
        </IconButton>
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>Request Submitted</Typography>
        </Box>
        
        <Box className={classes.cardBodyContainer}>
          <Box className={classes.statusContainer}>
           <Box className={classes.cardContainer}>
            <Box className={classes.imageContainer}>
                <img className={classes.checkImg} src={checkmark} alt='img' /> 
                <span className={classes.request}>Your request #{props.submitMsg?.requestNo} submitted successfully!</span>
            </Box> 
             <Typography className={classes.paragraph}>Based on your loan status and availability of documents, it may take up to {props.submitMsg?.sla} to process your request.</Typography> 
            </Box> 
          </Box>
        </Box>

        <Box className={classes.cardFooter}>
          <Button
            variant='contained'
            color='primary'
            className={classes.btn}
            onClick={props.handleCancelIcon}
            >Got it</Button>
        </Box>
      </Box> 
    </Dialog>
  )
}
