import React from "react";
import { Box, Button, Dialog, IconButton, Typography } from "@material-ui/core";
import { useStyles } from './styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Error';


export const ProcessFeeAlert = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      PaperProps={{ classes: { root: classes.dialogStyle } }}
      onClose={props.handleClose}
      open={props.open}
    >
      <Box className={classes.root}>
        <Box className={classes.header}>
          <IconButton className={classes.iconStyle} onClick={props.handleClose}>
            <CancelIcon />
          </IconButton>
        </Box>
        <Box className={classes.body}>
          { ((props.lateFee == 'processing') || (props.lateFee == 'both')) &&
          <Box className={classes.warning}>
            <ErrorIcon style={{ color: 'red', marginRight: '8px', fontSize: '22px' }} />
            <div ><p className={classes.colorRed}>Warning:</p>
              <h5 className={classes.processingFee}>Payment for this date will result in a processing fee.</h5>
            </div>
          </Box>
         }
        { ((props.lateFee == 'late') || (props.lateFee == 'both')) &&
          <Box className={classes.imageContainer}>
            <Typography className={classes.paragraph}>Scheduling payment for this date will result in a late fee.</Typography>
          </Box>
        }
        
        </Box>
      </Box>
    </Dialog>
  )
}
