import Cookies from 'js-cookie';
import { axiosPublic } from '../config';
import { getAppSource, getUserType } from '../helpers'
import { encrypt } from '../helpers/encrypt';

export const saveUser = async (userData) => {
  userData.privateLabelUrl = Cookies.get("privateLabelUrl")
  userData.ipAddress = Cookies.get("publicIp")
  userData.browser = Cookies.get("browser")
  try {
    const res = await axiosPublic.post('/OT/UserRegistration', userData);    
    return res.data
  } catch (e) {    
    return null
  }
}

export const checkUsername = async (username) => {
  const data = {
    "userName": encrypt(username),
    "accountNumber": "",
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get("publicIp"),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType()
  }

  try {
    const res = await axiosPublic.post('/OT/UserORLoanExist', data);    
    return { data: res.data, status: res.status }
  } catch (e) {    
    return null
  }
}

export const passwordPolicy = async () => {
  const data = {
    "policyId": 1,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get("publicIp"),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }

  try {
    const res = await axiosPublic.post('/OT/GetPolicy', data);
    return res.data
  } catch (e) {    
    return null
  }
}