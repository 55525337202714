import React, { useState, useEffect } from 'react';
import {
  Box, Button, IconButton,
  InputAdornment, FormControl, CssBaseline, CircularProgress, OutlinedInput, InputLabel
} from '@material-ui/core';
import { useStyles } from './styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Cookies from 'js-cookie';
import {
  getPublicIp, hasLowerChar, hasUpperChar, hasValidLength,
  hasSpecialChar, hasDigits, getAppSource, getUserType
} from '../../helpers';
import { validateUrl, resetPassword, preLoginToken, getTheme } from '../../actions';
import { encrypt } from '../../helpers/encrypt';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';



export const ConfirmPassword = (props) => {
  const location = useLocation();
  const userId = location.search.split('?username=')[1];
  const classes = useStyles();
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('');
  const [notMatchedRrrorMsg, setNotMatchedRrrorMsg] = useState('');
  const [hasInvalidUrl, setHasInvalidUrl] = useState(true)
  const [linkExpired, setLinkExpired] = useState('');

  const dispatch = useDispatch();
  const [values, setValues] = useState({
    showPassword: false,
    showConfirmPassword: false,
    password: '',
    confirmPassword: ''
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = (prop) => {
    setValues({ ...values, [prop]: !values[prop] });
  };

  useEffect(() => {
    if (userId) {
      lookForPublicTokenAndValidateUrl()
    }
  }, []);

  const lookForPublicTokenAndValidateUrl = async () => {
    const token = await Cookies.get('publicToken');
    const publicIp = Cookies.get("publicIp");
    if (!publicIp) {
      await Cookies.set("publicIp", await getPublicIp());
    }
    if (!token) {
      await preLoginToken();
      await dispatch(getTheme());
    }
    await handleValidateUrl()
  }

  const handleValidateUrl = async () => {
    setLoading(true);
    setErrorMsg("");
    setNotMatchedRrrorMsg("");
    const data = {
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get("publicIp"),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": userId
    }

    const res = await validateUrl(data);
    if (res) {
      setLinkExpired('')
      setLoading(false);
      setHasInvalidUrl(false)
    } else {
      setLinkExpired("The link you followed has expired");
      setLoading(false);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (values.password !== values.confirmPassword) {
      setErrorMsg("");
      setNotMatchedRrrorMsg("New password and confirmation password not matched.");
      return;
    }
    setErrorMsg("");
    setNotMatchedRrrorMsg('');
    setLoading(true);
    const data = {
      "userName": userId,
      "password": encrypt(values.password),
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": await getPublicIp(),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
    }

    const res = await resetPassword(data);
    const { lstResponseStatus } = res;
    setLoading(false);
    if (res) {
      if (res && lstResponseStatus[0].statusCode === "0") {
        Cookies.remove('userName');
        props.handleSuccess()
      } else {
        setErrorMsg(lstResponseStatus[0].statusDesc);
      }
      return;
    }
    setErrorMsg("Something went wrong.");
    return;
  }
  const { password, confirmPassword } = values;
  const disable = hasLowerChar(password) && hasUpperChar(password) && hasValidLength(password) && hasSpecialChar(password) && hasDigits(password)
  const disable2 = hasLowerChar(confirmPassword) && hasUpperChar(confirmPassword) && hasValidLength(confirmPassword) && hasSpecialChar(confirmPassword) && hasDigits(confirmPassword)

  return (
    <CssBaseline>
      <Box container="true" maxWidth='xl' className={classes.root}>
        <Box className={classes.formContainer} >
          <p className={classes.title}>Reset Password</p>
          {(values.password !== values.confirmPassword) && <p className={classes.errorMsg}>{notMatchedRrrorMsg !== '' ? notMatchedRrrorMsg : ''}</p>}
          {errorMsg !== '' && <p className={classes.errorMsg}>{errorMsg !== '' ? errorMsg : ''}</p>}
          {linkExpired !== '' && <p className={classes.errorMsg}>{linkExpired !== '' ? linkExpired : ''}</p>}

          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Box className={`${classes.inputContainer} ${classes.inputMarginFixed}`}>
              <FormControl variant="outlined" fullWidth >
                <InputLabel className={classes.labelStyle} htmlFor="outlined-adornment-password">Enter New Password</InputLabel>
                <OutlinedInput 
                  disabled={linkExpired !== '' ? true : false}
                  color='primary'
                  id="outlined-adornment-password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={handleChange('password')}
                  labelWidth={165}
                  className={classes.inputStyle}
                  inputProps={{
                    className: classes.inputStyle,
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword('showPassword')}

                        edge="end"
                        tabIndex="-1"
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <Box className={classes.ruleContainer} style={{ display: disable || password.length === 0 ? 'none' : 'block' }}>
                <p className={classes.validationTitle}>Password Requirements</p>
                <p className={hasValidLength(password) ? classes.validText : classes.invalidText}>Password must be between 8 and 20 characters long</p>
                <p className={hasLowerChar(password) ? classes.validText : classes.invalidText}>Must contain atleast 1 lower case letter [a-z]</p>
                <p className={hasUpperChar(password) ? classes.validText : classes.invalidText}>Must also contain 1 upper case letter [A-Z]</p>
                <p className={hasDigits(password) ? classes.validText : classes.invalidText}>Must also contain 1 digit [0-9]</p>
                <p className={hasSpecialChar(password) ? classes.validText : classes.invalidText}>Must also contain 1 special character (#, %, $, !, &, @)</p>
              </Box>
            </Box>

            <Box className={classes.inputContainer}>
              <FormControl variant="outlined" fullWidth >
                <InputLabel className={classes.labelStyle} htmlFor="outlined-adornment-confirm-password">Confirm New Password</InputLabel>
                <OutlinedInput
                  disabled={linkExpired !== '' ? true : false}
                  color='primary'
                  id="outlined-adornment-confirm-password"
                  type={values.showConfirmPassword ? 'text' : 'password'}
                  value={values.confirmPassword}
                  onChange={handleChange('confirmPassword')}
                  labelWidth={190}
                  className={classes.inputStyle}
                  inputProps={{
                    className: classes.inputStyle,
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle confirm-password visibility"
                        onClick={() => handleClickShowPassword('showConfirmPassword')}

                        edge="end"
                        tabIndex="-1"
                      >
                        {values.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <Box className={classes.ruleContainer} style={{ display: values.password == values.confirmPassword ? 'none' : 'block' }}>
                <p className={ values.password == values.confirmPassword ? classes.validText : classes.invalidText}>New password and confirmation password not matched</p>
              </Box>
            </Box>

            <Box >
              {loading ?
                <Button type='submit' disabled={true} className={classes.signUpBtn} size="large" fullWidth variant="contained" color="primary">
                  <CircularProgress color='primary' />
                </Button>
                :
                <Button disabled={hasInvalidUrl || password.length === 0 || values.confirmPassword.length === 0 || !disable || !disable2 } type='submit' className={classes.signUpBtn} fullWidth variant="contained" color="primary">
                  submit
                </Button>
              }
            </Box>
          </form>
        </Box>
      </Box>
    </CssBaseline>
  )
}
