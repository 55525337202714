import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  item1: {
    order: 2,
    [theme.breakpoints.up('sm')]: {
      order: 1,
    },
  },
  item2: {
    order: 1,
    [theme.breakpoints.up('sm')]: {
      order: 2,
    },
  },
  root: {
    width: '100%',
    padding: '1em 0',
    maxWidth: '120em',
    margin: '0 auto',
    '&:last-child': {
      paddingBottom: '4em'
    },
    [theme.breakpoints.down('md')]: {
      padding: '1em 2em',
    },
  },
  bg: {
    background: 'rgb(214 207 207 / 54%)',
  },
  mainContainer: {
    width: '100%',
    boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
    background: '#fff',
    borderRadius: '12px',
    padding: '2.1em 8em 0em 8em',
    [theme.breakpoints.down('sm')]: {
      padding: '1em',
    },
  },
  itemBox: {
    minHeight: '300px',
    maxHeight: '400px',

  },
  sunHeading: {
    color: '#585757',
    fontFamily: 'Montserrat',
    fontSize: '21px',
    fontWeight: 'bold',
    letterSpacing: '0.28px',
    lineHeight: '25px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    }
  },
  pageHeading: {
    color: '#1A4664',
    fontFamily: 'Montserrat',
    fontSize: '22px',
    fontWeight: '700',
    letterSpacing: '0.18px',
    lineHeight: '24px',
    maxWidth: '1200px',
    margin: '0 auto !important',
    padding: '1.6em 0',
    [theme.breakpoints.down('md')]: {
      padding: '1em 1em !important',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      padding: '1em 1em 1em 1em !important'
    }
  },
  mainHeading: {
    maxWidth: '1200px',
    margin: '0 auto !important',
    padding: '1.6em 0',
    fontSize: '2.5em',
    color: '#535D6D',
    fontWeight: '800',
    paddingBottom: '20px !important',
    paddingTop: '1em !important',
    lineHeight: '29px',
    [theme.breakpoints.down('md')]: {
      padding: '1em 1em !important',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2em',
      padding: '1em 1em 1em 1em !important'
    }
  },
  bodyFont: {
    color: '#585757',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '0.47px',
    lineHeight: '18px',
  },
  divHeight: {
    height: '100%'
  },
  h3Font: {
    color: '#585757',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: 'bold',
    letterSpacing: '0.6px',
    lineHeight: '22px',
    marginTop: '9px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
  },
  paymentNotAllowedContainer: {
    padding: '2.5em 1em 2em 1em'
  },
  paymentNotAllowed: {
    color: '#B00020',
    fontFamily: 'Montserrat',
    letterSpacing: '0.1px',
    fontSize: '14px',
    fontWeight: '600',
    [theme.breakpoints.down('xs')]: {
      fontSize: '13px',
    },
  },
  mb16: {
    marginBottom: '16px'
  },
  button: {
    padding: '6px 7px',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    width: '204px',
    [theme.breakpoints.down('sm')]: {
      width: '40%',
      fontSize: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '70%',
      fontSize: '14px',
    },
  },
  autopayCon: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '6px'
  },
  paymentSch: {
    maxWidth: 204,
    fontSize: '16px',
    marginBottom: '10px',
    fontFamily: 'Montserrat',
    color: '#0C0B0B',
    lettetSpacing: '0.53px',
    lineHeight: '19px'
  },
  dueDate: {
    padding: '5px 4px',
    fontFamily: 'Montserrat',
    width: '204px',
    marginBottom: '16px',
    textTransform: "none",
    fontSize: '14px',
    border: '1px solid #9a9595',
    color: '#7A9D7D',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      fontSize: '11px',
      padding: '1px 2px',
      marginBottom: '10px'
    },
  },

  delequentDays: {
    backgroundColor: '#C3791A',
    color: '#fff',
    border: 'none',
    height: '35px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '10px',
      paddingRight: '10px'
    },
  },

  defaulter: {
    height: '33px',
    width: '223px',
    borderRadius: '6px',
    backgroundColor: '#B00020',
    color: '#fff',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '600',
    letterSpacing: '0.47px',
    lineHeight: '18px',
    textAlign: 'center',
    padding: '0.6em 0',
    marginBottom: '1.3em'
  },
  watchIcon: {
    margin: '0 6px 0 0',
    height: '24px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 1px 0 0',
    },
  },
  loanDetail: {
    color: '#0C0B0B',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    letterSpacing: '0.47px',
    lineHeight: '18px',
    marginBottom: '18px'
  },
  allLoanDetails: {
    margin: '12px 0',
    borderBottom: '1px solid #dcdfe0',
  },
  addedRemovedLoan: {
    color: '#0C0B0B',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '400',
    letterSpacing: '0.47px',
    lineHeight: '18px',
  },

  textBold: {
    fontWeight: '600'
  },


  loanErrorContect: {
    margin: '18px 0'
  },
  loanConfirm: {
    margin: '20px auto 5px auto',
    display: 'block',
  },
  popupBody: {
    padding: '20px 30px 30px 30px',
    fontSize: '14px'
  },

  marginFix: {
    marginLeft: 10,
  },

  flexBox: {
    [theme.breakpoints.up('xs')]: {
      alignItem: 'center'
    },
  },

  errorDisplay: {
    textAlign: 'center',
    color: 'red',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 600,
    letterSpacing: '0.36px',
    lineHeight: '19px',
    marginBottom: 6,
    marginTop: 10,
  },
 
  transferBox: {
    width: '100%',
    alignItems: 'center',
    backgroundColor: '#FFF2E1',
    borderRadius: 4,
    padding: 4,
    marginBottom: '2em',
    border: '4px solid #EDB342'
  },
 
  paidInFullIcon: {
    color: "#EDB36A",
    width: 25,
    height: 25,
    marginRight: 10,
  },
  purgedDays: {
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "700"
  },
  informationText: {
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "400",
  },

  accountAccess: {
    width: '100%',
    alignItems: 'center',
    backgroundColor: '#FFF0F0',
    padding: 4,
    marginBottom: '2em',
    borderRadius: 4,
    border: '4px solid #A22027'
  },
  infoIcon: {
    color: "#A22027",
    width: 25,
    height: 25,
    marginRight: 10,
  },
  informationText: {
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "400",
  },

}));