import React from 'react';
import { useStyles } from './styles';
import { Box, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';

export const EscrowAlert = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }}  open={props?.escrowAlertStatus}>
      <Box className={classes.root}>
      <IconButton onClick={props?.closeAccessDeniedPopup} className={classes.closeIcon}>
        <CancelIcon />
      </IconButton> 
        <Box className={classes.cardBody}>
          <Box className={classes.statusContainer}>
            <p className={classes.statusTitle}>Access Denied</p>
            <p className={classes.statusSubTitle}>Your account status does not qualify you to view this page </p>
          </Box>
          <Box className={classes.buttonContainer}>
            <Button type='submit' className={classes.signInBtn} size="medium" onClick={props?.closeAccessDeniedPopup} variant="contained" color="primary">
                OK
              </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}
