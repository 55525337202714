import { makeStyles, withStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: 550,
    borderRadius: 12,
    backgroundColor: 'white',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxHeight: "100%",
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '20px',
        margin: '50px 15px'
      },
  },

  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "400",
    textAlign: 'center',
    margin: 14,
  },

  cardBody: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '12px 2em',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  cardBodyContainer: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '8px 2em',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  statusTitle: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: '18px',
    fontWeight: "700",
    padding: '3px 2px 2px 3px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px'
    }
  },

  statusBody: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "bold",
  },

  btn: {
    width: 175,
    height: '40px',
    fontFamily: "Montserrat",
    fontSize: '16px',
    fontWeight: "600",
    margin: "10px",
    padding: '10px 10px',
    // color: '#202020',
    [theme.breakpoints.down('sm')]: {
        width: "100%",
        fontSize: '14px'
    }
  },

  closeIcon: {
    position: 'absolute',
    right: 42,
    paddingTop: 18,
    [theme.breakpoints.down('sm')]: {
        right: 10
    }
  },
  closeIco: {
    position: 'absolute',
    right: -5,
    paddingTop: 7,
    [theme.breakpoints.down('sm')]: {
        right: 10
    }
  },

  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },
  
  inputStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600"
  },

  labelStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "400",
  },

  cardFooter: {
    width: '100%',
    textAlign: 'center',
    backgroundColor: "#fff",
    marginBottom: 30,
    [theme.breakpoints.down('sm')]: {
      padding: '8px 80px 0px',
      width: '94%'
    },
  },

  paragraph: {
    color: "#4D4D4D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "500",
    marginBottom: 8,
    marginTop: 20,
    textAlign: 'center'
 },

cardContainer: {
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',
  marginTop: 15,
},

cardContent: {
  display: 'flex'
},

cardTitle: {
color: "#000000",
fontFamily: "Montserrat",
fontSize: "12px",
fontWeight: "600",
marginBottom: '20px',
letterSpacing: '0.15px',
linHheight: '24px',
},

checkImg: {
    width: 22,
    height: 22,
    [theme.breakpoints.down('sm')]: {
      paddingTop: "6px",
      width: 23
    },
},

imageContainer: {
    paddingTop: "6px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down('sm')]: {
      display: "block"
    },
},

request: {
    color: "#E02020",
    fontFamily: "Montserrat",
    fontSize: "18px",
    letterSpacing: "0.31px",
    fontWeight: "600",
    paddingTop: 4,
    [theme.breakpoints.down('sm')]: {
        fontSize: "20px"
    },
},

infoIcon: {
  paddingRight: "10px",
  fontSize: '30px',
  color: "#E02020"
},

}));

