import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.secondary.main,
    fontSize: '40px'
  },
  tooltip: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    padding: '20px 15px',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    letterSpacing: '0.55px',
    lineHeight: '21px',
    width: '500px',
    maxWidth: '500px',
    borderRadius: '10px'
  },
}));

export const useStyles = makeStyles((theme) => (
  {
    root: {
      width: '100%',
      minHeight: '100vh',
      backgroundColor: '#ccc',
      display: 'flex',
      justifyContent: 'center',
      backgroundPosition: "bottom left",
      backgroundRepeat: "no-repeat",
      backgroundSize: 'contain',
    },
    loadingRoot: {
      width: '100%',
      // height: '100vh',
      // padding: '6.4em 6%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // backgroundColor: '#ccc',
    },  

      pageHeading: {
        color: '#1A4664',
        fontFamily: 'Montserrat',
        fontSize: '22px',
        fontWeight: '700',
        letterSpacing: '0.18px',
        lineHeight: '24px',
        marginBottom: 25,
        marginTop: 24
      },
    infoStrip: {
      backgroundColor: '#FAFAFA',
      width: '100%',
      margin: '19px 0px 19px 0px',
      border:'1px solid #B4B4B4',
      borderRadius : '10px'
    },
    escrowList: {
        backgroundColor: '#FFFFFF',
        margin: '20px 40px',
        border:'1px solid #E4E4E4',
        borderRadius : '5px',
        // height: '200px'
    },
    
    infoBox: {
      backgroundColor: '#7EB1CC',
      margin: '40px',
      border:'1px solid #B4B4B4',
      borderRadius : '10px',
      padding: '15px'
    },
    inputStyle: {
        color: "#535D6D",
        fontWeight: "500",
        paddingLeft: "40px"
      },
      activeRadioLabel: {
        fontWeight: "500",
      },
      submitBtn: {
        fontFamily: "Inter",
        fontSize: 14,
        fontWeight: '700',
        marginLeft: 16,
        width: '182px',
        textTransform: 'capitalize'
      },
      submitLoader: {
        marginLeft: 16
      },
      resetBtn: {
       margin: '40px 0px 55px 40px',
       fontFamily: "Inter",
       fontSize: 14,
       fontWeight: "700",
       color: "#41403F",
       width: '182px',
       padding: '22px 0px 20px 2px',
       textTransform: 'capitalize'
      },
      commonBtn: {
        margin: '40px 40px 55px 0px',
        [theme.breakpoints.down('xs')]: {
          margin: '40px 40px 55px 38px'
        }
      },
      cancelBtn: {
        marginBottom: 20,
        fontFamily: "Inter",
        fontSize: 14,
        fontWeight: "700",
        color: "#41403F",
        width: '182px',
        textTransform: 'capitalize'
      },
      radioLabel: {
        fontFamily: "Inter",
        fontSize: "15px",
        fontWeight: "500",
        cursor: 'pointer',
        color: '#444444',
        letterSpacing: '0.25px',
        lineHeight: '17px',
        '& .MuiRadio-colorSecondary.Mui-checked': {
          color: "#535D6D",
          fontWeight: "500",
        }
      },
      submitText: {
        width: '100%',
        fontFamily: "Inter",
        fontSize: "15px",
        fontWeight: "500",
        cursor: 'pointer',
        color: '#444444',
        paddingLeft: '70px',
        paddingRight: '25px',
        letterSpacing: '0.25px',
        lineHeight: '17px',
        '& .MuiRadio-colorSecondary.Mui-checked': {
          color: "#535D6D",
          fontWeight: "500",
        }
      },
    information: {
      color: '#ffffff',
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "700",
      letterSpacing: "0.36px",
      lineHeight: "22px",
      padding: '6px 0px'
    },
    required: {
      color: '#ec3131',
      fontWeight: '600',
      marginLeft: '2px'
    },
    taxType: {
        color: '#444444',
        fontFamily: "Inter",
        fontSize: "18px",
        fontWeight: "600",
        letterSpacing: "0.36px",
        lineHeight: "16px",
        padding: '25px 0px 25px 0px',
        textTransform: 'capitalize'
    },
    hypen: {
      color: '#444444',
      fontFamily: "Inter",
      fontSize: "18px",
      fontWeight: "600",
      letterSpacing: "0.36px",
      lineHeight: "16px",
      padding: '25px 0px 25px 0px',
      textTransform: 'capitalize',
      margin: '0px 2px'
    },
    payeeType: {
        color: '#444444',
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "400",
        letterSpacing: "0.36px",
        lineHeight: "16px",
        padding:  '25px 0px 25px 0px',
        [theme.breakpoints.down('xs')]: {
          padding:  '25px 2px 25px 0px'
        }
    },
    reviewRequest: {
        color: '#ffffff',
        fontFamily: "Inter",
        fontSize: "14px",
        letterSpacing: "0.36px",
        lineHeight: "16px",
        padding: '0px 10px',
        fontWeight: "400"
    },
    icon: {
        margin: '20px 10px 20px 20px',
        fontSize: '30px',
        color: '#444444',
        height: '23px',
        width: '23px'
      },
      resetIcon: {
        margin: '18px 5px 20px 0px',
        fontSize: '30px',
        color: '#444444',
        height: '20px',
        width: '20px'
      },
      parcelId: {
        paddingRight: '48px',
        float: 'right',
        color: '#8F8F8F',
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "400",
        letterSpacing: "0.36px",
        lineHeight: "18px",
        padding: '25px 0px',
        [theme.breakpoints.down('xs')]: {
          paddingRight: '70px',
          padding: '10px 0px'
        },
      },
      toggleBtnBox: {
        paddingLeft: '50px'
      },
      escrow: {
        color: '#444444',
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "18px",
        padding: '8px 0px'
      },
      escrowDocName: {
        color: '#00A4EB',
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "18px",
        padding: '8px 0px',
        cursor: "pointer",
        width: "360px",
        wordBreak: "break-all",
        [theme.breakpoints.down('xs')]: {
          padding: '8px 58px',
          width: '250px'
        },
      },
      escrowed: {
        color: '#8F8F8F',
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "19px",
        padding: '16px 0px'
      },
      upload: {
        color: '#444444',
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "600",
        letterSpacing: "0.36px",
        lineHeight: "20px",
        padding: '0px 0px 10px 0px'
      },
      delete: {
        color: '#00A4EB',
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        padding: '8px 2px',
        cursor: "pointer"
      },
      deleteReplaceSection: {
        display: 'flex',
        marginLeft: '25px',
        [theme.breakpoints.down('xs')]: {
          marginLeft: "135px"
        },
      },
      addDocument : {
        color: '#00A4EB',
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        padding: '8px 2px',
        cursor: "pointer",
        marginLeft: "25px",
        [theme.breakpoints.down('xs')]: {
          marginLeft: "0px"
        }
      },
      loader: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },    
      addMore: {
        color: '#00A4EB',
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        padding: '8px 0px 8px 28px',
        cursor: "pointer",
        marginLeft: "30px"
      },
      uploadSection: {
         padding: '10px 50px',
         [theme.breakpoints.down('xs')]: {
          overflowX: 'auto'
        }
      },
      loanescrow: {
         color: '#444444',
         fontFamily: "Inter",
         fontSize: "15px",
         fontStyle: "Italic",
         fontWeight: "500",
         letterSpacing: "0.36px",
         lineHeight: "18px",
         padding: '10px 10px 30px 0px',
         [theme.breakpoints.down('xs')]: {
          padding: '10px 6px 30px 0px'
        },
      },
      addEscrowText1: {
        color: '#fd8e07',
        fontFamily: "Inter",
        fontSize: "15px",
        fontWeight: "600",
        letterSpacing: "0.36px",
        lineHeight: "16px",
        padding: '10px 0px 10px 0px'
     },
     addEscrowText2: {
      color: '#8F8F8F',
      fontFamily: "Inter",
      fontSize: "15px",
      fontStyle: "Italic",
      fontWeight: "500",
      letterSpacing: "0.25px",
      lineHeight: "16px",
      padding: '0px 20px 20px 0px'
   },
      toggleBtn: {
        marginTop: '12px',
        marginLeft: '20px'
      },
      progress: {
        color: '#fd8e07',
        marginTop: '14px',
        marginLeft: '20px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '600'
      },
      toggleOff: {
       color: '#8F8F8F',
       fontFamily: "Inter",
       fontSize: "14px",
       fontWeight: "500",
       lineHeight: "17px",
       padding: '18px 16px 10px 30px'
      },
    image: {
      marginLeft: '300px',
      marginTop: '-230px',
      marginBottom: '20px',
      height: '65px',
      [theme.breakpoints.down('sm')]: {
        height: '0px'
      },
    },
    errorMsg: {
      fontSize: '1.4em',
      fontWeight: 'bold',
      textAlign: 'justify',
      fontFamily: 'Inter',
      color: "red",
      marginLeft: '65px',
      [theme.breakpoints.down('sm')]: {
       padding: '0px 46px',
       marginLeft: '0px'
      },
    },
    apiErrorMsg: {
      fontSize: '1.4em',
      fontWeight: 'bold',
      textAlign: 'justify',
      fontFamily: 'Inter',
      color: "red"
    },
    nextEscrow: {
     padding: '0px 40px',
      [theme.breakpoints.down('sm')]: {
        padding: '0px 15px'
      },
    },
    escrowSection: {
     padding: '35px 40px 10px 42px',
     [theme.breakpoints.down('sm')]: {
        padding: '30px 40px 10px 15px',
     },
    },
    escrowHeading: {
      color: '#EDB36A',
      fontFamily: "Inter",
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: '600'
    },
    manageEscrow: {
      color: '#0079D0',
      cursor: "pointer",
      fontFamily: "Inter",
      fontSize: "14px",
      lineHeight: "24px",
      fontWeight: '400',
    },
    line: {
      border: '1px solid #E4E4E4'
    },
    infoText: {
      color: '#474747',
      fontFamily: "Montserrat",
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: '400',
      padding: '5px 20px 20px 16px'
    },
    
    infoHeading: {
      color: '#626262',
      fontFamily: "Montserrat",
      fontSize: "18px",
      lineHeight: "22px",
      fontWeight: '700',
      padding: '26px 20px 5px 16px'
    },
    container: {
      width: '120em',
      minWidth: "33.3em",
      marginTop: '3.6em',
      marginBottom: '6.6em',
      [theme.breakpoints.down('sm')]: {
        margin: '42px 16px',
      },
    },
 
    formContainer: {
      minHeight: "300px",
      backgroundColor: '#fff',
      borderRadius: 12,
      boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },


    containerPadding: {
      padding: "1px 0px"
    },

  infoIcon: {
      padding: "6px 6px",
      fontSize: '30px',
      color: theme.palette.secondary.contrastText,
    },

  }
));

