import React, { useState } from 'react';
import './style.css'

export const SwitchButton = (props) => {
    //const [status, setStatus]= useState(true)
    const {status, handleSwitch, switchName} = props

const handleChange=()=>{
    //setStatus(!status)
    if(handleSwitch){
        handleSwitch({fieldName:switchName, fieldValue:!status})
    }
   
}

    return (<label className="switch">
    <input type="checkbox" onChange={()=>handleChange()} checked={props.status} />
    <span className={`slider round textAligment ${props.classes}`}>{props.switchONText?props.switchONText:'on'} <span className="offBtn">{props.switchOFFText?props.switchOFFText:'off'}</span></span>    
  </label>)
}