import React, { Fragment, useState } from 'react';
import { useStyles } from './styles';
import { Box, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { HippoPopup } from '../../components'



export const HippoCard = (props) => {

  const { bannerBody, bannerImageUrl, bannerTitle,
    buttonColor, buttonText, buttonURL, titleBgColor, bannerSubTitle } = props.banner;

  const [isPopup, setIsPopup] = useState(false)

  const classes = useStyles();

  const handlePopup = () => {
    setIsPopup(true)
  }

  const handlePopupClose = (status) => {
    setIsPopup(status)
  }

  return (<Fragment>
    <Box className={classes.root}>
      <Box className={classes.header} style={{ backgroundColor: titleBgColor }}>
        <h1 className={classes.title}>{bannerTitle}</h1>
      </Box>
      <Box className={classes.body} >
        <h3 className={classes.subTitle} dangerouslySetInnerHTML={{ __html: bannerSubTitle }} />
        <img className={classes.logo} src={bannerImageUrl} alt='logo' />
        <Box className={classes.bodyTextContainer}>
          <p className={classes.bodyText} dangerouslySetInnerHTML={{ __html: bannerBody }}></p>
        </Box>

      </Box>
      <Box className={classes.footer}>
        {/* <Button component={Link} to={buttonURL} className={classes.btn} style={{ backgroundColor: buttonColor }}>{buttonText}</Button> */}
        <Button onClick={() => handlePopup()} className={classes.btn} style={{ backgroundColor: buttonColor }}>{buttonText}</Button>
      </Box>
    </Box>
    {isPopup ? (<HippoPopup
      open={isPopup}
      handleClose={handlePopupClose}
      accountNumber={props.accountNumber}></HippoPopup>) : null}
  </Fragment>
  )
}
