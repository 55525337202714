import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { useStyles } from './styles';

import { getPaymentHistoryTH } from '../../actions';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import CustomizedTables from '../TransactionHistory/CustomizedTables';
import { renderDefaultMsg, getAppSource, getUserType } from '../../helpers';

const DEFAULT_LOAD_ROWS = 0
const LOADMORE = 5

export const PaymentTransactionHistory = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('')
  const [showRecords, setShowRecords] = useState(DEFAULT_LOAD_ROWS)
  const [allRecords, setAllRecords] = useState([])
  const [trData, setTrData] = useState([])
  const [isLoader, setIsLoader] = useState(false)

  useEffect(() => {
    if (props.accountNumber) {
      let dt = getStartDate("Last30Days")
      getPaymentHistory(dateFormat(dt))
    }
  }, [props.accountNumber]);

  const getPaymentHistory = async (stDate) => {
    setErrorMessage('');
    const requestData = {
      "accountNumber": props.accountNumber ? props.accountNumber : '',
      "historyFromDate": stDate,
      "historyThruDate": dateFormat(new Date()),
      "historyNextSequence": 0,
      "historyNextTime": 0,
      "paymentType": "All",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get("publicIp"),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }
    setIsLoader(true)
    const result = await getPaymentHistoryTH(requestData)
    setIsLoader(false)
    if (result) {
      const { lstResponseStatus, lstAccountHistory } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        setAllRecords(lstAccountHistory)
        let noOfRecordsShow = showRecords + LOADMORE
        let data = lstAccountHistory.slice(0, noOfRecordsShow)
        setShowRecords(noOfRecordsShow)
        setTrData(data)
      } else {
        setAllRecords([])
        setTrData([])
        setErrorMessage(lstResponseStatus[0].statusDesc)

      }
    } else {
      setAllRecords([])
      setTrData([])
      setErrorMessage(renderDefaultMsg('400'));
    }
  }

  const handelLoadMore = () => {
    let noOfRecordsShow = showRecords + LOADMORE
    let data = allRecords.slice(0, noOfRecordsShow)
    setShowRecords(noOfRecordsShow)
    setTrData(data)
  }

  const fullHistory = () => {
    history.push('/TransactionHistory')
  }

  const getStartDate = (selectedDate) => {
    let date = new Date();
    if (selectedDate === "Last30Days") {
      date.setDate(date.getDate() - 30);
    } else if (selectedDate === "Last3Months") {
      date.setMonth(date.getMonth() - 3);
    } else if (selectedDate === "Last6Months") {
      date.setMonth(date.getMonth() - 6);
    } else if (selectedDate === "CurrentYear") {
      date.setDate(1);
      date.setMonth(0);
    } else if (selectedDate === "LastYear") {
      date.setFullYear(date.getFullYear() - 1);
    }
    return date;
  }

  const dateFormat = (date) => {
    let dt = new Date(date)
    return (dt.getMonth() + 1) + '/' + (dt.getDate()) + '/' + (dt.getFullYear());
  }

  return (<Box mt={4}>
    <Box className={classes.recentTransactionsContainer}>
      <Box py={5} pl={3}>
        {/* {errorMessage !== '' ? (<Typography className="errorMessage">{errorMessage}</Typography>) : ''} */}
        <Grid container direction="row" justify="flex-start" alignItems="center" >
          <Grid item md={10}>
            <Typography className={classes.headingText}>Transaction History</Typography>
          </Grid>
          <Grid item >
            <Typography onClick={() => fullHistory()} className={classes.link}>View Full History</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box px={2} pb={2}>

        <CustomizedTables
          data={allRecords} page="payment"></CustomizedTables>
      </Box>
      {/* <Box px={2} pb={2}>
        {allRecords.length > trData.length ?
          (<Typography onClick={() => handelLoadMore()} className={classes.link}>Load more</Typography>) : null}

      </Box> */}
    </Box>
  </Box>)
}