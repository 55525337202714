import { makeStyles, withStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 700,
    borderRadius: 12,
    backgroundColor: 'white',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)"
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxHeight: "100%",
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '20px',
        margin: '50px 15px'
      },
  },

  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  heading:{
    color: '#198100',
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    display: 'flex',
    alignItems: 'end'
  },
  heading2:{
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    color: '#000',
    marginTop:'20px'
  },
  body:{
    color: '#000',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    //lineHeight: '28px',
    marginTop:'20px'
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "400",
    textAlign: 'center',
    margin: 14,
  },

  cardBody: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '12px 2em',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  cardBody2: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '8px 2em',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  statusTitle: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '18px',
    fontWeight: "700",
    padding: '3px 2px 2px 3px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px'
    }
  },

  statusBody: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "bold",
  },

  linkStyle: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    fontWeight: "600",
    marginTop: 5,
  },
  singleFooterItem:{
    textAlign: 'center',
    padding: '0px 20px 20px'
  },
  btn: {
    width: 188,
    height: '32px',
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    fontWeight: "600",
    margin: "10px",
    '&.documentBtn':{
      width:'max-content'
    },
    [theme.breakpoints.down('sm')]: {
        width: 100,
        fontSize: '1em',
        height: '35px'
    }
  },

  closeIcon: {
    position: 'absolute',
    right: 42,
    paddingTop: 18,
    [theme.breakpoints.down('sm')]: {
        right: 10
    }
  },
  closeIco: {
    position: 'absolute',
    right: -5,
    paddingTop: 7,
    [theme.breakpoints.down('sm')]: {
        right: 10
    }
  },

  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },
  
  inputStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600"
  },

  labelStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "400",
  },

  cardFooter: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: "#fff",
    padding: '8px 95px 20px',
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      padding: '8px 40px 0'
    },
  },

  footerItem: {
    // marginBottom: 33,
    maxWidth: 175,
    marginRight: 25
  },

  boxMargin: {
    marginLeft: 75
  },

  paragraph: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: 15,
    fontWeight: "500",
    marginBottom: 8,
    textAlign: 'center'
 },

 proceed: {
  color: "#202020",
  fontFamily: "Montserrat",
  fontSize: 15,
  fontWeight: "700",
  marginBottom: 0,
  marginTop: 22,
  textAlign: 'center',
},


cardContainer: {
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',
  marginTop: 15,
},

card1: {
  maxWidth: '100%',
  padding: '10px',
  borderRadius: 12,
  border: '1px solid #EDB36A',
  backgroundColor: "#FBF0E1",
  boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
  marginBottom: 20,
  // display: 'flex',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    width: '100%',
    padding: '15px',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%'
  }
},

cardIcon: {
  width: 28,
  height: 22,
  color: "#EDB36A",
  marginTop: 22,
  [theme.breakpoints.down('sm')]: {
    marginBottom: 10,
  },
},

cardContent: {
  display: 'flex'
},

cardTitle: {
  color: "#000000",
  fontFamily: "Montserrat",
  fontSize: "12px",
  fontWeight: "600",
  marginBottom: '20px',
  letterSpacing: '0.15px',
  linHheight: '24px',
},

disableBtn: {
  color: "rgba(0, 0, 0, 0.26)",
  boxShadow: "none",
  backgroundColor: "rgba(0, 0, 0, 0.12)",
  cursor: "pointer",
  width: 188,
  height: '32px',
  fontFamily: "Montserrat",
  fontSize: '1.5em',
  fontWeight: "600",
  margin: "10px",
  '&:hover': {
    backgroundColor: "rgba(0, 0, 0, 0.12)",
  },
  [theme.breakpoints.down('sm')]: {
      width: 80,
      fontSize: '1.3em'
  }
},
paragraph: {
  color: "#121D22",
  fontFamily: "Montserrat",
  fontSize: 14,
  fontWeight: "500",
  marginBottom: 8,
  textAlign: 'center',
},
call: {
  color: '#1A4664',
  fontFamily: 'Montserrat',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: 'normal',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .ico': {
    height: '20px',
    width: '20px',
    marginRight: '8px'
  }
},
Divider: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '15px 0px'
},
DividerText: {
  color: '#121D22',
  textAlign: 'center',
  fontFamily: 'Montserrat',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  margin: '0px 5px'
},
innerContainer:{
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  margin:'0px 0px 20px'
},
btnText: {
  fontFamily: 'Montserrat',
  fontSize: '12px',
  fontStyle: 'italic',
  fontWeight: 400,
  lineHeight: '18px',
  marginBottom:'10px'
}

}));

