import React, { useState, useEffect, useRef } from 'react';
import Chart from 'chart.js';
import { numberWithCommas } from '../../helpers';
import moment from 'moment';


export const CustomChart = (props) => {

    const chartContainer = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);

    const chartConfig = {
        data: {
            datasets: [{
                type: 'line',
                label: 'Original',
                backgroundColor: "#202020",
                borderColor: "#202020",
                data: props.chartData.original,
                pointRotation: 0,
                fill: false,
                lineTension: 0,
                borderWidth: 2,

            }, {
                type: 'line',
                label: 'Projection',
                backgroundColor: "#F7B15C",
                borderColor: "#F7B15C",
                data: props.chartData.projection,
                fill: true,
                lineTension: 0,
                borderWidth: 2,
            }]
        },
        options: {
            responsive: true,
            tooltips: {
                mode: 'nearest',
                intersect: true
            },
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    distribution: 'series',
                    offset: true,
                    stacked: true,
                    ticks: {
                        major: {
                            enabled: false,
                            fontStyle: 'bold'
                        },
                        source: 'data',
                        beginAtZero: true,
                        autoSkip: true,
                        autoSkipPadding: 75,
                        maxRotation: 0,
                        sampleSize: 100
                    },
                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        callback: function (value, index, values) {
                            return numberWithCommas(parseFloat(value)) + ' $';
                        }
                    },
                }]
            },
            tooltips: {
                titleFontColor:"rgba(0,0,0,0.87)",
                titleFontFamily:'Montserrat',
                titleFontSize: 14,
               // titleAlign:"center",
               titleSpacing:4,
                xPadding:15,
                yPadding:15,
                borderColor:"#B3B8C0",
                borderWidth:2,
                backgroundColor: '#fff',
                bodyFontFamily:'Montserrat',
                bodyFontColor:'rgba(0,0,0,0.87)',
                callbacks: {
                    title: function (tooltipItem, data) {           
                        return moment(tooltipItem[0].label).format('ll');
                    },
                    afterTitle: function (tooltipItem, data) {                                             
                        var label = data.datasets[tooltipItem[0].datasetIndex].label || '';                        
                        if (label) {                           
                            label = 'Total Balance: $ ';
                        }
                        label += numberWithCommas(parseFloat(tooltipItem[0].yLabel).toFixed(2));                                               
                        return label;
                    },
                    label: function (tooltipItem, data) {
                        let principal = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].principal || '';
                        if(principal){
                            principal = `Principal: $ ${numberWithCommas(parseFloat(principal).toFixed(2))}`
                        }                                              
                        return principal;
                    },
                    // label: function (tooltipItem, data) {                                              
                    //     var label = data.datasets[tooltipItem.datasetIndex].label || '';                        
                    //     if (label) {                           
                    //         label = 'Total Balance: $';
                    //     }
                    //     label += tooltipItem.yLabel;                                               
                    //     return label;
                    // },
                    afterLabel: function (tooltipItem, data) {                        
                        let interest = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].interest || '';
                         interest = `Interest: $ ${numberWithCommas(parseFloat(interest).toFixed(2))}`                                              
                        return interest;
                    },                                        
                    labelTextColor: function(tooltipItem, chart) {
                        return 'rgba(0,0,0,0.87)';
                    }                    
                },
               
               
            }
        },

    };

    useEffect(() => {
        if (chartContainer && chartContainer.current && props.chartData && props.chartData.original && props.chartData.original.lenth !== 0) {
            const newChartInstance = new Chart(chartContainer.current, chartConfig);
            setChartInstance(newChartInstance);
        }
    }, [chartContainer]);


    useEffect(() => {
        if (chartInstance) {
            chartInstance.data.datasets[1].data = props.chartData.projection;
            chartInstance.update();
        }
    }, [props.chartData]);

    return (
        <div style={{ width: '100%' }}>
            <canvas ref={chartContainer} />
        </div>
    )
}