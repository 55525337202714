import React, { useEffect, useState } from 'react';
import { Box, CssBaseline, Grid, Typography, Button, CircularProgress } from '@material-ui/core';
import { useStyles } from './styles';
import { numberWithCommas, renderDefaultMsg, getUserType, getAppSource, scrollTop } from '../../helpers';
import {Taxes} from './Taxes';
import {HomeownerIns} from './HomeownerIns';
import {MortgageIns} from './MortgageIns';
import CustomBreadcrum from '../Breadcrum';
import { useSelector } from 'react-redux';
import { getEscrowInsuranceData, updateDisplayEscrowStatus, hideAnnouncement, insertActivityLog, getDashboardData } from '../../actions';
import { useHistory, useLocation } from 'react-router-dom';
import { EscrowStatus } from './EscrowStatus';
import { EscrowAnalysisAlert } from '../EscrowAnalysisAlert';
import line from '../../assets/line.png';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import Cookies from 'js-cookie';
import InfoIcon from '@material-ui/icons/Info';
import { decrypt } from '../../helpers/encrypt';
import { AccessAlert } from '../AccessAlert';
import {CoveredTile} from './CoveredTile';
import './styles.css'

const PROPERTY_TAXES = "Property Taxes"
const HOMEOWNER_INSURANCE = "Homeowners Insurance"
const MORTGAGE_INSURANCE = "Mortgage Insurance"

export const EscrowInfo = (props) => {

  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const escrowStatus = Cookies.get('escrow');
  const mytheme = useSelector(state => state.theme);
  const escrowData = useSelector(state => state?.escrowReducer);
  const loanInfoData = useSelector(state => state?.loanInfoReducer);

  const { primaryColorCode, secondaryColorCode } = mytheme;
  
  const [selectedTab, setSelectedTab] = useState(PROPERTY_TAXES)
  const [escrowInsurance, setInsuranceData] = useState()
  const [propertyTaxes, setPropertyTaxes] = useState([])
  const [HomeownerInsurance, setHomeownerInsurance] = useState([])
  const [mortgageInsurances, setMortgageInsurance] = useState()
  const [escrowApiError, setEscrowApiError] = useState('');
  const [open, setStatus] = useState(true);
  const [escrowRequestStatus, setEscrowrequestStatus] = useState([])
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState(null);
  const status = useSelector(state => state.announcementReducer);
  const releaseAnnouncement = useSelector(state => state.releaseAnnouncement);
  const selectedAccountData = useSelector(state => state.selectedAccountData);
  const privateLabelUrl = Cookies.get("privateLabelUrl")

  const [taxInsuranceMenuStatus, setTaxInsuranceMenu] = React.useState(false);
  const [eaStatus, setEAStatus] = useState(false);
  const [displayStatus, setTileDisplayStatus] = useState(false);

  useEffect(() => {
    if (releaseAnnouncement?.length > 0) {
      var filterData = releaseAnnouncement?.filter(item => item.pageURL == 'EscrowManage')
      if (filterData?.length > 0) {
        setTaxInsuranceMenu(true)
      } else {
        setTaxInsuranceMenu(false)
      }
    } else {
      if (Cookies.get('releaseIds') !== undefined) {
      const releaseIds = JSON.parse(Cookies.get('releaseIds'))
      var releaseIdData = releaseIds?.filter(item => item.pageURL == 'EscrowManage')
      if (releaseIdData?.length > 0) {
        setTaxInsuranceMenu(true)
      } else {
        setTaxInsuranceMenu(false)
      }
    }
  }
  }, [releaseAnnouncement])

  useEffect(() => {
      setTimeout(() => {
        setTaxInsuranceMenu(false)
        hideReleaseAnnouncement()
      }, 60000);
  }, [])

  useEffect(() => {
    if ((location.search.split('?escrowactivity=')[1] !== undefined)) {
      const queryParams = new URLSearchParams(location.search)
      sendActivityData()
      if (queryParams.has('escrowactivity')) {
        queryParams.delete('escrowactivity')
        history.replace({
          search: queryParams.toString(),
        })
      }
    }
  }, [(location.search.split('?escrowactivity=')[1] !== undefined)])
  
  const getData = async (props) => {
    setLoader(true)
    const allAccountsDetail = await Cookies.get("loanAccounts");
    let allAccounts = JSON.parse(allAccountsDetail);
    const filterAcc = allAccounts.filter((item) => item.accountNumber == decrypt(props?.accountNumber));
    setEAStatus(filterAcc[0]?.eaPopup2Status)

    const data = {
      "accountNumber": props?.accountNumber,
      "propertyState": selectedAccountData && selectedAccountData.propertyState ? selectedAccountData.propertyState : '', //new Added
      "investorCode": selectedAccountData && selectedAccountData.bsi_InvestorCode ? selectedAccountData.bsi_InvestorCode : '', //new Added
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };

    const res = await dispatch(getDashboardData(data));
    if (res) {
      setLoader(false)
      setData(res);
      if (privateLabelUrl.toLowerCase() === "bsi") {
        const tileDisplayOrder = res.bannerInfo.filter((item) => ((item.bannerName.toUpperCase() === 'COVERED') || (item.bannerName.toUpperCase() === 'MATIC'))).filter((key) => (key.isDisplay == true));
        setTileDisplayStatus(tileDisplayOrder[0]?.dispayOrder == 0 ? true : false)
      } 
    } else {
      setLoader(false)
    }
  }

  const sendActivityData = async () => {
    const data = {
      "accountNumber": props?.accountNumber,
      "activityCode": "UAL193",
      "activityStatus": "Success",
      "action": 'EscrowLink',
      "controller": 'EscrowNotification',
      "apiName": "EscrowNotification/AdhocEscrowTelemetry",
      "activityRemark": 'Borrower clicked on escrow account link',
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertActivityLog(data);
  }

  const hideReleaseAnnouncement = async () => {
    await dispatch(hideAnnouncement(false));
  }

  const getInsuranceData = async (props) => {
    setLoader(true);
    const response = await dispatch(getEscrowInsuranceData(props.accountNumber))
    if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode === '0') { 
      setEscrowApiError('')
      setLoader(false)
      setInsuranceData(response?.responseData?.responseData?.escrowOverview)
      setPropertyTaxes(response?.responseData?.responseData?.propertyTaxes)
      setHomeownerInsurance(response?.responseData?.responseData?.homeOwnersInsurances)
      setMortgageInsurance(response?.responseData?.responseData?.mortgageInsurance)
      setEscrowrequestStatus(response?.responseData?.responseData?.escrowRequestStatuses)
      if (response?.responseData?.responseData?.escrowRequestStatuses != null) {
        setStatus(true)
        setTimeout(() => {
          displayEsrowStatus(response?.responseData?.responseData?.escrowRequestStatuses)
         }, 30000)
      }
    } else if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode !== '0') {
      setLoader(false)
      setEscrowApiError(response?.responseData?.lstResponseStatus[0]?.statusDesc)
    } else {
      setLoader(false)
      setEscrowApiError(renderDefaultMsg('400'));
    }
  }

  useEffect(() => {
   if (props.accountNumber) 
    getInsuranceData(props)
    getData(props)
  },[props.accountNumber])
 
  useEffect(() => {
    if (escrowStatus != 'true') {
       history.push('/Dashboard?access=false');
    }
  }, [])

 
  const displayEsrowStatus = async (escrowStatusLists) => {
      for (var i=0 ; i < escrowStatusLists.length; i++) {
      const response = await updateDisplayEscrowStatus(props.accountNumber, escrowStatusLists[i].taskNumber, escrowStatusLists[i].controlNumber)
      if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode === '0') { 
        setStatus(false)
      }
    }
  }

  const handleManageAccount = () => {
    history.push({pathname: '/EscrowManage'});
  }
 
  const handleSubmitClose = () => {
    setStatus(false)
  }
 
  const accessDeniedPopup = () => {
    history.push('/Dashboard');
  }

  if (loader) {
    return (
      <Box className={classes.loader}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <CssBaseline>
       <Box container="true" maxWidth='xl' className={classes.root}>      
        <Box className={classes.container}>
        <CustomBreadcrum backPage="Loan Details" backPageLink="/LoanInfo" activePage="Taxes & Insurance Information" />
          <h1 className={classes.pageHeading} color="textPrimary" component='h4'>Taxes & Insurance Information</h1>
          {escrowApiError !== '' ? <Typography className={classes.errorMsg}>{escrowApiError}</Typography> : null}
         
        <Box className={classes.transferBox}>
          <Grid container direction="row" alignItems="center" style={{ paddingLeft: '5px' }}>
            <Grid item md={12} xs={12} lg={12}>
              <Box display='flex' alignItems="center">
                <InfoIcon style={{ fill: '#fff' }} className={classes.infoIcon}/>
                <Typography className={classes.completeUrSurvey}>Have questions on how your escrow analysis is processed? To view a short video explanation <a className={classes.click} onClick={() => history.push('/HelpDescription/FAQs?eaAnalysis')}>click here</a>.</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box> 

          {(open && escrowRequestStatus?.length > 0) &&
            <Box  alignItems="center" style={{ marginBottom: '0.5rem' }} className={classes.infoBox}>
                {escrowRequestStatus?.length > 0 && escrowRequestStatus?.map((key) => (
                <Typography className={classes.information}>
                  Your request with case number <b> {key.taskNumber}-{key.controlNumber}</b> submitted on  {moment(key?.requestedDate).format('ll')} is completed successfully.
                  </Typography>
              ))}
            </Box>
          }
           
         {data && displayStatus && <CoveredTile data={data}/>}
         { escrowInsurance &&
           <>
            <Box className={classes.infoStrip}>
          
               <>
                  <Grid container>
                    <Grid item md={6} xs={12} lg={6}>
                      <Box className={classes.escrowSection}>
                        <Typography className={classes.escrowHeading}>ESCROW</Typography>
                          <Grid container>
                            <Grid item  md={6} xs={12} lg={6}>
                              <Typography className={classes.escrowRec}>Current Escrow Balance</Typography>
                              <Typography className={classes.escrowRecAns}>{ Math.sign(escrowInsurance?.currentEscrowBalance) == -1 ? '-$' +  numberWithCommas(parseFloat(Math.abs(escrowInsurance?.currentEscrowBalance)).toFixed(2)) : '$' + numberWithCommas(parseFloat(escrowInsurance?.currentEscrowBalance).toFixed(2))}</Typography>
                             
                            </Grid>
                            <Grid item  md={6} xs={12} lg={6}>
                              <Typography className={classes.escrowRec}>Monthly Escrow Payment</  Typography>
                              <Typography className={classes.escrowRecAns}>{ Math.sign(escrowInsurance?.monthlyEscrowPayment) == -1 ?  '-$' + numberWithCommas(parseFloat(Math.abs(escrowInsurance?.monthlyEscrowPayment)).toFixed(2)) : '$' + numberWithCommas(parseFloat(escrowInsurance?.monthlyEscrowPayment).toFixed(2)) }</Typography>
                            <img src={line} className={classes.image}></img>
                            </Grid>
                           
                        </Grid>              
                      </Box>     
                    
                    </Grid>
                   
                    <Grid item  md={6} lg={6}  xs={12} >
                    
                    <Box className={classes.escrowSection}>
                      <Typography className={classes.escrowHeading}>TAXES & INSURANCE PAID (YTD)</Typography>
                     
                      <Grid container>
                        <Grid item md={3} lg={3}  xs={12} >
                          <Typography className={classes.boxText}>Taxes:</Typography>
                        </Grid>
                        <Grid item md={3} lg={3}  xs={12} >
                          <Typography  className={classes.boxTextValue}>{ 
                           Math.sign(escrowInsurance?.totalTaxAmount) == -1 ? '-$' + numberWithCommas(parseFloat(Math.abs(escrowInsurance?.totalTaxAmount)).toFixed(2))  : '$' + numberWithCommas(parseFloat(escrowInsurance?.totalTaxAmount).toFixed(2)) }</Typography>
                          </Grid>
                        <Grid item md={3} lg={3}  xs={12} >
                          <Typography  className={classes.boxText}>MI/PMI:</Typography>
                        </Grid>
                        <Grid item md={3} lg={3}  xs={12} >
                          <Typography  className={classes.boxTextValue}>{
                            Math.sign(escrowInsurance?.totalMIPMIAmount) == -1 ? '-$' + numberWithCommas(parseFloat(Math.abs(escrowInsurance?.totalMIPMIAmount)).toFixed(2))  : '$' + numberWithCommas(parseFloat(escrowInsurance?.totalMIPMIAmount).toFixed(2))
                            }</Typography>
                        </Grid>
                      </Grid>
                  
                 <Grid container>
                  <Grid item md={3} lg={3}  xs={12} >
                    <Typography className={classes.boxText}>Insurance:</Typography>
                  </Grid>
                  <Grid item md={3} lg={3}  xs={12} >
                    <Typography  className={classes.boxTextValue}>{
                     Math.sign(escrowInsurance?.totalInsuranceAmount) == -1 ? '-$' + numberWithCommas(parseFloat(Math.abs(escrowInsurance?.totalInsuranceAmount)).toFixed(2))  : '$' + numberWithCommas(parseFloat(escrowInsurance?.totalInsuranceAmount).toFixed(2))
                   }</Typography>
                  </Grid>

                  <Grid item md={3} lg={3}  xs={12} >
                    <Typography className={classes.boxText}>Total Paid:</Typography>
                  </Grid>
                  <Grid item md={3} xs={12} lg={3}   >
                    <Typography  className={classes.boxTextValue}>
                    { 
                      Math.sign(escrowInsurance?.totalPaid) == -1 ? '-$' + numberWithCommas(parseFloat(Math.abs(escrowInsurance?.totalPaid)).toFixed(2))  : '$' + numberWithCommas(parseFloat(escrowInsurance?.totalPaid).toFixed(2))
                    }</Typography>
                  </Grid>
                 </Grid>
               
                </Box>
              </Grid>
            </Grid>
          
            <Box className={classes.nextEscrow}>
            { (escrowInsurance.isEligibleForManageEscrow ||  escrowInsurance?.nextEAScheduledDate) &&
              <hr className={classes.line} />
            }
              <Grid container>
                <Grid item md={6} lg={6} sm={12}>
                { escrowInsurance?.nextEAScheduledDate &&
                <Typography className={classes.nextEscrowDate}>Your next escrow analysis is scheduled for <span className={classes.date}>
                {escrowInsurance?.nextEAScheduledDate ? moment(escrowInsurance.nextEAScheduledDate).format('MMMM, YYYY') : 'N/A'}
                </span></Typography> }
                </Grid>
                <Grid item md={6} lg={6} sm={12}>
                  { (escrowInsurance.isEligibleForManageEscrow && (propertyTaxes != null || HomeownerInsurance != null)) &&
                <Typography className={classes.escrowAccount}><span className={classes.manageEscrow} onClick={handleManageAccount}>
                <span class={taxInsuranceMenuStatus && status ? 'blink' : 'stop-blink'}>Click Here</span> 
                </span> to manage your escrow account</Typography> }
                </Grid>
              </Grid>
            </Box>
            
            </>
            
          </Box>
          <Box>
            <Box className={classes.tabContainer}>
                  <Grid container direction="row" justify="center" alignItems="center" className={classes.tabStyle}>
                      <Box p={3}
                          style={{'backgroundColor': selectedTab === PROPERTY_TAXES ? primaryColorCode : 'white', 'width' :  mortgageInsurances != null ? '33.33%' : '50%' }}
                          className={`${classes.tabs} ${selectedTab === PROPERTY_TAXES ? classes.activeTab : classes.tabShedow}`}
                          onClick={() => setSelectedTab(PROPERTY_TAXES)}><Box className={classes.linkStyle} >{PROPERTY_TAXES}</Box></Box>
                      <Box
                          p={3}
                          style={{'backgroundColor': selectedTab === HOMEOWNER_INSURANCE ? primaryColorCode : 'white',  'width' :  mortgageInsurances != null ? '33.33%' : '50%' }}
                          className={`${classes.tabs} ${selectedTab === HOMEOWNER_INSURANCE ? classes.activeTab : classes.tabShedow}`}
                          onClick={() => setSelectedTab(HOMEOWNER_INSURANCE)}>
                          <Box className={classes.linkStyle} >{HOMEOWNER_INSURANCE}</Box>
                      </Box> 
                      { mortgageInsurances != null ?
                      <Box p={3}
                          style={{'backgroundColor': selectedTab === MORTGAGE_INSURANCE ? primaryColorCode : 'white',  'width' :  '33.33%'}}
                          className={`${classes.tabs} ${selectedTab === MORTGAGE_INSURANCE ? classes.activeTab : classes.tabShedow}`}
                          onClick={() => setSelectedTab(MORTGAGE_INSURANCE)}><Box className={classes.linkStyle}>{MORTGAGE_INSURANCE}</Box></Box> : null }
                      
                  </Grid>
            </Box>
            {((selectedTab === PROPERTY_TAXES) && data)  ? (<Box className={classes.containerPadding}>
              <Taxes accountNumber={props.accountNumber} taxes={propertyTaxes} coveredBanner={data} displayStatus={displayStatus}></Taxes>
            </Box>) : null}


            {((selectedTab === HOMEOWNER_INSURANCE) && data) ? (<Box className={classes.containerPadding}>
              <HomeownerIns accountNumber={props.accountNumber} homeownerIns={HomeownerInsurance} coveredBanner={data} displayStatus={displayStatus}></HomeownerIns>
            </Box>) : null}

            {((selectedTab === MORTGAGE_INSURANCE) && data) ? (<Box className={classes.containerPadding}>
              <MortgageIns accountNumber={props.accountNumber} mortgageInsurances={mortgageInsurances} coveredBanner={data} displayStatus={displayStatus}></MortgageIns>
            </Box>) : null}

          </Box>
         </>
         }
        </Box>
      </Box>
      {/* {open &&
      <EscrowStatus
        open={open}
        escrowRequestStatus={escrowRequestStatus}
        handleSubmitClose={handleSubmitClose}
      />
     } */}
      {
        data?.displayEscrowAnalysisPopUp && eaStatus &&
           <EscrowAnalysisAlert escrowAlert={true} page={'escrow'} accountNumber={props?.accountNumber}/>
       }
       { loanInfoData && !loanInfoData?.bankDetail?.displayEscrowMenu &&
        <AccessAlert status={true} accessDeniedPopup={accessDeniedPopup}/>
       }
    </CssBaseline>
  )
}
