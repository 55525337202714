import { makeStyles, withStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
    borderRadius: 12,
    backgroundColor: 'white',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)"
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxHeight: "100%",
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '20px',
        margin: '50px 15px'
      },
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "400",
    textAlign: 'center',
    margin: 14,
  },

  cardBody: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '12px 2em',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  statusTitle: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: '18px',
    fontWeight: "700",
    padding: '3px 2px 2px 3px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px'
    }
  },

  statusBody: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "bold",
  },
  
  singleFooterItem:{
    textAlign: 'center',
    padding: '0px 20px 20px'
  },

  btn: {
    width: 188,
    height: '40px',
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600",
    margin: "10px",
    [theme.breakpoints.down('sm')]: {
        width: 100,
        fontSize: '1em',
        height: '35px'
    }
  },

  closeIcon: {
    position: 'absolute',
    right: -5,
    paddingTop: 7,
    [theme.breakpoints.down('sm')]: {
        right: 10
    }
  },

  footerItem: {
    marginBottom: 15,
    maxWidth: 175,
    marginRight: 25,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0
    },
  },

  boxMargin: {
    marginLeft: 75
  },

  paragraph: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "500",
    marginBottom: 4,
    paddingTop: 4
 },

cardContainer: {
  padding: '20px 30px'
},

closeIco: {
  position: 'absolute',
  right: -5,
  paddingTop: 7,
  [theme.breakpoints.down('sm')]: {
      right: 10
  }
},

errorMsg: {
  color: "#B00020",
  fontFamily: "Montserrat",
  fontSize: '1.4em',
  textAlign: "center",
  fontWeight: '600',
  marginBottom: 22,
},

}));

