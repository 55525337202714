import Cookies from 'js-cookie';
import { adminProtected } from '../config/adminConfig';
import { getAppSource, getUserType } from '../helpers';

export const getSuperAdminPrivatelabel = async () => {
  const data = {
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
  }

  try {
    const response = await adminProtected.post('Admin/GetPrivateLabelList', data);
    if (response.status === 200) {
      return response.data;
    }
    return null
  } catch (e) {
    return null
  }
}

export const getinvestorcodeLabel = async (labelData) => {
  try {
    const response = await adminProtected.post('Admin/GetInvestorCode', labelData);
    if (response.status === 200) {
      return response.data;
    }
    return null
  } catch (e) {
    return null
  }
}

export const GetUserActivityLog = async (data) => {
  try {
    const response = await adminProtected.post('Admin/GetUserActivityLog', data);
    if (response.status === 200) {
      return response.data;
    }
    return null
  } catch (e) {
    return null
  }
}

export const CheckUserExist = async (data) => {
  try {
    const response = await adminProtected.post('Admin/CheckUserExist', data);
    if (response.status === 200) {
      return response.data;
    }
    return null
  } catch (e) {
    return null
  }
}

export const DeleteUser = async (data) => {
  try {
    const response = await adminProtected.post('Admin/DeleteUser', data);
    if (response.status === 200) {
      return response.data;
    }
    return null
  } catch (e) {
    return null
  }
}

export const getSuperAdmininsertInvestorCode = async (labelData) => {
  const data = {
    "investorCode": labelData.investorCode,
    "isVA": labelData.isVA,
    "privateLabelCode": labelData.privateLabelCode,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
  }

  try {
    const response = await adminProtected.post('Admin/InsertInvestorCode', data);
    if (response.status === 200) {
      return response.data;
    }
    return null
  } catch (e) {
    return null
  }
}

export const updateInvestorcodelabel = async (data) => {
  try {
    const response = await adminProtected.post('Admin/UpdateInvestorCode', data);
    if (response.status === 200) {
      return response.data;
    }
    return null
  } catch (e) {
    return null
  }
}

export const DeleteInvestorCode = async (data) => {
  try {
    const response = await adminProtected.post('Admin/DeleteInvestorCode', data);
    if (response.status === 200) {
      return response.data;
    }
    return null
  } catch (e) {
    return null
  }
}

export const adminLogOutInfo = async () => {
  const data = {
    "accountNumber": '',
    "activityCode": "UAL139",
    "activityStatus": "Success",
    "action": "LogOut",
    "controller": "Dashboard",
    "apiName": "InsertActivityLog",
    "activityRemark": "User log out Successfully.",
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": "",
  };

  try {
    const res = await adminProtected.post('/Admin/InsertActivityLog', data);
    return res;
  } catch (e) {
    return null
  }
}