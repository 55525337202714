import { Box, Button, Typography, TextareaAutosize, Grid, CircularProgress, Divider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CancelIcon from '@material-ui/icons/Cancel';
import Rating from '@material-ui/lab/Rating';
import Cookies from 'js-cookie';

import emailFilled from '../../../assets/CommunicationPreferences/emailFilled.svg';
import phoneFilled from '../../../assets/CommunicationPreferences/phoneFilled.svg';
import tick_thanks from '../../../assets/PayoffRequest/tick_thanks.svg';

import callIcon from '../../../assets/callIcon.svg';
import applyOnline from '../../../assets/applyOnline.svg';
import InfoIcon from '@material-ui/icons/Info';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import LinearProgress from '@material-ui/core/LinearProgress';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import { getAppSource, getUserType, renderDefaultMsg } from '../../../helpers';
import { getPayoffQuestionList, submitPayoffQuestion } from '../../../actions';

import React, { useState } from 'react';
import { useStyles } from './styles';
import { useEffect } from 'react';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%' }}>
        <LinearProgress variant="determinate" {...props} size={40}
          thickness={4} />
      </Box>
    </Box>
  );
}

export const PayoffRequestSurveyQuestions = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { displayRefinance } = props;
  const [btnStatus, setStatus] = useState();
  const [value, setValue] = React.useState(0);
  const [sliderValue, setSliderValue] = React.useState(0);
  const [questionId, setQuestionId] = React.useState();
  const [popupOpen, setPopupOpen] = useState(false);
  const [questionList, setQuestionList] = React.useState([])
  const [errorMessage, setErrorMessage] = useState('');
  const [radioValue, setRadioValue] = useState('');
  const [nextQuestionId, setNextQuestionId] = useState();
  const [prevQuestionId, setPrevQuestionId] = useState([]);
  const [prevDisabled, setPrevDisabled] = useState(true);
  const [nextQuestionIdList, setNextQuestionIdList] = useState([]);
  const [textBox, setTextBox] = useState('');
  const [validErr, setValidation] = useState(false)
  const [valitationErrMsg, setValitationErrMsg] = useState('')
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [rating, setRating] = useState(0);
  const [thankyouPopup, setThankYouPopup] = useState(false);
  const [isLastQuestion, setLastQuestion] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const RADIO_BUTTON = "RadioButton";
  const SCALE = "Scale";
  const CHECKBOX = "Checkbox";
  const STAR = 'Star';
  useEffect(() => {
    getQuestionList();
  }, [])

  const getQuestionList = async () => {
    if (!props.accountNumber || props.accountNumber === '') {
      return;
    }
    const requestData = {
      "accountNumber": props.accountNumber,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
      "SurveyType": props.RequestType
    }
    setErrorMessage('')
    let result = await getPayoffQuestionList(requestData)
    if (result) {
      let { responseData: { lstResponseStatus, responseData } } = result;
      responseData = responseData.map((data) => {
        data.answers.sort((a, b) => a.answerOrder - b.answerOrder);
        return data;
      })
      responseData.sort((a, b) => a.questionOrder - b.questionOrder);
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {

        if (props.isPartial && props?.partiallySubmitedData?.length > 0) {
          setPopupOpen(true);
          const prevQuestions = [...prevQuestionId];
          const currentIndex = responseData.findIndex((data) => data.questionId === props?.partiallySubmitedData[props?.partiallySubmitedData.length - 1].questionId);
          let other = '';
          let sliderV = '';
          let noSelected_1question = false
          const ques = responseData.map((data, index) => {

            props?.partiallySubmitedData.map((innderData, i) => {
              
              if (prevQuestions.indexOf(innderData.questionId) === -1 && i < props?.partiallySubmitedData.length - 1) {
                prevQuestions.push(innderData.questionId);
              }
              if (innderData.questionId === data.questionId) {
                data.answers = data.answers.map((ans) => {
                  if (data.answerType === CHECKBOX) {
                    const arr = innderData?.answer.split(':');
                    if (innderData.answer === ans.answer) {
                      ans.checked = true;
                      ans.dataId = innderData.dataId;
                      data.answerGiven = true;
                    }
                    else if (arr[0].trim() === 'Other') {
                      ans.checked = true;
                      data.otherText = arr[1].trim();
                      other = data.otherText;
                      ans.dataId = innderData.dataId;
                      data.answerGiven = true;
                    }
                  }
                  else
                    if (data.answerType === RADIO_BUTTON || data.answerType === SCALE) {
                      if (innderData.answer === ans.answer) {
                        ans.selected = true;
                        ans.dataId = innderData.dataId;
                        data.answerGiven = true;
                        if(data.answerType === SCALE)
                        sliderV = ans.answer;
                      }
                      else{
                        ans.selected = false;
                        data.answerGiven = false;
                        ans.dataId = innderData.dataId;
                      }
                      if (innderData.questionId === 1 && innderData.answer === 'No') {
                        noSelected_1question = true;
                      }
                      
                    }
                    else
                      if (data.answerType === STAR) {
                        if (innderData?.isSkipped) {
                          data.isSkipped = innderData.isSkipped
                          ans.dataId = innderData.dataId;
                          data.answerGiven = false
                          // data.rating = false
                        } else {
                        const arr = innderData?.answer.split(':');

                        if (arr[0].trim() === ans.answer) {
                          //ans.selected = true;
                          ans.rating = arr[1].trim().split(' ')[0].trim();
                          ans.dataId = innderData.dataId;
                          data.answerGiven = true;
                        } else if (arr[0].trim() === 'Other') {
                          //ans.selected = true;
                          data.otherText = arr[1].trim();
                          other = data.otherText;
                          ans.dataId = innderData.dataId;
                          data.answerGiven = true;
                        }
                      }
                      }
                  return ans;
                })
                // FOr Not applicable cases
                    const ifNAinAnswer = data.answers.filter((data)=>data.answer ==='Not Applicable') || [];

                    if(ifNAinAnswer.length>0 && data.answerType === CHECKBOX){
                      const notApplicableChecked = data.answers.filter((data)=>data.checked && data.answer ==='Not Applicable') || [];
                      const applicableChecked = data.answers.filter((data)=>data.checked && data.answer !=='Not Applicable') || [];

                  data.answers = data.answers.map((obj) => {
                    if(notApplicableChecked.length>0 && obj.answer!=='Not Applicable'){
                      obj.isDisabled = true;
                    }
                    else  if(notApplicableChecked.length===0 && applicableChecked.length>0 && obj.answer==='Not Applicable'){
                      obj.isDisabled = true;
                    }
                    return obj;
                  })
                  }
              }
            })
            if (noSelected_1question) {
              data.answers = data.answers.map((ans) => {
                if (data.questionId === 5) {
                  if (ans.answerId !== 23 && ans.answerId !== 22 && ans.answerId !== 25) {
                    ans.isHide = true;
                  }
                }
                return ans;
              })
            }

            return data;
          });
          // setQuestionId(responseData[0].questionId)
          setTextBox(other);
          setSliderValue(sliderV)
          setPrevQuestionId(prevQuestions);
          setQuestionList(responseData);
          const currentQuestionId = props?.partiallySubmitedData[props?.partiallySubmitedData.length - 1].questionId

          setQuestionId(currentQuestionId);
          let per = 0;
          const totaLength = responseData.length;
          let index = 0;
          index = responseData.findIndex((item, i) => item.questionId == currentQuestionId);
          const i = index + 1;
          per = (((i) / totaLength) * 100).toFixed();
          setPercentage(per)
        }
        else {
          setQuestionId(responseData[0].questionId)
          setPopupOpen(true);
          setQuestionList(responseData)

        }

      } else {
        setQuestionId(responseData[0].questionId);
        setQuestionList(responseData)
        setPopupOpen(false);
      }
    } else {
      setCPData({})
      setErrorMessage(renderDefaultMsg('400'))
    }
  }
  const handleCancelThanksIcon = () => {
    setPopupOpen(false);
  }
  const handleCancelIcon = (isFromThanks) => {
    submitDetails(0);
    setPopupOpen(false);
    setNextQuestionIdList([]);
    props.setPartialSurvey(true);
  }

  const handleScaleRadioChange = (e, data) => {
    setSliderValue(e.target.value);
    const questions = questionList.map((data) => {
      if (data.questionId === questionId) {
        data.answers = data.answers.map((obj) => {
          if (obj.answer === e.target.value) {
            obj.selected = true;
          }
          else {
            obj.selected = false;
          }
          return obj;
        })
        //below code for check answered for calculate percentage
        //if(!data.answerGiven){
        const answerGiven = data.answers.filter((obj) => obj.selected) || [];
        if (answerGiven.length > 0) {
          data.answerGiven = true;
          getPercentage('add', questionId);
        }
        else {
          data.answerGiven = false;
          //getPercentage('minus',questionId);
        }
        //}
      }
      return data;
    });

    setQuestionList(questions);
    setNextQuestionIdList([]);
    setValidation(false);
    if (!data.nextQuestionId) {
      let nextQuestionId = getNextQuestionID(questionId);//questionId + 1;
      setNextQuestionId(nextQuestionId);
    }
    else {
      setNextQuestionId(data.nextQuestionId);
    }
  };

  const getPercentage = (action, id) => {
    let per = 0;
    const totaLength = questionList.length;
    //const percentage = ((prevQuestionId[prevQuestionId.length-1]) / totaLength) * 100;
    //const conditionFunction = (item) => item.questionId === questionId;
    let index = questionList.findIndex((item) => item.questionId == id);
    // index = questionList.slice(index).reverse().findIndex(obj => obj.answerGiven === true);
    const i = (action === 'add') ? index + 1 : index;
    per = (((i) / totaLength) * 100).toFixed();

    setPercentage(per)
  }

  const submitDetails = async (isCompleted) => {
    
    const allAnsweredQuestions = [...prevQuestionId, questionId];
    const list = questionList.filter((data) => allAnsweredQuestions.indexOf(data.questionId) !== -1);
    
    const payQA = [];
    list.map((data) => {
      data.answers.map((obj, index) => {
        if (data.otherText && (data.answerType === CHECKBOX || data.answerType === STAR) && obj.answer === 'Other (please specify): ') {
          let localObj =
          {
            "questionId": data.questionId,
            "answer": 'Other : ' + data.otherText,
            "isSkipped": data.isSkipped ? data.isSkipped : false
          }
          localObj.dataId = (obj.dataId && props.isPartial) ? obj.dataId : 0;
          payQA.push(localObj)
        }
        else if (obj.rating && data.answerType === STAR) {
          const ans = obj.answer + ' : ' + obj.rating + ' stars';
          let localObj =
          {
            "questionId": data.questionId,
            "answer": ans,
            "isSkipped":  false
          }
          localObj.dataId = (obj.dataId && props.isPartial) ? obj.dataId : 0;
          payQA.push(localObj)
        } else if (!obj.rating && data.isSkipped && data.answerType === STAR && index === 0) {//when star question is skipped
          let localObj =
          {
            "questionId": data.questionId,
            "answer": '',
            "isSkipped": data.isSkipped ? data.isSkipped : false
          }
          localObj.dataId = (obj.dataId && props.isPartial) ? obj.dataId : 0;
          payQA.push(localObj)
        }
        else if (obj.selected || obj.checked) {
          let localObj =
          {
            "questionId": data.questionId,
            "answer": obj.answer,
            "isSkipped": data.isSkipped ? data.isSkipped : false
          }
          localObj.dataId = (obj.dataId && props.isPartial) ? obj.dataId : 0;
          payQA.push(localObj)
        }
      })
      if ((data.answerType === 'TextArea') && data.otherText) {
        let localObj =
        {
          "questionId": data.questionId,
          "answer": data.otherText,
          "isSkipped": data.isSkipped ? data.isSkipped : false
        }
        localObj.dataId = (obj.dataId && props.isPartial) ? obj.dataId : 0;
        payQA.push(localObj)
      }
    });

    const payload = {
      "accountNumber": props.accountNumber,
      "isCompleted": isCompleted,
      "surveyAnswers": [...payQA],
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": Cookies.get('userName')
    }
    setLoadingSubmit(true);
    if (isCompleted === 1){
      setThankYouPopup(true);
      props.setPartialSurvey(false);
    }
    const result = await submitPayoffQuestion(payload);

    if (result) {
      const { responseData: { lstResponseStatus, responseData } = {} } = result;
      if (lstResponseStatus && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {

        setLoadingSubmit(false);
        setThankYouPopup(true);
      } else {
        setLoadingSubmit(false);
        setErrorMessage(renderDefaultMsg('400'))
      }
    } else {
      setLoadingSubmit(false);
      setErrorMessage(renderDefaultMsg('400'))
    }
  }

  const getValidation = (filteredData) => {
    if (filteredData.answerType === CHECKBOX || filteredData.answerType === RADIO_BUTTON || filteredData.answerType === SCALE) {
      const answered = filteredData.answers.filter((data) => data.selected || data.checked) || [];

      if (filteredData.answerType === CHECKBOX) {
        const answered1 = filteredData.answers.filter((data) => data.answer === 'Other (please specify): ' && data.checked) || [];
        if (!filteredData.otherText && answered1.length == 1) {
          setValitationErrMsg('Please specify other');
          return false;
        }
        setValitationErrMsg('Please select from one of the above options');
        return (answered.length > 0);
      }
      else {
        setValitationErrMsg('Please select from one of the above options');
        return (answered.length > 0);
      }
    }
    else if (filteredData.answerType === 'TextArea') {
      if (!filteredData.otherText) {
        setValitationErrMsg('Please specify the details')
        return false;
      }
    }
    else if (filteredData.answerType === STAR) {
      const answered = filteredData.answers.filter((data) => data.rating) || [];
      // setValitationErrMsg('Please provide ratings for each of the options above')
      //return (answered.length === filteredData.answers.length - 1);
      const list = questionList.map((data) => {
        if (data.questionId === questionId) {
          if (answered.length === 0 && !data.otherText) {
            data.isSkipped = true
          }
        }
        return data;
      });
      setQuestionList(list)
    }
    // else if (filteredData.answerType === STAR && props.RequestType ==='PaidOff') {
    //   const answered = filteredData.answers.filter((data) => data.rating) || [];
    //   setValitationErrMsg('Please provide ratings for each of the options above')
    //   return (answered.length === filteredData.answers.length - 1);
      
    // }
    setValitationErrMsg('');

    return true;
  }

  const checkConditionalValues = (id) => {
    questionList.map((data) => {
      if (data.questionId === id) {
        data.answers = data.answers.map((obj) => {
          if (obj.selected && obj.nextQuestionId === 0) {
            setLastQuestion(true);
          }
          return obj;
        })
      }
      return data;
    });
  }

  const getNextQuestion = () => {
    setRadioValue('')
    const filteredData = questionList.filter((data) => data.questionId === questionId)[0];
    const ifValid = getValidation(filteredData);
    setValidation(false)
    if (!ifValid) {
      setValidation(true)
      return;
    }

    // if last question then prev needs to be disabled
    if (questionList[questionList.length - 1]?.questionId === questionId || isLastQuestion) {
      //if user click on last question Id then api needs to call with user selection
      submitDetails(1);
      return;
    }
    if (nextQuestionIdList.length == 0) {
      if (filteredData.answerType === RADIO_BUTTON || filteredData.answerType === SCALE) {
        const obj = filteredData.answers.filter((data) => data.selected)[0];
        const nextQId = obj.nextQuestionId || getNextQuestionID(questionId);//questionId + 1;
        setQuestionId(nextQId);
        checkConditionalValues(nextQId);
        getNextOtherText(nextQId);

        const nextQuestioObj = questionList.filter((d) => d.questionId === nextQId)[0];
        if (nextQuestioObj?.answerGiven) {
          getPercentage('add', nextQuestioObj.questionId);
        }
      }
      else {
        let question = getNextQuestionID(questionId);//questionId + 1;
        setNextQuestionId(question);
        getNextOtherText(question);
        checkConditionalValues(question);
        setQuestionId(question);

        //  const index = questionList.findIndex((data) => data.questionId === question);
        // const conditionFunction = (item) => item.questionId === question;
        // let index = questionList.findIndex(conditionFunction);
        const nextQuestioObj = questionList.filter((d) => d.questionId === question)[0];
        
        if (nextQuestioObj?.answerGiven) {
          getPercentage('add', nextQuestioObj.questionId);
        }

        // if(questionList[index].answerGiven){
        //   getPercentage('add',question);
        // }
      }
    } else {
      let prevQues = nextQuestionIdList.pop();
      checkConditionalValues(prevQues);
      getNextOtherText(prevQues);
      setQuestionId(prevQues);

    }
    //setTextBox('');
    //Prev button disabled logic
    const prevQuestions = [...prevQuestionId];
    if (prevQuestions.indexOf(questionId) === -1)
      prevQuestions.push(questionId);
    setPrevQuestionId(prevQuestions);
  }
  // GET net question ID on click of next
  const getNextQuestionID = (questionId) => {
    const conditionFunction = (item) => item.questionId === questionId;
    const nextObject = questionList.findIndex(conditionFunction);
    const nextId = questionList[nextObject + 1]?.questionId || 0;
    return nextId;
  }
  const getNextOtherText = (value) => {
    const list = questionList.filter((item) => item.questionId === value) || [];
    const otherText = list[0]?.otherText;
    setTextBox(otherText);

  }
  //Reset the data when click on prev button
  const resetData = (id) => {
    const questions = questionList.map((data) => {
      if (data.questionId === id) {
        if (data.answerType === RADIO_BUTTON || data.answerType === SCALE) {
          data.answers = data.answers.map((obj) => {
            obj.selected = false;
            return obj;
          })
          if (data.answerType === SCALE) {
            setSliderValue(0);
          }
        }
        else if (data.answerType === CHECKBOX) {
          data.answers = data.answers.map((obj) => {
            obj.checked = false;
            return obj;
          })
          data.otherText = '';
        } else if (data.answerType === STAR) {
          data.answers = data.answers.map((obj) => {
            obj.rating = 0;
            return obj;
          })
          data.otherText = ''
        }
        else if (data.answerType === 'TextArea') {
          data.otherText = '';
        }
      }
      return data;
    });
    setQuestionList(questions);
  }

  //on click of prev questions
  const getPrevQuestion = () => {
    // resetData(questionId);
    setLastQuestion(false);
    const prevQuestions = [...prevQuestionId];
    const prevQues = prevQuestions.pop();
    const nextQuestionIdL = [...nextQuestionIdList];

    if (nextQuestionIdL.indexOf(questionId) === -1)
      nextQuestionIdL.push(questionId);

    setNextQuestionIdList(nextQuestionIdL);
    getNextOtherText(prevQues)
    setPrevQuestionId(prevQuestions);
    setValidation(false)
    setErrorMessage('')
    setQuestionId(prevQues);
  }
  const handleChangeCheckbox = (e, data) => {
    const questions = questionList.map((data) => {
      if (data.questionId === questionId) {

        data.answers = data.answers.map((obj) => {
          if (obj.answer === e.target.value) {
            obj.checked = e.target.checked;
          }
          return obj;
        })
        // FOr Not applicable cases
        const ifNAinAnswer = data.answers.filter((data)=>data.answer ==='Not Applicable') || [];
         const checkAllChecked = data.answers.filter((data)=>data.checked && data.answer !=='Not Applicable') || [];

         if(ifNAinAnswer.length>0){
        data.answers = data.answers.map((obj) => {
          if (obj.answer === e.target.value) {
            obj.checked = e.target.checked;
          }
          if(e.target.value==='Not Applicable' && obj.answer!=='Not Applicable' && e.target.checked){
            obj.isDisabled = true;
          }
          else  if(e.target.value==='Not Applicable' && obj.answer!=='Not Applicable' && !e.target.checked){
            obj.isDisabled = false;
          }
          else 
          if(e.target.value!=='Not Applicable' && obj.answer==='Not Applicable' && e.target.checked){
            obj.isDisabled = true;
          }
          else if(e.target.value!=='Not Applicable' && obj.answer==='Not Applicable' && !e.target.checked){
            if(checkAllChecked.length === 0)
            obj.isDisabled = false;
          }
          

          return obj;
        })
       }

        //below code for check answered for calculate percentage
        //if(!data.answerGiven){
        const answerGiven = data.answers.filter((data) => data.checked) || [];
        if (answerGiven.length > 0) {
          data.answerGiven = true;
          getPercentage('add', questionId);
        }
        else {
          data.answerGiven = false;
          getPercentage('minus', questionId);
        }
        // }
      }
      return data;
    });
    setNextQuestionIdList([]);
    setValidation(false);
    setQuestionList(questions);
  }

  const selectRadioOption = (e, data) => {
    setRadioValue(e.target.value);
    let questions = questionList.map((data) => {
      if (data.questionId === questionId) {
        data.answers = data.answers.map((obj) => {
          if (obj.answer === e.target.value) {
            obj.selected = true;
          }
          else {
            obj.selected = false;
          }
          return obj;
        })
        //if(!data.answerGiven){
        const answerGiven = data.answers.filter((obj) => obj.selected) || [];
        if (answerGiven.length > 0) {
          data.answerGiven = true;
          getPercentage('add', questionId);

        }
        else {
          data.answerGiven = false;
          // getPercentage('minus',questionId);

        }
        // }
      }
      return data;
    });

    if (questionId === 1 && e.target.value === 'No') {
      questions = questionList.map((data) => {
        if (data.questionId === 5) {
          data.answers = data.answers.map((obj) => {
            if (obj.answerId !== 23 && obj.answerId !== 22 && obj.answerId !== 25) {
              obj.isHide = true;
              obj.rating = null;
            }
            return obj;
          })
        }
        return data;
      })
    } else if (questionId === 1 && e.target.value === 'Yes') {
      questions = questionList.map((data) => {
        if (data.questionId === 5) {
          data.answers = data.answers.map((obj) => {
            if (obj.answerId !== 23 && obj.answerId !== 22 && obj.answerId !== 25) {
              obj.isHide = false;
            }
            return obj;
          })
        }
        return data;
      })
    }

    setQuestionList(questions);
    setValidation(false);
    setNextQuestionIdList([]);
    if (!data.nextQuestionId) {
      let nextQuestionId = getNextQuestionID(questionId);//questionId + 1;
      // setPrevQuestionId(questionId);
      setNextQuestionId(nextQuestionId);
    }
    else {
      //setPrevQuestionId(questionId);
      setNextQuestionId(data.nextQuestionId);
    }
    if (data.nextQuestionId === 0) {
      setLastQuestion(true)
    }
    else {
      setLastQuestion(false)
    }
    //setQuestionIds();
  }

  const updateTextValue = (e) => {
    const questions = questionList.map((data) => {
      if (data.questionId === questionId) {
        data.otherText = e.target.value;
      }
      if ((data.questionId === questionId) && (data.answerType == STAR)) {
        data.isSkipped = false
      }
      return data;
    });
    setValidation(false);
    setQuestionList(questions);
  }

  const calculateCommentLenth = () => {
    const length = textBox?.length
    if (isNaN(length)) {
      return 0
    } else {
      return length
    }
  }
 
  const callFunc = (e) => {
      const que = questionList.map((data) => {
      if (data.questionId === questionId) {
        //below code for check answered for calculate percentage
        const answerGiven = data.answers.filter((obj) => obj.rating > 0) || [];
        if (answerGiven.length == 0) {
          if (e.target.value?.trim()) {
            data.answerGiven = true;
            getPercentage('add', questionId);
          }
          else  {
            data.answerGiven = false;
            getPercentage('minus',questionId);
          }
       }
      }
      
      return data;
    });
   
    setQuestionList(que);
    setValidation(false);
    setTextBox(e.target.value); 
  }

  const handleChangeRatingValue = (e, newValue, answer, index) => {
    const questions = questionList.map((data) => {
      if (data.questionId === questionId) {
        data.answers = data.answers.map((ans) => {
          if (ans.answer === answer) {
            ans.rating = newValue;
            ans.dataId = 0;
          }
          return ans;
        })
        //data.answers[index].rating = e.target.value;
        //below code for check answered for calculate percentage
        if(!data.otherText) {
        const answerGiven = data.answers.filter((obj) => obj.rating > 0) || [];
        if (answerGiven.length > 0) {
          data.answerGiven = true;
          getPercentage('add', questionId);
        }
        else {
          data.answerGiven = false;
          getPercentage('minus',questionId);
        }
      }
       data.isSkipped = false
      }
      
      return data;
    });

    setQuestionList(questions);
    setValidation(false);
  }

  const getDynamicComponent = () => {
    if (questionId && questionList.length) {
      const filteredData = questionList.filter((data) => data.questionId === questionId)[0];

      if (filteredData.answerType === RADIO_BUTTON) {
        return (
          <>
            <Box className={classes.cardBody2}>
              <Box className={classes.statusContainer}>
                <Box className={classes.cardContainer}>
                  <Typography className={classes.question}>{filteredData.question}</Typography>
                </Box>
              </Box>
            </Box>
            {filteredData?.answers.map((data) => {
              return (<Box>
                <RadioGroup row aria-label="mortgages" className={classes.responses} name={filteredData.question} value={radioValue} onChange={(e) => selectRadioOption(e, data)}>
                  <FormControlLabel value={data.answer} checked={data.selected}
                    control={<Radio />} label={data.answer} />
                </RadioGroup>
              </Box>)
            })}
          </>
        )
      }
      else if (filteredData.answerType === CHECKBOX) {
        return (
          <Box>
            <Box className={classes.cardBody2}>
              <Box className={classes.statusContainer}>
                <Box className={classes.cardContainer}>
                  <Typography className={classes.question}>{filteredData.question}</Typography>
                </Box>
              </Box>
            </Box>
            <FormGroup row className={classes.responses}>
              {filteredData?.answers.map((data) => {
                return (<>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => handleChangeCheckbox(e, data)}
                        checked={data.checked}
                        value={data.answer}
                        disabled={data.isDisabled}
                        name={data.answer}
                        color="primary"
                        className={classes.inputStyle}
                      >
                      </Checkbox>
                    }
                    label={<Box className={classes.checkboxItem}>
                      {data.answer}  </Box>}
                  />
                  {data.answer === 'Other (please specify): ' && data.checked && <Box className={classes.textareaSection}>
                    <TextareaAutosize
                      style={{ height: '50px' }}
                      color="primary"
                      id={`outlined_impacted25099_${new Date().getMilliseconds}`} variant="outlined"
                      type="text"
                      name="comments"
                      maxLength="250"
                      value={textBox}
                      onChange={(e) => { setTextBox(e.target.value); setValidation(false) }}
                      onBlur={(e) => { updateTextValue(e) }}
                      placeholder='Please specify'
                      InputLabelProps={{
                        FormLabelClasses: {
                          asterisk: classes.labelAsterisk
                        }
                      }}
                      className={`${classes.textArea}`}
                    >
                    </TextareaAutosize>
                    <Typography className={classes.textAreaValidation}>{calculateCommentLenth(filteredData.otherText)}/250</Typography>
                  </Box>
                  }
                </>)
              })}
            </FormGroup>
          </Box>
        )
      }
      else if (filteredData.answerType === STAR) {
        return (
          <>
            <Box className={classes.cardBody2}>
              <Box className={classes.statusContainer}>
                <Box className={classes.cardContainer}>
                  <Typography className={classes.question}>{filteredData.question}</Typography>
                </Box>
              </Box>
            </Box>
            {filteredData?.answers.map((data) => {
              return (!data.isHide && <Box style={{ padding: '10px 40px' }} >
                <Grid item xs={12} sm={12} className={classes.starComponent}>
                  <Grid item xs={12} sm={9} md={9}>
                    <Typography className={classes.ratingLabel}>{data.answer}</Typography>
                  </Grid>
                  {data.answer !== 'Other (please specify): ' && <Grid item xs={12} sm={3} md={3}>
                    <Rating
                      key={data.answer}
                      value={data.rating}
                      name={data.answer}
                      defaultValue={rating}
                      onChange={(e, newValue) => handleChangeRatingValue(e, newValue, data.answer)}
                    />
                  </Grid>}
                </Grid>
              </Box>
              )
            })}
            {props.RequestType !== 'PaidOff' && <Box className={classes.textareaSection} style={{ margin: '-10px 25px' }}>
              <TextareaAutosize
                style={{ height: '50px' }}
                color="primary"
                id={`outlined_impacted25099_${new Date().getMilliseconds}`}
                variant="outlined"
                type="text"
                name="comments"
                maxLength="250"
                value={textBox}
                onChange={(e) => { callFunc(e) }}
                onBlur={(e) => { updateTextValue(e) }}
                placeholder='Please specify (Optional)'
                InputLabelProps={{
                  FormLabelClasses: {
                    asterisk: classes.labelAsterisk
                  }
                }}
                className={`${classes.textArea}`}
              >
              </TextareaAutosize>
              <Typography className={classes.textAreaValidation}>{calculateCommentLenth(filteredData.otherText)}/250</Typography>
            </Box>
            }
          </>)
      }
      else if (filteredData.answerType === SCALE) {
        return (
          <>
            <Box className={classes.cardBody2}>
              <Box className={classes.statusContainer}>
                <Box className={classes.cardContainer}>
                  <Typography className={classes.question}>{filteredData.question}</Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <RadioGroup row aria-label="mortgages" className={classes.slider} name="mortgages" value={sliderValue}
              >
                <Box>
                  {filteredData?.answers.map((data, index) => {
                    let classNa = `radioLabel${index + 1}`;
                    return (
                      <FormControlLabel value={data.answer}
                        label={
                          <Box className={`${classes.radioLabel} ${classes[classNa]} ${sliderValue == data.answer ? 'checked' : ''}`}>
                            <Typography className={classes.radioLabelText}>{index + 1}</Typography>
                          </Box>
                        }
                        control={<Radio className={classes.radioInput} checked={sliderValue === data.answer} onChange={(e) => handleScaleRadioChange(e, data)} />} />
                    )
                  })}
                </Box>
              </RadioGroup>
              <Box className={classes.sliderLabel}>
                <Typography className='text first'>Not Likely</Typography>
                <Typography className='text'>Very Likely</Typography>
              </Box>
            </Box>
          </>)
      }
      else if (filteredData.answerType === 'TextArea') {
        return (<><Box className={classes.cardBody2}>
          <Box className={classes.statusContainer}>
            <Box className={classes.cardContainer}>
              <Typography className={classes.question}>{filteredData.question}</Typography>
            </Box>
          </Box>
        </Box>
          <Box className={classes.textareaSection} style={{ margin: '0px 25px' }}>
            <TextareaAutosize
              style={{ height: '50px' }}
              color="primary"
              id={`outlined_impacted25099_${new Date().getMilliseconds}`}
              variant="outlined"
              type="text"
              name="comments"
              maxLength="250"
              value={textBox}
              onChange={(e) => { setTextBox(e.target.value); setValidation(false) }}
              onBlur={(e) => { updateTextValue(e) }}

              placeholder='Please specify'
              InputLabelProps={{
                FormLabelClasses: {
                  asterisk: classes.labelAsterisk
                }
              }}
              className={`${classes.textArea}`}
            >
            </TextareaAutosize>
            <Typography className={classes.textAreaValidation}>{calculateCommentLenth(filteredData.otherText)}/250</Typography>
          </Box>
        </>)
      }
    }
    return null;
  }

  const getNextButtonText = () => {
    let text = 'Next';
    if (questionId && questionList.length) {
      const filData = questionList.filter((data) => data.questionId === questionId)[0];
     
      if (filData.answerType === STAR && !filData.answerGiven && !textBox && props.RequestType !=='PaidOff') {
        text = 'Skip this question';
      } else if (filData.answerType === STAR && !filData.answerGiven && (props.RequestType ==='PaidOff')) {
        text = 'Skip this question';
      }
    }
    return text;
  }

  return (
    <Dialog PaperProps={{ classes: { root: classes.dialogStyle } }} open={popupOpen}>
      {!thankyouPopup && <Box className={classes.root}>
        <IconButton onClick={handleCancelIcon} className={classes.closeIco}>
          <CancelIcon />
        </IconButton>
        <Box className={classes.cardBody}>
          <Box className={classes.statusContainer}>
            {/* <Box className={classes.cardContainer}>
              <Typography className={classes.heading}>Awareness Questions.</Typography>
            </Box> */}
          </Box>
          <Box className={classes.progress}>
            <Typography className={classes.progressText}>{percentage}% Completed</Typography>
          </Box>
        </Box>
        <Box sx={{ width: '100%', padding: '0px 30px' }}>
          <LinearProgressWithLabel value={percentage}
            thickness={24}
            classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }}
          />
        </Box>
        {errorMessage !== '' ? (<Box px={3} pt={2}><Typography className={classes.errorMsg}>{errorMessage}</Typography></Box>) : null}

        <Box className={classes.maxHeight}>
          {getDynamicComponent(questionId)}
        </Box>

        {validErr ? (<Box px={3} pt={2}><Typography className={classes.errorMsg}>{valitationErrMsg}</Typography></Box>) : null}

        <Box className={classes.cardFooter}>
          <Box className={classes.footerItem}>
            <Button
              variant='outlined'
              className={`${classes.btn} ${(questionId === questionList[0]?.questionId) ? classes.disableBtn : ''}`}
              disabled={questionId === questionList[0]?.questionId}
              onClick={getPrevQuestion}
            > {'Prev'}</Button>
          </Box>

          <Box className={classes.footerItem}>
            {
              !loadingSubmit && <Button
                variant='contained'
                color='primary'
                className={classes.btn}
                onClick={getNextQuestion}
              > {(questionId === questionList[questionList.length - 1]?.questionId || isLastQuestion) ? 'FINISH' : getNextButtonText()}</Button>
            }
            {loadingSubmit && <Button disabled={true} type='submit' className={classes.subBtn} variant="contained" color='primary'>
              <CircularProgress color='primary' style={{ height: '24px', width: '24px' }} />
            </Button>}
          </Box>
        </Box>

      </Box>
      }
      {thankyouPopup && <Box className={classes.root}>
        <IconButton onClick={handleCancelThanksIcon} className={classes.closeIco}>
          <CancelIcon />
        </IconButton>
        <Box className={classes.cardBody2}>
          <Box className={classes.statusContainer}>
            <Box className={classes.cardContainerThnks}>
             {!fullScreen && <Typography><img src={tick_thanks} /></Typography>}
              <Typography className={classes.thankyouHeading}>THANK YOU!</Typography>
              <Typography className={classes.thankyouText}>Your input is vital in helping us enhance our services. We appreciate your time and feedback. Thank you for being a valued customer of BSI.</Typography>
              { displayRefinance &&
              <Box className={classes.innerContainer}>
                <p className={classes.paragraph}>If you are looking for a new loan/refinance call us</p>
                <a href={"tel:" + "8773359048"} style={{textDecoration: 'none'}}>
                <p className={classes.call}>
                  <img src={callIcon} className={'ico'} />
                  877-335-9048</p></a>
                <Box className={classes.Divider}>
                  <Box style={{ width: '20%' }}><Divider /></Box>
                  <Typography className={classes.DividerText}>OR</Typography>
                  <Box style={{ width: '20%' }}><Divider /></Box>
                </Box>

                {/* <p className={classes.paragraph}>Click here to</p> */}
                <Box><a href="https://bsifinancialrefinanceonline.mymortgage-online.com/" target="_blank" style={{ color: '#ffffff', textDecoration: 'none' }}><Button
                  variant='contained'
                  color='secondary'
                  className={classes.btn}
                  // onClick={props.handleSubmit}
                > Apply Online
                  <img src={applyOnline} style={{ marginLeft: '5px' }} />
                </Button></a></Box>
                <Typography className={classes.btnText}>
                  <InfoIcon style={{ fill: "#007FFF", height: '16px', width: '16px' }} />
                  This will redirect you to our application portal.</Typography>
              </Box> }


              <Button
                variant='contained'
                color='primary'
                className={classes.btn}
                onClick={handleCancelThanksIcon}
              > {'Completed'}</Button>

            </Box>
          </Box>
        </Box>
      </Box>}
    </Dialog >
  )
}
