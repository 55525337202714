import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => (
{
    table: {
        minWidth: 700
    },
    root: {
        '& .Mui-selected': {
          color: '#555D6C',
        },
        '& .MuiPaginationItem-root': {
          color: '#007FFF',
        },
      }
}));