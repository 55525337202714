import React, { useState } from 'react';
import { Box, Button, IconButton, InputAdornment, FormControl, CssBaseline, CircularProgress, Typography } from '@material-ui/core';
import { useStyles, CutomOutlinedInput, CustomInputLabel } from './styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { updatePassword } from '../../../actions';
import Cookies from 'js-cookie';
import { encrypt } from '../../../helpers/encrypt';
import { useHistory } from 'react-router-dom';
import { signOut, renderDefaultMsg, getAppSource, getUserType, isAdmin } from '../../../helpers';

export const ChangePassword = () => {

  const classes = useStyles();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [isProcessPassword, setIsProcessPassword] = useState(false)
  const [isOldPassword, setIsOldPassword] = useState(false)
  const [inputOldPassword, setInputOldPassword] = useState('')
  const [showOldPassword, setShowOldPassword] = useState(false)
  const [inputNewPassword, setInputNewPassword] = useState('')
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [inputConfirmPassword, setInputConfirmPassword] = useState('')
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [isNewPasswordBox, setIsNewPasswordBox] = useState(false)
  const [validNewPassword, setValidNewPassword] = useState({
    isLengthPass: false,
    isLowercasePass: false,
    isUppercasePass: false,
    isNumberPass: false,
    isSpecialCharacterPass: false
  })
  const [isConfirmPasswordBox, setIsConfirmPasswordBox] = useState(false)
  const [isPasswordMatched, setIsPasswordMatched] = useState(false)

  const handleChange = (prop) => (event) => {
    if (prop === 'inputOldPassword') {
      setInputOldPassword(event.target.value)
    } else if (prop === 'inputNewPassword') {
      let matchStatus = inputConfirmPassword === event.target.value ? true : false
      passwordFormate(event.target.value)
      setInputNewPassword(event.target.value)
      setIsPasswordMatched(matchStatus)
      if (inputConfirmPassword !== '') {
        setIsConfirmPasswordBox(!matchStatus)
      }
    } else if (prop === 'inputConfirmPassword') {
      let matchStatus = inputNewPassword === event.target.value ? true : false
      setInputConfirmPassword(event.target.value)
      setIsConfirmPasswordBox(!matchStatus)
      setIsPasswordMatched(matchStatus)
    }
  }

  const passwordFormate = (str) => {
    let isLowercasePass = false
    let isUppercasePass = false
    let isNumberPass = false
    let isSpecialCharacterPass = false
    let isLengthPass = false
    let strengthSizePass = 0

    if (/\d/.test(str)) {
      isNumberPass = true
      strengthSizePass++
    }

    if (/[A-Z]/.test(str)) {
      isUppercasePass = true
      strengthSizePass++
    }
    if (/[a-z]/.test(str)) {
      isLowercasePass = true
      strengthSizePass++
    }
    if (str.length >= 8 && str.length <= 20) {
      isLengthPass = true
      strengthSizePass++
    }

    if (/[~`@!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(str)) {
      isSpecialCharacterPass = true
      strengthSizePass++
    }

    let isNewPasswordBox = strengthSizePass === 5 ? false : true
    setValidNewPassword({
      isLengthPass,
      isLowercasePass,
      isUppercasePass,
      isNumberPass,
      isSpecialCharacterPass
    })
    setIsNewPasswordBox(isNewPasswordBox)
  }

  const validClass = (isValid) => {
    if (isValid) {
      return `${classes.validationText} ${classes.validationSuccess}`
    } else {
      return `${classes.validationText} ${classes.validationError}`
    }
  }

  const handleSubmit = async () => {
    if (inputOldPassword !== '' && isNewPasswordBox === false && isPasswordMatched) {
      const requestData = {
        "userName": "",
        "oldPassword": encrypt(inputOldPassword),
        "newPassword": encrypt(inputNewPassword),
        "privateLabelUrl": Cookies.get("privateLabelUrl"),
        "ipAddress": Cookies.get('publicIp'),
        "browser": Cookies.get("browser"),
        "appSource": getAppSource(),
        "userType": getUserType()
      }
      setIsProcessPassword(true)
      setErrorMessage('')
      setSuccessMessage('')
      const result = await updatePassword(requestData);
      if (result) {
        const { lstResponseStatus } = result
        if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
          signOut(() => history.push('/SignIn?password=changed'))
        } else {
          setIsProcessPassword(false)
          setSuccessMessage('')
          setErrorMessage(lstResponseStatus[0].statusDesc)
        }
      } else {
        setIsProcessPassword(false)
        setSuccessMessage('')
        setErrorMessage(renderDefaultMsg('400'))
      }
    }
  }

  const disabledBtn = inputOldPassword !== '' && isNewPasswordBox === false && isPasswordMatched
  return (
    <CssBaseline>
      <Box className={classes.formContainer}>
        <form noValidate autoComplete="off" >
          <Box className={classes.inputContainer}>
            <FormControl variant="outlined" >
              <CustomInputLabel className={classes.labelStyle} htmlFor="outlined-old-password">Old Password</CustomInputLabel>
              <CutomOutlinedInput
                id="outlined-old-password"
                type={showOldPassword ? 'text' : 'password'}
                value={inputOldPassword}
                onChange={handleChange('inputOldPassword')}
                labelWidth={200}
                inputProps={{
                  className: classes.inputStyle,
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowOldPassword(!showOldPassword)}
                      edge="end"
                      tabIndex="-1"
                    >
                      {showOldPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {isOldPassword ? (
              <Box border={1} mb={2} p={4} className={classes.validationBox}>
                <Box pb={2}>
                  <Typography className={classes.validationTitle}>Old Password Requirements</Typography>
                </Box>
                <Typography className={validClass(!isOldPassword)}>Old Password Required</Typography>
              </Box>) : null}
          </Box>

          {/* New Password */}
          <Box className={classes.inputContainer}>
            <FormControl variant="outlined" >
              <CustomInputLabel className={classes.labelStyle} htmlFor="outlined-new-password">New Password</CustomInputLabel>
              <CutomOutlinedInput
                id="outlined-new-password"
                type={showNewPassword ? 'text' : 'password'}
                value={inputNewPassword}
                onChange={handleChange('inputNewPassword')}
                labelWidth={200}
                inputProps={{
                  className: classes.inputStyle,
                  maxLength: 20
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      edge="end"
                      tabIndex="-1"
                    >
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {isNewPasswordBox ? (
              <Box border={1} mb={2} p={4} className={classes.validationBox}>
                <Box pb={2}>
                  <Typography className={classes.validationTitle}>Password Requirements</Typography>
                </Box>
                <Typography className={validClass(validNewPassword.isLengthPass)}>Password must be between 8 and 20 characters long</Typography>
                <Typography className={validClass(validNewPassword.isLowercasePass)}>Must contain atleast 1 lower case letter [a-z]</Typography>
                <Typography className={validClass(validNewPassword.isUppercasePass)}>Must also contain 1 upper case letter [A-Z]</Typography>
                <Typography className={validClass(validNewPassword.isNumberPass)}>Must also contain 1 digit [0-9]</Typography>
                <Typography className={validClass(validNewPassword.isSpecialCharacterPass)}>Must also contain 1 special character (#, %, $, !, &, @)</Typography>
              </Box>) : null}
          </Box>

          {/* Confirm Password */}
          <Box className={classes.inputContainer}>
            <FormControl variant="outlined" >
              <CustomInputLabel className={classes.labelStyle} htmlFor="outlined-new-password">Confirm Password</CustomInputLabel>
              <CutomOutlinedInput
                id="outlined-confirm-password"
                type={showConfirmPassword ? 'text' : 'password'}
                value={inputConfirmPassword}
                onChange={handleChange('inputConfirmPassword')}
                labelWidth={200}
                inputProps={{
                  className: classes.inputStyle,
                  maxLength: 20
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      edge="end"
                      tabIndex="-1"
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />

            </FormControl>
            {isConfirmPasswordBox ? (
              <Box border={1} mb={2} p={4} className={classes.validationBox}>
                <Box pb={2}>
                  <Typography className={classes.validationTitle}>Confirm Password Requirements</Typography>
                </Box>
                <Typography className={validClass(isPasswordMatched)}>Please confirm that the password in both fields match.</Typography>
              </Box>) : null}
          </Box>
          <Box textAlign="center" pt={3}>
            {successMessage ? (<Box><Typography className={classes.successMessage}>{successMessage}</Typography></Box>) : null}
            {errorMessage ? (<Box><Typography className={classes.errorMessage}>{errorMessage}</Typography></Box>) : null}
            {isProcessPassword ? (<Button className={classes.btn} disabled={true} variant="contained"><CircularProgress /></Button>) : (
              <Button
                onClick={() => handleSubmit()}
                className={classes.btn}
                variant="contained"
                color="primary"
                disabled={!disabledBtn}>
                SUBMIT</Button>)}
          </Box>
        </form>
      </Box>
    </CssBaseline>
  )
}
