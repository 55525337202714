import React from 'react';
import { IconButton } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CancelIcon from '@material-ui/icons/Cancel';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from './styles';
import { decrypt } from '../../helpers/encrypt';
import { maskAccount, maskAccountNo } from '../../helpers';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setPaymentOption } from '../../actions';


export const MultiLoanSuccessAlert = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { open, handleClose, data, selectedBank, paymentFrequncyType } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();


  const handleNavigation = () => {
    handleClose();
    dispatch(setPaymentOption('FAQ'));
    history.push('/PaymentOption')
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
      >
        <div className={classes.root}>
          <div className={classes.header}>
            <IconButton className={classes.iconStyle} onClick={handleClose}>
              <CancelIcon className={classes.iconStyle} />
            </IconButton>
          </div>
          {data.map((item) => {
            return (
              <div className={classes.container}>
                <h2 className={item.statusCode === '0' ? classes.statusSuccess : classes.statusFail}>{item.statusDesc}</h2>
                <div key={item.accountNumber} className={classes.displayFlex}>
                  {item.confirmationNumber && <h3 className={clsx(classes.text, classes.item)}>Request ID:<span className={classes.boldText}>#{decrypt(item.confirmationNumber)}</span></h3>}
                  {item.accountNumber && <h3 className={clsx(classes.text, classes.item)}>Loan Number: <span className={classes.boldText}>{maskAccount(decrypt(item.accountNumber))}</span></h3>}
                  {item.totalAmountDrafted && <h3 className={clsx(classes.text, classes.item)}>Total Payment: <span className={classes.boldText}>{item.draftAmount1}</span></h3>}
                  {item.draftDate1 && <h3 className={clsx(classes.text, classes.item)}>Payment date: <span className={classes.boldText}>{item.draftDate1}</span></h3>}
                  {selectedBank && selectedBank.nickName && selectedBank.bankAccountNumber && <h3 className={clsx(classes.text, classes.item)}>Bank Account: <span span className={classes.boldText}>{selectedBank.nickName} #{maskAccountNo(decrypt(selectedBank.bankAccountNumber))}</span></h3>}
                  {paymentFrequncyType && <h3 className={clsx(classes.text, classes.item)}>Payment Frequency: <span className={classes.boldText}>{paymentFrequncyType === 'M' ? "Monthly" : "Bi-Weekly"}</span></h3>}

                </div>
              </div>
            )
          })}
          <Button
            className={classes.btn}
            color='primary'
            variant='contained'
            onClick={handleNavigation}
          >OK</Button>
        </div>
      </Dialog>
    </div>
  );
}
