import React from 'react';
import { useStyles } from './styles';
import { Box, Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import { updateCommunicationConsent } from '../../actions';
import Cookies from 'js-cookie';
import { getUserType, getAppSource, isAdminCheck, renderDefaultMsg } from '../../helpers';

export const EnrollPaperLess = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { handleClose, accountNumber, isCommConcent, isCommConcentMobile, IsSmsConsent } = props;
  const [errorMsg, setErrorMsg] = React.useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMsg('');
    const data = {
      "accountNumber": accountNumber,
      "isLanveraEStatements": true,
      "isLanveraESmtNotification": isCommConcent,
      "isCellConsent": isCommConcentMobile,
      "isSmsConsent": IsSmsConsent,
      "isEmailConsent": isCommConcent,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    }

    const res = await updateCommunicationConsent(data);
    if (res) {
      handleClose(true)
    } else {
      setErrorMsg(renderDefaultMsg('400'));
    }
  }
 
  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={props.handleCancelIcon} open={props.open}>
     
      <Box className={classes.root}>
        <IconButton className={classes.closeIcon} onClick={() => handleClose()}>
          <CancelIcon />
        </IconButton>
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>Enrollment Confirmation</Typography>
        </Box>
        
        <Box className={classes.cardContainer}>
           {errorMsg.length !== 0 ? <p className={classes.errorMsg}>{errorMsg}</p> : ''}
          <Typography className={classes.paragraph}>I wish to enroll for paperless to access documents electronically.</Typography>
        </Box>

         <Box className={classes.singleFooterItem}>
            <Button
              variant='contained'
              color="primary"
              className={classes.btn}
              onClick={handleSubmit}
              disabled={isAdminCheck()}
            > CONFIRM</Button>
          </Box>
      </Box> 
    </Dialog>
    
  )
}
