import React, { Component } from 'react';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { Box, CircularProgress } from "@material-ui/core";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { numberWithCommas, maskLoan, maskAccountNo } from '../../helpers';
import { DeleteConfirmation } from '../ManageBankAccount/DeleteConfirmationOnAccount/DeleteConfirmation';
import { decrypt } from '../../helpers/encrypt';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
var businessMoment = require('moment-business-days');

const NO_RECORD = "No Record Found"

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#535D6D",
    color: "#ffffff",
    fontSize: "16px",
    fontWeight: "bold",
    letterSpacing: "0.36px",
    lineHeight: "19px",
    fontFamily: "Montserrat",
    borderRight: "1px solid #ffffff",
    verticalAlign: "top"
  },

  body: {
    fontSize: 16,
    color: "#202020",
    letterSpacing: "0.36px",
    lineHeight: "19px",
    fontFamily: "Montserrat"
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const nullCheck = (str) => {
  if (str) {
    return str
  } else {
    return '-'
  }
}
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  loaderContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabError: {
    fontSize: "16px",
    color: 'red',
    fontWeight: "bold",
    letterSpacing: "0.36px",
    lineHeight: "19px",
    fontFamily: "Montserrat",
    borderRight: "1px solid #ffffff",
    verticalAlign: "top",
    textAlign: 'center'
  },
  deleteIcon: {
    cursor: "pointer",
  },
  linkStyle2: {
    color: '#007FFF',
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  disableDeleteIcon: {
    cursor: "none",
    color: "lightgrey"
  },
  createdDate: {
    fontSize: "10px",
    color: '#4D4D4D',
    fontWeight: "400",
    paddingTop: 4
  },
  processing: {
    boxSizing: 'border-box',
    backgroundColor: '#C4DDF7',
    width: '384px',
    border: '2px solid #C4DDF7',
    borderRadius: '4px',
    color: '#007FFF',
    fontSize: 14,
    padding: 4,
    fontWeight: 600
  },
  upcoming: {
    boxSizing: 'border-box',
    backgroundColor: '#FBF0E1',
    width: '384px',
    border: '2px solid #FBF0E1',
    borderRadius: '4px',
    color: '#EA901E',
    fontSize: 14,
    padding: 4,
    fontWeight: 600
  },
  bankDetail: {
    paddingTop: 4
  }
});

const CustomTable = (props) => {

  const classes = useStyles();
  const { rows, dataError, loading,redirectToManageAutopay } = props;
  const [data,setData]= useState({});
  const [popup,showPopup] = useState(false);
  //Fetch multiloan details
  const [showMultiLoan,setShowMultiLoan] = useState(false)
  const getAccounts = async () => {
    const totalAccounts = await Cookies.get('totalAccounts');
    if (totalAccounts) {
      Number(totalAccounts) > 1 ? setShowMultiLoan(true) : setShowMultiLoan(false)
    }
  }
  useEffect(() => {
    getAccounts();
  }, []);

  if (loading) {
    return (
      <Box className={classes.loaderContainer}>
        <CircularProgress />
      </Box>
    )
  }


  const manageAutoPay = () => {
     redirectToManageAutopay(showMultiLoan);
  }

  const handleCancelIcon = () => {
    showPopup(false)
  }

  const handleSubmitClose = () => {
    showPopup(false)
  }

  const handleSubmit = () => {
    showPopup(false)
    props.deleteUpcomingSchedulePayment(data.draftDate,data.accountNumber);
  }

  const confirmDelete = (draftDate,accountNumber) => {
    setData({draftDate,accountNumber})
    showPopup(true);
   
  }

  const handleAutoay = (date) => {
    if (businessMoment(date, 'MM-DD-YYYY').businessDiff(businessMoment(moment(), 'MM-DD-YYYY'), true) >= 3) {
      return false;
    } else {
      return true;
    }
  }
  const getCondtionalValues = (row) => {
    let oneTimePayment = { show: false, disable: false,hideDelete:false };
    if (row.paymentType === "OneTime Payment") {
      if(moment(row.transactionDate).format('YYYY-MM-DD') > moment(props?.data?.estDateTime).format('YYYY-MM-DD')) {
        oneTimePayment.show = true;
      }
      else {
        oneTimePayment.show = true;
        oneTimePayment.hideDelete = true;
      }
    }
    else{
      oneTimePayment.disable =  handleAutoay(moment(nullCheck(row.transactionDate)).format("L"));
    }
    const draftDate = moment(nullCheck(row.transactionDate)).format("L");
    return (<StyledTableCell align="center">{!oneTimePayment.show ? (oneTimePayment.disable ? ( <Tooltip arrow title="You can not manage your payment, next scheduled payment is within 3 days.">
    <span style={{opacity:'0.5'}}>Manage Autopay</span>
  </Tooltip>):<a className={classes.linkStyle2} onClick={manageAutoPay} >Manage Autopay</a>) : <div className={classes.deleteIcon} >
  {!oneTimePayment.hideDelete && <DeleteIcon onClick={() => confirmDelete(draftDate,row.accountNumber)}/>}
    </div>}</StyledTableCell>)
  }
 
  const getPaymentStatus = (row) => {
    let oneTimePayment = { show: false, disable: false,hideDelete:false };
    if (row.paymentType === "OneTime Payment") {
      if(moment(row.transactionDate).format('YYYY-MM-DD') > moment(props?.data?.estDateTime).format('YYYY-MM-DD')) {
        oneTimePayment.show = true;
      }
      else {
        oneTimePayment.show = true;
        oneTimePayment.hideDelete = true;
      }
    }
    else{
      oneTimePayment.disable =  handleAutoay(moment(nullCheck(row.transactionDate)).format("L"));
    }

    if (!oneTimePayment.show && oneTimePayment.disable) {
      return true
    } else if (oneTimePayment.show && !oneTimePayment.hideDelete) {
      return true
    } else if (!oneTimePayment.show && !oneTimePayment.disable) {
      return true
    } else {
      return false
    }
  }


  const paymentBreakUp = (row) => { 

    const totalAmount = row?.paymentAmount ? `${'$' + row?.paymentAmount} (Payment Amount)` : '';
    const principalAmt = row?.additionalPrincipal ? `${'$' + row?.additionalPrincipal} (Additional Principal)` : '';
    const escrowAmt = row?.additionalEscrow ? `${'$' + row?.additionalEscrow} (Additional Escrow)` : '';
    const proFess = row.processingFee ? `${'$' + row?.processingFee} (Processing Fees)}` : '';

    return (<StyledTableCell align="center" style={{'cursor' : 'pointer'}}><Tooltip arrow title={ <>
      {totalAmount} {principalAmt !== '' && <br></br>}
      {principalAmt} {escrowAmt !== '' && <br></br>}
      {escrowAmt} {proFess !== '' && <br></br>}
      {proFess} 
    </> }><span>${numberWithCommas(parseFloat(row.totalAmount).toFixed(2))}</span></Tooltip></StyledTableCell>)
  }
 
  return (
    <>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Loan Number</StyledTableCell>
            <StyledTableCell align="center">Bank Details</StyledTableCell>
            <StyledTableCell align="center">Payment Type</StyledTableCell>
            <StyledTableCell align="center">Draft Date</StyledTableCell>
            <StyledTableCell align="center">Payment Amount</StyledTableCell>
            <StyledTableCell align="center">Payment Status</StyledTableCell>
            <StyledTableCell align="center">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          {rows && rows.length > 0 ? (rows.map((row, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell align="center">{decrypt(row.accountNumber)}</StyledTableCell>
              <StyledTableCell align="left">{row.bankAccountNumber ? maskAccountNo(decrypt(row.bankAccountNumber)) : '-'} {(row.bankAccountNumber && row.bankName) && <div className={classes.bankDetail}>{decrypt(row.bankName)}</div>}</StyledTableCell>
              <StyledTableCell align="center">{nullCheck(row.paymentType)} <div className={classes.createdDate}>{'Created on: ' + moment(nullCheck(row.createdDate)).format("L")}</div></StyledTableCell>
             
              <StyledTableCell align="center">{moment(nullCheck(row.transactionDate)).format("L")}</StyledTableCell>
              {paymentBreakUp(row)}
              <StyledTableCell align="center">{!getPaymentStatus(row) ? <span className={classes.processing}>Processing</span> : <span className={classes.upcoming}>Upcoming</span>}</StyledTableCell>
              {getCondtionalValues(row)}

            </StyledTableRow>
          ))) :
            (<StyledTableRow><StyledTableCell colSpan={9} align="center">{NO_RECORD}</StyledTableCell></StyledTableRow>)}

          {dataError !== '' && <StyledTableRow><StyledTableCell colSpan={9} align="center">{dataError}</StyledTableCell></StyledTableRow>}

        </TableBody>
      </Table>
    </TableContainer>
    { popup &&
        <DeleteConfirmation
         open={popup}
         popupText={{statusTitle:'Deletion Request',statusBody:'Are you sure you really want to delete this upcoming one-time payment? '}}
         handleCancelIcon={handleCancelIcon}
         handleSubmitClose={handleSubmitClose}
         handleSubmit={handleSubmit}
       />
      }
      
    </>
  );
}

export default class UpcomingPaymentTrns extends Component {
  render() {
    return (<CustomTable {...this.props}></CustomTable>)
  }
}