import React, { useEffect, useState } from 'react';
import { Box, Grid, CircularProgress } from "@material-ui/core";
import { DateRangecustom } from './Daterange/daterange';
import Button from '@material-ui/core/Button';
import { useStyles } from './styles';
import Cookies from 'js-cookie';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { scrollTop, renderDefaultMsg, getAppSource, getUserType } from '../../../../helpers';
import { Typography } from '@material-ui/core';
import { getGetMaintenanceMessage, UpdateMaintenanceMessage } from './../../../../actions';
import moment from 'moment';

export const Adminmaintainence = (props) => {

  const classes = useStyles();
  const [dataLoader, setDataLoader] = useState(false);
  const [isProcessPassword, setIsProcessPassword] = useState(false)
  const [values, setValues] = React.useState({
    id: '',
    active: false,
    msg: '',
    sDate: "",
    endD: "",
    startTime: null,
    endTime: null,
    estDateTime: ''
  });

  const [open, setOpen] = React.useState(true);
  const toggle = () => setOpen(!open);
  const [errorMessage, setErrorMessage] = useState('')
  const [message, setmessage] = useState('')
  const [timeIntervals, setTimeIntervals] = useState(['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00'])

  const handletickChange = (event) => {
    setValues({ ...values, active: event.target.checked })
  };

  const getMaintenanceMessagedata = async (e) => {
    setmessage('')
    setDataLoader(true)
    setErrorMessage("")
    const response = await getGetMaintenanceMessage();
    if (response) {
      const { lstResponseStatus, messageToDisplay, isActive, id, endDate, startDate, startTime, endTime, estDateTime } = response
      if (lstResponseStatus[0].statusCode == 0) {
        setValues({
          sDate: startDate,
          endD: endDate,
          msg: messageToDisplay,
          active: isActive,
          id: id,
          startTime: !startTime ? '00:00' : startTime,
          endTime: !endTime ? '23:00' : endTime,
          estDateTime: estDateTime
        })
      } else {
        setErrorMessage(lstResponseStatus[0].statusDesc)
      }
    } else {
      setErrorMessage(renderDefaultMsg('400'))
    }
    setDataLoader(false)
  }

  const endStartDateCheck = () =>  {
    var startDate = new Date(moment(values.sDate).format('YYYY-MM-DD'));
    var endDate   = new Date( moment(values.endD).format('YYYY-MM-DD'));
      return endDate.getTime() >= startDate.getTime();
  } 

  const endStartTimeCheck = () =>  {
    var startDate = new Date(moment(values.sDate).format('YYYY-MM-DD'));
    var endDate   = new Date( moment(values.endD).format('YYYY-MM-DD'));
    if ((endDate.getTime() == startDate.getTime()) && (values.endTime <= values.startTime)) {
      return false
    } else if (!values.startTime || !values.endTime) {
      return false
    } else {
      return true
    }
  } 

  const UpdateMaintenanceMessagedata = async (e) => {
    setmessage('')
    setErrorMessage('')
   
    if (endStartDateCheck()) {
        if (endStartTimeCheck()) {
        setIsProcessPassword(true)
        const dataa = {
          "userName": "",
          "privateLabelUrl": Cookies.get("privateLabelUrl"),
          "ipAddress": Cookies.get('publicIp'),
          "browser": Cookies.get("browser"),
          "appSource": getAppSource(),
          "userType": getUserType(),
          "accountNumber": "",
          "isActive": values.active,
          "startDate": values.sDate,
          "endDate": values.endD,
          "messageToDisplay": values.msg,
          "id": values.id,   
          "startTime": values.startTime,
          "endTime": values.endTime
        }
      const response = await UpdateMaintenanceMessage(dataa);

      if (response) {
        setIsProcessPassword(false)
        const { lstResponseStatus } = response
        if (lstResponseStatus[0].statusCode === "0") {
          setmessage("Maintainence Message created/updated successfully.")
        }
        else {
          setErrorMessage(lstResponseStatus[0].statusDesc)
        }
      } else {
        setErrorMessage(renderDefaultMsg('400'))
      }
     } else {
      setErrorMessage("Start time should be greater than end time.")
     }
    } else {
      setErrorMessage("Start date should be greater or equal to end date.")
    }
  }

  useEffect(() => {
    getMaintenanceMessagedata()
  }, []);

  const handleinput = (prop) => (event) => {
    setmessage('')
    setValues({ ...values, [prop]: event.target.value });
    return;
  };

  const setDate = (startDate, endDate) => {
    setValues({ ...values, sDate: startDate, endD: endDate })
  }

  const setTime = (sTime, eTime) => {
    setValues({ ...values, startTime: sTime, endTime: eTime })
  }

  if (dataLoader) {
    return (
      <Box className={classes.loaderRoot}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box className={classes.bgg}>
      <Box className={classes.fullpart}>
        <Box className={classes.root}>
          <Typography className={classes.title}>Maintainence</Typography>
          <div className={classes.firstarea}>
            <Box className={classes.mainContainer}>
              <Box my={1} mx={2} className={classes.formContainer} style={{ paddingBottom: '8px' }}>
                <Typography className={classes.note}>Please note: All dates and times are in Eastern Standard Time (EST). Ensure that you select the start and end date and time according to EST.</Typography>
                <Grid container>
                  <Grid item xs={12} md={8} style={{ padding: '0px' }}>
                    <div className={classes.ineer}>
                      <DateRangecustom end={values.endD} start={values.sDate} dateUpdate={setDate} timeUpdate={setTime} startTime={values.startTime} endTime={values.endTime} timeIntervals={timeIntervals} estDateTime={values.estDateTime}/>
                    </div>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12} md={7} style={{ marginTop: '20px' }}>
                    <div className={classes.midtxtarea}>
                      <TextField
                        aria-label="minimum height"
                        rows={5}
                        variant="outlined"
                        label="Maintainence Message"
                        style={{
                          width: '100%', color: 'rgba(0,0,0,0.74)', fontFamily: 'Montserrat',
                          fontSize: '16px',
                          letterSpacing: '0.5px',
                          lineHeight: '20px',
                        }}

                        multiline
                        inputProps={{
                          className: classes.text_part,
                        }}
                        value={values.msg}
                        onChange={handleinput('msg')}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={classes.buttoncheckarea}>
                      <div className={classes.buttoncheck_1}>
                        <div className={classes.buttoncheckarea}>
                          <div className={classes.insider_1}>
                            <Typography className={classes.text_part} style={{ paddingTop: '9px' }}>Active</Typography>
                          </div>
                          <div className={classes.insider_2}>
                            <Checkbox
                              checked={values.active}
                              size="small"
                              onChange={handletickChange}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </div>
                        </div>
                      </div>

                    </div>
                  </Grid>
                </Grid>
                <Grid container>
                  {errorMessage && <Grid item xs={12}>
                    <Typography style={{ color: 'red' }}>{errorMessage}</Typography>
                  </Grid>}
                  {message && <Grid item xs={12}>
                    <Typography style={{ color: 'green' }}>{message}</Typography>
                  </Grid>}
                </Grid>
                <Grid container>
                  <Grid item xs={12} style={{ paddingTop: '20px' }}>
                    <Box textAlign="center" style={{ marginBottom: '25px', maxWidth: '58%' }}>
                      {isProcessPassword ? (<Button className={classes.btn} disabled={true} variant="contained"><CircularProgress /></Button>) : (
                        <Button
                          onClick={UpdateMaintenanceMessagedata}
                          className={classes.btn}
                          variant="contained"
                          color="primary"
                        >
                          Update</Button>)}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </div>
        </Box>
      </Box>
    </Box>
  )
}
