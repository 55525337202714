import React, {useState} from 'react';
import { useStyles } from './styles';
import { Box, Button, Grid, FormControlLabel } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InfoIcon from '@material-ui/icons/Info';
import { numberWithCommas } from '../../helpers';

import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { maskLoan, getUserType, getAppSource } from '../../helpers'
import { insertActivityLog } from '../../actions'
import moment from 'moment';

export const MaturityExtensionAlert = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const [value, setValue] = useState('');
  const [skipDisable,setSkipDisable]=useState(false);
  const [open,setOpen]=useState(props?.promiseToPayAlertStatus);

  useEffect(()=>{
     //check maturity extension date
     
     const then = moment(); //.subtract(1, 'days');
    if(moment(props?.maturityExtensionsInfo?.maturityDate).isBefore(then)){
      // if(moment('05/10/2023').isBefore(then)){
           setSkipDisable(true);
     }


  },[props.maturityExtensionsInfo])

  const handleSkip = () => {
    setOpen(false);
   // Cookies.set("showPromisePopup", false);
    Cookies.set('isMaturityExtenstionOpened',false)
    Cookies.set('maturityExtensionMenu',true);
    sendActivityData('Skip');
  }
 

  const sendActivityData = async (btn) => {
    const remark = `Loan Maturiy notice - User clicked on ${btn} button with selection ${value==='Y'?'Request Payoff':'Extend Loan Terms'}`;
    const data = {
      "accountNumber": props.accountNumber,
      "activityCode": "UAL192",
      "activityStatus": "Success",
      "action": "GetAccountInfo",
      "controller": "Dashboard",
      "apiName": "GetAccountInfo",
      "activityRemark":  remark,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertActivityLog(data);
  }

  const routeToMaturityExt = () => {
    sendActivityData('Continue');
    Cookies.set('isMaturityExtenstionOpened',false)

    if(value==='Y'){
      setOpen(false);
    //  Cookies.set("showPromisePopup", false)
      Cookies.set('previousPage','maturity');
      history.push('/PayoffRequest')
    }
    else if(value==='N'){
      setOpen(false);
     // Cookies.set("showPromisePopup", false)
      history.push('/MaturityExtension')
    }
  }
  
  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }}  open={open}>
      <Box className={classes.root}>
         <Box className={classes.cardTitleBody}>
           <p className={classes.statusTitle}>Important Notice Regarding Maturity of Your Loan</p>
         </Box> 
        <Box className={classes.cardBody}>
          <Box className={classes.statusContainer}>
            {!skipDisable && <Box className={classes.cardContent1}>
                <InfoIcon className={classes.infoIcon} /> 
                <p className={classes.paragraph}>Your mortgage loan is approaching towards maturity. </p>
            </Box>}
            {skipDisable && <Box className={classes.cardContent1}>
                <InfoIcon className={classes.infoIcon} style={{ color: "#E02020"}} /> 
                <p className={classes.paragraph}>Your mortgage loan has passed the maturity date. </p>
            </Box>}
            <p style={{textAlign: "center", paddingTop: "15px"}}> <span className={classes.statusSubTitle}>Maturity Date: </span><span className={`${classes.date} ${skipDisable?'red':''}`}>{props?.maturityExtensionsInfo?.maturityDate}</span> </p>
            {props.principalLoan > 0 && <p style={{textAlign: "center", paddingTop: "5px"}}> <span className={classes.statusSubTitle}>Outstanding Principal Balance: </span> <span className={classes.date}>{`$ ${numberWithCommas(parseFloat(props.principalLoan).toFixed(2))}`}</span> <span className={classes.hours}>(Updated 24 hours ago)</span> </p>}
          </Box>

          <Box>
            <Box className={classes.radioBtn}>
              <RadioGroup row aria-label="digital_comm" name="digital_comm" value={value}
              onChange={(e)=>setValue(e.target.value)}
              >
                <FormControlLabel value="Y"
                  control={<Radio />} label="Request Payoff" checked={value === 'Y' ? true : false} classes={{ label:
                    classes.labelText
                  }} />
                <FormControlLabel value="N" checked={value === 'N' ? true : false}
                  control={<Radio />} label="Extend Loan Terms" classes={{ label:
                    classes.labelText
                  }}/>
              </RadioGroup>
            </Box>
            <Box className={classes.cardContent2}>
                <InfoIcon className={classes.cardIcon} /> 
                <p className={classes.info}> Be aware that if your loan is not paid off prior to maturity it will be subject to any late fees, penalties and/or additional charges in accordance with the Note and any correlating addendums, appendices, and riders.</p>
            </Box>
          </Box>

          <Box className={classes.buttonContainer}>
             <Grid container>
                <Grid item md={12} xs={12} lg={12}>
                    <Grid container style={{ marginTop: '12px',justifyContent:'center' }}>
                    {!skipDisable && <Grid item xs={6} md={6}>
                       <Button
                          className={classes.skipBtn}
                          variant="outlined"
                          onClick={() => handleSkip()} 
                          >SKIP</Button>
                        </Grid>}
                        <Grid item xs={6} md={6}>
                        <Button type='submit' disabled={!value} className={classes.continueBtn} size="medium" onClick={() => routeToMaturityExt()} variant="contained" color="primary">
                          CONTINUE
                        </Button>
                      </Grid>
                    </Grid>
               </Grid>
             </Grid>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}
