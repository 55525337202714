import React, { useEffect, useState, useRef } from 'react';
import { useStyles } from './styles';
import { Box, Button, CircularProgress, TextField } from '@material-ui/core';
import {
  CircularProgressbar
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Cookies from 'js-cookie';
import { encrypt, decrypt } from '../../helpers/encrypt';
import { getPublicIp, maskMobile, getAdminTheme, getUserType, getAppSource } from '../../helpers';
import { validateOTP, resendCode } from '../../actions';


export const VerifyCode = (props) => {
  const classes = useStyles();
  const [verificationCode, setVerificationCode] = useState('');
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('');
  const { email, mobileNumber, userName, firstName } = props.data;

  const handleChange = (event) => {
    setVerificationCode(event.target.value);
  };

  const [leftTime, setLeftTime] = useState(0);

  const increment = useRef(null);

  useEffect(() => {
    handleTimer()
  }, []);

  const handleTimer = () => {
    let timer = 600
    increment.current = setInterval(() => {
      setProgress((prevProgress) => (prevProgress === 0 ? 100 : prevProgress - 5 / 30));
      if (timer === 0) {
        setLeftTime("00:00")
        clearInterval(increment.current)
      } else {
        setLeftTime(timer--)
      }
    }, 1000);
    return () => clearInterval(increment.current);
  }

  const handleReset = () => {
    clearInterval(increment.current);
  }

  const handleSubmit = async (e) => {

    e.preventDefault();
    setErrorMsg("");
    setLoading(true);
    const data = {
      "userName": userName,
      "otp": encrypt(verificationCode),
      "mediumType": "SMS",
      "mobileNumber": mobileNumber,
      "email": "",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": await getPublicIp(),
      "browser": Cookies.get("browser"),
      "otpType": "RecoverPassword",
      "appSource": getAppSource(),
      "userType": getUserType(),
    }
    const res = await validateOTP(data);

    if (res) {
      if (res.lstResponseStatus[0].statusCode === '1001') {
        setLoading(false);
        setVerificationCode('');
        setErrorMsg(res.lstResponseStatus[0].statusDesc);
      } else {
        setLoading(false);
        setVerificationCode('');
        setErrorMsg("");
        props.handleStep(res.verificationDetail.userName);
      }
    } else {
      setErrorMsg("Invalid verification code.");
      setLoading(false);
      setVerificationCode('');
    }
  }

  const handleResendCode = async () => {
    setVerificationCode('');
    handleReset();
    setProgress(0);

    setLeftTime(0);
    handleTimer();

    setLoading(true);
    setErrorMsg("");
    const data = {
      "userName": userName,
      "mediumType": "SMS",
      "mobileNumber": mobileNumber,
      "email": "",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": await getPublicIp(),
      "browser": Cookies.get("browser"),
      "otpType": "RecoverPassword",
      "appSource": getAppSource(),
      "userType": getUserType(),
      "loanNo": "",
    }
    const res = await resendCode(data);
    if (res) {
      setLoading(false);
      setProgress(0)
    } else {
      setErrorMsg("Something went wrong");
      setLoading(false);
    }
  }


  const CircularProgressWithLabel = (props) => {
    const showTimer = (rt) => {
      if (rt === 'Completed') {
        return rt
      }
      rt = parseInt(rt)
      let minutes = Math.floor(rt / 60);
      let seconds = rt % 60

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      return `0${minutes}:${seconds}`;
    }

    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress size="8rem" variant="static" {...props} style={{ color: '#7EB1CC' }} />
        <Box
          className={classes.timerBox}
        >
          <p
            className={classes.timerText}>
            {showTimer(props.remaingTime)}
          </p>
          <p className={`${classes.timerText} ${classes.timerSmallText}`}>mins</p>
        </Box>
      </Box>
    );
  }

  const disableBtn = verificationCode.length !== 0 || loading;


  return (
    <Box container="true" maxWidth='xl' className={classes.root}>
      <Box className={classes.wrapper}>
        <p className={classes.title}>Reset Password</p>
        <p className={classes.errorMsg}>{errorMsg.length !== 0 ? errorMsg : ''}</p>
        <form autoComplete='off' noValidate onSubmit={handleSubmit}>
          <Box className={classes.inputContainer}>
            <TextField
              color='primary'
              fullWidth
              error={false}
              id="outlined-error"
              label="Verification Code"
              variant="outlined"
              onChange={handleChange}
              value={verificationCode}
              InputProps={{
                className: classes.inputStyle,
              }}
            />
          </Box>
          <p className={classes.hintText}>A Verification Code has been sent to the number {maskMobile(decrypt(mobileNumber))}. Please enter the Verification Code here to confirm your phone number.</p>
          <p className={classes.hintText}><button type='button' onClick={handleResendCode} className={classes.resendBtnText}>Resend Code</button></p>
          <p className={classes.expireText}>Code will be valid for 10 minutes </p>
          <Box className={classes.timerContainer}>
            <p className={classes.timeText}>Time remaining:</p>
            {/* <CircularProgressbar style={{ fontSize: '10px', color: 'red' }} className={classes.circle} value={(10 - progress) * 10} text={`${10 - progress} min`} /> */}
            <CircularProgressWithLabel remaingTime={leftTime} value={progress} />
          </Box>
          {/* <Box className={classes.btnContainer}>
            {loading ?
              <Button disabled={true} type='submit' className={classes.sendBtn} size="large"variant="contained" color="primary"><CircularProgress color='primary' size='30px' /></Button>
              :
              <Button disabled={(!disableBtn || (leftTime === '00:00'))} type='submit' size="large"className={classes.sendBtn} variant="contained" color="primary">Submit</Button>
            }
            </Box> */}
          <Box >
            
            {loading ?
              <Button disabled={true} type='submit' className={classes.submitBtn} size="large" variant="contained" fullWidth color="primary"><CircularProgress color='primary' size='30px' /></Button>
              :
              <Button disabled={(!disableBtn || (leftTime === '00:00'))} type='submit' size="large" className={classes.submitBtn} variant="contained" fullWidth color="primary">Next</Button>
            }

              <Button onClick={() => props?.backToRestPassword()} fullWidth className={classes.sendBtn} variant='outlined' color="primary" >Back</Button>
          </Box>
         
        </form>
      </Box>
    </Box >
  )
}
