import React from 'react';
import { useStyles, StyledTableCell, StyledTableRow } from './styles';
import { Box, Button, Grid, Tooltip, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { decrypt } from '../../../helpers/encrypt';
import { maskAccountNo } from '../../../helpers';

export const UpdateBankAccRequestPopup = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
   
 const renderTableBody = () => {
  return props?.data?.map((row, index) => {
    return (
      <StyledTableRow  key={`${index}`}>
        <StyledTableCell align="center">{decrypt(row.accountNumber)}</StyledTableCell>
        <StyledTableCell align="center">{decrypt(row.confirmationNumber)}</StyledTableCell>
      </StyledTableRow>)
  })
 }

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }}  open={props?.status}>
     
      <Box className={classes.root}>
          <IconButton className={classes.closeIcon} onClick={props?.handleSubmit}>
            <CancelIcon />
          </IconButton>
        
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>Bank Account Update Request</Typography>
        </Box>
        <Box className={classes.cardBody2}>
          <Box className={classes.statusContainer}>
           { props?.data?.length == 1 ?
           <>
            <p className={classes.requestId}> Bank account update request (Request ID #: { decrypt(props?.data[0]?.confirmationNumber)}) received. </p>
            <p className={classes.paragraph}> We have received your request to update your existing autopay with the revised bank account details. </p> 
            </> :
            
              <p className={classes.requestId}> We have received your request to update your existing autopay with the revised bank account details for below mentioned loan account(s):
              </p>
            }
          </Box>
          { props?.data?.length > 1  &&
            <TableContainer>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Loan Account</StyledTableCell>
                    <StyledTableCell align="center">Request ID</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderTableBody()}
                </TableBody>
              </Table>
            </TableContainer> }
        </Box>

        <Box className={classes.cardFooter}>
          <Box className={classes.footerItem}>
            <Button
               variant='contained'
               color='primary'
               onClick={props?.handleSubmit}
              className={classes.btn}
            > OK</Button>
          </Box>
        </Box>
      
      </Box> 
    </Dialog>

  )
}
