import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useStyles, StyledTableCell, StyledTableRow } from './styles';
import {
  TextField, Box, Button, FormControlLabel,
  Checkbox, Table, TableBody, TableContainer, TableFooter,
  TablePagination, TableRow, Paper, TableHead, CircularProgress
} from '@material-ui/core';
import { encrypt } from './../../../../helpers/encrypt';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { getActivitylog } from '../../../../actions';
import moment from 'moment';
import Cookies from 'js-cookie';
import { getAppSource, getUserType } from '../../../../helpers';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { INFO_ADMIN_RECORD } from '../../../../helpers/constants';


const SimpleTooltip = withStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#202020',
    fontFamily: "Montserrat",
    boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
    padding: "1em",
    fontSize: 16,
    maxWidth: 400,
  },
}))(Tooltip);

export const Adminuseractivitylog = (props) => {

  const classes = useStyles();
  const [loanNumber, setLoanNumber] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [activityLogs, setActivityLogs] = useState([]);
  const [loader, setLoader] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract(5, 'days'));
  const [endDate, setEndDate] = useState(moment());
  const [statusDesc, setStatusDesc] = useState('');

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
  };

  const handleLoanNumberChange = (e) => {
    setLoanNumber(e.target.value);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(0)
    getActivitylogData()
  }

  const getActivitylogData = async () => {


    let loanNumberCheck = ''

    if (props.loanNumber) {
      loanNumberCheck = encrypt(props.loanNumber)
    } else if (loanNumber.length) {
      loanNumberCheck = encrypt(loanNumber)
    }

    if (loanNumberCheck === '') {
      return false
    }

    setLoader(true);
    setActivityLogs([]);
    setTotalRecords(0)
    setStatusDesc('')

    const data = {
      "userName": '',
      "accountNumber": loanNumberCheck,
      "pageIndex": page,
      "pageSize": rowsPerPage,
      "IsIncludeAll": selectAll,
      "fromDate": moment(startDate).format('MM-DD-YYYY'),
      "toDate": moment(endDate).format('MM-DD-YYYY'),
      "isForAdmin": false,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
    }

    let res = await getActivitylog(data);
    if (res && res.lstResponseStatus[0].statusCode === '0') {
      setActivityLogs(res.lstActivityLog);
      setTotalRecords(res.totalCount)
    } else if (res && res.lstResponseStatus[0].statusCode !== '0') {
      setStatusDesc(res.lstResponseStatus[0].statusDesc)
    }
    setLoader(false);
  };

  useEffect(() => {
    getActivitylogData()
  }, [page, rowsPerPage, props.loanNumber])

  return (
    <Box className={classes.root}>
      <Box className={classes.main}>
        <h1 className={classes.title}>Activity Log</h1>
        <form className={classes.formStyle} onSubmit={handleSearch}>
          <Box className={classes.searchBox}>
            <Box className={classes.inputBox}>
              <TextField
                disabled={props.loanNumber ? true : false}
                id='loan-number'
                required
                variant='outlined'
                label="Enter Loan Number"
                size="small"
                value={props.loanNumber ? props.loanNumber : loanNumber}
                onChange={handleLoanNumberChange}
                inputProps={{
                  className: classes.inputStyle,
                }}
              />
            </Box>
            <Box className={classes.searchByBox}>
              <Box className={classes.inputBox}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    id="date-picker-From"
                    label="From"
                    size="small"
                    value={startDate}
                    minDate={moment(new Date()).subtract(30, 'days').format('L')}
                    onChange={handleStartDate}
                    autoOk
                    inputProps={{
                      className: classes.inputStyle,
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>

              <Box className={classes.inputBox}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    id="date-picker-to"
                    label="To"
                    size="small"
                    minDate={moment(new Date()).subtract(30, 'days').format('L')}
                    value={endDate}
                    onChange={handleEndDate}
                    autoOk
                    inputProps={{
                      className: classes.inputStyle,
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Box>
            <Box className={classes.inputBox}>
              <FormControlLabel
                classes={{ label: classes.includeAllLabel }}
                control={
                  <Checkbox
                    checked={selectAll}
                    onChange={handleSelectAll}
                    color="primary"
                  />
                }
                label="Include All"
                labelPlacement="start"
              />
              <SimpleTooltip placement='top-start' arrow title={INFO_ADMIN_RECORD}>
                <InfoIcon style={{ fontSize: '25px', top: '9px', position: 'relative', left: '10px', cursor: 'pointer' }} />
              </SimpleTooltip>
            </Box>
            <Box className={classes.searchBtnBox}>
              <Button
                className={classes.searchBtn}
                type='submit'
                color='primary' variant='contained'
                disabled={props.loanNumber ? false : loanNumber.length === 0 ? true : false}
              >
                Search
              </Button>
            </Box>
          </Box>
        </form>
        <Box className={classes.tableBox}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Activity Date</StyledTableCell>
                  <StyledTableCell align="center">IP Address</StyledTableCell>
                  <StyledTableCell align="center">Browser</StyledTableCell>
                  <StyledTableCell align="center">Page Name</StyledTableCell>
                  <StyledTableCell align="center">Activity Description</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loader && activityLogs.length ?
                  activityLogs.map(row => {
                    return (
                      <StyledTableRow key={row.id}>
                        <StyledTableCell align="center" >{moment(row.activityDate).format('MMM Do YY, h:mm:ss a')} </StyledTableCell>
                        <StyledTableCell align="center" >{row.ipAddress} </StyledTableCell>
                        <StyledTableCell align="center" >{row.browser} </StyledTableCell>
                        <StyledTableCell align="center" >{row.apiName} </StyledTableCell>
                        <StyledTableCell align="left">{row.activityRemark} </StyledTableCell>
                      </StyledTableRow>
                    )
                  })
                  :
                  <StyledTableRow>
                    <StyledTableCell colSpan={5}>
                      <Box className={classes.loaderBox}>
                        {loader ? <CircularProgress /> : (statusDesc) ? <p className={classes.noDataFoundText}>{statusDesc}</p> : <p className={classes.noDataFoundText}>No Data Found</p>}
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    count={totalRecords}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  )
}