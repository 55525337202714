import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField, OutlinedInput, InputLabel } from '@material-ui/core';
export const useStyles = makeStyles((theme) => (
  {
    formContainer: {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
    inputContainer: {
      marginBottom: '3.9em',
    },
    inputStyle: {
      color: "#535D6D",
      fontFamily: "Montserrat",
      fontSize: 16,
      fontWeight: "600",
      width: "390px",
      [theme.breakpoints.down('sm')]: {
        width: "auto",
      },
    },

    labelStyle: {
      color: "#535D6D",
      fontFamily: "Montserrat",
      fontSize: 16,
      fontWeight: "500",
      letterSpacing: "0.36px",
      lineHeight: "19px"
    },

    validationBox: {
      boxSizing: "border-box",
      border: "1px solid #A0A0A0",
      borderRadius: "12px",
      backgroundColor: "#FFFFFF",
      boxShadow: "0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.2)",
    },

    validationTitle: {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: "600",
      letterSpacing: "0.53px",
      lineHeight: "18px",
    },
    validationText: {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontStyle: "italic",
      letterSpacing: "0.53px",
      lineHeight: "24px",
    },
    validationError: {
      color: "#E30909"
    },
    validationSuccess: {
      color: "#00CB82"
    },
    btn: {
      width: "200px",
      height: '40px',
      fontSize: "16px",
      fontFamily: "Montserrat",
      fontWeight: "600",
      letterSpacing: "0.53px",
      lineHeight: "19px",
      textAlign: "center",
    },

    errorMessage: {
      color: 'red',
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontWeight: 600,
      letterSpacing: '0.36px',
      lineHeight: '19px',
      maxWidth: '80%',
      margin: '0 auto 20px auto'
    },

    succesMessage: {
      color: 'green',
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontWeight: 600,
      letterSpacing: '0.36px',
      lineHeight: '19px',
      maxWidth: '80%',
      margin: '0 auto 20px auto'
    },

    assistiveText: {
      color: 'rgba(0,0,0,0.6)',
      fontFamily: 'Montserrat',
      fontSize: '12px',
      letterSpacing: '0.4px',
      lineHeight: '16px',
      margin: '2px 0 0 10px'
    }
  }
));

export const CutomTextField = withStyles((theme) => ({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
}))(TextField);

export const CutomOutlinedInput = withStyles((theme) => ({
  root: {
    "&$focused $notchedOutline": {
      border: `2px solid ${theme.palette.primary.main}`,
    }
  },
  focused: {},
  notchedOutline: {}
}))(OutlinedInput);

export const CustomInputLabel = withStyles((theme) => ({
  root: {
    "&$focused": {
      color: theme.palette.primary.main,
    }
  },
  focused: {},
}))(InputLabel);

