import React from 'react';
import Slider from "react-slick";
import { TileCard } from "../TileCard";
import { Box, Typography } from '@material-ui/core';
import { getHelpTileContent, getPrivatelabel } from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { useStyles } from './styles';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getPointPrivateLabel } from '../../helpers';
import './styles.css';

export function SlickSlider(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const helpContent = useSelector(state => state.helpLandingReducer);
  const routeChange = (htmlContent, pageName, subHeader) => {
    if (subHeader !== null && subHeader !== '') {
      if (subHeader === '/ManageThirdPartyRequest') {
        props.token === 'publicToken' ? history.push(`/ThirdPartyRequest`) : history.push(`${subHeader}`);
        return;
      }
      history.push(`${subHeader}`);
    } else {
      dispatch(getHelpTileContent(htmlContent));
      history.push(`/HelpDescription/${pageName}`);
    }
  }

  let listOfHelpArticle = ''
  if (helpContent && helpContent.lstPageContentDetails) {
    if (helpContent.lstPageContentDetails != null) {
      listOfHelpArticle = helpContent.lstPageContentDetails.map((item, index) => {
        return (
          <TileCard onClick={() => routeChange(item.contentValue, item.contentKey, item.contentSubHeader)}
            imgUrl={item.contentHeader}
            tileCon={item.contentKey} key={`${index}_${item.contentHeader}`} />
        )
      })
    }
  }

  const SlickNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className={classes.nextBtn} onClick={onClick} >
        <NavigateNextIcon className={classes.nextArrow} />
      </div>
    );
  }

  const SlickPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className={classes.prevBtn} onClick={onClick}>
        <NavigateBeforeIcon className={classes.prevArrow} />
      </div>
    );
  }

  var settings = {
    centerMode: true,
    infinite: true,
    centerPadding: "120px",
    slidesToShow: getPointPrivateLabel() ? 3 : 4,
    speed: 500,
    dots: false,
    arrow: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          centerPadding: "40px",
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 767,
        settings: {
          centerPadding: "30px",
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: "20px",
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    nextArrow: <SlickNextArrow />,
    prevArrow: <SlickPrevArrow />
  };

  return (
    <Box className={`${classes.rootSlider} ${'acc1'}`}>
      <Typography className={classes.mainHeading} variant="h4" component="h4" >
        More Help Topics
        </Typography>
      <Slider {...settings} className={classes.slickContainer} >
        {listOfHelpArticle}
      </Slider>
    </Box>
  );
}