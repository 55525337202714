import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const useStyles = makeStyles((theme) => ({
  margin: {    
    boxSizing: "border-box",
    height: "56px",
    width: "100%",
    border: "1px solid #C0C0C0",
    borderRadius: "4px",
  },
  stateContainer: {
    '& .MuiFormLabel-root.MuiInputLabel-outlined': {
      color: '#535D6D',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '500',
      letterSpacing: '0.36px',
      lineHeight: '19px',
      background: '#fff',
      paddingRight: '5px',
      '& .Mui-disabled':{
        opacity:'0.5'
      }
    },
    '& .MuiFormLabel-root.MuiInputLabel-outlined.Mui-focused': {
      color: theme.palette.primary.main,
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      color: '#535D6D',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      letterSpacing: '0.36px',
      lineHeight: '19px',    
    },
    '& .Mui-disabled':{
      opacity:'0.5'
    }
  },
  formControl: {
    minWidth: 150,
  },
}));

export default function CustomSelectState(props) {
  const classes = useStyles();
  
  const handleChange = (event) => {
    props.selectCallback({ ddName: props.dropDownName, value: event.target.value })    
  };
  return (
    <div className={classes.stateContainer}>
      <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
        <InputLabel id="borrower-type-select-outlined" className={props.disabled?'Mui-disabled':''}>Select State</InputLabel>
        <Select
          labelId="borrower-state-outlined-label"
          id="borrower-state-select-outlined"
          color="primary"
          label="Select State"
          variant="outlined"
          className={classes.inputStyle}
          value={props.selectedValue}
          disabled={props.disabled}
          onChange={handleChange}
        >
          {props.optionsList ? props.optionsList.map((item, index) => {
            return (
              <MenuItem value={item.optionValue} key={(index - 1) + item.optionValue}>{item.optionText}</MenuItem>
            )
          }) : null}
        </Select>
      </FormControl>
    </div>

  );
}