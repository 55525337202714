import React, { useRef, useEffect } from 'react';
import YouTube from 'react-youtube';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const YouTubePlayer = ({ id,videoId, active, onVideoStart }) => {
 
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'));
  const playerRef = useRef(null);
  // Function to stop the video
  const stopVideo = () => {
    if (playerRef.current) {
      playerRef.current.pauseVideo();
    }
  };
  useEffect(() => {
    const videoIds = videoId+'_'+id;
    if (active) {
      onVideoStart(videoIds);
    } else {
      stopVideo();
    }
  }, [active]);

const videoPlayed=(videoId)=>{
    const videoIds = videoId+'_'+id;
    onVideoStart(videoIds);
}

  return (
    <div>
      <YouTube
        videoId={videoId}
        opts={{
            height:365,
            width: mediaQuery ? 320 : 420,
          playerVars: {
            autoplay: active ? 1 : 0,
          },
        }}
        onPlay={()=>videoPlayed(videoId)}
        onReady={(event) => {
          playerRef.current = event.target;
        }}
      />
    </div>
  );
};

export default YouTubePlayer;