import React, { useEffect, useState } from 'react';
import {
    Box, CssBaseline, CircularProgress, Grid, Typography, Button,
    FormControlLabel, FormGroup, Checkbox, Tooltip
  } from '@material-ui/core';
import { useStyles, useStylesBootstrap } from './styles';
import {  renderDefaultMsg, capitalizeFirstLetter, scrollTop, isAdmin, reviewingRequest } from '../../helpers';
import CustomBreadcrum from '../Breadcrum';
import { useSelector } from 'react-redux';
import { getEscrowInsuranceData, getEscrowDocuments, uploadEscrowDocuments, deleteEscrowDocuments, downloadEscrowDocument, resetEscrowDocument, submitEscrowDocuments } from '../../actions';
import InfoIcon from '@material-ui/icons/Info';
import bag from '../../assets/bag.png';
import reset from '../../assets/reset.png';
import mortgage from '../../assets/mortgage.png';
import insurance from '../../assets/insurance.png';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { SwitchButton } from './SwitchButton'
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DeleteAlert } from './DeleteAlert';
import { SubmitAlert } from './SubmitAlert';
import { FileSizeAlert } from './FileSizeAlert';
import { FileExtensionAlert } from './FileExtensionAlert';
import { SpecialCharAlert } from './SpecialCharAlert';
import { CitLengthAlert } from './CitLengthAlert';
import { UploadFileLengthValidationAlert } from './UploadFileLengthValidationAlert';
import axios from "axios";
import fileDownload from 'js-file-download';
import Cookies from 'js-cookie';
import { CIT_MESSAGE_LIMIT, ESCROW, ADD_ESCROW_TEXT1, ADD_ESCROW_TEXT2 , REMOVE_ESCROW_TEXT1, REMOVE_ESCROW_TEXT2, I_AGREE, FILE_SIZE_LIMII, FILE_LENGTH, AMOUNT_DIRECTLY_PAID } from '../../helpers/constants';
import { withStyles } from '@material-ui/core/styles';

const LightToolTip = withStyles((theme) => ({
  arrow: {
    color: '#235A86',
    // backgroundColor: '#ffffff',
  },
  tooltip: {
    backgroundColor: '#235A86',
    color: '#fffff',
    fontFamily: "Montserrat",
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    padding: "16px",
    fontSize: 14,
    maxWidth: 350,
    fontWeight: 500,
    borderRadius: 8,
    // border: "1px solid #4D4D4D"
  },
}))(Tooltip);

function BlueOnWhiteTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} placement="top" />;
}

export const EscrowManage = (props) => {

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const escrowStatus = Cookies.get('escrow');
  const mytheme = useSelector(state => state.theme);
  const escrowData = useSelector(state => state?.escrowReducer);
  const { primaryColorCode, secondaryColorCode } = mytheme;

  const [checkboxState, setCheckboxState] = useState(false);
  const [escrowApiError, setEscrowApiError] = useState('');
  const [escrowLists, setEscrowLists] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [loading,  setLoading] = useState(false);
  const [lineType, setLineType] = useState('');
  const [fileName, setfileName] = useState('');
  const [docName, setDocName] = useState('');
  const [lineTypeIndex, setLineTypeIndex] = useState(0);
  const [documentIndex, setDocumentIndex] = useState(0);
  const [openToolTip, setOpenToolTip] = useState(false);
  const [deleteStatus, setDeleteStatus] =  useState(false);
  const [replaceStatus, setReplaceStatus] = useState(false);
  const [documentId, setDocumentId] = useState(0);
  const [documentCode, setDocumentCode] = useState(0);
  const [openSubmitPopup, setSubmitPopupStatus] = useState(false);
  const [loader,  setLoader] = useState(true);
  const [validations, setValidations] = useState([]);
  const [alertStatus, setAlertStatus] = useState(false);
  const [resetStatus , setResetBtnStatus] =  useState(false);
  const [btnStatus, setBtnStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [concatList, setConcatLists] = useState([]);
  const [submitBtnStatus, setSubmitBtnStatus] = useState(false);
  const [allowedExtensionsStatus, setAllowedExtensionsStatus] = useState(false);
  const [file, setFilesName] = useState('');
  const [fileLengthStatus, setFileLengthStatus] = useState(false);
  const [showMessageStatus, setShowMessageStatus] = useState(false);
  const [submitMessages, setSubmitMessages] = useState([])
  const [specialChar, setFileContainSpecialChar] = useState(false);
  const [citLengthStatus, setCitLengthStatus] = useState(false);
  const [lineTypeStatus, setLineTypeStatus] = useState('tax')
  const [insuranceLineTypeCitLength, setInsuranceLineTypeCitLength] = useState(0);
  const [taxLineTypeCitLength, setTaxLineTypeCitLength] = useState(0);
  const [citEscrowStatus, setCitEscrowStatus] = useState('add');
  const [citLists, setCombinedLists] = useState([]);
  const [formSubmit, setFormSubmit] = useState(false);

  const handleClose = () => setOpen(false);
  const handleExtensionAlertClose = () => setAllowedExtensionsStatus(false)
  const handleFileLengthAlertClose = () => setFileLengthStatus(false)
 
  useEffect(() => {
    if (escrowStatus != 'true') {
      history.push({pathname: '/Dashboard'});
    }
  }, [])

  const handleSubmitClick = async () => {

    setFormSubmit(true)
    const filterEscrowedItems = escrowLists.filter((keys) => ((keys.show == true) && (keys.showEscrow == false)))
    const filterNonEscrowedItems = escrowLists.filter((keys) => keys.escrowStatus == "Escrowed"
     && keys.escrow == false)
   
    var concatArray = [];
    let combinedLists = concatArray.concat(filterEscrowedItems, filterNonEscrowedItems)
   
    var escrowData = [];
    combinedLists.map(item => {
        escrowData.push({linetype: item.escrowLineType, escrowLineType: item.lineType, 
          documentCount: item.docs.filter(i => i.fileName != null).length,
          currentEscrowStatus :  item.escrowStatus == "Escrowed"
          ? 1 : 0, requestedEscrowStatus:  item.escrowStatus == "Escrowed"
          && item.escrow == false ? 0 : 1 })
        
    })
  
    const response = await submitEscrowDocuments(props.accountNumber, escrowData)
    if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode === '0') {  
      setSubmitPopupStatus(true);
      setShowMessageStatus(false)
      setFormSubmit(false)
      // if (response?.responseData?.responseData != null && !response?.responseData?.responseData.isSuccess) {
      //     setShowMessageStatus(true)
      //     setSubmitMessages(response?.responseData?.responseData.lineSubmitResponses)
      // }

    } else if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode !== '0') {
      setFormSubmit(false)
      setEscrowApiError(response?.responseData?.lstResponseStatus[0]?.statusDesc)
      scrollTop()
    } else {
      setFormSubmit(false)
      setEscrowApiError(renderDefaultMsg('400'));
      scrollTop()
    }
  } 

  const filterDocIds = (item) => {
    if (item.docs.length > 0) {
     const ids = item.docs.map(({ documentId }) => documentId)
      return ids
    }
  }

  const handleDeleteDoc = (lineType, fileName, docName, lineTypeIndex, documentIndex, id) => {
    setLineTypeIndex(lineTypeIndex)
    setDocumentIndex(documentIndex)
    setLineType(lineType)
    setfileName(fileName)
    setFilesName(fileName)
    setDocName(docName)
    setOpen(true)
    setDocumentId(id)
  };

  const checkboxChangeHandler = (event) => {
    setCheckboxState(event.target.checked)
    handleValidations()
  }

  const handleValidations = () => {
    const filterEscrowItems = escrowLists.filter((keys) => ((keys.show == true) && (keys.showEscrow == false)))
   
    var requiredDocs = []
    if (filterEscrowItems.length > 0) {
      filterEscrowItems.map((keys) => { if (keys?.docs.length > 0) {
        keys?.docs.map(i => {
          if (i?.isRequired) {
            requiredDocs.push(i)
          }
        })
     }})
    }
    setValidations(requiredDocs)
    getValidate()
  }

  const getValidate = () => {

    const filterEscrowedItems = escrowLists.filter((keys) => ((keys.show == true) && (keys.showEscrow == false)))
    
    const filterNonEscrowedItems = escrowLists.filter((keys) => keys.escrowStatus == "Escrowed"
     && keys.escrow == false)
     var arr= [];
     let concatItems = arr.concat(filterEscrowedItems, filterNonEscrowedItems)
     setConcatLists(concatItems)
    var requiredDocuments = []
    filterEscrowedItems.map((keys) => { if (keys?.docs.length > 0) {
        keys?.docs.map(i => {
            requiredDocuments.push(i)
        })
    }})
    if (requiredDocuments.length  > 0) {
      var validate =  requiredDocuments.every(item => item.fileName == null) 
      if (filterNonEscrowedItems.length  == 0 && validate) {
         setResetBtnStatus(false)
      } else if (filterNonEscrowedItems.length  != 0) {
        setResetBtnStatus(false)
      }  else if (filterNonEscrowedItems.length  == 0 && !validate) {
        setResetBtnStatus(false)
      }
    }
    if (requiredDocuments.length  > 0) {
      const arr = requiredDocuments.filter(item => item.isRequired == true)
      const validate =  arr.every(item => item.fileName != null) 
      setSubmitBtnStatus(!validate)
    }
    if (filterNonEscrowedItems.length  > 0) {
      setBtnStatus(false)
    }
    if (requiredDocuments.length  == 0) { 
      setSubmitBtnStatus(false)
    }
  }

  const handleCitLengthAlertClose = () => {
    setCitLengthStatus(false)
  }

  const handleTooltipOpen = () => {
    setOpenToolTip(true);
  }

  const handleDelete = () => {
    setOpen(false);
    deleteDocuments()
  }

  const handleSubmit = () => {
    history.push({pathname: '/EscrowInfo'});
  }

  const handleSubmitClose = () => {
    history.push({pathname: '/EscrowInfo'});
  }

  const handleAlertClose = () => {
   setAlertStatus(false)
  }
  
  const handleSpecialCharAlertClose = () => {
    setFileContainSpecialChar(false)
  }

  const deleteDocuments = async () => {
    setLoading(true)
    const response = await deleteEscrowDocuments(props.accountNumber, documentId)
    if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode === '0') {  
      setLoading(false)
      let newObj = [...escrowLists]  
      newObj[lineTypeIndex]['docs'][documentIndex]['fileName'] = null
      newObj[lineTypeIndex]['docs'][documentIndex]['uploadStatus'] = false
      newObj[lineTypeIndex]['docs'][documentIndex]['deleteStatus'] = true
      setEscrowLists(newObj)
      handleValidations()
    }  else if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode !== "0") {
      setLoading(false)
      setErrorMsg(response.lstResponseStatus[0].statusDesc)
      scrollTop()
    } else {
      setLoading(false)
      setEscrowApiError(renderDefaultMsg('400'));
      scrollTop()
   }
  } 

  const downloadDocuments = async (lineType, fileName, docName, documentId) => {
    const response = await downloadEscrowDocument(props.accountNumber, documentId)
    if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode === '0') {  
      axios.get(response?.responseData?.responseData, {
        responseType: "blob"
      })
      .then(res => {
        fileDownload(res.data, docName)
      })
    }  else if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode !== "0") {
      setLoading(false)
      setErrorMsg(response.lstResponseStatus[0].statusDesc)
      scrollTop()
    } else {
      setLoading(false)
      setEscrowApiError(renderDefaultMsg('400'));
      scrollTop()
   }
  } 

  const resetDocuments = async () => {
    const response = await resetEscrowDocument(props.accountNumber)
    if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode === '0') {  
      window.location.reload()
    }  else if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode !== "0") {
      setLoading(false)
      window.location.reload()
      setErrorMsg(response.lstResponseStatus[0].statusDesc)
      scrollTop()
    } else {
      setLoading(false)
      setEscrowApiError(renderDefaultMsg('400'));
      scrollTop()
   }
  } 

  const cancelDocuments = async () => {
    const response = await resetEscrowDocument(props.accountNumber)
    if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode === '0') {  
      history.push({pathname: '/EscrowInfo'})
    } else {
      history.push({pathname: '/EscrowInfo'})
    }
  } 

  const getInsuranceData = async (props, escrowdoc) => {
    
    const response = await dispatch(getEscrowInsuranceData(props.accountNumber))
    if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode === '0') { 
      setLoader(false)
      setEscrowApiError('')
        var arr = [];
        if ( response?.responseData?.responseData?.propertyTaxes != null) {
          arr = response?.responseData?.responseData?.propertyTaxes;
        }
      
        var lists = [];
        if (response?.responseData?.responseData?.homeOwnersInsurances != null) {
          lists = arr.concat(response?.responseData?.responseData?.homeOwnersInsurances)
        } else {
          lists = arr;
        }
        const data = response?.responseData?.responseData?.mortgageInsurance;
       
        lists.map((item) =>  {item.escrow = item.escrowStatus == 'Escrowed' ? true : false,
        item.showDocument = false, item.docs = filterDocs(escrowdoc, item), item.show = false,
      item.escrowed = item.escrowStatus == 'Escrowed' ? true : false, item.showEscrow =  item.escrowStatus == 'Escrowed' ? true : false, item.checkEscrow = item.escrowStatus == 'Escrowed' ? true : false })
       if (data != null) {
          lists.push({
              "payeeName": data.payeeName,
              "policyTaxId": data.policyTaxId,
              "principalBalanceAmount": data.principalBalanceAmount,
              "taxIdType": data.taxIdType,
              "miMonthlyPremiumAmount": data.miMonthlyPremiumAmount,
              "originalAppraisedAmount": data.originalAppraisedAmount,
              "originalLoanAmount": data.originalLoanAmount,
              "lineType": 'MORTGAGE',
              "escrowLineType" :  'MORTGAGE INSURANCE',
              "escrowStatus" : 'Escrowed',
              "escrow": true,
              "showDocument": false,
              "docs": []
            })
        }
       setEscrowLists(lists)

    } else if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode !== '0') {
      setConcatLists([])
      setSubmitBtnStatus(false)
      setResetBtnStatus(false)
      setBtnStatus(false)
      setLoader(false)
      setValidations([])
      setAlertStatus(false)
      setOpen(false)
      setEscrowApiError(response?.responseData?.lstResponseStatus[0]?.statusDesc)
      scrollTop()
    } else {
      setLoader(false)
      setEscrowApiError(renderDefaultMsg('400'));
      scrollTop()
    }
  }
  
  const filterDocs = (escrowdoc, key) => {
      if (escrowdoc.length > 0) {
        let filterLineType =  escrowdoc.filter((keys) => (keys.lineType.toLowerCase() == key.escrowLineType.toLowerCase()))
        return filterLineType
      }
  }

  const getDocumentsList = async () => {
    const response = await getEscrowDocuments(props.accountNumber)
    if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode === '0') { 
      setLoader(true)
      const docs = response?.responseData?.responseData;
       setDocuments(response?.responseData?.responseData)
       if(escrowData != null && props.accountNumber) {
        setTimeout(() => {
            getEscrowData(docs)
        }, 1000)
      } else if (props.accountNumber) { 
          setTimeout(() => {
              getInsuranceData(props, response.responseData?.responseData)
          }, 1000)
      }
    } else if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode !== "0") {
      setLoading(false)
      setErrorMsg(response.lstResponseStatus[0].statusDesc)
      scrollTop()
    } else {
      setLoading(false)
      setEscrowApiError(renderDefaultMsg('400'));
      scrollTop()
   }
  }
 
  useEffect(() => {
    if(props.accountNumber) {
        getDocumentsList() 
    } 
   },[props.accountNumber])
 
   const getEscrowData = (docs) => {
     
   setLoader(false)
   setConcatLists([])
   setSubmitBtnStatus(false)
   setResetBtnStatus(false)
   setBtnStatus(false)
   setValidations([])
   setAlertStatus(false)
   setOpen(false)
    var arr = [];
    if (escrowData?.propertyTaxes != null) {
      arr = escrowData?.propertyTaxes 
    }
    var lists = [];
    if (escrowData?.homeOwnersInsurances != null) {
      lists = arr.concat(escrowData?.homeOwnersInsurances)
    } else {
      lists = arr;
    }
    
    const data = escrowData?.mortgageInsurance;
    lists.map((item) =>  {item.escrow = item.escrowStatus == 'Escrowed' ? true : false,
    item.showDocument = false, item.docs = filterDocs(docs, item), item.show = false,
    item.escrowed = item.escrowStatus == 'Escrowed' ? true : false , item.showEscrow =  item.escrowStatus == 'Escrowed' ? true : false , item.checkEscrow = item.escrowStatus == 'Escrowed' ? true : false })
    if (data != null) {
      lists.push({
          "payeeName": data.payeeName,
          "policyTaxId": data.policyTaxId,
          "principalBalanceAmount": data.principalBalanceAmount,
          "taxIdType": data.taxIdType,
          "miMonthlyPremiumAmount": data.miMonthlyPremiumAmount,
          "originalAppraisedAmount": data.originalAppraisedAmount,
          "originalLoanAmount": data.originalLoanAmount,
          "lineType": 'MORTGAGE',
          "escrowLineType" :  'MORTGAGE INSURANCE',
          "escrowStatus" : 'Escrowed',
          "escrow": true,
          "showDocument": false,
          "docs": []
        })
    }

     setEscrowLists(lists)
   }

  const handleChange = (item,index, key) => {

    const status = citLists.findIndex(item => ((item.escrowLineType == key.escrowLineType) && 
    (item.lineType == key.lineType)))
   
    var taxLineTypes = '';
    var insuranceLineTypes = '';
    if (key.lineType == 'TAX') {
     taxLineTypes = key.escrowLineType + "/" + (key.escrowStatus == 'Escrowed' ? 'Add' : 'Remove') + "/Docs " + 0
    } else {
       insuranceLineTypes = key.escrowLineType + "/" + (key.escrowStatus == 'Escrowed' ? 'Add' : 'Remove') + "/Docs " + 0
    }

    if (((taxLineTypeCitLength + taxLineTypes.length + 2) > CIT_MESSAGE_LIMIT) && key.lineType == 'TAX' && status == -1) {
      setCitEscrowStatus(key.escrowStatus == 'Escrowed' ? 'remove' : 'add')
      setCitLengthStatus(true)
      setLineTypeStatus('tax')
    } else if (((insuranceLineTypeCitLength + insuranceLineTypes.length + 2) > CIT_MESSAGE_LIMIT) && key.lineType == 'INSURANCE' && status == -1) { 
      setCitEscrowStatus(key.escrowStatus == 'Escrowed' ? 'remove' : 'add')
      setCitLengthStatus(true)
      setLineTypeStatus('insurance')
    } else {
      let newObj = [...escrowLists]  
      newObj[index]['escrow'] = item.fieldValue
      newObj[index]['show'] = !key.show
      newObj[index]['escrowed'] = item.escrow
      setEscrowLists(newObj)
      setLineTypeIndex(index)

      validateCitLength()
      if (checkboxState) {
        handleValidations()
      }
    }
  }
 
  const validateCitLength = () => {
 
    const filterEscrowedItems = escrowLists.filter((keys) => ((keys.show == true) && (keys.showEscrow == false)))
    const filterNonEscrowedItems = escrowLists.filter((keys) => keys.escrowStatus == "Escrowed"
     && keys.escrow == false)
   
    var concatArray = [];
    let combinedLists = concatArray.concat(filterEscrowedItems, filterNonEscrowedItems)
    setCombinedLists(combinedLists)

    const lineTypeTax =  combinedLists.filter((keys) => keys.lineType == "TAX")
    const lineTypeInsurance =  combinedLists.filter((keys) => keys.lineType == "INSURANCE")
   
    var citTaxMessage = '';
    var citInsuranceMessage = '';

    if (lineTypeTax.length > 0) {
      for(var i = 0; i < lineTypeTax.length; i++) {
        citTaxMessage += lineTypeTax[i].escrowLineType + "/" + ((lineTypeTax[i].show == true && lineTypeTax[i].showEscrow == false) ? 'Add' : 'Remove') + "/Docs " + lineTypeTax[i].docs.length + ", "
      }
      setTaxLineTypeCitLength(citTaxMessage.length - 2)
    } else {
      setTaxLineTypeCitLength(0)
    }

    if (lineTypeInsurance.length > 0) {
      for(var i = 0; i < lineTypeInsurance.length; i++) {
        citInsuranceMessage += lineTypeInsurance[i].escrowLineType + "/" + ((lineTypeInsurance[i].show == true && lineTypeInsurance[i].showEscrow == false) ? 'Add' : 'Remove') + "/Docs " + lineTypeInsurance[i].docs.length + ", "
      }
      setInsuranceLineTypeCitLength(citInsuranceMessage.length - 2)
    } else {
      setInsuranceLineTypeCitLength(0)
    }
   
  }

  const handleFile = (event, item, key) => {
    var allowedExtensions = /(\.jpeg|\.png|\.jpg|\.pdf|\.doc|\.docx)$/i;
    var specialCharacters =  /[\/:*?“<>|]/;
  
    if (event.target.files[0].size > FILE_SIZE_LIMII) {
      setFilesName(event.target.files[0].name)
      setAlertStatus(true)
    } else if (!allowedExtensions.exec(event.target.value)) { 
      setFilesName(event.target.files[0].name)
      setAllowedExtensionsStatus(true)
    } else if (event.target.files[0].name.length > FILE_LENGTH) {
      setFilesName(event.target.files[0].name)
      setFileLengthStatus(true)
    }  else if (specialCharacters.test(event.target.files[0].name)) {
      setFilesName(event.target.files[0].name)
      setFileContainSpecialChar(true) 
    } else {
      onFileUpload(event.target.files[0], item, key)
    }
  };
 
  const onFileUpload = async (file, type, key) => {
   
    setErrorMsg('');
    const count = escrowLists[lineTypeIndex].docs?.filter(value => value?.uploadStatus == true).length
    setLoading(true)
    const res = await uploadEscrowDocuments(props.accountNumber, lineType, file, fileName, count, replaceStatus, documentCode, documentId);
   
    if (res && res?.responseData?.lstResponseStatus && res?.responseData.lstResponseStatus[0]?.statusCode === '0') { 
      setLoading(false)
      let newObj = [...escrowLists]  
      newObj[lineTypeIndex]['docs'][documentIndex]['fileName'] = file.name
      newObj[lineTypeIndex]['docs'][documentIndex]['uploadStatus'] = true
      newObj[lineTypeIndex]['docs'][documentIndex]['deleteStatus'] = false
      newObj[lineTypeIndex]['docs'][documentIndex]['documentId'] = res?.responseData?.responseData
      setEscrowLists(newObj)
      handleValidations();
    } else if (res && res?.responseData?.lstResponseStatus && res?.responseData.lstResponseStatus[0]?.statusCode !== "0") {
      setLoading(false)
      setErrorMsg(res.lstResponseStatus[0].statusDesc)
      scrollTop()
    } else {
      setLoading(false)
      setEscrowApiError(renderDefaultMsg('400'));
      scrollTop()
   }
  };

  const handleAddMore = (event, item) => {
    var allowedExtensions = /(\.jpeg|\.png|\.jpg|\.pdf|\.doc|\.docx)$/i;
    var specialCharacters =  /[\/:*?“<>|]/;

    if (event.target.files[0].size > FILE_SIZE_LIMII) {
      setFilesName(event.target.files[0].name)
      setAlertStatus(true)
    } else if (!allowedExtensions.exec(event.target.value)) { 
      setFilesName(event.target.files[0].name)
      setAllowedExtensionsStatus(true)
    } else if (event.target.files[0].name.length > FILE_LENGTH) {
      setFilesName(event.target.files[0].name)
      setFileLengthStatus(true)
    }  else if (specialCharacters.test(event.target.files[0].name)) {
      setFilesName(event.target.files[0].name)
      setFileContainSpecialChar(true) 
    } else {
      onAddMoreFileUpload(event.target.files[0], item.escrowLineType)
    }
  }

  const onAddMoreFileUpload = async (file, type) => {
    setErrorMsg('');
    const count  = escrowLists[lineTypeIndex].docs?.filter(value => value?.uploadStatus == true).length
    const documentCode  = escrowLists[lineTypeIndex].docs[0].documentCode;
 
     const additionalDocumentCount  = escrowLists[lineTypeIndex].docs?.filter(value => value?.additionalDocument == true).length
     setLoading(true)
    const res = await uploadEscrowDocuments(props.accountNumber, lineType, file, deleteStatus ? fileName : documentCode.split('_')[0]+'_AddDoc' + (additionalDocumentCount + 1), count, replaceStatus, deleteStatus ? fileName : documentCode.split('_')[0]+'_AddDoc' + (additionalDocumentCount + 1), 0);
    if (res && res?.responseData?.lstResponseStatus && res?.responseData.lstResponseStatus[0]?.statusCode === '0') { 
      setLoading(false)
      let newObj = [...escrowLists]  
      newObj[lineTypeIndex]['docs'].push({"fileName": file.name, "documentName": 
      deleteStatus ? fileName : 'Additional Document ' + (additionalDocumentCount + 1), "uploadStatus": true, "additionalDocument" : true, "deleteStatus": false, "additionalDocCount" : additionalDocumentCount + 1, "documentCode" : documentCode.split('_')[0]+'_AddDoc' + (additionalDocumentCount + 1), "documentId" : res.responseData.responseData})
      setEscrowLists(newObj)
    } else if (res && res?.responseData?.lstResponseStatus && res?.responseData.lstResponseStatus[0]?.statusCode !== "0") {
      setLoading(false)
      setErrorMsg(res.lstResponseStatus[0].statusDesc)
      scrollTop()
    } else {
      setLoading(false)
      setEscrowApiError(renderDefaultMsg('400'));
      scrollTop()
    }
  };
 
  const uploadDocuments = (index, documentIndex, name, deleteStatus, replace, id, code, linetype) => {
    setLineTypeIndex(index)
    setDocumentIndex(documentIndex)
    setfileName(name)
    setDeleteStatus(deleteStatus)
    setReplaceStatus(replace)
    setDocumentId(id)
    setDocumentCode(code)
    setLineType(linetype)
  };
 
  const handleInputFile = (e) => {
    e.target.value = null;
  }
 
  if (loader) {
    return (
      <Box className={classes.loader}>
        <CircularProgress />
      </Box>
    )
  }
 
  const breadCrumText = () => {
    return 'Loan Details > Taxes & Insurance Information'
  }

  return (
    <CssBaseline>
      {!loader && 
      <>
       <Box container="true" maxWidth='xl' className={classes.root}>      
        <Box className={classes.container}>
        <CustomBreadcrum backPage={breadCrumText()} backPageLink="/EscrowInfo" activePage="Manage Escrow Account" />
          <h1 className={classes.pageHeading} color="textPrimary" component='h4'>Manage Escrow Account</h1>
          {escrowApiError !== '' ? <Typography className={classes.apiErrorMsg}>{escrowApiError}</Typography> : null}
         <Box  className={classes.infoStrip}>
          <Box style={{marginTop : '40px'}}>
            {escrowLists.map((item, index) => {  
              return(
            <Box  className={classes.escrowList} key={index}>
           
                <>
               <Grid container>
                        <Grid item  md={8} xs={12} lg={8}>
                            <Box display='flex'>
                            {item.lineType == 'TAX' ?
                            <img src={bag} className={classes.icon}/>:
                            item.lineType == 'INSURANCE' ?
                            <img src={insurance} className={classes.icon}/> :
                            <img src={mortgage} className={classes.icon}/> }
                            <Typography className={classes.taxType}>{capitalizeFirstLetter(item?.escrowLineType)}</Typography>
                            { (!item.isActiveRequest && item.checkEscrow) &&
                             <>
                              <Typography className={classes.hypen}> {'-'} </Typography>
                              <Typography className={classes.payeeType}> { item?.payeeName ? capitalizeFirstLetter(item?.payeeName) : 'N/A'}</Typography>
                              </>
                            }
                            </Box>
                        </Grid>
                    
                        <Grid item  md={4} xs={12} lg={4}>
                        { (!item.isActiveRequest && item.checkEscrow) &&
                        <>
                          { item.lineType == 'TAX' ?
                        
                            <Typography  className={classes.parcelId}>Parcel ID: {item.policyTaxId ? item.policyTaxId : 'N/A'}</Typography>
                           
                            :
                            (item.lineType == 'INSURANCE') ?
                            <Typography  className={classes.parcelId}>Policy No: {item.policyTaxId ? item.policyTaxId : 'N/A'}</Typography> :
                            <Typography  className={classes.parcelId}>Case No: {item.policyTaxId ? item.policyTaxId : 'N/A'}</Typography>
                          }
                          </>
                        }
                        </Grid>
                 </Grid>
                 {((item.checkEscrow && item.isEligibleForRemoveEscrow) || (!item.checkEscrow && item.isEligibleForAddEscrow)) &&
                 <Box display='flex' className={classes.toggleBtnBox}>
                       <>
                        {  item.lineType == 'TAX' ||  item.lineType == 'INSURANCE' ?
                           <>
                            <Typography className={classes.escrowed}>Escrow</Typography>
                            { !item.isActiveRequest ?
                            <Box  className={classes.toggleBtn}>
                            <SwitchButton
                             handleSwitch={(e) => handleChange(e, index,item)}
                             status={item.escrow}
                            ></SwitchButton>
                            </Box> : 
                            
                            <Typography className={classes.progress}>IN PROGRESS</Typography> 
                            
                            }
                            </>
                            : null
                        }
                       </>
                  </Box>
                 }
                  <Box  className={classes.toggleBtnBox}>
                      {item.escrow  && !item.show && !item.isActiveRequest &&
                    <Typography className={classes.loanescrow}>{ESCROW}</Typography>
                      }
                    { item.isActiveRequest &&  <Typography className={classes.loanescrow}>
                    {reviewingRequest(item.taskNumber, item.controlNumber)}
                      </Typography> }
                     {!item.escrow  &&  item.escrowStatus != 'Escrowed' && !item.isActiveRequest && <Typography  className={classes.loanescrow}>{AMOUNT_DIRECTLY_PAID}</Typography> }
                    { item.escrowStatus != 'Escrowed' && item.show &&
                      <>
                      <Typography className={classes.addEscrowText1}>{ADD_ESCROW_TEXT1}</Typography>
                      <Typography className={classes.addEscrowText2}>{ADD_ESCROW_TEXT2}</Typography>
                      </> }
                      {!item.escrow && item.escrowStatus == 'Escrowed' &&<>
                       <Typography className={classes.addEscrowText1}>{REMOVE_ESCROW_TEXT1}</Typography>
                       <Typography className={classes.addEscrowText2}>{REMOVE_ESCROW_TEXT2}</Typography>
                        </> }
                    
                  </Box>
                  { item.escrowStatus != 'Escrowed' && item.show && item?.docs?.length > 0 ?
                  <>
                    <hr className={classes.line} />
                 <Box className={classes.uploadSection} style={{"backgroundColor" : loading ? '#eeecec' : 'white'}}>
                      
                <Typography className={classes.upload}>Upload relevent documents
                 {loading && (index == lineTypeIndex) &&
                  <Box className={classes.loadingRoot}>
                    <CircularProgress />
                  </Box> 
                  }
                  </Typography>
                { item?.docs?.length > 0 && item?.docs.map((key, ind) => {  
                return(
                  <>
                    <hr className={classes.line} />
                    <Grid container key={ind}>
                            <Grid item  md={5} xs={5} lg={5}>
                            <Typography className={classes.escrow} style={{ display: 'flex' }}>
                             {key?.isRequired && <span className={classes.required}>* </span> }{key.documentName}
                             {key?.isRequired && 
                              <LightToolTip title={key.helpText} arrow placement="bottom"
                              >
                                <InfoIcon style={{color: secondaryColorCode, marginLeft: '3px', height: '18px'}} onClick={handleTooltipOpen} />
                              </LightToolTip> }
                            </Typography>
                            </Grid>
                            <Grid item  md={5} xs={5} lg={5}>
                            <Typography className={classes.escrowDocName} onClick={() => downloadDocuments(item.escrowLineType, key.documentName, key.fileName, key?.documentId)}>{key?.fileName ? key?.fileName : ''}</Typography>
                            </Grid>
                            <Grid item md={2} xs={2} lg={2}>
                              <input
                                accept=".pdf,.doc,.docx, image/png, image/jpg, image/jpeg"
                                type="file"
                              
                                name='selectedFile'
                                style={{display: 'none'}}
                                id="upload-button"
                                onClick={(e) => handleInputFile(e)}
                                onChange={(e) => handleFile(e,item, key)}
                                
                              />
                              {!key?.fileName ?
                              <label htmlFor="upload-button">
                                  <Typography  className={classes.addDocument} 
                                  onClick={() => uploadDocuments(index, ind, key.documentName, key.deleteStatus, false,  key?.documentId, key?.documentCode, item.escrowLineType )}>Add Document</Typography>
                              </label> : 
                              <Box className={classes.deleteReplaceSection}>
                               <Typography  className={classes.delete} onClick={() => handleDeleteDoc(item.escrowLineType, key.documentName, key.fileName, index, ind, key.documentId)}>Delete</Typography>
                               <Typography  className={classes.delete}>|</Typography>
                               <label htmlFor="upload-button">
                                <Typography  className={classes.delete} onClick={() => uploadDocuments(index, ind,  key.documentName,
                                   key.deleteStatus, true, key.documentId,  key?.documentCode,
                                   item.escrowLineType )}>Replace</Typography>
                               </label>
                              </Box>
                              }
                            </Grid>
                    </Grid>
                   
                 </>
                )})}
                { 
               
               item['docs'].every((item) => item.uploadStatus == true)
                && item.docs?.filter(value => value?.additionalDocument == true).length <= 1 ? 
                <>
                  <hr className={classes.line} />
                  <Grid container>
                            <Grid item  md={10} xs={10} lg={10}>
                            </Grid>
                            <Grid item  md={2} xs={2} lg={2}>
                            <input
                                accept=".pdf,.doc,.docx, image/png, image/jpg, image/jpeg"
                                type="file"
                              
                                name='add-more'
                                onClick={(e) => handleInputFile(e)}
                                style={{display: 'none'}}
                                id="add-more-button"
                                onChange={(e) => handleAddMore(e, item)}
                              />
                              <label htmlFor="add-more-button">
                              <Typography className={classes.addMore}  onClick={() => uploadDocuments(index, 0, '', false, false, 0, 0, item.escrowLineType)}>+ Add More</Typography>
                            </label>
                            </Grid>
                     </Grid>  </>  
                     : null
                }  
                 </Box> 
                 </> : null
                 }
                </> 
            </Box>
             )})}

               <FormGroup row>
                    <FormControlLabel
                        control={<Checkbox
                        checked={checkboxState}
                        onChange={(event) => checkboxChangeHandler(event)}
                        name="submit"
                    />}
                        label="By clicking submit, I acknowledge that this will serve as my digital signature."
                        className={classes.inputStyle}
                        classes={{
                            label: classes.radioLabel
                        }}
                    />
                    
              </FormGroup>
              <Typography className={`${classes.inputStyle} ${classes.submitText}`}>{I_AGREE}</Typography>

            
              <Box style={{marginTop: '10px'}}>
               { checkboxState && validations.length > 0 && validations.map(item => {
                  if (item.fileName == null || item.fileName == '') {
                    return(
                  <Typography className={classes.errorMsg}><span className={classes.required}>* </span> Upload required document "{item.documentName}" for {item.lineType}.</Typography>)
                  }
                })
               }
              </Box>
              
            
              <Grid container>
                        <Grid item  md={8} xs={12} lg={8}>
                            <Box display='flex'>
                             <Button type='submit' className={classes.resetBtn} 
                             disabled={ concatList.length == 0 || resetStatus || btnStatus }
                              onClick={resetDocuments} variant="outlined">
                              
                               <img src={reset} className={classes.resetIcon} />
                                Reset
                                </Button>
                            </Box>
                        </Grid>
                       
                        <Grid item  md={4} xs={12} lg={4}>
                            <Box display='flex' className={classes.commonBtn}>
                                    <Button type='submit' 
                                    className={classes.cancelBtn}  
                                    onClick={cancelDocuments} variant="outlined">
                                    Cancel
                                    </Button>
                                   

                                    {formSubmit ?
                                      <Button 
                                          type="submit"
                                          variant="contained"
                                          className={classes.submitLoader} 
                                          disabled={formSubmit}>
                                          <CircularProgress style={{ height: '24px', width: '24px' }} 
                                           color='primary' /> 
                                        </Button>
                                        :
                                        <Button type='submit'
                                        onClick={handleSubmitClick}
                                        // disabled={!checkboxState || validations?.filter((keys) => keys.fileName == null).length > 0 || validations.length == 0}  
                                        disabled={!checkboxState || submitBtnStatus || btnStatus || concatList.length == 0 || isAdmin()}
                                        className={classes.submitBtn}  variant="contained" color="primary">
                                        Submit
                                        </Button>
                                      }
                            </Box>
                        </Grid>
                </Grid> 
          </Box> 
        </Box>
        </Box>
      </Box>
      <DeleteAlert
        open={open}
        file={file}
        handleDelete={handleDelete}
        handleClose={handleClose}
      />
       <SubmitAlert
        open={openSubmitPopup}
        submitMessages={submitMessages}
        showMessageStatus={showMessageStatus}
        handleSubmit={handleSubmit}
        handleSubmitClose={handleSubmitClose}
      />
      <FileSizeAlert
        open={alertStatus}
        handleAlertClose={handleAlertClose}
        file={file}
      />
      <FileExtensionAlert
        open={allowedExtensionsStatus}
        handleExtensionAlertClose={handleExtensionAlertClose}
        file={file}
      />
      <UploadFileLengthValidationAlert
        open={fileLengthStatus}
        handleFileLengthAlertClose={handleFileLengthAlertClose}
        file={file}
       />
      <SpecialCharAlert
        open={specialChar}
        handleSpecialCharAlertClose={handleSpecialCharAlertClose}
        file={file}
      />
      <CitLengthAlert
        citEscrowStatus={citEscrowStatus}
        open={citLengthStatus}
        lineType={lineTypeStatus}
        handleCitLengthAlertClose={handleCitLengthAlertClose}
      />
    </> 
    }
    </CssBaseline>
  )
}
