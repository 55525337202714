import React, { useEffect, useState } from 'react';
import { Box, Breadcrumbs, Button, CircularProgress } from "@material-ui/core";
import { useStyles } from './styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import hardshipImg from '../../assets/hardship.png';
import Federal from '../../assets/FinancialHardship/Federal.svg';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getContent, getFinancialHardShipTiles } from '../../actions';
import { scrollTop } from '../../helpers';
import { DisasterAlert } from '../../components';
import Cookies from 'js-cookie';
import CustomBreadcrum from '../Breadcrum';

export const FinancialHardship = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useSelector(state => state.theme);
  const { customerCareNumber } = theme;
  const [topContent, setTopContent] = useState(null);
  const [middelContent, setMiddelContent] = useState(null);
  const [bottomContent, setBottomContent] = useState(null);
  const [financialHardShipTiles, setFinancialHardShipTiles] = useState([]);
  const [confirmAlert, setIsAgencyLoan] = React.useState(false);

  const getContentData = async () => {
    const res = await dispatch(getContent('PC020'));
    if (res) {
      res.map(content => {
        switch (content.contentKey.toUpperCase()) {
          case 'TOP1':
            setTopContent(content);
            return;
          case 'MIDDEL':
            setMiddelContent(content);
            return;
          case 'BOTTOM':
            setBottomContent(content);
            return;
          default:
            break;
        }
      })
      scrollTop();
    }
  }

  const getFinancialHardShipData = async () => {
    const res = await getFinancialHardShipTiles();
    if (res && res.lstResponseStatus[0].statusCode === '0') {
      const tiles = res.financialHardshipTiles.map(tile => {
        if (tile.tilesName.toUpperCase() === 'DontWaitWeCanHelp'.toUpperCase()) {
          tile.tilesBody = tile.tilesBody.replace("[CCNo]", customerCareNumber);
        }
        return tile;
      })
      if (Cookies.get("displayNaturalDisasterLink") == 'true') {
        setFinancialHardShipTiles(tiles)
      } else {
        const a = tiles.findIndex((tile) => tile.tilesName == "DisasterAssistance" )
        if (a !== -1) {
          const b = tiles.splice(a,1)
          setFinancialHardShipTiles(tiles)
        }
      }
     
      scrollTop();
    } else {
      scrollTop();
    }
  }

  useEffect(() => {
    getContentData();
    getFinancialHardShipData();
  }, [])

  const handleClose = () => {
    setIsAgencyLoan(false);
  }

  const routePage = (url) => {    
    if (url.tilesName == "DisasterAssistance") {
      if (Cookies.get("isAgencyLoan") == 'false') {
        setIsAgencyLoan(true)
      } else {
         history.push(`/DisasterAssistance`);
      }
    }
  }
 
  if (financialHardShipTiles.length === 0) {
    return (
      <Box className={classes.loaderContainer}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box className={classes.pageBg}>
      <Box className={classes.root}>
       
        <CustomBreadcrum backPage="Payments" backPageLink="/PaymentOption" activePage="Financial Hardship" />

        <h1 className={classes.title}>Mortgage Assistance - Know Your Options</h1>
        <Box className={classes.container}>
          <Box className={classes.havingDifficulty}>
            <Box className={classes.havingDifficultyContent}>
              {topContent && topContent.contentHeader && <h3 className={classes.subTitle} dangerouslySetInnerHTML={{ __html: topContent.contentHeader }}></h3>}
              {topContent && topContent.contentValue && <p className={classes.text} dangerouslySetInnerHTML={{ __html: topContent.contentValue }} />}
            </Box>
            <Box className={classes.havingDifficultyImgContainer}>
              <img className={classes.havingDifficultyImg} src={hardshipImg} alt='pic' />
            </Box>
          </Box>
        </Box>
        <Box className={classes.container}>
          <Box>
            {middelContent && middelContent.contentHeader && <h3 className={classes.subTitle} dangerouslySetInnerHTML={{ __html: middelContent.contentHeader }} />}
            {middelContent && middelContent.contentValue && <p className={classes.text} dangerouslySetInnerHTML={{ __html: middelContent.contentValue }} />}            
          </Box>
          <Box className={classes.cardContainer}>
            {financialHardShipTiles.map(tile => 
              {
              return (
              
                <Box className={classes.card} key={tile.id}>
                  <img className={classes.cardIcon} src={tile.tilesImageUrl} alt='icon' />
                  <Box className={classes.cardContent}>
                    <h3 className={classes.cardTitle}>{tile.tilesTitle}</h3>
                    <p className={classes.cardText}>{tile.tilesBody}</p>
                    <Box className={classes.btnContainer}>
                    
                      { ((tile.tilesName == 'DisasterAssistance') && (Cookies.get("isAgencyLoan") == 'false')) ? 
                       <span className={classes.linkBtn} onClick={() => routePage(tile)}
                       >  {tile.buttonText}
                       </span> :
                       <Link className={classes.linkBtn} to={{
                        pathname: `/${tile.buttonURL}`,
                        state: {
                          tilesName: tile.tilesName
                        }
                      }}>
                        {tile.buttonText}
                      </Link>
                      }
                        

                    </Box>
                  </Box>
                </Box>
              
              ) 
            })}
          </Box>

          <Box className={classes.accFinancialContainer}>
            <Box className={classes.accFinancialImgContainer}>
              <img className={classes.accFinancialImg} src={Federal} alt='pic' />
            </Box>
            <Box>
              {bottomContent && bottomContent.contentHeader && <h3 className={classes.subTitle} dangerouslySetInnerHTML={{ __html: bottomContent.contentHeader }} />}
              {bottomContent && bottomContent.contentValue && <p className={classes.text} dangerouslySetInnerHTML={{ __html: bottomContent.contentValue }} />}
            </Box>
          </Box>
        </Box>
      </Box>
      {
        confirmAlert && <DisasterAlert
          open={confirmAlert}
          handleClose={handleClose}
        />
      }
    </Box>
  );
}
