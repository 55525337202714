import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from "@material-ui/core";
import { useStyles } from './styles';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Cookies from 'js-cookie';
import { withStyles } from '@material-ui/core/styles';
import { scrollTop, renderDefaultMsg, getAppSource, getUserType } from '../../../../helpers';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { UpdateCompanyDetails, getGetCompanyDetails } from './../../../../actions';
import { Typography } from '@material-ui/core';


export const Admincompanydetails = (props) => {
  const classes = useStyles();
  const [dataError, setDataError] = useState('');
  const [dataLoader, setDataLoader] = useState(false);
  const [lstCompanyDetails, setlstCompanyDetails] = useState([]);

  const getCompanyDetailsdata = async (e) => {
    setDataLoader(true);
    const response = await getGetCompanyDetails();

    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode == '0') {
      setDataError('');
      setDataLoader(false);
      if (response && response.lstCompanyDetails.length > 0) {
        if (response && response.lstCompanyDetails !== null) {
          setlstCompanyDetails(response.lstCompanyDetails)
        }
      }
      
    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
      setDataLoader(false);
      setDataError(response.lstResponseStatus[0].statusDesc);
    } else {
      setDataLoader(false);
      setDataError(renderDefaultMsg('400'));
    }
  }

  const UpdateCompanyDetailsdata = async (id,status) => {
    setDataError('');
    var displayStatus = !status
    const data = {
      "pageID": id,
      "isActive": displayStatus,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }

    const response = await UpdateCompanyDetails(data);
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode == '0') {
      getCompanyDetailsdata()      
    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
      setDataError(response.lstResponseStatus[0].statusDesc);
    } else {
      setDataError(renderDefaultMsg('400'));
    }
  }

  useEffect(() => {
    getCompanyDetailsdata()
  }, []);

  return (
    <Box className={classes.bgg}>
      <Box className={classes.fullpart}>
        <Box className={classes.root}>
        <Typography className={classes.title}>Company Details</Typography>
          <div className={classes.firstarea}>
            <Paper>
              {dataError == "" ? <TableContainer>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Company Name</StyledTableCell>
                      <StyledTableCell align="right">Permission For Refinance</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {true && <TableBody>
                    {!dataLoader ? lstCompanyDetails.map((row) => (
                      <StyledTableRow key={row.privateLabelCode}>
                        <StyledTableCell component="th" scope="row">
                          {row.privateLabelName}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row" style={{ textAlign: 'right', paddingRight: '100px' }}>
                          {row?.lstPageDetails[0]?.isDisplay ? <CheckIcon onClick={() => UpdateCompanyDetailsdata( row.lstPageDetails[0].id, row?.lstPageDetails[0]?.isDisplay)} style={{ color: 'green', cursor: 'pointer' }} /> : <CloseIcon onClick={() => UpdateCompanyDetailsdata( row.lstPageDetails[0].id, row?.lstPageDetails[0]?.isDisplay)} style={{ color: 'red', cursor: 'pointer' }} />}
                        </StyledTableCell>
                      </StyledTableRow>
                    )):null}
                     {dataLoader && (<StyledTableRow>
                    <StyledTableCell colSpan="12" align="center"><CircularProgress /></StyledTableCell>
                  </StyledTableRow>)}
                  </TableBody>}
                  {/* {dataLoader && <TableBody>(<StyledTableRow>
                    <StyledTableCell colSpan="12" align="center"><CircularProgress /></StyledTableCell>
                  </StyledTableRow>)</TableBody>} */}
                </Table>
              </TableContainer> : <Box className={classes.dataErrorMsg}>{dataError}</Box>}

            </Paper>
          </div> 
        </Box>
      </Box >
    </Box>
  )
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#535D6D',
    color: theme.palette.common.white,
    fontSize: '14px',
    fontFamily: 'Montserrat',
    fontWeight: 'bold'
  },
  body: {
    fontSize: "14px",
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Montserrat"
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);