
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => (
  {
    root: {
      width: '100%',
      //minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      //alignItems: 'center',
      backgroundColor: "#E8E8E8"
    },


    layer: {
      position: "fixed",
      //backgroundColor: "rgba(0, 0, 255, 0.3)",
      backgroundColor: "#4e43432e",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 99999,
      //display: "none",
    },

    timerContainer: {
      background: '#cccaca',
      textAlign: 'center',
      maxWidth: '52%',
      padding: '20px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '90%',
      },
    },

    timer: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: "600",
      letterSpacing: "0.44px",
      lineHeight: "24px",
    },
    circle: {
      height: "125px",
      width: "125px",
      border: "2px solid #bbb",
      borderRadius: "50%",
      display: "inline-block",
    }


  }
));