import { makeStyles } from '@material-ui/core/styles';
import signinIcon from '../../assets/SignIn/SignIn.svg';

export const useStyles = makeStyles((theme) => ({
  mainRoot: {
    width: "100%",
    backgroundColor: '#ccc',
    backgroundImage: `url(${signinIcon})`,
    backgroundPosition: "bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'contain',
  },

  root: {
    width: "100%",
    maxWidth: "120em",
    padding: "2.4em 1.5em 9em 1.5em",
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      padding: "2.4em 1.5em 5em 1.5em",
    },
  },

  loaderContainer: {
    width: '100%',
    height: '100%',
    minHeight: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  container: {
    width: '100%',
    marginTop: 12,
    overflow: 'hidden',
  },

  link: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    marginTop: 14,
  },

  disabledLink: {
    color: "#7B8390",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    textDecoration: 'none',
  },

  activeLink: {
    color: theme.palette.primary.main,
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    textDecoration: 'none',
  },

  header: {
    padding: '12px',
    backgroundColor: theme.palette.secondary.main,
  },

  title: {
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: "600",
    color: '#fff',
    textAlign: 'center',
  },

  pageHeading: {
    color: '#1A4664',
    fontFamily: 'Montserrat',
    fontSize: '22px',
    fontWeight: '700',
    letterSpacing: '0.18px',
    lineHeight: '24px',
    marginBottom: 25,
    marginTop: 24,
  }


}));