import { SET_PAYMENT_OPTION } from '../types';

export default (state = "FAQ", action) => {
  switch (action.type) {
    case SET_PAYMENT_OPTION:
      return action.payload
    default:
      return state;
  }
}

