import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => (
  {
    root: {
      borderRadius: 12,
      backgroundColor: "#ffffff",
      boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
      //padding: '2.2em 10em',  
      padding:'2.5em'  
    },
    rootP: {
      padding: "20px 30px 30px 30px"
    },
    closeBtn:{      
      height:"24px",
      width:"24px",
      backgroundColor: "#7B8390",
      borderRadius:"50%",
      display: "inline-block",
      textAlign: "center",
      color: "#ffffff",
      cursor: "pointer",      
    },
    
    title: {
      color: "#0F355C",
      fontFamily: "Montserrat",
      fontSize: "24px",
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: "29px",
      textAlign: "center",
    },   
    summary: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 500,
      letterSpacing: "0.36px",
      lineHeight: "19px",
    },
    btnBox: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItem: 'center',
      marginTop: 40,
    },
    cancelBtn: {
      width: 140,
      fontFamily: "Montserrat",
      fontWeight: "600",
      letterSpacing: "0.53px",
      lineHeight: "19px",
      textAlign: "center",
      margin: "0px 10px "
    },
    leaveBtn: {
      color: "#ffffff",
      width: 140,
      fontFamily: "Montserrat",
      fontWeight: "600",
      letterSpacing: "0.53px",
      lineHeight: "19px",
      textAlign: "center",
      margin: "0px 10px "
    },

  saveExitBtn:{
    borderRadius:"28px",
    boxShadow: "0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2)",
    width: "205px",
    height:"48px",
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "1.43px",
    lineHeight: "16px",
  }

  }
));

