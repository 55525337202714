import React from 'react';
import { useStyles } from './styles';
import { Box, Typography, IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import tick from '../../assets/tick.png';
import { useHistory } from 'react-router-dom';
import { getDocLanverDetails } from '../../actions';
import Cookies from 'js-cookie';
import { getMortgageLabel, getLoanLabel, getAccountLabel, isHEI } from '../../helpers'

export const PaidInFullPopup = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const browserName = Cookies.get('browser');
  const privateLabelUrl = Cookies.get("privateLabelUrl")
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDocumentCenter = async () => {
    if (browserName.includes('Safari ')) {
    const res = await getDocLanverDetails(props.selectedAccount);
      if (res) {
      var newWin = window.open(res.url,"_blank");             
      if(!newWin || newWin.closed || typeof newWin.closed=='undefined') 
        { 
            alert('A popup blocker is preventing the application from opening the page. To allow pop-ups, go to Safari app and choose Safari>Preferences, then click websites and allow popup for your website.')
        }
      }
    } else {
      history.push('/DocumentCenter')
    }
  }

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={props?.closePaidInFullPopup} open={props?.open}>
     <IconButton onClick={props?.closePaidInFullPopup} className={classes.closeIcon}>
        <CancelIcon />
      </IconButton>
      <Box className={classes.root}>
       
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>{getAccountLabel()} Access Info</Typography>
        </Box>
        
        <Box style={{padding: '12px'}}>
           { (props?.liquidationType == 'chargedOff') &&
            <Box style={{display: 'flex'}}><img src={tick} alt="check icon" className={classes.tick}/><Typography className={classes.paragraph}>You may obtain more information within your charge off billing statement. Please note that the balance on the {getAccountLabel()?.toLowerCase()} is not being canceled or forgiven; and the {getLoanLabel()?.toLowerCase()} may be purchased, assigned, or transferred.</Typography></Box>
           }
             <Box style={{display: 'flex'}}><img src={tick} alt="check icon" className={classes.tick}/><Typography className={classes.paragraph}>You will now only have view rights, which will allow you to access information about your {getLoanLabel()?.toLowerCase()} balance, payment history, and {getAccountLabel()?.toLowerCase()} details.</Typography></Box>
             <Box style={{display: 'flex'}}><img src={tick} alt="check icon" className={classes.tick}/><Typography className={classes.paragraph}>You can view/download the available documents in  <a className={classes.link} onClick={() => handleDocumentCenter()}>document center</a> as usual before.</Typography></Box>
             { ((privateLabelUrl.toLowerCase() !== "aafmaa") && !isHEI()) &&
             <Box style={{display: 'flex'}}><img src={tick} alt="check icon" className={classes.tick}/><Typography className={classes.paragraph}>You can also request any missing {getMortgageLabel()?.toLowerCase()} document from <a className={classes.link} onClick={() => history.push('/DocumentRequest')}>document request</a> feature.</Typography></Box> }
        </Box>
    
      </Box> 
    </Dialog>
  )
}
