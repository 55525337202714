import { makeStyles, withStyles } from '@material-ui/core/styles';
import verificationImg from '../../assets/verification.svg'
import { OutlinedInput, InputLabel } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${verificationImg})`,
    backgroundPosition: "top left",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'cover'
  },

  formContainer: {
    minWidth: "58.8em",
    padding: '3.9em 4.9em',
    backgroundColor: '#fff',
    borderRadius: 12,
    marginTop: '6.6em',
    marginBottom: '6.6em',
    boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
    [theme.breakpoints.down('sm')]: {
      minWidth: "auto",
      width: '100%',
      padding: '20px',
      margin: '50px 15px'
    },
  },

  title: {
    fontSize: '3.6em',
    fontWeight: '600',
    textAlign: 'center',
    marginBottom: 33,
    marginTop: 0,
    lineHeight: 1,
    fontFamily: 'Montserrat',
    color: "#535D6D",
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.4em',
    },
  },

  inputContainer: {
    marginBottom: '2.8em',
  },

  inputMarginFixed: {
    // marginBottom: '0',
  },


  inputStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600"
  },

  labelStyle: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "500"
  },

  signUpBtn: {
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: '600',
    marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    },
  },

  errorMsg: {
    fontSize: '20px',
    fontWeight: '500',
    textAlign: 'center',
    marginBottom: 10,
    fontFamily: 'Montserrat',
    color: "red",
  },

  ruleContainer: {
    marginBottom: 28,
    border: '1px solid #A0A0A0',
    boxShadow: '0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.2)',
    boxSizing: 'border-box',
    borderRadius: '12px',
    backgroundColor: '#FFFFFF',
    padding: '32px'
  },

  validationTitle: {
    color: " #202020",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: "600",
    marginBottom: 5
  },

  validText: {
    color: '#00CB82',
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: "500",
    lineHeight: '1.5',
  },

  invalidText: {
    color: '#E30909',
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: "400",
    lineHeight: '1.5',
  }
}
));


export const CutomOutlinedInput = withStyles({
  root: {
    "&$focused $notchedOutline": {
      border: "2px solid #25ABC7"
    }
  },
  focused: {},
  notchedOutline: {}
})(OutlinedInput);

export const CustomInputLabel = withStyles({
  root: {
    "&$focused": {
      color: "#25ABC7",
    }
  },
  focused: {},
})(InputLabel);