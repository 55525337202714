import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';


export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: 12,
    marginTop: 28,
    backgroundColor: "#F5F6F7",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    padding: '3.6em 7.7em',
    [theme.breakpoints.down('sm')]: {
      padding: '1.6em',
    },
  },

  loader: {
    width: '100%',
    borderRadius: 12,
    marginTop: 28,
    backgroundColor: "#fff",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    padding: '3.6em 7.7em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '1.6em',
    },
  },

  loaderWrapper: {
    width: '100%',
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "500",
    marginBottom: 35,
  },

  table: {

  },

  norecordText: {
    color: "#0F355C",
    fontFamily: "Montserrat",
    fontSize: '2.4em',
    fontWeight: "500",
    textAlign: 'center',
    padding: '1em',
  },

  noDataBody: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    aligItems: 'cente',
  },

  btn: {
    maxWidth: 307,
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "500",
    display: 'block',
    margin: '2em auto 0',
    textAlign: 'center'
  },

}));


export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#535d6d",
    color: theme.palette.common.white,
    borderRight: 'solid 1px #fff',
    fontFamily: "Montserrat",
    fontSize: 14,
  },
  body: {
    fontSize: 14,
    fontFamily: "Montserrat",
    color: '#585757'
  },
}))(TableCell);



export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);