
import Bowser from "bowser";
import Cookies from 'js-cookie';
import { createTheme  } from '@material-ui/core/styles';
import { encrypt } from './encrypt';
import { useSelector } from 'react-redux';

const publicIp = require('public-ip');

export const setBrowser = async () => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const browserObj = browser.getBrowser()
  // await Cookies.set("browser", browser.getBrowserName())
  await Cookies.set("browser", `${browserObj.name} - ${browserObj.version}`)
}

export const domainName = 'https://myloanweb.com'

export const getPublicIp = async () => {
  if (Cookies.get("publicIp")) {
    return Cookies.get("publicIp")
  }
  const res = await publicIp.v4();
  if (res) {
    const encryptPublicIp = encrypt(res)
    return encryptPublicIp;
  } else {
    return encrypt('192.168.1.1')
  }
}

export const getAppSource = () => {
  return "Website";
}

export const getUserType = () => {
  if (Cookies.get("adminToken")) {
    return "Admin"
  }
  return "Borrower"
}

export const maskEmail = (myemailId) => {
  const maskid = myemailId.replace(/^(..)(.*)(.@.*)$/,
    (_, a, b, c) => a + b.replace(/./g, '*') + c
  );
  return maskid
}

export const maskMobile = (number) => {
  // return number.replace(/(?<=)\d(?=\d\d\d\d)/gm, '*');
  return "****" + number.slice(-4);
}

export const maskLoan = (number) => {
  let lastFour = number.slice(-4);
  return "***" + lastFour;
}

export const maskAccountNo = (number) => {
  let lastFour = number.slice(-4);
  return "XXX" + lastFour;
}

export const hasValidLength = (text) => {
  if (text.length >= 8 && text.length <= 20) {
    return true;
  }
  return false
}


export const hasLowerChar = (text) => {
  if ((/[a-z]/.test(text))) {
    return true;
  }
  return false
}


export const hasUpperChar = (text) => {
  if ((/[A-Z]/.test(text))) {
    return true;
  }
  return false
}


export const hasSpecialChar = (text) => {
  if ((/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/).test(text)) {
    return true;
  }
  return false
}


export const hasDigits = (text) => {
  if (/\d/.test(text)) {
    return true;
  }
  return false
}

export const signOut = async (callBack) => {
  Cookies.remove('privateToken');
  Cookies.remove('accessTokenID');
  Cookies.remove('adminToken');
  Cookies.remove('selectedAccount');
  Cookies.remove('totalAccounts');
  Cookies.remove('requestType');
  Cookies.remove('subOrdinate');
  Cookies.remove('escrow');
  Cookies.remove('publicIp');
  Cookies.remove('maturityExtensionMenu');
  Cookies.remove('displayNaturalDisasterLink');
  Cookies.remove('isAgencyLoan');
  Cookies.remove('redirectUrl');
  Cookies.remove('isRefiStateEligible');
  Cookies.set("publicIp", await getPublicIp()) 
  Cookies.remove('releaseIds');
  Cookies.remove('announcement');
  Cookies.remove('serviceReleasedFlag');
  Cookies.remove('paidInFullFlag');
  Cookies.remove('isAgency');
  Cookies.remove('ceaseDesistFlag');
  Cookies.remove('promisePlan');
  Cookies.remove('showPromisePopup');
  Cookies.remove("isMaturityExtenstionOpened");
  Cookies.remove('requestId');
  Cookies.remove('isDocRequestAllowed');
  Cookies.remove("payOffRequestSLA");
  Cookies.remove("displaySurvey");
  Cookies.remove("refinanceFlag");
  Cookies.remove("loanAccounts");
  Cookies.remove("chargedOffFlag");
  Cookies.remove("fclCompletedFlag")
  Cookies.remove('showDeliqPopup');
  setTimeout(callBack, 100);

}

export const scrollTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
}

export const renderDraftingOption = (option) => {
  switch (option) {
    case "M":
      return "Monthly";
    case "B":
      return "Bi-Weekly";
    case "S":
      return "semi monthly";
    case "W":
      return "Weekly";
    default:
      return "Monthly";
  }
}

export const renderDraftingAmount = (option, amount) => {
  switch (option) {
    case "M":
      return amount;
    case "B":
      return amount / 2;
    case "S":
      return amount / 2;
    case "W":
      return amount / 4;
    default:
      return amount;
  }
}


export const maskAccount = (account) => {
  return "..." + account.slice(-4)
}

export const renderFullDraftingOption = (option) => {
  const newOption = option.toUpperCase()
  switch (newOption) {
    case "Co-BORROWER":
      return "C";
    case "BORROWER":
      return "B";
    default:
      return "T";
  }
}

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const checkDigitOnly = (number) => {
  if ((/^[0-9]+$/).test(number) || number === '') {
    return true;
  }
  return false;
}

export const checkDigitWithDecimal = (number) => {
  if ((/^\d*(\.\d{0,2})?$/).test(number) || number === '') {
    return true;
  }
  return false;
}

export const consecutiveDigits = (input) => {
  if (input.length == 9) {
    const allSeqNumsArr = ['123456789', '012345678']
    const status = allSeqNumsArr.filter((num) => num == input).length > 0;
    return !status;
  } else {
    return false;
  }
}

export const reverseConsecutiveDigits = (input) => {
  if (input.length == 9) {
    const reverseSeqNumsArr = ['987654321', '876543210'];
    const status = reverseSeqNumsArr.filter((num) => num == input).length > 0;
    return !status;
  } else {
    return false;
  }
}

export const checkAllDigits = (num) => {
  if (num.length == 9) {
    var first = num % 10;
    while (num) {
      if (num % 10 !== first) return true;
        num = Math.floor(num / 10);
    }
    return false
  } else {
    return false
  }
}

export const sequentialDigits = (input) => {
  if (input.length == 9) {
    const allSeqNumsArr = [123456789];
    const status = allSeqNumsArr.filter((num) => num == input).length > 0;
    return !status;
  } else {
    return false;
  }
}

export const digitsStartsWith = (num) => {
  if (num.length == 9) {
    const digits = (""+num).split("");
    const startWith = digits[0]+digits[1]+digits[2];
    if (startWith == '666' || startWith == '000') {
      return false
    } else {
     return true
    }
  } else {
    return false
  }
}

export const digitsEndsWith = (num) => {
  if (num.length == 9) {
    const digits = (""+num).split("");
    const endsWith = digits[5]+digits[6]+digits[7]+digits[8];
    if (endsWith == '0000') {
      return false
    } else {
     return true
    }
  } else {
    return false
  }
}

export const digitsInPositions = (num) => {
  if (num.length == 9) {
    const digits = (""+num).split("");
    const inPositions = digits[3]+digits[4];
    if (inPositions == '00') {
      return false
    } else {
     return true
    }
  } else {
    return false
  }
}

export const reverseSequentialDigits = (input) => {
  if (input.length == 9) {
    const reverseSeqNumsArr = [987654321];
    const status = reverseSeqNumsArr.filter((num) => num == input).length > 0;
    return !status;
  } else {
    return false;
  }
}

export const validateEmail = (email) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) || email === '') {
  // if (/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(email) || email === '') {
    return null;
  }
  // else if (email.trim() === "") {
  //   return "Email is required";
  // }
  return "Please enter a valid email address.";
}

export const validateZip = (code) => {
  if ((/^[0-9]+$/).test(code) && code.length === 5) {
    return null;
  } else if (code.trim() === "") {
    return "Zip is required";
  }
  return "Please enter valid borrower's zip code.";
}

// create captch
export const returnCaptchaCode = () => {
  var code;
  document.getElementById('captcha').innerHTML = "";
  var charsArray = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var lengthOtp = 6;
  var captcha = [];
  for (var i = 0; i < lengthOtp; i++) {
    var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
    if (captcha.indexOf(charsArray[index]) == -1)
      captcha.push(charsArray[index]);
    else i--;
  }
  var canv = document.createElement("canvas");

  var fir = Math.floor(Math.random() * 10)
  var secc = Math.floor(Math.random() * 10)
  var third = Math.floor(Math.random() * 10)
  captcha[1] = fir;
  captcha[3] = secc;
  captcha[5] = third;

  canv.id = "captcha";
  canv.width = 170;
  canv.height = 40;
  canv.padding = 10;
  var ctx = canv.getContext("2d");
  ctx.font = "600 36px 'Montserrat'";
  ctx.fillText(captcha.join(""), 0, 30);
  code = captcha.join("");
  document.getElementById("captcha").appendChild(canv); // adds the canvas to the body element
  return code;
}

export const renderDefaultMsg = (msgCode) => {
  switch (msgCode) {
    case "200":
      return "";
    case "400":
      return "We apologize for the inconvenience. Please try your request again as we ran into a system issue.";
    default:
      return "We apologize for the inconvenience. Please try your request again as we ran into a system issue.";
  }
}

export const checkPhoneLength = (number) => {
  if (number !== '' && number.length === 10) {
    return true
  } else {
    return false
  }
}
export const checkCellPhoneLength = (number) => {
  if (number !== '' && number.length === 10) {
    return true
  } else {
    return false
  }
}
export const checkSSNLength = (number) => {
  if (number !== '' && number.length === 9) {
    return true
  } else {
    return false
  }
}
export const checkValidEmail = (email) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(email) || email === '') {
  // if (/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(email) || email === '') {
    return true;
  } else {
    return false
  }
}

export const checkAccountValidEmail = (email) => {
  if (email.split("")[0] == '.') {
    return false;
  } else {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(email) || email === '') {
    // if (/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(email) || email === '') {
      return true;
    } else {
      return false
    }
  }
}

export const getMyTheme = (mytheme) => {
  const { primaryColorCode, secondaryColorCode, headerBackgrounColorCode,
    headerFountcolorCode, footerFountColorCode, footerBackgroundColorCode,
    footerTitleColorCode, primaryBtnColor, secondaryBtnColor } = mytheme;
  const theme = createTheme ({
    header: {
      backgrounColor: headerBackgrounColorCode,
      fontColor: headerFountcolorCode,
    },
    footer: {
      backgroundColor: footerBackgroundColorCode,
      fontColor: footerFountColorCode,
      titleColor: footerTitleColorCode
    },
    palette: {
      primary: {
        main: primaryColorCode,
        contrastText: primaryBtnColor,
      },
      secondary: {
        main: secondaryColorCode,
        contrastText: secondaryBtnColor,
      }
    },
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "1.4em",
          fontFamily: "Montserrat",
          color: "#FFF",
          backgroundColor: secondaryColorCode,
          padding: '1.6em',
        },
        arrow: {
          color: secondaryColorCode,
        }
      },
      MuiButton: {
        root: {
          height: 40,
        },
        contained: {
          fontWeight: '600',
        },
        outlined: {
          fontWeight: '600',
          color: '#535D6D',
          border: `1px solid ${primaryColorCode}`
        }
      }
    },
    props: {
      MuiDialog: {
        disableBackdropClick: true,
      }
    }
  });

  return theme
}

export const getAdminTheme = () => {

  const theme = createTheme ({
    header: {
      backgrounColor: '#EAECED',
      fontColor: '#202020',
    },
    palette: {
      primary: {
        main: '#F7B15C',
        contrastText: '#535D6D',
      },
      secondary: {
        main: '#7EB1CC',
        contrastText: '#FFFFFF',
      }
    },
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "1.4em",
          fontFamily: "Montserrat",
          color: "#FFF",
          backgroundColor: '#7EB1CC',
          padding: '1.6em',
        },
        arrow: {
          color: '#7EB1CC',
        }
      },
      MuiButton: {
        root: {
          height: 40,
        },
        contained: {
          fontWeight: '600',
        },
        outlined: {
          fontWeight: '600',
        }
      }
    }
  });

  return theme
}

const getWidgetProfile = () => {
  
  const privatelabel = window.location.pathname.split('/')[1];
  const clientName = Cookies.get('clientName');
  const isAgencyLoan = Cookies.get('isAgency');
  

  if (Cookies.get('privateToken')) {
    if (privatelabel !== undefined && clientName !== undefined && clientName !== null) {
      if (privatelabel.toLowerCase() == 'aafmaa' || clientName.toLowerCase() == 'vrm') {
      return 'Email - Customer Care Contact'
      } else if (isAgencyLoan == 'true') {
        return 'Email - Customer Care Contact -ESPL NON-AGENCY'
      } else {
        return 'Email - Customer Service Contact'
      }
   } else {
    return 'Email - Customer Service Contact'
   }
  } else {
    if (privatelabel !== undefined) {
      if (privatelabel.toLowerCase() == 'bsi' || privatelabel.toLowerCase() == 'aafmaa') {
        return 'Email - Customer Care Contact'
      } else {
        return 'Email - Customer Service Contact'
      }
    } else {
      return 'Email - Customer Service Contact'
    }  
  }
}


export const removeHyphenHandler = (num) => {
  return num.replace(/-/g, "");
}


export const isAdmin = () => {
  if (Cookies.get("adminToken") || (Cookies.get("paidInFullFlag") == 'true') || (Cookies.get("serviceReleasedFlag") == 'true') || (Cookies.get("chargedOffFlag") == 'true') || (Cookies.get("fclCompletedFlag") == 'true')) {
    return true;
  }
  return false;
}

export const isAdminCheck = () => {
  if (Cookies.get("adminToken")) {
    return true;
  }
  return false;
}

export const howItWorkHomeownerInsurance = () => {
  const theme = useSelector(state => state.theme);
  return `Homeowner’s insurance is required for everyone who has a mortgage and can protect you financially in the event of a fire, theft or other covered event. ${theme.privateLabelName} requires you to have at least 100% repair or replacement cost coverage in the event your home is damaged due to a covered event.  A homeowner policy may also cover the contents of your home and provide personal liability coverage. There are multiple types of insurance policies, including hazard, flood, wind and fire and insurance requirements may vary by property type. Flood insurance is required if your property is located in a FEMA-designated flood zone.`
}

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();
}

export const reviewingRequest = (taskNumber, controlNumber) => {
  return `We are reviewing your request. Your request identification number is ${taskNumber}-${controlNumber}.`
}

export const achSuspensionMessage = (customerCareNumber) => {
  return `Your recurring payment is suspended. Please contact Customer Care at ${ customerCareNumber } to reactivate your recurring draft.`
}

export const isHEI = () => {
  const privatelabel = window.location.pathname.split('/')[1];
  if ((privatelabel?.toLowerCase() == 'point') || (privatelabel?.toLowerCase() == 'cornerstone')) { 
    return true
  } else {
    return false
  }
}

export const getLoanLabel = () => {
  if (isHEI()) {
    return 'Contract';
  } else {
    return 'Loan';
  }
}

export const getBorrowerLabel = () => {
  if (isHEI()) {
    return 'Homeowner';
  } else {
    return 'Borrower';
  }
}

export const getMortgageLabel = () => {
  if (isHEI()) {
    return 'Home Equity Investment';
  } else {
    return 'Mortgage';
  }
}

export const getAccountLabel = () => {
  if (isHEI()) {
    return 'Contract';
  } else {
    return 'Account';
  }
}

export const getPointPrivateLabel = () => {
  if (isHEI()) {
    return true;
  } else {
    return false;
  }
}

export const fiv9options = {
  "rootUrl": "https://app.five9.com/consoles/",
  "type": "email",
  "title": '.',
  "tenant": "ServisOne",
  "profiles": getWidgetProfile(),
  "showProfiles": false,
  "theme": "default-theme.css",
  "fields": {
    "name": {
      "value": "",
      "show": true,
      "label": "Name"
    },
    "email": {
      "value": "",
      "show": true,
      "label": "Email"
    },
    "Loan Number": {
      "value": "",
      "show": true,
      "label": `${getLoanLabel()} Number` 
    },
    "subject": {
      "value": "",
      "show": true,
      "label": ""
    }
  }
};

export const getFCLCompletedStatus = (loanInfoData) => {
  if (((loanInfoData?.liquidationStatus) && (loanInfoData?.liquidationType == 'FCLCompleted') && (loanInfoData?.remainingPurgedDays > 30)) || ((loanInfoData?.isLoanPurged) && (loanInfoData?.liquidationType == 'FCLCompleted'))) {
    return true;
  } else {
    return false;
  }
}

export const getFCLAccesDaysStatus = (loanInfoData) => {
  if ((loanInfoData?.liquidationStatus) && (loanInfoData?.liquidationType == 'FCLCompleted') && (loanInfoData?.remainingPurgedDays < 31) && (!loanInfoData?.isLoanPurged)) {
    return true;
  } else {
    return false;
  }
}

export const getChargedOffStatus = (loanInfoData) => {
  if (((loanInfoData?.liquidationStatus) && (loanInfoData?.liquidationType == 'ChargeOff') && (loanInfoData?.remainingPurgedDays > 30)) || ((loanInfoData?.isLoanPurged) && (loanInfoData?.liquidationType == 'ChargeOff'))) {
    return true;
  } else {
    return false;
  }
}

export const getChargedOffAccesDaysStatus = (loanInfoData) => {
  if ((loanInfoData?.liquidationStatus) && (loanInfoData?.liquidationType == 'ChargeOff') && (loanInfoData?.remainingPurgedDays < 31) && (!loanInfoData?.isLoanPurged)) {
    return true;
  } else {
    return false;
  }
}

export const getPayOffStatus = (loanInfoData) => {
  if (((loanInfoData?.liquidationStatus) && (loanInfoData?.liquidationType == 'PayOff') && (loanInfoData?.remainingPurgedDays > 30)) || ((loanInfoData?.isLoanPurged) && (loanInfoData?.liquidationType == 'PayOff'))) {
    return true;
  } else {
    return false;
  }
}

export const getPayOffAccesDaysStatus = (loanInfoData) => {
  if ((loanInfoData?.liquidationStatus) && (loanInfoData?.liquidationType == 'PayOff') && (loanInfoData?.remainingPurgedDays < 31) && (!loanInfoData?.isLoanPurged)) {
    return true;
  } else {
    return false;
  }
}

export const getServiceTransferStatus = (loanInfoData) => {
  if (((loanInfoData?.liquidationStatus) && (loanInfoData?.liquidationType == 'ServiceTransfer') && (loanInfoData?.remainingPurgedDays > 30)) || ((loanInfoData?.isLoanPurged) && (loanInfoData?.liquidationType == 'ServiceTransfer'))) {
    return true;
  } else {
    return false;
  }
}

export const getServiceAccessDaysStatus = (loanInfoData) => {
  if ((loanInfoData?.liquidationStatus) && (loanInfoData?.liquidationType == 'ServiceTransfer') && (loanInfoData?.remainingPurgedDays < 31) && (!loanInfoData?.isLoanPurged)) {
    return true;
  } else {
    return false;
  }
}

export const changesReflectStatus = (loanInfoData) => {
  if (((loanInfoData?.liquidationStatus) && (loanInfoData?.liquidationType == 'ServiceTransfer')) || ((loanInfoData?.liquidationStatus) && (loanInfoData?.liquidationType == 'PayOff')) || ((loanInfoData?.liquidationStatus) && (loanInfoData?.liquidationType == 'ChargeOff')) || ((loanInfoData?.liquidationStatus) && (loanInfoData?.liquidationType == 'FCLCompleted'))) {
    return true;
  }
  return false;
}
