import React from 'react';
import { useStyles } from './styles';
import { Box, Button, FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { saveUserTermandCondition } from '../../actions';
import { getAppSource, getPublicIp, getUserType, renderDefaultMsg } from '../../helpers';
import Cookies from 'js-cookie';

export const TermsConditions = () => {

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [errorMsg, setErrorMsg] = React.useState('')
  const [checkboxState, setCheckboxState] = React.useState(false);

  const handleClose = () => setOpen(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const privateLabel = window.location.pathname.split('/')[1];
  const url = privateLabel != null && privateLabel != undefined ? 'https://myloanweb.com/'+window.location.pathname.split('/')[1].toLowerCase()+'/Terms' : 'https://myloanweb.com/bsi/Terms';
  
  const checkboxChangeHandler = (event) => {
    setCheckboxState(event.target.checked)
  }

  const handleSubmit = async () => {
    const data = {
      "userName": "",
      "isTermAccept": true,
      "isActive": true,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": await getPublicIp(),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType()
    }
    const res = await saveUserTermandCondition(data);
    if (res) {
    const { lstResponseStatus } = res;
    if (res && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
     handleClose();
    } else {
      setErrorMsg('')
      setErrorMsg(lstResponseStatus[0].statusDesc)
     }
    } else {
      setErrorMsg(renderDefaultMsg('400'))
    }
  }

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={handleClose} open={open} disableEscapeKeyDown={true}>
      <Box className={classes.root}>
        <Box className={classes.cardBody}>
          <Box className={classes.statusContainer}>
          <p className={classes.errorMsg} dangerouslySetInnerHTML={{ __html: errorMsg !== '' ? errorMsg : '' }} />

            <p className={classes.statusTitle}>
            We’ve updated our Terms & Conditions with respect to electronic communications and text messaging. You can review these at <a target="_blank" href={url}>{url}</a>.</p>
           
           <p className={classes.statusDesc}>
             Continued use of MyLoanWeb following the update constitutes acceptance of our updated Terms & Conditions.
           </p>
            {/* <Box className={classes.checkboxContainer}>
                <FormGroup row>
                  <FormControlLabel
                    control={<Checkbox
                      checked={checkboxState}
                      onChange={(event) => checkboxChangeHandler(event)}
                      name="readTnC"
                    />}
                    label="I agree to the Terms and Conditions"
                    className={classes.inputStyle}
                    classes={{
                      label: checkboxState ? classes.activeRadioLabel : classes.radioLabel
                    }}
                  />
                </FormGroup>
              </Box> */}
          </Box>
        </Box>
        <Box className={classes.cardFooter}>
          <Box className={classes.footerItem}>
          </Box>
          <Box className={classes.footerItem}>
            <Button
              variant='contained'
              color='primary'
              className={classes.btn}
              // disabled={!checkboxState}
              onClick={handleSubmit}
            >Continue</Button>
          </Box>
        </Box>
      </Box>
   
    </Dialog>
  )
}