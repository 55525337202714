import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 750,
    borderRadius: 12,
    position: 'relative',

    backgroundColor: 'white',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)"
  },

  dialogStyle: {
    maxHeight: "100%",
    maxWidth:'90%',
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '20px',
        margin: '50px 15px'
      },
  },

  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  header: {
    fontFamily: "Montserrat",
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '12px 2em',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  radioText:{
    color:'#3D3D3D',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  header: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '12px 2em',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  iconContainer: {

  },

  title: {
    color: "#555D6C",
    fontFamily: "Montserrat",
    fontSize: '28px',
    fontWeight: "700",
    textAlign: 'center',
    padding: 14
  },
  radiogroup: {
    marginTop: '25px'
  },
  cardBody: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    // textAlign: 'center',
    padding: '1.80rem 5em',
    backgroundColor: '#ffffff',
   // boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },
 
  statusContainer: {
    marginBottom: 30,
  },

  statusTitle: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "500",
    marginBottom: 12,
    marginTop: 36,
  },

  statusBody: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "bold",
  },

  bodyText: {
    color: "#0C0B0B",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    marginBottom: 30,
    marginTop: 0,
  },

  linkStyle: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    fontWeight: "600",
    marginTop: 5,
  },

  btn: {
    width: 188,
    fontFamily: "Montserrat",
    fontSize: '1.5em',
    fontWeight: "600",
  },

  closeIcon: {
    position: 'absolute',
    right: 10,
  },

  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },

  statusSubTitle: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "500",
    marginBottom: 12,
    marginTop: 12,
  },

  statusSubTitles: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '1.7em',
    fontWeight: "500",
    marginBottom: 2,
    marginTop: 0,
  },

  inputStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600"
  },

  labelStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "400",
  },

  statusUpdateTinTitle: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "500",
    marginBottom: 12,
    marginTop: 36,
  },

  statusUpdateTinSubTitle: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "500",
    marginBottom: 12,
    marginTop: 26,
  },

  form: {
    marginLeft: 50,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 25
    },
  },

  cardFooter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: "#fff",
    padding: '20px 5.5em 0',
    // boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    },
  },

  footerText: {
    color: "#0C0B0B",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    fontWeight: "400",
  },

  linkStyle: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    fontWeight: "600",
    marginTop: 5,
  },

  footerItem: {
    marginBottom: 33,
    maxWidth: 175,
  },

  table: {
    overflowX: 'auto',
    overflowY: 'auto',
    '& .MuiTableContainer-root': {
      overflowX: 'auto',
      overflowY: 'auto'
    }
  },
  label: {
    color: '#3D3D3D',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    '&.value': {
      fontWeight: '700'
    }
  },
  errorText: {
    color: "red",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "600",
    letterSpacing: "0.31px",
    lineHeight: "18px",
    paddingLeft:'30px',
    background:'#ffffff'
  },
  link: {
    color: '#71A1BF',
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
    textDecorationLine: 'underline',
    cursor:'pointer'
  }

}));
