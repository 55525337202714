import React, { Component } from 'react'

export default class TermAndConditions extends Component {

    createMarkup = (str) => {
        return { __html: str };
    }

    render() {
        return (<div style={{
            color: 'rgba(0,0,0,0.74)', fontFamily: 'Montserrat',
            fontSize: '16px',
            letterSpacing: '0.5px',
            lineHeight: '20px',
        }}>
            <div
                style={{
                    padding: "10px",
                    fontFamily: 'Montserrat',
                    fontSize: '16px',
                    color: 'rgba(0,0,0,0.74)',
                    letterSpacing: '0.5px',
                    lineHeight: '20px',
                    fontWeight: '400'
                }}
                dangerouslySetInnerHTML={this.createMarkup(this.props.data)}
            /></div>
        )
    }
}