import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
// import './styles.css'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',  left: 0, right: 0, background: theme.palette.primary.main,
    width: '100%', zIndex: 2, width: '100%'
  },
  top: {
    top: 0
  },
  noTop: {
    top: 56
  },
  marquee: {
    margin: 0,
    fontWeight: 600,
    padding: 3,
    color: theme.palette.primary.contrastText,
    fontSize: 18,
    fontFamily: 'Montserrat',
    fontWeight: 600,
    lineHeight: '23px',
    letterSpacing: '0.18px'
  }
}));

export const MarqueeText = (props) => {
  const classes = useStyles();
  const mytheme = useSelector(state => state.theme);
  const { primaryColorCode } = mytheme;
 
  const stop = () => {
    document.getElementById("marquee").stop();
  }

  const start = () => {
    document.getElementById("marquee").start();
  }

  return (
    <div className={`${classes.root} ${props.alertMessage == null ? classes.top : classes.noTop}`}>
      <marquee className={classes.marquee} onMouseOver={() => stop()} onMouseOut={() => start()} id="marquee" dangerouslySetInnerHTML={{ __html: props.text }}></marquee>
    </div>
  );
}
// theme.palette.primary.main,