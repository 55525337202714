import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Box, CssBaseline, CircularProgress, Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { getNotifications, getAllNotifications, updateNotification } from '../../actions';
import Cookies from 'js-cookie';
import { getAppSource, getUserType, isAdminCheck, renderDefaultMsg } from '../../helpers';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CancelIcon from '@material-ui/icons/Cancel';
import moment from 'moment';

export const Notification = () => {

  const mytheme = useSelector(state => state.theme);
  const { secondaryColorCode } = mytheme;
  const [showDetail, setShowDetail] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [loader, setLoader] = useState(false)
  const [errorMes, setErrorMes] = useState('')
  const [nextBtnState, setNextBtnState] = useState(true);
  const [prevBtnState, setPrevBtnState] = useState(true);

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [data, setData] = useState([])
  const { nid } = useParams()

  useEffect(() => {
    getNotificationData()
  }, []);

  const getNotificationData = async () => {

    const requestData = {
      "isBellRequest": false,
      "userName": "",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType()
    }
    setLoader(true)
    setErrorMes('')
    const result = await getAllNotifications(requestData)
    if (result) {
      const { lstResponseStatus, notifications } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        setData(notifications)
        // viewDetail2(parseInt(nid), notifications)
        setLoader(false)
      } else {
        setData([])
        setLoader(false)
        setErrorMes("There is no notification to display.")
      }
    } else {
      setData([])
      setLoader(false)
      setErrorMes(renderDefaultMsg('400'))
    }
  }

  const updateNotificationItem = async (id) => {
    const requestData = {
      "notificationId": id,
      "isRead": isAdminCheck() ? false : true,
      "userName": "",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType()
    }
    setErrorMes('')
    const result = await updateNotification(requestData)

    if (result) {
      const { lstResponseStatus, notifications } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        dispatch(getNotifications()); // update header data
        getNotificationData() // refresh updated data 
      } else {
        setErrorMes(lstResponseStatus[0].statusDesc)
      }
    } else {
      setErrorMes(renderDefaultMsg('400'))
    }
  }

  const viewDetail2 = (id, data) => {
    if (id && data.length > 0) {
      let filtered = data.filter(item => item.notificationId === id)
      if (filtered.length > 0 && filtered[0]?.isRead === false) {
        updateNotificationItem(id)
      }
      setSelectedItem(filtered[0])
      setShowDetail(true)
    }
  }

  const viewDetail = (id) => {
    if (id) {
      history.push(`/Notification/${id}`)
      let filtered = data.filter(item => item.notificationId === id)
      if (filtered[0]?.isRead === false) {
        updateNotificationItem(id)
      }
      setSelectedItem(filtered[0])
      setShowDetail(true);
      //set timeout for scroll to selected notification
      setTimeout(()=>{
        const element = document.getElementById('notification-'+id);
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
      },3000)
     

      if (filtered[0].notificationId === data[0].notificationId) {
        setPrevBtnState(false)
        setNextBtnState(true)
      }
      if (filtered[0].notificationId === data[data.length - 1].notificationId) {
        setNextBtnState(false)
        setPrevBtnState(true)
      }
    }
  }

  const closeViewDetail = () => {
    setPrevBtnState(true)
    setNextBtnState(true)
    setSelectedItem({})
    setShowDetail(false)
    history.push('/Notification')
  }

  const nullCheck = (text) => text ? text : ''

  const handelPrev = () => {
    setNextBtnState(true);
    let index = data.indexOf(selectedItem);
    if (index > 0) {
      const { notificationId } = data[index - 1]
      history.push(`/Notification/${notificationId}`)
    }
    if (index === 1) {
      setPrevBtnState(false)
    }
  }

  const handelNext = () => {
    setPrevBtnState(true);
    let index = data.indexOf(selectedItem);
    if (index < data.length - 1) {
      const { notificationId } = data[index + 1]
      history.push(`/Notification/${notificationId}`)
    }
    if ((index + 1) === (data.length - 1)) {
      setNextBtnState(false)
    }
  }

  const shortText = (text) => {
    if (text !== '') {
      return text.length < 70 ? text : text.substr(0, 70) + '...'
    } else {
      return text
    }
  }

  const htmlToText = (str) => {
    return (<Typography dangerouslySetInnerHTML={{ __html: str }}></Typography>)
  }

  const dateFormat = (dateStr) => {
    const currentDate = new Date().toDateString()
    var utcDate = dateStr+'Z';
    const dt = new Date(utcDate)

    // if (currentDate === dt.toDateString()) {
      // const hours = dt.getHours()
      // const minutes = dt.getMinutes()
    //   return `${hours}:${minutes}`
    // } else {
      // return moment(dt).format("MMM Do") + ` ${hours}:${minutes}`
      const format1 = "MM/DD/YYYY HH:mm"
      return moment(dt).format(format1);
    // }    
  }

  const errorHandler = () => {
    if (errorMes !== '') {
      return (<Box align="center" py={5}>
        <Typography className={`${classes.textBold} errorMessage`}>{errorMes}</Typography>
      </Box>)
    } else if (loader) {
      return (<Box align="center" py={5}>
        <CircularProgress />
      </Box>)
    }
  }

  if (nid !== '' && nid!==NaN && data && data.length > 0) {
    if (selectedItem && selectedItem.notificationId != nid) {
      viewDetail(parseInt(nid))
    }
  }

  return (
    <CssBaseline>
      <Box  className={classes.root}>
        <Box className={classes.container}>
          <Box py={2}>
            <Typography className={classes.title}>Message Center</Typography>
          </Box>
          <Grid container direction="row"  >
            <Grid item md>
              <Box  className={`${classes.formContainer} ${showDetail?'scroll':''}`}>
                {data.length !== 0 && loader === false ? (<Box className={classes.borderBottom}></Box>) : null}
                {data.length !== 0 && loader === false ? data.map((row, i) => (<Fragment key={i}>

                  <Box p={2} key={i} id={`${`notification-`+row.notificationId}`}  className={`${classes.borderBottom} ${row?.isRead ? '' : classes.unread} ${row.notificationId === selectedItem.notificationId ? classes.activeRow : ''}`} >
                    <Grid container direction="row" justify="center" alignItems="center" >
                      <Grid item md>
                        <Typography style={{ float: "left" }}
                          className={`${classes.textBold} ${classes.link} ${row.notificationId === selectedItem.notificationId ? classes.active : ''}`}
                          onClick={() => viewDetail(row.notificationId)}>{row.notificationTitle}</Typography>
                        {showDetail ? (<span className={`${classes.textBold} ${classes.alignRight}`} style={{ float: "right" }}>{dateFormat(row.createdDate)}</span>) : null}

                      </Grid>
                      {!showDetail ? (<Grid item md={7} className={`${classes.shortMessage} ${classes.link} ${classes.hideInMobile}`} onClick={() => viewDetail(row.notificationId)}>{shortText(row.shortNotificationBody)}</Grid>) : null}
                      {!showDetail ? (<Grid item className={`${classes.textBold} ${classes.alignRight}`} md={1}>{dateFormat(row.createdDate)}</Grid>) : null}
                    </Grid>
                  </Box>
                  {/* Detail Mobile View start */}
                  <Box className={classes.showInMobile}>
                    <Box className={`${row.notificationId === selectedItem.notificationId ? '' : classes.inActiveMobile}`}>
                      <Box height={"100%"} className={`${classes.detailsContainer}`}>
                        <Box pb={2}>
                          {htmlToText(selectedItem.notificationBody)}
                        </Box>
                        <Box height={"100%"}>
                          <Grid container direction="row" justify="center" alignItems="flex-end" >
                            <Grid item>
                              {prevBtnState && <Typography className={classes.prevBtn} component={"span"} style={{ color: secondaryColorCode }} color="primary" onClick={() => handelPrev()}><ArrowLeftIcon /> Prev</Typography>}

                              {nextBtnState && <Typography className={classes.nextBtn} component={"span"} style={{ color: secondaryColorCode }} color="primary" onClick={() => handelNext()}>Next <ArrowRightIcon /></Typography>}
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Box></Box>
                  {/* Detail Mobile End */}

                </Fragment>)) : errorHandler()}
              </Box>
            </Grid>

            {loader === false && showDetail ? (
              <Grid className={classes.hideInMobile} item md={7}>
                <Box height={"100%"} className={`${classes.detailsContainer}`}>
                  <Box height={"90%"} style={{overflow:'auto'}}>
                    <Typography align="right">
                      <CancelIcon onClick={() => closeViewDetail()} className={classes.closeBtn} />
                    </Typography>
                    <Typography className={classes.bodyTitle} >{nullCheck(selectedItem.notificationTitle)}</Typography>
                    <Box py={4}>
                      {htmlToText(selectedItem.notificationBody)}
                    </Box>
                  </Box>

                  <Box height={"10%"} display="flex">
                    <Grid container direction="row" justify="center" alignItems="flex-end" >
                      {prevBtnState && <Grid item>
                        <Box display="flex" className={classes.prevBtn} style={{ color: secondaryColorCode }} onClick={() => handelPrev()} >
                          <Typography component={"span"} ><ArrowLeftIcon className={classes.secondaryColorCode} /> </Typography>
                          <Typography component={"span"} className={classes.nextPrevArrow}> Prev</Typography>
                        </Box>
                      </Grid>}
                      {nextBtnState && <Grid item>
                        <Box display="flex" className={classes.nextBtn}
                          style={{ color: secondaryColorCode }}
                          onClick={() => handelNext()}
                        >
                          <Typography component={"span"} className={classes.nextPrevArrow}>Next</Typography>
                          <Typography component={"span"} ><ArrowRightIcon className={classes.secondaryColorCode} /></Typography>
                        </Box>
                      </Grid>}
                    </Grid>
                  </Box>
                </Box>
              </Grid>) : null}
          </Grid>
        </Box>
      </Box>
    </CssBaseline>
  )
}