import React, { Component, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import { Box, Grid } from '@material-ui/core';
import { useStyles } from './styles';

const NO_RECORD = "No Record Found"

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#535D6D",
    color: "#ffffff",
    fontSize: "15px",
    fontWeight: "bold",
    letterSpacing: "0.36px",
    lineHeight: "19px",
    fontFamily: "Montserrat",
    borderRight: "1px solid #ffffff",
    verticalAlign: "top"
  },
  body: {
    fontSize: 16,
    color: "#202020",
    letterSpacing: "0.36px",
    lineHeight: "19px",
    fontFamily: "Montserrat"
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const nullCheck = (str) => {
  if (str) {
    return str
  } else {
    return ''
  }
}

const nullCheckBuydown = (str) => {
  if (str !== null) {
    return str
  } else {
    return '$0.00'
  }
}

const CustomTable = (props) => {
  
  const classes = useStyles();
  const rows = props.data;
  const filterBuyDown = rows.filter((item) => ((item?.buydown !== null) && (item?.buydown !== '$0.00')))
  const [page, setPage] = React.useState(1);
  const [countPerPage, setCountPerPage] = useState((props.page == 'payment') ? 5  : 10);
  const totalPages = Math.ceil(rows.length / ((props.page == 'payment') ? 5  : 10));

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
    <div className={classes.root}>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell rowSpan={2} align="left">Date</StyledTableCell>
            <StyledTableCell rowSpan={2} align="left">Description</StyledTableCell>
            <StyledTableCell colSpan={filterBuyDown?.length > 0 ? 7 : 6} align="center">Payment Description</StyledTableCell>
            <StyledTableCell rowSpan={2} align="left">Transaction Amount</StyledTableCell>
          </TableRow>

          <TableRow>
            <StyledTableCell align="left">Principal</StyledTableCell>
            <StyledTableCell align="left">Interest</StyledTableCell>
            <StyledTableCell align="left">Escrow</StyledTableCell>
            <StyledTableCell align="left">Fees</StyledTableCell>
            <StyledTableCell align="left">Late Charges</StyledTableCell>
            <StyledTableCell align="left">Unapplied</StyledTableCell>
            {(filterBuyDown?.length > 0) && <StyledTableCell align="left">Buydown</StyledTableCell>}
          </TableRow>
        </TableHead>
        <TableBody>

          {rows.length > 0 ? (rows.slice((page - 1) * countPerPage, page * countPerPage)?.map((row, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell component="th" scope="row">{row.transPostingDate}</StyledTableCell>
              <StyledTableCell align="left">{nullCheck(row.transDesc)}</StyledTableCell>
              <StyledTableCell align="left">{nullCheck(row.transPrincipalAmount)}</StyledTableCell>
              <StyledTableCell align="left">{nullCheck(row.transIntrestAmount)}</StyledTableCell>
              <StyledTableCell align="left">{nullCheck(row.transEscrowAmount)}</StyledTableCell>
              <StyledTableCell align="left">{nullCheck(row.transFeesAmount)}</StyledTableCell>
              <StyledTableCell align="left">{nullCheck(row.transLateChargeAmount)}</StyledTableCell>
              <StyledTableCell align="left">{nullCheck(row.unappliedAmount)}</StyledTableCell>
              {(filterBuyDown?.length > 0) && <StyledTableCell align="left">{nullCheckBuydown(row?.buydown)}</StyledTableCell>}
              <StyledTableCell align="left">{nullCheck(row.transAmount)}</StyledTableCell>
            </StyledTableRow>
          ))) : (<StyledTableRow><StyledTableCell colSpan={9} align="center">{NO_RECORD}</StyledTableCell></StyledTableRow>)}
         
        </TableBody>
      </Table>
    </TableContainer>
       
      { (rows.length > 0) &&
       <Grid container>
            <Grid item md={8} xs={12} lg={8}>
              <Box className={classes.warningBox}>
              </Box>
            </Grid>

            <Grid item md={4} xs={12} lg={4}>
              <Box style={{float: 'right', paddingTop: 8}}>
                <Pagination count={totalPages} page={page} onChange={handleChange} />
              </Box>
            </Grid>
        </Grid>
      }
      </div>
       </>   
  );
}

export default class CustomizedTables extends Component {

  render() {
    return (<CustomTable {...this.props}></CustomTable>)
  }

}