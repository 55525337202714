import React, { useEffect, useState } from 'react';
import {
  Box, Grid, CircularProgress, TableContainer, Paper, Table, TableBody, TableHead, TableCell,
  TableRow, Button, Typography, TextField, MenuItem, FormControl, Select, Checkbox, FormControlLabel
} from "@material-ui/core";
import { useStyles } from './styles';
import { withStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { getAppSource, getUserType, renderDefaultMsg } from '../../../../helpers';
import { encrypt, decrypt } from './../../../../helpers/encrypt';
import {
  updateInvestorcodelabel, getSuperAdminPrivatelabel,
  getSuperAdmininsertInvestorCode, getinvestorcodeLabel
} from './../../../../actions';
import Cookies from 'js-cookie';

export const Mapinvestor = (props) => {

  const classes = useStyles();
  const [labelvalue, setlabelvalue] = React.useState('');
  const [dataError, setDataError] = useState('');
  const [message, setmessage] = useState('');
  const [dataLoader, setDataLoader] = useState(false);
  const [investorerror, setinvestorerror] = useState(false);
  const [privatelabels, setprivatelabels] = useState([]);
  const [activitylog, setactivitylog] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [values, setValues] = useState({
    investorCode: '',
    isVA: false
  });

  const [tableDataError, setTableDataError] = useState('');
  const [loader, setLoader] = useState(false);
  const [pageList, setPageList] = useState([]);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [submitErrorMsg, setSubmitErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  const ITEM_PER_PAGE = 5;

  useEffect(() => {
    getSuperAdminPrivatelabeldata();
  }, []);

  const getSuperAdminPrivatelabeldata = async (e) => {
    setDataLoader(true);
    setValues({ investorCode: '' })
    setlabelvalue('');
    setSubmitErrorMsg('');
    const response = await getSuperAdminPrivatelabel();
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
      await setlabelvalue(response.privateLabelList[0].privateLabelCode);
      await setDataError('');
      await setDataLoader(false);
      await setprivatelabels(response.privateLabelList);

    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
      setDataLoader(false);
      setDataError(response.lstResponseStatus[0].statusDesc);
    } else {
      setDataLoader(false);
      setDataError(renderDefaultMsg('400'));
    }
  }

  useEffect(() => {
    getInvestorCodeData()
  }, [privatelabels])

  const getInvestorCodeData = async (label) => {
    setLoader(true);
    setTableDataError('');
    setactivitylog([]);
    setPageList([]);
    setSubmitErrorMsg('');
    setSuccessMsg('');
    const dataa = {
      "investorCode": '',
      "privateLabelCode": labelvalue ? labelvalue : '',
      "pageIndex": 0,
      "pageSize": 60,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
    }
    const response = await getinvestorcodeLabel(dataa);
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode == '0') {
      setTableDataError('');
      setLoader(false);
      setactivitylog(response.lstInvestorCode);

      if (response.lstInvestorCode.length > ITEM_PER_PAGE) {
        setPageList(response.lstInvestorCode.slice(0, ITEM_PER_PAGE))
      } else {
        setPageList(response.lstInvestorCode)
      }

    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
      setTableDataError(response.lstResponseStatus[0].statusDesc);
      setLoader(false);
    } else {
      setTableDataError(renderDefaultMsg('400'));
      setLoader(false);
    }
  }

  const submitDataHandler = async () => {
    if (values.investorCode !== '') {
      setinvestorerror(false)
      getSuperAdmininsertInvestorCodedata()
    }
    else {
      setinvestorerror(true)
    }
  }

  const handleChangeVALoan = (event) => {
    setValues({ ...values, isVA: event.target.checked })
  }

  const getSuperAdmininsertInvestorCodedata = async () => {
    setSubmitLoader(true);
    setTableDataError('');
    setSubmitErrorMsg('');
    setSuccessMsg('');
    const labelData = {
      investorCode: encrypt(values.investorCode),
      isVA: values.isVA,
      privateLabelCode: labelvalue ? labelvalue : ''

    }
    const response = await getSuperAdmininsertInvestorCode(labelData);
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
      setDataError('')
      setSubmitLoader(false);
      setSuccessMsg(response.lstResponseStatus[0].statusDesc)
      setValues({ investorCode: '', isVA: false })
    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
      setSubmitLoader(false);
      setSubmitErrorMsg(response.lstResponseStatus[0].statusDesc);
    } else {
      setSubmitLoader(false);
      setSubmitErrorMsg(renderDefaultMsg('400'));
    }
  }

  const textChangeHandler = (prop) => (event) => {
    setinvestorerror(false)
    if ((/^[0-9]+$/).test(event.target.value) || event.target.value === '') {
      setValues({ ...values, [prop]: event.target.value.slice(0, 10) });
    }
  };

  const goBack = () => {
    props.clickBack();// can pass callback data here
  }

  const cancelBtnHandler = () => {
    props.clickBack();// can pass callback data here
    props.closeDialog()
  }

  const handleChange = async (event) => {
    setlabelvalue(event.target.value);
  };

  const changePage = (event, page) => {
    let startIndex = ITEM_PER_PAGE * (page - 1)
    let endIndex = (ITEM_PER_PAGE * page)
    let newUseList = activitylog.slice(startIndex, endIndex)
    setPageList(newUseList)
    setCurrentPage(page)
  }

  if (dataLoader) {
    return (
      <Box className={classes.loaderRoot}>
        <CircularProgress />
      </Box>
    )
  }
  if (dataError !== '') {
    return (
      <Box className={classes.dataErrorMsg}>
        {dataError}
      </Box>
    )
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={10}>
          <div className={classes.firstareaa_1}>
            <div className={classes.inerr_1_p}>
              <Typography className={classes.text_part}>Company Name</Typography>
            </div>
            <div className={classes.inerr_1_p_1}>
              <div className={classes.fullarea}>
                <div className={classes.full_1}>
                  <FormControl variant="outlined" className={classes.formControl} >
                    <Select
                      size="small"
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={labelvalue}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{
                        className: classes.inputStyle,
                        'aria-label': 'Without label',
                        form: {
                          autocomplete: 'off',
                        },
                      }}
                      InputLabelProps={{
                        classes: { root: classes.textField }
                      }}
                    >
                      {privatelabels.map(item => (
                        <MenuItem key={item.privateLabelCode} value={item.privateLabelCode}>
                          {item.privateLabelName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className={classes.full_2}>
                  <Button
                    onClick={getInvestorCodeData}
                    type='submit'
                    className={classes.searchbtn}
                    size="large" variant="contained" color="primary" style={{ marginBottom: '15px' }}>
                    Get Investor Code List
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.firstareaa_1} >
            <div className={classes.inerr_1_p}>
              <Typography className={classes.text_part} style={{ paddingTop: '20px' }}>Investor Code</Typography>
            </div>
            <div style={{ position: "absolute", paddingTop: '30px', marginTop: "35px" }}>
              <Typography className={classes.text_part}>Restricted Client?</Typography>
            </div>
            <div className={classes.inerr_1_p_1} style={{ padding: '8px', width: '29%' }}>
              <TextField
                size="small"
                color="primary"
                autoComplete="off"
                id="outlined-loanNumber"
                variant="outlined"
                type="text"
                value={values.investorCode}
                onChange={textChangeHandler('investorCode')}
                fullWidth
                inputProps={{
                  className: classes.inputStyle_1,
                  maxLength: 10,
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
              {investorerror && <Typography color="error" style={{ fontSize: '11px' }}>Please enter investorcode</Typography>}
              {submitErrorMsg != '' && <p className={classes.errMessage}>{submitErrorMsg}</p>}
              {successMsg !== '' && <p className={classes.successMsg}>{successMsg}</p>}

              <div className={classes.holder} style={{ marginTop: '12px' }}>
                <Checkbox
                  onChange={(e) => handleChangeVALoan(e)}
                />
              </div>
              <div className={classes.holder} style={{ marginTop: '12px' }}>
                <div className={classes.buttonContainer}>
                  <Button
                    disabled={submitLoader}
                    onClick={submitDataHandler}
                    type='submit'
                    className={classes.searchbtn}
                    size="large" variant="contained" color="primary">
                    {submitLoader ? <CircularProgress className={classes.disableBtnLoader} /> : 'Submit'}
                  </Button>

                  <Button onClick={cancelBtnHandler}
                    type='submit'
                    className={classes.searchbtn}
                    size="large" variant="contained" color="primary">
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} md={2} style={{ textAlign: 'center', paddingTop: '6px' }}>
          <Button onClick={cancelBtnHandler} type='submit' className={classes.searchbtn} size="large" variant="contained" color="primary">
            goback
        </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          {message && <Typography style={{ textAlign: 'center' }} className={classes.success}>{message}</Typography>}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.firstarea}>
            <Box>
              <TableContainer component={Paper}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Company Name</StyledTableCell>
                      <StyledTableCell align="center">Investor Code</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!loader ? pageList.length ? pageList.map((row, i) => (<StyledTableRow key={i}>
                      <StyledTableCell align="left" >{row.privateLabelName}</StyledTableCell>
                      <StyledTableCell align="center" >{decrypt(row.investorCode)} </StyledTableCell>

                    </StyledTableRow>)) : <StyledTableRow>
                      <StyledTableCell colSpan={8}>
                        <p className={classes.noDataFoundText}>No data found</p>
                      </StyledTableCell>
                    </StyledTableRow>
                      : <StyledTableRow>
                        <StyledTableCell colSpan={8}>
                          <CircularProgress className={classes.dataLoaderCir} />
                        </StyledTableCell>
                      </StyledTableRow>}

                    {tableDataError !== '' && <StyledTableRow>
                      <StyledTableCell colSpan={8}>
                        <p className={classes.noDataFoundText}>{tableDataError}</p>
                      </StyledTableCell>
                    </StyledTableRow>}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            {activitylog.length > ITEM_PER_PAGE ? (
              <Box textAlign="center" p={2}>
                <Pagination
                  count={Math.ceil(activitylog.length / ITEM_PER_PAGE)}
                  onChange={(e, cPage) => changePage(e, cPage)}
                  color="primary"
                  variant="text"
                  shape="rounded" />
              </Box>) : null}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

const StyledTableCell = withStyles((theme) => ({
  root: {
    fontFamily: "Montserrat",
  },
  head: {
    backgroundColor: "#535D6D",
    color: "#ffffff",
    fontSize: "14px",
    fontWeight: "bold",
    letterSpacing: "0.36px",
    lineHeight: "17px",
    fontFamily: "Montserrat",
    borderRight: "1px solid #ffffff",
    verticalAlign: "top",
    padding: "10px"
  },
  body: {
    fontSize: 14,
    color: "#202020",
    letterSpacing: "0.36px",
    lineHeight: "17px",
    fontFamily: "Montserrat"
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);