import React, { Fragment, useEffect, useState } from 'react';
import { Box, TextField, Typography, Checkbox, FormControlLabel, Button, CircularProgress } from '@material-ui/core';
import { NextPrevButton } from '../NextPrevButton'
import { useStyles } from './styles';
import { SaveAndExitPopup } from '../SaveAndExitPopup';
import { useHistory } from 'react-router-dom';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EmailIcon from '@material-ui/icons/Email';
import GetAppIcon from '@material-ui/icons/GetApp';
import { scrollTop, renderDefaultMsg } from '../../../helpers';
import { getPdf } from '../../../actions';

import { useSelector, useDispatch } from 'react-redux';

export const SectionComplete = (props) => {

  //const {parentStyle} = props
  const classes = useStyles();
  const history = useHistory();
  const selectedAccount = useSelector(state => state.selectedAccount);
  const [downoadLoader,setDownoadLoader ] = useState(false)
  const [errorMessage,setErrorMessage] =useState('')

  const getContentData = async () => {
    setDownoadLoader(true);
    // var acn = encrypt('6787054581');
    // var accnt = "W3gd+B4rY/gwvA+9iHtiYg=="
    const response = await getPdf(selectedAccount);

    if (response) {
      setDownoadLoader(false);
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', selectedAccount + '_LMA.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
    else {
      setDownoadLoader(false);
      setErrorMessage(renderDefaultMsg('400'))
    }
  };





  return (
    <Box className={classes.root}>
      {/* <Box style={{ marginTop: "-85px" }}>
        <SaveAndExitPopup actionCallBack={saveAndExitAction}></SaveAndExitPopup>
      </Box> */}
      <Box>
        <Typography className={classes.title}>Loss Mitigation Application: Complete</Typography>
        <Box py={1}>
          <Typography className={classes.acknowledgementText}>You have successfully completed the Loss Mitigation Application. To review your application simply click on the section on the left which you would like to review.</Typography>
        </Box>
        <Box py={1}>
          <Typography className={classes.acknowledgementText}>If you would like to submit this application, you will be required to download and print the application from the link below, sign the application wherever necessary and mail the form to us.</Typography>
        </Box>
        

        <Box my={3} p={2} className={classes.downloadBox}>
          <Box className={classes.flexBox}>
            <Box ml={6}>
              <Typography className={classes.downloadTitle}>Loss Mitigation Application</Typography>
              <Box display={"flex"}>
                <Box pl={1} pr={2}>
                  <img width="53" src="/static/media/pdf_icon.3a6f9788.svg" title="PDF" alt="PFD" />
                </Box>
                <Typography className={classes.smallText}>File Format: PDF</Typography>
              </Box>
            </Box>
            <Box pt={6}>  
              {!downoadLoader ?(<Button             
                onClick={getContentData}                
                variant="contained"
                color="primary"
                className={classes.downloadd}
                startIcon={<GetAppIcon />}>
                  DOWNLOAD FORM
                </Button>):(<Button variant="contained" color="primary" className={classes.downloadd}>
                 <CircularProgress color='primary' style={{ width: 25, height: 25 }} />
                </Button>)}
            </Box>
          </Box>
          {errorMessage !== '' ? (<p className={classes.errorMsg}> {errorMessage}</p>) : null}
        </Box>
        <Typography className={classes.acknowledgementText}>You can send the form to the following:</Typography>
        <Box pt={1} className={classes.contactBoxFlex}>
          <Box className={`${classes.addressBox} ${classes.contactBoxFlex2}`}>
            <Box pr={2}><LocationOnIcon /></Box>
            <Box>
              <Typography className={classes.acknowledgementText}>BSI Financial Services</Typography>
              <Typography className={classes.acknowledgementText}>P.O. Box 517</Typography>
              <Typography className={classes.acknowledgementText}>Titusville, PA 16354-0517</Typography>
            </Box>
          </Box>
          <Box className={classes.contactBoxFlex2}>
            <Box pr={2}><EmailIcon /></Box>
            <Box>
              <Typography className={classes.acknowledgementText}>Mail us at <a href="mailto:CustomerCare@bsifinancial.com">CustomerCare@bsifinancial.com</a> </Typography>
            </Box>
          </Box>
        </Box>
      </Box>


    </Box>
  )
}