import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import Body from './Body';

const formStyles = {
  position: 'fixed',
  backgroundColor: '#4D4D4D',
  border: '1px solid #4D4D4D',
  borderRadius: '6px 6px 0 0',
  zIndex: '50000',
  bottom: '0px',
  right: '8%',
  width: '324px'
};

class Form extends Component {
  render() {
    const {
      headerStyles,
      headerText,
      position,
      handleClose,
      handleSubmit,
      handleMessageInput,
      handleEmailInput,
      handleRatingInput,
      handleCustomPosition,
      messageInput,
      emailInput,
      ratingInput,
      showEmailInput,
      showMessageInput,
      showRatingInput,
      numberOfStars,
      bodyText,
      handleCloseWithoutSave,
      handleCloseWithSave,
      requestSubmit
    } = this.props;

    var customFormStyles = handleCustomPosition(position, formStyles);
    return (
      <div style={{ ...customFormStyles, ...this.props.style }}>
        { !requestSubmit &&
        <Header
          styles={headerStyles}
          // headerText={headerText}
          handleCloseWithoutSave={handleCloseWithoutSave}
          handleCloseWithSave={handleCloseWithSave}
          // handleClose={handleClose}
        />
          }
        <Body
          bodyText={bodyText}
          numberOfStars={numberOfStars}
          showEmailInput={showEmailInput}
          showMessageInput={showMessageInput}
          showRatingInput={showRatingInput}
          handleMessageInput={handleMessageInput}
          handleEmailInput={handleEmailInput}
          handleRatingInput={handleRatingInput}
          messageInput={messageInput}
          emailInput={emailInput}
          ratingInput={ratingInput}
          handleSubmit={handleSubmit}
          requestSubmit={requestSubmit}
          handleCloseWithoutSave={handleCloseWithoutSave}
        />
        {/* <Footer handleSubmit={handleSubmit} handleClose={handleClose} /> */}
      </div>
    );
  }
}

export default Form;
