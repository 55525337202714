import React, { Fragment, useEffect, useState } from 'react';
import {
  Box, TextField, FormControl,
  FormLabel, RadioGroup, FormControlLabel, Radio, Button, Typography, Checkbox, TextareaAutosize
} from '@material-ui/core';
import { NextPrevButton } from '../NextPrevButton'
import { useStyles } from './styles';
import { SaveAndExitPopup } from '../SaveAndExitPopup';
import { useHistory } from 'react-router-dom';
import { checkDigitOnly, checkDigitWithDecimal, checkPhoneLength, scrollTop } from '../../../helpers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';


export const SectionG = (props) => {
  const { parentStyle } = props
  const classes = useStyles();
  const history = useHistory();

  const nullCheck = (str) => str ? str : ''
  const [sectionG, setSectionG] = useState({
    isUpdated: false,
    isApplicableSectionG: "",
    question1: "",
    question2: "",
    question3: "",
    q3PropertyAddress: "",
    q3CurrentValue: "",
    q3LoanNumber: "",
    q3MonthlyPayment: "",

    question4: "",
    q4ServicerName: "",
    q4LoanNumber: "",
    q4MonthlyPayment: "",

    question5: "",
    q5MonthlyFee: "",
    question6: "", question7: "", question8: "", question9: "", question10: "",
    question11: "",
    question12: "", question12OtherText: "",
    question13: "",
    question14: "", question15: "", question16: "", question17: "",
    question18: "",
    question19: "",
    question20: "",
    question21: "",
    question22: "",
    question23: "",

    isRPCCheckbox1: false,
    isRPCCheckbox2: false
  })

  const {
    isApplicableSectionG,
    question1, question2, question3,
    q3PropertyAddress,
    q3CurrentValue,
    q3LoanNumber,
    q3MonthlyPayment,

    question4,
    q4ServicerName,
    q4LoanNumber,
    q4MonthlyPayment,

    question5,
    q5MonthlyFee,
    question6, question7, question8, question9, question10,
    question11,
    question12, question12OtherText,
    question13,
    question14,
    question15,
    question16,
    question17,
    question18,
    question19,
    question20,
    question21,
    question22,
    question23,
    isRPCCheckbox1,
    isRPCCheckbox2

  } = sectionG

  useEffect(() => {
    if (props.data) {
      const { isApplicableSectionG, isRPCCheckbox1, isRPCCheckbox2, questions } = props.data
      const {
        question1, question2,
        question3,//q3PropertyAddress, q3CurrentValue, q3LoanNumber,q3MonthlyPayment,    
        question4,//q4ServicerName, q4LoanNumber, q4MonthlyPayment,    
        question5,//q5MonthlyFee,
        question6, question7, question8, question9, question10,
        question11, question12, question13, question14, question15, question16, question17,
        question18, question19, question20, question21, question22, question23,
      } = questions

      setSectionG({
        isApplicableSectionG: nullCheck(isApplicableSectionG),
        question1: nullCheck(question1.answer),
        question2: nullCheck(question2.answer),
        question3: nullCheck(question3.answer),
        q3PropertyAddress: nullCheck(question3.q3PropertyAddress),
        q3CurrentValue: nullCheck(question3.q3CurrentValue),
        q3LoanNumber: nullCheck(question3.q3LoanNumber),
        q3MonthlyPayment: nullCheck(question3.q3MonthlyPayment),

        question4: nullCheck(question4.answer),
        q4ServicerName: nullCheck(question4.q4ServicerName),
        q4LoanNumber: nullCheck(question4.q4LoanNumber),
        q4MonthlyPayment: nullCheck(question4.q4MonthlyPayment),

        question5: nullCheck(question5.answer),
        q5MonthlyFee: nullCheck(question5.q5MonthlyFee),
        question6: nullCheck(question6.answer),
        question7: nullCheck(question7.answer),
        question8: nullCheck(question8.answer),
        question9: nullCheck(question9.answer),
        question10: nullCheck(question10.answer),
        question11: nullCheck(question11.answer),
        question12: nullCheck(question12.answer),
        question12OtherText: nullCheck(question12.otherText),
        question13: nullCheck(question13.answer),
        question14: nullCheck(question14.answer),
        question15: nullCheck(question15.answer),
        question16: nullCheck(question16.answer),
        question17: nullCheck(question17.answer),
        question18: nullCheck(question18.answer),
        question19: nullCheck(question19.answer),
        question20: nullCheck(question20.answer),
        question21: nullCheck(question21.answer),
        question22: nullCheck(question22.answer),
        question23: nullCheck(question23.answer),
        isRPCCheckbox1: checkBoxStatus(isRPCCheckbox1),
        isRPCCheckbox2: checkBoxStatus(isRPCCheckbox2),
        isUpdated: true,
      })
    }
  }, [props.data])

  useEffect(() => {
    if (sectionG.isUpdated) {
      let formErrors = formValidationSectionG()
      setFormErrorMap(formErrors)
    }
  }, [sectionG.isUpdated])

  const handleChange = (event) => {
    let numberFieldList = ["q3LoanNumber", "q4LoanNumber", "question19"]
    let amountFieldList = ["q3CurrentValue", "q3MonthlyPayment", "q4MonthlyPayment", "q5MonthlyFee", "question14", "question22"]

    if (numberFieldList.indexOf(event.target.name) > -1) {
      if (checkDigitOnly(event.target.value) === false) {
        return false
      }
    }
    
    if (amountFieldList.indexOf(event.target.name) > -1) {
      if (checkDigitWithDecimal(event.target.value) === false) {
        return false
      }
    }
    if (["isRPCCheckbox1", "isRPCCheckbox2"].indexOf(event.target.name) > -1) {
      setSectionG({ ...sectionG, [event.target.name]: event.target.checked });
    } else {
      setSectionG({ ...sectionG, [event.target.name]: event.target.value });
    }
  };
  const handleDateChange = (inputName) => (date) => {
    setSectionG({ ...sectionG, [inputName]: date });
  }

  const handleSave = (e) => {
    //e.preventDefault();
    let formErrors = formValidationSectionG()
    let formErrorLength = Object.keys(formErrors).length
    let sectionCompleteStatus = formErrorLength > 0 ? "incomplete" : "complete"
    props.status({ id: 6, sectionName: "section_G", status: sectionCompleteStatus })

    const data = {
      "section_G": {
        isApplicableSectionG: isApplicableSectionG,
        isRPCCheckbox1: isRPCCheckbox1,
        isRPCCheckbox2: isRPCCheckbox2,
        "questions": {
          "question1": {
            "questionText": "I am requesting mortgage assistance for a rental property",
            "answer": question1
          },
          "question2": {
            "questionText": "I am requesting mortgage assistance for a second or seasonal home",
            "answer": question2
          },
          "question3": {
            "questionText": "I am requesting mortgage assistance for a home that is no longer my primary residence due to an out of area job transfer or foreign service assignment. I intend tooccupy this property as my primary residence some time in the future",
            "answer": question3,
            "q3PropertyAddress": q3PropertyAddress,
            "q3CurrentValue": q3CurrentValue,
            "q3LoanNumber": q3LoanNumber,
            "q3MonthlyPayment": q3MonthlyPayment,
          },
          "question4": {
            "questionText": "Do you have a second mortgage on the property?",
            "answer": question4,
            "q4ServicerName": q4ServicerName,
            "q4LoanNumber": q4LoanNumber,
            "q4MonthlyPayment": q4MonthlyPayment,
          },
          "question5": {
            "questionText": "Do you have condominium or home owners association(HOA)fees?",
            "answer": question5,
            "q5MonthlyFee": q5MonthlyFee
          },
          "question6": {
            "questionText": "Are HOA fees paid current?",
            "answer": question6
          },
          "question7": {
            "questionText": "Name/address that fees are paid to",
            "answer": question7
          },
          "question8": {
            "questionText": "Does your mortgage payment include taxes and insurance?",
            "answer": question8
          },
          "question9": {
            "questionText": 'If "No",are the taxes and insurance paid current?',
            "answer": question9
          },
          "question10": {
            "questionText": "Annual home owners insurance",
            "answer": question10
          },
          "question11": {
            "questionText": "Annual Property Taxes",
            "answer": question11
          },
          "question12": {
            "questionText": "If requesting assistance for a rental property, property is currently",
            "answer": question12,
            "otherText": question12 === "Other" ? question12OtherText : ''
          },
          "question13": {
            "questionText": "If rental propertyis occupied by tenant Term of lease/occupancy",
            "answer": question13,
          },
          "question14": {
            "questionText": "Gross Monthly Rent",
            "answer": question14
          },
          "question15": {
            "questionText": "If rental property is vacant, describe efforts to rent property",
            "answer": question15
          },
          "question16": {
            "questionText": "If you have a non-rent-paying occupant,describe your relationship to them and the duration of their occupancy",
            "answer": question16
          },
          "question17": {
            "questionText": "Is the property for sale?",
            "answer": question17
          },
          "question18": {
            "questionText": "Listing Agent's Name",
            "answer": question18
          },
          "question19": {
            "questionText": "Phone Number",
            "answer": question19
          },
          "question20": {
            "questionText": "List Date",
            "answer": question20
          },
          "question21": {
            "questionText": "Have you received a purchase offer?",
            "answer": question21
          },
          "question22": {
            "questionText": "Amount of Offer",
            "answer": question22
          },
          "question23": {
            "questionText": "Closing Date",
            "answer": question23
          },
        },
        "sectionStatus": {
          "status": sectionCompleteStatus,
          "sectionName": "Section G:Other Property For Which Assistance Is Requested"
        },

      }
    }
    console.log("===section G Data===", data)
    props.saveFromParent(data)
  }

  const saveAndExitAction = (action) => {
    if (action === "leave") {
      handleSave()
      history.push('/Dashboard')
    }
  }

  const checkBoxStatus = (status) => {
    if (status === "true" || status === true) {
      return true
    } else {
      return false
    }
  }
  /*======Next Prev Work section wise=====*/
  const subSteps = ["step1", "RentalProperty"]
  const subStepsLength = subSteps.length - 1
  const [activeSubStep, setActiveSubStep] = useState(0);
  const nextSubStep = () => {
    if (activeSubStep < subStepsLength) {
      setActiveSubStep(activeSubStep + 1)
      scrollTop()
    } else {
      // go to next
      handleSave()
    }
  }
  const backSubStep = () => {
    if (activeSubStep !== 0 && activeSubStep <= subStepsLength) {
      setActiveSubStep(activeSubStep - 1)
    } else if (activeSubStep === 0) {
      props.previous()
    }
    scrollTop()
  }

  /*Error handler*/
  const [formErrorMap, setFormErrorMap] = useState([])
  const isEmpty = (str) => str ? false : true

  const formValidationSectionG = () => {
    const formErrorMessage = {}

    if (isEmpty(isApplicableSectionG)) {
      formErrorMessage["isApplicableSectionG"] = "This is required field"
    } else if (isApplicableSectionG === "Yes") {
      if (isEmpty(question1)) {
        formErrorMessage["question1"] = "This is required field"
      }
      if (isEmpty(question2)) {
        formErrorMessage["question2"] = "This is required field"
      }
      if (isEmpty(question3)) {
        formErrorMessage["question3"] = "This is required field"
      }
      if (isEmpty(q3PropertyAddress)) {
        formErrorMessage["q3PropertyAddress"] = "This is required field"
      }
      if (isEmpty(q3CurrentValue)) {
        formErrorMessage["q3CurrentValue"] = "This is required field"
      }
      if (isEmpty(q3LoanNumber)) {
        formErrorMessage["q3LoanNumber"] = "This is required field"
      }
      if (isEmpty(q3MonthlyPayment)) {
        formErrorMessage["q3MonthlyPayment"] = "This is required field"
      }
      if (isEmpty(question4)) {
        formErrorMessage["question4"] = "This is required field"
      } else if (question4 === 'Yes') {
        if (isEmpty(q4ServicerName)) {
          formErrorMessage["q4ServicerName"] = "This is required field"
        }
        if (isEmpty(q4LoanNumber)) {
          formErrorMessage["q4LoanNumber"] = "This is required field"
        }
        if (isEmpty(q4MonthlyPayment)) {
          formErrorMessage["q4MonthlyPayment"] = "This is required field"
        }
      }
      //q5
      if (isEmpty(q4MonthlyPayment)) {
        formErrorMessage["question5"] = "This is required field"
      }
      if (isEmpty(q5MonthlyFee)) {
        formErrorMessage["q5MonthlyFee"] = "This is required field"
      }
      if (isEmpty(question6)) {
        formErrorMessage["question6"] = "This is required field"
      }
      if (isEmpty(question7)) {
        formErrorMessage["question7"] = "This is required field"
      }
      if (isEmpty(question8)) {
        formErrorMessage["question8"] = "This is required field"
      }
      if (isEmpty(question9)) {
        formErrorMessage["question9"] = "This is required field"
      }
      if (isEmpty(question10)) {
        formErrorMessage["question10"] = "This is required field"
      }
      if (isEmpty(question11)) {
        formErrorMessage["question11"] = "This is required field"
      }
      if (isEmpty(question12)) {
        formErrorMessage["question12"] = "This is required field"
      } else if (question12 === "Other") {
        if (isEmpty(question12OtherText)) {
          formErrorMessage["question12OtherText"] = "This is required field"
        }
      }
      if (isEmpty(question13)) {
        formErrorMessage["question13"] = "This is required field"
      }
      if (isEmpty(question14)) {
        formErrorMessage["question14"] = "This is required field"
      }
      if (isEmpty(question15)) {
        formErrorMessage["question15"] = "This is required field"
      }
      if (isEmpty(question16)) {
        formErrorMessage["question16"] = "This is required field"
      }
      if (isEmpty(question17)) {
        formErrorMessage["question17"] = "This is required field"
      } else if (question17 === "Yes") {
        if (isEmpty(question18)) {
          formErrorMessage["question18"] = "This is required field"
        }
        if (isEmpty(question19)) {
          formErrorMessage["question19"] = "This is required field"
        } else if (!checkPhoneLength(question19)) {
          formErrorMessage["question19"] = "invalid"
        }
        if (isEmpty(question20)) {
          formErrorMessage["question20"] = "This is required field"
        }
      }
      if (isEmpty(question21)) {
        formErrorMessage["question21"] = "This is required field"
      } else if (question21 === "Yes") {
        if (isEmpty(question22)) {
          formErrorMessage["question22"] = "This is required field"
        }
        if (isEmpty(question23)) {
          formErrorMessage["question23"] = "This is required field"
        }
      }

    }
    if (checkBoxStatus(isRPCCheckbox1) === false) {
      if (checkBoxStatus(isRPCCheckbox2) === false) {
        formErrorMessage["isRPCCheckbox2"] = "This is required field"
      }
    }

    console.log("===form error G===", formErrorMessage)
    return formErrorMessage;
  }

  const errorHandler = (field) => {
    if (formErrorMap[field]) {
      let message = formErrorMap[field] === "invalid" ? "Invalid Data, Please check the value" : "*"
      return message
    } else {
      return false
    }
  }

  const radioErrorHandler = (field) => {
    if (errorHandler(field)) {
      return (<span className={parentStyle.errorMessage}>{errorHandler(field)}</span>)
    }
  }
  /*Error handler end*/


  return (
    <Box className={classes.root}>
      <Box style={{ marginTop: "-85px" }}>
        <SaveAndExitPopup actionCallBack={saveAndExitAction}></SaveAndExitPopup>
      </Box>
      <Box height={"90%"} style={{ paddingTop: "25px" }}>
        <Typography className={classes.title}>Other Property For Which Assistance Is Requested </Typography>
        <form className={classes.formStyle}>
          {activeSubStep === 0 && (<Fragment>
            <Box className={classes.inputBox}>

              <FormControl component="fieldset" className={classes.formControl} >
                <FormLabel component="legend" className={parentStyle.formLabel}>Is applicable this section
                  {radioErrorHandler("isApplicableSectionG")}
                </FormLabel>
                <RadioGroup name='isApplicableSectionG' value={isApplicableSectionG} onChange={handleChange}>
                  <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>

              {/* <FormControlLabel
                control={<Checkbox checked={checkBoxStatus(isApplicableSectionG)} onChange={handleChange} name="isApplicableSectionG" color="primary" />}
                label="Check this box if this section does not apply to you." /> */}
            </Box>
            {isApplicableSectionG === 'Yes' && <Box>
              <Box className={classes.inputBox}>
                <FormControl component="fieldset" className={classes.formControl} >
                  <FormLabel component="legend" className={parentStyle.formLabel}>I am requesting mortgage assistance for a rental property
                  {radioErrorHandler("question1")}
                  </FormLabel>
                  <RadioGroup name='question1' value={question1} onChange={handleChange}>
                    <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" className={classes.formControl} >
                  <FormLabel component="legend" className={parentStyle.formLabel}>I am requesting mortgage assistance for a second or seasonal home
                  {radioErrorHandler("question2")} </FormLabel>
                  <RadioGroup name='question2' value={question2} onChange={handleChange}>
                    <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
                <FormControl component="fieldset" className={classes.formControl} >
                  <FormLabel component="legend" className={parentStyle.formLabel}>I am requesting mortgage assistance for a home that is no longer my primary residence due to an out of area job transfer or foreign service assignment. I intend
tooccupy this property as my primary residence some time in the future {radioErrorHandler("question3")}</FormLabel>
                  <RadioGroup name='question3' value={question3} onChange={handleChange}>
                    <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>

                <Box className={classes.flexBox}>
                  <TextField
                    size="small"
                    error={errorHandler('q3PropertyAddress') !== false}
                    helperText={errorHandler('q3PropertyAddress')}
                    onChange={handleChange}
                    value={q3PropertyAddress}
                    name="q3PropertyAddress"
                    label="Property Address"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />
                  <TextField
                    size="small"
                    error={errorHandler('q3LoanNumber') !== false}
                    helperText={errorHandler('q3LoanNumber')}
                    onChange={handleChange}
                    value={q3LoanNumber}
                    name="q3LoanNumber"
                    label="Loan Number"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />
                </Box>

                <Box className={classes.flexBox}>
                  <TextField
                    size="small"
                    error={errorHandler('q3CurrentValue') !== false}
                    helperText={errorHandler('q3CurrentValue')}
                    onChange={handleChange}
                    value={q3CurrentValue}
                    name="q3CurrentValue"
                    label="Current Value"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />
                  <TextField
                    size="small"
                    error={errorHandler('q3MonthlyPayment') !== false}
                    helperText={errorHandler('q3MonthlyPayment')}
                    onChange={handleChange}
                    value={q3MonthlyPayment}
                    name="q3MonthlyPayment"
                    label="Monthly Payment"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />
                </Box>

                <Typography className={classes.title}>Provider of your first mortgage (if not BSI)</Typography>

                <FormControl component="fieldset" className={classes.formControl} >
                  <FormLabel component="legend" className={parentStyle.formLabel}>Do you have a second mortgage on the property?
                  {radioErrorHandler("question4")}
                  </FormLabel>
                  <RadioGroup name='question4' value={question4} onChange={handleChange}>
                    <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
                {question4 === "Yes" ? (<Fragment>
                  <Box className={classes.flexBox}>
                    <TextField
                      size="small"
                      error={errorHandler('q4ServicerName') !== false}
                      helperText={errorHandler('q4ServicerName')}
                      onChange={handleChange}
                      value={q4ServicerName}
                      name="q4ServicerName"
                      label="Servicer Name"
                      color='secondary'
                      variant="outlined"
                      className={parentStyle.inputField}
                      InputProps={{
                        classes: { root: parentStyle.inputText }
                      }}
                    />
                    <TextField
                      size="small"
                      error={errorHandler('q4LoanNumber') !== false}
                      helperText={errorHandler('q4LoanNumber')}
                      onChange={handleChange}
                      value={q4LoanNumber}
                      name="q4LoanNumber"
                      label="Loan Number"
                      color='secondary'
                      variant="outlined"
                      className={parentStyle.inputField}
                      InputProps={{
                        classes: { root: parentStyle.inputText }
                      }}
                    />
                  </Box>
                  <Box className={classes.flexBox}>
                    <TextField
                      size="small"
                      error={errorHandler('q4MonthlyPayment') !== false}
                      helperText={errorHandler('q4MonthlyPayment')}
                      onChange={handleChange}
                      value={q4MonthlyPayment}
                      name="q4MonthlyPayment"
                      label="Monthly Payment"
                      color='secondary'
                      variant="outlined"
                      className={parentStyle.inputField}
                      InputProps={{
                        classes: { root: parentStyle.inputText }
                      }}
                    />
                  </Box></Fragment>) : null}

                <FormControl component="fieldset" className={classes.formControl} >
                  <FormLabel component="legend" className={parentStyle.formLabel}>Do you have condominium or home owners association(HOA)fees?
                  {radioErrorHandler("question5")}
                  </FormLabel>
                  <RadioGroup name='question5' value={question5} onChange={handleChange}>
                    <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
                {<Box className={classes.flexBox}>
                  <TextField
                    size="small"
                    error={errorHandler('q5MonthlyFee') !== false}
                    helperText={errorHandler('q5MonthlyFee')}
                    onChange={handleChange}
                    value={q5MonthlyFee}
                    name="q5MonthlyFee"
                    label="Monthly Fee"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputFieldNumber}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />
                </Box>}
                <FormControl component="fieldset" className={classes.formControl} >
                  <FormLabel component="legend" className={parentStyle.formLabel}>Are HOA fees paid current?
                  {radioErrorHandler("question6")}
                  </FormLabel>
                  <RadioGroup name='question6' value={question6} onChange={handleChange}>
                    <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
                <Box className={classes.inputBox}>
                  <Typography className={parentStyle.questionText}>Name/address that fees are paid to {radioErrorHandler("question7")}</Typography>
                  <TextareaAutosize
                    rowsMin={3}
                    onChange={handleChange}
                    value={question7}
                    name="question7"
                    //label="Name/address that fees are paid to"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputTextTextarea}

                  // className={parentStyle.inputField}
                  // InputProps={{
                  //   classes: { root: parentStyle.inputText }
                  // }}
                  />
                </Box>
                <FormControl component="fieldset" className={classes.formControl} >
                  <FormLabel component="legend" className={parentStyle.formLabel}>Does your mortgage payment include taxes and insurance?
                  {radioErrorHandler("question8")}
                  </FormLabel>
                  <RadioGroup name='question8' value={question8} onChange={handleChange}>
                    <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" className={classes.formControl} >
                  <FormLabel component="legend" className={parentStyle.formLabel}>If "No",are the taxes and insurance paid current?
                  {radioErrorHandler("question9")}
                  </FormLabel>
                  <RadioGroup name='question9' value={question9} onChange={handleChange}>
                    <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
                <Box className={classes.flexBox}>
                  <TextField
                    size="small"
                    error={errorHandler('question10') !== false}
                    helperText={errorHandler('question10')}
                    onChange={handleChange}
                    value={question10}
                    name="question10"
                    label="Annual home owners insurance"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />
                  <TextField
                    size="small"
                    error={errorHandler('question11') !== false}
                    helperText={errorHandler('question11')}
                    onChange={handleChange}
                    value={question11}
                    name="question11"
                    label="Annual Property Taxes"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />
                </Box>
                <FormControl component="fieldset" className={classes.formControl} >
                  <FormLabel component="legend" className={parentStyle.formLabel}>If requesting assistance for a rental property, property is currently
                  {radioErrorHandler("question12")}
                  </FormLabel>
                  <RadioGroup name='question12' value={question12} onChange={handleChange}>
                    <FormControlLabel className={parentStyle.labelStyle} value="Vacant and available for rent" control={<Radio />} label="Vacant and available for rent" />
                    <FormControlLabel className={parentStyle.labelStyle}
                      value="Occupied with out rent by your legal dependent, parent or grandparent as their principal residence"
                      control={<Radio />}
                      label="Occupied with out rent by your legal dependent, parent or grandparent as their principal residence" />
                    <FormControlLabel className={parentStyle.labelStyle}
                      value="Occupied by a tenant as their principal residence" control={<Radio />}
                      label="Occupied by a tenant as their principal residence" />
                    <FormControlLabel className={parentStyle.labelStyle} value="Other" control={<Radio />} label="Other" />
                  </RadioGroup>
                </FormControl>
                {question12 === "Other" && <TextField
                  size="small"
                  error={errorHandler('question12OtherText') !== false}
                  helperText={errorHandler('question12OtherText')}
                  onChange={handleChange}
                  value={question12OtherText}
                  name="question12OtherText"
                  label="Other Text"
                  color='secondary'
                  variant="outlined"
                  className={parentStyle.inputField}
                  InputProps={{
                    classes: { root: parentStyle.inputText }
                  }}
                />}
                <Box>
                  <Typography className={parentStyle.questionText}>If rental propertyis occupied by tenant Term of lease/occupancy</Typography>
                  <TextField
                    size="small"
                    error={errorHandler('question13') !== false}
                    helperText={errorHandler('question13')}
                    onChange={handleChange}
                    value={question13}
                    name="question13"
                    // label=""
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />
                </Box>
                <Box>
                  <Typography className={parentStyle.questionText}>Gross Monthly Rent</Typography>
                  <TextField
                    size="small"
                    error={errorHandler('question14') !== false}
                    helperText={errorHandler('question14')}
                    onChange={handleChange}
                    value={question14}
                    name="question14"
                    // label="Gross Monthly Rent"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputFieldNumber}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />
                </Box>
                {<Box>
                  <Typography className={parentStyle.questionText}>If rental property is vacant, describe efforts to rent property</Typography>
                  <TextField
                    size="small"
                    error={errorHandler('question15') !== false}
                    helperText={errorHandler('question15')}
                    onChange={handleChange}
                    value={question15}
                    name="question15"
                    // label=""
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />
                </Box>}
                {<Box>
                  <Typography className={parentStyle.questionText}>If you have a non-rent-paying occupant,describe your relationship to them and the duration of their occupancy</Typography>
                  <TextField
                    size="small"
                    error={errorHandler('question16') !== false}
                    helperText={errorHandler('question16')}
                    onChange={handleChange}
                    value={question16}
                    name="question16"
                    // label=""
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />
                </Box>}
                <FormControl component="fieldset" className={classes.formControl} >
                  <FormLabel component="legend" className={parentStyle.formLabel}>Is the property for sale?
                  {radioErrorHandler("question17")}
                  </FormLabel>
                  <RadioGroup name='question17' value={question17} onChange={handleChange}>
                    <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
                {question17 === "Yes" && <Fragment><Box className={classes.flexBox}>
                  <TextField
                    size="small"
                    error={errorHandler('question18') !== false}
                    helperText={errorHandler('question18')}
                    onChange={handleChange}
                    value={question18}
                    name="question18"
                    label="Listing Agent's Name"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />
                  <TextField
                    size="small"
                    error={errorHandler('question19') !== false}
                    helperText={errorHandler('question19')}
                    onChange={handleChange}
                    value={question19}
                    name="question19"
                    label="Phone Number"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />
                </Box>
                  <Box className={classes.flexBox}>
                    {/* <TextField
                    size="small"
                    error={errorHandler('question20')!==false}
                  helperText={errorHandler('question20')}
                      onChange={handleChange}
                      value={question20}
                      name="question20"
                      label="List Date"
                      color='secondary'
                      variant="outlined"
                      className={parentStyle.inputField}
                      InputProps={{
                        classes: { root: parentStyle.inputText }
                      }}
                    /> */}

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        //disabled={isRestrict}
                        views={['year', 'month', 'date']}
                        size="small"
                        error={errorHandler('question20') !== false}
                        helperText={errorHandler('question20')}
                        color='secondary' autoOk={true}
                        //disableToolbar
                        //minDate={new Date()}
                        variant="inline" inputVariant="outlined" format="MM/dd/yyyy" fullWidth

                        id="id-question20"
                        label="List Date"
                        value={question20}
                        name="question20"
                        onChange={handleDateChange("question20")}

                        KeyboardButtonProps={{ 'aria-label': 'change date', }}
                        placeholder={"MM/dd/yyyy"}
                        className={parentStyle.inputField}
                        InputProps={{ classes: { root: parentStyle.inputText } }}
                        InputLabelProps={{ shrink: true, }}
                      />
                    </MuiPickersUtilsProvider>
                  </Box></Fragment>}
                <FormControl component="fieldset" className={classes.formControl} >
                  <FormLabel component="legend" className={parentStyle.formLabel}>Have you received a purchase offer?
                  {radioErrorHandler("question21")}
                  </FormLabel>
                  <RadioGroup name='question21' value={question21} onChange={handleChange}>
                    <FormControlLabel className={parentStyle.labelStyle} value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel className={parentStyle.labelStyle} value="No" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
                {question21 === "Yes" && <Box className={classes.flexBox}>
                  <TextField
                    size="small"
                    error={errorHandler('question22') !== false}
                    helperText={errorHandler('question22')}
                    onChange={handleChange}
                    value={question22}
                    name="question22"
                    label="Amount of Offer"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  />
                  {/* <TextField
                  size="small"
                  error={errorHandler('question23')!==false}
                  helperText={errorHandler('question23')}
                    onChange={handleChange}
                    value={question23}
                    name="question23"
                    label="Closing Date"
                    color='secondary'
                    variant="outlined"
                    className={parentStyle.inputField}
                    InputProps={{
                      classes: { root: parentStyle.inputText }
                    }}
                  /> */}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      //disabled={isRestrict}
                      views={['year', 'month', 'date']}
                      size="small"
                      error={errorHandler('question23') !== false}
                      helperText={errorHandler('question23')}
                      color='secondary' autoOk={true}
                      //disableToolbar
                      //minDate={new Date()}
                      variant="inline" inputVariant="outlined" format="MM/dd/yyyy" fullWidth

                      id="id-question23"
                      label="Closing Date"
                      value={question23}
                      name="question23"
                      onChange={handleDateChange("question23")}

                      KeyboardButtonProps={{ 'aria-label': 'change date', }}
                      placeholder={"MM/dd/yyyy"}
                      className={parentStyle.inputField}
                      InputProps={{ classes: { root: parentStyle.inputText } }}
                      InputLabelProps={{ shrink: true, }}
                    />
                  </MuiPickersUtilsProvider>
                </Box>}
              </Box>
            </Box>}

          </Fragment>)}
          {/* RENTAL PROPERTY CERTIFICATION START */}
          {activeSubStep === 1 && (<Fragment>
            <Box>
              <Box className={classes.inputBox}>
                <Typography className={classes.subtitle}>Rental Property Certification</Typography>
                <Typography >You must complete this certification if you are requesting a mortgage modification with respect to a rental property</Typography>
                <FormControlLabel
                  control={<Checkbox checked={checkBoxStatus(isRPCCheckbox1)} onChange={handleChange} name="isRPCCheckbox1" color="primary" />}
                  label="Check this box if this section does not apply to you." />
              </Box>
              {checkBoxStatus(isRPCCheckbox1) === false && <Box>
                <Box className={classes.inputBox}>
                  <Typography>1-Iintendtorentthepropertytoatenantortenantsforatleastfiveyearsfollowingtheeffectivedateofmymortgage modification.Iunderstandthatthe servicer, the
                  U.S. Department of the Treasury,ortheir respective agentsmay ask me toprovide evidenceof my intentionto rent the property duringsuchtime.I further understand
                  that such evidence must show that I used reasonable efforts to rent the property to a tenant or tenants on a year-round basis, ifthe property is or becomesvacant
during such a five-yearperiod</Typography>
                </Box>
                <Box className={classes.inputBox}>
                  <Typography>Note:Theterm “reasonableefforts”includes, withoutlimitation,advertisingthepropertyforrent in localnewspapers,websites orothercommonly used formsof writtenor
electronicmedia, and/orengaginga real estateor other professionalto assistin rentingthe property,in eithercase,at orbelow marketrent.</Typography>
                </Box>
                <Box className={classes.inputBox}>
                  <Typography>2- The property is not my secondary residence and I do not in tend to use the property as a secondary residence for at least five years following the effective date of my
                  mortgage modification. I understand that if I do use the property as a secondary residenceduring suchfive-year period, my use of the property may be consideredto
beinconsistentwith the certificationsI have made herein.</Typography>
                </Box>
                <Box className={classes.inputBox}>
                  <Typography>Note: The term “secondary residence” includes, without limitation, a second home, vacation home or other type of residence that Ipersonally use or occupy on a
part-time,seasonalor otherbasis.</Typography>
                </Box>
                <Box className={classes.inputBox}>
                  <Typography>3-I donotown more thanfive(5)single-familyhomes (i.e.,one-to-four unitproperties)(exclusive ofmy principalresidence).</Typography>
                </Box>
                <Box className={classes.inputBox}>
                  <Typography>Notwithstanding the foregoing conditions, I may at any time sell the property, occupy it as my personal residence, or permit my legal dependent, parent or
                  grandparent to occupyit as their principal residence with no rent charged or collected, none of which will be considered to be inconsistent with the certifications
made herein</Typography>
                </Box>
                <FormControlLabel
                  control={<Checkbox checked={checkBoxStatus(isRPCCheckbox2)} onChange={handleChange} name="isRPCCheckbox2" color="primary" />}
                  label="By checking this box and initialingbelow,I am requestinga mortgage modification with respectto the rental property describedinthissectionandI hereby certify under
              penalty of perjurythat eachof the statements above aretrueandcorrect with respectto that property." />
                {radioErrorHandler("isRPCCheckbox2")}
              </Box>
              }

            </Box>
          </Fragment>)}
          {/* RENTAL PROPERTY CERTIFICATION END */}

        </form>
      </Box>
      <Box height={"10%"}>
        <NextPrevButton
          //handleNextCallback={props.next}
          handleNextCallback={nextSubStep}
          handlePrevCallBack={backSubStep}
          activeStepIndex={props.activeSection}></NextPrevButton>
        {/* <NextPrevButton
          handleNextCallback={handleSave}
          handlePrevCallBack={props.previous}
          activeStepIndex={props.activeSection}></NextPrevButton> */}
      </Box>
    </Box>
  )
}